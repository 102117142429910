export enum ExerciseSolvedEnum {
    TIMER,
    TIMER_END,
    EXERCISE_END,
    EXERCISE_SOLVED_MSG_1,
    EXERCISE_SOLVED_MSG_2,
    CURRENT_EXERCISE_CHANGED,
    EXERCISE_ERROR,
    SHOW_SOLUTION,
    SHOW_ANSWER,
    SHOW_CUSTOM
}