import { Component, Input, OnInit, OnDestroy, HostListener, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'app/core/guards/auth.service';
import { ExerciseService } from 'app/services/exercise.service';
declare var $

@Component({
  selector: 'ex-rapid',
  templateUrl: './rapid.component.html',
  styleUrls: ['./rapid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RapidComponent implements OnInit, OnDestroy {

  timerEnabled = false
  secs = 0
  sets = []
  currentSet = 0
  colores = [
    { r: 255, g: 145, b: 0 },
    { r: 12, g: 161, b: 241 },
    { r: 110, g: 203, b: 11 },
    { r: 59, g: 179, b: 156 },
    { r: 255, g: 192, b: 0 },
    { r: 134, g: 59, b: 153 }
  ]
  r = 0

  @Input() review
  @Input() show_errors;
  @Input() controlSession : boolean = false;
  @Input() slide : boolean = false; @Input() current;
  exercise;
  draggingElement
  ok = []
  error = []
  finished = false
  numOk = 0
  numErr = 0
  setComplete = false
  myInternalTimer
  myInternalTimer2
  myInternalTimer3

  elementsOk
  elementsErr
  leftWords
  rightWords
  leftValidation
  rightValidation
  opciones = 0

    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case "SHOW_SOLUTION":
                this.finished = true;
                if (this.exerciseService.showingSolution) {
                    setTimeout(this.ShowAnswersTimeOut.bind(this), 100);
                    break;
                } else {
                    setTimeout(this.showSolution.bind(this), 100);
                    break;
                }
            case "SHOW_ANSWER":
            case "SHOW_CUSTOM":
                this.ShowAnswer();
                break;
        }
    }

  constructor(
    private exerciseService: ExerciseService
  ) {
    this.exercise = this.exerciseService.getExerciseDefinition();
  }
    ngOnDestroy() {
        this.clearTimers()
    }

  ngOnInit() {  
    this.init()
    if (this.review) {
      this.elementsOk = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsOK
      );
      this.elementsErr = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsERR
      );
      if (this.show_errors == false) {
        this.showSolution();
      } else if (this.show_errors == true || this.show_errors == null) {
        this.ShowAnswer();
      }
    }
  }

  ShowAnswersTimeOut() {

  }

  showSolution() {
    for (let i = 0; i < this.sets.length; i++) {
      for (let j = 0; j < this.sets[i].length; j++) {
        if (this.sets[i][j].opcion == true)
          this.sets[i][j].answered = true;
        else
          this.sets[i][j].answered = null;
      }
    }
  }

  init() {
    this.sets = this.exercise.palabras;
    if (this.review) return
    for (let i = 0; i < this.sets.length; i++) {
      //this.sets[i].shuffle();
      this.sets[i] = this.sets[i].sort((a, b) => 0.5 - Math.random())
    }
    this.secs = this.exercise.segundos;
    // this.secs = 1000; Temp time to test
    this.r = this.getRandomInt(0, this.colores.length - 1);
    this.myInternalTimer = setTimeout(this.onInternalTimeout.bind(this), 1000);
  }

  validateWord(event) {
    var target = event.target;
    if (target.id == "")
      target = target.parentElement;
    var index = target.getAttribute("index");
    if (this.finished || this.sets[this.currentSet][index].answered == true)
      return;

    if (this.sets[this.currentSet][index].opcion == false) {
      for (let i = 0; i < this.sets[this.currentSet].length; i++)
        this.sets[this.currentSet][i].answered = null;
      this.sets[this.currentSet][index].answered = false;
      this.exerciseService.answer(this.currentSet + ";" + this.sets[this.currentSet][index].idPalabra, false, this.sets.length);
    }
    else {
      for (let i = 0; i < this.sets[this.currentSet].length; i++)
        if (this.sets[this.currentSet][i].answered == false)
          this.sets[this.currentSet][i].answered = null;
      this.sets[this.currentSet][index].answered = true;
      this.exerciseService.answer(this.currentSet + ";" + this.sets[this.currentSet][index].idPalabra, true, this.sets.length);
      //verifica si con esta respuesta se ha terminado, si ese es el caso
      //borra los timers y corta ejecucion de codigo
      if(this.exerciseService.getCurrentExercise().NumElementsOK == this.sets.length){
        this.clearTimers();
        return;
      }
    }
    var setComplete = true;
    for (let i = 0; i < this.sets[this.currentSet].length; i++) {
      if (this.sets[this.currentSet][i].opcion == true && this.sets[this.currentSet][i].answered != true)
        setComplete = false;
      if (this.sets[this.currentSet][i].opcion == false && this.sets[this.currentSet][i].answered != null)
        setComplete = false;
    }

    this.setComplete = setComplete;

    if (this.setComplete) {
      clearTimeout(this.myInternalTimer)
      this.myInternalTimer2 = setTimeout(this.ChangeSetFalse.bind(this), 700);
    }

  }

  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  color(answered, timeout) {
    var c = this.colores[this.r];
    if (timeout == true) {
      return {
        'border': '0.1em solid ',
        'border-color': 'var(--naranja)'
      }
    }
    if (answered == true) {
      return {
        'border': '0.1em solid ',
        'border-color': 'var(--verde)'
      }
    }
    if (answered == false) {
      return {
        'border': '0.1em solid ',
        'border-color': 'red'
      }
    }
    return {
      'background': "rgb(" + c.r + ", " + c.g + ", " + c.b + ")",
      'border': '0.1em solid',
      'border-color': "rgb(" + c.r + ", " + c.g + ", " + c.b + ")"
    }
  }

  ChangeSetTrue() {
    this.ChangeSet(true);
  }

  ChangeSetFalse() {
    this.ChangeSet(false);
  }

  ChangeSet(error) {
    if (this.finished == true) {
      return;
    }
    if (error) {
      // this.$emit("error");
      this.exerciseService.answer(this.currentSet + ";", false, this.sets.length);
    }
    // se agrego la condicion que este codigo se ejecute solo si, no se ha completado correctamente todo el ejercicio
    if (this.currentSet + 1 == this.sets.length && this.exerciseService.getCurrentExercise().NumElementsOK < this.sets.length) {
      // this.$emit("timer-stop");
      this.finished = true;
      this.exerciseService.endExercise();
      this.clearTimers();
      // this.$emit('exercise-solved');
    } else {
      this.setComplete = false;
      this.secs = this.exercise.segundos;
      this.myInternalTimer = setTimeout(this.onInternalTimeout.bind(this), 1000);
      this.currentSet++;
    }
  }

  onInternalTimeout() {
      if(this.controlSession) return;
    if (this.secs > 0)
      this.secs--;
    if (this.secs == 0) {
      for (let i = 0; i < this.sets[this.currentSet].length; i++)
        if (this.sets[this.currentSet][i].answered != true && this.sets[this.currentSet][i].opcion == true)
          this.sets[this.currentSet][i].timeout = true;
      this.myInternalTimer3 = setTimeout(this.ChangeSetFalse.bind(this), 700);
    }

    if (this.secs > 0 && this.finished == false)
      this.myInternalTimer = setTimeout(this.onInternalTimeout.bind(this), 1000);
    else
      clearTimeout(this.myInternalTimer);
  }

  ShowAnswer() {
    for (var p = 0; p < this.elementsErr.length; p++) {
      if (!(typeof this.elementsErr[p] === "string")) continue
      let split = this.elementsErr[p].split(";")
      this.elementsErr[p] = {
        a: split[0],
        b: split[1]
      }
    }
    for (var p = 0; p < this.elementsOk.length; p++) {
      if (!(typeof this.elementsOk[p] === "string")) continue
      let split = this.elementsOk[p].split(";")
      this.elementsOk[p] = {
        a: split[0],
        b: split[1]
      }
    }
    for (let i = 0; i < this.sets.length; i++) {
      for (let j = 0; j < this.sets[i].length; j++) {
        this.sets[i][j].answered = null;
        for (var k = 0; k < this.elementsErr.length; k++) {
          if (i == this.elementsErr[k].a && this.sets[i][j].idPalabra == this.elementsErr[k].b) {
            this.sets[i][j].answered = false;
          }
        }
        for (var k = 0; k < this.elementsOk.length; k++) {
          if (i == this.elementsOk[k].a && this.sets[i][j].idPalabra == this.elementsOk[k].b && this.sets[i][j].palabra.answered != false) {
            this.sets[i][j].answered = true;
          }
        }
      }
    }
  }
  //funcion para limpiar los timers
  clearTimers(){
    clearTimeout(this.myInternalTimer);
    clearTimeout(this.myInternalTimer2)
    clearTimeout(this.myInternalTimer3)
  }
}
