<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="frase spe_gap" fxLayout="column" fxLayoutAlign="stretch"
    [ngStyle]="{'pointer-events': review ? 'none;' : ''}">

    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div class="wordsContainer">
        <div class="items_container spe_gap" fxLayout="column" fxLayoutAlign="stretch">
            <div fxLayout="column spe_gap" fxLayoutAlign="space-between stretch" class="dropzone phraseBox">
                <div *ngIf="solucion.length == 0"></div>
                <div *ngIf="solucion.length > 0" fxLayout="row wrap" fxLayoutAlign="start start" class="respuesta_gap">
                    <div class="respuesta" fxLayout="row" *ngFor="let index of solucion">
                        {{ palabras[index].palabra }}
                    </div>
                </div>
                <div id="author" class="authorBox">
                    {{ exercise.libro }}.
                    <span class="authorInfo"> {{exercise.autor}}</span>
                </div>
            </div>
            <div fxFlex="" class="palabras">
                <div [attr.id]="i" [ngClass]="{'bien': palabra.answered == true, 'mal': palabra.answered == false && (show_errors != false || !review)}"
                    [style]="getWordPosition(palabra)" class="draggable palabra"
                    *ngFor="let palabra of palabras; let i = index">
                    <span>{{ palabra.palabra }}</span>
                </div>
            </div>
        </div>
    </div>

</div>