import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpParams, HttpEvent, HttpHeaderResponse, HttpResponse, HttpErrorResponse, HttpUserEvent, HttpProgressEvent } from '@angular/common/http';
import { Location } from '@angular/common';
import { environment } from 'environments/environment';

@Injectable()
export class LicenseService {

  baseUrl = environment.baseApi + '/api/License';

  constructor(private http: HttpClient, private _location: Location) {
  }

  getAvailableLicenses = function (idUser) {
    return this.http.get(this.baseUrl + "/available/iduser/" + idUser);
  }

  getPendingLicenses = function (idUser) {
    return this.http.get(this.baseUrl + "/pending/iduser/" + idUser);
  }

  canCreateParentUsers(IdParent: number) {
    return this.http.get(this.baseUrl + "/canCreateUsers/IdParent/" + IdParent);
  }
  CanCreateSchoolUsers(IdSchool: number){
    return this.http.get(this.baseUrl + "/canCreateUsers/IdSchool/" + IdSchool);
  }

}
