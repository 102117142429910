import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpParams, HttpEvent, HttpHeaderResponse, HttpResponse, HttpErrorResponse, HttpUserEvent, HttpProgressEvent } from '@angular/common/http';
import { environment } from 'environments/environment';
import interact from 'interactjs';
import { ExerciseSolvedEnum } from 'app/core/shared/enums/exercise-solved.enum';
declare var $, d;

@Injectable()
export class TestExerciseService {

    baseUrl = environment.baseApi + '/api/ExerciseTest';
    currentExercise = null;
    showingSolution = false;
    exerciseEnded = false;
    oks = [];
    kos = [];

    currentDictation;
    timer = {
        interval: null,
        startTime: null,
        currentSeconds: null,
        fullSeconds: null
    }

    constructor(private http: HttpClient) {}

    public get exercisesSolvedEnum(): typeof ExerciseSolvedEnum{
        return this.exerciseSolvedEnum
    }
        
    public get exerciseSolvedEnum(): typeof ExerciseSolvedEnum{
        return ExerciseSolvedEnum
    }

    setCurrentDictation(dictado) {
        this.currentDictation = dictado;
    }

    getDictation = function (level) {
        if (level >= 12)
            return this.http.get('.https://walinwa.blob.core.windows.net/images/DictationsSec/' + this.currentDictation.dictado + '.txt');
        else
            return this.http.get('.https://walinwa.blob.core.windows.net/images/Dictations/' + this.currentDictation.dictado + '.txt');
    }

    getDictations(idUser) {
        var params = { idUser: idUser };
        return this.http.post(this.baseUrl + '/dictations', params);
    }

    updateExercise(exercise) {
        exercise.ElementsOK = JSON.stringify(this.oks);
        exercise.ElementsERR = JSON.stringify(this.kos);
        return this.http.put(this.baseUrl + '/' + exercise.IdExercise, exercise)
    }

    getNewExercise(idType, idThema, level) {
        return this.http.get(this.baseUrl + '/idType/' + idType + '/idThema/' + idThema + '/level/' + level);
    }

    reset() {
        this.currentExercise = null;
        this.showingSolution = false;
        this.timer = {
            interval: null,
            startTime: null,
            currentSeconds: null,
            fullSeconds: null
        };
        this.oks = [];
        this.kos = [];
        this.exerciseEnded = false;
        interact('.dropzone').off('drop', function (e) { return; });
        interact('.dropzone').on('drop', function (e) { return; });
    }

    setCurrentExercise(exercise) {
        console.log("setCurrentExercise", exercise);
        interact('.dropzone').off('drop', function (e) { return; });
        interact('.dropzone').on('drop', function (e) { return; });
        this.currentExercise = exercise;
        this.currentExercise.NumElementsOK = 0;
        this.currentExercise.NumElementsERR = 0;
        this.currentExercise.NumElementsNull = 0;
        this.currentExercise.Result = "OK";
        this.currentExercise.StartDate = new Date();
        this.currentExercise.EndDate = new Date();
        this.startTimer();
    }

    getCurrentExercise() {
        return this.currentExercise;
    }

    getExerciseDefinition() {
        return JSON.parse(this.currentExercise.ExerciseDefinition);
    }

    getExerciseModel() {
        return this.currentExercise ? this.currentExercise.ExerciseModel : null;
    }

    getSeconds() {
        let now = new Date().getTime();
        return (now - this.timer.startTime) / 1000;
    }

    startTimer() {
        let exerciseDefinition = this.getExerciseDefinition();
        this.timer.fullSeconds = parseFloat(exerciseDefinition.tiempo);
        this.timer.startTime = new Date().getTime();
        this.timer.interval = setInterval(() => {
            this.timer.currentSeconds = this.getSeconds();
            if (this.timeExpired()) {
                if (this.currentExercise) this.currentExercise.Result = "ERR";
                window.postMessage(this.exercisesSolvedEnum[this.exerciseSolvedEnum.TIMER_END], "*");
                this.stopTimer();
            }
        }, 100);
    }

    stopTimer() {
        this.exerciseEnded = true;
        if (this.currentExercise) this.currentExercise.EndDate = new Date();
        interact('.dropzone').off('drop', function (e) { return; });
        interact('.dropzone').on('drop', function (e) { return; });
        clearInterval(this.timer.interval);
    }

    timeExpired() {
        return this.getSeconds() >= this.timer.fullSeconds;
    }

    getFullSeconds() {
        return this.timer.fullSeconds;
    }

    getCurrentSeconds() {
        return this.timer.currentSeconds;
    }

    showSolution() {
        this.showingSolution = true;
    }

    isShowingSolution() {
        return this.showingSolution;
    }

    answer(item, ok, nOptions) {
        if (ok == true) {
            if (this.oks.indexOf(item) != -1) return;
            this.oks.push(item);
            this.currentExercise.NumElementsOK++;
        }
        if (ok == false) {
            if (this.kos.indexOf(item) != -1) return;
            this.kos.push(item);
            this.currentExercise.NumElementsERR++;
            this.currentExercise.Result = "ERR";
        }
        this.updateExercise(this.currentExercise).subscribe(result => { })
        if (this.currentExercise.NumElementsOK == nOptions) {
            this.endExercise();
        }
    }

    endExercise() {
        window.postMessage("EXERCISE_END", "*");
        this.stopTimer();
    }

    exerciseIsEnded() {
        return this.exerciseEnded;
    }

    initInteract(callbacks: any) {
        if (!interact['initialized']) {
            interact('.draggable')
                .draggable({
                    'manualStart': true,
                    'onmove': function (event) {
                        var target = event.target;
                        var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
                        var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;
                        target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
                        target.setAttribute('data-x', x);
                        target.setAttribute('data-y', y);
                    },
                    'onend': function (event) {
                        if (event.target.remove)
                            event.target.remove();
                    }
                })
                .on('move', function (event) {
                    var interaction = event.interaction;
                    if (interaction.pointerIsDown && !interaction.interacting() && event.currentTarget.classList.contains('draggable')) {
                        var cloneChildId = event.currentTarget.getAttribute("dragChildImg");
                        if (cloneChildId) {
                            for (var i = 0; i < event.currentTarget.childNodes.length; i++) {
                                if (event.currentTarget.childNodes[i].id == cloneChildId)
                                    var original = event.currentTarget.childNodes[i];
                            }
                        }
                        else {
                            var original = event.currentTarget;
                        }
                        var clone = original.cloneNode(true);
                        clone.className = clone.className.replace(/\bdrag-element-source\b/, 'drag-element-item');
                        original.parentElement.appendChild(clone);
                        var marginVertical = $(original).outerHeight(true) - $(original).outerHeight() + "px ";
                        var marginHorizontal = $(original).outerWidth(true) - $(original).outerWidth() + "px";
                        clone.style.position = "absolute";
                        clone.style.left = $(original).position().left + "px";
                        clone.style.top = $(original).position().top + "px";
                        clone.style.width = original.clientWidth + "px";
                        clone.style.height = original.clientHeight + "px";
                        clone.style.margin = marginVertical + marginHorizontal;
                        try {
                            interaction.start({ name: 'drag' }, event.interactable, clone);
                        }
                        catch (err) {
                            if (clone.remove)
                                clone.remove();
                        }
                    } else {
                        interaction.start({ name: 'drag' }, event.interactable, event.currentTarget);
                    }
                });
            interact('.dropzone')
                .dropzone({ overlap: 'center' })
                .on('dragenter', function (event) {
                    var dropRect = interact.getElementRect(event.target),
                        dropCenter = {
                            x: dropRect.left + dropRect.width / 2,
                            y: dropRect.top + dropRect.height / 2
                        };
                    var draggableElement = event.relatedTarget,
                        dropzoneElement = event.target;
                    dropzoneElement.classList.add('drop-target');
                    draggableElement.classList.add('can-drop');
                })
                .on('dragleave', function (event) {
                    event.target.classList.remove('drop-target');
                    event.relatedTarget.classList.remove('can-drop');
                })
                .on('dropactivate', function (event) {
                    event.target.classList.add('drop-active');
                })
                .on('dropdeactivate', function (event) {
                    event.target.classList.remove('drop-active');
                    event.target.classList.remove('drop-target');
                })
                .on('drop', (event) => {
                    event.relatedTarget.textContent = '';
                });
            interact['initialized'] = true;
        }



        if (callbacks.onmove) {
            interact('.draggable').draggable({
                'onmove': callbacks.onmove
            });
        }

        interact('.dropzone').off('drop', function (e) { return; });
        interact('.dropzone').on('drop', function (e) { return; });

        if (callbacks.drop) {
            interact('.dropzone').on('drop', callbacks.drop);
        }

    }

    initDragging(options:any = {}) {
        new d.Dragging({
            dragging_zone: ".dragging-zone",
            ondragstart:  (event, element) => {
                if (options.ondragstart) options.ondragstart(event, element)
            },
            ondragend:  (event, element) => {
                if (options.ondragend) options.ondragend(event, element)
            },
            ondrop:  (event, element) => {
                if (options.ondrop) options.ondrop(event, element)
            },
            onmove:  (event, element) => {
                if (options.onmove) options.onmove(event, element)
            }
        })
    }

}
