<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="coloca spe_gap" fxLayout="column" fxLayoutAlign="stretch"
    [ngStyle]="{'pointer-events': review ? 'none;' : ''}">

    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <!-- <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="ruleContainer spe_gap" [ngClass]="{'element-hidden': exercise.regla == null}" >
        <div class="exerciseRule" fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-center">
            <span [innerHtml]="exercise.regla"></span>
        </div>
    </div> -->
    <!-- 2da Caja de mensaje -->
    <div class="ruleContainer" [hidden]="exercise.regla == null">
        <div class="exerciseRule">
            <span class="regla" [innerHtml]="exercise.regla"></span>
        </div>
    </div>

    <div class="wordsContainer" id="space-for-text">
        <div class="items_container">
            <section fxLayout="row nowrap" fxLayoutAlign="center" class="spe_gap" [ngClass]="{'fixControlSessions': controlSession}">
                <div id="answer{{ i }}" type="up" [attr.index]="i" *ngFor="let text of answer; let i = index"
                    class="answerBox dropzone" [ngClass]="{'boxOk':result == true, 'boxError': result==false, 'large': answer.length > 3}">
                    <div class="answerBoxChild draggable" type="up" [attr.index]="i">{{ text }}</div>
                </div>
            </section>

            <div class="optionsContainer dropzone spe_gap" id="space-for-buttons" fxLayout="row nowrap" fxLayoutAlign="center">
                <div id="option{{ i }}" type="down" [attr.index]="i" *ngFor="let text of options; let i = index"
                    class="optionBox draggable" dragChildImg="option{{ i }}">
                    <div class="optionBoxChild ">{{ text }}</div>
                </div>
            </div>
        </div>
    </div>
</div>