import { Component, HostListener, Input, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ExerciseSolvedEnum } from 'app/core/shared/enums/exercise-solved.enum';
import { SharedService } from 'app/core/shared/shared.service';
import { ExerciseService } from 'app/services/exercise.service';

@Component({
  selector: 'ex-boxes2b',
  templateUrl: './boxes2b.component.html',
  styleUrls: ['./boxes2b.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Boxes2bComponent implements OnInit, OnDestroy {
  @Input() review;
  @Input() show_errors;
  @Input() controlSession : boolean = false;
  @Input() slide : boolean = false; @Input() current;

  mobile: boolean = false;
  exercise;
  leftWords = [];
  rightWords = [];
  leftValidation;
  rightValidation;
  draggingWordIndex;
  exerciseIsEnded = false;
  finished = false

  elementsOk;
  elementsErr;
  draggingElement

    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_SOLUTION]:
                setTimeout(this.ShowSolution.bind(this), 100)
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_ANSWER]:
                setTimeout(this.ShowAnswer.bind(this), 100)
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.TIMER_END]:
                this.exerciseIsEnded = true;
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_CUSTOM]:
                setTimeout(this.ShowAnswer.bind(this), 100)
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.EXERCISE_END]:
                this.exerciseIsEnded = true;
                break;
        }
    }

  constructor(private _sharedService: SharedService, private exerciseService: ExerciseService) {}
  
  public get exercisesSolvedEnum(): typeof ExerciseSolvedEnum{
    return this.exerciseSolvedEnum
  }

  public get exerciseSolvedEnum(): typeof ExerciseSolvedEnum{
    return ExerciseSolvedEnum
  }

  ngOnDestroy(){
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerWidth;
    this.mobile = this._sharedService.isMobile();
  }

  ngOnInit() {
    this.mobile = this._sharedService.isMobile();
    this.exercise = this.exerciseService.getExerciseDefinition();
    this.leftValidation = this.exercise.boxes[0].valor;
    this.rightValidation = this.exercise.boxes[1].valor;
    if (this.review) {
      this.elementsOk = JSON.parse(this.exerciseService.getCurrentExercise().ElementsOK);
      this.elementsErr = JSON.parse(this.exerciseService.getCurrentExercise().ElementsERR);
      if (this.show_errors == false) {
        this.ShowSolution();
      } else if (this.show_errors == true || this.show_errors == null) {
        this.ShowAnswer();
        }
    } else {
      this.exerciseService.initInteract({
        onmove: (event) => {
          this.draggingWordIndex = event.target.getAttribute("id");
          this.draggingElement = event.target
        },
        drop: (event) => {
          if (this.exerciseIsEnded) return;
          let boxIndex = event.target.getAttribute("boxIndex");
          this.drop(boxIndex);
        }
      });
    }
  }

  ShowSolution() {
    this.leftWords = [];
    this.rightWords = [];
    for (var j = 0; j < this.exercise.palabras.length; j++) {
      this.exercise.palabras[j].answered = true;
      if (this.exercise.palabras[j].opcion == this.leftValidation) {
        this.leftWords.push({
          palabra: this.exercise.palabras[j].rae,
          answered: true,
        });
      }
      if (this.exercise.palabras[j].opcion == this.rightValidation) {
        this.rightWords.push({
          palabra: this.exercise.palabras[j].rae,
          answered: true,
        });
      }
    }
  }
  ShowAnswer() {
    this.leftWords = [];
    this.rightWords = [];
    for (var i = 0; i < this.exercise.palabras.length; i++) {
      this.exercise.palabras[i].answered = null;
      for (var j = 0; j < this.elementsErr.length; j++) {
        this.elementsErr[j] = parseInt(this.elementsErr[j]);
        if (
          this.exercise.palabras[i].palabra ==
          this.exercise.palabras[this.elementsErr[j]].palabra
        ) {
          this.exercise.palabras[i].answered = false;
        }
      }
    }
    for (var i = 0; i < this.exercise.palabras.length; i++) {
      for (var j = 0; j < this.elementsOk.length; j++) {
        this.elementsOk[j] = parseInt(this.elementsOk[j]);
        if (
          this.exercise.palabras[i].palabra ==
          this.exercise.palabras[this.elementsOk[j]].palabra &&
          this.exercise.palabras[i].answered != false
        ) {
          this.exercise.palabras[i].answered = true;
          if (
            this.exercise.palabras[i].opcion == this.leftValidation
          ) {
            this.leftWords.push({
              palabra: this.exercise.palabras[i].rae,
              answered: true,
            });
          }
          if (
            this.exercise.palabras[i].opcion == this.rightValidation
          ) {
            this.rightWords.push({
              palabra: this.exercise.palabras[i].rae,
              answered: true,
            });
          }
        }
      }
    }
  }

  showingSolution() {
    let isShowingSolution = this.exerciseService.isShowingSolution();
    if (isShowingSolution) {
      for (let word of this.exercise.palabras) {
        if (word.answered != true) {
          if (word.opcion == this.leftValidation) {
            this.leftWords.push({ palabra: word.rae, answered: false });
          }
          if (word.opcion == this.rightValidation) {
            this.rightWords.push({ palabra: word.rae, answered: false });
          }
          word.answered = true;
        }
      }
    }
    return isShowingSolution;
  }

  drop(boxIndex) {


    if (!this.exercise.palabras[this.draggingWordIndex]) return;

    if (this.exercise.palabras[this.draggingWordIndex].answered == true) return;

    for (let word of this.exercise.palabras) {
      if (word.answered != true) word.answered = null;
    }

    let word = this.exercise.palabras[this.draggingWordIndex];

    let wordValidation = word.opcion;
    let boxValidator;
    if (boxIndex == 0)
      boxValidator = this.leftValidation;
    else
      boxValidator = this.rightValidation;

    if (wordValidation == boxValidator) {

      if (boxIndex == 0)
        this.leftWords.push({ palabra: this.exercise.palabras[this.draggingWordIndex].rae, answered: true });
      else
        this.rightWords.push({ palabra: this.exercise.palabras[this.draggingWordIndex].rae, answered: true });
      this.exercise.palabras[this.draggingWordIndex].answered = true;
      this.exerciseService.answer(this.draggingWordIndex, this.exercise.palabras[this.draggingWordIndex].answered, this.exercise.palabras.length);
    }
    else {
      this.exercise.palabras[this.draggingWordIndex].answered = false;
      this.exerciseService.answer(this.draggingWordIndex, this.exercise.palabras[this.draggingWordIndex].answered, this.exercise.palabras.length);
    }
    var done = true;
    for (var i = 0, len = this.exercise.palabras.length; i < len; i++) {

      if (this.exercise.palabras[i].answered != true) {
        done = false;
      }
    }
    if (done) {
      this.exerciseIsEnded = true;
    }
    return;

    }
    showHourglassAnswer(){
        this.leftValidation = this.exercise.boxes[0].valor;
        this.rightValidation = this.exercise.boxes[1].valor;
        this.rightWords = []
        this.leftWords = []
        this.exercise.palabras.forEach(palabra => palabra.answered = null);
        this.elementsErr.forEach(indexPalabra => {
            let palabraErrada = this.exercise.palabras[indexPalabra]
            palabraErrada.answered = false
        })
        this.elementsOk.forEach(indexPalabra => {
            let palabraAcertada = this.exercise.palabras[indexPalabra]
            if (palabraAcertada.answered == false) {
                return;
            }
            palabraAcertada.answered = true
            if(palabraAcertada.opcion == this.leftValidation){
                this.leftWords.push({ palabra: palabraAcertada.rae, answered: false });
            }
            else {
                this.rightWords.push({ palabra: palabraAcertada.rae, answered: false });
            }
        })
    }

}
