<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="marca1 spe_gap" fxLayout="column" fxLayoutAlign="stretch"
    [ngStyle]="{'pointer-events': review ? 'none;' : ''}">

    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div fxFlex="100" fxLayout="column nowrap" fxLayoutAlign="space-between center" class="wordsContainer"
        id="space-for-text">
        <div *ngFor="let word of exercise.palabras; let i = index" id="rowContainer{{ i }}" class="exerciseRowContainer"
            fxFlex="" fxLayout="row nowrap" fxLayoutAlign="center center">
            <div *ngFor="let sylabe of word.sylabes; let j = index" [ngClass]="{
                    wordBoxOk: sylabe.answered == true,
                    wordBoxError: sylabe.answered == false,
                    answerBox: sylabe.text != '-',
                    answerBoxChildLine: sylabe.text == '-',
                    wordBoxNotAnswered: sylabe.answered == null && j == ((word.val - 1) * 2) && show_errors == null
                }">
                <span (click)="validateClick($event)" id="answer{{ i }}{{ j }}" [attr.index]="j" [attr.indexword]="i">
                    {{ sylabe.text }}
                </span>

            </div>
        </div>
    </div>
</div>