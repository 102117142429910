import { FindUrlPipe } from '../../../pipes/find-url.pipe';
import { AvatarShopService } from 'app/services/avatar-shop.service';
import { AvatarItem } from 'app/core/shared/state/models/avatar-item.model';
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
@Component({
    selector: 'avatar-file',
    templateUrl: './avatar-file.component.html',
    styleUrls: ['./avatar-file.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AvatarFileComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
    @Input() activeItems: AvatarItem[];

    constructor(private avatarService: AvatarShopService) { 
        
        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit(): void {
    }

    getImageUrl(item: AvatarItem): string {
        return "https://walinwa.blob.core.windows.net" + this.avatarService.subDirectory + this.resolveImageName(item)+ "?ngsw-bypass=true";
    }

    isTypeNoAttribute(item: AvatarItem): boolean {
        return item.Type == 4 || item.Type == 5 || item.Type == 6 || item.Type == 7 || item.Type == 10 || item.Type == 11
    }

    resolveImageName(item: AvatarItem): string{
        let imageName = "";
        if (item?.selectedAttribute && !this.isTypeNoAttribute(item)) {
            imageName = item.Id + "_" + item.selectedAttribute.Color + ".png";
        } else {
            if (this.isTypeNoAttribute(item)) {
                imageName = item.Id + ".png";
            } else {
                imageName = item.Id + "_0.png";
            }
        }
        return imageName;
    }

}
