import { CourseService } from 'app/services/course.service';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  AfterViewInit,
  HostListener,
  ChangeDetectorRef,
} from "@angular/core";
import * as moment from "moment";
import { UserService } from "app/services/user.service";
import { AccountService } from "app/main/authentication/account.service";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "environments/environment";
import { FuseDialogContinueComponent } from "@fuse/components/dialog-continue/dialog-continue.component";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { SchoolService } from "app/services/school.service";
import { NavigationService } from "app/services/navigation.service";
import { AuthService } from "app/services/auth.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";;
import { SharedService } from "app/core/shared/shared.service";
import { AbstractControl, FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "school",
  templateUrl: "./school.component.html",
  styleUrls: ["./school.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SchoolComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;

  numStudents:Number = 0;
  maxNumStudents:Number = 0;
  dataLoaded = false;
  currentUser;
  schoolAdminView = false;
  school_valid_until:Date = null;

  showHelp = false;
  hasClasses;
  schoolAdmin;
  walinwaAdmin;

  // intentar limpiar
  childComponent;
  showInfo;
  modificarDatos;
  showPassword1;
  showPassword2;
  showPassword3;
  newPassword;
  checkClaveActual;
  checkRepetirPass = true;
  checkNewPass = true;
  authUsers;
  classes = [];
  isTeacher = true;
  assignedIdClass = 0;
    editSchoolData = false;
  // Custom
  isMobile: boolean;
  regExp = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜäÄëËïÏöÖç\s\.\-'ª’]+$/u
  isiPad: boolean;
    formData: UntypedFormGroup;
    formSchool: UntypedFormGroup;

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    setTimeout(() => {
      this.updateHeight();
    }, 750);
  }

  constructor(
    private router: Router,
    private userService: UserService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private accountService: AccountService,
    private _matDialog: MatDialog,
    public schoolService: SchoolService,
    public navigationService: NavigationService,
    private authService: AuthService,
    public sharedService: SharedService,
    private _formBuilder: UntypedFormBuilder,
    private courseService: CourseService,
    private _detector: ChangeDetectorRef,
    private _translateService: TranslateService
  ) {
    iconRegistry.addSvgIcon(
      "gafas_off",
      sanitizer.bypassSecurityTrustResourceUrl(
        "https://walinwa.blob.core.windows.net/images/gafas1.svg?ngsw-bypass=true"
      )
    );
    iconRegistry.addSvgIcon(
      "gafas_on",
      sanitizer.bypassSecurityTrustResourceUrl(
        "https://walinwa.blob.core.windows.net/images/gafas2.svg?ngsw-bypass=true"
      )
    );
    this.navigationService.reset();
    this._unsubscribeAll = new Subject();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnInit() {
    if (!this.authService.check()) return;
    if (this.router.url.includes("admin")) this.schoolAdminView = true;
    else this.schoolAdminView = false;
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.schoolAdmin = this.currentUser.IsSchoolAdmin || false;
    this.walinwaAdmin = this.currentUser.IsAdmin || false;
    this.initializeObservables();
    
    if (this.currentUser.IdSchool) {
        this.getSchool();
        this.schoolService.isUserAssigned(this.currentUser.Id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                if (result) {
                this.assignedIdClass = result.IdClass
                this.sharedService.changeSchoolHasClasses(true);
            }
            this.schoolService.changeIsTeacher(this.currentUser.IdRole == 2 || result != null)
        });
        
    } else {
        this.redirectUser();
      this.dataLoaded = true;
    }
    if (!this.authService.currentUser.TutorialDone) {
      this.showHelp = true;
    }

    if (this.schoolAdminView) {
      // document.body.classList.add("admin_profile");
      document.getElementById("noMenuAdminProfile").classList.add("admin_profile");
    } else {
      // document.body.classList.add("student_profile");
      document.getElementById("noMenuAdminProfile").classList.add("student_profile");
    }

    // Detect Only Ipad
    this.isiPad = this.detectiPad(); 
  }
    initializeObservables() {
        this.sharedService.num_students
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                this.numStudents = data;
            });
        this.sharedService.max_num_students
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                this.maxNumStudents = data;
            });
        this.schoolService.is_teacher
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                this.isTeacher = data;
                this.schoolAdminView = !this.isTeacher;
                this.redirectUser();
            })
        this.sharedService.school_has_classes
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                this.hasClasses = data;
                if (!this.hasClasses) {
                    if (this.schoolAdmin) this.changeProfile();
                }
            });
        this.sharedService.school_valid_until
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                this.school_valid_until = data;
            });

        this.sharedService.reload_teacher
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
            });
    }
  ngAfterViewInit() {

    setTimeout(() => {
      this.updateHeight();
      // console.log("delay 2.5 second");
    }, 2500);
  }
    redirectUser() {
            if (this.authService.currentUser.IdSchool == null) {
                if(this.router.url?.toLowerCase() == "/school" || this.router.url?.toLowerCase() == "/school/"){
                    this.navigationService.go("/school/parentStudents");
                }
                return;
            }
            if (this.isTeacher) {
                if(this.router.url?.toLowerCase() == "/school" || this.router.url?.toLowerCase() == "/school/"){
                    this.navigationService.go("/school/students");
                }
                return;
            }
            this.navigationService.go("/school/admin");
        
    }
  detectiPad(): boolean {
    const userAgent = window.navigator.userAgent;
    return /iPad/i.test(userAgent);
  }

    updateHeight() {
        let TooperHeaderElement = document.getElementById(
            "TooperHeaderElement"
        )?.offsetHeight;
        let FotFooterElement =
            document.getElementById("FotFooterElement")?.offsetHeight;
        if (TooperHeaderElement == null || FotFooterElement == null) {
            return;
        }
        let windowWidth = window.innerWidth;
        let windowHeight = window.innerHeight;

        let mainBoxHeight = windowHeight - TooperHeaderElement - FotFooterElement - 36;

        this.isMobile = this.detectMobile();

        let rootElement = document.documentElement;
        let newClass = "scroll_vertical";

        let style = document.createElement("style");
        style.innerHTML = "." + newClass + " { height:" + mainBoxHeight + "px; }";

        // console.log("TooperHeaderElement: " + TooperHeaderElement + " - FotFooterElement: " + FotFooterElement + " - windowWidth: " + windowWidth + " - windowHeight: " + windowHeight + " - mainBoxHeight: " + mainBoxHeight);

        // if (this.isMobile == true && windowWidth > 743 && windowWidth < 1367) {
        if (windowWidth > 959) {
            rootElement.appendChild(style);
        }
    }

  detectMobile(): boolean {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile|android|iphone|ipad|iemobile|wpdesktop/i.test(userAgent);
  }

    demo() {
        this.schoolService.changeTestSession(true);
        this.navigationService.go("/school/admin", {
            state: "demo",
            backToTeacher: !this.schoolAdminView,
        });
        if (!this.schoolAdminView) {
            this.changeProfile()
        }
    }
  closeModal(){
    this.editSchoolData = false;
  }
  changeInfoShow() {
    if(this.schoolAdmin && this.router.url.includes("school/admin")){
        this.formSchool = this._formBuilder.group({
            Name: [this.schoolService.school.Name, [Validators.required]],
            Cif: [this.schoolService.school.Cif],
            MaxNumStudents: [this.schoolService.school.MaxNumStudents],
            Street: [this.schoolService.school.Street],
            State: [this.schoolService.school.State],
            Cp: [this.schoolService.school.CP],
            Email: [this.schoolService.school.Email, [Validators.required/*, this.multipleEmail*/]],
            Responsible: [this.schoolService.school.Responsible],
        });
        this.formSchool.markAllAsTouched();
        this.editSchoolData = !this.editSchoolData;
        return;
    }

    this.showInfo = !this.showInfo;
        if (this.showInfo) {
            setTimeout(function () {
                document.body.addEventListener(
                    "click",
                    bodyListener.bind(this),
                    true
                );
            }, 100);
        } else {
            document.body.removeEventListener(
                "click",
                bodyListener.bind(this),
                true
            );
        }
        function bodyListener() {
            this.showInfo = false;
        }
  }

  /*multipleEmail = (control: AbstractControl): { [key: string]: any } | null => {
    const emails = control.value.split(';').map(e => e.trim());
    const forbidden = emails.some(email => Validators.email(new FormControl(email)));
    
    return forbidden ? { 'Email': { value: control.value } } : null;
  };*/

  bodyListener() {
    this.showInfo = false;
  }

  getClasses(callback) {
    this.userService
      .getClassesByIdTutor(this.currentUser.Id)
      .subscribe((result: any) => {
        this.classes = result;
        this.classes.sort(function (a, b) {
          return a.Level - b.Level;
        });
        this.userService
          .GetAuthorizedStudentsByParentId(this.currentUser.Id)
          .subscribe(
            (response) => {
              if (response != null) {
                this.authUsers = response;
                if (this.authUsers.length != 0) {
                  for (
                    let i = 0;
                    i < this.authUsers.length;
                    i++
                  ) {
                    this.authUsers[i].AuthUser = true;
                  }
                }
                let noClasses = false;
                if (this.currentUser.IdSchool != null) {
                  if (this.classes == null) {
                    this.classes = [];
                    noClasses = true;
                  }
                  if (
                    this.authUsers &&
                    this.authUsers.length > 0
                  ) {
                    let classAuth = {
                      Name: "Autorizados",
                      IdClass: -1,
                    };
                    this.classes.push(classAuth);
                  }
                }
              }
              this.hasClasses = this.classes.length > 0;
              if (!this.hasClasses) {
                if (this.schoolAdmin) this.changeProfile();
              }
              callback();
            },
            (error) => {
              this.hasClasses = this.classes.length > 0;
              if (!this.hasClasses) {
                if (this.schoolAdmin) this.changeProfile();
              }
              callback();
            }
          );
      });
  }

  getSchool() {
    this.schoolService
      .getSchoolByUser(this.currentUser.Id)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        this.schoolService.school = result;
        this.sharedService.changeMaxNumStudents(result.MaxNumStudents);
        this.sharedService.changeNumStudents(result.NumStudents);
        this.sharedService.changeSchoolValidUntil(result.ValidUntil);
        this.dataLoaded = true;
      });
  }

  changeAdminView() {
    if (this.walinwaAdmin) this.router.navigateByUrl("/admin");
  }

  onActivate(componentRef) {
    this._detector.detectChanges();   
    setTimeout(() => {
      this.childComponent = componentRef;
    }, 0);
  }

  isParentStudent() {
    if (this.currentUser.IsTutor && this.currentUser.IdSchool == null)
      return true;
    return false;
  }

  changeProfile() {
    this.schoolAdminView = !this.schoolAdminView;
    if (this.schoolAdminView) {
      this.router.navigateByUrl("school/admin");

      // document.body.classList.add("admin_profile");
      document.getElementById("noMenuAdminProfile").classList.add("admin_profile");
      
      // document.body.classList.remove("student_profile");
      document.getElementById("noMenuAdminProfile").classList.remove("student_profile");
    } else {
      this.router.navigateByUrl("school/students");

      // document.body.classList.remove("admin_profile");
      document.getElementById("noMenuAdminProfile").classList.remove("admin_profile");
      // document.body.classList.add("student_profile");
      document.getElementById("noMenuAdminProfile").classList.add("student_profile");
    }
  }

  formatDate(date) {
    return moment(date).format("DD/MM/YYYY");
  }
  openEditSchol
  openEditUser() {
    this.modificarDatos = 1;

    this.showPassword1 = false;
    this.showPassword2 = false;
    this.showPassword3 = false;
    this.newPassword = {
      act: {
        value: undefined,
        changed: false,
      },
      p1: {
        value: undefined,
        changed: false,
      },
      p2: {
        value: undefined,
        changed: false,
      },
    };
      this.formData = this._formBuilder.group({
        Name: [this.currentUser.Name, [Validators.required, Validators.pattern(this.regExp)]],
        FamilyName: [this.currentUser.FamilyName, [Validators.pattern(this.regExp)]],
        Email: [this.currentUser.Email, [Validators.required, Validators.email]],
        Alias: [this.currentUser.Alias, [Validators.maxLength(50)]],
      });
      this.formData.markAllAsTouched();
    this.checkClaveActual = true;
  }

    updateUser() {
    if (this.formData.invalid) return;
    this.currentUser.Name = this.formData.value.Name.trim();
    this.currentUser.FamilyName = this.formData.value.FamilyName?.trim();
    this.currentUser.Email = this.formData.value.Email.trim();
    this.currentUser.NormalizedEmail = this.currentUser.Email.toUpperCase();
    this.currentUser.Alias = this.formData.value.Alias?.trim()
    this.userService
      .updateUserEmailAndNameAndFamilynameAndAlias(this.currentUser)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result) => {
        sessionStorage.setItem(
          "currentUser",
          JSON.stringify(this.currentUser)
        );
        this.modificarDatos = null;
      },error => {
        if (error.error == "Email")
            this.formData.controls["Email"].setErrors({
                unique: true,
            });
      });
  }
    updateSchoolInfo() {
        let request = {
            IdSchool: this.schoolService.school.IdSchool,
            IdUser: this.currentUser.Id,
            Name: this.formSchool.value.Name,
            Cif: this.formSchool.value.Cif,
            Street: this.formSchool.value.Street,
            Cp: this.formSchool.value.Cp,
            State: this.formSchool.value.State,
            Responsible: this.formSchool.value.Responsible,
            Email: this.formSchool.value.Email,
        }
        this.schoolService.updateSchoolBySchoolAdmin(request)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(result => {
            this.dataLoaded = false;
            this.getSchool()
        })
    }
    showConfirmationMessage() {
        if (this.formSchool.invalid) return;
        let messages = []
        messages[0] = "Datos del centro";
        messages[1] = "Se va a proceder a guardar los cambios realizados en los datos del centro. ¿Estás seguro?"
        this.navigationService.warn(messages, () => {
            this.updateSchoolInfo();
        }, () => { })
    }

  comprobarClaveActual() {
    let userInfo = {
      userName: this.currentUser.UserName,
      password: this.newPassword.act.value,
      offset: (-(new Date().getTimezoneOffset() / 60)).toString(),
      userAgent: window.navigator.userAgent,
      version: environment.version,
    };

    this.accountService
      .login(userInfo)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (result) => {
          this.checkClaveActual = true;
          this.changePassword();
        },
        (error) => {
          this.checkClaveActual = false;
        }
      );
  }

  changePassword() {
    if (!this.newPassword.p1.value) {
      this.checkNewPass = false;
      return;
    }
    if (
      this.newPassword.p1.value != this.newPassword.p2.value ||
      !this.newPassword.p2.value
    ) {
      this.checkRepetirPass = false;
      return;
    }
    if (
      this.newPassword.p1.changed &&
      this.newPassword.p2.changed &&
      this.newPassword.p1.value == this.newPassword.p2.value
    ) {
      var message = [];
      message[0] = this._translateService.instant('SCHOOL.ATTENTION');
      message[1] =
      this._translateService.instant('STUDENT.MODAL-CHANGE-PASSWORD-MESSAGE');

      let confirmDialog = this._matDialog.open(
        FuseDialogContinueComponent,
        {
          disableClose: true,
        }
      );
      confirmDialog.componentInstance.title = message[0];
      confirmDialog.componentInstance.message1 = message[1];
      confirmDialog.componentInstance.message2 = message[2];
      confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
      confirmDialog.componentInstance.margin = false;
      confirmDialog.componentInstance.theme = "white";

      confirmDialog.componentInstance.options = [
        {
          text: "Sí",
          callback: () => {
            this.userService
              .changePassword(
                this.currentUser.Id,
                this.newPassword.p1.value
              )
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe(
                (result) => {
                  this.modificarDatos = null;
                },
                (error) => {
                  var message = [];
                  message[0] = "Error al cambiar contraseña";
                  let confirmDialog2 = this._matDialog.open(
                    FuseDialogContinueComponent,
                    {
                      disableClose: true,
                    }
                  );
                  confirmDialog2.componentInstance.title =
                    message[0];
                  confirmDialog2.componentInstance.textButton =
                    this._translateService.instant('ACCEPT');
                  confirmDialog2.componentInstance.margin =
                    false;

                  confirmDialog2
                    .afterClosed()
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result) => {
                      this.modificarDatos = null;
                      confirmDialog = null;
                    });
                }
              );
          },
        },
        {
          text: "No",
          callback: () => { },
        },
      ];
      confirmDialog.componentInstance.margin = false;
      confirmDialog
        .afterClosed()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          result();
          confirmDialog = null;
        });

      return;
    }
  }

  changeShow(val: boolean) {
    this.showHelp = val;
    if (!this.authService.currentUser.TutorialDone) {
      this.authService.currentUser.TutorialDone = true;
      this.userService
        .UpdateUserTutorial(this.authService.currentUser)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
          this.authService.currentUser.TutorialDone = true;
        });
    }
  }

  ProfeAdminCheck() {
    if (this.schoolAdminView) {
      // document.body.classList.add("admin_profile");
      document.getElementById("noMenuAdminProfile").classList.add("admin_profile");
      // document.body.classList.remove("student_profile");
      document.getElementById("noMenuAdminProfile").classList.remove("student_profile");
    } else {
      // document.body.classList.add("student_profile");
      document.getElementById("noMenuAdminProfile").classList.add("student_profile");
      // document.body.classList.remove("admin_profile");
      document.getElementById("noMenuAdminProfile").classList.remove("admin_profile");
    }
  }

  openMobMenuFunction() {
    var mobi = document.getElementById("openMobMenu");
    var mobi_close = document.getElementById("openMobMenuClose");
    var mobi_modal = document.getElementById("mobiMenuFlot");
    var mobi_modal_back = document.getElementById("backOverlayMenu");

    mobi.classList.toggle("active");
    mobi_close.classList.toggle("active");
    mobi_modal.classList.toggle("active");
    mobi_modal_back.classList.toggle("active");
    }

    exit() {
        this.navigationService.exit()
    }
    verFacturas(){
        this.editSchoolData = false;
        this.navigationService.go("school/admin/facturas")
    }
    checkActiveActivities(){
        let activeActivities = "";
        let school = this.schoolService.school;
        console.log(school);
        if(school.Dictation){
            activeActivities += " Dictados,"
        }
        if(school.ReadingText){
            activeActivities += " Comprensión lectora,"
        }
        if(school.DeductionText){
            activeActivities += " Deducción,"
        }
        if(school.HearingText){
            activeActivities += " Comprensión auditiva,"
        }
        return activeActivities.trim().replace(/,$/, ".");
    }
}
