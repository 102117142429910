<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="pasap1 spe_gap" (click)="setFocus(focusing ? false : true)" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" fxLayout="column" fxLayoutAlign="stretch">

    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>
    <div class="palabraContainer" [style]="color()">
        <span [ngClass]="{'pequeño': exercise.def.length > 100}">{{ exercise.def }}</span>
    </div>
    <div class="palabra">
        <div [ngClass]="{'bien': letra.answered == true,'mal': letra.answered == false}" class="letra" [attr.letra]="i" *ngFor="let letra of letras; let i = index">
            <span [hidden]="letra.answered != true">{{ letra.letra }}</span>
        </div>
    </div>

    <div class="wordsContainer">
        <div class="items_container" fxLayout="row nowrap" fxLayout.lt-md="column" fxLayoutAlign="center center" fxLayoutAlign.lt-md="start center" fxLayoutAlign.xs="start center" fxLayoutGap="1rem">
            <div fxFlex="20rem" fxFlex.lt-md="0 0 auto" class="contenedor_rosco">
                <img  class="rosco" src="https://walinwa.blob.core.windows.net/images/Exercises/Pasap/pasapalabra.gif?ngsw-bypass=true" />
                <div class="letra_actual"><span [style]="color()">{{ exercise.letra }}</span></div>
            </div>
            <div class="letters" [hidden]="isMobile">
                <ul class="list-inline">
                    <li [style]="color()" *ngFor="let letter of letters; let i = index" class="letterContainer">
                        <div (click)="add_letra(letter.name)" [attr.letra]="letter.name" class="letter-button letter">
                            {{ letter.name }}
                        </div>
                    </li>
                    <!-- <br /> -->
                    <li [style]="color()" *ngFor="let letter of accentLetters; let i = index" class="letterContainer">
                        <div (click)="add_letra(letter.name)" [attr.letra]="letter.name" class="letter-button letter">
                            {{ letter.name }}
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</div>
<form style="position: fixed; top: -99999em; left: -99999em;" autocomplete="off" *ngIf="this.isMobile">
    <input [(ngModel)]="input" autocorrect="off" autocapitalize="off" spellcheck="false" (ngModelChange)="change()"
        [ngModelOptions]="{standalone: true}" autocomplete="off" type="text" id="dictation-input" autofocus  />
</form>