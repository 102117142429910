<div *ngIf="dataLoaded" fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutAlign="center center" class="spe_gap header_top">
    <div class="" fxFlex="20%" fxFlex.xs="25%">
        <div class="imgGame" *ngIf="availableGames.length != 0" [style.background-image]="getGameImg()"></div>
    </div>
    <div class="" fxFlex="0 0 calc(50% - 2rem)" fxFlex.xs="0 0 calc(75% - 2rem)">
        <div class="gameButtonsDiv" [hidden]="(availableGames.length == 0) ? true : false">
            <div class="paginator">
                <div class="fa fa-chevron-left  paginationButton" [ngClass]="{'buttonDisabled': selectedGameIndex == 0}"
                    (click)="changeGame(-1)"></div>
                <div [style.background-image]="getCurrentGameTitle()" class="title"></div>
                <div class="fa fa-chevron-right  paginationButton" [ngClass]="{'buttonDisabled': deactivateNextGame()}"
                    (click)="changeGame(1)"></div>
            </div>
        </div>
    </div>
    <div class="" fxFlex="25%" fxFlex.xs="75%">
        <div *ngIf="availableGames[selectedGameIndex] && !loading && !(availableGames.length == 0 || disableGames || !canPlay)"
            class="gameStartDiv">
            <button type="button" (click)="startGame(selectedGameIndex)" class="spe_button_a"
                [ngClass]="{'buttonDisabled': currentUser.Coins < availableGames[selectedGameIndex].Walinwos || calling == true}">
                <div fxLayout="row" fxLayoutAlign="center center" class="spe_gap">
                    <div class="priceText">
                        Jugar&nbsp;por&nbsp;<br />{{
                        availableGames[selectedGameIndex].Walinwos}}&nbsp;walinwos
                    </div>
                    <img fxFlex="2.5rem" src="https://walinwa.blob.core.windows.net/images/walistar.png?ngsw-bypass=true">
                </div>
            </button>
        </div>
        <div class="gameStartDiv" *ngIf="!loading && disableGames">
            <span>{{'STUDENT.GAMES.GAME.GAMES-DISABLED-BY-TIME' | translate:{initTime: GamesConditions.GameIni.substr(0,5), endTime: GamesConditions.GameEnd.substr(0,5)} }}</span>
        </div>
        <div class="gameStartDiv" *ngIf="!loading" [hidden]="canPlay">
            <span>{{'STUDENT.GAMES.GAME.DAILY-LIMIT' | translate:{maxGames: cls?.MaxDailyGames} }}</span>
        </div>
    </div>
</div>


<div *ngIf="dataLoaded" class="walinwa-box-with-tabs">
    <div class="walinwa-box">
        <div class="walinwa-tabs walinwa-tabs-top" [hidden]="currentUser.IdSchool == null">
            <div [ngClass]="{'orange-selected': tab == 1}"  (click)="tab == 1 ? null: changeTab(1)" class="walinwa-tab-gray">{{'STUDENT.GAMES.GAME.RANKING-CLASSROOM' | translate }}
            </div>
            <div [ngClass]="{'orange-selected': tab == 2}" (click)="tab == 2 ? null : changeTab(2)" class="walinwa-tab-gray">{{'STUDENT.GAMES.GAME.RANKING-GENERAL' | translate }}
            </div>
        </div>

        <div class="walinwa-tabs walinwa-tabs-top" [hidden]="currentUser.IdSchool != null">
            <div class="walinwa-tab-gray orange-selected">{{'STUDENT.GAMES.GAME.RANKING' | translate }}</div>
        </div>

        <div class="walinwa-box-content">

            <div class="ranking" [ngClass]="{'hidden': availableGames.length == 0}">

                <div class="ww_table_rwd" [ngClass]="{'hidden': tab == 2}">

                    <table *ngIf="topScoresAula" class="walinwa-table">
                        <thead>
                            <tr>
                                <!-- <th style="width: 5%;"></th>
                                <th class="text-center" [ngClass]="{'hidden': currentUser.IdSchool == null}">#</th>
                                <th [ngClass]="{'hidden': currentUser.IdSchool == null}">Alias</th>
                                <th [ngClass]="{'hidden': currentUser.IdSchool != null}">#</th>
                                <th [ngClass]="{'hidden': currentUser.IdSchool != null}">Name</th> -->
                                <th colspan="3">
                                    <em style="font-weight: normal;">{{'STUDENT.GAMES.GAME.SCORE-UPDATE-ADVICE' | translate }}</em>
                                </th>
                                <th class="text-center">{{'STUDENT.GAMES.GAME.SCORE' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="" *ngFor="let studentScore of topScoresAula; let i = index"
                                [ngClass]="{'hidden': topUserScoreAula && topUserScoreAula.Position > 10 && i >= 10}">
                                <td style="width: 5%; border: none;">
                                    <div class="mini_avatar" fxFlex="3rem">
                                        <avatar-file *ngIf="studentScore.activeItems && studentScore.activeItems.length > 0" [activeItems]=studentScore.activeItems
                                            style="cursor: pointer;"></avatar-file>
                                        <img *ngIf="!studentScore.activeItems || studentScore.activeItems.length == 0"
                                            src="https://walinwa.blob.core.windows.net/images/user_ico-orange.svg?ngsw-bypass=true" class="default" />
                                    </div>
                                </td>
                                <td style="width: 2%;" class="text-center"
                                    [ngClass]="{'hidden': currentUser.IdSchool == null}">
                                    {{i+ 1}}
                                </td>
                                <td style="width: 70%;" [ngClass]="{'hidden': currentUser.IdSchool == null}">
                                    {{studentScore.Alias}}
                                </td>
                                <td style="width: 3%;" class="" [ngClass]="{'hidden': currentUser.IdSchool != null}">
                                    {{i+ 1}}
                                </td>
                                <td style="width: 20%;" [ngClass]="{'hidden': currentUser.IdSchool != null}">
                                    {{studentScore.UserName}}
                                </td>
                                <td style="" class="text-center">
                                    {{studentScore.Points}}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div *ngIf="topUserScoreAula" class="userRanking"
                        [ngClass]="{'hidden': topUserScoreAula == null || topUserScoreAula.Position <= 10}">
                        {{'STUDENT.GAMES.GAME.SCORE-TELL-TEXT' | translate:{maxScore: topUserScoreAula.Points, classroomRanking: topUserScoreAula.Position} }}
                    </div>

                </div>

                <div class="ww_table_rwd" [ngClass]="{'hidden': tab == 1}">

                    <table class="walinwa-table">
                        <thead>
                            <tr>
                                <!-- <th style="width: 5%;"></th>
                                <th class="text-center" [ngClass]="{'hidden': currentUser.IdSchool == null}">#</th>
                                <th [ngClass]="{'hidden': currentUser.IdSchool == null}">Alias</th>
                                <th [ngClass]="{'hidden': currentUser.IdSchool != null}">#</th>
                                <th [ngClass]="{'hidden': currentUser.IdSchool != null}">Name</th> -->
                                <th colspan="3">
                                    <em style="font-weight: normal;">{{'STUDENT.GAMES.GAME.SCORE-UPDATE-ADVICE' | translate }}</em>
                                </th>
                                <th class="text-center">{{'STUDENT.GAMES.GAME.SCORE' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="" *ngFor="let studentScore of topScoresGeneral; let i = index"
                                [ngClass]="{'hidden': topScoresGeneral.Position > 10 && i >= 10}">
                                <td style="width: 5%; border: none;">
                                    <div class="mini_avatar" fxFlex="3rem">
                                        <avatar-file *ngIf="studentScore.activeItems && studentScore.activeItems.length > 0" [activeItems]=studentScore.activeItems
                                            style="cursor: pointer;"></avatar-file>
                                        <img *ngIf="!studentScore.activeItems || studentScore.activeItems.length == 0"
                                        src="https://walinwa.blob.core.windows.net/images/user_ico-orange.svg?ngsw-bypass=true" class="default" />
                                    </div>
                                </td>
                                <td style="width: 2%;" class="text-center">
                                    {{i+ 1}}
                                </td>
                                <td style="width: 70%;">
                                    {{studentScore.Alias}}
                                </td>
                                <td style="" class="text-center">
                                    {{studentScore.Points}}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div *ngIf="topUserScoreGeneral" class="userRanking"
                        [ngClass]="{'hidden': topUserScoreGeneral == null || topUserScoreGeneral.Position <= 10}">
                        {{'STUDENT.GAMES.GAME.SCORE-TELL-TEXT' | translate:{maxScore: topUserScoreAula.Points, classroomRanking: topUserScoreAula.Position} }}
                    </div>

                </div>

            </div>

            <div class="noGameDiv" [hidden]="availableGames.length > 0">
                {{availableGames.length}}
                <div class="imgNoGame" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Themes/gameNotAvailable.png&quot;)'"></div>
                <span class="noGame">{{'STUDENT.GAMES.GAME.ALL-GAMES-LOCKED' | translate }}</span>
            </div>

        </div>
    </div>
</div>