<div class="guionp spe_gap" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" fxLayout="column" fxLayoutAlign="stretch">
    <!-- Footer Enunciado -->
    <div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
        <span [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
    </div>
    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}</div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div  id="space-for-text" [ngClass]="{'wordsContainer': !slide,'words-container-slide': slide}">

        <div id="TextContainer" class="items_container" [ngClass]="{'mini': palabras.length > 10}">
            <ng-container *ngIf="exercise.operador == 'nn'">
                <div *ngFor="let palabra of palabras; let i = index" id="wordContainer{{ i }}" [attr.idWord]="i" class="wordBox dropzone a" [ngClass]="{ wordBoxOk:  palabra.answered == true, wordBoxError:  palabra.answered == false, shake:  palabra.answered == false && !review, 'myCustomShake':  palabra.answered == false, 'mini': palabras.length > 10 }" [hidden]="exercise.operador != 'nn'">
                    <div *ngFor="let fragmento of palabra.palabraGuion" class="placeholderTextDiv placeholderTextDiv1">
                        {{fragmento == " " ? "&nbsp;" : fragmento}}
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!(exercise.operador != '1n' && exercise.operador != '11')">
                <div *ngFor="let palabra of palabras; let i = index" id="wordContainer{{ i }}" [attr.idWord]="i" class="wordBox dropzone b" [ngClass]="{ wordBoxOk:  palabra.answered == true, wordBoxError:  palabra.answered == false, shake:  palabra.answered == false && !review, 'myCustomShake':  palabra.answered == false, 'mini': palabras.length > 10 }" [hidden]="exercise.operador != '1n' && exercise.operador != '11' ">
                    <div *ngIf="isArray(palabra.palabraGuion)">
                        <div [hidden]="!isArray(palabra.palabraGuion)" *ngFor="let fragmento of palabra.palabraGuion" class="placeholderTextDiv placeholderTextDiv2">
                            {{fragmento == " " ? "&nbsp;" : fragmento}}
                        </div>
                    </div>
                    
                    <div [hidden]="isArray(palabra.palabraGuion)" class="placeholderTextDiv placeholderTextDiv2">
                        {{ palabra.palabraGuion }}
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!(exercise.operador != '2n' && exercise.operador != '22')">
                <div *ngFor="let palabra of palabras; let i = index" id="wordContainer{{ i }}" [attr.idWord]="i" class="wordBox c" [ngClass]="{ wordBoxOk:  palabra.answered == true, wordBoxError:  palabra.answered == false, shake:  palabra.answered == false && !review, 'myCustomShake':  palabra.answered == false, 'mini': palabras.length > 10 }" [hidden]="exercise.operador != '2n' && exercise.operador != '22' ">

                    <div *ngFor="let fragmento of palabra.palabraGuion; let j = index" class="d placeholderTextDiv placeholderTextDiv3">
    
                        <div [hidden]="fragmento != '_'" class="dropLetterArea e" [ngClass]="{'element-hidden': fragmento != '_'}">
                            {{fragmento}}
                            <div [attr.palabraGuionIndex]="j" [attr.idWord]="i" class="dropzone"></div>
                        </div>
    
                        <div class="f" [hidden]="fragmento === '_'" [ngClass]="{'element-hidden': fragmento == '_'}">
                            {{fragmento == " " ? "&nbsp;" : fragmento}}
                        </div>
    
                    </div>
    
                </div>
            </ng-container>
        </div>
    </div>

    <div class="optionsContainer" id="space-for-buttons" *ngIf="!finished" [ngClass]="{'optionsContainer-slide': slide, 'fixControlSessions': controlSession }">
        <div [ngClass]="{ bola:  bola(), 'element-collapse': finished }" *ngFor="let option of exercise.opciones; let i = index" class="exerciseLetterOptionContainer">
            <div id="option{{ i }}" class="exerciseLetterOption drag-element-source draggable" [attr.idOption]="i" dragChildImg="option{{ i }}">{{option}}</div>
        </div>
    </div>

</div>