import { SharedService } from 'app/core/shared/shared.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { CoinService } from 'app/services/coin.service';
import { DailyTextService } from 'app/services/daily-text.service';
import { ExerciseService } from 'app/services/exercise.service';
import { NavigationService } from 'app/services/navigation.service';
import { TestExerciseService } from 'app/services/test-exercise.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-ecoembes',
  templateUrl: './ecoembes.component.html',
  styleUrls: ['./ecoembes.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EcoembesComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  errors = 0
  timeout_secs = 60;
  timeout_secs_initial = 60;
  user_id
  daily_text
  palabras
  palabrasSolved
  come_from_dict
  daily_text_score
  calling
  finished
  show_time_out
  walinwos
  fin
  dragging_id_word
  errores = 0
  directory = "Ecoembes/"
  containers = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4, hiddenOnXs: true },
    { id: 5, hiddenOnXs: true },
    { id: 6, hiddenOnXs: true }
  ];
  constructor(
    private authService: AuthService,
    private navigationService: NavigationService,
    private dailyTextService: DailyTextService,
    private coinService: CoinService,
    private exerciseService: ExerciseService,
    public sharedService: SharedService
  ) { 
    this._unsubscribeAll = new Subject();
    }

    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
      }
  ngOnInit() {
    if (!this.authService.check()) return
    this.setTimes()
    this.user_id = this.authService.currentUser.Id;
    this.daily_text = this.navigationService.params.dailyText;
    this.come_from_dict = this.navigationService.params.dict;
    this.containers.forEach(container => {
        console.log(container)
    })
    if (this.daily_text == null || this.daily_text.Phrase == null) {
      this.exit();
      return;
    }
    this.palabras = JSON.parse(this.daily_text.Phrase);
    this.palabrasSolved = [];
    for (var i = 0; i < this.palabras.length; i++) {
      this.palabras[i].answered = null;
      for (var j = 0; j < this.palabras.length; j++) {
        if (this.palabras[j].cubo == i + 1)
          this.palabrasSolved.push(this.palabras[j]);

      }
    }
    this.palabras = this.shuffle(this.palabras);
    this.start()
  }

  cancel() {
    this.navigationService.go("/student/session")
  }

  setTimes() {
    switch (this.authService.currentUser.Level) {
      case 8:
        this.timeout_secs = 120;
        break;
      case 9:
        this.timeout_secs = 120;
        break;
      case 10:
        this.timeout_secs = 100;
        break;
      case 11:
        this.timeout_secs = 100;
        break;
      case 12:
        this.timeout_secs = 80;
        break;
      case 13:
        this.timeout_secs = 80;
        break;
      default:
        this.timeout_secs = 60;
        break;
    }
    this.timeout_secs_initial = this.timeout_secs;
  }

  // Temporal solo para testear
  // setTimes() {
  //   switch (this.authService.currentUser.Level) {
  //     case 8:
  //       this.timeout_secs = 120;
  //       break;
  //     case 9:
  //       this.timeout_secs = 120;
  //       break;
  //     case 10:
  //       this.timeout_secs = 100;
  //       break;
  //     case 11:
  //       this.timeout_secs = 100;
  //       break;
  //     case 12:
  //       this.timeout_secs = 80;
  //       break;
  //     case 13:
  //       this.timeout_secs = 80;
  //       break;
  //     default:
  //       this.timeout_secs = 60;
  //       break;
  //     }
  //     this.timeout_secs = 1000;
  //   this.timeout_secs_initial = this.timeout_secs;
  // }

  exit() {
    if (this.come_from_dict) {
      this.navigationService.go("student/dictation")
    } else {
      this.navigationService.go("student/session")
    }
  }

  shuffle(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  saveWalinwos(walis) {
    this.dailyTextService.addCurrentScore(this.authService.currentUser.Id, this.daily_text.IdDailyText, walis)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.successAddDailyTextScore2(result, walis)
      },
      error => {
        this.navigationService.goLogin()
      }
    )
  }

  successAddDailyTextScore2(result, walis = this.walinwos) {
    this.calling = false;
    if (result === null) {
      this.daily_text_score = null;
    }
    else {
      this.daily_text_score = result;
      if (this.daily_text_score.Score != 3) {
        if (walis > 0) this.coinService.showCoins(25, () => { this.sharedService.changeUserCoins(true) }, null, "coin-plank")
      }
    }
  }

  getTablonClass() {
    var classes = [];
    classes.push("finalizar")
    if (this.finished) {
      classes.push("abajo")
      classes.push('tablonNormal')
    }
    return classes.join(" ")
  }

  worldSize() {
    return this.timeout_secs * 95 / this.timeout_secs_initial;
  }

  timeOutViewSolution() {
    for (var i = 0; i < this.palabrasSolved.length; i++) {
      this.palabrasSolved[i].answered = true;
    }
    for (var i = 0; i < this.palabras.length; i++) {
      this.palabras[i].answered == true;
    }
    this.show_time_out = false;
  }

  start() {
    setTimeout(() => {
      this.exerciseService.initInteract({
        onmove: (event) => {
          this.dragging_id_word = event.target.getAttribute("idWord")
        },
        drop: (event) => {
          let id_box = event.target.getAttribute("idBox")
          for (let i = 0; i < this.palabras.length; i++) {
            if (this.palabras[i].answered == false) this.palabras[i].answered = null;
          }
          let idWord = this.dragging_id_word
          let idBox = id_box

          //search word
          if (this.palabras[idWord].cubo == idBox)
            this.palabras[idWord].answered = true;
          else {
            this.errores++;
            this.palabras[idWord].answered = false;
            setTimeout(() => {
              this.palabras[idWord].answered = null;
            }, 500)
          }
          for (let i = 0; i < this.palabrasSolved.length; i++) {
            if (this.palabrasSolved[i].cubo == idBox && this.palabras[idWord].answered)
              this.palabrasSolved[i].answered = true;
          }
          let finished = true;
          for (let i = 0; i < this.palabras.length; i++) {
            if (this.palabras[i].answered != true)
              finished = false;
          }
          if (finished) {
            this.walinwos = 100 - (20 * this.errores);
            if (this.walinwos < 20)
              this.walinwos = 20;
            this.saveWalinwos(this.walinwos);
          }
          this.finished = finished;
        },
      });
      let interval = setInterval(() => {
        if (this.finished) {
          clearInterval(interval)
          return;
        }
        this.timeout_secs--
        if (this.timeout_secs <= 0) {
          this.saveWalinwos(0)
          this.show_time_out = true;
          clearInterval(interval)
        }
      }, 1000)
    }, 1000)
  }
    getImgUrl(imageName: string, baseRoute = false) {
        if (baseRoute) {
            return "https://walinwa.blob.core.windows.net/images/" + imageName+ "?ngsw-bypass=true";
        }
        return "https://walinwa.blob.core.windows.net/images/" + this.directory + imageName+ "?ngsw-bypass=true";
    }

}
