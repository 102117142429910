<div class="walinwa-box">

    <form class="walinwa-box-content">

        <div class="">
            <span class="font_blue"><b>{{'SCHOOL.TEACHER.CONFIG.EMAILS.REPORTS' | translate}}</b></span>
        </div>
        <section fxLayout="row wrap" fxLayoutAlign="space-between center" fxNgClass.gt-sm="spe_gap">
            <div fxFlex="100%" fxFlex.gt-sm="" ngStyle.lt-md="margin-bottom:1rem;">
                <mat-checkbox style="font-weight: bold;" (change)="setSessionReport()"
                    [checked]="formData.SessionReports == 1" >
                    <span [innerHTML]="'SCHOOL.TEACHER.CONFIG.EMAILS.REPORTS-OPTION1' | translate"></span>
                </mat-checkbox>
            </div>
        </section>
    
        <section fxLayout="row wrap" fxLayoutAlign="space-between center" fxNgClass.gt-sm="spe_gap">
            <div fxFlex="100%" fxFlex.gt-sm="" ngStyle.lt-md="margin-bottom:1rem;">
                <mat-checkbox style="font-weight: bold;" (change)="setDailyReport()"
                    [checked]="formData.SessionReports == 2" >
                    <span [innerHTML]="'SCHOOL.TEACHER.CONFIG.EMAILS.REPORTS-OPTION2' | translate"></span>
                </mat-checkbox>
            </div>
        </section>
        <div class="">
            <span class="font_blue"><b>{{'SCHOOL.TEACHER.CONFIG.EMAILS.ALERTS' | translate}}</b></span>
        </div>

        <section fxLayout="row wrap" fxLayoutAlign="space-between center" fxNgClass.gt-sm="spe_gap">
            <div fxFlex="100%" fxFlex.gt-sm="" ngStyle.lt-md="margin-bottom:1rem;">
                <mat-checkbox style="font-weight: bold;" (change)="setNoSessionDaily()"
                    [checked]="formData.WarningNoSession == 1" >
                    <span [innerHTML]="'SCHOOL.TEACHER.CONFIG.EMAILS.ALERTS-OPTION1' | translate"></span>
                </mat-checkbox>
            </div>
        </section>

        <section fxLayout="row wrap" fxLayoutAlign="space-between center" fxNgClass.gt-sm="spe_gap">
            <div fxFlex="100%" fxFlex.gt-sm="" ngStyle.lt-md="margin-bottom:1rem;">
                <mat-checkbox style="font-weight: bold;" (change)="setNoSession5Days()"
                    [checked]="formData.WarningNoSession == 2" >
                    <span [innerHTML]="'SCHOOL.TEACHER.CONFIG.EMAILS.ALERTS-OPTION2' | translate"></span>
                </mat-checkbox>
            </div>
        </section>
        <div class="walinwa-row" fxLayoutAlign="end end" fxLayoutAlign.xs="center center">

            <button (click)="submitForm()" class="new_but azul">
                {{'SAVE' | translate}}
            </button>

        </div>
    </form>

</div>