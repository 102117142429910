<div class="walinwa-box">

    <div class="walinwa-box-content" id="editSessionContainer">
        <div>
            <div>{{'SCHOOL.TEACHER.ACTIVITIES.DEDUCTION.TITLE' | translate }}</div>
            <div class="custom-form-field" style="margin-top: 20px">
                <mat-form-field>
                    <label>{{'SCHOOL.TEACHER.ACTIVITIES.DEDUCTION.COURSE' | translate }}</label>
                    <mat-select [(value)]="selectedCourse" [(ngModel)]="selectedCourse"
                        (selectionChange)="getDeductionTexts()">
                        <mat-option class="scrollable" *ngFor="let course of courses | sortBy:'asc':'Level'"
                            [value]="course.Level">{{ course.Name }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <cal-deduction-text *ngIf="deductionTexts"
                    class="calendario dinamico walCalendar azul ww_customCalendar" [ngClass]="{'sixWeeks': six_weeks}"
                    [showAll]="true" [deductionTexts]="deductionTexts" [parent]="this" [fullDay]="false"
                    [currentUser]="student" [selectedDay]="selectedDay" [currentMonth]="currentMonth"
                    [licenseDate]="licenseDate">
                </cal-deduction-text>
            </div>
            <!-- <div class="custom-form-field">
                <label>{{'SCHOOL.COURSE' | translate}}</label>
                <select [(ngModel)]="selectedCourse" (change)="getDeductionTexts()">
                    <option *ngFor="let course of courses | sortBy:'asc':'Level'" [value]="course.Level">
                        {{ course.Name }}
                    </option>
                </select>
            </div>
            <div> {{selectedCourse}}</div> -->
        </div>

        <div class="walinwa-row spe_gap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center">

            <button [disabled]="!hasExercise || afterLicense" (click)="showExercise()" class="new_but azul">
                {{'SCHOOL.TEACHER.ACTIVITIES.DEDUCTION.SHOW-EXERCISE' | translate }}
            </button>

        </div>

    </div>

</div>
<router-outlet></router-outlet>