

<div *ngIf="!loading" class="main spe_gap" fxLayout="column" fxLayoutAlign="stretch space-between" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/' + (this.myZoneService.subDirectory + this.getImgName()) + '&quot;)'">
    <div class="" fxLayout="row" fxLayoutAlign="end end">
        <img class="pointer" fxFlex="3rem" src="https://walinwa.blob.core.windows.net/images/MiZona/plus.svg?ngsw-bypass=true" alt="Mis escenarios" routerLink="/student/scenary" />
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxLayoutAlign.xs="center center" class="spe_gap">
        <div class="item-button">
            <img *ngIf="canBuy" routerLink="/student/shop" class="boton-tienda" src="https://walinwa.blob.core.windows.net/images/MiZona/boton-tienda.svg?ngsw-bypass=true"
                alt="ir a la tienda" />
            <img *ngIf="!canBuy" (click)="showZoneDialog()" class="boton-tienda" src="https://walinwa.blob.core.windows.net/images/MiZona/boton-tienda-bloqueado.svg?ngsw-bypass=true"
                alt="tienda bloqueada" />    
        </div>
        <div class="item-button">
            <img  routerLink="/student/progress/report" class="boton-progresos" src="https://walinwa.blob.core.windows.net/images/MiZona/boton-progresos.svg?ngsw-bypass=true" 
            alt="ir a mi progreso" />
        </div>
    </div>
    <div fxFlex="" fxLayout="row wrap" fxLayoutAlign="space-between end" fxLayoutAlign.xs="center center" class="spe_gap">
        <div class="item-button">
            <img routerLink="/student/rewards" class="boton-premios" 
            src="https://walinwa.blob.core.windows.net/images/MiZona/boton-premios.svg?ngsw-bypass=true"  alt="ir a mis premios" />
        </div>
        <div class="item-button">
            <img *ngIf="canPlay" routerLink="/student/games" class="boton-juegos"
            src="https://walinwa.blob.core.windows.net/images/MiZona/boton-juegos.svg?ngsw-bypass=true" alt="ir a juegos" />
            <img (click)="showGameDialog()" *ngIf="!canPlay" class="boton-juegos"
            src="https://walinwa.blob.core.windows.net/images/MiZona/boton-juegos-bloqueado.svg?ngsw-bypass=true" alt="juegos bloqueados" />
        </div>
    </div>
    <div class="avatar-profile-header" *ngIf="avatarActive" fxHide.xs>
        <avatar-file [activeItems]="activeItems" (click)="openAvatar()"
            style="cursor: pointer"></avatar-file>
    </div>
</div>