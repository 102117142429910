import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { TranslateService } from '@ngx-translate/core';
import { LeeBooks } from 'app/core/shared/state/models/Wali-lee/lee-books.model';
import { LeeClass } from 'app/core/shared/state/models/Wali-lee/lee-class.model';
import { AuthService } from 'app/services/auth.service';
import { NavigationService } from 'app/services/navigation.service';
import { UserService } from 'app/services/user.service';
import { WaliLeeService } from 'app/services/wali-lee.service';
import { DxDataGridComponent } from 'devextreme-angular';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { catchError, finalize, take, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'walilee-list',
  templateUrl: './walilee-list.component.html',
  styleUrl: './walilee-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WalileeListComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<void> = new Subject();
  private _booksAsignedSubject = new Subject<LeeClass[]>();
  private _booksSubject = new BehaviorSubject<LeeBooks[]>([]);
  private _booksAsigned: LeeClass[] = [];
  private _books: LeeBooks[] = [];
  
  @ViewChild('dataGrid') dataGrid: DxDataGridComponent;
  @Input('repaint') set repaint(value: boolean) {
    if (value) setTimeout(() => this.dataGrid?.instance.repaint());
  }

  public title: any = (book) => `${book.Title} ${book.Author}`;
  public minDate: Date = new Date(new Date().setHours(0, 0, 0, 0));
  public maxDate: Date = new Date(new Date().setFullYear(this.minDate.getFullYear() + 1));
  public editorOptions: any = { min: this.minDate, max: this.maxDate, useMaskBehavior: true, onInitialized: (e) => {
    // Mantener la funcionalidad predeterminada de onValueChanged
    let originalOnValueChanged = e.component.option("onValueChanged");

    e.component.option("onValueChanged", function(args) {

      if (args.value) {

        const _date = moment(args.value); 

        const year = _date.year();
        const month = _date.month();
        const day = _date.date();
    
        const date = moment.utc([year, month, day]).startOf('day'); 
        args.value = date.toDate();
      }

      if (originalOnValueChanged) originalOnValueChanged.apply(this, arguments);
    });
  }};
  public book: LeeClass = null;
  public booksAsigned$ = this._booksAsignedSubject.asObservable();
  public books$ = this._booksSubject.asObservable();
  public classes: any = [];
    
  constructor (private _navigationService: NavigationService,
               private _walileeService: WaliLeeService,
               private _userService: UserService,
               private _authService: AuthService,
               private _translateService: TranslateService,
               private _matDialog: MatDialog) {
    this.checkMatchLevel = this.checkMatchLevel.bind(this);
    this.setCellValue = this.setCellValue.bind(this);
    this.checkIsBookSelectable = this.checkIsBookSelectable.bind(this);
    this.onRowRemoving = this.onRowRemoving.bind(this);
  }

  ngOnInit(): void {
    this._navigationService.enableExitButton();
    this._initSubscriptions();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private _initSubscriptions() {
    combineLatest([
      this._userService.getClassesByIdTutor(this._authService.currentUser.Id),
      this._walileeService.getBooks(),
      this._walileeService.getClassBooks(this._authService.currentUser.Id),
    ]).pipe(takeUntil(this._unsubscribeAll), take(1)).subscribe(data => {
      //this.classes = [{ IdClass: 0, Name: 'Todas mis aulas'}, ...((data[0] || []) as any[]).sort((a, b) => a.Level - b.Level)];
      this.classes = ((data[0] || []) as any[]).sort((a, b) => a.Level - b.Level);
      this._books = data[1];
      this._booksSubject.next(this._books);
      this._emitMappedBooks(data[2]);
      
      setTimeout(() => this.dataGrid.instance.repaint());
    });
  }

  private _emitMappedBooks(books: LeeClass[]) {
      books.map(book => {
        book.StartDate = moment.utc(book.StartDate).toDate();
        book.EndDate = moment.utc(book.EndDate).toDate();
        //book['_STARDATE'] = book.StartDate.toLocaleDateString("es-ES");
        //book['_ENDDATE'] = book.EndDate.toLocaleDateString("es-ES");
      });

      this._booksAsigned = books.sort((a, b) => b.StartDate.getTime() - a.StartDate.getTime());
      this._booksAsignedSubject.next(this._booksAsigned);
  }

  public getClassesBooks() {
    return this._walileeService.getClassBooks(this._authService.currentUser.Id).pipe(takeUntil(this._unsubscribeAll), tap(books => this._emitMappedBooks(books))).subscribe();
  }

  public setCellValue(newData, value, currentRowData) {
    newData.IdClass = value;

    const currentClassLevel = this.classes.find(c => c.IdClass === value)?.Level ?? 0;

    if (currentClassLevel) {
      const books = this._books.filter(book => !this._booksAsigned.find(asigned => asigned.IdLee === book.IdLee && asigned.IdClass === newData.IdClass)).filter(book => (currentClassLevel % 2 === 0 ? [currentClassLevel, currentClassLevel + 1] : [currentClassLevel, currentClassLevel - 1]).indexOf(book.ULevel) > -1);

      if (!this._isBookSelectable(currentClassLevel, currentRowData.IdLee) || !books.find(book => book.IdLee === currentRowData.IdLee)) newData.IdLee = null;

      this._booksSubject.next(books);

      return;
    }

    this._booksSubject.next(this._books);
  }

  public getBookTitle(idLee: number) {
    if (!idLee) return '(Todos)';
    
    const book = ((this._books || []).find(book => book.IdLee === idLee) || { Title: '', Author: '' });

    return book ? `<span class='book-title'>${book.Title}. </span><span>${book.Author}</span>` : '';
  }

  public checkIsBookSelectable(e: any) {
    const currentClassLevel = this.classes.find(c => c.IdClass === e.data.IdClass)?.Level ?? 0;

    if (!e.data.IdLee || !currentClassLevel || currentClassLevel === 0) return true;

    return this._isBookSelectable(currentClassLevel, e.data.IdLee);
  }

  private _isBookSelectable(currentClassLevel: number, idLee: number): boolean {
    const currentSelectedBookId = idLee ?? null;

    if (currentSelectedBookId) {
      const currentSelectedBookLevel = this._books.find(book => book.IdLee === currentSelectedBookId).ULevel;

      let isSelectable = false;

      if (currentClassLevel) {
        isSelectable = (currentSelectedBookLevel % 2 === 0 ? [currentSelectedBookLevel , currentSelectedBookLevel + 1] : [currentSelectedBookLevel, currentSelectedBookLevel - 1]).indexOf(currentClassLevel) > -1;
      } else {
        const allClassLevels = this.classes.map(_class => _class.Level);
        const bookLevels = currentSelectedBookLevel % 2 === 0 ? [currentSelectedBookLevel , currentSelectedBookLevel + 1] : [currentSelectedBookLevel, currentSelectedBookLevel - 1];

        isSelectable = allClassLevels.every(level => bookLevels.includes(level));
      }

      return isSelectable;
    }

    return false;
  }

  public checkMatchLevel(e: any) {
    const currentClassLevel = this.classes.find(c => c.IdClass === e.data.IdClass)?.Level ?? 0;

    if (!e.data.IdLee || !currentClassLevel || currentClassLevel === 0) return true;

    const isValid: boolean = this._isBookSelectable(currentClassLevel, e.data.IdLee);

    return isValid;
  }

  public checkIsLaterThan(e: any) {
    if (!e.data.StartDate || !e.data.EndDate) return true;

    const isValid: boolean = e.data.StartDate < (e.data.EndDate);
    
    return isValid;
  }

  public onInitNewRow(e: any) {
    e.data.IdTutor = this._authService.currentUser.Id;

    (e.component.option('columns') || []).forEach(c => e.component.columnOption(c.dataField, 'allowEditing', true));

    this._booksSubject.next(this._books);
  }

  public async onEditingStart(e: any) {
    if (e.data.IdTutor !== this._authService.currentUser.Id) {
      e.cancel = true;

      return;
    }

    e.component.columnOption('IdClass', 'allowEditing', false);
    e.component.columnOption('IdLee', 'allowEditing', false);
    //this.started = await this._walileeService.isClassBookStarted(e.data.IdLee, e.data.IdClass).pipe(takeUntil(this._unsubscribeAll)).toPromise();
    //e.component.columnOption('StartDate', 'allowEditing', true);
  }

  public onCellPrepared(e: any) {
    if(e.rowType === 'data' && e.column.command === 'edit') {  
      let $links = e.cellElement.querySelector('.dx-link');  

      if (!$links) return;

      if(e.row.data.IdTutor && e.row.data.IdTutor !== this._authService.currentUser.Id) $links.remove();

      if (e.row.isEditing) { 
        $links = e.cellElement.querySelector('.dx-link.dx-icon-trash');

        if (!$links) return;

        $links.remove();
      }
    }  
  }

  public onRowInserting(e: any) {
    e.cancel = this._walileeService.createClassBook(e.data).pipe(takeUntil(this._unsubscribeAll), catchError(_e => { setTimeout(() => e.component.cancelEditData()); throw _e; })).toPromise().then(classes => {

      if ((classes || []).length === 0) {
        this._navigationService.error([this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.ASSIGN-BOOK-TITLE'), this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.SUCCESS-ASSIGN-BOOK')], _ => {});
        return false;

      } else {
        this._navigationService.error([this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.ASSIGN-BOOK-TITLE'), this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.BOOK-ALREADY-ASSIGN')], _ => {});

        return true;
      }
    }, _ => {
      this._navigationService.error([this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.ASSIGN-BOOK-TITLE'), this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.ERROR-ASSIGN-BOOK')], _ => {});      
      
      return true;
    });
  }

  /*public async onRowUpdating(e: any) {
    if(this.started && e.oldData.StartDate < e.newData?.StartDate){
        this._navigationService.warn([this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.MODAL-EDIT-FROM-DATE-TITLE'), this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.MODAL-EDIT-FROM-DATE-MESSAGE')], async ok => {
            console.log("entro")
            await this.updateRow(e);
        }, ko => {
        })
        return;
    }    
  }*/

  public async onRowUpdating(e: any) {
    const oldData = { ...e.oldData };

    e.cancel = new Promise(async (resolve, reject) => {

      const started = await this._walileeService.isClassBookStarted(e.newData.IdLee || e.oldData.IdLee, e.newData.IdClass || e.oldData.IdClass).pipe(takeUntil(this._unsubscribeAll)).toPromise();

      if (started && oldData.StartDate && e.newData.StartDate && oldData.StartDate < e.newData.StartDate){
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
          disableClose: true
        });

        confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.MODAL-EDIT-FROM-DATE-TITLE');
        confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.MODAL-EDIT-FROM-DATE-MESSAGE')
        confirmDialog.componentInstance.theme = "white";
        confirmDialog.componentInstance.margin = false;
        confirmDialog.componentInstance.options = [
          {
            text: "Cancelar",
            callback: true
          },
          {
            text: this._translateService.instant('ACCEPT'),
            callback: false
          }
        ];
        confirmDialog.componentInstance.margin = false;

        const cancel = await confirmDialog.afterClosed().toPromise();

        if (cancel) {
          resolve(true);

          return;
        } 
      }    
      console.clear();
console.log({ ...oldData, ...e.newData });
      await this._walileeService.udpateClassBook({ ...oldData, ...e.newData }).pipe(takeUntil(this._unsubscribeAll), catchError(_e => { setTimeout(() => e.component.cancelEditData()); throw _e; })).toPromise().then(classes => {

        if ((classes || []).length === 0) {
            this._navigationService.error([this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.ASSIGN-BOOK-TITLE'), this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.SUCCESS-EDIT-DATE')], _ => { });

            resolve(false);

        } else {
            this._navigationService.error([this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.ASSIGN-BOOK-TITLE'), this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.BOOK-ALREADY-ASSIGN')], _ => { });

            resolve(true);
        }
      }, _ => {
          this._navigationService.error([this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.ASSIGN-BOOK-TITLE'), this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.ERROR-EDIT-BOOK-ASSIGNMENT')], _ => { });

          resolve(true);
      });
    });
  }

  public async onRowRemoving(e: any) {
      e.component.beginCustomLoading();

      this._walileeService.isClassBookStarted(e.row.data.IdLee, e.row.data.IdClass).pipe(takeUntil(this._unsubscribeAll), catchError(e => { e.component.endCustomLoading(); throw e; })).subscribe(isStarted => {
      this._navigationService.warn([this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.DELETE-BOOK'), isStarted ? this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.DELETE-BOOK-STARTED') : this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.DELETE-BOOK-CONFIRMATION')], accept => {

        this._walileeService.deleteClassBook(e.row.data.IdLee, e.row.data.IdClass, e.row.data.IdTutor).pipe(takeUntil(this._unsubscribeAll), catchError(e => { e.component.endCustomLoading(); throw e; }), finalize(() => e.component.endCustomLoading())).subscribe(ok => {

          if (ok) {
            this._navigationService.error([this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.DELETE-BOOK'), this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.SUCCESS-DELETE-BOOK')], _ => {});
            this.getClassesBooks();
          } else {
            this._navigationService.error([this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.DELETE-BOOK'), this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.ERROR-DELETE-BOOK')], _ => {});
          }
        });

      }, cancel => { e.component.endCustomLoading(); });
    }, _ => { 
      this._navigationService.error([this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.DELETE-BOOK'), this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.WALILEE.WALILEE-LIST.ERROR-DELETE-BOOK')], accept => {});
    });
  }

  public onAddRow(e: any) {
    if (!(this.dataGrid.instance as any).getController("editing").isEditing()) {
      this.dataGrid.instance.addRow();
    }
  }
}
