<div class="walinwa-header">
    <div class="container_ww" fxLayout="row" fxLayoutAlign="space-between center">
        <img src="https://walinwa.blob.core.windows.net/images/logo_walinwa_pantallas1.png?ngsw-bypass=true" class="" fxFlex="8rem" fxShow fxHide.lt-md>
        <img src="https://walinwa.blob.core.windows.net/images/walinwa-logo-mob.svg?ngsw-bypass=true" fxFlex="2rem" fxShow.lt-md fxHide />
        <div fxFlex.xs="0 0 calc(100% - 4rem)" class="walinwa-header-demand-buttons spe_gap" fxLayout="row wrap"
            fxLayoutAlign="end center">
            <button (click)="reset()" [hidden]="!showUpdateButton" [disabled]="loading" mat-raised-button color="accent"
                class="submit-button" fxFlex="80px" fxFlex.gt-xs="110px">
                {{'DEMAND.RESET-BUTTON' | translate}}
            </button>
            <button (click)="update()" [hidden]="!showUpdateButton || exerciseOk" [disabled]="loading" mat-raised-button
                color="accent" class="submit-button" fxFlex="80px" fxFlex.gt-xs="110px">
                {{'SAVE' | translate}}
            </button>
            <button (click)="next()" [hidden]="!exerciseOk" mat-raised-button color="accent" [disabled]="loading"
                class="submit-button" fxFlex="110px">
                {{'DEMAND.SUBMIT-BUTTON' | translate}}
            </button>
            <button (click)="logout()" mat-raised-button color="accent" [disabled]="loading" class="submit-button"
                fxFlex="80px" fxFlex.gt-xs="110px">
                {{'DEMAND.EXIT-BUTTON' | translate}}
            </button>
        </div>
    </div>
</div>
<div class="container_ww body">
    <div class="walinwa-box walinwa-box-green walinwa-100">
        <div class="walinwa-box-header">
            {{'DEMAND.DEMANDSURVEYHEADER' | translate}}
        </div>
        <div class="walinwa-box-content">
            <div class="" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="30px" fxLayout.xs="column"
                fxLayoutAlign.xs="stretch" fxLayoutGap.xs="1.25rem">
                <div fxFlex.gt-xs="">
                    <label class="font_blue">{{'DEMAND.EXERCISECOUNT-FIELD' | translate}}</label>
                    <div class="ww_field_spe">
                        <input inputmode="numeric" type="number" [ngModelOptions]="{standalone: true}" [(ngModel)]="numEjercicios" />
                    </div>
                </div>
                <div fxFlex.gt-xs="">
                    <label class="font_blue">{{'DEMAND.GRADE-FIELD' | translate}}</label>
                    <div class="ww_field_spe">
                        <select [ngModelOptions]="{standalone: true}" [(ngModel)]="Level">
                            <option [value]="course.Level" *ngFor="let course of courses">{{ course.Name }}</option>
                        </select>
                    </div>
                </div>
                <div fxFlex.gt-xs="">
                    <label class="font_blue">{{'DEMAND.EXERCISENUMBER-FIELD' | translate}}</label>
                    <div class="ww_field_spe">
                        <input inputmode="numeric" type="number" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="exercise.IdExerciseType" />
                    </div>
                </div>
            </div>
            <div class="" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="30px" fxLayout.xs="column"
                fxLayoutAlign.xs="stretch" fxLayoutGap.xs="1.25rem">
                <div fxFlex.gt-xs="">
                    <label class="font_blue">{{'DEMAND.CIVILIZATION-FIELD' | translate}}</label>
                    <div class="ww_field_spe">
                        <select [ngModelOptions]="{standalone: true}" [(ngModel)]="idCivilization">
                            <option [value]="civilization.IdCivilization" *ngFor="let civilization of civilizations">{{
                                civilization.IdCivilization }}
                            </option>
                        </select>
                    </div>
                </div>
                <div fxFlex.gt-xs="">
                    <label class="font_blue">{{'DEMAND.WORDCOUNT-FIELD' | translate}}</label>
                    <div class="ww_field_spe">
                        <input inputmode="numeric" type="number" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="exercise.MinNumWordOcurrences" />
                    </div>
                </div>
                <div fxFlex.gt-xs="">
                    <label class="font_blue">{{'DEMAND.DAYTIME-FIELD' | translate}}</label>
                    <div class="ww_field_spe">
                        <select [ngModelOptions]="{standalone: true}" [(ngModel)]="horario" placeholder="Horario">
                            <option *ngFor="let horario of horarios" [value]="horario.Name">{{ horario.Name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="30px" fxLayout.xs="column"
                fxLayoutAlign.xs="stretch" fxLayoutGap.xs="1.25rem">
                <div fxFlex.gt-xs="">
                    <label class="font_blue">{{'DEMAND.TOPICNUMBER-FIELD' | translate}}</label>
                    <div fxFlexxFill fxLayout="row" fxLayoutGap="30px">
                        <div class="ww_field_spe">
                            <input inputmode="numeric" type="number" [ngModelOptions]="{standalone: true}" [(ngModel)]="exercise.IdThema" />
                        </div>
                        <div class="ww_field_spe">
                            <input inputmode="numeric" type="number" [ngModelOptions]="{standalone: true}"
                                [(ngModel)]="exercise.IdSubthema" />
                        </div>
                    </div>
                </div>
                <div fxFlex.gt-xs="">
                    <mat-checkbox [(ngModel)]="userAdmin" [ngModelOptions]="{standalone: true}">
                        {{'DEMAND.ADMIN-CHECKBOX' | translate}}
                    </mat-checkbox>
                </div>
                <div fxFlex.gt-xs="" fxHide.xs></div>
            </div>
            <div class="" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="30px" fxLayout.xs="column"
                fxLayoutAlign.xs="stretch" fxLayoutGap.xs="1.25rem">
                <div fxFlex.gt-xs="">
                    <label class="font_blue">{{'DEMAND.LEVEL-FIELD' | translate}}</label>
                    <div fxFlexxFill fxLayout="row" fxLayoutGap="30px">
                        <div class="ww_field_spe">
                            <input inputmode="numeric" type="number" [ngModelOptions]="{standalone: true}" [(ngModel)]="exercise.MinLevel"
                                placeholder="Min" />
                        </div>
                        <div class="ww_field_spe">
                            <input inputmode="numeric" type="number" [ngModelOptions]="{standalone: true}" [(ngModel)]="exercise.MaxLevel"
                                placeholder="Max" />
                        </div>
                    </div>
                </div>
                <div fxFlex.gt-xs="">
                    <label class="font_blue">{{'DEMAND.BOOKLEVEL-FIELD' | translate}}</label>
                    <div fxFlexxFill fxLayout="row" fxLayoutGap="30px">
                        <div class="ww_field_spe">
                            <input inputmode="numeric" type="number" [ngModelOptions]="{standalone: true}" [(ngModel)]="exercise.MinBookLevel" />
                        </div>
                        <div class="ww_field_spe">
                            <input inputmode="numeric" type="number" [ngModelOptions]="{standalone: true}" [(ngModel)]="exercise.MaxBookLevel" />
                        </div>
                    </div>
                </div>
                <div fxFlex.gt-xs="">
                    <label class="font_blue">{{'DEMAND.CLONESESSION-FIELD' | translate}}</label>
                    <div class="ww_field_spe">
                        <input inputmode="numeric" type="number" [ngModelOptions]="{standalone: true}" [(ngModel)]="IdSessionToClone" />
                    </div>
                </div>
            </div>
            <div class="json-area">
                <div>model = {{exercise.ExerciseModel}}</div>
                <div *ngIf="exercise.ExerciseJson">json = {{exercise.ExerciseJson.ExerciseDefinition}}</div>
                <div>error = {{error}}</div>
            </div>
        </div>
    </div>
</div>