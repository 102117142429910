import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivityType } from 'app/core/shared/enums/activity-tipe.enum';
import { RewardResult } from 'app/core/shared/state/models/reward-result.model';
import { TopUserScores } from 'app/core/shared/state/models/top-user-scores.model';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GameService {

    baseUrl = environment.baseApi + '/api/Game1';

    constructor(private http: HttpClient) { }

    getTopScoresGameAula(idClass: number, numScores: number, idGame: number) {
        return this.http.get<TopUserScores[]>(this.baseUrl + '/getTopScoresAula/' + idClass + '/' + numScores + '/' + idGame);
    }

    getTopScoresGameGeneral(idSchool: number, numScores: number, idGame: number) {
        return this.http.get<TopUserScores[]>(this.baseUrl + '/getTopScoresGeneral/' + idSchool + '/' + numScores + '/' + idGame);
    }

    getTopScoreGameAula(idClass: number, idUser : number, idGame : number) {
        return this.http.get(this.baseUrl + '/getTopScoreAula/' + idClass + '/' + idUser + '/' + idGame);
    }

    getTopScoreGameGeneral(idSchool: number, idUser: number, idGame: number) {
        return this.http.get(this.baseUrl + '/getTopScoreGeneral/' + idSchool + '/' + idUser + '/' + idGame);
    }

    canPlay(IdUser: number, IdGame: number) {
        return this.http.get(this.baseUrl + '/canPlay/' + IdUser + '/' + IdGame);
    }
    SaveGameResult(IdGame: number, IdUser: number, Seconds: number, Fails: number, Clues: number, BackgroundImage: string, IdActivity = {}, ActivityType: ActivityType){
        var params = {
            IdGame,
            IdUser,
            Seconds,
            Fails,
            Clues,
            BackgroundImage,
            IdActivity,
            ActivityType,
        }
        return this.http.post<RewardResult>(this.baseUrl + "/SaveGameResult/", params)
    }
    ping() {
        return this.http.get(this.baseUrl + '/pingAuth');
    }

}
