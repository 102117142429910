import { AppService } from '../../../../../app.service';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'app/services/auth.service';
import { NavigationService } from 'app/services/navigation.service';
import { SchoolService } from 'app/services/school.service';
import { UserService } from 'app/services/user.service';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

declare var $;

@Component({
    selector: "app-games",
    templateUrl: "./challenge.component.html",
    styleUrls: ["./challenge.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ChallengeConfigComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    classes;
    selectedClass = null;
    form;
    isIpad = false;

    constructor(
        private schoolService: SchoolService,
        private userService: UserService,
        private navigationService: NavigationService,
        private authService: AuthService,
        private formBuilder: UntypedFormBuilder,
        private matDialog: MatDialog,
        private appService: AppService,
        private _translateService: TranslateService
    ) {
        this._unsubscribeAll = new Subject();
        this.selectedClass = 0;
        this.form = this.formBuilder.group({
            RetoIni: [null],
            RetoEnd: [null],
        });
    }

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit(): void {
        if (!this.authService.check()) return;
        this.getClasses();
        if (this.appService.mobileDetect().IsIPad) {
            this.isIpad = true;
        }
    }

    getClasses() {
        this.userService.getClassesByIdTutor(this.authService.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
            result => {
                this.classes = result;
                this.classes.sort(function (a, b) {
                    return a.Level - b.Level;
                });
                if (!this.selectedClass) this.changeClass();
                setTimeout(() => {
                }, 0);
            },
            error => {
                this.navigationService.exit();
            });
    }

    hasValue(time) {
        return time != null && time != "";
    }

    save() {
        if (this.isInvalid()){
            let confirmDialog = this.matDialog.open(FuseDialogContinueComponent,{
                disableClose: true,
            });
            confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.CONFIG.MODAL-BAD-TIME-RANGE-TITLE');
            confirmDialog.componentInstance.message1 =
                this._translateService.instant('SCHOOL.TEACHER.CONFIG.MODAL-BAD-TIME-RANGE-MESSAGE1');
            confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
            confirmDialog.componentInstance.margin = false;
            confirmDialog.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                confirmDialog = null;
            });
            return;
        }
        if (this.isInvalid2()) {
			let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
				disableClose: true
			});
			confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.CONFIG.MODAL-BAD-TIME-RANGE-TITLE');
			confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.CONFIG.MODAL-BAD-TIME-RANGE-MESSAGE2');
			confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
			confirmDialog.componentInstance.margin = false;
			confirmDialog.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
				confirmDialog = null;
			});
			return;
        }
    
        this.schoolService.saveChallengeConfig(this.authService.currentUser.Id, this.selectedClass, this.form.value.RetoIni, this.form.value.RetoEnd)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
            result => {
                let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
                    disableClose: true
                });
                confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.CONFIG.SUCCESS-SAVE-CONFIG-TITLE');
                confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.CONFIG.SUCCESS-SAVE-CONFIG-MESSAGE');
                confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
                confirmDialog.componentInstance.margin = false;
                confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
                    confirmDialog = null;
                    this.getClasses();
                });
            },
            error => {
                let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
                    disableClose: true
                });
                confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.CONFIG.ERROR-SAVE-CONFIG-TITLE');
                confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.CONFIG.ERROR-SAVE-CONFIG-MESSAGE');
                confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
                confirmDialog.componentInstance.margin = false;
                confirmDialog.afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(result => {
                    confirmDialog = null;
                });
            }
        )
    }

    isInvalid(): boolean {
        if (
            (this.hasValue(this.form.value.RetoIni) &&
                !this.hasValue(this.form.value.RetoEnd)) ||
            (!this.hasValue(this.form.value.RetoIni) &&
                this.hasValue(this.form.value.RetoEnd))
        ) {
            return true;
        }
        return false
    }

    isInvalid2(): boolean {
        if(
            this.hasValue(this.form.value.RetoIni) &&
            this.hasValue(this.form.value.RetoEnd) &&
            this.form.value.RetoIni > this.form.value.RetoEnd
        )
        {
            return true
        }
        return false;
    }

    sameValues() {
        if (!this.classes) return;
        if (this.classes.length == 0) return;
        let values1 = {
            RetoIni: this.classes[0].RetoIni,
            RetoEnd: this.classes[0].RetoEnd,
        };
        for (let cls of this.classes) {
            let values2 = {
                RetoIni: cls.RetoIni,
                RetoEnd: cls.RetoEnd,
            };
            if (JSON.stringify(values1) != JSON.stringify(values2))
                return false;
        }
        return true;
    }

    changeClass() {
        setTimeout(() => {
            this.selectedClass = parseFloat(this.selectedClass.toString());
        }, 100);
        let cls;
        if (this.selectedClass == 0) {
            if (this.sameValues()) {
                cls = this.classes[0];
            } else {
                this.form.patchValue({
                    RetoIni: null,
                    RetoEnd: null,
                });
                return;
            }
        } else {
            cls = this.classes.find((c) => c.IdClass == this.selectedClass);
        }
        this.form.patchValue({
            RetoIni: cls.RetoIni,
            RetoEnd: cls.RetoEnd,
        });
    }
}
