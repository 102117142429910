import { AppService } from './../../../../../app.service';
import { PersonalizedTestAnswer } from 'app/core/shared/state/models/personalized-test/personalized-test-answer.model';
import { PersonalizedTestQuestion } from 'app/core/shared/state/models/personalized-test/personalized-test-question.model';
import { PersonalizedTestService } from 'app/services/personalized-test.service';
import { PersonalizedTest } from 'app/core/shared/state/models/personalized-test/personalized-test.model';
import { CustomSession } from 'app/core/shared/state';
import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectorRef, HostListener, Inject, AfterViewChecked } from '@angular/core';
import { CustomizedSessionService } from 'app/services/customized-session.service';
import * as moment from 'moment';
import { AuthService } from 'app/services/auth.service';
import { NavigationService } from 'app/services/navigation.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { TestExerciseService } from 'app/services/test-exercise.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Subject, of } from 'rxjs'
import { catchError, shareReplay, takeUntil } from 'rxjs/operators';
import { ChatGPTRequestModel } from 'app/core/shared/state/models/chatGPTRequestModel';
import { SpinnerService } from '@fuse/components/spinner/spinner.service';
import { PersonalizedTestDTO } from 'app/core/shared/state/models/personalized-test/PersonalizedTestDTO.model';
import { ClassService } from 'app/services/class.service';
import { CourseService } from 'app/services/course.service';
import { UiService } from '@fuse/components/spinner/ui.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { environment } from 'environments/environment';
import { prototype } from 'events';
import { ComplementsComponent } from '../complements.component';
import { SchoolService } from 'app/services/school.service';
import { TranslateService } from '@ngx-translate/core';

enum Operation {
    None = 0,
    Create = 1,
    Edit = 2,
    View = 3,
    Clone = 4,
    Join = 5
}

@Component({
    selector: "app-personalized-test",
    templateUrl: "./personalized-test.component.html",
    styleUrls: ["./personalized-test.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class PersonalizedTestComponent implements OnInit, OnDestroy {
    confirmDialog: MatDialogRef<FuseDialogContinueComponent>;


    @HostListener('window:resize', ['$event']) 
    onResize(e: Event) {
        if (this.contextMenuVisible) this.hideContextMenu(this.contextMenuVisible);
    }
    
    private _unsubscribeAll: Subject<any>;
    private _testQuestions: string = null;
    questionsForm : UntypedFormGroup;
    classes : any;
    courses: any;
    personalizedTests: PersonalizedTest[] = [];
    personalizedCommunityTests: PersonalizedTest[] = [];
    personalizedTest: PersonalizedTest;
    classes_personalizedTests = [];
    loading;
    state;
    calendar;
    form: UntypedFormGroup;
    submitted;
    clasesSeleccionadas = [];
    messages = [];
    isRequired = false;
    selectedDay;
    customizedSessions: CustomSession[] = [];
    showMessage = false;
    blockAssignation = false;
    nameExists = false;
    editingPersonalizedTestName = false;
    editing = false;
    selectedHour = null;
    showTestList = false;
    cancelTest = false;
    date;
    addedNewQuestion = false;
    isProd = environment.production;
    ShareTestOpt = [
        'No compartir',
        'Compartido con los compañeros de mi centro',
        'Compartido con profesores de la comunidad Walinwa'
    ]
    time = `${new Date().getHours()}:${
        (new Date().getMinutes() < 10 ? "0" : "") + new Date().getMinutes()
    }`;

    public readonly OPERATION = Operation;
    public chatGPTTestPopupVisible = false;
    public canCreateTestWithChatGPT$ = this.personalizedTestService.canCreateTestWithChatGPT().pipe(shareReplay(1));
    public testJoinEnabled: boolean = false;
    public currentTest: any = null;
    public currentOperation: Operation = Operation.None;
    public contextMenuVisible: number = null;
    public contextMenuX: number = null;
    public contextMenuY: number = null;
    public testsTab: number = 1;

    public advancedOptionsVisible: boolean = false;

    constructor(
        public authService: AuthService,
        public personalizedTestService: PersonalizedTestService,
        private customizedSessionService: CustomizedSessionService,
        public navigationService: NavigationService,
        private formBuilder: UntypedFormBuilder,
        private courseService: CourseService,
        private _spinnerService: SpinnerService,
        private _detector: ChangeDetectorRef,
        private classService: ClassService,
        private _matDialog: MatDialog,
        private _ui: UiService,
        public schoolService: SchoolService,
        private _translateService: TranslateService,
        @Inject(ComplementsComponent) private _tabContainer: ComplementsComponent
    ) {
        this._unsubscribeAll = new Subject();
        
        this.state = "classes-personalizedTests"
        this.navigationService.setStateTest('classes-personalizedTests');
        this.loading = true
        this.showMessage = false;
        this.selectedDay = moment().hour(0).minute(0).second(0).millisecond(0);
        this._ui.spin$.next(true);

    }

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    Questions(): UntypedFormArray {
        return this.questionsForm.get("Questions") as UntypedFormArray;
    }

    ngOnInit() {
        if (!this.authService.check()) return;
        
        this.courseService.Courses.pipe(takeUntil(this._unsubscribeAll)).subscribe(courses => {
            this.courses = courses;
        })
        this.questionsForm = this.formBuilder.group({
            Questions: this.formBuilder.array([]),
        });
        this.getClasses();
        this.getPersonalizedTests()
    }

    newQuestion(): UntypedFormGroup {
        const idQuestion = this._getNextQuestionId();

        return this.formBuilder.group({
            //IdQuestion: 0,
            IdQuestion: idQuestion,
            Question: ["", Validators.required],
            ChatGPT: [null],
            TestAnswer: this.formBuilder.array([this.newAnswer(idQuestion)]),
        });
    }

    newEmptyQuestion(question: PersonalizedTestQuestion = null): UntypedFormGroup {        
        const questionId = question ? question.IdQuestion : this._getNextQuestionId();

        return this.formBuilder.group({
            //IdQuestion: question ? question.IdQuestion : 0,
            IdQuestion: questionId,
            Question: question ? [question.Question, Validators.required] : ["", Validators.required],
            ChatGPT: [question.ChatGPT || null],
            TestAnswer: this.formBuilder.array([]),
        });
    }

    addQuestion(question: PersonalizedTestQuestion = null, autofocus: boolean = false) {
        if (question) {
            this.Questions().push(this.newEmptyQuestion(question));
        } else {
            this.addedNewQuestion = true;
            this.Questions().push(this.newQuestion());
        }

        if (autofocus) {
            setTimeout(() => this._tabContainer.scrollableElement.nativeElement.scrollTop = this._tabContainer.scrollableElement.nativeElement.scrollHeight);
            /*setTimeout(() => {
                //this.setFocus(this.questionsForm.get("Questions").value.length);
                const lastQuestion = this.Questions().at(this.questionsForm.get("Questions").value.length - 1);
                this.setFocus(lastQuestion.get('IdQuestion').value);
            }, 100);*/
        }
    }

    /*setFocus(index) {
        document.getElementById("focused" + index).focus();
    }*/

    removeQuestion(idQuestion: number) {           
        const index = this._findQuestionIndexById(idQuestion);
      
        if (index !== -1) {
            this.Questions().removeAt(index);
        }
    }

    private _findQuestionIndexById(idQuestion: number) {           
        return this.Questions().controls.findIndex(control => {
            return control.get('IdQuestion').value === idQuestion;
        });
    }

    private _getNextQuestionId(): number {
        let idQuestion = 0;

        this.Questions().controls.forEach(control => {
            const currentId = control.get('IdQuestion').value;

            if (currentId > idQuestion)
                idQuestion = currentId;
        });

        return ++idQuestion;
    }

    private _getNextQuestionAnswerId(idQuestion: number): number {
        let idAnswer = 0;

        const answers = this.QuestionAnswers(idQuestion);

        if ((answers || []).length > 0) {
            answers.controls.forEach(control => {
                const currentId = control.get('IdAnswer').value;

                if (currentId > idAnswer)
                    idAnswer = currentId;
            });
        }

        return ++idAnswer;
    }

    /*QuestionAnswers(questionIndex): FormArray {
        return this.Questions()
            .at(questionIndex)
            .get("TestAnswer") as FormArray;
    }*/
    QuestionAnswers(idQuestion: number): UntypedFormArray {
        const index = this._findQuestionIndexById(idQuestion);

        if (index !== -1) {
            return this.Questions().at(index).get("TestAnswer") as UntypedFormArray;
        }

        return null;
    }


    newAnswer(idQ: number, answer: PersonalizedTestAnswer = null): UntypedFormGroup {
        const idQuestion = answer ? answer.IdQuestion : idQ ? idQ : this._getNextQuestionId();
        const idAnswer = answer ? answer.IdAnswer : this._getNextQuestionAnswerId(idQuestion);

        return this.formBuilder.group({
            //IdQuestion: answer ? answer.IdQuestion : 0,
            IdQuestion: idQuestion,
            //IdAnswer: answer ? answer.IdAnswer : 0,
            IdAnswer: idAnswer,
            Answer: answer ? [answer.Answer, Validators.required] : ["", Validators.required],
            IsCorrect: answer ? answer.IsCorrect : false,
        });
    }

    /*addQuestionAnswer(questionIndex: number, answer: PersonalizedTestAnswer = null) {
        this.QuestionAnswers(questionIndex).push(this.newAnswer(answer));
    }*/

    addQuestionAnswer(idQuestion: number, answer: PersonalizedTestAnswer = null) {
        let answers = this.QuestionAnswers(idQuestion);

        if (answers) {
            answers.push(this.newAnswer(idQuestion, answer));
        }

        //this.QuestionAnswers(idQuestion).push(this.newAnswer(answer));
    }

    /*removeQuestionAnswer(questionIndex: number, answerIndex: number) {
        this.QuestionAnswers(questionIndex).removeAt(answerIndex);
    }*/

    removeQuestionAnswer(idQuestion:number, idAnswer: number) {
        const indexQ = this._findQuestionIndexById(idQuestion);
      
        if (indexQ !== -1) {
            let answers = this.Questions().at(indexQ).get("TestAnswer") as UntypedFormArray;

            const indexA = answers.controls.findIndex(control => {
                return control.get('IdAnswer').value === idAnswer;
            });

            if (indexA !== -1) {
                answers.removeAt(indexA);
            }
        }
    }

    changeCorrectAnswer(idQuestion: number, idAnswer: number): void {

        if (this.form && this.form.value.MultipleCorrectAnswers || this.personalizedTest && this.personalizedTest.MultipleCorrectAnswers) {
            let respuesta = this.questionsForm.value.Questions.find(q => q.IdQuestion === idQuestion).TestAnswer.find(r => r.IdAnswer === idAnswer);
            respuesta.IsCorrect = !respuesta.IsCorrect;
            return;
        }
        this.questionsForm.value.Questions.find(q => q.IdQuestion === idQuestion).TestAnswer.forEach(respuesta => {
            if (idAnswer == respuesta.IdAnswer) {
                respuesta.IsCorrect = !respuesta.IsCorrect;
            } else {
                respuesta.IsCorrect = false;
            }
        }
        );
    }

    public drop(event: CdkDragDrop<string[]>): void {
        const questions = this.Questions();
        const control = questions.controls[event.previousIndex];

        let originalQuestions = JSON.parse(this._testQuestions) as PersonalizedTestQuestion[];

        questions.removeAt(event.previousIndex);
        questions.insert(event.currentIndex, control);

        const formQuestions = this.questionsForm.value.Questions as PersonalizedTestQuestion[];
    
        formQuestions.forEach((currentQuestion, i) => {
            const newIdQuestion = ++i;

            if (currentQuestion.IdQuestion !== newIdQuestion) {
                let idQuestionFrom = currentQuestion.IdQuestion;
                let idQuestionTo = newIdQuestion;
                let originalQuestionFrom = originalQuestions.find(question => question.IdQuestion === idQuestionFrom);
                let originalQuestionTo = originalQuestions.find(question => question.IdQuestion === idQuestionTo);
   
                if (originalQuestionFrom) {
                    originalQuestionFrom.IdQuestion = idQuestionTo;
                    originalQuestionTo.TestAnswer.forEach(a => a.IdQuestion = idQuestionTo);
                }

                if (originalQuestionTo) {
                    originalQuestionTo.IdQuestion = idQuestionFrom;
                    originalQuestionTo.TestAnswer.forEach(a => a.IdQuestion = idQuestionFrom);
                }

                let nextQuestionIndex = this.questionsForm.value.Questions.findIndex(q => q.IdQuestion === idQuestionTo);

                if (nextQuestionIndex >= 0) {
                    
                    let nextQuestion = this.questionsForm.value.Questions[nextQuestionIndex];

                    nextQuestion.IdQuestion = idQuestionFrom;
                    nextQuestion.TestAnswer.forEach(a => a.IdQuestion = idQuestionFrom);
                }

                currentQuestion.IdQuestion = idQuestionTo;
                currentQuestion.TestAnswer = currentQuestion.TestAnswer.map((a, j)=> {
                    a.IdQuestion = idQuestionTo;

                    return a;
                });
            }

            return currentQuestion;
        });

        this.resetForm();

        formQuestions.forEach(question => {
            this.addQuestion(question);

            question.TestAnswer.forEach(answer => {
                this.addQuestionAnswer(question.IdQuestion, answer);
            });
        });

        this._detector.detectChanges();
        this._testQuestions = JSON.stringify(originalQuestions);
    }
    objtostr(obj) {
        return JSON.stringify(obj);
    }
    getClasses() {
        this.classes = this.classService.GetClasses();
    }
    get sortData() {
        return this.classes_personalizedTests.sort((a, b) => {
            return <any>new Date(b.Date) - <any>new Date(a.Date);
        });
    }
    getPersonalizedTests() {
        this.personalizedTestService.getByUser(this.authService.currentUser.Id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result: PersonalizedTestDTO[]) => {
                    this.classes_personalizedTests = result.filter(x => (x.IdClass ?? 0) !== 0);
                    const mappedTest = this.personalizedTestService.MapFromDTOToTest(result);
                    this.personalizedTests = mappedTest.filter(t => t.ShareTest !== 2);
                    this.personalizedCommunityTests = mappedTest.filter(t => t.ShareTest === 2);
                    this.customizedSessions = []
                    this.classes_personalizedTests.forEach(AssignedTest => {
                        AssignedTest.TestState = ""
                        AssignedTest.formatted_date = new Date(AssignedTest.Date).toLocaleDateString("es-ES")
                        AssignedTest.editingTestDate = false
                        AssignedTest.TestState = (AssignedTest.Finished || 0) === 0 ? 
                        this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.NOT-DONE') : 
                        (AssignedTest.Total || 0) === (AssignedTest.Finished || 0) ? 
                        this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.FINISHED', {}) : 
                            this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.FINISHED-AMOUNT', {finished: AssignedTest.Finished || 0, total: AssignedTest.Total || 0})

                        AssignedTest.editingTestDate = (AssignedTest.Finished || 0) !== 0;
                        this.customizedSessions.push(AssignedTest)
                        /*let tests = this.personalizedTests.find(e => e.IdTest == AssignedTest.IdTest)
                        if (tests){
                            if(!tests.count_assigned){
                                tests.count_assigned = 0
                            }
                            tests.count_assigned++
                        }
                        else {
                            this.personalizedTests.push(AssignedTest)
                        }
                        */
                    });
                    this.loading = false
                },() => {
                    this.loading = false;
                });
    }

    /*get sortData(){
        return this.classes_personalizedTests.sort((a, b) => {
            return <any>new Date(b.Date) - <any>new Date(a.Date);
        });
    }*/

    public showErrorMessage(title: string, message: string): void {
        this.navigationService.error([title, message],(_) => {});
    }

    private _checkIfShowCannotDeleteTest(personalizedTest: PersonalizedTest): boolean {
        if ((personalizedTest.Finished ?? 0) > 0) {
            this.navigationService.error(
                [
                    this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.DELETE-TEST-TITLE'),
                    this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.DELETE-TEST-MESSAGE1'),
                ],
                (ko) => {}
            );
            return false;
        } else if ((personalizedTest.CustomSessions || []).length > 0) {
            this.navigationService.error(
                [
                    this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.DELETE-TEST-TITLE'),
                    this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.DELETE-TEST-MESSAGE2'),
                ],
                (ko) => {}
            );
            return false;
        }

        return true;
    }

    async deletePersonalizedTest(personalizedTest: PersonalizedTest) {
        const result = await this.canEditOrChangeName(personalizedTest, this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.DELETE-TEST-TITLE'))
        if (!result) {
            return;
        }

        this.navigationService.warn([this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.DELETE-TEST-TITLE'), this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.DELETE-TEST-CONFIRMATION')], ok => {
            this.personalizedTestService.remove(personalizedTest)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
                this.classes_personalizedTests = []
                this.getPersonalizedTests()
            })
        }, ko => {

        })
    }

    deleteClassPersonalizedTest(class_personalizedTest) {
        if (!this._checkIfShowCannotDeleteTest(class_personalizedTest)) {
            return;
        }

        this.navigationService.warn([this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.DELETE-CLASS-TEST-TITLE'), this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.DELETE-CLASS-TEST-CONFIRMATION')], ok => {
            this.loading = true
            let list = [{
                IdClass: class_personalizedTest.IdClass,
                Date: class_personalizedTest.Date,
                IdTest: -1,
            }];
            this.customizedSessionService.add(list)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
                result => {
                    this.state = "classes-personalizedTests"
                    this.navigationService.setStateTest('classes-personalizedTests');
                    this.classes_personalizedTests = []
                    this.getPersonalizedTests()
                })
        }, ko => {

        })
    }

    back() {
        this.state = "classes-personalizedTests";
    }

    addClassPersonalizedTest() {
        this.showTestList = false;
        this.state = "add-class-personalizedTest";
        this.navigationService.setStateTest("add-class-personalizedTest");
        this.navigationService.enableBackButtonTest(
            true,
            "add-class-personalizedTest"
        );
        this.navigationService.add_class_step = 1;
        this.calendar = {
            current_user: this.authService.currentUser,
            selected_day: moment(),
            current_month: moment().month(),
        };
    }

    editPersonalizedTests() {
        this.clearTestsSelection();
        this.showTestList = true;
        this.state = "add-class-personalizedTest";
        this.navigationService.setStateTest("add-class-personalizedTest");
        this.navigationService.enableBackButtonTest(
            true,
            "add-class-personalizedTest"
        );
        this.navigationService.add_class_step = -1;
    }

    addPersonalizedTest() {
        this.clearTestsSelection();
        this.currentTest = null;
        this.currentOperation = Operation.Create;
        this.editing = false;
        this.resetForm();
        this.submitted = false;
        let Level = null;
        if (this.classes.some((x) => x.selected)) {
            Level = this.classes
                .sort((x) => x.Level - x.Level)
                .find((y) => y.selected).Level;
        }
        this.form = this.formBuilder.group({
            Name: ["Prueba", [Validators.required, Validators.maxLength(40)]],
            TLevel: [
                Level ? Level : this.courses[0].Level,
                [Validators.required],
            ],
            ShareTest: 0,
            MessQuestions: null,
            TestFormat: null,
            ProportionalScore: null,
            MultipleCorrectAnswers: null,
            AutoOpenTest: null,
        });
        this.state = "add-personalizedTest";
        this.navigationService.setStateTest("add-personalizedTest");
    }
    getCourse(personalizedTest){
        if(personalizedTest.TLevel == null){
            return this.courses[0].Name
        }
        return this.courses.find(x => x.Level == personalizedTest.TLevel).Name
    }
    formatHour(time) {
        let hours = time.split(":")[0];
        let minutes = time.split(":")[1];
        return hours + ":" + minutes;
    }
    async startChangingPersonalizedTestName(personalizedTest: PersonalizedTest, communityTest = false) {
        const result = await this.canEditOrChangeName(personalizedTest)
        if(!result){
            return;
        }
        personalizedTest.editingPersonalizedTestName = true;
        setTimeout(() => {
            if(communityTest){
                document.getElementById("change-name-community").focus();
            }
            else {
                document.getElementById("change-name").focus();
            }
            
        }, 100);
    }
    changePersonalizedTestName(personalizedTest: PersonalizedTest, communityTest = false) {
        this.nameExists = false;
        this.isRequired = false;
        var el = document.getElementById(
            communityTest ? "change-name-community": "change-name"
        ) as HTMLInputElement | null;
        el.value = el.value.trim();
        if (el.value == personalizedTest.Name) {
            personalizedTest.editingPersonalizedTestName = false;
        }
        if (el.value == "") {
            this.isRequired = true;
            return;
        }
        this.AssertNameExists(personalizedTest.IdTest, el.value);
        if (this.nameExists) {
            return;
        }
        this.personalizedTestService.updateTestName(personalizedTest.IdTest, el.value)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
            result => {
                this.classes_personalizedTests = []
                this.getPersonalizedTests();
            }, error => {
            }
        )
    }

    AssertNameExists(IdTest: number, name: string){
        this.nameExists = false;
        return;
        this.personalizedTests.forEach((test) => {
            if (test.IdTest != IdTest && test.Name.toUpperCase() == name.toUpperCase() && test.IdTutor != this.authService.currentUser.Id) {
                this.nameExists = true;
            }
        });
        this.personalizedCommunityTests.forEach((test) => {
            if (test.IdTest != IdTest && test.Name.toUpperCase() == name.toUpperCase() && test.IdTutor != this.authService.currentUser.Id) {
                this.nameExists = true;
            }
        });
    }
    editPersonalizedTestQuestions(personalizedTest: PersonalizedTest = null, editingName = false) {
        this._tabContainer.scrollableElement.nativeElement.scrollTop = 0;
        this.nameExists = false;
        this.personalizedTest = null;
        this.submitted = true;
        this.form.markAllAsTouched();

        this.AssertNameExists(personalizedTest ? personalizedTest.IdTest : 0, this.form.value.Name)
        if (this.nameExists) {
            this.form.get('Name').setErrors({ nameExists: true })
            return;
        }
        this.currentTest = null;

        if (personalizedTest) {
            this.resetForm();
            this._ui.showSpinner()
            this.personalizedTestService.getTestQuestions(personalizedTest.IdTest)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(result => {
                    personalizedTest.TestQuestion = result;
                    this.resetForm();

                    this._testQuestions = JSON.stringify(personalizedTest.TestQuestion || []);
                    personalizedTest.TestQuestion.forEach((pregunta/*, preguntaIndex*/) => {
                        this.addQuestion(pregunta);
                        pregunta.TestAnswer.forEach((respuesta/*, respuestaIndex*/) => {
                            //this.addQuestionAnswer(preguntaIndex, respuesta);
                            this.addQuestionAnswer(pregunta.IdQuestion, respuesta);
                        });
                    });

                    if (this.currentOperation !== Operation.View) {
                        personalizedTest.TestQuestion.forEach((pregunta/*, preguntaIndex*/) => {
                            //this.addQuestionAnswer(preguntaIndex);
                            this.addQuestionAnswer(pregunta.IdQuestion);
                        });
                    }

                    this._detector.detectChanges();
                    this.personalizedTest = personalizedTest;
                    this.state = "add-personalizedTestQuestions";
                    this.navigationService.setStateTest(
                        "add-personalizedTestQuestions"
                    );
                    this.editing = true;
                    this._ui.stopSpinner()
                    return;
                });

            return;

        } else {
            this._testQuestions = JSON.stringify([]);
        }
        if (this.form && this.form.invalid) return;
        if (!this.editing) {
            this.addQuestion();
        }
        this.state = 'add-personalizedTestQuestions'
        this.navigationService.setStateTest('add-personalizedTestQuestions')
        // this.personalizedTestService.insert(this.authService.currentUser.Id, this.form.value.Name)
        //.pipe(takeUntil(this._unsubscribeAll))
        //.subscribe(
        //     (personalizedTest: PersonalizedTest) => {
        //         this.personalizedTest = personalizedTest
        //         this.addQuestion()
        //         this.state = 'add-personalizedTestQuestions'
        //         this.navigationService.setStateTest('add-personalizedTestQuestions')
        //         // se crea el test personalizado y se envia al usuario a crear las preguntas para el mismo
        //     }
        // )
        return;
    }
    saveQuestion() {
        this.loading = true;
        this.questionsForm.value.Questions.forEach((pregunta) => {
            if (
                pregunta.TestAnswer[pregunta.TestAnswer.length - 1].Answer == ""
            ) {
                pregunta.TestAnswer.splice(pregunta.TestAnswer.length - 1, 1);
            }
        });
        // if(this.questionsForm && this.questionsForm.invalid){
        //     this.loading = false;
        //     return;
        // }
        let count = 0;
        this.questionsForm.value.Questions.forEach((pregunta) => {
            pregunta.TestAnswer.forEach((respuesta) => {
                if (respuesta.IsCorrect) {
                    count++;
                }
            });
        });
        if (count < this.questionsForm.value.Questions.length) {
            this.showMessage = true;
            this.loading = false;
            return;
        }
        this.showMessage = false;

        const questions = this._updateQuestionsOrigin((this.questionsForm.value.Questions || []) as PersonalizedTestQuestion[]);
        if(this.form && this.personalizedTest){
            if(!this.form.value.MultipleCorrectAnswers && this.personalizedTest.MultipleCorrectAnswers && count > this.questionsForm.value.Questions.length){
                this.showMultipleAnswersDialog()
                this.loading = false;
                return;
            }
        }
        if (!this.editing) {
            if (this.form?.value.TestFormat == true) {
                this.form.value.TestFormat = 1;
            } else if (this.form?.value.TestFormat == false) {
                this.form.value.TestFormat = 0;
            }

            let test = {
                IdTutor: this.authService.currentUser.Id,
                Name: this.form ? this.form.value.Name : this.personalizedTest?.Name,
                IdTestTheme: this.personalizedTest?.IdTestTheme ?? null,
                TLevel: this.form ? this.form.value.TLevel : this.personalizedTest?.TLevel,
                ShareTest: this.form ? this.form.value.ShareTest : this.personalizedTest?.ShareTest,
                MessQuestions: this.form ? this.form.value.MessQuestions : this.personalizedTest?.MessQuestions,
                TestFormat: this.form ? this.form.value.TestFormat : this.personalizedTest?.TestFormat,
                MultipleCorrectAnswers: this.form ? this.form.value.MultipleCorrectAnswers : this.personalizedTest?.MultipleCorrectAnswers,
                ProportionalScore: this.form ? this.form.value.ProportionalScore : this.personalizedTest?.ProportionalScore,
                AutoOpenTest: this.form ? this.form.value.AutoOpenTest : this.personalizedTest?.AutoOpenTest,
            }

            this.personalizedTestService.addTest(test)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(
                    (personalizedTest: PersonalizedTest) => {
                        this.personalizedTest = personalizedTest
                        this.personalizedTestService.addQuestions(this.personalizedTest.IdTest, questions)
                            .pipe(takeUntil(this._unsubscribeAll))
                            .subscribe((result) => {
                                this.save(this.personalizedTest.IdTest);
                                this.resetForm()
                            })
                        // se crea el test personalizado y se envia al usuario a crear las preguntas para el mismo
                    }
                )
        } else {
            if (this.form?.value.TestFormat == true) {
                this.form.value.TestFormat = 1;
            } else if (this.form?.value.TestFormat == false) {
                this.form.value.TestFormat = 0;
            }

            let test = {
                IdTest: this.personalizedTest.IdTest,
                IdTutor: this.authService.currentUser.Id,
                Name: this.form ? this.form.value.Name : this.personalizedTest?.Name,
                TLevel: this.form ? this.form.value.TLevel : this.personalizedTest?.TLevel,
                ShareTest: this.form ? this.form.value.ShareTest : this.personalizedTest?.ShareTest,
                MessQuestions: this.form ? this.form.value.MessQuestions : this.personalizedTest?.MessQuestions,
                TestFormat: this.form ? this.form.value.TestFormat : this.personalizedTest?.TestFormat,
                MultipleCorrectAnswers: this.form ? this.form.value.MultipleCorrectAnswers : this.personalizedTest?.MultipleCorrectAnswers,
                ProportionalScore: this.form ? this.form.value.ProportionalScore : this.personalizedTest?.ProportionalScore,
                AutoOpenTest: this.form ? this.form.value.AutoOpenTest : this.personalizedTest?.AutoOpenTest,
            }
            this.personalizedTestService.updateTest(test)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((personalizedTest: PersonalizedTest) => {
                    this.personalizedTest = personalizedTest
                    this.personalizedTestService.addQuestions(this.personalizedTest.IdTest, questions)
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe((result) => {
                                this.save(this.personalizedTest.IdTest);
                                this.resetForm()
                        })
                    });
        }
    }


    saveTest() {
        this.loading = true;
        this.nameExists = false;
        this.submitted = false;
        if (this.form?.value.TestFormat == true) {
            this.form.value.TestFormat = 1;
        } else if (this.form?.value.TestFormat == false) {
            this.form.value.TestFormat = 0;
        }

        let test = {
            IdTest: this.currentTest.IdTest,
            IdTutor: this.authService.currentUser.Id,
            Name: this.form ? this.form.value.Name : this.currentTest?.Name,
            TLevel: this.form ? this.form.value.TLevel : this.currentTest?.TLevel,
            ShareTest: this.form ? this.form.value.ShareTest : this.currentTest?.ShareTest,
            MessQuestions: this.form ? this.form.value.MessQuestions : this.currentTest?.MessQuestions,
            TestFormat: this.form ? this.form.value.TestFormat : this.currentTest?.TestFormat,
            MultipleCorrectAnswers: this.form ? this.form.value.MultipleCorrectAnswers : this.currentTest?.MultipleCorrectAnswers,
            ProportionalScore: this.form ? this.form.value.ProportionalScore : this.currentTest?.ProportionalScore,
            AutoOpenTest: this.form ? this.form.value.AutoOpenTest : this.personalizedTest?.AutoOpenTest,
        }
        if(this.form && this.currentTest){
            if(!this.form.value.MultipleCorrectAnswers && this.currentTest.MultipleCorrectAnswers){
                this.showMultipleAnswersDialog()
                this.loading = false;
                return;
            }
        }
        if(this.currentTest){
            this.AssertNameExists(this.currentTest.IdTest, this.form.value.Name)
        }
        else if(this.form){
            this.AssertNameExists(0, this.form.value.Name)
        }
        if(this.nameExists){
            this.form.get('Name').setErrors({nameExists: true})
            this.loading = false;
            this.submitted = true;
            return;
        }
        this.submitted = true;
        this.personalizedTestService.updateTest(test).pipe(takeUntil(this._unsubscribeAll)).subscribe((personalizedTest: PersonalizedTest) => {
            this.personalizedTest = personalizedTest
            this.save(this.personalizedTest.IdTest);
            this.resetForm();
        });        
    }

    selectedDayChanged(date) {
        this.calendar.selected_day = date;
        this.selectedDay = moment(date)
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0);
        this.calendar.current_month = this.calendar.selected_day.month();
        if (this.calendar.current_month != this.selectedDay.month() + 1) {
        }
    }

    continue() {
        if (this.navigationService.add_class_step < 3) {
            this.navigationService.add_class_step++;
            if (this.navigationService.add_class_step == 2) {
                this.showMessage = false;
                this.blockAssignation = false;
                this.classes_personalizedTests?.forEach((clase) => {
                    clase &&
                    moment(clase?.Date).format("DD/MM/YYYY").toString() ==
                        moment(this.calendar.selected_day._d)
                            .format("DD/MM/YYYY")
                            .toString()
                        ? this.clasesSeleccionadas.push(clase)
                        : (this.showMessage = false);
                });
                this.clasesSeleccionadas = this.clasesSeleccionadas.filter(
                    (item, index) => {
                        return this.clasesSeleccionadas.indexOf(item) === index;
                    }
                );
            }
        } else {
            this.save();
        }
    }
    isPast(date) {
        return moment(date).isBefore(
            moment().hour(0).minute(0).second(0).millisecond(0)
        );
    }
    save(Idtest = null) {
        this.loading = true;
        let test = this.personalizedTests.find((e) => e.selected)
        if(test == null){
            test = this.personalizedCommunityTests.find((e) => e.selected)
        }
        let list = [];
        for (let i = 0; i < this.classes.length; i++) {
            if (this.classes[i].selected) {
                list.push({
                    IdClass: this.classes[i].IdClass,
                    Date: this.calendar
                        ? this.calendar.selected_day
                        : this.personalizedTest.CustomSessions[0].Date,
                    IdTest: Idtest
                        ? Idtest
                        : this.testsTab === 2 ? this.personalizedCommunityTests.find((e) => e.selected).IdTest : this.personalizedTests.find((e) => e.selected).IdTest,
                    TestTime: this.selectedHour ? this.selectedHour : null,
                    TestOpen: test?.AutoOpenTest,
                });
            }
        }
        for (let i = 0; i < this.classes.length; i++) this.classes[i].selected = false;
        for (let i = 0; i < this.personalizedTests.length; i++) this.personalizedTests[i].selected = false;
        for (let i = 0; i < this.personalizedCommunityTests.length; i++) this.personalizedCommunityTests[i].selected = false;
        this.customizedSessionService.add(list)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
            result => {
                this.editing = false;
                this.state = "classes-personalizedTests"
                this.navigationService.setStateTest("classes-personalizedTests");
                this.navigationService.enableExitButton(true)
                this.classes_personalizedTests = []
                this.getPersonalizedTests()
            })
    }

    selectClass(cls) {
        if (!cls.selected) cls.selected = false;
        cls.selected = !cls.selected;
        this.checkPersonalizedTest();
    }

    checkPersonalizedTest() {
        this.messages = [];
        this.showMessage = false;
        this.blockAssignation = false;
        this.classes.forEach((cls) => {
            if (
                cls.selected &&
                this.clasesSeleccionadas.some((x) => x.IdClass == cls.IdClass)
            ) {
                let clase = this.clasesSeleccionadas.find((x) => x.IdClass == cls.IdClass)
                if (clase && (clase.Finished ?? 0) == 0) {
                    var message = this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.ALREADY-ASSIGNED-TEST-MESSAGE', {className: cls.Name})
                }
                else if(clase){
                    this.blockAssignation = true;
                    var message = this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.ALREADY-STARTED-TEST-MESSAGE', {className: clase.Name})
                }
                this.messages.push(message);
                this.showMessage = true;
            }
        });
        console.log(this.messages);
    }

    disableWizard() {
        if (this.navigationService.add_class_step == 1) {
            return (
                !this.calendar.selected_day ||
                moment(this.calendar.selected_day).isBefore(
                    moment().hour(0).minute(0).second(0).millisecond(0)
                )
            );
        } else if (this.navigationService.add_class_step == 2) {
            return this.classes.filter((c) => c.selected).length == 0;
        } else if (this.navigationService.add_class_step == 3) {
            if (this.testsTab === 2) 
                return this.personalizedCommunityTests.filter((e) => e.selected).length == 0;
            else
                return this.personalizedTests.filter((e) => e.selected).length == 0;
        }
    }

    public clearTestsSelection(): void {
        this.personalizedTests.forEach(t => t.selected = false);
        this.personalizedCommunityTests.forEach(t => t.selected = false);
    }

    selectPersonalizedTest(personalizedTest: PersonalizedTest) {
        for (let i = 0; i < this.personalizedTests.length; i++)
            this.personalizedTests[i].selected = false;
        personalizedTest.selected = true;
    }

    selectPersonalizedCommunityTest(personalizedTest: PersonalizedTest) {
        for (let i = 0; i < this.personalizedCommunityTests.length; i++)
            this.personalizedCommunityTests[i].selected = false;
        personalizedTest.selected = true;
    }

    resetForm() {
        for (let i = this.Questions().length - 1; i >= 0; i--) {        
            this.Questions().removeAt(i);
        }
        
        /*this.questionsForm.value.Questions.forEach(() => {
            this.removeQuestion(0);
        });*/
        this.questionsForm.updateValueAndValidity();
    }

    startChangingTestDate(class_test) {
        class_test.editingTestDate = true;
        this.date = new UntypedFormControl(moment(class_test.Date));
        setTimeout(() => {
            document.getElementById("change-date").focus();
        }, 100);
    }
    closeEditingDate() {
        if (
            this.classes_personalizedTests.find(
                (x) => x.editingTestDate == true
            )
        ) {
            this.classes_personalizedTests.forEach((class_personalizedTest) => {
                class_personalizedTest.editingTestDate = false;
                this.nameExists = false;
            });
        }
    }

    changeTestDate(class_test) {
        this.nameExists = false;
        var list = [];
        list.push({
            IdClass: class_test.IdClass,
            Date: class_test.Date,
            IdTest: class_test.IdTest,
            TestTime: class_test.TestTime,
            TestOpen: class_test.TestOpen,
        })
        this.customizedSessionService.add(list)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
            result => {
              class_test.editingTestDate = false;
              this.state = "classes-personalizedTests"
              this.navigationService.enableExitButton();
              this.navigationService.setStateTest('classes-personalizedTests');
            }
        )
    }

    /*detectNewAnswer(indexQ, indexA) {
        if (
            this.questionsForm.get("Questions").value[indexQ]?.TestAnswer
                ?.length == indexA
        ) {
            this.addQuestionAnswer(indexQ);
        }
    }*/

    detectNewAnswer(idQuestion: number, indexA) {
        const index = this._findQuestionIndexById(idQuestion);

        if (index !== -1) {
            if (this.questionsForm.get("Questions").value[this._findQuestionIndexById(idQuestion)]?.TestAnswer?.length == indexA) {
                this.addQuestionAnswer(idQuestion);
            }
        }
    }

    /*isLatest(indexQ, indexA) {
        return (
            this.questionsForm.get("Questions").value[indexQ]?.TestAnswer
                ?.length == indexA
        );
    }*/

    isLatest(idQuestion: number, indexA): boolean {
        const index = this._findQuestionIndexById(idQuestion);

        if (index !== -1) {
            return this.questionsForm.get("Questions").value[this._findQuestionIndexById(idQuestion)]?.TestAnswer?.length == indexA;
        }

        return false;
    }
    isLastQuestion(idQuestion): boolean{
        return idQuestion + 1 == this.questionsForm.get("Questions").value.length
    }
    /*getCurrentState(customSession: CustomSession) {
        if (customSession.Test.TestResult.length == 0) {
            return (customSession.TestState = "");
        }
        let doneTest = customSession.Test.TestResult.filter((x) =>
            moment(x.StartDate)
                .hour(0)
                .minute(0)
                .second(0)
                .millisecond(0)
                .isSame(moment(customSession.Date))
        );
        if (doneTest.length > 0) {
            if (
                doneTest.every((x) => x.FinishDate != null) &&
                doneTest.length == this.classes.find((x) => x.IdClass == customSession.IdClass).StudentCount
            ) {
                return (customSession.TestState = "Finalizado");
            } else if (
                doneTest.filter((x) => x.FinishDate == null).length <
                this.classes.find((x) => x.IdClass == customSession.IdClass).StudentCount
            ) {
                return (customSession.TestState =
                    "Finalizados " +
                    doneTest.filter((x) => x.FinishDate != null).length +
                    "/" + this.classes.find((x) => x.IdClass == customSession.IdClass).StudentCount);
            }
        } else {
            return (customSession.TestState = "");
        }
        return (customSession.TestState = "");
    }*/

    public showChatGPTTestPopup(): void {
        this.submitted = true;
        this.form.markAllAsTouched();
        this.nameExists = false;
        this.AssertNameExists(0, this.form.value.Name)

        if (this.nameExists) {
            this.form.get('Name').setErrors({nameExists: true})
            return;
        }          

        if (this.form && this.form.invalid) return;

        this.personalizedTest = null;
        this.chatGPTTestPopupVisible = true;
    }

    public async getChatGPTTest(testConfig: ChatGPTRequestModel): Promise<void> {        
        this._spinnerService.show();

        let personalizedTest: PersonalizedTest = {
            editingPersonalizedTestName: false,
            Finished: 0,
            IdTest: null,
            IdTutor: this.authService.currentUser.Id,
            IdTestTheme: testConfig.idTestTheme,
            Name: this.form.value.Name,
            CreationDate: new Date(),
            TLevel: this.form.value.TLevel,
            ShareTest: this.form.value.ShareTest,
            MessQuestions: this.form.value.MessQuestions,
            TestFormat: this.form.value.TestFormat,
            ProportionalScore: this.form.value.ProportionalScore,
            MultipleCorrectAnswers: this.form.value.MultipleCorrectAnswers,
            AutoOpenTest: this.form.value.AutoOpenTest,
        };

        const chatGPTQuestions = await this.personalizedTestService.getChatGPTTest(testConfig).pipe(catchError(error => { this._spinnerService.hide(); console.log(error); return of(null); })).toPromise();

        this.chatGPTTestPopupVisible = false;

        if (chatGPTQuestions && (chatGPTQuestions.Questions || []).length > 0) {
            alert(`{"messages": [{"role": "user", "content": "${chatGPTQuestions.Prompt}"}, {"role": "assistant", "content": "${chatGPTQuestions.Response}"}]}`);

            personalizedTest.TestQuestion = chatGPTQuestions.Questions.map((q, i) => {
                q.IdQuestion = ++i;
                q.TestAnswer = q.TestAnswer.map((a, j)=> {
                    a.IdAnswer = ++j; 

                    return a;
                });

                return q;
            })

            this._testQuestions = JSON.stringify(personalizedTest.TestQuestion || []);
            this.resetForm();

            personalizedTest.TestQuestion.forEach((pregunta/*, preguntaIndex*/) => {
                this.addQuestion(pregunta);
                pregunta.TestAnswer.forEach((respuesta/*, respuestaIndex*/) => {
                    //this.addQuestionAnswer(preguntaIndex, respuesta);
                    this.addQuestionAnswer(pregunta.IdQuestion, respuesta);
                });
            });

            personalizedTest.TestQuestion.forEach((pregunta/*, preguntaIndex*/) => {
                this.addQuestionAnswer(pregunta.IdQuestion);
            });

            this.personalizedTest = personalizedTest;
            this.state = "add-personalizedTestQuestions";
            this.navigationService.setStateTest("add-personalizedTestQuestions");
            this._spinnerService.hide();

            return;
        } else {
            alert("chatGPT no ha respondido correctamente. Revisa la consola de salida para ver su respuesta.")

            return;
        }
    }    

    private _updateQuestionsOrigin(questions: PersonalizedTestQuestion[]): PersonalizedTestQuestion[] {
        const originalQuestions = JSON.parse(this._testQuestions) as PersonalizedTestQuestion[];

        originalQuestions.filter(question => question.ChatGPT === 1).forEach(originalQuestion => {
            let currentQuestion = questions.find(question => question.IdQuestion === originalQuestion.IdQuestion);

            if (currentQuestion) {
                if (currentQuestion.Question !== originalQuestion.Question && originalQuestion.ChatGPT === 1)
                    currentQuestion.ChatGPT = 2;
                else {
                    (originalQuestion.TestAnswer || []).forEach(originalAnswer => {
                        let currentAnswer = (currentQuestion.TestAnswer || []).find(answer => answer.IdAnswer === originalAnswer.IdAnswer);

                        if (currentAnswer && originalQuestion.ChatGPT === 1 && (currentAnswer.Answer !== originalAnswer.Answer || currentAnswer.IsCorrect !== originalAnswer.IsCorrect))
                            currentQuestion.ChatGPT = 2;
                    });
                }
            }
        });

        return questions;
    }

    public checkJoinTestButtonActivation(e: any): void {
        this.testJoinEnabled = (this.personalizedTests || []).filter(t => t.selected).length > 1;
    }

    public joinTests(): void {
        this.nameExists = false;
        this.personalizedTest = null;
        this.submitted = true;
        this.AssertNameExists(0, this.form.value.Name)
        if (this.nameExists) {
            this.form.get('Name').setErrors({nameExists: true})
            return;
        }        
        
        if (this.form && this.form.invalid) return;

        this._spinnerService.show();

        /*let personalizedTest: PersonalizedTest = {
            editingPersonalizedTestName: false,
            Finished: 0,
            IdTest: null,
            IdTutor: this.authService.currentUser.Id,
            IdTestTheme: null,
            Name: this.form.value.Name,
            CreationDate: new Date()
        };*/

        //const joinedTestQuestions = [].concat(...(this.currentTest || []).filter(t => t.selected).map(t => t.TestQuestion));               

        this.currentTest.TestQuestion = (this.currentTest.TestQuestion || []).map((q, i) => {
            q.IdTest = null;
            q.IdQuestion = ++i;
            q.TestAnswer = q.TestAnswer.map((a, j)=> {
                a.IdTest = null;
                a.IdQuestion = q.IdQuestion;
                a.IdAnswer = ++j; 

                return a;
            });

            return q;
        })

        this._testQuestions = JSON.stringify(this.currentTest.TestQuestion || []);
        this.resetForm();

        this.currentTest.TestQuestion.forEach((pregunta) => {
            this.addQuestion(pregunta);
            pregunta.TestAnswer.forEach((respuesta) => {
                this.addQuestionAnswer(pregunta.IdQuestion, respuesta);
            });
        });

        this.currentTest.TestQuestion.forEach((pregunta) => {
            this.addQuestionAnswer(pregunta.IdQuestion);
        });

        this.personalizedTest = this.currentTest;
        this.state = "add-personalizedTestQuestions";
        this.navigationService.setStateTest("add-personalizedTestQuestions");
        this._spinnerService.hide();
    }

    public hideContextMenu(idTest: number) : boolean {
        this.contextMenuVisible = null;
        this[`isContextMenuVisible${idTest}`] = false;
        document.getElementById(`trTest${idTest}`).style.background = 'inherit';

        return true;
    }

    public showContextMenu(idTest: number, e: any) {
        this.clearTestsSelection();
        
        if (this.contextMenuVisible) {
            if (this.contextMenuVisible !== idTest) {
                this.hideContextMenu(this.contextMenuVisible);
            } else {
                this.hideContextMenu(idTest);
                this.contextMenuVisible = null;

                return;
            }
        }

        this.contextMenuVisible = idTest;
        this[`isContextMenuVisible${idTest}`] = true;
        document.getElementById(`trTest${idTest}`).style.background = 'rgba(12, 161, 241, 0.2)';
        
        const clientRect = e.target.getBoundingClientRect();

        this.contextMenuY = clientRect.top - 5;
        this.contextMenuX = clientRect.left - 100;
        this._detector.detectChanges();
    }

    public async showTestPropertiesForm(personalizedTest: PersonalizedTest, operation: Operation): Promise<void> {
        if(operation == Operation.Edit){
            const result = await this.canEditOrChangeName(personalizedTest)
            if(!result){
                return;
            }
        }
        
        this.currentTest = personalizedTest;
        this.currentOperation = operation;
        if (operation === Operation.Join) 
            this.currentTest = await this.getCurrentJoinTest();
        if(operation === Operation.View){
            this.personalizedTestService.doTest = true;
            return;
        }
        this._showTestPropertiesForm(this.currentTest);
    }
    async canEditOrChangeName(personalizedTest: PersonalizedTest, title = this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.EDIT-TEST-TITLE')) {
        let editing = title.toLowerCase().includes("editar") ? true : false
        if (personalizedTest) {
            const ClassList = await this.customizedSessionService.IsTestAssigned(personalizedTest.IdTest).toPromise();
            if(ClassList && ClassList.length > 0 && personalizedTest.ShareTest == 2){
                let message = ''
                if(editing){
                    message = this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.ERROR-EDIT-SHARED-TEST')
                }
                else {
                    message = this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.ERROR-DELETE-SHARED-TEST')
                }
                this.showDialog(title, message)
                return false;
            }
            if(ClassList && ClassList.length > 0){
                let message = '';
                if(ClassList.some(x => x.StudentFinishedCount > 0)){
                    
                    if(editing){
                        message = this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.EDIT-TEST-MESSAGE1')
                    }
                    else {
                        message = this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.DELETE-TEST-MESSAGE1')
                    }
                }
                else {
                    if(editing){
                        message = this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.EDIT-TEST-MESSAGE2')
                    }
                    else {
                        message = this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.DELETE-TEST-MESSAGE2')
                    }
                }
                this.showDialog(title, message)
                return false;
            }
            /*
            if (ClassList && ClassList.length == 1) {
                let message = "Este test está asignado al aula " + ClassList[0].ClassName + " y no se puede " + (editing ? "Editar." : "Eliminar.");
                if (ClassList[0].StudentFinishedCount && ClassList[0].StudentFinishedCount > 0) {
                    message = "Este test no se puede " + (editing ? "modificar" : "eliminar") + " porque está asignado al aula " + ClassList[0].ClassName + " y ya lo han realizado uno o más alumnos."
                }
                this.showDialog(title, message)
                return false;
            }
            else if(ClassList?.length > 1){
                let message = "";
                if(ClassList.some(x => x.StudentFinishedCount > 0)){
                    message = "Este test no se puede " + (editing ? "modificar" : "eliminar") + " porque está asignado al aula ";
                    
                    ClassList.forEach((clase, index) => {
                        if(index < ClassList.length - 1){
                            message += clase.ClassName + " , "
                        }
                        else {
                            message += clase.ClassName + " "
                        }
                    })
                    message += "y ya lo han realizado uno o más alumnos."
                }
                else {
                    message = "Este test está asignado al aula ";
                    ClassList.forEach((clase, index) => {
                        if(index < ClassList.length - 1){
                            message += clase.ClassName + " , "
                        }
                        else {
                            message += clase.ClassName + " y no se puede "
                        }
                    })
                    message+= "" + (editing ? "Editar." : "Eliminar.");
                }
                this.showDialog(title, message)
                return false;
            }
        */
        }
        
        return true;
    }
    public showCloneTestPropertiesForm(personalizedTest: PersonalizedTest): void {        
        this.currentTest = JSON.parse(JSON.stringify(personalizedTest));
        this.currentTest.Name = null;
        this.currentOperation = Operation.Clone;

        this._showTestPropertiesForm(this.currentTest);
    }

    private _showTestPropertiesForm(personalizedTest: PersonalizedTest) {
        this.editing = false;
        this.addedNewQuestion = false;
        this.submitted = false;
        this.form = this.formBuilder.group({
            Name: [personalizedTest?.Name, [Validators.required, Validators.maxLength(40)]],
            TLevel: [personalizedTest?.TLevel ?? this.courses[0].Level,[Validators.required]],
            ShareTest: personalizedTest?.ShareTest ?? 0,
            MessQuestions: personalizedTest?.MessQuestions,
            TestFormat: personalizedTest?.TestFormat,
            ProportionalScore: personalizedTest?.ProportionalScore,
            MultipleCorrectAnswers: personalizedTest?.MultipleCorrectAnswers,
            AutoOpenTest: personalizedTest?.AutoOpenTest,
        });

        if (this.currentOperation === Operation.View)
            this.form.disable();

        this.state = "add-personalizedTest";
        this.navigationService.setStateTest("add-personalizedTest");
    }

    public async cloneTests(): Promise<void> {
        this.nameExists = false;
        this.personalizedTest = null;
        this.submitted = true;
        
        this.AssertNameExists(0, this.form.value.Name)
        if (this.nameExists) {
            this.form.get('Name').setErrors({nameExists: true})
            return;
        }
        
        if (this.form && this.form.invalid) return;

        this._spinnerService.show();
        this.nameExists = false;
        this.personalizedTest = null;

        this.resetForm();
        this.editing = false;        

        let questions = await this.personalizedTestService.getTestQuestions(this.currentTest.IdTest).pipe(takeUntil(this._unsubscribeAll)).toPromise();

        this.currentTest.IdTutor = this.authService.currentUser.Id;
        this.currentTest.TestQuestion = questions.map((q, i) => {
            q.IdTest = null;
            q.IdQuestion = ++i;
            q.TestAnswer = q.TestAnswer.map((a, j)=> {
                a.IdTest = null;
                a.IdQuestion = q.IdQuestion;
                a.IdAnswer = ++j; 

                return a;
            });

            return q;
        })

        this._testQuestions = JSON.stringify(this.currentTest.TestQuestion || []);
                    
        this.currentTest.TestQuestion.forEach((pregunta) => {
            this.addQuestion(pregunta);
            pregunta.TestAnswer.forEach((respuesta) => {
                this.addQuestionAnswer(pregunta.IdQuestion, respuesta);
            });
        });

        this.currentTest.TestQuestion.forEach((pregunta) => {
            this.addQuestionAnswer(pregunta.IdQuestion);
        });

        this._spinnerService.hide();
        this.personalizedTest = this.currentTest;
        this.state = "add-personalizedTestQuestions";
        this.navigationService.setStateTest(
            "add-personalizedTestQuestions"
        );

        this._detector.detectChanges();
        this.currentTest = null;
    }

    public canEditTest(personalizedTest: PersonalizedTest): boolean {
        return personalizedTest.IdTutor === this.authService.currentUser.Id;
    }

    public async getCurrentJoinTest(): Promise<PersonalizedTest> {
        const questions = await this.personalizedTestService.getTestsQuestions((this.personalizedTests || []).filter(t => t.selected).map(t => t.IdTest)).pipe(takeUntil(this._unsubscribeAll)).toPromise();
        let selectedTests = this.personalizedTests.filter(x => x.selected)
        let personalizedTest: PersonalizedTest = {
            editingPersonalizedTestName: false,
            Finished: 0,
            IdTest: null,
            IdTutor: this.authService.currentUser.Id,
            IdTestTheme: null,
            Name: null,
            CreationDate: new Date(),
            TestQuestion: questions,
            MultipleCorrectAnswers: this.personalizedTests.filter(x => x.selected).some(x => x.MultipleCorrectAnswers)
        };

        return personalizedTest;
    }
    toLocalTime(date){
        return moment(date).add(moment(date).utcOffset(), 'minutes')
    }
    showMultipleAnswersDialog(){
        this.confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });
        this.confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.MULTIPLE-ANSWERS-TITLE');
        this.confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.MULTIPLE-ANSWERS-MESSAGE') ;
        this.confirmDialog.componentInstance.space = true;
        this.confirmDialog.componentInstance.margin = false;
        this.confirmDialog.componentInstance.options = [{
            text: this._translateService.instant('ACCEPT'),
            callback: () => {
                this.confirmDialog = null;
            }
        }];
        this.confirmDialog.afterClosed()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(result => {
            result();
            this.confirmDialog = null;
        });
    }
    showDialog(title: string, message: string){
        this.confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });
        this.confirmDialog.componentInstance.title = title;
        this.confirmDialog.componentInstance.message1 = message;
        this.confirmDialog.componentInstance.space = true;
        this.confirmDialog.componentInstance.margin = false;
        this.confirmDialog.componentInstance.theme = title.toLowerCase().includes("editar") ? "blue" : "white"
        this.confirmDialog.componentInstance.options = [{
            text: this._translateService.instant('ACCEPT'),
            callback: () => {}
        }];
        this.confirmDialog.afterClosed()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(result => {
            result();
            this.confirmDialog = null;
        });
    }
}
