<div class="civilizationsTitle" [hidden]="hideCivilization" *ngIf="dataLoaded" [style.background-image]="getImgByName('aventura-26.svg')">

</div>
<div class="civilizations" [hidden]="hideCivilization" *ngIf="dataLoaded" [style.background-image]="getImgByName('mundo.svg')">

    <div class="controlsCiv">
        <div class="layer" (click)="goToCivilization(0)">
            <div class="layerimg" [style.background-image]="civilizations[0].style"></div>
            </div>
        <div class="layer" (click)="goToCivilization(1)">
            <div class="layerimg" [style.background-image]="civilizations[1].style"></div>
            </div>
        <div class="layer" (click)="goToCivilization(2)">
            <div class="layerimg" [style.background-image]="civilizations[2].style"></div>
        </div>
    </div>
    <div class="controlsCiv">
        <div class="layer" (click)="goToCivilization(3)">
            <div class="layerimg" [style.background-image]="civilizations[3].style"></div>
            </div>
        <div class="layer" (click)="goToCivilization(4)">
        <div class="layerimg" [style.background-image]="civilizations[4].style"></div>
        </div>
        <div class="layer2">
            <div class="layerimg" [style.background-image]="civilizations[5].style" (click)="goToCivilization(5)"></div>
            </div>
    </div>
    <div class="controlsCiv">
        <div class="layer2">
            <div class="layerimg" [style.background-image]="civilizations[6].style" (click)="goToCivilization(6)"></div>
            </div>
        <div class="layer">
            <div class="layerimg" [style.background-image]="getImgByName('CivilizationButton_bloqueado.svg')"></div>
        </div>
        <div class="layer2">
            <div class="layerimg" [style.background-image]="civilizations[7].style" (click)="goToCivilization(7)"></div>
            </div>
    </div>
    <div class="layerimgSecret" (click)="goToCivilization(6)"></div>
</div>
<div class="civilizationMap" [hidden]="hideCivilization == false" *ngIf="dataLoaded">
    <div class="civilizationBack" [ngClass]="'civilizationBack' + this.currentCivilizationIndex" [style.background-image]="getCivilizationStyle()">
        <div class="stage stage1" *ngFor="let stage of civilizations[currentCivilizationIndex].HistoryStages; let i = index" [ngClass]="getStageClass(i)" [style.background-image]="getStageStyle(i)">
        </div>
        <button [disabled]="calling" type="button" (click)=" (!getButtonClass().includes('hidden')) ? buyStage(civilizations[currentCivilizationIndex].HistoryStages[currentStageIndex]) : null" class="goldenButton2 buyButton" [ngClass]="getButtonClass()">
            <div class="priceText" [hidden]="civilizations[currentCivilizationIndex].HistoryStages[currentStageIndex].Active != true">
                {{civilizations[currentCivilizationIndex].HistoryStages[currentStageIndex].Walinwos}}
            </div>
            <div [hidden]="civilizations[currentCivilizationIndex].HistoryStages[currentStageIndex].Active == true || civilizations[currentCivilizationIndex].HistoryStages[currentStageIndex]?.bought == true" [innerHtml]="etapa_bloqueada(civilizations[currentCivilizationIndex].HistoryStages[currentStageIndex])"></div>
            <img class="waliGameCoin" src="https://walinwa.blob.core.windows.net/images/walistar.png?ngsw-bypass=true" [hidden]="civilizations[currentCivilizationIndex].HistoryStages[currentStageIndex].Active != true">
        </button>
        <button type="button" (click)="resetLevel()" [disabled]="isButtonDisabled()" class="goldenButton2 buyButton" [ngClass]="getButtonFinishClass()">
            <div class="priceText">
                {{'STUDENT.GAMES.END-GRADE-BUTTON' | translate }}
            </div>
        </button>
    </div>
    <div id="button_game" [style]="getButonGameBackImg()"  [ngClass]="getButonGameBackClass()"></div>
    <div style="z-index: 101 !important" (mousedown)="changeBackground(true)" (mouseup)="changeBackground(false)"  [ngClass]="getButonGameBackClass()" (click)="goToGame(currentCivilizationIndex)"></div>
</div>

<div data-pop="pop-swirl" id="popup" (click)="closePopUp()">
    <div class="popupcontent" *ngIf="dataLoaded">
        <div [style.background-image]="civilizations[currentCivilizationIndex].HistoryStages[currentStageIndex].rewardStyle"></div>
    </div>
</div>

<div id="overlay" (click)="closePopUp()"></div>