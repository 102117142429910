<div class="walinwa-box walinwa-box-green" *ngIf="!showHelp">
    <div class="walinwa-box-header spe_gap" fxLayout="row wrap" fxLayoutALign="space-between center">
        <div fxFlex="">
            {{'STUDENT.SCENARY.TITLE' | translate}}
        </div>
        <div fxFlex="1.4em">
            <img (click)="showHelp = !showHelp" src="https://walinwa.blob.core.windows.net/images/blanco-93.png?ngsw-bypass=true"
                class="pointer" *ngIf="!showHelp" />
        </div>
    </div>
    <div class="walinwa-box-content">
        <div class="walinwa-box-zone-items">
            <div class="walinwa-box-zone-item" *ngFor="let item of availableItems; let i = index">
                <div class="selectablePanel" (click)="selectItem(i)"
                    [ngClass]="{ selectedPanel: item.selected == true }">
                    <div class="stageStatus" [ngClass]="{ activeBg: item.Active == true }">
                        <div class="stageImg" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/MiZona/' + getImgName(item) + '&quot;)'"></div>
                    </div>
                </div>
            </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center" class="spe_gap">
            <button [disabled]="loading" (click)="save()" class="new_but azul">
                {{'STUDENT.SCENARY.ACTIVATE' | translate}}
            </button>
        </div>
    </div>
</div>

<div class="walinwa-box walinwa-box-green" *ngIf="showHelp">
    <div class="walinwa-box-header">
        <div> {{'STUDENT.SCENARY.EARN-NEW-SCENARIES' | translate}}</div>
    </div>
    <div class="walinwa-box-content" fxLayout="row wrap">
        <div class="spe_gap" fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="center center">
            <div fxFlex="35%" fxFlex.lt-md="60%">
                <img src="https://walinwa.blob.core.windows.net/images/MiZona/Mizona_Walinwin.png?ngsw-bypass=true" />
            </div>
            <div fxFlex="" fxFlex.lt-md="100%" class="spe_gap" fxLayout="column" fxLayoutAlign="stretch">
                <div class="text">
                    {{'STUDENT.SCENARY.TEXT' | translate}}
                </div>

                <div class="text">
                    {{'STUDENT.SCENARY.TEXT2' | translate}}
                </div>

                <div class="text">
                    Actualmente <span class="userInfo">{{userInfo.DoneActivities == 0 ? 'no' : ''}}</span> tienes <span
                        class="userInfo">{{userInfo.DoneActivities == 0 ? '' : userInfo.DoneActivities }}</span>
                    actividades
                    realizadas con una nota igual o superior a <span class="userInfo">{{userInfo.Score}}</span>. Te
                    {{userInfo.RemainingActivities <= 0 ? 'falta' : 'faltan'}}
                    <span class="userInfo">{{userInfo.RemainingActivities  <= 0 ? '1' : userInfo.RemainingActivities}}</span> para desbloquear tu nuevo escenario{{userInfo.RemainingActivities <= 0 ? ' siempre que no reinicies la siguiente actividad.' : '.'}}
                </div>

                <div class="text">{{'STUDENT.SCENARY.GOOD-LUCK' | translate}}</div>
                <div fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center" class="spe_gap">
                    <button (click)="showHelp = !showHelp" class="new_but azul">
                        {{'CONTINUE' | translate}}
                    </button>
                </div>

            </div>

        </div>
    </div>
</div>