import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { AuthService } from "app/core/guards/auth.service";
import { ExerciseSolvedEnum } from "app/core/shared/enums/exercise-solved.enum";
import { ExerciseService } from "app/services/exercise.service";
import { toPath } from "lodash";
declare var $;

@Component({
    selector: "ex-mod2",
    templateUrl: "./mod2.component.html",
    styleUrls: ["./mod2.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class Mod2Component implements OnInit, OnDestroy {
    @Input() review;
    @Input() show_errors;
    @Input() controlSession : boolean = false;
    @Input() slide : boolean = false; @Input() current;
    exercise;
    draggingElement;
    f = false;
    timeout = null;
    ok = [];
    error = [];
    finished = false;
    numOk = 0;
    numErr = 0;

    elementsOk;
    elementsErr;
    leftWords;
    rightWords;
    leftValidation;
    rightValidation;
    opciones = 0;

    myTimeOut
    count = 0;
    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_SOLUTION]:
                this.finished = true;
                clearTimeout(this.myTimeOut)
                if (this.exerciseService.showingSolution) {
                    setTimeout(this.ShowAnswersTimeOut.bind(this), 100);
                    break;
                } else {
                    setTimeout(this.ShowSolution.bind(this), 100);
                    break;
                }
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_ANSWER]:
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_CUSTOM]:
                this.finished = true;
                clearTimeout(this.myTimeOut)
                setTimeout(this.ShowAnswer.bind(this), 100)
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.EXERCISE_END]:
                this.finished = true
                clearTimeout(this.myTimeOut)
                break;
        }
    }
    constructor(private exerciseService: ExerciseService) {
        this.exercise = this.exerciseService.getExerciseDefinition();
    }
    public get exercisesSolvedEnum(): typeof ExerciseSolvedEnum{
        return this.exerciseSolvedEnum
    }
    
    public get exerciseSolvedEnum(): typeof ExerciseSolvedEnum{
        return ExerciseSolvedEnum
    }
    ngOnDestroy() {
        this.finished = true
        clearTimeout(this.myTimeOut)
    }

    ngOnInit() {
        this.setAnswers();
        this.setPositions();
        this.FillExercise();
        if (this.review) {
            this.elementsOk = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsOK
            );
            this.elementsErr = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsERR
            );
            if (this.show_errors == false) {
                this.ShowSolution();
              } else if (this.show_errors == true || this.show_errors == null) {
                this.ShowAnswer();
              }
        }
    }
    cleanExercise(){
        this.finished = false;
        this.exercise.palabras.forEach(palabra => {
            palabra.answered = null;
            palabra.notAnserwed = null;
            if (palabra.opcion == true) {
                this.opciones++;
            }
        });
    }
    ShowAnswersTimeOut() {
        for (var i = 0; i < this.exercise.palabras.length; i++) {
            this.exercise.palabras[i].opacity = 1;
            this.exercise.palabras[i].notAnserwed = null;
            if (this.exercise.palabras[i].answered == null) {
                if (this.exercise.palabras[i].opcion)
                    this.exercise.palabras[i].notAnserwed = true;
            }

        }

    }
    setAnswers() {
        for (var i = 0; i < this.exercise.palabras.length; i++) {
            this.exercise.palabras.answered = null;
            if (this.exercise.palabras[i].opcion == true) {
                this.opciones++;
            }
        }
    }

    setPositions() {
        if (!this.exercise.recarga) this.exercise.recarga = 2;
        else this.exercise.recarga = parseFloat(this.exercise.recarga);
        this.exercise.palabras = this.shuffle(this.exercise.palabras);
        for (var i = 0; i < this.exercise.palabras.length; i++) {
            var top = Math.random() * 10 + 1;
            var left = Math.random() * 10 + 1;
            this.exercise.palabras[i].top = top;
            this.exercise.palabras[i].left = left;
            this.exercise.palabras[i].opacity = 1;
        }
        if (!this.review) this.desaparecer_palabras();
    }

    desaparecer_palabras() {


        for (var i = 0; i < this.exercise.palabras.length; i++) {
            if (this.exercise.palabras[i].answered == null) {
                this.exercise.palabras[i].opacity -= 0.1;
            } else {
                this.exercise.palabras[i].opacity = 1;
            }
            if (this.exercise.palabras[i].opacity <= 0) {
                this.exercise.palabras[i].opacity = 1;
                this.finished = true;
                if (this.timeout == null) this.timeout = true;
                if(this.count == 0) {
                    this.count++;
                    window.postMessage("TIMER_END", "*");
                }
            }
        }

        if (this.finished) { 
            for (var i = 0; i < this.exercise.palabras.length; i++) {
                this.exercise.palabras[i].opacity = 1;
            }
            return
        }

        this.myTimeOut = setTimeout(
            this.desaparecer_palabras.bind(this),
            8000 / this.exercise.velocidad
        );
    }

    getPalabra(palabra) {
        if(this.controlSession){
            return palabra.palabra;
        }
        if (palabra.answered == true || this.finished || this.review) {
            return palabra.rae;
        }
        return palabra.palabra;
    }

    shuffle(array) {
        var currentIndex = array.length,
            temporaryValue,
            randomIndex;
        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array;
    }

    FillExercise() {
        for (let i = 0; i < this.exercise.palabras.length; i++) {
            this.exercise.palabras[i].palabraAux =
                this.exercise.palabras[i].palabra;
        }
    }

    getWordPosition(palabra) {
        if(this.controlSession && !this.slide){
            return {
                'font-size': "1.82em;",
                'padding': '0.25em 3em;',
                'border-radius': '2em;'
            };
        }
        return {
            position: "relative",
            top: palabra.top + "%",
            left: palabra.left + "%",
            opacity: !this.finished ? palabra.opacity : 1,
        };
    }

    validar(palabra) {
        if (this.finished) return;

        if (palabra.answered != true && palabra.answered != false) {
            if (palabra.opcion == true) {
                palabra.answered = true;
                palabra.opacity = 1;
                this.exerciseService.answer(
                    palabra.idPalabra,
                    true,
                    this.opciones
                );
                this.numOk++;
            } else {
                palabra.answered = false;
                palabra.opacity = 1;
                this.exerciseService.answer(
                    palabra.idPalabra,
                    false,
                    this.opciones
                );
                this.numErr++;
                /*
                setTimeout(() => {
                    palabra.answered = null
                }, 1000 * 0.5)
                */
            }
            if (this.numOk == this.opciones) {
                this.finished = true;
                this.timeout = false;
            }
        }
    }

    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    ShowSolution() {
        for (var i = 0; i < this.exercise.palabras.length; i++) {
            this.exercise.palabras[i].notAnsewred = null
            this.exercise.palabras[i].answered = this.exercise.palabras[i].opcion == true ? true : null;
        }
    }
    ShowAnswer() {
        var oks = this.elementsOk
        var errors = this.elementsErr
        for (var i = 0; i < this.exercise.palabras.length; i++) {
            this.exercise.palabras[i].answered = null;
            this.exercise.palabras[i].notAnsewred = null;
        }

        for (var i = 0; i < oks.length; i++) {
            for (var j = 0; j < this.exercise.palabras.length; j++) {
                if (this.exercise.palabras[j].idPalabra == oks[i])
                    this.exercise.palabras[j].answered = true;
            }
        }

        for (var i = 0; i < errors.length; i++) {
            for (var j = 0; j < this.exercise.palabras.length; j++) {
                if (this.exercise.palabras[j].idPalabra == errors[i])
                    this.exercise.palabras[j].answered = false;
            }
        }

        for (var i = 0; i < this.exercise.palabras.length; i++) {
            if (this.exercise.palabras[i].opcion && this.exercise.palabras[i].answered == null)
                this.exercise.palabras[i].notAnserwed = true;
        }
    }
}
