<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="ex_img spe_gap" (click)="setFocus(focusing ? false : true)" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" fxLayout="column" fxLayoutAlign="stretch">

    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div class="wordsContainer">
        <div class="items_container">
            <!--IMAGEN-->
            <div class="imagen" [ngClass]="{'imagen-slide': slide}">
                <img [src]="getImgSrc()" />
            </div>

            <!--PALABRA-->
            <div class="palabra" *ngIf="!review || !showingSolution">
                <div [ngClass]="{'bien': letra.answered == true, 'mal': letra.answered == false}" class="letra"
                    [attr.letra]="i" *ngFor="let letra of letras; let i = index">
                    <span [hidden]="letra.answered != true">{{ letra.letra }}</span>
                </div>
            </div>

            <!--PALABRA-->
            <div class="palabra" *ngIf="review && showingSolution">
                <div [ngClass]="{'bien': letra.answered == true,'mal': letra.answered == false}" class="letra dropzone"
                    [attr.letra]="i" *ngFor="let letra of letras; let i = index">
                    <span>{{ letra.letra }}</span>
                </div>
            </div>

            <!--TECLADO-->
            <div class="letters" *ngIf="!review || !showingSolution" [hidden]="controlSession || isMobile">
            <ul class="list-inline">
                    <li *ngFor="let letter of letters; let i = index" class="letterContainer">
                        <div (click)="add_letra(letter.name)" [attr.letra]="letter.name" class="letter-button letter">
                            {{ letter.name }}
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</div>
<form style="position: fixed; top: -99999em; left: -99999em;" autocomplete="off" *ngIf="this.isMobile">
    <input [(ngModel)]="input" autocorrect="off" autocapitalize="off" spellcheck="false" (ngModelChange)="change()"
        [ngModelOptions]="{standalone: true}" autocomplete="off" type="text" id="dictation-input" autofocus  />
</form>
<!--  -->
<!-- <div class="ex_img" [ngStyle]="{'pointer-events': review ? 'none;' : ''}">   <h2 *ngIf="controlSession" class="doc-number">{{current + 1}}</h2>

    --TEMA --
    <div *ngIf="!controlSession" class="exerciseTheme">
        <h1 [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></h1>
    </div>
    --FIN TEMA --

    --ENUNCIADO --
    <div class="text-center exerciseTitle"  [ngClass]="{'title' : controlSession, 'exerciseTitle-slide': slide}">
        <p   [innerHtml]="exercise.enunciado"></p>
    </div>

    --ENUNCIADO --

    --IMAGEN--
    <div class="imagen" [ngClass]="{'imagen-slide': slide}" [ngStyle]="controlSession ? {padding: '1em;'} : {}">

        <img [src]="getImgSrc()" />

    </div>
    --FIN IMAGEN--

    --PALABRA--
    <div class="palabra" *ngIf="!review || !showingSolution">
        <div [ngClass]="{'bien': letra.answered == true, 'mal': letra.answered == false}" class="letra" [attr.letra]="i" *ngFor="let letra of letras; let i = index">
            <span [hidden]="letra.answered != true">{{ letra.letra }}</span>
        </div>
    </div>
    --FIN PALABRA--

    --PALABRA--
    <div class="palabra" *ngIf="review && showingSolution">
        <div [ngClass]="{'bien': letra.answered == true,'mal': letra.answered == false}" class="letra dropzone" [attr.letra]="i" *ngFor="let letra of letras; let i = index">
            <span >{{ letra.letra }}</span>
        </div>
    </div>
    --FIN PALABRA--

    --TECLADO--
    <div class="letters" *ngIf="!review || !showingSolution" [hidden]="controlSession">
        <ul class="list-inline">
            <li *ngFor="let letter of letters; let i = index" class="letterContainer">
                <div (click)="add_letra(letter.name)" [attr.letra]="letter.name" class="letter-button letter">
                    {{ letter.name }}
                </div>
            </li>

        </ul>
    </div>
    --FIN TECLADO--
</div> -->