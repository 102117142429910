import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterAuthComponent } from './footer-auth.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
FooterAuthComponent],
imports     : [
    RouterModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,

    FuseSharedModule
],
exports     : [
FooterAuthComponent]
})
export class FooterAuthModule { }
