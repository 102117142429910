import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CoinService {

  // showCoin = false;
  // showCoin1 = false;
  // showCoin2 = false;
  // showCoin3 = false;
  // showCoinUp;
  // changeBlue = false;
  // multipleCoins = null;
  // showMultipleCoins = false;

  constructor(private authService: AuthService) { }

  showCoins(n, callback, from = null, to = null) {
    for (let i = 0; i < n; i++) {
      let coin = new Image();
      coin.src = "https://walinwa.blob.core.windows.net/images/Student/coin.gif?ngsw-bypass=true";
      coin.classList.add("walinwa-coin");
      
      if (!from) {
        coin.style.top = "calc(100% + 2em)";
        coin.style.left = Math.floor(Math.random() * 100) + "%";
      } else {
        let element = document.getElementById(from);
        coin.style.top = element.getBoundingClientRect().top + "px";
        let width2 = element.getBoundingClientRect().width;
        let left =  element.getBoundingClientRect().left + (width2 / 2);
        coin.style.left = left + "px";
      }

      coin.style.transition = ((Math.random() * 1.5) + 0.75) + "s";
      document.querySelectorAll(".walinwa-app")[0].appendChild(coin);
      setTimeout(() => {
        if (!to) {
          coin.style.top = "-2em";
        } else {
          let element2 = document.getElementById(to);
          coin.style.top = element2.getBoundingClientRect().top + "px";
          let width2 = element2.getBoundingClientRect().width;
          let left =  element2.getBoundingClientRect().left + (width2 / 2);
          coin.style.left = left + "px";
        }
        setTimeout(() => {
          coin.remove();
        }, 1000);
      }, 100);
    }
    callback()
  }

  showMultipleCoins(callback) {
    this.showCoins(50, callback);
  }
}
