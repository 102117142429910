import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { AuthService } from "app/core/guards/auth.service";
import { ExerciseSolvedEnum } from "app/core/shared/enums/exercise-solved.enum";
import { ExerciseService } from "app/services/exercise.service";
import { Console } from "console";
declare var $;

@Component({
    selector: "ex-mod3",
    templateUrl: "./mod3.component.html",
    styleUrls: ["./mod3.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class Mod3Component implements OnInit, OnDestroy {
    @Input() review;
    @Input() show_errors;
    @Input() controlSession : boolean = false;
    @Input() slide : boolean = false; @Input() current;
    exercise;
    draggingElement;
    f = false;
    timeout = null;
    ok = [];
    error = [];
    finished = false;
    numOk = 0;
    numErr = 0;

    elementsOk;
    elementsErr;
    leftWords;
    rightWords;
    leftValidation;
    rightValidation;
    opciones = 0;
    mytimeout
    count = 0;
    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case "SHOW_SOLUTION":
                this.finished = true;
                clearTimeout(this.mytimeout)
                if (this.exerciseService.showingSolution) {
                    setTimeout(this.ShowAnswersTimeOut.bind(this), 100);
                    break;
                } else {
                    setTimeout(this.showSolution.bind(this), 100);
                    break;
                }
            case "SHOW_ANSWER":
            case "SHOW_CUSTOM":
                this.ShowAnswer();
                break;
        }
    }

    constructor(private exerciseService: ExerciseService) {
        this.exercise = this.exerciseService.getExerciseDefinition();
    }
    public get exercisesSolvedEnum(): typeof ExerciseSolvedEnum{
        return this.exerciseSolvedEnum
    }
    
    public get exerciseSolvedEnum(): typeof ExerciseSolvedEnum{
        return ExerciseSolvedEnum
    }
    ngOnDestroy(){
        clearTimeout(this.mytimeout)
    }

    ngOnInit() {
        this.setAnswers();
        if (!this.review) this.setPositions();
        this.FillExercise();
        if (this.review) {
            this.elementsOk = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsOK
            );
            this.elementsErr = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsERR
            );
            if (this.show_errors == false) {
                this.showSolution();
              } else if (this.show_errors == true || this.show_errors == null) {
                this.ShowAnswer();
              }
        } else {
        }
    }
    cleanExercise(){
        this.finished = false;
        this.exercise.palabras.forEach(palabra => {
            palabra.answered = null;
            palabra.notAnserwed = null;
            if (palabra.opcion == true) {
                this.opciones++;
            }
        });
    }
    setAnswers() {
        for (var i = 0; i < this.exercise.palabras.length; i++) {
            this.exercise.palabras.answered = null;
            if (this.exercise.palabras[i].opcion) {
                this.opciones++;
            }
        }
    }

    setPositions() {
        if (!this.exercise.recarga) this.exercise.recarga = 2;
        else this.exercise.recarga = parseFloat(this.exercise.recarga);
        this.exercise.palabras = this.shuffle(this.exercise.palabras);
        var c = 0;
        for (var i = 0; i < this.exercise.palabras.length; i++) {
            if (c === 3) c = 0; else c++;
            var top = c * 10;
            var left = i * c * 10;
            this.exercise.palabras[i].top = top;
            this.exercise.palabras[i].left = left;
            this.exercise.palabras[i].fontSize = 100;
        }
        this.desaparecer_palabras();
    }

    desaparecer_palabras() {
        for (var i = 0; i < this.exercise.palabras.length; i++) {
            if (this.exercise.palabras[i].answered == null) {
                this.exercise.palabras[i].fontSize -= 10;
            } else {
                this.exercise.palabras[i].fontSize = 100;
            }
            if (this.exercise.palabras[i].fontSize <= 0) {
                this.exercise.palabras[i].fontSize = 100;
                this.finished = true;
                clearTimeout(this.mytimeout)
                if (this.timeout == null) this.timeout = true;
                if(this.count == 0) {
                    this.count++;
                    window.postMessage("TIMER_END", "*");
                }
            }
        }

        if (!this.finished)
            this.mytimeout = setTimeout(
                this.desaparecer_palabras.bind(this),
                8000 / this.exercise.velocidad
            );
    }

    getPalabra(palabra) {
        if (palabra.answered == true || this.finished) {
            return palabra.rae;
        }
        return palabra.palabra;
    }

    shuffle(array) {
        var currentIndex = array.length,
            temporaryValue,
            randomIndex;
        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array;
    }

    FillExercise() {
        for (let i = 0; i < this.exercise.palabras.length; i++) {
            this.exercise.palabras[i].palabraAux =
                this.exercise.palabras[i].palabra;
        }
    }

    getWordPosition(palabra) {
        if (this.review) return {}
        var letra = palabra.fontSize + "%";
        var top = palabra.top + "%";
        var left = palabra.left + "%";
        var margin = "1%";
        return {
            "font-size": letra,
        };
    }

    validar(palabra) {


        if (this.finished) return;
        if (palabra.answered != true && palabra.answered != false) {
            if (palabra.opcion) {
                palabra.answered = true;
                palabra.fontSize = 100;
                this.exerciseService.answer(
                    palabra.idPalabra,
                    true,
                    this.opciones
                );
                this.numOk++;
            } else {
                palabra.answered = false;
                this.exerciseService.answer(
                    palabra.idPalabra,
                    false,
                    this.opciones
                );
                this.numErr++;
            }
            if (this.numOk == this.opciones) {
                this.finished = true;
                clearTimeout(this.mytimeout)
                this.timeout = false;
            }
        }
    }

    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    }
    ShowAnswersTimeOut() {
        for (var i = 0; i < this.exercise.palabras.length; i++) {
            this.exercise.palabras[i].opacity = 1;
            this.exercise.palabras[i].notAnserwed = null;
            if (this.exercise.palabras[i].answered == null) {
                if (this.exercise.palabras[i].opcion)
                    this.exercise.palabras[i].notAnserwed = true;
            }

        }

    }
    showSolution() {
        for (var i = 0; i < this.exercise.palabras.length; i++) {
            this.exercise.palabras[i].notAnserwed = null
            this.exercise.palabras[i].answered = this.exercise.palabras[i].opcion == true ? true : null
        }
    }
    ShowAnswer() {
        var oks = JSON.parse(
            this.exerciseService.getCurrentExercise().ElementsOK
        );
        var errors = JSON.parse(
            this.exerciseService.getCurrentExercise().ElementsERR
        );
        for (var i = 0; i < this.exercise.palabras.length; i++) {
            this.exercise.palabras[i].answered = null;
            this.exercise.palabras[i].notAnserwed = null;
        }

        for (var i = 0; i < oks.length; i++) {
            for (var j = 0; j < this.exercise.palabras.length; j++) {
                if (this.exercise.palabras[j].idPalabra == oks[i])
                    this.exercise.palabras[j].answered = true;
            }
        }

        for (var i = 0; i < errors.length; i++) {
            for (var j = 0; j < this.exercise.palabras.length; j++) {
                if (this.exercise.palabras[j].idPalabra == errors[i])
                    this.exercise.palabras[j].answered = false;
            }
        }

        for (var i = 0; i < this.exercise.palabras.length; i++) {
            if (
                this.exercise.palabras[i].opcion &&
                this.exercise.palabras[i].answered == null
            )
                this.exercise.palabras[i].notAnserwed = true;
        }
    }
}
