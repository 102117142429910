import {
    Component,
    OnInit,
    OnDestroy,
    ViewEncapsulation,
    ChangeDetectorRef
} from "@angular/core";
import { Router } from "@angular/router";
import { SchoolService } from "app/services/school.service";
import { CourseService } from "app/services/course.service";
import { UiService } from "@fuse/components/spinner/ui.service";
import { SharedService } from "app/core/shared/shared.service";
import { NavigationService } from "app/services/navigation.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Profiles } from "app/core/shared/session.model";


@Component({
    selector: "school-admin",
    templateUrl: "./admin.component.html",
    styleUrls: ["./admin.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class AdminComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    currentUser;
    cursoDemo = null;
    state;
    school;
    courses;
    coursesForDemo;
    calling = false;
    dataLoaded = true;
    dataLoaded2 = true;
    loading = false;

    constructor(
        private router: Router,
        public schoolService: SchoolService,
        private courseService: CourseService,
        private _ui: UiService,
        private _sharedService: SharedService,
        private navigationService: NavigationService,
        private _detector: ChangeDetectorRef
    ) {  
        this._unsubscribeAll = new Subject();
        this._ui.spin$.next(true);
    }

    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit() {
        this.loading = true;
        this.init();

        this.courseService.Courses.pipe(takeUntil(this._unsubscribeAll)).subscribe(courses => {
            this.courses = courses;
            this.loading = false;
        });
        this.schoolService.test_session.subscribe(test_session => {
            if(test_session){
                this.demo()
            }
        })
    }

    init() {
        if (!sessionStorage.getItem("currentUser")) {
            this.goLogin();

            return;
        }

        this.school = this.schoolService.school;
        this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
        this.navigationService.reset();

        if (this.navigationService.params.state) {
            if (this.navigationService.params.state == "demo")
                this.demo();
            else
                this.goLogin(); 
        } else { 
            if (sessionStorage.getItem(Profiles.IsSchoolAdmin) === 'true' || sessionStorage.getItem(Profiles.IsSuperAdmin) === 'true')
                this.ChangeState("start");
            else
                this.goLogin();            
        }    
    }

    ChangeState(state) {
        this.state = state;
        this.navigationService.enableExitButton(true);
        setTimeout(() => this._detector.detectChanges());
    }

    goLogin() {
        sessionStorage.removeItem("currentUser");
        sessionStorage.removeItem("adminLogin");
        sessionStorage.removeItem("state");
        sessionStorage.removeItem("state_params");
        sessionStorage.setItem("isLogged", "false");
        this.router.navigateByUrl("login");
    }

    demo() {
        this.ChangeState("demo");

        setTimeout(() => {
            this.coursesForDemo = this.courses;
            this.cursoDemo = this.coursesForDemo[0].Level;
        }, 100);
    }

    exit() {
        this.goLogin();
    }

    cancelDemo() {
        this.ChangeState("start");
    }

    continueDemo() {
        if (this.calling == true) return;

        this.loading = true;
        this.calling = true;

        // this._ui.showSpinner();

        this.schoolService
            .startDemo(
                this.school.IdSchool,
                this.cursoDemo,
                this.currentUser.Id
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result) => {
                    // this._ui.stopSpinner();
                    this.okContinueDemo(result);
                },
                (error) => {
                    // this._ui.stopSpinner();
                    this.errorContinueDemo();
                }
            );
    }

    okContinueDemo(user) {
        this.calling = false;
        this.loading = false;
        sessionStorage.setItem("returnCode", user.ReturnCode);
        this._sharedService.redirectRol(user.User);
    }

    errorContinueDemo() {
        this.calling = false;
        this.loading = false;
    }

    startPayment() {
        this.navigationService.go("/school/adminPayment", {});
    }

    startPromo() {
        this.router.navigateByUrl("/school/adminPromo");
    }

    viewReports() {
        this.router.navigateByUrl("/school/admin/report");
    }

    public navigateTo(route: string): void {
        this.navigationService.reset();
        this._detector.detectChanges();
        setTimeout(() => this._detector.detectChanges());
        this.router.navigateByUrl(route);
    }
}
