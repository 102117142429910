<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="ahorca spe_gap" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" fxLayout="column"
    fxLayoutAlign="stretch">

    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div class="wordsContainer" id="space-for-text">
        <div class="items_container spe_gap" fxLayout="column" fxLayoutAlign="space-between center"
            fxLayoutAlign.lt-md="start center">
            <div class="exerciseRule" [ngClass]="{'element-hidden': exercise.regla == null}"
                [innerHtml]="exercise.regla"></div>
            <div fxLayout="row nowrap" fxLayout.xs="row wrap" class="intentos_sec">
                <div fxFlex="" fxFlex.xs="100%">
                    <div class="secretWord spe_gap"
                        [ngClass]="{ 'perfect': review && elementsErr.length == 0 && elementsOk.length == secretWord.length}"
                        fxLayout="column">
                        <div class="retries" *ngIf="errors != 1">
                            Te quedan <span class="textRed">{{ errors }}</span> intentos
                        </div>
                        <div class="retries" *ngIf="errors == 1">
                            Te queda <span class="textRed">{{ errors }}</span> intento
                        </div>
                        <div class="word">
                            <ul class="list-inline">
                                <li *ngFor="let letter of secretWord; let i = index">
                                    <span [hidden]="letter.show" class="letter">_</span>
                                    <span [hidden]="!letter.show" class="letter">{{ letter.value }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- El logo no ayuda mostrarlo en mobile, pero mantengo su estructura para una evaluación a futuro / Leo -->
                <div fxHide fxShow.xs class="imageContainer two">
                    <div class="image" [style.background-image]="getImgStyle()"></div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-evenly stretch" class="keyboard_sec spe_gap">
                <div fxFlex="" class="letters">
                    <ul class="list-inline" [ngClass]="{'fixControlSessions': controlSession}">
                        <li *ngFor="let letter of letters; let i = index" class="letterContainer">
                            <div [ngClass]="{ 'chosen': letter.chosen}" class="letter-button letter"
                                [disabled]="letter.chosen" (click)="letter.chosen ? null : try(letter);">
                                {{ letter.name }}
                            </div>
                        </li>
                    </ul>
                </div>
                <div fxHide.xs class="imageContainer">
                    <div class="image" [style.background-image]="getImgStyle()"></div>
                </div>
            </div>
        </div>
    </div>
</div>