import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'app/services/user.service';
import * as moment from 'moment';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { TeacherMessageService } from 'app/services/teacher-message.service';
import { MatDialog } from '@angular/material/dialog';
import { NavigationService } from 'app/services/navigation.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'app/services/auth.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { SharedService } from 'app/core/shared/shared.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: "app-messages",
    templateUrl: "./messages.component.html",
    styleUrls: ["./messages.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class MessagesComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  currentUser;
  dataLoaded = false;
  authUsers;
  classes = [];
  alumnos = [];
  status = 'newMessage';
  user;
  sendEnabled = false;
  messageDetails;
  states;
  selectedClassId
  selectedClassName
  sentMessages
  allSelected
  students
  messageText
  calling
  selectedIdUser
  selectedAuthUser
  formDataErr1
  formDataErr2
  form: UntypedFormGroup = new UntypedFormGroup({});
  sendingEmail = false;
    attachment: any;
    BigFile = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private teacherMessageService: TeacherMessageService,
    private _matDialog: MatDialog,
    private navigationService: NavigationService,
    private fb: UntypedFormBuilder,
    public sharedService: SharedService,
    private _translateService: TranslateService,
  ) { 
    this._unsubscribeAll = new Subject();
    this.createForm();
  }

  ngOnDestroy(): void
  {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
  }

  ngOnInit() {
    if (!sessionStorage.getItem("currentUser")) return this.navigationService.exit();
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (!this.currentUser) return this.navigationService.exit();
    this.navigationService.setStateMessage('newMessage');
    this.navigationService.enableExitButton();
    this.user = this.currentUser;
    this.userService.getClassesByIdTutor(this.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetClasses.bind(this), this.errorCallback.bind(this));
  }
  createForm(){
    this.form = this.fb.group({
      subject: ['', [Validators.required]],
      body: ['', [Validators.required]]
    })
  }
  

  removeHashtags(text) {
    return text.split('#')[0];
  }

  showCurso(index) {
    for (let i = 0; i < this.classes.length; i++) {
      this.classes[i].selected = false;
    }
    this.classes[index].selected = true;
    this.selectedClassId = this.classes[index].IdClass;
    this.selectedClassName = this.classes[index].Name;
    if (this.selectedClassId == -1) {
      this.successLoad(this.authUsers);
    } else {
      this.userService.getStudentsByParentId(this.currentUser.Id, this.selectedClassId)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successLoad.bind(this), this.errorCallback.bind(this));
    }
  }

  changeStatus(status) {
    this.status = status;
    this.navigationService.setStateMessage(status);
    this.messageDetails = null;
    if (this.navigationService.messageState == 'viewMessage') {
      this.teacherMessageService.getMessages(100)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successLoadMessages.bind(this), this.errorCallback.bind(this));
    }
  }

    getComa(user) {
        if (
            user.FamilyName != null &&
            user.Name != null &&
            user.FamilyName != "" &&
            user.Name != ""
        ) {
            return ",";
        }
        return "";
    }

    selectAll() {
        if (this.allSelected) {
            this.allSelected = false;
            for (let i = 0; i < this.students.length; i++)
                this.students[i].selected = false;
        } else {
            this.allSelected = true;
            for (let i = 0; i < this.students.length; i++)
                this.students[i].selected = true;
        }
    }

    checkCanSend() {
        if (this.messageText && this.messageText.length > 0) {
            for (let i = 0; i < this.students.length; i++)
                if (this.students[i].selected) return true;
        }
        return false;
    }

  getMessageDetails(IdMessage) {
    this.teacherMessageService.getDetails(IdMessage)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe((response) => {
      this.messageDetails = response;
      if (this.messageDetails.length == 0) {
        this.messageDetails = null;
        this.teacherMessageService.getMessages(100)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successLoadMessages.bind(this), this.errorCallback.bind(this));
      }
      if (this.messageDetails) {
        this.navigationService.enableBackButtonMessage(true,'viewMessageInfo');
      }
    }, this.errorCallback.bind(this));
  }
  findMessage(IdMessage){
    return this.sentMessages.find(x => x.IdMessage == IdMessage)?.Message
  }
  goBackMessages() {
    this.messageDetails = null;
    this.teacherMessageService.getMessages(100)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successLoadMessages.bind(this), this.errorCallback.bind(this));
  }

  removeMessageUser(messageUser) {
    let message = [];
    message[0] = this._translateService.instant('SCHOOL.TEACHER.MESSAGES.DELETE-MESSAGE-TITLE');
    message[1] = this._translateService.instant('SCHOOL.TEACHER.MESSAGES.DELETE-MESSAGE-MESSAGE1');
    message[2] = this._translateService.instant('SCHOOL.TEACHER.MESSAGES.DELETE-MESSAGE-MESSAGE2');
    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    confirmDialog.componentInstance.message1 = message[1];
    confirmDialog.componentInstance.message2 = message[2];
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
    confirmDialog.componentInstance.margin = false;
    confirmDialog.componentInstance.options = [{
      text: this._translateService.instant('ACCEPT'),
      callback: () => {
        let tableText = "";
        for (let i = 0; i < this.messageDetails.length; i++) {
          if (this.messageDetails[i].IdUser == messageUser.IdUser) continue;
          tableText += this.messageDetails[i].User.UserName + ', ';
        }
        tableText = tableText.substring(0, tableText.length - 2);
        this.teacherMessageService.removeMessageUser(messageUser.IdMessage, messageUser.IdUser, tableText)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe((response) => {
          let message = [];
          message[0] = this._translateService.instant('SCHOOL.TEACHER.MESSAGES.SUCCESS-DELETE-MESSAGE-TITLE');
          message[1] = this._translateService.instant('SCHOOL.TEACHER.MESSAGES.SUCCESS-DELETE-MESSAGE-MESSAGE');

          let confirmDialog2 = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
          });
          confirmDialog2.componentInstance.title = message[0];
          confirmDialog2.componentInstance.message1 = message[1];
          confirmDialog2.componentInstance.textButton = this._translateService.instant('ACCEPT');
          confirmDialog2.componentInstance.margin = false;
          confirmDialog2.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
            confirmDialog2 = null;
          });
          this.getMessageDetails(this.messageDetails[0].IdMessage);
        }, this.errorCallback.bind(this));
      }
    }, {
      text: this._translateService.instant('CANCEL'),
      callback: () => { }
    }];
    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      result();
      confirmDialog = null;
    });
  }

  sendMessage() {
    this.calling = true;
    let studentIdList = [];
    let classMessage = true;
    let tableText = "";
    for (let i = 0; i < this.students.length; i++)
      if (this.students[i].selected) {
        studentIdList.push(this.students[i].Id);
        tableText += this.students[i].UserName + ', ';
      } else {
        classMessage = false;
      }
    if (classMessage)
      tableText = this.selectedClassName;
    else
      tableText = tableText.substring(0, tableText.length - 1);
    this.teacherMessageService.addTeacherMessage(this.messageText, tableText, studentIdList)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successSent.bind(this), this.errorCallbackSent.bind(this));
  }

    formatDate(date) {
        return moment(date).format("DD/MM/YYYY");
    }

    showMsgCut(text, len) {
        let result = text.replace(/<br\s*[\/]?>/gi, " ");
        if (result.length > len) return result.substring(0, len) + "...";
        return result;
    }

    showMsgCutAll(text) {
        let result = text.replace(/<br\s*[\/]?>/gi, " ");
        return result;
    }

  showMsgFull(msg) {
    let message = [];
    message[0] = msg;
    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
    confirmDialog.componentInstance.margin = false;
    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      confirmDialog = null;
    });
  }

    successLoadMessages(response) {
        this.sentMessages = response;
    }

  successSent() {
    this.calling = false;
    let message = [];
    message[0] = this._translateService.instant('SCHOOL.TEACHER.MESSAGES.SEND-MESSAGE-TITLE');
    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
    confirmDialog.componentInstance.margin = false;
    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      confirmDialog = null;
    });
    for (let i = 0; i < this.students.length; i++) this.students[i].selected = false;
    this.messageText = null;
    this.allSelected = false;
  }

  successGetClasses(response) {
    this.classes = response;
    if (this.classes.length == 0) {
      this.router.navigateByUrl("school/students")
    } else {
      this.classes.sort(function (a, b) {
        if (a.Level === b.Level) {
          if (a.Name > b.Name) return 1;
          else if (a.Name < b.Name) return -1;
          else return 0;
        }
        if (a.Level > b.Level) return 1;
        else if (a.Level < b.Level) return -1;
        else return 0;
      });
      this.selectedClassId = this.classes[0].IdClass;
      this.selectedClassName = this.classes[0].Name;
      this.classes[0].selected = true;
      this.userService.getStudentsByParentId(this.currentUser.Id, this.selectedClassId)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successLoad.bind(this), this.errorCallback.bind(this));
    }
  }

  successLoad(response) {
    this.calling = false;
    this.allSelected = false;
    this.students = [];
    for (let i = 0; i < response.length; i++) {
      if (response[i].UserName.toUpperCase().includes(".TMP.") == false) this.students.push(response[i]);
    }
    for (let i = 0; i < this.students.length; i++) {
      if (this.students[i].Id == this.selectedIdUser) {
        if (this.students[i].AuthUser != null)
          this.selectedAuthUser = true;
        else
          this.selectedAuthUser = false;
      }
    }
    if (!this.authUsers) {
      this.calling = true;
      this.userService.GetAuthorizedStudentsByParentId(this.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successLoadAuth.bind(this), this.errorCallback.bind(this));
    } else {
      this.dataLoaded = true;
    }
  }

    successLoadAuth(response) {
        this.calling = false;
        if (response) {
            this.authUsers = response;
            if (this.authUsers.length != 0) {
                for (let i = 0; i < this.authUsers.length; i++) {
                    this.authUsers[i].AuthUser = true;
                }
            }
            let noClasses = false;
            if (this.currentUser.IdSchool) {
                if (this.classes == null) {
                    this.classes = [];
                    noClasses = true;
                }
                if (this.authUsers != null && this.authUsers.length > 0)
                    this.classes.push({ Name: "Autorizados", IdClass: -1 });
            }
            if (!this.currentUser.IdSchool || noClasses) {
                for (let i = 0; i < this.authUsers.length; i++)
                    this.students.push(this.authUsers[i]);
            }
        }
        this.dataLoaded = true;
    }

  errorCallback() {
    this.calling = false;
    this.router.navigateByUrl("school/students")
  };
  errorCallbackSent() {
    this.calling = false;
    let message = [];
    message[0] = this._translateService.instant('SCHOOL.TEACHER.MESSAGES.ERROR-SEND-MESSAGE-TITLE');
    message[1] = this._translateService.instant('SCHOOL.TEACHER.MESSAGES.ERROR-SEND-MESSAGE-MESSAGE');
    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    confirmDialog.componentInstance.message1 = message[1];
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
    confirmDialog.componentInstance.margin = false;
    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
        confirmDialog = null;
        this.attachment = null;
        this.form.reset()
    });
  }

  submitForm() {
    this.sendingEmail = true;
    this.userService.sendInfoEmail(this.authService.currentUser.Id, this.form.get('body').value, this.form.get('subject').value, this.attachment ? this.attachment : null)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(x =>{
        this.okCallback();
        this.sendingEmail = false;
    },error => {
        this.errorCallback();
    });
  };

  okCallback() {
    this.sendingEmail = false
    var message = []
    message[0] = this._translateService.instant('SCHOOL.TEACHER.MESSAGES.SUCCESS-SEND-MESSAGE-TITLE')
    message[1] = this._translateService.instant('SCHOOL.TEACHER.MESSAGES.SUCCESS-SEND-MESSAGE-MESSAGE')
    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    })
    confirmDialog.componentInstance.title = message[0]
    confirmDialog.componentInstance.message1 = message[1]
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
    confirmDialog.componentInstance.margin = false
    confirmDialog.componentInstance.margin = false
    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
        .subscribe(result => {
        this.attachment = null;
        confirmDialog = null;
        this.form.reset()
    })
    
  }
  saveFile(event){
    if(event.target.files[0].size > 2097152){
        this.BigFile = true;
        return;
    }
    this.BigFile = false;
    this.attachment = event.target.files[0]
  }
}
