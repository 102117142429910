import { Component, Input, OnInit, OnDestroy, HostListener, ViewEncapsulation } from '@angular/core';
import { ExerciseService } from 'app/services/exercise.service';
declare var $, wordfindgame

@Component({
  selector: 'ex-sopa',
  templateUrl: './sopa.component.html',
  styleUrls: ['./sopa.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SopaComponent implements OnInit, OnDestroy {

  @Input() review;
  @Input() show_errors;
  @Input() controlSession : boolean = false;
  @Input() slide : boolean = false; @Input() current;
  exercise;
  words = []
  draggingOption;
  exerciseIsEnded = false;
  finished = false;
  data_loaded = false

  puzzle;
  gamePuzzle
  muestra
  reloj

  elementsOk;
  elementsErr;
  numero_random = Math.floor(Math.random() * (10000 - 0 + 1) + 0)
 

    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case "SHOW_SOLUTION":
                this.finished = true;
                if (this.exerciseService.showingSolution) {
                    setTimeout(this.showAnswersTimeOut.bind(this), 100);
                    break;
                } else {
                    setTimeout(this.showSolution.bind(this), 100);
                    break;
                }
            case "SHOW_ANSWER":
            case "SHOW_CUSTOM":
                this.ShowAnswer();
                break;
            case "SAVE_ANSWER":
                $('li.word').each((index, element) => {
                    if (index >= 0) {
                      if ($(element).hasClass('wordFound')) {
                        this.exerciseService.answer(index, true, this.words.length)
                      }
                    }
                  });
                break;
        }
    }
  
  constructor(private exerciseService: ExerciseService) {
    this.exercise = this.exerciseService.getExerciseDefinition();
    this.FillExercise();
  }
    ngOnDestroy() {
    }

  ngOnInit() {
    if (this.review) {
      this.elementsOk = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsOK
      );
      this.elementsErr = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsERR
      );
      if (this.show_errors == false && this.elementsOk.length != this.exercise.palabras.length) {
        this.showSolution();
      } else if (this.show_errors == true || this.show_errors == null) {
        this.ShowAnswer();
        this.showSolution()
      } else if (this.show_errors == false && this.elementsOk.length == this.exercise.palabras.length){
            this.showHourGlassAnswer();
      }
    } else {

    }
  }

  FillExercise() {
    for (var j = 0; j < this.exercise.palabras.length; j++) {
      this.words.push(this.exercise.palabras[j].palabra);
    }

    if (this.exercise.muestra) {
      this.muestra = true;
    }


    var orientaciones = ['horizontal', 'horizontalBack', 'vertical', 'verticalUp',
      'diagonal', 'diagonalUp', 'diagonalBack', 'diagonalUpBack'];



    //Según el parámetro ‘level’ también daremos más dificultad al ejercicio:
    //1.	Opción 1: muestra solo palabras en horizontal y vertical
    //2.	Opcion2: palabras en horizontal y vertical de izda a derecha y de derecha a izquierda
    //3.	Opción 3: muestra también palabras en diagonal de arriba a abajo
    //4.	Opción 4: muestra las dos anteriores y palabras al revés(abajo arriba, derecha izquierda y diagonal de abajo a arriba)

    if (this.exercise.level == 1) {
      orientaciones = ['horizontal', 'vertical'];
    }

    if (this.exercise.level == 2) {
      orientaciones = ['horizontal', 'vertical', 'diagonal'];
    }

    if (this.exercise.level == 3) {
      orientaciones = ['horizontal', 'horizontalBack', 'vertical', 'verticalUp',
        'diagonal'];
    }

    if (this.exercise.level == 4) {
      orientaciones = ['horizontal', 'horizontalBack', 'vertical', 'verticalUp',
        'diagonal', 'diagonalUp', 'diagonalBack', 'diagonalUpBack'];
    }

    var opciones = { height: this.exercise.size, width: this.exercise.size, fillBlanks: true, orientations: orientaciones };

    setTimeout(() => {
      this.gamePuzzle = wordfindgame.create(this.words, '#juego' + this.numero_random, '#Palabras' + this.numero_random, opciones);
      wordfindgame.pre_solve(this.gamePuzzle, this.words);
      this.reloj = setInterval(() => {
        if (this.finished) {
          clearInterval(this.reloj);
          return;
        }
          $('li.word').each((index, element) => {
            if (index >= 0) {
              if ($(element).hasClass('wordFound')) {
                this.exerciseService.answer(index, true, this.words.length)
              }
            }
          });
          this.finished = true;
          clearInterval(this.reloj);
      }, 100);
      this.data_loaded = true
    }, 100)

  }

  buscar_index_palabra(palabra) {
    for (var i = 0; i < this.exercise.palabras.length; i++) {
      if (this.exercise.palabras[i].palabra == palabra) {
        return i;
      }
    }
    return -1;
  }

  showSolution() {
    setTimeout(() => {
      wordfindgame.finishGame();
      wordfindgame.solve(this.gamePuzzle, this.words, this.review);
    }, 1000)
  }

  ShowAnswer() {
    setTimeout(() => {
      $('.solved').removeClass('solved');
      wordfindgame.finishGame();
      wordfindgame.answered(this.gamePuzzle, this.words, this.elementsOk);
    }, 1000)
  }
  showHourGlassAnswer() {
    setTimeout(() => {
        $('.solved-review').removeClass('solved-review');
        wordfindgame.finishGame();
        wordfindgame.answered(this.gamePuzzle, this.words, this.elementsOk, this.review);
      }, 1000)
  }
  showAnswersTimeOut() {
    $('li.word').each((index, element) => {
      // var val = $(element).text();
      // var index = this.buscar_index_palabra(val);
      if ($(element).hasClass('wordFound')) {
        // exerciseService.AddOk(index);
        //this.exerciseService.answer(index, true, this.words.length)
      } else {
        // this.$emit("error");
        // exerciseService.AddError(index);
        //this.exerciseService.answer(index, false, this.words.length)
      }
    });
    wordfindgame.finishGame();
    wordfindgame.solve(this.gamePuzzle, this.words, this.review);
  }

}
