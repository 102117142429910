<div class="walinwa-box walinwa-box-green" fxNgClass.gt-sm="scroll_vertical" *ngIf="dataLoaded">
    <div class="walinwa-box-header">
        {{'SCHOOL.NEW-STUDENT-TITLE' | translate}}
    </div>
    <form class="walinwa-box-content" fxLayout="column" fxLayoutAlign="stretch" [formGroup]="newUserForm"
        *ngIf="newUserForm" autocomplete="new-password">
        <div fxLayout="column" fxLayoutAlign="stretch" class="spe_gap">
            <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="spe_gap">
                <div fxFlex="" fxFlex.lt-md="100%">
                    <mat-form-field>
                        <label class="font_blue">{{'SCHOOL.SCHOOL-COURSE' | translate }}</label>
                        <mat-select formControlName="Level">
                            <mat-option class="scrollable" [value]="course.Level" *ngFor="let course of courses | sortBy:'asc':'Level'">{{ course.Name }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="newUserForm.get('Level').hasError('required')">
                            {{'FIELD-REQUIRED' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="" fxFlex.lt-md="100%">                    
                    <mat-form-field class="less_top_margin_field two">
                        <label class="font_blue">{{'BIRTHDATE' | translate }}</label>
                        <input matInput [matDatepicker]="picker" [max]="maxDate" formControlName="DateOfBirth">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="newUserForm.get('DateOfBirth').hasError('required')">
                            {{'FIELD-REQUIRED' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="spe_gap">
                <div fxFlex="" fxFlex.lt-md="100%" class="">
                    <mat-form-field>
                        <label class="font_blue">{{'NAME' | translate}}</label>
                        <input maxlength="41" formControlName="Name" matInput type="text" class="">
                        <mat-error *ngIf="newUserForm.get('Name').hasError('required')">
                            {{'FIELD-REQUIRED' | translate}}
                        </mat-error>
                        <mat-error *ngIf="newUserForm.get('Name').hasError('pattern')">
                            {{'PATTERN-ERROR' | translate}}
                        </mat-error>
                        <mat-error *ngIf="newUserForm.get('Name').hasError('maxlength')">
                            {{'NAME-MAX' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="" fxFlex.lt-md="100%" class="">
                    <mat-form-field>
                        <label class="font_blue">{{'FAMILYNAME' | translate}}</label>
                        <input maxlength="51" formControlName="FamilyName" matInput type="text" class="">
                        <mat-error *ngIf="newUserForm.get('FamilyName').hasError('required')">
                            {{'FIELD-REQUIRED' | translate}}
                        </mat-error>
                        <mat-error *ngIf="newUserForm.get('FamilyName').hasError('pattern')">
                            {{'PATTERN-ERROR' | translate}}
                        </mat-error>
                        <mat-error *ngIf="newUserForm.get('FamilyName').hasError('maxlength')">
                            {{'FAMILYNAME-MAX' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="spe_gap">
                <div fxFlex="" fxFlex.lt-md="100%">
                    <mat-form-field>
                        <label class="font_blue">{{'USERNAME' | translate}}</label>
                        <input maxlength="25" formControlName="Username" matInput type="text" class="" autocomplete="off">
                        <mat-error *ngIf="newUserForm.get('Username').hasError('required')">
                            {{ 'FIELD-REQUIRED' | translate }}
                        </mat-error>
                        <mat-error *ngIf="newUserForm.get('Username').hasError('unique')">
                            {{'AUTHENTICATION.ERROR.USERNAME' | translate}}
                        </mat-error>
                        <mat-error *ngIf="newUserForm.get('Username').hasError('minlength') || newUserForm.get('Username').hasError('minlength')">
                            {{'USERNAME-MIN-MAX' | translate }}
                        </mat-error>
                        <mat-error *ngIf="newUserForm.get('Username').hasError('pattern')">
                            {{'PATTERN-ERROR' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="" fxFlex.lt-md="100%">
                    <mat-form-field>
                        <label class="font_blue">{{'SCHOOL.INITIAL-PASSWORD' | translate}}</label>
                        <input maxlength="10" formControlName="Password" matInput class=""  style="background-color: transparent !important; padding-left: 0px !important;"
                            [type]="showPassword1 ? 'text' : 'password'" autocomplete="off">
                        <mat-icon [svgIcon]="showPassword1 ? 'gafas_on' : 'gafas_off'" (click)="showPassword1 = !showPassword1" style="position: absolute; top:20px; right: 0px;">
                        </mat-icon>
                        <mat-error *ngIf="newUserForm.get('Password').hasError('required')">
                            {{'FIELD-REQUIRED' | translate }}
                        </mat-error>
                        <mat-error *ngIf="newUserForm.get('Password').hasError('length')">
                            {{ 'PASSWORD-LENGTH' | translate }}
                        </mat-error>
                        <mat-error *ngIf="newUserForm.get('Password').hasError('characters')">
                            {{'SCHOOL.PARENT.STUDENTS.NEW-STUDENT.PASSWORD-INVALID' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center" class="spe_gap">
            <button [disabled]="calling || newUserForm.invalid" (click)="saveUser()" class="new_but azul">
                {{'SAVE' | translate}}
            </button>
        </div>

    </form>

</div>