<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="boxes3 spe_gap" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" fxLayout="column" fxLayoutAlign="stretch">

    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div class="wordsContainer">
        <div class="items_container spe_gap" fxLayout="column" fxLayoutAlign="stretch">
            <div fxFlex="" id="TextContainer" class="wordsDrag">
                <div *ngFor="let palabra of exercise.palabras; let i = index" id="{{ i }}" [attr.validation]="palabra.opcion" [ngClass]="{'wordError' : palabra.answered == false, 'word0': palabra.answered == true && palabra.opcion == boxesOption[0], 'word1': palabra.answered == true && palabra.opcion == boxesOption[1],'word2': palabra.answered == true && palabra.opcion == boxesOption[2],'wordBox-cs': controlSession }" class="wordBox draggable">
                    <span class="placeholderTextDiv">{{ palabra.palabra }}</span>
                </div>
            </div>
            <div fxFlex="10rem" class="boxesContainer spe_gap" id="space-for-boxes" fxLayout="row" fxLayoutAlign="space-between stretch">
                <div fxFlex="" *ngFor="let box of exercise.boxes; let i = index" id="boxContainer{{ i }}" [attr.idBox]="box.valor" class="box box{{ i }} dropzone">
                    <span>{{ box.descripcion }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  -->
<!-- <div class="boxes3" [ngStyle]="{'pointer-events': review ? 'none;' : ''}">   <h2 *ngIf="controlSession" class="doc-number">{{current + 1}}</h2>

    <div *ngIf="!controlSession" class="exerciseTheme">
        <h1 [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></h1>
    </div>

    <div class="text-center exerciseTitle"  [ngClass]="{'model-title': controlSession,'exerciseTitle-slide': slide}">
        <p   [innerHtml]="exercise.enunciado"></p>
    </div>    

    <div id="space-for-text" [ngClass]="{'wordsContainer': !slide, 'words-container-slide': slide}" [ngStyle]="controlSession ? {'margin-top': '1em;'} : {}">
        <div id="TextContainer" class="text-center">
            <div *ngFor="let palabra of exercise.palabras; let i = index" id="{{ i }}" [attr.validation]="palabra.opcion" [ngClass]="{'wordError' : palabra.answered == false, 'word0': palabra.answered == true && palabra.opcion == boxesOption[0], 'word1': palabra.answered == true && palabra.opcion == boxesOption[1],'word2': palabra.answered == true && palabra.opcion == boxesOption[2],'wordBox-cs': controlSession }" class="wordBox draggable">
                <p [ngStyle]="controlSession && !slide? {'font-size': '1.7em;'} : {}" class="placeholderTextDiv">{{ palabra.palabra }}</p>
            </div>
        </div>
    </div>

    <div class="boxesContainer" id="space-for-boxes" [ngStyle]="controlSession && !slide ? {'margin-top': '2em;'} : {}">
        <div *ngFor="let box of exercise.boxes; let i = index" id="boxContainer{{ i }}" [attr.idBox]="box.valor" class="box box{{ i }} dropzone">
            <p [ngStyle]="controlSession ? {'margin': '2em 1em;', 'font-size': '1.6em;'} : {'margin': '0 !important;'}">{{ box.descripcion }}</p>
        </div>
    </div>

</div> -->