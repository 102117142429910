<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="pasap spe_gap" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" fxLayout="column"
    fxLayoutAlign="stretch">

    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div class="wordsContainer">
        <div class="items_container spe_gap" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch"
            fxLayoutAlign.lt-md="stretch">
            <!--ROSCO-->
            <div fxFlex="" fxFlex.lt-md="0 0 auto" [hidden]="showResult" class="contenedor_rosco" fxLayout="column" fxLayoutAlign="stretch">
                <div class="definicion" [style]="defFondo()">
                    {{ exercise.palabras[index_letra_actual]?.def }}
                </div>
                <div fxFlex="100%" class="cont_ltra_actual">
                    <div class="rosco" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Exercises/Pasap/pasapalabra.gif?ngsw-bypass=true&quot;)'"></div>
                    <div class="letra_actual"><span [style]="color()">{{
                            exercise.palabras[index_letra_actual].letra}}</span>
                    </div>
                </div>
            </div>
            <!--ROSCO-->
            <!--PALABRAS-->
            <div fxFlex="20rem" fxFlex.lt-md="0 0 auto" [hidden]="showResult" class="palabras">
                <div (click)="validar(palabra)" [style]="palFondo(palabra)"
                    [ngClass]="{'bien': palabra.answered == true,'mal': palabra.answered == false}" class="palabra"
                    *ngFor="let palabra of exercise.palabras[index_letra_actual].opciones; let i = index">
                    <span>{{ palabra.palabra }}</span>
                </div>
            </div>
            <!--PALABRAS-->
            <div [hidden]="!showResult" id="columnResult" class="wordsResult"
                [ngClass]="{ 'element-hidden': showResult == false }">
                <div *ngFor="let item of exercise.result" class="wordBox wordBoxResult" id="result{{ i }}"
                    [ngClass]="{ wordBoxOk: item.answered == true, wordBoxError: item.answered == false, wordBoxNull: item.answered == null}">
                    <span [innerHtml]="replaceI(item.word)"></span>
                </div>
            </div>
        </div>
    </div>
</div>