<section fxLayout="column" fxLayoutAlign="stretch" class="spe_gap">
    <div>
        <div class="walinwa-box walinwa-box-purple">

            <cal-hearing-text class="calendario hearing walCalendar ww_customCalendar_two" [ngClass]="{'sixWeeks': sixWeeks() }" [parent]="this" [fullDay]="isMobile == false ? true : false" [hearingTexts]="hearingTexts || []" [currentUser]="currentUser" [selectedDay]="selectedDay" [currentMonth]="currentMonth">
            </cal-hearing-text>
        
            <div class="ww_footi_cal" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="0.5rem">
                <div fxHide fxShow.gt-xs>
                    <i class="fas fa-asterisk asterisco fa-sm"></i> <span class="texto_asterisco">{{'STUDENT.HEARING-TEXT.AVAILABLE-SCHEDULE-ADVICE' | translate }}</span>
                </div>

                <tooltip fxShow.xs fxHide.gt-xs id="stateSession"
                    info='Puedes hacer los ejercicios de comprensión auditiva  todos los lunes.'
                    color="excl" position=""></tooltip>
        
                <div>
                    <button *ngIf="hearingTexts && finished()" [disabled]="!canPlay() || loading || isFuture" (click)="play(true)" class="new_but azul">{{'STUDENT.TEXT-EXERCISES.CHECK-EXERCISE-BUTTON' | translate }}</button>
            
                    <button *ngIf="hearingTexts && !finished() && !started()" [disabled]="loading || isFuture" [hidden]="!canDoIt" (click)="play(false)" class="new_but azul">{{'STUDENT.TEXT-EXERCISES.START-EXERCISE-BUTTON' | translate }}</button>

                    <button *ngIf="hearingTexts && !finished() && started()" [disabled]="loading || isFuture" [hidden]="!canDoIt"
                        (click)="play(false)" class="new_but azul">{{'STUDENT.TEXT-EXERCISES.END-EXERCISE-BUTTON' | translate }}</button>
                </div>
            </div>
        
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="stretch">
        <daily-text [color]="'var(--fucsia)'"></daily-text>
    </div>
</section>
<ng-template #template>
    <comprehensions-tabs></comprehensions-tabs>
</ng-template>
<router-outlet></router-outlet>