
<section fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutAlign.lt-md="stretch"
    class="spe_gap">
        <div fxFlex="" fxNgClass.gt-sm="scroll_vertical">
            <div class="ww_table_rwd">
                <table class="walinwa-table">
                    <thead>
                        <tr>
                            <th style="width: 25%;">{{'NAME' | translate}}</th>
                            <th style="width: 25%;">{{'USERNAME' | translate}}</th>
                            <th style="width: 25%;">{{'SCHOOL.COURSE' | translate}}</th>
                            <th style="width: 25%;">{{'SCHOOL.PARENT.STUDENTS.LICENSE-VALIDITY' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr [ngClass]="{'selected' : selectedIdUser == student.Id, 'disabled': student.Activated == false}"
                        *ngFor="let student of students" (click)="selectUser(student.Id)"
                        class="">
                        <td>{{ student.FamilyName }}{{ getComa(student)}} {{ student.Name }}</td>
                        <td>{{ student.UserName }}</td>
                        <td>{{ student.Course.Name }}</td>
                        <td>{{ student.LicenseStatus }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div fxFlex="12rem" fxFlex.lt-md="0 0 auto" fxNgClass.gt-sm="scroll_vertical">
            <div class="ww_sidebar_inner">
                <div (click)="goEditStudent()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="center"
                    fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap">
                    <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                        <img src="https://walinwa.blob.core.windows.net/images/School/ico-edit-alum.svg?ngsw-bypass=true)" />
                    </div>
                    <div fxFlex="" [innerHTML]="'SCHOOL.EDIT-STUDENT-TITLE' | translate"></div>
                </div>

                <div (click)="license()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="center"
                    fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap" *ngIf="!selectedAuthUser">
                    <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                        <img src="https://walinwa.blob.core.windows.net/images/School/ico-licencia.svg?ngsw-bypass=true" />
                    </div>
                    <div fxFlex="" [innerHTML]="'SCHOOL.PARENT.STUDENTS.LICENSE-TITLE' | translate"></div>
                </div>

                <div (click)="goSessionReview()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="center"
                    fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap">
                    <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                        <img src="https://walinwa.blob.core.windows.net/images/School/ico-rev-activi.svg?ngsw-bypass=true" />
                    </div>
                    <div fxFlex="" [innerHTML]="'SCHOOL.REVIEW-ACTIVITIES-TITLE' | translate"></div>
                </div>

                <div (click)="resetStudentPassword()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="center"
                    fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap">
                    <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                        <img src="https://walinwa.blob.core.windows.net/images/School/ico-reini-contra.svg?ngsw-bypass=true" />
                    </div>
                    <div fxFlex="" [innerHTML]="'SCHOOL.RESET-PASSWORD-TITLE' | translate"></div>
                </div>

                <div (click)="goAuthTutor()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="center"
                    fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap" *ngIf="!selectedAuthUser">
                    <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                        <img src="https://walinwa.blob.core.windows.net/images/School/ico-auto-tutor.svg?ngsw-bypass=true" />
                    </div>
                    <div fxFlex="" [innerHTML]="'SCHOOL.AUTH-TUTOR-TITLE' | translate"></div>
                </div>

                <div (click)="deleteUser()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="center"
                    fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap" *ngIf="!selectedAuthUser">
                    <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                        <img src="https://walinwa.blob.core.windows.net/images/School/ico-elim-alum.svg?ngsw-bypass=true" />
                    </div>
                    <div fxFlex="" [innerHTML]="'SCHOOL.DELETE-STUDENT-TITLE' | translate"></div>
                </div>

                <div (click)="addUser()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="center"
                    fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap" [ngClass]="{ 'iPad_iPhone_margin': isIpadIphone }">
                    <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                        <img src="https://walinwa.blob.core.windows.net/images/School/ico-add-alum.svg?ngsw-bypass=true" />
                    </div>
                    <div fxFlex="" [innerHTML]="'SCHOOL.ADD-STUDENT-TITLE' | translate"></div>
                </div>
            </div>
        </div>
    </section>
<img [hidden]="!loading" style="width: 10em; position: fixed;top: 0;left: 0;bottom: 0;right: 0;margin: auto;" src="https://walinwa.blob.core.windows.net/images/loading.gif?ngsw-bypass=true">
