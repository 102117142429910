import { AvatarShopService } from 'app/services/avatar-shop.service';
import { forkJoin } from 'rxjs';
import { SharedService } from 'app/core/shared/shared.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { AuthService } from 'app/services/auth.service';
import { CivilizationService } from 'app/services/civilization.service';
import { HistoryStageService } from 'app/services/historyStage.service';
import { NavigationService } from 'app/services/navigation.service';
import { ShopService } from 'app/services/shop.service';
import { UserService } from 'app/services/user.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { ShopItemUser } from 'app/core/shared/state';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GamesComponent implements OnInit, OnDestroy{
    private _unsubscribeAll: Subject<any>;

  currentUser;
  isPopup = false;
  buying = false;
  dataLoaded = false;
  hideCivilization = false;
  civilizations = null;
  userHistoryStages = null;
  newBougth = false;
  currentCivilizationIndex = 0;
  currentStageIndex = 0;
  calling
  boughtItems
  overlay = document.getElementById("overlay");
  popup = document.getElementById("popup");
  fileUrl = "https://walinwa.blob.core.windows.net/images/Themes/"
  ButtonIsPressed = false;

  private _newGames: boolean = false;

  constructor(
    private authService: AuthService,
    private navigationService: NavigationService,
    private userService: UserService,
    private shopService: ShopService,
    private historyStageService: HistoryStageService,
    private civilizationService: CivilizationService,
    private sanitizer: DomSanitizer,
    private _matDialog: MatDialog,
    private avatarShopService: AvatarShopService,
    public sharedService: SharedService,
    private _translateService: TranslateService
  ) { 
    this._unsubscribeAll = new Subject();
}

ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  
  ngOnInit() {
    if (!this.authService.check()) return;
    this.currentUser = this.authService.currentUser;
    this._newGames = moment(JSON.parse(sessionStorage.getItem("currentUser")).CreationDate) > moment("2024-05-31");
    this.successCallbackLoadStudent();

  }

  getCivilizationStyle() {
    return this.sanitize("url('" + this.fileUrl + this.civilizations[this.currentCivilizationIndex].ImgFolder + "/StageBackground.png')");
  }
    changeBackground(ButtonIsPressed: boolean) {
        this.ButtonIsPressed = ButtonIsPressed;
    }
    getButtonClass(): string {
        if (this.civilizations == null)
            return "hidden";
        if (this.civilizations[this.currentCivilizationIndex].HistoryStages == null)
            return "hidden";
        if (this.civilizations[this.currentCivilizationIndex].HistoryStages[this.currentStageIndex].Name === 'fin' && this.currentUser.IdSchool === null) {
            return "hidden";
        }
        if (this.civilizations[this.currentCivilizationIndex].HistoryStages[this.currentStageIndex].Walinwos === 0)
            return "hidden";
        if (this.calling) {
            return 'disabledButton  buyButton_' + this.currentCivilizationIndex + '_' + this.currentStageIndex;
        }
        if (this.civilizations[this.currentCivilizationIndex].HistoryStages[this.currentStageIndex].Active == false && !this.civilizations[this.currentCivilizationIndex].HistoryStages[this.currentStageIndex].bought) {
            return 'EsButton disabledButton  buyButton_' + this.currentCivilizationIndex + '_' + this.currentStageIndex;
        }
        if (this.civilizations[this.currentCivilizationIndex].HistoryStages[this.currentStageIndex].enabled == false
            || this.civilizations[this.currentCivilizationIndex].HistoryStages[this.currentStageIndex].bought) {
            return "hidden";
        }


        if (this.civilizations != null && this.currentUser.Coins < this.civilizations[this.currentCivilizationIndex].HistoryStages[this.currentStageIndex].Walinwos) {
            return 'disabledButton jumpButton buyButton_' + this.currentCivilizationIndex + '_' + this.currentStageIndex;
        }


        return 'jumpButton buyButton_' + this.currentCivilizationIndex + '_' + this.currentStageIndex;
    }

  isButtonDisabled() {
      return this.getButtonFinishClass().includes('hidden');
  }

  getButtonFinishClass() {
    if (this.civilizations == null || this.currentCivilizationIndex == null || this.currentStageIndex == null || this.civilizations[this.currentCivilizationIndex].HistoryStages == null)
        return "hidden";
    if (this.civilizations[this.currentCivilizationIndex].HistoryStages[this.currentStageIndex].Name === 'FIN' || this.civilizations[this.currentCivilizationIndex].HistoryStages[this.currentStageIndex].Name === 'A FIN') {
      if (this.currentUser.IdSchool === null) {
        return 'buyButton_' + this.currentCivilizationIndex + '_' + this.currentStageIndex;
      }
    }
    return "hidden";
  }

  getButonGameBackClass() {
    if (this.civilizations == null)
      return "hidden";
    if (this.civilizations[this.currentCivilizationIndex].HistoryStages == null)
      return "hidden";
    if (this.civilizations[this.currentCivilizationIndex].HistoryStages[0].enabled == false
      || this.civilizations[this.currentCivilizationIndex].HistoryStages[0].bought == false)
      return "hidden";

    return 'gameButtonBack' + this.currentCivilizationIndex;
  }
  getButonGameBackImg() {
    if (this.civilizations == null)
      return "hidden";
    if (this.civilizations[this.currentCivilizationIndex].HistoryStages == null)
      return "hidden";
    if (this.civilizations[this.currentCivilizationIndex].HistoryStages[0].enabled == false
      || this.civilizations[this.currentCivilizationIndex].HistoryStages[0].bought == false)
      return "hidden";
    
    let url = this.fileUrl + this.civilizations[this.currentCivilizationIndex].ImgFolder + "/";
    if(!this.ButtonIsPressed){
        url += "buttonBack1.gif"
    }
    else {
        url += "buttonBack2.gif"
    }
    return {
        "background-image": " url(" + url + ")",
    };
  }
  getButonGameClass() {
    if (this.civilizations == null)
      return "hidden";
    if (this.civilizations[this.currentCivilizationIndex].HistoryStages == null)
      return "hidden";
    if (this.civilizations[this.currentCivilizationIndex].HistoryStages[0].enabled == false
      || this.civilizations[this.currentCivilizationIndex].HistoryStages[0].bought == false)
      return "hidden";

    return 'gameButton' + this.currentCivilizationIndex;
  }
  getStageClass(index){
    return 'stage_' + this.currentCivilizationIndex + '_' + index;
  }
  getStageStyle(index) {
    let partialImageName = this.currentCivilizationIndex == 0 || (this.currentCivilizationIndex == 4 && this._newGames) ? '_new' : '';
    if(this.currentCivilizationIndex == 4 && environment.production){
        partialImageName = ''
    }
    var css;
    if (this.civilizations[this.currentCivilizationIndex].HistoryStages[index].enabled == false) {
      if (this.currentCivilizationIndex == 6 && index == 5 && this.currentUser.IdSchool != null)
        {
            return css = this.sanitize("url('" + this.fileUrl + this.civilizations[this.currentCivilizationIndex].ImgFolder + "/Stage"+ index + "bSchool.png')");
        }
        return css = this.sanitize("url('" + this.fileUrl + this.civilizations[this.currentCivilizationIndex].ImgFolder + "/Stage"+ index + `b${partialImageName}.png')`);
    }
    if (this.civilizations[this.currentCivilizationIndex].HistoryStages[index].bought) {
      if (this.currentCivilizationIndex == 6 && index == 4 && this.currentUser.IdSchool != null)
        return css = this.sanitize("url('" + this.fileUrl + this.civilizations[this.currentCivilizationIndex].ImgFolder + "/Stage"+ index + "School.png')");
      return css = this.sanitize("url('" + this.fileUrl + this.civilizations[this.currentCivilizationIndex].ImgFolder + "/Stage"+ index + `${partialImageName}.png')`);
    }
    if (this.currentCivilizationIndex == 6 && index == 4 && this.currentUser.IdSchool != null)
        return css = this.sanitize("url('" + this.fileUrl + this.civilizations[this.currentCivilizationIndex].ImgFolder + "/Stage"+ index + "nSchool.png')");
      return css = this.sanitize("url('" + this.fileUrl + this.civilizations[this.currentCivilizationIndex].ImgFolder + "/Stage"+ index + `n${partialImageName}.png')`);
  }

  goToGame(game) {
    if(this.getButonGameClass().includes('hidden')){
      return;
    }
    
    if (this.civilizations[game].percentage == null || this.civilizations[game].percentage < 0) return;
    this.navigationService.go(`student/games/${game}`);
  }

  nextCivilization() {
    if (this.newBougth) {
      this.goToCivilization(this.currentCivilizationIndex + 1);
      this.newBougth = false;
    }
    else {
        if (this.civilizations[this.currentCivilizationIndex + 1].enabled) {
            this.goToCivilization(this.currentCivilizationIndex + 1);
          }
    }
    
  }

  etapa_bloqueada(hs) {
    //if (hs && hs.dateOn) {    
    if (hs && hs.PendingSessions) {
            //var date = new Date(hs.dateOn);
      //return "Próxima etapa: <br/> " + date.toLocaleDateString('es-ES');
      if (hs.PendingSessions > 0)
        return this._translateService.instant('STUDENT.GAMES.AVAILABLE-AFTER-SESSIONS',{pendingSessions:Math.abs(hs.PendingSessions)});
      else if (hs.PendingSessions === -2)
        return this._translateService.instant('STUDENT.GAMES.AVAILABLE-AFTER-TOMORROW-SESSION');
      else if (hs.PendingSessions === -1)
        return this._translateService.instant('STUDENT.GAMES.AVAILABLE-AFTER-TODAY-SESSION');
    } else {
      return this._translateService.instant('STUDENT.GAMES.STAGE-BLOCKED');
    }
  }

  resetLevel() {
    if(this.getButtonFinishClass().includes('hidden')){
      return;
    }
    if (this.isButtonDisabled()) {
      return;
    }
    var message = [];
    message[0] = this._translateService.instant('STUDENT.GAMES.NEXT-GRADE.1');
    message[1] = this._translateService.instant('STUDENT.GAMES.NEXT-GRADE.2');
    message[2] = this._translateService.instant('STUDENT.GAMES.NEXT-GRADE.3');
    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    confirmDialog.componentInstance.message1 = message[1];
    confirmDialog.componentInstance.message2 = message[2];
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
    confirmDialog.componentInstance.margin = false;
    confirmDialog.componentInstance.theme = "white";
    confirmDialog.componentInstance.options = [{
      text: this._translateService.instant('STUDENT.GAMES.YES'),
      callback: () => {
        this.calling = true;
        this.userService.updateLevel(this.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.reloadUserFinish.bind(this), this.errorCallback.bind(this));
      }
    }, {
      text: this._translateService.instant('STUDENT.GAMES.NO'),
      callback: () => { }
    }];
    confirmDialog.componentInstance.margin = false;
    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      result();
      confirmDialog = null;
      return;
    });
  }



  buyStage(stage) {
    //TODOJO mirar cuando llega al final de la civi, porque Active se supone que será false (al menos antes)
    if(this.getButtonClass().includes('hidden')){
      return
    }
    if (stage.enabled == false) return;
    if (stage.Active != true) {
      if (stage.lastStage) {
        var message = [];
        message[0] = this._translateService.instant('STUDENT.GAMES.NEXT-CIVILIZATION.1');
        message[1] = this._translateService.instant('STUDENT.GAMES.NEXT-CIVILIZATION.2',{pendingSessions: Math.abs(stage.PendingSessions)});
        message[2] = this._translateService.instant('STUDENT.GAMES.NEXT-CIVILIZATION.3');
        message[3] = this._translateService.instant('STUDENT.GAMES.NEXT-CIVILIZATION.4');
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
          disableClose: true
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.message2 = message[2];
        confirmDialog.componentInstance.message3 = message[3];
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
        confirmDialog.componentInstance.margin = false;
        confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(_ => {
          confirmDialog = null;
        });
        return;
      } else {
        var message = [];
        message[0] = this._translateService.instant('STUDENT.GAMES.NEXT-STAGE-LOCKED.1');
        message[1] = this._translateService.instant('STUDENT.GAMES.NEXT-STAGE-LOCKED.2',{pendingSessions: Math.abs(stage.PendingSessions)});
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
          disableClose: true
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
        confirmDialog.componentInstance.margin = false;
        confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(_ => {
          confirmDialog = null;
        });
        return;
      }
    }
    if (this.currentUser.Coins < stage.Walinwos) {
      var message = [];
      message[0] = "";
      message[1] = this._translateService.instant('STUDENT.GAMES.NOT-ENOUGH-WALIS');
      let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
        disableClose: true
      });
      confirmDialog.componentInstance.title = message[0];
      confirmDialog.componentInstance.message1 = message[1];
      confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
      confirmDialog.componentInstance.margin = false;
      confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(_ => {
        confirmDialog = null;
      });
      return;
    }
    if (this.buying) return;

    var message = [];
    message[0] = this._translateService.instant('STUDENT.GAMES.UNLOCK-STAGE-HEADER');
    message[1] = this._translateService.instant('STUDENT.GAMES.UNLOCK-STAGE-TEXT',{price: stage.Walinwos});
    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    confirmDialog.componentInstance.message1 = message[1];
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
    confirmDialog.componentInstance.margin = false;
    confirmDialog.componentInstance.theme = "white";
    confirmDialog.componentInstance.options = [{
      text: this._translateService.instant('STUDENT.GAMES.YES'),
      callback: () => {
        this.calling = true;
        this.historyStageService.setHistoryStageToUser(this.currentUser.Id, stage.IdHistoryStage)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
          _ => {
            this.reloadUser()
          },
          _ => {
            this.errorCallback()
          }
        )
      }
    }, {
      text: this._translateService.instant('STUDENT.GAMES.NO'),
      callback: () => { }
    }];
    confirmDialog.componentInstance.margin = false;
    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      result();
      confirmDialog = null;
      return;
    });
  }

  exitCivilization() {
    this.hideCivilization = false;

  };
  goToCivilization(civilizationIndex) {
    if (this.civilizations[civilizationIndex].enabled == false)
      return;

    let partialImageName = civilizationIndex == 0 || (civilizationIndex == 4 && this._newGames) ? '_new' : '';
    if(this.currentCivilizationIndex == 4 && environment.production){
        partialImageName = ''
    }
    this.currentCivilizationIndex = civilizationIndex;
    this.hideCivilization = true;
    this.currentStageIndex = 0;
    var civCounter = 0;
    for (var j = 0; j < this.civilizations[civilizationIndex].HistoryStages.length; j++) {
      this.civilizations[civilizationIndex].HistoryStages[j].enabled = this.IsHistoryStageEnabled(this.civilizations[civilizationIndex].HistoryStages[j]);
      this.civilizations[civilizationIndex].HistoryStages[j].bought = this.IsHistoryStageBought(this.civilizations[civilizationIndex].HistoryStages[j]);
        if (j == 0 || j == 4) {
            this.civilizations[civilizationIndex].HistoryStages[j].rewardStyle = this.sanitize("url('" + this.fileUrl + "reward" + j + ".png')");;
        } else {
            this.civilizations[civilizationIndex].HistoryStages[j].rewardStyle = this.sanitize("url('" + this.fileUrl + this.civilizations[civilizationIndex].ImgFolder + "/reward" + j + partialImageName +".png')");
        }
        if (this.civilizations[civilizationIndex].HistoryStages[j].bought)
        civCounter++;
      if (this.civilizations[civilizationIndex].HistoryStages[j].enabled && this.isPopup == false)
        this.currentStageIndex = j;

      if (j + 1 == this.civilizations[civilizationIndex].HistoryStages.length)
        this.civilizations[civilizationIndex].HistoryStages[j].lastStage = true;
      else
        this.civilizations[civilizationIndex].HistoryStages[j].lastStage = false;
    }


  }

  successLoadBoughtItems(result: ShopItemUser[]) {
    this.shopService.ChangeUserBoughtItems(result);
    this.calling = false;
    var availableItems = [];
    var today = new Date();
    result.forEach(userItem => {
        if(userItem.ShopItem.IdCivilization != null && userItem.ShopItem.IdCivilization == this.currentUser.Civilization.IdCivilization){
            availableItems.push(userItem);
        }
        if (new Date(userItem.ShopItem.specialFrom2).getTime() <= today.getTime() && new Date(userItem.ShopItem.specialTo2).getTime() >= today.getTime()) {
            availableItems.push(userItem);
          }
    })
    this.boughtItems = availableItems;
    this.dataLoaded = true;
      this.buying = false;
      if (this.isPopup) {
          this.showPopUp();
        }
  }

  reloadUser() {
      this.newBougth = true;
      this.isPopup = true;

    this.authService.reloadUser(() => {
      this.successCallbackLoadStudent()
    })
  }

  reloadUserFinish(result) {
    this.authService.reloadUser(() => {
      this.successCallbackLoadStudentFinish()
    })
  }

  successCallbackLoadStudentFinish() {
    this.navigationService.go("student/session");
    this.sharedService.changeReloadStudent(true);
    this.avatarShopService.setAvatarStatus(false);
  }

  successCallbackLoadStudent() {
    forkJoin([
        this.civilizationService.getCivilizationsUser(this.authService.currentUser.Id),
        this.historyStageService.getHistoryStagesByUser(this.authService.currentUser.Id),
        this.shopService.getBoughtByIdUser(this.authService.currentUser.Id)
    ]).pipe(takeUntil(this._unsubscribeAll))
        .subscribe((data: any[]) => {
        this.civilizations = data[0];
        this.userHistoryStages = data[1];
        if (this.popup) {
            this.successLoadBoughtItems(data[2] ?? null);
        }
        else {
            this.updateCivilizationsState(data[2] ?? null);
        }
        
    }, () => {
        this.errorCallback();
    })
  }

  sanitize(url) {
    return this.sanitizer.bypassSecurityTrustStyle(url);
  }

    updateCivilizationsState(userBoughtItems: ShopItemUser[] = null) {
        for (var i = 0; i < this.civilizations.length; i++) {
            this.civilizations[i].enabled = this.IsCivilizationEnabled(this.civilizations[i]);
            var civCounter = 0;
            let partialImageName = i == 0 || (i == 4 && this._newGames) ? '_new' : '';
            if(this.currentCivilizationIndex == 4 && environment.production){
                partialImageName = ''
            }
            for (var j = 0; j < this.civilizations[i].HistoryStages.length; j++) {
                this.civilizations[i].HistoryStages[j].enabled = this.IsHistoryStageEnabled(this.civilizations[i].HistoryStages[j]);
                this.civilizations[i].HistoryStages[j].bought = this.IsHistoryStageBought(this.civilizations[i].HistoryStages[j]);
                if (j != 0 && j != 4) this.civilizations[i].HistoryStages[j].rewardStyle = this.sanitize("url('" + this.fileUrl + this.civilizations[i].ImgFolder + "/reward" + j + partialImageName +".png')");
                else this.civilizations[i].HistoryStages[j].rewardStyle = this.sanitize("url('" + this.fileUrl + "reward" + j + ".png')");
                if (this.civilizations[i].HistoryStages[j].bought) {
                    civCounter++;
                }
                if (this.civilizations[i].HistoryStages[j].enabled && this.isPopup == false)
                    this.currentStageIndex = j;
                this.civilizations[i].percentage = 100 * civCounter / this.civilizations[i].HistoryStages.length;
            }
            if (this.civilizations[i].enabled) {
                if (this.civilizations[i].percentage == 100) {
                    this.civilizations[i].style = this.sanitize("url('" + this.fileUrl + this.civilizations[i].ImgFolder + "/CivilizationButton.svg')");
                }
                else {
                    this.civilizations[i].style = this.sanitize("url('" + this.fileUrl + this.civilizations[i].ImgFolder + "/CivilizationButton" + this.civilizations[i].percentage + ".svg')");
                }

            } else {
                this.civilizations[i].style = this.sanitize("url('" + this.fileUrl + "CivilizationButton_bloqueado.svg')");
            }
        }
        this.civilizationService.civilizations = this.civilizations;
        this.successLoadBoughtItems(userBoughtItems);
    }

  errorCallback() {
    this.navigationService.goLogin();
  }

  IsCivilizationEnabled(civilization) {
    for (var i = 0; i < civilization.HistoryStages.length; i++) {
      if (civilization.HistoryStages[i].IdHistoryStage == 1)
        return true;
      if (!this.userHistoryStages)
        return false;

      for (var j = 0; j < this.userHistoryStages.length; j++) {
        if (civilization.HistoryStages[i].IdHistoryStage <= (this.userHistoryStages[j].IdHistoryStage + 1))
          return true;
      }
    }
    return false;
  }

  IsHistoryStageEnabled(historyStage) {

    if (!this.userHistoryStages)
      return false;
    if (historyStage.IdHistoryStage == 1)
      return true;
    for (var j = 0; j < this.userHistoryStages.length; j++) {

      if (historyStage.IdHistoryStage == (this.userHistoryStages[j].IdHistoryStage + 1))
        return true;
    }
    return false;
  }
  IsHistoryStageBought(historyStage) {

    if (!this.userHistoryStages)
      return false;
    for (var j = 0; j < this.userHistoryStages.length; j++) {
      if (this.userHistoryStages[j].IdHistoryStage == historyStage.IdHistoryStage)
        return true;
    }
    return false;
  }

  closePopUp() {
    if(!this.dataLoaded){
        return;
    }
    this.isPopup = false;
    document.getElementById("overlay").className = '';
      document.getElementById("popup").className = '';
      setTimeout(() => {
        if (this.currentStageIndex + 1 < this.civilizations[this.currentCivilizationIndex].HistoryStages.length) this.currentStageIndex = this.currentStageIndex + 1;
        if (this.newBougth) this.nextCivilization();
      }, 500);
  }
    getImgByName(imgName: string) {
        return this.sanitize("url('" + this.fileUrl + imgName + "')");
    }
  showPopUp() {
    document.getElementById("overlay").className = 'show';
    document.getElementById("popup").className = 'show';
  }

}
