import { Component, Input, OnInit, OnDestroy, HostListener, ViewEncapsulation } from '@angular/core';
import { AppService } from 'app/app.service';
import { AuthService } from 'app/core/guards/auth.service';
import { ExerciseService } from 'app/services/exercise.service';
declare var $

@Component({
  selector: 'ex-cruci',
  templateUrl: './cruci.component.html',
  styleUrls: ['./cruci.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CruciComponent implements OnInit, OnDestroy {

  @Input() review
  @Input() show_errors;
  @Input() controlSession : boolean = false;
  @Input() slide : boolean = false; @Input() current;
  exercise;
  finished = false
  draggingElement
  regla = "";
  secretWord = [];
  letters = [{ name: 'a' }, { name: 'b' }, { name: 'c' }, { name: 'd' },
  { name: 'e' }, { name: 'f' }, { name: 'g' }, { name: 'h' }, { name: 'i' },
  { name: 'j' }, { name: 'k' }, { name: 'l' }, { name: 'm' }, { name: 'n' },
  { name: 'ñ' }, { name: 'o' }, { name: 'p' }, { name: 'q' }, { name: 'r' },
  { name: 's' }, { name: 't' }, { name: 'u' }, { name: 'v' }, { name: 'w' },
  { name: 'x' }, { name: 'y' }, { name: 'z' }];
  wordError = false
  errors

  elementsOk
  elementsErr

    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case "SHOW_SOLUTION":
                this.finished = true;
                if (this.exerciseService.showingSolution) {
                    setTimeout(this.ShowAnswersTimeOut.bind(this), 100);
                    break;
                } else {
                    setTimeout(this.ShowSolution.bind(this), 100);
                    break;
                }
            case "SHOW_ANSWER":
            case "SHOW_CUSTOM":    
                this.exercise = this.exerciseService.getExerciseDefinition();
                this.FillWord();
                this.ShowAnswer();
            break;
        }
    }
  isDesktop = false;

  mobileDetect;
  constructor(
    private exerciseService: ExerciseService,
    private appService: AppService
  ) {
    this.exercise = this.exerciseService.getExerciseDefinition();
  }
    ngOnDestroy(){
    }

  ngOnInit() {
    this.addClass();
    this.FillWord();
    this.FillRule();
    this.mobileDetect = this.appService.mobileDetect();
    if (this.review) {
      this.elementsOk = JSON.parse(this.exerciseService.getCurrentExercise().ElementsOK)
      this.elementsErr = JSON.parse(this.exerciseService.getCurrentExercise().ElementsERR)
      if (this.show_errors == false) {
        this.ShowSolution();
      } else if (this.show_errors == true || this.show_errors == null) {
        this.ShowAnswer();
      }
    } else {
     
      this.exerciseService.initInteract({
        onmove: (event) => {
          this.draggingElement = event.target
        },
        drop: (event) => {
          if (this.finished) return;
          var element = this.draggingElement
          var bin = event.target
          for (var j = 0; j < this.exercise.Horizontales.length; j++) {
            for (var i = 0; i < this.exercise.Horizontales[j].word.length; i++) {
              if (this.exercise.Horizontales[j].word[i].solved == false)
                this.exercise.Horizontales[j].word[i].solved = null;
            }
          }
          var idOption = element.getAttribute("idOption");
          var idWord = bin.getAttribute("idWord");
          var idLetter = bin.getAttribute("idLetter");

          if (this.ValidateLetter(idOption, idWord, idLetter)) {
            this.exerciseService.answer(idWord + ";" + idLetter, true, 100);
            this.exercise.Horizontales[idWord].word[idLetter].show = true;
            this.exercise.Horizontales[idWord].word[idLetter].solved = true;
            for (var i = 0; i < this.exercise.Horizontales.length; i++) {
              for (var j = 0; j < this.exercise.Horizontales[i].word.length; j++) {
                if (this.exercise.Horizontales[i].word[j].show == false)
                  return;
              }
            }
            //Exercise finished     
            this.FinishExercise();
          } else {
            // this.$emit("error");
            this.exerciseService.answer(idWord + ";" + idLetter, false, 100);
            this.exercise.Horizontales[idWord].word[idLetter].solved = false;
          }
        }
      });
    }
  }

  getImgClass() {
    return 'step' + this.errors;
  }
    ShowSolution() {
        for (var i = 0; i < this.exercise.Horizontales.length; i++) {
            var currentWord = this.exercise.Horizontales[i].word;
            for (var j = 0; j < currentWord.length; j++) {
                var currentLetter = currentWord[j];
                if (!currentLetter.show || (currentLetter.show && currentLetter.solved == false)) {
                    currentLetter.show = true;
                    currentLetter.solved = true;
                }
            }
        }
    }

    ShowAnswer() {
        for (var i = 0; i < this.exercise.Horizontales.length; i++) {
            var horizontalWord = this.exercise.Horizontales[i].word;
            for (var j = 0; j < horizontalWord.length; j++) {
                var currentLetter = horizontalWord[j];

                currentLetter.solved = null;

                if (this.FindLetterOnOk(i, j)) {
                    currentLetter.show = true;
                    currentLetter.solved = true;
                }

                if (this.FindLetterOnErr(i, j)) {
                    currentLetter.show = false;

                    if (this.show_errors == null) {
                        currentLetter.show = true;
                    }

                    currentLetter.solved = false;
                }
            }
        }
    }

  FindLetterOnErr(wordIndex, letterIndex) {
      let result = false;
      this.elementsErr.forEach(errores => {
        const arr = errores.split(";")
        if (arr[0] == wordIndex && arr[1] == letterIndex){
            return result = true;
        }
      });
     return result;
  }

  FindLetterOnOk(wordIndex : number, letterIndex : number) {
      let result = false;
        this.elementsOk.forEach(aciertos => {
        const arr : number = aciertos.split(";")
        if (arr[0] == wordIndex && arr[1] == letterIndex && result == false){
            return result = true;
        }
      });
     return result;
  }

  FinishExercise() {
    //Exercise Finished
    this.finished = true;
    // this.$emit('timer-stop');
    // this.$emit('exercise-solved');
    this.exerciseService.endExercise()
  }

  getPosClass(item) {
    switch (item.posicion) {
      case "1":
        return "pos1";
        break;
      case "2":
        return "pos2";
        break;
      case "3":
        return "pos3";
        break;
      case "4":
        return "pos4";
        break;
      case "5":
        return "pos5";
        break;
      case "6":
        return "pos6";
        break;
      case "7":
        return "pos7";
        break;
      case "8":
        return "pos8";
        break;
    }
  }

  ValidateLetter(idOption, idWord, letter) {
    if (this.letters[idOption].name == this.exercise.Horizontales[idWord].word[letter].compare)
      return true;
    return false;
  }

  ShowAnswersTimeOut() {
    for (var i = 0; i < this.exercise.Horizontales.length; i++) {
      for (var j = 0; j < this.exercise.Horizontales[i].word.length; j++) {
        this.exercise.Horizontales[i].word[j].show = true;
      }
    }
  }

  FillRule() {
    this.regla = this.exercise.Vertical.definicion + "<br/>";
    for (var i = 0; i < this.exercise.Horizontales.length; i++) {
      this.regla += this.exercise.Horizontales[i].definicion + "<br/>";
    }
  }

  FillWord() {
    this.exercise.Vertical.word = [];
    this.exercise.Vertical.palabra.split("").forEach((element) => {
      this.exercise.Vertical.word.push({ value: element, compare: this.replaceAccent(element) });
    });

    for (var i = 0; i < this.exercise.Horizontales.length; i++) {
      this.exercise.Horizontales[i].idWord = i;
      this.exercise.Horizontales[i].word = [];
      var letters = this.exercise.Horizontales[i].palabra.split("");
      for (var j = 0; j < letters.length; j++) {
        var letraH = letters[j];
        if (this.exercise.nivel == 1) {
          if (this.isVowel(letraH))
            this.exercise.Horizontales[i].word.push({ value: letraH, compare: this.replaceAccent(letraH), show: true, solved: false });
          else
            this.exercise.Horizontales[i].word.push({ value: letraH, compare: this.replaceAccent(letraH), show: false, solved: null });
        } else if (this.exercise.nivel == 2) {
          if (!this.isVowel(letraH))
            this.exercise.Horizontales[i].word.push({ value: letraH, compare: this.replaceAccent(letraH), show: true, solved: false });
          else
            this.exercise.Horizontales[i].word.push({ value: letraH, compare: this.replaceAccent(letraH), show: false, solved: null });
        } else if (this.exercise.nivel == 3) {
          if (this.isComplexLetter(letraH))
            this.exercise.Horizontales[i].word.push({ value: letraH, compare: this.replaceAccent(letraH), show: false, solved: null });
          else {
            if ((letraH == 'l' || letraH == 'L')
              &&
              (
                (j + 1 < letters.length && (letters[j + 1] == 'l' || letters[j + 1] == 'L'))
                || (j - 1 >= 0 && (letters[j - 1] == 'l' || letters[j - 1] == 'L'))
              )
            )
              this.exercise.Horizontales[i].word.push({ value: letraH, compare: this.replaceAccent(letraH), show: false, solved: null });
            else
              this.exercise.Horizontales[i].word.push({ value: letraH, compare: this.replaceAccent(letraH), show: true, solved: false });
          }
        }
        else
          this.exercise.Horizontales[i].word.push({ value: letraH, compare: this.replaceAccent(letraH), show: false, solved: null });
      };
    }
  }

  isVowel(item) {
    var remp = this.replaceAccent(item);
    if (remp == 'a' || remp == 'A' || remp == 'e' || remp == 'E' || remp == 'i' || remp == 'I' || remp == 'o' || remp == 'O' || remp == 'u' || remp == 'U')
      return true;
    return false;
  }

  isComplexLetter(item) {
    var remp = this.replaceAccent(item);
    if (remp == 'x' || remp == 'X' || remp == 'b' || remp == 'B' || remp == 'v' || remp == 'V' || remp == 'g' || remp == 'G' || remp == 'j' || remp == 'J' || remp == 'y' || remp == 'Y' || remp == 'q' || remp == 'Q' || remp == 'h' || remp == 'H' || remp == 'z' || remp == 'Z')
      return true;
    return false;
  }

  replaceAccent(item) {
    item = item.replace(/á/g, "a");
    item = item.replace(/é/g, "e");
    item = item.replace(/í/g, "i");
    item = item.replace(/ó/g, "o");
    item = item.replace(/ú/g, "u");
    item = item.replace(/ü/g, "u");
    item = item.replace(/Á/g, "A");
    item = item.replace(/É/g, "E");
    item = item.replace(/Í/g, "I");
    item = item.replace(/Ó/g, "O");
    item = item.replace(/Ú/g, "U");
    item = item.replace(/Ü/g, "u");
    return item;
  }
    addClass(){
        var ua = navigator.userAgent;
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
            this.isDesktop = false;
        }
        else {
            this.isDesktop = true;
        }
    }

}
