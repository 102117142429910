import { AppService } from '../../../../../app.service';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SchoolService } from 'app/services/school.service';
import { UserService } from 'app/services/user.service';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { AuthService } from 'app/services/auth.service';
import * as moment from 'moment';
import { UntypedFormBuilder } from '@angular/forms';
import { NavigationService } from 'app/services/navigation.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
declare var $;

@Component({
    selector: "app-activities",
    templateUrl: "./activities.component.html",
    styleUrls: ["./activities.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ConfigActivitiesComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

	user;
	classes;
	selectedClass;
	selectedDay;
	currentMonth;
	hasExercise;
	form;
	isIpad: boolean = false;
    enableReading: any;
    enableDeduction: any;
    enableHearing: any;

	constructor(
		private router: Router,
		private authService: AuthService,
		private userService: UserService,
		private schoolService: SchoolService,
		private matDialog: MatDialog,
		private formBuilder: UntypedFormBuilder,
		private navigationService: NavigationService,
		private appService: AppService,
        private _translateService: TranslateService
	) {
        this._unsubscribeAll = new Subject();
		moment.locale('es')
		this.selectedClass = 0;
		this.form = this.formBuilder.group({
			HearEnd: [null],
			HearIni: [null],
			DedEnd: [null],
			DedIni: [null],
			ReadEnd: [null],
			ReadIni: [null],
		});
	}

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit() {
        if (!this.authService.check()) return;
        this.selectedDay = moment();
        this.enableReading = this.schoolService.school.ReadingText;
        this.enableDeduction = this.schoolService.school.DeductionText;
        this.enableHearing = this.schoolService.school.HearingText;
        this.currentMonth = this.selectedDay.month() + 1;
        this.user = this.authService.currentUser;
        if (this.appService.mobileDetect().IsIPad) {
            this.isIpad = true;
        }
        this.getClasses();
    }

	getClasses() {
		this.userService.getClassesByIdTutor(this.user.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
			result => {
				this.classes = result;
				this.classes.sort(function (a, b) {
					return a.Level - b.Level;
				});
				if (!this.selectedClass) this.checkConfig();
				setTimeout(() => {
					$('#select-class').val(this.selectedClass);
				}, 0);
			},
			error => {
				this.navigationService.exit();
			});
	}

    checkConfig() {
        setTimeout(() => {
            this.selectedClass = parseFloat(this.selectedClass.toString());
        }, 100);
        let cls;
        if (this.selectedClass == 0) {
            if (this.sameValues()) {
                cls = this.classes[0];
            } else {
                this.form.patchValue({
                    HearIni: null,
                    HearEnd: null,
                    DedEnd: null,
                    DedIni: null,
                    ReadEnd: null,
                    ReadIni: null,
                });
                return;
            }
        } else {
            cls = this.classes.find((c) => c.IdClass == this.selectedClass);
        }
        this.form.patchValue({
            HearEnd: cls.HearEnd,
            HearIni: cls.HearIni,
            DedEnd: cls.DedEnd,
            DedIni: cls.DedIni,
            ReadEnd: cls.ReadEnd,
            ReadIni: cls.ReadIni,
        });
    }

    sameValues() {
        if (!this.classes) return;
        if (this.classes.length == 0) return;
        let values1 = {
            HearEnd: this.classes[0].HearEnd,
            HearIni: this.classes[0].HearIni,
            DedEnd: this.classes[0].DedEnd,
            DedIni: this.classes[0].DedIni,
            ReadEnd: this.classes[0].ReadEnd,
            ReadIni: this.classes[0].ReadIni,
        };
        for (let cls of this.classes) {
            let values2 = {
                HearEnd: cls.HearEnd,
                HearIni: cls.HearIni,
                DedEnd: cls.DedEnd,
                DedIni: cls.DedIni,
                ReadEnd: cls.ReadEnd,
                ReadIni: cls.ReadIni,
            };
            if (JSON.stringify(values1) != JSON.stringify(values2))
                return false;
        }
        return true;
    }

    sixWeeks() {
        return $(".week").length - 1 >= 6;
    }

    hasValue(time) {
        return time != null && time != "";
    }

    isInvalid() {
        if (
            (this.hasValue(this.form.value.HearIni) &&
                !this.hasValue(this.form.value.HearEnd)) ||
            (this.hasValue(this.form.value.DedIni) &&
                !this.hasValue(this.form.value.DedEnd)) ||
            (this.hasValue(this.form.value.ReadIni) &&
                !this.hasValue(this.form.value.ReadEnd)) ||
            (!this.hasValue(this.form.value.DedIni) &&
                this.hasValue(this.form.value.DedEnd)) ||
            (!this.hasValue(this.form.value.HearIni) &&
                this.hasValue(this.form.value.HearEnd)) ||
            (!this.hasValue(this.form.value.ReadIni) &&
                this.hasValue(this.form.value.ReadEnd)) ||
            (this.hasValue(this.form.value.Hearini) &&
                !this.hasValue(this.form.value.HearEnd)) ||
            (!this.hasValue(this.form.value.HearIni) &&
                this.hasValue(this.form.value.HearEnd))

        )
            return true;
        else return false;
    }

    isInvalid2() {
        if (
            (this.hasValue(this.form.value.HearIni) &&
                this.hasValue(this.form.value.HearEnd) &&
                this.form.value.HearIni > this.form.value.HearEnd) ||
            (this.hasValue(this.form.value.DedIni) &&
                this.hasValue(this.form.value.DedEnd) &&
                this.form.value.DedIni > this.form.value.DedEnd) ||
            (this.hasValue(this.form.value.ReadIni) &&
                this.hasValue(this.form.value.ReadEnd) &&
                this.form.value.ReadIni > this.form.value.ReadEnd) ||
                (this.hasValue(this.form.value.HearIni) &&
                this.hasValue(this.form.value.HearEnd) &&
                this.form.value.HearIni > this.form.value.HearEnd)
        )
            return true;
        else return false;
    }

	save() {
		if (this.isInvalid()) {
			let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
				disableClose: true
			});
			confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.CONFIG.MODAL-BAD-TIME-RANGE-TITLE');
			confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.CONFIG.MODAL-BAD-TIME-RANGE-MESSAGE1');
			confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
			confirmDialog.componentInstance.margin = false;
			confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
				confirmDialog = null;
			});
			return;
        }

        if (this.isInvalid2()) {
			let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
				disableClose: true
			});
			confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.CONFIG.MODAL-BAD-TIME-RANGE-TITLE');
			confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.CONFIG.MODAL-BAD-TIME-RANGE-MESSAGE2');
			confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
			confirmDialog.componentInstance.margin = false;
			confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
				confirmDialog = null;
			});
			return;
        }

		this.schoolService.saveActivitiesConfig(
			this.authService.currentUser.Id, 
			this.selectedClass, 
			this.form.value.HearIni, 
			this.form.value.HearEnd, 
			this.form.value.DedIni, 
			this.form.value.DedEnd, 
			this.form.value.ReadIni, 
			this.form.value.ReadEnd)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
			result => {
				let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
					disableClose: true
				});
				confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.CONFIG.ACTIVITIES.SUCCESS-SAVE-CONFIG-TITLE');
				confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.CONFIG.SUCCESS-SAVE-CONFIG-MESSAGE');
				confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
				confirmDialog.componentInstance.margin = false;
				confirmDialog.afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(result => {
					confirmDialog = null;
					this.getClasses();
				});
			},
			error => {
				let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
					disableClose: true
				});
				confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.CONFIG.ERROR-SAVE-CONFIG-TITLE');
				confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.CONFIG.ERROR-SAVE-CONFIG-MESSAGE');
				confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
				confirmDialog.componentInstance.margin = false;
				confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
					confirmDialog = null;
				});
			});
	}

}
