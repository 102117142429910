<div class="main_container" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/esquina-inf-der.svg&quot;)'">
    <section fxLayout="column" fxLayoutAlign="stretch" class="box_options">
        <div>
            <div class="box_op">
                <div class="title">
                    <span>{{'AUTHENTICATION.INITIAL-REGISTRATION.TITLE' | translate}}</span>
                </div>
                <div class="cont">
                    <form class="walinwa-box-content" [formGroup]="initialRegistrationForm" autocomplete="off"
                        novalidate>
                        <div style="margin-bottom: 1rem;">
                            <span>{{'AUTHENTICATION.INITIAL-REGISTRATION.SUBTITLE' | translate}}</span>
                        </div>
                        <div>
                            <label class="font_blue"><b>{{'AUTHENTICATION.INITIAL-REGISTRATION.CLASS-CODE' | translate}}</b></label>
                            <mat-form-field class="spe-form-field">
                                <input maxlength="70" matInput formControlName="code">
                                <mat-error
                                    *ngIf="submitted && initialRegistrationForm.get('code').hasError('required')">
                                    {{'FIELD-REQUIRED' | translate}}
                                </mat-error>
                                <mat-hint class="mat-error" *ngIf="errorMessage">
                                    {{ errorMessage | translate}}
                                </mat-hint>
                            </mat-form-field>
                        </div>
                        <div style="margin-bottom: 1rem;">
                            <a target="_blank" [href]="href">{{'AUTHENTICATION.INITIAL-REGISTRATION.SUBTITLE-LINK' | translate}}</a>
                        </div>
                        <div fxLayoutAlign="end end" fxLayoutAlign.xs="center center" style="margin-top: 35px !important;">
                            <button mat-raised-button class="submit-button orange fixed_button" aria-label="LOGIN"
                                (click)="initialRegistration(initialRegistrationForm)">
                                {{'CONTINUE' | translate}}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</div>