<div class="walinwa-box-modal-overlay2">
    <div fxLayout="column" fxLayoutAlign="center center" style="height: 100%; min-width: 100%; width: 100%;">
        <div style="position: absolute; top:0; left: 0; z-index:999999; background-repeat: no-repeat; width: 12%; height: 100%;" [style.background-image]="walileeBackgroundStyle"></div>
        <div style="position: absolute; top:0; right: 0; z-index:999999; width: 100%; padding: 10px 10px 0px 0px; width: 50px;">
            <img class="pointer" (click)="finish()" src="https://walinwa.blob.core.windows.net/images/ico-close-avatar.svg?ngsw-bypass=true" style="filter: brightness(900%);">
        </div>
        <div [ngClass]="{ 'walinwa-box-modal-100': mobile, 'walinwa-box-modal-90': !mobile }" style="margin-top: 0; border-radius: 1.5em;" [ngStyle.xs]="mobile ? 'height: 100%; min-width: 100%;' : ''">
            @if (ready) {
                <div style="width: 100%; height: 100%" [ngStyle]="{'background-color': state === 'REVIEW' ? 'white' : '#0ca1f1', 'border-radius': state === 'REVIEW' ? '25px' : '0'}">
                    @if (book) {
                        <div style="padding: 1em 1.25em;">
                            <div style="font-size: 36px;" [ngStyle]="{'background-color': state === 'REVIEW' ? 'white' : '#0ca1f1', 'color': state === 'REVIEW' ? 'var(--azul)' : 'white'}" fxLayout="row" fxLayoutAlign="start start">
                                <div>
                                    <span style="font-style: italic;">{{ book.Title }} </span>
                                    <span>({{ book.Author }})</span>
                                </div>
                            </div>
                            <div style="font-size: 28px;" 
                                [ngStyle]="{'background-color': state === 'REVIEW' ? 'white' : '#0ca1f1', 'color': state === 'REVIEW' ? 'var(--azul)' : 'white'}" fxLayout="row" fxLayoutAlign="start start">
                                <div>
                                    <span>Capítulo: {{ section.Chapter }}. </span>
                                    <span style="font-style: italic;"></span>{{ section.Title }}
                                </div>
                            </div>
                        </div>
                    }
                    @if (state === 'TEST') {
                        <div class="header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="0.5rem">
                            <div style="font-size: 120%;" fxFlex="auto" fxFlex.xs="200px" fxLayout="column wrap"
                                fxLayoutAlign="start start">
                                <div>Pregunta {{ questionIndex + 1 }} de {{ (questions || []).length }}.</div>
                                <div style="font-size: 28px; text-align: start;" [innerHtml]="questions[questionIndex].Question | replaceTags: questionIndex + 1"></div>
                            </div>
                        </div>
                        <div>
                            <div class="content" ngStyle.xs="padding: calc(var(--font-size) * 2) calc(var(--font-size) * 1);">
                                <form [formGroup]="questionsForm" class="walinwa-box-modal-container d-flex flex-column scrollable questions-box">
                                    <div formArrayName="Questions" style="width: 100%;">
                                        <div class="reading-text-content">
                                            <div [formGroupName]="questionIndex">
                                                <div class="reading-text-question">
                                                    <div formArrayName="Answer">
                                                        @for (answer of QuestionAnswers(questionIndex).controls; track $index) {
                                                            <div class="answer-box">
                                                                <div [formGroupName]="$index">
                                                                    <div class="answer-inner" [ngClass]="{ 'correct': answer.value.IsCorrect }" [style.color]="answer.value.IsCorrect ? 'white' : '#5e767d'" [style.border-color]="answer.value.IsCorrect ? '#6ecb0b' : questionColors[$index]" [style.background-color]="answer.value.IsCorrect ? '#6ecb0b' : 'white'" [innerHtml]="questions[questionIndex].Answers[$index].Answer | replaceTags" (click)="changeCorrectAnswer(questionIndex, $index)"></div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="spe_gap" fxLayout="row" fxLayout.xs="row wrap" fxLayoutAlign="end end" style="width: 100%; margin-top: 20px; padding-right: 10px;">
                                    <button class="new_but azul" *ngIf="questionIndex > 0" [disabled]="loading" (click)="previous()">
                                        Anterior
                                    </button>
                                    <button class="new_but azul" *ngIf="questionIndex < questions.length - 1" [disabled]="loading" (click)="next()">
                                        Siguiente
                                    </button>
                                    <button [disabled]="loading" (click)="saveTest()" *ngIf="questionIndex == questions.length - 1 || triedSave"
                                        class="new_but azul">
                                        Finalizar test
                                    </button>
                                </div>
                            </div>
                        </div>
                    }

                    @if (state === 'REVIEW') {
                        <div class="reviewing">
                            <form [formGroup]="questionsForm" class="walinwa-box-modal-container d-flex flex-column scrollable questions-box">
                                <div formArrayName="Questions" style="width: 100%;">
                                    @for (question of Questions().controls; let questionIndex = $index; track questionIndex;) {
                                        <div class="reading-text-content">
                                            <div [formGroupName]="questionIndex">
                                                <div class="reading-text-question">
                                                    <div class="question-name" [innerHtml]="questions[questionIndex].Question | replaceTags: questionIndex + 1"></div>
                                                    <div formArrayName="Answer">
                                                        <fieldset>
                                                            @for (answer of QuestionAnswers(questionIndex).controls; let answerIndex = $index; track answerIndex;) {
                                                                <div class="reading-text-answers">
                                                                <div [ngClass]="{ 'ok': answer.value.IsCorrect, 'ko': answer.value.IsIncorrect }" class="reading_text_review_question_answer">
                                                                    <div class="reading_text_review_question_answer_check" fxFlex="2rem" style="align-items: center; display: flex;">
                                                                        @if (answer.value.IsCorrect) {
                                                                            <i class="fas fa-check"></i>
                                                                        }
                                                                    </div>                    
                                                                    <div class="reading_text_review_question_answer_text" [innerHtml]="answer.value.Answer | replaceTags"></div>
                                                                </div>
                                                            </div>
                                                            }
                                                        </fieldset>                                    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </form>
                        </div>
                    }

                    <!--@if (state === 'REVIEW') {
                        <div class="scroll_vertical">
                        @for (question of Questions().controls; let questionIndex = $index; track questionIndex;) {
                            <div class="header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="0.5rem">
                                <div style="font-size: 120%;" fxFlex="auto" fxFlex.xs="200px" fxLayout="column wrap"
                                    fxLayoutAlign="start start">
                                    <div>Pregunta {{ questionIndex + 1 }} de {{ (questions || []).length }}.</div>
                                    <div style="font-size: 28px; text-align: start;" [innerHtml]="questions[questionIndex].Question"></div>
                                </div>
                            </div>
                            <div>
                                <div class="content" ngStyle.xs="padding: calc(var(--font-size) * 2) calc(var(--font-size) * 1);">
                                    <form [formGroup]="questionsForm" class="walinwa-box-modal-container d-flex flex-column scrollable questions-box">
                                        <div formArrayName="Questions" style="width: 100%;">
                                            <div class="reading-text-content">
                                                <div [formGroupName]="questionIndex">
                                                    <div class="reading-text-question">
                                                        <div formArrayName="Answer">
                                                            @for (answer of QuestionAnswers(questionIndex).controls; let answerIndex = $index; track answerIndex;) {
                                                                <div class="answer-box">
                                                                    <div [formGroupName]="answerIndex">
                                                                        <div class="answer-inner" [style.color]="answer.value.IsIncorrect ? 'white' : answer.value.IsCorrect ? 'white' : '#5e767d'" [style.border-color]="answer.value.IsIncorrect ? 'red' : answer.value.IsCorrect ? '#6ecb0b' : questionColors[answerIndex]" [style.background-color]="answer.value.IsIncorrect ? 'red' : answer.value.IsCorrect ? '#6ecb0b' : 'white'" [innerHtml]="questions[questionIndex].Answers[answerIndex].Answer"></div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        }
                        </div>
                    }-->
                </div>            
            } @else {
                <img style="width: 10em; position: fixed;top: 0;left: 0;bottom: 0;right: 0;margin: auto;" src="https://walinwa.blob.core.windows.net/images/loading.gif?ngsw-bypass=true">
            }
        </div>
    </div>
</div>