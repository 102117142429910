<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="selecf spe_gap" fxLayout="column" fxLayoutAlign="stretch"
    [ngStyle]="{'pointer-events': review ? 'none;' : ''}">

    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div class="wordsContainer" id="space-for-text">
        <div class="items_container spe_gap" fxLayout="column" fxLayoutAlign="center">
            <div class="wordRow phraseBox">
                <div class="phrase" *ngIf="!review || controlSession || show_errors == null">
                    <div style="display: inline-block;" class="noBreakLetterBlock"></div> <!--Necesario para inicier apaertura y cierre de palabras-->
                        <ng-container *ngFor="let item of letters; let i = index">

                            <span class="letter" (click)="validate(i-1)"
                                *ngIf="item.letter != '' && item.letter != ' ' && item.letter != '-'"
                                [ngClass]="{'Breaking':item.break == true,  'lastLetter' : i == letters.length - 1 }">
                                {{ item.letter }}
                            </span>

                            <span class="medium" (click)="toggleState(i)" *ngIf="item.letter == ''"
                                [ngClass]="{'element-hidden': item.ok,'noBreakLetter':item.break == false }">
                                <span class="boxError">{{ i === activeIndex ? (itemState === 'default' ? '&nbsp;&nbsp;&nbsp;' : '&nbsp;-&nbsp;') : '&nbsp;&nbsp;&nbsp;' }}</span>
                            </span>

                            <div style="display: inline-block;" *ngIf="item.break == false && item.letter == '' && !item.ok"
                                [ngClass]="{'noBreakLetterBlock':item.break == false, 'BlockingB': item.ok}"></div>

                            <!-- <span class="letter boxError" *ngIf="item.letter == '-'">
                                {{item.letter}}&nbsp;
                            </span> -->

                            <!-- <span class="emptySpace" [ngClass]="{'noBreakLetter':item.break == false }"
                                *ngIf="item.letter == ' '">
                                {{item.letter}}
                            </span> -->
                        </ng-container>
                    
                </div>
                <div class="phrase review" *ngIf="review && !controlSession && show_errors != null">
                    <div style="display: inline-block;" class="noBreakLetterBlock" *ngIf="show_errors !== null"></div> <!--Necesario para inicier apaertura y cierre de palabras-->
                    <ng-container *ngFor="let item of letters; let i = index">
                        <span class="letter" *ngIf="item.letter != '' && item.letter != ' ' && item.letter != '-'">
                            {{item.letter}}
                        </span>

                        <!-- <span class="letter element-hidden" (click)="validate(i)" *ngIf="item.letter == ''">
                            &nbsp;&nbsp;
                        </span> -->

                        <span class="letter boxError reviewBoxError" *ngIf="item.letter == '-'">
                            {{item.letter}}</span>

                        
                        
                        <span class="emptySpace" *ngIf="item.letter == ' '">
                                {{item.letter}}&nbsp;&nbsp;
                            </span>

                        <div style="display: inline-block;" *ngIf="item.letter == ' ' && show_errors !== null" class="noBreakLetterBlock"></div>

                        
                    </ng-container>
                </div>
                <div id="author" class="authorBox">
                    {{exercise.libro}}<span class="authorInfo"> {{exercise.autor}}</span>
                </div>
            </div>
        </div>
    </div>
</div>