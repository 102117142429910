<div id="dialog-continue" [ngClass]="[theme]" class="content-dialog">
    <img (click)="dialogRef.close(false)" *ngIf="canClose" class="close-icon"
    src="https://walinwa.blob.core.windows.net/images/iconos-gris-99.png?ngsw-bypass=true">
    <mat-dialog-content>
        <div>
            <h1 *ngIf="title" matDialogTitle>{{title}}</h1>
            <p [innerHtml]="message1"></p>
            <p style="margin-top: 1em" [ngClass]="{'margin-p': !margin}" class="" [innerHtml]="message2"></p>
            <p style="margin-top: 1em" [ngClass]="{'margin-p': !margin}" class="" [innerHtml]="message3"></p>
            <p style="margin-top: 1em" [ngClass]="{'margin-p': !margin}" class="" *ngFor="let message of messages"
                [innerHtml]="message"></p>
            <p *ngIf="options && options.length >= 3 || isReport" style="margin-top: 1em" [ngClass]="{'margin-p': !margin}">
                <select id="selectedOption" formControlName="selectedOption" style="z-index: 100; position: relative;">
                    <option *ngFor="let option of options" [value]="option.text">{{option.text}}</option>
                </select>
            </p>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="pt-24 one fixed" *ngIf="!options && !disableButtons">
            <button mat-raised-button color="accent" [disabled]="!active" class="submit-button" (click)="dialogRef.close(true)">{{textButton |
                translate }}</button>
        </div>
        <div *ngIf="options && options.length < 3 && !isReport" [ngClass]="{'buttons-space' : space}"
            class="pt-24 two fixed">
            <button *ngFor="let option of options" [disabled]="!active" mat-raised-button color="accent" class="submit-button"
                (click)="dialogRef.close(option.callback)">{{ option.text }}</button>
        </div>

        <div *ngIf="options && options.length >= 3 || isReport" class="pt-24 three fixed"
            [ngClass]="{'buttons-space' : isReport}">

            <button mat-raised-button color="accent" [disabled]="!active" class="submit-button" (click)="selectOption()">Aceptar</button>

            <button *ngIf="isReport" mat-raised-button color="accent" class="submit-button" [disabled]="!active"
                mat-dialog-close>Cancelar</button>
        </div>
    </mat-dialog-actions>
</div>