import { ErrorHandler, Injectable } from '@angular/core';
import { LogService } from 'app/services/log.service';
import { LogEventLevel } from './log-models';

@Injectable()
export class CustomErrorHandler extends ErrorHandler {

  constructor(private logService: LogService) {
    super();
  }
    
  async handleError(error) {
    try {
        const message = `${error.name || ''}: "${error.message || ''}" at ${error.stack || ''} *** archivo: ${error.fileName || ''} linea: ${error.lineNumber || ''} columna: ${error.columnNumber || ''}`;

        await this.logService.logMessage(message, LogEventLevel.Error).toPromise();
    } catch(e) {
        console.error("Ha sido imposible registrar la traza.");
        console.error(e);
    }
    
    super.handleError(error);
    //throw error;
  }
}
