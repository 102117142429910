<div class="new_cartel nodiploma walinwa-plank walinwa-plank-down walinwa-plank-common">
    <div class="head">
        <img class="close_button" src="https://walinwa.blob.core.windows.net/images/ico-close-popup.svg?ngsw-bypass=true" (click)="close()" />
        <span class="sup_title" [innerHTML]="'STUDENT.WALILEE.PLANK.FINISHED-ALL-TESTS' | translate"></span>
    </div>
    <div class="desc" [style.background-image]="confetiBackgroundStyle" style="background-repeat: no-repeat; background-size: 100%;">
        <div class="notas with_avatar spe_gap" fxLayout="row" fxLayoutAlign="space-between center">
            <div fxFlex="20">
                <img class="walinwin-img" src="https://walinwa.blob.core.windows.net/images/pose2.svg?ngsw-bypass=true" />
            </div>
            <div fxFlex="">
                <div class="c_m_top">{{ 'STUDENT.WALILEE.PLANK.HOPE-YOU-LIKED-IT' | translate }}</div>
                <div class="c_m_top" style="font-weight: bold;">{{ title }}</div>
                <div class="c_m_bottom">{{ 'STUDENT.WALILEE.PLANK.KEEP-READING' | translate }}</div>
        </div>
    </div>
</div>