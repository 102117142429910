<div class="walinwa-box-modal-overlay scrollable" id="waliletras-main" style="align-items: center;">
    <section class="letras_intro_pop" *ngIf="state == 'pre'" fxLayout="column" fxLayoutAlign="stretch">
        <div class="head">
            <span>{{'STUDENT.WALILETRAS.TITLE' | translate}}</span>
        </div>
        <div class="bod" fxLayout="row" fxLayout.xs="row wrap" fxLayoutAlign="space-between center"
            fxLayoutAlign.xs="center center">
            <div class="one" fxFlex="30%" fxFlex.xs="35%">
                <img [src]="getImgUrl('waliletras_walinwin.png')">
            </div>
            <div class="two" fxFlex="100%" [innerHTML]="'STUDENT.WALILETRAS.CONTENT' | translate">
            </div>
        </div>
        <div class="fot spe_gap" fxLayout="row" fxLayout.xs="row wrap" fxLayoutAlign="end center"
            fxLayoutAlign.xs="center center">
            <button (click)="back()" class="new_but azul">
                {{'STUDENT.WALILETRAS.LATER' | translate}}
            </button>
            <button (click)="start()" [disabled]="loading" class="new_but azul">
                {{'START' | translate}}
            </button>
        </div>
    </section>
    <!--  -->
    <section class="back_pop" *ngIf="state == 'exerc'" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/WaliLetras/fondos-01.jpg&quot;)'">
        <div [hidden]="!spark" class="spark" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/WaliLetras/fuegos.gif&quot;)'"></div>
        <div class="cont spe_gap" fxLayout="column" fxLayoutAlign="stretch">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center"
                fxLayoutAlign.xs="center center" class="spe_gap mainheader">
                <div fxHide fxShow.xs class="text-right" style="width: 100%;">
                    <img (click)="showMessage()" [src]="getImgUrl('ico-close-popup.svg', true)" class="pointer close_pop">
                </div>
                <div>
                    <div class="waliletras_logo" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/WaliLetras/componentes_cuboslogo_barra.svg&quot;)'">
                        <span class="title">{{'STUDENT.WALILETRAS.WALILETRAS' | translate}}</span><span class="badge">{{walinwos}} walinwos</span>
                    </div>
                </div>
                <div fxNgClass.gt-xs="text-right">
                    <img (click)="showMessage()" [src]="getImgUrl('ico-close-popup.svg', true)" class="pointer close_pop" fxHide.xs>
                    <div class="tiempos" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/WaliLetras/tiempo.svg&quot;)'">
                        <div>{{'STUDENT.WALILETRAS.TIME' | translate}}</div>
                        <span>{{waliLetrasService.getTime()}}</span>
                    </div>
                </div>
            </div>
            <div fxFlex="" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between stretch"
                fxLayoutAlign.xs="start center" class="spe_gap mainbody">
                <div class="cubos" fxFlex="" fxFlex.xs="initial" fxLayout="row" fxLayoutAlign="center start">
                    <div fxFlex="0 1 36rem" fxLayout="row wrap" fxLayoutAlign="center start" class="cubos_gap">

                        <div *ngFor="let letra of letras; let i = index" class="new_item_cubo">
                            <div class="espacio3D">
                                <div [id]="'cubo'+i" class="cubo3D" [ngClass]="getImg(i)" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/WaliLetras/cubo-' +  (i == 7 ? colors[0] : colors[i]) + '.svg&quot;)'">
                                    <div class="base"></div>
                                    <aside class="cara cara1">{{letra}}</aside>
                                    <aside class="cara cara2"></aside>
                                    <aside class="cara cara3"></aside>
                                    <aside class="cara cara4"></aside>
                                    <aside class="cara cara5"></aside>
                                    <aside class="cara cara6"></aside>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxFlex.xs="" class="palabrasbox">
                    <div class="boxi spe_gap" fxLayout="column" id="box-scrollable">
                        <div [attr.id]="'palabra_' + i" class="card-content"
                            *ngFor="let palabra of palabras; let i = index">
                            <!-- la DIV tiene que ser un for del eleemento memo que contiene las palabras-->
                            <div (click)="setFocus(true)" [tabindex]="i" fxLayout="row" fxLayoutAlign="center center"
                                fxLayoutGap="4px" class="palabras" #palabras id="palabra">
                                <!-- cada span tiene que ser un for de los caracteres de esa palabra.... y si el caracter titila le ponemoes una clase
                        si el caracter tiene la letraEscritar, mostramos la letra escrita, si el caracter es un espacio le ponemos otra clase-->
                                <span style="color: white; margin-right: .5em;">{{i + 1}}.</span>
                                <ng-container *ngFor="let letra of palabra.caracteres;let j = index">
                                    <span class="letras"
                                        (click)="deleteCaracter(i,getPosition(letra, palabra.caracteres))"
                                        [attr.id]="'letter_' + i + '_' + j" [ngClass]="{
                                    titila: letra.titila,
                                    correcta: letra.letraEscrita != null && palabra.answered,
                                    mal: letra.letraEscrita != null && palabra.answered == false,
                                    oculta: letra.letraEscrita == null && (palabra.answered == false ||  palabra.answered == true)
                                }" type="text" [innerHtml]="letra.letraEscrita? letra.letraEscrita: '&nbsp'">
                                    </span>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between stretch" class="mainfooter">
                <div class="one">
                    <img (click)="showInfo()" class="pointer moreinfob" [src]="getImgUrl('info.png')">
                    <div class="walinwa-tooltip walile" *ngIf="viewInfo" (click)="viewInfo = false">
                        <div class="walinwa-tooltip-info walinwa-tooltip-info-primary tooltip">
                            <b [innerHTML]="'STUDENT.WALILETRAS.WALILETRAS-INFO' | translate">
                            </b>
                        </div>
                    </div>
                </div>
                <div class="two" fxLayout="row" fxLayoutAlign="center center">
                    <button (click)="showMessage()" class="new_but oranges">{{'FINISH' | translate}}</button>
                </div>
            </div>
        </div>
    </section>
    <!--  -->
    <section class="back_pop review" *ngIf="state == 'review'" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/WaliLetras/fondos-01.jpg&quot;)'">
        <div class="cont spe_gap" fxLayout="column" fxLayoutAlign="stretch">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center"
                fxLayoutAlign.xs="center center" class="spe_gap mainheader">
                <div fxHide fxShow.xs class="text-right" style="width: 100%;">
                    <img (click)="close()" [src]="getImgUrl('ico-close-popup.svg', true)" class="pointer close_pop">
                </div>
                <div>
                    <div class="waliletras_logo review" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/WaliLetras/componentes_cuboslogo_barra.svg&quot;)'">
                        <span class="title">{{'STUDENT.WALILETRAS.WALILETRAS' | translate}}</span>
                    </div>
                </div>
                <div fxNgClass.gt-xs="text-right">
                    <img (click)="close()" [src]="getImgUrl('ico-close-popup.svg', true)" class="pointer close_pop" fxHide.xs>
                    <div class="tiempos review" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/WaliLetras/tiempo_review.svg&quot;)'">
                        <div>{{'STUDENT.WALILETRAS.EARNED-WALINWOS' | translate}}</div>
                        <div>{{walinwos}} walinwos.</div>
                        <div class="text-center">{{'STUDENT.WALILETRAS.PARTICIPATE-TEXT' | translate}}</div>
                    </div>
                </div>
            </div>
            <div fxFlex="" fxLayout="column" fxLayoutAlign="start stretch" class="spe_gap mainbody">
                <div class="box_words_result" fxLayout="row wrap" fxLayoutAlign="start start">
                    <ng-container *ngFor="let palabra of palabras; let i = index">
                        <span *ngIf="palabra.answered">{{getPalabra(i)}}</span>
                    </ng-container>
                </div>
                <div fxFlex="" class="tables_section" fxLayout="column" fxLayoutAlign="stretch">
                    <div fxFlex="" class="walinwa-box-with-tabs" fxLayout="column" fxLayoutAlign="stretch">
                        <div fxFlex="" class="walinwa-box" fxLayout="column" fxLayoutAlign="stretch">
                            <div class="walinwa-tabs walinwa-tabs-top">
                                <div [ngClass]="{'orange-selected': tab == 1}" (click)="tab=1" class="walinwa-tab-gray">
                                    {{'STUDENT.WALILETRAS.CLASS-SCORE' | translate}}</div>
                                <div [ngClass]="{'orange-selected': tab == 2}" (click)="tab=2" class="walinwa-tab-gray">
                                    {{'STUDENT.WALILETRAS.SCHOOL-SCORE' | translate}}</div>
                            </div>

                            <div fxFlex="" class="ww_table_rwd" [ngClass]="{'hidden': tab == 2}">
                                <table class="walinwa-table">
                                    <thead>
                                        <tr>
                                            <!-- <td style="width:50px">#</td>
                                            <td style="">Alias</td> -->
                                            <td colspan="2"></td>
                                            <td style="width:100px" align="center">Walinwos</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let resultado of AulaResult; let i = index">
                                            <td>{{i + 1}}</td>
                                            <td>{{resultado.StudentName}}
                                            </td>
                                            <td align="center">{{resultado.Score}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div fxFlex="" class="ww_table_rwd" [ngClass]="{'hidden': tab == 1}">
                                <table class="walinwa-table">
                                    <thead>
                                        <tr>
                                            <!-- <td style="width:50px">#</td>
                                            <td style="">Alias</td> -->
                                            <td colspan="2"></td>
                                            <td style="width:100px" align="center">Walinwos</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let resultado of StudentsResult; let i = index">
                                            <td>{{i + 1}}</td>
                                            <td>{{resultado.StudentName}}
                                            </td>
                                            <td align="center">{{resultado.Score}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<plank-selector *ngIf="!review" title="Actividad terminada" [isFinAnticipado]="false" [show]="finished && messages.length > 0"
    mark="-1" [messages]="messages" [walinwos]="this.calcScore()" (onClose)="back() "></plank-selector>

<form style="position: fixed; top: -99999em; left: -99999em;" autocomplete="off">
    <input (blur)="setFocus(false)" [(ngModel)]="input" (ngModelChange)="change()" autocorrect="off"
        autocapitalize="off" spellcheck="false" [ngModelOptions]="{standalone: true}" autocomplete="off" type="text"
        id="dictation-input" />
</form>