import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { EmailConfigService } from 'app/services/email-config.service';
import { NavigationService } from 'app/services/navigation.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-config-parents',
  templateUrl: './config-parents.component.html',
  styleUrls: ['./config-parents.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfigParentsComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  currentUser;
  dataLoaded = false;
  states = [];
  state;

  constructor(
    private router: Router,
    private emailConfigService: EmailConfigService,
    private navigationService: NavigationService
  ) { 
    this._unsubscribeAll = new Subject();
}

ngOnDestroy(): void
   {
       // Unsubscribe from all subscriptions
       this._unsubscribeAll.next();
       this._unsubscribeAll.complete();
   }

  ngOnInit(): void {
    if (!sessionStorage.getItem("currentUser")) return this.navigationService.exit();
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (!this.currentUser) return this.navigationService.exit();
      this.navigationService.enableBackButton(true, this.back.bind(this));
      this.dataLoaded = true;
      this.editMailConfig();
  }

  back() {
    if (this.states.length > 1) {
      var last = this.states[this.states.length - 2];
      this.states.splice(this.states.length - 1, 1);
      this[last]();
      this.states.splice(this.states.length - 1, 1);
    } else {
      this.router.navigateByUrl("/school/parentStudents")
    }
  }

  editMailConfig() {
    this.state = 'mail';
    this.states.push('editMailConfig');
    if (this.router.url == '/school/configParents') {
      this.router.navigateByUrl(this.router.url + '/mail');
    } else {
      this.router.navigateByUrl('/school/configParents/mail');
    }
  }

  errorCallback() {
    this.navigationService.exit();
  }


}
