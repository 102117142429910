import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { AuthService } from "app/core/guards/auth.service";
import { ExerciseSolvedEnum } from "app/core/shared/enums/exercise-solved.enum";
import { SharedService } from "app/core/shared/shared.service";
import { ExerciseService } from "app/services/exercise.service";
import { environment } from "environments/environment";
declare var $;

@Component({
    selector: "ex-ahorca",
    templateUrl: "./ahorca.component.html",
    styleUrls: ["./ahorca.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class AhorcaComponent implements OnInit, OnDestroy {
    @Input() review;
    @Input() show_errors;
    @Input() controlSession : boolean = false;
    @Input() slide : boolean = false; @Input() current;
    exercise;
    finished = false;
    errors = 8;
    secretWord = [];
    letters = [
        { name: "a", chosen: false },
        { name: "b", chosen: false },
        { name: "c", chosen: false },
        { name: "d", chosen: false },
        { name: "e", chosen: false },
        { name: "f", chosen: false },
        { name: "g", chosen: false },
        { name: "h", chosen: false },
        { name: "i", chosen: false },
        { name: "j", chosen: false },
        { name: "k", chosen: false },
        { name: "l", chosen: false },
        { name: "m", chosen: false },
        { name: "n", chosen: false },
        { name: "ñ", chosen: false },
        { name: "o", chosen: false },
        { name: "p", chosen: false },
        { name: "q", chosen: false },
        { name: "r", chosen: false },
        { name: "s", chosen: false },
        { name: "t", chosen: false },
        { name: "u", chosen: false },
        { name: "v", chosen: false },
        { name: "w", chosen: false },
        { name: "x", chosen: false },
        { name: "y", chosen: false },
        { name: "z", chosen: false },
    ];
    wordError = false;

    elementsOk;
    elementsErr;

    event_index;

    constructor(private exerciseService: ExerciseService, private sharedService: SharedService) {
        this.exercise = this.exerciseService.getExerciseDefinition();
    }

    public get exercisesSolvedEnum(): typeof ExerciseSolvedEnum{
        return this.exerciseSolvedEnum
    }
    
      public get exerciseSolvedEnum(): typeof ExerciseSolvedEnum{
        return ExerciseSolvedEnum
    }

    ngOnDestroy() {
        window["removeListener"](this.event_index)
    }

    ngOnInit() {
        this.FillSecretWord();
        if (this.review) {
            this.elementsOk = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsOK
            );
            this.elementsErr = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsERR
            );
            this.errors = this.errors - this.elementsErr.length;
            this.ShowAnswer();
        }
            this.event_index = window.addEventListener(
                "message",
                (event) => {
                    let data = event.data;
                    switch (data) {
                        case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_SOLUTION]:
                            this.ShowSolution();
                            break;
                        case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_ANSWER]:
                            this.ShowAnswer();
                            break;
                        case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_CUSTOM]:
                            this.ShowAnswer();
                            break;
                        case this.exercisesSolvedEnum[this.exerciseSolvedEnum.TIMER_END]:
                            this.finished = true;
                            break;
                    }
                },
                {once : this.review ? false : true}
            );
        
    }
    getImgStyle() {
        return "url('https://walinwa.blob.core.windows.net/images/Exercises/Ahorca/" + this.errors + ".png')";
    }
    getImgClass() {
        return "step" + this.errors;
    }

    FillSecretWord() {
    if (this.exercise.frase && this.exercise.palabra){
        let regEx = new RegExp(this.exercise.palabra, "ig");
        let palabra = this.exercise.frase.match(regEx)
        if (palabra != null && palabra.length > 0) {
            this.exercise.palabra  = palabra[0]
        }
    }
        this.exercise.palabra.split("").forEach((element) => {
            this.secretWord.push({
                value: element,
                compare: this.replaceAccent(element).toLowerCase(),
                show: false,
            });
        });
    }

    replaceAccent(item) {
        item = item.replace(/á/g, "a");
        item = item.replace(/é/g, "e");
        item = item.replace(/í/g, "i");
        item = item.replace(/ó/g, "o");
        item = item.replace(/ú/g, "u");
        item = item.replace(/ü/g, "u");
        item = item.replace(/Á/g, "A");
        item = item.replace(/É/g, "E");
        item = item.replace(/Í/g, "I");
        item = item.replace(/Ó/g, "O");
        item = item.replace(/Ú/g, "U");
        item = item.replace(/Ü/g, "u");
        return item;
    }

    FinishExercise() {
        this.secretWord.forEach((item) => {
            item.show = true;
        });
        this.finished = true;
        this.exerciseService.endExercise()
        // if (this.errors == 0)
        //   this.$emit('exercise-notsolved');
        // else
        //   this.$emit('exercise-solved');
    }

    ShowAnswer() {
        this.elementsErr.forEach(error => {
            this.letters[error].chosen = true;
        });
        this.elementsOk.forEach(acierto => {
            this.letters[acierto].chosen = true;
        });
        this.secretWord.forEach(palabra => {
            palabra.show = false;
            this.elementsOk.forEach(acierto => {
                if(this.letters[acierto].name == palabra.value){
                    palabra.show = true;
                }
            });
        })
    }

    ShowSolution() {
        this.letters.forEach(function (item) {
            item.chosen = false;
            // item.ok = false;
            // item.error = false;
        });
        this.elementsErr.forEach(error => {
            this.letters[error].chosen = true;
        });
        this.elementsOk.forEach(acierto => {
            this.letters[acierto].chosen = true;
        });
        this.secretWord.forEach(function (item) {
            item.show = true;
        });
        
    }

    try(letter) {

        if (this.finished) return;
        var found = false;
        var finishWord = true;
        let index = -1
        for (let i = 0; i < this.letters.length; i++) {
            if (this.letters[i].name == letter.name) {
                this.letters[i].chosen = true;
                index = i
            }
        }
        this.secretWord.forEach((item) => {
            if (item.compare == letter.name) {
                item.show = true;
                found = true;
            }
            if (item.show == false) finishWord = false;
        });
        if (found == false) {
            this.errors--;
            // this.$emit("error");
            this.exerciseService.answer(
                index,
                false,
                this.secretWord.length
            );
        } else {
            let opts = this.secretWord.length;
            let resolved = this.secretWord.filter(l => l.show == true)
            if (resolved.length == this.secretWord.length) {
                opts = 1
            }
            this.exerciseService.answer(
                index,
                true,
                opts
            );
        }

        if (this.errors == 0 || finishWord) {
            if (this.errors == 0) {
                this.exerciseService.getCurrentExercise().Result = 'ERR'
            }
            else if (finishWord && this.errors > 0) {
                this.exerciseService.getCurrentExercise().Result = 'OK'
            }
            this.FinishExercise();
        }
    }
}
