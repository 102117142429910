import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ExerciseService } from "app/services/exercise.service";
import * as _ from "lodash";
declare var $;

@Component({
    selector: "ex-define",
    templateUrl: "./define.component.html",
    styleUrls: ["./define.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class DefineComponent implements OnInit, OnDestroy {
    @Input() review;
    @Input() show_errors;
    @Input() controlSession : boolean = false;
    @Input() slide: boolean = false;
    @Input() current;
    exercise;
    finished = false;
    elementsOk;
    elementsErr;

    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case "SHOW_SOLUTION":
                this.finished = true;
                if (this.exerciseService.showingSolution) {
                    setTimeout(this.ShowAnswersTimeOut.bind(this), 100);
                    break;
                } else {
                    setTimeout(this.ShowSolution.bind(this), 100);
                    break;
                }
            case "SHOW_ANSWER":
            case "SHOW_CUSTOM":
                setTimeout(this.ShowAnswer.bind(this), 100)
                break;
        }
    }
    
    constructor(private exerciseService: ExerciseService) {
        this.exercise = this.exerciseService.getExerciseDefinition();
    }
    ngOnDestroy(){
    }

    ngOnInit() {
        var lastChar = this.exercise.libro[this.exercise.libro.length - 1];
        if (lastChar != "?" && lastChar != "!") {
            this.exercise.libro = this.exercise.libro + ".";
        }
        //  FillDictionary();
        if (this.review) {
            this.elementsOk = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsOK
            );
            this.elementsErr = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsERR
            );
            if (this.show_errors == false) {
                this.ShowSolution();
              } else if (this.show_errors == true || this.show_errors == null) {
                this.ShowAnswer();
              }
        } else {
            this.FillExercise();
        }
    }

    FillExercise() {
        for (let i = 0; i < this.exercise.opciones.length; i++) {
            this.exercise.opciones[i].palabraAux = this.exercise.opciones[i].palabra;
        }
    }

    getFrase() {
        var result = "";
        var regex = new RegExp(
            "[^0-9a-zA-Z]" + this.exercise.texto + "[^0-9a-zA-Z]"
        );
        var inicio = this.exercise.frase.search(regex);
        var longitud = this.exercise.texto.length;
        inicio++;
        longitud++;
        var fin = this.exercise.frase.length - 1;
        result +=
            "<span>" + this.exercise.frase.substring(0, inicio - 1) + "</span>";
        if (this.exercise.frase.substring(inicio + longitud).length == 1) {
            result +=
                "<span style='border: 2px solid gray;' class='resaltado'> " +
                this.exercise.frase.substring(inicio, inicio + longitud) +
                "" +
                this.exercise.frase.substring(inicio + longitud) +
                " </span>";
        } else {
            result +=
                "<span style='border: 2px solid gray;' class='resaltado'> " +
                this.exercise.frase.substring(inicio, inicio + longitud) +
                " </span>";
            result +=
                "<span>" +
                this.exercise.frase.substring(inicio + longitud) +
                "</span>";
        }
        return result;
    }

    validateClick(index) {
        if (this.finished) return;
        var result = true;
        var numTotal = 0,
            numTotalOk = 0;
        for (var i = 0; i < this.exercise.opciones.length; i++) {
            this.exercise.opciones[i].answered = null;
        }
        if (this.exercise.opciones[index].opcion) {
            this.exercise.opciones[index].answered = true;
            this.exerciseService.answer(index, true, 1);
            result = true;
        } else {
            this.exercise.opciones[index].answered = false;
            this.exerciseService.answer(index, false, 1);
            result = false;
        }
        if (result) {
            // this.$emit("timer-stop");
            this.finished = true;
            // this.$emit('exercise-solved');
        }
    }

    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    }
    ShowSolution() {
        for (var j = 0; j < this.exercise.opciones.length; j++) {
            this.exercise.opciones[j].palabraAux =
                this.exercise.opciones[j].rae;
            if (this.exercise.opciones[j].opcion == true) {
                this.exercise.opciones[j].answered = true;
                this.exercise.opciones[j].notAnserwed = false;
            }
            if (this.exercise.opciones[j].opcion == false) {
                this.exercise.opciones[j].answered = null;
                this.exercise.opciones[j].notAnserwed = false;
            }
        }
    }

    ShowAnswer() {
        for (var i = 0; i < this.exercise.opciones.length; i++) {
            if (this.exercise.opciones[i].opcion == true)
                this.exercise.opciones[i].notAnserwed = true;
        }
        for (var i = 0; i < this.exercise.opciones.length; i++) {
            this.exercise.opciones[i].palabraAux =
                this.exercise.opciones[i].rae;
            this.exercise.opciones[i].answered = null;
            for (var j = 0; j < this.elementsErr.length; j++) {
                if (
                    this.exercise.opciones[i].valor ==
                    this.exercise.opciones[this.elementsErr[j]].valor
                ) {
                    this.exercise.opciones[i].answered = false;
                    this.exercise.opciones[i].notAnserwed = false;
                }
            }
        }
        for (var i = 0; i < this.exercise.opciones.length; i++) {
            for (var j = 0; j < this.elementsOk.length; j++) {
                if (
                    this.exercise.opciones[i].valor ==
                    this.exercise.opciones[this.elementsOk[j]].valor &&
                    this.exercise.opciones[i].answered != false
                ) {
                    this.exercise.opciones[i].answered = true;
                    this.exercise.opciones[i].notAnserwed = false;
                }
            }
        }
    }

    ShowAnswersTimeOut() {
        for (var j = 0; j < this.exercise.opciones.length; j++) {
            if (this.exercise.opciones[j].opcion == true && this.exercise.opciones[j].answered != true) {
                this.exercise.opciones[j].answered = false;
                this.exercise.opciones[j].notAnserwed = true;
            }
            if (this.exercise.opciones[j].opcion == false) {
                this.exercise.opciones[j].answered = null;
            }
        }
    }

}
