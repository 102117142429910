<div class="walinwa-box-with-tabs" *ngIf="dataLoaded" fxNgClass.gt-sm="scroll_vertical">
    <div class="walinwa-tabs walinwa-tabs-top">
        <div [ngClass]="{ selected: status == 'newMessage' }" (click)="changeStatus('newMessage')"
            class="walinwa-tab-gray">{{'SCHOOL.TEACHER.MESSAGES.NEW-MESSAGE' | translate}}</div>
        <div [ngClass]="{ selected: status == 'viewMessage'  }" (click)="changeStatus('viewMessage')"
            class="walinwa-tab-gray">{{'SCHOOL.TEACHER.MESSAGES.MY-MESSAGES' | translate}}</div>
        <div [ngClass]="{ selected: status == 'suggestionsMessage'  }" (click)="changeStatus('suggestionsMessage')"
            class="walinwa-tab-gray">{{'SCHOOL.TEACHER.MESSAGES.SUGGESTIONS' | translate}}</div>
    </div>
    <div class="walinwa-box walinwa-box-green">
        <div class="walinwa-box-content"
            [hidden]="navigationService.messageState === 'viewMessage' || navigationService.messageState === 'suggestionsMessage' || navigationService.messageState === 'viewMessageInfo'">
            <div fxLayout="row wrap" fxLayoutAlign="space-between stretch" class="spe_gap">
                <div fxFlex="100%" fxFlex.gt-xs="" class="ww_boxed" fxNgClass.gt-xs="ww_height">
                    <mat-checkbox *ngFor="let class of classes | sortBy:'asc':'Level';let i = index"
                        [(ngModel)]="class.selected" (ngModelChange)="showCurso(i)">
                        <span style="margin-left: -0.3em;">{{class.Name}}</span>
                    </mat-checkbox>
                </div>
                <div fxFlex="100%" fxFlex.gt-xs="" class="ww_boxed" fxNgClass.gt-xs="ww_height">
                    <mat-checkbox *ngFor="let student of students;let i = index" [(ngModel)]="student.selected">
                        <span style="margin-left: -0.3em;">{{ student.FamilyName }} {{ getComa(student)}} {{
                            student.Name }}</span>
                    </mat-checkbox>
                </div>
            </div>
            <div class="walinwa-row spe_gap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center">
                <button (click)="selectAll()" *ngIf="!allSelected" class="new_but azul">
                    {{'SCHOOL.TEACHER.MESSAGES.SELECT-ALL' | translate}}
                </button>
                <button (click)="selectAll()" *ngIf="allSelected" class="new_but azul">
                    {{'SCHOOL.TEACHER.MESSAGES.DESELECT-ALL' | translate}}
                </button>
            </div>
            <div class="">
                <textarea class="mainLoginInput text-area" [(ngModel)]="messageText" (ngModelChange)="checkCanSend()"
                    [placeholder]="'SCHOOL.TEACHER.MESSAGES.MESSAGE-INPUT' | translate"></textarea>
            </div>
            <div class="walinwa-row spe_gap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center">
                <button [disabled]="checkCanSend() != true || calling" (click)="sendMessage()" class="new_but azul">
                    {{'SCHOOL.TEACHER.MESSAGES.SEND' | translate}}
                </button>
            </div>
        </div>

        <div class="walinwa-box-content"
            [hidden]="navigationService.messageState === 'newMessage' || navigationService.messageState === 'suggestionsMessage'">
            <div
                [hidden]="navigationService.messageState === 'newMessage' || navigationService.messageState === 'suggestionsMessage' || navigationService.messageState === 'viewMessageInfo'">
                <div class="ww_table_rwd">
                    <table class="walinwa-table">
                        <thead>
                            <tr>
                                <th>{{'SCHOOL.TEACHER.MESSAGES.DATE' | translate}}</th>
                                <th>{{'MESSAGE' | translate}}</th>
                                <th>{{'SCHOOL.TEACHER.MESSAGES.STUDENTS' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr (click)="getMessageDetails(msg.IdMessage)"
                                *ngFor="let msg of sentMessages;let i = index">
                                <td>{{formatDate(msg.SentDate)}}</td>
                                <td [innerHtml]="showMsgCut(removeHashtags(msg.Message), 50)"></td>
                                <td [innerHtml]="showMsgCut(msg.TableText, 15)"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div
                [hidden]="navigationService.messageState === 'newMessage' || navigationService.messageState === 'suggestionsMessage' || navigationService.messageState === 'viewMessage'">
                <div class="ww_table_rwd">
                    <table class="walinwa-table">
                        <thead>
                            <tr>
                                <th>{{'USERNAME' | translate}}</th>
                                <th>{{'MESSAGE' | translate}}</th>
                                <th style="width:74px">{{'SCHOOL.TEACHER.MESSAGES.STATE' | translate}}</th>
                                <th style="width:50px"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let user of messageDetails;let i = index" class="walinwa-table-row">
                                <td>{{ user.User.UserName }}</td>
                                <td class="ww_break_word"
                                    [innerHtml]="showMsgCutAll(removeHashtags(findMessage(user.IdMessage)))"></td>
                                <td *ngIf="user.ViewDate">{{'SCHOOL.TEACHER.MESSAGES.SEEN' | translate}}</td>
                                <td *ngIf="!user.ViewDate">{{'SCHOOL.TEACHER.MESSAGES.PENDING' | translate}}</td>
                                <td>
                                    <img src="https://walinwa.blob.core.windows.net/images/School/ico-trash.svg?ngsw-bypass=true" class="spe_ico_exam"
                                        (click)="removeMessageUser(user)" *ngIf="!user.ViewDate" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="walinwa-box-content"
            [hidden]="navigationService.messageState === 'viewMessage' || navigationService.messageState === 'newMessage' || navigationService.messageState === 'viewMessageInfo'">
            <form [formGroup]="form" name="contactForm" class="contactForm" autocomplete="new-password"
                fxLayout="column" fxLayoutAlign="stretch" class="spe_gap">
                <div class="custom-form-field">
                    <label>{{'SCHOOL.TEACHER.MESSAGES.MATTER' | translate}}</label>
                    <input type="text" maxlength="70" name="Subject" formControlName="subject" required tabindex="1" class="input17"/>
                    <mat-error *ngIf="form.get('subject').invalid && form.get('subject').touched">{{'FIELD-REQUIRED' | translate}}</mat-error>
                </div>
                <div class="">
                    <label class="font_blue"><b>{{'MESSAGE' | translate}}</b></label>
                    <textarea type="text" class="valor autofocus text-area" name="Body" formControlName="body" required
                        tabindex="2" rows="10"></textarea>
                    <mat-error *ngIf="form.get('body').invalid && form.get('body').touched">{{'FIELD-REQUIRED' | translate}}</mat-error>
                </div>
                <div class="custom-form-field">
                    <label>{{'SCHOOL.TEACHER.MESSAGES.FILE-NAME' | translate}} <span style="color: var(--gris);">{{ this.attachment ?
                            this.attachment.name : '' }}</span>
                        <br *ngIf="BigFile">
                        <mat-error *ngIf="BigFile">
                            {{'SCHOOL.TEACHER.MESSAGES.FILE-LARGE' | translate}}
                        </mat-error>
                    </label>
                    <input hidden (change)="saveFile($event)" name="attachment" #fileInput type="file" />
                </div>
                <div class="walinwa-row spe_gap" fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
                    <button (click)="fileInput.click()" [disabled]="form.invalid || sendingEmail" value="Enviar"
                    class="new_but azul">{{'SCHOOL.TEACHER.MESSAGES.ADD-FILE' | translate}}</button>

                    <button (click)="submitForm()" [disabled]="form.invalid || sendingEmail" value="Enviar"
                    class="new_but azul">{{'SCHOOL.TEACHER.MESSAGES.SEND' | translate}}</button>
                </div>
            </form>
        </div>
    </div>
</div>