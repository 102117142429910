import { Class } from 'app/core/shared/state';
import { UserService } from 'app/services/user.service';
import { ClassService } from './../../../../services/class.service';
import { environment } from './../../../../../environments/environment';
import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { EmailConfigService } from 'app/services/email-config.service';
import { NavigationService } from 'app/services/navigation.service';
import { SchoolService } from 'app/services/school.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: "app-complements",
    templateUrl: "./complements.component.html",
    styleUrls: ["./complements.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ComplementsComponent implements OnInit, OnDestroy {
    @ViewChild('scrollableElement') scrollableElement: ElementRef;

    private _unsubscribeAll: Subject<any>;
    currentUser;
    dataLoaded = false;
    states = [];
    enableReading;
    enableDeduction;
    enableDictation;
    state;
    constructor(
        private router: Router,
        private authService: AuthService,
        private emailConfigService: EmailConfigService,
        private navigationService: NavigationService,
        public schoolService: SchoolService,
        private classService: ClassService,
        private userService: UserService
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit(): void {
        if (!this.authService.check()) return
        this.currentUser = this.authService.currentUser
        this.enableReading = this.schoolService.school.ReadingText;
        this.enableDeduction = this.schoolService.school.DeductionText;
        this.enableDictation = this.schoolService.school.Dictation;
        this.navigationService.enableExitButton();
        this.GetClassesReduced();
    }

    GetClassesReduced(): void {
        this.userService.getReducedClassesByIdTutor(this.currentUser.Id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((ClassesList: Class[]) => {
                this.classService.ClassesReduced = ClassesList;
                this.dataLoaded = true;
                let params = this.router.url.split("/")
                let last = params[params.length - 1]
                switch (last) {
                    default:
                        this.editSessions();
                        break;
                }
            })
    }

    back() {
        if (this.states.length > 1) {
            var last = this.states[this.states.length - 2];
            this.states.splice(this.states.length - 1, 1);
            this[last]();
            this.states.splice(this.states.length - 1, 1);
        } else {
            this.router.navigateByUrl("school/students")
        }
    }

    editSessions() {
        if (!this.schoolService.school.PredefinedSession) {
            return;
        }
        this.state = 'editSessions';
        this.states.push('editSessions');
        if (this.router.url == '/school/complements') {
            this.router.navigateByUrl(this.router.url + '/editSessions');
        } else {
            this.router.navigateByUrl('/school/complements/editSessions');
        }
    }

    editExams() {
        if (!this.schoolService.school.Exam) {
            return;
        }
        this.state = 'exams';
        this.states.push('editExams');
        if (this.router.url == '/school/complements') {
            this.router.navigateByUrl(this.router.url + '/exams');
        } else {
            this.router.navigateByUrl('/school/complements/exams');
        }
    }
    controlSession() {
        if (!this.schoolService.school.ControlSession) {
            return;
        }
        this.state = 'controlSession';
        this.states.push('controlSession');
        if (this.router.url == '/school/complements') {
            this.router.navigateByUrl(this.router.url + '/controlSession');
        } else {
            this.router.navigateByUrl('/school/complements/controlSession');
        }
    }
    personalizedTest() {
        if (!this.schoolService.school.PersonalizedTest) {
            return;
        }
        this.state = 'personalizedTest';
        this.states.push('personalizedTest');
        if (this.router.url == '/school/complements') {
            this.router.navigateByUrl(this.router.url + '/personalizedTest');
        } else {
            this.router.navigateByUrl('/school/complements/personalizedTest');
        }
    }

    errorCallback() {
        this.navigationService.exit();
    }


}
