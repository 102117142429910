import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'app/app.service';
import { SharedService } from 'app/core/shared/shared.service';
import { AuthService } from 'app/services/auth.service';
import { ReportService } from 'app/services/report.service';
import { SessionService } from 'app/services/session.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
declare var $;

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReportComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  NotDone;
  Started;
  Finished;
  NotFinished;
  student;
  loading;
  disableActionButton;
  reportTypes;
  reportType;
  years;
  year;
  currentSessionStatus;
  currentSessionId;
  selectedDay;
  selectedDay_desde;
  selectedDay_hasta;
  currentMonth;
  expandControls;
  htmlCode;
  SessionStatus;

  constructor(
    private sanitizer: DomSanitizer,
    private appService: AppService,
    private dateAdapter: DateAdapter<Date>,
    private authService: AuthService,
    private sessionService: SessionService,
    private reportService: ReportService,
    private _matDialog: MatDialog,
    public sharedService: SharedService,
    private _translateService: TranslateService
  ) {
    this._unsubscribeAll = new Subject();
    this.dateAdapter.setLocale("es-ES");
    this.NotDone = 0;
    this.Started = 1;
    this.Finished = 2;
    this.NotFinished = 4;
    this.loading = false;
    this.disableActionButton = false;
    this.reportTypes = [
        {
            name: 'studentHistory',
            desc: this._translateService.instant('SCHOOL.PARENT.STUDENTS.REPORT.STUDENTHISTORY')
        },
        {
            name: 'studentRegularity',
            desc: this._translateService.instant('SCHOOL.PARENT.STUDENTS.REPORT.STUDENTREGULARITY')
        }, 
        {
            name: 'studentSession',
            desc: this._translateService.instant('SCHOOL.PARENT.STUDENTS.REPORT.STUDENTSESSION')
        }, 
        {
            name: 'dictationHistory',
            desc: this._translateService.instant('SCHOOL.PARENT.STUDENTS.REPORT.DICTATIONHISTORY')
        }, 
        {
            name: 'evaReadingText',
            desc: this._translateService.instant('SCHOOL.PARENT.STUDENTS.REPORT.EVAREADINGTEXT')
        }];
    this.reportType = this.reportTypes[0].name;
    this.years = [];
    this.years.push({ name: moment().subtract(1, 'years').format('YYYY'), desc: moment().subtract(1, 'years').format('YYYY') });
    this.years.push({ name: moment().format('YYYY'), desc: moment().format('YYYY') });
    this.year = this.years[1].name;
    this.currentSessionStatus = null;
    this.currentSessionId = null;
    this.selectedDay = moment();
    this.selectedDay_desde = moment().locale("es").format();
    this.selectedDay_hasta = moment().locale("es").format();
    this.currentMonth; //0 index month
    this.expandControls = true;
    this.SessionStatus = { finished: "FINISHED", started: "STARTED", newSession: "NEW", generating: "GENERATING", notfinished: "NOTFINISHED" };
  }
  
  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnInit() {
    if (!this.authService.check()) return;
    this.student = this.authService.currentUser;
    this.getDates();
    this.loadSessionMonth();
  }

  parseDate(str) {
    if (str instanceof Date) return moment(str);
    if (moment.isMoment(str)) return str;
    let d = parseFloat(str.split('/')[0]);
    let m = parseFloat(str.split('/')[1]) - 1;
    let a = parseFloat(str.split('/')[2]);
    return moment(new Date(a, m, d));
  }

  getDates() {
    this.selectedDay_desde = moment().startOf('month').format();
    this.selectedDay_hasta = moment().format();
  }

  checkButton() {
    switch (this.reportType) {
      case 'studentSession':
        if (this.currentSessionStatus == null || (this.currentSessionStatus != this.Finished && this.currentSessionStatus != this.NotFinished)) this.disableActionButton = true;
        else this.disableActionButton = false;
        break;
      case 'sessionDetail':
        if (this.currentSessionStatus == null || (this.currentSessionStatus != this.Finished && this.currentSessionStatus != this.NotFinished)) this.disableActionButton = true;
        else this.disableActionButton = false;
        break;
      case 'studentHistory':
        this.disableActionButton = false;
        break;
      case 'dictationHistory':
        this.disableActionButton = false;
        break;
      case 'evaReadingText':
        this.disableActionButton = false;
        break;
      case 'studentRegularity':
        this.disableActionButton = false;
        break;
    }
  }

  changeControls() {
    if(!this.dateHidden() && moment(this.selectedDay_desde).isAfter(moment(this.selectedDay_hasta))){
        this.callMessage();
        return;
    }
    if (this.expandControls == false) {
      this.expandControls = true;
    } else {
      if (this.expandControls == true) {
        switch (this.reportType) {
          case 'studentSession':
            if (this.currentSessionStatus == this.Finished || this.currentSessionStatus == this.NotFinished) {
              this.showSessionReport();
              this.expandControls = false;
            }
            break;
          case 'sessionDetail':
            if (this.currentSessionStatus == this.Finished || this.currentSessionStatus == this.NotFinished) {
              this.showSessionDetailedReport();
              this.expandControls = false;
            }
            break;
          case 'studentHistory':
            this.showStudentHistory();
            this.expandControls = false;
            break;
          case 'dictationHistory':
            this.showDictationHistory();
            this.expandControls = false;
            break;
          case 'evaReadingText':
            this.showEvaReadingText();
            this.expandControls = false;
            break;
          case 'studentRegularity':
            this.showStudentRegularity();
            break;
        }
      }
    }
  }
  callMessage(){
    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
        disableClose: true
      });
      confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.MODAL-TITLE');
      confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.MODAL-MESSAGE');
      confirmDialog.componentInstance.margin = false;
      confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
  
      confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
        result();
        confirmDialog = null;
      });
  }
  dateHidden() {
    return this.reportType != 'studentHistory' && this.reportType != 'dictationHistory' && this.reportType != 'evaReadingText'
  }
  showStudentHistory() {
    if (moment(this.selectedDay_hasta).isSameOrAfter(moment(this.selectedDay_desde))) {
      var reportName = "studentHistory";
      var parameters = [{
        idUser: this.student.Id
      }, {
        fechaInicio: moment(this.selectedDay_desde).format('YYYY-MM-DD')
      }, {
        fechaFin: moment(this.selectedDay_hasta).format('YYYY-MM-DD')
      }];
      this.htmlCode = null;
      this.reportService.getHtml(reportName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
        result => {
          this.successGetHtml(result);
        },
        error => {
          this.errorCallback();
        }
      );
      this.loading = true;
      this.expandControls = false;
    }
  }

  showEvaReadingText() {
    if (moment(this.selectedDay_hasta).isSameOrAfter(moment(this.selectedDay_desde))) {
      var reportName = "evaReadingText";
      var parameters = [{
        idUser: this.student.Id
      }, {
        fechaInicio: moment(this.selectedDay_desde).format('YYYY-MM-DD')
      }, {
        fechaFin: moment(this.selectedDay_hasta).format('YYYY-MM-DD')
      }];
      this.htmlCode = null;
      this.reportService.getHtml(reportName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
        result => {
          this.successGetHtml(result);
        },
        error => {
          this.errorCallback();
        }
      );
      this.loading = true;
      this.expandControls = false;
    }
  }

  showDictationHistory() {
    if (moment(this.selectedDay_hasta).isSameOrAfter(moment(this.selectedDay_desde))) {
      var reportName = "dictationHistory";
      var parameters = [{
        idUser: this.student.Id
      }, {
        fechaInicio: moment(this.selectedDay_desde).format('YYYY-MM-DD')
      }, {
        fechaFin: moment(this.selectedDay_hasta).format('YYYY-MM-DD')
      }];
      this.htmlCode = null;
      this.reportService.getHtml(reportName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
        result => {
          this.successGetHtml(result);
        },
        error => {
          this.errorCallback();
        }
      );
      this.loading = true;
      this.expandControls = false;
    }
  }

  showStudentRegularity() {
    let reportName = "studentRegularity";
    let parameters = [{
      usser: this.student.Id
    }, {
      year: this.year
    }];
    this.htmlCode = null;
    this.reportService.getHtml(reportName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.successGetHtml(result);
      },
      error => {
        this.errorCallback();
      }
    );
    this.loading = true;
    this.expandControls = false;
  }

  showSessionDetailedReport() {
    let reportName = "studentSessionDetail";
    let parameters = [{
      session: this.currentSessionId
    }];
    this.htmlCode = null;
    this.reportService.getHtml(reportName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.successGetHtml(result);
      },
      error => {
        this.errorCallback();
      }
    );
    this.loading = true;
    this.expandControls = false;
  }

  showSessionReport() {
    let reportName = "studentSession";
    let parameters = [{
      usser: this.student.Id
    }, {
      session: this.currentSessionId
    }];
    this.htmlCode = null;
    this.reportService.getHtml(reportName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.successGetHtml(result);
      },
      error => {
        this.errorCallback();
      }
    );
    this.loading = true;
    this.expandControls = false;
  }

  selectedDayChanged(newDate) {
    this.currentSessionStatus = null;
    this.selectedDay = newDate;
    if (this.currentMonth != this.selectedDay.month() + 1) {
      this.loadSessionMonth();
    } else {
      for (var i = 0; i < this.student.Sessions.length; i++) {
        if (this.selectedDay.isSame(moment(this.student.Sessions[i].ExecutionDate), "day")) {
          if (this.student.Sessions[i].Status == this.SessionStatus.started) this.currentSessionStatus = this.NotDone;
          else if (this.student.Sessions[i].Status == this.SessionStatus.notfinished) this.currentSessionStatus = this.NotFinished;
          else if (this.student.Sessions[i].Status == this.SessionStatus.finished) this.currentSessionStatus = this.Finished;
          else if (this.student.Sessions[i].Status == this.SessionStatus.newSession) this.currentSessionStatus = null;
          this.currentSessionId = this.student.Sessions[i].IdSession;
        }
      }
    }
    this.checkButton();
  }

  loadSessionMonth() {
    this.sessionService.getSessionByUserMonthYear(this.student.Id, this.selectedDay.month() + 1, this.selectedDay.year())
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.successLoadSessionMonth(result);
      },
      error => {
        this.errorCallback();
      }
    )
    this.loading = true;
  }

  successLoadSessionMonth(result) {
    this.loading = false;
    if (this.selectedDay) {
      this.student.Sessions = result;
      this.currentMonth = this.selectedDay.month() + 1; //0 index month   
      this.selectedDayChanged(this.selectedDay);
    }
  }

  successGetHtml(data) {
    this.loading = false;
    this.htmlCode = this.sanitizer.bypassSecurityTrustHtml(data.html);
    setTimeout(() => {
      let heightNoInput = this.appService.reference1;
      let pantalla_inicial = parseFloat("1344px");
      let pantalla_final = (parseFloat(heightNoInput) * 1.4);
      let d = (pantalla_final * 100) / pantalla_inicial;
      d = 100 - d;
      d = d / 100;
      $("#report-frame").contents().find("html").css("zoom", (1 - d) * 1.5);
    }, 100)
  }

  errorCallback() {
    this.loading = false;
    this.expandControls = true;
  }

}
