<div class="main_container" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/esquina-inf-der.svg&quot;)'">
    <section fxLayout="column" fxLayoutAlign="stretch" class="box_options">
        <div class="box_op">
            <div class="title">
                {{'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-EDUCATIONAL.ADMIN-GUIDE.TITLE' | translate}}
            </div>
            <div class="cont">
                <div class="spe_gap" fxLayout="column" fxLayoutAlign="stretch">
                    <div class="text-container">
                        <div [innerHTML]="'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-EDUCATIONAL.ADMIN-GUIDE.TEXT-CONTENT1' | translate">
                        </div>
                        <div class="links">
                            <a href="mailto:info@walinwa.com">info&#64;walinwa.com</a>
                            <a style="display: flex;" href="https://educacion30.app.teenvio.com/v4/public/estadisticas/link/educacion30/7198/3/101863/8ab3ecd6e5d207fc38a3e496e6248dc0/">
                                <i class="whatsapp-icon" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/whatsapp_icon.png&quot;)'"></i>699 833 402</a>
                        </div>
                        <br>
                        {{'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-EDUCATIONAL.ADMIN-GUIDE.TEXT-CONTENT2' | translate}}
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="center center">
                        <button mat-raised-button color="accent" (click)="continue()" class="submit-button fixed_button">
                            {{'START' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>