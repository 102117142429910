import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DictationService } from 'app/services/dictation.service';
import { ExerciseService } from 'app/services/exercise.service';
import { NavigationService } from 'app/services/navigation.service';
import { UserService } from 'app/services/user.service';
import * as moment from 'moment';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
declare var $;

@Component({
    selector: "app-dictation",
    templateUrl: "./dictation.component.html",
    styleUrls: ["./dictation.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class DictationReviewComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
  student: any;
  selectedDayDict: any;
  dictations: any;
  currentMonthDict: any;
  year: any;
  currentDict: any;
  selectedIdClass: any;
  selectedIdUser: any;
  isAdmin: boolean;
  activeButton = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private navigationService: NavigationService,
    private dictationService: DictationService
  ) { 
    this._unsubscribeAll = new Subject();
}

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit() {
        if (this.navigationService.params.IdUser)
            this.selectedIdUser = this.navigationService.params.IdUser;
        if (this.navigationService.params.IdClass)
            this.selectedIdClass = this.navigationService.params.IdClass;
        if (this.navigationService.params.isAdmin)
            this.isAdmin = this.navigationService.params.isAdmin;
        this.selectedDayDict = moment();
        this.currentMonthDict = this.selectedDayDict.month() + 1;
        this.year = this.selectedDayDict.year();
        this.getUser(this.activatedRoute.params["value"].Id);
    }

   getUser(id) {
    this.userService.getUserById(id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        if (result == null) return this.navigationService.exit();
        this.student = result;
        this.getDictations();
      },
      this.errorCallback.bind(this)
    )
  }

    selectedDayChangedDictation(newDate, canDoIt, day) {
        if (day && day.css.includes("isDone")) {
            this.activeButton = true;
        } else {
            this.activeButton = false;
        }
        this.selectedDayDict = newDate;
        this.check_dictado();
    }

  getDictations() {
      this.dictationService.getDictations(this.student?.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe((response) => {
      this.dictations = response;
      this.check_dictado();
    }, (response) => { });
  }
  
  check_dictado() {
    this.currentDict = null
    let dictation = this.selectedDayDict.week() + '_' + this.selectedDayDict.isoWeekday()
    this.dictationService.getDictResult(this.student.Id, dictation)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        if (!result) return 
        this.dictationService.getDictationInfo(this.student.Level, this.selectedDayDict.week(), this.selectedDayDict.isoWeekday())
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
          result => {
            this.currentDict = result
          }
        )
      }
    )

  }

    errorCallback() {
        this.navigationService.exit();
    }

    sixWeeks() {
        return $(".week").length - 1 >= 6;
    }

    startReview() {
        this.navigationService.go(`${this.router.url}/dictation/review/`, {
            user: this.student,
            IdClass: this.selectedIdClass,
            selectedDay: this.selectedDayDict,
            isAdmin: this.isAdmin,
        });
    }
}
