import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExtraRewardService {

  baseUrl = environment.baseApi + '/api/ExtraReward';

  constructor(private http: HttpClient) { }

  getLastMonthReward(idUser) {
    var offset = -(new Date().getTimezoneOffset() / 60);
    var params = { timeZone: offset };
    return this.http.post(this.baseUrl + '/month/' + idUser, params);
  };

  getLastYearPerseverance(idUser) {
    var offset = -(new Date().getTimezoneOffset() / 60);
    var params = { timeZone: offset };
    return this.http.post(this.baseUrl + '/getLastYearPerseverance/' + idUser, params);
  };

  getBirthdayReward(idUser) {
    var offset = -(new Date().getTimezoneOffset() / 60);
    var params = { timeZone: offset };
    return this.http.post(this.baseUrl + '/getBirthdayReward/' + idUser, params);
  };

  getLastBirthday(idUser) {
    return this.http.get(this.baseUrl + '/getLastBirthDay/' + idUser);
  };

  extraRewardViewed(idCoinTransaction) {
    if (idCoinTransaction == null) return;
    return this.http.post(this.baseUrl + '/viewed/' + idCoinTransaction, {});
  };

}
