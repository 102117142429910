<img
    [ngClass]="{
        cuerpo: item.Type == 1,
        orejas: item.Type == 2,
        peloSub: item.Type == 9,
        pelo: item.Type == 3,
        ojo: item.Type == 4,
        boca: item.Type == 5,
        nariz: item.Type == 6,
        camiseta: item.Type == 7,
        cejas: item.Type == 8,
        lentes: item.Type == 10,
        sombreros: item.Type == 11
    }"
    *ngFor="let item of activeItems; let i = index"
    [src]="getImageUrl(item)"
/>
