import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { environment } from 'environments/environment';
import { SharedService } from 'app/core/shared/shared.service';

@Component({
    selector   : 'dialog-continue',
    templateUrl: './dialog-continue.component.html',
    styleUrls  : ['./dialog-continue.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FuseDialogContinueComponent
{
    public message1: string;
    public message2: string;
    public message3: string;
    public textButton: string;
    public title :string;
    public margin:boolean;
    public options;
    public isReport = false;
    public theme = '';//"blue";
    public space : boolean = false;
    public canClose = false
    public active: boolean = true;
    public disableButtons = false;
    public messages = [];

    constructor(
        public dialogRef: MatDialogRef<FuseDialogContinueComponent>,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        public sharedService: SharedService
    )
    {
    }

    selectOption() {
        let element: any = document.getElementById("selectedOption");
        let selectedOptionText = element.value;
        let selectedOption = this.options.find(o => o.text == selectedOptionText);
        this.dialogRef.close(selectedOption.callback)
    }

}
