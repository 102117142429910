import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { AuthService } from "app/core/guards/auth.service";
import { ExerciseService } from "app/services/exercise.service";
declare var $;

@Component({
    selector: "ex-orden",
    templateUrl: "./orden.component.html",
    styleUrls: ["./orden.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class OrdenComponent implements OnInit, OnDestroy {
    @Input() review;
    @Input() show_errors;
    @Input() controlSession : boolean = false;
    @Input() slide : boolean = false; @Input() current;
    exercise;
    finished = false;
    leftWords = [];
    rightWords = [];
    draggingElement;

    elementsOk;
    elementsErr;

    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case "SHOW_SOLUTION":
                if (!this.review) {
                    setTimeout(this.ShowAnswersTimeOut.bind(this), 100);
                    break;
                } else {
                    setTimeout(this.showSolution.bind(this), 100);
                    break;
                }
            case "SHOW_ANSWER":
                setTimeout(this.ShowAnswer.bind(this), 100)
                break;
            case "SHOW_CUSTOM":
                this.ShowAnswer();
                break;
        }
    }

    constructor(private exerciseService: ExerciseService) {
        this.exercise = this.exerciseService.getExerciseDefinition();
    }
    ngOnDestroy() {
    }

    ngOnInit() {
        this.FillVariables();
        if (this.review) {
            this.elementsOk = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsOK
            );
            this.elementsErr = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsERR
            );
            if (this.show_errors == false) {
                this.showSolution();
            } else {
                this.ShowAnswer();
            }
        } else {
            this.exerciseService.initInteract({
                onmove: (event) => {
                    this.draggingElement = event.target;
                },
                drop: (event) => {
                    if (this.finished) return;
                    let element = this.draggingElement;
                    let bin = event.target;
                    var binColumn = bin.getAttribute("column");
                    var elementColumn = element.getAttribute("column");
                    var binIndex = bin.getAttribute("index");
                    var elementIndex = element.getAttribute("index");

                    //Drop on right from left
                    if (binColumn == "right" && elementColumn == "left") {
                        if (this.rightWords[binIndex].word == "") {
                            this.rightWords[binIndex].word =
                                this.leftWords[elementIndex].word;
                            this.leftWords[elementIndex].word = "";
                        } else {
                            var aux = this.rightWords[binIndex].word;
                            this.rightWords[binIndex].word =
                                this.leftWords[elementIndex].word;
                            this.leftWords[elementIndex].word = aux;
                        }
                    }

                    //Drop on left from right
                    if (binColumn == "left" && elementColumn == "right") {
                        if (this.leftWords[binIndex].word == "") {
                            this.leftWords[binIndex].word =
                                this.rightWords[elementIndex].word;
                            this.rightWords[elementIndex].word = "";
                        } else {
                            var aux = this.leftWords[binIndex].word;
                            this.leftWords[binIndex].word =
                                this.rightWords[elementIndex].word;
                            this.rightWords[elementIndex].word = aux;
                        }
                    }

                    //Drop on left from right
                    if (binColumn == "right" && elementColumn == "right") {
                        var aux = this.rightWords[binIndex].word;
                        this.rightWords[binIndex].word =
                            this.rightWords[elementIndex].word;
                        this.rightWords[elementIndex].word = aux;
                    }
                    //Drop on left from right
                    if (binColumn == "left" && elementColumn == "left") {
                        var aux = this.leftWords[binIndex].word;
                        this.leftWords[binIndex].word =
                            this.leftWords[elementIndex].word;
                        this.leftWords[elementIndex].word = aux;
                    }

                    this.ValidateResult();
                },
            });
        }
    }

    ValidateResult() {
        //First check if finished
        var done = true;
        for (let i = 0; i < this.rightWords.length; i++) {
            this.rightWords[i].answered = null;
            if (this.rightWords[i] == null || this.rightWords[i].word == "")
                done = false;
        }
        var correct = true;
        for (let i = 0; i < this.rightWords.length; i++) {
            let value = this.rightWords[i];
            let key = i;
            if (this.exercise.orden[key] != value.word) {
                if (value.word != "") {
                    correct = false;
                    this.rightWords[key].answered = false;
                    this.exerciseService.answer(
                        key,
                        false,
                        this.leftWords.length
                    );
                }
            } else {
                this.rightWords[key].answered = true;
                this.exerciseService.answer(key, true, this.leftWords.length);
            }
        }
        if (correct && done) {
            this.finished = true;
            // this.$emit("timer-stop");
            // this.$emit('exercise-solved');
        }
    }

    FillVariables() {
        this.leftWords = []
        this.rightWords = []
        for (let i = 0; i < this.exercise.orden.length; i++) {
            this.leftWords.push({
                word: this.exercise.palabras[i],
                answered: null,
            });
            this.rightWords.push({ word: "", answered: null });
        }
    }
    showSolution() {
        this.leftWords = [];
        this.exercise.orden.forEach((value, key) => {
            this.leftWords.push({ word: "", answered: null });
            if (value != this.rightWords[key])
                this.rightWords[key] = { word: value, answered: true };
        });
    }
    ShowAnswersTimeOut() {
        this.leftWords = [];
        this.exercise.orden.forEach((value, key) => {
            this.leftWords.push({ word: "", answered: null });
            if (value != this.rightWords[key])
                this.rightWords[key] = { word: value, answered: null };
        });
    }
    ShowAnswer() {
        this.FillVariables()
        for (let i = 0; i < this.exercise.orden.length; i++) {
            if (this.elementsErr.includes(i)) {
                this.leftWords[i].word = this.exercise.orden[i];
                this.leftWords[i].answered = false;
            } else {
                this.leftWords[i].word = "";
                this.leftWords[i].answered = null;
                if (this.elementsOk.includes(i)) {
                    this.rightWords[i].word = this.exercise.orden[i];
                    this.rightWords[i].answered = true;
                }
                else {
                    this.leftWords[i].word = this.exercise.orden[i];
                }
            }
        }
    }

}
