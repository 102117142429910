<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} ">
    </span>
</div>

<div class="ex-sopa spe_gap" [hidden]="!data_loaded" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" fxLayout="column"
    fxLayoutAlign="stretch">

    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div class="wordsContainer">
        <div class="items_container spe_gap contenido_sopa_muestra" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="stretch">
            <div fxFlex="" fxFlex.lt-md="0 0 auto" class="" fxLayout="row" fxLayoutAlign="center center">
                <div class="juego" [ngClass]="{'juego-control': controlSession && !slide}"
                    [id]="'juego' + this.numero_random"></div>
            </div>
            <div fxFlex="15rem" fxFlex.lt-md="0 0 auto" class="palabras" [ngClass]="{'palabras-control': controlSession && !slide, 'palabras-slide': slide}"
                [id]="'Palabras' + this.numero_random"></div>
        </div>
    </div>
</div>
<!--  -->
<!-- <div class="ex-sopa" [hidden]="!data_loaded" [ngStyle]="{'pointer-events': review ? 'none;' : ''}">   <h2 *ngIf="controlSession" class="doc-number">{{current + 1}}</h2>
    <div *ngIf="!controlSession" class="exerciseTheme">
        <h1 [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></h1>
    </div>
    <div class="text-center exerciseTitle" [ngClass]="{'exerciseTitle-slide': slide}">
        <p   [innerHtml]="exercise.enunciado"></p>
    </div>
    <div class="contenido_sopa_muestra" [ngClass]="{'contenido-sopa-muestra-slide': slide}" [ngStyle]="controlSession && !slide? {'height': '34em'} :  !controlSession && !slide ? {'height':'45em'} :{}" >
        <div class="sopa-muestra" [ngClass]="{'is-ctl-session': controlSession && !slide }">
            <div class="juego" [ngClass]="{'juego-control': controlSession && !slide}" [id]="'juego' + this.numero_random"></div> 
        </div>
        <div class="palabras" [ngClass]="{'palabras-control': controlSession && !slide, 'palabras-slide': slide}" [id]="'Palabras' + this.numero_random"></div>
    </div>
</div> -->