import { Component, Input, OnInit, OnDestroy, HostListener, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'app/core/guards/auth.service';
import { ExerciseService } from 'app/services/exercise.service';
declare var $

@Component({
  selector: 'ex-flechas',
  templateUrl: './flechas.component.html',
  styleUrls: ['./flechas.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FlechasComponent implements OnInit, OnDestroy {

  @Input() review
  @Input() show_errors;
  @Input() controlSession : boolean = false;
  @Input() slide : boolean = false; @Input() current;
  exercise;
  draggingElement
  ok = []
  error = []
  finished = false
  numOk = 0
  numErr = 0

  elementsOk
  elementsErr
  leftWords
  rightWords
  leftValidation
  rightValidation
  opciones = 0

    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case "SHOW_SOLUTION":
                this.ShowSolution();
                break;
            case "SHOW_ANSWER":
                this.ShowAnswer();
                break;
            case "SHOW_CUSTOM":
                this.exercise = this.exerciseService.getExerciseDefinition();
                this.set_answers_null()
                break;
        }
    }

  constructor(
    private exerciseService: ExerciseService
  ) {
    this.exercise = this.exerciseService.getExerciseDefinition();
  }
    ngOnDestroy(){      
    }

  ngOnInit() {
    this.set_answers_null()
    this.FillExercise()
    if (this.review) {
      this.elementsOk = JSON.parse(this.exerciseService.getCurrentExercise().ElementsOK)
      this.elementsErr = JSON.parse(this.exerciseService.getCurrentExercise().ElementsERR)
      if (this.show_errors == false) {
        this.ShowSolution();
      } else if (this.show_errors == true) {
        this.ShowAnswer();
      } else {
        this.ShowAnswer();
      }
    } else {
    }
  }

  set_answers_null() {
    for (var i = 0; i < this.exercise.palabras.length; i++) {
      this.exercise.palabras[i].answered = null;
    }
  }

  FillExercise() {
    for (var i = 0; i < this.exercise.palabras.length; i++) {
      this.exercise.palabras[i].palabraAux = this.exercise.palabras[i].palabra;
    }
  }

  validar(palabra, opcion) {
    if (this.finished) return;
    if (palabra.opcion == opcion) {
      palabra.answered = true;
      this.exerciseService.answer(this.exercise.palabras.indexOf(palabra), true, this.exercise.palabras.length);
      this.numOk++;
    } else {
      palabra.answered = false;
      this.exerciseService.answer(this.exercise.palabras.indexOf(palabra), false, this.exercise.palabras.length);
      this.numErr++;
    }
    if (this.numOk == this.exercise.palabras.length) {
      // this.$emit("timer-stop");
      this.finished = true;
      // this.$emit('exercise-solved');
    }
  }

ShowSolution() {
    for (var i = 0; i < this.exercise.palabras.length; i++) {
        this.exercise.palabras[i].answered = true;
    }
}

  ShowAnswer() {
    var oks = this.elementsOk
    var errors = this.elementsErr
    for (var i = 0; i < this.exercise.palabras.length; i++) {
      this.exercise.palabras[i].answered = null;
    }
    for (var i = 0; i < errors.length; i++) {
      for (var j = 0; j < this.exercise.palabras.length; j++) {
        if (this.exercise.palabras[j].idPalabra == this.exercise.palabras[errors[i]].idPalabra)
          this.exercise.palabras[j].answered = false;
      }
    }
    for (var i = 0; i < oks.length; i++) {
      for (var j = 0; j < this.exercise.palabras.length; j++) {
        if (this.exercise.palabras[j].idPalabra == this.exercise.palabras[oks[i]].idPalabra && this.exercise.palabras[j].answered == null)
          this.exercise.palabras[j].answered = true;
      }
    }
  }

}
