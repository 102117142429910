import { AppService } from './../../../../../app.service';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'app/services/auth.service';
import { NavigationService } from 'app/services/navigation.service';
import { SchoolService } from 'app/services/school.service';
import { UserService } from 'app/services/user.service';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { ClassService } from 'app/services/class.service';
import { ZoneConfig } from 'app/core/shared/state/models/Class/zone-config.model';
import { TranslateService } from '@ngx-translate/core';
declare var $;
@Component({
    selector: 'app-zone',
    templateUrl: './zone.component.html',
    styleUrls: ['./zone.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ZoneConfigComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
    classes;
    selectedClass = null;
    form;
    isIpad = false;
    SessionMyZoneConditions = [
        { value: 0, desc: this._translateService.instant('SCHOOL.TEACHER.CONFIG.SESSIONCONDITIONS-NO-BLOCK') },
        { value: 1, desc: this._translateService.instant('SCHOOL.TEACHER.CONFIG.SESSIONCONDITIONS-FINISH-DAILY') },
        { value: 2, desc: this._translateService.instant('SCHOOL.TEACHER.CONFIG.SESSIONCONDITIONS-FINISH-STARTED') },
        { value: 3, desc: this._translateService.instant('SCHOOL.TEACHER.CONFIG.SESSIONCONDITIONS-FINISH-ALL') }
    ]

    constructor(
        public schoolService: SchoolService,
        private userService: UserService,
        private navigationService: NavigationService,
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private matDialog: MatDialog,
        private appService: AppService,
        private classService: ClassService,
        private _translateService: TranslateService
    ) {
        this._unsubscribeAll = new Subject();
        this.selectedClass = 0;
        this.form = this.formBuilder.group({
            SessionMyZoneCondition: [0],
            DictationMyZoneCondition: [false],
            ComprensionMyZoneCondition: [false],
            MemoryMyZoneCondition: [false],
            TestMyZoneCondition: [false]
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit(): void {
        if (!this.authService.check()) return;
        this.getClasses();
        if (this.appService.mobileDetect().IsIPad) {
            this.isIpad = true;
        }
    }

    getClasses() {
        this.userService.getClassesByIdTutor(this.authService.currentUser.Id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                result => {
                    this.classes = result;
                    this.classes.sort(function (a, b) {
                        return a.Level - b.Level;
                    });
                    if (!this.selectedClass) this.changeClass();
                    setTimeout(() => {
                        $('#select-class').val(this.selectedClass);
                    }, 0);
                },
                error => {
                    this.navigationService.exit();
                });
    }

    save() {
        let zoneConfig: ZoneConfig = {
            IdUser: this.authService.currentUser.Id,
            IdClass: this.selectedClass,
            SessionMyZoneCondition : this.form.value.SessionMyZoneCondition,
            DictationMyZoneCondition : this.form.value.DictationMyZoneCondition,
            ComprensionMyZoneCondition : this.form.value.ComprensionMyZoneCondition,
            MemoryMyZoneCondition : this.form.value.MemoryMyZoneCondition,
            TestMyZoneCondition : this.form.value.TestMyZoneCondition,
        }
        this.classService.saveZoneConfig(zoneConfig)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                result => {
                    let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
                        disableClose: true
                    });
                    confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.CONFIG.SUCCESS-SAVE-CONFIG-TITLE');
                    confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.CONFIG.SUCCESS-SAVE-CONFIG-MESSAGE');
                    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
                    confirmDialog.componentInstance.margin = false;
                    confirmDialog.afterClosed()
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(result => {
                            confirmDialog = null;
                            this.getClasses();
                        });
                },
                error => {
                    let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
                        disableClose: true
                    });
                    confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.CONFIG.ERROR-SAVE-CONFIG-TITLE');
                    confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.CONFIG.ERROR-SAVE-CONFIG-MESSAGE');
                    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
                    confirmDialog.componentInstance.margin = false;
                    confirmDialog.afterClosed()
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(result => {
                            confirmDialog = null;
                        });
                }
            )
    }
    sameValues() {
        if (!this.classes) return;
        if (this.classes.length == 0) return;
        let values1 = {
            SessionMyZoneCondition: this.classes[0].SessionMyZoneCondition ?? 0,
            DictationMyZoneCondition: this.classes[0].DictationMyZoneCondition,
            ComprensionMyZoneCondition: this.classes[0].ComprensionMyZoneCondition,
            MemoryMyZoneCondition: this.classes[0].MemoryMyZoneCondition,
            TestMyZoneCondition: this.classes[0].TestMyZoneCondition,
        };
        for (let cls of this.classes) {
            let values2 = {
                SessionMyZoneCondition: cls.SessionMyZoneCondition,
                DictationMyZoneCondition: cls.DictationMyZoneCondition ?? 0,
                ComprensionMyZoneCondition: cls.ComprensionMyZoneCondition,
                MemoryMyZoneCondition: cls.MemoryMyZoneCondition,
                TestMyZoneCondition: cls.TestMyZoneCondition,
            };
            if (JSON.stringify(values1) != JSON.stringify(values2))
                return false;
        }
        return true;
    }

    changeClass() {
        setTimeout(() => {
            this.selectedClass = parseFloat(this.selectedClass.toString());
        }, 100);
        let cls;
        if (this.selectedClass == 0) {
            if (this.sameValues()) {
                cls = this.classes[0];
            } else {
                this.form.patchValue({
                    SessionMyZoneCondition: 0,
                    DictationMyZoneCondition: false,
                    ComprensionMyZoneCondition: false,
                    MemoryMyZoneCondition: false,
                    TestMyZoneCondition: false,
                });
                return;
            }
        } else {
            cls = this.classes.find((c) => c.IdClass == this.selectedClass);
        }
        this.form.patchValue({
            SessionMyZoneCondition: cls.SessionMyZoneCondition ?? 0,
            DictationMyZoneCondition: cls.DictationMyZoneCondition,
            ComprensionMyZoneCondition: cls.ComprensionMyZoneCondition,
            MemoryMyZoneCondition: cls.MemoryMyZoneCondition,
            TestMyZoneCondition: cls.TestMyZoneCondition,
        });
    }
}
