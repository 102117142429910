import { ReadingText } from 'app/core/shared/state/models/Reading-text/reading-text.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpParams, HttpEvent, HttpHeaderResponse, HttpResponse, HttpErrorResponse, HttpUserEvent, HttpProgressEvent } from '@angular/common/http';
import { Location } from '@angular/common';
import { environment } from 'environments/environment';
import { CalendarReadingText } from 'app/core/shared/state/models/Reading-text/reading-text-month.model';
import { ReadingTextResult } from 'app/core/shared/state/models/Reading-text/reading-text-result.model';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ReadingTextService {

    baseUrl = environment.baseApi + '/api/ReadingText';
    MonthlyReadingTexts: CalendarReadingText[] = null;
  
  private s_reload_monthly_reading = new BehaviorSubject<boolean>(false);
  reload_monthly_reading = this.s_reload_monthly_reading.asObservable();
  constructor(private http: HttpClient) {
  }

    getReadingTextByMonth(idUser : number, month: number, year: number, level: number, idRole: number, creationDate: any) {
    return this.http.post<CalendarReadingText[]>(this.baseUrl + '/getReadingTextByMonth/', { idUser, month, year, level, idRole, creationDate });
    }

  getReadingTextByLevel(level, month, year) {
    return this.http.post<CalendarReadingText[]>(this.baseUrl + '/getReadingTextByLevel/', { level, month, year });
  }

  getReadingTextByid(IdReadingText : number, IdUser : number, selectedDate: number) {
    return this.http.post<ReadingText>(this.baseUrl + '/getReadingTextByid/', { IdReadingText, IdUser, selectedDate });
  }

  answer(IdReadingText: number, IdQuestion: number, IdAnswer: number, IdUser: number, IsCorrect: boolean) {
    return this.http.post(this.baseUrl + '/answer/', { IdReadingText, IdQuestion, IdAnswer, IdUser, IsCorrect });
  }

  start(IdReadingText : number, IdUser : number, TextDate: any, NumExercises : number, IdQuestion: number, IdAnswer: number, IsCorrect: boolean) {
    return this.http.post(this.baseUrl + '/start/', { IdReadingText, IdUser, TextDate, NumExercises, IdQuestion, IdAnswer, IsCorrect });
  }
  restart(IdReadingText: number, IdUser: number, UserAnswersTotal: any, UserAnswersOk: number, Retries: number) {
    return this.http.post(this.baseUrl + '/restart/', { IdReadingText, IdUser, UserAnswersTotal, UserAnswersOk, Retries });
  }

  getScore(IdReadingText: number, IdUser: number, TextDate: any) {
    return this.http.post<ReadingTextResult>(this.baseUrl + '/getScore/', { IdReadingText, IdUser, TextDate });
  }
    changeReloadMonthlyReading(reload_monthly_reading: boolean) {
        this.s_reload_monthly_reading.next(reload_monthly_reading);
 }

}
