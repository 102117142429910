<div class="walinwa-box walinwa-box-green" *ngIf="dataLoaded" fxNgClass.gt-sm="scroll_vertical">

    <div class="walinwa-box-header">
        {{'SCHOOL.AUTH-TEACHER-TITLE' | translate}} '{{ student.UserName }}'
    </div>

    <div class="walinwa-box-content">
        <div fxLayout="column" fxLayoutAlign="stretch" class="spe_gap">
            <div>
                {{'SCHOOL.AUTH-TEACHER-MESSAGE' | translate}}
            </div>
            <div>
                <label class="font_blue">{{'EMAIL' | translate}}</label>
                <div class="ww_field_spe">
                    <input [(ngModel)]="email" type="email">
                </div>
                <mat-error *ngIf="error">
                    {{errorDescription}}
                </mat-error>
            </div>
            <div>
                <mat-checkbox [(ngModel)]="correoConfirmacion">
                    {{'SCHOOL.SEND-PASSWORD-EMAIL' | translate}}
                </mat-checkbox>
            </div>
            <div>
                <div class="ww_table_rwd">
                    <table *ngIf="authUsers.length>0" class="walinwa-table" style="border-top: 0.05em solid var(--gris);">
                        <tbody>
                            <tr [ngClass]="{'selected' : authUser == selectedAuthUser}" (click)="selectAuthUser(authUser)"
                                *ngFor="let authUser of authUsers">
                                <td class="text-center">{{ authUser }}</td>
                                <td style="width: 50px;">
                                    <img (click)="delete()" class="spe_ico_exam" src="https://walinwa.blob.core.windows.net/images/School/ico-trash.svg?ngsw-bypass=true">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="spe_gap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center">

            <button [disabled]="calling" (click)="addAuthUser()" class="new_but azul">
                {{'SAVE' | translate}}
            </button>

        </div>

    </div>

</div>