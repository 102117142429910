<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="marcar spe_gap" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" fxLayout="column"
    fxLayoutAlign="stretch">

    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <!-- 2da Caja de mensaje -->
    <div class="ruleContainer" [hidden]="exercise.regla == null">
        <div [ngClass]="{ verde: !review}" class="exerciseRule">
            <span class="regla" [innerHtml]="exercise.regla"></span>
        </div>
    </div>
    <div id="TextContainer" class="wordsContainer">
        <div class="items_container">
            <div *ngFor="let palabra of exercise.palabras; let i = index" class="divBox wordBox0Col"
                [ngClass]="{morado: !review || controlSession, 'wordOk': palabra.answered == true, 'wordError': palabra.answered == false, 'wordNotAnswered' : palabra.notAnserwed == true, shake:  (palabra.answered == false || palabra.notAnserwed == true) && !review }"
                [attr.id]="i" (click)="validateClick(i)">
                <span class="wordBox">{{ palabra.palabraAux }}</span>
            </div>
        </div>
    </div>
</div>