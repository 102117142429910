<div class="walinwa-box walinwa-box-orange">
    <div class="walinwa-box-content" [hidden]="expandControls == false">
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxNgClass.gt-sm="spe_gap">
            <div fxFlex="0 0 calc(50% - 1 rem)" fxFlex.lt-md="100%">
                <label class="font_blue">{{'SCHOOL.TEACHER.TRACKING.REPORT.TITLE' | translate}}</label>
                <div class="ww_field_spe">
                    <select (change)="checkButton()" [(ngModel)]="reportType" [ngModelOptions]="{standalone: true}">
                        <option [value]="reportType.name" *ngFor="let reportType of reportTypes"
                            [hidden]="(selectedClassId == -1 && !reportType.alumno) || (reportType.admin && !admin) || (reportType.readingText && !enableReadingText)">
                            {{ reportType.desc }}
                        </option>
                    </select>
                </div>
            </div>
            <div fxFlex="0 0 calc(50% - 1 rem)" fxFlex.lt-md="100%" [hidden]="reportType != 'studentRegularity'">
                <label class="font_blue">{{'SCHOOL.TEACHER.TRACKING.REPORT.YEAR' | translate}}</label>
                <div class="ww_field_spe">
                    <select [(ngModel)]="year" [ngModelOptions]="{standalone: true}">
                        <option [value]="year.name" *ngFor="let year of years">
                            {{ year.desc }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxNgClass.gt-sm="spe_gap" [hidden]="dateHidden()">
            <div fxFlex="0 0 calc(50% - 1 rem)" fxFlex.lt-md="100%">                
                <mat-form-field class="walinwa-100 less_top_margin_field">
                    <label class="font_blue">{{'SCHOOL.TEACHER.TRACKING.REPORT.FROM' | translate}}</label>
                    <input matInput [matDatepicker]="picker1" [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="selectedDay_desde">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
            </div>
            <div fxFlex="0 0 calc(50% - 1 rem)" fxFlex.lt-md="100%">                
                <mat-form-field class="walinwa-100 less_top_margin_field">
                    <label class="font_blue">{{'SCHOOL.TEACHER.TRACKING.REPORT.TO' | translate}}</label>
                    <input matInput [matDatepicker]="picker2" [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="selectedDay_hasta">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="" [hidden]="reportType != 'studentSession' && reportType != 'sessionDetail'">
            <calendar id="student-report-calendar" *ngIf="student" class="calendario dinamico azul walCalendar ww_customCalendar" [parent]="this" [fullDay]="false" [currentUser]="student"
                [selectedDay]="selectedDay" [currentMonth]="currentMonth"></calendar>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center" class="spe_gap">
            <button (click)="changeControls()" [hidden]="expandControls == false" [disabled]="disableActionButton"
            class="new_but azul">
            {{'SCHOOL.TEACHER.TRACKING.REPORT.SHOW-REPORT' | translate}}
            </button>
        </div>
    </div>
</div>

<div class="walinwa-box-modal-overlay" *ngIf="expandControls == false">
    <div class="walinwa-report">
        <div class="walinwa-box-modal-header">
            {{'SCHOOL.TEACHER.TRACKING.REPORT.REPORT-SECTION' | translate}}
            <div class="walinwa-box-modal-header-icons">
                <img src="https://walinwa.blob.core.windows.net/images/but-home.svg?ngsw-bypass=true" title="Volver al inicio" (click)="changeControls()"
                    [hidden]="expandControls == true" />
            </div>
        </div>
        <div *ngIf="loading" class="walinwa-box-modal-container walinwa-box-modal-container-loading">
            <img src="https://walinwa.blob.core.windows.net/images/loading.gif?ngsw-bypass=true" />
        </div>
        <iframe *ngIf="!loading" id="report-frame" [attr.srcdoc]="htmlCode"
            class="walinwa-box-modal-container"></iframe>
    </div>
</div>