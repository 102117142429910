
<div class="header">
    <i class="fa fa-angle-left" (click)="previous()"></i>
    <!-- <span>{{month.format("MMMM, YYYY")}}</span> --><span>{{month.format("MMMM")}} {{'COMPONENTS.CALENDAR.DATE-CONNECTOR' | translate }} {{month.format("YYYY")}}</span>
    <i class="fa fa-angle-right" (click)="next()"></i>
</div>
<div *ngIf="fullDay" class="week names">
    <span class="day">{{'DAYS.MONDAY'| translate}}</span>
    <span class="day">{{'DAYS.TUESDAY'| translate}}</span>
    <span class="day">{{'DAYS.WEDNESDAY'| translate}}</span>
    <span class="day">{{'DAYS.THURSDAY'| translate}}</span>
    <span class="day">{{'DAYS.FRIDAY'| translate}}</span>
    <span class="day">{{'DAYS.SATURDAY'| translate}}</span>
    <span class="day">{{'DAYS.SUNDAY'| translate}}</span>
</div>
<div *ngIf="!fullDay" class="week names">
    <span class="day">L</span>
    <span class="day">M</span>
    <span class="day">X</span>
    <span class="day">J</span>
    <span class="day">V</span>
    <span class="day">S</span>
    <span class="day">D</span>
</div>
<div class="week" *ngFor="let week of weeks">
    <span class="day" [ngClass]="{ 'notDone': day.notDone, 'disabled-month': day.disabledMonth && day.isCurrentMonth, 'different-month': !day.isCurrentMonth, 'isDone' : day.isDone, 'isPending':!day.isDone && isPending(day) && day.isCurrentMonth, 'currentSession': day.currentSession, 'selected': day.date.isSame(selectedDay), 'low-score': day.lowScore}" (click)="select(day)" *ngFor="let day of week.days">{{day.number}}</span>
</div>