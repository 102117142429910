<section fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutAlign.lt-md="stretch" class="spe_gap">
    <div fxFlex="" fxFlex.lt-md="0 0 auto" fxNgClass.gt-sm="scroll_vertical">
        <div class="walinwa-box walinwa-box-green">
            <div class="walinwa-box-header spe_gap" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
                <div>
                    {{'SCHOOL.ADMIN.IMPORT-CLASSROOM.DATA.TITLE' | translate}}
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center" class="spe_gap">
                    <!--img src="https://walinwa.blob.core.windows.net/images/School/ico-descargar-excel.svg?ngsw-bypass=true"
                        fxFlex="2.5rem" class="pointer" /-->
                    <div (click)="expanded ? treeControl.collapseAll() : treeControl.expandAll(); expanded = !expanded;"class="pointer">
                        {{ expanded ? 'Contraer árbol' : 'Expandir árbol'}}
                    </div>
                </div>
            </div>
            <!--div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="0.5rem" class="">
                <img (click)="treeControl.expandAll()" src="https://walinwa.blob.core.windows.net/images/School/ico-descargar-aulas.svg?ngsw-bypass=true" fxFlex="2.5rem" class="pointer" />
                <div>
                    <select [(ngModel)]="expandir" class="download_select">
                        <option value="false">Expandir</option>
                        <option value="true">Contraer</option>
                    </select>
                </div>
            </div-->

            <div class="walinwa-box-content no_padding_tables" fxLayout="column" fxLayoutAlign="stretch">
                <div class="walinwa-box-content">
                    @if (dataReady) {
                        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" style="background-color: transparent !important;">
                            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
                                <button mat-icon-button disabled></button>
                                <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)" (change)="checklistSelection.toggle(node);"></mat-checkbox>
                                <label class="font_blue custom-node">{{ node.item.isTutor ? 'Tutor: ' : ''}}<span style="color:rgba(0, 0, 0, 0.42);">{{ getNodeName(node.item) }}</span></label>
                            </mat-tree-node>  
                            <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                                <button mat-icon-button matTreeNodeToggle>
                                    <mat-icon class="mat-icon-rtl-mirror" data-expand="true">
                                        {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                                    </mat-icon>
                                </button>
                                <mat-checkbox #checkbox [checked]="descendantsAllSelected(node)" [indeterminate]="descendantsPartiallySelected(node)" (change)="itemSelectionToggle(node, checkbox)"></mat-checkbox>
                                @if (node.level === 0) {
                                    <label class="font_blue custom-node">Curso:</label>
                                    <mat-form-field class="">                                        
                                        <mat-select [(ngModel)]="node.walinwaLevel">
                                            @for (class of getCourses() | async | sortBy: 'asc': 'Level'; track $index) {
                                                <mat-option class="scrollable" [value]="class.Level">{{ class.Name }}</mat-option>
                                            }
                                        </mat-select>
                                    </mat-form-field>
                                    @if ((node.walinwaLevel || 0) <= 0 && touched && (checkbox.checked || importAllSelected))
                                    {
                                        <div><mat-error>{{'FIELD-REQUIRED' | translate }}</mat-error></div>
                                    }
                                    <label class="font_blue custom-node">{{'SCHOOL.ADMIN.IMPORT-CLASSROOM.DATA.CLASS' | translate}} <span style="color:rgba(0, 0, 0, 0.42);">{{ getNodeName(node.item) }}</span></label>
                                } @else {
                                    <label class="font_blue custom-node">{{ getNodeName(node.item) }}</label>
                                }
                            </mat-tree-node>
                        </mat-tree>
                    } @else {
                        <div style="text-align: center;">{{'SCHOOL.ADMIN.IMPORT-CLASSROOM.DATA.MESSAGE-LOADING-DATA' | translate}}</div>
                    }
                </div>
            </div>
        </div>
    </div>
    <div fxFlex="12rem" fxFlex.lt-md="0 0 auto" fxNgClass.gt-sm="scroll_vertical">
        <div class="ww_sidebar_inner">
            <div (click)="importAllClassroomData()"  fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap">
                <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                    <img src="https://walinwa.blob.core.windows.net/images/School/seleccionar-todo.svg?ngsw-bypass=true" />
                </div>
                <div fxFlex="" [innerHTML]="'SCHOOL.ADMIN.IMPORT-CLASSROOM.DATA.IMPORT-ALL' | translate"></div>
            </div>
            <div (click)="importSelectedClassroomData()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap">
                <div fxFlex="3rem" fxFlex.lt-md="3.6rem">
                    <img src="https://walinwa.blob.core.windows.net/images/School/importar-seleccion.svg?ngsw-bypass=true" />
                </div>
                <div fxFlex="" [innerHTML]="'SCHOOL.ADMIN.IMPORT-CLASSROOM.DATA.IMPORT-SELECTION' | translate"></div>
            </div>            
        </div>
    </div>
</section>