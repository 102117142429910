import { Injectable } from '@angular/core';
import { StoreConfig, Store } from '@datorama/akita';

import { Class } from './models/class.model';
import { User } from './models/user.model';
import { School } from './models/school.model';

export interface DataState {
  class: Class,
  user: User,
  school: School
  students: any,
  password: any,
}

export function createInitialDataState(): DataState {
  return {
    class: null,
    user: null,
    students: null,
    school: null,
    password: null
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'data' })
export class DataStore extends Store<DataState>  {
  constructor() {
    super(createInitialDataState());
    }

    resetData() {
        this.update(createInitialDataState());
      }
}