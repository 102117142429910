import { environment } from 'environments/environment';
import { FileRequest } from 'app/core/shared/state/models/file/file.model';
import { FileService } from 'app/services/file.service';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { CivilizationService } from 'app/services/civilization.service';
import { ControlSessionService } from 'app/services/control-session.service';
import { CourseService } from 'app/services/course.service';
import { ExerciseService } from 'app/services/exercise.service';
import { NavigationService } from 'app/services/navigation.service';
import { SessionService } from 'app/services/session.service';
import { UserService } from 'app/services/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SharedService } from 'app/core/shared/shared.service';

@Component({
  selector: 'app-demand',
  templateUrl: './demand.component.html',
  styleUrls: ['./demand.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DemandComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;
  initialExercise
  numEjercicios = 1;
  userAdmin = true;
  civilizations = [];
  horarios = [];
  horario = null;
  idCivilization = 0;
  courses = [];
  Level = 0;
  IdSessionToClone;
  exercise: any = {
    SessionOrder: 1,
    IdExerciseType: 10000,
    IdThema: 1,
    StudentLevel: 1,
    IdSubthema: 0,
    MinLevel: 6,
    MaxLevel: 12,
    MinNumWordOcurrences: 20,
    MinBookLevel: 6,
    MaxBookLevel: 18
  };
  showUpdateButton = true;
  exerciseOk = false;
  error;
  onDemandAdmin
    loading: boolean;

  constructor(
    private authService: AuthService,
    private navigationService: NavigationService,
    private courseService: CourseService,
    private exerciseService: ExerciseService,
    private civilizationService: CivilizationService,
    private sessionService: SessionService,
    private userService: UserService,
    private controlSessionService: ControlSessionService,
    public sharedService: SharedService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    }

  testMethod(){
    let arr= [10010,11010,11015,16050,30010,13810,11110,10510,11510,15430,11850,15510,15810,16080,30510,10810,11210,10551,10612,10713,16010,
      12110,10410,11310,16060,11910,15610,16040,30610,15710,30710,15910,30810,17010,16210,16310,16410,30910,16510,12510,16610,16710,16090,
      16110,16100,17100,17200,15750,31310,30750,31410,15620,31320,10875,30950,10920,17500]
        console.log(arr.length)
        arr.forEach((IdExerciseType, index) => {
            this.exercise['IdExerciseType'] = IdExerciseType
            this.exercise['IdSession'] = 3582187;
            this.exercise['IdUser'] = this.authService.currentUser.Id;
            console.log(index)
            if(index > 0){
              this.exercise['SessionOrder'] = this.exercise['SessionOrder'] + 1;
            }
            this.exerciseService.newExercise(this.exercise)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
              result => {
                  this.controlSessionService.insertExercise(1,result)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe()
              },
              error => {
                this.showUpdateButton = true;
                this.error = 'Unable to create a new exercise: ' + error.error;
              }
            )
        })
    }

  ngOnInit() {
    if (!this.authService.check()) return;
    // if (this.initialExercise) {
    //   this.exercise = (JSON.parse(JSON.stringify(initialExercise)));
    //   this.exercise.SessionOrder = 1;
    //   IdSession: this.authService.currentUser.Sessions[0].IdSession
    // }
      if (this.exerciseService.exercise) {
        this.exercise = this.exerciseService.exercise;
        this.numEjercicios = this.exerciseService.numExercises;
          this.resetSavedExerciseData();
    }
    this.loading = true;
    this.courseService.Courses.pipe(takeUntil(this._unsubscribeAll)).subscribe(courses => {
        this.courses = courses;
    })
    this.civilizationService.getCivilizations()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.successCivlization(result);
      },
      error => {
        this.errorCivilization(error);
      }
    )
    this.sessionService.getAllSchedules()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.successHorarios(result);
      },
      error => {
        this.errorHorarios(error)
      }
    )
    this.sessionService.createTmpSession(this.authService.currentUser.Id, this.authService.currentUser.OnDemand)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.loading = false;
        this.successCreateTmpSessionUser(result);
      },
      error => {
        this.loading = false;
        this.errorHorarios(error);
      }
    )
  }

  resetSavedExerciseData() {
    this.exerciseService.exercise = null;
    this.exerciseService.numExercises = 1;
  }
  next() {
    this.onDemandAdmin = this.userAdmin;
    this.exerciseService.horario = this.horario;
    this.navigationService.go("student/session", {
      session: this.authService.currentUser.Sessions[0]
    });
  }

    logout() {
    this.resetSavedExerciseData();
    this.navigationService.goLogin();
  }

  reset() {
    this.exercise = {
      SessionOrder: 1,
      IdExerciseType: 10000,
      IdThema: 1,
      StudentLevel: 1,
      IdSubthema: 0,
      MinLevel: 6,
      MaxLevel: 12,
      MinNumWordOcurrences: 20,
      MinBookLevel: 6,
      MaxBookLevel: 18
    };
  }

    update() {
        this.showUpdateButton = false;
        this.initialExercise = (JSON.parse(JSON.stringify(this.exercise)));
        this.RemoveDefaultUnusedValues();
        this.exercise['IdSession'] = this.authService.currentUser.Sessions[0].IdSession;
        this.exercise['IdUser'] = this.authService.currentUser.Id;
        if (this.IdSessionToClone) {
            this.resetSavedExerciseData();
            this.sessionService.clone(this.authService.currentUser.Sessions[0].IdSession, this.IdSessionToClone)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(
                    result => {
                        this.sessionService.getSession(this.authService.currentUser.Sessions[0].IdSession)
                            .pipe(takeUntil(this._unsubscribeAll))
                            .subscribe(result => {
                                this.authService.currentUser.Sessions[0] = result
                                this.exerciseOk = true;
                            })
                    },
                    error => {
                        this.showUpdateButton = true;
                        this.error = 'Unable to create a new exercise: ' + error.data.Message;
                    }
                )
        } else {
            this.exerciseService.exercise = this.exercise;
            this.exerciseService.numExercises = this.numEjercicios;
            this.exerciseService.newExercise(this.exercise)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(
                    result => {
                        this.successCallbackUpdate(result)
                    },
                    error => {
                        this.showUpdateButton = true;
                        this.error = 'Unable to create a new exercise: ' + error.error;
                    }
                )
        }
    }

  successCallbackUpdate(result) {
    this.exerciseOk = true;
    this.exercise = result;
    this.authService.currentUser.Sessions[0].Exercises.push(this.exercise);
    this.error = "";
    this.numEjercicios--;

    if (this.numEjercicios > 0) {
      this.exerciseOk = false;
      this.initialExercise.SessionOrder++;
      this.exercise = (JSON.parse(JSON.stringify(this.initialExercise)));
      if (this.exercise) {
        if (this.authService.currentUser.Sessions[0]) {
          this.exercise.IdSession = this.authService.currentUser.Sessions[0].IdSession;
          this.exercise.IdUser = this.authService.currentUser.Id;
          this.exerciseService.newExercise(this.exercise)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
            result => {
              this.successCallbackUpdate(result)
            },
            error => {
              this.showUpdateButton = true;
              this.error = 'Unable to create a new exercise: ' + error.data.Message;
            }
          )
        }
      }
    } else {
      this.authService.currentUser.Level = this.Level;
      this.authService.currentUser.IdCivilizacion = this.idCivilization;
      this.userService.UpdateUserCourseAndCivilization(this.authService.currentUser)
.subscribe(
        result => {
          this.authService.reloadUser(() => {
            this.exerciseOk = true;
          });
        },
        error => {
          this.errorCivilization(error);
        }
      )
    }
  }

  showFieldsQ1Q2() {
    if ((this.exercise.IdExerciseType >= 10000 && this.exercise.IdExerciseType < 20000) || this.exercise.IdExerciseType == 0) return true;
    return false;
  }

  showFieldsQ3Q4() {
    if (this.exercise.IdExerciseType >= 30000 && this.exercise.IdExerciseType < 40000) return true;
    return false;
  }

  updateUserinfo(response) {
    this.authService.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  successCreateTmpSessionUser(response) {
    console.log("successCreateTmpSessionUser", response)
    this.authService.currentUser.Sessions = [];
    response.Exercises = [];
    this.authService.currentUser.Sessions.push(response);
  }

  RemoveDefaultUnusedValues() {
    if ((this.exercise.IdExerciseType >= 10000 && this.exercise.IdExerciseType < 20000) || this.exercise.IdExerciseType == 0) {
      this.exercise.MinBookLevel = null;
      this.exercise.MaxBookLevel = null;
    }
  }

  successCivlization(response) {
    this.civilizations = response;
    this.idCivilization = this.authService.currentUser.IdCivilizacion;
    this.Level = this.authService.currentUser.Level;
  }

  errorCivilization(error) {
    this.navigationService.goLogin();
  }

  successHorarios(response) {
    this.horarios = response;
    this.horario = "mañana";
    // if ($cookies.get("ultimoHorarioOneDemand") != null) {
    //   this.horario = $cookies.get("ultimoHorarioOneDemand");
    // } else {
    //   this.horario = "mañana";
    // }
  }

  errorHorarios(error) {
    this.navigationService.goLogin();
  }

  errorCourse(error) {
    this.navigationService.goLogin();
  }

    PreloadImages(items: string[], onComplete: () => void) {
        let count = items.length;
      
        function pendingImages() {
          count--;
          if (count === 0) {
            onComplete();
          }
        }
      
        for (let i = 0; i < items.length; i++) {
          this.loadImageItem(items, i, pendingImages);
        }
      }
      
      loadImageItem(items: string[], i: number, onComplete: () => void) {
        const img = new Image();
        img.addEventListener("load", () => {
          img.removeEventListener("load", () => {});
          onComplete();
        });
      
        img.src = items[i];
        img.style.visibility = "hidden";
      }

}
