import { CustomSession } from 'app/core/shared/state';
import { Component, Input, OnInit, Sanitizer, OnDestroy,ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ReportService } from 'app/services/report.service';
import { SchoolService } from 'app/services/school.service';
import { SessionService } from 'app/services/session.service';
import { UserService } from 'app/services/user.service';
import { AppService } from 'app/app.service';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { NavigationService } from 'app/services/navigation.service';
import { DateAdapter } from '@angular/material/core';
import { AuthService } from 'app/services/auth.service';
import { CustomizedSessionService } from 'app/services/customized-session.service';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { BlobResponse } from '../../../../core/shared/state/models/blob-response-model';
import { TranslateService } from '@ngx-translate/core';
declare let $;

let exams = [];
let selectedClassId: number;

@Component({
    selector: "school-report",
    templateUrl: "./report.component.html",
    styleUrls: ["./report.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ReportComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  @Input() admin;
  schoolReportControllerSelectedDay = moment().hour(0).minute(0).second(0).millisecond(0);
  NotDone = 0;
  Finished = 1;
  Pending = 2;
  Started = 3;
  NotFinished = 4;
  calling;
  currentSessionId;
  SessionStatus = {
    finished: "FINISHED",
    started: "STARTED",
    newSession: "NEW",
    generating: "GENERATING",
    notfinished: "NOTFINISHED"
  }
  examDate;
  authUsersAvailable = false;
  loading = false;
  dictEnabled;
  specificTheme = null;
  selectedClassId
  authUsers
  informeControl = new UntypedFormControl();
  informeGroups: InformeGroup[] = [
    {
      name: this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.GENERALS'),
      informes: [
        
        { name: 'evaCalifica', desc: this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.EVACALIFICA'), alumno: false },
        { name: 'studentHistory', desc: this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.STUDENTHISTORY'), alumno: true },
      ],
    },
    {
      name: this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.SESIONS'),
      informes: [
        { name: 'evaResumeGroup', desc: this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.EVARESUMEGROUP'), alumno: false },
        { name: 'EvaExamGroup', desc: this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.EVAEXAMGROUP'), alumno: false },
        { name: 'studentStatus', desc: this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.STUDENTSTATUS'), alumno: true },
        { name: 'evaMainGroup', desc: this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.EVAMAINGROUP'), alumno: false },
        { name: 'studentLastWeek', desc: this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.STUDENTLASTWEEK'), alumno: true },
        { name: 'studentEvolution', desc: this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.STUDENTEVOLUTION'), alumno: true },
        { name: 'studentSession', desc: this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.STUDENTSESSION'), alumno: true },
        { name: 'evaCalificaSchool', desc: this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.EVACALIFICASCHOOL'), admin: true },
      ],
    },
    {
      name: this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.DICTATIONS-GROUP'),
      informes: [
        { name: 'dictations', desc: this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.DICTATIONS-REPORT'), alumno: false, dictation : true },
        { name: 'dictationHistory', desc: this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.DICTATIONHISTORY'), alumno: true, dictation : true },
      ],
    },
    {
      name: this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.READINGANDDEDUCTION'),
      informes: [
        { name: 'EvaReadingTextGroup', desc: this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.EVAREADINGTEXTGROUP'), readingText: true },
        { name: 'EvaReadingText', desc: this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.EVAREADINGTEXT'), readingText: true },
      ],
    },
    {
      name: this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.DOWNLOADS'),
      informes: [
        { name: 'studentsZip', desc: this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.STUDENTSZIP'), alumno: false },
        { name: 'historyZip', desc: this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.HISTORYZIP'), alumno: false }
      ],
    },
  ];
  reportType;
  years;
  year;
  classes = [];
  currentMonth = 0;
  selectedClass;
  selectedDay = this.schoolReportControllerSelectedDay;
  selectedDay_desde = moment().locale("es").format();
  selectedDay_hasta = moment().locale("es").format();
  currentSessionStatus = null;
  disableActionButton = false;
  showDownload = false;
  downloading = false;
  studentId = null;
  student = null;
  dataLoaded = false;
  students = [];
  expandControls = true;
  month = this.schoolReportControllerSelectedDay.month() + 1;
  htmlCode = null;
  currentUser;
  enableReadingText;
  enableDeductionText
  xSes = '75 %'
  xDic = '75 %'
  xRead = '75 %'
  xDed = '75 %'
  xHear = '75 %'
  percents = ['10 %','25 %','50 %','75 %','100 %']

  constructor(
    private router: Router,
    private userService: UserService,
    public schoolService: SchoolService,
    private sessionService: SessionService,
    private reportService: ReportService,
    private sanitizer: DomSanitizer,
    private appService: AppService,
    private _matDialog: MatDialog,
    private navigationService: NavigationService,
    private dateAdapter: DateAdapter<Date>,
    private authService: AuthService,
    private customizedSessionService: CustomizedSessionService,
    private _translateService: TranslateService
  ) {
    this._unsubscribeAll = new Subject();
    this.dateAdapter.setLocale("es-ES");
  }

  ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

  ngOnInit(): void {
    if (!sessionStorage.getItem("currentUser")) {
      sessionStorage.removeItem('currentUser');
      sessionStorage.removeItem('adminLogin');
      sessionStorage.removeItem("state");
      sessionStorage.removeItem("state_params");
      sessionStorage.setItem("isLogged", "false");
      this.router.navigateByUrl("login");
      return;
    }
    if (this.admin) {
      this.navigationService.setStateTest("viewReport");
      this.navigationService.enableBackButtonTest(true,'viewReport');
    } else {
      this.navigationService.enableExitButton();
    }
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (!this.authService.check()) return;
    this.reportType = this.informeGroups[0].informes[0].name;
    this.enableReadingText = this.schoolService.school.ReadingText;
    this.dictEnabled = this.schoolService.school.Dictation;
    this.enableDeductionText = this.schoolService.school.DeductionText;
    this.getYears();
    this.getDates();
    this.getClasses();
  }

  dateFilter(date) {
    if(exams === undefined || exams.length == 0)return false;
    let exam = exams.findIndex(e => moment(e.Date).isSame(date) && e.IdClass == selectedClassId)
    return exam > -1
  }
  checkDate(date){
    this.disableForExam(date);
  }
  disableForExam(date = null) {
      if (this.reportType != "EvaExamGroup") return false
      let today = moment(this.examDate);
      let exam;
      if (date) {
        exam = exams.findIndex(e => moment(e.Date).isSame(date, "day") && moment(e.Date).isSame(date, "month") && e.IdClass == selectedClassId)
      }
      else {
        exam = exams.findIndex(e => moment(e.Date).isSame(today, "day") && moment(e.Date).isSame(today, "month") && e.IdClass == selectedClassId)
      }
    if (exam != - 1) this.disableActionButton = false;
    return exam == -1
  }
  getExams() {
    let idList: number[] = []
        this.classes.forEach(x => { idList.push(x.IdClass) });
        idList = idList.sort((a,b) => a - b);
      this.schoolService.getAllExamDates(idList)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe((response: CustomSession[][]) => {
          this.successGetExamDates(response)
      }, () => {
          this.errorCallback(null)
      });
  }

    getYears() {
        this.years = [];
        // [{ name: 2019, desc: '2019' }, { name: 2020, desc: '2020' }]
        let currentYear = new Date().getFullYear();
        for (let i = 1; i >= 0; i--) {
            let year = {
                name: currentYear - i,
                desc: (currentYear - i).toString(),
            };
            this.years.push(year);
        }
        this.year = this.years[this.years.length - 1].name;
    }

    getDates() {
        this.examDate = moment().format();
        this.selectedDay_hasta = moment().format();
        this.selectedDay_desde = moment().startOf("month").format();
    }

    getClasses() {
        if(this.currentUser.IdRole == 6){
            this.schoolService.getSchoolClasses(this.currentUser.IdSchool)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(this.successGetClasses.bind(this), this.errorCallback.bind(this));
        }
        else {
            this.userService.getClassesByIdTutor(this.currentUser.Id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(this.successGetClasses.bind(this), this.errorCallback.bind(this));
        }
        
    }

    back() {
        if (this.admin) {
            this.router.navigateByUrl("/school/admin");
        } else {
            this.router.navigateByUrl("/school/students");
        }
    }

    getComa(user) {
        if (
            user.FamilyName != null &&
            user.Name != null &&
            user.FamilyName != "" &&
            user.Name != ""
        ) {
            return ",";
        }
        return "";
    }

    checkButton() {
        console.log("this reportType >", this.reportType);

        switch (this.reportType) {
            case "dictationHistory":
                if (this.student == null) this.disableActionButton = true;
                else this.disableActionButton = false;
                break;
            case "studentSession":
                if (
                    this.currentSessionStatus == null ||
                    (this.currentSessionStatus != this.Finished &&
                        this.currentSessionStatus != this.NotFinished)
                )
                    this.disableActionButton = true;
                else this.disableActionButton = false;
                break;
            case "studentSessionDetail":
                if (
                    this.currentSessionStatus == null ||
                    (this.currentSessionStatus != this.Finished &&
                        this.currentSessionStatus != this.NotFinished)
                )
                    this.disableActionButton = true;
                else this.disableActionButton = false;

                break;
            case "evaMainGroup":
                this.disableActionButton = false;
                // if (this.authUsersAvailable) {
                //   let findAuth = false;
                //   let index = -1;
                //   for (let i = 0; i < this.classes.length; i++)
                //     if (this.classes[i].IdClass == -1) {
                //       findAuth = true;
                //       index = i;
                //     }
                //   if (findAuth)
                //     this.classes.splice(index, 1);
                // }
                break;
            case "evaResumeGroup":
                this.disableActionButton = false;
                // if (this.authUsersAvailable) {
                //   let findAuth = false;
                //   let index = -1;
                //   for (let i = 0; i < this.classes.length; i++)
                //     if (this.classes[i].IdClass == -1) {
                //       findAuth = true;
                //       index = i;
                //     }
                //   if (findAuth)
                //     this.classes.splice(index, 1);
                // }
                break;
            case "studentsZip":
                this.disableActionButton = false;
                // if (this.authUsersAvailable) {
                //   let findAuth = false;
                //   let index = -1;
                //   for (let i = 0; i < this.classes.length; i++)
                //     if (this.classes[i].IdClass == -1) {
                //       findAuth = true;
                //       index = i;
                //     }
                //   if (findAuth)
                //     this.classes.splice(index, 1);
                // }
                break;
            case "historyZip":
                this.disableActionButton = false;
                // if (this.authUsersAvailable) {
                //   let findAuth = false;
                //   let index = -1;
                //   for (let i = 0; i < this.classes.length; i++)
                //     if (this.classes[i].IdClass == -1) {
                //       findAuth = true;
                //       index = i;
                //     }
                //   if (findAuth)
                //     this.classes.splice(index, 1);
                // }
                break;
            case "qualifications":
                this.disableActionButton = false;
                // if (this.authUsersAvailable) {
                //   let findAuth = false;
                //   let index = -1;
                //   for (let i = 0; i < this.classes.length; i++)
                //     if (this.classes[i].IdClass == -1) {
                //       findAuth = true;
                //       index = i;
                //     }
                //   if (findAuth)
                //     this.classes.splice(index, 1);
                // }
                break;
            case "evaCalificaSchool":
                this.disableActionButton = false;
                break;
            case "studentHistory":
                if (this.student == null) this.disableActionButton = true;
                else this.disableActionButton = false;

                break;
            case "studentRegularity":
                if (this.student == null) this.disableActionButton = true;
                else this.disableActionButton = false;

                break;
            case "studentStatus":
                if (this.student == null) this.disableActionButton = true;
                else this.disableActionButton = false;

                break;
            case "studentEvolution":
                if (this.student == null) this.disableActionButton = true;
                else this.disableActionButton = false;

                break;
            case "studentLastWeek":
                if (this.student == null) this.disableActionButton = true;
                else this.disableActionButton = false;

                break;
            case "dictations":
                this.disableActionButton = false;
                break;
            case "EvaReadingText":
                this.disableActionButton = false;
                break;
            case "EvaReadingTextGroup":
                this.disableActionButton = false;
                break;
            case "evaCalifica":
                this.disableActionButton = false;
                break;
            case "EvaExamGroup":
                if (!this.examDate) {
                    this.disableActionButton = true;
                } else {
                    this.disableActionButton = false;
                }
                break;
        }
        this.dataLoaded = true;
        setTimeout(() => {
            $("input[datepicker]").datepicker({
                dateFormat: "dd/mm/yy",
                onSelect: (dateText, inst) => {
                    this[inst.id] = new Date(
                        inst.selectedYear,
                        inst.selectedMonth,
                        inst.selectedDay
                    );
                },
            });
        }, 100);
    }

    changeControls() {
        if (
            !this.dateHidden() &&
            moment(this.selectedDay_desde).isAfter(
                moment(this.selectedDay_hasta)
            )
        ) {
            this.callMessage();
            return;
        }
        this.showDownload = false;
        if (this.expandControls == false) {
            //openning
            this.expandControls = true;
            this.htmlCode = "";
        } else if (this.expandControls == true) {
            //closing

            if (this.reportType == "dictationHistory") {
                if (this.student != null) this.showDictationHistory();
            } else if (this.reportType == "evaMainGroup")
                this.showEvaMainGroup();
            else if (this.reportType == "evaResumeGroup")
                this.showEvaResumeGroup();
            else if (this.reportType == "qualifications")
                this.showEvaQualificationsGroup();
            else if (this.reportType == "evaCalificaSchool")
                this.showEvaQualificationsSchool();
            else if (this.reportType == "studentsZip") {
                this.getStudentsGroupZip();
            } else if (this.reportType == "historyZip") {
                this.getStudentsHistoryZip();
            } else if (this.reportType == "studentHistory") {
                if (this.student != null) this.showStudentHistory();
            } else if (this.reportType == "studentRegularity") {
                if (this.student != null) this.showStudentRegularity();
            } else if (this.reportType == "studentStatus") {
                if (this.student != null) this.showStudentStatus();
            } else if (this.reportType == "studentSession") {
                if (
                    this.currentSessionStatus == this.Finished ||
                    this.currentSessionStatus == this.NotFinished
                ) {
                    this.showSessionReport();
                    this.expandControls = false;
                } else this.expandControls = true;
            } else if (this.reportType == "studentSessionDetail") {
                if (
                    this.currentSessionStatus == this.Finished ||
                    this.currentSessionStatus == this.NotFinished
                ) {
                    this.showSessionDetailedReport();
                    this.expandControls = false;
                } else this.expandControls = true;
            } else if (this.reportType == "studentEvolution") {
                if (this.student != null) this.showEvolution();
            } else if (this.reportType == "studentLastWeek") {
                if (this.student != null) this.showLastWeek();
            } else if (this.reportType == "dictations") {
                this.showDictactionGroup();
            } else if (this.reportType == "EvaReadingText") {
                this.showEvaReadingText();
            } else if (this.reportType == "EvaReadingTextGroup") {
                this.showEvaReadingTextGroup();
            } else if (this.reportType == "evaCalifica") {
                this.showDictaQualiGroup();
            } else if (this.reportType == "EvaExamGroup") {
                this.showExamGroup();
            }
            this.showDownload = !this.expandControls;
        }
    }
    dateHidden() {
        return (
            this.reportType != "EvaReadingText" &&
            this.reportType != "EvaReadingTextGroup" &&
            this.reportType != "dictationHistory" &&
            this.reportType != "evaCalificaSchool" &&
            this.reportType != "dictations" &&
            this.reportType != "studentEvolution" &&
            this.reportType != "evaMainGroup" &&
            this.reportType != "evaResumeGroup" &&
            this.reportType != "studentHistory" &&
            this.reportType != "qualifications" &&
            this.reportType != "studentsZip" &&
            this.reportType != "historyZip" &&
            this.reportType != "dictactions" &&
            this.reportType != "evaCalifica" &&
            this.reportType != "studentStatus"
        );
    }
    callMessage() {
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
          });
          confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.MODAL-TITLE');
          confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.MODAL-MESSAGE');
          confirmDialog.componentInstance.margin = false;
          confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
      
          confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
            result();
            confirmDialog = null;
        });
    }
  showDictationHistory() {
    let endDate = moment(this.selectedDay_hasta);
    let startDate = moment(this.selectedDay_desde);
    if (endDate >= startDate) {
      let reportName = "dictationHistory";
      let parameters = [
        { idUser: this.student.Id },
        { fechaInicio: startDate.format('YYYY-MM-DD') },
        { fechaFin: endDate.format('YYYY-MM-DD') }
      ];
      this.reportService.getHtml(reportName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetHtml.bind(this), this.errorCallback.bind(this));
      this.loading = true;
      this.htmlCode = null;
      this.expandControls = false;
    }
  }

  changeClass() {
    if (this.classes == null) return;
    selectedClassId = null;
    for (let i = 0; i < this.classes.length; i++)
      if (this.classes[i].IdClass == this.selectedClassId)
        this.selectedClass = this.classes[i];
    this.students = [];
    if (this.selectedClass.IdClass == -1) {
      this.students = this.authUsers;
      this.reportType = 'studentLastWeek';
    } else {
      this.userService.getStudentsByParentId(this.selectedClass.IdTutor, this.selectedClassId)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetStudents.bind(this), this.errorCallback.bind(this));
      if (this.reportType == 'EvaExamGroup') {
        selectedClassId = this.selectedClass.IdClass
        this.examDate = moment().format()
        this.disableActionButton = true;
      }
    }
  }

    changeDate() {
        this.checkButton();
    }

    getPdf() {
        this.downloading = true;
        if (this.reportType == "evaMainGroup") this.downloadEvaMainGroup("pdf");
        else if (this.reportType == "evaResumeGroup")
            this.downloadEvaResumeGroup("pdf");
        else if (this.reportType == "qualifications")
            this.downloadEvaQualificationsGroup("pdf");
        else if (this.reportType == "evaCalificaSchool")
            this.downloadEvaQualificationsSchool("pdf");
        else if (this.reportType == "studentHistory") {
            if (this.student != null) this.downloadStudentHistory("pdf");
        } else if (this.reportType == "studentRegularity") {
            if (this.student != null) this.downloadStudentRegularity("pdf");
        } else if (this.reportType == "studentStatus") {
            if (this.student != null) this.downloadStudentStatus("pdf");
        } else if (this.reportType == "studentSession") {
            if (
                this.currentSessionStatus == this.Finished ||
                this.currentSessionStatus == this.NotFinished
            ) {
                this.downloadSessionReport("pdf");
            }
        } else if (this.reportType == "studentSessionDetail") {
            if (
                this.currentSessionStatus == this.Finished ||
                this.currentSessionStatus == this.NotFinished
            ) {
                this.downloadSessionDetailedReport("pdf");
            }
        } else if (this.reportType == "studentEvolution") {
            if (this.student != null) this.downloadEvolution("pdf");
        } else if (this.reportType == "studentLastWeek") {
            if (this.student != null) this.downloadLastWeek("pdf");
        } else if (this.reportType == "dictations")
            this.downloadDictGroup("pdf");
        else if (this.reportType == "evaCalifica")
            this.downloadDictQualiGroup("pdf");
        else if (this.reportType == "EvaExamGroup")
            this.downloadExamReport("pdf");
        else if (this.reportType == "EvaReadingTextGroup")
            this.downloadEvaReadingTextGroup("pdf");
        else if (this.reportType == "EvaReadingText")
            this.downloadEvaReadingText("pdf");
        else if (this.reportType == "dictationHistory")
            this.downloadDictationHistory("pdf")
    }

    getExcel() {
        this.downloading = true;
        if (this.reportType == "evaMainGroup")
            this.downloadEvaMainGroup("excel");
        else if (this.reportType == "evaResumeGroup")
            this.downloadEvaResumeGroup("excel");
        else if (this.reportType == "qualifications")
            this.downloadEvaQualificationsGroup("excel");
        else if (this.reportType == "evaCalificaSchool")
            this.downloadEvaQualificationsSchool("excel");
        else if (this.reportType == "studentHistory") {
            if (this.student != null) this.downloadStudentHistory("excel");
        } else if (this.reportType == "studentRegularity") {
            if (this.student != null) this.downloadStudentRegularity("excel");
        } else if (this.reportType == "studentStatus") {
            if (this.student != null) this.downloadStudentStatus("excel");
        } else if (this.reportType == "studentSession") {
            if (
                this.currentSessionStatus == this.Finished ||
                this.currentSessionStatus == this.NotFinished
            ) {
                this.downloadSessionReport("excel");
            }
        } else if (this.reportType == "studentSessionDetail") {
            if (
                this.currentSessionStatus == this.Finished ||
                this.currentSessionStatus == this.NotFinished
            ) {
                this.downloadSessionDetailedReport("excel");
            }
        } else if (this.reportType == "studentEvolution") {
            if (this.student != null) this.downloadEvolution("excel");
        } else if (this.reportType == "studentLastWeek") {
            if (this.student != null) this.downloadLastWeek("excel");
        } else if (this.reportType == "dictations")
            this.downloadDictGroup("excel");
        else if (this.reportType == "evaCalifica")
            this.downloadDictQualiGroup("excel");
        else if (this.reportType == "EvaExamGroup")
            this.downloadExamReport("excel");
        else if (this.reportType == "EvaReadingTextGroup")
            this.downloadEvaReadingTextGroup("excel");
        else if (this.reportType == "EvaReadingText")
            this.downloadEvaReadingText("excel");
            else if (this.reportType == "dictationHistory")
            this.downloadDictationHistory("excel")
    }

  showEvaQualificationsSchool() {
    let endDate = moment(this.selectedDay_hasta);
    let startDate = moment(this.selectedDay_desde);
    if (endDate >= startDate) {

      let reportName = "evaCalificaSchool";
      let parameters = [
        { idschool: this.schoolService.school.IdSchool },
        { fechaInicio: startDate.format('YYYY-MM-DD') },
        { fechaFin: endDate.format('YYYY-MM-DD') }

      ];
      this.reportService.getHtml(reportName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetHtml.bind(this), this.errorCallback.bind(this));
      this.htmlCode = null;
      this.loading = true;
      this.expandControls = false;
    }
  }

    downloadEvaQualificationsSchool(format) {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate >= startDate) {
            let pdfName =
                startDate.format("DD-MM") + "_" + endDate.format("DD-MM");


      let reportName = "evaCalificaSchool";
      let parameters = [
        { idschool: this.schoolService.school.IdSchool },
        { fechaInicio: startDate.format('YYYY-MM-DD') },
        { fechaFin: endDate.format('YYYY-MM-DD') }];
      if (format == 'pdf')
        this.reportService.getPdf(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
      else
        this.reportService.getExcel(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
      this.loading = true;
    }
  }

    loadSessionMonth() {
        setTimeout(() => {
            if (this.studentId == null) return;
            this.sessionService
                .getSessionByUserMonthYear(
                    this.studentId,
                    this.month,
                    this.year
                )
                .subscribe(
                    this.successLoadSessionMonth.bind(this),
                    this.errorCallback.bind(this)
                );
            this.currentMonth = 0;
        }, 100);
    }

    successLoadSessionMonth(response) {
        if (response == null) return this.navigationService.exit();
        for (let i = 0; i < this.students.length; i++) {
            if (this.students[i].Id == this.studentId)
                this.student = this.students[i];
        }
        this.student.Sessions = response;
        this.currentMonth = this.month;
        this.currentSessionStatus = null;
        this.checkButton();
    }

    selectedDayChanged(newDate) {
        this.currentSessionStatus = null;
        this.selectedDay = newDate;
        if (this.month != this.selectedDay.month() + 1) {
            this.month = this.selectedDay.month() + 1;
            this.year = this.selectedDay.year();
            this.loadSessionMonth();
        } else {
            for (let i = 0; i < this.student.Sessions.length; i++) {
                if (
                    this.selectedDay.isSame(
                        moment(this.student.Sessions[i].ExecutionDate),
                        "day"
                    )
                ) {
                    if (
                        this.student.Sessions[i].Status ==
                        this.SessionStatus.started
                    )
                        this.currentSessionStatus = this.NotDone;
                    else if (
                        this.student.Sessions[i].Status ==
                        this.SessionStatus.notfinished
                    )
                        this.currentSessionStatus = this.NotFinished;
                    else if (
                        this.student.Sessions[i].Status ==
                        this.SessionStatus.finished
                    )
                        this.currentSessionStatus = this.Finished;
                    else if (
                        this.student.Sessions[i].Status ==
                        this.SessionStatus.newSession
                    )
                        this.currentSessionStatus = null;
                    this.currentSessionId = this.student.Sessions[i].IdSession;
                }
            }
        }
        this.checkButton();
    }

    showEvaMainGroupCallback(alternative) {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);

        let classId;
        if (this.selectedClass != null) classId = this.selectedClass.IdClass;
        else classId = 0;

    let reportName = "evaMainGroup";
    let parameters = [

      { fechaInicio: startDate.format('YYYY-MM-DD') },
      { fechaFin: endDate.format('YYYY-MM-DD') },
      { class: classId }
    ];
    if (alternative == "Visualizar") {
      this.reportService.getHtml(reportName, parameters)
        
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetHtml.bind(this), this.errorCallback.bind(this));
      this.htmlCode = null;
      this.loading = true;
      this.expandControls = false;
    } else if (alternative == "PDF") {
      this.disableActionButton = true;
      this.downloadEvaMainGroup("pdf");
    } else if (alternative == "Excel") {
      this.disableActionButton = true;
      this.downloadEvaMainGroup("excel");
    }
  }

    showEvaMainGroup() {
        let message = [];
        message[0] = this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.SHOWEVAMAINGROUP-TITLE');
        message[1] = this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.SHOWEVAMAINGROUP-MESSAGE1');
        message[2] = this._translateService.instant('SCHOOL.TEACHER.TRACKING.REPORT.SHOWEVAMAINGROUP-MESSAGE2');

        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.message2 = message[2];
        confirmDialog.componentInstance.margin = false;
        confirmDialog.componentInstance.isReport = true;

        confirmDialog.componentInstance.options = [
            {
                text: "PDF",
                callback: () => {
                    this.showEvaMainGroupCallback("PDF");
                },
            },
            {
                text: "Excel",
                callback: () => {
                    this.showEvaMainGroupCallback("Excel");
                },
            },
        ];

    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      result();
      confirmDialog = null;
    });

    
  }

    showEvaResumeGroup() {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate >= startDate) {
            let classId;
            if (this.selectedClass != null)
                classId = this.selectedClass.IdClass;
            else classId = 0;

      let reportName = "evaResumeGroup";
      let parameters = [
        { fechaInicio: startDate.format('YYYY-MM-DD') },
        { fechaFin: endDate.format('YYYY-MM-DD') },
        { class: classId }
      ];
      this.reportService.getHtml(reportName, parameters)
        
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetHtml.bind(this), this.errorCallback.bind(this));
      this.htmlCode = null;
      this.loading = true;
      this.expandControls = false;
    }
  }

    showEvaReadingTextGroup() {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate >= startDate) {
            let classId;
            if (this.selectedClass != null)
                classId = this.selectedClass.IdClass;
            else classId = 0;

      let reportName = "evaReadingTextGroup";
      let parameters = [
        { fechaInicio: startDate.format('YYYY-MM-DD') },
        { fechaFin: endDate.format('YYYY-MM-DD') },
        { class: classId }
      ];
      this.reportService.getHtml(reportName, parameters)
        
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetHtml.bind(this), this.errorCallback.bind(this));
      this.htmlCode = null;
      this.loading = true;
      this.expandControls = false;
    }
  }

    showEvaReadingText() {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate >= startDate) {
            let classId;
            if (this.selectedClass != null)
                classId = this.selectedClass.IdClass;
            else classId = 0;

      let reportName = "evaReadingText";
      let parameters = [
        { fechaInicio: startDate.format('YYYY-MM-DD') },
        { fechaFin: endDate.format('YYYY-MM-DD') },
        { idUser: this.student.Id }
      ];
      this.reportService.getHtml(reportName, parameters)
        
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetHtml.bind(this), this.errorCallback.bind(this));
      this.htmlCode = null;
      this.loading = true;
      this.expandControls = false;
    }
  }

    downloadEvaReadingTextGroup(format) {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate >= startDate) {
            let classId;

            let pdfName =
                "Grupo_" +
                startDate.format("DD-MM") +
                "_" +
                endDate.format("DD-MM");
            if (this.selectedClass != null) {
                classId = this.selectedClass.IdClass;
                for (let i = 0; i < this.classes.length; i++) {
                    if (this.classes[i].IdClass == classId)
                        pdfName =
                            "Grupo_" +
                            this.classes[i].Name +
                            "_" +
                            startDate.format("DD-MM") +
                            "_" +
                            endDate.format("DD-MM");
                }
            } else classId = 0;

      let reportName = "evaReadingTextGroup";
      let parameters = [
        {
          fechaInicio: startDate.format('YYYY-MM-DD')
        },
        {
          fechaFin: endDate.format('YYYY-MM-DD')
        },
        {
          class: classId
        }];
      if (format == 'pdf')
        this.reportService.getPdf(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
      else
        this.reportService.getExcel(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
      this.loading = true;
    }
  }

  downloadEvaReadingText(format) {
    let endDate = moment(this.selectedDay_hasta);
    let startDate = moment(this.selectedDay_desde);
    if (endDate >= startDate) {
      let reportName = "evaReadingText";
      let pdfName = this.student.Name + "_" + this.student.FamilyName + "_" + startDate.format('DD-MM') + "_" + endDate.format('DD-MM');
      let parameters = [
        {
          idUser: this.student.Id
        },
        {
          fechaInicio: startDate.format('YYYY-MM-DD')
        },
        {
          fechaFin: endDate.format('YYYY-MM-DD')
        }
      ];
      if (format == 'pdf')
        this.reportService.getPdf(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
      else
        this.reportService.getExcel(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
      this.loading = true;
    }
  }
    downloadDictationHistory(format) {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate >= startDate) {
            let reportName = "dictationHistory";
            let pdfName = this.student.Name + "_" + this.student.FamilyName + "_" + startDate.format('DD-MM') + "_" + endDate.format('DD-MM');
            let parameters = [
                {
                    idUser: this.student.Id
                },
                {
                    fechaInicio: startDate.format('YYYY-MM-DD')
                },
                {
                    fechaFin: endDate.format('YYYY-MM-DD')
                }
            ];
            if (format == 'pdf')
                this.reportService.getPdf(reportName, pdfName, parameters)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
            else
                this.reportService.getExcel(reportName, pdfName, parameters)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
            this.loading = true;
        }
    }

    showDictactionGroup() {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate >= startDate) {
            let classId;
            if (this.selectedClass != null)
                classId = this.selectedClass.IdClass;
            else classId = 0;

      let reportName = "evaDictGroup";
      let parameters = [
        { fechaInicio: startDate.format('YYYY-MM-DD') },
        { fechaFin: endDate.format('YYYY-MM-DD') },
        { class: classId }
      ];
      this.reportService.getHtml(reportName, parameters)
        
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetHtml.bind(this), this.errorCallback.bind(this));
      this.htmlCode = null;
      this.loading = true;
      this.expandControls = false;
    }
  }

    showDictaQualiGroup() {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate >= startDate) {
            let classId;
            if (this.selectedClass != null)
                classId = this.selectedClass.IdClass;
            else classId = 0;

      let reportName = "evaCalifica";
      let parameters = [
        { fechaInicio: startDate.format('YYYY-MM-DD') },
        { fechaFin: endDate.format('YYYY-MM-DD') },
        { class: classId },
        { xSes: this.xSes.split("%")[0]},
        { xDic: this.xDic.split("%")[0]},
        { xRead: this.xRead.split("%")[0]},
        { xDed: this.xDed.split("%")[0]},
        {xHear: this.xHear.split("%")[0]} 
      ];
      this.reportService.getHtml(reportName, parameters)
        
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetHtml.bind(this), this.errorCallback.bind(this));
      this.htmlCode = null;
      this.loading = true;
      this.expandControls = false;
    }
  }

    parseDate(str) {
        if (str instanceof Date) return moment(str);
        if (moment.isMoment(str)) return str;
        let d = parseFloat(str.split("/")[0]);
        let m = parseFloat(str.split("/")[1]) - 1;
        let a = parseFloat(str.split("/")[2]);
        return moment(new Date(a, m, d));
    }

    showExamGroup() {
        let date = moment(this.examDate);

        let classId;
        if (this.selectedClass != null) {
            classId = this.selectedClass.IdClass;
        } else {
            classId = 0;
        }

    let reportName = "EvaExamGroup";
    let parameters = [
      { fecha: date.format('YYYY-MM-DD') },
      { class: classId }
    ];
    this.reportService.getHtml(reportName, parameters)
      
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetHtml.bind(this), this.errorCallback.bind(this));
    this.htmlCode = null;
    this.loading = true;
    this.expandControls = false;

  }

    showEvaQualificationsGroup() {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);

        if (endDate >= startDate) {
            let classId;

            if (this.selectedClass != null)
                classId = this.selectedClass.IdClass;
            else classId = 0;

            let reportName = "evaCalificaGroup";
            let parameters = [
                { fechaInicio: startDate.format("YYYY-MM-DD") },
                { fechaFin: endDate.format("YYYY-MM-DD") },
                { class: classId },
            ];

      this.reportService.getHtml(reportName, parameters)
        
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetHtml.bind(this), this.errorCallback.bind(this));
      this.htmlCode = null;
      this.loading = true;
      this.expandControls = false;
    }
  }

  getStudentsGroupZip() {
    let reportName = "studentEvolution";
    let endDate = moment(this.selectedDay_hasta);
    let startDate = moment(this.selectedDay_desde);
    if (endDate >= startDate) {
      let classId;
      if (this.selectedClass != null)
        classId = this.selectedClass.IdClass;
      else
        classId = 0;
      let parameters = [
        { fechaInicio: startDate.format('YYYY-MM-DD') },
        { fechaFin: endDate.format('YYYY-MM-DD') },
        { tema: this.specificTheme }
      ];
      let zipName = "Grupo_" + startDate.format('DD-MM') + "_" + endDate.format('DD-MM');
      this.reportService.getZip(reportName, zipName, classId, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
      this.loading = true;
      this.expandControls = true;
    }
  }

  getStudentsHistoryZip() {
    let reportName = "studentHistory";
    let endDate = moment(this.selectedDay_hasta);
    let startDate = moment(this.selectedDay_desde);
    if (endDate >= startDate) {
      let classId;
      if (this.selectedClass != null)
        classId = this.selectedClass.IdClass;
      else
        classId = 0;
      let parameters = [
        { fechaInicio: startDate.format('YYYY-MM-DD') },
        { fechaFin: endDate.format('YYYY-MM-DD') }
      ];
      let zipName = "GrupoHist_" + startDate.format('DD-MM') + "_" + endDate.format('DD-MM');
      this.reportService.getZip(reportName, zipName, classId, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
      this.loading = true;
      this.expandControls = true;
    }
  }

  showStudentHistory() {
    let endDate = moment(this.selectedDay_hasta);
    let startDate = moment(this.selectedDay_desde);
    if (endDate >= startDate) {

      let reportName = "studentHistory";
      let parameters = [
        { idUser: this.student.Id },
        { fechaInicio: startDate.format('YYYY-MM-DD') },
        { fechaFin: endDate.format('YYYY-MM-DD') }
      ];
      this.reportService.getHtml(reportName, parameters)
        
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetHtml.bind(this), this.errorCallback.bind(this));
      this.htmlCode = null;
      this.loading = true;
      this.expandControls = false;
    }
  }

    showStudentRegularity() {
        let reportName = "studentRegularity";

    let parameters = [
      { usser: this.student.Id },
      { year: this.year }
    ];
    this.reportService.getHtml(reportName, parameters)
      
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetHtml.bind(this), this.errorCallback.bind(this));
    this.htmlCode = null;
    this.loading = true;
    this.expandControls = false;
  }

    showSessionReport() {
        let reportName = "studentSession";

    let parameters = [
      { usser: this.studentId },
      { session: this.currentSessionId }
    ];
    this.reportService.getHtml(reportName, parameters)
      
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetHtml.bind(this), this.errorCallback.bind(this));
    this.htmlCode = null;
    this.loading = true;
    this.expandControls = false;
  }

  showSessionDetailedReport() {

    let reportName = "studentSessionDetail";
    let parameters = [
      { session: this.currentSessionId }
    ];
    this.reportService.getHtml(reportName, parameters)
      
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetHtml.bind(this), this.errorCallback.bind(this));
    this.htmlCode = null;
    this.loading = true;
    this.expandControls = false;
  }

  showStudentStatus() {
    let endDate = moment(this.selectedDay_hasta);
    let startDate = moment(this.selectedDay_desde);
    let reportName = "studentStatus";
    if (endDate >= startDate) {
    let parameters = [
      { usser: this.studentId },
      { fechaInicio: startDate.format('YYYY-MM-DD') },
      { fechaFin: endDate.format('YYYY-MM-DD') }
    ];
    this.reportService.getHtml(reportName, parameters)
      
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetHtml.bind(this), this.errorCallback.bind(this));
    this.htmlCode = null;
    this.loading = true;
    this.expandControls = false;
    }
  }

    showEvolution() {
        let reportName = "studentEvolution";

    let endDate = moment(this.selectedDay_hasta);
    let startDate = moment(this.selectedDay_desde);
    if (endDate >= startDate) {

      let parameters = [
        { idUser: this.student.Id },
        { fechaInicio: startDate.format('YYYY-MM-DD') },
        { fechaFin: endDate.format('YYYY-MM-DD') },
        { tema: this.specificTheme }
      ];
      this.reportService.getHtml(reportName, parameters)
        
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetHtml.bind(this), this.errorCallback.bind(this));
      this.loading = true;
      this.htmlCode = null;
      this.expandControls = false;
    }
  }

    showLastWeek() {
        let reportName = "studentLastWeek";

    let parameters = [
      { idUser: this.student.Id }
    ];
    this.reportService.getHtml(reportName, parameters)
      
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetHtml.bind(this), this.errorCallback.bind(this));
    this.loading = true;
    this.htmlCode = null;
    this.expandControls = false;
  }

    downloadEvaResumeGroup(format) {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate >= startDate) {
            let classId;

            let pdfName =
                "Grupo_" +
                startDate.format("DD-MM") +
                "_" +
                endDate.format("DD-MM");
            if (this.selectedClass != null) {
                classId = this.selectedClass.IdClass;
                for (let i = 0; i < this.classes.length; i++) {
                    if (this.classes[i].IdClass == classId)
                        pdfName =
                            "Grupo_" +
                            this.classes[i].Name +
                            "_" +
                            startDate.format("DD-MM") +
                            "_" +
                            endDate.format("DD-MM");
                }
            } else classId = 0;

      let reportName = "evaResumeGroup";
      let parameters = [
        {
          fechaInicio: startDate.format('YYYY-MM-DD')
        },
        {
          fechaFin: endDate.format('YYYY-MM-DD')
        },
        {
          class: classId
        }];
      if (format == 'pdf')
        this.reportService.getPdf(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
      else
        this.reportService.getExcel(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
      this.loading = true;
    }
  }

    downloadExamReport(format) {
        let date = moment(this.examDate);

        let classId;
        if (this.selectedClass != null) {
            classId = this.selectedClass.IdClass;
        } else {
            classId = 0;
        }

    let reportName = "EvaExamGroup";
    let parameters = [
      { fecha: date.format('YYYY-MM-DD') },
      { class: classId }
    ];
    let pdfName = "Examen_" + date.format('DD-MM');
    if (format === 'pdf')
      this.reportService.getPdf(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
    else
      this.reportService.getExcel(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
    this.loading = true;
  }

    downloadDictGroup(format) {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate >= startDate) {
            let classId;

            let pdfName =
                "Grupo_" +
                startDate.format("DD-MM") +
                "_" +
                endDate.format("DD-MM");
            if (this.selectedClass != null) {
                classId = this.selectedClass.IdClass;
                for (let i = 0; i < this.classes.length; i++) {
                    if (this.classes[i].IdClass == classId)
                        pdfName =
                            "Grupo_" +
                            this.classes[i].Name +
                            "_" +
                            startDate.format("DD-MM") +
                            "_" +
                            endDate.format("DD-MM");
                }
            } else classId = 0;

      let reportName = "evaDictGroup";
      let parameters = [
        {
          fechaInicio: startDate.format('YYYY-MM-DD')
        },
        {
          fechaFin: endDate.format('YYYY-MM-DD')
        },
        {
          class: classId
        }];
      if (format == 'pdf')
        this.reportService.getPdf(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
      else
        this.reportService.getExcel(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
      this.loading = true;
    }
  }

    downloadDictQualiGroup(format) {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate >= startDate) {
            let classId;

            let pdfName =
                "Grupo_" +
                startDate.format("DD-MM") +
                "_" +
                endDate.format("DD-MM");
            if (this.selectedClass != null) {
                classId = this.selectedClass.IdClass;
                for (let i = 0; i < this.classes.length; i++) {
                    if (this.classes[i].IdClass == classId)
                        pdfName =
                            "Grupo_" +
                            this.classes[i].Name +
                            "_" +
                            startDate.format("DD-MM") +
                            "_" +
                            endDate.format("DD-MM");
                }
            } else classId = 0;

      let reportName = "evaCalifica";
      let parameters = [
        {
          fechaInicio: startDate.format('YYYY-MM-DD')
        },
        {
          fechaFin: endDate.format('YYYY-MM-DD')
        },
        {
          class: classId
        },
        {
          xSes: this.xSes.split("%")[0]
        },
        { 
          xDic: this.xDic.split("%")[0] 
        },
        { 
          xRead: this.xRead.split("%")[0] 
        },
        { 
          xDed: this.xDed.split("%")[0]
        },
        {
            xHear: this.xHear.split("%")[0]
        }
    ];
      if (format == 'pdf')
        this.reportService.getPdf(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
      else
        this.reportService.getExcel(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
      this.loading = true;
    }
  }

    downloadEvaQualificationsGroup(format) {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate >= startDate) {
            let classId;

            let pdfName =
                "Grupo_" +
                startDate.format("DD-MM") +
                "_" +
                endDate.format("DD-MM");
            if (this.selectedClass != null) {
                classId = this.selectedClass.IdClass;
                for (let i = 0; i < this.classes.length; i++) {
                    if (this.classes[i].IdClass == classId)
                        pdfName =
                            "Grupo_" +
                            this.classes[i].Name +
                            "_" +
                            startDate.format("DD-MM") +
                            "_" +
                            endDate.format("DD-MM");
                }
            } else classId = 0;

      let reportName = "evaCalificaGroup";
      let parameters = [
        {
          fechaInicio: startDate.format('YYYY-MM-DD')
        },
        {
          fechaFin: endDate.format('YYYY-MM-DD')
        },
        {
          class: classId
        }];
      if (format == 'pdf')
        this.reportService.getPdf(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
      else
        this.reportService.getExcel(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
      this.loading = true;
    }
  }

    downloadEvaMainGroup(format) {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate >= startDate) {
            let classId;
            let pdfName =
                "GrupoDet_" +
                startDate.format("DD-MM") +
                "_" +
                endDate.format("DD-MM");
            if (this.selectedClass != null) {
                classId = this.selectedClass.IdClass;
                for (let i = 0; i < this.classes.length; i++) {
                    if (this.classes[i].IdClass == classId)
                        pdfName =
                            "GrupoDet_" +
                            this.classes[i].Name +
                            "_" +
                            startDate.format("DD-MM") +
                            "_" +
                            endDate.format("DD-MM");
                }
            } else classId = 0;

            let reportName = "evaMainGroup";

            let parameters = [
                {
                    fechaInicio: startDate.format("YYYY-MM-DD"),
                },
                {
                    fechaFin: endDate.format("YYYY-MM-DD"),
                },
                {
                    class: classId,
                },
            ];

      if (format == 'pdf')
        this.reportService.getPdf(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
      else
        this.reportService.getExcel(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
      this.loading = true;
    }
  }

  downloadStudentHistory(format) {

    let endDate = moment(this.selectedDay_hasta);
    let startDate = moment(this.selectedDay_desde);
    if (endDate >= startDate) {
      let reportName = "studentHistory";
      let pdfName = this.student.Name + "_" + this.student.FamilyName + "_" + startDate.format('DD-MM') + "_" + endDate.format('DD-MM');
      let parameters = [
        {
          idUser: this.student.Id
        },
        {
          fechaInicio: startDate.format('YYYY-MM-DD')
        },
        {
          fechaFin: endDate.format('YYYY-MM-DD')
        }
      ];
      if (format == 'pdf')
        this.reportService.getPdf(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
      else
        this.reportService.getExcel(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
      this.loading = true;
    }
  }

    downloadSessionReport(format) {
        let reportName = "studentSession";
        let pdfName =
            this.student.Name +
            "_" +
            this.student.FamilyName +
            "_" +
            this.currentSessionId;
        for (let i = 0; i < this.student.Sessions.length; i++) {
            if (this.student.Sessions[i].IdSession == this.currentSessionId)
                pdfName =
                    this.student.Name +
                    "_" +
                    this.student.FamilyName +
                    "_" +
                    moment(this.student.Sessions[i].ExecutionDate).format(
                        "DD-MM"
                    );
        }

    let parameters = [{
      usser: this.studentId
    },
    {
      session: this.currentSessionId
    }];
    if (format == 'pdf')
      this.reportService.getPdf(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
    else
      this.reportService.getExcel(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
    this.loading = true;
  }

  downloadStudentRegularity(format) {
    let reportName = "studentRegularity";
    let pdfName = this.student.Name + "_" + this.student.FamilyName + "_" + moment().format('DD-MM');
    let parameters = [
      { usser: this.studentId },
      { year: this.year }
    ];
    if (format == 'pdf')
      this.reportService.getPdf(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
    else
      this.reportService.getExcel(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
    this.loading = true;
  }

    downloadStudentStatus(format) {
        let endDate = moment(this.selectedDay_hasta);
        let startDate = moment(this.selectedDay_desde);
        if (endDate >= startDate) {
            let reportName = "studentStatus";
            let pdfName =
                this.student.Name +
                "_" +
                this.student.FamilyName +
                "_" +
                startDate.format("DD-MM") +
                "_" +
                endDate.format("DD-MM");
            let parameters = [
                {
                    usser: this.studentId,
                },
                {
                    fechaInicio: startDate.format("YYYY-MM-DD"),
                },
                {
                    fechaFin: endDate.format("YYYY-MM-DD"),
                },
            ];
            if (format == 'pdf')
                this.reportService.getPdf(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
            else
                this.reportService.getExcel(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
            this.loading = true;
        }
    }

    downloadSessionDetailedReport(format) {
        let reportName = "studentSessionDetail";
        let pdfName =
            this.student.Name +
            "_" +
            this.student.FamilyName +
            "_" +
            this.currentSessionId;
        for (let i = 0; i < this.student.Sessions.length; i++) {
            if (this.student.Sessions[i].IdSession == this.currentSessionId)
                pdfName =
                    "Det_" +
                    this.student.Name +
                    "_" +
                    this.student.FamilyName +
                    "_" +
                    moment(this.student.Sessions[i].ExecutionDate).format(
                        "DD-MM"
                    );
        }

    let parameters = [{
      session: this.currentSessionId
    }];
    if (format == 'pdf')
      this.reportService.getPdf(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
    else
      this.reportService.getExcel(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
    this.loading = true;
  }

  downloadEvolution(format) {
    let reportName = "studentEvolution";
    let endDate = moment(this.selectedDay_hasta);
    let startDate = moment(this.selectedDay_desde);
    if (endDate >= startDate) {
      let pdfName = this.student.Name + "_" + this.student.FamilyName + "_" + startDate.format('DD-MM') + "_" + endDate.format('DD-MM');
      let parameters = [
        { idUser: this.student.Id },
        { fechaInicio: startDate.format('YYYY-MM-DD') },
        { fechaFin: endDate.format('YYYY-MM-DD') },
        { tema: this.specificTheme }
      ];
      if (format == 'pdf')
        this.reportService.getPdf(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
      else
        this.reportService.getExcel(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
      this.loading = true;
    }
  }

  downloadLastWeek(format) {
    let reportName = "studentLastWeek";
    let endDate = moment().startOf('day');
    let pdfName = this.student.Name + "_" + this.student.FamilyName + "_Semana_" + endDate.format('DD-MM');
    let parameters = [
      { idUser: this.student.Id }
    ];
    if (format == 'pdf')
      this.reportService.getPdf(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
    else
      this.reportService.getExcel(reportName, pdfName, parameters)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successDownload.bind(this), this.errorCallback.bind(this));
    this.loading = true;
  }

    successGetHtml(data) {
        this.loading = false;
        this.htmlCode = this.sanitizer.bypassSecurityTrustHtml(data.html);
        setTimeout(() => {
            let heightNoInput = this.appService.reference1;
            let pantalla_inicial = parseFloat("1344px");
            let pantalla_final = parseFloat(heightNoInput) * 1.4;
            let d = (pantalla_final * 100) / pantalla_inicial;
            d = 100 - d;
            d = d / 100;
            $("#report-frame")
                .contents()
                .find("html")
                .css("zoom", (1 - d) * 1.5);
        }, 100);
    }

    notLoading() {
        this.loading = false;
    }

    successDownload(response: any) {
        const blobResponse = response?.body as BlobResponse;
        
        this.loading = false;
        this.disableActionButton = false;
        let link = document.createElement("a");
        link.setAttribute("href", URL.createObjectURL(blobResponse.file));
        link.setAttribute("target", "_blank");
        link.setAttribute("download", blobResponse.fileName);
        document.body.appendChild(link);
        link.click();
        this.downloading = false;
    }

    successGetStudents(response) {
        this.loading = false;
        if (response == null) return this.navigationService.exit();
        this.students = response;
        if (this.authUsersAvailable && this.students.length == 0) {
            for (let i = 0; i < this.authUsers.length; i++) {
                if (this.authUsers[i].IdClass == this.selectedClassId) {
                    this.students.push(this.authUsers[i]);
                }
            }
        }
        this.student = this.students[0]
        this.studentId = this.student.Id
    }

  successGetClasses(response) {
    this.loading = false;
    this.selectedClass = null;
    this.selectedClassId = null;
    this.authUsers = null;
    this.classes = null;
    this.classes = response.sort(function (a, b) {
      return a.Level - b.Level;
    });;
    if (this.classes.length == 0) {
      this.classes = null;
    } else {
      this.selectedClass = this.classes[0];
      this.selectedClassId = this.classes[0].IdClass;
      selectedClassId = this.classes[0].IdClass;
      this.userService.getStudentsByParentId(this.selectedClass.IdTutor, this.selectedClassId)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetStudents.bind(this), this.errorCallback.bind(this));
      this.getExams();
    }
    if (this.authUsers == null) {
      this.calling = true;
      this.userService.GetAuthorizedStudentsByParentId(this.authService.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successLoadAuth.bind(this), this.errorCallback.bind(this));
    } else {
      this.successLoadAuth({ data: this.authUsers });
    }
  }

    successLoadAuth(response) {
        if (response != null) {
            this.authUsers = response;
            if (this.authUsers.length != 0) {
                this.authUsersAvailable = true;
                for (let i = 0; i < this.authUsers.length; i++) {
                    this.authUsers[i].AuthUser = true;
                }
            }
            let noClasses = false;
            if (this.authService.currentUser.IdSchool != null) {
                if (this.classes == null) {
                    this.classes = [];
                    noClasses = true;
                }
                if (this.authUsers && this.authUsers.length > 0) {
                    let classAuth = {
                        Name: "Autorizados",
                        IdClass: -1,
                    };
                    this.classes.push(classAuth);
                }
            }
            if (this.authService.currentUser.IdSchool == null || noClasses) {
                if (this.authUsers.length != 0) {
                    for (let i = 0; i < this.authUsers.length; i++) {
                        this.students.push(this.authUsers[i]);
                    }
                }
            }
        } else {
            this.calling = false;
        }
        this.checkButton();
    }

    successLoadAuthClasses(response) {
        if (response != null) {
            if (this.classes == null) {
                this.classes = response;
            } else {
                for (let i = 0; i < response.length; i++)
                    this.classes.push(response[i]);
            }
        }
        this.calling = false;
    }

    formatDate(date) {}

    successGetExamDates(response: CustomSession[][]) {
        if (response === null || response === null)
            return this.navigationService.exit();
        exams = [];
        response.forEach((classExam) => {
            classExam.forEach((exam) => {
                exams.push(exam);
            });
        });
    }

    errorCallback(error: any) {
        this.loading = false;
        this.downloading = false;
        this.expandControls = true;
    }
}
interface InformeGroup {
    disabled?: boolean;
    name: string;
    informes: Informe[];
}
interface Informe {
    desc: string;
    name: string;
    alumno?: boolean;
    readingText?: boolean;
    dictation?: boolean;
    deductionText?: boolean;
    admin?: boolean;
}
