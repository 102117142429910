<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} ">
    </span>
</div>

<div class="cruci spe_gap" [ngStyle]="{'pointer-events': review ? 'default;' : ''}" fxLayout="column"
    fxLayoutAlign="stretch" [ngClass]="{'noreview': !review}">

    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div class="wordsContainer" id="space-for-text">
        <div class="items_container spe_gap" fxLayout="column" fxLayoutAlign="stretch">
            <div fxFlex="" fxFlex.lt-md="0 0 auto" fxLayout="row nowrap" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutAlign="start center" class="spe_gap">
                <div fxFlex="65%" fxFlex.lt-md="0 0 auto" class="lettersContent">
                    <div class="wordCruci" *ngFor="let wordH of exercise.Horizontales; let i = index"
                        [ngClass]="getPosClass(wordH)">
                        <div class="info">{{ i + 1 }}.</div>
                        <ul class="list-inline">
                            <li *ngFor="let letter of wordH.word; let i = index" class="wordLetter"
                                [ngClass]="{'dropzone': letter.show == false}" [attr.idLetter]="i"
                                [attr.idWord]="wordH.idWord">
                                <span [hidden]="letter.show" class="letter-button two letter"
                                    [ngClass]="{'letter-buttonV': i == wordH.posicion -1, 'letter-buttonRed': letter.solved == false && !review,'letter-borderRed': letter.solved == false && review}">&nbsp;</span>
                                <span [hidden]="!letter.show" class="letter-button two letter"
                                    [ngClass]="{'letter-buttonV': i == wordH.posicion -1, 'letter-borderRed': letter.solved == false && review, 'letter-buttonGreen': letter.solved == true && !review, 'letter-borderGreen': letter.solved == true && review}">{{
                                    letter.value }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div fxFlex="" fxFlex.lt-md="0 0 auto" class="lettersContent back">
                    <ul class="list-inline two" style="padding: 0;">
                        <li *ngFor="let letter of letters; let i = index" class="letterContainer">
                            <div [ngClass]="{ chosen: letter.chosen}" class="letter-button letter draggable"
                                [attr.idOption]="i">
                                {{ letter.name }}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="exerciseTitleRule">
                <div class="exerciseRule" [innerHtml]="regla"></div>
            </div>
        </div>
    </div>
</div>
<!--  -->
<!-- <div class="cruci" [ngStyle]="{'pointer-events': review ? 'none;' : ''}">   <h2 *ngIf="controlSession" class="doc-number">{{current + 1}}</h2>
    <div *ngIf="!controlSession" class="exerciseTheme">
        <h1 [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></h1>
    </div>
    <div *ngIf="controlSession" class="text-center exerciseTitle" [ngClass]="{'exerciseTitle-slide': slide}" [ngStyle]="controlSession ? {'margin-top': '1em;'} : {}">
        <p   [innerHtml]="exercise.enunciado"></p>
    </div>
    <div class="wordsContainer" id="space-for-text" [ngClass]="{'words-container-slide': slide, 'words-container-session': controlSession && !slide}" >
        <div class="secretWord">
            <div *ngIf="!controlSession" class="text-center exerciseTitle">
                <p [innerHtml]="exercise.enunciado"></p>
            </div>
            <div class="helperContainer" [ngStyle]="{'margin-left': slide ? '-2em': ''}">
                <div class="helper">
                    <div class="lettersContent">
                        <div class="wordCruci" *ngFor="let wordH of exercise.Horizontales; let i = index" [ngClass]="getPosClass(wordH)">
                            <div class="info">{{ i + 1 }}.</div>
                            <ul class="list-inline">
                                <li *ngFor="let letter of wordH.word; let i = index" class="wordLetter" [ngClass]="{'dropzone': letter.show == false}" [attr.idLetter]="i" [attr.idWord]="wordH.idWord">
                                    <span [hidden]="letter.show" [ngStyle]="{'border': !review ? '0.1em solid var(--naranja);' : ''}" class="letter-button letter" [ngClass]="{'letter-buttonV': i == wordH.posicion -1, 'letter-buttonRed': letter.solved == false && !review,'letter-borderRed': letter.solved == false && review}">&nbsp;</span>
                                    <span [hidden]="!letter.show" [ngStyle]="{'border': !review ? '0.1em solid var(--naranja);' : ''}" class="letter-button letter" [ngClass]="{'letter-buttonV': i == wordH.posicion -1, 'letter-buttonGreen': letter.solved == true && !review, 'letter-borderGreen': letter.solved == true && review}">{{ letter.value }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="letters" *ngIf="!controlSession">
            <div class="helper">
                <div class="lettersContent">
                    <ul class="list-inline" style="padding: 0;">
                        <li *ngFor="let letter of letters; let i = index" class="letterContainer">
                            <div [ngClass]="{ chosen: letter.chosen}" class="letter-button letter draggable" [attr.idOption]="i">
                                {{ letter.name }}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div [ngStyle]="controlSession && !slide ? {clear: 'none;', 'margin-top': '0'} : {}"  [ngClass]="{'exerciseTitleRule-slide': slide,'exerciseTitleRule-slide-tablet': slide && mobileDetect.IsAndroidTablet, 'exerciseTitleRule-session': controlSession && !slide}" class="text-center exerciseTitleRule">
        <p class="exerciseRule" [ngStyle]="controlSession && !slide ? {'margin-top': '3% auto 0'} : {}" [ngClass]="{'add-margin' : isDesktop == true && !slide}" [innerHtml]="regla"></p>
    </div>
</div> -->