import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpParams, HttpEvent, HttpHeaderResponse, HttpResponse, HttpErrorResponse, HttpUserEvent, HttpProgressEvent } from '@angular/common/http';
import { Location } from '@angular/common';
import { environment } from 'environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CourseService {

    baseUrl = environment.baseApi + '/api/Course';
    private s_Courses = new BehaviorSubject<any>(null);
    Courses = this.s_Courses.asObservable();
    constructor(private http: HttpClient, private _location: Location) {
        if(this.s_Courses.value == null){
            this.getAll().subscribe((courses: []) => {
                this.loadCourses(courses);
            })
        }
    }

    loadCourses(courses: any){
        this.s_Courses.next(courses);
    }

    getAll() {
        return this.http.get(this.baseUrl + '/');
    }

}
