<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="selec spe_gap" fxLayout="column" fxLayoutAlign="stretch" [ngStyle]="{'pointer-events': review ? 'none;' : ''}">
    
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div fxFlex="auto" class="wordsContainer" id="space-for-text">
        <div class="wordRow">
            <div
                *ngFor="let item of letters; let i = index"
                (click)="validate(i)"
                [ngClass]="{
                    'boxOk': item.answered == true,
                    'boxError': item.answered == false,
                    'elementEmpty': item.letter == '',
                    'answerBoxLine': item.letter == '-',
                    'answerBox': item.letter != '-' && item.letter != ''
                }"
            >
                <span
                    id="answer{{ i }}"
                    type="up"
                    index="{{ i }}"
                    [ngClass]="{
                        answerBoxChild: item.letter != '-',
                        answerBoxChildLine: item.letter == '-'
                    }"
                >
                    {{ item.letter }}
                </span>
            </div>
        </div>
    </div>
</div>
