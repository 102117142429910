<div class="ww_c_popup_back">
    <div class="ww_c_popup large" style="max-height: 100vh !important;">
        <div class="head spe_gap" fxLayout="row" fxLayoutAlign="space-between center">
            <div>
                <span>{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.CHATGPT-TEST.TITLE' | translate }}</span>
            </div>
            <div>
                <img class="pointer" fxFlex="2rem" src="https://walinwa.blob.core.windows.net/images/ico-close-popup.svg?ngsw-bypass=true" (click)="onCancel.emit()"/>
            </div>
        </div>
        <div class="cont">
            <form fxLayout="column" [formGroup]="formChatGPTTest" fxLayoutAlign="stretch">
                <div>
                    <label class="font_blue">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.CHATGPT-TEST.THEME' | translate }}</label>
                    <mat-form-field class="less_top_margin_field">
                        <mat-select formControlName="idTestTheme" class="waliFacturasSelect">
                            <ng-container *ngIf="themes | async as themes">
                                <mat-option class="scrollable" *ngFor="let theme of themes" [value]="theme.IdTestTheme">{{theme.TestTheme}}</mat-option>
                            </ng-container>
                        </mat-select>
                        <mat-error *ngIf="formChatGPTTest.get('idTestTheme').hasError('required')">
                            {{ 'FIELD-REQUIRED' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <label class="font_blue">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.CHATGPT-TEST.COURSE' | translate }}</label>
                    <mat-form-field class="less_top_margin_field">
                        <mat-select formControlName="course" class="waliFacturasSelect">
                            <ng-container *ngIf="courses | async as courses">
                                <mat-option class="scrollable" *ngFor="let course of courses" [value]="course.Name">{{course.Name}}</mat-option>
                            </ng-container>
                        </mat-select>
                        <mat-error *ngIf="formChatGPTTest.get('course').hasError('required')">
                            {{ 'FIELD-REQUIRED' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <label class="font_blue">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.CHATGPT-TEST.DIFFICULTY' | translate }}</label>
                    <mat-form-field class="less_top_margin_field">
                        <mat-select formControlName="difficulty" class="waliFacturasSelect">
                            <mat-option class="scrollable" *ngFor="let difficulty of ['Alta', 'Media', 'Baja']" [value]="difficulty">{{difficulty}}</mat-option>
                        </mat-select>
                        <!--mat-error *ngIf="formChatGPTTest.get('difficulty').hasError('required')">
                            La dificultad es obligatoria
                        </mat-error-->
                    </mat-form-field>
                </div>
                <div>
                    <label class="font_blue">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.CHATGPT-TEST.QUESTIONS-NUMBER' | translate }}</label>
                    <mat-form-field class="less_top_margin_field">
                        <input formControlName="number" matInput type="number" class="form-control" min="1" max="100" autocomplete="off"/>
                        <mat-error *ngIf="formChatGPTTest.get('number').hasError('required')">
                            {{ 'FIELD-REQUIRED' | translate }}
                        </mat-error>
                        <mat-error *ngIf="formChatGPTTest.get('number').hasError('min')">
                            {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.CHATGPT-TEST.QUESTIONS-NUMBER-MIN' | translate }}
                        </mat-error>   
                        <mat-error *ngIf="formChatGPTTest.get('number').hasError('max')">
                            {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.CHATGPT-TEST.QUESTIONS-NUMBER-MAX' | translate }}
                        </mat-error>    
                    </mat-form-field>
                </div>
                <div>
                    <label class="font_blue">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.CHATGPT-TEST.ANSWERS-NUMBER' | translate }}</label>
                    <mat-form-field class="less_top_margin_field">
                        <input formControlName="answers" matInput type="number" class="form-control" min="2" max="6" autocomplete="off"/>                        
                        <mat-error *ngIf="formChatGPTTest.get('answers').hasError('required')">
                            {{ 'FIELD-REQUIRED' | translate }}
                        </mat-error>
                        <mat-error *ngIf="formChatGPTTest.get('answers').hasError('min')">
                            {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.CHATGPT-TEST.ANSWERS-NUMBER-MIN' | translate }}
                        </mat-error>  
                        <mat-error *ngIf="formChatGPTTest.get('answers').hasError('max')">
                            {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.CHATGPT-TEST.ANSWERS-NUMBER-MAX' | translate }}
                        </mat-error>                    
                        <mat-error *ngIf="formChatGPTTest.get('answers').hasError('greatherThan')">
                            {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.CHATGPT-TEST.ANSWERS-NUMBER-GREATHER-THAN' | translate }}
                        </mat-error>                        
                    </mat-form-field>
                </div>
                <div>
                    <label class="font_blue">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.CHATGPT-TEST.CORRECT-ANSWERS-NUMBER' | translate }}</label>
                    <mat-form-field class="less_top_margin_field">
                        <input formControlName="correct" matInput type="number" class="form-control" min="1" max="6" autocomplete="off"/>
                        <mat-error *ngIf="formChatGPTTest.get('correct').hasError('required')">
                            {{ 'FIELD-REQUIRED' | translate }}
                        </mat-error>    
                        <mat-error *ngIf="formChatGPTTest.get('correct').hasError('min')">
                            {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.CHATGPT-TEST.CORRECT-ANSWERS-NUMBER-MIN' | translate }}
                        </mat-error>            
                        <mat-error *ngIf="formChatGPTTest.get('correct').hasError('max')">
                            {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.CHATGPT-TEST.CORRECT-ANSWERS-NUMBER-MAX' | translate }}
                        </mat-error>         
                        <mat-error *ngIf="formChatGPTTest.get('correct').hasError('smallerThan')">
                            {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.CHATGPT-TEST.CORRECT-ANSWERS-NUMBER-SMALLER-THAN' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <label class="font_blue">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.CHATGPT-TEST.PROMPT' | translate }}</label>
                    <mat-form-field class="less_top_margin_field">                        
                        <textarea type="text" formControlName="prompt" matInput></textarea>                   
                        <mat-error *ngIf="formChatGPTTest.get('prompt').hasError('required')">
                            {{ 'FIELD-REQUIRED' | translate }}
                        </mat-error>  
                    </mat-form-field>
                </div>
                <div>
                    <label class="font_blue">{{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.CHATGPT-TEST.TEMPERATURE' | translate }}</label>
                    <mat-form-field class="less_top_margin_field">
                        <input formControlName="temperature" matInput type="number" class="form-control" min="0" max="2" autocomplete="off"/>                        
                        <mat-error *ngIf="formChatGPTTest.get('temperature').hasError('min')">
                            {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.CHATGPT-TEST.TEMPERATURE-MIN' | translate }}
                        </mat-error>  
                        <mat-error *ngIf="formChatGPTTest.get('temperature').hasError('max')">
                            {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.CHATGPT-TEST.TEMPERATURE-MAX' | translate }}
                        </mat-error>                    
                    </mat-form-field>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutAlign.xs="center center" class="spe_gap">
                    <button type="button" (click)="getChatGPTTest()" class="new_but azul">
                        {{'SCHOOL.TEACHER.COMPLEMENTS.PERSONALIZED-TEST.CHATGPT-TEST.GENERATE' | translate }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>