import { DeductionTextService } from './../../../../../services/deduction-text.service';
import { Component, OnInit, ViewChild, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { CourseService } from 'app/services/course.service';
import { NavigationService } from 'app/services/navigation.service';
import * as moment from 'moment';
import { CalDeductionTextComponent } from 'app/components/cal-deduction-text/cal-deduction-text.component';
import { SchoolService } from 'app/services/school.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
declare var $;

@Component({
    selector: "app-deduction",
    templateUrl: "./deduction.component.html",
    styleUrls: ["./deduction.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class DeductionComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    @ViewChild(CalDeductionTextComponent) child3: CalDeductionTextComponent;
    deductionTexts;
    student;
    selectedDay = moment();
    currentMonth;
    courses = [];
    selectedCourse;
    hasExercise = false;
    six_weeks = false;
    licenseDate;
    afterLicense;

    constructor(
        private authService: AuthService,
        private courseService: CourseService,
        private deductionTextService: DeductionTextService,
        private router: Router,
        private navigationService: NavigationService,
        private schoolService: SchoolService
    ) { 
        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit() {
        if (!this.authService.check()) return;
        this.selectedDay = moment();
        this.currentMonth = this.selectedDay.month() + 1;
        this.licenseDate = this.schoolService.school.ValidUntil;
        this.courseService.Courses.pipe(takeUntil(this._unsubscribeAll)).subscribe(courses => {
            this.courses = courses;
            if (this.courses) {
                this.selectedCourse = this.courses[0].Level;
            }
            
        })
        this.deductionTextService.reload_monthly_deduction
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                if (result) {
                    this.getDeductionTexts();
                }
        })
        this.getDeductionTexts();
    }

    getDeductionTexts() {
        this.hasExercise = false;
        this.deductionTextService.getDeductionByLevel(this.selectedCourse, this.currentMonth, this.selectedDay.year())
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
            result => {
                this.deductionTexts = result;
                this.deductionTextService.MonthlyDeduction = result;
                this.child3.refresh((weeks_count) => {
                    this.six_weeks = weeks_count >= 6;
                });
                if (!this.deductionTexts) return;
                let currentReadingText = this.deductionTexts.find((r) =>
                    moment(r.date).isSame(this.selectedDay)
                );
                if (!currentReadingText) return;
                if (moment().month() < this.selectedDay.month() - 1) return;
                if (moment().month() > this.selectedDay.month() + 1) return;
                this.hasExercise = true;
            });
    }

    selectedDayChangedDT(newDate) {
        this.selectedDay = newDate;
        this.afterLicense = this.selectedDay.isAfter(moment(this.licenseDate));
        if (this.currentMonth != this.selectedDay.month() + 1) {
            this.currentMonth = this.selectedDay.month() + 1;
            this.getDeductionTexts();
        } else {
            this.hasExercise = false;
            if (!this.deductionTexts) return;
            let currentReadingText = this.deductionTexts.find((r) =>
                moment(r.date).isSame(this.selectedDay)
            );
            if (!currentReadingText) return;
            if (moment().month() < this.selectedDay.month() - 1) return;
            if (moment().month() > this.selectedDay.month() + 1) return;
            this.hasExercise = true;
        }
    }

    showExercise() {
        if (!this.deductionTexts) return;
        let currentDeductionText = this.deductionTexts.find((r) =>
            moment(r.date).isSame(this.selectedDay)
        );
        if (!currentDeductionText) return;
        this.navigationService.go(
            this.router.url + "/review",
            { review:true, reviewing: true, date: currentDeductionText.date, IdDeduction: currentDeductionText.IdDeduction, selectedDay: this.selectedDay }
        );
    }
}
