<div class="walinwa-box">

    <div class="walinwa-box-content">
        <div>
            <div>
                {{'SCHOOL.TEACHER.ACTIVITIES.DICTATION.TITLE' | translate }}
            </div>
            <div class="custom-form-field" style="margin-top: 20px">
                <mat-form-field>
                    <label>{{'SCHOOL.TEACHER.ACTIVITIES.DICTATION.COURSE' | translate }}</label>
                    <mat-select [(ngModel)]="selectedCourse" (selectionChange)="checkDictation()">
                        <mat-option class="scrollable" *ngFor="let course of courses  | sortBy:'asc':'Level'"
                            [value]="course.Level">{{ course.Name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- <div class="custom-form-field">
                <label>{{'SCHOOL.COURSE' | translate}}</label>
                <select [(ngModel)]="selectedCourse" (change)="checkDictation()">
                    <option *ngFor="let course of courses | sortBy:'asc':'Level'" [value]="course.Level">
                        {{ course.Name }}
                    </option>
                </select>
            </div> -->

            <cal-dictations *ngIf="selectedCourse" class="calendario dinamico walCalendar azul ww_customCalendar"
                [parent]="this" [fullDay]="false" [showAll]="true" [currentUser]="user" [selectedDay]="selectedDay"
                [currentMonth]="currentMonth" [dictations]="[]" [licenseDate]="licenseDate">
            </cal-dictations>

        </div>
        <div class="walinwa-row spe_gap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center">

            <button (click)="showDictation()" [disabled]="!hasExercise || afterLicense" class="new_but azul">
                {{'SCHOOL.TEACHER.ACTIVITIES.DICTATION.SHOW-EXERCISE' | translate }}
            </button>

        </div>
    </div>

</div>

<router-outlet></router-outlet>