<div class="walinwa-box">
    <form [formGroup]="form" class="walinwa-box-content">
        <!--div style="margin-top: -1.3rem; margin-bottom: -1rem;"-->
        <div style="margin-bottom: -0.30rem">
            <mat-form-field>
                <label class="font_blue"><b>{{'CLASS' | translate}}</b></label>
                <mat-select id="select-class" [(ngModel)]="selectedClass" (selectionChange)="checkConfig()"
                    [ngModelOptions]="{standalone: true}">
                    <mat-option class="scrollable" [value]="0">{{'SCHOOL.TEACHER.CONFIG.ALL-CLASSES' | translate}}</mat-option>
                    <mat-option class="scrollable" *ngFor="let class of classes | sortBy:'asc':['Level','Code']"
                        [value]="class.IdClass">{{ class.Name }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxNgClass.gt-sm="adjust_ipad_margin" fxNgClass.gt-md="">
            {{'SCHOOL.TEACHER.CONFIG.ACTIVITIES.TIMEBLOCK' | translate}}
        </div>
        <div>
            <label class="cust_label" [ngClass]="{'disabled' : !enableReading}"><b>{{'SCHOOL.TEACHER.CONFIG.ACTIVITIES.READING' | translate}}</b></label>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutAlign.lt-md="end center" class="spe_gap" fxNgClass.gt-sm="adjust_ipad_margin" fxNgClass.gt-md="">
            <div fxFlex="" fxFlex.lt-md="" class="custom-form-field">
                <span class=""><b>{{'SCHOOL.TEACHER.CONFIG.TIMEINI' | translate}}</b></span>
                <input type="time" *ngIf="!enableReading" disabled [ngClass]="{ 'iPad-time-input': isIpad }" formControlName="ReadIni">
                <input type="time" *ngIf="enableReading" [ngClass]="{ 'iPad-time-input': isIpad }" formControlName="ReadIni">
            </div>
            <div fxFlex="" fxFlex.lt-md="" class="custom-form-field">
                <span class=""><b>{{'SCHOOL.TEACHER.CONFIG.TIMEEND' | translate}}</b></span>
                <input *ngIf="!enableReading" disabled type="time" [ngClass]="{ 'iPad-time-input': isIpad }" formControlName="ReadEnd">
                <input *ngIf="enableReading" type="time" [ngClass]="{ 'iPad-time-input': isIpad }" formControlName="ReadEnd">
            </div>
            <div fxFlex="" fxHide fxShow.gt-sm class="custom-form-field">
                <!--  -->
            </div>
        </div>
        <div >
            <label class="cust_label" [ngClass]="{'disabled' : !enableDeduction}"><b>{{'SCHOOL.TEACHER.CONFIG.ACTIVITIES.DEDUCTION' | translate}}</b></label>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutAlign.lt-md="end center" class="spe_gap" fxNgClass.gt-sm="adjust_ipad_margin" fxNgClass.gt-md="">
            <div fxFlex="" fxFlex.lt-md="" class="custom-form-field">
                <span class=""><b>{{'SCHOOL.TEACHER.CONFIG.TIMEINI' | translate}}</b></span>
                <input type="time" *ngIf="!enableDeduction" disabled [ngClass]="{ 'iPad-time-input': isIpad }" formControlName="DedIni">
                <input type="time" *ngIf="enableDeduction" [ngClass]="{ 'iPad-time-input': isIpad }" formControlName="DedIni">
            </div>
            <div fxFlex="" fxFlex.lt-md="" class="custom-form-field">
                <span class=""><b>{{'SCHOOL.TEACHER.CONFIG.TIMEEND' | translate}}</b></span>
                <input *ngIf="!enableDeduction" disabled  type="time" [ngClass]="{ 'iPad-time-input': isIpad }" formControlName="DedEnd">
                <input  *ngIf="enableDeduction"type="time" [ngClass]="{ 'iPad-time-input': isIpad }" formControlName="DedEnd">
            </div>
            <div fxFlex="" fxHide fxShow.gt-sm class="custom-form-field">
                <!--  -->
            </div>
        </div>
        <div >
            <label class="cust_label" [ngClass]="{'disabled' : !enableHearing}"><b>{{'SCHOOL.TEACHER.CONFIG.ACTIVITIES.HEARING' | translate}}</b></label>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutAlign.lt-md="end center" class="spe_gap" fxNgClass.gt-sm="adjust_ipad_margin" fxNgClass.gt-md="">
            <div fxFlex="" fxFlex.lt-md="" class="custom-form-field">
                <span class=""><b>{{'SCHOOL.TEACHER.CONFIG.TIMEINI' | translate}}</b></span>
                <input type="time" *ngIf="!enableHearing" disabled [ngClass]="{ 'iPad-time-input': isIpad }" formControlName="HearIni">
                <input type="time" *ngIf="enableHearing" [ngClass]="{ 'iPad-time-input': isIpad }" formControlName="HearIni">
            </div>
            <div fxFlex="" fxFlex.lt-md="" class="custom-form-field">
                <span class=""><b>{{'SCHOOL.TEACHER.CONFIG.TIMEEND' | translate}}</b></span>
                <input *ngIf="!enableHearing" disabled type="time" [ngClass]="{ 'iPad-time-input': isIpad }" formControlName="HearEnd">
                <input *ngIf="enableHearing" type="time" [ngClass]="{ 'iPad-time-input': isIpad }" formControlName="HearEnd">
            </div>
            <div fxFlex="" fxHide fxShow.gt-sm class="custom-form-field">
                <!--  -->
            </div>
        </div>
        <div class="walinwa-row" fxLayoutAlign="end end" fxLayoutAlign.xs="center center">
            <button (click)="save()" class="new_but azul">
                {{'SAVE' | translate}}
            </button>
        </div>
    </form>

</div>

<router-outlet></router-outlet>