<section class="ww_header" id="TooperHeaderElement">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="container_ww" fxLayoutGap.xs="0"
        fxLayoutGap.gt-xs="1rem">
        <div fxFlex="7.4120rem" fxHide.xs fxShow>
            <img src="https://walinwa.blob.core.windows.net/images/logo_walinwa_pantallas1.png?ngsw-bypass=true" fxFlex.xs="9rem" *ngIf="!christmas" />
            <img src="https://walinwa.blob.core.windows.net/images/logo_Walinwa_navidad.gif?ngsw-bypass=true" fxFlex.xs="9rem" *ngIf="christmas">
        </div>
        <div class="logo_mobile_topper" (click)="transactions()" fxHide fxShow.xs>
            <img src="https://walinwa.blob.core.windows.net/images/walinwa-logo-mob.svg?ngsw-bypass=true" />
        </div>
        <div fxFlex="" fxLayout="row wrap" fxLayoutAlign="center center" class="tablero_mob">
            <div [hidden]="adminMessages == null" class="incommingMessageWalinwa mr-8" (click)="showAdminMessagesF()">
            </div>
            <div [hidden]="teacherMessages == null" class="incommingMessageTeacher mr-8"
                (click)="showTeacherMessagesF()">
            </div>
            <!--  -->
            <div fxFlex="5.25rem" fxFlex.xs="4.5rem"
                *ngIf="tourWords && tourWords.Show && !teacherMessages && !adminMessages && (tourWords.TournamentAvailable || isWeekend())"
                class="mr-8">
                <img (click)="initTourWords()" [ngClass]="{ww_blink: !tourWords.IsWeekend && !tourWords.completed}"
                    src="https://walinwa.blob.core.windows.net/images/Student/torneo/boton_tp.svg?ngsw-bypass=true">
            </div>
            <div fxFlex="5.25rem" fxFlex.xs="4.5rem" *ngIf="adminMessages != null" class="mr-8">
                <img (click)="showAdminMessagesF()" src="https://walinwa.blob.core.windows.net/images/Student/msgStdWalinwa.svg?ngsw-bypass=true" class="ww_blink">
            </div>
            <div fxFlex="5.25rem" fxFlex.xs="4.5rem" *ngIf="teacherMessages != null" class="mr-8">
                <img (click)="showTeacherMessagesF()" src="https://walinwa.blob.core.windows.net/images/Student/msgStdProfe.svg?ngsw-bypass=true" class="ww_blink">
            </div>
            <!--  -->
            <div fxLayout="row" fxLayoutAlign="center center" class="c_gap">
                <div id="coin-plank-mob" fxHide fxShow.xs *ngIf="tourWords || adminMessages || teacherMessages"></div>
                <div class="wa_box_coins" (click)="transactions()" fxHide fxShow.xs id="coin-plank-mob"
                    *ngIf="tourWords == null && adminMessages == null && teacherMessages == null">
                    <div *ngIf="currentUser">{{currentUser.Coins | digitSpacingPipe}}</div>
                    <div>walinwos</div>
                </div>
                <div [ngStyle]="{'background-image': 'url(' + getTableroStyle() + ')'}" id="coin-plank"
                    class="coins-plank" fxHide.xs>
                    <div class="coinsNumber rtext" [ngClass]="{'oscuro': oscuro()}">
                        <img class="coinsInfo" (click)="transactions()" src="https://walinwa.blob.core.windows.net/images/ico-info-white.svg?ngsw-bypass=true" />
                        <span>{{'STUDENT.YOU-HAVE' | translate}}</span><br>
                        <span *ngIf="currentUser">{{currentUser.Coins | digitSpacingPipe}}</span><br>
                        <span>walinwos</span>
                    </div>
                </div>
                <div class="treasure" (click)="transactions()" [style]="getChestStyle()" fxHide.xs></div>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" class="spe_gap">
            <div class="ww_userinfo text-right">
                <div class="walinwa-tooltip" fxNgClass.xs="walinwa-tooltip-top" fxNgClass.gt-xs="walinwa-tooltip-left"
                    *ngIf="licenseInfo" (click)="licenseInfo=false">
                    <div class="walinwa-tooltip-info walinwa-tooltip-info-primary">
                        {{'USERNAME' | translate}} <b>{{ currentUser.Id }}</b>.
                    </div>
                    <div class="walinwa-tooltip-info" *ngIf="currentUser.IdClass != null">
                        {{'STUDENT.CLASS-ID' | translate}} <b>{{ currentUser.IdClass }}</b>,
                        <b>{{currentUser.Name}}</b>,
                    </div>
                    <div class="walinwa-tooltip-info" *ngIf="currentUser.IdClass != null">
                        {{'CLASS' | translate}}: <b>{{currentUser.ClassName}}</b>
                    </div>
                    <div class="walinwa-tooltip-info">
                        {{'STUDENT.LICENSE-VALID-TO' | translate}} <b>{{
                            formatDate(currentUser.ValidUntil) }}</b>.
                    </div>
                    <div class="ww_last_login" *ngIf="currentUser.LastLogin">{{'STUDENT.LAST-LOGIN' | translate}} <b>{{
                            currentUser.LastLogin.Version }}</b></div>
                </div>
                <div *ngIf="currentUser">
                    <img (click)="showLicenseInfo()" style="
                                cursor: pointer;
                                height: 1.2rem;
                                margin-right: 0.3rem; display: inline; vertical-align: middle;
                            " src="https://walinwa.blob.core.windows.net/images/ico-info-white.svg?ngsw-bypass=true" [hidden]="schoolAdminView == true" fxHide.xs />
                    <span class="pointer" (click)="birthday()">{{currentUser.UserName}}</span>
                </div>
                <div *ngIf="currentUser" (click)="birthday()" class="pointer">
                    {{currentUser.Alias}}
                </div>
                <div *ngIf="currentUser && currentUser.SchoolName != null && currentUser.SchoolName != ''"
                    class="pointer" (click)="birthday()">
                    {{ currentUser.SchoolName }}
                </div>
            </div>
            <!-- <div (click)="birthday(false)" routerLink="/student/session" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: false}" fxFlex="5.25rem" class="pointer"> -->
            <div (click)="avatarActive ? PanelAvantarShow() : 'javascript:void(0)'" fxFlex="5.25rem" class="pointer">
                <img src="https://walinwa.blob.core.windows.net/images/user_ico.svg?ngsw-bypass=true"  class="pointer" width="100%" *ngIf="!avatarActive" />
                <div class="student_avatar_header" *ngIf="avatarActive">
                    <div class="circle"></div>
                    <avatar-file *ngIf="activeItems" [activeItems]=activeItems></avatar-file>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="ww_content" id="MainContent" fxLayout="column" fxLayoutAlign="stretch">
    <div class="container_ww" fxFlex="" fxLayout="column" fxLayoutAlign="stretch">
        <div class="mob_topper_icons">
            <div fxFlex="3rem" id="noMenuAdminProfile">
                <img src="https://walinwa.blob.core.windows.net/images/Student/ico-menu-mobile-student.svg?ngsw-bypass=true" class="mobile_but_menu active" id="openMobMenu"
                    (click)="openMobMenuFunction();" />
                <img src="https://walinwa.blob.core.windows.net/images/Student/ico-menu-mobile-close-student.svg?ngsw-bypass=true" class="mobile_but_menu_close"
                    id="openMobMenuClose" (click)="openMobMenuFunction();" />
            </div>
        </div>
        <!--  -->
        <section style="width: 100%; height: 100%;" fxLayout="row" fxLayout.lt-md="column"
            fxLayoutAlign="space-between stretch" fxLayoutAlign.lt-md="stretch" fxNgClass.gt-sm="spe_gap">
            <div fxFlex="11rem" fxFlex.lt-md="0 0 auto" fxNgClass.lt-md="mob_cont_menu" fxNgClass.gt-sm="scroll_vertical"
                class="iphonechromepopup">
                <div [hidden]="!(completeMenuChecked$ | async)">
                    <div class="ww_sidebar_menu" id="mobiMenuFlot">
                        <button routerLink="/student/session" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">{{'SCHOOL.REVIEW-ACTIVITIES.SESSION-TITLE' | translate}}</button>

                        @if (sharedService.dictation_enabled | async) {
                            <button routerLink="/student/dictation" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">{{'SCHOOL.REVIEW-ACTIVITIES.DICTATION-TITLE' | translate}}</button>
                        } @else {
                            <button class="disabled-sidebar-button">{{'SCHOOL.REVIEW-ACTIVITIES.DICTATION-TITLE' | translate}}</button>
                        }

                        @if ((sharedService.reading_text_enabled | async) || (sharedService.deduction_text_enabled | async) || (sharedService.hearing_text_enabled | async)) {
                            @if (isNotCurrentLocation()) {
                                @if (sharedService.reading_text_enabled | async) {                                                                        
                                    <button routerLink="/student/reading-text">{{'STUDENT.COMPREHENSIONS-TITLE' | translate}}</button>
                                } @else {
                                    @if (sharedService.deduction_text_enabled | async) {
                                        <button routerLink="/student/deduction">{{'STUDENT.COMPREHENSIONS-TITLE' | translate}}</button>
                                    } @else {
                                        @if (sharedService.hearing_text_enabled | async) {
                                            <button routerLink="/student/hearing-text">{{'STUDENT.COMPREHENSIONS-TITLE' | translate}}</button>
                                        }
                                    }
                                }
                            } @else {
                                <button class="active">{{'STUDENT.COMPREHENSIONS-TITLE' | translate}}</button>
                            }
                        } @else {
                            <button class="disabled-sidebar-button">{{'STUDENT.COMPREHENSIONS-TITLE' | translate}}</button>
                        }

                        <!--@if (sharedService.reading_text_enabled | async) {
                            <button routerLink="/student/reading-text" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">Comprensión</button>
                        } @else {
                            <button class="disabled-sidebar-button">Comprensión</button>
                        }

                        @if (sharedService.deduction_text_enabled | async) {
                            <button routerLink="/student/deduction" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">Deducción</button>
                        } @else {
                            <button [disabled]="true" class="disabled-sidebar-button">Deducción</button>
                        }

                        @if (sharedService.hearing_text_enabled | async) {
                            <button routerLink="/student/hearing-text" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">Auditiva</button>
                        } @else {
                            <button [disabled]="true" class="disabled-sidebar-button">Auditiva</button>
                        }-->

                        @if (reto$ | async) {
                            <button routerLink="/student/challenge" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">{{'STUDENT.CHALLENGE-TITLE' | translate}}</button>
                        }

                        @if (visualMemoryService.memo_image_enabled | async) {
                            <button routerLink="/student/visual-memory" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">{{'STUDENT.VISUALMEMORY-TITLE' | translate}}</button>
                        }
                        @if (waliLee$ | async) {
                            <button routerLink="/student/walilee"
                            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                            false}">{{'SCHOOL.TEACHER.WALILEE-TITLE' | translate }}</button>
                            }

                        <button routerLink="/student/zone" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">{{'STUDENT.MYZONE-TITLE' | translate}}</button>
                        
                        @if (isDevMode) {
                            <button routerLink="/student/minigames" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">{{'STUDENT.MINIGAMES-TITLE' | translate}}</button>
                        }
                    </div>
                </div>
            </div>
            <div id="studentContainer" fxFlex="" fxFlex.lt-md="0 0 auto" fxNgClass.gt-sm="scroll_vertical" class="iphonechromepopup">
                <ng-container *ngTemplateOutlet="template"></ng-container>
                <router-outlet (activate)="activate($event)"></router-outlet>
            </div>
        </section>
    </div>
</section>

<section class="ww_footer" id="FotFooterElement">
    <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="container_ww spe_gap" fxHide fxShow.gt-sm>
        <div>
            <button [hidden]="demoUser == true && smDemo != true"
                (click)="!(demoUser == true && smDemo != true) ? logoutUser() : null" class="ww_button goback">
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0.5rem">
                    <span fxFlex="1.875rem">
                        <img src="https://walinwa.blob.core.windows.net/images/ico-goback.svg?ngsw-bypass=true"  />
                    </span>
                    <span>
                        {{'DEMAND.EXIT-BUTTON' | translate}}
                    </span>
                </div>
            </button>
            <button [hidden]="demoUser != true || smDemo == true"
                (click)="!(demoUser != true || smDemo == true) ? logoutUser() : null" class="ww_button goback">
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0.5rem">
                    <span fxFlex="1.875rem">
                        <img src="https://walinwa.blob.core.windows.net/images/ico-goback.svg?ngsw-bypass=true" />
                    </span>
                    <span>
                        {{'STUDENT.BACK' | translate}}
                    </span>
                </div>
            </button>
        </div>
        <div [hidden]="contactEnabled == false">
            <button (click)="contact()" class="none_button">
                <img src="https://walinwa.blob.core.windows.net/images/ico-footer-my-s.svg?ngsw-bypass=true" style="margin-right: 0.5em; width: 30px;"> {{'STUDENT.CONTACT-TITLE' | translate}}
            </button>
        </div>
        <div>
            <button (click)="openHelp()" class="none_button">
                <img src="https://walinwa.blob.core.windows.net/images/ico-footer-repasar-teoria.svg?ngsw-bypass=true" style="margin-right: 0.5em; width: 30px;"> {{'STUDENT.HELP-TITLE' | translate}}
            </button>
        </div>
        <div>
            <button (click)="tutorial()" class="none_button">
                <img src="https://walinwa.blob.core.windows.net/images/ico-help.svg?ngsw-bypass=true" style="margin-right: 0.5em; width: 26px;" /> {{'STUDENT.TUTORIAL-TITLE' | translate}}
            </button>
        </div>
    </div>
    <!-- Mobile -->
    <div fxLayout="row" fxLayoutAlign="space-between start" class="container_ww spe_gap footer_op_mob" fxShow
        fxHide.gt-sm>
        <div>
            <a href="javascript:void(0)" [hidden]="demoUser == true && smDemo != true"
                (click)="!(demoUser == true && smDemo != true) ? logoutUser() : null" fxLayout="column"
                fxLayoutAlign="center center" fxLayoutGap="0.5rem" class="text-center">
                <img src="https://walinwa.blob.core.windows.net/images/ico-goback.svg?ngsw-bypass=true" class="fico" />
                <span>{{'DEMAND.EXIT-BUTTON' | translate}}</span>
            </a>
            <a [hidden]="demoUser != true || smDemo == true"
                (click)="!(demoUser != true || smDemo == true) ? logoutUser() : null" fxLayout="column"
                fxLayoutAlign="center center" fxLayoutGap="0.5rem" class="text-center">
                <img src="https://walinwa.blob.core.windows.net/images/ico-goback.svg?ngsw-bypass=true" class="fico" />
                <span>{{'STUDENT.BACK' | translate}}</span>
            </a>
        </div>
        <div [hidden]="contactEnabled == false">
            <a href="javascript:void(0);" (click)="contact()" fxLayout="column" fxLayoutAlign="center center"
                fxLayoutGap="0.5rem" class="text-center">
                <img src="https://walinwa.blob.core.windows.net/images/ico-footer-my-s.svg?ngsw-bypass=true" class="fico" />
                <span [innerHTML]="'STUDENT.CONTACT-TITLE2' | translate"></span>
            </a>
        </div>
        <div>
            <a href="javascript:void(0);" (click)="openHelp()" fxLayout="column" fxLayoutAlign="center center"
                fxLayoutGap="0.5rem" class="text-center">
                <img src="https://walinwa.blob.core.windows.net/images/ico-footer-repasar-teoria.svg?ngsw-bypass=true" class="fico" />
                <span [innerHTML]="'STUDENT.HELP-TITLE2' | translate"></span>
            </a>
        </div>
        <div>
            <a href="javascript:void(0);" (click)="tutorial()" fxLayout="column" fxLayoutAlign="center center"
                fxLayoutGap="0.5rem" class="text-center">
                <img src="https://walinwa.blob.core.windows.net/images/ico-help.svg?ngsw-bypass=true" class="fico" />
                <span>{{'STUDENT.TUTORIAL-TITLE' | translate}}</span>
            </a>
        </div>
    </div>
</section>

<div class="back_overlay_menu" id="backOverlayMenu" (click)="openMobMenuFunction()"></div>

<!-- Avatar Panel -->
<section id="avatarpanel" class="avatar_panel" *ngIf="avatarActive && avatarShopVisible">
    <div class="back_overlay">
        <avatarShop fxFlex="100%" fxLayout="column" fxLayoutAlign="stretch" (onClose)="onAvatarShopClose()"></avatarShop>
    </div>
</section>
<!-- END Avatar Panel -->

<!-- Modales -->
<div class="walinwa-overlay" *ngIf="askBirthday || showTeacherMessages || showAdminMessages" fxLayout="column"
    fxLayoutAlign="center center">
    <section class="section_initial_avatar" fxLayout="column">
        <div class="walinwa-modal walinwa-box newmodal" *ngIf="showTeacherMessages || showAdminMessages">
            <div class="walinwa-modal-header walinwa-box-header" *ngIf="showAdminMessages"
                style="background-color: var(--verdeAzulado);">
                {{'STUDENT.WALINWA-MESSAGES' | translate}}
                <img (click)="closeAdminMessage()" style="cursor: pointer; height: 1.5em"
                src="https://walinwa.blob.core.windows.net/images/blanco-99.png?ngsw-bypass=true">
            </div>
            <div class="walinwa-modal-header walinwa-box-header" *ngIf="showTeacherMessages"
                style="background-color: var(--morado);">
                {{'STUDENT.TEACHER-MESSAGES' | translate}}
                <img (click)="closeTeacherMessage()" style="cursor: pointer; height: 1.5em"
                src="https://walinwa.blob.core.windows.net/images/blanco-99.png?ngsw-bypass=true">
            </div>
            <div class="walinwa-modal-body walinwa-box-content" *ngIf="showAdminMessages">
                <div class="messageText allowScroll"
                    [innerHtml]="splitByHastahg(adminMessages[0].AdminMessage.Message)">
                </div>
                <div fxLayout="row" fxLayoutAlign="end">
                    <img fxFlex="3rem" src="https://walinwa.blob.core.windows.net/images/Student/msgGlobo.png?ngsw-bypass=true" />
                </div>
            </div>
            <div class="walinwa-modal-body walinwa-box-content" *ngIf="showTeacherMessages">
                <div class="messageText allowScroll"
                    [innerHtml]="splitByHastahg(teacherMessages[0].TeacherMessage.Message)"></div>
                <div fxLayout="row" fxLayoutAlign="end">
                    <img fxFlex="3rem" src="https://walinwa.blob.core.windows.net/images/Student/msgProfe.png?ngsw-bypass=true" />
                </div>
            </div>
        </div>
        <div fxFlex="" class="modify_student_data modal_mis_datos" *ngIf="askBirthday || askPassword" fxFlex="column"
            fxLayoutAlign="stretch">
            <div fxFlex="" class="walinwa-box-with-tabs" fxLayout="column" fxLayoutAlign="stretch">
                <!-- <div class="walinwa-tabs walinwa-tabs-top">
                    <div [ngClass]="{ 'orange-selected': !status}" (click)="setStatus(false)"
                        class="walinwa-tab-gray two">
                        Mis datos
                    </div>
                    <div *ngIf="avatarActive" [ngClass]="{ 'orange-selected': status}" (click)="setStatus(true)"
                        class="walinwa-tab-gray two">
                        Mi avatar
                    </div>
                </div> -->
                <div fxFlex="" fxFlex.xs="100%" class="box_white_datos" fxLayout="row" fxLayoutAlign="center center"
                    fxLayoutAlign.xs="space-between start">
                    <div class="spe_gap" fxFlex="0 1 40.625rem" *ngIf="!status" fxLayout="column"
                        fxLayoutAlign="stretch">
                        <form [formGroup]="formData" class="ww_box_misdatos spe_gap" fxLayout="row"
                            fxLayout.xs="row wrap" fxLayoutAlign="space-between stretch"
                            fxLayoutAlign.xs="center center">
                            <div fxFlex="30%" fxFlex.xs="50%">
                                <div class="avatar_card" *ngIf="avatarActive">
                                    <avatar-file *ngIf="activeItems" [activeItems]=activeItems></avatar-file>
                                </div>
                                <img src="https://walinwa.blob.core.windows.net/images/user_ico-orange.svg?ngsw-bypass=true" style="height: 85%; width: 90%;"
                                    *ngIf="!avatarActive" />
                            </div>
                            <div fxFlex="" fxFlex.xs="100%" fxLayout="column" fxLayoutAlign="stretch">
                                <div>
                                    <mat-form-field class="less_top_margin_field" fxFlexFill>
                                        <label class="font_blue" fxFlexFill>{{'NAME' | translate}}</label>
                                        <input [readonly]="currentUser.BlockName" [ngStyle]="currentUser.BlockName ? { 'background-color': 'var(--grisClaro)' } : null" formControlName="Name" matInput
                                            type="text" class="form-control" />
                                        <mat-error *ngIf="formData.get('Name').hasError('required')">
                                            {{'FIELD-REQUIRED' | translate}}
                                        </mat-error>
                                        <mat-error *ngIf="formData.get('Name').hasError('pattern')">
                                            {{'PATTERN-ERROR' | translate}}
                                        </mat-error>
                                        <mat-error *ngIf="formData.get('Name').hasError('maxlength')">
                                            {{'NAME-MAX' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field class="less_top_margin_field" fxFlexFill>
                                        <label class="font_blue" fxFlexFill>{{'FAMILYNAME' | translate}}</label>
                                        <input [readonly]="currentUser.BlockName" [ngStyle]="currentUser.BlockName ? { 'background-color': 'var(--grisClaro)' } : null" formControlName="FamilyName" matInput type="text" class="form-control" />
                                        <mat-error *ngIf="formData.get('FamilyName').hasError('pattern')">
                                            {{'PATTERN-ERROR' | translate}}
                                        </mat-error>
                                        <mat-error *ngIf="formData.get('FamilyName').hasError('maxlength')">
                                            {{'FAMILYNAME-MAX' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field class="less_top_margin_field" fxFlexFill>
                                        <label class="font_blue" fxFlexFill>{{'ALIAS' | translate}}</label>
                                        <input [disabled]="currentUser.BlockAlias" formControlName="Alias" matInput
                                            type="text" class="form-control"
                                            [ngClass]="currentUser.BlockAlias ? 'blockedfield':''" />
                                        <mat-error *ngIf="formData.get('Alias').hasError('maxlength')">
                                            {{'ALIAS-MAX' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <div style="position: relative;">
                                        <tooltip style="position: absolute; top: -3px; left: 165px;" id="dict" [info]="'STUDENT.BIRTHDAY-TOOLTIP' | translate" position="R">
                                        </tooltip>
                                    </div>
                                    <label class="font_blue">
                                        {{'BIRTHDATE' | translate}}
                                    </label>
                                    <mat-form-field class="less_top_margin_field" fxFlexFill>
                                        <input matInput [matDatepicker]="picker" [max]="maxDateDB"
                                            formControlName="DateOfBirth">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                        </form>
                        <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutAlign.xs="center center"
                            class="spe_gap">
                            <button (click)="showAskPassword()" class="new_but azul">
                                {{'STUDENT.CHANGE-PASSWORD' | translate}}
                            </button>
                            <button [disabled]="formData.invalid" (click)="updateUser()" class="new_but azul">
                                {{'SAVE' | translate}}
                            </button>
                        </div>
                        <div class="ww_c_popup_back" *ngIf="askPassword">
                            <div class="ww_c_popup large">
                                <div class="head spe_gap" fxLayout="row" fxLayoutAlign="space-between center">
                                    <div>
                                        {{'STUDENT.CHANGE-PASSWORD' | translate}}
                                    </div>
                                    <div>
                                        <img (click)="askPassword = false" class="pointer" fxFlex="20px"
                                        src="https://walinwa.blob.core.windows.net/images/ico-close-avatar.svg?ngsw-bypass=true" style="filter: brightness(900%);">
                                    </div>
                                </div>
                                <div class="cont">
                                    <form *ngIf="askPassword" fxLayout="column" fxLayoutAlign="stretch">
                                        <div>
                                            <label class="font_blue" fxFlexFill>{{'STUDENT.CURRENT-PASSWORD' | translate}}</label>
                                            <mat-form-field class="less_top_margin_field"
                                                [ngClass]="{ error: !checkClaveActual }" fxFlexFill>
                                                <input (ngModelChange)="newPassword.act.changed=true"
                                                    [(ngModel)]="newPassword.act.value"
                                                    [ngModelOptions]="{standalone: true}" matInput
                                                    [type]="showPassword1 ? 'text' : 'password'" class="form-control">
                                                <i class="icone pass_icon"
                                                    [ngClass]="showPassword1 ? 'eye-open' : 'eye-close'" matSuffix
                                                    (click)="showPass1()"></i>
                                            </mat-form-field>
                                            <mat-error *ngIf="!checkClaveActual" style="margin:-2rem 0 1rem">
                                                {{'PASSWORD-WRONG' | translate}}
                                            </mat-error>
                                        </div>
                                        <div>
                                            <label class="font_blue" fxFlexFill>{{ 'AUTHENTICATION.RES_PW.PASSWORD1' | translate }}</label>
                                            <mat-form-field class="less_top_margin_field" fxFlexFill>
                                                <input (ngModelChange)="newPassword.p1.changed=true"
                                                    [(ngModel)]="newPassword.p1.value"
                                                    [ngModelOptions]="{standalone: true}" matInput
                                                    [type]="showPassword2 ? 'text' : 'password'" class="form-control">
                                                <i class="icone pass_icon"
                                                    [ngClass]="showPassword2 ? 'eye-open' : 'eye-close'" matSuffix
                                                    (click)="showPass2()"></i>
                                            </mat-form-field>
                                            <mat-error
                                                *ngIf="newPassword.p1.changed && (len(newPassword.p1.value) < 6 || len(newPassword.p1.value) > 10)"
                                                style="margin:-2rem 0 1rem">
                                                {{'PASSWORD-LENGTH' | translate}}.
                                            </mat-error>
                                        </div>
                                        <div>
                                            <label class="font_blue" fxFlexFill>{{'STUDENT.REPEAT-PASSWORD' | translate}}</label>
                                            <mat-form-field
                                                [ngClass]="{ error: newPassword.p2.changed && newPassword.p2.value != newPassword.p1.value }"
                                                class="less_top_margin_field" fxFlexFill>
                                                <input (ngModelChange)="newPassword.p2.changed=false"
                                                    [(ngModel)]="newPassword.p2.value"
                                                    [ngModelOptions]="{standalone: true}" matInput
                                                    [type]="showPassword3 ? 'text' : 'password'" class="form-control">
                                                <i class="icone pass_icon"
                                                    [ngClass]="showPassword3 ? 'eye-open' : 'eye-close'" matSuffix
                                                    (click)="showPass3()"></i>
                                            </mat-form-field>
                                            <mat-error
                                                *ngIf="newPassword.p2.changed && newPassword.p2.value != newPassword.p1.value"
                                                style="margin:-2rem 0 1rem">
                                                {{'AUTHENTICATION.RES_PW.PASSWORD-DIFFERENT' | translate}}
                                            </mat-error>
                                            <mat-error
                                                *ngIf="newPassword.p2.changed && (len(newPassword.p2.value) < 6 || len(newPassword.p2.value) > 10)"
                                                style="margin:-2rem 0 1rem">
                                                {{'PASSWORD-LENGTH' | translate}}.
                                            </mat-error>
                                        </div>
                                        <div fxLayout="row wrap" fxLayoutAlign="space-between center"
                                            fxLayoutAlign.xs="center center">
                                            <div fxHide fxShow.gt-xs></div>
                                            <button (click)="comprobarClaveActual()" class="new_but azul">
                                                {{'SAVE' | translate}}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="walinwa_close_image popupdatos pointer" src="https://walinwa.blob.core.windows.net/images/ico-close-avatar.svg?ngsw-bypass=true"
                    (click)="closeModal()" />
            </div>
        </div>
    </section>
</div>
<studentHelp (changeShowHelp)="changeShow($event)" *ngIf="showHelp"></studentHelp>

<reviewTheory (changeShowHelp)="changeShow($event)" *ngIf="showReview"></reviewTheory>

<div class="walinwa-overlay" *ngIf="showTourWords">

    <div class="walinwa-modal modal-tour-word modal-tour-word-step-1" *ngIf="showTourWords && tourWords.Step == 1">

        <div class="mdl2">

            <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
                <div fxFlex="2.5rem"></div>
                <div fxFlex="" fxLayout="column" fxLayoutAlign="center center">
                    <div *ngIf="!tourWords.IsShort" [innerHtml]="'STUDENT.TOUR-WORD.TITLE1' | translate"></div>
                    <div *ngIf="tourWords.IsShort"> {{'STUDENT.TOUR-WORD.TITLE2' | translate}}</div>
                </div>
                <img (click)="closeWordsTournament()" class="pointer" src="https://walinwa.blob.core.windows.net/images/ico-close-popup.svg?ngsw-bypass=true"
                    fxFlex="2.5rem" />
            </div>

            <div class="contenido welcome">

                <div class="row">

                    <div class="col1">

                        <img src="https://walinwa.blob.core.windows.net/images/Student/torneo/walin_tp.png?ngsw-bypass=true" class="walin_tp">

                    </div>

                    <div class="col2">

                        <div class="text" [innerHtml]="'STUDENT.TOUR-WORD.TEXT' | translate : {text: tourWords.IsShort ? ('STUDENT.TOUR-WORD.MORE' | translate) : ('STUDENT.TOUR-WORD.LESS' | translate)}">
                        </div>

                        <div fxLayout="row" fxLayoutAlign="center center" ngStyle.gt-xs="margin-top: 1em;">
                            <button (click)="initTourWords(2)" class="new_but azul">
                                {{ 'CONTINUE' | translate }}
                            </button>
                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

    <div class="walinwa-modal modal-tour-word modal-tour-word-step-2" *ngIf="showTourWords && tourWords.Step == 2">

        <div class="mdl2">

            <div class="header spe_gap" *ngIf="!tourWords.IsWeekend" fxLayout="row"
                fxLayoutAlign="space-between center">
                <img (click)="tourWords.Step=1" fxFlex="3rem" class="pointer"
                src="https://walinwa.blob.core.windows.net/images/blanco-93.png?ngsw-bypass=true">
                <div fxFlex="" fxLayout="column" fxLayoutAlign="center center">
                    <div> {{'STUDENT.TOUR-WORD.TITLE3' | translate}}</div>
                    <div class="subHeadrTour">
                        {{tourPhaseMsg}}
                        <img style="width: 1.4em;" (click)="changeTourTooltip()"
                            src="https://walinwa.blob.core.windows.net/images/blanco-93.png?ngsw-bypass=true" class="mdl2-header-tooltip-phase"
                            *ngIf="tourPhaseTooltip">
                    </div>
                    <div (click)="changeTourTooltip()" *ngIf="showTourTooltip == true" class="mdl2-header-info-tooltip">
                        <div class="pico"></div>
                        <div class="caja">
                            <div class="text">{{tourPhaseTooltip}}</div>
                        </div>
                    </div>
                </div>
                <img (click)="closeWordsTournament()" class="pointer" src="https://walinwa.blob.core.windows.net/images/ico-close-popup.svg?ngsw-bypass=true"
                    fxFlex="2.5rem" />
            </div>

            <div class="header spe_gap" *ngIf="tourWords.IsWeekend" fxLayout="row" fxLayoutAlign="space-between center">
                <div></div>
                <div fxFlex="" fxLayout="column" fxLayoutAlign="center center">
                    <div> {{'STUDENT.TOUR-WORD.MOST-VOTED-WORDS' | translate}}</div>
                    <div class="subHeadrTour">
                        {{tourPhaseMsg}}
                        <img (click)="changeTourTooltip()" src="https://walinwa.blob.core.windows.net/images/blanco-93.png?ngsw-bypass=true"
                            class="mdl2-header-tooltip-phase" *ngIf="tourPhaseTooltip">
                    </div>
                    <div (click)="changeTourTooltip()" *ngIf="showTourTooltip == true" class="mdl2-header-info-tooltip">

                        <div class="pico"></div>
                        <div class="caja">
                            <div class="text">{{tourPhaseTooltip}}</div>
                        </div>

                    </div>
                </div>
                <img (click)="tourWords.Step=1" class="pointer" src="https://walinwa.blob.core.windows.net/images/ico-close-popup.svg?ngsw-bypass=true" fxFlex="2.5rem">
            </div>

            <div class="contenido">

                <div class="week-ranking" *ngIf="tourWords.IsWeekend">

                    <div class="week-ranking-day">

                        <div class="day">{{'DAYS.MONDAY'| translate}}</div>

                        <div class="week-ranking-word">

                            <img src="https://walinwa.blob.core.windows.net/images/Student/torneo/TP_1_on.png?ngsw-bypass=true" class="week-ranking-word-medal">

                            <div class="tour-word-info-tooltip spe" *ngIf="tourWords.Day1.ShowInfo">
                                <div class="pico"></div>
                                <div class="caja">
                                    <div class="definition" [innerHtml]="checkDefinition(tourWords.Day1.Definition)">
                                    </div>
                                </div>
                            </div>
                            <div (click)="showWordInfo4('Day1')" class="word">{{ tourWords.Day1 ? tourWords.Day1.IdWord
                                : '' }}</div>
                        </div>
                    </div>

                    <div class="week-ranking-day">
                        <div class="day">{{'DAYS.TUESDAY'| translate}}</div>
                        <div class="week-ranking-word">
                            <img src="https://walinwa.blob.core.windows.net/images/Student/torneo/TP_1_on.png?ngsw-bypass=true" class="week-ranking-word-medal">
                            <div class="tour-word-info-tooltip spe" *ngIf="tourWords.Day2.ShowInfo">
                                <div class="pico"></div>
                                <div class="caja">
                                    <div class="definition" [innerHtml]="checkDefinition(tourWords.Day2.Definition)">
                                    </div>
                                </div>
                            </div>
                            <div (click)="showWordInfo4('Day2')" class="word">{{ tourWords.Day2 ? tourWords.Day2.IdWord
                                : '' }}</div>
                        </div>
                    </div>

                    <div style="margin-right: -1%;" class="week-ranking-day">

                        <div class="day">{{'DAYS.WEDNESDAY'| translate}}</div>

                        <div class="week-ranking-word">

                            <img src="https://walinwa.blob.core.windows.net/images/Student/torneo/TP_1_on.png?ngsw-bypass=true)" class="week-ranking-word-medal">

                            <div class="tour-word-info-tooltip spe" *ngIf="tourWords.Day3.ShowInfo">

                                <div class="pico"></div>
                                <div class="caja">

                                    <div class="definition" [innerHtml]="checkDefinition(tourWords.Day3.Definition)">
                                    </div>

                                </div>

                            </div>

                            <div (click)="showWordInfo4('Day3')" class="word">{{ tourWords.Day3 ? tourWords.Day3.IdWord
                                : '' }}</div>

                        </div>

                    </div>

                    <div class="week-ranking-day">

                        <div class="day">{{'DAYS.THURSDAY'| translate}}</div>

                        <div class="week-ranking-word">

                            <img src="https://walinwa.blob.core.windows.net/images/Student/torneo/TP_1_on.png?ngsw-bypass=true" class="week-ranking-word-medal">

                            <div class="tour-word-info-tooltip spe" *ngIf="tourWords.Day4.ShowInfo">

                                <div class="pico"></div>
                                <div class="caja">

                                    <div class="definition" [innerHtml]="checkDefinition(tourWords.Day4.Definition)">
                                    </div>

                                </div>

                            </div>

                            <div (click)="showWordInfo4('Day4')" class="word">{{ tourWords.Day4 ? tourWords.Day4.IdWord
                                : '' }}</div>

                        </div>

                    </div>

                    <div class="week-ranking-day">

                        <div class="day">{{'DAYS.FRIDAY'| translate}}</div>

                        <div class="week-ranking-word">

                            <img src="https://walinwa.blob.core.windows.net/images/Student/torneo/TP_1_on.png?ngsw-bypass=true" class="week-ranking-word-medal">

                            <div class="tour-word-info-tooltip spe" *ngIf="tourWords.Day5.ShowInfo">

                                <div class="pico"></div>
                                <div class="caja">

                                    <div class="definition" [innerHtml]="checkDefinition(tourWords.Day5.Definition)">
                                    </div>

                                </div>

                            </div>

                            <div (click)="showWordInfo4('Day5')" class="word">{{ tourWords.Day5 ? tourWords.Day5.IdWord
                                : '' }}</div>

                        </div>

                    </div>

                </div>

                <div class="tour-ranking" *ngIf="tourWords.completed && !tourWords.IsWeekend">

                    <div class="tour-ranking-position tour-ranking-position-1" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Student/torneo/degradado.png&quot;)'">

                        <img src="https://walinwa.blob.core.windows.net/images/Student/torneo/TP_1_on.png?ngsw-bypass=true" class="tour-ranking-medal">

                        <div style="top: 11em; right: 5em;" class="tour-word-info-tooltip"
                            *ngIf="tourWords.Word1 && tourWords.Word1.ShowInfo">

                            <div class="pico"></div>
                            <div class="caja">

                                <div class="definition"
                                    [innerHtml]="checkDefinition(getWord(tourWords.tourWordUserScore.Gold || tourWords.Gold).Definition)">
                                </div>

                            </div>

                        </div>

                        <div (click)="showWordInfo('Word1')" class="tour-ranking-word">{{
                            getWord(tourWords.tourWordUserScore.Gold || tourWords.Gold).IdWord }}</div>

                    </div>

                    <div class="tour-ranking-position tour-ranking-position-2" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Student/torneo/degradado.png&quot;)'">

                        <img src="https://walinwa.blob.core.windows.net/images/Student/torneo/TP_2_on.png?ngsw-bypass=true"  class="tour-ranking-medal">

                        <div style="top: 8em; right: 11em;" class="tour-word-info-tooltip"
                            *ngIf="tourWords.Word2 && tourWords.Word2.ShowInfo">

                            <div class="pico"></div>
                            <div class="caja">

                                <div class="definition"
                                    [innerHtml]="checkDefinition(getWord(tourWords.tourWordUserScore.Silver || tourWords.Silver).Definition)">
                                </div>

                            </div>

                        </div>

                        <div (click)="showWordInfo('Word2')" class="tour-ranking-word">{{
                            getWord(tourWords.tourWordUserScore.Silver || tourWords.Silver).IdWord }}</div>

                    </div>

                    <div class="tour-ranking-position tour-ranking-position-3" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Student/torneo/degradado.png&quot;)'">

                        <img src="https://walinwa.blob.core.windows.net/images/Student/torneo/TP_3_on.png?ngsw-bypass=true" class="tour-ranking-medal">

                        <div style="top: 5.5em; right: 12em;" class="tour-word-info-tooltip"
                            *ngIf="tourWords.Word3 && tourWords.Word3.ShowInfo">

                            <div class="pico"></div>
                            <div class="caja">

                                <div class="definition"
                                    [innerHtml]="checkDefinition(getWord(tourWords.tourWordUserScore.Bronze || tourWords.Bronze).Definition)">
                                </div>

                            </div>

                        </div>

                        <div (click)="showWordInfo('Word3')" class="tour-ranking-word">
                            {{ getWord(tourWords.tourWordUserScore.Bronze || tourWords.Bronze).IdWord }}
                        </div>

                    </div>

                </div>

                <div class="tour-words" *ngIf="!tourWords.completed && !tourWords.IsWeekend">

                    <div class="tour-word dropzone {{ tourWords.Word1.Medal }}" [attr.word]="Word1" id="Word1">

                        {{ tourWords.Word1.IdWord }}

                        <div class="tour-word-info-tooltip" *ngIf="tourWords.Word1 && tourWords.Word1.ShowInfo">

                            <div class="pico"></div>
                            <div class="caja">

                                <div class="definition" [innerHtml]="checkDefinition(tourWords.Word1.Definition)"></div>

                            </div>

                        </div>

                        <img (click)="showWordInfo('Word1')" src="https://walinwa.blob.core.windows.net/images/blanco-93.png?ngsw-bypass=true"
                            class="tour-word-info">

                    </div>

                    <div class="tour-word dropzone {{ tourWords.Word2.Medal }}" [attr.word]="Word2" id="Word2">

                        {{ tourWords.Word2.IdWord }}

                        <div class="tour-word-info-tooltip" *ngIf="tourWords.Word2 && tourWords.Word2.ShowInfo">

                            <div class="pico"></div>
                            <div class="caja">

                                <div class="definition" [innerHtml]="checkDefinition(tourWords.Word2.Definition)"></div>

                            </div>

                        </div>

                        <img (click)="showWordInfo('Word2')" src="https://walinwa.blob.core.windows.net/images/blanco-93.png?ngsw-bypass=true"
                            class="tour-word-info">

                    </div>

                    <div class="tour-word dropzone {{ tourWords.Word3.Medal }}" [attr.word]="Word3" id="Word3">

                        {{ tourWords.Word3.IdWord }}

                        <div class="tour-word-info-tooltip" *ngIf="tourWords.Word3 && tourWords.Word3.ShowInfo">

                            <div class="pico"></div>
                            <div class="caja">

                                <div class="definition" [innerHtml]="checkDefinition(tourWords.Word3.Definition)"></div>

                            </div>

                        </div>

                        <img (click)="showWordInfo('Word3')" src="https://walinwa.blob.core.windows.net/images/blanco-93.png?ngsw-bypass=true"
                            class="tour-word-info">

                    </div>

                </div>

                <div class="tour-word-medals" *ngIf="!tourWords.completed && !tourWords.IsWeekend">

                    <div [ngClass]="{ draggable: !tourWords.completed }" [attr.medal]="Gold" id="Gold"
                        src="https://walinwa.blob.core.windows.net/images/Student/torneo/TP_1_on.png?ngsw-bypass=true" class="tour-word-medal"
                        [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Student/torneo/TP_1_on.png&quot;)'" [hidden]="tourWords.Gold" 
                        style="background-size: 100% !important; background-repeat: no-repeat; height: 160px !important; background-position: center center;"></div>

                    <img [attr.medal]="Gold" id="Gold" src="https://walinwa.blob.core.windows.net/images/Student/torneo/TP_1_off.png?ngsw-bypass=true"
                        class="tour-word-medal" [hidden]="!tourWords.Gold">

                    <div [ngClass]="{ draggable: !tourWords.completed }" [attr.medal]="Silver" id="Silver" class="tour-word-medal"
                    [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Student/torneo/TP_2_on.png&quot;)'" [hidden]="tourWords.Silver" 
                        style="background-size: 100% !important; background-repeat: no-repeat; height: 160px !important; background-position: center center;"></div>

                    <img [attr.medal]="Silver" id="Silver" src="https://walinwa.blob.core.windows.net/images/Student/torneo/TP_2_off.png?ngsw-bypass=true"
                        class="tour-word-medal" [hidden]="!tourWords.Silver">

                    <div [ngClass]="{ draggable: !tourWords.completed }" [attr.medal]="Bronze" id="Bronze"
                        class="tour-word-medal" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Student/torneo/TP_3_on.png&quot;)'" [hidden]="tourWords.Bronze"
                        style="background-size: 100% !important; background-repeat: no-repeat; height: 160px !important; background-position: center center;"></div>

                    <img [attr.medal]="Bronze" id="Bronze" src="https://walinwa.blob.core.windows.net/images/Student/torneo/TP_3_off.png?ngsw-bypass=true"
                        class="tour-word-medal" [hidden]="!tourWords.Bronze">

                </div>

                <div class="tour-word-medals-info" *ngIf="!tourWords.completed && !tourWords.IsWeekend">
                    {{'STUDENT.TOUR-WORD.DRAG-MEDALS' | translate}}
                </div>

                <div class="tour-word-button">

                    <button (click)="tourWords.Step=3" [disabled]="!tourWords.lastTourWordDayScore" class="new_but azul">
                        {{'STUDENT.TOUR-WORD.SHOW-REWARDS' | translate}}
                    </button>

                    <button (click)="goToTournament()" *ngIf="tourWords.IsWeekend" class="new_but azul">
                        {{'STUDENT.TOUR-WORD.SHOW-RANKING' | translate}}
                    </button>



                    <!-- <a href="" (click)="goToTournament()" target="_blank" *ngIf="tourWords.IsWeekend"
                        class="boton mediano primario">
                        Ver la clasificación
                    </a> -->

                    <div *ngIf="!tourWords.IsWeekend">

                    </div>

                </div>

            </div>

        </div>

    </div>

    <div class="walinwa-modal modal-tour-word modal-tour-word-step-2" *ngIf="showTourWords && tourWords.Step == 3">

        <div class="mdl2" fxLayout="column">



            <div class="header spe_gap" fxLayout="row" fxLayoutAlign="space-between center">

                <img (click)="tourWords.Step=1" fxFlex="3rem" class="pointer"
                src="https://walinwa.blob.core.windows.net/images/blanco-93.png?ngsw-bypass=true" />
                <div fxFlex="" fxLayout="column" fxLayoutAlign="center center">
                    <div>{{'STUDENT.TOUR-WORD.TITLE3' | translate}}</div>
                    <div style="font-size: calc(var(--font-size)*1.2);" class="subHeadrTour">
                        {{tourPhaseMsg}}
                        <img style="width: 1.4em;" (click)="changeTourTooltip()"
                            src="https://walinwa.blob.core.windows.net/images/blanco-93.png?ngsw-bypass=true" class="mdl2-header-tooltip-phase"
                            *ngIf="tourPhaseTooltip">
                    </div>
                    <div (click)="changeTourTooltip()" *ngIf="showTourTooltip == true" class="mdl2-header-info-tooltip">

                        <div class="pico"></div>
                        <div class="caja">
                            <div class="text">{{tourPhaseTooltip}}</div>
                        </div>

                    </div>
                </div>
                <img (click)="closeWordsTournament()" class="pointer" src="https://walinwa.blob.core.windows.net/images/ico-close-popup.svg?ngsw-bypass=true" fxFlex="2.5rem">
            </div>

            <div class="contenido">

                <div class="row">

                    <div class="col6">
                        <div class="titletour">
                            {{'STUDENT.TOUR-WORD.LAST-VOTE-RESULTS' | translate}}
                        </div>
                        <div style="margin-top: 1em;" class="medals medals-with-podium">
                            <div class="medal medal-1" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Student/torneo/degradado.png&quot;)'">
                                <img src="https://walinwa.blob.core.windows.net/images/Student/torneo/TP_1_on.png?ngsw-bypass=true">
                                <div style="top: 4em;" class="tour-word-info-tooltip spe"
                                    *ngIf="tourWords.lastTourWordDayScore?.GoldWord.ShowInfo">

                                    <div class="pico"></div>
                                    <div class="caja">

                                        <div class="definition"
                                            [innerHtml]="checkDefinition(tourWords.lastTourWordDayScore?.GoldWord.Definition)">
                                        </div>

                                    </div>

                                </div>
                                <span (click)="showWordInfo2('GoldWord')">{{
                                    tourWords.lastTourWordDayScore?.GoldWord.IdWord }}</span>
                            </div>
                            <div class="medal medal-2" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Student/torneo/degradado.png&quot;)'">
                                <img src="https://walinwa.blob.core.windows.net/images/Student/torneo/TP_2_on.png?ngsw-bypass=true">
                                <div style="top: 3em;" class="tour-word-info-tooltip spe"
                                    *ngIf="tourWords.lastTourWordDayScore?.SilverWord.ShowInfo">

                                    <div class="pico"></div>
                                    <div class="caja">

                                        <div class="definition"
                                            [innerHtml]="checkDefinition(tourWords.lastTourWordDayScore?.SilverWord.Definition)">
                                        </div>

                                    </div>

                                </div>
                                <span (click)="showWordInfo2('SilverWord')">{{
                                    tourWords.lastTourWordDayScore?.SilverWord.IdWord }}</span>
                            </div>
                            <div class="medal medal-3" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Student/torneo/degradado.png&quot;)'">
                                <img src="https://walinwa.blob.core.windows.net/images/Student/torneo/TP_3_on.png?ngsw-bypass=true">
                                <div style="top: 2em;" class="tour-word-info-tooltip spe"
                                    *ngIf="tourWords.lastTourWordDayScore?.BronzeWord.ShowInfo">

                                    <div class="pico"></div>
                                    <div class="caja">

                                        <div class="definition"
                                            [innerHtml]="checkDefinition(tourWords.lastTourWordDayScore?.BronzeWord.Definition)">
                                        </div>

                                    </div>

                                </div>
                                <span (click)="showWordInfo2('BronzeWord')">{{
                                    tourWords.lastTourWordDayScore?.BronzeWord.IdWord }}</span>
                            </div>
                        </div>
                        <div class="titletour" *ngIf="tourWords.lastTourWordUserScore">
                            {{'STUDENT.TOUR-WORD.YOUR-VOTE-WAS' | translate}}
                        </div>
                        <div style="margin-top: 1em;" class="medals medals-with-podium"
                            *ngIf="tourWords.lastTourWordUserScore">
                            <div class="medal medal-1" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Student/torneo/degradado.png&quot;)'">
                                <img src="https://walinwa.blob.core.windows.net/images/Student/torneo/TP_1_on.png?ngsw-bypass=true">
                                <div style="top: 4em;" class="tour-word-info-tooltip spe"
                                    *ngIf="tourWords.lastTourWordUserScore.GoldWord.ShowInfo">

                                    <div class="pico"></div>
                                    <div class="caja">

                                        <div class="definition"
                                            [innerHtml]="checkDefinition(tourWords.lastTourWordUserScore.GoldWord.Definition)">
                                        </div>

                                    </div>

                                </div>
                                <span (click)="showWordInfo3('GoldWord')">{{
                                    tourWords.lastTourWordUserScore.GoldWord.IdWord }}</span>
                            </div>
                            <div class="medal medal-2" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Student/torneo/degradado.png&quot;)'">
                                <img src="https://walinwa.blob.core.windows.net/images/Student/torneo/TP_2_on.png?ngsw-bypass=true">
                                <div style="top: 4em;" class="tour-word-info-tooltip spe"
                                    *ngIf="tourWords.lastTourWordUserScore.SilverWord.ShowInfo">

                                    <div class="pico"></div>
                                    <div class="caja">

                                        <div class="definition"
                                            [innerHtml]="checkDefinition(tourWords.lastTourWordUserScore.SilverWord.Definition)">
                                        </div>

                                    </div>

                                </div>
                                <span (click)="showWordInfo3('SilverWord')">{{
                                    tourWords.lastTourWordUserScore.SilverWord.IdWord }}</span>
                            </div>
                            <div class="medal medal-3" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Student/torneo/degradado.png&quot;)'">
                                <img src="https://walinwa.blob.core.windows.net/images/Student/torneo/TP_3_on.png?ngsw-bypass=true">
                                <div style="top: 4em;" class="tour-word-info-tooltip spe"
                                    *ngIf="tourWords.lastTourWordUserScore.BronzeWord.ShowInfo">

                                    <div class="pico"></div>
                                    <div class="caja">

                                        <div class="definition"
                                            [innerHtml]="checkDefinition(tourWords.lastTourWordUserScore.BronzeWord.Definition)">
                                        </div>

                                    </div>

                                </div>
                                <span (click)="showWordInfo3('BronzeWord')">{{
                                    tourWords.lastTourWordUserScore.BronzeWord.IdWord }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col4">

                        <div class="reward"
                            *ngIf="tourWords.lastTourWordUserScore && tourWords.lastTourWordUserScore.Walinwos > 0">
                            <div class="text">{{'STUDENT.VISUAL-MEMORY.YOU-WON' | translate}}</div>
                            <div class="walinwos-text">
                                <div class="walinwos">{{tourWords.lastTourWordUserScore.Walinwos}}</div>
                                <div class="text">walinwos</div>
                            </div>
                            <!-- [hidden]="tourWords.lastTourWordUserScore.Reclamado" -->
                            <div [hidden]="tourWords.lastTourWordUserScore.Reclamado" (click)="setTourWordReward()"
                                id="btn-reclamar" class="btn-reclamar">
                                {{'ADMIN.WALI-FACTURAS.CLAIM-BUTTON' | translate }}
                            </div>
                            <div style="height: 2em;" [hidden]="!tourWords.lastTourWordUserScore.Reclamado">

                            </div>
                        </div>
                        <div class="no-reward"
                            *ngIf="!tourWords.lastTourWordUserScore || tourWords.lastTourWordUserScore.Walinwos == 0">
                            <span>{{'STUDENT.TOUR-WORD.KEEP-VOTING' | translate}}</span>
                        </div>

                    </div>

                </div>

                <div class="tour-word-button-2">

                    <button (click)="tourWords.Step=2" class="new_but azul">
                        {{'FOOTER.BACK' | translate}}
                    </button>

                    <button *ngIf="tourWords.IsWeekend" (click)="goToTournament()" class="new_but azul">
                        {{'STUDENT.TOUR-WORD.SHOW-RANKING' | translate}}
                    </button>

                    <div *ngIf="!tourWords.IsWeekend">

                    </div>

                </div>

            </div>

        </div>

    </div>
</div>

<div (click)="hideBirthdayMessage()" class="walinwa-overlay extra" *ngIf="todayBirthday && !checked_announcements">
    <div class="birthdayReward" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/felicitacionCumpleaños.png&quot;)'" >
        <h1>¡¡¡{{'STUDENT.BIRTHDAY-TITLE' | translate}}{{ currentUser.Name }}!!!</h1>
        <h3>{{'STUDENT.BIRTHDAY-MESSAGE' | translate}}<span
                style="color:#823B9B;"><b>1000 walinwos</b></span>{{'STUDENT.BIRTHDAY-MESSAGE2' | translate}}</h3>
        <h2>{{'STUDENT.BIRTHDAY-MESSAGE3' | translate}}</h2>
    </div>
</div>

<div (click)="showNextPresent()" class="walinwa-overlay extra"
    *ngIf="!checked_announcements && currentUser && currentUser.ShopPresents_nuevos.length != 0">
    <div *ngFor="let present of currentUser.ShopPresents_nuevos; let i = Index" class="presentReward" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/felicitacionCumpleaños.png&quot;)'">
        <h1>¡¡{{'STUDENT.AVATAR-SHOP.GIFT-CORRECT-MESSAGE1' | translate}}!!</h1>
        <h3>{{ present.AliasFrom }} {{'STUDENT.NEW-PRESENT-MESSAGE' | translate}} {{ present.ShopItemName }}</h3>
        <h3 style="color: var(--azul); word-break: break-all;" *ngIf="present.Message">{{ present.Message }}</h3>
    </div>
</div>
<div (click)="showNextAvatarPresent()" class="walinwa-overlay extra"
    *ngIf="!checked_announcements && currentUser && currentUser.AvatarShopPresents_nuevos.length != 0">
    <div *ngFor="let present of currentUser.AvatarShopPresents_nuevos; let i = Index" class="presentReward" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/felicitacionCumpleaños.png&quot;)'">
        <h1>¡¡{{'STUDENT.AVATAR-SHOP.GIFT-CORRECT-MESSAGE1' | translate}}!!</h1>
        <h3>{{ present.AliasFrom }} {{'STUDENT.NEW-PRESENT-MESSAGE' | translate}} {{ present.AvatarItem.Description }}</h3>
        <h3 style="color: var(--azul); word-break: break-all;" *ngIf="present.Message">{{ present.Message }}</h3>
    </div>
</div>
<div (click)="hideRetoMessage()" class="walinwa-overlay" *ngIf="showRetoMessage && !checked_announcements">
    <div class="retoReward">
        <h1>{{ retoMsg }}</h1>
        <h1 class="retoMsgAlt" *ngIf="retoMsgAlt">{{ retoMsgAlt }}</h1>
        <h3 *ngIf="retoMsg2">{{ retoMsg2 }}</h3>
        <h3 style="color: var(--azul);" class="retoRewardAlt" *ngIf="retoMsg2Alt">{{ retoMsg2Alt }}</h3>
    </div>
</div>

<div class="walinwa-overlay" *ngIf="!checked_announcements && showReward == true && todayBirthday != true" fxLayout="column" fxLayoutAlign="center center">
    <div class="rewardContainer" fxLayout="row wrap" fxLayoutAlign.gt-md="space-between center" fxLayoutAlign="center center">
        <div fxFlex="25rem" class="rewardRiplomaContanier">
            <img [src]="'https://walinwa.blob.core.windows.net/images/Perseverance/' + rewardImg + '.png?ngsw-bypass=true'" alt="">
        </div>
        <div fxFlex.gt-md="" fxFlex="100%" class="rewardMessageContainer" [innerHtml]="rewardText"></div>
        <img fxFlex="2.5rem" class="closeMessageIcon" src="https://walinwa.blob.core.windows.net/images/ico-close-avatar.svg?ngsw-bypass=true"
            (click)="closeReward()" />
    </div>
</div>

<div (click)="hideRetoEndMessage()" class="walinwa-overlay extra" *ngIf="!checked_announcements && showRetoEndMessage">
    <div class="retoRewardEnd" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Student/retoEnd.png&quot;)'">
        <div class="contentRewardEnd">
            <h1>{{ retoMsg }}</h1>
            <h1 *ngIf="retoMsgAlt">{{ retoMsgAlt }}</h1>
            <h3 *ngIf="retoMsg2">{{ retoMsg2 }}</h3>
            <h3 style="color: var(--azul);" *ngIf="retoMsg2Alt">{{ retoMsg2Alt }}</h3>
        </div>
    </div>
</div>

<div (click)="hideRetoGuestMessage()" class="walinwa-overlay extra"
    *ngIf="showRetoGuestMessage && !checked_announcements">
    <div class="retoRewardGuest" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Student/retoGuest.png&quot;)'">
    </div>
</div>

<div class="walinwa-overlay only_img" (click)="closePopUp()" *ngIf="showPopUp">
    <img [src]="getImg()" *ngIf="announcement" />
</div>

<div class="walinwa-overlay extra" *ngIf="showPopUpBook && !checked_announcements" (click)="closePopUpBook()">
    <div class="rewardBack" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/walinwin_06.png&quot;)'">
        <div class="announcements-book">
            <p class="title"><b>{{'SCHOOL.ATTENTION' | translate}}</b></p>
            <p class="p-announcements-book">{{'STUDENT.NEW-DICTATION-BOOK-TITLE' | translate}}</p>
            <p style="color:blue;" class="p-announcements-book">
                <b><em>{{bookName}}</em></b>
            </p>
            <p class="p-announcements-book">{{'STUDENT.NEW-DICTATION-BOOK-MESSAGE' | translate}}</p>
        </div>
    </div>
</div>

