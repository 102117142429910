<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>
<div class="verbos spe_gap" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" fxLayout="column"
fxLayoutAlign="stretch">   
    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div class="wordsContainer" id="space-for-text" [ngClass]="{'words-container-slide': slide}">
        <div class="items_container">
            <div class="verbContainer">
                <div class="verb">Verbo: {{exercise.verbo}}</div>
                <div class="conjContainer">
                    <div class="conj"><span class="subVerb">Conjugación: </span><span>{{exercise.tiempoverbal}}</span></div>
                    <div class="pers"><span class="subVerb">Persona: </span><span>{{exercise.persona}}</span></div>
                </div>
            </div>
           
            <div id="TextContainer" class="Text_Container">
                <div *ngFor="let palabra of exercise.palabras; let i = index"
                     validation="{{palabra.opcion}}" 
                     [ngClass]="{'divBox' : true, 'wordOk': palabra.answered == true, 'wordError': palabra.answered == false }"
                      id="{{i}}" (click)="validateClick($event)">
                    <div class="wordBox">{{palabra.palabra}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  -->
<!-- <div class="verbos" [ngStyle]="{'pointer-events': review ? 'none;' : ''}">   <h2 *ngIf="controlSession" class="doc-number">{{current + 1}}</h2>
    <div *ngIf="!controlSession" class="exerciseTheme">
        <h1 [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></h1>
    </div>
    <div class="text-center exerciseTitle" [ngClass]="{'exerciseTitle-slide': slide}">
        <p   [innerHtml]="exercise.enunciado"></p>
    </div>
    <div class="wordsContainer"  id="space-for-text" [ngStyle]="controlSession ? {'margin-top': '2em;'} : {}" [ngClass]="{'words-container-slide': slide}">
        <div class="verbContainer">
            <p class="verb" [ngStyle]="controlSession && !slide ? {'font-size': '2.8vh;'} : !controlSession && !slide ? {'font-size': '3vh;'} : {}">Verbo: {{exercise.verbo}}</p>
            <div class="conjContainer">
                <p class="conj"><span class="subVerb">Conjugación: </span><span>{{exercise.tiempoverbal}}</span></p>
                <p class="pers"><span class="subVerb">Persona: </span><span>{{exercise.persona}}</span></p>
            </div>
        </div>
       
        <div id="TextContainer" class="text-center">
            <div *ngFor="let palabra of exercise.palabras; let i = index"
                 validation="{{palabra.opcion}}" 
                 [ngClass]="{'divBox' : true, 'wordOk': palabra.answered == true, 'wordError': palabra.answered == false }"
                  id="{{i}}" (click)="validateClick($event)">
                <p class="wordBox">{{palabra.rae}}</p>
            </div>
        </div>
    </div>
</div> -->
