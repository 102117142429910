import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { AuthService } from "app/core/guards/auth.service";
import { ExerciseSolvedEnum } from "app/core/shared/enums/exercise-solved.enum";
import { ExerciseService } from "app/services/exercise.service";
declare var $;


@Component({
  selector: 'ex-verbos',
  templateUrl: './verbos.component.html',
  styleUrls: ['./verbos.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VerbosComponent implements OnInit, OnDestroy {
    @Input() review;
    @Input() show_errors;
    @Input() controlSession : boolean = false;
    @Input() slide : boolean = false; @Input() current;
    exercise;
    draggingElement;
    letters = [];
    ok = [];
    error = [];
    finished = false;

    elementsOk = [];
    elementsErr = [];
    leftWords;
    rightWords;
    leftValidation;
    rightValidation;

    @HostListener('window:message', ['$event'])
    exerciseListener(event){
        let data = event.data;
                switch (data) {
                    case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_SOLUTION]:
                        setTimeout(this.showAnswersTimeOut.bind(this), 100);
                        break;
                    case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_ANSWER]:
                        setTimeout(this.showAnswer.bind(this), 100)
                        break;
                    case this.exercisesSolvedEnum[this.exerciseSolvedEnum.TIMER_END]:
                        this.finished = true
                        break;
                    case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_CUSTOM]:
                        this.finished = true
                        this.exercise = this.exerciseService.getExerciseDefinition();
                        break;
                    case this.exercisesSolvedEnum[this.exerciseSolvedEnum.EXERCISE_END]:
                        this.finished = true
                        break;
                }
    }

    constructor(private exerciseService: ExerciseService) {
        this.exercise = this.exerciseService.getExerciseDefinition();
    }
    ngOnDestroy() {
    }

    public get exercisesSolvedEnum(): typeof ExerciseSolvedEnum{
        return this.exerciseSolvedEnum
    }
        
    public get exerciseSolvedEnum(): typeof ExerciseSolvedEnum{
        return ExerciseSolvedEnum
    }

    ngOnInit() {
        if (this.review) {
            this.elementsOk = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsOK
            );
            this.elementsErr = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsERR
            );
            if (this.show_errors == false) {
                this.showAnswersTimeOut();
            } else if (this.show_errors == true) {
                this.showAnswer();
            }
        } else {
            
        }
    }
    validateClick (event) { 
        var target = event.target;
        if (target.id =="")
            target = target.parentElement;

        if (this.finished || (this.exercise.palabras[target.id] && this.exercise.palabras[target.id].answered))
            return;            
        
        var validation = this.exercise.palabras[target.id].opcion;
        var result = true;
        for (var i = 0, len = this.exercise.palabras.length; i < len; i++) {
            if (this.exercise.palabras[i].answered == false) {
                this.exercise.palabras[i].answered = null;
            }
        }

        if (validation == true) {
            this.exercise.palabras[target.id].answered = true;    
            this.exerciseService.answer(target.id, true, 1);
            this.finished = true;
            
        } else {                        
            this.exercise.palabras[target.id].answered = false;
            this.exerciseService.answer(target.id, false, 1);

        } 
    };
    showAnswersTimeOut() {
        this.exercise = this.exerciseService.getExerciseDefinition();
        for (var j = 0; j < this.exercise.palabras.length ; j++) {
            if (this.exercise.palabras[j].opcion == true) {
                this.exercise.palabras[j].answered = true;
            }
            if (this.exercise.palabras[j].opcion == false) {
                this.exercise.palabras[j].answered = null;                   
            }
        }            
    }; 

    showAnswer() {
        for (var i = 0; i < this.exercise.palabras.length ; i++) {
            var answered = null;
            for (var p = 0; p < this.elementsOk.length; p++) {
                if (this.exercise.palabras[i].palabra == this.exercise.palabras[this.elementsOk[p]].palabra) {
                    answered = true;
                }
            }
            for (var p = 0; p < this.elementsErr.length; p++) {
                if (this.exercise.palabras[i].palabra == this.exercise.palabras[this.elementsErr[p]].palabra) {
                    answered = false;
                }
            }
            this.exercise.palabras[i].answered = answered;
        }
    }
}
