<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="coloca1 spe_gap" fxLayout="column" fxLayoutAlign="stretch" [ngStyle]="{'pointer-events': review ? 'none;' : ''}">

    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div fxFlex="auto" class="ruleContainer spe_gap" fxLayout="column nowrap" fxLayoutAlign="space-between center">
        <div class="exerciseRule" fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-center">
            <span [innerHtml]="exercise.regla"></span>
        </div>
        <div fxFlex="auto" fxLayout="row" fxLayoutAlign="space-around center" class="wordsContainer" id="space-for-text">
            <div fxFlex="auto" *ngFor="let text of answer; let i = index" class="answerBox" [ngClass]="{'boxOk':result == true, 'boxError':result==false}">
                <span id="answer{{ i }}" type="up" [attr.index]="i" class="answerBoxChild draggable dropzone">{{text}}</span>
            </div>
        </div>
    </div>

    <div  class="dropzone" id="space-for-buttons" *ngIf="show_errors == null || !review">
        <div  [style]="getWordPosition(i)" *ngFor="let text of options; let i = index" type="down" [attr.index]="i" class="optionBox draggable" dragChildImg="option{{ i }}">
            <span id="option{{ i }}" class="optionBoxChild ">{{text}}</span>
        </div>
    </div>

</div>