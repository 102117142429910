import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LicenseService } from 'app/services/license.service';
import { NavigationService } from 'app/services/navigation.service';
import { UserService } from 'app/services/user.service';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LicenseComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  currentUser;
  calling = false;
  dataLoaded = false;
  licenses;
  mytimeout = null;
  user = null;

  constructor(
    private navigationService: NavigationService,
    private licenseService: LicenseService,
    private userService: UserService,
    private matDialog: MatDialog,
    private _translateService: TranslateService
  ) { 
    this._unsubscribeAll = new Subject();
}

ngOnDestroy(): void
   {
       // Unsubscribe from all subscriptions
       this._unsubscribeAll.next();
       this._unsubscribeAll.complete();
   }

  ngOnInit() {
    if (!sessionStorage.getItem("currentUser")) return this.navigationService.exit();
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (this.navigationService.params.student) this.user = this.navigationService.params.student;
    if (!this.currentUser || !this.user) return this.navigationService.exit();
    this.navigationService.enableBackButton(true, this.back.bind(this));
    this.calling = true;
    this.loadLicenses();
    this.dataLoaded = true;
    this.calling = false;
  }

  back() {
    this.navigationService.go("/school/parentStudents")
  }

  errorCallbackLoadStudent(error) {
    this.navigationService.goLogin();
  };
  successCallbackUpdateLicense(response) {
    this.navigationService.go("/school/myAccount")
  };

  loadLicenses() {
    this.licenseService.getAvailableLicenses(this.user.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successCallbackGetLicenses.bind(this), this.errorCallbackkGetLicenses.bind(this));
  }

  successCallbackGetLicenses(response) {

    if (response == 'free') {
      var message = [];

      message[0] = this._translateService.instant('SCHOOL.PARENT.STUDENTS.LICENSE.MODAL-LICENSE-TITLE');
      message[1] = this._translateService.instant('SCHOOL.PARENT.STUDENTS.LICENSE.MODAL-LICENSE-MESSAGE', {userName: this.user.Name});
      message[2] = this._translateService.instant('SCHOOL.PARENT.STUDENTS.LICENSE.MODAL-LICENSE-MESSAGE2');

      let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
        disableClose: true
      });
      confirmDialog.componentInstance.title = message[0];
      confirmDialog.componentInstance.message1 = message[1];
      confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
      confirmDialog.componentInstance.margin = false;
      confirmDialog.componentInstance.margin = false;
      confirmDialog.componentInstance.theme = "white";
      confirmDialog.componentInstance.options = [
        {
          text: this._translateService.instant('ACCEPT'),
          callback: () => {
            this.userService.SetFreeLicenseToUser(this.user.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successCallbackUpdateLicense.bind(this), this.errorCallbackUpdateLicense.bind(this));
          }
        }, {
          text: "Cancelar",
          callback: () => {
            this.back();
          }
        }];
      confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
        result();
        confirmDialog = null;
      });

    } else {

      this.licenses = response;
      this.licenses = this.licenses.sort(function (a, b) {
        return a.Duration - b.Duration;
      });
      for (var i = 0; i < this.licenses.length; i++) {
        this.licenses[i].active = true;
      }
    }
  };

  errorCallbackUpdateLicense(error) {
    this.navigationService.go("/school/myAccount")
  };

  errorCallbackkGetLicenses(error) {
    if (error.error != null && error.error == "noLicenseAvailable") {
      var message = [];
      message[0] = this._translateService.instant('SCHOOL.PARENT.STUDENTS.LICENSE.MODAL-ERROR-LICENSE-TITLE');
      message[1] = this._translateService.instant('SCHOOL.PARENT.STUDENTS.LICENSE.MODAL-ERROR-LICENSE-MESSAGE');
      let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
        disableClose: true
      });
      confirmDialog.componentInstance.title = message[0];
      confirmDialog.componentInstance.message1 = message[1];
      confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
      confirmDialog.componentInstance.margin = false;
      confirmDialog.componentInstance.margin = false;
      confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
        this.navigationService.go("/school/myAccount")
        confirmDialog = null;
      });
      return;
    }
    this.navigationService.goLogin();
  };

  submitForm(index) {
    if (!this.licenses[index].Active)
      return;
    if(this.calling)
      return;
    this.calling = true;
    this.userService.SetLicenseToUser(this.user.Id, this.licenses[index].IdLicenseType)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successCallbackUpdateLicense.bind(this), this.errorCallbackUpdateLicense.bind(this));
  };

  cancel() {
    this.back();
  }

}
