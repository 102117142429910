import { RetoList } from './../../../core/shared/state/models/reto-list.model';
import { RetoService } from './../../../services/reto.service';
import { ClassService } from 'app/services/class.service';
import { SharedService } from 'app/core/shared/shared.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation, Renderer2, ElementRef, AfterViewInit } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { NavigationService } from 'app/services/navigation.service';
import { SessionService } from 'app/services/session.service';
import { MatDialog } from '@angular/material/dialog';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import * as moment from 'moment';
import { CurrentSessionStatusEnum } from 'app/core/shared/enums/current-session-status.enum';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { Reto } from 'app/core/shared/state';
import { ClassByLevelDTO } from 'app/core/shared/state/models/Class/class-by-level.model';
import { AvatarShopService } from 'app/services/avatar-shop.service';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
declare var dCarousel;

@Component({
    selector: 'app-challenge',
    templateUrl: './challenge.component.html',
    styleUrls: ['./challenge.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ChallengeComponent implements OnInit, OnDestroy, AfterViewInit {
    private _unsubscribeAll: Subject<any>;
    currentUser;
    calling = true;
    viewReto = true;
    viewListRetos = false;
    dataLoaded = false;
    Classes: any = [];
    viewCurso = []
    alumnos = [];
    noWrapSlides = false;
    temas = [
        { Theme: "va", Description: this._translateService.instant('STUDENT.CHALLENGE.THEMES.VARIATED') },
        { Theme: "fp", Description: this._translateService.instant('STUDENT.CHALLENGE.THEMES.MAIN-PHONEMES') },
        { Theme: "tf", Description: this._translateService.instant('STUDENT.CHALLENGE.THEMES.ALL-PHONEMES') },
        { Theme: "tl", Description: this._translateService.instant('STUDENT.CHALLENGE.THEMES.ACCENT') },
        { Theme: "gr", Description: this._translateService.instant('STUDENT.CHALLENGE.THEMES.GRAMMAR') },
        { Theme: "vr", Description: this._translateService.instant('STUDENT.CHALLENGE.THEMES.VERBS') },
        { Theme: "vo", Description: this._translateService.instant('STUDENT.CHALLENGE.THEMES.VOCABULARY') }
    ]
    slides = [
        { id: 1, desc: this._translateService.instant('STUDENT.CHALLENGE.SLIDES.1') },
        { id: 2, desc: this._translateService.instant('STUDENT.CHALLENGE.SLIDES.2') },
        { id: 3, desc: this._translateService.instant('STUDENT.CHALLENGE.SLIDES.3') },
        { id: 4, desc: this._translateService.instant('STUDENT.CHALLENGE.SLIDES.4') }
    ]
    active = -1
    tema = this.temas[0];
    retos: RetoList[];
    todayRetoCompleted = false;
    currentReto: Reto;
    borrar = false;
    loadingReto = false;
    aceptar: boolean
    comenzar: boolean
    rechazar: boolean
    creator
    carousel
    tries = 0;
    timeBlock;
    blockTime;
    activeItems: Object;
    avatarActive: boolean;


    constructor(
        private authService: AuthService,
        private navigationService: NavigationService,
        private sessionService: SessionService,
        private classService: ClassService,
        private _matDialog: MatDialog,
        public sharedService: SharedService,
        private retoService: RetoService,
        private renderer: Renderer2, 
        private el: ElementRef,
        private avatarShopService: AvatarShopService,
        private _translateService: TranslateService
    ) {
        this._unsubscribeAll = new Subject();
    }

    public get currentSessionStatusEnum(): typeof CurrentSessionStatusEnum {
        return this.currentSessionStatuEnum
    }

    public get currentSessionStatuEnum(): typeof CurrentSessionStatusEnum {
        return CurrentSessionStatusEnum
    }

    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit() {
        if (!this.authService.check()) return;
        this.currentUser = this.navigationService.params.user ? this.navigationService.params.user : this.authService.currentUser;
        if(this.avatarShopService.availableItems != null && this.avatarShopService.availableItems.length > 0){
            
        }
        this.getAvatarItems();
        if (this.retoService.listClasses.length > 0) {
            this.successloadClasses(this.retoService.listClasses)
        }
        else {
            this.loadClasses();
        }
        this.avatarShopService.currentStatus
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(resp => {
                this.avatarActive = resp;
                if (this.avatarActive) {
                    this.GetActiveItems();
                }
            })
        this.avatarShopService.ItemBuyed
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.GetActiveItems();
            });
        

    }

    ngAfterViewInit(): void {
        // 
        
  
    }
    getAvatarItems() {
        this.avatarShopService.getAvailableItems()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                this.avatarShopService.availableItems = result;
            })
    }
    getRetos() {
        this.resetStudents();
        this.retoService.getRetos(this.authService.currentUser.Id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                result => {
                    this.successGetReto(result);
                },
                error => {
                    this.errorCallback();
                }
            )
    }

    showCurso(index) {
        this.viewCurso = [];
        for (var i = 0; i < this.Classes.length; i++) {
            this.viewCurso[i] = false;
        }
        this.viewCurso[index] = true;
    }

    showMiReto() {
        this.viewReto = true;
        this.viewListRetos = false;
    }

    showListRetos() {
        this.viewReto = false;
        this.viewListRetos = true;
    }

    getAlumnosSeleccionados() {
        var aux = [];
        this.alumnos.forEach((alumno) => {
            if (alumno.selected) {
                aux.push(alumno.Id);
            }
        })
        return aux;
    }

    jugar(reto) {
        if (this.tries > 0) return;
        if (this.calling) return;
        var invitacion = null;
        if (reto != null && reto.Invitaciones != null) {
            for (var i = 0; i < reto.Invitaciones.length; i++) {
                if (reto.Invitaciones[i].IdGuest == this.currentUser.Id)
                    invitacion = reto.Invitaciones[i];
            }
            if (this.currentUser.Coins < 50 && invitacion.Status === 'Esperando') {
                this.showDialog();
                return;
            }
            this.tries++;
            this.calling = true;
            this.successLoadInvitation(invitacion)
        }
    }

    formatDateTime(date): string {
        return moment(date).format("DD/MM/YYYY HH:mm");
    }

    showDialog(): void {
        let message = [];
        message[0] = "";
        message[1] = this._translateService.instant('STUDENT.CHALLENGE.NOT-ENOUGH-WALIS-MESSAGE');
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.margin = false;
        confirmDialog.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                confirmDialog = null;
            });
    }
    crear(): void {
        if (this.calling) return;
        if (this.currentUser.Coins < 100) {
            this.showDialog();
            return;
        }
        var alumnos = this.getAlumnosSeleccionados();
        if (alumnos.length <= 0) {
            return;
        }
        this.loadingReto = true;
        var tema = this.tema.Theme;
        this.calling = true;
        this.retoService.crearReto(alumnos, tema, this.authService.currentUser.Id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                result => {
                    this.getRetos();
                    this.sharedService.changeUserCoins(true)
                },
                error => {
                    this.ErrorCrearReto(error);
                }
            )
    }

    ErrorCrearReto(error) {
        let message = [];
        let aux = []
        message[0] = this._translateService.instant('STUDENT.CHALLENGE.CHALLENGE-CREATION-FAILED');
        message[1] = this._translateService.instant('STUDENT.CHALLENGE.OTHER-USERS-ALREADY-ON-CHALLENGE-ERROR');
        error.error.forEach((studentId : number) => {
            let student = this.alumnos.find(x => x.Id == studentId)
            if (student) {
                aux.push((student.Name + ' ' + student.FamilyName).trim())
            }
        });
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });
        message[2] = aux.join(", ")
        confirmDialog.componentInstance.title = message[0];
        message.splice(0, 1)
        confirmDialog.componentInstance.messages = message
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.margin = false;
        confirmDialog.componentInstance.theme = "white";
        confirmDialog.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                this.getRetos();
                this.calling = false;
                this.sharedService.changeUserCoins(true)
                confirmDialog = null;
            });
        return;
    }

    rechazarReto(idReto : number): void {
        if (this.calling) return;
        this.calling = true;
        this.loadingReto = true;
        this.retoService.rechazarReto(this.authService.currentUser.Id, idReto)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                result => {
                    this.successRechazarReto(result);
                },
                error => {
                    this.errorCallbackRechazarReto(error);
                }
            )
    }

    successRechazarReto(respuesta) {
        this.calling = true;
        this.loadingReto = true;
        this.getRetos();
        this.sharedService.changeUserCoins(true)
        // reload coins
    }
    getTodayReto(idReto: number) {
        this.retoService.getReto(idReto)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                result => {

                    this.successGetTodayReto(result);
                },
                error => {
                    this.errorCallback();
                }
            )
    }
    successGetTodayReto(result: Reto) {
        this.currentReto = result;
        var rechazado = false;
        this.todayRetoCompleted = true;
        var someoneStarted = false;
        console.log(this.currentReto);
        this.currentReto.Invitaciones.forEach((invitacion) => {
            if ((invitacion.Status === this.currentSessionStatusEnum[this.currentSessionStatusEnum.Rechazado] || invitacion.Status === this.currentSessionStatusEnum[this.currentSessionStatusEnum.Cancelado]) && invitacion.IdGuest === this.currentUser.Id) {
                rechazado = true;
                return;
            }
            if (invitacion.IdGuest == this.currentUser.Id) {
                if(this.activeItems){
                    this.currentUser.activeItems = this.activeItems
                }
                invitacion.Guest = this.currentUser;
            }
            else {
                invitacion.Guest = this.alumnos.find(x => x.Id == invitacion.IdGuest)
            }
            
            if (invitacion.Status !== this.currentSessionStatusEnum[this.currentSessionStatusEnum.Rechazado] && invitacion.Status !== this.currentSessionStatusEnum[this.currentSessionStatusEnum.Cancelado]
                && invitacion.Status !== this.currentSessionStatusEnum[this.currentSessionStatusEnum.Finalizado] && invitacion.Status !== this.currentSessionStatusEnum[this.currentSessionStatusEnum.Anulado]) {
                this.todayRetoCompleted = false;
            }
            if (this.currentReto.IdUser === invitacion.IdGuest) {
                this.creator = invitacion.Guest;
            }
            if (invitacion.Status === this.currentSessionStatusEnum[this.currentSessionStatusEnum.Esperando] && invitacion.IdGuest === this.currentUser.Id) {
                this.aceptar = true;
                if (this.currentReto.IdUser !== this.currentUser.Id) {
                    this.rechazar = true;
                }

            } else if (invitacion.Status === this.currentSessionStatusEnum[this.currentSessionStatusEnum.Aceptado] && invitacion.IdGuest === this.currentUser.Id) {
                this.comenzar = true;
            }
            if (invitacion.Status === this.currentSessionStatusEnum[this.currentSessionStatusEnum.Aceptado] || invitacion.Status === this.currentSessionStatusEnum[this.currentSessionStatusEnum.Finalizado]) {
                someoneStarted = true;
            }
        })
        if (rechazado || this.todayRetoCompleted) {
            this.currentReto = null;
            return;
        }
        if (this.currentReto.IdUser === this.currentUser.Id && someoneStarted === false) this.borrar = true;
    }

    successGetReto(result: RetoList[]) {
        this.calling = false;
        this.loadingReto = false;
        this.retos = result;
        this.aceptar = false;
        this.comenzar = false;
        this.rechazar = false;
        this.borrar = false;
        this.currentReto = null;
        let todayReto = this.retos.find(x => moment(x.fecha).isSame(moment(), 'day') && x.Status == "Esperando" || x.Status == "Aceptado")
        if (todayReto) {
            this.getTodayReto(todayReto.IdReto);
        }
        
        this.dataLoaded = true;
        this.currentUser.IdReto = null;
        if (!this.authService.currentUser.AdminLogin) {
            this.setRetoAsRead();
        }
    }

    setRetoAsRead() {
        this.retoService.setRetoAsRead(this.authService.currentUser.Id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe()
    }

    getTema(temaId) {
        for (var i = 0; i < this.temas.length; i++) {
            if (this.temas[i].Theme === temaId) {
                return this.temas[i];
            }
        }
    }

    successLoadInvitation(result :any) {
        this.calling = false;
        this.sessionService.currentSession = result;
        this.sessionService.reto = true;
        this.sessionService.currentSession.Status = "Aceptado";
        this.retoService.updateInvitacion(this.sessionService.currentSession, this.authService.currentUser.OnDemand || this.authService.currentUser.IsAdmin)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                result => {
                    this.successCallbackStartReto(result);
                },
                error => {
                    this.errorCallback();
                }
            )
    }

    successCallbackStartReto(result) {
        result.Invitacion.session = this.sessionService.currentSession;
        this.navigationService.go("student/session/play", { session: result.Invitacion });
    }

    loadClasses() {
        this.classService.getSchoolClassesByLevel(this.currentUser.IdSchool, this.currentUser.IdClass)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (resultClasses: any) => {
                    this.successloadClasses(resultClasses);
                },
                error => {
                    this.errorCallback();
                }
            )

    }

    successloadClasses(clasesList: ClassByLevelDTO[][]) {
        clasesList.forEach(claseRaw => {
            let clase = {
                IdClass: claseRaw[0].IdClass,
                Name: claseRaw[0].ClassName,
                RetoIni: claseRaw[0].RetoIni,
                RetoEnd: claseRaw[0].RetoEnd,
                Students: claseRaw
            }
            this.Classes.push(clase)
        })
        this.retoService.listClasses = clasesList;
        let userClass = this.Classes.find(x => x.IdClass == this.currentUser.IdClass);
        this.getChallengeBlock(userClass);
        this.alumnos = [];
        this.viewCurso = [];
        this.Classes.sort((a, b) => {
            if (a.Name.toUpperCase() > b.Name.toUpperCase()) return 1;
            return -1;
        });
        this.Classes.forEach(clase => {
            this.viewCurso.push(false);
            let userIndex = clase.Students.findIndex(x => x.Id == this.currentUser.Id)
            if (userIndex >= 0) {
                clase.Students.splice(userIndex, 1);
            }
            this.alumnos.push(...clase.Students);
        });
        this.loadStudentsActiveItems();
        this.viewCurso[0] = true;
        this.getRetos();
        setTimeout(() => {
            dCarousel()
        }, 100)
    }

    loadStudentsActiveItems() {
        let subItem = null
        this.alumnos.forEach(alumno => {
            alumno.activeItems = null;
            subItem = null;
            if (alumno.Items) {
                const itemArray = alumno.Items.split(" // ").filter(Boolean);
                const parseItem = (item: string) => {
                    const [Id, Color] = item.split(" - ").map(val => parseInt(val.trim()));
                    let Type = Math.floor(Id / 100); // Tomar el primer dígito
                    if(Id >= 300 && Id < 400){
                        let item = this.avatarShopService.availableItems.find(x => x.Id == Id)
                        if(item?.SubId){
                            subItem = {
                                Id: item.SubId,
                                selectedAttribute: { Color },
                                Type: 9
                            }
                        }
                    }
                    if (Id >= 900) {
                        Type = 10
                        if (Id >= 910) {
                            Type = 11;
                        }
                    }
                    return {
                        Id,
                        selectedAttribute: { Color },
                        Type
                    };
                };
                alumno.activeItems = itemArray.map(parseItem);
                if(subItem){
                    alumno.activeItems.push(subItem)
                }
            }
        });
    }

    resetStudents() {
        this.alumnos
            .sort((a, b) => {
                if ((a.FamilyName + ',' + a.Name).toUpperCase() > (b.FamilyName + ',' + b.Name).toUpperCase()) {
                    return 1;
                }
                return -1;
            }).forEach((alumno) => {
                alumno.selected = false;
            })
    }

    errorCallback() {
        this.calling = true;
        this.navigationService.goLogin();
    }
    errorCallbackRechazarReto(error) {
        this.calling = true;
        this.loadingReto = true;
        var message = [];
        message[0] = this._translateService.instant('STUDENT.CHALLENGE.DELETE-CHALLENGE');
        message[1] = error.message;
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.margin = false;
        confirmDialog.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                this.getRetos();
                this.calling = false;
                confirmDialog = null;
            });
        return;
    }

    getChallengeBlock(userClass: any) {
        this.timeBlock = false
        this.classService.canExecuteDeductionText(this.authService.currentUser.IdClass)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.timeBlock = !result.canStart
                this.blockTime = userClass;
            })
    }

    getSliderClass(index) {
        if (index === this.active) return "active";
    }

    GetActiveItems(): void {
        this.avatarShopService.GetActiveItemsByIdUser(this.authService.currentUser.Id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {
                this.activeItems = result;
            })
    }
}
