<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="flechas spe_gap" fxLayout="column" fxLayoutAlign="stretch"
    [ngStyle]="{'pointer-events': review ? 'none;' : ''}">

    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div class="wordsContainer">
        <div class="items_container spe_gap" fxLayout="column" fxLayoutAlign="space-between stretch">
            <div fxFlex="" fxLayout="column" fxLayoutAlign="start center" class="palabras">
                <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="palabra"
                    [ngClass]="{'bien': palabra.answered == true, 'mal': palabra.answered == false}"
                    *ngFor="let palabra of exercise.palabras; let i = index">
                    <i [ngClass]="{'mal': palabra.answered == false}"
                        (click)="validar(palabra, exercise.boxes[0].valor)" class="fa fa-chevron-left"></i>
                    <span>{{ palabra.palabra }}</span>
                    <i [ngClass]="{'mal': palabra.answered == false}"
                        (click)="validar(palabra, exercise.boxes[1].valor)" class="fa fa-chevron-right"></i>
                </div>
            </div>
            <div fxLayout="row nowrap" fxLayoutAlign="space-around center" class="cajas">
                <div fxFlex="" fxLayout="column nowrap" fxLayoutAlign="end center" class="caja"
                    *ngFor="let caja of exercise.boxes; let i = index">
                    <span class="palabra" [ngClass]="{'element-hidden': palabra.answered != true}"
                        *ngFor="let palabra of exercise.palabras; let j = index"
                        [hidden]="palabra.opcion!=caja.valor">{{ palabra.rae }}</span>
                    <span class="descripcion">{{ caja.descripcion }}</span>
                </div>
            </div>
        </div>
    </div>

</div>