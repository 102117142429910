import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpParams, HttpEvent, HttpHeaderResponse, HttpResponse, HttpErrorResponse, HttpUserEvent, HttpProgressEvent } from '@angular/common/http';
import { Location } from '@angular/common';
import { environment } from 'environments/environment';

@Injectable()
export class VersionService {

    baseUrl = environment.baseApi + '/api/Version';

    constructor(private http: HttpClient, private _location: Location) {
    }

    getVersion() {
        return this.http.get(this.baseUrl);
    }

    ping() {
        return this.http.get(this.baseUrl + "/ping");
    }

}
