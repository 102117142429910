import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CustomSession } from 'app/core/shared/state';
import { PersonalizedTestResult } from 'app/core/shared/state/models/personalized-test/personalized-test-result.model';
import { PersonalizedTest } from 'app/core/shared/state/models/personalized-test/personalized-test.model';
import { AuthService } from 'app/services/auth.service';
import { CourseService } from 'app/services/course.service';
import { CustomizedSessionService } from 'app/services/customized-session.service';
import { EmailConfigService } from 'app/services/email-config.service';
import { NavigationService } from 'app/services/navigation.service';
import { PersonalizedTestService } from 'app/services/personalized-test.service';
import { SchoolService } from 'app/services/school.service';
import { UserService } from 'app/services/user.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: "app-tracking",
    templateUrl: "./tracking.component.html",
    styleUrls: ["./tracking.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class TrackingComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    currentUser;
    state;
    loading: boolean;
    selectedDay: any;
    constructor(
        private router: Router,
        private authService: AuthService,
        private navigationService: NavigationService,
        public schoolService: SchoolService
    ) { 
        this._unsubscribeAll = new Subject();
    }
    
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit(): void {
        if (!this.authService.check()) return;
        this.currentUser = this.authService.currentUser;
        this.goReport();
        this.selectedDay = moment().hour(0).minute(0).second(0).millisecond(0);
    }
    goReport() {
        this.state = "report";
        if (this.router.url == "/school/tracking") {
            this.router.navigateByUrl(this.router.url + "/report");
        } else {
            this.router.navigateByUrl("/school/tracking/report");
        }
    }

    GoTestResult() {
        this.state = "test";
        this.navigationService.setStateTest("classes-personalizedTests");
        if (this.router.url == "/school/tracking") {
            this.navigationService.go(this.router.url + "/test");
        } else {
            this.navigationService.go("/school/tracking/test");
        }
    }
}
