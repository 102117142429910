import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ExerciseSolvedEnum } from "app/core/shared/enums/exercise-solved.enum";
import { ExerciseService } from "app/services/exercise.service";
import { Observable } from "rxjs";

@Component({
    selector: "ex-unir",
    templateUrl: "./unir.component.html",
    styleUrls: ["./unir.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class UnirComponent implements OnInit, OnDestroy {
    @Input() review;
    @Input() show_errors;
    @Input() controlSession : boolean = false;
    @Input() slide : boolean = false; @Input() current;
    currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    //Used to hide the content
    dataLoaded = false;
    leftSelected = null;
    rightSelected;
    leftError;
    rightError;
    exercise;
    elementsOk;
    elementsErr;
    showResult = false;
    finished = false;
    showingSolution = false;

    @HostListener('window:message', ['$event'])
    exerciseListener(event){
        let data = event.data;
        switch (data) {
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_SOLUTION]:
                this.finished = true;
                if (this.exerciseService.showingSolution) {
                    setTimeout(this.ShowAnswersTimeOut.bind(this), 100);
                    break;
                } else {
                    setTimeout(this.ShowSolution.bind(this), 100);
                    break;
                }
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_ANSWER]:
                if(this.elementsErr.length >= 1) {
                    this.ShowAnswer();
                }
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_CUSTOM]:
                this.ShowAnswer();
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.TIMER_END]:
                this.finished = true;
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.EXERCISE_END]:
                this.finished = true;
                break;
        }
    }

    constructor(private exerciseService: ExerciseService) {
        this.exercise = this.exerciseService.getExerciseDefinition();
    }
    public get exercisesSolvedEnum(): typeof ExerciseSolvedEnum{
        return this.exerciseSolvedEnum
    }
    
    public get exerciseSolvedEnum(): typeof ExerciseSolvedEnum{
        return ExerciseSolvedEnum
    }

    ngOnInit(): void {
        //this.eventsSubscription = this.events.subscribe((event) =>
        //    this.handleEvents(event)
        //);
        if (this.review) {
            this.elementsOk = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsOK
            );
            this.elementsErr = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsERR
            );
            if (this.show_errors == false) {
                this.ShowSolution();
            } else if (this.show_errors == true) {
                if(this.elementsErr.length >= 1) {
                    this.ShowAnswer();
                }
            }
            else if (this.show_errors == null) {
                this.ShowAnswer();
            }
        }
        else {
            this.initInteract();
        }
    }
    /*
    handleEvents(event) {
        console.log(event.event);
        switch (event.event) {
            case "SHOW_ANSWER_TIMEOUT":
                this.ShowAnswersTimeOut();
                break;
            case "SHOW_SOLUTION":
                this.finished = true;
                this.ShowSolution();
                break;
            case "SHOW_ANSWER":
                this.ShowAnswer();
                break;
        }
    }
    */
    initInteract() {
        this.exercise.result = [];
        this.exerciseService.initInteract({
            onmove: (event) => {
                if (event.target.getAttribute("wordIndexLeft")) {
                    this.leftSelected =
                        event.target.getAttribute("wordIndexLeft");
                } else {
                    this.rightSelected =
                        event.target.getAttribute("wordIndexRight");
                }
            },
            drop: (event) => {
                if (this.finished) return;
                if (this.leftSelected) {
                    if (event.target.getAttribute("wordIndexRight")) {
                        this.rightSelected =
                            event.target.getAttribute("wordIndexRight");
                    }
                }
                if (this.rightSelected) {
                    if (event.target.getAttribute("wordIndexLeft")) {
                        this.leftSelected =
                            event.target.getAttribute("wordIndexLeft");
                    }
                }
                if (!this.leftSelected) return
                if (!this.rightSelected) return
                this.ValidateResult();
            },
        });
    }
    ValidateResult() {
        if (
            this.exercise.valor[this.leftSelected] != null &&
            this.exercise.grupo1[this.leftSelected] ==
                this.exercise.valor[this.leftSelected].v1 &&
            this.exercise.grupo2[this.rightSelected] ==
                this.exercise.valor[this.leftSelected].v2
        ) {
            this.exercise.grupo1[this.leftSelected] = null;
            this.exercise.grupo2[this.rightSelected] = null;
            if (this.exercise.operador == "un")
                result =
                    this.exercise.valor[this.leftSelected].v1.replace("_", "") +
                    this.exercise.valor[this.leftSelected].v2.replace("_", "");
            else
                result =
                    this.exercise.valor[this.leftSelected].v1 +
                    " / " +
                    this.exercise.valor[this.leftSelected].v2;
            let elemIndex = this.exercise.result.findIndex(x => x.word == result)
            if(elemIndex > -1) {
                this.exercise.result.splice(elemIndex, 1, { word: result, answered: true });
            }
            else {
                this.exercise.result.splice(this.exercise.result.length, 0, { word: result, answered: true });
            }
            this.exerciseService.answer(
                parseFloat(this.leftSelected),
                true,
                this.exercise.grupo1.length
            );
            //exerciseService.AddOk("1:" + this.exercise.valor[this.leftSelected].v1 + ";" + this.leftSelected + ";2:" + this.exercise.valor[this.leftSelected].v2 + ";" + this.rightSelected);
            // this.exerciseService.AddOk(parseFloat(this.leftSelected));

            this.leftError = null;
            this.leftSelected = null;
            this.rightError = null;
            this.rightSelected = null;
        } else if (
            this.exercise.valor[this.rightSelected] != null &&
            this.exercise.grupo1[this.leftSelected] ==
                this.exercise.valor[this.rightSelected].v1 &&
            this.exercise.grupo2[this.rightSelected] ==
                this.exercise.valor[this.rightSelected].v2
        ) {
            this.exercise.grupo1[this.leftSelected] = null;
            this.exercise.grupo2[this.rightSelected] = null;
            var result;
            if (this.exercise.operador == "un")
                result =
                    this.exercise.valor[this.rightSelected].v1.replace(
                        "_",
                        ""
                    ) +
                    this.exercise.valor[this.rightSelected].v2.replace("_", "");
            else
                result =
                    this.exercise.valor[this.rightSelected].v1 +
                    " / " +
                    this.exercise.valor[this.rightSelected].v2;
                    let elemIndex = this.exercise.result.findIndex(x => x.word == result)
                    if(elemIndex > -1) {
                        this.exercise.result.splice(elemIndex, 1, { word: result, answered: true });
                    }
                    else {
                        this.exercise.result.splice(this.exercise.result.length, 0, { word: result, answered: true });
                    }
                this.exerciseService.answer(
                    parseFloat(this.rightSelected),
                    true,
                    this.exercise.grupo1.length
                );
                this.leftError = null;
                this.leftSelected = null;
                this.rightError = null;
                this.rightSelected = null;
        } else {
            // this.$emit("error");
            this.exerciseService.answer(
                parseFloat(this.leftSelected),
                false,
                this.exercise.grupo1.length
            );
            this.leftError = this.leftSelected;
            this.leftSelected = null;
            this.rightError = this.rightSelected;
            this.rightSelected = null;
            setTimeout(() => {
                this.leftError = null;
                this.rightError = null;
            }, 500);
        }

        if (
            this.exercise.grupo1.length == this.exercise.result.length ||
            this.exercise.grupo2.length == this.exercise.result.length
        ) {
            this.finished = true;
            this.showResult = true;
            this.showingSolution = true;
            // this.$emit("timer-stop");
            // this.$emit('exercise-solved');
            return;
        }
    }
    ShowAnswersTimeOut() {
        this.showingSolution = true;
        this.leftError = null;
        this.leftSelected = null;
        this.rightError = null;
        this.rightSelected = null;
        this.showResult = false;
        for (var i = 0; i < this.exercise.grupo1.length; i++) {
            if (this.exercise.grupo1[i] != null) {
                var result;
                if (this.exercise.operador == "un")
                    result =
                        this.exercise.valor[i].v1.replace("_", "") +
                        this.exercise.valor[i].v2.replace("_", "");
                else
                    result =
                        this.exercise.valor[i].v1 +
                        " / " +
                        this.exercise.valor[i].v2;
                this.exercise.result.splice(this.exercise.result.length, 0, {
                    word: result,
                    answered: false,
                });
            }
            this.exercise.grupo1[i] = null;
            this.exercise.grupo2[i] = null;
        }
    }
    showHourglassAnswer() {
        this.finished = false;
        this.exercise = this.exerciseService.getExerciseDefinition();
        this.exercise.result = []
    }

    ShowAnswer() {
        this.showingSolution = true;
        this.exercise.result = [];
        this.showResult = true;
        if (!this.exercise.grupo1) this.exercise.grupo1 = this.exercise.valor;
        for (var i = 0; i < this.exercise.valor.length; i++) {
            var answered = null;
            for (var p = 0; p < this.elementsErr.length; p++) {
                if (
                    this.elementsErr[p] < this.exercise.valor.length &&
                    this.exercise.valor[i].v1 ==
                        this.exercise.valor[this.elementsErr[p]].v1
                )
                    answered = false;
            }
            for (var p = 0; p < this.elementsOk.length; p++) {
                if (
                    this.elementsOk[p] < this.exercise.valor.length &&
                    this.exercise.valor[i].v1 ==
                        this.exercise.valor[this.elementsOk[p]].v1 &&
                    answered == null
                )
                    answered = true;
            }
            var result;
            if (this.exercise.operador == "un")
                result =
                    this.exercise.valor[i].v1.replace("_", "") +
                    this.exercise.valor[i].v2.replace("_", "");
            else
                result =
                    this.exercise.valor[i].v1 +
                    " / " +
                    this.exercise.valor[i].v2;
            this.exercise.result.splice(this.exercise.result.length, 0, {
                word: result,
                answered: answered,
            });
            this.exercise.grupo1[i] = null;
            this.exercise.grupo2[i] = null;
        }
    }
    ShowSolution() {
        this.leftError = null;
        this.leftSelected = null;
        this.rightError = null;
        this.rightSelected = null;
        this.showResult = true;
        this.showingSolution = true;
        this.exercise.result = [];
        if (!this.exercise.grupo1) this.exercise.grupo1 = this.exercise.valor;
        for (var i = 0; i < this.exercise.grupo1.length; i++) {
            var result;
            if (this.exercise.operador == "un")
                result =
                    this.exercise.valor[i].v1.replace("_", "") +
                    this.exercise.valor[i].v2.replace("_", "");
            else
                result =
                    this.exercise.valor[i].v1 +
                    " / " +
                    this.exercise.valor[i].v2;
            this.exercise.result.splice(this.exercise.result.length, 0, {
                word: result,
                answered: true,
            });
            this.exercise.grupo1[i] = null;
            this.exercise.grupo2[i] = null;
        }
    }

    ngOnDestroy() {
        //this.eventsSubscription.unsubscribe();   window["removeListener"](this.event_index)
    }
     
}
