import { PersonalizedTestService } from 'app/services/personalized-test.service';
import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectionStrategy, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Observable, Subject } from 'rxjs'
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ChatGPTRequestModel } from 'app/core/shared/state/models/chatGPTRequestModel';
import { CourseService } from 'app/services/course.service';
import { TestThemes } from 'app/core/shared/state/models/personalized-test/personalized-test.model';
import { Course } from 'app/core/shared/state';
import { SchoolService } from 'app/services/school.service';

@Component({
    selector: 'app-personalized-test-chatgpt-popup',
    templateUrl: './personalized-test-chatgpt-popup.component.html',
    styleUrls: ['./personalized-test-chatgpt-popup.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonalizedTestChatGPTPopupComponent implements OnInit, OnDestroy {
    @Output() readonly onCancel: EventEmitter<void> = new EventEmitter<void>();
    @Output() readonly onAccept: EventEmitter<ChatGPTRequestModel> = new EventEmitter<ChatGPTRequestModel>();

    private _unsubscribeAll: Subject<any>;
    private _accepted: boolean = false;

    public formChatGPTTest : UntypedFormGroup;
    public themes: Observable<TestThemes[]>;
    public courses: Observable<Course[]>;

    constructor(
        private _personalizedTestService: PersonalizedTestService,
        private _courseService: CourseService,
        private _formBuilder: UntypedFormBuilder,
        private _detector: ChangeDetectorRef,
        public schoolService: SchoolService,
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    ngOnInit() {
        this._accepted = false;
        this._createSubscriptions();
        this._createForm();
    }

    private _createSubscriptions() {
        this.themes = this._personalizedTestService.getThemes();
        this.courses = this._courseService.Courses;
    }

    private _createForm(): void {
        this.formChatGPTTest = this._formBuilder.group({
            idTestTheme: new UntypedFormControl(null, [Validators.required]),
            course: new UntypedFormControl(null, [Validators.required]),
            difficulty: new UntypedFormControl(null/*, [Validators.required]*/),
            number: new UntypedFormControl(null, [Validators.required, Validators.max(100), Validators.min(1)]),
            answers: new UntypedFormControl(null, [Validators.required, Validators.max(6), Validators.min(2), this._greaterOrSmallerThanValidator('>', 'correct')]),
            correct: new UntypedFormControl(null, [Validators.required, Validators.max(6), Validators.min(1), this._greaterOrSmallerThanValidator('<', 'answers')]),
            prompt: new UntypedFormControl("Dame #Number# #Pregunta/s# tipo test, de dificultad #Difficulty#, con #Answers# respuestas posibles, con un máximo de respuestas correctas de #Correct# y un mínimo 1, sobre '#TestTheme#', para #Course#. Incluye preguntas de teoría y definición. No quiero preguntas cuyas respuestas se repitan. No quiero faltas ortográficas.", [Validators.required]),
            temperature: new UntypedFormControl(null, [Validators.max(2), Validators.min(0)])
        });
    }

    private _greaterOrSmallerThanValidator(operator: '<' | '>', property: string): ValidatorFn {
        return (control: AbstractControl) => {
            if (!control.value || !control.parent?.get(property)?.value) return null;

            const greatherValidation = operator === '>';
            const isValid: boolean = greatherValidation ? control.value > (control.parent?.get(property)?.value ?? 0) : control.value < (control.parent?.get(property)?.value ?? 0);
            
            if (isValid) {
                if (control.parent?.get(property)?.invalid) setTimeout(() => control.parent?.get(property).updateValueAndValidity());
                
                return null;
            }

            return greatherValidation ? { 'greatherThan': true } : { 'smallerThan': true }
        };
    }

    public getChatGPTTest(): void {        
        this.formChatGPTTest.markAllAsTouched();
        this._detector.detectChanges();

        if (this.formChatGPTTest.valid && !this._accepted) {
            this._accepted = true;
            this.onAccept.emit(this.formChatGPTTest.value);
        }
    }
}