import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpParams, HttpEvent, HttpHeaderResponse, HttpResponse, HttpErrorResponse, HttpUserEvent, HttpProgressEvent } from '@angular/common/http';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { FileRequest, FileResponse } from 'app/core/shared/state/models/file/file.model';
import { FileService } from './file.service';

@Injectable({
  providedIn: 'root'
})
export class MyZoneService {
    imgUrls: FileResponse[] = [];
    baseUrl = environment.baseApi + '/api/MyZone';
    subDirectory = "MiZona/"

    constructor(private http: HttpClient,
        private fileService: FileService) { }



  GetUserItems(IdUser : number){
    return this.http.get(this.baseUrl + "/" + IdUser)
  }
  GetUserInfo(IdUser : number){
    return this.http.get(this.baseUrl + "/userInfo/" + IdUser)
  }
  update(IdUser : number, IdMyZone : number){
    return this.http.post(this.baseUrl + "/idUser/" + IdUser + "/IdMyZone/" + IdMyZone, {});
}
}
