<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="mlluvia spe_gap" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" fxLayout="column" fxLayoutAlign="stretch">
    <!-- Caja de mensaje inicial -->
    <section fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap">
        <div fxFlex="" fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
            <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
            </div>
            <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
                <span [innerHtml]="exercise.enunciado"></span>
            </div>
        </div>
        <div [hidden]="exercise.NumPalabras == null">
            <div class="numWords">{{ exercise.NumPalabras - itemsSelected}}</div>
        </div>
    </section>

    <div class="wordsContainer">
        <div class="items_container" id="space-for-text" [ngClass]="{'element-hidden': showAnswer}">
            <div *ngFor="let palabra of exercise.palabras; let i = index" class="wordBox" [hidden]="palabra.topN == 0"
                [ngClass]="{'wordOk': palabra.answered == true, 'wordError': palabra.error == true  }"
                [ngStyle]="getStyle(palabra)" id="{{i}}" (click)="validateClick(i)">{{palabra.palabra}}</div>
        </div>
        <div class="items_container two" id="space-for-text" [ngClass]="{'element-hidden': showAnswer == false}">
            <div *ngFor="let palabra of exercise.palabras; let i = index" validation="{{palabra.opcion}}"
                [ngClass]="{'divBox' : true,'divBox-control' : controlSession && !slide, 'wordOk': palabra.answered == true, 'wordError': palabra.answered == false, 'wordNotAnswered': palabra.notAnserwed == true  }"
                id="{{i}}" (click)="validateClick(i)">
                <span class="wordBox2">{{palabra.palabra}}</span>
            </div>
        </div>
    </div>
</div>


<!--  -->
<!-- <div *ngIf="!controlSession" class="exerciseTheme" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" [ngClass]="{'exerciseTheme2': timerEnabled == false}">
    <h1 [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></h1>
</div>
<h2 *ngIf="controlSession" class="doc-number2">{{current + 1}}</h2>
<div class="text-center exerciseTitle" [ngClass]="{'exerciseTitle-slide': slide}" [ngStyle]="controlSession ? {'margin-top': '.5em;'} : {}">
    <p   [innerHtml]="exercise.enunciado"></p>
    <div [ngStyle]="controlSession ? {padding: '1%;','margin-bottom': '2%;'} : {}" class="numWords" [hidden]="exercise.NumPalabras == null">{{ exercise.NumPalabras - itemsSelected}}</div>
</div>
<div class="wordsContainer"  id="space-for-text" [ngClass]="{'element-hidden': showAnswer, 'wordContainerFull' : exercise.Go == 1 || exercise.Go == 2}">
    <div *ngFor="let palabra of exercise.palabras; let i = index"
         class="wordBox"
         [hidden]="palabra.topN == 0"
         [ngClass]="{'wordOk': palabra.answered == true, 'wordError': palabra.error == true  }"
         [ngStyle]="getStyle(palabra)"
         id="{{i}}" (click)="validateClick(i)">{{palabra.palabra}}</div>
</div>
<div class="wordsContainer2" [ngStyle]="controlSession ? {'margin-bottom': '0;'} : {'margin-bottom': '36%;'}" id="space-for-text" [ngClass]="{'element-hidden': showAnswer == false,'words-container2-slide': slide}">
    <div id="TextContainer" class="text-center"  [ngStyle]="controlSession ? {'margin-top': '4em;'} : {}">
        <div *ngFor="let palabra of exercise.palabras; let i = index"
             validation="{{palabra.opcion}}"
             [ngClass]="{'divBox' : true,'divBox-control' : controlSession && !slide, 'wordOk': palabra.answered == true, 'wordError': palabra.answered == false, 'wordNotAnswered': palabra.notAnserwed == true  }"
             id="{{i}}" (click)="validateClick(i)">
            <p class="wordBox2">{{palabra.palabra}}</p>
        </div>
    </div>
</div> -->