<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="parejas spe_gap" fxLayout="column" fxLayoutAlign="stretch"
    [ngStyle]="{'pointer-events': review ? 'none;' : ''}">

    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div class="wordsContainer" id="space-for-text">
        <div class="items_container">
            <div *ngFor="let palabra of duplicatedWords; let i = index" class="wordBox" [ngClass]="{
                wordOk: palabra.answered == true,
                wordError: palabra.answered == false,
                'large': duplicatedWords.length > 4
            }" [id]="i+ numero_random" (click)="validateClick(i)">
                <div *ngIf="review" class="front card" [ngClass]="{
                wordBoxFront:
                    palabra.answered == true ||
                    palabra.selected == true ||
                    palabra.selected == false ||
                    started == false,
                'largeword': palabra.palabra.length > 10
            }">
                    {{ palabra.palabra }}
                </div>
                <span *ngIf="!review" class="front card" [ngClass]="{
                    wordBoxFront:
                        palabra.answered == true ||
                        palabra.selected == true ||
                        started == false,
                    'largeword': palabra.palabra.length > 10
                }">
                    {{ palabra.palabra }} 
                </span>
                <div *ngIf="!review" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/logo_walinwa.png&quot;)'" class="back card" [ngClass]="{
                    wordBoxBack:
                        palabra.answered != true &&
                        palabra.selected != true &&
                        started == true 
                }"></div>
            </div>
        </div>
    </div>
</div>