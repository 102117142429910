import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatProgressSpinner, MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay'

import { SpinnerComponent } from './spinner.component';

@NgModule({
    declarations: [
        SpinnerComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        OverlayModule,
        MatProgressSpinnerModule
    ],
    exports: [
        SpinnerComponent
    ]
})
export class SpinnerModule {
}
