import { CalendarReadingText } from 'app/core/shared/state/models/Reading-text/reading-text-month.model';
import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/es'

@Component({
  selector: 'cal-reading-text',
  templateUrl: './cal-reading-text.component.html',
  styleUrls: ['./cal-reading-text.component.scss']
})
export class CalReadingTextComponent implements OnInit {
  @Input() fullDay;
  @Input() currentUser;
  @Input() selectedDay;
  @Input() currentMonth;
  @Input() parent;
  @Input() readingTexts : CalendarReadingText[]
  @Input() showAll
  @Input() licenseDate;

  calendarUser;
  month;
  weeks;
  has_refreshed = true

  constructor(
  ) {
    moment.locale('es')
  }

  ngOnInit() {
    this.calendarUser = this.currentUser;
    if (this.selectedDay == null || this.selectedDay.clone === "undefined") return;
    let currentDay = this.selectedDay.clone();
    this.month = currentDay.clone();
    var start = currentDay.clone();
    start.date(1);
    this._buildMonth(start, this.month);
    if (this.month.month() != moment().month()) this.selectedDay = null;
    this.selectToday();
  }

  isPending(day){
    let currentReadingText = this.readingTexts.find((r) => { return moment(r.date).isSame(day.date) });
    if (currentReadingText?.TextDate == null ) {
      return false;
    }else{
      if (currentReadingText && currentReadingText.TextDate != null && currentReadingText.FinishDate == null) {
        return true;
      } else {
        return false;
      }
    }
    
  }

  selectToday() {
    for(let week of this.weeks) {
      for(let day of week.days) {
        if(!this.currentSession(day.date)) continue;
        this.select(day);
        return;
      }
    }
  }

  selectedDayChangedCL(date) {
    let currentDay = this.selectedDay.clone();
    this.month = currentDay.clone();
    var start = currentDay.clone();
    start.date(1);
    this._buildMonth(start, this.month);
    this.parent.selectedDayChangedCL(date);
  }

  refresh(callback = (weeks_count) => {}) {
    callback(this.weeks.length)
    setTimeout(() => {
      this.select({date:this.selectedDay})
    }, 100)
  }

  select(day) {
    this.selectedDay = day.date;
    this.selectedDayChangedCL(this.selectedDay);
  }
  next() {
    this.selectedDay.month(this.selectedDay.month() + 1);
    this.month = this.selectedDay.clone();
    this.selectedDayChangedCL(this.selectedDay);
  }

  previous() {
    this.selectedDay.month(this.selectedDay.month() - 1);
    this.month = this.selectedDay.clone();
    this.selectedDayChangedCL(this.selectedDay);
  }

  isPast(date) {
    let now = moment()
    if(this.showAll) {
        if(this.licenseDate != null && moment(date).isAfter(moment(this.licenseDate))) return false;
      // last month, current month and next month
      if (date.month() == now.month()) return true
      if (date.month() == now.month() - 1) return true
      if (date.month() == now.month() + 1) return true
      return false
    }
    return date.isSameOrBefore(now)
  }
  isDone(date) {
    if (!this.readingTexts) return false;
    var currentReadingText = this.readingTexts.find(function (r) {
      return r.Week == date.week() && r.Day == date.isoWeekday()
    });
    if (!currentReadingText) return false;
    if (currentReadingText.Score == null) return false;
    return currentReadingText.FinishDate != null;
  }

  isFailed(date) {
    if (!this.readingTexts) return false;
    var currentReadingText = this.readingTexts.find(function (r) {
      return r.Week == date.week() && r.Day == date.isoWeekday()
    });
    if (!currentReadingText) return false;
    if (!currentReadingText.Score) return false;
    if (!currentReadingText.FinishDate) return false;
    return currentReadingText.Score < 5;
  };
  notDone(date) {
    if (!this.readingTexts) return false;
    if(this.licenseDate != null && moment(date).isAfter(moment(this.licenseDate))) return false;
    var currentReadingText = this.readingTexts.find(function (r) {
      return r.Week == date.week() && r.Day == date.isoWeekday()
    });
    if (!currentReadingText) return false;
    if (this.currentSession(date)) return false;
    return !this.isDone(date) && !this.isFailed(date);
  }

  currentSession(date) {
    if (!this.readingTexts) return false;
    if(this.licenseDate != null && moment(date).isAfter(moment(this.licenseDate))) return false;
    var currentReadingText = this.readingTexts.find(function (r) {
      return r.Week == date.week() && r.Day == date.isoWeekday()
    });
    if (!currentReadingText) return false;
    var today = moment();
    return moment(date).calendar().indexOf("hoy") != -1;
  }

  isLowScore(date) {
    return false
    if (!this.readingTexts) return false
    var currentReadingText = this.readingTexts.find(function (r) {
      return r.Week == date.week() && r.Day == date.isoWeekday()
    })
    if (!currentReadingText) return false
  }

  isAvailableMonth(date) {
    if (!this.calendarUser) return true;
    if (moment(this.calendarUser.CreationDate).diff(date, 'days') > 0 && !this.calendarUser.UserName.includes(".tmp.")) return true;
    if ((date.month() > moment().month() - 2 && date.year() === moment().year()) ||
      (date.year() > moment().year()) ||
      (moment().month() == 0 && date.month() == 11 && (date.year() + 1) === moment().year()))
      return true;
    return false;
  }

  isAvailable(date) {
    var dictado = date.week() + '_' + date.isoWeekday();
    return true;
  }

  _removeTime(date) {
    var a = 0;
    return date.hour(a).minute(a).second(a).millisecond(a);
  }

  _buildMonth(start, month) {
    if (start == null) return;
    this.weeks = [];
    var done = false, date = start.clone(), monthIndex = date.month(), count = 0;
    while (!done) {
      this.weeks.push({ days: this._buildWeek(date.clone(), month) });
      console.log(this.weeks)
      date = date.clone();
      date.add(1, "w");
      done = count++ > 2 && (monthIndex !== date.startOf('week').month());
      monthIndex = date.month();
    }
  }

  _buildWeek(date, month) {
    let days = [];
    let dayItereator = date.startOf('week');
    for (var i = 0; i < 7; i++) {
      days.push({
        name: dayItereator.format("dd").substring(0, 1),
        number: dayItereator.date(),
        isCurrentMonth: dayItereator.month() === month.month(),
        isToday: dayItereator.isSame(new Date(), "day"),
        date: dayItereator,
        notDone: !this.showAll  && this.notDone(dayItereator) && this.isPast(dayItereator),
        disabledMonth: !this.isAvailableMonth(dayItereator),    
        isDone: (!this.isLowScore(dayItereator) && !this.showAll && (this.isDone(dayItereator) || this.isFailed(dayItereator))) || (this.showAll && this.notDone(dayItereator) && this.isPast(dayItereator)),      
        currentSession: this.currentSession(dayItereator),
        lowScore: this.isLowScore(dayItereator) && (this.isDone(dayItereator) || this.isFailed(dayItereator))
      });
      dayItereator = dayItereator.clone();
      dayItereator.add(1, "d");
    }
    return days;
  }

}
