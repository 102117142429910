<div class="walinwa-box-modal-overlay" *ngIf="readingText">

        <div *ngIf="!review" class="comprension_block" [ngClass]="{ 'active': readingText }" fxLayout="column"
        fxLayoutAlign="stretch" fxLayoutGap="0">
        <div class="topper" [ngStyle]="{ 'background-image': 'url(&quot;https://walinwa.blob.core.windows.net/images/Comprensiones/CL/fondo-comprension-header.png?ngsw-bypass=true&quot;)' }" fxFlex="2.875rem" fxFlex.xs="1.90rem"></div>
        <div class="body" [ngStyle]="{ 'background-image': 'url(&quot;https://walinwa.blob.core.windows.net/images/Comprensiones/CL/fondo-comprension-body.png?ngsw-bypass=true&quot;)' }" [ngStyle.xs]="{ 'background-image': 'none' }" fxFlex="" fxLayout="column" fxLayoutAlign="stretch" fxLayoutGap="0" fxLayoutGap.lt-lg="20px">
            <div class="header_modal" fxLayout="row" fxLayoutAlign="space-between center">
                <div fxHide.lt-lg fxShow></div>
                <div *ngIf="questionIndex==-1" class="title_blue" fxHide fxShow.lt-lg>
                    {{'STUDENT.READING-TEXT.PLAY.READING-COMPREHENSION' | translate }}<br>{{ getDate() }}
                </div>
                <div *ngIf="questionIndex>-1 && readingText" class="" fxHide fxShow.lt-lg>
                    {{'STUDENT.TEXT-EXERCISES.QUESTIONS-PROGRESS' | translate:{questionNum:questionIndex + 1,questionCount:readingText.Questions.length} }}
                </div>
                <div *ngIf="readingText" class="walinwos_badge" fxLayout="row" fxLayoutAlign="center center"
                    fxLayoutGap="7px" fxHide fxShow.lt-lg>
                    <img src="https://walinwa.blob.core.windows.net/images/walinwo.gif?ngsw-bypass=true" fxFlex="2.5rem">
                    <span>
                        {{ walinwos }} {{'STUDENT.TEXT-EXERCISES.OF-CONNECTOR' | translate }} {{ readingText.Questions.length * 10 }}
                    </span>
                </div>
                <div fxFlex="2rem">
                    <img (click)="finish()" src="https://walinwa.blob.core.windows.net/images/ico-close-avatar.svg?ngsw-bypass=true" class="pointer" fxFlex="2em">
                </div>
            </div>
            <div fxFlex="" class="content_scroll" fxLayout="column" fxLayoutAlign="stretch" fxLayoutGap="20px" fxLayoutGap.lt-lg="0">
                <div fxFlex="5rem" fxLayout="row" fxLayoutAlign="space-between center" fxHide.lt-lg fxShow>
                    <div fxFlex="7rem">
                        <img src="https://walinwa.blob.core.windows.net/images/Comprensiones/logo_walinwa.svg?ngsw-bypass=true">
                    </div>
                    <div>
                        <div *ngIf="questionIndex==-1" class="font_blue">
                            {{'STUDENT.READING-TEXT.PLAY.READING-COMPREHENSION' | translate }} {{ getDate() }}
                        </div>
                        <div *ngIf="questionIndex>-1 && readingText" class="font_blue">
                            {{'STUDENT.TEXT-EXERCISES.QUESTIONS-PROGRESS' | translate:{questionNum:questionIndex + 1,questionCount:readingText.Questions.length} }}
                        </div>
                    </div>
                    <div>
                        <div *ngIf="readingText" class="walinwos_badge" fxLayout="row" fxLayoutAlign="center center"
                            fxLayoutGap="7px">
                            <img src="https://walinwa.blob.core.windows.net/images/walinwo.gif?ngsw-bypass=true" fxFlex="2.5rem">
                            <span>
                                {{ walinwos }} {{'STUDENT.TEXT-EXERCISES.OF-CONNECTOR' | translate }} {{ readingText.Questions.length * 10 }}
                            </span>
                        </div>
                    </div>
                </div>
                <form [formGroup]="form" *ngIf="readingText" class="" fxFlex="" fxLayout="column"
                    fxLayoutAlign="space-between stretch" fxLayoutGap="1.25rem">

                    <div *ngIf="questionIndex==-1">
                        <div class="sangrado_text" [innerHtml]="readingText.Fragment | replaceTags"></div>

                        <div class="author_book">
                            <span>{{ getBookName(readingText.Book) }}</span>
                            <span *ngIf="readingText.Author"> {{ readingText.Author }}</span>
                        </div>
                    </div>

                    <div *ngIf="questionIndex>-1" class="reading-text-content" fxFlex="" fxLayout="column"
                        fxLayoutAlign="stretch" fxLayoutGap="1.25rem">
                        <div class="reading-text-question"
                            [innerHtml]="readingText.Questions[questionIndex].Question | replaceTags"></div>

                        <fieldset [disabled]="correct != null" class="reading-text-answers" fxLayout="column"
                            fxLayoutAlign="stretch" fxLayoutGap="1.25rem">
                            <label [ngClass]="{ isCorrect: correct != null && answer.IsCorrect }"
                                class="reading-text-answer"
                                *ngFor="let answer of readingText.Questions[questionIndex].Answers; let i = index;">
                                <input type="radio" [value]="answer.IdAnswer" formControlName="IdAnswer">
                                <span style="width: 100%;" [innerHtml]="answer.Answer | replaceTags"></span>
                            </label>
                        </fieldset>
                    </div>

                    <div class="buttons fixid" fxLayout="row" fxLayout.xs="row wrap"
                        fxLayoutAlign="end end">
                        <button [disabled]="loading" (click)="next()" *ngIf="questionIndex==-1" class="new_but azul">
                            {{'STUDENT.TEXT-EXERCISES.START-EXERCISE-BUTTON' | translate }}
                        </button>
                        <button [disabled]="loading || correct != null" (click)="next()"
                            *ngIf="questionIndex>-1 && !score" class="new_but azul">
                            {{'STUDENT.TEXT-EXERCISES.ANSWER-BUTTON' | translate }}
                        </button>
                    </div>

                </form>
            </div>
        </div>
        <div class="footer" [ngStyle]="{ 'background-image': 'url(&quot;https://walinwa.blob.core.windows.net/images/Comprensiones/CL/fondo-comprension-footer.png?ngsw-bypass=true&quot;)' }"
        [ngStyle.xs]="{ 'background-image': 'url(&quot;https://walinwa.blob.core.windows.net/images/Comprensiones/CL/fondo-comprension-footer-b.png?ngsw-bypass=true&quot;)' }"
        fxFlex="2.5625rem" fxFlex.xs="1.90rem"></div>
    </div>

    <div *ngIf="review && !loading" class="comprension_block review_task" [ngClass]="{ 'active': readingText }" fxLayout="column"
        fxLayoutAlign="stretch" fxLayoutGap="0">
        <div class="topper" [ngStyle]="{ 'background-image': 'url(&quot;https://walinwa.blob.core.windows.net/images/Comprensiones/CL/fondo-comprension-header.png?ngsw-bypass=true&quot;)' }" fxFlex="2.875rem" fxFlex.xs="1.90rem"></div>
        <div class="body" [ngStyle.xs]="{ 'background-image': 'none' }" [ngStyle]="{ 'background-image': 'url(&quot;https://walinwa.blob.core.windows.net/images/Comprensiones/CL/fondo-comprension-body.png?ngsw-bypass=true&quot;)' }" fxFlex="" fxLayout="column" fxLayoutAlign="stretch" fxLayoutGap="20px">
            <div class="header_modal" fxLayout="row" fxLayout.xs="row wrap" fxLayoutAlign="space-between center" fxLayoutGap.xs="7px">
                <div class="title_blue" fxFlexOrder="1">
                    <span>{{'STUDENT.READING-TEXT.PLAY.READING-COMPREHENSION' | translate }}<br>{{ getDate() }}</span>
                </div>
                <div fxFlexOrder="2" fxFlexOrder.xs="4" fxFlex.xs="100%">
                    <span *ngIf="!reviewingActivity && readingText.Score != null" style="color: var(--verde) "><span> {{'STUDENT.TEXT-EXERCISES.MARK-TELL-TEXT' | translate }} {{
                            readingText.Score }}</span><span style="margin-left: 6px;"
                            *ngIf="readingText.Retries > 0">({{'STUDENT.READING-TEXT.PLAY.RETRIES' | translate }}
                            {{readingText.Retries}})</span></span>
                </div>
                <div fxFlex="2rem" fxFlexOrder="3">
                    <img (click)="finish()" src="https://walinwa.blob.core.windows.net/images/ico-close-avatar.svg?ngsw-bypass=true" fxFlex="2em" class="pointer">
                </div>
            </div>

            <div fxFlex="" class="content_scroll" fxLayout="column" fxLayoutAlign="stretch" fxLayoutGap="1.25rem">

                <div class="box_question" fxFlex="">
                    <div class="comment sangrado_text" [innerHtml]="readingText.Fragment | replaceTags"></div>
                    <div class="author_book">
                        <span>{{ getBookName(readingText.Book) }} </span>
                        <span *ngIf="readingText.Author"> {{ readingText.Author }}</span>
                    </div>
                </div>

                <div>
                    <label class="font_blue">{{'STUDENT.READING-TEXT.PLAY.ANSWERS-LABEL' | translate }}</label>
                </div>

                <div class="box_question" fxFlex="" fxLayout="column" fxLayoutAlign="stretch" fxLayoutGap="1.25rem">
                    <div class="reading_text_review_question"
                        *ngFor="let question of readingText.Questions; let i = index">
                        
                        <div class="verdeAzulado"
                            [innerHtml]="question.Question | replaceTags: i + 1">
                        </div>

                        <div [ngClass]="{ ok: answer.IsCorrect, ko: !answer.IsCorrect && wasSelected(answer) }"
                            class="reading_text_review_question_answer"
                            *ngFor="let answer of question.Answers; let i = index;" fxLayout="row" fxLayoutAlign="start center">

                            <div class="reading_text_review_question_answer_check" fxFlex="2rem">
                                <i *ngIf="answer.IsCorrect" class="fas fa-check"></i>
                            </div>

                            <div class="reading_text_review_question_answer_text">
                                <span [innerHtml]="answer.Answer | replaceTags"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer" [ngStyle]="{ 'background-image': 'url(&quot;https://walinwa.blob.core.windows.net/images/Comprensiones/CL/fondo-comprension-footer.png?ngsw-bypass=true&quot;)' }"
        [ngStyle.xs]="{ 'background-image': 'url(&quot;https://walinwa.blob.core.windows.net/images/Comprensiones/CL/fondo-comprension-footer-b.png?ngsw-bypass=true&quot;)' }"
        fxFlex="2.5625rem" fxFlex.xs="1.90rem"></div>
    </div>

    <img (click)="goNext(false)" *ngIf="correct === false && !loading" class="correct" src="https://walinwa.blob.core.windows.net/images/Comprensiones/bad.png?ngsw-bypass=true">
    <img (click)="goNext(false)" *ngIf="correct === true && !loading" class="correct" src="https://walinwa.blob.core.windows.net/images/Comprensiones/good.png?ngsw-bypass=true">

</div>

<plank-selector *ngIf="finished" title="Ejercicio terminado" [isFinAnticipado]="false" [show]="messages.length>0"
    [mark]="score.Score" [messages]="messages" [walinwos]="score.Walinwos" [newScenary]="nuevoFondo"
    [errors]="score.NumExercises - score.NumOk" (onClose)="finish()"></plank-selector>