import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CalDeductionTextComponent } from 'app/components/cal-deduction-text/cal-deduction-text.component';
import { CalendarDeduction } from 'app/core/shared/state/models/deduction/deduction-month.model';
import { DeductionTextService } from 'app/services/deduction-text.service';
import { NavigationService } from 'app/services/navigation.service';
import { UserService } from 'app/services/user.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
declare var $;
@Component({
    selector: 'app-parent-review-deduction-text',
    templateUrl: './parent-review-deduction-text.component.html',
    styleUrls: ['./parent-review-deduction-text.component.scss']
})
export class ParentReviewDeductionTextComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
    @ViewChild(CalDeductionTextComponent) child: CalDeductionTextComponent;
    six_weeks_deduction
    selectedDayDT: any;
    month: any;
    year: any;
    currentMonthDT: any;
    student: any;
    deductionTexts: CalendarDeduction[];
    activeButton = false;
    IdUser: any;

    constructor(
        private userService: UserService,
        private navigationService: NavigationService,
        private deductionService: DeductionTextService,
        private router: Router,
        private ref: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit() {
        this.selectedDayDT = moment();
        this.year = this.selectedDayDT.year();
        this.currentMonthDT = this.selectedDayDT.month() + 1;
        if (this.navigationService.params.IdUser)
            this.IdUser = this.navigationService.params.IdUser;
        if (this.navigationService.params.student) {
            this.student = this.navigationService.params.student
            this.getDeductions();
        }
        else {
            this.getUser(this.IdUser);
        }
    }

    getUser(id) {
        this.userService.getUserById(id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                result => {
                    if (result == null) return this.navigationService.exit();
                    this.student = result;
                    this.navigationService.params.student = result;
                    this.getDeductions();
                },
                this.errorCallback.bind(this)
            )
    }

    getDeductions() {
        this.deductionTexts = [];
        this.ref.detectChanges();
        this.deductionService.getDeductionTextByMonth(this.student?.Id, this.currentMonthDT, this.selectedDayDT.year(), this.student?.Level, this.student?.IdRole, this.student?.CreationDate)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                result => {
                    if (result == null) return this.navigationService.exit();
                    this.deductionTexts = result;
                    this.deductionService.MonthlyDeduction = result;
                    this.child.refresh((weeks_count) => {
                        this.six_weeks_deduction = weeks_count >= 6
                    })
                },
                this.errorCallback.bind(this)
            )
    }

    selectedDayChangedDT(newDate) {
        this.selectedDayDT = newDate;
        if (!this.deductionTexts) return;
        if (this.currentMonthDT != this.selectedDayDT.month() + 1) {
            this.currentMonthDT = this.selectedDayDT.month() + 1;
            if (this.child)
                this.six_weeks_deduction = this.child.weeks.length >= 6;
            this.getDeductions();
        }

        let currentDeductionText = this.deductionTexts.find((r) =>
            moment(r.date).isSame(this.selectedDayDT)
        );
        if (!currentDeductionText) return;
        if (currentDeductionText.Score == null) return;
        if (currentDeductionText.FinishDate) {
            this.activeButton = true;
        }

    }

    startReview() {
        if (!this.deductionTexts) return;
        let currentDeductionText = this.deductionTexts.find((r) =>
            moment(r.date).isSame(this.selectedDayDT)
        );
        if (!currentDeductionText) return;
        this.navigationService.go(this.router.url + "/review",
            {
                IdUser: this.student.Id,
                review: true,
                date: currentDeductionText.date,
                parentReview: true,
                student: this.student,
                IdDeduction: currentDeductionText.IdDeduction
            }
        );
    }

    errorCallback() {
        this.navigationService.go("/school/parentStudents");
    }

    sixWeeks() {
        return $(".week").length - 1 >= 6;
    }
}
