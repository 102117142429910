import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { AuthService } from "app/core/guards/auth.service";
import { ExerciseSolvedEnum } from "app/core/shared/enums/exercise-solved.enum";
import { ExerciseService } from "app/services/exercise.service";
declare var $;

@Component({
    selector: "ex-coloca",
    templateUrl: "./coloca.component.html",
    styleUrls: ["./coloca.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ColocaComponent implements OnInit, OnDestroy {
    @Input() review;
    @Input() show_errors;
    @Input() controlSession : boolean = false;
    @Input() slide : boolean = false; @Input() current;
    exercise;
    draggingElement;
    answer = [];
    options = [];
    result;
    globalError;
    finished = false;

    elementsOk;
    elementsErr;

    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_SOLUTION]:
                this.finished = true;
                if (this.exerciseService.showingSolution) {
                    setTimeout(this.ShowAnswersTimeOut.bind(this), 100);
                    break;
                } else {
                    setTimeout(this.ShowSolution.bind(this), 100);
                    break;
                }
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_ANSWER]:
                setTimeout(this.ShowAnswer.bind(this), 100);
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_CUSTOM]:
                this.exercise = this.exerciseService.getExerciseDefinition();
                this.answer = []
                if (this.elementsErr.length > 0) {
                    this.result = false;
                } else if (this.elementsOk.length > 0) {
                    this.result = true;
                } else {
                    this.result = null;
                }
                this.DrawModel9(this.exercise);
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.TIMER_END]:
                this.finished = true;
                break;
        }
    }
    exerciseData;

    constructor(private exerciseService: ExerciseService) {
        this.exercise = this.exerciseService.getExerciseDefinition();
        this.exerciseData = this.exerciseService.getCurrentExercise();
    }

    public get exercisesSolvedEnum(): typeof ExerciseSolvedEnum{
        return this.exerciseSolvedEnum
    }
    
    public get exerciseSolvedEnum(): typeof ExerciseSolvedEnum{
        return ExerciseSolvedEnum
    }

    ngOnDestroy(){
    }

    ngOnInit() {
        this.DrawModel9(this.exercise);
        if (this.review) {
            this.elementsOk = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsOK
            );
            this.elementsErr = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsERR
            );
            if (this.show_errors == false) {
                this.ShowSolution();
              } else if (this.show_errors == true) {
                this.ShowAnswer();
            }
            else {
                if (this.elementsErr.length > 0) {
                    this.result = false;
                } else if (this.elementsOk.length > 0) {
                    this.result = true;
                } else {
                    this.result = null;
                }
            }
        } else {
            this.exerciseService.initInteract({
                onmove: (event) => {
                    this.draggingElement = event.target;
                },
                drop: (event) => {
                    if (this.finished) return;
                    var bin = event.target;
                    if (bin.id == "space-for-buttons") {
                        this.handleDropDown(this.draggingElement, bin);
                    } else {
                        this.handleDropUp(this.draggingElement, bin);
                    }
                },
            });
        }
    }

    handleDropDown(item, bin) {
        this.result = null;
        var typeItem = item.getAttribute("type");
        var indexBin = bin.getAttribute("index");
        var indexItem = item.getAttribute("index");
        // if (typeItem == "down") return;
        // if (typeItem == "up") {
            if (this.answer[indexItem] == "") return;
            this.options.push(this.answer[indexItem]);
            this.answer[indexItem] = "";
        // }
    }

    handleDropUp(item, bin) {
        var typeItem = item.getAttribute("type");
        var indexBin = bin.getAttribute("index");
        var indexItem = item.getAttribute("index");
        if (typeItem == "down") {
            if (this.options[indexItem] == "") return;
            if (this.answer[indexBin] == "") {
                this.answer[indexBin] = this.options[indexItem];
                this.options.splice(indexItem, 1);
            } else {
                var tmp = this.answer[indexBin];
                this.answer[indexBin] = this.options[indexItem];
                this.options.splice(indexItem, 1);
                this.options.push(tmp);
            }
        }
        if (typeItem == "up") {
            var tmp = this.answer[indexBin];
            this.answer[indexBin] = this.answer[indexItem];
            this.answer[indexItem] = tmp;
        }

        //Validate if finshed
        for (var i = 0; i < this.answer.length; i++) {
            if (this.answer[i] == "") return;
        }
        for (var i = 0; i < this.answer.length; i++) {
            if (this.answer[i] != this.exercise.palabras[0].palabra[i]) {
                // this.$emit("error");
                var r = [];
                for (var j = 0; j < this.answer.length; j++) {
                    r.push(
                        this.exercise.palabras[0].palabra.indexOf(
                            this.answer[j]
                        )
                    );
                }
                if (!this.globalError) {
                    this.exerciseService.answer(1, false, 1);
                    this.globalError = true;
                }
                this.result = false;
                return;
            }
        }
        console.log("entro")
        //OK
        this.result = true;
        this.exerciseService.answer(1, true, 1);
        this.exerciseService.endExercise();
        this.finished = true;
    }
    ShowAnswersTimeOut() {
        if (this.result != true)
        this.result = null;
        this.answer = (JSON.parse(JSON.stringify(this.exercise.palabras[0].palabra)));
      }
    DrawModel9(exercise) {
        for (var i = 0; i < this.exercise.palabras[0].muestra.length; i++) {
            this.answer.push("");
        }
        //Clone the first word, just one word in this version
        this.options = JSON.parse(
            JSON.stringify(this.exercise.palabras[0].muestra)
        );
    }
    ShowSolution() {
        this.result = true;
        this.answer = JSON.parse(
            JSON.stringify(this.exercise.palabras[0].palabra)
        );
    }
    ShowAnswer() {
        this.answer = this.exercise.palabras[0].palabra;
        if (this.elementsErr.length > 0) {
            this.result = false;
        } else if (this.elementsOk.length > 0) {
            this.result = true;
        } else {
            this.result = null;
            this.answer = [];
            //Just one word in this version
            for (var i = 0; i < this.exercise.palabras[0].muestra.length; i++) {
                this.answer.push("");
            }
        }
    }
}
