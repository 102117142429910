import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EmailConfigService } from 'app/services/email-config.service';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: "app-config-mail",
    templateUrl: "./config-mail.component.html",
    styleUrls: ["./config-mail.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ConfigMailComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  currentUser;
  step = 1;
  formData;

  constructor(
    private router: Router,
    private location: Location,
    private emailConfigService: EmailConfigService,
    private _formBuilder: UntypedFormBuilder,
    private _matDialog: MatDialog,
    private _translateService: TranslateService
  ) { 
    this._unsubscribeAll = new Subject();
}

ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit(): void {
        if (!sessionStorage.getItem("currentUser")) {
            this.goLogin();
            return;
        }
        this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

        if (!this.currentUser) {
            this.goLogin();
            return;
        }

        this.formData = {
            SessionReports: this.currentUser.SessionReports,
            WarningNoSession: this.currentUser.WarningNoSession,
            noemail: this.currentUser.noemail,
        };
    }

    setNoMail() {
        if (this.formData.noemail) this.formData.noemail = false;
        else this.formData.noemail = true;
        this.formData.WarningNoSession = 0;
        this.formData.SessionReports = 0;
    }

    setSessionReport() {
        if (this.formData.SessionReports == 1) this.formData.SessionReports = 0;
        else this.formData.SessionReports = 1;
        this.formData.noemail = false;
    }

    setDailyReport() {
        if (this.formData.SessionReports == 2) this.formData.SessionReports = 0;
        else this.formData.SessionReports = 2;
        this.formData.noemail = 0;
    }

    setNoSessionDaily() {
        if (this.formData.WarningNoSession == 1)
            this.formData.WarningNoSession = 0;
        else this.formData.WarningNoSession = 1;
        this.formData.noemail = false;
    }

    setNoSession5Days() {
        if (this.formData.WarningNoSession == 2)
            this.formData.WarningNoSession = 0;
        else this.formData.WarningNoSession = 2;
        this.formData.noemail = false;
    }

    cancel() {
        this.location.back();
    }

  submitForm() {

    this.emailConfigService.update(this.currentUser.Id, this.formData.SessionReports, this.formData.WarningNoSession, this.formData.noemail)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successCallbackUpdateEmailConfig.bind(this), this.errorCallback.bind(this));

  }

    successCallbackUpdateEmailConfig(response) {
        this.currentUser.SessionReports = this.formData.SessionReports;
        this.currentUser.WarningNoSession = this.formData.WarningNoSession;
        this.currentUser.noemail = this.formData.noemail;
        sessionStorage.setItem("currentUser", JSON.stringify(this.currentUser));

        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title =
        this._translateService.instant('SCHOOL.TEACHER.CONFIG.EMAILS.MODAL-TITLE');
        confirmDialog.componentInstance.message1 =
            this._translateService.instant('SCHOOL.TEACHER.CONFIG.SUCCESS-SAVE-CONFIG-MESSAGE');
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.margin = false;

    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      confirmDialog = null;
    });
    
  }

    errorCallback() {
        this.goLogin();
    }

    goLogin() {
        sessionStorage.removeItem("currentUser");
        sessionStorage.removeItem("adminLogin");
        sessionStorage.removeItem("state");
        sessionStorage.removeItem("state_params");
        sessionStorage.setItem("isLogged", "false");
        this.router.navigateByUrl("login");
    }
}
