import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class getResult{

    /**
   * @description 
   * @param {string} JMP     la abreviacion de JUMP, significa que el user es un administrador y apreto el botó para saltear el ejercicio 
   * @param {string} OK_JMP  Respondo algo bien y sin terminar me salto el ejercicio.
   * @param {string} ERR_JMP Solo en modo administrador. Cometo algún error y me salto el ejercicio.
   * @param {string} OUT     No respondo nada y se me acaba el tiempo (en sopa1 puede dar mucho porque no se registran los errores)
   * @param {string} OK_OUT  Respondo parte del ejercicio bien pero se me acaba el tiempo antes de completarlo.
   * @param {string} ERR_OUT Cometo algún error y se me acaba el tiempo.
   * @param {string} ERR_OK  Cometo algún error y luego respondo bien.
   * @param {string} OK      Ejercicio resuelto correctamente sin ningún error
   * @copyright walinwa.net
   */

    public getResult(result?: string){
        switch (result) {
            case "JMP":
                return null
            case "OK-JMP":
                return null
            case "ERR-JMP":
                return null
            case "OUT":
                return null
            case "OK-OUT":
                return null
            case "ERR-OUT":
                return null
            case "ERR-OK":
                return false
            case "OK":
                return true
            case "ERR":
                return false
            default:
                return null;
        }
    }

}
