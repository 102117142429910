<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>
<div class="unir4" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" fxLayout="column" fxLayoutAlign="stretch" [ngClass]="{'fixControlSessions': controlSession}">
    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div class="wordsContainer" [ngClass]="{'fixControlSessions': controlSession}">
        <div class="items_container">
            <div id="row1" [ngClass]="{'element-hidden': showResult == true }">
                <div *ngFor="let word of exercise.grupo1; let i = index" class="wordBoxOption draggable" [ngClass]="{
                        textSelected: i == leftSelected,
                        wordBoxError: i == leftError,
                        'element-hidden': word == null
                    }" id="col1{{ i }}" column="left" [attr.index]="i" [innerHtml]="replaceIByEmpty(word)">
                </div>
            </div>
            <div id="row2" [ngClass]="{ 'element-hidden': showResult == true }">
                <div *ngFor="let word of exercise.grupo2; let i = index" class="dropzone phraseBox" [ngClass]="{
                        'phraseBox-extra': !slide,
                        'phrase-box-slide': slide,
                        textSelected: i == rightSelected,
                        wordBoxError: i == rightError,
                        'element-hidden': word == null
                    }" id="col2{{ i }}" column="right" [attr.index]="i">
                    <span [ngClass]="{'p-i-control': controlSession}" class="p-i" [innerHtml]="word"></span>
                </div>
            </div>
            <div [hidden]="!showResult" id="columnResult" class="wordsResult"
                [ngClass]="{ 'element-hidden': showResult == false }">
                <div *ngFor="let item of exercise.result" class="wordBox wordBoxResult" id="result{{ i }}"
                    [ngClass]="{ wordBoxOk: item.answered == true, wordBoxError: item.answered == false, wordBoxNull: item.answered == null}">
                    <span class="p-i2" [innerHtml]="replaceI(item.word)"></span>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  -->
<!-- <div class="unir4" [ngStyle]="{'pointer-events': review ? 'none;' : ''}">   <h2 *ngIf="controlSession" class="doc-number">{{current + 1}}</h2>
    <div *ngIf="!controlSession" class="exerciseTheme">
        <h1 [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></h1>
    </div>
    <div class="text-center exerciseTitle" [ngClass]="{'exerciseTitle-slide': slide}">
        <p   [innerHtml]="exercise.enunciado"></p>
    </div>
    <div class="optionsContainer" [ngStyle]="controlSession ? {width: '100%;', margin : '1em 0 0 0'} : {}">
        <div id="row1"  [ngClass]="{'wordsContainer': !slide, 'words-container-slide': slide, 'element-hidden': showResult == true }">
            <div [ngStyle]="controlSession && !slide ? {'font-size': '1.3em;'} : {}" *ngFor="let word of exercise.grupo1; let i = index" class="wordBoxOption draggable" [ngClass]="{
                    textSelected: i == leftSelected,
                    wordBoxError: i == leftError,
                    'element-hidden': word == null
                }" id="col1{{ i }}" column="left" [attr.index]="i" [innerHtml]="replaceIByEmpty(word)">
            </div>
        </div>
        <div id="row2" class="wordsContainer2" [ngClass]="{ 'element-hidden': showResult == true }">
            <div *ngFor="let word of exercise.grupo2; let i = index"
                class="dropzone" 
                [ngClass]="{
                    'phraseBox': !slide,
                    'phrase-box-slide': slide,
                    textSelected: i == rightSelected,
                    wordBoxError: i == rightError,
                    'element-hidden': word == null
                }" 
                id="col2{{ i }}" column="right" [attr.index]="i">
                <p [ngClass]="{'p-i-control': controlSession}" class="p-i" [innerHtml]="word"></p>
            </div>
        </div>
        <div [hidden]="!showResult" id="columnResult" class="wordsResult" [ngClass]="{ 'element-hidden': showResult == false }">
            <div style="position: relative" *ngFor="let item of exercise.result" class="wordBox wordBoxResult" id="result{{ i }}" [ngClass]="{ wordBoxOk: item.answered == true, wordBoxError: item.answered == false, wordBoxNull: item.answered == null}">
                <p class="p-i2" [innerHtml]="replaceI(item.word)"></p>
            </div>
        </div>
    </div>
</div> -->