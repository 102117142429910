import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
    selector   : 'fuse-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls  : ['./confirm-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FuseConfirmDialogComponent
{
    public message1: string;
    public message2: string;
    public message3: string;
    public textButton1: string;
    public textButton2: string;
    public title :string;
    public margin:boolean;

    constructor(
        public dialogRef: MatDialogRef<FuseConfirmDialogComponent>,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    )
    {
    }

}
