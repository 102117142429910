import { PersonalizedTestResult } from '../core/shared/state/models/personalized-test/personalized-test-result.model';
import { PersonalizedTest, TestThemes } from '../core/shared/state/models/personalized-test/personalized-test.model';
import { ControlSession } from '../core/shared/state/models/control-session.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpParams, HttpEvent, HttpHeaderResponse, HttpResponse, HttpErrorResponse, HttpUserEvent, HttpProgressEvent } from '@angular/common/http';
import { Location } from '@angular/common';
import { environment } from 'environments/environment';
import { PersonalizedTestQuestion } from 'app/core/shared/state/models/personalized-test/personalized-test-question.model';
import { ChatGPTRequestModel } from 'app/core/shared/state/models/chatGPTRequestModel';
import { Observable } from 'rxjs';
import { PersonalizedTestDTO } from 'app/core/shared/state/models/personalized-test/PersonalizedTestDTO.model';
import { PersonalizedTestUserReview } from 'app/core/shared/state/models/test-user-review.model';
import { TestUserResult } from 'app/core/shared/state/models/personalized-test/personalized-test-user-result.model';
@Injectable({
    providedIn: 'root'
  })
  export class PersonalizedTestService {

    baseUrl = environment.baseApi + '/api/PersonalizedTest';
    doTest = false;
    constructor(private http: HttpClient, private _location: Location) {
    }
    getAll(){
        return this.http.get<PersonalizedTest[]>(this.baseUrl);
    }
    getUserDoneTest(IdUser: number, IdClass: number){
        return this.http.get<PersonalizedTestUserReview[]>(this.baseUrl + '/user/' + IdUser + '/' + IdClass);
    }
    getPersonalizedTestById(IdTest: number, IdUser: number, Date: any, Review: boolean, Rewarded: boolean = false, Score: number = 0) {
        var params = {
            IdTest,
            IdUser,
            Date,
            Review,
            Rewarded,
            Score
        }
        return this.http.post<PersonalizedTest>(this.baseUrl + '/getPersonalizedTestById', params);
    }
    getTestQuestions(idTest: number){
        return this.http.get<PersonalizedTestQuestion[]>(this.baseUrl + '/questions/' + idTest);
    }
    GetTestResult(IdTest: number, Date: any, IdClass: number){
        let params = {
            IdTest,
            Date,
            IdClass
        }
        return this.http.post<TestUserResult[]>(this.baseUrl + '/getTestResult/', params);
    }

    public getTestsQuestions(idsTest: number[]): Observable<PersonalizedTestQuestion[]>{
      return this.http.get<PersonalizedTestQuestion[]>(this.baseUrl + '/GetTestsQuestions?' + idsTest.map(id => `ids=${id}`).join('&'));
    }

    getByUser(idUser) {
      return this.http.get<PersonalizedTest[]>(this.baseUrl + '/idUser/' + idUser);
    };
    updateTestName(idTest, name) {
      var test = { IdTest: idTest, Name: name };
      return this.http.post(this.baseUrl + '/updateTestName/', test);
    };
  
    addTest(test) {
        return this.http.post(this.baseUrl + "/add", test);
    }

    updateTest(test) {
      return this.http.put(this.baseUrl + "/update", test);
    }

  cloneTest(IdTest: number, IdTutor: number) {
    var params = {
      IdTest: IdTest,
      Idtutor: IdTutor
    }
    return this.http.post(this.baseUrl + "/clone", params);
  }
    start(IdTest: number, IdUser: number, numExercises: number){
        var params = {
            IdTest: IdTest,
            IdUser: IdUser,
            numExercises: numExercises
          }
          return this.http.post(this.baseUrl + "/start", params);
    }
    update(idTest : number, idUser : number,numOk = 0, numPosibleAnswers: number){
        var params = {
            IdTest: idTest,
            IdUser: idUser,
            NumOk: numOk,
            NumPosibleAnswers: numPosibleAnswers
        }
        return this.http.post(this.baseUrl + "/updateTestResult", params);
    }
    updateTestAnswers(idTest: number, idUser: number, obj: testRequest[]){
        return this.http.put(this.baseUrl + "/idTest/" + idTest + "/idUser/" + idUser, obj);
    }
  
    remove(personalizedTest : PersonalizedTest) {
      return this.http.delete(this.baseUrl + "/idTest/" + personalizedTest.IdTest);
    };
    addQuestions(IdTest : number, Questions){
        return this.http.post(this.baseUrl + "/addQuestions/" + IdTest, Questions);
    }
    
    //getChatGPTTest(testConfig: ChatGPTRequestModel): Observable<PersonalizedTestQuestion[]>{ //TODOJO
    getChatGPTTest(testConfig: ChatGPTRequestModel): Observable<any>{ 
      return this.http.post<PersonalizedTestQuestion[]>(`${this.baseUrl}/GetChatGPTTest`, testConfig);
    }

    public getThemes(): Observable<TestThemes[]> {
      return this.http.get<TestThemes[]>(`${this.baseUrl}/GetThemes`);
    }

    public canCreateTestWithChatGPT(): Observable<boolean> {
      return this.http.get<boolean>(`${this.baseUrl}/CanCreateTestWithChatGPT`);
    }
    MapFromDTOToTest(DTOList :PersonalizedTestDTO[]){
        return DTOList.filter(x => x.IdClass === 0).map((PersonalizedTestDTO) => {
            const { IdTest, Name, IdTutor, LastUpdateDate, MultipleCorrectAnswers, TLevel, Aula, Finished, Total, ShareTest, UserName, MessQuestions, TestFormat, ProportionalScore, TestOpen, CreationDate, AutoOpenTest} = PersonalizedTestDTO;
            return {
                IdTest,
                Name,
                IdTutor,
                Aula,
                Tutor: null,
                TestQuestion: [],
                TestResult: [],
                TestAnswerUser: [],
                CustomSessions: [],
                selected: false,
                //count_assigned: undefined,
                editingPersonalizedTestName: false,
                CreationDate,
                ShareTest,
                TestFormat,
                TLevel,
                LastUpdateDate,
                MultipleCorrectAnswers,
                Finished,
                Total,
                TestOpen,
                TestState: null,
                UserName,
                MessQuestions,
                ProportionalScore,
                AutoOpenTest
              };
        });
    }

}
 interface testRequest{
    IdQuestion: number,
    IdAnswer: number,
}
