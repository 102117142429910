<div class="walinwa-box-modal-overlay" style="align-items: center; background-color: rgba(0, 0, 0, .5);">
    <div class="walinwa-review">
        <div class="modal-back-image">
            <div class="paginationArea leftSide" fxHide fxShow.xs>
                <div [ngClass]="{ 'pagination-disabled': currentStep == 1 }" class="pagination-button" (click)="changePage(false)">
                    <i class="fa fa-chevron-left"></i>
                </div>
            </div>
            <div class="page">
                <img class="main" [src]="'https://walinwa.blob.core.windows.net/images/' + subDirectory + '/' + getImgName() + '?ngsw-bypass=true'" alt="">
                <div class="walinwa-close-modal" (click)="finish()">
                    <img src="https://walinwa.blob.core.windows.net/images/ico-close-popup.svg?ngsw-bypass=true" />
                </div>
                <div class="paginationArea leftSide nomobile" fxHide.xs fxShow>
                    <div [ngClass]="{ 'pagination-disabled': currentStep == 1 }" class="pagination-button" (click)="changePage(false)">
                        <i class="fa fa-chevron-left"></i>
                    </div>
                </div>
                <div class="paginationArea rightSide nomobile" fxHide.xs fxShow>
                    <div class="pagination-button" [hidden]="currentStep >= numberOfPages()" (click)="changePage(true)">
                        <i class="fa fa-chevron-right"></i>
                    </div>
                    <div class="pagination-button pagination-disabled" [hidden]="currentStep < numberOfPages()" (click)="finish()">
                        <i class="fa fa-chevron-right"></i>
                    </div>              
                </div>
            </div>
            <div class="paginationArea rightSide" fxHide fxShow.xs>
                <div class="pagination-button" [hidden]="currentStep >= numberOfPages()" (click)="changePage(true)">
                    <i class="fa fa-chevron-right"></i>
                </div>
                <div class="pagination-button pagination-disabled" [hidden]="currentStep < numberOfPages()" (click)="finish()">
                    <i class="fa fa-chevron-right"></i>
                </div>              
            </div>
        </div>
    </div>
</div>