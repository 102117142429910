import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ExerciseSolvedEnum } from 'app/core/shared/enums/exercise-solved.enum';
import { ExerciseService } from 'app/services/exercise.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'ex-mLluvia',
  templateUrl: './m-lluvia.component.html',
  styleUrls: ['./m-lluvia.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MLLuviaComponent implements OnInit, OnDestroy {

  @Input() review
  @Input() show_errors;
  @Input() controlSession: boolean = false;
  @Input() slide: boolean = false;
  @Input() current;
  exercise = null
  finished = false
  showAnswer = false
  speed = 1000
  dataLoaded = false
  iterations = 0
  itemsSelected = 0
  timerEnabled = false
  numErr = 0
  elementsOk;
  elementsErr;
  internalTimeout;

    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_SOLUTION]:
                this.finished = true;
                if (this.exerciseService.showingSolution) {
                    setTimeout(this.ShowAnswersTimeOut.bind(this), 100);
                    break;
                } else {
                    setTimeout(this.ShowSolution.bind(this), 100);
                    break;
                }
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_ANSWER]:
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_CUSTOM]:
                setTimeout(this.ShowAnswer.bind(this), 100)
                this.finished = true;
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.TIMER_END]:
                this.finished = true;
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.EXERCISE_END]:
                this.finished = true;
                this.showAnswer = true;
                break;
        }
    }
  eventsSubject: Subject<object> = new Subject<object>();
  constructor(private exerciseService: ExerciseService) { }

  public get exercisesSolvedEnum(): typeof ExerciseSolvedEnum{
    return this.exerciseSolvedEnum
  }

  public get exerciseSolvedEnum(): typeof ExerciseSolvedEnum{
    return ExerciseSolvedEnum
  }

  ngOnDestroy(){
    clearTimeout(this.internalTimeout)
  }

  ngOnInit() {
    this.start()
    if (this.review) {
        this.elementsOk = JSON.parse(
            this.exerciseService.getCurrentExercise().ElementsOK
        );
        this.elementsErr = JSON.parse(
            this.exerciseService.getCurrentExercise().ElementsERR
        );
        if (this.show_errors == false) {
          this.ShowSolution();
        } else if (this.show_errors == true || this.show_errors == null) {
          this.ShowAnswer();
        }
    }
  }
  ShowAnswer() {
    this.showAnswer = true;
    for (var i = 0; i < this.exercise.palabras.length; i++) {
      this.exercise.palabras[i].answered = null;
      this.exercise.palabras[i].notAnserwed = null;
    }
    for (var p = 0; p < this.elementsErr.length; p++) {
      this.exercise.palabras[this.elementsErr[p]].answered = false;
    }
    for (var p = 0; p < this.elementsOk.length; p++) {
      this.exercise.palabras[this.elementsOk[p]].answered = true;
    }
    for (var i = 0; i < this.exercise.palabras.length; i++) {
      if (this.exercise.palabras[i].opcion && this.exercise.palabras[i].answered == null)
        this.exercise.palabras[i].notAnserwed = true;
    }

  }
  ShowHourGlassSolution() {
    this.finished = true;
    this.showAnswer = true;
    this.exercise.palabras.forEach((palabra,indexPalabra ) => {
        palabra.answered = null;
        palabra.notAnserwed = null;
        this.elementsOk.forEach(acertado => {
            if(indexPalabra == acertado){
                palabra.answered = true;
            }
        });
    });
    this.showAnswer = true;
  }
  ShowSolution() {
    this.exercise.palabras.forEach((value, key) => {
      if (value.opcion)
        value.answered = true;
      else
        value.answered = null;
        value.notAnserwed = null;
    });
    this.showAnswer = true;
  };
  getStyle(palabra) {
    if (this.exercise.Go == 1)
      return { position: 'absolute', left: palabra.left, top: palabra.top, right: palabra.right };
    if (this.exercise.Go == 2)
      return { position: 'absolute', left: palabra.left, bottom: palabra.bottom, right: palabra.right };
    if (this.exercise.Go == 3)
      return { position: 'absolute', left: palabra.left, top: palabra.top, bottom: palabra.bottom };
    if (this.exercise.Go == 4)
      return { position: 'absolute', right: palabra.right, top: palabra.top, bottom: palabra.bottom };
  }

  validateClick(index) {
    var numTotal = 0;
    if (this.finished || this.exercise.palabras[index].answered) return

    var result = true;
    for (var i = 0, len = this.exercise.palabras.length; i < len; i++) {
      numTotal++;
      if (this.exercise.palabras[i].error == true) {
        this.exercise.palabras[i].error = null;
      }
    }
    if (this.exercise.palabras[index].opcion) {
      this.exercise.palabras[index].answered = true;
      if (this.exercise.palabras[index].rae) this.exercise.palabras[index].palabra = this.exercise.palabras[index].rae;
      this.exerciseService.answer(index, true, this.exercise.NumPalabras)
      this.itemsSelected++;
    }
    else {
      // this.$emit("error");
      this.exercise.palabras[index].answered = false;
      this.exercise.palabras[index].error = true;
      this.exerciseService.answer(index, false, this.exercise.NumPalabras)
      this.numErr++;
    }

    if (this.exercise.NumPalabras == null) {
      //Check if every word has been answered
      for (var i = 0, len = this.exercise.palabras.length; i < len; i++) {
        if (this.exercise.palabras[i].opcion && this.exercise.palabras[i].answered != true) {
          result = false;
        }
        if (this.exercise.palabras[i].opcion == false && this.exercise.palabras[i].answered == true) {
          result = false;
        }
      }
    } else {
      if (this.itemsSelected != this.exercise.NumPalabras)
        result = false;
    }
    if (result) {
      this.finished = true;
      if (this.numErr >= (numTotal * 0.4)) window.postMessage("EXERCISE_SOLVED_MSG_2", "*");
    }
  }

  start() {
    var json = this.exerciseService.getCurrentExercise().ExerciseJson.ExerciseDefinition.replace(/\r?\n|\r/g, "")
    try {
      this.exercise = JSON.parse(json)
    } catch (err) {
      return
    }
    this.speed = (11 - this.exercise.velocidad) * 10
    if (this.exercise.Go == null) this.exercise.Go = 1
    if (!this.exercise.NumPalabras) this.exercise.NumPalabras = this.exercise.palabras.filter(p => p.opcion == true).length
    this.FillDictionary();
    this.Draw();
    this.dataLoaded = true;
    this.onMoveItems()
  }

  FillDictionary() {
    this.exerciseService.dictionaryWordList = []
    for (let i = 0; i < this.exercise.palabras.length; i++) {
      let found = false;
      for (let j = 0; j < this.exerciseService.dictionaryWordList.length; j++) {
        if (this.exerciseService.dictionaryWordList[j] == this.exercise.palabras[i].rae) found = true;
      }
      if (found == false) this.exerciseService.dictionaryWordList.push(this.exercise.palabras[i].rae);
    }
  }

  Draw() {
    for (let i = 0; i < this.exercise.palabras.length; i++) {
      this.exercise.palabras[i].topN = 0;
      this.exercise.palabras[i].leftN = 0;
      if (this.exercise.Go == 1) this.exercise.palabras[i].top = this.exercise.palabras[i].topN + "%";
      if (this.exercise.Go == 2) this.exercise.palabras[i].bottom = this.exercise.palabras[i].topN + "%";
      if (this.exercise.Go == 3) this.exercise.palabras[i].left = this.exercise.palabras[i].leftN + "%";
      if (this.exercise.Go == 4) this.exercise.palabras[i].right = this.exercise.palabras[i].leftN + "%";
      if (this.exercise.Go == 1 || this.exercise.Go == 2) {
        switch (i % 5) {
          case 1:
            this.exercise.palabras[i].left = (Math.floor(Math.random() * 10) + 2) + "%";
            this.exercise.palabras[i].right = null;
            break;
          case 3:
            this.exercise.palabras[i].left = (Math.floor(Math.random() * 10) + 20) + "%";
            this.exercise.palabras[i].right = null;
            break;
          case 0:
            this.exercise.palabras[i].left = (Math.floor(Math.random() * 10) + 40) + "%";
            this.exercise.palabras[i].right = null;
            break;
          case 2:
            this.exercise.palabras[i].left = null;
            this.exercise.palabras[i].right = (Math.floor(Math.random() * 10) + 20) + "%";
            break;
          case 4:
            this.exercise.palabras[i].left = null;
            this.exercise.palabras[i].right = (Math.floor(Math.random() * 10) + 2) + "%";
            break;
        }
      } else {
        switch (i % 5) {
          case 1:
            this.exercise.palabras[i].top = (Math.floor(Math.random() * 10) + 2) + "%";
            this.exercise.palabras[i].bottom = null;
            break;
          case 3:
            this.exercise.palabras[i].top = (Math.floor(Math.random() * 10) + 20) + "%";
            this.exercise.palabras[i].bottom = null;
            break;
          case 0:
            this.exercise.palabras[i].top = (Math.floor(Math.random() * 10) + 40) + "%";
            this.exercise.palabras[i].bottom = null;
            break;
          case 2:
            this.exercise.palabras[i].top = null;
            this.exercise.palabras[i].bottom = (Math.floor(Math.random() * 10) + 20) + "%";
            break;
          case 4:
            this.exercise.palabras[i].top = null;
            this.exercise.palabras[i].bottom = (Math.floor(Math.random() * 10) + 2) + "%";
            break;
        }
      }
    }
  }

  onMoveItems() {
    var isMobile: boolean;
    var startItem = (isMobile = window.innerWidth > 599) ? Math.floor(this.iterations / 20) : Math.floor(this.iterations / 40); // Aquí se juega con los tiempos de aparición de las palabras
    if (startItem > this.exercise.palabras.length && this.exercise.Continuo) {
      this.iterations = 0;
    }
    if (startItem < this.exercise.palabras.length && (this.exercise.palabras[startItem].topN == 0 || this.exercise.palabras[startItem].topN > 100)) {
      this.exercise.palabras[startItem].topN = 1;
      this.exercise.palabras[startItem].leftN = 1;
    }
    let anyItemOnScreen = false;
    for (let i = 0; i < this.exercise.palabras.length; i++) {
      if (this.exercise.palabras[i].topN != 0)
        this.exercise.palabras[i].topN = this.exercise.palabras[i].topN + 0.25;
      if (this.exercise.palabras[i].leftN != 0)
        this.exercise.palabras[i].leftN = this.exercise.palabras[i].leftN + 0.25;
      if (this.exercise.Go == 1)
        this.exercise.palabras[i].top = this.exercise.palabras[i].topN + "%";
      if (this.exercise.Go == 2)
        this.exercise.palabras[i].bottom = this.exercise.palabras[i].topN + "%";
      if (this.exercise.Go == 3)
        this.exercise.palabras[i].left = this.exercise.palabras[i].leftN + "%";
      if (this.exercise.Go == 4)
        this.exercise.palabras[i].right = this.exercise.palabras[i].leftN + "%";
      if (this.exercise.palabras[i].topN < 100 && this.exercise.palabras[i].leftN < 100)
        anyItemOnScreen = true;
    }
    if (anyItemOnScreen == false) {
        
        /*
        no entiendo la necesidad de este código

      if (this.exercise.Continuo == false) {
        for (var i = 0; i < this.exercise.palabras.length; i++) {
            if (this.exercise.palabras[i].opcion && this.exercise.palabras[i].answered != true) {
            this.exerciseService.answer(i, false, this.exercise.NumPalabras)
          }
        }
      }
      */
      this.finished = true;
      window.postMessage(this.exercisesSolvedEnum[this.exerciseSolvedEnum.TIMER_END], "*");
      this.eventsSubject.next({ event: this.exercisesSolvedEnum[this.exerciseSolvedEnum.TIMER_END]  });  
      // this.$emit('exercise-solved');
    }
    this.iterations++
    if (!this.finished) {
      this.internalTimeout = setTimeout(this.onMoveItems.bind(this), this.speed)
    }
  }

  ShowAnswersTimeOut() {
    for (var i = 0; i < this.exercise.palabras.length; i++) {
        if ((this.exercise.palabras[i].answered == null || this.exercise.palabras[i].answered == undefined) && this.exercise.palabras[i].opcion) {
            this.exercise.palabras[i].notAnserwed = true
        }
    }
    this.showAnswer = true;
  }

}
