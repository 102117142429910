import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ExerciseSolvedEnum } from "app/core/shared/enums/exercise-solved.enum";
import { ExerciseService } from "app/services/exercise.service";

@Component({
    selector: "ex-boxes2",
    templateUrl: "./boxes2.component.html",
    styleUrls: ["./boxes2.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class Boxes2Component implements OnInit, OnDestroy {
    @Input() review;
    @Input() show_errors;
    @Input() controlSession : boolean = false;
    @Input() slide : boolean = false; @Input() current;
    exercise;
    leftWords = [];
    rightWords = [];
    leftValidation;
    rightValidation;
    draggingWordIndex;
    exerciseIsEnded = false;

    elementsOk;
    elementsErr;

    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_SOLUTION]:
                if (this.exerciseService.showingSolution) {
                    setTimeout(this.showAnswersTimeOut.bind(this), 100);
                    break;
                } else {
                    setTimeout(this.ShowSolution.bind(this), 100);
                    break;
                }
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_ANSWER]:
                this.ShowAnswer();
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_CUSTOM]:
                this.showHourglassAnswer();
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.TIMER_END]:
                this.exerciseIsEnded = true;
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.EXERCISE_END]:
                this.exerciseIsEnded = true;
                break;
        }
    }

    constructor(private exerciseService: ExerciseService) {
        this.exercise = this.exerciseService.getExerciseDefinition();
        this.leftValidation = this.exercise.boxes[0].valor;
        this.rightValidation = this.exercise.boxes[1].valor;
    }

    public get exercisesSolvedEnum(): typeof ExerciseSolvedEnum{
        return this.exerciseSolvedEnum
    }
    
    public get exerciseSolvedEnum(): typeof ExerciseSolvedEnum{
        return ExerciseSolvedEnum
    }

    ngOnDestroy(){
    }

    ngOnInit() {
        if (this.review) {
            this.elementsOk = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsOK
            );
            this.elementsErr = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsERR
            );
            if (this.show_errors == false) {
                this.ShowSolution();
            } else if (this.show_errors == true) {
                this.ShowAnswer();
            }
            else if (this.show_errors == null){
                this.showHourglassAnswer();
            }
        } else {
            this.exerciseService.initInteract({
                onmove: (event) => {
                    this.draggingWordIndex =
                        event.target.getAttribute("wordIndex");
                },
                drop: (event) => {
                    if (this.exerciseIsEnded) return;
                    let boxIndex = event.target.getAttribute("boxIndex");
                    this.drop(boxIndex);
                },
            });
        }
    }
    showHourglassAnswer(){
        this.leftValidation = this.exercise.boxes[0].valor;
        this.rightValidation = this.exercise.boxes[1].valor;
        this.rightWords = []
        this.leftWords = []
        this.exercise.palabras.forEach(palabra => palabra.answered = null);
        this.elementsErr.forEach(indexPalabra => {
            let palabraErrada = this.exercise.palabras[indexPalabra]
            palabraErrada.answered = false
            
        })
        this.elementsOk.forEach(indexPalabra => {
            let palabraAcertada = this.exercise.palabras[indexPalabra]
            if (palabraAcertada.answered == false) {
                return;
            }
            palabraAcertada.answered = true;
            if(palabraAcertada.opcion == this.leftValidation){
                this.leftWords.push({ palabra: palabraAcertada.palabra, answered: true });
            }
            else {
                this.rightWords.push({ palabra: palabraAcertada.palabra, answered: true });
            }
        })
    }
    ShowSolution() {
        this.leftWords = [];
        this.rightWords = [];
        for (var j = 0; j < this.exercise.palabras.length; j++) {
            this.exercise.palabras[j].answered = true;
            if (this.exercise.palabras[j].opcion == this.leftValidation) {
                this.leftWords.push({ palabra: this.exercise.palabras[j].palabra, answered: true });
            }
            if (this.exercise.palabras[j].opcion == this.rightValidation) {
                this.rightWords.push({ palabra: this.exercise.palabras[j].palabra, answered: true });
            }
        }
    }

    showAnswersTimeOut() {
        for (var j = 0; j < this.exercise.palabras.length; j++) {
            if (this.exercise.palabras[j].answered != true) {
                if (this.exercise.palabras[j].opcion == this.leftValidation) {
                    this.leftWords.push({ palabra: this.exercise.palabras[j].palabra, answered: null });
                }
                if (this.exercise.palabras[j].opcion == this.rightValidation) {
                    this.rightWords.push({ palabra: this.exercise.palabras[j].palabra, answered: null });
                }
            }
        }
    }
    ShowAnswer() {

        this.leftWords = [];
        this.rightWords = [];
        for (var i = 0; i < this.exercise.palabras.length; i++) {
            this.exercise.palabras[i].answered = null;
        }
        for (var j = 0; j < this.elementsErr.length; j++) {
            this.exercise.palabras[this.elementsErr[j]].answered = false;
        }
        for (var j = 0; j < this.elementsOk.length; j++) {
            if (this.exercise.palabras[this.elementsOk[j]].answered == false) continue
            this.exercise.palabras[this.elementsOk[j]].answered = true;
            if (this.exercise.palabras[this.elementsOk[j]].opcion == this.leftValidation) {
                this.leftWords.push({ palabra: this.exercise.palabras[this.elementsOk[j]].palabra, answered: true });
            }
            if (this.exercise.palabras[this.elementsOk[j]].opcion == this.rightValidation) {
                this.rightWords.push({ palabra: this.exercise.palabras[this.elementsOk[j]].palabra, answered: true });
            }
        }
    }

    showingSolution() {
        let isShowingSolution = this.exerciseService.isShowingSolution();
        // if (isShowingSolution) {
        //     for (let word of this.exercise.palabras) {
        //         if (word.answered != true) {
        //             if (word.opcion == this.leftValidation) {
        //                 this.leftWords.push({
        //                     palabra: word.rae,
        //                     answered: false,
        //                 });
        //             }
        //             if (word.opcion == this.rightValidation) {
        //                 this.rightWords.push({
        //                     palabra: word.rae,
        //                     answered: false,
        //                 });
        //             }
        //             word.answered = true;
        //         }
        //     }
        // }
        return isShowingSolution;
    }

    drop(boxIndex) {
        if (!this.exercise.palabras[this.draggingWordIndex]) return;

        if (this.exercise.palabras[this.draggingWordIndex].answered == true)
            return;

        for (let word of this.exercise.palabras) {
            if (word.answered != true) word.answered = null;
        }

        let word = this.exercise.palabras[this.draggingWordIndex];

        let wordValidation = word.opcion;
        let boxValidator;
        if (boxIndex == 0) boxValidator = this.leftValidation;
        else boxValidator = this.rightValidation;

        if (wordValidation == boxValidator) {
            console.log("entro")
            if (boxIndex == 0)
                this.leftWords.push({
                    palabra: this.exercise.palabras[this.draggingWordIndex].palabra,
                    answered: true,
                });
            else
                this.rightWords.push({
                    palabra: this.exercise.palabras[this.draggingWordIndex].palabra,
                    answered: true,
                });
            this.exercise.palabras[this.draggingWordIndex].answered = true;
            this.exerciseService.answer(
                this.draggingWordIndex,
                this.exercise.palabras[this.draggingWordIndex].answered,
                this.exercise.palabras.length
            );
        } else {
            console.log("entro")
            this.exercise.palabras[this.draggingWordIndex].answered = false;
            this.exerciseService.answer(
                this.draggingWordIndex,
                this.exercise.palabras[this.draggingWordIndex].answered,
                this.exercise.palabras.length
            );
        }
        var done = true;
        for (var i = 0, len = this.exercise.palabras.length; i < len; i++) {
            if (this.exercise.palabras[i].answered != true) {
                done = false;
            }
        }
        if (done) {
            this.exerciseIsEnded = true;
        }
        return;
    }
}
