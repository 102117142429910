import { AppService } from '../../../../../app.service';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SchoolService } from 'app/services/school.service';
import { UserService } from 'app/services/user.service';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { AuthService } from 'app/services/auth.service';
import * as moment from 'moment';
import { UntypedFormBuilder } from '@angular/forms';
import { NavigationService } from 'app/services/navigation.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
declare var $;

@Component({
  selector: 'app-dict-config',
  templateUrl: './dict-config.component.html',
  styleUrls: ['./dict-config.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DictConfigComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

	user;
	classes;
	selectedClass;
	selectedDay;
	currentMonth;
	dictations;
	hasExercise;
	form;
	isIpad: boolean = false;
    enableDictation: any;

	constructor(
		private authService: AuthService,
		private userService: UserService,
		private schoolService: SchoolService,
		private matDialog: MatDialog,
		private formBuilder: UntypedFormBuilder,
		private navigationService: NavigationService,
		private appService: AppService,
        private _translateService: TranslateService
	) {
        this._unsubscribeAll = new Subject();
		moment.locale('es')
		this.selectedClass = 0;
		this.form = this.formBuilder.group({
			DictEnd: [null],
			DictIni: [null],
			DictSpace: [false],
            AccentPenalty: [false]
		});
	}

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit() {
        if (!this.authService.check()) return;
        this.selectedDay = moment();
        this.enableDictation = this.schoolService.school.Dictation;
        this.currentMonth = this.selectedDay.month() + 1;
        this.user = this.authService.currentUser;
        if (this.appService.mobileDetect().IsIPad) {
            this.isIpad = true;
        }
        this.getClasses();
    }

	getClasses() {
		this.userService.getClassesByIdTutor(this.user.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
			result => {
				this.classes = result;
				this.classes.sort(function (a, b) {
					return a.Level - b.Level;
				});
				if (!this.selectedClass) this.checkDictation();
				setTimeout(() => {
					$('#select-class').val(this.selectedClass);
				}, 0);
			},
			error => {
				this.navigationService.exit();
			});
	}

    checkDictation() {
        setTimeout(() => {
            this.selectedClass = parseFloat(this.selectedClass.toString());
        }, 100);
        let cls;
        if (this.selectedClass == 0) {
            if (this.sameValues()) {
                cls = this.classes[0];
            } else {
                this.form.patchValue({
                    DictEnd: null,
                    DictIni: null,
                    DictSpace: false,
                    AccentPenalty: false,
                });
                return;
            }
        } else {
            cls = this.classes.find((c) => c.IdClass == this.selectedClass);
        }
        this.form.patchValue({
            DictIni: cls.DictIni,
            DictEnd: cls.DictEnd,
            DictSpace: cls.DictSpace,
            AccentPenalty: cls.AccentPenalty
        });
    }

    sameValues() {
        if (!this.classes) return;
        if (this.classes.length == 0) return;
        let values1 = {
            DictEnd: this.classes[0].DictEnd,
            DictIni: this.classes[0].DictIni,
            DictSpace: this.classes[0].DictSpace,
            AccentPenalty: this.classes[0].AccentPenalty
        };
        for (let cls of this.classes) {
            let values2 = {
                DictEnd: cls.DictEnd,
                DictIni: cls.DictIni,
                DictSpace: cls.DictSpace,
                AccentPenalty: cls.AccentPenalty
            };
            if (JSON.stringify(values1) != JSON.stringify(values2))
                return false;
        }
        return true;
    }

    sixWeeks() {
        return $(".week").length - 1 >= 6;
    }

    hasValue(time) {
        return time != null && time != "";
    }

    isInvalid() {
        if (
            (this.hasValue(this.form.value.DictIni) &&
                !this.hasValue(this.form.value.DictEnd)) ||
            (!this.hasValue(this.form.value.DictIni) &&
                this.hasValue(this.form.value.DictEnd))
        )
            return true;
        else return false;
    }

    isInvalid2() {
        if (
            (this.hasValue(this.form.value.DictIni) &&
                this.hasValue(this.form.value.DictEnd) &&
                this.form.value.DictIni > this.form.value.DictEnd)
        )
            return true;
        else return false;
    }

	save() {
		if (this.isInvalid()) {
			let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
				disableClose: true
			});
			confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.CONFIG.MODAL-BAD-TIME-RANGE-TITLE');
			confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.CONFIG.MODAL-BAD-TIME-RANGE-MESSAGE1');
			confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
			confirmDialog.componentInstance.margin = false;
			confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
				confirmDialog = null;
			});
			return;
        }

        if (this.isInvalid2()) {
			let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
				disableClose: true
			});
			confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.CONFIG.MODAL-BAD-TIME-RANGE-TITLE');
			confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.CONFIG.MODAL-BAD-TIME-RANGE-MESSAGE2');
			confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
			confirmDialog.componentInstance.margin = false;
			confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
				confirmDialog = null;
			});
			return;
        }

		this.schoolService.saveDictConfig(
			this.authService.currentUser.Id, 
			this.selectedClass, 
			this.form.value.DictIni, 
			this.form.value.DictEnd,
			this.form.value.DictSpace,
            this.form.value.AccentPenalty)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
			result => {
				let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
					disableClose: true
				});
				confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.CONFIG.DICTATIONS.SUCCESS-SAVE-CONFIG-TITLE');
				confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.CONFIG.SUCCESS-SAVE-CONFIG-MESSAGE');
				confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
				confirmDialog.componentInstance.margin = false;
				confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
					confirmDialog = null;
					this.getClasses();
				});
			},
			error => {
				let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
					disableClose: true
				});
				confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.CONFIG.DICTATIONS.ERROR-SAVE-CONFIG-TITLE');
				confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.CONFIG.ERROR-SAVE-CONFIG-MESSAGE');
				confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
				confirmDialog.componentInstance.margin = false;
				confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
					confirmDialog = null;
				});
			});
	}

}
