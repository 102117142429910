<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="sopa2 spe_gap" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" fxLayout="column" fxLayoutAlign="stretch">

    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div id="contenido_sopa" class="wordsContainer">
        <div class="items_container spe_gap contenido_sopa_muestra" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between stretch" fxLayoutAlign.xs="stretch">
            <div fxFlex="" fxFlex.xs="0 0 auto" fxFlexOrder.xs="2" fxLayout="row" fxLayoutAlign="center center">
                <div class="juego" [id]="'juego' + this.numero_random"></div>
            </div>
            <div fxFlex="15rem" fxFlex.xs="0 0 auto" fxFlexOrder.xs="1" fxLayout="row" fxLayoutAlign="center center" class="imagen" [ngClass]="{'fixControlSessions': controlSession}">
                <img [src]="getImgSrc()" />
            </div>
        </div>
    </div>

</div>
<!--  -->
<!-- <div class="sopa2" [ngStyle]="{'pointer-events': review ? 'none;' : ''}">   <h2 *ngIf="controlSession" class="doc-number">{{current + 1}}</h2>

    <div *ngIf="!controlSession" class="exerciseTheme">
        <h1 [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></h1>
    </div>

    <div class="text-center exerciseTitle" [ngClass]="{'exerciseTitle-slide': slide}">
        <p   [innerHtml]="exercise.enunciado"></p>
    </div>

    <div id="contenido_sopa" class="contenido_sopa" [ngClass]="{'contenido-sopa-slide': slide}">

        <div style="width:100%;height:100%;display:flex;justify-content:center;align-items:center">
            <div class="juego" [id]="'juego' + this.numero_random"></div>
        </div>

    </div>

    
    <div [ngStyle]="controlSession ? {margin: '4em 0 0 -3em;'} :{}" class="imagen" [ngClass]="{'imagen-slide': slide}">

        <img [src]="getImgSrc()" />

    </div>
    

</div> -->