import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpParams, HttpEvent, HttpHeaderResponse, HttpResponse, HttpErrorResponse, HttpUserEvent, HttpProgressEvent } from '@angular/common/http';
import { Location } from '@angular/common';
import { environment } from 'environments/environment';

@Injectable()
export class TeacherMessageService {

  baseUrl = environment.baseApi + '/api/TeacherMessage';

  constructor(private http: HttpClient, private _location: Location) {
  }

  addTeacherMessage(message, tableText, studentIdList) {
    var params = { Message: message, TableText: tableText, StudentIdList: studentIdList };
    return this.http.post(this.baseUrl + "/add/", params);
  }

  getMessages(numMessages) {
    return this.http.get(this.baseUrl + "/get/" + numMessages);
  }

  getDetails(idMessage) {
    return this.http.get(this.baseUrl + "/getDetails/" + idMessage);
  }

  removeMessageUser(idMessage, idUser, tableText) {
    var params = { IdMessage: idMessage, IdUser: idUser, TableText: tableText };
    return this.http.post(this.baseUrl + "/removeMessageUser/", params);
  }

}
