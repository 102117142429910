
<div class="header">
    <i class="fa fa-angle-left" (click)="previous()"></i>
    <!-- <span>{{month.format("MMMM, YYYY")}}</span> --><span>{{month.format("MMMM")}} {{'COMPONENTS.CALENDAR.DATE-CONNECTOR' | translate }} {{month.format("YYYY")}}</span>
    <i class="fa fa-angle-right" (click)="next()"></i>
</div>
<div *ngIf="fullDay" class="week names">
    <span class="day">{{'DAYS.MONDAY'| translate}}</span>
    <span class="day">{{'DAYS.TUESDAY'| translate}}</span>
    <span class="day">{{'DAYS.WEDNESDAY'| translate}}</span>
    <span class="day">{{'DAYS.THURSDAY'| translate}}</span>
    <span class="day">{{'DAYS.FRIDAY'| translate}}</span>
    <span class="day">{{'DAYS.SATURDAY'| translate}}</span>
    <span class="day">{{'DAYS.SUNDAY'| translate}}</span>
</div>
<div *ngIf="!fullDay" class="week names">
    <span class="day">L</span>
    <span class="day">M</span>
    <span class="day">X</span>
    <span class="day">J</span>
    <span class="day">V</span>
    <span class="day">S</span>
    <span class="day">D</span>
</div>
<div class="week calWeek" *ngFor="let week of weeks">
    <span class="day" [ngClass]="{ 'finished' : day.finished, 'started' : day.started, 'currentSession' : day.currentSession, 'notDone' : day.notDone, 'today': day.isToday, 'disabled-month': day.disabledMonth, 'different-month': !day.isCurrentMonth, 'selected': day.date.isSame(selectedDay, 'day') }" (click)="select(day)" *ngFor="let day of week.days">{{day.number}}</span>
</div>