import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpParams, HttpEvent, HttpHeaderResponse, HttpResponse, HttpErrorResponse, HttpUserEvent, HttpProgressEvent } from '@angular/common/http';
import { environment } from 'environments/environment';
import interact from 'interactjs';
declare var $;


@Injectable({
  providedIn: 'root'
})
export class HelpService {

  baseUrl = environment.baseApi + '/api/Help';

  constructor(private http: HttpClient) { }

  getHelp(theme, idtheme) {
    return this.http.get(this.baseUrl + '/theme/' + theme +'/idtheme/' + idtheme, {});
  };

}
