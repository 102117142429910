import { Component, OnInit, Input, OnDestroy, HostListener, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { ExerciseService } from 'app/services/exercise.service';

@Component({
  selector: 'ex-selecf',
  templateUrl: './selecf.component.html',
  styleUrls: ['./selecf.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelecfComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() review
  @Input() show_errors;
  @Input() controlSession : boolean = false;
  @Input() slide : boolean = false; @Input() current;
  exercise;
  letters = [];
  finished = false;

  elementsOk;
  elementsErr;

  itemState: 'default' | 'clicked' = 'default';
  activeIndex: number = -1;

  validatorErros: boolean;

  @HostListener('window:message', ['$event'])
    exerciseListener(event){
      let data = event.data;
      switch (data) {
        case "SHOW_SOLUTION":
          this.finished = true;
          if (this.exerciseService.showingSolution) {
            setTimeout(this.ShowAnswersTimeOut.bind(this), 100);
            break;
          } else {
            setTimeout(this.showSolution.bind(this), 100);
            break;
          }
        case "SHOW_ANSWER":
          this.ShowAnswer()
          break
          case "SHOW_CUSTOM":
            this.exercise = this.exerciseService.getExerciseDefinition();
            this.init()
            break;
      }
    }
  
  constructor(private exerciseService: ExerciseService) {
    this.exercise = this.exerciseService.getExerciseDefinition();
    
  }
    ngOnDestroy() {
    }

  ngOnInit() {
    this.validatorErros = this.show_errors;

    this.init()
    if (this.review) {
      this.elementsOk = JSON.parse(this.exerciseService.getCurrentExercise().ElementsOK)
      this.elementsErr = JSON.parse(this.exerciseService.getCurrentExercise().ElementsERR)
      if (this.show_errors == false) {
        this.showSolutionB();
      } else if (this.show_errors == true) {
        this.ShowAnswerB();
      }
    }
  }

  ngAfterViewInit(): void {
    // Obtén todos los elementos con la clase 'noBreakLetterBlock'
    const elements = document.querySelectorAll('.noBreakLetterBlock');

    // Recorre los elementos
    elements.forEach((element, index) => {
      // Crea un nuevo div que actuará como contenedor
      const containerDiv = document.createElement('span');
      containerDiv.classList.add('newBlockWordArea');

      // Encuentra el elemento siguiente con la misma clase
      let nextElement = element.nextElementSibling;
      while (nextElement && !nextElement.classList.contains('noBreakLetterBlock')) {
        // Mueve el siguiente elemento al contenedor
        containerDiv.appendChild(nextElement);

        // Obtén el siguiente elemento después del elemento actual
        nextElement = element.nextElementSibling;
      }

      // Inserta el contenedor antes del elemento actual
      element.parentNode.insertBefore(containerDiv, element.nextSibling);
    });


  }

  ShowAnswersTimeOut() {
    for (var i = 0, len = this.letters.length; i < len; i++) {
      if (this.letters[i].answer == true) {
        this.letters[i].answered = true;
        this.letters[i].letter = " ";
        // console.log("answer true a"); //Leo
      }
    }
    for (var i = 0, len = this.letters.length; i < len; i++) {
      if (this.letters[i].answered == false) {
        this.letters[i].answered = null;
        this.letters[i].letter = "";
        // console.log("answered true b"); //Leo
      }
    }
    for (var i = 0, len = this.letters.length; i < len; i++) {
      this.letters[i].ok = true;
      // console.log("letters c"); //Leo
    }
  }

  init() {
      this.letters = [];
    this.exercise.muestra = this.exercise.frase.split(" ")
    let lastChar = this.exercise.libro[this.exercise.libro.length - 1];
    if (lastChar != '?' && lastChar != '!') {
      this.exercise.libro = this.exercise.libro + '.';
    }
    for (var i = 0; i < this.exercise.frase.length; i++) {
      if (this.exercise.frase.charAt(i) != ' ') {

        if ((i + 1 != this.exercise.frase.length && this.exercise.frase.charAt(i + 1) == ' ')
          || (i - 1 >= 0 && this.exercise.frase.charAt(i - 1) == ' '))
          this.letters.push({ letter: this.exercise.frase.charAt(i), answer: false, answered: null, break: false, ok: false });
        else
          this.letters.push({ letter: this.exercise.frase.charAt(i), answer: false, answered: null, break: true, ok: false });


        //if ((i + 1 != this.exercise.frase.length && this.exercise.frase.charAt(i + 1) == ' ')
        //    || (i - 1 >= 0 && this.exercise.frase.charAt(i - 1) == ' ')) {
        //    this.letters.push({ letter: this.exercise.frase.charAt(i), answer: false, answered: null, break: false });
        //} else {
        //    if ((i + 1) == this.exercise.frase.length - 1) {
        //        this.letters.push({ letter: this.exercise.frase.charAt(i) + " " + this.exercise.frase.charAt(i+1), answer: false, answered: null, break: true });
        //        break;
        //    } else {
        //        this.letters.push({ letter: this.exercise.frase.charAt(i), answer: false, answered: null, break: true });
        //    }

        //}

        if (i + 1 != this.exercise.frase.length) {
          if (this.exercise.frase.charAt(i + 1) != ' ')
            this.letters.push({ letter: "", answer: false, answered: null, break: true, ok: false });
          else
            this.letters.push({ letter: "", answer: false, answered: null, break: false, ok: false });
        }
      };
    }
    var counter = -1;
    for (var i = 0; i < this.exercise.muestra.length; i++) {
      counter += this.exercise.muestra[i].length * 2;
      if (counter < this.letters.length)
        this.letters[counter].answer = true;
    }
  }

  validate(index) {
    if (index < 0) return;
    if (this.letters[index].letter != "" || this.finished) return;
    for (var i = 0, len = this.letters.length; i < len; i++) {
      if (this.letters[i].answered == false) {
        this.letters[i].answered = null;
        this.letters[i].letter = "";
      }
    }
    if (this.letters[index].answer) {
      var ok = null;
      for (var i = index - 1; i >= 0; i--) {
        if (this.letters[i].answer == true) {
          if (this.letters[i].answered == true) {
            ok = i;
          }
          break;
        }
        if (i == 0) {
          ok = i;
        }
      }
      if (ok != null) {
        for (let i = index - 1; i >= ok; i--) {
          this.letters[i].ok = true;
        }
      }
      ok = null;
      for (let i = index + 1; i < this.letters.length; i++) {
        if (this.letters[i].answer == true) {
          if (this.letters[i].answered == true) {
            ok = i;
          }
          break;
        }
        if (i == this.letters.length - 1) {
          ok = i;
        }
      }
      if (ok != null) {
        for (let i = index + 1; i <= ok; i++) {
          this.letters[i].ok = true;
        }
      }
      this.letters[index].answered = true;
      this.letters[index].ok = true;
      // this.letters[index].letter = " ";
      this.exerciseService.answer(index, true, this.exercise.muestra.length - 1);
    } else {
      this.letters[index].answered = false;
      // this.letters[index].letter = "-";
      // this.$emit("error");
      this.exerciseService.answer(index, false, this.exercise.muestra.length - 1);
    }
    var done = true;
    for (var i = 0; i < this.letters.length; i++) {
      if (this.letters[i].ok == false && this.letters[i].letter != ' ') {
        done = false;
      }
    }
    if (done) {
      // this.$emit("timer-stop");
      this.finished = true;
      // this.$emit('exercise-solved');
    }
  }

  toggleState(index: number): void {
    // Desactivar el estado en el índice anterior
    if (this.activeIndex !== -1) {
      this.itemState = 'default';
    }

    // Actualizar el índice activo
    this.activeIndex = index;

    // Cambiar el estado en el índice actual
    this.itemState = this.itemState === 'default' ? 'clicked' : 'default';

    // Agregar aquí la lógica de la función validate() para el índice actual
    if (this.itemState === 'clicked') {
      this.validate(index);
    }
  }

  showSolution() {
    if(this.validatorErros == true ){
      const elements = document.querySelectorAll('.reviewBoxError');
      elements.forEach((element) => {
        element.classList.add('hide');
      });
      // console.log("showSolution + validator errors b " + this.validatorErros); //Leo
    }else{
      // if(this.show_errors == null){
      //   for (var i = 0, len = this.letters.length; i < len; i++) {
      //     if (this.letters[i].answer == true) {
      //       this.letters[i].answered = true;
      //       this.letters[i].letter = " ";
      //     }
      //   }
      //   console.log("null 1"); //Leo
      // }
      // if(this.show_errors == false){
        
      //   console.log("false 1") //Leo
      // }
      for (var i = 0, len = this.letters.length; i < len; i++) {
        if (this.letters[i].answer == true) {
          this.letters[i].answered = true;
          this.letters[i].letter = " ";
        }
      }
    }
  }

  showSolutionB() {
    for (var i = 0, len = this.letters.length; i < len; i++) {
      if (this.letters[i].answer == true) {
        this.letters[i].answered = true;
        this.letters[i].letter = " ";
        // console.log("showSolutionB a"); //Leo
      }
    }
    for (var i = 0, len = this.letters.length; i < len; i++) {
      if (this.letters[i].answered == false) {
        this.letters[i].answered = null;
        this.letters[i].letter = "";
        // console.log("showSolutionB b"); //Leo
      }
    }
  }
  
  ShowAnswer() {
    const elements = document.querySelectorAll('.reviewBoxError');
    elements.forEach((element) => {
      element.classList.remove('hide');
    });
    // console.log("ShowAnswer"); //Leo
  }

  ShowAnswerB() {
    for (var p = 0; p < this.elementsErr.length; p++) {
      this.letters[this.elementsErr[p]].answered = false;
      this.letters[this.elementsErr[p]].letter = "-";
    }
    for (var p = 0; p < this.elementsOk.length; p++) {
      this.letters[this.elementsOk[p]].answered = true;
      this.letters[this.elementsOk[p]].letter = " ";
    }
  }

  initInteract() {
    this.exerciseService.initInteract({
      onmove: (event) => {

      },
      drop: (event) => {

      }
    })
  }

}
