<div class="walinwa-box-with-tabs" fxNgClass.gt-sm="scroll_vertical">
    <div class="walinwa-tabs walinwa-tabs-top">
        <div *ngIf="(schoolService.school.Origin == 'ESPECIAL' || schoolService.school.Origin == 'WALI')" [ngClass]="{ 'selected': state == 'report'}" (click)="goReport()" class="walinwa-tab-gray">
            {{'SCHOOL.TEACHER.TRACKING.REPORT-TITLE' | translate}}</div>
        <div *ngIf="(schoolService.school.Origin == 'ESPECIAL' || schoolService.school.Origin == 'WALI')" [ngClass]="{ 'selected': state == 'test'}" (click)="GoTestResult()"
            class="walinwa-tab-gray">{{'SCHOOL.TEACHER.TRACKING.TEST-TITLE' | translate}}</div>
    </div>
    <div class="walinwa-box">
        <router-outlet></router-outlet>
    </div>
</div>