export interface ILogMessage {
    message: string;
    level: LogEventLevel
    MustLog: boolean;
}

export enum LogEventLevel
{
    Verbose,
    Debug,
    Information,
    Warning,
    Error,
    Fatal
}