import { Theme } from 'app/core/shared/state/models/theme.model';
import { ControlSessionExercise } from './../../../../../core/shared/state/models/control-sesson-exercise.model';
import { ControlSessionDTO } from './../../../../../core/shared/state/models/control-session-dto.model';
import { ChangeDetectorRef, ElementRef, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { ControlSessionService } from '../../../../../services/control-session.service';
import { CustomSession } from 'app/core/shared/state';
import { ControlSession } from '../../../../../core/shared/state/models/control-session.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CustomizedSessionService } from 'app/services/customized-session.service';
import { UserService } from 'app/services/user.service';
import * as moment from 'moment';
import { AuthService } from 'app/services/auth.service';
import { NavigationService } from 'app/services/navigation.service';
import { CourseService } from 'app/services/course.service';
import { AbstractControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ExerciseService } from 'app/services/exercise.service';
import { TestExerciseService } from 'app/services/test-exercise.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import * as _ from 'lodash';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { Test2Service } from 'app/services/test2.service';
import { ExamDTO } from 'app/core/shared/state/models/ExamDTO.model';
import { ClassService } from 'app/services/class.service';
import { SharedService } from 'app/core/shared/shared.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: "app-control-session",
    templateUrl: "./control-session.component.html",
    styleUrls: ["./control-session.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ControlSessionComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    infoPopupHelp: boolean = true;

    @ViewChild('aForm') aForm: ElementRef;
    @ViewChildren('slide') slides: QueryList<ElementRef>;
    classes
    controlSessions: ControlSession[] = []
    controlSession: ControlSession
    classes_controlSessions
    loading
    calendar
    courses
    form: UntypedFormGroup
    submitted
    themes = null;
    clasesSeleccionadas = [];
    messages = [];
    exercises;
    current_exercise;
    dragging_element;
    selectedDay;
    customizedSessions: CustomSession[];
    showMessage;
    nameExists = false;
    isRequired = false;
    editingControlSessionName = false;
    editing = false;
    loadingExercises: boolean = false;
    themesFP = null;
    themesFS = null;
    themesGR = null;
    themesFT = null;
    showControlList = false;
    cancelControl = false;
    date
    classes_exams = []
    exerciseListPaginated: any[] = [];
    itemsPerLoad = 15;
    LastLoad = 0;

    constructor(
        private authService: AuthService,
        private controlSessionService: ControlSessionService,
        private customizedSessionService: CustomizedSessionService,
        public navigationService: NavigationService,
        private courseService: CourseService,
        private formBuilder: UntypedFormBuilder,
        private exerciseService: ExerciseService,
        private testExerciseService: TestExerciseService,
        private userService: UserService,
        private changeDetectorRef: ChangeDetectorRef,
        private test2Service: Test2Service,
        private classService: ClassService,
        public _sharedService: SharedService,
        private _translateService: TranslateService
    ) {
        this._unsubscribeAll = new Subject();
        this.classes = []
        this.controlSessions = []
        this.classes_controlSessions = []
        this.navigationService.setStateControl('classes-controlSessions');
        this.loading = true
        this.customizedSessions = [];
        this.showMessage = false;
        this.selectedDay = moment();

    }

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit() {
        if (!this.authService.check()) return
        this.loading = true;
        this.courseService.Courses.pipe(takeUntil(this._unsubscribeAll)).subscribe(courses => {
            this.courses = courses;
        })
        this.getThemes();
        this.getExams();
        this.getControlSessions()
        this.getClasses()
    }
    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(
            this.exercises,
            event.previousIndex,
            event.currentIndex
        );
    }

    getClasses() {
        this.classes = this.classService.GetClasses();
    }

    getThemes() {
        if (this.customizedSessionService.themes.length > 0) {
            this.successLoadthemes(this.customizedSessionService.themes)
        }
        else {
            this.customizedSessionService.getThemes()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((response: Theme[]) => {
                    this.successLoadthemes(response)
                }, () => {
                    this.navigationService.exit()
                })
        }
    }

    successLoadthemes(response) {
        if (!response) return this.navigationService.exit();
        this.themes = response;
        this.themesFP = this.themes.filter((x) => x.Type == "FP");
        this.themesFS = this.themes.filter((x) => x.Type == "FS");
        this.themesFT = this.themes.filter((x) => x.Type == "FT");
        this.themesGR = this.themes.filter((x) => x.Type == "GR");
        this.themesFP.push({
            Theme: "ALL",
            Type: "FP",
            Description: "Todos",
        });
    }
    objtostr(obj) {
        return JSON.stringify(obj);
    }

    getControlSessions(){
        this.classes_controlSessions = []
        this.controlSessionService.getByUser(this.authService.currentUser.Id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: ControlSessionDTO[]) => {
                this.classes_controlSessions = result.filter(x => x.IdClass !== 0);
                this.controlSessions = this.controlSessionService.MapFromDTOToControl(result);
                this.classes_controlSessions.forEach(AssignedControl => {
                    AssignedControl.formatted_date = new Date(AssignedControl.Date).toLocaleDateString("es-ES")
                    this.customizedSessions.push(AssignedControl)
                    let controlSessions = this.controlSessions.find(e => e.IdControl == AssignedControl.IdControl)
                    if (controlSessions){
                        if(!controlSessions.count_assigned){
                            controlSessions.count_assigned = 0
                        }
                        controlSessions.count_assigned++
                    }
                    else {
                        this.controlSessions.push(AssignedControl)
                    }
                });
                this.loading = false;
            }, () => {
                this.loading = false;
            })
    }

    getExams() {
        this.classes_exams = []
        this.test2Service.getByUser(this.authService.currentUser.Id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: ExamDTO[]) => {
                this.classes_exams = result.filter(x => x.IdClass !== 0);
                this.classes_exams.forEach(AssignedExam => {
                    AssignedExam.formatted_date = new Date(AssignedExam.Date).toLocaleDateString("es-ES")
                    AssignedExam.editingExamDate = false
                    this.customizedSessions.push(AssignedExam)
                });
                this.loading = false;
            }, () => {
                this.loading = false;
            })
    }

    get sortData(){
        return this.classes_controlSessions.sort((a, b) => {
            return <any>new Date(b.Date) - <any>new Date(a.Date);
          });
        }

    deleteControlSession(controlSession: ControlSession) {
        if (
            controlSession.count_assigned > 0 ||
            controlSession.CustomSessions.length > 0
        ) {
            this.navigationService.error(
                [
                    this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.DELETE-SESSION-TITLE'),
                    this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.DELETE-SESSION-MESSAGE'),
                ],
                (ko) => {}
            );
            return;
        }

        this.navigationService.warn([this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.DELETE-SESSION-TITLE'), this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.DELETE-SESSION-CONFIRMATION')], ok => {
            this.controlSessionService.remove(controlSession)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
                this.getControlSessions()
            })
        }, ko => {

        })
    }

    deleteClassControlSession(class_controlSession) {
        this.navigationService.warn([this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.DELETE-CLASS-SESSION-TITLE'), this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.DELETE-CLASS-SESSION-CONFIRMATION')], ok => {
            this.loading = true
            let list = [{
                IdClass: class_controlSession.IdClass,
                Date: class_controlSession.Date
            }];
            this.customizedSessionService.add(list)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                result => {
                    this.navigationService.setStateControl('classes-controlSessions');
                    this.getControlSessions()
                })
        }, ko => {

        })
    }

    back() {}

    addClassControlSession() {
        this.showControlList = false;
        this.navigationService.setStateControl("add-class-controlSession");
        this.navigationService.enableBackButtonEControl(
            true,
            "add-class-controlSession"
        );
        this.navigationService.add_class_step = 1;
        let tomorrow = moment().add(1, 'days')
        this.calendar = {
            current_user: this.authService.currentUser,
            selected_day: tomorrow,
            current_month: tomorrow.month() + 1
        }
    }

    editControlSessions() {
        this.showControlList = true;
        this.navigationService.setStateControl("add-class-controlSession");
        this.navigationService.enableBackButtonEControl(
            true,
            "add-class-controlSession"
        );
        this.navigationService.add_class_step = -1;
    }

    addControlSession() {
        this.submitted = false;
        let Level = null;
        if (this.classes.some((x) => x.selected)) {
            Level = this.classes
                .sort((x) => x.Level - x.Level)
                .find((y) => y.selected).Level;
        }
        this.form = this.formBuilder.group({
            Name: ["Prueba", [Validators.required, this.nameExistsValidator.bind(this)]],
            Level: [
                Level ? Level : this.courses[0].Level,
                [Validators.required],
            ],
            FP: [""],
            FS: [""],
            FT: [""],
            GR: [""],
        });
        this.form.markAllAsTouched();
        this.navigationService.setStateControl("add-controlSession");
    }
    nameExistsValidator(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return null;
        }

        const nameExists = this.controlSessions.some(session => session.Name === control.value);
        return nameExists ? { nameExists: true } : null;
    }
    startChangingControlSessionName(controlSession: ControlSession) {
        controlSession.editingControlSessionName = true;
        setTimeout(() => {
            document.getElementById("change-name").focus();
        }, 100);
    }
    changeControlSessionName(controlSession: ControlSession) {
        this.nameExists = false;
        this.isRequired = false;
        var el = document.getElementById(
            "change-name"
        ) as HTMLInputElement | null;
        el.value = el.value.trim();
        if (el.value == controlSession.Name) {
            controlSession.editingControlSessionName = false;
        }
        if (el.value == "") {
            this.isRequired = true;
            return;
        }
        this.controlSessions.forEach((sesion) => {
            if (
                controlSession.IdControl != sesion.IdControl &&
                sesion.Name == el.value
            ) {
                this.nameExists = true;
            }
        });
        if (this.nameExists) {
            return;
        }
        this.controlSessionService.updateControlSessionName(controlSession.IdControl, el.value)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
            result => {
                this.classes_controlSessions = []
                this.getControlSessions();
            }, error => {
            }
        )
    }
    editSession(controlSession: ControlSession) {
        this.editing = true;
        this.editControlSessionExercises(controlSession);
    }
    editControlSessionExercises(controlSession: ControlSession = null, editingName = false) {
        this.nameExists = false;
        this.controlSession = null;

        if (editingName) {
            this.controlSessions.forEach((sesion) => {
                if (sesion.Name == this.form.value.Name) {
                    this.nameExists = true;
                }
            });
            if (this.nameExists) {
                return;
            }
        }
        if (controlSession) {
            this.controlSessionService.GetControlExercises(controlSession.IdControl)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: ControlSessionExercise[]) => {
                this.setExercises(controlSession, result)
                this.form = this.formBuilder.group({
                    Name: [controlSession.Name, [Validators.required, this.nameExistsValidator.bind(this)]],
                    Level: [this.courses[0].Level, [Validators.required]],
                    FP: [''],
                    FS: [''],
                    FT: [''],
                    GR: [''],
                });
                this.form.markAllAsTouched();
                this.navigationService.setStateControl("edit-controlSession-exercises");
                this.LastLoad = 0;
                this.exerciseListPaginated = []
                this.cargarMasItems();
                setTimeout(() => {
                    let scrollable = document.getElementById("scroll");
                    scrollable.addEventListener("scroll", (event) => {
                        this.onScroll(event);
                    });
                }, 1000);
                
                this.current_exercise = 0
                
                for (let i = 0; i < this.exercises.length; i++) this.getExercise(i)
                this.selectSlide(this.current_exercise)
                this.initDragging()
                return;
            }, () => {
                return;
            })
            return;
        }
        this.submitted = true
        if (this.form && this.form.invalid) return
        this.loading = true;
        this.controlSessionService.insert(this.authService.currentUser.Id, this.form.value.Name)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (controlSession: ControlSession) => {
                    if (!this.classes.find(x => x.selected)) {
                        this.saveWithoutDate(controlSession.IdControl, this.form.value.Level)
                    }
                    else {
                        this.save2(controlSession.IdControl, this.form.value.Level)
                    }
                    //ya con la sesion de control creada y con su custom sesion asignada se deberian agregar los ejercicios

                    //luego de agregar los ejercicios, se cambia el estado y se abre la vista para editar los ejercicios
                }
            )
        return;

    }
    setExercises(controlSession: ControlSession, exercises: ControlSessionExercise[]){
        this.controlSession = controlSession
        exercises.forEach(ejercicio => {
            ejercicio.ElementsOK = "[]"
            ejercicio.ElementsERR = "[]"
        });
        this.exercises = exercises;
        this.controlSession.Exercises = exercises;
    }
    initDragging() {
        setTimeout(() => {
            this.testExerciseService.initDragging({
                ondragstart: (event, element) => {
                    this.dragging_element = element;
                },
                ondrop: (event, element) => {
                    let slide_from_index = parseInt(
                        this.dragging_element.getAttribute("index")
                    );
                    let slide_to_index = parseInt(
                        element.getAttribute("index")
                    );

                    let exercises = [];
                    for (let i = 0; i < this.exercises.length; i++) {
                        if (i == slide_from_index) {
                            exercises.push(this.exercises[slide_to_index]);
                        } else if (i == slide_to_index) {
                            exercises.push(this.exercises[slide_from_index]);
                        } else {
                            exercises.push(this.exercises[i]);
                        }
                    }
                    this.exercises = exercises;

                    this.dragging_element = null;
                },
                ondragend: () => {
                    this.dragging_element = null;
                },
            });
        }, 1000);
    }

    finishEditExercises() {
        if (this.exercises.length == 0) {
            this.navigationService.error(
                [this._translateService.instant('ERROR'), this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.FINISH-EDIT-EXERCISES-MESSAGE')],
                () => {}
            );
            return;
        }
        if (this.controlSession) {
            this.loading = true;
            this.controlSessionService.removeExercisesFromControlSession(this.controlSession.IdControl)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
                () => {
                    this.saveExercise(this.controlSession.IdControl, this.exercises)
                },
                err => {
                    this.loading = false;
                    console.log(err);
                }
            )
            return
        }
    }

    saveExercise(idControl, exercises) {
        this.controlSessionService.insertExercise(idControl, exercises)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
            () => {
                this.save(idControl)
            },
            err => {
                this.loading = false;
                console.log(err);
            }
        )
    }

    selectSlide(index, clicked = false) {
        this.current_exercise = index;
        this.getExercise(index, clicked);
    }

    before(index, clicked = false) {
        if (index < 0) return;
        this.current_exercise = index;
        this.getExercise(index, clicked);
        let ele2 = document.getElementById("scroll");
        const x = ele2.scrollHeight / this.exercises.length;
        ele2.scrollTop = x * index;
    }

    next(index, clicked = false) {
        if (index >= this.exercises.length) return;
        this.current_exercise = index;
        this.getExercise(index, clicked);
        let ele2 = document.getElementById("scroll");
        const x = ele2.scrollHeight / this.exercises.length;
        ele2.scrollTop = x * index;
    }

    getExercise(index, clicked = false) {
        this.loading = true;
        if (clicked) {
            this.changeDetectorRef.detectChanges();
        }
        let exercise = this.exercises[index];
        if (exercise?.empty) {
            return;
        }
        if (!exercise.ExerciseJson) this.reloadModel(index);
        if (exercise.ExerciseJson) {
            this.loading = false;
            this.loadingExercises = false;
        }
    }

    reloadModel(index) {
        this.exerciseService.getNewExercise(this.exercises[index], this.form ? this.form.value.Level : this.courses[0].Level)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
            this.exercises[index] = result
            this.loading = false
            this.loadingExercises = false;
        }, error => {
            this.loading = false
            this.loadingExercises = false;
        });
    }

    getNewExercise(index, replace = false, add_new = true) {
        this.loading = true;
        this.current_exercise = index;
        if (!replace) {
            index--;
        }
        this.exerciseService.getNewExercise(this.exercises[index], this.form ? this.form.value.Level : this.courses[0].Level)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
            if (add_new) {
                this.exercises.splice(this.current_exercise, 0, result);
            } else {
                this.exercises[this.current_exercise] = result
            }
            this.reloadItems();
            this.loading = false
            this.loadingExercises = false;
            this.initDragging()
        }, error => {
            this.loading = false
            this.loadingExercises = false;
        });
    }

    selectedDayChanged(date) {
        this.selectedDay = date;
        this.calendar.current_month = this.calendar.selected_day.month()
        if (this.calendar.current_month != this.selectedDay.month() + 1) {
            this.calendar.selected_day = date
          }
    }

    continue() {
        if (this.navigationService.add_class_step < 3) {
            this.navigationService.add_class_step++;
            if (this.navigationService.add_class_step == 2) {
                this.showMessage = false
                this.customizedSessions.forEach(sesion => {
                    (sesion.IdControl != null || sesion.IdExam != null) && moment(sesion?.Date).format("DD/MM/YYYY").toString() == moment(this.selectedDay).format("DD/MM/YYYY").toString() ? this.clasesSeleccionadas.push(sesion) : this.showMessage = false;
                });
                this.clasesSeleccionadas = this.clasesSeleccionadas.filter(
                    (item, index) => {
                        return this.clasesSeleccionadas.indexOf(item) === index;
                    }
                );
            }
        } else {
            this.save();
        }
    }

    save(IdControl = null) {
        this.loading = true;
        let list = [];
        for (let i = 0; i < this.classes.length; i++) {
            if (this.classes[i].selected) {
                list.push({
                    IdClass: this.classes[i].IdClass,
                    Date: this.selectedDay,
                    IdControl: IdControl ? IdControl : this.controlSessions.find(e => e.selected).IdControl,
                    FP: this.form? this.form.value.FP : "",
                    FS: this.form? this.form.value.FS : "",
                    FT: this.form? this.form.value.FT : "",
                    GR: this.form? this.form.value.GR : ""
                });
            }
        }
        
        this.customizedSessionService.add(list)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
            result => {
                if(!this.classes.find(x => x.selected)){
                    this.editControlSessions()
                }
                else {
                    this.navigationService.setStateControl("classes-controlSessions");
                }
                for (let i = 0; i < this.classes.length; i++) this.classes[i].selected = false
                for (let i = 0; i < this.controlSessions.length; i++) this.controlSessions[i].selected = false
                this.editing = false;
                this.getControlSessions()
            })
    }

    save2(IdControl, level) {
        this.loading = true;
        this.loadingExercises = true;
        let list = [];
        var obj = {
            FP: this.form ? this.form.value.FP : "",
            FS: this.form ? this.form.value.FS : "",
            FT: this.form ? this.form.value.FT : "",
            GR: this.form ? this.form.value.GR : ""
        }
        for (let i = 0; i < this.classes.length; i++) {
            if (this.classes[i].selected) {
                list.push({
                    IdClass: this.classes[i].IdClass,
                    Date: this.selectedDay,
                    IdControl: IdControl,
                    FP: this.form ? this.form.value.FP : "",
                    FS: this.form ? this.form.value.FS : "",
                    FT: this.form ? this.form.value.FT : "",
                    GR: this.form ? this.form.value.GR : "",
                });
            }
        }
        for (let i = 0; i < this.controlSessions.length; i++) this.controlSessions[i].selected = false
        this.customizedSessionService.add(list)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                result => {
                    this.controlSessionService.createExercises(IdControl, level, obj)
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(
                            (controlSession: ControlSession) => {
                                this.controlSessionService.GetControlExercises(controlSession.IdControl)
                                .pipe(takeUntil(this._unsubscribeAll))
                                .subscribe((exercises: ControlSessionExercise[]) => {
                                    this.setExercises(controlSession, exercises)
                                    this.editControlSessionExercises(controlSession);
                                    this.loading = false;
                                    this.loadingExercises = false;
                                    this.editing = false;
                                })
                            });
                })
    }
    saveWithoutDate(IdControl, level) {
        this.loading = true
        this.loadingExercises = true;
        var obj = {
            FP: this.form ? this.form.value.FP : "",
            FS: this.form ? this.form.value.FS : "",
            FT: this.form ? this.form.value.FT : "",
            GR: this.form ? this.form.value.GR : ""
        }
        this.controlSessionService.createExercises(IdControl, level, obj)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (controlSession: ControlSession) => {
                    this.editing = false;
                    this.editControlSessionExercises(controlSession);
                    this.loading = false;
                    this.loadingExercises = false;
                });
    }
    selectClass(cls) {
        if (!cls.selected) cls.selected = false;
        cls.selected = !cls.selected;
        this.checkControlSession();
    }

    checkControlSession() {
        this.messages = [];
        this.showMessage = false;
        this.classes.forEach((cls) => {
            if (
                cls.selected &&
                this.clasesSeleccionadas.some((x) => x.IdClass == cls.IdClass)
            ) {
                if (
                    this.clasesSeleccionadas.find(
                        (x) => x.IdClass == cls.IdClass
                    ).IdControl != null
                ) {
                    var message = this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.ALREADY-ASSIGNED-SESSION-MESSAGE', {className: cls.Name})
                } else {
                    var message = this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.ALREADY-ASSIGNED-EXAM-MESSAGE', {className: cls.Name})
                }
                this.messages.push(message);
                this.showMessage = true;
            }
        });
    }

    selectControlSession(controlSession: ControlSession) {
        for (let i = 0; i < this.controlSessions.length; i++)
            this.controlSessions[i].selected = false;
        controlSession.selected = true;
    }

    disableWizard() {
        if (this.navigationService.add_class_step == 1) {
            return (!this.selectedDay ||
            moment(this.selectedDay).isSameOrBefore(moment(),"day"))
        } else if (this.navigationService.add_class_step == 2) {
            return this.classes.filter((c) => c.selected).length == 0;
        } else if (this.navigationService.add_class_step == 3) {
            return this.controlSessions.filter((e) => e.selected).length == 0;
        }
    }

    removeExercise() {
        if (this.exercises.length <= 1) return;
        this.exercises.splice(this.current_exercise, 1);
        if (
            this.current_exercise > 0 &&
            this.current_exercise == this.exercises.length
        ) {
            this.current_exercise--;
        }
        this.reloadItems()
        this.selectSlide(this.current_exercise, true);
    }

    replace(text: string): string {
        var find = "</i>";
        var re = new RegExp(find, "g");
        let res = text.replace(/<i>/g, "&nbsp");
        return res.replace(re, "&nbsp");
    }
    cancel() {
        this.cancelControl = true;
        let message = [];
        if (!this.editing) {
            message[0] = this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.CANCEL-CREATION-TITLE');
            message[1] =
            this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.CANCEL-CREATION-MESSAGE');
        } else {
            message[0] = this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.CANCEL-EDIT-TITLE');
            message[1] = this._translateService.instant('SCHOOL.TEACHER.COMPLEMENTS.CONTROL-SESSION.CANCEL-EDIT-MESSAGE');
        }
        this.navigationService.warn(
            message,
            (ok) => {
                this.cancelYes();
            },
            (ko) => {
                this.cancelNo();
            }
        );
    }
    cancelNo() {
        this.cancelControl = false;
    }
    cancelYes() {
        if(!this.editing){
            this.controlSessionService.remove(this.controlSession)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
                this.classes_controlSessions = []
                this.editControlSessions();
                this.getControlSessions();
            })
            
            this.editing = false;
        }else {
            this.getControlSessions()
            this.editControlSessions()
        }
    }
    ShowHideHelpPopup() {
        this.infoPopupHelp = !this.infoPopupHelp;
    }

    OpenPanelSlidesMobile(){
        const panel = document.getElementById("scroll");
        const panelShadow = document.getElementById("shadow_slides_close");

        panel.classList.add('active');
        panelShadow.classList.add('active');
    }

    ClosePanelSlidesMobile(){
        const panel = document.getElementById("scroll");
        const panelShadow = document.getElementById("shadow_slides_close");

        panel.classList.remove('active');
        panelShadow.classList.remove('active');
    }
    cargarMasItems() {
        const nuevosItems = this.exercises.slice(this.LastLoad, this.LastLoad + this.itemsPerLoad);
        this.exerciseListPaginated = [...this.exerciseListPaginated, ...nuevosItems];
        this.LastLoad += this.itemsPerLoad;
    }
    reloadItems() {
        const nuevosItems = this.exercises.slice(0, this.exerciseListPaginated.length + 1);
        this.exerciseListPaginated = nuevosItems;
    }

    onScroll(event: any) {
        if (this.navigationService.stateControl != 'edit-controlSession-exercises') {
            return;
        }
        let scrollable = document.getElementById("scroll");
        const pos = scrollable.scrollTop + document.documentElement.clientHeight;
        let max = scrollable.scrollHeight - 250;
        if (pos >= max) {
            this.cargarMasItems();
        }
    }
}
