import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ExerciseService } from 'app/services/exercise.service';

@Component({
  selector: 'ex-marcar',
  templateUrl: './marcar.component.html',
  styleUrls: ['./marcar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MarcarComponent implements OnInit, OnDestroy {
    @Input() review;
    @Input() show_errors;
    @Input() controlSession : boolean = false;
    @Input() slide: boolean = false;
    @Input() current;
    elementsOk = [];
    elementsErr = [];
    exercise;
    numErr = 0
    numOk = 0
    finished = false
    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case "SHOW_SOLUTION":
                if (this.exerciseService.showingSolution) {
                    setTimeout(this.ShowAnswersTimeOut.bind(this), 100);
                    break;
                } else {
                    setTimeout(this.ShowSolution.bind(this), 100);
                    break;
                }
            case "SHOW_ANSWER":
                setTimeout(this.ShowAnswer.bind(this), 100);
                break;
            case "SHOW_CUSTOM":
                setTimeout(this.ShowAnswer.bind(this), 100);
                break;
        }
    }
    
    constructor(public exerciseService: ExerciseService) {
        this.initExercise();
    }
    ngOnDestroy(){
    }
    
    ngOnInit(): void {
        if (this.review) {
            this.elementsOk = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsOK
            );
            this.elementsErr = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsERR
            );
            if (this.show_errors == false) {
                this.ShowSolution();
              } else if (this.show_errors == true || this.show_errors == null) {
                this.ShowAnswer();
              }
        } else {
        }
    }
    initExercise(){
        this.exercise = this.exerciseService.getExerciseDefinition();
        for (let i = 0; i < this.exercise.palabras.length; i++) this.exercise.palabras[i].palabraAux = this.exercise.palabras[i].palabra
        
    }
    ShowAnswersTimeOut() {
        for (var j = 0; j < this.exercise.palabras.length; j++) {
            if (this.exercise.palabras[j].opcion == true && this.exercise.palabras[j].answered != true) {
                this.exercise.palabras[j].answered = false;
                this.exercise.palabras[j].notAnserwed = true;
            }
            if (this.exercise.palabras[j].opcion == false) {
                this.exercise.palabras[j].answered = null;
            }
            if (this.exercise.palabras[j].rae)
                this.exercise.palabras[j].palabraAux = this.exercise.palabras[j].rae;
        }
    }

    ShowSolution() {
        for (var j = 0; j < this.exercise.palabras.length; j++) {
            if (this.exercise.palabras[j].rae)
                this.exercise.palabras[j].palabraAux = this.exercise.palabras[j].rae;
            else
                this.exercise.palabras[j].palabraAux = this.exercise.palabras[j].palabra;
            if (this.exercise.palabras[j].opcion == true) {
                this.exercise.palabras[j].answered = true;
                this.exercise.palabras[j].notAnserwed = false;
            }
            if (this.exercise.palabras[j].opcion == false) {
                this.exercise.palabras[j].answered = null;
                this.exercise.palabras[j].notAnserwed = false;
            }
        }
    }

    ShowAnswer() {
        for (var i = 0; i < this.exercise.palabras.length; i++) {
            if (this.exercise.palabras[i].opcion == true)
                this.exercise.palabras[i].notAnserwed = true;
        }

        for (var i = 0; i < this.exercise.palabras.length; i++) {
            if (this.exercise.palabras[i].rae)
                this.exercise.palabras[i].palabraAux = this.exercise.palabras[i].rae;
            else
                this.exercise.palabras[i].palabraAux = this.exercise.palabras[i].palabra;
            this.exercise.palabras[i].answered = null;
            for (var j = 0; j < this.elementsErr.length; j++) {
                this.exercise.palabras[this.elementsErr[j]].answered = false;
                this.exercise.palabras[this.elementsErr[j]].notAnserwed = false;
            }
        }
        for (var i = 0; i < this.exercise.palabras.length; i++) {
            for (var j = 0; j < this.elementsOk.length; j++) {
                if (this.exercise.palabras[this.elementsOk[j]].answered != false) {
                    this.exercise.palabras[this.elementsOk[j]].answered = true;
                    this.exercise.palabras[this.elementsOk[j]].notAnserwed = false;
                }
            }
        }
    }

    validateClick(index) {

        if (this.finished || this.exercise.palabras[index].answered)
            return;

        var result = true;
        var numTotal = 0, numTotalOk = 0;
        for (var i = 0, len = this.exercise.palabras.length; i < len; i++) {
            numTotal++;
            if (this.exercise.palabras[i].opcion == true)
                numTotalOk++;
            if (this.exercise.palabras[i].answered == false) {
                this.exercise.palabras[i].answered = null;
            }
        }

        if (this.exercise.palabras[index].opcion == true) {
            this.exercise.palabras[index].answered = true;
            if (this.exercise.palabras[index].rae)
                this.exercise.palabras[index].palabraAux = this.exercise.palabras[index].rae;
            this.exerciseService.answer(index, true, 100);
            this.numOk++;
        } else {
            this.exercise.palabras[index].answered = false;
            // this.$emit("error");
            this.exerciseService.answer(index, false, 100);
            this.numErr++;
        }

        if (this.exercise.operador == '1r') {
            if (this.exercise.palabras[index].opcion == true) {
                result = true;
                for (var i = 0, len = this.exercise.palabras.length; i < len; i++) {
                    if (this.exercise.palabras[i].opcion == true)
                        this.exercise.palabras[i].answered = true;
                }
            }
            else
                result = false;
        }
        else {
            for (var i = 0, len = this.exercise.palabras.length; i < len; i++) {
                if (this.exercise.palabras[i].opcion == false && this.exercise.palabras[i].answered) {
                    result = false;
                }
                if (this.exercise.palabras[i].opcion == true && this.exercise.palabras[i].answered != true) {
                    result = false;
                }
            }
        }
        if (result) {
            // this.$emit("timer-stop");
            this.exerciseService.endExercise()
            this.finished = true;
            if (numTotalOk > 2 && this.numErr >= (numTotal * 0.4))
                window.postMessage("EXERCISE_SOLVED_MSG_2", "*");
            else if ((numTotalOk == 1 || (numTotalOk == 2 && numTotal > 5)) && this.numErr >= (numTotal * 0.5))
                window.postMessage("EXERCISE_SOLVED_MSG_1", "*");
        }
    }

}
