<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="pregunta spe_gap" fxLayout="column" fxLayoutAlign="stretch"[ngStyle]="{'pointer-events': review ? 'none;' : ''}"> 

    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>
    
    <div fxFlex="17rem" [ngClass]="{'exerciseQuestionBlue': truefalse == true, 'exercise-question-slide': slide,'exerciseQuestion':!slide}">
        <div fxFlex="" class="phraseBox" [ngClass]="{'phraseBoxBlue': truefalse == true,'phraseBoxNoBlue': truefalse == false}">
            <span [innerHtml]="exercise.pregunta" [ngClass]="{'phraseContentBlue': truefalse == true}"></span>
        </div>
    </div>

    <div fxFlex="" class="optionsContainer" id="space-for-buttons" >
        <div *ngFor="let option of exercise.opciones; let i = index" class="wordBoxContainer" [hidden]="truefalse == true">
            <div class="wordBox" (click)="validateOption(i)" [ngClass]="{ wordBoxOk:  option.answered == true, wordBoxError:  option.answered == false}">
                <span id="option{{ i }}" class="exerciseOption">{{ option.valor }}</span>
            </div>
        </div>
        <div class="handsContainer" [hidden]="truefalse == false">
            <div class="wordBoxContainerTF ">
                <div [style.background-image]="getImgUrl()" class="trueFalseOption trueOption" (click)="validateOption(0)" [ngClass]="{ 'trueOptionOk':  exercise.opciones[0].answered == true, 'trueOptionErr':  exercise.opciones[0].answered == false}">
                </div>
            </div>
            <div class="wordBoxContainerTF">
                <div [style.background-image]="getImgUrl(true)" class="trueFalseOption falseOption" (click)="validateOption(1)" [ngClass]="{ 'falseOptionOk':  exercise.opciones[1].answered == true, 'falseOptionErr':  exercise.opciones[1].answered == false}">
                </div>
            </div>
        </div>
    </div>

</div>
