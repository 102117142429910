import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { DataStore } from './data.store';
import { DataQuery } from './data.query';
import { Class } from './models/class.model';
import { User } from './models/user.model';
import { School } from './models/school.model';
import { EntityStore } from '@datorama/akita';


@Injectable({ providedIn: 'root' })
export class DataService  {

  constructor(private dataStore: DataStore, private dataQuery: DataQuery,
    private translateService: TranslateService) {
  }

  getData() {

  }

  saveDataClass(data: Class) {
    this.dataStore.update(state => { return {  class: data } });
  }

  saveDataUser(data: User) {
    this.dataStore.update(state => { return {  user: data } });
  }

  saveDataStudents(data: any) {
    this.dataStore.update(state => { return {  students: data } });
  }

  saveDataSchool(data: School) {
    this.dataStore.update(state => { return { school: data } });
  }

  savePassword(data: any) {
    this.dataStore.update(state => { return { password: data } });
  }

}