<div class="main_container" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/esquina-inf-der.svg&quot;)'">
    <section fxLayout="column" fxLayoutAlign="stretch" class="box_options">
        <div>
            <div class="box_op">
                <div class="title">
                    <span>{{ 'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-EDUCATIONAL.SCHOOL-DATA' | translate }}</span>
                </div>
                <div class="cont">
                    <form class="walinwa-box-content" [formGroup]="freeTrialEducationalForm"
                        autocomplete="new-password">
                        <div>
                            <label class="font_blue"><b>{{ 'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-EDUCATIONAL.SCHOOL-NAME' | translate }}</b></label>
                            <mat-form-field class="spe-form-field">
                                <input matInput type="text" class="form-control " formControlName="name">
                                <mat-error *ngIf="freeTrialEducationalForm.get('name').hasError('required')"
                                    class="customized_error">
                                    {{ 'FIELD-REQUIRED' | translate }}
                                </mat-error>
                                <mat-error *ngIf="freeTrialEducationalForm.get('name').hasError('pattern')"
                                    class="customized_error two">
                                    {{ 'PATTERN-ERROR' | translate }}
                                </mat-error>
                                <mat-error *ngIf="freeTrialEducationalForm.get('name').errors?.nameNotValid"
                                    class="customized_error two">
                                    {{ 'PATTERN-ERROR' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="column" fxLayout.gt-sm="row" fxNgClass.gt-sm="spe_gap">
                            <div fxFlex="" id="extra_margin_mobile">
                                <label class="font_blue"><b>{{ 'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-EDUCATIONAL.STATE' | translate }}</b></label>
                                <mat-form-field class="spe-form-field">
                                    <input matInput type="text" class="form-control " formControlName="state">
                                    <mat-error *ngIf="freeTrialEducationalForm.get('state').hasError('required')">
                                        {{ 'FIELD-REQUIRED' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="">
                                <label class="font_blue"><b>{{'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-EDUCATIONAL.COUNTRY' | translate}}</b></label>
                                <mat-form-field class="spe-form-field">
                                    <mat-select class="select scrollable"
                                        (selectionChange)="changeCountry($event.value)" formControlName="country">
                                        <mat-option class="scrollable" value="AF">Afganistán</mat-option>
                                        <mat-option class="scrollable" value="AL">Albania</mat-option>
                                        <mat-option class="scrollable" value="DE">Alemania</mat-option>
                                        <mat-option class="scrollable" value="AD">Andorra</mat-option>
                                        <mat-option class="scrollable" value="AO">Angola</mat-option>
                                        <mat-option class="scrollable" value="AI">Anguilla</mat-option>
                                        <mat-option class="scrollable" value="AQ">Antártida</mat-option>
                                        <mat-option class="scrollable" value="AG">Antigua y Barbuda</mat-option>
                                        <mat-option class="scrollable" value="AN">Antillas Holandesas</mat-option>
                                        <mat-option class="scrollable" value="SA">Arabia Saudí</mat-option>
                                        <mat-option class="scrollable" value="DZ">Argelia</mat-option>
                                        <mat-option class="scrollable" value="AR">Argentina</mat-option>
                                        <mat-option class="scrollable" value="AM">Armenia</mat-option>
                                        <mat-option class="scrollable" value="AW">Aruba</mat-option>
                                        <mat-option class="scrollable" value="AU">Australia</mat-option>
                                        <mat-option class="scrollable" value="AT">Austria</mat-option>
                                        <mat-option class="scrollable" value="AZ">Azerbaiyán</mat-option>
                                        <mat-option class="scrollable" value="BS">Bahamas</mat-option>
                                        <mat-option class="scrollable" value="BH">Bahrein</mat-option>
                                        <mat-option class="scrollable" value="BD">Bangladesh</mat-option>
                                        <mat-option class="scrollable" value="BB">Barbados</mat-option>
                                        <mat-option class="scrollable" value="BE">Bélgica</mat-option>
                                        <mat-option class="scrollable" value="BZ">Belice</mat-option>
                                        <mat-option class="scrollable" value="BJ">Benin</mat-option>
                                        <mat-option class="scrollable" value="BM">Bermudas</mat-option>
                                        <mat-option class="scrollable" value="BY">Bielorrusia</mat-option>
                                        <mat-option class="scrollable" value="MM">Birmania</mat-option>
                                        <mat-option class="scrollable" value="BO">Bolivia</mat-option>
                                        <mat-option class="scrollable" value="BA">Bosnia y Herzegovina</mat-option>
                                        <mat-option class="scrollable" value="BW">Botswana</mat-option>
                                        <mat-option class="scrollable" value="BR">Brasil</mat-option>
                                        <mat-option class="scrollable" value="BN">Brunei</mat-option>
                                        <mat-option class="scrollable" value="BG">Bulgaria</mat-option>
                                        <mat-option class="scrollable" value="BF">Burkina Faso</mat-option>
                                        <mat-option class="scrollable" value="BI">Burundi</mat-option>
                                        <mat-option class="scrollable" value="BT">Bután</mat-option>
                                        <mat-option class="scrollable" value="CV">Cabo Verde</mat-option>
                                        <mat-option class="scrollable" value="KH">Camboya</mat-option>
                                        <mat-option class="scrollable" value="CM">Camerún</mat-option>
                                        <mat-option class="scrollable" value="CA">Canadá</mat-option>
                                        <mat-option class="scrollable" value="TD">Chad</mat-option>
                                        <mat-option class="scrollable" value="CL">Chile</mat-option>
                                        <mat-option class="scrollable" value="CN">China</mat-option>
                                        <mat-option class="scrollable" value="CY">Chipre</mat-option>
                                        <mat-option class="scrollable" value="VA">Ciudad del Vaticano (Santa
                                            Sede)</mat-option>
                                        <mat-option class="scrollable" value="CO">Colombia</mat-option>
                                        <mat-option class="scrollable" value="KM">Comores</mat-option>
                                        <mat-option class="scrollable" value="CG">Congo</mat-option>
                                        <mat-option class="scrollable" value="CD">Congo, República Democrática
                                            del</mat-option>
                                        <mat-option class="scrollable" value="KR">Corea</mat-option>
                                        <mat-option class="scrollable" value="KP">Corea del Norte</mat-option>
                                        <mat-option class="scrollable" value="CI">Costa de Marfíl</mat-option>
                                        <mat-option class="scrollable" value="CR">Costa Rica</mat-option>
                                        <mat-option class="scrollable" value="HR">Croacia (Hrvatska)</mat-option>
                                        <mat-option class="scrollable" value="CU">Cuba</mat-option>
                                        <mat-option class="scrollable" value="DK">Dinamarca</mat-option>
                                        <mat-option class="scrollable" value="DJ">Djibouti</mat-option>
                                        <mat-option class="scrollable" value="DM">Dominica</mat-option>
                                        <mat-option class="scrollable" value="EC">Ecuador</mat-option>
                                        <mat-option class="scrollable" value="EG">Egipto</mat-option>
                                        <mat-option class="scrollable" value="SV">El Salvador</mat-option>
                                        <mat-option class="scrollable" value="AE">Emiratos Árabes Unidos</mat-option>
                                        <mat-option class="scrollable" value="ER">Eritrea</mat-option>
                                        <mat-option class="scrollable" value="SI">Eslovenia</mat-option>
                                        <mat-option class="scrollable" value="ES">España</mat-option>
                                        <mat-option class="scrollable" value="US">Estados Unidos</mat-option>
                                        <mat-option class="scrollable" value="EE">Estonia</mat-option>
                                        <mat-option class="scrollable" value="ET">Etiopía</mat-option>
                                        <mat-option class="scrollable" value="FJ">Fiji</mat-option>
                                        <mat-option class="scrollable" value="PH">Filipinas</mat-option>
                                        <mat-option class="scrollable" value="FI">Finlandia</mat-option>
                                        <mat-option class="scrollable" value="FR">Francia</mat-option>
                                        <mat-option class="scrollable" value="GA">Gabón</mat-option>
                                        <mat-option class="scrollable" value="GM">Gambia</mat-option>
                                        <mat-option class="scrollable" value="GE">Georgia</mat-option>
                                        <mat-option class="scrollable" value="GH">Ghana</mat-option>
                                        <mat-option class="scrollable" value="GI">Gibraltar</mat-option>
                                        <mat-option class="scrollable" value="GD">Granada</mat-option>
                                        <mat-option class="scrollable" value="GR">Grecia</mat-option>
                                        <mat-option class="scrollable" value="GL">Groenlandia</mat-option>
                                        <mat-option class="scrollable" value="GP">Guadalupe</mat-option>
                                        <mat-option class="scrollable" value="GU">Guam</mat-option>
                                        <mat-option class="scrollable" value="GT">Guatemala</mat-option>
                                        <mat-option class="scrollable" value="GY">Guayana</mat-option>
                                        <mat-option class="scrollable" value="GF">Guayana Francesa</mat-option>
                                        <mat-option class="scrollable" value="GN">Guinea</mat-option>
                                        <mat-option class="scrollable" value="GQ">Guinea Ecuatorial</mat-option>
                                        <mat-option class="scrollable" value="GW">Guinea-Bissau</mat-option>
                                        <mat-option class="scrollable" value="HT">Haití</mat-option>
                                        <mat-option class="scrollable" value="HN">Honduras</mat-option>
                                        <mat-option class="scrollable" value="HU">Hungría</mat-option>
                                        <mat-option class="scrollable" value="IN">India</mat-option>
                                        <mat-option class="scrollable" value="ID">Indonesia</mat-option>
                                        <mat-option class="scrollable" value="IQ">Irak</mat-option>
                                        <mat-option class="scrollable" value="IR">Irán</mat-option>
                                        <mat-option class="scrollable" value="IE">Irlanda</mat-option>
                                        <mat-option class="scrollable" value="BV">Isla Bouvet</mat-option>
                                        <mat-option class="scrollable" value="CX">Isla de Christmas</mat-option>
                                        <mat-option class="scrollable" value="IS">Islandia</mat-option>
                                        <mat-option class="scrollable" value="KY">Islas Caimán</mat-option>
                                        <mat-option class="scrollable" value="CK">Islas Cook</mat-option>
                                        <mat-option class="scrollable" value="CC">Islas de Cocos o Keeling</mat-option>
                                        <mat-option class="scrollable" value="FO">Islas Faroe</mat-option>
                                        <mat-option class="scrollable" value="HM">Islas Heard y McDonald</mat-option>
                                        <mat-option class="scrollable" value="FK">Islas Malvinas</mat-option>
                                        <mat-option class="scrollable" value="MP">Islas Marianas del Norte</mat-option>
                                        <mat-option class="scrollable" value="MH">Islas Marshall</mat-option>
                                        <mat-option class="scrollable" value="UM">Islas menores de Estados
                                            Unidos</mat-option>
                                        <mat-option class="scrollable" value="PW">Islas Palau</mat-option>
                                        <mat-option class="scrollable" value="SB">Islas Salomón</mat-option>
                                        <mat-option class="scrollable" value="SJ">Islas Svalbard y Jan
                                            Mayen</mat-option>
                                        <mat-option class="scrollable" value="TK">Islas Tokelau</mat-option>
                                        <mat-option class="scrollable" value="TC">Islas Turks y Caicos</mat-option>
                                        <mat-option class="scrollable" value="VI">Islas Vírgenes (EEUU)</mat-option>
                                        <mat-option class="scrollable" value="VG">Islas Vírgenes (Reino
                                            Unido)</mat-option>
                                        <mat-option class="scrollable" value="WF">Islas Wallis y Futuna</mat-option>
                                        <mat-option class="scrollable" value="IL">Israel</mat-option>
                                        <mat-option class="scrollable" value="IT">Italia</mat-option>
                                        <mat-option class="scrollable" value="JM">Jamaica</mat-option>
                                        <mat-option class="scrollable" value="JP">Japón</mat-option>
                                        <mat-option class="scrollable" value="JO">Jordania</mat-option>
                                        <mat-option class="scrollable" value="KZ">Kazajistán</mat-option>
                                        <mat-option class="scrollable" value="KE">Kenia</mat-option>
                                        <mat-option class="scrollable" value="KG">Kirguizistán</mat-option>
                                        <mat-option class="scrollable" value="KI">Kiribati</mat-option>
                                        <mat-option class="scrollable" value="KW">Kuwait</mat-option>
                                        <mat-option class="scrollable" value="LA">Laos</mat-option>
                                        <mat-option class="scrollable" value="LS">Lesotho</mat-option>
                                        <mat-option class="scrollable" value="LV">Letonia</mat-option>
                                        <mat-option class="scrollable" value="LB">Líbano</mat-option>
                                        <mat-option class="scrollable" value="LR">Liberia</mat-option>
                                        <mat-option class="scrollable" value="LY">Libia</mat-option>
                                        <mat-option class="scrollable" value="LI">Liechtenstein</mat-option>
                                        <mat-option class="scrollable" value="LT">Lituania</mat-option>
                                        <mat-option class="scrollable" value="LU">Luxemburgo</mat-option>
                                        <mat-option class="scrollable" value="MK">Macedonia, Ex-República Yugoslava
                                            de</mat-option>
                                        <mat-option class="scrollable" value="MG">Madagascar</mat-option>
                                        <mat-option class="scrollable" value="MY">Malasia</mat-option>
                                        <mat-option class="scrollable" value="MW">Malawi</mat-option>
                                        <mat-option class="scrollable" value="MV">Maldivas</mat-option>
                                        <mat-option class="scrollable" value="ML">Malí</mat-option>
                                        <mat-option class="scrollable" value="MT">Malta</mat-option>
                                        <mat-option class="scrollable" value="MA">Marruecos</mat-option>
                                        <mat-option class="scrollable" value="MQ">Martinica</mat-option>
                                        <mat-option class="scrollable" value="MU">Mauricio</mat-option>
                                        <mat-option class="scrollable" value="MR">Mauritania</mat-option>
                                        <mat-option class="scrollable" value="YT">Mayotte</mat-option>
                                        <mat-option class="scrollable" value="MX">México</mat-option>
                                        <mat-option class="scrollable" value="FM">Micronesia</mat-option>
                                        <mat-option class="scrollable" value="MD">Moldavia</mat-option>
                                        <mat-option class="scrollable" value="MC">Mónaco</mat-option>
                                        <mat-option class="scrollable" value="MN">Mongolia</mat-option>
                                        <mat-option class="scrollable" value="MS">Montserrat</mat-option>
                                        <mat-option class="scrollable" value="MZ">Mozambique</mat-option>
                                        <mat-option class="scrollable" value="NA">Namibia</mat-option>
                                        <mat-option class="scrollable" value="NR">Nauru</mat-option>
                                        <mat-option class="scrollable" value="NP">Nepal</mat-option>
                                        <mat-option class="scrollable" value="NI">Nicaragua</mat-option>
                                        <mat-option class="scrollable" value="NE">Níger</mat-option>
                                        <mat-option class="scrollable" value="NG">Nigeria</mat-option>
                                        <mat-option class="scrollable" value="NU">Niue</mat-option>
                                        <mat-option class="scrollable" value="NF">Norfolk</mat-option>
                                        <mat-option class="scrollable" value="NO">Noruega</mat-option>
                                        <mat-option class="scrollable" value="NC">Nueva Caledonia</mat-option>
                                        <mat-option class="scrollable" value="NZ">Nueva Zelanda</mat-option>
                                        <mat-option class="scrollable" value="OM">Omán</mat-option>
                                        <mat-option class="scrollable" value="NL">Países Bajos</mat-option>
                                        <mat-option class="scrollable" value="PA">Panamá</mat-option>
                                        <mat-option class="scrollable" value="PG">Papúa Nueva Guinea</mat-option>
                                        <mat-option class="scrollable" value="PK">Paquistán</mat-option>
                                        <mat-option class="scrollable" value="PY">Paraguay</mat-option>
                                        <mat-option class="scrollable" value="PE">Perú</mat-option>
                                        <mat-option class="scrollable" value="PN">Pitcairn</mat-option>
                                        <mat-option class="scrollable" value="PF">Polinesia Francesa</mat-option>
                                        <mat-option class="scrollable" value="PL">Polonia</mat-option>
                                        <mat-option class="scrollable" value="PT">Portugal</mat-option>
                                        <mat-option class="scrollable" value="PR">Puerto Rico</mat-option>
                                        <mat-option class="scrollable" value="QA">Qatar</mat-option>
                                        <mat-option class="scrollable" value="UK">Reino Unido</mat-option>
                                        <mat-option class="scrollable" value="CF">República Centroafricana</mat-option>
                                        <mat-option class="scrollable" value="CZ">República Checa</mat-option>
                                        <mat-option class="scrollable" value="ZA">República de Sudáfrica</mat-option>
                                        <mat-option class="scrollable" value="DO">República Dominicana</mat-option>
                                        <mat-option class="scrollable" value="SK">República Eslovaca</mat-option>
                                        <mat-option class="scrollable" value="RE">Reunión</mat-option>
                                        <mat-option class="scrollable" value="RW">Ruanda</mat-option>
                                        <mat-option class="scrollable" value="RO">Rumania</mat-option>
                                        <mat-option class="scrollable" value="RU">Rusia</mat-option>
                                        <mat-option class="scrollable" value="EH">Sahara Occidental</mat-option>
                                        <mat-option class="scrollable" value="KN">Saint Kitts y Nevis</mat-option>
                                        <mat-option class="scrollable" value="WS">Samoa</mat-option>
                                        <mat-option class="scrollable" value="AS">Samoa Americana</mat-option>
                                        <mat-option class="scrollable" value="SM">San Marino</mat-option>
                                        <mat-option class="scrollable" value="VC">San Vicente y Granadinas</mat-option>
                                        <mat-option class="scrollable" value="SH">Santa Helena</mat-option>
                                        <mat-option class="scrollable" value="LC">Santa Lucía</mat-option>
                                        <mat-option class="scrollable" value="ST">Santo Tomé y Príncipe</mat-option>
                                        <mat-option class="scrollable" value="SN">Senegal</mat-option>
                                        <mat-option class="scrollable" value="SC">Seychelles</mat-option>
                                        <mat-option class="scrollable" value="SL">Sierra Leona</mat-option>
                                        <mat-option class="scrollable" value="SG">Singapur</mat-option>
                                        <mat-option class="scrollable" value="SY">Siria</mat-option>
                                        <mat-option class="scrollable" value="SO">Somalia</mat-option>
                                        <mat-option class="scrollable" value="LK">Sri Lanka</mat-option>
                                        <mat-option class="scrollable" value="PM">St Pierre y Miquelon</mat-option>
                                        <mat-option class="scrollable" value="SZ">Suazilandia</mat-option>
                                        <mat-option class="scrollable" value="SD">Sudán</mat-option>
                                        <mat-option class="scrollable" value="SE">Suecia</mat-option>
                                        <mat-option class="scrollable" value="CH">Suiza</mat-option>
                                        <mat-option class="scrollable" value="SR">Surinam</mat-option>
                                        <mat-option class="scrollable" value="TH">Tailandia</mat-option>
                                        <mat-option class="scrollable" value="TW">Taiwán</mat-option>
                                        <mat-option class="scrollable" value="TZ">Tanzania</mat-option>
                                        <mat-option class="scrollable" value="TJ">Tayikistán</mat-option>
                                        <mat-option class="scrollable" value="TF">Territorios franceses del
                                            Sur</mat-option>
                                        <mat-option class="scrollable" value="TP">Timor Oriental</mat-option>
                                        <mat-option class="scrollable" value="TG">Togo</mat-option>
                                        <mat-option class="scrollable" value="TO">Tonga</mat-option>
                                        <mat-option class="scrollable" value="TT">Trinidad y Tobago</mat-option>
                                        <mat-option class="scrollable" value="TN">Túnez</mat-option>
                                        <mat-option class="scrollable" value="TM">Turkmenistán</mat-option>
                                        <mat-option class="scrollable" value="TR">Turquía</mat-option>
                                        <mat-option class="scrollable" value="TV">Tuvalu</mat-option>
                                        <mat-option class="scrollable" value="UA">Ucrania</mat-option>
                                        <mat-option class="scrollable" value="UG">Uganda</mat-option>
                                        <mat-option class="scrollable" value="UY">Uruguay</mat-option>
                                        <mat-option class="scrollable" value="UZ">Uzbekistán</mat-option>
                                        <mat-option class="scrollable" value="VU">Vanuatu</mat-option>
                                        <mat-option class="scrollable" value="VE">Venezuela</mat-option>
                                        <mat-option class="scrollable" value="VN">Vietnam</mat-option>
                                        <mat-option class="scrollable" value="YE">Yemen</mat-option>
                                        <mat-option class="scrollable" value="YU">Yugoslavia</mat-option>
                                        <mat-option class="scrollable" value="ZM">Zambia</mat-option>
                                        <mat-option class="scrollable" value="ZW">Zimbabue</mat-option>
                                        <!-- <mat-option *ngFor="let country of countries" [value]="country">{{country}}</mat-option> -->
                                    </mat-select>
                                    <mat-error *ngIf="freeTrialEducationalForm.get('country').hasError('required')">
                                        {{ 'FIELD-REQUIRED' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div ngStyle.gt-sm="width: calc(50% - 10px);">
                            <label class="font_blue"><b>{{ 'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-EDUCATIONAL.PROMOTIONAL-CODE' | translate}}</b></label>
                            <mat-form-field class="spe-form-field">
                                <input matInput type="text" class="form-control " formControlName="origin">
                            </mat-form-field>
                        </div>
                        <div class="walinwa-row spe_gap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center" style="margin-top: 35px !important;">
                            <button mat-raised-button color="" (click)="continue()" class="orange submit-button fixed_button">
                                {{ 'CONTINUE' | translate }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</div>