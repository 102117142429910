<div class="walinwa-box-modal-overlay" style="align-items: center; background-color: rgba(0, 0, 0, .5);">
    <div class="walinwa-review">
        <div class="modal-back-image">
            <div class="paginationArea leftSide">
                <div [hidden]="help.length==1" [ngClass]="{ 'pagination-disabled': currentStep == 1 }" class="pagination-button" (click)="changePage(false)">
                    <img src="https://walinwa.blob.core.windows.net/images/retroceso.svg?ngsw-bypass=true" >
                </div>
            </div>
            <div class="page">
                <img class="main" [src]="'https://walinwa.blob.core.windows.net/images/' + subDirectory + '/' + getImgName() + '?ngsw-bypass=true'" alt="">
                <div class="walinwa-close-modal" (click)="finish()">
                    <img src="https://walinwa.blob.core.windows.net/images/ico-close-popup.svg?ngsw-bypass=true" />
                </div>
                <ng-container *ngIf="currentStep == 1">
                    <a href="javascript:void(0);" class="go_page page2" (click)="jumpToPage(2)"></a>
                    <a href="javascript:void(0);" class="go_page page17" (click)="jumpToPage(17)"></a>
                    <a href="javascript:void(0);" class="go_page page27" (click)="jumpToPage(27)"></a>
                    <a href="javascript:void(0);" class="go_page page37" (click)="jumpToPage(37)"></a>
                    <a href="javascript:void(0);" class="go_page page51" (click)="jumpToPage(51)"></a>
                </ng-container>
                <ng-container *ngIf="currentStep == 2 || currentStep == 17 || currentStep == 27 || currentStep == 37 || currentStep == 51">
                    <a href="javascript:void(0);" class="indice" (click)="jumpToPage(1)">{{'STUDENT.GO-TO-THE-INDEX' | translate}}</a>
                </ng-container>
            </div>
            <div class="paginationArea rightSide">
                <div class="pagination-button" [hidden]="currentStep >= numberOfPages() || help.length==1" (click)="changePage(true)">
                    <img src="https://walinwa.blob.core.windows.net/images/avance.svg?ngsw-bypass=true">
                </div>
                <div class="pagination-button pagination-disabled" [hidden]="currentStep < numberOfPages() || help.length==1" (click)="finish()">
                    <img src="https://walinwa.blob.core.windows.net/images/avance.svg?ngsw-bypass=true">
                </div>              
            </div>
        </div>
    </div>
</div>