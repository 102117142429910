import { Component, Input, OnInit, OnDestroy, HostListener, ViewEncapsulation } from '@angular/core';
import { ExerciseService } from 'app/services/exercise.service';

@Component({
  selector: 'ex-marca-f',
  templateUrl: './marca-f.component.html',
  styleUrls: ['./marca-f.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MarcaFComponent implements OnInit, OnDestroy {
  @Input() review;
  @Input() show_errors;
  @Input() controlSession : boolean = false;
  @Input() slide : boolean = false; @Input() current;
  exercise;
  draggingElement;
  letters = [];
  ok = [];
  error = [];
  finished = false;
  answered;
  numItemToEvaluate = 0;

  elementsOk = [];
  elementsErr = [];
  leftWords;
  rightWords;
  leftValidation;
  rightValidation;
  tmpPhrases = []
  numOk = 0
  numErr = 0

    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case "SHOW_SOLUTION":
                this.showSolution();
                break;
            case "SHOW_ANSWER":
            case "SHOW_CUSTOM":
                this.ShowAnswer();
                break;
        }
    }
  
  constructor(private exerciseService: ExerciseService) {
    this.exercise = this.exerciseService.getExerciseDefinition();
    this.tmpPhrases = (JSON.parse(JSON.stringify(this.exercise.frases)));
  }
    ngOnDestroy(){

    }

  ngOnInit() {
    if (this.review) {
      this.elementsOk = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsOK
      );
      this.elementsErr = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsERR
      );
      if (this.show_errors == false) {
        this.showSolution();
      } else if (this.show_errors == true || this.show_errors == null) {
        this.ShowAnswer();
      }
    }
  }
    parseEnunciado() {
    return this.exercise.enunciado.replace("<br/><br/>", "<br>");
  }
  

  validate(index) {
    var numTotal = 0, numTotalOk = 0;
    if (this.finished)
      return;
    if (this.tmpPhrases[index].answered == true)
      return;
    for (var i = 0; i < this.tmpPhrases.length; i++) {
      numTotal++;
      if (this.tmpPhrases[i].opcion == true)
        numTotalOk++;
      if (this.tmpPhrases[i].answered != true)
        this.tmpPhrases[i].answered = null;

    }

    if (this.tmpPhrases[index].opcion == true) {
      this.tmpPhrases[index].answered = true;
      this.numOk++;
      // exerciseService.AddOk(index);
      this.exerciseService.answer(index, true, 1)
    } else {
      // $scope.$emit("error");
      this.tmpPhrases[index].answered = false;
      this.numErr++;
      // exerciseService.AddError(index);
      this.exerciseService.answer(index, false, 1)
    }
    for (var i = 0; i < this.tmpPhrases.length; i++) {
      if (this.tmpPhrases[index].answered == false)
        return;
    }
    this.finished = true;
    if (numTotalOk > 1 && this.numErr >= (numTotal * 0.4))
      window.postMessage("EXERCISE_SOLVED_MSG_2", "*");
    else if (numTotalOk == 1 && this.numErr > (numTotal * 0.5))
      window.postMessage("EXERCISE_SOLVED_MSG_1", "*");
  }

  validateClick(spanId) {
    if (this.finished) return;
    this.answered = null;
    for (var i = 0; i < this.exercise.palabras.length; i++) {
      if (this.exercise.palabras[i].opcion == "F") {
        this.exercise.palabras[i].answered = null;
        this.answered = null;
      }
    }
    this.exercise.palabras[spanId].answered = true;
    let options = 0;
    this.exercise.palabras.forEach((palabra) => {
      if (palabra.opcion === "T") options += 1;
    });
    if (this.exercise.palabras[spanId].opcion == "T") {
      this.exerciseService.answer(spanId, true, options);
      if (this.numItemToEvaluate == 1) {
        this.answered = true;
        this.finished = true;
        return;
      }
    } else {
      this.exerciseService.answer(spanId, false, options);
    }

    var done = true;
    for (var i = 0; i < this.exercise.palabras.length; i++) {
      if (
        this.exercise.palabras[i].opcion == "T" &&
        this.exercise.palabras[i].answered != true
      )
        done = false;
      if (
        this.exercise.palabras[i].opcion != "T" &&
        this.exercise.palabras[i].answered == true
      ) {
        done = false;
        this.answered = false;
      }
    }
    if (done) {
      this.answered = true;
      this.finished = true;
    }
  }
  showSolution() {
    this.answered = true;
    this.tmpPhrases.forEach((value, key) => {
      if (value.opcion == true)
        value.answered = true;
      else
        value.answered = null;
    });
  }

  ShowAnswer() {
    switch (this.exerciseService.getCurrentExercise().Result) {
      case "JMP":
        this.answered = null;
        break;
      case "OK-JMP":
        this.answered = null;
        break;
      case "ERR-JMP":
        this.answered = false;
        break;
      case "OUT":
        this.answered = null;
        break;
      case "OK-OUT":
        this.answered = null;
        break;
      case "ERR-OUT":
        this.answered = false;
        break;
      case "ERR-OK":
        this.answered = false;
        break;
      case "OK":
        this.answered = true;
        break;
    }

    this.tmpPhrases.forEach((value, key) => {
      value.answered = null;
      for (var p = 0; p < this.elementsErr.length; p++) {
        if (key == this.elementsErr[p]) value.answered = false;
      }
      for (var p = 0; p < this.elementsOk.length; p++) {
        if (key == this.elementsOk[p] && value.answered != false)
          value.answered = true;
      }
    });
  }
}
