<div class="walinwa-box-with-tabs" fxNgClass.gt-sm="scroll_vertical" *ngIf="dataLoaded">
    <div class="walinwa-tabs walinwa-tabs-top" *ngIf="step == 1">
        <div [ngClass]="{ 'selected': state == 'pending' }" (click)="viewPending()" class="walinwa-tab-gray">{{'SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.PENDING-PAYMENTS' | translate}}</div>
        <div [ngClass]="{ 'selected': state == 'paid'}" (click)="viewPaid()" class="walinwa-tab-gray">{{'SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.SUCCESSFUL-PAYMENTS' | translate}}
        </div>
    </div>
    <div class="walinwa-box walinwa-box-green">
        <div class="walinwa-box-header" *ngIf="step == 2">
            {{'SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.LICENSE-PAYMENT' | translate}}
        </div>
        <div class="walinwa-box-content spe_gap" fxLayout="column" fxLayoutAlign="stretch" *ngIf="state == 'paid'">
            <div class="ww_table_rwd">
                <table class="walinwa-table">
                    <thead>
                        <tr>
                            <th class="text-center">{{'SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.STATUS-TITLE' | translate}}</th>
                            <th class="text-center" [innerHTML]="'SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.PAYMENT-DATE-TITLE' | translate"></th>
                            <th class="text-center" [innerHTML]="'SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.INITIAL-AMOUNT-TITLE' | translate"></th>
                            <th class="text-center" [innerHTML]="'SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.APPLIED-DISCOUNT-TITLE' | translate"></th>
                            <th class="text-center" [innerHTML]="'SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.FINAL-AMOUNT-TITLE' | translate"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr [ngClass]="{'ok': paid.Paid == true, 'ko': paid.Paid == false}"
                            *ngFor="let paid of paidLicenses" class="">
                            <td class="text-center">
                                {{formatStatus(paid)}}
                            </td>
                            <td class="text-center">
                                {{formatDate(paid.PaymentDate)}}
                            </td>
                            <td class="text-center">
                                {{paid.InitialAmount.toFixed(1)}} €
                            </td>
                            <td class="text-center">
                                {{paid.DiscountAmount.toFixed(1)}} €
                            </td>
                            <td class="text-center">
                                {{paid.Paid ? paid.FinalAmount.toFixed(1) : zero.toFixed(1)}} €
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="walinwa-box-content spe_gap" fxLayout="column" fxLayoutAlign="stretch"
            *ngIf="state == 'pending' && step == 1">
            <div class="ww_table_rwd">
                <table class="walinwa-table">
                    <thead>
                        <tr>
                            <th class="text-center">{{'USERNAME' | translate}}</th>
                            <th class="text-center">{{'SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.VALIDITY-PERIOD' | translate}}</th>
                            <th class="text-center">{{'SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.AMOUNT' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr (click)="pending.selected = ! pending.selected" [ngClass]="{'selected' : pending.selected}"
                            *ngFor="let pending of pendingLicenses" class="">
                            <td class="text-center">
                                {{pending.Name}}
                            </td>
                            <td class="text-center">
                                {{formatDate(pending.StartDate)}} - {{formatDate(pending.EndDate)}}
                            </td>
                            <td class="text-center">
                                {{pending.Price.toFixed(1)}} €
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <div class="ww_final_import" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                    <div>{{'SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.FINAL-AMOUNT' | translate}}</div>
                    <div class="font_blue">{{totalAmountWithDisc().toFixed(1)}} €</div>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between end" fxLayoutAlign.xs="center center" class="spe_gap">
                <button (click)="cancel()" class="new_but azul">
                    {{'SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.DELETE-LICENSES' | translate}}
                </button>
                <button (click)="moreLicenses()" class="new_but azul">
                    {{'SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.BUY-LICENSES' | translate}}
                </button>
                <button (click)="continue()" [hidden]="state == 'paid' || totalAmount() == 0" class="new_but azul">
                    {{'CONTINUE' | translate}}
                </button>
            </div>
        </div>
        <div class="walinwa-box-content spe_gap" fxLayout="column" fxLayoutAlign="stretch"
            *ngIf="state == 'pending' && step == 2">
            <div>
                {{'SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.DISCOUNT-TITLE' | translate}}
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="spe_gap">
                <div fxFlex="" fxFlex.xs="100%">
                    <label class="font_blue">{{'SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.DISCOUNT-CODE' | translate}}</label>
                    <div class="ww_field_spe">
                        <input matInput type="text" [(ngModel)]="discountCode" />
                    </div>
                    <mat-error *ngIf="error">
                        {{errorDescription}}
                    </mat-error>
                    <mat-error *ngIf="discountOk">
                        {{discountDescription}}
                    </mat-error>
                </div>
                <div>
                    <button (click)="validateCode()" [disabled]="calling"
                    class="new_but azul">{{'SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.VALIDATE-CODE' | translate}}</button>
                </div>
            </div>
            <div>
                <div class="ww_table_rwd">
                    <table class="walinwa-table" style="border-top: 0.05em solid var(--gris);">
                        <tbody>
                            <tr>
                                <td>{{'SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.INITIAL-AMOUNT' | translate}}</td>
                                <td>{{ totalAmount().toFixed(1) }} €</td>
                            </tr>
                            <tr>
                                <td>{{'SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.APPLIED-DISCOUNT' | translate}}</td>
                                <td>{{ discountPrice.toFixed(1) }} €</td>
                            </tr>
                            <tr>
                                <td>{{'SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.FINAL-AMOUNT' | translate}}</td>
                                <td>{{ finalPrice.toFixed(1) }} €</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div [hidden]="finalPrice == 0">
                {{'SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.PAYMENT-TITLE' | translate}}
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center" class="spe_gap">
                <button class="new_but azul" (click)="pay()" [disabled]="disablePay()">{{'SCHOOL.PARENT.STUDENTS.MY-ACCOUNT.PAY' | translate}}</button>
            </div>
        </div>
        <div class="walinwa-box-content spe_gap" fxLayout="column" fxLayoutAlign="stretch"
            *ngIf="state == 'pending' && step == 3">
            <div
                style="position: fixed; top: 0; left: 0; width: 100vw; height: 100vh; display: -webkit-box; display: flex; -webkit-box-pack: center; justify-content: center; -webkit-box-align: center; align-items: center; background-color: rgba(0, 0, 0, 0.2); z-index: 1;">
                <div style="width: 85%; height: 90%; position: relative; font-size: 1rem; margin-bottom: -1.5em;">
                    <tpv [config]="tpvInfo" (onClose)="closeTPV()" *ngIf="state == 'pending' && step == 3"></tpv>
                </div>
            </div>
        </div>
    </div>
</div>