<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>
<div class="unir6 spe_gap" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" fxLayout="column" fxLayoutAlign="stretch" [ngClass]="{'fixControlSessions': controlSession}">

    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div class="wordsContainer" [ngClass]="{'fixControlSessions': controlSession}">
        <div class="items_container" [ngClass]="{'fixControlSessions': controlSession}">
            <div [hidden]="review && showingSolution" id="row1"
                [ngClass]="{'element-hidden':  showResult == true}">

                <div *ngFor="let word of exercise.grupo1; let i = index" class="wordBoxOption draggable" [ngClass]="{'textSelected':  i == leftSelected, 'wordBoxError': i == leftError, 
                 'element-hidden': word == null  }" id="col1{{i}}" column="left" [attr.index]="i">
                    {{word}}
                </div>
            </div>
            <div [hidden]="review && showingSolution" id="row2"
                [ngClass]="{'element-hidden':  showResult == true}">
                <div *ngFor="let word of exercise.grupo2; let i = index" class=" dropzone phraseBox" [ngClass]="{'phrase-box-slide': controlSession,'phraseBox-extra': !controlSession && !slide, 'textSelected':  i == rightSelected ,'wordBoxError': i == rightError,
                 'element-hidden': word == null }" id="col2{{i}}" column="right" [attr.index]="i">
                    {{word}}
                </div>
            </div>
            <div [hidden]="(!review && !showingSolution && !showResult) || controlSession" id="columnResult" class="wordsResult"
                [ngClass]="{ 'element-hidden':  showResult == false}">
                <div *ngFor="let item of exercise.result" class="wordBox wordBoxResult" id="result{{i}}"
                    [hidden]="!review && !finished"
                    [ngClass]="{ 'wordBoxOk':  item.answered == true,  'wordBoxError': item.answered == false}">
                    <span [innerHtml]="replaceI(item.word)"></span>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  -->
<!-- <div class="unir6" [ngStyle]="{'pointer-events': review ? 'none;' : ''}">   <h2 *ngIf="controlSession" class="doc-number">{{current + 1}}</h2>
    <div *ngIf="!controlSession" class="exerciseTheme">
        <h1 [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></h1>
    </div>
    <div class="text-center exerciseTitle" [ngClass]="{'exerciseTitle-slide': slide}">
        <p   [innerHtml]="exercise.enunciado"></p>
    </div>
    <div class="optionsContainer">
        <div [hidden]="review && showingSolution" id="row1" class="wordsContainer" [ngClass]="{ 'words-container-slide': slide ,'element-hidden':  showResult == true}">

            <div *ngFor="let word of exercise.grupo1; let i = index" class="wordBoxOption draggable" [ngClass]="{'textSelected':  i == leftSelected, 'wordBoxError': i == leftError, 
                 'element-hidden': word == null  }" id="col1{{i}}" column="left" [attr.index]="i">
                {{word}}
            </div>

        </div>
        <div  [hidden]="review && showingSolution" id="row2" class="wordsContainer2" [ngClass]="{'words-container2-slide': slide, 'element-hidden':  showResult == true}">
            <div [ngStyle]="controlSession && !slide? {'font-size' : '1.5em;'} :{}"  *ngFor="let word of exercise.grupo2; let i = index" class=" dropzone" [ngClass]="{'phrase-box-slide': controlSession,'phraseBox': !controlSession && !slide, 'textSelected':  i == rightSelected ,'wordBoxError': i == rightError,
                 'element-hidden': word == null }" id="col2{{i}}" column="right" [attr.index]="i">
                {{word}}
            </div>
        </div>
        <div [hidden]="!review && !showingSolution && !showResult" id="columnResult" class="wordsResult" [ngClass]="{ 'element-hidden':  showResult == false}">
            <div *ngFor="let item of exercise.result" class="wordBox wordBoxResult" id="result{{i}}" [hidden]="!review && !finished" [ngClass]="{ 'wordBoxOk':  item.answered == true,  'wordBoxError': item.answered == false}">
                <p [innerHtml]="replaceI(item.word)"></p>
            </div>
        </div>
    </div>
</div> -->