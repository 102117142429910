<div class="main_container" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/esquina-inf-der.svg&quot;)'">
    <section fxLayout="column" fxLayoutAlign="stretch" class="box_options">
        <div class="box_op">
            <div class="title">
                <span>{{ 'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-PARTICULAR.STUDENT-INFO.TITLE' | translate }}</span>
            </div>
            <div class="cont">
                <form class="walinwa-box-content" [formGroup]="studentInfoForm" autocomplete="new-password">
                    <div>
                        <span class="" *ngFor="let student of studentList; let i = index" (click)="loadStudent(i)"
                            [ngClass]="students[i] == undefined ? 'student' : 'student pointer'">
                            <img class="studentImg" #img [style.background-color]="getBackgroundColor(i, img)"
                            src="https://walinwa.blob.core.windows.net/images/School/ico-edit-alum.svg?ngsw-bypass=true">
                            <span *ngIf="students.length != 0 && students[i] != undefined" class="userNameIcon">{{
                                students[i].userName }}</span>
                        </span>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" fxNgClass.gt-sm="spe_gap">
                        <div fxFlex="">
                            <label class="font_blue">{{ 'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-PARTICULAR.STUDENT-INFO.NAME' | translate }}</label>
                            <mat-form-field class="spe-form-field">
                                <input matInput type="text" [(ngModel)]="name" formControlName="name">
                                <mat-error *ngIf="studentInfoForm.get('name').hasError('required')">
                                    {{ 'FIELD-REQUIRED' | translate }}
                                </mat-error>
                                <mat-error *ngIf="studentInfoForm.get('name').hasError('pattern')">
                                    {{ 'PATTERN-ERROR' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="">
                            <label class="font_blue">{{ 'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-PARTICULAR.STUDENT-INFO.GRADE' | translate }}</label>
                            <mat-form-field class="spe-form-field">
                                <mat-select [disabled]="students.length === 5" formControlName="level"
                                    (selectionChange)="onSelectionChange($event)">
                                    <mat-option class="scrollable" *ngFor="let level of levels" [value]="level.Level">
                                        {{ level.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="studentInfoForm.get('level').hasError('required')">
                                    {{ 'FIELD-REQUIRED' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" fxNgClass.gt-sm="spe_gap">
                        <div fxFlex="">
                            <label class="font_blue">{{ 'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-PARTICULAR.STUDENT-INFO.USER' | translate }} <small>{{
                                    'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-PARTICULAR.STUDENT-INFO.USER-COMMENT' | translate
                                    }}</small></label>
                            <mat-form-field class="spe-form-field">
                                <input matInput type="text" [(ngModel)]="userName" formControlName="userName">
                                <mat-error *ngIf="studentInfoForm.get('userName').hasError('required')">
                                    {{ 'FIELD-REQUIRED' | translate }}
                                </mat-error>
                                <mat-error *ngIf="studentInfoForm.get('userName').hasError('pattern')">
                                    {{ 'PATTERN-ERROR' | translate }}
                                </mat-error>
                                <mat-error *ngIf="studentInfoForm.get('userName').errors?.minlength">
                                    {{ 'USERNAME-MIN-MAX' | translate }}
                                </mat-error>
                                <mat-error *ngIf="studentInfoForm.get('userName').errors?.maxlength">
                                    {{ 'USERNAME-MIN-MAX' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="">
                            <label class="font_blue">{{ 'BIRTHDATE' | translate }}</label>
                            <mat-form-field class="spe-form-field">
                                <input matInput [disabled]="students.length === 5" [matDatepicker]="picker"
                                    [min]="minDate" [max]="maxDate" [(ngModel)]="dateOfBirth"
                                    formControlName="dateOfBirth">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error *ngIf="studentInfoForm.get('dateOfBirth').hasError('required')">
                                    {{ 'FIELD-REQUIRED' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="hasError()" style="margin-top: -1em;">
                        <mat-error>
                            {{ errorMessage | translate}}
                        </mat-error>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutAlign.xs="center center" class="spe_gap" style="margin-top: 35px !important;">
                        <button mat-raised-button [disabled]="students.length === 5 || studentInfoForm.invalid" (click)="add()"
                            class="submit-button fixed_button orange">
                            {{ 'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-PARTICULAR.STUDENT-INFO.ADD-OTHER' | translate }}
                        </button>
                        <button mat-raised-button (click)="finish($event)" class="submit-button fixed_button orange"
                            [disabled]="studentInfoForm.invalid">
                            {{ 'FINISH' | translate }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </section>
</div>