import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class ImageService {
    baseUrl = environment.baseApi + '/api/Image';
constructor(
    private http: HttpClient, 
) { }

    getCountImage(coutName: string){
        return this.http.get(this.baseUrl + "/getCountImage/"+coutName);
    }
}
