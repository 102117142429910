import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpParams, HttpEvent, HttpHeaderResponse, HttpResponse, HttpErrorResponse, HttpUserEvent, HttpProgressEvent } from '@angular/common/http';
import { Location } from '@angular/common';
import { environment } from 'environments/environment';

@Injectable()
export class EmailConfigService {

  baseUrl = environment.baseApi + '/api/EmailConfiguration';

  constructor(private http: HttpClient, private _location: Location) {
  }

  getConfiguration(idUser) {
    return this.http.get(this.baseUrl + '/idUser/' + idUser);
  };

  update(idUser, sessionReports, warningNoSession, nomail) {
    return this.http.put(this.baseUrl + '/' + idUser + '/' + sessionReports + '/' + warningNoSession + '/' + nomail, {});
  }

}
