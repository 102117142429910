
<div class="walinwa-box walinwa-box-green" fxNgClass.gt-sm="scroll_vertical" *ngIf="dataLoaded">
    <div class="walinwa-box-header" *ngIf="!extraFields">
        {{'SCHOOL.EDIT-STUDENT' | translate}} '{{ student.UserName }}'
    </div>
    <div class="walinwa-box-header" *ngIf="extraFields">
        {{'SCHOOL.STUDENT-OPTIONS' | translate}} '{{ student.UserName }}'
    </div>
    <form class="walinwa-box-content" [formGroup]="formDataStudent" *ngIf="formDataStudent" autocomplete="new-password" fxLayout="column">
        <div *ngIf="!extraFields" fxLayout="row wrap" fxLayoutAlign="space-between start" class="spe_gap">
            <div fxFlex="15rem" fxFlex.xs="100%">
                <div class="content-avatar" id="avatar-download"
                    [ngStyle]="{'background-color': isBackground ? 'transparent' : ''}">
                    <avatar-file *ngIf="activeItems && activeItems.length > 0" [activeItems]=activeItems (click)="saveAvatar()" class="pointer"></avatar-file>
                    <img *ngIf="activeItems.length == 0" src="https://walinwa.blob.core.windows.net/images/School/avatar-default.svg?ngsw-bypass=true" />
                </div>
            </div>
            <div fxFlex="" fxFlex.xs="100%" fxLayout="column" fxLayputAlign="stretch">
                <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="spe_gap">
                    <div fxFlex="" fxFlex.lt-md="100%">
                        <mat-form-field class="">
                            <label class="font_blue">{{'SCHOOL.SCHOOL-COURSE' | translate }}</label>
                            <mat-select formControlName="Level">
                                <mat-option class="scrollable" [value]="course.Level" *ngFor="let course of courses | sortBy:'asc':'Level'">{{ course.Name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxFlex="" fxFlex.lt-md="100%">                        
                        <mat-form-field class="less_top_margin_field two">
                            <label class="font_blue">{{'BIRTHDATE' | translate }}</label>
                            <input matInput [matDatepicker]="picker" [max]="maxDate" formControlName="DateOfBirth"
                                style="width: 75%!important;">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error
                                *ngIf="formDataStudent.get('DateOfBirth').hasError('required') && student.DateOfBirth != ''">
                                {{ 'FIELD-REQUIRED' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxNgClass.gt-sm="spe_gap">
                    <div fxFlex="0 0 calc(50% - 0.5rem)" fxFlex.lt-md="100%">                        
                        <mat-form-field class="less_top_margin_field two">
                            <label class="font_blue">{{'NAME' | translate}}</label>
                            <input maxlength="41" formControlName="Name" matInput type="text" class="">
                            <mat-error *ngIf="formDataStudent.get('Name').hasError('required')">
                                {{'FIELD-REQUIRED' | translate}}
                            </mat-error>
                            <mat-error *ngIf="formDataStudent.get('Name').hasError('pattern')">
                                {{'PATTERN-ERROR' | translate}}
                            </mat-error>
                            <mat-error *ngIf="formDataStudent.get('Name').hasError('maxlength')">
                                {{'NAME-MAX' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="0 0 calc(50% - 0.5rem)" fxFlex.lt-md="100%">                        
                        <mat-form-field class="less_top_margin_field two">
                            <label class="font_blue">{{'FAMILYNAME' | translate}}</label>
                            <input maxlength="51" formControlName="FamilyName" matInput type="text" class="">
                            <mat-error *ngIf="formDataStudent.get('FamilyName').hasError('pattern')">
                                {{'PATTERN-ERROR' | translate}}
                            </mat-error>
                            <mat-error *ngIf="formDataStudent.get('FamilyName').hasError('maxlength')">
                                {{'FAMILYNAME-MAX' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="spe_gap">
                    <div fxFlex="" fxFlex.lt-md="100%">
                        <div style="position: relative;">
                            <mat-form-field class="less_top_margin_field two">
                                <label class="font_blue" (click)="showStudentId=!showStudentId">{{'USERNAME' | translate}}</label>
                                <input formControlName="Username" disabled matInput type="text" class="">                            
                                
                            </mat-form-field>
                            <mat-error *ngIf="formDataStudent.get('Username').hasError('unique')">
                                {{'AUTHENTICATION.ERROR.USERNAME' | translate}}
                            </mat-error>
                            <div class="ww_custom_error">
                                <mat-error *ngIf="formDataStudent.get('Username').hasError('required')">
                                    {{ 'FIELD-REQUIRED' | translate }}
                                </mat-error>
                                <mat-error *ngIf="formDataStudent.get('Username').hasError('min')">
                                    {{'USERNAME-MIN-MAX' | translate}}
                                </mat-error>
                                <mat-error *ngIf="formDataStudent.get('Username').hasError('max')">
                                    {{'USERNAME-MIN-MAX' | translate}}
                                </mat-error>
                                <mat-error *ngIf="formDataStudent.get('Username').hasError('pattern')">
                                    {{'PATTERN-ERROR' | translate}}
                                </mat-error>
                            </div>
                            
                            <div *ngIf="showStudentId" style="right: 15%; z-index: 1; height: 4em; width: 15em;"
                                class="walinwa-tooltip walinwa-tooltip-right">
                                <div class="walinwa-tooltip-info walinwa-tooltip-info-primary">
                                    {{'SCHOOL.USER-ID' | translate}} {{ student.Id }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxFlex="" fxFlex.lt-md="100%">                        
                        <mat-form-field class="less_top_margin_field two">
                            <label class="font_blue">{{'ALIAS' | translate}}</label>
                            <input maxlength="51" formControlName="Alias" matInput type="text" class="">
                            <mat-error *ngIf="formDataStudent.get('Alias').hasError('maxlength')">
                                {{'ALIAS-MAX' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" *ngIf="!extraFields">
            <div fxFlex="15rem" fxFlex.xs="100%" fxShow fxHide.xs></div>
            <div fxFlex="" fxLayout="row wrap" fxLayoutAlign="end start" fxLayoutAlign.xs="center center" class="spe_gap">
                <button *ngIf="!extraFields" [disabled]="calling" (click)="changeExtraFields()" class="new_but azul">
                    {{'SCHOOL.OPTIONS' | translate}}
                </button>
                <button [disabled]="calling || formDataStudent.invalid" (click)="updateStudent()" class="new_but azul">
                    {{'SAVE' | translate}}
                </button>
            </div>
        </div>

        <div [hidden]="!extraFields">
            <label class="font_blue">{{'SCHOOL.MULTIME' | translate}}</label>
            <div class="ww_field_spe">
                <select formControlName="MultTime" class="select">
                    <option [value]="multiTime.value" *ngFor="let multiTime of multiTimes">
                        {{ multiTime.name }}
                    </option>
                </select>
            </div>
        </div>
        <div [hidden]="!extraFields">
            <mat-checkbox class="" [(ngModel)]="student.SmallSession" [ngModelOptions]="{standalone: true}">
                {{'SCHOOL.SMALLSESSION' | translate}}
                <tooltip position="R" [info]="'SCHOOL.SMALLSESSION-INFO' | translate"></tooltip>
            </mat-checkbox>
        </div>
        <div [hidden]="!extraFields">
            <mat-checkbox class="" [(ngModel)]="student.BlockName" [ngModelOptions]="{standalone: true}">{{'SCHOOL.BLOCKNAME' | translate}}</mat-checkbox>
            <tooltip position="R" [info]="'SCHOOL.BLOCKNAME-INFO' | translate"></tooltip>
        </div>
        <div [hidden]="!extraFields">
            <mat-checkbox class="" [(ngModel)]="student.GiftShopDisabled" [ngModelOptions]="{standalone: true}">{{'SCHOOL.GIFTSHOPDISABLED' | translate}}</mat-checkbox>
            <tooltip position="R" [info]="'SCHOOL.GIFTSHOPDISABLED-INFO' | translate"></tooltip>
        </div>
        <div [hidden]="!extraFields">
            <mat-checkbox class="" [(ngModel)]="student.BlockMessageGift" [ngModelOptions]="{standalone: true}">{{'SCHOOL.BLOCKMESSAGEGIFT' | translate}}</mat-checkbox>
            <tooltip position="R" [info]="'SCHOOL.BLOCKMESSAGEGIFT-INFO' | translate"></tooltip>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center" class="spe_gap"
            [hidden]="!extraFields">
            <button [disabled]="calling" (click)="updateStudent()" class="new_but azul">
                {{'SAVE' | translate}}
            </button>
        </div>
    </form>
</div>