import { Component, OnInit, ViewEncapsulation, HostListener, AfterViewInit, Renderer2, OnDestroy } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RepositoryService } from 'app/core/repository/repository.service';
import { SharedService } from 'app/core/shared/shared.service';
import { UiService } from '@fuse/components/spinner/ui.service';
import { DataService } from 'app/core/shared/state';
import { environment } from 'environments/environment';
import { AppService } from 'app/app.service';
import { ToolbarService } from 'app/services/toolbarService.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'initial-registration',
  templateUrl: './initial-registration.component.html',
  styleUrls: ['./initial-registration.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class InitialRegistrationComponent implements OnInit, AfterViewInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  mobile: boolean = false;
   iPad = /iPad|Macintosh/i.test(navigator.userAgent); mobileDetect;
  initialRegistrationForm: UntypedFormGroup;
  code: string;
  isError: boolean;
  errorMessage = null;
  submitted = false;
  href='https://www.walinwa.com/doc/acceso_alumnos_codigoaula.pdf'

  constructor(private _formBuilder: UntypedFormBuilder,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _router: Router,
    private _repositoryService: RepositoryService,
    public _sharedService: SharedService,
    private _ui: UiService,
    private _dataService: DataService,
    private appService: AppService,
    private toolbarService:ToolbarService,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute
  ) {
    this._unsubscribeAll = new Subject();
    this._ui.spin$.next(true);
    this.mobileDetect = this.appService.mobileDetect();
  }

  ngOnDestroy(): void
  {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerWidth;
    this.mobile = this._sharedService.isMobile();
  }

  ngAfterViewInit(){

    const matToolbarRef = this.toolbarService.getToolbarRef();
    if (matToolbarRef) {
        setTimeout(() => {
            this.renderer.addClass(matToolbarRef.nativeElement, 'only-logo');
        }, 0);
    }
    }

  ngOnInit(): void {

    this.mobile = this._sharedService.isMobile();
    this.initialRegistrationForm = this._formBuilder.group({
      code: ['', Validators.required]
    });

  }

  initialRegistration(form) {
    
    this.submitted = true;
    this.errorMessage = null;
    if (this.initialRegistrationForm.valid) {
      let initial = Object.assign({}, form.value);
      let url = environment.baseApi + '/api/School/class/' + initial.code;
      this._ui.showSpinner();
      this._repositoryService.get(url)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe((response: any) => {

        this._ui.stopSpinner();
        this._dataService.saveDataClass(response);
        this._router.navigate(['./new-student'], {relativeTo: this.activatedRoute});
    
      },
        error => {
          this.errorMessage = this._sharedService.getError(error);
          this._ui.stopSpinner();
          this.submitted = false;
        });
    }
  }

  hasError(): boolean {
    return this._sharedService.hasError(this.errorMessage);
  }
}
