import { Component, Input, OnInit, OnDestroy, HostListener, ViewEncapsulation } from '@angular/core';
import { ExerciseService } from 'app/services/exercise.service';

@Component({
  selector: 'ex-coloca1',
  templateUrl: './coloca1.component.html',
  styleUrls: ['./coloca1.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Coloca1Component implements OnInit, OnDestroy {

  @Input() review
  @Input() show_errors;
  @Input() controlSession : boolean = false;
  @Input() slide : boolean = false; @Input() current;
  exercise
  draggingOption
  exerciseIsEnded = false
  finished = false

  answer = [];
  options = [];
  globalError = false;
  positions = [];

  elementsOk
  elementsErr

    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case "SHOW_SOLUTION":
                this.showSolution();
                break;
            case "SHOW_ANSWER":
                this.ShowAnswer();
                break;
            case "SHOW_CUSTOM":
                this.exercise = this.exerciseService.getExerciseDefinition();
                this.answer = []
                this.result = null;
                this.DrawModel9();
                break;
        }
    }

  constructor(private exerciseService: ExerciseService) {
    this.exercise = this.exerciseService.getExerciseDefinition();
    
  }
    ngOnDestroy(): void {
    }

  ngOnInit() {
    this.DrawModel9();
    if (this.review) {
      this.elementsOk = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsOK
      );
      this.elementsErr = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsERR
      );
      if (this.show_errors == false) {
        this.showSolution();
      } else if (this.show_errors == true) {
        this.ShowAnswer();
      }
    } else {
      this.initInteract();
    }
  }

  showSolution() {
    this.result = true;
    this.answer = JSON.parse(
      JSON.stringify(this.exercise.palabras[0].palabra)
    );
  }
  ShowAnswer() {
    this.answer = this.exercise.palabras[0].palabra;
    if (this.elementsErr.length > 0) {
      this.result = false;
    } else if (this.elementsOk.length > 0) {
      this.result = true;
    } else {
      this.result = null;
      this.answer = [];
      //Just one word in this version
      for (var i = 0; i < this.exercise.palabras[0].muestra.length; i++) {
        this.answer.push("");
      }
    }
  }

  initInteract() {
    this.exerciseService.initInteract({
      onmove: (event) => {
        this.draggingOption = event.target
      },
      drop: (event) => {
        if (this.finished) return;
        let bin = event.target;
        if (bin.id == "space-for-buttons") {
          this.handleDropDown(this.draggingOption, bin);
        }
        else {
          this.handleDropUp(this.draggingOption, bin);
        }
      },
    });
  }

  handleDropDown(item, bin) {
    this.result = null;
    var angularElement: HTMLElement =
        document.getElementById("space-for-text");
    angularElement.classList.remove("wordError");
    var typeItem = item.getAttribute("type");
    var indexBin = bin.getAttribute("index");
    var indexItem = item.getAttribute("index");
    if (typeItem == "down") return;
    if (typeItem == "up") {
        if (this.answer[indexItem] == "") return;
        this.options.push(this.answer[indexItem]);
        this.answer[indexItem] = "";
    }
}

handleDropUp(item, bin) {
    var angularElement: HTMLElement =
        document.getElementById("space-for-text");
    angularElement.classList.remove("wordError");
    var typeItem = item.getAttribute("type");
    var indexBin = bin.getAttribute("index");
    var indexItem = item.getAttribute("index");
    if (typeItem == "down") {
        if (this.options[indexItem] == "") return;
        if (this.answer[indexBin] == "") {
            this.answer[indexBin] = this.options[indexItem];
            this.options.splice(indexItem, 1);
        } else {
            var tmp = this.answer[indexBin];
            this.answer[indexBin] = this.options[indexItem];
            this.options.splice(indexItem, 1);
            this.options.push(tmp);
        }
    }
    if (typeItem == "up") {
        var tmp = this.answer[indexBin];
        this.answer[indexBin] = this.answer[indexItem];
        this.answer[indexItem] = tmp;
    }

    //Validate if finshed
    for (var i = 0; i < this.answer.length; i++) {
        if (this.answer[i] == "") return;
    }
    for (var i = 0; i < this.answer.length; i++) {
        if (this.answer[i] != this.exercise.palabras[0].palabra[i]) {
            // this.$emit("error");
            var r = [];
            for (var j = 0; j < this.answer.length; j++) {
                r.push(
                    this.exercise.palabras[0].palabra.indexOf(
                        this.answer[j]
                    )
                );
            }
            if (!this.globalError) {
                this.exerciseService.answer(1, false, 1);
                this.globalError = true;
            }
            this.result = false;
            return;
        }
    }

    //OK
    this.result = true;
    this.exerciseService.answer(1, true, 1);
    this.finished = true;
    var angularElement: HTMLElement =
        document.getElementById("space-for-text");
    angularElement.classList.remove("wordError");
    angularElement.classList.add("wordOk");
    this.exerciseService.endExercise()
}

  result

  showAnswersTimeOut() {
    if (this.result != true)
      this.result = null;
    this.answer = (JSON.parse(JSON.stringify(this.exercise.palabras[0].palabra)));
    var angularElement = document.getElementById("space-for-text")
    angularElement.classList.remove("wordError");
    angularElement.classList.add("wordOk");
  }

  DrawModel9() {
    //Just one word in this version
    for (var i = 0; i < this.exercise.palabras[0].muestra.length; i++) {
      this.answer.push("");
    }
    //Clone the first word, just one word in this version
    this.options = (JSON.parse(JSON.stringify(this.exercise.palabras[0].muestra)));
      this.setPositions();
      console.log(this.positions);

    }

    setPositions() {
        let minDistance = 4;
        let r1, r2;
        this.options.forEach(option => {
            do {
                r1 = this.getRandomInt(60, 85);
                r2 = this.getRandomInt(15, 85);
            } while (this.positionExists(r1, r2) || this.tooCloseToOthers(r1, r2, minDistance));
            
          this.positions.push({
            top: r1,
            left: r2
          });
        });
    }

  getRandomInt(min : number, max: number) {
    return Math.floor(Math.random() * (max - min)) + min;
  }


    positionExists(top: number, left: number) {
        for (var j = 0; j < this.positions.length; j++) {
            if (this.positions[j].top === top && this.positions[j].left === left) {
                return true;
            }
        }
        return false;
    }
    tooCloseToOthers(top: number, left: number, minDistance: number) {
        for (var j = 0; j < this.positions.length; j++) {
            
            var otherTop = this.positions[j].top;
            var otherLeft = this.positions[j].left;
            var distance = Math.sqrt(Math.pow(top - otherTop, 2) + Math.pow(left - otherLeft, 2));

            if (distance < minDistance) {
                return true;
            }
        }
        return false;
    }

  getWordPosition(i) {
    return {
      'position': 'absolute',
      'top': this.positions[i].top + '%',
      'left': this.positions[i].left + '%'
    };
  }

}
