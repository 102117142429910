/*
 *ngFor="let c of oneDimArray | sortBy:'asc'"
 *ngFor="let c of arrayOfObjects | sortBy:'asc':'propertyName'"
*/
import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {

  removeAccents(text) {
    if (typeof text !== 'string') return text
    const charMap = {
        'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U',
        'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u',
        'Ñ': 'N', 'ñ': 'n'
      };
  
      return text.replace(/[ÁÉÍÓÚáéíóúÑñ]/g, match => charMap[match]);
  }

  transform(value: any, order: any = '', column: any = ''): any[] {
    if (!value || order === '' || !order) { return value; } // no array
    if (value.length <= 1) { return value; } // array with only one item
    if (!column || column === '') {
      if (order === 'asc') { return value.sort() }
      else { return value.sort().reverse(); }
    } // sort 1d array
    if (Array.isArray(column)) {
      return this.orderBy(value, column, [order]);
    } else {
      return this.orderBy(value, [column], [order]);
    }
  }
  orderBy(arr, columns, orders) {
    let order = "asc"
    if (orders.length > 0) order = orders[0]
    return arr.sort((a,b) => {
      for (let column of columns) {
        if(a[column] == null) a[column] = ""
        if(b[column] == null) b[column] = ""
        let item_a = this.removeAccents(a[column])
        let item_b = this.removeAccents(b[column])
        if (item_a === item_b) {
          continue
        }
          if (order == 'asc') {
              if (typeof item_a !== 'string') {
                return item_a > item_b ? 1 : -1
            }
            return item_a.localeCompare(item_b);
          } else {
            if (typeof item_a !== 'string') {
                return item_a > item_b ? -1 : 1
            }
            return item_b.localeCompare(item_a);
          }
      }
    })
  }
}