<section style="width: 100%; height: 100%;" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch"
    fxLayoutAlign.lt-md="stretch" fxNgClass.gt-sm="spe_gap">
    <div fxFlex="11rem" fxFlex.lt-md="0 0 auto" fxNgClass.lt-md="mob_cont_menu" fxNgClass.gt-sm="scroll_vertical">
        <div class="ww_sidebar_menu" id="mobiMenuFlot">
            <button routerLink="./parentStudents" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"
                class="">
                {{'SCHOOL.PARENT.STUDENTS-TITLE' | translate }}
            </button>

            <button [disabled]="inConfig()" routerLink="./configParents" routerLinkActive="active"
                class="">
                {{'SCHOOL.CONFIG-TITLE' | translate }}
            </button>

            <button routerLink="./myAccount" routerLinkActive="active"
                class="">
                {{'SCHOOL.PARENT.MY-ACCOUNT-TITLE' | translate }}
            </button>

            <button routerLink="./parentReport" routerLinkActive="active" class="">
                {{'SCHOOL.TRACKING-TITLE' | translate }}
            </button>

        </div>
    </div>
    <div fxFlex="" fxFlex.lt-md="0 0 auto" id="">
        <router-outlet></router-outlet>
    </div>
</section>