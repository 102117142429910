<div class="walinwa-box">
    <form [formGroup]="form" class="walinwa-box-content">
        <!--div style="margin-top: -1.3rem; margin-bottom: -1rem;"-->
        <div style="margin-bottom: -1rem;">
            <mat-form-field>
                <label class="font_blue">{{'CLASS' | translate}}</label>
                <mat-select id="select-class" [(ngModel)]="selectedClass" (selectionChange)="changeClass()"
                    [ngModelOptions]="{standalone: true}">
                    <mat-option class="scrollable" [value]="0">{{'SCHOOL.TEACHER.CONFIG.ALL-CLASSES' | translate}}</mat-option>
                    <mat-option class="scrollable" *ngFor="let class of classes | sortBy:'asc':['Level','Code']"
                        [value]="class.IdClass">{{ class.Name }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div>
            {{'SCHOOL.TEACHER.CONFIG.CHALLENGE.TIMEBLOCK' | translate}}
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="spe_gap">
            <div fxFlex="165px" fxFlex.lt-md="" class="custom-form-field">
                <label>{{'SCHOOL.TEACHER.CONFIG.TIMEINI' | translate}}</label>
                <!-- <input [ngClass]="{ 'iPad-time-input': isIpad }" formControlName="RetoIni" type="time"> -->
                <input [ngClass]="{ 'iPad-time-input': isIpad }" formControlName="RetoIni" type="time">
            </div>
            <div fxFlex="165px" fxFlex.lt-md="" class="custom-form-field">
                <label>{{'SCHOOL.TEACHER.CONFIG.TIMEEND' | translate}}</label>
                <!-- <input [ngClass]="{ 'iPad-time-input': isIpad }" formControlName="RetoEnd" type="time"> -->
                <input [ngClass]="{ 'iPad-time-input': isIpad }" formControlName="RetoEnd" type="time">
            </div>
        </div>

        <div class="walinwa-row" fxLayoutAlign="end end" fxLayoutAlign.xs="center center">

            <button (click)="save()" class="new_but azul">
                {{'SAVE' | translate}}
            </button>

        </div>
    </form>

</div>