
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpParams, HttpEvent, HttpHeaderResponse, HttpResponse, HttpErrorResponse, HttpUserEvent, HttpProgressEvent } from '@angular/common/http';
import { Location } from '@angular/common';
import { environment } from 'environments/environment';

@Injectable()
export class Test2Service {

  baseUrl = environment.baseApi + '/api/Exam';

  constructor(private http: HttpClient, private _location: Location) {
  }
  getExamById(idExam){
    return this.http.get(this.baseUrl + '/idExam/' + idExam);
  }
  getByUser(idUser) {
    return this.http.get(this.baseUrl + '/idUser/' + idUser);
  };

  GetExamExercises(IdExam: number) {
    return this.http.get(this.baseUrl + '/Exercises/' + IdExam);
  }
  updateExamName(idExam, name) {
    var exam = { IdExam: idExam, Name: name };
    return this.http.post(this.baseUrl + '/updateExamName/', exam);
  };

  insert(idUser, name) {
    var exam = { Name: name, IdTutor: idUser };
    return this.http.put(this.baseUrl, exam);
  };

  removeExercisesFromExam(idExam) {
    return this.http.get(this.baseUrl + "/removeExercisesFromExam/" + idExam);
  }

  insertExercise(idExam, exercise) {
    return this.http.put(this.baseUrl + "/idExam/" + idExam, exercise);
  };

  remove(exam) {
    return this.http.delete(this.baseUrl + "/idExam/" + exam.IdExam);
  };


  removeExercise(idExam, type, thema) {
    return this.http.delete(this.baseUrl + "/idExam/" + idExam + "/type/" + type + "/thema/" + thema);
  };


}