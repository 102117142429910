import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from 'app/services/course.service';
import { NavigationService } from 'app/services/navigation.service';
import { SchoolService } from 'app/services/school.service';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

declare var $;

@Component({
    selector: "app-edit-class",
    templateUrl: "./edit-class.component.html",
    styleUrls: ["./edit-class.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class EditClassComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  dataLoaded = false;
  class = null;
  formDataClass;
  showTimeInfo = false;
  showSessionInfo = false;
  courses = [];
  teachers = [];
  teachersAux = [];
  selectedClassText = "";
  classLetters = ['', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'Ñ', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  currentUser;
  showSmallSession = false;
  showMultiTime = false;
  multiTimes = [
    {
      value: 1,
      name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-DEFAULT')
    },
    {
        value: -1,
        name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-NO-TIME')
    }, {
      value: 1.5,
      name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-MODERATE')
    }, {
      value: 2,
      name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-MEDIUM')
    }, {
      value: 2.5,
      name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-HIGH')
    }, {
      value: 3,
      name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-HIGHER')
    }, {
      value: 4,
      name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-MAX')
    }
  ];
  state = 1;
  selectedTeacherAux;
  selectedIdUser;
  selectedIdClass;

  constructor(
    private activateRoter: ActivatedRoute,
    private _formBuilder: UntypedFormBuilder,
    private router: Router,
    private courseService: CourseService,
    public schoolService: SchoolService,
    private navigationService: NavigationService,
    private matDialog: MatDialog,
    private _translateService: TranslateService
  ) { 
    this._unsubscribeAll = new Subject();
}

ngOnDestroy(): void
  {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
  }

  ngOnInit() {
    if (!sessionStorage.getItem("currentUser")) return this.navigationService.exit();
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (!this.currentUser) return this.navigationService.exit();
    if (this.navigationService.params.IdUser) this.selectedIdUser = this.navigationService.params.IdUser;
      if (this.navigationService.params.IdClass) this.selectedIdClass = this.navigationService.params.IdClass;
    this.navigationService.enableBackButton(true, this.back.bind(this));
    this.getClass();
    this.courseService.Courses.pipe(takeUntil(this._unsubscribeAll)).subscribe(courses => {
        this.courses = courses;
    })
  }

    back() {
        if (this.state == 1) {
            this.navigationService.go("/school/students", {
                IdUser: this.selectedIdUser,
                IdClass: this.selectedIdClass,
            });
        } else {
            this.changeState();
        }
    }

    isMe(teacher) {
        return teacher.UserTutor.Email == this.currentUser.Email;
    }

    changeState() {
        if (this.state == 1) {
            this.state = 2;
        } else {
            this.state = 1;
        }
    }
    getClass(): void {
        this.schoolService.getClassToEdit(this.selectedIdClass)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(clase => {
                this.successGetClass(clase);
            },
            () => {
                this.errorCallback()
            })
    }
    addTeacherAux() {
        if (this.selectedTeacherAux == null) return;
        this.schoolService.addAuthTeacher(this.class.IdClass, this.selectedTeacherAux)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                () => {
                    this.getClass();
                },() => {
                    this.getClass();
                })
    }

    removeAuthTeacher(teacher) {
        this.schoolService.removeAuthTeacher(this.class.IdClass, teacher.IdUserTutor)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                () => {
                    this.getClass();
                }, () => {
                    this.getClass();
                })
    }

    showCard(num) {
        if (num == 0) {
            if (this.showMultiTime) {
                this.showMultiTime = false;
            } else {
                this.showMultiTime = true;
                this.showSmallSession = false;
            }
        }
        if (num == 1) {
            if (this.showSmallSession) {
                this.showSmallSession = false;
            } else {
                this.showSmallSession = true;
                this.showMultiTime = false;
            }
        }
    }

    updateClass() {
        var codeRegEx = /^[0-9A-Za-z\.]+$/;

        if (
            this.formDataClass.value.Code != "" &&
            this.formDataClass.value.Code != null
        ) {
            let Code = this.formDataClass.value.Code.trim();
            var validName = Code.match(codeRegEx);
            if (validName == null) {
                this.formDataClass.form.controls["Code"].setErrors({
                    incorrect: true,
                });
                return;
            }
        }

        if (
            this.formDataClass.value.Code ==
            this.schoolService.school.Initials.toLowerCase() + "."
        ) {
            this.formDataClass.controls["Code"].setErrors({ required: true });
            return;
        }

        if (this.formDataClass.invalid) return;

    this.schoolService.updateSimpleClass(
      this.class.IdClass,
      this.formDataClass.value.Name,
      this.formDataClass.value.Level,
      this.formDataClass.value.Teacher,
      this.formDataClass.value.Code,   
    )
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        var message = [];
        message[0] = this._translateService.instant('SCHOOL.EDIT-CLASS-TITLE');
        message[1] = this._translateService.instant('SCHOOL.TEACHER.STUDENTS.EDIT-CLASS.MODAL-EDIT-CLASS-MESSAGE');

                    let confirmDialog = this.matDialog.open(
                        FuseDialogContinueComponent,
                        {
                            disableClose: true,
                        }
                    );
                    confirmDialog.componentInstance.title = message[0];
                    confirmDialog.componentInstance.message1 = message[1];
                    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
                    confirmDialog.componentInstance.margin = false;

        confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
          this.navigationService.go("/school/students", {
            IdClass: this.selectedIdClass,
            IdUser: this.selectedIdUser
          })
          confirmDialog = null;
        });
      },
      error => {
        this.errorCallback();
      }
    )
  }

  calculateClassName() {
    var name = "";
    // this.newClassForm.Name.$setValidity("unique", true);
    if (this.formDataClass.Course == null) {
      this.formDataClass.Name = this.selectedClassText;
    }
    if (this.selectedClassText != null) {
      name = this.formDataClass.Name;
      for (var i = 0; i < this.courses.length; i++) {
        if (this.courses[i].Level == this.formDataClass.Level) {
          switch (this.courses[i].Level) {
            case 8:
              name = '3º ' + this.selectedClassText;
              break;
            case 9:
              name = '4º ' + this.selectedClassText;
              break;
            case 10:
              name = '5º ' + this.selectedClassText;
              break;
            case 11:
              name = '6º ' + this.selectedClassText;
              break;
            case 12:
              name = '1º ESO ' + this.selectedClassText;
              break;
            case 13:
              name = '2º ESO ' + this.selectedClassText;
              break;
            case 14:
              name = '3º ESO ' + this.selectedClassText;
              break;
            case 15:
              name = '4º ESO ' + this.selectedClassText;
              break;
            case 16:
              name = '1º Bach. ' + this.selectedClassText;
              break;
            case 17:
              name = '2º Bach. ' + this.selectedClassText;
              break;
            default:
              name = this.courses[i].Name + ' ' + this.selectedClassText;
          }
        }
      }
      this.formDataClass.Name = name;
    }
  }

    successGetClass(response) {
        this.class = response;
        if (this.class.MultTime) {
            this.class.MultTime = this.class.MultTime;
        } else {
            this.class.MultTime = 1;
        }
        this.formDataClass = this._formBuilder.group({
            Level: [this.class.Level],
            Teacher: [this.class.IdTutor],
            TeacherAux: [this.class.TeacherAux],
            Name: [this.class.Name],
            Code: [this.class.Code],
            MultTime: [this.class.MultTime],
            SmallSession: [this.class.SmallSession],
            SmallSessionWeekend: [this.class.SmallSessionWeekend],
            SmallSessionSummer: [this.class.SmallSessionSummer],
            BlockName: [this.class.BlockName],
            BlockAlias: [this.class.BlockAlias],
            BlockContact: [this.class.BlockContact],
            GiftShopDisabled: [this.class.GiftShopDisabled],
            BlockMessageGift: [this.class.BlockMessageGift],
            AvatarDisabled: [this.class.AvatarDisabled],
        });

        setTimeout(() => {
            $("#formCodeId").bind("input propertychange", () => {
                this.resetClass();
            });
        }, 1000);

    this.schoolService.getSchoolTeachers(this.schoolService.school.IdSchool)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetSchoolTeachers.bind(this), this.errorCallback.bind(this));
  }

    resetClass() {
        if (
            this.formDataClass.value.Code == null ||
            this.formDataClass.value.Code.toLowerCase().indexOf(
                this.schoolService.school.Initials.toLowerCase() + "."
            ) != 0
        ) {
            this.formDataClass.patchValue({
                Code: this.schoolService.school.Initials.toLowerCase() + ".",
            });
        }
    }
  successGetSchoolTeachers(response) {
    this.teachers = response;
    this.teachersAux = [];
    for (var i = 0; i < this.teachers.length; i++) {
      if (this.teachers[i].Id == this.class.IdTutor) continue;
      if (this.class.AuthorizedParents.find(a => a.IdUserTutor == this.teachers[i].Id)) continue;
      this.teachersAux.push(this.teachers[i]);
    }
    this.selectedTeacherAux = this.teachersAux.length > 0 ? this.teachersAux[0].Id : null;
    this.dataLoaded = true;
  }

    errorCallback() {
        this.navigationService.exit();
    }
    parseProfesorName(user) {
        return user.FamilyName + ", " + user.Name;
    }
}
