import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { TranslateService } from '@ngx-translate/core';
import { AdminMessageService } from 'app/services/adminMessages.service';
import { AuthService } from 'app/services/auth.service';
import { NavigationService } from 'app/services/navigation.service';
import { UserService } from 'app/services/user.service';
import * as moment from 'moment';

import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContactComponent implements OnInit, OnDestroy, AfterViewInit {
    private _unsubscribeAll: Subject<any>;

  hideStudentMessages = false;
  hideAdminMessages = true;
  hideAskAdmin = true;
  askAdminPass;
  askAdminMessages: any = [];
  hideNewAdminMessage = true;
  hideConversation = true;
  adminMessage;
  adminLogin = false
  searchMessageText;
  selectedMessage;
  calling = false;
  sendingEmail = false;
  conversationMessages = [];
  studentMessages = [];
  formData: any = {}
  attachment: File
  BigFile = false;

  constructor(
    private authService: AuthService,
    private navigationService: NavigationService,
    private adminMessagesService: AdminMessageService,
    private userService: UserService,
    private _matDialog: MatDialog,
    private _translateService: TranslateService
  ) { 
    this._unsubscribeAll = new Subject();
}

ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
}

  ngOnInit(): void {
    if (!this.authService.check()) return
    this.adminLogin = this.authService.currentUser.AdminLogin
    this.userService.GetStudentMessages(this.authService.currentUser).subscribe(
      result => {
        this.successGetStudentMessages(result)
      },
      error => {
        this.errorGetStudentMessages(error)
      }
    )
  }

  ngAfterViewInit(){
    const dudasSugerencias = document.getElementById("dudasSugerenciasFirst");
    dudasSugerencias.click();
  }

  okCallback() {
    this.sendingEmail = false
    this.attachment = null;
    this.formData = {}
    var message = []
    message[0] = this._translateService.instant('STUDENT.CONTACT.MESSAGE-SENT-TEXT1');
    message[1] = this._translateService.instant('STUDENT.CONTACT.MESSAGE-SENT-TEXT2');
    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    })
    confirmDialog.componentInstance.title = message[0]
    confirmDialog.componentInstance.message1 = message[1]
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
    confirmDialog.componentInstance.margin = false
    confirmDialog.componentInstance.margin = false
    confirmDialog.afterClosed().subscribe(result => {
        this.userService.GetStudentMessages(this.authService.currentUser).subscribe(result =>{
            this.successGetStudentMessages(result)
        }, error =>{
            this.errorGetStudentMessages(error)
        });
      //this.navigationService.go('/student/contact', {})
        this.hideStudentMessages = false
    })
  }

  errorCallback() {
    this.sendingEmail = false;
    this.attachment = null;
    this.formData = {}
    this.navigationService.goLogin();
  }

  inputMessage
  messagesFiltered = []

  searchMessages() {
    setTimeout(() => {
      if (this.inputMessage == '') {
        this.messagesFiltered = [];
        return;
      }
      this.adminMessagesService.getAvailableMessages(this.inputMessage, 10).subscribe(
        (result: any) => {
          this.messagesFiltered = result
        },
        error => {
          this.messagesFiltered = []
        }
      );
    }, 100)
  }

  trim(text) {
    return text.toString().trim()
  }

  selectMessage(message) {
    this.selectedMessage = message
    this.adminMessage.textMessage = message.Message
  }

  hideAskAdminF() {
    this.hideAskAdmin = true;
  }

  cancel() {
    this.navigationService.go('/student', {});
  }

  formDataErr1
  formDataErr2

  dudas() {
    this.hideStudentMessages = true
    this.formDataErr1 = false
    this.formDataErr2 = false
  }

  submitForm(formData) {
    this.formDataErr1 = false
    this.formDataErr2 = false
    // this.contactForm.$setSubmitted();
    this.sendingEmail = true;
    if (formData) {
      if (formData.Body == "SignalRDictado" && formData.Subject == "SignalRDictado") {
        this.sendingEmail = false;
        return;
      }
    }

    if (!formData.Subject || formData.Subject == "") {
      this.formDataErr1 = true
      this.sendingEmail = false;
      return
    }

    if (!formData.Body || formData.Body == "") {
      this.formDataErr2 = true
      this.sendingEmail = false;
      return
    }

    if (formData == null || formData.Body == null || formData.Body == '' || formData.Subject == null || formData.Subject == '') {
      this.sendingEmail = false;
      return;
    }
    this.userService.sendInfoEmail(this.authService.currentUser.Id, formData.Body, formData.Subject, this.attachment ? this.attachment : null).subscribe(x =>{
        this.okCallback()
    },error => {
        this.errorCallback();
    });
  };

  reset() {
    this.adminMessage = {};
    this.selectedMessage = null;
    var fecha_actual = new Date();
    var fecha_actual_mas_1_semana = new Date();
    fecha_actual_mas_1_semana.setDate(fecha_actual_mas_1_semana.getDate() + 7);
    this.adminMessage.textMessage = this._translateService.instant('STUDENT.CONTACT.DEFAULT-MESSAGE-TEXT');
    this.inputMessage = ""
    this.adminMessage.startDate = new Date(fecha_actual);
    this.adminMessage.endDate = new Date(fecha_actual_mas_1_semana);
  }

  mis_mensajes() {
    this.hideConversation = true;
    this.hideNewAdminMessage = true;
    this.hideAdminMessages = false;
  }

  conversation() {

    this.askAdminPass = '';
    this.hideAskAdmin = true;
    this.hideStudentMessages = true;
    this.hideAdminMessages = true;
    this.hideNewAdminMessage = true;
    this.hideConversation = false;

    if (!this.hideConversation) {
      this.conversationMessages = [];
      for (var i = 0; i < this.studentMessages.length; i++) {
        this.conversationMessages.push(this.studentMessages[i]);
      }
      for (var i = 0; i < this.askAdminMessages.length; i++) {
        this.conversationMessages.push(this.askAdminMessages[i]);
      }
      this.conversationMessages.sort((a, b) => {
        var date_a;
        var date_b;
        if (a.AdminMessage) {
          date_a = new Date(a.StartDate).getTime();
        } else {
          date_a = new Date(a.Moment).getTime();
        }
        if (b.AdminMessage) {
          date_b = new Date(b.StartDate).getTime();
        } else {
          date_b = new Date(b.Moment).getTime();
        }
        return date_b - date_a;
      });

    }
  }

  newAdminMessage() {
    this.adminMessagesService.addAdminMessage(this.adminMessage.textMessage).subscribe((response) => { this.selectedMessage = response; console.log(response); this.saveMessage(); }, () => { });
  }

  editAdminMessage() {
    this.adminMessagesService.updateMessage(this.selectedMessage.Message, this.adminMessage.textMessage).subscribe(
      result => {
        this.selectedMessage = result;
      },
      error => {

      });
  }
  dosCifras(n) {
    if (parseFloat(n) < 10) {
      return "0" + n;
    }
    return n;
  }

  saveMessage() {

    var tmp1 = this.adminMessage.startDate;
    if (typeof tmp1 == "string") {
      tmp1 = moment(tmp1, 'DD/MM/YYYY hh:mm:ss')
    } else {
      tmp1 = moment(tmp1)
    }

    var tmp2 = this.adminMessage.endDate;
    if (typeof tmp2 == "string") {
      tmp2 = new Date(this.dosCifras(tmp2.split("/")[2]) + "-" + this.dosCifras(tmp2.split("/")[1]) + "-" + this.dosCifras(tmp2.split("/")[0]) + "T00:00:00Z");
    } else {
      tmp2 = moment(tmp2)
    }

    return this.adminMessagesService.addMessage(this.authService.currentUser.Id, this.selectedMessage.IdAdminMessage, tmp1, tmp2).subscribe(() => {

      this.adminMessagesService.getUserMessages(this.authService.currentUser.Id).subscribe((response) => {

        this.adminMessage = undefined;
        this.searchMessageText = undefined;
        this.selectedMessage = undefined;

        this.askAdminMessages = response;
        this.hideNewAdminMessage = true;
        this.hideAdminMessages = false;

      }, () => { });

    }, () => { });
  };

  changeToStudentMessages() {

    if (!this.hideConversation || !this.hideNewAdminMessage || !this.hideAdminMessages) {
      this.hideConversation = false;
      this.hideNewAdminMessage = true;
      this.hideAdminMessages = true;
    } else {
      this.hideStudentMessages = !this.hideStudentMessages;
      this.hideConversation = true;
      this.hideNewAdminMessage = true;
      this.hideAdminMessages = true;
    }

  }

  checkValidForm() {
    if (this.adminMessage.startDate != null && this.adminMessage.endDate != null) return true
    return false
  }
  checkValidAdminForm() {
    if (!this.adminMessage) return false
    if (this.adminMessage.textMessage != null && this.adminMessage.textMessage != "") return true
    return false
  }

  checkNuevoAdminMessage() {
    if (!this.adminMessage) return
    if (this.selectedMessage == null && this.adminMessage && this.adminMessage.textMessage && this.adminMessage.textMessage != "") return false
    return true
  }

  // changeToAdminMessages() {
  //   if (this.hideAdminMessages == false) {
  //     this.hideAdminMessages = true;
  //   } else {
  //     this.hideAskAdmin = false;
  //     // setTimeout(() {
  //     //     $('#askAdminPass').focus();
  //     // }, 1000); 
  //   }
  // }

  showAdminMessage() {
    this.hideNewAdminMessage = false;
    this.hideAdminMessages = true;
    this.hideConversation = true;
    this.reset();
  }

  searchMessage() {
    if (this.searchMessageText == null || this.searchMessageText == '')
      return null;
    this.calling = true;
    return this.adminMessagesService.getAvailableMessages(this.searchMessageText, 10).subscribe((response) => { return response; }, (response) => { });
  };

  changeToAdminMessages() {
    this.askAdminPass = '';
    //this.hideAdminMessages = !this.hideAdminMessages;
    this.hideAskAdmin = true;
    this.hideAdminMessages = true;
    this.hideStudentMessages = true;

    this.adminMessagesService.getUserMessages(this.authService.currentUser.Id).subscribe((response) => { this.askAdminMessages = response; this.conversation(); }, () => { });
  }

  successGetStudentMessages(response) {
    this.studentMessages = response;
    if (this.adminLogin) this.changeToAdminMessages();
  }

  errorGetStudentMessages(error) { }

  saveFile(event){
    if(event.target.files[0].size > 2097152){
        this.BigFile = true;
        return;
    }
    this.BigFile = false;
    this.attachment = event.target.files[0]
  }
}
