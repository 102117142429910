import { AppService } from 'app/app.service';
import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Router, RouterState } from "@angular/router";
import { TurnEnum } from "app/core/shared/enums/turn.enum";
import { SharedService } from "app/core/shared/shared.service";
import { Exerc, Session, ShopItem, ShopItemType, ShopItemUser } from "app/core/shared/state";
import { User } from '../../core/shared/state/models/user.model';
import { AuthService } from "app/services/auth.service";
import { ExerciseService } from "app/services/exercise.service";
import { GlobalService } from "app/services/global.service";
import { HelpService } from "app/services/help.service";
import { NavigationService } from "app/services/navigation.service";
import { SessionService } from "app/services/session.service";
import { ShopService } from "app/services/shop.service";
import { TestExerciseService } from "app/services/test-exercise.service";
import { TestService } from "app/services/test.service";
import { UserService } from "app/services/user.service";
import { VersionService } from "app/services/version.service";
import * as moment from "moment";
import { ExerciseSolvedEnum } from "app/core/shared/enums/exercise-solved.enum";
import { getResult } from 'app/core/shared/helpers/get-result';
import { Theme } from 'app/core/shared/helpers/theme';
import { Shedule } from 'app/core/shared/state/models/shedule.model'
import { CurrentSessionStatusEnum } from "app/core/shared/enums/current-session-status.enum";
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { RetoService } from 'app/services/reto.service';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
declare var $;

@Component({
    selector: "app-exercise",
    templateUrl: "./exercise.component.html",
    styleUrls: ["./exercise.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ExerciseComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    available_items: ShopItemUser[];
    student: User;

    messages: string[] = [];
    item_list: string[] = [];
    okMessageImg: any;
    background2: string;
    status: string;
    fullName: string;
    messageTextTitle: string = "";
    timeOutMessageImg: any;
    alertMsg: string;
    exerciseModel: string;
    timer_column: string;
    timerLineUlr: string;
    background: string;
    diplomaImageUrl: string;
    comic_font: boolean;
    premium: boolean;
    default_logo: boolean;
    specialOk: boolean;
    specialTimeout: boolean;
    noche: boolean;
    showEndSession: boolean;
    showSolved: boolean;
    showMedal: boolean;
    showTrophy: boolean;
    showTimeOut: boolean;
    alreadyCancelled: boolean;
    prevCallFinished: boolean;
    uniqueSolution: boolean;
    removeRetoFromUser: boolean;
    retoFnished: boolean;
    retoFnishedLost: boolean;
    exerciseEnded: boolean;
    timeExpired: boolean;
    continueDisabled: boolean;
    loading: boolean = false;
    homeEnabled: boolean = false;
    helpDisabled: boolean = false;
    showDiploma: boolean = false;
    reto: boolean = false;
    showJump: boolean = false;
    goToNext: boolean = false;
    loadingExercise: boolean = false
    errorFound: boolean = false;
    show_errors: boolean = false;
    review: boolean = false;
    timerEnabled: boolean = true;
    showHelp: boolean = false;
    showTablon: boolean = false;
    flag: boolean = false;
    initial_show_errors: boolean;
    extraWalis = 0;
    skillCoins: number[];
    timeCoins: number[];
    exerciseNumber: number;
    exerciseNumberToShow: string;
    mark: number;
    sessionLength: number;
    sessionLengthToShow: number;
    duracion_session: number;
    duracion_ejercicio: number;
    SkillCoinsWon: number;
    SkillCoins: number;
    TimeCoinsWon: number;
    TimeCoins: number;
    walinwos: number = 0;
    penalty: number = 0;
    exerciseIndex: number = 0;
    sessionId: number;
    exerciseId: number;
    parentReview = false;
    IdClass: number;
    IdCivilization: number = 1;

    SessionStatus = {
        finished: "FINISHED",
        started: "STARTED",
        newSession: "NEW",
        generating: "GENERATING",
        notfinished: "NOTFINISHED",
    };

    horario;
    badges: any = {};
    medal;
    tema;
    help = null;
    buttons = {
        nextExercise: true,
    };
    fileUrl = "https://walinwa.blob.core.windows.net/images/Themes/"
    eventsSubject: Subject<object> = new Subject<object>();
    special: boolean = false;
    directory = "https://walinwa.blob.core.windows.net/images/"
    constructor(
        private navigationService: NavigationService,
        public authService: AuthService,
        private sessionService: SessionService,
        private shopService: ShopService,
        private exerciseService: ExerciseService,
        private helpService: HelpService,
        private userService: UserService,
        private versionService: VersionService,
        private globalService: GlobalService,
        private helper: getResult,
        private theme: Theme,
        private appService: AppService,
        private retoService: RetoService,
        public sharedService: SharedService,
        private _translateService: TranslateService
    ) {
        this._unsubscribeAll = new Subject();
    }
    @HostListener('window:contextmenu', ['$event'])
    contextMenuListener(event: any) {
        event.preventDefault();
    }

    @HostListener('window:message', ['$event'])
    checkEvent(event: any) {
        switch (event.data) {
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.TIMER]:
                if (!this.exerciseService.getCurrentExercise()) return
                this.loadingExercise = false
                let time = Math.floor(this.exerciseService.getSeconds())
                let total_coins = this.exerciseService.getCurrentExercise().TimeCoins
                let total_time = this.exerciseService.getExerciseDefinition().tiempo
                let range = Math.floor(total_time / total_coins)
                if (time % range == 0 && time > 0) {
                    let coins = total_coins - (time / range)
                    if (coins > 0) this.timeCoins = new Array(coins)
                }
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.TIMER_END]:
                this.status = "Saved"
                this.timeExpired = true;
                this.badges.exerciseEnded = false;
                this.badges.timeExpired = true;
                this.homeEnabled = true;
                this.skillCoins = [];
                this.timeCoins = []
                setTimeout(() => {
                    this.exerciseSolved();
                }, 1000)
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.EXERCISE_END]:
                if (this.timeExpired) return
                this.status = "Saved"
                this.exerciseEnded = true;
                this.badges.exerciseEnded = true;
                this.buttons.nextExercise = true;
                this.homeEnabled = true;
                this.exerciseSolved();
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.EXERCISE_SOLVED_MSG_1]:
                this.alertMsg = "messageCircleTimeOk1";
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.EXERCISE_SOLVED_MSG_2]:
                this.timeCoins = [];
                this.exerciseService.getCurrentExercise().TimeCoinsWon = 0;
                this.alertMsg = "messageCircleTimeOk2";
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.CURRENT_EXERCISE_CHANGED]:
                this.status = null;
                this.timeExpired = false
                this.homeEnabled = true;
                this.currentExerciseChanged();
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.EXERCISE_ERROR]:
                this.skillCoins.pop()
                if (this.exerciseService.getCurrentExercise() != null) {
                    if (this.exerciseService.getCurrentExercise().Theme == "tmo" ||
                        this.exerciseService.getCurrentExercise().Theme == "hps" ||
                        this.exerciseService.getCurrentExercise().Theme == "tai")
                        this.openHelp()
                }
                break;
        }
    }
    @HostListener('window:popstate', ['$event'])
    onPopStateHandler(event: any) {
        console.log(event.target.location)
        if (event.target.location.href.includes("student/session") || event.target.location.href.includes("student/challenge") && this.exerciseService.getCurrentExercise() && !this.review) {
            if (this.status != "Saved") {
                this.exerciseSolved(true)
                this.exerciseService.setCurrentExercise(null, false)
            }
        }
    }
    @HostListener('window:pagehide', ['$event'])
    onPageHideHandler() {
        console.log();
        if (this.isIos()) {
            this.saveDataToStorage();
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    onBeforeUnloadHandler() {
        console.log();
        if (!this.isIos()) {
            this.saveDataToStorage();
        }
    }

    isIos() {
        return navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i);
    }

    public get turnsEnum(): typeof TurnEnum {
        return this.turnEnum;
    }

    public get turnEnum(): typeof TurnEnum {
        return TurnEnum;
    }

    public get exercisesSolvedEnum(): typeof ExerciseSolvedEnum {
        return this.exerciseSolvedEnum
    }

    public get exerciseSolvedEnum(): typeof ExerciseSolvedEnum {
        return ExerciseSolvedEnum
    }

    public get currentSessionStatusEnum(): typeof CurrentSessionStatusEnum {
        return this.currentSessionStatuEnum
    }

    public get currentSessionStatuEnum(): typeof CurrentSessionStatusEnum {
        return CurrentSessionStatusEnum
    }

    ngOnDestroy() {
        this.sessionService.currentSession = null;
        this.exerciseService.stopTimer();
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit() {
        if ((!this.isReto()) && this.navigationService.params.session) {
            this.sessionService.getSession(this.navigationService.params.session.IdSession)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(session => {
                    this.navigationService.params.session = session;
                    this.init();
                })
        } else if (this.isReto()) {
            this.retoService.getRetoExercises(this.sessionService.currentSession)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: Exerc[]) => {
                    this.sessionService.currentSession.Exercises = result;
                    this.init();
                })
        }
        else {
            this.init();
        }
    }
    init() {
        if (this.authService.currentUser && this.authService.currentUser.Civilization) {
            this.IdCivilization = this.authService.currentUser.Civilization.IdCivilization
        }
        // mostrar tablon con nota (no)
        this.showTablon = false;

        // comprueba usuario logeado
        if (!this.authService.check()) return;

        // permitir saltar de ejercicio
        if (this.authService.currentUser.OnDemand || this.authService.currentUser.IsAdmin) this.showJump = true;
        // letra comic
        this.comic_font = !(!this.authService.currentUser.Course || this.authService.currentUser.Course.Level > 11);
        if (this.navigationService.params.parentReview) {
            this.parentReview = true;
        }
        if (this.navigationService.params.IdClass) {
            this.IdClass = this.navigationService.params.IdClass;
        }
        // recibimos sesion por parametro
        if (this.navigationService.params.session) {
            // cargamos la sesion
            this.sessionService.currentSession = this.navigationService.params.session;
            // comprobamos si es un reto
            if (this.sessionService.currentSession.IdReto) {
                this.sessionService.reto = true;
                this.reto = true;
            }
            else {
                this.sessionService.reto = false;
                this.reto = false;
            }
        }

        // si no hay sesion volvemos a la vista del calendario
        if (!this.sessionService.currentSession) return this.navigationService.go("student/session");

        // si se recibe parametro "errors" entramos en vista de "revision"
        if (this.navigationService.params.errors != undefined) {
            this.review = true;
            this.exerciseService.review = true;

            this.show_errors = this.navigationService.params.errors;
            this.initial_show_errors = this.show_errors

            if (this.sessionService.currentSession.ScoreNew && this.initial_show_errors) {
                this.sessionService.currentSession.Exercises = this.sessionService.currentSession.Exercises.filter(e => e.SessionOrder > 100 || e.Result === 'OK');
            }
        }

        // ordenamos los ejercicios de la sesion por orden
        this.sessionService.currentSession.Exercises.sort(
            (a, b) => (a.SessionOrder % 100) - (b.SessionOrder % 100)
        );
        // si es una sesion (no es review)
        if (!this.review) {

            this.buttons.nextExercise = false;

            // cargamos el fondo
            this.getAvailableItems(() => {
                this.changeBackground();
            });

            // cargamos el ejercicio
            this.goToNextExercise();

            // iniciamos el event listener
        } else {
            this.homeEnabled = true;
            this.showJump = true;
            // si es una revision, cargamos el usuario de la sesion
            this.userService.getUserById(this.sessionService.currentSession.IdUser)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(
                    (result: User) => {
                        this.successCallbackGet(result);
                    },
                    () => {
                        this.navigationService.goLogin();
                    }
                );
        }

    }
    isReto() {
        let isReto = false;
        if (this.sessionService.currentSession) {
            if (this.sessionService.currentSession.IdReto) {
                isReto = true;
            }
        }
        if (this.navigationService.params.session && this.navigationService.params.session.IdReto) {
            isReto = true;
            this.sessionService.currentSession = this.navigationService.params.session
        }
        return isReto;
    }
    saveDataToStorage() {        
        if (this.status != "Saved") {
            this.exerciseService.getCurrentExercise().EndDate = moment()
            this.exerciseService.getCurrentExercise().Result = "JMP"
            sessionStorage.setItem("jump-exercise", JSON.stringify(this.exerciseService.getCurrentExercise()))
        }
    }
    successCallbackGet(result: User) {

        // cargamos el alumno
        this.student = result;

        // nombre del alumno
        this.fullName = this.student.Name;

        // nombre completo del alumno (si tiene apellido = nombre + apellido)
        if (this.student.FamilyName != null) this.fullName = this.fullName + " " + this.student.FamilyName;

        // sin letra comic
        this.comic_font = false;

        // cargamos su fondo
        this.getAvailableItems(() => {
            this.changeBackground();
        });

        // cargamos el ejercicio de la revision
        this.goToNextExerciseReview();

    }

    goToNextExerciseReview() {

        this.show_errors = this.initial_show_errors
        this.flag = false;
        // por defecto el ejercicio actual se pone a null
        this.exerciseService.setCurrentExercise(null, this.review);

        if (this.show_errors == false) {
console.log("goToNextExerciseReview " + this.exerciseIndex);
            // si hemos dado "revisar sesion" se carga cualquier ejercicio, empezando por el primero
            this.exerciseService.setCurrentExercise(this.sessionService.currentSession.Exercises[this.exerciseIndex], this.review);

        } else {

            // si le damos a revisar errores, se busca el siguiente con errores
            var found = false;
            while (
                found == false &&
                this.sessionService.currentSession != null &&
                this.sessionService.currentSession.Exercises != null &&
                this.sessionService.currentSession.Exercises[
                this.exerciseIndex
                ] != null &&
                this.exerciseIndex <
                this.sessionService.currentSession.Exercises.length
            ) {
                if (
                    (this.sessionService.currentSession.Exercises[
                        this.exerciseIndex
                    ].ElementsERR != null &&
                        this.sessionService.currentSession.Exercises[
                            this.exerciseIndex
                        ].ElementsERR != "[]") ||
                    (this.sessionService.currentSession.Exercises[
                        this.exerciseIndex
                    ].Result != null &&
                        this.sessionService.currentSession.Exercises[
                            this.exerciseIndex
                        ].Result.indexOf("OUT") != -1) ||
                    (this.sessionService.currentSession.Exercises[
                        this.exerciseIndex
                    ].Result != null &&
                        this.sessionService.currentSession.Exercises[
                            this.exerciseIndex
                        ].Result.indexOf("JMP") != -1)
                ) {
                    //HACK 0001265
                    if (
                        this.sessionService.currentSession.Exercises[
                            this.exerciseIndex
                        ].ExerciseModel == "ahorca"
                    ) {
                        if (
                            this.sessionService.currentSession.Exercises[
                                this.exerciseIndex
                            ].Result == "ERR" ||
                            this.sessionService.currentSession.Exercises[
                                this.exerciseIndex
                            ].Result.indexOf("OUT") != -1 ||
                            this.sessionService.currentSession.Exercises[
                                this.exerciseIndex
                            ].Result.indexOf("JMP") != -1 ||
                            this.sessionService.currentSession.Exercises[
                                this.exerciseIndex
                            ].Result.indexOf("ERR-OK") != -1
                        )
                            found = true;
                        else this.exerciseIndex++;
                    } else if (
                        this.sessionService.currentSession.Exercises[
                            this.exerciseIndex
                        ].ExerciseModel == "colocaf"
                    ) {
                        if (
                            this.sessionService.currentSession.Exercises[
                                this.exerciseIndex
                            ].Result.indexOf("OUT") != -1 ||
                            this.sessionService.currentSession.Exercises[
                                this.exerciseIndex
                            ].Result.indexOf("JMP") != -1
                        )
                            found = true;
                        else this.exerciseIndex++;
                    } else if (
                        this.sessionService.currentSession.Exercises[
                            this.exerciseIndex
                        ].ExerciseModel == "parejas"
                    ) {
                        if (
                            this.sessionService.currentSession.Exercises[
                                this.exerciseIndex
                            ].Result.indexOf("OUT") != -1 ||
                            this.sessionService.currentSession.Exercises[
                                this.exerciseIndex
                            ].Result.indexOf("JMP") != -1
                        )
                            found = true;
                        else this.exerciseIndex++;
                    } else found = true;
                } else this.exerciseIndex++;
            }
            if (found) {
                this.errorFound = true;
                this.exerciseService.setCurrentExercise(
                    this.sessionService.currentSession.Exercises[
                    this.exerciseIndex
                    ],
                    this.review
                );
            }
            else {
                setTimeout(() => {
                    this.goHome()
                }, 1000);
                return;
            }
        }
        this.result = "Err";
        this.status = this.show_errors ? "S" : "A";

        this.sessionId = this.sessionService.currentSession.IdSession
        this.penalty = this.sessionService.currentSession.penalty
        if (this.exerciseService.getCurrentExercise()) this.exerciseId = this.exerciseService.getCurrentExercise().ExerciseJson.IdJson

        this.get_duracion();
        this.gotoSubState();
        // comprobamos si el ejercicio tiene errores
        if (this.exerciseService.getCurrentExercise()) {
            let ElementsERR = JSON.parse(this.exerciseService.getCurrentExercise().ElementsERR)
            let ElementsOK = JSON.parse(this.exerciseService.getCurrentExercise().ElementsOK)
            if (this.helper.getResult(this.result) == null) {
                this.show_errors = null
            } else if (ElementsERR.length > 0 && !(this.result == "OK" && this.exerciseService.getCurrentExercise().ExerciseModel.includes("ahorca"))) {
                this.show_errors = true
                this.errorFound = true
            } else {
                this.show_errors = false
            }
        }
        console.log("show errors", this.show_errors)
        console.log("initial show errors", this.initial_show_errors)
    }

    get_duracion() {
        this.duracion_session =
            this.sessionService.currentSession.Duration / 60;
        var index = this.exerciseIndex;
        if (index == null || index <= 0) index = 1;
        var ejercicio_actual =
            this.sessionService.currentSession.Exercises[index - 1];
        var ejercicio_actual_StartDate = new Date(ejercicio_actual.StartDate);
        var ejercicio_actual_EndDate = new Date(ejercicio_actual.EndDate);
        var diff2 = Math.abs(
            ejercicio_actual_StartDate.getTime() -
            ejercicio_actual_EndDate.getTime()
        );
        this.SkillCoinsWon = ejercicio_actual.SkillCoinsWon;
        this.SkillCoins = ejercicio_actual.SkillCoins;
        this.TimeCoinsWon = ejercicio_actual.TimeCoinsWon;
        this.TimeCoins = ejercicio_actual.TimeCoins;
        this.duracion_ejercicio = Math.floor(diff2 / 1000);     
        // this.timerEnabled =
    }

    coins;
    sessionScore;
    sessionScoreNew;
    result;

    gotoSubState() {

        // cargamos la puntuacion
        if (this.sessionService.currentSession != null) {
            this.sessionScore = this.sessionService.currentSession.Score;
            this.sessionScoreNew = null;

            if (this.sessionService.currentSession.ScoreNew && !(this.sessionService.currentSession.Exercises || []).find(e => e.Result === null) && (this.sessionService.currentSession.Exercises || []).find(e => e.SessionOrder > 100))
                this.sessionScoreNew = this.sessionService.currentSession.ScoreNew > this.sessionService.currentSession.Score ? this.sessionService.currentSession.ScoreNew : null;

        }

        // cargamos el numero de ejercicio
        this.exerciseNumber = this.exerciseService.getCurrentExercise() ? this.exerciseService.getCurrentExercise().SessionOrder : 0;
        this.exerciseNumberToShow = this._getExerciseNumberToShow(this.exerciseNumber);

        // si no hay más ejercicios con error volver al calendario
        if (this.exerciseNumber == 0 && this.show_errors) return this.navigationService.go("/student/session")

        // cargamos numero de ejercicios en la sesion
        this.sessionLength = this.exerciseService.getCurrentExercise() ? this.sessionService.currentSession.Exercises.length : 0;
        this.sessionLengthToShow = this.exerciseService.getCurrentExercise() ? this._getTotalExerciseNumberToShow(this.sessionService.currentSession) : 0;

        if (this.show_errors && this.errorFound == false) {
            return;
        }

        if (this.exerciseService.getCurrentExercise() != null) {
            this.coins = new Array(
                this.exerciseService.getCurrentExercise().CoinsWon
            );
            this.result = this.exerciseService.getCurrentExercise().Result;
            this.exerciseModel =
                this.exerciseService.getCurrentExercise().ExerciseModel;

            if (this.exerciseService.getCurrentExercise().ExerciseModel == "") {
                this.goToNextExercise();
            }
        }
        //   else if (this.exerciseService.getCurrentExercise().ExerciseModel == "guionp") {
        //     $state.go('exercise.viewerCommon.viewer1', null, { reload: 'exercise.viewerCommon.viewer1' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "boxes2") {
        //     $state.go('exercise.viewerCommon.viewer2', null, { reload: 'exercise.viewerCommon.viewer2' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "boxes2b") {
        //     $state.go('exercise.viewerCommon.viewer2b', null, { reload: 'exercise.viewerCommon.viewer2b' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "boxes3") {
        //     $state.go('exercise.viewerCommon.viewer3', null, { reload: 'exercise.viewerCommon.viewer3' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "marca" ||
        //     exerciseService.getCurrentExercise().ExerciseModel == "marcar" ||
        //     exerciseService.getCurrentExercise().ExerciseModel.indexOf("marca.") >= 0) {
        //     $state.go('exercise.viewerCommon.viewer4', null, { reload: 'exercise.viewerCommon.viewer4' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "guionf") {
        //     $state.go('exercise.viewerCommon.viewer5', null, { reload: 'exercise.viewerCommon.viewer5' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "orden") {
        //     $state.go('exercise.viewerCommon.viewer6b', null, { reload: 'exercise.viewerCommon.viewer6b' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "unir") {
        //     $state.go('exercise.viewerCommon.viewer7', null, { reload: 'exercise.viewerCommon.viewer7' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "unir2") {
        //     $state.go('exercise.viewerCommon.viewer7', null, { reload: 'exercise.viewerCommon.viewer7' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "unir3") {
        //     $state.go('exercise.viewerCommon.viewer7d', null, { reload: 'exercise.viewerCommon.viewer7d' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "unir4") {
        //     $state.go('exercise.viewerCommon.viewer7e', null, { reload: 'exercise.viewerCommon.viewer7e' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "unir5") {
        //     $state.go('exercise.viewerCommon.viewer7f', null, { reload: 'exercise.viewerCommon.viewer7f' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "unir6") {
        //     $state.go('exercise.viewerCommon.viewer7g', null, { reload: 'exercise.viewerCommon.viewer7g' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "marcaT") {
        //     $state.go('exercise.viewerCommon.viewer8', null, { reload: 'exercise.viewerCommon.viewer8' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "coloca") {
        //     $state.go('exercise.viewerCommon.viewer9', null, { reload: 'exercise.viewerCommon.viewer9' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "marca1") {
        //     $state.go('exercise.viewerCommon.viewer10', null, { reload: 'exercise.viewerCommon.viewer10' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "pregunta") {
        //     $state.go('exercise.viewerCommon.viewer11', null, { reload: 'exercise.viewerCommon.viewer11' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "selec") {
        //     $state.go('exercise.viewerCommon.viewer12', null, { reload: 'exercise.viewerCommon.viewer12' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "mLluvia") {
        //     $state.go('exercise.viewerCommon.viewer13', null, { reload: 'exercise.viewerCommon.viewer13' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "parejas") {
        //     $state.go('exercise.viewerCommon.viewer14', null, { reload: 'exercise.viewerCommon.viewer14' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "marcaf") {
        //     $state.go('exercise.viewerCommon.viewer15', null, { reload: 'exercise.viewerCommon.viewer15' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "rapid") {
        //     $state.go('exercise.viewerCommon.viewer16', null, { reload: 'exercise.viewerCommon.viewer16' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "ahorca") {
        //     $state.go('exercise.viewerCommon.viewer17', null, { reload: 'exercise.viewerCommon.viewer17' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "cruci") {
        //     $state.go('exercise.viewerCommon.viewer18', null, { reload: 'exercise.viewerCommon.viewer18' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "colocaf") {
        //     $state.go('exercise.viewerCommon.viewer19', null, { reload: 'exercise.viewerCommon.viewer19' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "verbos") {
        //     $state.go('exercise.viewerCommon.viewer20', null, { reload: 'exercise.viewerCommon.viewer20' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "selecf") {
        //     $state.go('exercise.viewerCommon.viewer21', null, { reload: 'exercise.viewerCommon.viewer21' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "numer") {
        //     $state.go('exercise.viewerCommon.viewer22', null, { reload: 'exercise.viewerCommon.viewer22' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "boxesn") {
        //     $state.go('exercise.viewerCommon.viewer23', null, { reload: 'exercise.viewerCommon.viewer23' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "sopa") {
        //     $state.go('exercise.viewerCommon.viewer24', null, { reload: 'exercise.viewerCommon.viewer24' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "juego2") {
        //     $state.go('exercise.viewerCommon.viewer25', null, { reload: 'exercise.viewerCommon.viewer25' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "boxes2c") {
        //     $state.go('exercise.viewerCommon.viewer26', null, { reload: 'exercise.viewerCommon.viewer26' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "boxes3c") {
        //     $state.go('exercise.viewerCommon.viewer27', null, { reload: 'exercise.viewerCommon.viewer27' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "boxes4c") {
        //     $state.go('exercise.viewerCommon.viewer28', null, { reload: 'exercise.viewerCommon.viewer28' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "define") {
        //     $state.go('exercise.viewerCommon.viewer29', null, { reload: 'exercise.viewerCommon.viewer29' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "mod1") {
        //     $state.go('exercise.viewerCommon.viewer30', null, { reload: 'exercise.viewerCommon.viewer30' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "flechas") {
        //     $state.go('exercise.viewerCommon.viewer31', null, { reload: 'exercise.viewerCommon.viewer31' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "mod2") {
        //     $state.go('exercise.viewerCommon.viewer32', null, { reload: 'exercise.viewerCommon.viewer32' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "mod3") {
        //     $state.go('exercise.viewerCommon.viewer33', null, { reload: 'exercise.viewerCommon.viewer33' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "img") {
        //     $state.go('exercise.viewerCommon.viewer34', null, { reload: 'exercise.viewerCommon.viewer34' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "pasap") {
        //     $state.go('exercise.viewerCommon.viewer35', null, { reload: 'exercise.viewerCommon.viewer35' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "pasap1") {
        //     $state.go('exercise.viewerCommon.viewer36', null, { reload: 'exercise.viewerCommon.viewer36' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "sopa1") {
        //     $state.go('exercise.viewerCommon.viewer37', null, { reload: 'exercise.viewerCommon.viewer37' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "sopa2") {
        //     $state.go('exercise.viewerCommon.viewer38', null, { reload: 'exercise.viewerCommon.viewer38' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "verbos1") {
        //     $state.go('exercise.viewerCommon.viewer39', null, { reload: 'exercise.viewerCommon.viewer39' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "ahorcafr") {
        //     $state.go('exercise.viewerCommon.viewer40', null, { reload: 'exercise.viewerCommon.viewer40' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "frase") {
        //     $state.go('exercise.viewerCommon.viewer41', null, { reload: 'exercise.viewerCommon.viewer41' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "opc1") {
        //     $state.go('exercise.viewerCommon.viewer42', null, { reload: 'exercise.viewerCommon.viewer42' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "rima") {
        //     $state.go('exercise.viewerCommon.viewer43', null, { reload: 'exercise.viewerCommon.viewer43' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "ahorcai") {
        //     $state.go('exercise.viewerCommon.viewer44', null, { reload: 'exercise.viewerCommon.viewer44' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "ahorcar") {
        //     $state.go('exercise.viewerCommon.viewer45', null, { reload: 'exercise.viewerCommon.viewer45' });
        //   } else if (exerciseService.getCurrentExercise().ExerciseModel == "coloca1") {
        //     $state.go('exercise.viewerCommon.viewer46', null, { reload: 'exercise.viewerCommon.viewer46' });
        //   } else
        //     this.goHome();
        // } else {
        //   if (this.exerciseIndex > 0)
        //     this.goHome();
        // }
    }

    currentExerciseChanged() {
        if (!this.exerciseService.getCurrentExercise()) return
        this.exerciseNumber = this.exerciseService.getCurrentExercise().SessionOrder
        this.exerciseNumberToShow = this.exerciseNumberToShow = this._getExerciseNumberToShow(this.exerciseNumber);

        if (this.sessionService.currentSession) this.sessionScore = this.sessionService.currentSession.Score;
        this.helpDisabled = this.exerciseService.getCurrentExercise().Theme != "ads" &&
            this.exerciseService.getCurrentExercise().Theme != "art" &&
            this.exerciseService.getCurrentExercise().Theme != "bv" &&
            this.exerciseService.getCurrentExercise().Theme != "dip" &&
            this.exerciseService.getCurrentExercise().Theme != "gj" &&
            this.exerciseService.getCurrentExercise().Theme != "gtl" &&
            this.exerciseService.getCurrentExercise().Theme != "h" &&
            this.exerciseService.getCurrentExercise().Theme != "c" &&
            this.exerciseService.getCurrentExercise().Theme != "hps" &&
            this.exerciseService.getCurrentExercise().Theme != "lly" &&
            this.exerciseService.getCurrentExercise().Theme != "mbp" &&
            this.exerciseService.getCurrentExercise().Theme != "pco" &&
            this.exerciseService.getCurrentExercise().Theme != "prf" &&
            this.exerciseService.getCurrentExercise().Theme != "r" &&
            this.exerciseService.getCurrentExercise().Theme != "suf" &&
            this.exerciseService.getCurrentExercise().Theme != "sya" &&
            this.exerciseService.getCurrentExercise().Theme != "tai" &&
            this.exerciseService.getCurrentExercise().Theme != "til" &&
            this.exerciseService.getCurrentExercise().Theme != "tmo" &&
            this.exerciseService.getCurrentExercise().Theme != "sig" &&
            this.exerciseService.getCurrentExercise().Theme != "ver";

        this.sessionLength = this.sessionService.currentSession.Exercises.length;
        this.sessionLengthToShow = this.exerciseService.getCurrentExercise() ? this._getTotalExerciseNumberToShow(this.sessionService.currentSession) : 0;
    }

    exerciseSolved(jmp = false) {
        const currentExercise = this.exerciseService.getCurrentExercise();
        if (!currentExercise) return;
    
        currentExercise.EndDate = this.getNow();
    
        const {
            NumElementsERR,
            NumElementsOK,
            NumItemToEvaluate,
            ExerciseModel
        } = currentExercise;
    
        if (NumElementsERR > 0) {
            if (ExerciseModel.includes("ahorca") && this.timeExpired) {
                currentExercise.Result = "OUT";
            } else if (!ExerciseModel.includes("ahorca")) {
                currentExercise.Result = "ERR-OK";
            }
        } else if (
            NumElementsOK !== NumItemToEvaluate &&
            ["rapid", "pasap"].includes(ExerciseModel)
        ) {
            currentExercise.Result = "OK-OUT";
        } else if (
            NumElementsOK === NumItemToEvaluate &&
            !(ExerciseModel === "pasap1")
        ) {
            currentExercise.Result = "OK";
        }
    
        if (jmp) {
            
            if (
                NumElementsERR > 0 &&
                !ExerciseModel.includes("ahorca")
            ) {
                currentExercise.Result = "ERR-JMP";
            } else if (
                NumElementsOK > 0 &&
                !(ExerciseModel === "pasap1" || ExerciseModel.includes("ahorca"))
            ) {
                currentExercise.Result = "OK-JMP";
            } else {
                currentExercise.Result = "JMP";
            }
        }
    
        this.status = "Saving";
        this.UpdateExerciseFinished(jmp);
    }

    UpdateExerciseFinished(jmp = false) {
        if (this.sessionService.reto && !jmp) {
            this.exerciseService
                .updateExercReto(this.exerciseService.getCurrentExercise(), this.authService.currentUser.IsAdmin)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(() => {
                    //this.successCallbackUpdateExerciseOk();
                }, error => {
                    this.errorCallbackUpdateExercise(error);
                });

                this.successCallbackUpdateExerciseOk();

        } else if (this.sessionService.reto && jmp) {
            this.exerciseService
                .updateExercReto(this.exerciseService.getCurrentExercise(), this.authService.currentUser.IsAdmin)
                .subscribe(() => {
                    //this.successCallbackUpdateExerciseOk();
                }, error => {
                    this.errorCallbackUpdateExercise(error);
                });

                this.successCallbackUpdateExerciseOk();

        } else if (!jmp) {
            this.exerciseService
                .updateExercise(this.exerciseService.getCurrentExercise(),this.authService.currentUser.IsAdmin)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                    //this.successCallbackUpdateExerciseOk();
                }, (err) => {
                    this.errorCallbackUpdateExercise(err);
                });

                this.successCallbackUpdateExerciseOk();

        } else if (jmp) {
            this.exerciseService
                .updateExercise(this.exerciseService.getCurrentExercise(), this.authService.currentUser.IsAdmin)
                .subscribe((result: any) => {
                    //this.successCallbackUpdateExerciseOk();
                }, (err) => {
                    this.errorCallbackUpdateExercise(err);
                });

                this.successCallbackUpdateExerciseOk();
        }
    }

    errorCallbackUpdateExercise(error) {
        if (error == "InvalidTimeException") {
            var message = [];
            message[0] = this._translateService.instant('STUDENT.EXERCISE.MODAL-INVALID-TIME-TITLE');
            message[1] =
            this._translateService.instant('STUDENT.EXERCISE.MODAL-INVALID-TIME-MESSAGE');
            // var modalInstance = $uibModal.open({
            //     animation: this.animationsEnabled,
            //     backdrop: 'static',
            //     templateUrl: './pages/templates/message.html',
            //     controller: 'messageController',
            //     resolve: {
            //         messages: function () {
            //             return message;
            //         }
            //     }
            // });

            // modalInstance.result.then(function () {
            //     $state.go('studentMenu');
            // }, function () {
            //     $state.go('studentMenu');
            // });
            this.navigationService.go("student/session");

            return;
        }

        this.navigationService.goLogin();
    }

    successCallbackUpdateExerciseOk() {
        if (this.status != "Saving") return;
        this.status = "Saved";
        this.showSolved = true;
        $(window).bind("keypress", (event) => {
            if ((event.which == 13 || event.keyCode == 13) && this.status == "Saved") {
                this.continue();
                $(window).unbind("keypress");
            }
        });
    }

    getBackgroundStyle() {
        if(window.innerWidth < 959){
            return {
                'background-image': "url('https://walinwa.blob.core.windows.net/images/back-sessions-mobile.svg')"
            }
        }
        let bgStyle = '';
        for (var i = 0; i < this.item_list.length; i++) {
            bgStyle += 'url(' + this.item_list[i] + '),';
        }

        if (this.background2)
            bgStyle += 'url(' + this.background2 + '),';

        if (this.background) {
            return {
                'background-image': bgStyle + ' url(' + this.background + ')'
            }
        }

        return {};
    }

    getAvailableItems(callback) {
        this.available_items = [];
        let today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        this.shopService.BoughtItems.forEach(userItem => {

            if (userItem.ShopItem.IdCivilization != 9) {

                if (userItem.ShopItem.IdCivilization == this.authService.currentUser.Civilization.IdCivilization) {
                    this.available_items.push(userItem);
                }
            } else if (new Date(userItem.ShopItem.specialFrom2).getTime() <= today.getTime() &&
                new Date(userItem.ShopItem.specialTo2).getTime() >= today.getTime()) {
                this.available_items.push(userItem);
            }
        })
        callback();
    }

    changeBackground() {
        // //CAMBIO DE FONDO SEGUN TIENDA
        if (!this.available_items) {
            this.background = this.fileUrl + this.authService.currentUser.Civilization.ImgFolder + "/shop/" + this.authService.currentUser.Civilization.IdCivilization + "10.jpg";
            this.background2 = this.fileUrl + this.authService.currentUser.Civilization.ImgFolder + "/background.png";
            this.timerLineUlr = this.fileUrl + this.authService.currentUser.Civilization.ImgFolder + "/TimerLine.png";
        } else {
            let active_map = null;
            let active_items: ShopItem[] = [];
            let active_msg: ShopItem[] = [];
            let active_others: ShopItem[] = [];
            this.available_items = this.available_items.sort((a, b) => a.IdShopItem - b.IdShopItem);
            this.available_items.forEach((element: ShopItemUser) => {
                if (element != null && element.ShopItem != null) {
                    if (element.Status == true && (element.ShopItem.Type == 1 || element.ShopItem.Type == 2)) {
                        active_map = element.ShopItem;
                    } else if (element.Status == true && (element.ShopItem.Type == 3 || element.ShopItem.Type == 5 || element.ShopItem.Type == 11)) {
                        active_items.push(element.ShopItem);
                    } else if (element.Status == true && (element.ShopItem.Type == 4 || element.ShopItem.Type == 6)) {
                        active_msg.push(element.ShopItem);
                    } else if (element.Status == true && element.ShopItem.Type == 7 || element.ShopItem.Type == 8 || element.ShopItem.Type == 9) {
                        active_others.push(element.ShopItem);
                    }
                }
            });

            if (!active_map) {
                this.background = this.fileUrl + this.authService.currentUser.Civilization.ImgFolder + "/shop/" + this.authService.currentUser.Civilization.IdCivilization + "10.jpg";
                this.background2 = this.fileUrl + this.authService.currentUser.Civilization.ImgFolder + "/background.png";
                this.timerLineUlr = this.fileUrl + this.authService.currentUser.Civilization.ImgFolder + "/TimerLine.png";
                this.timer_column = null;
            } else if (active_map.Type == 1) {
                this.premium = false;
                this.background2 = this.fileUrl + this.authService.currentUser.Civilization.ImgFolder + "/background.png";
                this.timerLineUlr = this.fileUrl + this.authService.currentUser.Civilization.ImgFolder + "/TimerLine.png";
                this.timer_column = null;
                if (active_map.IdCivilization != 9) {
                    this.background = this.fileUrl + this.authService.currentUser.Civilization.ImgFolder + "/shop/" + active_map.IdShopItem + ".jpg";
                }
                else {
                    console.log("entro special")
                    this.background = this.fileUrl + "Special/" + active_map.IdShopItem + ".jpg";
                    this.background2 = this.fileUrl + "Special/background.png"
                    this.timerLineUlr = this.fileUrl + "Special/TimerLine.svg"
                    this.special = true;
                }
                    
                
            } else {
                this.timerLineUlr = this.fileUrl + this.authService.currentUser.Civilization.ImgFolder + "/TimerLine.png";
                this.premium = true;
                //let tmp_active_items: ShopItem[] = [];
                //active_items.find(element => { if (element.Type == 11) { tmp_active_items.push(element) } })
                //active_items = tmp_active_items;
                // if (!$rootScope.horarioOneDemand || $rootScope.horarioOneDemand == null) {
                this.sessionService.getActualSchedule()
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result: Shedule) => {
                        this.successGetActualSchedule(result);
                    });
            }

            this.default_logo = true;
            this.item_list = [];
            active_items.sort((a, b) => b.IdShopItem - a.IdShopItem);
            // El logo siempre el primero
            if (this.premium != true) {
                if (this.special != true) {
                    active_items.forEach(element => {
                        if (element.Type == 11) {
                            this.default_logo = false;
                            this.item_list.push(this.fileUrl + this.authService.currentUser.Civilization.ImgFolder + "/shop/" + element.IdShopItem + ".gif");
                            this.item_list.push(this.fileUrl + this.authService.currentUser.Civilization.ImgFolder + "/shop/" + element.IdShopItem + ".png");
                        }
                        else if (element.Type == 3) {
                            this.item_list.push(this.fileUrl + this.authService.currentUser.Civilization.ImgFolder + "/shop/" + element.IdShopItem + ".gif");
                            this.item_list.push(this.fileUrl + this.authService.currentUser.Civilization.ImgFolder + "/shop/" + element.IdShopItem + ".png");
                        } else if (element.Type == 5) {
                            this.item_list.push(this.fileUrl + this.authService.currentUser.Civilization.ImgFolder + "/shop/" + element.IdShopItem + ".gif");
                            this.item_list.push(this.fileUrl + this.authService.currentUser.Civilization.ImgFolder + "/shop/" + element.IdShopItem + ".png");
                        }
                    })
                }
                
            }
            

            this.okMessageImg = this.getImgUrl("message_backgroud_small_new.png");
            this.timeOutMessageImg = this.getImgUrl("message_backgroud_new.png");
            this.specialOk = false;
            this.specialTimeout = false;
            active_msg.forEach((element: ShopItem) => {
                if (element.Type == 4) {
                    if (element.IdCivilization != 9) {
                        this.okMessageImg = this.fileUrl + this.authService.currentUser.Civilization.ImgFolder + "/shop/" + element.IdShopItem + ".png";
                    } else {
                        this.okMessageImg = this.fileUrl + "Special/" + element.IdShopItem + ".png";
                        this.specialOk = true;
                    }
                } else if (element.Type == 6) {
                    if (element.IdCivilization != 9) {
                        this.timeOutMessageImg = this.fileUrl + this.authService.currentUser.Civilization.ImgFolder + "/shop/" + element.IdShopItem + ".png";
                    }
                    else {
                        this.timeOutMessageImg = this.fileUrl + "Special/" + element.IdShopItem + ".png";
                        this.specialTimeout = true;
                    }
                }
            });
        }
    }
    getPremiumFireClass() {
        switch (this.IdCivilization) {
            case 1:
                return 'fprehistoria';
            case 2:
                return 'fegipto';
            case 3:
                return 'froma';
            case 4:
                return 'fesquimo';
            case 5:
                return 'fmaya';
            case 6:
                return 'fchina';
            case 7:
                return 'farabe';
            case 8:
                return 'fverano';
            default:
                return 'fprehistoria';
        }
    }
    successGetActualSchedule(result: Shedule) {
        this.horario = result.Name;
        if (this.exerciseService.horario != null) {
            this.horario = this.exerciseService.horario;
            this.exerciseService.horario = null;
        }
        let horario = this.horario;
        
        if (horario == "mañana") horario = this.turnsEnum[this.turnsEnum.manana];
        (horario == this.turnsEnum[this.turnsEnum.noche]) ? this.noche = true : this.noche = false;
        this.background2 = null;
        this.background = this.fileUrl + this.authService.currentUser.Civilization.ImgFolder + "/premium/" + horario + ".jpg";

        //background2 - Leo
        //this.timer_column = this.fileUrl + this.authService.currentUser.Civilization.ImgFolder + "/" + horario + "/TimerColumn.png";

        this.timerLineUlr = this.fileUrl + this.authService.currentUser.Civilization.ImgFolder + "/TimerLine.png";
        this.horario = {
            name: horario,
            background: this.background,
            //timerColumn: this.timer_column,
        };
    }

    getErrores() {
        if (!this.exerciseService.getCurrentExercise()) return 0;
        return this.exerciseService.getCurrentExercise().NumElementsERR;
    }

    reset() {
        if (this.exerciseNumber != this.sessionLength) this.exerciseModel = null
        this.timeExpired = false;
        this.exerciseEnded = false;
        this.buttons = {
            nextExercise: false,
        };
        this.badges = {
            timeExpired: false,
            exerciseEnded: false,
        };
        this.exerciseService.reset();
    }

    goToNextExercise() {
        this.loadingExercise = true
        this.alreadyCancelled = false;
        this.badges.timeExpired = false
        this.status = null;
        if (this.exerciseNumber != this.sessionLength) this.exerciseModel = null
        var n1 = null;
        this.exerciseService.disableShowSolution();
        if (this.exerciseService.getCurrentExercise() != null)
            n1 = this.exerciseService.getCurrentExercise().SessionOrder;

        if (
            this.exerciseService.getCurrentExercise() != null &&
            this.exerciseService.getCurrentExercise().StartDate != null &&
            this.exerciseService.getCurrentExercise().EndDate != null
        ) {
            this.exerciseService.setCurrentExercise(null, this.review);
        }
        if (
            this.exerciseService.getCurrentExercise() != null &&
            this.sessionService.currentSession != null &&
            this.exerciseService.getCurrentExercise().IdSession !=
            this.sessionService.currentSession.IdSession
        )
            this.exerciseService.setCurrentExercise(null, this.review);
        if (this.sessionService.currentSession.Exercises != null) {

            this.sessionService.currentSession.Exercises.forEach((element: Exerc, index) => {
                if (this.sessionService.reto) this.reto = true;
                if (
                    (
                        this.exerciseService.getCurrentExercise() == null ||
                        (
                            this.exerciseService.getCurrentExercise().StartDate != null &&
                            this.exerciseService.getCurrentExercise().EndDate != null
                        )
                    ) &&
                    (
                        element.StartDate == null ||
                        element.EndDate == null
                    )
                ) {
                    this.exerciseService.setCurrentExercise(element, this.review);
                    //(this.sessionService.currentSession.Exercises.length == index + 1) ? this.homeEnabled = false : this.homeEnabled = true
                    index = this.sessionService.currentSession.Exercises.length;
                }
            })
        }

        if (this.exerciseService.getCurrentExercise() != null) {
            this.skillCoins = new Array(
                this.exerciseService.getCurrentExercise().SkillCoins
            );
            this.timeCoins = new Array(
                this.exerciseService.getCurrentExercise().TimeCoins
            );
            this.continueDisabled = false;
            this.showSolved = false;
            if (!this.exerciseService.getCurrentExercise().loads) {
                this.exerciseService.getCurrentExercise().loads = 1;
            } else
                this.exerciseService.getCurrentExercise().loads =
                    this.exerciseService.getCurrentExercise().loads + 1;
            this.exerciseService.getCurrentExercise().StartDate = this.getNow();
            this.exerciseNumber = this.exerciseService.getCurrentExercise().SessionOrder;
            this.exerciseNumberToShow = this._getExerciseNumberToShow(this.exerciseNumber);
            this.sessionLength = this.sessionService.currentSession.Exercises.length;
            this.sessionLengthToShow = this._getTotalExerciseNumberToShow(this.sessionService.currentSession);
            this.prevCallFinished = false;
            this.uniqueSolution = false;
            this.alertMsg = null;
            this.exerciseModel =
                this.exerciseService.getCurrentExercise().ExerciseModel;
            this.timerEnabled = !(this.exerciseService.getFullSeconds() <= 0 || this.authService.currentUser.MultTime === -1);
            this.badges.exerciseEnded = false;
            this.loading = false;
        } else {
            //Finish the session or the challenge
            if (this.sessionService.reto) {
                let count = 0;
                this.sessionService.currentSession.Exercises.forEach(ejercicio => {
                    if (ejercicio.EndDate != null) {
                        count++;
                    }
                });
                if (count == this.sessionService.currentSession.Exercises.length) {
                    this.sessionService.currentSession.Status = this.currentSessionStatusEnum[this.currentSessionStatusEnum.Finalizado];

                    this.retoService
                        .updateInvitacion(
                            this.sessionService.currentSession,
                            this.authService.currentUser.OnDemand ||
                            this.authService.currentUser.IsAdmin
                        )

                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe({
                            next: this.successCallbackFinishReto.bind(this),
                            error: this.navigationService.goLogin.bind(this)
                        });
                } else {
                    this.globalService.clientError("updateInvitacion, se intento de terminar el reto antes de que esten los 10 ejercicios hechos, currentSession: " + JSON.stringify(this.sessionService.currentSession))
                }
            } else {
                if ((this.sessionService.currentSession.Status != this.SessionStatus.finished) || (this.sessionService.currentSession.Status === this.SessionStatus.finished && !!(this.sessionService.currentSession.Exercises || []).find(e => e.SessionOrder > 100))) {
                    this.sessionService.currentSession.Status = this.SessionStatus.finished;
                    this.sessionService.updateSession(
                        this.sessionService.currentSession,
                        this.authService.currentUser.OnDemand ||
                        this.authService.currentUser.IsAdmin
                    )

                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe({
                            next: this.successCallbackFinishSession.bind(this),
                            error: this.navigationService.goLogin.bind(this)
                        });
                }
            }
        }
        this.homeEnabled = this.review;
    }

    continueReviewDisabled() {
        return this.exerciseIndex == this.sessionLength - 1
    }

    exerciseBackDisabled() {
        return this.exerciseIndex == 0
    }

    // reviewNextExercise (){
    //     this.exerciseService.setCurrentExercise(null);
    //     if (this.errors == false) {
    //         //Show any exercise
    //         if (sessionService.currentSession.Exercises != null && sessionService.currentSession.Exercises[this.exerciseIndex] != null && sessionService.currentSession.Exercises[this.exerciseIndex].StartDate != null || this.exerciseIndex == 0) {
    //             exerciseService.setCurrentExercise(sessionService.currentSession.Exercises[this.exerciseIndex]);
    //         }
    //     }
    //     else {
    //         //search next exercise with errors
    //         var found = false
    //         while (found == false &&
    //             this.sessionService.currentSession != null &&
    //             this.sessionService.currentSession.Exercises != null &&
    //             this.sessionService.currentSession.Exercises[this.exerciseIndex] != null &&
    //             this.exerciseIndex < this.sessionService.currentSession.Exercises.length) {
    //             if ((this.sessionService.currentSession.Exercises[this.exerciseIndex].ElementsERR != null &&
    //                 this.sessionService.currentSession.Exercises[this.exerciseIndex].ElementsERR != '[]') ||
    //                 (this.sessionService.currentSession.Exercises[this.exerciseIndex].Result != null && this.sessionService.currentSession.Exercises[this.exerciseIndex].Result.indexOf('OUT') != -1 )||
    //                 (this.sessionService.currentSession.Exercises[this.exerciseIndex].Result != null && this.sessionService.currentSession.Exercises[this.exerciseIndex].Result.indexOf('JMP') != -1)) {
    //                 //HACK 0001265
    //                 if (this.sessionService.currentSession.Exercises[this.exerciseIndex].ExerciseModel == "ahorca") {
    //                     if (this.sessionService.currentSession.Exercises[this.exerciseIndex].Result == 'ERR' ||
    //                         this.sessionService.currentSession.Exercises[this.exerciseIndex].Result.indexOf('OUT') != -1 ||
    //                         this.sessionService.currentSession.Exercises[this.exerciseIndex].Result.indexOf('JMP') != -1)
    //                         found = true;
    //                     else
    //                         this.exerciseIndex++;
    //                 } else if (this.sessionService.currentSession.Exercises[this.exerciseIndex].ExerciseModel == "colocaf") {
    //                     if (this.sessionService.currentSession.Exercises[this.exerciseIndex].Result.indexOf('OUT') != -1 ||
    //                         this.sessionService.currentSession.Exercises[this.exerciseIndex].Result.indexOf('JMP') != -1)
    //                         found = true;
    //                     else
    //                         this.exerciseIndex++;
    //                 } else if (this.sessionService.currentSession.Exercises[this.exerciseIndex].ExerciseModel == "parejas") {
    //                     if (this.sessionService.currentSession.Exercises[this.exerciseIndex].Result.indexOf('OUT') != -1 ||
    //                         this.sessionService.currentSession.Exercises[this.exerciseIndex].Result.indexOf('JMP') != -1)
    //                         found = true;
    //                     else
    //                         this.exerciseIndex++;
    //                 }
    //                 else
    //                     found = true;
    //             }
    //             else
    //                 this.exerciseIndex++;
    //         }
    //         if (found) {
    //             this.errorFound = true;
    //             this.exerciseService.setCurrentExercise(this.sessionService.currentSession.Exercises[this.exerciseIndex]);
    //         }
    //     }
    //     this.get_duracion();
    //     this.gotoSubState();
    // }

    solTitle;

    getIconResult() {
        this.solTitle = "Ver solución";
        switch (this.show_errors) {
            case null:
                if(this.exerciseService.getCurrentExercise() && this.exerciseService.getCurrentExercise().Result.includes("JMP")){
                    return this.getImgUrl("saltar.svg")
                }
                return this.getImgUrl("ico_out.svg")
            case true:
                return this.getImgUrl("ico_err.png")
            case false:
                this.solTitle = "Ver respuesta";
                return this.getImgUrl("ico_ok.png")
        }
    }

    showAnswer() {
        if (this.show_errors == null) {
            this.flag = true;
            this.dispatchEvent(this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_SOLUTION], false)
        }
        else {
            let ElementsERR = JSON.parse(this.exerciseService.getCurrentExercise().ElementsERR)
            if (ElementsERR.length == 0 && this.flag != true) {
                //this.dispatchEvent("SHOW_SOLUTION", false)
            }
            else {
                let event_name = this.show_errors ? this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_SOLUTION] : this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_ANSWER]
                this.dispatchEvent(this.flag ? this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_CUSTOM] : event_name, this.flag ? null : this.show_errors ? false : true)
            }
        }
    }
    dispatchEvent(event_name: string, show_errors) {
        if (show_errors) {
            let ElementsERR = JSON.parse(this.exerciseService.getCurrentExercise().ElementsERR)
            if (ElementsERR.length == 0) {
                this.show_errors = null;
            }
        }
        else {
            this.exerciseService.disableShowSolution();
        }
        this.status = show_errors ? 'A' : 'S';
        window.postMessage(event_name, "*");
        this.eventsSubject.next({ event: event_name });
        this.show_errors = show_errors;
    }
    exerciseBack() {
        if (this.exerciseBackDisabled()) return
        this.exerciseModel = null
        setTimeout(() => {
            this.goToPreviousExercise()
        }, 500)
    }

    goToPreviousExercise() {
        this.flag = false
        let currentIndex = this.exerciseIndex - 1

        // if (currentIndex <= 0) return;
        // // this.exerciseIndex = this.exerciseIndex - 2;


        this.exerciseIndex--

        if (this.initial_show_errors == false) {
            //Show any exercise
            if (
                this.sessionService.currentSession.Exercises != null &&
                this.sessionService.currentSession.Exercises[
                this.exerciseIndex
                ] != null
            ) {
                this.exerciseService.setCurrentExercise(
                    this.sessionService.currentSession.Exercises[
                    this.exerciseIndex
                    ],
                    this.review
                );
            }
        } else if (this.initial_show_errors = true) {
            //search next exercise with errors
            var found = false;

            while (
                found == false &&
                this.sessionService.currentSession.Exercises != null &&
                this.sessionService.currentSession.Exercises[
                this.exerciseIndex
                ] != null &&
                this.exerciseIndex >= 0
            ) {
                if (
                    (this.sessionService.currentSession.Exercises[
                        this.exerciseIndex
                    ].ElementsERR != null &&
                        this.sessionService.currentSession.Exercises[
                            this.exerciseIndex
                        ].ElementsERR != "[]") ||
                    (this.sessionService.currentSession.Exercises[
                        this.exerciseIndex
                    ].Result &&
                        (this.sessionService.currentSession.Exercises[
                            this.exerciseIndex
                        ].Result.indexOf("OUT") != -1 ||
                            this.sessionService.currentSession.Exercises[
                                this.exerciseIndex
                            ].Result.indexOf("JMP") != -1))
                )
                    found = true;
                else this.exerciseIndex--;
            }
            if (!found) {
                return this.navigationService.go("/student/session")
            }
            this.exerciseService.setCurrentExercise(
                this.sessionService.currentSession.Exercises[
                this.exerciseIndex
                ],
                this.review
            );
        }
        if (this.exerciseService.getCurrentExercise()) this.exerciseId = this.exerciseService.getCurrentExercise().ExerciseJson.IdJson
        this.get_duracion();
        this.gotoSubState();

        // comprobamos si el ejercicio tiene errores
        let ElementsERR = JSON.parse(this.exerciseService.getCurrentExercise().ElementsERR)
        let ElementsOK = JSON.parse(this.exerciseService.getCurrentExercise().ElementsOK)

        if (this.helper.getResult(this.result) == null) {
            this.show_errors = null
        } else if (ElementsERR.length > 0) {
            this.show_errors = true
            this.errorFound = true
        } else {
            this.show_errors = false
        }

    }

    getCurrentPorcent(): number {
        let currentSeconds = this.exerciseService.getCurrentSeconds();
        let fullSeconds = this.exerciseService.getFullSeconds();
        if (!currentSeconds || !fullSeconds) return 0;
        return (currentSeconds * 85) / fullSeconds;
    }

    getCurrentPorcentMobile(): number {
        let currentSeconds = this.exerciseService.getCurrentSeconds();
        let fullSeconds = this.exerciseService.getFullSeconds();
        if (!currentSeconds || !fullSeconds) return 0;
        return (currentSeconds * 100) / fullSeconds;
    }

    getNow() {
        if (this.authService.currentUser.userAdmin) {
            var now = moment();
            if (this.sessionService.currentSession.ExecutionDate == null) {
                return now;
            }
            var sessionDate = moment(this.sessionService.currentSession.ExecutionDate);
            if (sessionDate.diff(now.clone().startOf("day"), "days") != 0) {
                now.month(sessionDate.month());
                now.year(sessionDate.year());
                now.date(sessionDate.date());
            }
            return now;
        } else {
            return moment();
        }
    }

    continue(force = false): void {
        if (this.loadingExercise) return
        if (this.continueDisabled) return
        if (this.loading) return;
        if (force && this.status == null && !this.showJump) return;
        this.loading = true;
        this.exerciseEnded = true
        if (this.exerciseNumber != this.sessionLength) this.exerciseModel = null
        this.exerciseService.forceStopTimer()
        this.badges.exerciseEnded = false
        if (this.showJump == true && force == true && this.status != 'Saved') {
            this.loading = false;
            this.cancelExercise(true);
            return
        }
        if (this.sessionService.currentSession.IdReto) {
            this.retoService.getRetoExercises(this.sessionService.currentSession)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: Exerc[]) => {
                    this.sessionService.currentSession.Exercises = result;
                    this.goToNextExercise();
                })
        }
        else {
            this.reset()
            setTimeout(() => {
                this.goToNextExercise();
            }, 10);
            
        }
    }

    continueReview(): void {
        //if (this.continueReviewDisabled()) return
        this.exerciseModel = null
        this.exerciseIndex++
        setTimeout(() => {
            this.goToNextExerciseReview()
            this.currentExerciseChanged()
        }, 500)
    }

    cancelExercise(goToNextVar): void {
        if (this.uniqueSolution) return;
        if (this.alreadyCancelled) return;
        this.alreadyCancelled = true;
        this.uniqueSolution = true;
        this.goToNext = goToNextVar;
        //SAVE THE RESULT
        if (this.exerciseService.getCurrentExercise()) {
            this.exerciseService.getCurrentExercise().EndDate = this.getNow();
            if (this.exerciseService.getCurrentExercise().NumElementsERR > 0 && !this.exerciseService.getCurrentExercise().ExerciseModel.includes("ahorca")) {
                this.exerciseService.getCurrentExercise().Result = "ERR-JMP";
            } else if (
                this.exerciseService.getCurrentExercise().NumElementsOK > 0
                && !(this.exerciseService.getCurrentExercise().ExerciseModel == "pasap1" || this.exerciseService.getCurrentExercise().ExerciseModel.includes("ahorca"))
            ) {
                this.exerciseService.getCurrentExercise().Result = "OK-JMP";
            } else {
                this.exerciseService.getCurrentExercise().Result = "JMP";
            }
            // this.exerciseService.getCurrentExercise().SecondsHelp = this.pauseSeconds;
            this.status = "Saving";
            this.UpdateExerciseCancel();
        }
    }

    UpdateExerciseCancel(): void {
        if (this.sessionService.reto) {
            if (this.goToNext) {
                this.exerciseService
                    .updateExercReto(
                        this.exerciseService.getCurrentExercise(),
                        this.authService.currentUser.IsAdmin
                    )

                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe({
                        next: this.successCallbackUpdateExerciseErr.bind(this),
                        error: this.errorCallbackUpdateExercise.bind(this)
                    });
            } else {
                this.exerciseService
                    .updateExercReto(
                        this.exerciseService.getCurrentExercise(),
                        this.authService.currentUser.IsAdmin
                    )

                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe({
                        next: this.successCallbackGotoStudentMenu.bind(this),
                        error: this.errorCallbackUpdateExercise.bind(this)
                    });
            }
        } else {
            if (this.goToNext) {
                this.exerciseService
                    .updateExercise(
                        this.exerciseService.getCurrentExercise(),
                        this.authService.currentUser.IsAdmin
                    )

                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result : any) => {
                        this.successCallbackUpdateExerciseErr(result);
                    },(err) => {
                            this.errorCallbackUpdateExercise(err);
                        }
                    );
            } else {
                this.exerciseService
                    .updateExercise(
                        this.exerciseService.getCurrentExercise(),
                        this.authService.currentUser.IsAdmin
                    )

                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe({
                        next: this.successCallbackGotoStudentMenu.bind(this),
                        error: this.errorCallbackUpdateExercise.bind(this)
                    });
            }
        }
    }

    successCallbackUpdateExerciseErr(response): void {
        // if (this.status != "Saving") return;
        this.status = "Saved";
        this.Continue();
    }

    successCallbackGotoStudentMenu(response): void {
        this.navigationService.go("student/session");
    }

    Continue(): void {
        // if (this.status != "Saved") return;
        this.continueDisabled = true;
        this.reset();
        this.goToNextExercise();
    }

    async successCallbackFinishSession(response) {
        this.loading = false;
        console.log("success callback finish session", response)
        if (!response) {
            this.navigationService.goLogin();
            return;
        } else {
            this.medal = null;
            if (response != null && response != "" && response.Medal) {
                this.medal = response.Medal;
                if (this.medal.IdMedal.includes("MASTER"))
                    this.showTrophy = true;
                else this.showMedal = true;

            }

            let scoreRangesList: any = await this.sessionService.getSessionScoreConditionsRanges().toPromise();

            this.sessionService.currentSession.ClientTransaction =
                response.Session.ClientTransaction;
            this.sessionService.currentSession.Score = response.Session.Score;            
            this.sessionService.currentSession.RetrieveScore = response.Session.RetrieveScore;           
            this.sessionService.currentSession.ScoreNew = response.Session.ScoreNew;
            this.sessionService.currentSession.penalty =
                response.Session.penalty;
            this.sessionService.currentSession.FirstSession =
                response.Session.FirstSession;
            if (this.sessionService.currentSession.Score == null) {
                this.navigationService.go("student/session");
            } else {
                if (this.sessionService.currentSession.penalty == null)
                    this.sessionService.currentSession.penalty = 0;
                this.sessionService.insertGlobalResult(
                    this.sessionService.currentSession.IdSession
                )
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe();

                this.reto = false;
                this.showEndSession = true;
                this.showSolved = false;
                this.showTimeOut = false;

                if (this.sessionService.currentSession.ScoreNew) {
                    this.mark = this.sessionService.currentSession.RetrieveScore;
                    this.walinwos = this.sessionService.currentSession.ClientTransaction.Amount;

                    if (this.sessionService.currentSession.ScoreNew > this.sessionService.currentSession.Score) {
                        this.messageTextTitle = this._translateService.instant('STUDENT.GAMES.NEXT-CIVILIZATION.1');
                        this.messages[0] = this._translateService.instant('STUDENT.EXERCISE.YOUR-NEW-SCORE-IS', {score: this.sessionService.currentSession.ScoreNew});

                    } else {
                        this.messageTextTitle = this._translateService.instant('STUDENT.EXERCISE.FINISHED-SESSION');
                        this.messages[0] = this._translateService.instant('STUDENT.EXERCISE.YOUR-OLD-SCORE-IS', {score: this.sessionService.currentSession.Score});
                    }

                } else if (
                    this.sessionService.currentSession.FirstSession &&
                    this.sessionService.currentSession.Score > 2 &&
                    !this.authService.currentUser.OnDemand && 
                    !this.authService.currentUser.IsAdmin
                ) {
                    if (
                        this.sessionService.currentSession.Score >= 6 &&
                        this.sessionService.currentSession.penalty == 0
                    )
                        this.messageTextTitle = this._translateService.instant('STUDENT.GAMES.NEXT-CIVILIZATION.1');
                    else this.messageTextTitle = this._translateService.instant('STUDENT.EXERCISE.FINISHED-SESSION');

                    let sessionRange = scoreRangesList.find(x => x.IdCondition == 3 && this.sessionService.currentSession.Score <= x.Score);
                    const retried = this.sessionService.currentSession.ScoreNew || this.sessionService.currentSession.ScoreNew === 0;

                    this.diplomaImageUrl = sessionRange.DiplomaImageUrl;                    
                    this.messages = [];
                    this.messages[0] = this._translateService.instant('STUDENT.EXERCISE.OBTAINED-WALIS', {
                            walinwos: (this.sessionService.currentSession.ClientTransaction.Amount - (retried ? 0 : (sessionRange?.Walinwos ?? 0))), 
                            text: (retried ? "" : this._translateService.instant('STUDENT.EXERCISE.MESSAGE1', {walinwos: (sessionRange?.Walinwos ?? 0)}))
                        })
                    this.messages[1] =
                    this._translateService.instant('STUDENT.EXERCISE.MESSAGE2');
                    this.messages[2] =
                        "Total: " +
                        this.sessionService.currentSession.ClientTransaction
                            .Amount +
                        " walinwos.";
                    this.showDiploma = true;
                    this.walinwos =
                        this.sessionService.currentSession.ClientTransaction.Amount;
                    this.mark = this.sessionService.currentSession.Score;
                    // this.$emit("cambiar_t");
                } else if (
                    this.sessionService.currentSession.Score >= 9 &&
                    this.sessionService.currentSession.penalty == 0
                ) {
                    var extraWalis = 0;
                    let idCondition = 0;
                    if (
                        this.sessionService.currentSession.isReduced ||
                        (this.sessionService.currentSession.isPredefined &&
                            this.sessionService.currentSession.Exercises
                                .length < 30) ||
                        (!this.sessionService.currentSession.isReduced &&
                            !this.sessionService.currentSession.isPredefined &&
                            this.sessionService.currentSession.Exercises
                                .length < 20)
                    ) {
                        idCondition = 1;
                    } else {
                        idCondition = 2;
                    }

                    let sessionRange = scoreRangesList.find(x => x.IdCondition == idCondition && this.sessionService.currentSession.Score <= x.Score);

                    this.extraWalis = sessionRange.Walinwos;
                    extraWalis = sessionRange.Walinwos;
                    this.messages = [];
                    this.diplomaImageUrl = sessionRange.DiplomaImageUrl;
                    this.messages[0] = this.messages[0] = this._translateService.instant('STUDENT.EXERCISE.OBTAINED-WALIS', {
                        walinwos: (this.sessionService.currentSession.ClientTransaction.Amount -extraWalis),
                        text: this._translateService.instant('STUDENT.EXERCISE.MESSAGE3', {walinwos: extraWalis})
                    })
                    this.messages[1] =
                        "Total: " +
                        this.sessionService.currentSession.ClientTransaction
                            .Amount +
                        " walinwos.";
                    this.walinwos =
                        this.sessionService.currentSession.ClientTransaction.Amount;
                    this.mark = this.sessionService.currentSession.Score;

                    this.showDiploma = true;
                    // this.$emit("cambiar_t");
                } else {
                    if (
                        this.sessionService.currentSession.Score >= 6 &&
                        this.sessionService.currentSession.penalty == 0
                    )
                        this.messageTextTitle = this._translateService.instant('STUDENT.GAMES.NEXT-CIVILIZATION.1');
                    else this.messageTextTitle = this._translateService.instant('STUDENT.EXERCISE.FINISHED-SESSION');

                    this.messages = [];

                    if (this.sessionService.currentSession.penalty == 0) {
                        this.messages[0] = this._translateService.instant('STUDENT.EXERCISE.SESSION-FINISHED');
                        // this.messages[1] = "Has obtenido " + this.sessionService.currentSession.ClientTransaction.Amount + " walinwos.";
                        this.walinwos = this.sessionService.currentSession.ClientTransaction.Amount;
                        this.mark = this.sessionService.currentSession.Score;
                    } else {
                        this.messages[0] =
                        this._translateService.instant('STUDENT.EXERCISE.SESSION-FINISHED-SKIPPED');
                        // this.messages[1] = "Has obtenido " + this.sessionService.currentSession.ClientTransaction.Amount + " walinwos.";
                        this.walinwos =
                            this.sessionService.currentSession.ClientTransaction.Amount;
                        this.mark = this.sessionService.currentSession.Score;
                    }

                    this.showDiploma = false;
                }
                setTimeout(() => {
                    if (!this.medal) {
                        this.showTablon = true;
                    }
                }, 250);
            }
        }
    }

    successCallbackFinishReto(response): void {
        this.loading = false;
        if (response === null) {
            this.navigationService.goLogin();
            return;
        } else {
            this.sessionService.currentSession.Score =
                response.Invitacion.Score;
            this.messages = [];
            this.mark = this.sessionService.currentSession.RetrieveScore || this.sessionService.currentSession.Score;
            
            if (response.Invitacion.Position) {
                this.retoFnished = true;
                this.retoFnishedLost = false;
                this.removeRetoFromUser = true;
                if (response.Invitacion.Position === 1) {
                    var counter = 0;
                    response.Invitacion.Reto.Invitaciones.forEach(invitacion => {
                        if (invitacion.Status === this.currentSessionStatusEnum[this.currentSessionStatusEnum.Finalizado]) {
                            counter++;
                        }
                    });
                    var walis = counter * 100;
                    if (counter === 1 || counter === 0) {
                        walis = 100;
                        this.messages[1] =
                        this._translateService.instant('STUDENT.EXERCISE.CHALLENGE-ONLY-FINISHER');
                        // this.messages[2] =
                        //     "Has recuperado " + walis + " walinwos.";
                    } else {
                        this.messages[0] = this._translateService.instant('STUDENT.GAMES.NEXT-CIVILIZATION.1');
                        this.messages[1] =
                        this._translateService.instant('STUDENT.EXERCISE.CHALLENGE-FIRST-POSITION');
                        // this.messages[2] = "Has ganado " + walis + " walinwos.";
                    }
                    this.walinwos = walis;
                } else {
                    this.retoFnished = false;
                    this.retoFnishedLost = true;
                    this.messages[1] = this._translateService.instant('STUDENT.MODAL-CHALLENGE-FINISHED-MESSAGE', {position: response.Invitacion.Position})
                }
            } else {
                this.removeRetoFromUser = false;
                this.retoFnished = false;
                this.retoFnishedLost = false;
                this.messages[1] =
                this._translateService.instant('STUDENT.EXERCISE.CHALLENGE-CHECK-SCORE-TABLE');
            }


            this.showEndSession = true;
            this.showMedal = false;
            this.showTrophy = false;
            this.showDiploma = false;
            this.showSolved = false;
            this.showTimeOut = false;
            this.reto = true;
            this.sessionService.reto = false;
            setTimeout(() => {
                if (!this.medal) this.showTablon = true;
            }, 250);
        }
    }

    goHome(): void {
        if ((this.homeEnabled == false || this.status != "Saved") && this.showJump == false) return;
        if (this.IdClass != null) {
            let idUser = this.navigationService.params.session.IdUser;
            if (this.navigationService.params.isAdmin) {
                this.navigationService.go("/school/admin/students/null/sessionAdmin/" + idUser + '/' + this.IdClass, {
                    IdUser: idUser,
                    IdClass: this.IdClass,
                    isAdmin: true,
                })
            } else {
                this.navigationService.go('/school/session/' + idUser + '/' + this.navigationService.params.IdClass + '/sessionReview/' + idUser + '/' + this.navigationService.params.IdClass, {
                    IdUser: idUser,
                    IdClass: this.IdClass
                })
            }

        } else if (this.parentReview == true) {
                this.navigationService.go("/school/parentStudents/review", {
                    IdUser: this.navigationService.params.IdUser,
                    student: this.navigationService.params.student,
                });
                return;
        } else if(this.isReto()) {
            this.navigationService.go("student/challenge");
        } else {
            this.navigationService.go("student/session");
        }
    }

    showSolution(): void {
        this.eventsSubject.next({ event: "SHOW_ANSWER_TIMEOUT" });
        this.exerciseService.showSolution();
        this.buttons.nextExercise = true;
        this.badges.timeExpired = false;
        window.postMessage("SHOW_SOLUTION", "*");
    }

    successCallbackGetHelp(response): void {
        if (response == "" || response == null) {
            this.help = null;
        } else {
            this.help = response;
            this.showHelp = true;
        }
    }

    changeShow(val: boolean): void {
        this.showHelp = val;
        this.exerciseService.restartTimer();
    }

    openHelp() {
        if (this.exerciseService.getCurrentExercise().Theme) {
            if (
                this.exerciseService.getCurrentExercise().Theme != "ads" &&
                this.exerciseService.getCurrentExercise().Theme != "art" &&
                this.exerciseService.getCurrentExercise().Theme != "bv" &&
                this.exerciseService.getCurrentExercise().Theme != "dip" &&
                this.exerciseService.getCurrentExercise().Theme != "gj" &&
                this.exerciseService.getCurrentExercise().Theme != "gtl" &&
                this.exerciseService.getCurrentExercise().Theme != "h" &&
                this.exerciseService.getCurrentExercise().Theme != "c" &&
                this.exerciseService.getCurrentExercise().Theme != "hps" &&
                this.exerciseService.getCurrentExercise().Theme != "lly" &&
                this.exerciseService.getCurrentExercise().Theme != "mbp" &&
                this.exerciseService.getCurrentExercise().Theme != "pco" &&
                this.exerciseService.getCurrentExercise().Theme != "prf" &&
                this.exerciseService.getCurrentExercise().Theme != "r" &&
                this.exerciseService.getCurrentExercise().Theme != "suf" &&
                this.exerciseService.getCurrentExercise().Theme != "sya" &&
                this.exerciseService.getCurrentExercise().Theme != "tai" &&
                this.exerciseService.getCurrentExercise().Theme != "til" &&
                this.exerciseService.getCurrentExercise().Theme != "tmo" &&
                this.exerciseService.getCurrentExercise().Theme != "sig" &&
                this.exerciseService.getCurrentExercise().Theme != "ver"
            ) {
                return;
            }
        }

        this.exerciseService.pauseTimer();
        this.helpService.getHelp(this.exerciseService.getCurrentExercise().Theme, this.exerciseService.getCurrentExercise().IdThema)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: this.successCallbackGetHelp.bind(this),
                error: function () { }
            });
    }

    getDiplomaClass() {
        if (
            this.sessionService == null ||
            this.sessionService.currentSession == null
        )
            return null;
        if (
            this.sessionService.currentSession.FirstSession &&
            this.sessionService.currentSession.Score > 2 &&
            !this.authService.currentUser.OnDemand
        ) {
            return "firstSession";
        } else {
            if (
                this.sessionService.currentSession.isReduced ||
                (this.sessionService.currentSession.isPredefined &&
                    this.sessionService.currentSession.Exercises.length < 30) ||
                (!this.sessionService.currentSession.isReduced &&
                    !this.sessionService.currentSession.isPredefined &&
                    this.sessionService.currentSession.Exercises.length < 20)
            ) {
                if (this.sessionService.currentSession.Score < 9.2)
                    return "score20";
                else if (this.sessionService.currentSession.Score < 9.4)
                    return "score40";
                else if (this.sessionService.currentSession.Score < 9.6)
                    return "score60";
                else if (this.sessionService.currentSession.Score < 9.8)
                    return "score80";
                else if (this.sessionService.currentSession.Score < 10)
                    return "score90";
                else return "score100";
            } else {
                if (this.sessionService.currentSession.Score < 9.2)
                    return "score100";
                else if (this.sessionService.currentSession.Score < 9.4)
                    return "score150";
                else if (this.sessionService.currentSession.Score < 9.6)
                    return "score200";
                else if (this.sessionService.currentSession.Score < 9.8)
                    return "score300";
                else if (this.sessionService.currentSession.Score < 10)
                    return "score400";
                else return "score500";
            }
        }
    }

    finishSession() {
        if (this.status == "Saved") return;
        this.changeBackground();
        this.showEndSession = false;
        if (this.sessionService.reto) {
            if (this.removeRetoFromUser) {
                this.retoService.setRetoAsRead(this.authService.currentUser.Id)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe();
                this.authService.currentUser.IdReto = null;
            }
            this.navigationService.go("student/challenge");
        } else this.navigationService.go("student/session");
    }

    closeMedal() {
        this.medal = null
        setTimeout(() => {
            this.showTablon = true
        }, 500)
    }

    getMedalImage() {
        let imgName = "";
        if (this.medal == null)
            return;
        if (this.medal.Step == 1)
            imgName = "Medals/bronceFull.gif"
        if (this.medal.Step == 2)
            imgName =  "Medals/plataFull.gif"
        if (this.medal.Step == 3)
            imgName =  "Medals/oroFull.gif"
        if (this.medal.Step == 4)
            imgName =  "Medals/platinoFull.gif"
        return "url('https://walinwa.blob.core.windows.net/images/" + imgName + "')";
    }

    getMedalTextClass() {
        if (this.medal == null)
            return;
        if (this.medal.Step == 1)
            return "medalTextBronze";
        if (this.medal.Step == 2)
            return "medalTextSilver";
        if (this.medal.Step == 3)
            return "medalTextGold";
        if (this.medal.Step == 4)
            return "medalTextPlatinum";
    }

    getMedalText() {
        var text = this._translateService.instant('STUDENT.EXERCISE.MEDAL-TITLE');
        if (this.medal == null)
            return;
        if (this.medal.Step == 1)
            return text + this._translateService.instant('STUDENT.MEDAL-MESSAGE-BRONZE', {medalName: this.medal.MedalName});
        if (this.medal.Step == 2)
            return text + this._translateService.instant('STUDENT.MEDAL-MESSAGE-SILVER', {medalName: this.medal.MedalName});
        if (this.medal.Step == 3)
            return text + this._translateService.instant('STUDENT.MEDAL-MESSAGE-GOLD', {medalName: this.medal.MedalName});
        if (this.medal.Step == 4)
            return text + this._translateService.instant('STUDENT.MEDAL-MESSAGE-PLATINUM', {medalName: this.medal.MedalName});
    }

    getMedalMasterText() {
        var text = this._translateService.instant('STUDENT.EXERCISE.MASTER-MEDAL-TITLE');
        if (this.medal == null)
            return;
        if(this.medal.IdMedal.includes("MASTER")){
            return text + this.medal.MedalName.toUpperCase() + this._translateService.instant('STUDENT.EXERCISE.MASTER-MEDAL-MESSAGE')
        }
    }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    private _getExerciseNumberToShow(sessionOrder: number): string {
        console.log("sessionOrder " + sessionOrder);
        return sessionOrder > 100 ? this.review && !this.initial_show_errors ? `${sessionOrder - 100}r` : (sessionOrder - 100).toString() : sessionOrder.toString();
    }

    
    private _getTotalExerciseNumberToShow(session: Session): number {
        return this.review && !this.initial_show_errors ? (session.Exercises.length - session.Exercises.filter(e => e.SessionOrder > 100).length) : session.Exercises.length;
    }
    getFuegoImg(){
        return this.fileUrl + this.authService.currentUser.Civilization.ImgFolder + "/premium/fuego.gif"
    }
    onImgError(event){
        event.target.src = this.fileUrl + "fuego.gif"
    }
    getImgUrl(imgName: string, baseDirectory = false) {
    /*    if(baseDirectory){
            return this.sharedService_.getImgUrl(imgName);
        }*/
        return this.exerciseService.findImgUrl(imgName);
    }
}
