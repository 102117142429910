import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SchoolService } from 'app/services/school.service';
import { UserService } from 'app/services/user.service';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { NavigationService } from 'app/services/navigation.service';
import { AuthService } from 'app/services/auth.service';
import { UntypedFormBuilder } from '@angular/forms';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: "app-edit-config-session",
    templateUrl: "./edit-config-session.component.html",
    styleUrls: ["./edit-config-session.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class EditConfigSessionComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  classes;
  selectedClass : number;
  form;
  sessionValues = [
    { value: 0, desc: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-PERSONALIZED-BY-STUDENT') },
    { value: true, desc: 'Sí' },
    { value: false, desc: 'No' }
  ];
  multTimes = [
    
    {
      value: 1,
      name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-DEFAULT')
    },
    {   value: 0, 
        name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-PERSONALIZED-BY-STUDENT') 
    },
    {
        value: -1,
        name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-NO-TIME')
    }, {
      value: 1.5,
      name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-MODERATE')
    }, {
      value: 2,
      name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-MEDIUM')
    }, {
      value: 2.5,
      name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-HIGH')
    }, {
      value: 3,
      name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-HIGHER')
    }, {
      value: 4,
      name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-MAX')
    }
  ]

  constructor(
    private router: Router,
    private schoolService: SchoolService,
    private userService: UserService,
    private _matDialog: MatDialog,
    private navigationService: NavigationService,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private _translateService: TranslateService
  ) {
    this._unsubscribeAll = new Subject();
    this.selectedClass = 0;
    this.form = this.formBuilder.group({
      MultTime: [1],
      SmallSession: [0],
      SmallSessionWeekend: [false],
      SmallSessionSummer: [false],
      WeekendSkipTodaySession: [false],
      Retrieve: [false]
    });
  }

  ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

  ngOnInit(): void {
    if (!this.authService.check()) return;
    this.getClasses();
  }

  getClasses() {
    this.userService.getClassesFullByIdTutor(this.authService.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.classes = result;
        this.classes.sort(function (a, b) {
          return a.Level - b.Level;
        });
        this.changeClass();
        console.log("getClasses");
      },
      error => {
        this.navigationService.exit();
      });
  }

    sameValues() {
      //console.log("sameValues");
        if (!this.classes) return;
        if (this.classes.length == 0) return;
        //console.log("sameValues2");
        let values1 = {
            MultTime: this.classes[0].MultTime,
            SmallSession: this.classes[0].SmallSession,
            SmallSessionWeekend: this.classes[0].SmallSessionWeekend,
            SmallSessionSummer: this.classes[0].SmallSessionSummer,
            WeekendSkipTodaySession: !this.classes[0].WeekendSkipTodaySession,
            Retrieve: this.classes[0].Retrieve,
        };
        for (let cls of this.classes) {
            let values2 = {
                MultTime: cls.MultTime,
                SmallSession: cls.SmallSession,
                SmallSessionWeekend: cls.SmallSessionWeekend,
                SmallSessionSummer: cls.SmallSessionSummer,
                WeekendSkipTodaySession: !cls.WeekendSkipTodaySession,
                Retrieve: cls.Retrieve,
            };
            if (JSON.stringify(values1) != JSON.stringify(values2) && this.classes[0].NumStudents > 0 && cls.NumStudents > 0)
                return false;
        }
        //console.log("sameValues3");
        return true;
    }

    /*formatValues(val, revert = false) {
        if (!revert) {
            if (val == null) {
                return 2;
            } else if (val == true) {
                return 1;
            } else if (val == false) {
                return 2;
            }
        } else {
            if (val == 0) {
                return false;
            } else if (val == 1) {
                return true;
            } else if (val == 2) {
                return false;
            }
        }
    }*/
    changeClass() {
        let cls;

        this.form.enable();
        
        if (this.selectedClass == 0) {
            if (this.sameValues()) {
                cls = this.classes[0];
            } else {
                const classes = this.classes.filter(c => c.NumStudents > 0);

                if (classes.length <= 1) {
                  cls = classes[0];
                } else {
                  this.form.patchValue({
                      MultTime: classes.every(c => ((c.MultTime || 1) === (classes[0].MultTime || 1))) ? classes[0].MultTime || 1 : 0,
                      SmallSession: classes.every(c => (c.SmallSession || false) === (classes[0].SmallSession || false)) ? classes[0].SmallSession || false : 0,
                      SmallSessionWeekend: classes.every(c => c.SmallSessionWeekend === classes[0].SmallSessionWeekend) ? classes[0].SmallSessionWeekend || false : false,
                      SmallSessionSummer: classes.every(c => c.SmallSessionSummer === classes[0].SmallSessionSummer) ? classes[0].SmallSessionSummer || false : false,
                      WeekendSkipTodaySession: classes.every(c => c.WeekendSkipTodaySession === classes[0].WeekendSkipTodaySession) ? !classes[0].WeekendSkipTodaySession || false : false,
                      Retrieve: classes.every(c => c.Retrieve === classes[0].Retrieve) ? classes[0].Retrieve || false : false
                  });
                
                  return;
                }
            }

            this.form.patchValue({
              MultTime: !cls.DifferentConfigMultTime ? (cls.MultTime || 1) : 0,
              SmallSession: !cls.DifferentConfigSmallSession ? cls.SmallSession || false : 0,
              SmallSessionWeekend: cls.SmallSessionWeekend,
              SmallSessionSummer: cls.SmallSessionSummer,
              Retrieve: cls.Retrieve,
              WeekendSkipTodaySession: !cls.WeekendSkipTodaySession,
          });

          return;
        } 

        cls = this.classes.find((c) => c.IdClass == this.selectedClass);

        let multTime = this.form.get('MultTime');
        let smallSession = this.form.get('SmallSession');

        if (cls.NumStudents === 0 && this.selectedClass !== 0) {
          multTime.disable();
          smallSession.disable();
        } else {
          multTime.enable();
          smallSession.enable();
        }

        this.form.patchValue({
          MultTime: !cls.DifferentConfigMultTime ? cls.NumStudents > 0 ? (cls.MultTime || 1) : 1 : 0,
          SmallSession: !cls.DifferentConfigSmallSession ? cls.NumStudents > 0 ? /*this.formatValues(*/cls.SmallSession || false/*)*/ : false : 0,
          SmallSessionWeekend: cls.SmallSessionWeekend,
          SmallSessionSummer: cls.SmallSessionSummer,
          Retrieve: cls.Retrieve,
          WeekendSkipTodaySession: !cls.WeekendSkipTodaySession,
        });
    }

    public showClassWithoutStudents(): void {
      if (this.selectedClass !== 0 && this.classes.find(c => c.IdClass == this.selectedClass).NumStudents === 0) {
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
          disableClose: true
        });

        confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.CONFIG.SESSIONS.CLASS-WITHOUT-STUDENTS-TITLE');
        confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.CONFIG.SESSIONS.CLASS-WITHOUT-STUDENTS-MESSAGE');
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
        confirmDialog.componentInstance.margin = false;

        confirmDialog.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe(_ => {
          confirmDialog = null;
        });
      }
    }

  save() {
    this.schoolService.saveSessionConfig(this.authService.currentUser.Id, this.selectedClass, this.form.value.MultTime, /*this.formatValues(*/this.form.value.SmallSession === 0 ? null : this.form.value.SmallSession || false/*, true)*/, this.form.value.SmallSessionWeekend, this.form.value.SmallSessionSummer, this.form.value.Retrieve, !this.form.value.WeekendSkipTodaySession)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
          disableClose: true
        });
        confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.CONFIG.SESSIONS.SAVE-CONFIG-TITLE');
        confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.CONFIG.SUCCESS-SAVE-CONFIG-MESSAGE');
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
        confirmDialog.componentInstance.margin = false;

        confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
          confirmDialog = null;
          setTimeout(() => { this.selectedClass = parseFloat(this.selectedClass.toString()) }, 1000);
          this.getClasses();
        });
      }, error => {
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
          disableClose: true
        });
        confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.CONFIG.SESSIONS.SAVE-CONFIG-TITLE');
        confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.CONFIG.ERROR-SAVE-CONFIG-MESSAGE');
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
        confirmDialog.componentInstance.margin = false;

        confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
          confirmDialog = null;
        });
      }
    )

  }

}
