<div class="walinwa-overlay extra">
    <div class="contenedorMargenesEco columna verde" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Ecoembes/walinwa_fondo_ecoembes.jpg&quot;)'">

        <div class="messageCircleMed" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Exercises/message_backgroud_new.png&quot;)'" (click)="timeOutViewSolution()" [hidden]="!show_time_out">
    
        </div>
    
        <div class="messageCircleMed messageCircleFinished"
            [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Ecoembes/fin_ejercicio_' + walinwos + '.png&quot;)'" (click)="cancel()"
            [hidden]="!finished">
        </div>
    
        <div class="title" fxLayout="row" fxLayout.xs="row wrap" fxLayoutAlign="space-between stretch">
            <div class="mundo" fxFlex="5.5rem" fxHide.xs>
                <img [src]="getImgUrl('mundo.png')" class="mundoEco" [style.height.%]="worldSize()" />
            </div>
            <div class="titleText">
                <span class="title1">{{'STUDENT.ECOEMBES.TITLE-1' | translate }}</span>
                <span class="title2">{{'STUDENT.ECOEMBES.TITLE-2' | translate }}</span>
            </div>
            <div class="acctionButtons" fxLayout="column" fxLayout.xs="row-reverse" fxLayoutAlign="space-between end" fxLayoutAlign.xs="end start">
                <img title="Salir del dictado" class="cancelIcon pointer" [src]="getImgUrl('ico-close-avatar.svg', true)" (click)="cancel()" [ngClass]="{'element-collapse': fin == true}" />
                <img [src]="getImgUrl('ecoembes.png')" class="logoEcoembes" />
            </div>
            <div fxFlex="100%" fxShow.xs fxHide>
                <div class="progressbar">
                    <div class="timeri" [style.width.%]="worldSize()"></div>
                </div>
            </div>
        </div>
    
        <div class="options dragging-zone">
    
            <div *ngFor="let palabra of palabras; let i = index" id="wordContainer{{i}}" class="option">
                <div [hidden]="palabra.answered" [attr.idWord]="i" class="optionBox draggable" [ngClass]="{optionBoxError: palabra.answered  == false, 'myCustomShake':  palabra.answered == false}">
                    <div id="option{{i}}" class="exerciseLetterOption drag-element-source" [attr.idOption]="i">{{palabra.texto}}</div>
                </div>
            </div>
    
        </div>
    
        <div class="containersAnswer" fxHide.xs fxShow>
            <div *ngFor="let palabra of palabrasSolved; let i = index" class="container">
                <div class=" optionBox optionBox{{i}} optionBoxAnswer" [hidden]="palabra.answered  != true">
                    {{palabra.texto}}
                </div>
            </div>
        </div>
        <div class="containersAnswer mobile one" fxHide fxShow.xs>
            <div *ngFor="let palabra of palabrasSolved.slice(0, 3); let i = index" class="container">
                <div class=" optionBox optionBox{{i}} optionBoxAnswer" [hidden]="palabra.answered  != true">
                    {{palabra.texto}}
                </div>
            </div>
        </div>
        <div class="containers">
            <div *ngFor="let container of containers" 
            [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Ecoembes/recicla' + (container.id == 6 ? 0 : container.id) + '.png&quot;)'"
            class="container dropzone" [id]="'box-' + container.id" [attr.idBox]="container.id"
            [fxhide.xs]="container.id > 3">
            </div>
        </div>
        <div class="containersAnswer mobile two" fxHide fxShow.xs>
            <div *ngFor="let palabra of palabrasSolved.slice(3); let i = index" class="container">
                <div class=" optionBox optionBox{{i+3}} optionBoxAnswer" [hidden]="palabra.answered  != true">
                    {{palabra.texto}}
                </div>
            </div>
        </div>
        <!--div class="containers mobile" fxHide fxShow.xs>
            <div class="container container4 dropzone" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Ecoembes/recicla4.png&quot;)'" [attr.idBox]="4" id="box-4"></div>
            <div class="container container5 dropzone" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Ecoembes/recicla5.png&quot;)'" [attr.idBox]="5" id="box-5"></div>
            <div class="container container0 dropzone" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Ecoembes/recicla0.png&quot;)'" [attr.idBox]="6" id="box-6"></div>
        </div-->
        <div class="footer">
            <div class="textoFooter">{{'STUDENT.ECOEMBES.FOOTER' | translate }}</div>
        </div>
    </div>
</div>
