<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="sopa1 spe_gap" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" fxLayout="column"
    fxLayoutAlign="stretch">

    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
            <!-- <span class="second_enunciado" [ngClass]="{'pequeño': exercise.def.length > 76}">{{ exercise.def }}</span> -->
        </div>
    </div>

    <!-- 2da Caja de mensaje -->
    <div class="ruleContainer" [hidden]="exercise.def == null">
        <div class="exerciseRulenew">
            {{ exercise.def }}
        </div>
    </div>

    <div id="contenido_sopa" class="wordsContainer contenido_sopa_muestra" [ngClass]="{'fixControlSessions': controlSession}">
        <div class="items_container" [ngClass]="{'fixControlSessions': controlSession}">
            <div class="juego" [id]="'juego' + this.numero_random"></div>
        </div>
    </div>
</div>

<!--  -->
<!-- <div class="sopa1" [ngStyle]="{'pointer-events': review ? 'none;' : ''}">   <h2 *ngIf="controlSession" class="doc-number">{{current + 1}}</h2>

    <div *ngIf="!controlSession" class="exerciseTheme">
        <h1 [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></h1>
    </div>   

    <div id="contenido_sopa" class="contenido_sopa" [ngClass]="{'contenido-sopa-slide': slide}" >
        <div style="width: 100%;">
            <div class="definicion" [ngStyle]="controlSession ? {padding: '2% 3%;'} : {}">
                <span [ngStyle]="!controlSession ? {'font-size': '125%;'} : {'font-size': slide ? '1.25em;' : '1.56em;', 'margin-bottom': '0.5em;'}"  [innerHtml]="exercise.enunciado"></span>
                <span  [ngStyle]="!slide ? {'font-size': '3vh;'} : {'font-size': '1.4em;'}" [ngClass]="{'pequeño': exercise.def.length > 76}">{{ exercise.def }}</span>
            </div>
        </div>
        
        <div [ngStyle]="controlSession && !slide? {'margin-top': '2em;'} : controlSession && slide? {'margin-top': '0;'} : {}" style="width:90%;height:100%;display:flex;justify-content:center;align-items:baseline; margin-top: 3em;">
            <div class="juego" [id]="'juego' + this.numero_random"></div> 
        </div>
    </div>

</div> -->