<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="marca spe_gap" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" fxLayout="column" fxLayoutAlign="stretch">

    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <!-- 2da Caja de mensaje -->
    <div class="ruleContainer" [hidden]="exercise.regla == null">
        <div [ngClass]="{ verde: !review}" class="exerciseRulenew">
            <span class="regla" [innerHtml]="exercise.regla"></span>
        </div>
    </div>

    <div class="wordsContainer"  id="space-for-text">
        <div id="TextContainer" class="items_container">
            <div *ngFor="let palabra of exercise.palabras; let i = index"  [ngClass]="{'divBox' : true, morado: !review || controlSession, 'wordBox0Col' : numCols ==0, 'wordBox1Col' : numCols ==1, 'wordBox2Col' : numCols == 2 ,'wordBox2Col-cs' : numCols == 2 && controlSession, 'wordBox3Col' : numCols == 3, 'wordOk': palabra.answered == true, 'wordError': palabra.answered == false, 'wordNotAnswered' : palabra.notAnserwed == true, shake:  (palabra.answered == false || palabra.notAnserwed == true) && !review }" [attr.id]="i" (click)="validateClick(i)">
                <span class="wordBox">{{ palabra.palabraAux }}</span>
            </div>
        </div>
    </div>
</div>
<!--  -->
<!-- <div class="marca" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" [ngClass]="{ 'marcar': exerciseModel == 'marcar' }">
    <h2 *ngIf="controlSession" class="doc-number">{{current + 1}}</h2>

    <div *ngIf="!controlSession && !slide" class="exerciseTheme">
        <h1 [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></h1>
    </div>

    <div *ngIf="exerciseModel != 'marcar'" class="text-center exerciseTitle" [ngClass]="{'exerciseTitle-slide': slide}" [ngStyle]="controlSession ? {'margin-bottom': exerciseModel == 'marca.2' || exerciseModel == 'marca.3' ? '2em;': '1em;'} : {}">
        <p  [innerHtml]="exercise.enunciado"></p>
    </div>

    <div [ngClass]="{ 'ruleContainer-control': controlSession  && exerciseId == 10920}" class="ruleContainer" [ngStyle]="{'margin-top': !slide && controlSession && exerciseModel == 'marca.1' ? '1em;' : '0','padding-top': !slide && controlSession && exerciseModel == 'marca' ? '3em;' : '15px'}" [hidden]="exercise.regla == null">
        <div  [ngClass]="{ verde: !review}" class="exerciseRule">
            <p [hidden]="review" [ngStyle]="!slide ? {'font-size' : '2.5vh;','margin':'.5em 0;'} : {}" *ngIf="exerciseModel == 'marcar'" [innerHtml]="exercise.enunciado"></p>
            <p [hidden]="!controlSession" [ngStyle]="!slide ? {'font-size' : '2.5vh;','margin':'.5em 0;'} : {}" *ngIf="exerciseModel == 'marcar'" [innerHtml]="exercise.enunciado"></p>
            <p class="regla"  [ngStyle]="!slide ? {'font-size' : '2.5vh;','margin':'.5em 0;'} : {}"  [innerHtml]="exercise.regla"></p>
        </div>
    </div>

    <div class="wordsContainer"  id="space-for-text" [ngStyle]="!controlSession && !slide && exerciseModel == 'marca' ? {'margin-top': exerciseId != 15030 ? '8em;' : '0em;'} : {}">
        <div style="font-size: var(--font-size);" [ngStyle]="{ 'padding': numCols == 0 && exercise.palabras.length < 20 && !slide && controlSession ? '2em 2em 0;' : '' }" id="TextContainer" class="text-center" [ngClass]="{'text-center-control': controlSession && !slide && exerciseId == 10920}">
            <div  *ngFor="let palabra of exercise.palabras; let i = index"  [ngClass]="{'divBox' : true, morado: !review || controlSession, 'wordBox0Col' : numCols ==0, 'wordBox1Col' : numCols ==1, 'wordBox2Col' : numCols == 2 ,'wordBox2Col-cs' : numCols == 2 && controlSession, 'wordBox3Col' : numCols == 3, 'wordOk': palabra.answered == true, 'wordError': palabra.answered == false, 'wordNotAnswered' : palabra.notAnserwed == true, shake:  (palabra.answered == false || palabra.notAnserwed == true) && !review }" [attr.id]="i" (click)="validateClick(i)">
                <p [ngStyle]="controlSession && exerciseModel == 'marca.1' ? {margin: '1.5% auto 1.5% auto;'} : {}" class="wordBox">{{ palabra.palabraAux }}</p>
            </div>
        </div>
    </div>
</div> -->