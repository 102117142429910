<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>
<div class="unir4a" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" fxLayout="column" fxLayoutAlign="stretch">
    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div class="wordsContainer">
        <div class="items_container" [ngClass]="{'large': exercise.grupo2.length > 4}">
            <div id="row1" [ngClass]="{ 'element-hidden': showResult == true }">
                <div *ngFor="let word of exercise.grupo1; let i = index" class="wordBoxOption draggable" [ngClass]="{
                        textSelected: i == leftSelected,
                        wordBoxError: i == leftError,
                        'element-hidden': word == null
                    }" id="col1{{ i }}" column="left" [attr.index]="i" [innerHtml]="replaceIByEmpty(word)">
                </div>
            </div>
            <div id="row2" [ngClass]="{ 'element-hidden': showResult == true }">
                <div *ngFor="let word of exercise.grupo2; let i = index" class="phraseBox dropzone" [ngClass]="{
                        textSelected: i == rightSelected,
                        wordBoxError: i == rightError,
                        'element-hidden': word == null
                    }" id="col2{{ i }}" column="right" [attr.index]="i">
                    <span class="p-i" [innerHtml]="word"></span>
                </div>
            </div>
            <div id="columnResult" class="wordsResult">
                <div style="position: relative" *ngFor="let item of exercise.result" class="wordBox wordBoxResult"
                    id="result{{ i }}"
                    [ngClass]="{ wordBoxOk: item.answered == true, wordBoxError: item.answered == false, wordBoxNull: item.answered == null}">
                    <span class="p-i2" [innerHtml]="replaceI(item.word)"></span>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  -->
<!-- <div class="unir4a" [ngStyle]="{'pointer-events': review ? 'none;' : ''}">   <h2 *ngIf="controlSession" class="doc-number">{{current + 1}}</h2>
    <div *ngIf="!controlSession" class="exerciseTheme">
        <h1 [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></h1>
    </div>
    <div class="text-center exerciseTitle" [ngClass]="{'exerciseTitle-slide': slide}">
        <p [innerHtml]="exercise.enunciado"></p>
    </div>
    <div class="optionsContainer" [ngStyle]="controlSession ? {width: '100%;', margin : '1em 0 0 0'} : {}">
        <div id="row1" class="wordsContainer" [ngClass]="{ 'element-hidden': showResult == true }">
            <div *ngFor="let word of exercise.grupo1; let i = index" class="wordBoxOption draggable" [ngClass]="{
                    textSelected: i == leftSelected,
                    wordBoxError: i == leftError,
                    'element-hidden': word == null
                }" id="col1{{ i }}" column="left" [attr.index]="i" [innerHtml]="replaceIByEmpty(word)">
            </div>
        </div>
        <div id="row2"  class="wordsContainer2" [ngClass]="{ 'element-hidden': showResult == true }">
            <div *ngFor="let word of exercise.grupo2; let i = index" class="phraseBox dropzone" [ngClass]="{
                    textSelected: i == rightSelected,
                    wordBoxError: i == rightError,
                    'element-hidden': word == null
                }" id="col2{{ i }}" column="right" [attr.index]="i">
                <p class="p-i" [innerHtml]="word"></p>
            </div>
        </div>
        <div id="columnResult" class="wordsResult">
            <div style="position: relative" *ngFor="let item of exercise.result" class="wordBox wordBoxResult" id="result{{ i }}" [ngClass]="{ wordBoxOk: item.answered == true, wordBoxError: item.answered == false, wordBoxNull: item.answered == null}">
                <p class="p-i2" [innerHtml]="replaceI(item.word)"></p>
            </div>
        </div>
    </div>
</div> -->