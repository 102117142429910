<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="rima spe_gap" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" fxLayout="column"
    fxLayoutAlign="stretch">

    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div class="wordsContainer">
        <div class="items_container spe_gap" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center"
            fxLayoutAlign.lt-md="stretch">
            <div fxFlex="" fxFlex.lt-md="0 0 auto" class="rimas" fxLayout="column">
                <div fxFlex="" *ngIf="!review || !showingSolution" class="rimasCaja">
                    <div class="dropzone rima" [attr.index]="i" *ngFor="let rima of rimas; let i = index"
                        [innerHtml]="rima"></div>
                </div>
                <div fxFlex="" class="rimasCaja" *ngIf="review && showingSolution">
                    <div class="rima" [attr.index]="i"
                        [ngClass]="{'fraseOk': rima.status == 'ok','fraseErr': rima.status == 'error','fraseNotAnswered': rima.status == 'notAnswered' }"
                        *ngFor="let rima of rimas; let i = index" [innerHtml]="rima.rima"></div>
                </div>
                <div class="authorBox">
                    <div class="author">
                        Gustavo Adolfo Bécquer
                    </div>
                </div>
            </div>
            <div fxFlex="20rem" fxFlex.lt-md="0 0 auto" class="palabras">
                <div [ngClass]="{'error': error == i}" class="palabra draggable" [attr.index]="i"
                    [attr.rimaIndex]="palabra.rimaIndex" *ngFor="let palabra of palabras; let i = index">{{
                    palabra.palabra }}</div>
            </div>
        </div>
    </div>

</div>
<!--  -->
<!-- <div class="rima" [ngStyle]="{'pointer-events': review ? 'none;' : ''}">   <h2 *ngIf="controlSession" class="doc-number">{{current + 1}}</h2>

    <div *ngIf="!controlSession" class="exerciseTheme">
        <h1 [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></h1>
    </div>

    <div class="text-center exerciseTitle" [ngClass]="{'exerciseTitle-slide': slide}"> 
        <p   [innerHtml]="exercise.enunciado"></p>
    </div>

    <div class="space" [ngClass]="{'space-control': controlSession && !slide}" [ngStyle]="controlSession && !slide ? {'height': '55vh;','margin-top':'2em'} : !controlSession &&  !slide ? {'height': '73vh;'} : slide ? {'height': '35vh;'} : {}" >

        <div  [ngClass]="{'rimas-slide': controlSession && slide,'rimas': !slide }">

            <div *ngIf="!review || !showingSolution" [ngClass]="{'rimas-caja-slide': controlSession && slide,'rimasCaja': !slide }">

                <div class="dropzone" [ngClass]="{'rima-slide': controlSession && slide,'rima': !slide }" [attr.index]="i" *ngFor="let rima of rimas; let i = index" [innerHtml]="rima"></div>

            </div>

            <div class="rimasCaja" *ngIf="review && showingSolution">

                <div class="rima" [attr.index]="i" [ngClass]="{'fraseOk': rima.status == 'ok','fraseErr': rima.status == 'error','fraseNotAnswered': rima.status == 'notAnswered' }" *ngFor="let rima of rimas; let i = index" [innerHtml]="rima.rima"></div>

            </div>

            <div  [ngClass]="{'authorBox-slide': controlSession && slide,'authorBox': !slide }">
                <div class="author">
                    Gustavo Adolfo Bécquer
                </div>
            </div>

        </div>

        <div [ngClass]="{'palabras-slide': controlSession && slide,'palabras': !slide }">

            <div [ngClass]="{'error': error == i, 'palabra-slide': controlSession && slide, 'palabra-ctrl-session': controlSession && !slide}" class="palabra draggable" [attr.index]="i" [attr.rimaIndex]="palabra.rimaIndex" *ngFor="let palabra of palabras; let i = index">{{ palabra.palabra }}</div>

        </div>

    </div>

</div> -->