<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="define spe_gap" fxLayout="column" fxLayoutAlign="stretch" [ngStyle]="{'pointer-events': review ? 'none;' : ''}">
    
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div class="phraseBox" fxFlex="auto" fxLayout="column nowrap" fxLayoutAlign="center">
        <div class="phraseBoxChild">
            <span class="subPhrase" [innerHtml]="getFrase()"></span>
        </div>
        <div id="author" class="authorBox">
            {{ exercise.libro }} <span class="authorInfo">{{ exercise.autor }}</span>
        </div>
    </div>
    <div class="wordsContainer" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-around center">
         <div class="options" *ngFor="let opcion of exercise.opciones; let i = index" id="{{ i }}" (click)="validateClick(i)" [ngClass]="{'wordOk': opcion.answered == true, 'wordError': opcion.answered == false, 'wordNotAnswered' : opcion.notAnserwed == true && show_errors != null}">
            <span  class="wordBox">{{opcion.valor}}</span>
        </div>
    </div>
</div>