import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { AuthService } from "app/core/guards/auth.service";
import { ExerciseSolvedEnum } from "app/core/shared/enums/exercise-solved.enum";
import { ExerciseService } from "app/services/exercise.service";
declare var $;

@Component({
    selector: "ex-mod1",
    templateUrl: "./mod1.component.html",
    styleUrls: ["./mod1.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class Mod1Component implements OnInit, OnDestroy {
    @Input() review;
    @Input() show_errors;
    @Input() controlSession : boolean = false;
    @Input() slide : boolean = false; @Input() current;
    exercise;
    draggingElement;
    ok = [];
    error = [];
    finished = false;
    numOk = 0;
    numErr = 0;

    elementsOk;
    elementsErr;
    leftWords;
    rightWords;
    leftValidation;
    rightValidation;
    opciones = 0;

    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_SOLUTION]:
                this.finished = true;
                if (this.exerciseService.showingSolution) {
                    setTimeout(this.showAnswersTimeOut.bind(this), 100);
                    break;
                } else {
                    setTimeout(this.showSolution.bind(this), 100);
                    break;
                }
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_ANSWER]:
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_CUSTOM]:
                this.finished = true;
                setTimeout(this.ShowAnswer.bind(this), 100)
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.TIMER_END]:
                this.finished = true
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.EXERCISE_END]:
                this.finished = true
                break;
        }
    }
    
    constructor(private exerciseService: ExerciseService) {
        this.exercise = this.exerciseService.getExerciseDefinition();
    }
    public get exercisesSolvedEnum(): typeof ExerciseSolvedEnum{
        return this.exerciseSolvedEnum
    }
    
    public get exerciseSolvedEnum(): typeof ExerciseSolvedEnum{
        return ExerciseSolvedEnum
    }
    ngOnDestroy() {
    }

    ngOnInit() {
        
        if (this.review) {
            this.finished = true;
            this.elementsOk = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsOK
            );
            this.elementsErr = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsERR
            );
            if (this.show_errors == false) {
                this.showSolution();
            } 
            else if (this.show_errors == true || this.show_errors == null) {
                this.ShowAnswer();
            }
        } else {
            this.setAnswers();
            this.setPositions();
            this.FillExercise();
        }
    }
    cleanExercise(){
        this.finished = false;
        this.exercise.palabras.forEach(palabra => {
            palabra.answered = null;
            palabra.notAnserwed = null;
            if (palabra.opcion == true) {
                this.opciones++;
            }
        });
    }
    setAnswers() {
        for (var i = 0; i < this.exercise.palabras.length; i++) {
            this.exercise.palabras.originalIndex = i;
            this.exercise.palabras.answered = null;
            if (this.exercise.palabras[i].opcion == true) {
                this.opciones++;
            }
        }
    }

    setPositions() {
        if (this.finished) return
        if (!this.exercise.recarga) this.exercise.recarga = 2;
        else this.exercise.recarga = parseFloat(this.exercise.recarga);
        this.exercise.palabras = this.shuffle(this.exercise.palabras);
        for (var i = 0; i < this.exercise.palabras.length; i++) {
            var top = Math.random() * 10 + 1;
            var left = Math.random() * 10 + 1;
            this.exercise.palabras[i].top = top;
            this.exercise.palabras[i].left = left;
        }
        if (this.numOk != this.opciones && !this.finished)
            setTimeout(
                this.setPositions.bind(this),
                this.exercise.recarga * 1000
            );
    }

    getPalabra(palabra) {
        if (palabra.answered == true || this.finished) {
            return palabra.rae;
        }
        return palabra.palabra;
    }

    shuffle(array) {
        var currentIndex = array.length,
            temporaryValue,
            randomIndex;
        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array;
    }

    FillExercise() {
        for (let i = 0; i < this.exercise.palabras.length; i++) {
            this.exercise.palabras[i].palabraAux =
                this.exercise.palabras[i].palabra;
        }
    }

    getWordPosition(palabra) {
        if(this.controlSession && !this.slide){
            return {
                'font-size': "1.82em;",
                'padding': '0.25em 3em;',
                'border-radius': '2em;'
            };
        }
        if(this.finished) return;
        return {
            position: "relative",
            top: palabra.top + "%",
            left: palabra.left + "%",
        };
    }

    validar(palabra) {
        if (this.finished) return;

        if (palabra.answered != true && palabra.answered != false) {
            if (palabra.opcion == true) {
                palabra.answered = true;
                this.exerciseService.answer(
                    palabra.idPalabra,
                    true,
                    this.opciones
                );
                this.numOk++;
            } else {
                palabra.answered = false;
                // this.$emit("error");
                this.exerciseService.answer(
                    palabra.idPalabra,
                    false,
                    this.opciones
                );
                this.numErr++;
            }
            if (this.numOk == this.opciones) {
                // this.$emit("timer-stop");
                this.finished = true;
                // this.$emit('exercise-solved');
            }
        }
    }

    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    }
    showAnswersTimeOut(){
        for (var i = 0; i < this.exercise.palabras.length; i++) {
            if ((this.exercise.palabras[i].answered == null || this.exercise.palabras[i].answered == undefined) && this.exercise.palabras[i].opcion) {
                this.exercise.palabras[i].notAnserwed = true
            }
        }
    }
    showSolution() {
        for (var i = 0; i < this.exercise.palabras.length; i++) {
            this.exercise.palabras[i].notAnserwed = null;
            this.exercise.palabras[i].answered = null;
            if(this.exercise.palabras[i].opcion){
                this.exercise.palabras[i].answered = true
            }
        }
    }
    ShowAnswer() {
        this.exercise.palabras.forEach(palabra => {
            var answered = null;
            this.elementsErr.forEach(idError => {
                if (palabra.idPalabra == idError) {
                    answered = false;
                }  
            });
            this.elementsOk.forEach(idAcierto => {
                if (palabra.idPalabra == idAcierto) {
                    answered = true;
                }  
            });
            if(answered == null && palabra.opcion == true) {
                palabra.notAnserwed = true;
            }
            palabra.answered = answered;
        });
        // for (var i = 0; i < this.exercise.palabras.length; i++) {
        //     var answered = null;
        //     for (var p = 0; p < this.elementsErr.length; p++) {
        //         if (this.exercise.palabras[i].idPalabra == this.elementsErr[p]) {
        //             answered = false;
        //         }                  
        //     }
        //     for (var p = 0; p < this.elementsOk.length; p++) {
        //         if (this.exercise.palabras[i].idPalabra == this.elementsOk[p]) {
        //             answered = true;
        //         }
        //     }
        //     this.exercise.palabras[i].answered = answered;
        //     if(answered == null && this.exercise) {
                
        //     }
        // }
    }
}
