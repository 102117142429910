<div class="walinwa-box-with-tabs" fxNgClass.gt-sm="scroll_vertical">
    <div class="walinwa-tabs walinwa-tabs-top">
        <div [ngClass]="{ 'selected_verdeAzulado': selectedTab === WaliLeeTabs.List }" (click)="selectTab(WaliLeeTabs.List)" class="walinwa-tab-gray">{{'SCHOOL.TEACHER.COMPLEMENTS.WALILEE.BOOK-LIST' | translate }}</div>
        <div [ngClass]="{ 'selected_verdeAzulado': selectedTab === WaliLeeTabs.Results }" (click)="selectTab(WaliLeeTabs.Results)" class="walinwa-tab-gray">{{'SCHOOL.TEACHER.COMPLEMENTS.WALILEE.RESULTS' | translate }}</div>
        <div [ngClass]="{ 'selected_verdeAzulado': selectedTab === WaliLeeTabs.Library }" (click)="selectTab(WaliLeeTabs.Library)" class="walinwa-tab-gray">{{'SCHOOL.TEACHER.COMPLEMENTS.WALILEE.LIBRARY' | translate }}</div>
    </div>
    <div class="walinwa-box-green">
        <walilee-list [hidden]="selectedTab !== WaliLeeTabs.List" [repaint]="selectedTab === WaliLeeTabs.List"></walilee-list>
    </div>
    <div class="walinwa-box-green">
        <walilee-results [hidden]="selectedTab !== WaliLeeTabs.Results" [repaint]="selectedTab === WaliLeeTabs.Results"></walilee-results>
    </div>
    <div class="walinwa-box-green">
        <walilee-library [hidden]="selectedTab !== WaliLeeTabs.Library" [repaint]="selectedTab === WaliLeeTabs.Library"></walilee-library>
    </div>
</div>