import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { SharedService } from 'app/core/shared/shared.service';
import { BlobResponse } from 'app/core/shared/state/models/blob-response-model';
import { NavigationService } from 'app/services/navigation.service';
import { PaymentService } from 'app/services/payment.service';
import { ReportService } from 'app/services/report.service';
import { SchoolService } from 'app/services/school.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'admin-facturas',
    templateUrl: './admin-facturas.component.html',
    styleUrls: ['./admin-facturas.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        '(window:resize)': 'onResize($event)'
    }
})
export class AdminFacturasComponent implements OnInit, OnDestroy {
    confirmDialog: MatDialogRef<FuseDialogContinueComponent>;

    walifacturas = [];
    colegio = [];
    dataLoaded = false;
    deuda: boolean = false;
    private _unsubscribeAll: any;


    constructor(
        private NavigationService: NavigationService,
        public schoolService: SchoolService,
        private reportService: ReportService,
        private dateAdapter: DateAdapter<Date>
    ) {
        this._unsubscribeAll = new Subject();
        this.dateAdapter.setLocale("es-ES");
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    ngOnInit(): void {
        this.getSchool();
    }
    getSchool() {
        this.dataLoaded = false;
        this.schoolService.GetSchoolAndFacturasById(this.schoolService.school.IdSchool)
            .pipe(takeUntil(this._unsubscribeAll)).subscribe((school: any) => {
                console.log(school);
                school.WaliFacturas.forEach(factura => {
                    factura.Name = school.Name
                });
                this.dataLoaded = true;
                this.walifacturas = school.WaliFacturas
            })
    }
    isDeuda(colegio): boolean{
        if(colegio && colegio.WaliFacturas != null && colegio.WaliFacturas.length > 0){
            return colegio.WaliFacturas.some(element => element.FechaAbono == null );
        }
    }
    DeudaFactura(factura) {
        console.log(factura)
        return factura.FechaAbono == null;
    }
    close(){
        this.NavigationService.go("/school/admin")
    }
    isSchool(waliFactura) {
        if (waliFactura.Name == null) {
            return false;
        }
        return true;
    }

    formatearFecha(fecha) {
        return new Date(fecha).toLocaleDateString("es-ES");
    }

    getPrecioNeto(waliFactura) {
        return ((waliFactura.Precio * waliFactura.Alumnos) + waliFactura.Neto2).toFixed(2);
    }


    exportarPDF(num) {
        var n;
        if (num.split("/").length > 1) { n = num.split("/")[1]; } else { n = num; }

        var pdfName = "FA" + n;
        var reportName = "waliFacturas";
        var parameters = [{
            numFactura: num
        }];

        this.reportService.getPdf(reportName, pdfName, parameters).pipe(takeUntil(this._unsubscribeAll)).subscribe(
            (result: any) => {
                const blobResponse = result?.body as BlobResponse;
                var link = document.createElement("a");
                link.setAttribute("href", URL.createObjectURL(blobResponse.file));
                link.setAttribute("target", "_blank");
                link.setAttribute("download", blobResponse.fileName);
                document.body.appendChild(link)
                link.click();
            }
        )
    }
}
