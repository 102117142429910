<div class="walinwa-box-with-tabs">
    <div class="walinwa-tabs walinwa-tabs-top">
        <div class="walinwa-tab-gray" [ngClass]="{ 'orange-selected': tab==1 }" (click)="setTab(1)">{{'STUDENT.REWARDS.MEDALS' | translate}}</div>
        <div class="walinwa-tab-gray" [ngClass]="{ 'orange-selected': tab==2 }" (click)="setTab(2)">{{'STUDENT.REWARDS.CONSISTENCY' | translate}}</div>
    </div>
    <div class="walinwa-box walinwa-box-orange">
        <div class="ww_table_rwd" *ngIf="tab==1">
            <table class="table">
                <tbody>
                    <tr>
                        <td class="colMedal">
                            <div class="medalImg" [style.background-image]="getMedalBackground('bv')" [ngClass]="getMedalStatus('bv')"></div>
                            <div class="medalText">
                                <span class="medalTextBold" [ngClass]="getMedalTextClass('bv')">{{'STUDENT.REWARDS.BV' | translate}}</span></div>
                        </td>
                        <td class="colMedal">
                            <div class="medalImg" [style.background-image]="getMedalBackground('gj')" [ngClass]="getMedalStatus('gj')"></div>
                            <div class="medalText">
                                <span class="medalTextBold" [ngClass]="getMedalTextClass('gj')">{{'STUDENT.REWARDS.GJ' | translate}}</span></div>
                        </td>
                        <td class="colMedal">
                            <div class="medalImg" [style.background-image]="getMedalBackground('lly')" [ngClass]="getMedalStatus('lly')"></div>
                            <div class="medalText">
                                <span class="medalTextBold" [ngClass]="getMedalTextClass('lly')">{{'STUDENT.REWARDS.LLY' | translate}}</span></div>
                        </td>
                        <td class="colMedal">
                            <div class="medalImg" [style.background-image]="getMedalBackground('h')" [ngClass]="getMedalStatus('h')"></div>
                            <div class="medalText">
                                <span class="medalTextBold" [ngClass]="getMedalTextClass('h')">{{'STUDENT.REWARDS.H' | translate}}</span></div>
                        </td>
                        <td class="colMedal">
                            <div class="trophyCol">
                                <div class="medalImg" [style.background-image]="getMedalBackground('MASTERP')" [ngClass]="getMedalStatus('MASTERP')"></div>
                                <div class="medalTextMaster" [ngClass]="getMedalTextMasterStatus('MASTERP')">
                                    <span [innerHtml]="getMedalTextMaster('MASTERP')"></span></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="colMedal">
                            <div class="medalImg" [style.background-image]="getMedalBackground('xs')" [ngClass]="getMedalStatus('xs')"></div>
                            <div class="medalText">
                                <span class="medalTextBold" [ngClass]="getMedalTextClass('xs')">{{'STUDENT.REWARDS.XS' | translate}}</span></div>
                        </td>
                        <td class="colMedal">
                            <div class="medalImg" [style.background-image]="getMedalBackground('c')" [ngClass]="getMedalStatus('c')"></div>
                            <div class="medalText">
                                <span class="medalTextBold" [ngClass]="getMedalTextClass('c')">{{'STUDENT.REWARDS.C' | translate}}</span></div>
                        </td>
                        <td class="colMedal">
                            <div class="medalImg" [style.background-image]="getMedalBackground('dz')" [ngClass]="getMedalStatus('dz')"></div>
                            <div class="medalText">
                                <span class="medalTextBold" [ngClass]="getMedalTextClass('dz')">{{'STUDENT.REWARDS.DZ' | translate}}</span></div>
                        </td>
                        <td class="colMedal">
                            <div class="medalImg" [style.background-image]="getMedalBackground('gu')" [ngClass]="getMedalStatus('gu')"></div>
                            <div class="medalText">
                                <span class="medalTextBold" [ngClass]="getMedalTextClass('gu')">{{'STUDENT.REWARDS.GU' | translate}}</span></div>
                        </td>
                        <td class="colMedal">
                            <div class="trophyCol">
                                <div class="medalImg" [style.background-image]="getMedalBackground('MASTERS')" [ngClass]="getMedalStatus('MASTERS')"></div>
                                <div class="medalTextMaster" [ngClass]="getMedalTextMasterStatus('MASTERS')"><span [innerHtml]="getMedalTextMaster('MASTERS')"></span></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="colMedal">
                            <div class="medalImg" [style.background-image]="getMedalBackground('otr')" [ngClass]="getMedalStatus('otr')"></div>
                            <div class="medalText">
                                <span class="medalTextBold" [ngClass]="getMedalTextClass('otr')">{{'STUDENT.REWARDS.OTR' | translate}}</span></div>
                        </td>
                        <td class="colMedal">
                            <div class="medalImg" [style.background-image]="getMedalBackground('r')" [ngClass]="getMedalStatus('r')"></div>
                            <div class="medalText">
                                <span class="medalTextBold" [ngClass]="getMedalTextClass('r')">{{'STUDENT.REWARDS.R' | translate}}</span></div>
                        </td>
                        <td class="colMedal">
                            <div class="medalImg" [style.background-image]="getMedalBackground('mn')" [ngClass]="getMedalStatus('mn')"></div>
                            <div class="medalText">
                                <span class="medalTextBold" [ngClass]="getMedalTextClass('mn')">{{'STUDENT.REWARDS.MN' | translate}}</span></div>
                        </td>
                        <td class="colMedal">
                            <div class="medalImg" [style.background-image]="getMedalBackground('mbp')" [ngClass]="getMedalStatus('mbp')"></div>
                            <div class="medalText">
                                <span class="medalTextBold" [ngClass]="getMedalTextClass('mbp')">{{'STUDENT.REWARDS.MBP' | translate}}</span></div>
                        </td>

                    </tr>
                    <tr>
                        <td class="colMedal">
                            <div class="medalImg" [style.background-image]="getMedalBackground('til')" [ngClass]="getMedalStatus('til')"></div>
                            <div class="medalText">
                                <span class="medalTextBold" [ngClass]="getMedalTextClass('til')">{{'STUDENT.REWARDS.TIL' | translate}}</span></div>
                        </td>
                        <td class="colMedal">
                            <div class="medalImg" [style.background-image]="getMedalBackground('dip')" [ngClass]="getMedalStatus('dip')"></div>
                            <div class="medalText">
                                <span class="medalTextBold" [ngClass]="getMedalTextClass('dip')">{{'STUDENT.REWARDS.DIP' | translate}}</span></div>
                        </td>
                        <td class="colMedal">
                            <div class="medalImg" [style.background-image]="getMedalBackground('tai')" [ngClass]="getMedalStatus('tai')"></div>
                            <div class="medalText">
                                <span class="medalTextBold" [ngClass]="getMedalTextClass('tai')">{{'STUDENT.REWARDS.TAI' | translate}}</span></div>
                        </td>
                        <td class="colMedal">
                            <div class="medalImg" [style.background-image]="getMedalBackground('tmo')" [ngClass]="getMedalStatus('tmo')"></div>
                            <div class="medalText">
                                <span class="medalTextBold" [ngClass]="getMedalTextClass('tmo')">{{'STUDENT.REWARDS.TMO' | translate}}</span></div>
                        </td>
                        <td class="colMedal">
                            <div class="trophyCol">
                                <div class="medalImg" [style.background-image]="getMedalBackground('MASTERT')" [ngClass]="getMedalStatus('MASTERT')"></div>
                                <div class="medalTextMaster" [ngClass]="getMedalTextMasterStatus('MASTERT')"><span [innerHtml]="getMedalTextMaster('MASTERT')"></span></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="colMedal">
                            <div class="medalImg" [style.background-image]="getMedalBackground('abc')" [ngClass]="getMedalStatus('abc')"></div>
                            <div class="medalText">
                                <span class="medalTextBold" [ngClass]="getMedalTextClass('abc')">{{'STUDENT.REWARDS.ABC' | translate}}</span></div>
                        </td>
                        <td class="colMedal">
                            <div class="medalImg" [style.background-image]="getMedalBackground('ppp')" [ngClass]="getMedalStatus('ppp')"></div>
                            <div class="medalText">
                                <span class="medalTextBold" [ngClass]="getMedalTextClass('ppp')">{{'STUDENT.REWARDS.PPP' | translate}}</span></div>
                        </td>
                        <td class="colMedal">
                            <div class="medalImg" [style.background-image]="getMedalBackground('mem')" [ngClass]="getMedalStatus('mem')"></div>
                            <div class="medalText">
                                <span class="medalTextBold" [ngClass]="getMedalTextClass('mem')">{{'STUDENT.REWARDS.MEM' | translate}}</span></div>
                        </td>
                        <td class="colMedal">
                            <div class="medalImg" [style.background-image]="getMedalBackground('ana')" [ngClass]="getMedalStatus('ana')"></div>
                            <div class="medalText">
                                <span class="medalTextBold" [ngClass]="getMedalTextClass('ana')">{{'STUDENT.REWARDS.ANA' | translate}}</span></div>
                        </td>
                        <td class="colMedal">
                            <div class="trophyCol">
                                <div class="medalImg" [style.background-image]="getMedalBackground('MASTERO')" [ngClass]="getMedalStatus('MASTERO')"></div>
                                <div class="medalTextMaster" [ngClass]="getMedalTextMasterStatus('MASTERO')">
                                    <span [innerHtml]="getMedalTextMaster('MASTERO')"></span></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="walinwa-box-content perseverance-items" *ngIf="tab==2">
            <div *ngFor="let perseveranceItem of perseveranceItems; let i = index" class="perseverance-item">
                <img style="width: 10em; height: auto;"
                    [src]="'https://walinwa.blob.core.windows.net/images/Perseverance/perseverance' + perseveranceItem.Amount + '_medal.png'"
                    class="perseverance-item-medal">
                <div class="perseverance-item-date">
                    {{perseveranceItem.month}} - {{perseveranceItem.year}}
                </div>
            </div>
        </div>
    </div>
</div>