import { Component, Input, OnInit, OnDestroy, HostListener, ViewEncapsulation } from '@angular/core';
import { ExerciseService } from 'app/services/exercise.service';

@Component({
  selector: 'ex-unir6',
  templateUrl: './unir6.component.html',
  styleUrls: ['./unir6.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Unir6Component implements OnInit, OnDestroy {

  @Input() review
  @Input() show_errors;
  @Input() controlSession : boolean = false;
  @Input() slide : boolean = false; @Input() current;
  
  currentUser
  dataLoaded
  leftSelected
  rightSelected
  leftError
  rightError
  exercise
  showResult
  finished
  mytimeout
  removeErrorClassTimer
  draggingElement

  elementsOk
  elementsErr
  showingSolution = false
  @HostListener('window:message', ['$event'])
    exerciseListener(event){
      let data = event.data;
      switch (data) {
        case "SHOW_SOLUTION":
            this.finished = true;
            if (this.exerciseService.showingSolution) {
                setTimeout(this.ShowAnswersTimeOut.bind(this), 100);
                break;
          } else {
                setTimeout(this.ShowSolution.bind(this), 100);
                break;
            }
        case "SHOW_ANSWER":
        case "SHOW_CUSTOM":
          setTimeout(this.ShowAnswer.bind(this), 500)
          this.finished = true;
          break;
      }
    }

  constructor(private exerciseService: ExerciseService) {
    this.exercise = this.exerciseService.getExerciseDefinition();
    this.exercise.result = []
    // randomize the words
    this.exercise.grupo1 = this.exercise.grupo1.sort((a, b) => 0.5 - Math.random())
  }
    ngOnDestroy() {
    }

  ngOnInit() {
    if (this.review) {
      this.elementsOk = JSON.parse(this.exerciseService.getCurrentExercise().ElementsOK)
      this.elementsErr = JSON.parse(this.exerciseService.getCurrentExercise().ElementsERR)
      if (this.show_errors == false) {
          this.ShowSolution();
      } else if (this.show_errors == true || this.show_errors == null) {
          this.ShowAnswer();
      }
    } else {
      this.exerciseService.initInteract({
        onmove: (event) => {
          this.draggingElement = event.target
        },
        drop: (event) => {

          if (this.finished) return;
          var binColumn = event.target.getAttribute("column");
          var elementColumn = this.draggingElement.getAttribute("column");
          var binIndex = event.target.getAttribute("index");
          var elementIndex = this.draggingElement.getAttribute("index");


          //Drop on right from left
          if (binColumn == "right" && elementColumn == "left") {
            this.leftSelected = elementIndex;
            this.rightSelected = binIndex;
          }

          //Drop on left from right
          if (binColumn == "left" && elementColumn == "right") {
            this.leftSelected = binIndex;
            this.rightSelected = elementIndex;
          }

          //Drop on left from right
          if (binColumn == "right" && elementColumn == "right") {
            return;
          }
          //Drop on left from right
          if (binColumn == "left" && elementColumn == "left") {
            return;
          }

          this.ValidateResult();
        }
      });
    }
  }

  ShowAnswer() {
    this.showingSolution = true
    this.exercise = this.exerciseService.getExerciseDefinition();
    this.exercise.result = [];
    if (!this.exercise.grupo1)
      this.exercise.grupo1 = this.exercise.valor;
    for (var i = 0; i < this.exercise.valor.length; i++) {
      var answered = null;
      for (var p = 0; p < this.elementsErr.length; p++) {
        if (this.elementsErr[p] < this.exercise.valor.length && this.exercise.valor[i].v1 == this.exercise.valor[this.elementsErr[p]].v1)
          answered = false;
      }
      for (var p = 0; p < this.elementsOk.length; p++) {
        if (this.elementsOk[p] < this.exercise.valor.length && this.exercise.valor[i].v1 == this.exercise.valor[this.elementsOk[p]].v1 && answered == null)
          answered = true;
      }
      var result;
      if (this.exercise.operador == "un")
        result = this.exercise.valor[i].v1.replace('_', '') + this.exercise.valor[i].v2.replace('_', '');
      else
        result = this.exercise.valor[i].v1 + " / " + this.exercise.valor[i].v2;
      this.exercise.result.splice(this.exercise.result.length, 0, { word: result, answered: answered });
      this.exercise.grupo1[i] = null;
      this.exercise.grupo2[i] = null;
    }
    for (let i = 0; i < this.exercise.grupo2.length; i++) {
      this.exercise.grupo2[i] = null;
    }
  }

  ShowSolution() {
    this.showingSolution = true
    this.showResult = true;
    this.exercise = this.exerciseService.getExerciseDefinition();
    this.exercise.result = [];
    if (!this.exercise.grupo1) this.exercise.grupo1 = this.exercise.valor;
    for (var i = 0; i < this.exercise.grupo1.length; i++) {
      if (this.exercise.grupo1[i] != null) {
        var result;
        if (this.exercise.operador == "un")
          result = this.exercise.valor[i].v1.replace('_', '') + this.exercise.valor[i].v2.replace('_', '');
        else
          result = this.exercise.valor[i].v1 + " / " + this.exercise.valor[i].v2;
        this.exercise.result.splice(this.exercise.result.length, 0, { word: result, answered: true });
      }
    }
  }

  ShowAnswersTimeOut() {
    this.showingSolution = true;
    this.leftError = null;
    this.leftSelected = null;
    this.rightError = null;
    this.rightSelected = null;
    this.showResult = true;
    for (var i = 0; i < this.exercise.valor.length; i++) {
            var result;
            if (this.exercise.operador == "un")
                result =
                    this.exercise.valor[i].v1.replace("_", "") +
                    this.exercise.valor[i].v2.replace("_", "");
            else
                result =
                    this.exercise.valor[i].v1 +
                    " / " +
                    this.exercise.valor[i].v2;
                    let isAnswered = this.exercise.result.findIndex(w => w.word == result) > -1
                    if (!isAnswered) this.exercise.result.splice(this.exercise.result.length, 0, { word: result, answered: null });
        this.exercise.grupo1[i] = null;
        this.exercise.grupo2[i] = null;
    }
}

  ValidateResult() {
    if (this.exercise.valor[this.leftSelected] != null && this.exercise.grupo1[this.leftSelected] == this.exercise.valor[this.leftSelected].v1 &&
      this.exercise.grupo2[this.rightSelected] == this.exercise.valor[this.leftSelected].v2) {
      this.exercise.grupo1[this.leftSelected] = null;
      this.exercise.grupo2[this.rightSelected] = null;
      var result;
      if (this.exercise.operador == "un")
        result = this.exercise.valor[this.leftSelected].v1.replace('_', '') + this.exercise.valor[this.leftSelected].v2.replace('_', '');
      else
        result = this.exercise.valor[this.leftSelected].v1 + " / " + this.exercise.valor[this.leftSelected].v2;
      this.exercise.result.splice(this.exercise.result.length, 0, { word: result, answered: true });
      this.exerciseService.answer(parseFloat(this.leftSelected), true, this.exercise.grupo1.length);

      this.leftError = null;
      this.leftSelected = null;
      this.rightError = null;
      this.rightSelected = null;

    } else
      if (this.exercise.valor[this.rightSelected] != null && this.exercise.grupo1[this.leftSelected] == this.exercise.valor[this.rightSelected].v1 &&
        this.exercise.grupo2[this.rightSelected] == this.exercise.valor[this.rightSelected].v2) {
        this.exercise.grupo1[this.leftSelected] = null;
        this.exercise.grupo2[this.rightSelected] = null;
        var result;
        if (this.exercise.operador == "un")
          result = this.exercise.valor[this.rightSelected].v1.replace('_', '') + this.exercise.valor[this.rightSelected].v2.replace('_', '');
        else
          result = this.exercise.valor[this.rightSelected].v1 + " / " + this.exercise.valor[this.rightSelected].v2;
        this.exercise.result.splice(this.exercise.result.length, 0, { word: result, answered: true });
        this.exerciseService.answer(parseFloat(this.rightSelected), true, this.exercise.grupo1.length);
        this.leftError = null;
        this.leftSelected = null;
        this.rightError = null;
        this.rightSelected = null;

      }
      else {
        // this.$emit("error");
        this.exerciseService.answer(parseFloat(this.leftSelected), false, this.exercise.grupo1.length);
        this.leftError = this.leftSelected;
        this.leftSelected = null;
        this.rightError = this.rightSelected;
        this.rightSelected = null;
        // this.removeErrorClassTimer = $timeout(this.onRemoveErrorClassTimer, 500);
        setTimeout(() => {
          this.leftError = null
          this.rightError = null
        }, 1000)
      }

    if (this.exercise.grupo1.length == this.exercise.result.length) {
      this.finished = true;
      this.showResult = true;
      // this.$emit("timer-stop");
      // this.$emit('exercise-solved');
      return;
    }

    if (this.exercise.grupo2.length == this.exercise.result.length) {
      this.finished = true;
      this.showResult = true;
      // this.$emit("timer-stop");
      // this.$emit('exercise-solved');
    }
  }
  replaceI(text) {
    let partes = text.split("/")
    partes[0] = "<span>" + partes[0] + "</span>"
    return partes[0] + " / " + partes[1]
  }
}
