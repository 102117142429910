import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { SharedService } from "app/core/shared/shared.service";
import { ExerciseService } from "app/services/exercise.service";
declare var $;

@Component({
    selector: "ex-boxes2c",
    templateUrl: "./boxes2c.component.html",
    styleUrls: ["./boxes2c.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class Boxes2cComponent implements OnInit, OnDestroy {
    @Input() review;
    @Input() show_errors;
    @Input() controlSession : boolean = false;
    @Input() slide : boolean = false; @Input() current;
    exercise;
    draggingElement;
    ok = [];
    error = [];
    finished = false;
    elementsOk;
    elementsErr;
    numero_random = Math.floor(Math.random() * (10000 - 0 + 1) + 0)
    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case "SHOW_SOLUTION":
                this.finished = true;
                if (this.exerciseService.showingSolution) {
                    setTimeout(this.ShowAnswersTimeOut.bind(this), 100);
                    break;
                } else {
                    setTimeout(this.ShowSolution.bind(this), 100);
                    break;
                }
            case "SHOW_ANSWER":
            case "SHOW_CUSTOM":
                setTimeout(() => {
                    this.ShowAnswer();
                }, 100);
                
                break;
        }
    }

    constructor(private exerciseService: ExerciseService, private sharedService: SharedService) {
        this.exercise = this.exerciseService.getExerciseDefinition();
    }
    ngOnDestroy(){
    }

    ngOnInit() {
        if (this.review) {
            this.finished = true;
            this.elementsOk = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsOK
            );
            this.elementsErr = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsERR
            );
                this.cargar_opciones();
            if (this.show_errors == false) {
                setTimeout(() => {
                    this.ShowSolution();
                }, 100);
            } else if (this.show_errors == true || this.show_errors == null) {
                setTimeout(() => {
                    this.ShowAnswer();
                }, 100);
              }
        } else {
            this.initInteract();
            setTimeout(() => {
                this.cargar_opciones();
            }, 100);
        }
    }
    ShowSolution() {
        for (var j = 0; j < this.exercise.palabras.length; j++) {
            this.exercise.palabras[j].answered = true;
        }
    }
    ShowAnswer() {
    
        // Inicializar todas las palabras como no contestadas
        for (let i = 0; i < this.exercise.palabras.length; i++) {
            this.exercise.palabras[i].answered = null;
        }
        // Marcado de palabras incorrectas
        for (let j = 0; j < this.elementsErr.length; j++) {
            const errIndex = this.elementsErr[j];
            if (this.exercise.palabras[errIndex]) {
                this.exercise.palabras[errIndex].answered = false;
            }
        }
    
        // Marcado de palabras correctas
        for (let j = 0; j < this.elementsOk.length; j++) {
            const okIndex = this.elementsOk[j];
            if (this.exercise.palabras[okIndex] && this.exercise.palabras[okIndex].answered !== false) {
                this.exercise.palabras[okIndex].answered = true;
            }
        }
    }

    initInteract() {
        this.exerciseService.initInteract({
            onmove: (event) => {
                this.draggingElement = event.target;
            },
            drop: (event) => {
                var index_palabra = parseFloat(
                    this.draggingElement.getAttribute("id").substr(7)
                );
                var index_caja = parseFloat(
                    event.target.getAttribute("id").substr(3)
                );

                var palabra = this.exercise.palabras[index_palabra];
                var caja = this.exercise.boxes[index_caja];
                if (palabra.opcion == caja.valor) {
                    palabra.answered = true;
                    if (this.ok.indexOf(index_palabra) < 0) {
                        this.ok.push(index_palabra);
                        this.exerciseService.answer(
                            index_palabra,
                            true,
                            this.exercise.palabras.length
                        );
                    }
                } else {
                    this.error.push(index_palabra);
                    palabra.answered = false;
                    this.exerciseService.answer(
                        index_palabra,
                        false,
                        this.exercise.palabras.length
                    );
                }

                if (this.ok.length == this.exercise.palabras.length) {
                    this.FinishExercise();
                }
            },
        });
    }

    FinishExercise() {
        this.finished = true;
        this.colocar_en_su_caja();
    }

    colocar_en_su_caja() {
        for (var i = 0; i < this.error.length; i++) {
            var index_palabra = this.error[i]; 
            this.exercise.palabras[index_palabra].answered = false;
        }
    }

    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    cargar_opciones() {
        for (var i = 0; i < this.exercise.palabras.length; i++) {
            this.exercise.palabras[i].answered = null;
            $("#palabra" + i + this.slide + "boxes2c" + this.numero_random).css("bottom", this.getRandomInt(20, this.slide ? 60 : 70) + "%");
            $("#palabra" + i + this.slide + "boxes2c" + this.numero_random).css("left", this.getRandomInt(0, 65) + "%");
        }
    }

    ShowAnswersTimeOut() {
        this.colocar_en_su_caja();
    }
    /*getImgUrl() {
        return this.sharedService_.getImgBackgroundUrl("Exercises/Boxesc/front.png")
    }*/
}
