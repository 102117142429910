<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="ahorcar spe_gap" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" fxLayout="column"
    fxLayoutAlign="stretch">

    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div class="wordsContainer" id="space-for-text">
        <div class="items_container spe_gap" fxLayout="column" fxLayoutAlign="space-evenly center"
            fxLayoutAlign.lt-md="start center">
            <div class="exerciseRule" [hidden]="exercise.regla == null" [innerHtml]="exercise.regla"></div>
            <div class="secretWord spe_gap" fxLayout="column">
                <div class="retries" *ngIf="errors != 1">
                    Te quedan <span class="textRed">{{errors }}</span> intentos
                </div>
                <div class="retries" *ngIf="errors == 1">
                    Te queda <span class="textRed">{{errors }}</span> intento
                </div>
                <div class="word">
                    <div class="enlinea">
                        <span class="enlinea" [ngClass]="{'firstPhraseSpace': addMargin == true}">
                            {{ fragmentos[0] }}
                        </span>
                        <div class="enlinea sinSalto" *ngFor="let letter of secretWord; let i = index">
                            <span [hidden]="letter.show" class="letter">_</span>
                            <span [hidden]="!letter.show" class="letter reviewLetter"
                                [ngClass]="{'letterSpace': (i +1) != secretWord.length}">{{letter.value}}</span>
                        </div>
                        <span class="enlinea" [ngClass]="{'lastPhraseSpace': addMargin == true}">
                            {{ fragmentos[1] }}
                        </span>
                    </div>
                </div>
                <div id="author" class="authorBox">
                    {{ exercise.libro }}.
                    <span class="authorInfo"> {{exercise.autor}}</span>
                </div>
            </div>
            <div fxHide fxShow.xs class="imageContainer two">
                <div class="image" [style.background-image]="getImgStyle()"></div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-evenly stretch" class="keyboard_sec spe_gap">
                <div fxFlex="" class="letters">
                    <ul class="list-inline" [ngClass]="{'fixControlSessions': controlSession}">
                        <li *ngFor="let letter of letters" class="letterContainer">
                            <div class="letter-button letter" [ngClass]="{ 'chosen': letter.chosen}"
                                [disabled]="letter.chosen" (click)="letter.chosen ? null : try(letter);">
                                {{letter.name}}
                            </div>
                        </li>
                    </ul>
                </div>
                <div fxHide.xs class="imageContainer">
                    <div class="image" [style.background-image]="getImgStyle()"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  -->
<!-- <div class="ahorcar" [ngStyle]="{'pointer-events': review ? 'none;' : ''}">   <h2 *ngIf="controlSession" class="doc-number">{{current + 1}}</h2>

    <div *ngIf="!controlSession" class="exerciseTheme">
        <h1 [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></h1>
    </div>

    <div class="text-center exerciseTitle" [ngClass]="{'exerciseTitle-slide': slide}">
        <p   [innerHtml]="exercise.enunciado"></p>
    </div>

    <div class="wordsContainer" id="space-for-text">

        <p class="exerciseRule" [hidden]="exercise.regla == null" [innerHtml]="exercise.regla"></p>

        <div class="secretWord" [ngStyle]="controlSession ? {'height': '12em;'} : {}">
            <div class="retries" *ngIf="errors != 1" [ngStyle]="controlSession ? {'font-size': '150%;'} : {}">
                Te quedan <span class="textRed" >{{errors }}</span> intentos
            </div>
            <div class="retries" *ngIf="errors == 1" [ngStyle]="controlSession ? {'font-size': '150%;'} : {}">
                Te queda <span class="textRed" >{{errors }}</span> intento
            </div>
            <div class="word">

                <div class="enlinea">
                    <span class="enlinea" style="margin: 0 0.20em 0 0" [ngClass]="{'firstPhraseSpace': addMargin == true}" [ngStyle]="controlSession ? {'font-size': '140%;'} : {}">
                        {{ fragmentos[0] }}
                    </span>
                    <div class="enlinea sinSalto" *ngFor="let letter of secretWord; let i = index">
                        <span [hidden]="letter.show" class="letter" [ngStyle]="controlSession ? {'font-size': '1.5em;'} : {}">_</span>
                        <span [hidden]="!letter.show" class="letter reviewLetter" [ngClass]="{'letterSpace': (i +1) != secretWord.length}">{{letter.value}}</span>
                    </div>
                    <span [ngStyle]="controlSession ? {'font-size': '140%;'} : {}" class="enlinea" [ngClass]="{'lastPhraseSpace': addMargin == true}">
                        {{ fragmentos[1] }}
                    </span>
                </div>

            </div>
            <div id="author" class="authorBox" *ngIf="!controlSession">
                {{ exercise.libro }}.
                <span class="authorInfo"> {{exercise.autor}}</span>
            </div>
        </div>




        <div class="letters" *ngIf="!controlSession">
            <ul class="list-inline">
                <li *ngFor="let letter of letters" class="letterContainer">
                    <div class="letter-button letter" [ngClass]="{ 'chosen': letter.chosen}" [disabled]="letter.chosen" (click)="try(letter);">
                        {{letter.name}}
                    </div>
                </li>
            </ul>
        </div>
        <div class="imageContainer" *ngIf="!controlSession">
            <div class="image" [ngStyle]="{'background-image': getImgStyle()}"></div>
        </div>
    </div>

</div> -->