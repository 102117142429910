import { Injectable } from '@angular/core';

//cdk
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

//rxjs
import { Subject } from 'rxjs'
import { scan, map } from 'rxjs/operators'
import { SpinnerComponent } from './spinner.component';

@Injectable({
    providedIn: 'root',
})
export class UiService {

    private spinnerTopRef = this.cdkSpinnerCreate();

    spin$ :Subject<boolean> = new Subject()
    attached = false;
    constructor(
        private overlay: Overlay,
    ) {

      this.spin$
        .asObservable()
        .pipe(
          map(val => val ? 1 : -1 ),
          scan((acc, one) => (acc + one) >= 0 ? acc + one : 0, 0)
        )
        .subscribe(
          (res) => {
            if(res === 1){ this.showSpinner() }
            else if( res == 0 ){ 
              this.spinnerTopRef.hasAttached() ? this.stopSpinner(): null;
            }
          }
        )
    }

    private cdkSpinnerCreate() {
        return this.overlay.create({
            panelClass: 'spinner-overlay',
            hasBackdrop: false,
            positionStrategy: this.overlay.position()
                .global()
                .centerHorizontally()
                .centerVertically()
        })
    }

    

    public showSpinner(){
      try{
            if(!this.attached){
                this.spinnerTopRef.attach(new ComponentPortal<SpinnerComponent>(SpinnerComponent));
                this.attached = true;
            }else {
                document.getElementsByClassName("spinner-overlay")[0].classList.remove("element-hidden");
            }
      } catch(e){}
    }

    public stopSpinner(){
        document.getElementsByClassName("spinner-overlay")[0].classList.add("element-hidden");
    }
}