import { Injectable, ElementRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToolbarService {
  private matToolbarRef: ElementRef;

  setToolbarRef(ref: ElementRef) {
    this.matToolbarRef = ref;
  }

  getToolbarRef(): ElementRef {
    return this.matToolbarRef;
  }
}