<div class="walinwa-box">
    <div class="walinwa-box-content">
        <div *ngIf="!student">
            {{'SCHOOL.REVIEW-ACTIVITIES.REVIEW-READING-TITLE' | translate: {userInfo: ''} }}
        </div>
        <div *ngIf="student">
            {{ 
                'SCHOOL.REVIEW-ACTIVITIES.REVIEW-READING-TITLE' 
                | translate: { userInfo: (student.FamilyName ? student.FamilyName + ', ' : '') + student.Name + ' (' + student.UserName + ')' }
            }}
        </div>
        <div>
            <cal-reading-text
                *ngIf="readingTexts"
                class="calendario dinamico walCalendar azul ww_customCalendar"
                [ngClass]="{ sixWeeks: sixWeeks() }"
                [readingTexts]="readingTexts"
                [parent]="this"
                [fullDay]="false"
                [currentUser]="student"
                [selectedDay]="selectedDayCL"
                [currentMonth]="currentMonthCL"
            >
            </cal-reading-text>
        </div>
        <div fxLayoutAlign="end end" fxLayoutAlign.xs="center center">
            <button
                (click)="startReview()"
                [disabled]="!activeButton"
                class="new_but azul"
            >
                {{'SCHOOL.REVIEW-ACTIVITIES.REVIEW-EXERCISE' | translate}}
            </button>
        </div>
    </div>
</div>
<router-outlet></router-outlet>