import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'app/app.service';
import { SharedService } from 'app/core/shared/shared.service';
import { FileRequest, FileResponse } from 'app/core/shared/state/models/file/file.model';
import { FindUrlPipe } from 'app/pipes/find-url.pipe';
import { AuthService } from 'app/services/auth.service';
import { FileService } from 'app/services/file.service';
import { NavigationService } from 'app/services/navigation.service';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'studentHelp',
    templateUrl: './student-help.component.html',
    styleUrls: ['./student-help.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class StudentHelpComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    @Output() changeShowHelp = new EventEmitter<boolean>();

    currentStep = 1;
    steps = 0;
    currentUser;
    subDirectory = "Tutoriales/studentTutorial";
    imgUrls: FileResponse[] = [];
    isMobile: any;
    constructor(
        private router: Router,
        private authService: AuthService,
        private navigationService: NavigationService,
        private fileService: FileService,
        private appService: AppService,
        public sharedService: SharedService
    ) { 
        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
      }
      
    ngOnInit(): void {
        this.fileService.getCountImage(this.subDirectory.split("/")[1])
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
            (result: number) => {
                this.steps = result;
            },
            (err) => {
                console.error(err);
            });
        if (!this.authService.check()) return;
        this.isMobile = this.appService.mobileDetect().IsIPhone || this.appService.mobileDetect().IsAndroidPhone || window.innerWidth < 600;
        if (this.isMobile) {
            this.subDirectory = "Tutoriales/studentTutorialMovil";
        }
        this.currentUser = this.authService.currentUser;
        switch (this.router.url) {
            case '/student/session':
                this.currentStep = 2;
                break;
            case '/student/dictation':
                this.currentStep = 5;
                break;
            case '/student/reading-text':
                this.currentStep = 6;
                break;
            case '/student/deduction':
                this.currentStep = 7;
                break;
            case '/student/hearing-text':
                this.currentStep = 8;
                break;
            case '/student/challenge':
                this.currentStep = 9;
                break;
            case '/student/visual-memory':
                this.currentStep = 10;
                break;
            case '/student/zone':
                this.currentStep = 11;
                break;
            case '/student/rewards':
                this.currentStep = 12;
                break;
            case '/student/shop':
                this.currentStep = 13;
                break;
            case '/student/games':
                this.currentStep = 15;
                break;
            case '/student/progress/report':
                this.currentStep = 16;
                break;

            default:
                this.currentStep = 1;
                break;
        }
    }

    changePage(forward: boolean): void {
        if (forward) {
            this.currentStep++;
        }
        else {
            if (this.currentStep > 1)
                this.currentStep--;
        }
    }

    numberOfPages() {
        return this.steps;
    };

    getImgName(): string {
        let imageName = '';
        if (this.isMobile) {
            imageName = "ayuda_alumnos_vm-"
        }
        else {
            imageName = "ayuda_alumnos-"
        }
            if (this.currentStep < 10) {
                imageName += '0' + this.currentStep.toString();
            } else {
                imageName += this.currentStep.toString();
            }
            imageName = imageName + ".png";
            return imageName;
    }

    finish() {
        this.changeShowHelp.emit(false);
    };

    goLogin() {
        this.navigationService.goLogin();
    }
}
