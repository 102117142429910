<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="orden spe_gap" fxLayout="column" fxLayoutAlign="stretch" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" [ngClass]="{'fixControlSessions': controlSession}">

    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div class="wordsContainer" [ngClass]="{'fixControlSessions': controlSession}">
        <div class="items_container" fxLayout="row" fxLayoutAlign="space-around center">
            <div fxFlex="45%" fxLayout="column" fxLayoutAlign="space-between center" id="leftColumn" class="column">
                <div *ngFor="let word of leftWords; let i = index"
                     id="orden left{{ i }}" 
                     column="left" [attr.index]="i"
                     class="wordBox draggable dropzone"
                     [ngClass]="{ 'wordBoxEmpty' : word.word == '', 'wordBoxError': word.answered == false }">
                    <span>{{ word.word }}</span>
                </div>
            </div>
            <div fxFlex="45%" fxLayout="column" fxLayoutAlign="space-between center" id="rightColumn" class="column">
                <div *ngFor="let word of rightWords; let i = index" id="orden right{{ i }}"
                     column="right" [attr.index]="i"
                     class=" wordBox draggable dropzone"
                     [ngClass]="{'wordBoxOk': word.answered == true, 'wordBoxError': word.answered == false, 'wordBoxEmpty' : word.word == '' }">
                    <span>{{ word.word }}</span>
                </div>
            </div>
        </div>
    </div>
</div>