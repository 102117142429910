<div class="walinwa-box walinwa-box-green" *ngIf="dataLoaded">
    <div class="walinwa-box-content" [hidden]="expandControls == false">
        <!--div class="custom-form-field" style="margin-top: -1.3rem; margin-bottom: -1rem;"-->
        <div class="custom-form-field">
            <mat-form-field>
                <label>{{'SCHOOL.TEACHER.TRACKING.REPORT.TITLE' | translate}}</label>
                <mat-select [(ngModel)]="reportType" [ngModelOptions]="{standalone: true}">
                    <mat-optgroup *ngFor="let group of informeGroups" [label]="group.name" [disabled]="group.disabled"
                        class="scrollable">
                        <mat-option *ngFor="let informe of group.informes" [value]="informe.name"
                            style="color: #3CB2F2;" (click)="checkButton()"
                            [hidden]="(selectedClassId == -1 && !informe.alumno) || (informe.admin && !admin) || (informe.readingText && !enableReadingText) || (informe.dictation && !dictEnabled) ">
                            {{informe.desc}}
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
        </div>
        <div [hidden]="dateHidden()" fxLayout="row wrap" fxLayoutAlign="space-between center" fxNgClass.gt-sm="spe_gap">
            <div fxFlex="100%" fxFlex.gt-sm="" class="">
                <mat-form-field>
                    <label class="font_blue"><b>{{'SCHOOL.TEACHER.TRACKING.REPORT.FROM' | translate}}</b></label>
                    <input matInput [matDatepicker]="picker1" [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="selectedDay_desde">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
            </div>
            <div fxFlex="100%" fxFlex.gt-sm="" class="">
                <mat-form-field>
                    <label class="font_blue"><b>{{'SCHOOL.TEACHER.TRACKING.REPORT.TO' | translate}}</b></label>
                    <input matInput [matDatepicker]="picker2" [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="selectedDay_hasta">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxNgClass.gt-sm="spe_gap">
            <div fxFlex="100%" fxFlex.gt-sm="" class="custom-form-field"
                [hidden]="!classes || reportType == 'evaCalificaSchool'">
                <mat-form-field>
                    <label>{{'CLASS' | translate}}</label>
                    <mat-select (selectionChange)="changeClass()" [(ngModel)]="selectedClassId"
                        [ngModelOptions]="{standalone: true}">
                        <mat-option class="scrollable" [value]="class.IdClass"
                            *ngFor="let class of classes | sortBy:'asc':['Level','Code']">{{class.Name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="100%" fxFlex.gt-sm="" class="custom-form-field"
                [hidden]="reportType != 'EvaReadingText' && reportType != 'dictationHistory' && reportType != 'studentSession' && reportType != 'studentRegularity' && reportType != 'studentStatus' && reportType != 'studentSessionDetail' && reportType != 'studentHistory' && reportType != 'studentEvolution' && reportType != 'studentLastWeek'">
                <mat-form-field>
                    <label>{{'SCHOOL.TEACHER.TRACKING.REPORT.STUDENT' | translate}}</label>
                    <mat-select (selectionChange)="loadSessionMonth()" [(ngModel)]="studentId"
                        [ngModelOptions]="{standalone: true}">
                        <mat-option class="scrollable" [value]="std.Id"
                            *ngFor="let std of students">{{std.FamilyName + getComa(std) + ' ' +
                            std.Name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- [hidden]="reportType != 'EvaReadingText' && reportType != 'dictationHistory' && reportType != 'studentSession' && reportType != 'studentRegularity' && reportType != 'studentStatus' && reportType != 'studentSessionDetail' && reportType != 'studentHistory' && reportType != 'studentEvolution' && reportType != 'studentLastWeek'">
                <label>Alumno</label>
                <select (change)="loadSessionMonth()" [(ngModel)]="studentId" [ngModelOptions]="{standalone: true}">
                    <option value=""></option>
                    <option [value]="std.Id" *ngFor="let std of students">{{ std.FamilyName + getComa(std) + ' ' +
                        std.Name }}
                    </option>
                </select> -->
            </div>
            <div fxFlex="100%" fxFlex.gt-sm="" *ngIf="reportType == 'EvaExamGroup'">
                <mat-form-field>
                    <label class="font_blue"><b>{{'SCHOOL.TEACHER.TRACKING.REPORT.DATE' | translate}}</b></label>
                    <input [matDatepickerFilter]="dateFilter" (ngModelChange)="checkDate($event)" matInput
                        [matDatepicker]="picker3" [ngModelOptions]="{standalone: true}" [(ngModel)]="examDate">
                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                    <mat-datepicker #picker3></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="this.reportType == 'evaCalifica'" ngStyle.lt-md="margin-bottom:1rem; margin-top:0.5rem;">
            {{'SCHOOL.TEACHER.TRACKING.REPORT.ACTIVITY-PERCENT-TITLE' | translate}}
        </div>
        <div *ngIf="this.reportType == 'evaCalifica'" class="hide-subsript">
            <div class="config-option">
                <div>
                    <label class=" font_blue"><b>{{'SCHOOL.TEACHER.TRACKING.REPORT.SESSIONS' | translate}}</b></label><br>
                    <span>{{'SCHOOL.TEACHER.TRACKING.REPORT.SESSIONS-AMOUNT' | translate}}</span>
                </div>
                <div class="custom-form-field">
                    <mat-form-field>
                        <mat-select [(ngModel)]="xSes" [ngModelOptions]="{standalone: true}">
                            <mat-option class="scrollable" [value]="percent"
                                *ngFor="let percent of percents">{{percent}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="config-option">
                <div>
                    <label class=" font_blue"><b>{{'SCHOOL.TEACHER.TRACKING.REPORT.DICTATIONS' | translate}}</b></label><br>
                    <span>{{'SCHOOL.TEACHER.TRACKING.REPORT.DICTATIONS-AMOUNT' | translate}}</span>
                </div>
                <div class="custom-form-field">
                    <mat-form-field>
                        <mat-select [(ngModel)]="xDic" [ngModelOptions]="{standalone: true}">
                            <mat-option class="scrollable" [value]="percent"
                                *ngFor="let percent of percents">{{percent}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div *ngIf="this.reportType == 'evaCalifica'" class="hide-subsript">
            <div class="config-option">
                <div>
                    <label class=" font_blue"><b>{{'SCHOOL.TEACHER.TRACKING.REPORT.READING' | translate}}</b></label><br>
                    <span>{{'SCHOOL.TEACHER.TRACKING.REPORT.READING-AMOUNT' | translate}}</span>
                </div>
                <div class="custom-form-field">
                    <mat-form-field>
                        <mat-select [(ngModel)]="xRead" [ngModelOptions]="{standalone: true}">
                            <mat-option class="scrollable" [value]="percent"
                                *ngFor="let percent of percents">{{percent}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="config-option">
                <div>
                    <label class=" font_blue"><b>{{'SCHOOL.TEACHER.TRACKING.REPORT.DEDUCTION' | translate}}</b></label><br>
                    <span>{{'SCHOOL.TEACHER.TRACKING.REPORT.DEDUCTION-AMOUNT' | translate}}</span>
                </div>
                <div class="custom-form-field">
                    <mat-form-field>
                        <mat-select [(ngModel)]="xDed" [ngModelOptions]="{standalone: true}">
                            <mat-option class="scrollable" [value]="percent"
                                *ngFor="let percent of percents">{{percent}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="config-option">
                <div>
                    <label class=" font_blue"><b>{{'SCHOOL.TEACHER.TRACKING.REPORT.HEARING' | translate}}</b></label><br>
                    <span>{{'SCHOOL.TEACHER.TRACKING.REPORT.HEARING-AMOUNT' | translate}}</span>
                </div>
                <div class="custom-form-field">
                    <mat-form-field>
                        <mat-select [(ngModel)]="xHear" [ngModelOptions]="{standalone: true}">
                            <mat-option class="scrollable" [value]="percent"
                                *ngFor="let percent of percents">{{percent}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div [hidden]="reportType == 'evaCalifica'" fxLayout="column" fxLayoutAlign="stretch">
            <div class="custom-form-field" [hidden]="reportType != 'studentRegularity'">
                <label>{{'SCHOOL.TEACHER.TRACKING.REPORT.YEAR' | translate}}</label>
                <select [(ngModel)]="year" [ngModelOptions]="{standalone: true}">
                    <option [value]="year.name" *ngFor="let year of years">{{ year.desc }}
                    </option>
                </select>
            </div>
            <div>
                <calendar [hidden]="reportType != 'studentSession' && reportType != 'studentSessionDetail'"
                    *ngIf="student" class="calendario dinamico walCalendar azul ww_customCalendar" [parent]="this"
                    [fullDay]="false" [currentUser]="student" [selectedDay]="selectedDay" [currentMonth]="currentMonth">
                </calendar>
            </div>
        </div>
        <div style="margin-top: 2em;" class="walinwa-row spe_gap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center">
            <button (click)="changeControls()" [hidden]="expandControls == false"
                [disabled]="disableActionButton || disableForExam()" class="new_but azul">
                {{'SCHOOL.TEACHER.TRACKING.REPORT.SHOW-REPORT' | translate}}
            </button>
        </div>
    </div>
</div>

<div class="walinwa-box-modal-overlay" [hidden]="expandControls == true">
    <div class="walinwa-report">
        <div class="walinwa-box-modal-header">
            {{'SCHOOL.TEACHER.TRACKING.REPORT.REPORT-SECTION' | translate}}
            <div class="walinwa-box-modal-header-icons">
                <img src="https://walinwa.blob.core.windows.net/images/School/descargar-excel-b.svg?ngsw-bypass=true" title="Descargar en Excel" (click)="getExcel()"
                    [hidden]="showDownload == false" />
                <img src="https://walinwa.blob.core.windows.net/images/School/descargar-pdf-b.svg?ngsw-bypass=true" title="Descargar en PDF" (click)="getPdf()"
                    [hidden]="showDownload == false" />
                <img src="https://walinwa.blob.core.windows.net/images/but-home.svg?ngsw-bypass=true" title="Volver al inicio" (click)="changeControls()"
                    [hidden]="expandControls == true" />
            </div>
        </div>
        <div *ngIf="loading" class="walinwa-box-modal-container walinwa-box-modal-container-loading">
            <img src="https://walinwa.blob.core.windows.net/images/loading.gif?ngsw-bypass=true">
        </div>
        <iframe *ngIf="!loading" id="report-frame" [attr.srcdoc]="htmlCode"
            class="walinwa-box-modal-container"></iframe>
    </div>
</div>
<img [hidden]="!loading" style="width: 10em; position: fixed;top: 0;left: 0;bottom: 0;right: 0;margin: auto;"
src="https://walinwa.blob.core.windows.net/images/loading.gif?ngsw-bypass=true">