import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { AuthService } from "app/core/guards/auth.service";
import { ExerciseSolvedEnum } from "app/core/shared/enums/exercise-solved.enum";
import { ExerciseService } from "app/services/exercise.service";
import { Observable } from "rxjs";
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';

declare var $;

@Component({
    selector: "ex-marca1",
    templateUrl: "./marca1.component.html",
    styleUrls: ["./marca1.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class Marca1Component implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
    @Input() review;
    @Input() show_errors;
    @Input() controlSession : boolean = false;
    @Input() slide : boolean = false; @Input() current;
    @Input() events: Observable<void>;
    exercise;
    draggingElement;
    finished = false;
    exerciseIsEnded = false;

    elementsOk;
    elementsErr;
    eventsSubscription;

    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.TIMER_END]:
                this.finished = true;
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.EXERCISE_END]:
                this.finished = true;
                break;
        }
    }

    constructor(private exerciseService: ExerciseService) {
        this._unsubscribeAll = new Subject();
        this.exercise = this.exerciseService.getExerciseDefinition();
    }

    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    public get exercisesSolvedEnum(): typeof ExerciseSolvedEnum{
        return this.exerciseSolvedEnum
    }
    
    public get exerciseSolvedEnum(): typeof ExerciseSolvedEnum{
        return ExerciseSolvedEnum
    }

    ngOnInit() {
        if(!this.controlSession){
            this.events
.pipe(takeUntil(this._unsubscribeAll))
.subscribe((event) =>
            this.handleEvents(event)
            );
        }
        this.DrawModel10();
        if (this.review) {
            this.elementsOk = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsOK
            );
            this.elementsErr = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsERR
            );
            if (this.show_errors == false) {
                this.ShowSolution();
              } else if (this.show_errors == true || this.show_errors == null) {
                this.ShowAnswer();
              }
        } else {
        }
    }
    handleEvents(event) {
        switch (event.event) {
            case "SHOW_ANSWER_TIMEOUT":
                this.ShowAnswersTimeOut();
                break;
            case "SHOW_SOLUTION":
                this.finished = true;
                this.ShowSolution();
                break;
            case "SHOW_ANSWER":
            case "SHOW_CUSTOM":
                this.ShowAnswer();
                break;
        }
    }

    validateClick(event) {
        if (this.finished) return;
        var itemEl = document.getElementById(event.target.id);
        if (!itemEl) return;
        var itemIndex = parseInt(itemEl.getAttribute("index"));
        var indexWord = parseInt(itemEl.getAttribute("indexword"));

        if (this.exercise.palabras[indexWord].answered == true) return;
        if (this.exercise.palabras[indexWord].sylabes[itemIndex].text == "-")
            return;
        for (
            var i = 0;
            i < this.exercise.palabras[indexWord].sylabes.length;
            i++
        )
            this.exercise.palabras[indexWord].sylabes[i].answered = null;
        if (itemIndex == (this.exercise.palabras[indexWord].val - 1) * 2) {
            this.exerciseService.answer(
                indexWord + ";" + itemIndex,
                true,
                this.exercise.palabras.length
            );
            this.exercise.palabras[indexWord].sylabes[itemIndex].answered =
                true;
            this.exercise.palabras[indexWord].answered = true;
        } else {
            this.exerciseService.answer(
                indexWord + ";" + itemIndex,
                false,
                this.exercise.palabras.length
            );
            this.exercise.palabras[indexWord].sylabes[itemIndex].answered =
                false;
            this.exercise.palabras[indexWord].answered = false;
        }
        for (
            var i = 0;
            i < this.exercise.palabras[indexWord].sylabes.length;
            i++
        ) {
            if (this.exercise.palabras[indexWord].sylabes[i].answered == false)
                return;
        }
        for (var i = 0; i < this.exercise.palabras.length; i++) {
            if (this.exercise.palabras[i].answered != true) return;
        }
        this.finished = true;
    }

    DrawModel10() {
        for (var j = 0; j < this.exercise.palabras.length; j++) {
            this.exercise.palabras[j].sylabes = [];
            for (var i = 0; i < this.exercise.palabras[j].palabra.length; i++) {
                var word: any = {};
                word.text = this.exercise.palabras[j].palabra[i];
                word.answered = null;
                this.exercise.palabras[j].sylabes.push(word);
                if (i < this.exercise.palabras[j].palabra.length - 1) {
                    word = {};
                    word.text = "-";
                    word.answered = null;
                    this.exercise.palabras[j].sylabes.push(word);
                }
            }
        }
    }
    ShowSolution() {
        for (var j = 0; j < this.exercise.palabras.length; j++) {
            for (var i = 0; i < this.exercise.palabras[j].sylabes.length; i++) {
                if (i == (this.exercise.palabras[j].val - 1) * 2)
                    this.exercise.palabras[j].sylabes[i].answered = true;
                else this.exercise.palabras[j].sylabes[i].answered = null;
            }
        }
    }

    ShowAnswer() {
        for (var p = 0; p < this.elementsErr.length; p++) {
            if ( typeof(this.elementsErr[p]) == "string") {
                let split = this.elementsErr[p].split(";");
                this.elementsErr[p] = {
                     a: split[0],
                     b: split[1],
    
                };
            }
        }
        for (var p = 0; p < this.elementsOk.length; p++) {
            if (typeof(this.elementsOk[p]) == "string") {
                let split = this.elementsOk[p].split(";");
                this.elementsOk[p] = {
                     a: split[0],
                     b: split[1],
                };
            }
        }
        for (var j = 0; j < this.exercise.palabras.length; j++) {
            for (var i = 0; i < this.exercise.palabras[j].sylabes.length; i++) {
                this.exercise.palabras[j].sylabes[i].answered = null;
                for (var p = 0; p < this.elementsErr.length; p++) {
                    if (
                        j == this.elementsErr[p].a &&
                        i == this.elementsErr[p].b
                    )
                        this.exercise.palabras[j].sylabes[i].answered = false;
                }
                for (var p = 0; p < this.elementsOk.length; p++) {
                    if (
                        j == this.elementsOk[p].a &&
                        i == this.elementsOk[p].b &&
                        this.exercise.palabras[j].sylabes[i].answered != false
                    )
                        this.exercise.palabras[j].sylabes[i].answered = true;
                }
            }
        }
    }

    // ShowAnswer() {
    //     for (var j = 0; j < this.exercise.palabras.length; j++) {
    //         for (var i = 0; i < this.exercise.palabras[j].sylabes.length; i++) {
    //             this.exercise.palabras[j].sylabes[i].answered = null;
    //             for (var p = 0; p < this.elementsErr.length; p++) {
    //                 if (
    //                     j == this.elementsErr[p].a &&
    //                     i == this.elementsErr[p].b
    //                 )
    //                     this.exercise.palabras[j].sylabes[i].answered = false;
    //             }
    //             for (var p = 0; p < this.elementsOk.length; p++) {
    //                 if (
    //                     j == this.elementsOk[p].a &&
    //                     i == this.elementsOk[p].b &&
    //                     this.exercise.palabras[j].sylabes[i].answered != false
    //                 )
    //                     this.exercise.palabras[j].sylabes[i].answered = true;
    //             }
    //         }
    //     }
    // }

    ShowAnswersTimeOut() {
        for (var j = 0; j < this.exercise.palabras.length; j++) {
            for (var i = 0; i < this.exercise.palabras[j].sylabes.length; i++) {
                if (i == (this.exercise.palabras[j].val - 1) * 2)
                    this.exercise.palabras[j].sylabes[i].answered = true;
                else this.exercise.palabras[j].sylabes[i].answered = null;
            }
        }
    }
}
