import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LeeBooks } from "app/core/shared/state/models/Wali-lee/lee-books.model";
import { LeeClass } from "app/core/shared/state/models/Wali-lee/lee-class.model";
import { LeeQuestions } from "app/core/shared/state/models/Wali-lee/lee-questions.model";
import { LeeResult } from "app/core/shared/state/models/Wali-lee/lee-result.model";
import { LeeSections } from "app/core/shared/state/models/Wali-lee/lee-sections.model";
import { LeeTracking } from "app/core/shared/state/models/Wali-lee/lee-tracking.model";
import { LeeUserAnswers } from "app/core/shared/state/models/Wali-lee/lee-user-answers.model";
import { environment } from "environments/environment";
import { Observable } from "rxjs";

@Injectable()
export class WaliLeeService {
    baseUrl = environment.baseApi + '/api/WaliLee';


    constructor(private http: HttpClient) {}
    GetBook(IdUser: number): Observable<LeeBooks>{
        return this.http.get<LeeBooks>(this.baseUrl + "/GetBook/" + IdUser);
    }
    GetShowWaliLee(IdUser: number): Observable<boolean> {
        return this.http.get<boolean>(this.baseUrl + "/GetShowWaliLee/" + IdUser);
    }
    GetMap(IdUser: number): Observable<LeeSections[]> {
        return this.http.get<LeeSections[]>(this.baseUrl + "/GetMap/" + IdUser);
    }

    public getMapSectionResult(idLee: number, idSection: number, idUser: number): Observable<LeeResult> {        
        return this.http.get<LeeResult>(`${this.baseUrl}/GetMapSectionResult/${idLee}/${idSection}/${idUser}`);
    }

    public getQuestionsAndAnswers(IdUser: number, IdLee: number, IdSection:number){
        return this.http.get<LeeQuestions[]>(this.baseUrl + "/GetQuestionsAndAnswers/IdUser/" + IdUser + "/IdLee/" + IdLee + "/IdSection/" + IdSection)
    }

    public getUserQuestionsAndAnswers(IdUser: number, IdLee: number, IdSection:number){
        return this.http.get<LeeUserAnswers[]>(this.baseUrl + "/GetUserQuestionsAndAnswers/IdUser/" + IdUser + "/IdLee/" + IdLee + "/IdSection/" + IdSection)
    }

    Start(IdUser: number, IdLee: number, IdSection: number, IdClass: number, NumExercises: number) {
        var params = {
            IdLee: IdLee,
            IdSection: IdSection,
            IdUser: IdUser,
            IdClass: IdClass,
            NumExercises: NumExercises
        }
        return this.http.post(this.baseUrl + "/Start", params)
    }
    Restart(IdUser: number, IdLee: number, IdSection: number) {
        var params = {
            IdLee: IdLee,
            IdSection: IdSection,
            IdUser: IdUser,
        }
        return this.http.post(this.baseUrl + "/Restart", params)
    }

    public saveTest(idLee: number, idSection: number, idUser: number, idClass: number, answers: updateTestAnswersRequest[]) {
        return this.http.put(`${this.baseUrl}/SaveTest/${idLee}/${idSection}/${idUser}/${idClass}`, answers);
    }

    /*updateTestAnswers(IdLee: number, IdSection: number, IdUser: number, obj: updateTestAnswersRequest[]) {
        return this.http.put(this.baseUrl + "/idLee/" + IdLee + "/idSection/" + IdSection + "/idUser/" + IdUser, obj);
    }
    updateTestResult(IdLee: number, IdSection: number, IdUser: number, NumOk: number, NumPosibleAnswers: number): Observable<LeeResult> {
        let params = {
            IdLee,
            IdSection,
            IdUser,
            NumOk,
            NumPosibleAnswers
        }
        return this.http.post<LeeResult>(this.baseUrl + "/UpdateTestResult/", params)
    }*/

    public getBooks(): Observable<LeeBooks[]> {
        return this.http.get<LeeBooks[]>(`${this.baseUrl}/GetBooks`);
    }

    public getClassBooks(idTeacher: number): Observable<LeeClass[]> {
        return this.http.get<LeeClass[]>(`${this.baseUrl}/GetClassBooks?idTeacher=${idTeacher}`);
    }

    public isClassBookStarted(idLee: number, idClass: number): Observable<boolean> {
        return this.http.get<boolean>(`${this.baseUrl}/IsClassBookStarted?idLee=${idLee}&idClass=${idClass}`);
    }

    public createClassBook(leeClass: LeeClass): Observable<string[]> {
        return this.http.post<string[]>(`${this.baseUrl}/CreateClassBook`, leeClass);
    }

    public udpateClassBook(leeClass: LeeClass): Observable<string[]> {
        return this.http.put<string[]>(`${this.baseUrl}/UpdateClassBook`, leeClass);
    }

    public deleteClassBook(idLee: number, idClass: number, idTeacher: number): Observable<boolean> {
        return this.http.delete<boolean>(`${this.baseUrl}/DeleteClassBook?idLee=${idLee}&idClass=${idClass}&idTeacher=${idTeacher}`);
    }
    
    public getResults(idTeacher: number): Observable<LeeTracking[]> {
        return this.http.get<LeeTracking[]>(`${this.baseUrl}/GetResults?idTeacher=${idTeacher}`);
    }

    public getNextClassBook(idUser: number): Observable<LeeClass> {
        return this.http.get<LeeClass>(`${this.baseUrl}/GetNextClassBook?idUser=${idUser}`);
    }
}

interface updateTestAnswersRequest{
    IdQuestion: number,
    IdAnswer: number,
}