import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { AuthService } from "app/services/auth.service";
import { ExerciseService } from "app/services/exercise.service";

@Component({
    selector: "ex-boxes3",
    templateUrl: "./boxes3.component.html",
    styleUrls: ["./boxes3.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class Boxes3Component implements OnInit, OnDestroy {
    @Input() review;
    @Input() show_errors;
    @Input() controlSession : boolean = false;
    @Input() slide : boolean = false; @Input() current;
    currentUser;
    dataLoaded = false;
    exercise;
    boxesOption = [];
    mytimeout = null;
    finished = false;
    validation;
    draggingElement;

    elementsOk;
    elementsErr;

    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case "SHOW_SOLUTION":
                this.ShowSolution();
                break;
            case "SHOW_ANSWER":
                this.ShowAnswer();
                break;
            case "SHOW_CUSTOM":
                this.exercise = this.exerciseService.getExerciseDefinition();
                break;
        }
    }

    constructor(
        private authService: AuthService,
        private exerciseService: ExerciseService
    ) {
        this.currentUser = this.authService.currentUser;
        this.exercise = this.exerciseService.getExerciseDefinition();
    }
    ngOnDestroy(){
        
    }

    ngOnInit() {
        if (this.review) {
            this.elementsOk = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsOK
            );
            this.elementsErr = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsERR
            );
            for (var i = 0, len = this.exercise.boxes.length; i < len; i++) {
                this.boxesOption.push(this.exercise.boxes[i].valor);
            }
            if (this.show_errors == false) {
                this.ShowSolution();
              } else if (this.show_errors == true) {
                this.ShowAnswer();
              }
        } else {
            this.FillDictionary();
            for (var i = 0, len = this.exercise.boxes.length; i < len; i++) {
                this.boxesOption.push(this.exercise.boxes[i].valor);
            }
            this.exerciseService.initInteract({
                onmove: (event) => {
                    this.draggingElement = event.target;
                    this.validation = event.target.getAttribute("validation");
                },
                drop: (event) => {
                    if (this.finished == true) return;
                    for (var j = 0; j < this.exercise.palabras.length; j++) {
                        if (this.exercise.palabras[j].answered == false)
                            this.exercise.palabras[j].answered = null;
                    }
                    var wordValidation = this.validation;
                    var idBox = event.target.getAttribute("idBox");
                    var item = this.draggingElement;

                    if (this.exercise.palabras[item.id].answered == true)
                        return;
                    if (wordValidation == idBox) {
                        this.exercise.palabras[item.id].answered = true;
                        this.exerciseService.answer(
                            item.id,
                            true,
                            this.exercise.palabras.length
                        );
                    } else {
                        this.exercise.palabras[item.id].answered = false;
                        this.exerciseService.answer(
                            item.id,
                            false,
                            this.exercise.palabras.length
                        );
                    }
                    var done = true;
                    for (
                        var i = 0, len = this.exercise.palabras.length;
                        i < len;
                        i++
                    ) {
                        if (this.exercise.palabras[i].answered != true) {
                            done = false;
                        }
                    }
                    if (done) {
                        this.finished = true;
                        // this.$emit("timer-stop");
                        // this.$emit('exercise-solved');
                    }
                },
            });
        }
    }
    ShowSolution() {
        for (var j = 0; j < this.exercise.palabras.length; j++) {
            this.exercise.palabras[j].answered = true;
        }
    }
    ShowAnswer() {
        for (var i = 0; i < this.exercise.palabras.length; i++) {
            this.exercise.palabras[i].answered = null;
            for (var j = 0; j < this.elementsErr.length; j++) {
                if (
                    this.exercise.palabras[i].palabra ==
                    this.exercise.palabras[this.elementsErr[j]].palabra
                ) {
                    this.exercise.palabras[i].answered = false;
                }
            }
        }
        for (var i = 0; i < this.exercise.palabras.length; i++) {
            for (var j = 0; j < this.elementsOk.length; j++) {
                if (
                    this.exercise.palabras[i].palabra ==
                        this.exercise.palabras[this.elementsOk[j]].palabra &&
                    this.exercise.palabras[i].answered != false
                ) {
                    this.exercise.palabras[i].answered = true;
                }
            }
        }
    }

    ShowAnswersTimeOut() {
        for (var j = 0; j < this.exercise.palabras.length; j++) {
            this.exercise.palabras[j].answered = true;
        }
    }

    FillDictionary() {
        this.exerciseService.dictionaryWordList = [];
        for (var j = 0; j < this.exercise.palabras.length; j++) {
            if (this.exercise.palabras[j].rae)
                this.exerciseService.dictionaryWordList.push(
                    this.exercise.palabras[j].rae
                );
            else
                this.exerciseService.dictionaryWordList.push(
                    this.exercise.palabras[j].palabra
                );
        }
    }
}
