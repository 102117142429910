<div class="walinwa-box walinwa-box-green" *ngIf="dataLoaded" fxNgClass.gt-sm="scroll_vertical">

    <div class="walinwa-box-header" [hidden]="state==1">
        {{'SCHOOL.AUTH-CLASS-TITLE' | translate }} {{ class.Name }}
    </div>

    <div class="walinwa-box-header" [hidden]="state==2">
        {{'SCHOOL.EDIT-CLASS-TITLE' | translate }} {{ class.Name }}
    </div>

    <div [hidden]="state==1" class="walinwa-box-content">
        <div>
            {{'SCHOOL.AUTH-CLASS-MESSAGE' | translate }}
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="spe_gap">
            <div fxFlex="" fxFlex.xs="100%" class="custom-form-field">
                <mat-form-field class="spe_drop_down">
                    <label>{{'SCHOOL.AUTH-NEW-TEACHER' | translate }}</label>
                    <mat-select [disabled]="teachersAux.length==0" [(ngModel)]="selectedTeacherAux">
                        <mat-option class="scrollable" *ngIf="teachersAux.length==0" [value]="null">{{'SCHOOL.NO-TEACHERS' | translate }}</mat-option>
                        <mat-option class="scrollable" *ngFor="let teacher of teachersAux | sortBy:'asc':'FamilyName'"
                            [value]="teacher.Id">{{parseProfesorName(teacher)}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="14rem" fxFlex.xs="100%">
                <button [disabled]="!selectedTeacherAux" (click)="addTeacherAux()" class="new_but azul">
                    {{'SAVE' | translate}}
                </button>
            </div>
        </div>
        <div>
            <label class="font_blue"><b>{{'SCHOOL.AUTH-TEACHERS' | translate }}</b></label>
        </div>
        <div>
            <div class="ww_table_rwd">
                <table class="walinwa-table">
                    <tbody>
                        <tr *ngFor="let teacher of class.AuthorizedParents">
                            <td>
                                {{ teacher.UserTutor.Email }}
                            </td>
                            <td style="width:50px">
                                <img [hidden]="isMe(teacher)" (click)="removeAuthTeacher(teacher)" class="spe_ico_exam" 
                                src="https://walinwa.blob.core.windows.net/images/School/ico-trash.svg?ngsw-bypass=true">
                            </td>
                        </tr>
                        <tr *ngIf="class.AuthorizedParents.length == 0">
                            <td>
                                {{'SCHOOL.NO-AUTH-TEACHERS' | translate }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <form [hidden]="state==2" [formGroup]="formDataClass" class="walinwa-box-content" *ngIf="formDataClass">

        <div fxLayout="column" fxLayputAlign="stretch" class="spe_gap">
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="spe_gap">
                <div fxFlex="" fxFlex.xs="100%" class="custom-form-field">                    
                    <mat-form-field class="less_top_margin_field">
                        <label class="font_blue"><b>{{'SCHOOL.SCHOOL-COURSE' | translate }}</b></label>
                        <mat-select formControlName="Level">
                            <mat-option class="scrollable" [value]="class.Level"
                                *ngFor="let class of courses | sortBy:'asc':'Level'">{{ class.Name }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="formDataClass.get('Level').hasError('required')">
                            {{'FIELD-REQUIRED' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="" fxFlex.xs="100%" style="margin-top: 1px">                    
                    <mat-form-field class="less_top_margin_field  arrowed-autocomplete">
                        <label class="font_blue" [innerHTML]="'SCHOOL.CLASS-LETTER' | translate"></label>
                        <input formControlName="Name" [matAutocomplete]="autoGrupo" matInput type="text"
                            class="form-control" />
                        <svg viewBox="0 0 24 24" width="24px" height="24px" focusable="false" aria-hidden="true"><path d="M7 10l5 5 5-5z"></path></svg>
                        <mat-autocomplete autoActiveFirstOption #autoGrupo="matAutocomplete">
                            <mat-option class="scrollable" [value]="letter" *ngFor="let letter of classLetters">
                                {{ letter }}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="formDataClass.get('Name').hasError('required')">
                            {{'FIELD-REQUIRED' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="spe_gap">
                <div fxFlex="" fxFlex.xs="100%" class="custom-form-field">                    
                    <mat-form-field class="less_top_margin_field">
                        <label class="font_blue"><b>{{'SCHOOL.AUTH-TEACHER' | translate }}</b></label>
                        <mat-select [disabled]="teachersAux.length==0" formControlName="Teacher">
                            <mat-option class="scrollable" [value]="teacher.Id"
                                *ngFor="let teacher of teachers | sortBy:'asc':'FamilyName'">{{parseProfesorName(teacher)}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="formDataClass.get('Level').hasError('required')">
                            {{'FIELD-REQUIRED' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <!-- <label>{{'SCHOOL.AUTH-TEACHER' | translate }}</label>
                    <select formControlName="Teacher">
                        <option [value]="teacher.Id" *ngFor="let teacher of teachers">{{ teacher.FullName }}</option>
                    </select> -->
                </div>
                <div fxFlex="" fxFlex.xs="100%" [hidden]="state == 'newClass'" style="position: relative;" style="margin-top: 1px">                    
                    <mat-form-field class="less_top_margin_field">
                        <label class="font_blue" (click)="showClassId=!showClassId"><b>{{'SCHOOL.CLASS-CODE' | translate }}</b></label>
                        <input id="formCodeId" formControlName="Code" matInput type="text" class="form-control">
                        <mat-error *ngIf="formDataClass.get('Code').hasError('required')">
                            {{'FIELD-REQUIRED' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <div *ngIf="showClassId" style="right: 0%; top: -18%; z-index: 1; height: 4em; width: 15em;"
                        class="walinwa-tooltip walinwa-tooltip-right">
                        <div class="walinwa-tooltip-info walinwa-tooltip-info-primary">
                            {{'CLASS' | translate }}: {{ classToEdit.IdClass }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center" class="spe_gap">

            <button (click)="changeState()" [disabled]="calling" class="new_but azul">
                {{'SCHOOL.AUTH-TEACHERS' | translate }}
            </button>

            <button [disabled]="calling" (click)="updateClass()" class="new_but azul">
                {{'SAVE' | translate}}
            </button>

        </div>

    </form>

</div>