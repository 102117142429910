import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from 'app/services/navigation.service';
import { SchoolService } from 'app/services/school.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';

@Component({
    selector: 'app-parent-review',
    templateUrl: './parent-review.component.html',
    styleUrls: ['./parent-review.component.scss']
})
export class ParentReviewComponent implements OnInit {
    currentUser: any;
    enableDictation: boolean = true;
    enableReadingText: boolean = true;
    enableDeductionText: boolean = true;
    enableHearingTexts: boolean = true;
    state: string;
    IdUser: number;
    navigate: any;
    baseUrl: string = "/school/parentStudents/review";
    constructor(
        private activatedRoute: ActivatedRoute,
        private navigationService: NavigationService,
        private schoolService: SchoolService
    ) {
        moment.locale("es");
    }

    ngOnInit() {
        if (!sessionStorage.getItem("currentUser"))
            return this.navigationService.exit();
        this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
        if (!this.currentUser) return this.navigationService.exit();
        if (this.navigationService.params.IdUser)
            this.IdUser = this.navigationService.params.IdUser;
        this.navigationService.enableBackButton(true, () => {
            this.navigationService.go("/school/parentStudents", {
                IdUser: this.IdUser,
            });
        });
        if (this.navigationService.params.navigate)
            this.navigate = this.navigationService.params.navigate;
        if (this.navigate) {
            
            this.initNavigate();
        } else {
            this.goSesion();
            this.state = "session";
        }
    }
    errorCallback() {
        this.navigationService.go("/school/parentStudents");
    }
    initNavigate() {
        switch (this.navigate) {
            case "dictation":
                this.state = "dictations";
                this.goDicts();
                break;
            case "deduction":
                this.state = "deduction";
                this.goDeduction();
                break;
            case "reading":
                this.state = "reading";
                this.goReading();
                break;
            case "hearing":
                this.state = "hearing";
                this.goHearing();
                break;

            default:
                this.state = "session";
                this.goSesion();
                break;
        }
    }

    goDicts() {
        if (!this.enableDictation) {
            return;
        }
        this.state = "dictations";
        this.navigationService.go(this.baseUrl + "/dictations",
            {
                IdUser: this.IdUser,
                student: this.navigationService.params?.student
            }
        );
    }

    goReading() {
        if (!this.enableReadingText) {
            return;
        }
        this.state = "reading";
        this.navigationService.go(this.baseUrl + "/readingTexts",
            {
                IdUser: this.IdUser,
                student: this.navigationService.params?.student
            }
        );
    }
    goDeduction() {
        if (!this.enableDeductionText) {
            return;
        }
        this.state = "deduction";

        this.navigationService.go(this.baseUrl + "/deductionTexts",
            {
                IdUser: this.IdUser,
                student: this.navigationService.params?.student
            }
        );
    }

    goHearing() {
        if (!this.enableHearingTexts) {
            return;
        }
        this.state = "hearing";

        this.navigationService.go(this.baseUrl + "/hearingTexts",
            {
                IdUser: this.IdUser,
                student: this.navigationService.params?.student
            }
        );
    }

    goSesion() {
        this.state = "session";
        this.navigationService.go(this.baseUrl + "/sessions",
            {
                IdUser: this.IdUser,
                student: this.navigationService.params?.student
            }
        );
    }
}
