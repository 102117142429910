<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="unir spe_gap" fxLayout="column" fxLayoutAlign="stretch" *ngIf="exercise" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" [ngClass]="{'fixControlSessions': controlSession}">

    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}</div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div fxFlex="" fxLayout="row wrap" fxLayoutAlign="space-between center" class="model-columns" [ngClass]="{'fixControlSessions': controlSession}" [hidden]="showingSolution">
            <div fxFlex="50" fxLayout="column" fxLayoutAlign="space-between center" class="box-words">
                <div
                    [attr.wordIndexLeft]="i"
                    [ngClass]="{ 'box-word-error': leftError == i }"
                    [hidden]="word == null"
                    class="box-word draggable dropzone"
                    *ngFor="let word of exercise.grupo1; let i = index"
                >
                <span class="box-text"> {{ word }} </span>
                </div>
            </div>
            <div class="box-words" fxFlex="50" fxLayout="column" fxLayoutAlign="space-between center">
                <div
                    [attr.wordIndexRight]="i"
                    [ngClass]="{ 'box-word-error': rightError == i }"
                    [hidden]="word == null"
                    class="box-word draggable dropzone"
                    *ngFor="let word of exercise.grupo2; let i = index"
                >
                <span class="box-text"> {{ word }} </span>
                </div>
        </div>
    </div>
    <div class="model-columns" fxFlex="100" fxLayout="column" fxLayoutAlign="space-between center" [hidden]="!showingSolution">
        <div
            class="box-word" 
            [ngClass]="{ 'box-word-ok': word.answered, 'box-word-error': showResult && word.answered === false}"
            *ngFor="let word of exercise.result; let i = index">
                <span>
                    {{ word.word }}
                </span>
        </div>
    </div>
</div>
