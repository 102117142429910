import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, expand, last, map, takeUntil, takeWhile } from 'rxjs/operators';
import { SpinnerService } from '@fuse/components/spinner/spinner.service';
import { EMPTY, Subject, of } from 'rxjs';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import { CourseService } from 'app/services/course.service';
import { Course } from 'app/core/shared/state/models/course.model';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { MatDialog } from '@angular/material/dialog';
import { SchoolService } from 'app/services/school.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-admin-classroom-data',
  templateUrl: './admin-classroom-data.component.html',
  styleUrl: './admin-classroom-data.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AdminClassroomDataComponent implements OnInit, OnDestroy {
  private _classroomData: ItemNode[] = [];
  private _unsubscribeAll: Subject<any>;

  @Input() token: string;
  @Input() disabled: boolean;
  @Output() readonly onError: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly onImportData: EventEmitter<ImportData> = new EventEmitter<ImportData>();

  public dataReady: boolean = false;
  public flatNodeMap = new Map<ItemFlatNode, ItemNode>();
  public nestedNodeMap = new Map<ItemNode, ItemFlatNode>();
  public treeControl: FlatTreeControl<ItemFlatNode>;    
  public treeFlattener: MatTreeFlattener<ItemNode, ItemFlatNode>;    
  public dataSource: MatTreeFlatDataSource<ItemNode, ItemFlatNode>;
  public checklistSelection = new SelectionModel<ItemFlatNode>(true);
  public courses: Course[];
  public expanded: boolean = false;
  public touched: boolean = false;
  public importAllSelected: boolean = false;
    
  constructor(private _http: HttpClient, 
              private _detector: ChangeDetectorRef,
              private _spinnerService: SpinnerService,
              private _schoolService: SchoolService,
              private _coursesService: CourseService,
              private _translateService: TranslateService,
              private _matDialog: MatDialog) {
    this._unsubscribeAll = new Subject();
    this._prepareTree();
  }

  ngOnInit(): void {
    this._getGoogleClassroomData();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private _prepareTree() {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<ItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  private async _getGoogleClassroomData() {
    this._spinnerService.show();
    this._detector.detectChanges();

    /*const response: any = await this._http.post('https://oauth2.googleapis.com/token', {
      code: this.code,
      client_id: this.clientId,
      client_secret: this.clientSecret,
      redirect_uri: window.location.href.split('?')[0],
      grant_type:'authorization_code'
    }).toPromise();*/

    this._classroomData = await this._http.get(`https://classroom.googleapis.com/v1/courses?pageSize=500`, { headers: { Authorization: 'Bearer ' + this.token, "Content-Type": "application/json" }}).pipe(
      catchError(error => { console.error('Error: ', error); this.onError.emit(); return of(null); }),
      expand((response: any) => response?.nextPageToken ? this._http.get(`https://classroom.googleapis.com/v1/courses?pageSize=500&pageToken=${response.nextPageToken}`) : EMPTY), 
      last(),     
      takeWhile(response => response !== null),
      takeUntil(this._unsubscribeAll),
      map((response: any) => response ? (response.courses || []).map(c => { return { id: c.id, item: { id: c.id, givenName: c.name, ownerId: c.ownerId, type: 'classes' }, children: [{ id: `${c.id}teachers`, item: 'Profesores', type: 'teachers', children: [] }, { id: `${c.id}students`, item: 'Alumnos', type: 'students', children: [] }] } }) : [])
    ).toPromise();

    for (const c of (this._classroomData || [])) {
      for (const currentEntity of ['teachers', 'students']) {
        c.children.find(e => e.type === currentEntity).children = (await this._http.get(`https://classroom.googleapis.com/v1/courses/${c.id}/${currentEntity}?pageSize=500`, { headers: { Authorization: 'Bearer ' + this.token, "Content-Type": "application/json" } }).pipe(
          catchError(error => { console.error('Error: ', error); this.onError.emit(); return of(null); }),
          expand((response: any) => response?.nextPageToken ? this._http.get(`https://classroom.googleapis.com/v1/courses/${c.id}/${currentEntity}?pageSize=500&pageToken=${response.nextPageToken}`) : EMPTY),
          last(),
          takeWhile(response => response !== null),
          takeUntil(this._unsubscribeAll),
          map((response: any) => response ? (response[currentEntity] || []).map(e => { return { id: `${c.id}${e.userId}`, item: { id: e.userId, ...e.profile.name, email: e.profile.emailAddress, type: currentEntity, isTutor: e.userId === c.item.ownerId } } }) : [])
        ).toPromise()).sort((a, b) => (a.item.familyName || '').localeCompare(b.item.familyName || '') || - (a.item.givenName || '').localeCompare(b.item.givenName || ''));
      }
    }

    this.dataSource.data = this._classroomData;   
    this.dataReady = true;
    this._spinnerService.hide();
    this._detector.detectChanges();
  }

  public getNodeName(nodeItem: any): string {
    let name =  typeof(nodeItem) === 'string' ? nodeItem || '' : `${(nodeItem.familyName || '')}, ${(nodeItem.givenName || '')}`;

    return name.indexOf(',') === 0 ? name.split(',')[1] : name;
  }
  
  public getLevel = (node: ItemFlatNode) => node.level;  

  public isExpandable = (node: ItemFlatNode) => node.expandable;

  public getChildren = (node: ItemNode): ItemNode[] => node.children;

  public hasChild = (_: number, nodeData: ItemFlatNode) => nodeData.expandable;
 
  public transformer = (node: ItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.id === node.id ? existingNode : new ItemFlatNode();

    flatNode.id = node.id;
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.walinwaLevel = null;
    flatNode.expandable = (node.children || []).length > 0;

    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);

    return flatNode;
  }
  
  public descendantsAllSelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descendantsAllSelected = descendants.length > 0 && this.treeControl.getDescendants(node).every(child => this.checklistSelection.isSelected(child) || this.descendantsAllSelected(child))

    descendantsAllSelected ? this.checklistSelection.select(node) : this.checklistSelection.deselect(node);

    return descendantsAllSelected;
  }

  public descendantsPartiallySelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descendantsPartiallySelected = descendants.length > 0 && descendants.some(child => this.checklistSelection.isSelected(child) || this.descendantsPartiallySelected(child));

    return descendantsPartiallySelected && !this.descendantsAllSelected(node);      
  }  
  
  public itemSelectionToggle(node: ItemFlatNode, checkbox: any): void {       
    const descendants = this.treeControl.getDescendants(node);      

    this.descendantsPartiallySelected(node) || this.descendantsAllSelected(node) ? this.checklistSelection.deselect(...descendants.concat(node)) : this.checklistSelection.select(...descendants.concat(node));

    if (checkbox)  checkbox.checked = this.checklistSelection.isSelected(node);
  }
  
  private _selected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);

    return this.checklistSelection.isSelected(node) || (descendants.length > 0 && descendants.some(child => this.checklistSelection.isSelected(child) || this._selected(child)));
  }

  public getCourses() {
    return this._coursesService.Courses.pipe(takeUntil(this._unsubscribeAll));
  }

  public importAllClassroomData() {
    if (this.disabled) return;
    
    this.importAllSelected = true;
    this.touched = true;
    this._detector.detectChanges();

    for (let [key, value] of this.flatNodeMap.entries()) {      
      value.selected = this._selected(key);

      if (typeof(value.item) ===  "object")
        value.item.walinwaLevel = key.walinwaLevel;
    }

    if (this._classroomData.find(c => (c.item.walinwaLevel || 0) <= 0)) {
      this._showDialog(this._translateService.instant('SCHOOL.ADMIN.IMPORT-CLASSROOM.DATA.MODAL-ERROR-MESSAGE'));

      return;
    }

    const classroomData = this._prepareData(this._classroomData, false);
    const importData: ImportData = { idSchool: this._schoolService.school.IdSchool, classroomData: classroomData};

    this.onImportData.emit(importData);
  }

  public importSelectedClassroomData() {
    if (this.disabled) return;

    this.importAllSelected = false;
    this.touched = true;
    this._detector.detectChanges();

    for (let [key, value] of this.flatNodeMap.entries()) {      
      value.selected = this._selected(key);

      if (typeof(value.item) ===  "object")
        value.item.walinwaLevel = key.walinwaLevel;
    }

    if (!this._classroomData.find(c => c.selected)) {
      this._showDialog(this._translateService.instant('SCHOOL.ADMIN.IMPORT-CLASSROOM.DATA.MODAL-ERROR-MESSAGE2'));
      
      return;
    }

    if ((this._classroomData.find(c => c.selected && (c.item.walinwaLevel || 0) <= 0))) {
      this._showDialog(this._translateService.instant('SCHOOL.ADMIN.IMPORT-CLASSROOM.DATA.MODAL-ERROR-MESSAGE'));
      
      return;
    } 

    const classroomData = this._prepareData(this._classroomData, true);
    const importData: ImportData = { idSchool: this._schoolService.school.IdSchool, classroomData: classroomData};

    this.onImportData.emit(importData);
  }

  private _prepareData(classroomData: ItemNode[], onlySelected: boolean): ItemNode[] {
    const selected = (classroomData || []).filter(item => item.selected || onlySelected === false).map(item => {
      let _item = JSON.parse(JSON.stringify(item));

      delete _item.selected;

      _item.children = this._prepareData(_item.item.type === 'classes' ? _item.children[0].children.concat(_item.children[1].children) : _item.children, onlySelected);

      return _item;
    });

    return selected;
  }

  private _showDialog(message: string) {    
    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
        disableClose: true
    });

    confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.ADMIN.IMPORT-CLASSROOM.MODAL-IMPORT-FROM-CLASSROM-TITLE');
    confirmDialog.componentInstance.message1 = message;
    confirmDialog.componentInstance.space = true;
    confirmDialog.componentInstance.margin = false;
    confirmDialog.componentInstance.theme = "blue"
    confirmDialog.componentInstance.options = [{
        text: this._translateService.instant('ACCEPT'),
        callback: () => {}
    }];

    confirmDialog.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe(_ =>  confirmDialog = null);
  }
}

export interface ImportData {
  idSchool: number;
  classroomData: ItemNode[];
}

export class ItemNode {
  children: ItemNode[];
  id: string;
  item: any;
  type: string;
  selected: boolean;
}

export class ItemFlatNode {
  id: string;
  item: any;
  level: number;
  expandable: boolean;
  walinwaLevel: number;
  selected: boolean;
}