import { environment } from './../../../environments/environment';
import { WaliLetrasService } from './../../services/wali-letras.service';
import { PersonalizedTest } from '../../core/shared/state/models/personalized-test/personalized-test.model';
import { CustomSession } from 'app/core/shared/state';
import { PersonalizedTestService } from './../../services/personalized-test.service';
import { Component, Input, OnInit, ChangeDetectorRef, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'app/services/auth.service';
import { CoinService } from 'app/services/coin.service';
import { DailyTextService } from 'app/services/daily-text.service';
import { NavigationService } from 'app/services/navigation.service';
import { ShopService } from 'app/services/shop.service';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import * as moment from 'moment';
import 'moment/locale/es'
import { ReplaceTags } from 'app/pipes/replace-tags.pipe';
import { SharedService } from 'app/core/shared/shared.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'daily-text',
  templateUrl: './daily-text.component.html',
  styleUrls: ['./daily-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DailyTextComponent implements OnInit, OnDestroy {

  @Input() color;
  IdTest : number;

  ecoText = false;
  didyouknow = false;
  weeklyText;
  sundayText = false;
  dailyText;
  currentUser;
  calling = false;
  dailyTextScore;
  coin;
  day;
  loadingText = true;
  availableItems = [];
  isDemo = false
  pendingTest = false;
  buttonText = this._translateService.instant('COMPONENTS.DAILY-TEXT.PARTICIPATE-BUTTON');
  isProd = environment.production
  fileUrl = "Themes/"
    waliLetrasToday = false;
  private _unsubscribeAll: Subject<any>;
    mobile: boolean;

  constructor(
    private authService: AuthService,
    private navigationService: NavigationService,
    private dailyTextService: DailyTextService,
    private shopService: ShopService,
    private coinService: CoinService,
    private _matDialog: MatDialog,
    private _translateService: TranslateService,
    public personalizedTestService: PersonalizedTestService,
    public waliLetrasService: WaliLetrasService,
    public sharedService: SharedService
  ) { 
    this._unsubscribeAll = new Subject();
  }
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    async ngOnInit(): Promise<void> {
        if (!this.authService.check()) return;
        this.mobile = this.sharedService.isMobile()
        this.currentUser = this.authService.currentUser;
        //this.day = moment().utc().isoWeekday();
        await this.handleDailyTextDay();
        // this.day = 2; // Leo
        if (!this.isProd) {
            await this.handleWaliletrasDay();
        }
        if (this.waliLetrasToday && !this.isProd) this.color = "var(--verde)";

        this.getAvailableItems();

        this.isDemo = this.authService.currentUser.UserName.includes(".tmp.")
        this.getDailyText();
        if (this.waliLetrasToday && !this.isProd) {
            this.checkWaliLetras();
        }
        this.initListeners();
    }
    initListeners() {
        this.shopService.user_bought_items.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            this.getAvailableItems();
        })
        this.sharedService.custom_session_has_test.pipe(takeUntil(this._unsubscribeAll)).subscribe((result: number) => {
            this.IdTest = result;
            console.log("entro")
            
            if (result && result != 0) {
                this.pendingTest = true;
            }
            else {
                this.pendingTest = false;
            }
        })
        this.waliLetrasService.change_button.subscribe(result => {
            this.checkWaliLetras();
        })
    }

    async handleWaliletrasDay(): Promise<void> {
        const result = await this.getWaliletrasDay().toPromise();
        if (result === this.day) {
            this.waliLetrasToday = true;
        }
    }
    async handleDailyTextDay(): Promise<void> {
        const result = await this.getDailyTextDay().toPromise();
        if(result != -1){
            this.day = result;
        }
        else {
            this.day = moment().utc().isoWeekday();
        }
    }
    getWaliletrasDay() {
        return this.waliLetrasService.getActivityDay();
    }
    getDailyTextDay(){
        return this.dailyTextService.getDailyTextDay()
    }
    checkWaliLetras() {
        this.waliLetrasService.isFinished(this.authService.currentUser.Id).pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
            if (result.IsFinished == false) {
              this.buttonText = this._translateService.instant('CONTINUE');
          }
          if (result.IsFinished) {
              this.buttonText = this._translateService.instant('COMPONENTS.DAILY-TEXT.CHECK-BUTTON');
          }
      })
    }
    getDailyText(reloadData: boolean = false): void {
        if (this.dailyTextService.dailyText != null) {
            if (this.day > 6) {
                this.loadingText = true;
                this.successLoadWeeklyText(this.dailyTextService.dailyText)
            }
            else if (this.day == 6) {
                this.loadingText = true;
                this.successLoadDailyTextSaturday(this.dailyTextService.dailyText)
            }
            else {
                this.loadingText = true;
                this.successLoadDailyText(this.dailyTextService.dailyText)
            }
        }
        else {
            if (this.day > 6) {
                this.loadingText = true;
                this.dailyTextService.getWeekResult().pipe(takeUntil(this._unsubscribeAll)).subscribe((result: any) => {
                    this.dailyTextService.dailyText = result.moreLikes
                    this.successLoadWeeklyText(result);
                }, () => {
                    this.errorCallback();
                });
            }
            else if (this.day == 6) {
                this.loadingText = true;
                this.dailyTextService.getCurrent().pipe(takeUntil(this._unsubscribeAll)).subscribe((result: []) => {
                    this.dailyTextService.dailyText = result;
                    this.successLoadDailyTextSaturday(result);
                }, () => {
                    this.errorCallback();
                })
            }
            else {
                this.loadingText = true;
                this.dailyTextService.getCurrent().pipe(takeUntil(this._unsubscribeAll)).subscribe((result: []) => {
                    this.dailyTextService.dailyText = result;
                    this.successLoadDailyText(result);
                }, () => {
                    this.errorCallback();
                })
            }
        }
    }

    getAvailableItems() {
        this.availableItems = [];
        let today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        this.shopService.BoughtItems.forEach(userItem => {

            if (userItem.ShopItem.IdCivilization != 9) {

                if (userItem.ShopItem.IdCivilization == this.currentUser.Civilization.IdCivilization) {
                    this.availableItems.push(userItem);
                }
            } else if (new Date(userItem.ShopItem.specialFrom2).getTime() <= today.getTime() &&
                        new Date(userItem.ShopItem.specialTo2).getTime() >= today.getTime()) {
                this.availableItems.push(userItem);
            }
        })
    }

  like() {
    if (this.isDemo) return
    if (this.dailyTextScore != null && this.dailyTextScore.Score != 1) return;
    if (this.calling)
      return;
    if (this.dailyTextScore == null && this.dailyText != null) {
      this.calling = true;
      this.coin = 1;
      this.AddScore(1)
    }
  }

  dontLike() {
    if (this.isDemo) return
    if (this.dailyTextScore != null && this.dailyTextScore.Score != -1) return;
    if (this.calling)
      return;
    if (this.dailyTextScore == null && this.dailyText != null) {
      this.calling = true;
      this.coin = 2;
      this.AddScore(-1)
      }
  }

  dontKnow() {
    if (this.isDemo) return
    if (this.dailyTextScore != null && this.dailyTextScore.Score != 0) return;
    if (this.calling)
      return;

    if (this.dailyTextScore == null && this.dailyText != null) {
      this.calling = true;
      this.coin = 3;
      this.AddScore(0)
    }
  }

  successLoadDailyScore(response) {

    if (response === null) {

      this.dailyTextScore = null;
      this.dailyTextService.dailyTextScore = null;     
    }
    else {

      this.dailyTextScore = response;
      this.dailyTextService.dailyTextScore = response;

    }

  }

  getImgButtonFrase(boton) {
        var url = "https://walinwa.blob.core.windows.net/images/Student/daily-text/" + boton + ".png";
      if (this.availableItems && this.availableItems.length > 0) {
        this.availableItems = this.availableItems.sort((a, b) => a.IdShopItem - b.IdShopItem);
      for (var i = 0; i < this.availableItems.length; i++) {
        if (this.availableItems[i].Status == 1 && this.availableItems[i].ShopItem.Type == 10) {
          if (this.availableItems[i].ShopItem.IdCivilization != 9)
            url = "https://walinwa.blob.core.windows.net/images/" + this.fileUrl + this.availableItems[i].ShopItem.Civilization.ImgFolder + "/shop/" + this.availableItems[i].ShopItem.IdShopItem + "_" + boton + ".png";
          else
            url = "https://walinwa.blob.core.windows.net/images/" + this.fileUrl + "Special/" + this.availableItems[i].ShopItem.IdShopItem + "_" + boton + ".png";
        }
      }
    }
    return url;
  }

  successLoadDailyScore2(response) {
    this.dailyTextScore = response;
    this.dailyTextService.dailyTextScore = response;
    var options = this.dailyText.Book.split(',');
    this.dailyText.options = [];
    this.dailyText.answer = this.dailyTextScore;

    for (var i = 0; i < options.length; i++) {
      var tmp = options[i].trim();
      var correct = tmp.charAt(0) == '(' && options[i].slice(-1) == ')';
      var option = correct ? options[i].replace('(', '').replace(')', '') : options[i];

      this.dailyText.options.push({
        option: option.trim(),
        correct: correct
      });

    }
  }

  successLoadDailyScoreSaturday(response) {

    if (response === null) {
      this.dailyTextService.dailyTextScore = null;
      this.dailyTextScore = null;
      this.ecoText = true;
      this.loadingText = false;
      this.color = "var(--verde)"
    }
    else {
      this.ecoText = false;
      this.dailyTextScore = response;
      this.dailyTextService.dailyTextScore = response;
      this.dailyTextService.getWeekResult().pipe(takeUntil(this._unsubscribeAll)).subscribe(this.successLoadWeeklyText.bind(this), this.errorCallback.bind(this));

    }

  }

  successAddDailyTextScore(response) {

    const mobi = window.innerWidth;

    this.calling = false;
    if (response === null) {
      this.dailyTextScore = null;
      this.dailyTextService.dailyTextScore = null;
    }
    else {
      if (this.coin === 1)
        {
          if(mobi > 599){
            this.coinService.showCoins(10, () => { this.sharedService.changeUserCoins(true) }, "like", "coin-plank");
          }else{
            this.coinService.showCoins(10, () => { this.sharedService.changeUserCoins(true) }, "like-mob", "coin-plank-mob");
          }
        }
        
      if (this.coin === 2)
        {
          if(mobi > 599){
            this.coinService.showCoins(10, () => { this.sharedService.changeUserCoins(true) }, "dontLike", "coin-plank");;
          }else{
            this.coinService.showCoins(10, () => { this.sharedService.changeUserCoins(true) }, "dontLike-mob", "coin-plank-mob");
          }
        }

      if (this.coin === 3)
        {
          if(mobi > 599){
            this.coinService.showCoins(10, () => { this.sharedService.changeUserCoins(true) }, "dontKnow", "coin-plank");
          }else{
            this.coinService.showCoins(10, () => { this.sharedService.changeUserCoins(true) }, "dontKnow-mob", "coin-plank-mob");
          }
        }

      this.dailyTextScore = response;
      this.dailyTextService.dailyTextScore = response;
    }
  }

  successAddDailyTextScore2(response) {

    this.calling = false;
    if (response === null) {
      this.dailyTextScore = null;
      this.dailyTextService.dailyTextScore = null;
    }
    else {
      this.dailyTextScore = response;
      this.dailyTextService.dailyTextScore = response;
      if (this.dailyTextScore.Score != 3) {
        const mobi = window.innerWidth;
        this.coinService.showCoins(25, () => {
            this.sharedService.changeUserCoins(true)
        }, null, (mobi > 599 ? "coin-plank" : "coin-plank-mob"))
      }
    }
  }

  successLoadDailyText(response) {
    if (response === null) {
      this.dailyText = null;
      this.loadingText = false;
    }
    else {
      this.dailyText = response;
      this.weeklyText = false;
      this.sundayText = false;
      this.loadingText = false;
      this.ecoText = false;
      if (this.dailyText != null && this.dailyText.Autor === '¿SABÍAS QUÉ?')
        this.didyouknow = true;
      if (this.dailyText != null && this.dailyText.Autor === 'ECOEMBES')
        this.ecoText = true;
      if(this.dailyTextService.dailyTextScore !== undefined){
        this.successLoadDailyScore(this.dailyTextService.dailyTextScore)
      }
      else {
        this.dailyTextService.getCurrentScore(this.currentUser.Id, this.dailyText.IdDailyText)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(result => {
            this.successLoadDailyScore(result);
        },() => {
            this.errorCallback()
        });
      }
      
    }

  }

  successLoadDailyTextSaturday(response) {
    if (response === null) {
      this.color = "var(--verde)"
      this.dailyText = null;
      this.loadingText = false;
        if (this.day == 6) {
          this.dailyTextService.getWeekResult().pipe(takeUntil(this._unsubscribeAll)).subscribe(this.successLoadWeeklyText.bind(this), this.errorCallback.bind(this));
        }
    }
    else {
      this.dailyText = response;
      this.weeklyText = false;
      this.sundayText = false;
      this.ecoText = false;
      if (this.dailyText != null && this.dailyText.Autor === '¿SABÍAS QUÉ?')
        this.didyouknow = true;
      this.dailyTextService.getCurrentScore(this.currentUser.Id, this.dailyText.IdDailyText).pipe(takeUntil(this._unsubscribeAll)).subscribe(this.successLoadDailyScoreSaturday.bind(this), this.errorCallback.bind(this));
    }
  }

  successLoadWeeklyText(response) {

    if (response === null) {

      this.dailyText = null;
      this.loadingText = false;

    }
    else {
        if (response.moreLikes == undefined) {
          this.dailyText = response
        }
        else {
            this.dailyText = response.moreLikes;
        }
      if (this.dailyText != null && this.dailyText.Autor === '¿SABÍAS QUÉ?')
        this.didyouknow = true;
      if (this.dailyText != null && this.dailyText.Autor === 'ECOEMBES')
        this.ecoText = true;
      this.loadingText = false
      this.weeklyText = true;
      if (this.dailyText != null && this.dailyText.Autor === 'La pregunta del domingo') {
        this.didyouknow = false;
        this.weeklyText = false;
        this.sundayText = true;
        if(this.dailyTextService.dailyTextScore !== undefined){
            this.successLoadDailyScore2(this.dailyTextService.dailyTextScore);
        }
        else {
            this.dailyTextService.getCurrentScore(this.currentUser.Id, this.dailyText.IdDailyText).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                this.successLoadDailyScore2(result);
            }, () => {
                this.errorCallback();
            })
        }
      }
    }

  }
  ecoembes() {
    if (this.isDemo) return
    if (this.dailyTextScore) return;
    this.navigationService.go("student/ecoembes", { dailyText: this.dailyText, dict: false })
    // $state.go('studentMenu.studentEcoembes', { dailyText: this.dailyText, dict: false });
  }
  answerSunday(optionIndex) {

    if (this.isDemo) return
    if (this.dailyTextScore) return;

    this.coin = optionIndex + 1;

    if (this.dailyText.options[optionIndex].correct) {
      this.AddScore(2)
      this.dailyText.answer = this.dailyText.options[optionIndex];

    } else {
      this.AddScore(3)

      var correctAnswer;

      for (var i = 0; i < this.dailyText.options.length; i++) {

        if (this.dailyText.options[i].correct) {

          correctAnswer = this.dailyText.options[i];

        }

      }

      let message = [];
      message[0] = this._translateService.instant('COMPONENTS.DAILY-TEXT.WRONG-ANSWER-TEXT');
      message[1] = this._translateService.instant('COMPONENTS.DAILY-TEXT.TELL-ANSWER-TEXT') + new ReplaceTags().transform(correctAnswer.option);
      let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
        disableClose: true
      });
      confirmDialog.componentInstance.title = message[0];
      confirmDialog.componentInstance.message1 = message[1];
      confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
      confirmDialog.componentInstance.margin = false;
      confirmDialog.afterClosed().subscribe(result => {
        this.dailyText.answer = this.dailyText.options[optionIndex];
        confirmDialog = null;
      });
    }

  }

  AddScore(score: number){
    this.dailyTextService.addCurrentScore(this.currentUser.Id, this.dailyText.IdDailyText, score)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(result => {
        if(score === 2 || score === 3) {
            this.successAddDailyTextScore2(result)
        }
        else {
            this.successAddDailyTextScore(result)
        }
    },() => {
        this.errorCallback();
    })
  }

  errorCallback() {
    this.navigationService.exit();
  }
    /*getImgUrl(imgName: string, baseDirectory = false) {
        if (baseDirectory) {
            return this.sharedService_.getImgUrl(imgName)
        }
        return this.sharedService_.getImgUrl("Student/daily-text/" + imgName);
    }*/
}
