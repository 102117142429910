<form style="display: none" *ngIf="config" id="tpvForm" target="tpviframe" action="{{ config.url }}" method="POST">
    <input name="Ds_SignatureVersion" [(ngModel)]="config.parametrs.Ds_SignatureVersion" />
    <input name="Ds_MerchantParameters" [(ngModel)]="config.parametrs.Ds_MerchantParameters" />
    <input name="Ds_Signature" [(ngModel)]="config.parametrs.Ds_Signature" />
    <input type="submit" value="Enviar">
</form>
<div class="walinwa-overlay">
    <div class="walinwa-modal2">
        <img (click)="close()" class="walinwa-modal2-close" src="https://walinwa.blob.core.windows.net/images/blanco-99.png?ngsw-bypass=true">
        <iframe name="tpviframe" class="walinwa-tpv-iframe"></iframe>
    </div>
</div>