import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { NavigationService } from 'app/services/navigation.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-parent',
  templateUrl: './parent.component.html',
  styleUrls: ['./parent.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ParentComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  dataLoaded = false;
  currentUser;

  constructor(private router: Router, private navigationService: NavigationService, private authService: AuthService, private activatedRoute: ActivatedRoute) { 
    this._unsubscribeAll = new Subject();
   }

   ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

  ngOnInit() {
    if(!this.authService.check()) return;
    this.currentUser = this.authService.currentUser;
    this.dataLoaded = true;
  }

  exit() {
    this.navigationService.exit(true);
  }

  inConfig(){
    return this.router.url.includes("config");
  }

}
