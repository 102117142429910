import { AvatarShopService } from 'app/services/avatar-shop.service';
import { SchoolService } from 'app/services/school.service';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { AuthService } from 'app/services/auth.service';
import { DictationService } from 'app/services/dictation.service';
import { NavigationService } from 'app/services/navigation.service';
import * as moment from 'moment';
import { CalDictationsComponent } from 'app/components/cal-dictations/cal-dictations.component';
import { Router } from '@angular/router';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { SharedService } from 'app/core/shared/shared.service';
import { ClassService } from 'app/services/class.service';
import { TranslateService } from '@ngx-translate/core';
declare var $;

@Component({
    selector: 'app-dictation',
    templateUrl: './dictation.component.html',
    styleUrls: ['./dictation.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DictationComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
    @ViewChild(CalDictationsComponent) child: CalDictationsComponent;

    currentUser;
    selectedDay;
    currentMonth;
    month;
    year;
    dictations = null;
    isFuture = false;
    canDoIt = false;
    isDone = false;
    loading: boolean = true;
    tooltipText = this._translateService.instant('STUDENT.DICTATION.UNLOCK-AVATAR-TOOLTIP');
    isMobile: boolean;
    timeBlock: boolean = false;
    dictRewardsInfo = null;

    constructor(
        private authService: AuthService,
        private dictationService: DictationService,
        private navigationService: NavigationService,
        private router: Router,
        private _matDialog: MatDialog,
        private sharedService: SharedService,
        private schoolService: SchoolService,
        private avatarShopService: AvatarShopService,
        private classService: ClassService,
        private _translateService: TranslateService
    ) {
        this._unsubscribeAll = new Subject();
        this.selectedDay = moment();
        this.currentMonth = this.selectedDay.month() + 1;
        this.month = this.selectedDay.month() + 1;
        this.year = this.selectedDay.year();
        this.canDoIt = false;
        this.isDone = false;
    }
    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    ngOnInit() {
        this.avatarShopService.currentStatus
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                if (result) {
                    this.tooltipText = this._translateService.instant('STUDENT.DICTATION.UNLOCK-AVATAR-ACCESSORIES-TOOLTIP')
                }
            })
        if (!this.authService.check()) return;
        this.sharedService.dictation_enabled
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(dictationEnabled => {
                if (!dictationEnabled) {
                    this.navigationService.go("student/session");
                }
            })
        this.currentUser = this.authService.currentUser;
        this.getDictationsByMonth();
        this.dictationService.onFinishDictation
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(x => {
                this.getDictRewardsInfo()
                this.selectedDayChangedDictation(x, true)
            });

        this.isMobile = this.detectMobile();
            this.getDictRewardsInfo()
    }
    getDictRewardsInfo() {
        this.dictationService.getDictRewardsInfo(this.authService.currentUser.Id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
            this.dictRewardsInfo = result
        })
    }
    detectMobile(): boolean {
        const userAgent = navigator.userAgent.toLowerCase();
        return /mobile|android|iphone|ipad|iemobile|wpdesktop/i.test(userAgent);
    }

    sixWeeks() {
        return $('.week').length - 1 >= 6;
    }
    RewardText() {
        var text = "<span>"
        if (!this.dictRewardsInfo || !this.dictRewardsInfo.LastRewardDate) {
            text = this._translateService.instant('STUDENT.DICTATION.NEVER-REWARDED-TEXT');
        }
        else if(this.dictRewardsInfo.LastRewardDate) {
            text = this._translateService.instant('STUDENT.DICTATION.LAST-REWARD-TEXT') + moment(this.dictRewardsInfo.LastRewardDate).format("DD/MM/YYYY") + "."
        }
        text += this._translateService.instant('STUDENT.DICTATION.GOALS.HEADER');
        text += "<span>" + this.dictRewardsInfo?.t6 + this._translateService.instant('STUDENT.DICTATION.GOALS.GOAL1');
        text += "<span>" + this.dictRewardsInfo?.t7 + this._translateService.instant('STUDENT.DICTATION.GOALS.GOAL2');
        text += "<span>" + this.dictRewardsInfo?.t8 + this._translateService.instant('STUDENT.DICTATION.GOALS.GOAL3');
        text += "<span>" + this.dictRewardsInfo?.t9 + this._translateService.instant('STUDENT.DICTATION.GOALS.GOAL4');
        return text;
    }
    async selectedDayChangedDictation(newDate, reload = false) {
        this.selectedDay = newDate;
        this.month = this.selectedDay.month() + 1;
        this.year = this.selectedDay.year();
        this.canDoIt = false;
        this.isDone = false;

        if (reload || this.currentMonth != this.selectedDay.month() + 1) {
            this.currentMonth = this.selectedDay.month() + 1;
            this.getDictationsByMonth();
        }
        else {
            let dictation = (this.dictations || []).find(a => a.Dictado == this.selectedDay.week() + '_' + this.selectedDay.isoWeekday());
            if (dictation) {
                this.isDone = true;
            }
        }
        if (!reload) {
            this.assertCanDoIt();
        }

        this.assertIsFuture();
    }
    
    assertCanDoIt() {
        if (this.selectedDay.format() < this.authService.currentUser.CreationDate && !this.authService.currentUser.UserName.includes(".tmp.")) {
            this.canDoIt = false;
        }
        else {
            this.canDoIt = true;
        }
    }

    async assertIsFuture() {
        if (this.authService.currentUser.IsAdmin) {
            this.isFuture = false;
        } else {
            if (moment().utc().isSameOrBefore(this.selectedDay, 'day')) //today or future
                this.isFuture = true;
            else if (moment(this.authService.currentUser.CreationDate).diff(this.selectedDay, 'days', true) > 0)
                this.isFuture = true;
            else if (!this.isDone && moment().utc().month() - this.selectedDay.month() > 1)
                this.isFuture = true;
            else
                this.isFuture = false;
        }
    }

    getDictationsByMonth(reload = false) {
        this.dictationService.getDictationsByMonth(this.authService.currentUser.Id, this.month, this.year)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(resp => {
                this.dictations = resp || [];

                let dictation = this.dictations.find(a => a.Dictado == this.selectedDay.week() + '_' + this.selectedDay.isoWeekday());
                if (dictation) {
                    this.isDone = true;
                }

                this.assertCanDoIt();
                this.assertIsFuture();

            }, (err) => {
                console.error(err);
            })

    }

    play() {
        if (!this.isAvailableMonth(this.selectedDay)) {
            let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
                disableClose: true
            });
            confirmDialog.componentInstance.title = this._translateService.instant('STUDENT.DICTATION.START-DICTATION-DIALOG-TITLE');
            confirmDialog.componentInstance.message1 = this._translateService.instant('STUDENT.DICTATION.CANT-RECOVER-DICTATION-FROM-DATE');
            confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
            confirmDialog.componentInstance.margin = false;
            confirmDialog.afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(result => {
                    this.router.navigate(['/student/dictations']);
                    confirmDialog = null;
                });
            return;
        }
        if (this.currentUser.IdClass) {
            this.checkTimeBlock();
        }
        else {
            this.navigationService.go("student/dictation/play/", { selectedDay: this.selectedDay });
        }
        this.fixPositionScroll_vertical_Class();
    }
    checkTimeBlock() {
        this.timeBlock = false;
        this.classService.canExecuteDictation(this.currentUser.IdClass)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result: any) => {
            //console.log(result);
            this.timeBlock = !result.canStart
            if(this.timeBlock){
                let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
                    disableClose: true
                });
                confirmDialog.componentInstance.title = this._translateService.instant('STUDENT.DICTATION.DISABLED-THIS-HOURS');
                confirmDialog.componentInstance.theme = "white";
                confirmDialog.componentInstance.margin = false;
                confirmDialog.componentInstance.options = [
                    {
                        text: this._translateService.instant('ACCEPT'),
                    }
                ];
                confirmDialog.componentInstance.margin = false;
                confirmDialog.afterClosed()
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(result => {
                        confirmDialog = null;
                    });
            }
            else {
                this.navigationService.go("student/dictation/play/", { selectedDay: this.selectedDay });
            }
        }, error => {
            this.loading = false;
        })
    }
    review() {
        this.navigationService.go("student/dictation/review/", { selectedDay: this.selectedDay });
        this.fixPositionScroll_vertical_Class();
    }
    isAvailableMonth(date) {
        if (!this.currentUser) return true;
        if (moment(this.currentUser.CreationDate).diff(date, 'days') > 0 && !this.currentUser.UserName.includes(".tmp.")) return true;
        if ((date.month() > moment().month() - 2 && date.year() === moment().year()) ||
            (date.year() > moment().year()) ||
            (moment().month() == 0 && date.month() == 11 && (date.year() + 1) === moment().year()))
            return true;
        return false;
    }

    fixPositionScroll_vertical_Class() {
        const elements = document.querySelectorAll('.iphonechromepopup');
        elements.forEach((element) => {
            // (element as HTMLElement).style.overflow = 'inherit';
            element.classList.add("popup");
        });
        //console.log("popup scroll_fixed");
    }
}
