<section fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutAlign.lt-md="stretch"
    class="spe_gap">
    <div fxFlex="" fxNgClass.gt-sm="scroll_vertical">
        <div fxLayout="row" fxLayoutAlign="row" fxLayoutGap="20px" class="ww_tb_he_in">
            <div fxFlex="">
                <mat-form-field class="ww_mat-form-field-clas">
                    <mat-select class="select" [ngModelOptions]="{standalone: true}" (selectionChange)="changeClass()"
                        [(ngModel)]="selectedClass">
                        <mat-option class="scrollable" [value]="class.IdClass"
                            *ngFor="let class of classes; let i = index">{{class.Name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="" fxLayout="column" fxLayoutAlign="stretch">
                <span *ngIf="selectedClassCode">{{'SCHOOL.TEACHER.STUDENTS.CLASS-CODE' | translate }} {{selectedClassCode}}</span>
                <span *ngIf="!selectedClassCode">{{'SCHOOL.TEACHER.STUDENTS.NO-CLASS-CODE' | translate }}</span>
                <span>{{'SCHOOL.TEACHER.STUDENTS.STUDENTS-COUNT' | translate }} {{ students.length }}</span>
            </div>
        </div>
        <div class="">
            <div class="ww_table_rwd">
            <table class="walinwa-table">
                <thead>
                    <tr>
                        <th style="width: 50%;">{{'NAME' | translate}}</th>
                        <th style="width: 50%;">{{'USERNAME' | translate}}</th>
                    </tr>
                </thead>
                <ng-container *ngIf="checkStudents()">
                    <tbody>
                        <tr [ngClass]="{'selected' : selectedIdUser == student.Id, 'disabled': student.Activated == false}"
                            *ngFor="let student of students"
                            (click)="selectUser(student.Id)" class="walinwa-table-row">
                            <td>{{ student.FamilyName }}{{ getComa(student)}} {{ student.Name }}</td>
                            <td>{{ student.UserName }}</td>
                        </tr>
                    </tbody>
                </ng-container>
                <ng-container *ngIf="!checkStudents()">
                    <tbody>
                        <tr [ngClass]="{'selected' : selectedIdUser == student.Id, 'disabled': student.Activated == false}"
                            *ngFor="let student of students" (click)="selectUser(student.Id)"
                            class="walinwa-table-row">
                            <td>{{ student.FamilyName }}{{ getComa(student)}} {{ student.Name }}</td>
                            <td>{{ student.UserName }}</td>
                        </tr>
                    </tbody>
                </ng-container>
            </table>
            </div>
        </div>
    </div>
    <div fxFlex="12rem" fxFlex.lt-md="0 0 auto" fxNgClass.gt-sm="scroll_vertical">
        <div class="ww_sidebar_inner">
            <div (click)="goEditStudent()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center"
                fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap">
                <div fxFlex="3rem" fxFlex.lt-md="3.6rem"><img src="https://walinwa.blob.core.windows.net/images/School/ico-edit-alum.svg?ngsw-bypass=true" /></div>
                <div fxFlex="" [innerHTML]="'SCHOOL.EDIT-STUDENT-TITLE' | translate"></div>
            </div>
            <div [hidden]="this.selectedClass == -1" (click)="goEditClass()" fxLayout="row" fxLayout.lt-md="column"
                fxLayoutAlign="start center" fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem"
                fxNgClass.gt-sm="spe_gap">
                <div fxFlex="3rem" fxFlex.lt-md="3.6rem"><img src="https://walinwa.blob.core.windows.net/images/School/ico-edit-aula.svg?ngsw-bypass=true" /></div>
                <div fxFlex=""><span [innerHTML]="'SCHOOL.TEACHER.STUDENTS.EDIT-CLASS-TITLE' | translate"></span></div>
            </div>
            <div (click)="goSessionReview()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center"
                fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap">
                <div fxFlex="3rem" fxFlex.lt-md="3.6rem"><img src="https://walinwa.blob.core.windows.net/images/School/ico-rev-activi.svg?ngsw-bypass=true" /></div>
                <div fxFlex=""><span [innerHTML]="'SCHOOL.REVIEW-ACTIVITIES-TITLE' | translate"></span></div>
            </div>
            <div (click)="resetStudentPassword()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center"
                fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap">
                <div fxFlex="3rem" fxFlex.lt-md="3.6rem"><img src="https://walinwa.blob.core.windows.net/images/School/ico-reini-contra.svg?ngsw-bypass=true" /></div>
                <div fxFlex=""><span [innerHTML]="'SCHOOL.RESET-PASSWORD-TITLE' | translate"></span></div>
            </div>
            <div (click)="newUser()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center"
                fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap">
                <div fxFlex="3rem" fxFlex.lt-md="3.6rem"><img src="https://walinwa.blob.core.windows.net/images/School/ico-add-alum.svg?ngsw-bypass=true" /></div>
                <div fxFlex=""><span [innerHTML]="'SCHOOL.ADD-STUDENT-TITLE' | translate"></span></div>
            </div>
            <div (click)="goAuthTutor()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center"
                fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap">
                <div fxFlex="3rem" fxFlex.lt-md="3.6rem"><img src="https://walinwa.blob.core.windows.net/images/School/ico-auto-tutor.svg?ngsw-bypass=true" /></div>
                <div fxFlex=""><span [innerHTML]="'SCHOOL.AUTH-TUTOR-TITLE' | translate"></span></div>
            </div>
            <div (click)="deleteUser()" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center"
                fxLayoutAlign.lt-md="center" fxLayoutGap.lt-md="0.3rem" fxNgClass.gt-sm="spe_gap" [ngClass]="{ 'iPad_iPhone_margin': isIpadIphone }">
                <div fxFlex="3rem" fxFlex.lt-md="3.6rem"><img src="https://walinwa.blob.core.windows.net/images/School/ico-elim-alum.svg?ngsw-bypass=true" /></div>
                <div fxFlex=""><span [innerHTML]="'SCHOOL.DELETE-STUDENT-TITLE' | translate"></span></div>
            </div>
        </div>
    </div>
</section>
<img [hidden]="!loading" style="width: 10em; position: fixed;top: 0;left: 0;bottom: 0;right: 0;margin: auto;"  src="https://walinwa.blob.core.windows.net/images/loading.gif?ngsw-bypass=true">
