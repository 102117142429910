import { ChangeDetectorRef, Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'app/app.service';
import { DeductionTextService } from 'app/services/deduction-text.service';
import { DictationService } from 'app/services/dictation.service';
import { ExerciseService } from 'app/services/exercise.service';
import { NavigationService } from 'app/services/navigation.service';
import { ReadingTextService } from 'app/services/reading-text.service';
import { SchoolService } from 'app/services/school.service';
import { SessionService } from 'app/services/session.service';
import { UserService } from 'app/services/user.service';
import * as moment from 'moment';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
declare var $;

@Component({
    selector: "app-session",
    templateUrl: "./session.component.html",
    styleUrls: ["./session.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class SessionReviewComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  six_weeks_deduction
  six_weeks_reading

    tab = 0;
    student;
    currentUser;
    NotDone = 0;
    Started = 3;
    Finished = 1;
    Pending = 2;
    NotFinished = 4;
    // student = $stateParams.student;
    showReview = false;
    showReviewDict = false;
    showReviewCL = false;
    showReviewDT = false;
    dataLoaded = false;
    dictEnabled = true;
    status = "sessions";
    currentDict = null;
    selectedDay;
    selectedDayDict;
    selectedDayCL;
    selectedDayDT;
    month;
    year;

    dictations;
    currentMonth;
    currentMonthDict = 0;
    currentMonthCL = 0;
    currentMonthDT = 0;
    currentSessionStatus = null;
    currentSession;
    SessionStatus = {
        finished: "FINISHED",
        started: "STARTED",
        newSession: "NEW",
        generating: "GENERATING",
        notfinished: "NOTFINISHED",
    };
    selectedIdUser: any;
    selectedIdClass: any;
    idClass: any;
    isAdmin: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private sessionService: SessionService,
    private navigationService: NavigationService,
  ) { 
    this._unsubscribeAll = new Subject();
}

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit() {
        if (this.navigationService.params.IdUser)
            this.selectedIdUser = this.navigationService.params.IdUser;
        if (this.navigationService.params.IdClass)
            this.selectedIdClass = this.navigationService.params.IdClass;
        if (this.navigationService.params.isAdmin)
            this.isAdmin = this.navigationService.params.isAdmin;

        this.selectedDay = moment();
        this.month = this.selectedDay.month() + 1;
        this.year = this.selectedDay.year();
        this.idClass = this.activatedRoute.params["value"].IdClass;
        this.getUser(this.activatedRoute.params["value"].Id);
    }

  getUser(id) {
    this.userService.getUserById(id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        if (result == null) return this.navigationService.exit();
        this.student = result;
        this.loadSessionMonth();
        ;
      },
      this.errorCallback.bind(this)
    )
  }
  loadSessionMonth() {
    this.sessionService.getSessionByUserMonthYear(this.student.Id, this.month, this.year)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successLoadSessionMonth.bind(this), this.errorCallback.bind(this));
  }

    successLoadSessionMonth(response) {
        if (response == null) return this.navigationService.exit();
        this.student.Sessions = response;
        this.currentMonth = this.month;
        this.dataLoaded = true;
        this.loadCurrentSession();
    }

  loadCurrentSession() {
    this.currentSessionStatus = null;
    if (!this.selectedDay) return;
    for (var i = 0; i < this.student.Sessions.length; i++) {
      if (this.selectedDay.isSame(moment(this.student.Sessions[i].ExecutionDate), "day")) {
        this.sessionService.getSession(this.student.Sessions[i].IdSession)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successLoadSession.bind(this), this.errorCallback.bind(this));
      }
    }
  }

    successLoadSession(response) {
        if (response == null) return this.navigationService.exit();
        this.currentSessionStatus = null;
        this.currentSession = response;
        if (this.currentSession.Status == this.SessionStatus.started) {
            this.currentSessionStatus = this.Started;
            this.showReview = false
        }
        else if (this.currentSession.Status == this.SessionStatus.notfinished) {
            this.currentSessionStatus = this.NotFinished;
            this.showReview = false
        }
        else if (this.currentSession.Status == this.SessionStatus.finished) {
            this.currentSessionStatus = this.Finished;
            this.showReview = !this._isRetrySessionAvailable() &&
                this.currentSession.Exercises != null &&
                this.currentSession.Exercises.length > 0;
        } else if (
            this.currentSession.Status == this.SessionStatus.newSession
        ) {
            this.showReview = false;
            if (moment().diff(this.selectedDay, "days", true) < 0) {
                this.currentSessionStatus = null;
            } else if (moment().diff(this.selectedDay, "days", true) < 1)
                this.currentSessionStatus = this.Pending;
            else this.currentSessionStatus = this.NotDone;
        }
    }

    private _isRetrySessionAvailable(): boolean {
        return this.currentSession && moment(this.currentSession.ExecutionDate).diff(moment().utc(), 'days') === 0 && this.currentSession.RetrieveScore !== null && this.currentSession.RetrieveScore !== undefined && this.currentSession.ScoreNew === null;
    }    

    startReview() {
        if (
            this.currentSessionStatus != this.Finished &&
            this.currentSessionStatus != this.NotFinished
        )
            return;
        this.navigationService.go("student/session/play", {
            session: this.currentSession,
            errors: false,
            IdClass: this.idClass,
            isAdmin: this.isAdmin,
        });
    }
    sixWeeks() {
        return $(".week").length - 1 >= 6;
    }

    errorCallback() {
        this.navigationService.exit();
    }
    selectedDayChanged(newDate) {
        this.showReview = false;
        this.selectedDay = newDate;
        if (this.month != this.selectedDay.month() + 1) {
            this.month = this.selectedDay.month() + 1;
            this.year = this.selectedDay.year();
            this.loadSessionMonth();
            return;
        }
        this.loadCurrentSession();
    }

    startReviewErrors() {
        if (
            this.currentSessionStatus != this.Finished &&
            this.currentSessionStatus != this.NotFinished
        )
            return;

        this.navigationService.go("student/session/play", {
            session: this.currentSession,
            errors: true,
            IdClass: this.selectedIdClass,
            isAdmin: this.isAdmin,
        });
    }
}
