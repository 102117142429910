<div *ngIf="!review" id="visualmemory-main" class="exercise-container not-selectable" 
    fxFlex="" fxLayout="column" fxLayoutAlign="stretch" 
    [ngStyle]="{ 'background-image': 'url(&quot;https://walinwa.blob.core.windows.net/images/MemoImage/memoriavisual.png?ngsw-bypass=true&quot;)' }"
    [ngStyle.sm]="{ 'background-image': 'url(&quot;https://walinwa.blob.core.windows.net/images/MemoImage/nubes-rosa.svg?ngsw-bypass=true&quot;), url(&quot;https://walinwa.blob.core.windows.net/images/MemoImage/nubes-rosa-fondo.svg?ngsw-bypass=true&quot;)' }"
    [ngStyle.xs]="{ 'background-image': 'url(&quot;https://walinwa.blob.core.windows.net/images/MemoImage/nubes-rosa.svg?ngsw-bypass=true&quot;), url(&quot;https://walinwa.blob.core.windows.net/images/MemoImage/nubes-rosa-fondo.svg?ngsw-bypass=true&quot;)' }">
    <div class="" fxLayout="row" fxLayoutAlign="end end" style="position: relative;">
        <img (click)="close()" src="https://walinwa.blob.core.windows.net/images/ico-close-popup.svg?ngsw-bypass=true" class="walinwa-box-modal-header-button" fxFlex="2.5em" class="pointer closewindow" >
    </div>
    <div fxFlex="" class="exercise-contain spe_gap" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-between" fxLayoutAlign="stretch">
        <div fxFlex="15rem" fxFlex.lt-md="100%" fxFlex.xs="0 0 auto" class="left">
            <div id="timer" class="timer-container" fxShow fxHide.lt-md>
                <div style="color: var(--gris);" class="history-point-timer">
                    <span [hidden]="!animate" id="walinwos-span">200</span>
                    <span [hidden]="animate">{{_visualMemory.walinwos}}</span>
                    <p>walinwos</p>
                </div>
                <div class="tutotial">
                    <ng-container *ngIf="_visualMemory.step == 1">
                        <h1> {{'STUDENT.VISUAL-MEMORY.PAGE' | translate}} {{_visualMemory.page}} </h1>
                        <p [innerHTML]="'STUDENT.VISUAL-MEMORY.STEP1' | translate">
                        </p>
                    </ng-container>
                    <ng-container *ngIf="_visualMemory.step == 2 && !_visualMemory.validated">
                        <h1> {{'STUDENT.VISUAL-MEMORY.PAGE' | translate}} {{_visualMemory.page}} </h1>
                        <p [innerHTML]="'STUDENT.VISUAL-MEMORY.STEP2-NOT-VALIDATED' | translate">
                        </p>
                    </ng-container>
                    <ng-container *ngIf="_visualMemory.step == 2 && (!checkAciertos() && _visualMemory.validated)">
                        <h1> {{'STUDENT.VISUAL-MEMORY.PAGE' | translate}} {{_visualMemory.page}} </h1>
                        <p [innerHTML]="'STUDENT.VISUAL-MEMORY.STEP2-NOT-APRROBED' | translate : {aciertos: aciertos}">
                        </p>
                    </ng-container>
                    <ng-container
                        *ngIf="_visualMemory.page != 5 && _visualMemory.step == 2 && (checkAciertos() && _visualMemory.validated)">
                        <h1 class="game-continue-h1">{{'STUDENT.VISUAL-MEMORY.STEP2-TITLE' | translate}}</h1>
                        <p class="game-continue-p" [innerHTML]="'STUDENT.VISUAL-MEMORY.STEP2-APRROBED' | translate : {walinwos: 5 * (_visualMemory.page + 1)}">
                        </p>
                    </ng-container>
                    <ng-container
                        *ngIf="_visualMemory.page == 5 && _visualMemory.step == 2 && (checkAciertos() && _visualMemory.validated)">
                        <h1 class="game-continue-h1">{{'STUDENT.VISUAL-MEMORY.STEP2-PERFECT-TITLE' | translate}}</h1>
                        <p class="game-continue-p">{{'STUDENT.VISUAL-MEMORY.STEP2-PERFECT-TEXT' | translate}}</p>
                    </ng-container>
                </div>
                <img src="https://walinwa.blob.core.windows.net/images/walinwo.gif?ngsw-bypass=true" class="walinwo-gif"
                    [ngStyle]="{ bottom: this.currentPorcent + '%' }">                   
                <div class="columnTimer" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/' + directory + 'completo.png&quot;)'"></div>
                <div class="timer">
                    <img src="https://walinwa.blob.core.windows.net/images/MemoImage/lineas-turno.png?ngsw-bypass=true" />
                </div>
            </div>
            <div fxShow.lt-md fxHide fxLayout="column" fxLayoutAlign="center center" class="ww_new_panel">
                <div class="ww_walinwos">
                    <span [hidden]="!animate" id="walinwos-span">200</span>
                    <span [hidden]="animate">{{_visualMemory.walinwos}}</span>
                    <div class="text-center">walinwos</div>
                </div>
                <div class="new_bar">
                    <div class="progressbar">
                        <div class="timeri" [ngStyle]="{ width: (this.currentPorcent * 2) + '%' }"
                            [ngClass]="(this.currentPorcent == 0) ? 'complete' : ''"></div>
                    </div>
                </div>
                <div class="ww_intronote">
                    <ng-container *ngIf="_visualMemory.step == 1">
                        <b> {{'STUDENT.VISUAL-MEMORY.PAGE' | translate}} {{_visualMemory.page}} </b>
                        <div [innerHTML]="'STUDENT.VISUAL-MEMORY.STEP1' | translate">
                        </div>
                    </ng-container>
                    <ng-container *ngIf="_visualMemory.step == 2 && !_visualMemory.validated">
                        <b> {{'STUDENT.VISUAL-MEMORY.PAGE' | translate}} {{_visualMemory.page}} </b>
                        <div [innerHTML]="'STUDENT.VISUAL-MEMORY.STEP2-NOT-VALIDATED' | translate">
                        </div>
                    </ng-container>
                    <ng-container *ngIf="_visualMemory.step == 2 && (!checkAciertos() && _visualMemory.validated)">
                        <b> {{'STUDENT.VISUAL-MEMORY.PAGE' | translate}} {{_visualMemory.page}} </b>
                        <div [innerHTML]="'STUDENT.VISUAL-MEMORY.STEP2-NOT-APRROBED' | translate : {aciertos: aciertos}">
                        </div>
                    </ng-container>
                    <ng-container
                        *ngIf="_visualMemory.page != 5 && _visualMemory.step == 2 && (checkAciertos() && _visualMemory.validated)">
                        <b class="game-continue-h1">{{'STUDENT.VISUAL-MEMORY.STEP2-TITLE' | translate}}</b>
                        <div class="game-continue-p" [innerHTML]="'STUDENT.VISUAL-MEMORY.STEP2-APRROBED' | translate : {walinwos: 5 * (_visualMemory.page + 1)}">
                        </div>
                    </ng-container>
                    <ng-container
                        *ngIf="_visualMemory.page == 5 && _visualMemory.step == 2 && (checkAciertos() && _visualMemory.validated)">
                        <b class="game-continue-h1">{{'STUDENT.VISUAL-MEMORY.STEP2-PERFECT-TITLE' | translate}}</b>
                        <div class="game-continue-p">{{'STUDENT.VISUAL-MEMORY.STEP2-PERFECT-TEXT' | translate}}</div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div fxFlex="" fxFlex.xs="" class="center spe_gap" fxLayout="column" fxLayoutAlign="stretch" id="box_scroll_auto">
            <div fxFlex="" class="content-box-games" id="box-games">
                <div [hidden]="!spark" id="spark-gif" class="spark" 
                [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/' + directory + 'spark.gif&quot;)'"></div>
                <div [attr.id]="'box_' + i" class="card-content spe_gap" *ngFor="let item of dataMemo; let i = index"
                    fxLayout="column" fxLayoutAlign="space-between">
                    <div fxFlex="" (click)="selectedCard(i)" class="father-img">
                        <img [src]="imageUrl(item)" />
                    </div>
                    <!-- la DIV tiene que ser un for del eleemento memo que contiene las palabras-->
                    <div (click)="setFocus(true)" [tabindex]="i" fxLayout="row" fxLayoutAlign="center center"
                        class="memo-text" #memoText id="memo-text"
                        *ngIf="!this.timerEnd && this._visualMemory.step > 1 && !_visualMemory.validated else nameMemo">
                        <!-- cada span tiene que ser un for de los caracteres de esa palabra.... y si el caracter titila le ponemoes una clase
                        si el caracter tiene la letraEscritar, mostramos la letra escrita, si el caracter es un espacio le ponemos otra clase-->
                        <div *ngFor="let espacio of [].constructor(getPalabras(item)); let j = index">
                            <ng-container *ngFor="let caracter of getCaracteres(j, item); index as indexOfelement;">
                                <span *ngIf="isShowable(caracter.caracter)"
                                    (click)="deleteCaracter(i,getPosition(caracter, item.caracteres))"
                                    [attr.id]="'letter_' + getPosition(caracter, item.caracteres) + i" [ngClass]="{
                                    'titila currentChar': caracter.titila, 
                                    espacio: caracter.caracter == ' ', 
                                    'memo-text-char letra borde': caracter.caracter != ' '
                                }" type="text" [innerHtml]="show(caracter.caracter)">
                                </span>
                            </ng-container>
                        </div>
                    </div>
                    <ng-template #nameMemo>
                        <div *ngIf="_visualMemory.validated && item.sonIguales == false" class="noSonIguales">
                            <span
                                style="text-decoration: line-through; text-align: center;">{{getTextoIngresado(i)}}</span>
                        </div>
                        <div [class]="{'name-memo': !_visualMemory.validated, 
                        'verificar-respuesta': _visualMemory.validated && item.sonIguales,
                        'noSonIguales-text' : _visualMemory.validated && item.sonIguales == false,
                        'no-escrito': _visualMemory.validated && item.sonIguales == null 
                        }">
                            <span>{{item.name}}</span>
                        </div>
                    </ng-template>
                </div>
            </div>
            <div class="footer spe_gap" fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
                <button [disabled]="loading" class="new_but azul"
                    *ngIf="_visualMemory.step > 1 && !_visualMemory.validated" (click)="validate()">{{'STUDENT.VISUAL-MEMORY.VALIDATE' | translate}}</button>
                <button [disabled]="loading" *ngIf="_visualMemory.step == 1" (click)="start()" class="new_but azul">{{'START' | translate}}</button>
                <button [disabled]="loading"
                    *ngIf="_visualMemory.step > 1 && _visualMemory.validated && _visualMemory.page <= 5"
                    (click)="finish()" class="new_but azul">{{'FINISH' | translate}}</button>
                <button [disabled]="loading"
                    *ngIf="_visualMemory.step > 1 && (checkAciertos() && _visualMemory.validated) && _visualMemory.page <= 4"
                    (click)="continue()" class="new_but azul">{{'STUDENT.VISUAL-MEMORY.CONTINUE' | translate}}</button>
            </div>
        </div>
    </div>
    <form style="position: fixed; top: -99999em; left: -99999em;" autocomplete="off">
        <input (blur)="setFocus(false)" [(ngModel)]="input" (ngModelChange)="change()" autocorrect="off"
            autocapitalize="off" spellcheck="false" [ngModelOptions]="{standalone: true}" autocomplete="off" type="text"
            id="dictation-input" />
    </form>
</div>

<div *ngIf="review" class="exercise-container not-selectable review" 
    [ngStyle]="{ 'background-image': 'url(&quot;https://walinwa.blob.core.windows.net/images/MemoImage/fondo_review_mob.svg?ngsw-bypass=true&quot;)' }" 
    [ngStyle.sm]="{ 'background-image': 'url(&quot;https://walinwa.blob.core.windows.net/images/MemoImage/nubes-rosa.svg?ngsw-bypass=true&quot;), url(&quot;https://walinwa.blob.core.windows.net/images/MemoImage/nubes-rosa-fondo.svg?ngsw-bypass=true&quot;)' }"
    [ngStyle.xs]="{ 'background-image': 'url(&quot;https://walinwa.blob.core.windows.net/images/MemoImage/nubes-rosa.svg?ngsw-bypass=true&quot;), url(&quot;https://walinwa.blob.core.windows.net/images/MemoImage/nubes-rosa-fondo.svg?ngsw-bypass=true&quot;)' }"
    fxFlex="" fxLayout="column"
    fxLayoutAlign="stretch">
    <div class="" fxLayout="row" fxLayoutAlign="end end">
        <img (click)="close()" src="https://walinwa.blob.core.windows.net/images/ico-close-popup.svg?ngsw-bypass=true" class="walinwa-box-modal-header-button"
            fxFlex="2.5em" class="pointer closewindowreview">
    </div>
    <div fxFlex="" class="exercise-contain spe_gap" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-between" fxLayoutAlign="stretch">
        <div fxFlex="12.8rem" fxFlex.lt-md="100%" fxFlex.xs="0 0 auto" class="left">
            <div class="bar_review" fxShow fxHide.lt-md>
                <div class="bar_notification" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/' + directory + 'reloj_review.svg&quot;)'">
                    <div class="text">
                        <h2 style="color:white; font-weight: bold; font-size: 1.1em;" [innerHTML]="'STUDENT.VISUAL-MEMORY.TITLE' | translate">
                            Memoria <br>
                            visual
                        </h2>
                    </div>
                    <div class="timer-text">
                        <span style="color:white;">
                            {{'STUDENT.VISUAL-MEMORY.ACTIVITY-DONE' | translate}}
                        </span>
                    </div>
                    <div class="walinwos">
                        <div *ngIf="_visualMemory.walinwos > 0">Has ganado</div>
                        <span>{{_visualMemory.walinwos}}</span>
                        <div >walinwos</div>
                    </div>
                </div>
            </div>
            <div fxShow.lt-md fxHide fxLayout="column" fxLayoutAlign="center center" class="ww_new_panel spe_gap">
                <div class="ww_walinwos">
                    <div *ngIf="_visualMemory.walinwos > 0">{{'STUDENT.VISUAL-MEMORY.YOU-WON' | translate}}</div>
                    <span>{{_visualMemory.walinwos}}</span>
                    <div >walinwos</div>
                </div>
                <div class="ww_intronote">
                    {{'STUDENT.VISUAL-MEMORY.ACTIVITY-DONE' | translate}}
                </div>
            </div>
        </div>
        <div fxFlex="" fxFlex.xs="" class="center spe_gap" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutAlign.lt-md="start stretch">
            <div class="images-container-review">
                <div class="card-content spe_gap" *ngFor="let item of reviewDataMemo; let i = index" fxLayout="column" fxLayoutAlign="space-between">
                    <div class="father-img">
                        <img [src]="imageUrl(item)" />
                    </div>
                    <div [class]="{
                        'verificar-respuesta': item.Pass,
                        'noSonIguales-text' : item.Pass == false,
                        'no-escrito': item.Pass == null,
                        'large-text': item.MemoImage.Name.length >= 27
                        }">
                        <span>{{item.MemoImage.Name}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>