import { Component, Input, OnInit, OnDestroy, HostListener, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'app/core/guards/auth.service';
import { SharedService } from 'app/core/shared/shared.service';
import { ExerciseService } from 'app/services/exercise.service';
declare var $

@Component({
  selector: 'ex-boxes4c',
  templateUrl: './boxes4c.component.html',
  styleUrls: ['./boxes4c.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Boxes4cComponent implements OnInit, OnDestroy {

  @Input() review
  @Input() show_errors;
  @Input() controlSession : boolean = false;
  @Input() slide : boolean = false; @Input() current;
  exercise;
  draggingElement
  ok = []
  error = []
  finished = false
  numero_random = Math.floor(Math.random() * (10000 - 0 + 1) + 0)
  elementsOk
  elementsErr

    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case "SHOW_SOLUTION":
                this.finished = true;
                if (this.exerciseService.showingSolution) {
                    setTimeout(this.colocar_en_su_caja.bind(this), 100);
                    break;
                }
                else {
                    setTimeout(this.ShowSolution.bind(this), 100);
                    break;
                }
            case "SHOW_ANSWER":
            case "SHOW_CUSTOM":
                setTimeout(this.ShowAnswer.bind(this), 100)
                break;
        }
    }

  constructor(
    private exerciseService: ExerciseService, private sharedService: SharedService
  ) {
    this.exercise = this.exerciseService.getExerciseDefinition();
  }
  ngOnDestroy() {
  }

  ngOnInit() {
      if (this.review) {
        this.finished = true;
      this.elementsOk = JSON.parse(this.exerciseService.getCurrentExercise().ElementsOK)
      this.elementsErr = JSON.parse(this.exerciseService.getCurrentExercise().ElementsERR)
      if (this.show_errors == false) {
        this.ShowSolution();
      } else if (this.show_errors == true) {
        this.ShowAnswer();
      } else {
        this.ShowAnswer();
      }
    } else {
      this.initInteract()
      setTimeout(() => {
        this.cargar_opciones()
      }, 100)
    }
  }

  initInteract() {
    this.exerciseService.initInteract({
      onmove: (event) => {
        this.draggingElement = event.target
      },
      drop: (event) => {
        var index_palabra = parseFloat(this.draggingElement.getAttribute("id").substr(7));
        var index_caja = parseFloat(event.target.getAttribute("id").substr(3));

        var palabra = this.exercise.palabras[index_palabra];
        var caja = this.exercise.boxes[index_caja];
        if (palabra.opcion == caja.valor) {
          palabra.answered = true;
          if (this.ok.indexOf(index_palabra) < 0) {
            this.ok.push(index_palabra);
            this.exerciseService.answer(index_palabra, true, this.exercise.palabras.length);
          }
        } else {
          this.error.push(index_palabra);
          palabra.answered = false;
          this.exerciseService.answer(index_palabra, false, this.exercise.palabras.length);
        }

        if (this.ok.length == this.exercise.palabras.length) {
          this.FinishExercise();
        }
      }
    })
  }

  FinishExercise() {
    this.finished = true
    this.colocar_en_su_caja()
  }

  colocar_en_su_caja() {
    for (var i = 0; i < this.error.length; i++) {
        var index_palabra = this.error[i];
        this.exercise.palabras[index_palabra].answered = false;
    }
}

  caja_correcta(palabra) {
    for (var i = 0; i < this.exercise.boxes.length; i++) {
      if (palabra.opcion == this.exercise.boxes[i].valor) {
        return i;
      }
    }
    return null;
  }

  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  cargar_opciones() {
    for (var i = 0; i < this.exercise.palabras.length; i++) {
        this.exercise.palabras[i].answered = null;
        $("#palabra" + i + this.slide + "boxes4c" + this.numero_random).css("bottom", this.getRandomInt(20, this.controlSession ? 60 : 70) + "%");
        $("#palabra" + i + this.slide + "boxes4c" + this.numero_random).css("left", this.getRandomInt(0, 65) + "%");
    }
}

  ShowSolution() {
    for (var j = 0; j < this.exercise.palabras.length; j++) {
      this.exercise.palabras[j].answered = true;
    }
  };

  ShowAnswer() {
    for (var i = 0; i < this.exercise.palabras.length; i++) {
      this.exercise.palabras[i].answered = null;
    }
    for (var j = 0; j < this.elementsErr.length; j++) {
      this.exercise.palabras[this.elementsErr[j]].answered = false;
    }
      for (var j = 0; j < this.elementsOk.length; j++) {
        if (this.exercise.palabras[this.elementsOk[j]].answered != false) {
          this.exercise.palabras[this.elementsOk[j]].answered = true;
        }
      }
  }
  /*getImgUrl() {
    return this.sharedService_.getImgBackgroundUrl("Exercises/Boxesc/front.png")
}*/

}
