@if (loadedAuth$ | async) {
<div class="walinwa-box walinwa-box-green" fxNgClass.gt-sm="scroll_vertical">
    <div class="walinwa-box-header">
        {{'SCHOOL.ADMIN.IMPORT-CLASSROOM.CLIENT.TITLE' | translate}}
    </div>
    <div class="walinwa-box-content" fxLayout="column" fxLayoutAlign="stretch">
        <div>
            {{'SCHOOL.ADMIN.IMPORT-CLASSROOM.CLIENT.TEXT' | translate}}
        </div>
        <form [formGroup]="formConnect">
            <div fxLayout="row" fxFlex.lt-md="column" style="width: 100% !important;">
                <div fxFlex="100%" fxFlex.lt-md="auto">                    
                    <mat-form-field>
                        <label class="font_blue" style="display: block;">{{'SCHOOL.ADMIN.IMPORT-CLASSROOM.CLIENT.CLIENT-ID' | translate}}</label>
                        <input matInput type="text" maxlength="300" class="form-control" formControlName="clientId">
                        <mat-error *ngIf="formConnect.get('clientId').touched && formConnect.get('clientId').hasError('required')">
                            {{'FIELD-REQUIRED' | translate}}
                        </mat-error>
                        <mat-error *ngIf="formConnect.get('clientId').touched && formConnect.get('clientId').hasError('maxlength')">
                            {{'SCHOOL.ADMIN.IMPORT-CLASSROOM.CLIENT.CLIENT-ID-MAX' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <!--div fxLayout="row" fxFlex.lt-md="column" style="width: 100% !important;">
                <div fxFlex="100%" fxFlex.lt-md="auto">                    
                    <mat-form-field>
                        <label class="font_blue" style="display: block;">Secreto del cliente</label>
                        <input matInput type="text" maxlength="300" class="form-control" formControlName="clientSecret">
                        <mat-error *ngIf="formConnect.get('clientSecret').touched && formConnect.get('clientSecret').hasError('required')">
                            El Secreto del cliente es obligatorio
                        </mat-error>
                        <mat-error *ngIf="formConnect.get('clientSecret').touched && formConnect.get('clientSecret').hasError('maxlength')">
                            El Secreto del cliente no puede superar los 300 carácteres
                        </mat-error>
                    </mat-form-field>
                </div>
            </div-->
            <div fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center" class="spe_gap">
                <button (click)="connectGoogleClassroom()" class="new_but azul" [disabled]="disabled">
                    {{'SCHOOL.ADMIN.IMPORT-CLASSROOM.CLIENT.CONNECT' | translate}}
                </button>
            </div>
        </form> 
    </div>
</div>
}