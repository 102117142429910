import { AppService } from 'app/app.service';
import { Component, OnInit, Input, OnDestroy, HostListener, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { ExerciseSolvedEnum } from 'app/core/shared/enums/exercise-solved.enum';
import { ExerciseService } from 'app/services/exercise.service';
declare var $

@Component({
  selector: 'ex-colocaf',
  templateUrl: './colocaf.component.html',
  styleUrls: ['./colocaf.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ColocafComponent implements OnInit, OnDestroy {

  @Input() review
  @Input() show_errors;
  @Input() controlSession : boolean = false;
  @Input() slide : boolean = false; @Input() current;
  exercise;
  input: any;
  answer = []
  letters = [{ name: 'a' }, { name: 'b' }, { name: 'c' }, { name: 'd' },
  { name: 'e' }, { name: 'f' }, { name: 'g' }, { name: 'h' }, { name: 'i' },
  { name: 'j' }, { name: 'k' }, { name: 'l' }, { name: 'm' }, { name: 'n' },
  { name: 'ñ' }, { name: 'o' }, { name: 'p' }, { name: 'q' }, { name: 'r' },
  { name: 's' }, { name: 't' }, { name: 'u' }, { name: 'v' }, { name: 'w' },
  { name: 'x' }, { name: 'y' }, { name: 'z' }, { name: 'á' }, { name: 'é' },
  { name: 'í' }, { name: 'ó' }, { name: 'ú' }, { name: 'ü' }]
  answered = null
  error = false
  finished = false
  timeout = false

  elementsOk
  elementsErr
  isMobile : boolean;
  inputLength = 0;
  regex = new RegExp(/Tab|Alt|Arrow|Page/);
    focusing: any;

  @HostListener('document:keydown', ['$event'])
  keyListener(event: KeyboardEvent) {
      if (this.review || this.isMobile) return;
      if (this.regex.test(event.key)) event.preventDefault();
      let index = this.letters.findIndex(x => x.name == event.key);

      if (index != null && index != -1) {
        this.insert(this.letters[index])
      }
  }
    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_SOLUTION]:
                this.showSolution()
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_ANSWER]:
                this.ShowAnswer()
                break
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_CUSTOM]:
                this.exercise = this.exerciseService.getExerciseDefinition();
                this.FillExercise();
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.TIMER_END]:
                this.timeout = true
                break
        }
    }

  constructor(private exerciseService: ExerciseService, private appService: AppService) {
    this.exercise = this.exerciseService.getExerciseDefinition();
  }

  public get exercisesSolvedEnum(): typeof ExerciseSolvedEnum{
    return this.exerciseSolvedEnum
  }

  public get exerciseSolvedEnum(): typeof ExerciseSolvedEnum{
    return ExerciseSolvedEnum
  }

    ngOnInit() {
    this.isMobile = this.appService.mobileDetect().IsIPhone || this.appService.mobileDetect().IsAndroidPhone || window.innerWidth < 600;
    this.FillExercise();
    if (this.review) {
      this.elementsOk = JSON.parse(this.exerciseService.getCurrentExercise().ElementsOK)
      this.elementsErr = JSON.parse(this.exerciseService.getCurrentExercise().ElementsERR)
      if (this.show_errors == false) {
        this.showSolution();
      } else if (this.show_errors == true) {
        this.ShowAnswer();
      }
    } 

    
  }

  ngOnDestroy() {
    document.querySelector('body')['removeListeners']();
  }

    change() {
        if (this.review) return;
        if (this.inputLength > this.input.length) {
            return;
        }
        var letter = this.input.charAt(this.input.length - 1)
        let index = this.letters.findIndex(x => x.name == letter);
        if (index != null && index != -1) {
            this.insert(this.letters[index])
        }
        this.inputLength = this.input.length
    }
    
    Validate() {
        let result = '';
        let expectedResult = '';
        for (var i = 0; i < this.answer.length; i++) {
            result += this.answer[i].name;
            expectedResult += this.exercise.palabra[i];
        }
        if (result.length < this.exercise.palabra.length) {
            if (result != expectedResult) {
                this.error = true;
                this.answered = false;
            }
            return;
        }
        if (result == this.exercise.palabra) {
            if (this.answered == false) {
                this.exerciseService.answer(1, false, 1);
            }
            this.exerciseService.answer(1, true, 1);
            this.setFocus(false);
            this.answered = true;
        } else {
            this.error = true;
            this.answered = false;
            this.exerciseService.answer(1, false, 1);
        }

    }


  insert(letter) {
    if (this.error) this.answer.pop();
    this.error = false;
    this.answer.push(letter);
    this.Validate();
  }

  FillExercise() {
    this.answered = null;
    this.answer = [];
    var guion = "";
    for (var i = 0; i < this.exercise.palabra.length; i++) {
      guion += '_ ';
    }
    var searchMask = this.exercise.palabra;
    var regEx = new RegExp(searchMask, "ig");
    this.exercise.frase2 = this.exercise.frase.replace(regEx, guion);
  }

  showSolution() {
    this.answer = [];
    for (var i = 0; i < this.exercise.palabra.length; i++) {
      this.answer.push({ name: this.exercise.palabra[i] });
    }
    this.finished = true;
    this.answered = true;
    this.error = false;
  }

    ShowAnswer() {
        this.setFocus(false);
        this.answer = [];
        for (var i = 0; i < this.exercise.palabra.length; i++) {
            this.answer.push({ name: this.exercise.palabra[i] });
          }
        if (this.elementsOk.length > 0 && this.elementsErr == 0) {
            this.error = false;
            this.answered = true;
        } else {
            this.error = true;
            this.answered = false;
        }
    }

    setFocus(bool = true) {
        if (!this.isMobile || this.review || this.answered) {
            return;
        }
        if (bool && !this.focusing) {
            document.getElementById("dictation-input").focus();
        }
        if (!bool && this.focusing) {
            document.getElementById("dictation-input").blur();
        }
        this.focusing = bool
    }
  
}
