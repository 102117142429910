import { environment } from 'environments/environment';
import { Component, OnInit,  OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { EmailConfigService } from 'app/services/email-config.service';
import { NavigationService } from 'app/services/navigation.service';
import { SchoolService } from 'app/services/school.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: "app-activities",
    templateUrl: "./activities.component.html",
    styleUrls: ["./activities.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ActivitiesComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
    currentUser: any;
    dataLoaded = false;
    states = [];
    enableReading: boolean;
    enableHearing: boolean;
    enableDeduction: boolean;
    enableDictation: boolean;
    state: string;

    constructor(
        private router: Router,
        private authService: AuthService,
        private emailConfigService: EmailConfigService,
        private navigationService: NavigationService,
        private schoolService: SchoolService
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit(): void {
        if (!this.authService.check()) return;
        this.currentUser = this.authService.currentUser;
        this.enableReading = this.schoolService.school.ReadingText;
        this.enableDeduction = this.schoolService.school.DeductionText;
        this.enableDictation = this.schoolService.school.Dictation;
        this.enableHearing = this.schoolService.school.HearingText;
        this.navigationService.enableExitButton();
        this.dataLoaded = true;
        let params = this.router.url.split("/");
        let last = params[params.length - 1];
        switch (last) {
            case "dictations":
                this.editDicts();
                break;
            case "reading":
                this.reading();
                break;
            default:
                if (this.enableDictation) {
                    this.editDicts();
                } else if (this.enableReading) {
                    this.reading();
                } else if(this.enableDeduction) {
                    this.deduction();
                } else {
                    this.hearing();
                }
                break;
        }
    }

    back() {
        if (this.states.length > 1) {
            var last = this.states[this.states.length - 2];
            this.states.splice(this.states.length - 1, 1);
            this[last]();
            this.states.splice(this.states.length - 1, 1);
        } else {
            this.router.navigateByUrl("school/students");
        }
    }
    editDicts() {
        if (!this.currentUser.IdSchool || !this.enableDictation) return;
        this.state = "dictations";
        this.states.push("editDicts");
        if (this.router.url == "/school/activities") {
            this.router.navigateByUrl(this.router.url + "/dictations");
        } else {
            this.router.navigateByUrl("/school/activities/dictations");
        }
    }

    reading() {
        if (!this.currentUser.IdSchool || !this.enableReading) return;
        this.state = "reading";
        this.states.push("reading");
        if (this.router.url == "/school/activities") {
            this.router.navigateByUrl(this.router.url + "/reading");
        } else {
            this.router.navigateByUrl("/school/activities/reading");
        }
    }
    deduction() {
        if (!this.currentUser.IdSchool || !this.enableDeduction) return;
        this.state = "deduction";
        this.states.push("deduction");
        if (this.router.url == "/school/activities") {
            this.router.navigateByUrl(this.router.url + "/deduction");
        } else {
            this.router.navigateByUrl("/school/activities/deduction");
        }
    }

    hearing() {
        if (!this.currentUser.IdSchool || !this.enableHearing) return;
        this.state = "hearing";
        this.states.push("hearing");
        if (this.router.url == "/school/activities") {
            this.router.navigateByUrl(this.router.url + "/hearing");
        } else {
            this.router.navigateByUrl("/school/activities/hearing");
        }
    }

    errorCallback() {
        this.navigationService.exit();
    }
}
