<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="verbos1 spe_gap" (click)="setFocus(focusing ? false : true)" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" fxLayout="column"
fxLayoutAlign="stretch">

    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div class="wordsContainer">
        <div class="items_container">
            <!--  -->
            <div class="verbContainer" [ngClass]="{'verb-container-slide': slide}">
                <div class="verb">Verbo: {{exercise.verbo}}</div>
                <div class="conjContainer" [ngClass]="{'conjContainer-controlSession': controlSession}">
                    <div class="conj"><span class="subVerb">Conjugación: </span><span>{{exercise.tiempoverbal}}</span></div>
                    <div class="pers"><span class="subVerb">Persona: </span><span>{{exercise.persona}}</span></div>
                </div>
            </div>
        
            <!--PALABRA-->
            <div class="palabra" [ngClass]="{'palabra-slide': slide}" >
                <div [ngClass]="{'bien': letra.answered == true,'mal': letra.answered == false}" class="letra" [attr.letra]="i" *ngFor="let letra of letras; let i = index">
                    <span *ngIf="letra.answered || show_errors == true">{{ letra.letra }}</span>
                </div>
            </div>
        
            <!--TECLADO-->
            <div class="letters" *ngIf="!showingSolution && !controlSession" [hidden]="this.isMobile">
                <ul class="list-inline">
                    <li *ngFor="let letter of letters; let i = index" class="letterContainer">
                        <div (click)="add_letra(letter.name)" [attr.letra]="letter.name" class="letter-button letter">
                            {{ letter.name }}
                        </div>
                    </li>
                </ul>
            </div>
            <!--  -->
        </div>
    </div>

</div>
<form style="position: fixed; top: -99999em; left: -99999em;" autocomplete="off" *ngIf="this.isMobile && !this.review">
    <input [(ngModel)]="input" autocorrect="off" autocapitalize="off" spellcheck="false" (ngModelChange)="change()"
        [ngModelOptions]="{standalone: true}" autocomplete="off" type="text" id="dictation-input" autofocus  />
</form>
<!---->
<!--
<div class="verbos1" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" >
    <h2 *ngIf="controlSession" class="doc-number">{{current + 1}}</h2>

    --TEMA --
    <div *ngIf="!controlSession" class="exerciseTheme">
        <h1 [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></h1>
    </div>
    --FIN TEMA --

    --ENUNCIADO --
    <div class="text-center exerciseTitle" [ngClass]="{'exerciseTitle-slide': slide}">
        <p   [innerHtml]="exercise.enunciado"></p>
    </div>
    --ENUNCIADO --

    <div style="margin-top: 1em;" class="verbContainer" [ngClass]="{'verb-container-slide': slide}">
        <p class="verb" [ngStyle]="!controlSession ? {'font-size': '3vh;'} : {'font-size': '2.8vh;'}">Verbo: {{exercise.verbo}}</p>
        <div class="conjContainer" [ngClass]="{'conjContainer-controlSession': controlSession}">
            <p class="conj"><span class="subVerb">Conjugación: </span><span>{{exercise.tiempoverbal}}</span></p>
            <p class="pers"><span class="subVerb">Persona: </span><span>{{exercise.persona}}</span></p>
        </div>
    </div>


    --PALABRA--
    <div class="palabra" [ngClass]="{'palabra-slide': slide}">
        <div [ngClass]="{'bien': letra.answered == true,'mal': letra.answered == false}" class="letra" [attr.letra]="i" *ngFor="let letra of letras; let i = index">
            <span [hidden]="letra.answered != true">{{ letra.letra }}</span>
        </div>
    </div>
    --FIN PALABRA--

    --TECLADO--
    <div [ngStyle]="controlSession ? {margin: '1% auto 3em;'} : {}" class="letters" *ngIf="!showingSolution && !controlSession">
        <ul class="list-inline" [ngStyle]="controlSession ? {width: '75%;'} : {}">
            <li *ngFor="let letter of letters; let i = index" class="letterContainer" [ngStyle]="controlSession ? {width: '2em;', height: '2em;'} : {}">
                <div (click)="add_letra(letter.name)" [attr.letra]="letter.name" class="letter-button letter">
                    {{ letter.name }}
                </div>
            </li>

        </ul>
    </div>
    --FIN TECLADO--

</div>
-->