<div class="walinwa-box-with-tabs" fxNgClass.gt-sm="scroll_vertical">
    <div class="walinwa-tabs walinwa-tabs-top">
        <div [ngClass]="{ 'selected': state == 'session'}" (click)="goSesion()" class="walinwa-tab-gray">
            {{'SCHOOL.REVIEW-ACTIVITIES.SESSION-TITLE' | translate}}
        </div>
        <div [ngClass]="{ 'selected': state == 'dictations', 'disabled-sidebar-button': !enableDictation}" (click)="goDicts()" class="walinwa-tab-gray">
            {{'SCHOOL.REVIEW-ACTIVITIES.DICTATION-TITLE' | translate}}
        </div>
        <div [ngClass]="{ 'selected': state == 'reading', 'disabled-sidebar-button': !enableReadingText}" (click)="goReading()" class="walinwa-tab-gray">
            {{'SCHOOL.REVIEW-ACTIVITIES.READING-TITLE' | translate}}
        </div>
        <div [ngClass]="{ 'selected': state == 'deduction', 'disabled-sidebar-button': !enableDeductionText}" (click)="goDeduction()" class="walinwa-tab-gray">
            {{'SCHOOL.REVIEW-ACTIVITIES.DEDUCTION-TITLE' | translate}}
        </div>
        <div [ngClass]="{ 'selected': state == 'hearing', 'disabled-sidebar-button': !enableHearingTexts}"  (click)="goHearing()" class="walinwa-tab-gray">
            {{'SCHOOL.REVIEW-ACTIVITIES.HEARING-TITLE' | translate}}
        </div>
    </div>
    <div class="walinwa-box-green">
        <router-outlet></router-outlet>
    </div>
</div>