import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CalHearingTextComponent } from 'app/components/cal-hearing-text/cal-hearing-text.component';
import { AuthService } from 'app/services/auth.service';
import { CourseService } from 'app/services/course.service';
import { HearingTextService } from 'app/services/hearing-text.service';
import { NavigationService } from 'app/services/navigation.service';
import { SchoolService } from 'app/services/school.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-hearing',
  templateUrl: './hearing.component.html',
  styleUrls: ['./hearing.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HearingComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
    @ViewChild(CalHearingTextComponent) child2: CalHearingTextComponent;
    hearingTexts;
    student;
    selectedDay = moment();
    currentMonth;
    courses = [];
    selectedCourse;
    hasExercise = false;
    six_weeks = false;
    licenseDate;
    afterLicense;



  constructor(
    private authService: AuthService,
    private courseService: CourseService,
    private hearingTextService: HearingTextService,
    private router: Router,
    private navigationService: NavigationService,
    private schoolService: SchoolService
  ) { 
    this._unsubscribeAll = new Subject();
}

ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

  ngOnInit() {
    if (!this.authService.check()) return;
    this.selectedDay = moment();
    this.currentMonth = this.selectedDay.month() + 1;
    this.licenseDate = this.schoolService.school.ValidUntil;
      this.courseService.Courses.pipe(takeUntil(this._unsubscribeAll)).subscribe(courses => {
          this.courses = courses;
          if (this.courses) {
              this.selectedCourse = this.courses[0].Level;
          }
      })
      this.getHearingTexts();
  }

  getHearingTexts() {
    this.hasExercise = false;
    this.hearingTextService.getHearingTextByLevel(this.selectedCourse, this.currentMonth, this.selectedDay.year())
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(
      result => {
        this.hearingTexts = result;
        this.hearingTextService.MonthlyHearing = result;
        this.child2.refresh((weeks_count) => {
          this.six_weeks = weeks_count >= 6
        })
        if (!this.hearingTexts) return;
        let currentHearingText = this.hearingTexts.find(r => moment(r.date).isSame(this.selectedDay));
        if (!currentHearingText) return;
        if (moment().month() < this.selectedDay.month() - 1) return
        if (moment().month() > this.selectedDay.month() + 1) return
        this.hasExercise = true;
      }
    )
  }

  selectedDayChangedCA(newDate) {
    this.selectedDay = newDate;
    this.afterLicense = this.selectedDay.isAfter(moment(this.licenseDate));
    if (this.currentMonth != this.selectedDay.month() + 1) {
      this.currentMonth = this.selectedDay.month() + 1;
      this.getHearingTexts();
    } else {
      this.hasExercise = false;
      if (!this.hearingTexts) return;
      let currentHearingText = this.hearingTexts.find(r => moment(r.date).isSame(this.selectedDay));
      if (!currentHearingText) return;
      if (moment().month() < this.selectedDay.month() - 1) return
      if (moment().month() > this.selectedDay.month() + 1) return
      this.hasExercise = true;
    }

    }

    showExercise() {
        if (!this.hearingTexts) return;
        let currentHearingText = this.hearingTexts.find((r) =>
            moment(r.date).isSame(this.selectedDay)
        );
        if (!currentHearingText) return;
        this.navigationService.go(
            this.router.url +"/review",
            {
                review: true,
                reviewing: true,
                date: currentHearingText.date,
                selectedDay: this.selectedDay,
                IdHearingText: currentHearingText.IdHearingText
            }
        );
    }
}