<span class="map-section" [style.cursor]="section.Approved || section.Current ? 'pointer': 'hand'" #mapSection (click)="showSectionData()">
    @if (section.Current) {
        <img src="https://walinwadevfr.blob.core.windows.net/images/WaliLee/marcapaginas.gif?ngsw-bypass=true" class="mark current">
        <img src="https://walinwadevfr.blob.core.windows.net/images/WaliLee/capitulo_desbloqueado.png?ngsw-bypass=true" class="mark">
    } @else {
        <img src="https://walinwadevfr.blob.core.windows.net/images/WaliLee/capitulo_bloqueado.png?ngsw-bypass=true" class="mark">
    }     
</span>

@if (section.Current) {
    <ng-template #mapSectionTemplate>
        @if (section.Current && section.showGame && !gamePlayed) {
            <iframe [src]="gameURL" [ngClass]="{ 'game': gameVisible, 'game-hidden ': !gameVisible }" [attr.params]="gameParams" [style.z-index]="gameVisible ? 999999 : -999999"></iframe>
        }

        @if (gamePlayed) {
            <div class="absoluteBackground" style="z-index: 999997;" [style.background-image]="gameBackgroundStyle">
                <div style="background-repeat: no-repeat; width: 12%; height: 100%;" [style.background-image]="walileeBackgroundStyle"></div>
                <div class="absoluteBackground" style="z-index: 999998;" [style.background-image]="confetiBackgroundStyle" ></div>
                <div style="position: absolute; top:0; right: 0; z-index:999999; width: 100%; padding: 10px 10px 0px 0px; width: 50px;">
                    <img class="pointer" (click)="goToMap()" src="https://walinwa.blob.core.windows.net/images/ico-close-avatar.svg?ngsw-bypass=true" style="filter: brightness(900%);">
                </div>
                <div class="congratulations">
                    <p style="background: transparent; font-size: 80px; font-weight: bold; margin-bottom: 0px !important;" [style.color]="textColor">{{ 'STUDENT.GAMES.NEXT-CIVILIZATION.1' | translate }}</p>
                    <p style="color:white; background: transparent; font-size: 24px; text-align: center;">{{ 'STUDENT.WALILEE.MAP-SECTION.SUCCESS-FINISH-GAME' | translate }}</p>
                    <div class="orange-circle" [style.background-color]="circleColor" [innerHtml]="gameReward"></div>
                </div>
            </div>  
        }

        @if (sectionDataVisible) {
            <walilee-test [book]="book" [section]="section" (onClose)="testClosed($event)"></walilee-test>
        }
    </ng-template>
}