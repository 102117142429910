import { FileRequest, FileResponse } from './../core/shared/state/models/file/file.model';
import { MemoImageUser } from './../core/shared/state/models/memo-image-user.model';
import { MemoImageExcer } from './../core/shared/state/models/memo-image-excer.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpParams, HttpEvent, HttpHeaderResponse, HttpResponse, HttpErrorResponse, HttpUserEvent, HttpProgressEvent } from '@angular/common/http';
import { Location } from '@angular/common';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { AuthService } from './auth.service';
import { FileService } from './file.service';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VisualMemoryService {

  baseUrl = environment.baseApi + '/api/VisualMemory';
  timer = {
    interval: null,
    startTime: null,
    currentSeconds: null,
    fullSeconds: null,
  };
  pageTimes = []
  page = null
  step = 1
  timerEnd = false
  walinwos = 0
  paused = false
    private s_has_finished = new Subject<boolean>();
    has_finished = this.s_has_finished.asObservable();
  validated = false
  imgUrls: FileResponse[] = [];
  subDirectory = "/images/MemoImage/"
  private s_memo_image_enabled = new BehaviorSubject<boolean>(false);
  memo_image_enabled = this.s_memo_image_enabled.asObservable();
  constructor(
    private http: HttpClient, 
    private authService : AuthService,
  ) {
      this.step = 1;
      this.timerEnd = false;
      this.walinwos = 0;
      this.paused = false;
      this.timer = {
        interval: null,
        startTime: null,
        currentSeconds: null,
        fullSeconds: null,
      };
        this.isActivated().subscribe(result => {
            this.changeMemoImageEnabled(result);
        })
  }
  changeMemoImageEnabled(memo_image_enabled: boolean){
    this.s_memo_image_enabled.next(memo_image_enabled);
    }

    changeHasFinished(hasFinished: boolean) {
        this.s_has_finished.next(hasFinished);
    }
  getImages(idUser: number, userLevel: number){
    return this.http.post(this.baseUrl + "/getImages/" + idUser, userLevel);
  }
  getReviewImages(idUser: number){
    return this.http.get(this.baseUrl + "/review/" + idUser);
  }
    isActivated(){
        return this.http.get<boolean>(this.baseUrl + "/isActivated/");
    }
    SaveExercises(body : MemoImageExcer[]){
        return this.http.post(this.baseUrl + "/Add/", body);
    }
    SavePage(body :MemoImageUser){
        return this.http.post(this.baseUrl + "/savePage/", body);
    }
    getTimer(userLevel: number) {
        return this.http.get(this.baseUrl + "/getTimerInfo/" + userLevel);
    }
    
    getmemoImageUser(idUser: number){
        return this.http.get(this.baseUrl + "/user/" + idUser);
    }

    public getData() {
        this.getmemoImageUser(this.authService.currentUser.Id).subscribe((result: MemoImageUser) => {
            this.page = result.Page;
            this.walinwos = result.Walinwos;
            if (this.page == 0) {
                this.changeHasFinished(true);
            }
            else {
                this.changeHasFinished(false);
            }
        })
    }

    pauseTimer() {
        this.paused = true;
    }

    restartTimer() {
        this.paused = false;
    }


  // Configuracion del Timer 

  public startTimer() {
    this.timer.fullSeconds = this.pageTimes.find(x => x.Step == this.step)['SecPag' + this.page]
    this.timer.startTime = new Date().getTime();
    this.timer.currentSeconds = 0;
    this.timerEnd = false;
    this.timer.interval = setInterval(() => {
        if (!this.paused) this.timer.currentSeconds = this.getSeconds();
      if (this.timer.fullSeconds == null) window.postMessage("EXERCISE_ENDED", "*");
      if (this.timer.fullSeconds != null && this.timeExpired()) {
        this.stopTimer();
      }
    }, 100);
  }

  public getSeconds() {
    let now = new Date().getTime();
    return (now - this.timer.startTime) / 1000;
  }

  public timeExpired() {
    return this.getSeconds() >= this.timer.fullSeconds;
  }

  public stopTimer() {
    this.timerEnd = true;
    clearInterval(this.timer.interval);
  }

  public getFullSeconds() {
    return this.timer.fullSeconds;
  }

  public getCurrentSeconds() {
    return this.timer.currentSeconds;
  }
  resetTimer() {
    this.timerEnd = false;
    this.timer = {
        interval: null,
        startTime: null,
        currentSeconds: null,
        fullSeconds: null,
    };
}
}
