<div class="walinwa-box">
    <div class="walinwa-box-content" id="editSessionContainer">
        <div>
            <div>{{'SCHOOL.TEACHER.ACTIVITIES.READING.TITLE' | translate }}</div>
            <div class="custom-form-field" style="margin-top: 20px">
                <mat-form-field>
                    <label>{{'SCHOOL.TEACHER.ACTIVITIES.READING.COURSE' | translate }}</label>
                    <mat-select [(value)]="selectedCourse" [(ngModel)]="selectedCourse"
                        (selectionChange)="getReadingTexts()">
                        <mat-option class="scrollable" *ngFor="let class of courses  | sortBy:'asc':'Level'"
                            [value]="class.Level">{{ class.Name }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- <div class="custom-form-field">
                <label>{{'SCHOOL.COURSE' | translate}}</label>
                <select [(ngModel)]="selectedCourse" (change)="getReadingTexts()">
                    <option *ngFor="let course of courses | sortBy:'asc':'Level'" [value]="course.Level">
                        {{ course.Name }}
                    </option>
                </select>
            </div> -->

            <cal-reading-text *ngIf="readingTexts" class="calendario dinamico walCalendar azul ww_customCalendar"
                [ngClass]="{'sixWeeks': six_weeks}" [showAll]="true" [readingTexts]="readingTexts" [parent]="this"
                [fullDay]="false" [currentUser]="student" [selectedDay]="selectedDay" [currentMonth]="currentMonth"
                [licenseDate]="licenseDate">
            </cal-reading-text>
        </div>

        <div class="walinwa-row spe_gap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center">

            <button [disabled]="!hasExercise || afterLicense" (click)="showExercise()" class="new_but azul">
                {{'SCHOOL.TEACHER.ACTIVITIES.READING.SHOW-EXERCISE' | translate }}
            </button>

        </div>

    </div>

</div>
<router-outlet></router-outlet>