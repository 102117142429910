import { cloneDeep } from 'lodash';
import { Injectable } from "@angular/core";
import { Class, Exerc, Reto } from "app/core/shared/state";
import { environment } from "environments/environment";
import { HttpClient } from '@angular/common/http';
import { RetoList } from 'app/core/shared/state/models/reto-list.model';

@Injectable()
export class RetoService {
    // no existe esta api, crear a futuro
    baseUrl = environment.baseApi + '/api/Reto';
    listClasses = []
    constructor(private http: HttpClient) {}

    getRetos(idUser: number) {
        return this.http.get<RetoList[]>(this.baseUrl + '/getRetos/' + idUser);
    }

    getReto(idReto: number) {
        return this.http.get<Reto>(this.baseUrl + '/getReto/' + idReto);
    }

    crearReto(alumnos : any, tema : string, idUser : number) {
        var offset = -(new Date().getTimezoneOffset() / 60);
        var params = { alumnos: alumnos, tema: tema, idUser: idUser, timeZone: offset };
        return this.http.post(this.baseUrl + '/crearReto/', params, {});
    };

    setRetoAsRead(idUser: number) {
        return this.http.post(this.baseUrl + '/setRetoAsRead/' + idUser, {});
    };

    rechazarReto(idUser : number, idReto : number) {
        var params = { IdUser: idUser, IdReto: idReto };
        return this.http.post(this.baseUrl + '/rechazarReto/', params);
    };

    updateInvitacion(invitacion, isAdmin) {
        var aux = {
            IdReto: invitacion.IdReto,
            IdGuest: invitacion.IdGuest,
            Status: invitacion.Status,
            IsAdmin: isAdmin || false,
        };
        return this.http.put(this.baseUrl + '/invitacion/' + invitacion.IdReto + '/' + invitacion.IdGuest, aux);
    };

    getRetoExercises(invitacion) {
        return this.http.get<Exerc[]>(this.baseUrl + '/getRetoExercises/' + invitacion.IdReto + '/' + invitacion.IdGuest);
    }
}