import { Component, OnInit, ViewEncapsulation, HostListener, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import * as moment from 'moment';

import { User, DataQuery, DataService, createUser } from 'app/core/shared/state';
import { UiService } from '@fuse/components/spinner/ui.service';
import { RepositoryService } from 'app/core/repository/repository.service';
import { SharedService } from 'app/core/shared/shared.service';
import { environment } from 'environments/environment';
import { AppService } from 'app/app.service';
import { ToolbarService } from 'app/services/toolbarService.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'family-password',
  templateUrl: './family-password.component.html',
  styleUrls: ['./family-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class FamilyPasswordComponent implements OnInit, AfterViewInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  user;
  students;
  mobile: boolean = false;
 iPad = /iPad|Macintosh/i.test(navigator.userAgent); mobileDetect;
  errorMessage: string;
  familyPasswordForm: UntypedFormGroup;

  hidePass = true;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _router: Router,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private renderer: Renderer2,
    private dataQuery: DataQuery,
    private _ui: UiService,
    private _repositoryService: RepositoryService,
    public _sharedService: SharedService,
    private _dataService: DataService,
    private appService: AppService,
    private toolbarService:ToolbarService,
    private activatedRoute: ActivatedRoute
  ) {
    this._unsubscribeAll = new Subject();
    this._ui.spin$.next(true);
    this.errorMessage = "";
    this.mobileDetect = this.appService.mobileDetect();
    this.familyPasswordForm = this._formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(10)]]
    });
      this.familyPasswordForm.markAllAsTouched();
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
}

  ngAfterViewInit(){
    const matToolbarRef = this.toolbarService.getToolbarRef();
    if (matToolbarRef) {
        setTimeout(() => {
            this.renderer.addClass(matToolbarRef.nativeElement, 'only-logo');
        }, 0);
    }
	}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerWidth;
    this.mobile = this._sharedService.isMobile();
  }

  ngOnInit(): void {
    this.mobile = this._sharedService.isMobile();
    this.user = this.dataQuery.user;
    if (this.user == null) {
      this._router.navigate(['./login']);
      return;
    }
    this.students = this.dataQuery.students;
    if (this.students == null) {
      this._router.navigate(['./login']);
      return;
    }
  }

  submit(form) {
    if (form.valid) {
      if (this.user && this.students) {
        let url = environment.baseApi + '/api/User/addTutor/';
        this._ui.showSpinner();
        this._dataService.savePassword(this.familyPasswordForm.value.password);
        var params = { user: this.user, students: this.students, password: this.familyPasswordForm.value.password };
        this._repositoryService.post(url, params)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe((response: any) => {
          this._ui.stopSpinner();
          this._router.navigate(['./wellcome']);
        },
          error => {
            this.errorMessage = this._sharedService.getError(error);
            this._ui.stopSpinner();
          });
      } else {
        this.errorMessage = "AUTHENTICATION.ERROR.UNKNOWN";
      }

    }
  }

}
