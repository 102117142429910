<section fxLayout="column" fxLayoutAlign="stretch" class="spe_gap" style="height: 100%;">
    <div>
        <div class="walinwa-box walinwa-box-yellow">

            <cal-dictations class="calendario dictado walCalendar ww_customCalendar_two"
                [ngClass]="{'sixWeeks': sixWeeks()}" [parent]="this" [fullDay]="isMobile == false ? true : false" [dictations]="dictations"
                [currentUser]="currentUser" [selectedDay]="selectedDay" [currentMonth]="currentMonth">
            </cal-dictations>

            <div class="d-flex justify-content-between align-items-center spe_gap ww_footi_cal">
                <div class="d-flex spe_gap" fxFlex="70%" fxHide fxShow.gt-xs>
                    
                    <tooltip id="stateSession" info='
                    <div class="walinwa-100">
                        {{RewardText()}} 
                    </div>
                    ' color="excl" position=""></tooltip>
                    <span class="texto_asterisco"> {{tooltipText}}
                    </span>
                </div>
                <tooltip fxShow.xs fxHide.gt-xs id="stateSession"
                    [info]=tooltipText
                    color="excl" position=""></tooltip>


                <div>
                    <button (click)="play()" [disabled]="!canDoIt || isFuture" *ngIf="!isDone" class="new_but azul">{{'STUDENT.DICTATION.START-BUTTON' | translate }}</button>
                    <button (click)="review()" [disabled]="!canDoIt || isFuture" *ngIf="isDone" class="new_but azul">{{'STUDENT.DICTATION.CHECK-BUTTON' | translate }}</button>
                </div>

                
            </div>

        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="stretch">
        <daily-text [color]="'var(--amarillo)'"></daily-text>
    </div>
</section>

<router-outlet></router-outlet>