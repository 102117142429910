<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="boxes2 spe_gap" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" fxLayout="column" fxLayoutAlign="stretch">

    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div class="wordsContainer">
        <div class="items_container model-columns spe_gap" fxLayout="row nowrap" fxLayoutAlign="space-between stretch">
            <div fxFlex="33.33%" class="left-box box" fxLayout="column">
                <div class="box-title" [ngClass]="{'large': exercise.boxes[0].descripcion.length > 1}">
                    {{ exercise.boxes[0].descripcion }}
                </div>
                <div fxFlex="" class="box-words dropzone" [attr.boxIndex]="0" [ngClass]="{'large': exercise.palabras.length>5}">
                    <div class="box-word"
                        [ngClass]="{'box-word-ok': word.answered == true, 'box-word-error': word.answered == false }"
                        *ngFor="let word of leftWords">
                        {{ word.palabra }}
                    </div>
                </div>
            </div>
            <div fxFlex="33.33%" class="center-box box" fxLayout="column">
                <div fxFlex="" class="box-words" [ngClass]="{'large': exercise.palabras.length>5}">
                    <div [attr.wordIndex]="i" [ngClass]="{
                            'box-word-error': word.answered == false,
                            invisible: word.answered == true || showingSolution()
                        }" class="draggable box-word" *ngFor="let word of exercise.palabras; let i = index">
                        <span>{{ word.palabra }}</span>
                    </div>
                </div>
            </div>
            <div fxFlex="33.33%" class="right-box box" fxLayout="column">
                <div class="box-title" [ngClass]="{'large': exercise.boxes[1].descripcion.length > 1}">
                    {{ exercise.boxes[1].descripcion }}
                </div>
                <div fxFlex="" class="box-words dropzone" [attr.boxIndex]="1" [ngClass]="{'large': exercise.palabras.length>5}">
                    <div class="box-word"
                        [ngClass]="{ 'box-word-ok': word.answered == true, 'box-word-error': word.answered == false }"
                        *ngFor="let word of rightWords">
                        {{ word.palabra }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  -->
<!-- <div class="model-container" [ngStyle]="{'pointer-events': review ? 'none;' : ''}">   
    <h2 *ngIf="controlSession" class="doc-number2">{{current + 1}}</h2>
    <div class="exerciseTitle1" [ngClass]="{'exerciseTitle-slide': slide}">
        <p [innerHtml]="exercise.enunciado"></p>
    </div>
    <div class="model-columns">
        <div class="left-box box">
            <div class="box-title" [ngStyle]="controlSession ? {'font-size' : '1.7em;'} : {}">
                {{ exercise.boxes[0].descripcion }}
            </div>
            <div class="box-words dropzone" [attr.boxIndex]="0">
                <div class="box-word"  [ngClass]="{'box-word-ok': word.answered == true, 'box-word-error': word.answered == false }" *ngFor="let word of leftWords">
                    {{ word.palabra }}
                </div>
            </div>
        </div>
        <div class="center-box box" [ngStyle]="controlSession && !slide && exercise.palabras.length > 6 ? {height: slide ? '28em;' : '28em;'} : {}">
            <div class="box-words" [ngStyle]="controlSession && !slide? {margin: '2em 0 0 0;'} : controlSession && !slide? {margin: '1em 0 0 0;'} : {}">
                <div [attr.wordIndex]="i" [ngClass]="{
                        'box-word-error': word.answered == false,
                        invisible: word.answered == true || showingSolution()
                    }" class="draggable box-word" [ngStyle]="controlSession && !slide ? {'font-size' : '1.3em;'} : {}"  *ngFor="let word of exercise.palabras; let i = index">
                    <span >{{ word.palabra }}</span>
                </div>
            </div>
        </div>
        <div class="right-box box">
            <div class="box-title" [ngStyle]="controlSession ? {'font-size' : '1.7em;'} : {}">
                {{ exercise.boxes[1].descripcion }}
            </div>
            <div class="box-words dropzone" [attr.boxIndex]="1">
                <div class="box-word" [ngClass]="{ 'box-word-ok': word.answered == true, 'box-word-error': word.answered == false }" *ngFor="let word of rightWords">
                    {{ word.palabra }}
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!controlSession" class="exerciseTheme">
        <h1 [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></h1>
    </div>
</div> -->