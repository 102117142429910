<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="boxes2c spe_gap" [ngStyle]="{'pointer-events': review ? 'none;' : '' }" fxLayout="column" fxLayoutAlign="stretch">
    
    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>
    
    <div fxFlex="" class="simulacion_canvas">
        <!--PALABRAS -->
        <div class="palabras" [ngClass]="{'fixControlSessions': controlSession}">
            <ng-container *ngFor="let palabra of exercise.palabras; let i = index">
                <div
                [id]="'palabra' + i + slide + 'boxes2c' + numero_random"
                class="palabra draggable"
                [ngClass]="{
                    'element-hidden':
                        palabra.answered == true && finished == false,
                        bien: palabra.answered == true,
                        mal: palabra.answered == false,
                        naranja:  palabra.answered == null && finished == true
                }"
                *ngIf="!finished"
            >
                <span>{{ palabra.palabra }}</span>
            </div>
            </ng-container>
            
        </div>
        <!--PALABRAS -->
        <!--CAJAS -->
        <div *ngFor="let caja of exercise.boxes; let i = index" class="caja-padre">
            <ng-container *ngFor="let palabra of exercise.palabras; let i = index">
                <div
                class="palabra-review"
                [ngClass]="{
                        bien: palabra.answered == true,
                        mal: palabra.answered == false,
                        naranja:  palabra.answered == null && finished == true
                }"
                *ngIf="finished && palabra.opcion == caja.valor"
            >
                <span>{{ palabra.palabra }}</span>
            </div>
            </ng-container>
            <div
            [id]="'box' + i + slide + 'boxes2c' + numero_random"
            class="caja dropzone" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Exercises/Boxesc/front.png?ngsw-bypass=true&quot;)'">
            <span class="descripcion">{{ caja.descripcion }}</span>
        </div>
    </div>
    </div>
</div>