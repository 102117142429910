<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="unir3 spe_gap" fxLayout="column" fxLayoutAlign="stretch" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" [ngClass]="{'fixControlSessions': controlSession}">

    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div class="optionsContainer" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center" [ngClass]="{'fixControlSessions': controlSession}">
            <div 
            [hidden]="showingSolution"
                *ngFor="let word of intialColumn; let i = index" 
                class="draggable dropzone wordBox" 
                [ngClass]="{
                    textSelected: word.selected,
                    wordBoxError: word.answered == false,
                    'element-hidden': word.answered
                }" id="col{{ i }}" [attr.index]="i" [attr.originalIndex]="word.originalIndex">
                <span>{{ word.palabra }}</span>
            </div>
            <div [hidden]="!showingSolution" *ngFor="let item of exercise.result; let i = index" class="wordBox wordBoxResult" id="result{{ i }}" [ngClass]="{
                    wordBoxOk: item.answered,
                    wordBoxError: item.answered == false
                }">
                <span>{{ item.word }}</span>
            </div>
    </div>
</div>