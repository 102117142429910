import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpParams, HttpEvent, HttpHeaderResponse, HttpResponse, HttpErrorResponse, HttpUserEvent, HttpProgressEvent } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Location } from '@angular/common';
import { AuthService } from 'app/core/guards/auth.service';
import { ExerciseService } from 'app/services/exercise.service';
import { TestExerciseService } from './test-exercise.service';


@Injectable()
export class TestService {

    baseUrl = environment.baseApi;
    currentUser;
    currentTest;
    currentExerciseNumber = null;
    constructor(
        private http: HttpClient, 
        private _location: Location, 
        private _authService: AuthService,
        private exerciseService: TestExerciseService
        ) {
    }

    public get(route: string) {
        return this.http.get(route);
    }

    public getAuth(route: string) {
        let header = this._authService.authJsonHeaders();
        return this.http.get(route, { headers: header });
    }

    public post(route: string, body) {
        return this.http.post(route, body);
    }

    public put(route: string, body) {
        return this.http.put(route, body);
    }

    public delete(route: string) {
        return this.http.delete(this.createCompleteRoute(route, this._location.normalize(this.baseUrl)));
    }

    private createCompleteRoute(route: string, envAddress: string) {
        return `${envAddress}/${route}`;
    }

    // 

    sortExercises() {
        this.currentTest.Exercises.sort(function (a, b) { return a.TestOrder - b.TestOrder });
    }

    getNumExercises() {
        return this.currentTest.Exercises.length;
    }

    resetExercise() {
        this.exerciseService.reset();
    }

    isTheEnd() {
        return this.currentExerciseNumber == this.currentTest.Exercises.length + 1;
    }

    goToNextExercise() {
        if(!this.currentExerciseNumber) {
            this.currentExerciseNumber = 1;
            
        } else {
            this.currentExerciseNumber++;
        }
        this.exerciseService.reset();
        if(this.isTheEnd()) {
            this.currentTest.Status = "FINISHED";
            return false;
        }
        this.exerciseService.setCurrentExercise(this.currentTest.Exercises[this.currentExerciseNumber-1]);
        return true;
    }

    // from exerciseService

    getExerciseModel() {
        return this.exerciseService.getExerciseModel();
    }

    getFullSeconds() {
        return this.exerciseService.getFullSeconds();
    }

    getCurrentSeconds() {
        return this.exerciseService.getCurrentSeconds();
    }

    getCurrentExerciseNumber() {
        return this.currentExerciseNumber;
    }

    getExerciseDefinition() {
        return this.exerciseService.getExerciseDefinition();
    }

    showSolution() {
        return this.exerciseService.showSolution();
    }

    isShowingSolution() {
        return this.exerciseService.isShowingSolution();
    }

    updateTest() {
        return this.http.post(this.baseUrl + "/api/SessionTest/" + this.currentTest.IdTest, this.currentTest);
    }

    deleteTest() {
        return this.http.delete(this.baseUrl + "/api/SessionTest/" + this.currentTest.IdTest);
    }

    getByUser(idUser) {
        return this.http.get(this.baseUrl + 'api/UserTest/idUser/' + idUser);
    };

}
