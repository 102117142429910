<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'exerciseTheme2': timerEnabled == false}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>
<div class="rapid spe_gap" [ngStyle]="{'pointer-events': review ? 'auto;' : ''}" [ngClass]="{'fixControlSessions': controlSession}" fxLayout="column"
    fxLayoutAlign="stretch">
    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left" [ngStyle]="{'position': (controlSession || slide) ? 'relative;':'','padding-right':(controlSession || slide) ? '3.8rem;':''}">
            <span [innerHtml]="exercise.enunciado"></span>
            <div *ngIf="controlSession || slide" class="boxTime">
                <div class="content-time">00:00</div>
            </div>
        </div>
    </div>

    <div class="wordsContainer" id="space-for-text" *ngIf="!review">
        <div class="items_container" [ngClass]="{wordBox2_box: sets[currentSet].length == 2, wordBox31_box: sets[currentSet].length == 3, wordBox61_box: sets[currentSet].length == 6, wordBox8_box: sets[currentSet].length == 8}">
            <div [ngStyle]="color(palabra.answered, palabra.timeout)"
                *ngFor="let palabra of sets[currentSet]; let i = index" id="word{{ i }}" [attr.index]="i"
                class="wordBox" [ngClass]="{ 
                wordBoxOk:  palabra.answered == true, 
                wordBoxError:  palabra.answered == false, 
                wordBoxTimeout:  palabra.timeout == true, 
                wordBox2: sets[currentSet].length == 2,
                wordBox31: sets[currentSet].length == 3 && i == 0,
                wordBox32: sets[currentSet].length == 3 && (i == 1 || i == 2),
                wordBox4: sets[currentSet].length == 4, 
                wordBox61: sets[currentSet].length == 6 && (i == 0 || i == 5) ,
                wordBox62: sets[currentSet].length == 6 && (i == 1 || i == 2 || i == 3 || i == 4) ,
                wordBox8: sets[currentSet].length == 8
            }" (click)="validateWord($event)">
                <div *ngIf="palabra.answered == true || setComplete == true">
                    {{palabra.rae}}
                </div>
                <div *ngIf="palabra.answered != true && setComplete != true">
                    {{palabra.palabra}}
                </div>
            </div>
            <!-- Timer ==> position:absolute -->
            <div class="internalTimer" [ngClass]="{ internalTimerEnd: secs <= 2 }">
                {{secs}}
            </div>
        </div>
    </div>

    <div class="wordsContainer" [ngClass]="{'words-container-slide': slide, 'fixControlSessions': controlSession }" id="space-for-text" *ngIf="review">
        <div class="items_container reviewing" [ngClass]="{'morethan1': sets.length > 1}">
            <div *ngFor="let set of sets" class="wordSet">
                <div *ngFor="let palabra of set; let i = index" id="word{{ i }}" [attr.index]="i" class="wordBoxSol"
                    [ngClass]="{ wordBoxOk:  palabra.answered == true, wordBoxError:  palabra.answered == false}">
                    <div *ngIf="palabra.palabra != '00:00'"
                        [ngStyle]="controlSession && !slide ? {'font-size': 'calc(var(--font-size) * 1.5)'} : controlSession && slide ? {'font-size': 'calc(var(--font-size) * 1.2)'} : {}">
                        {{ controlSession ? palabra.palabra : palabra.rae}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  -->
<!-- <div class="rapid" [ngStyle]="{'pointer-events': review ? 'none;' : ''}">   <h2 *ngIf="controlSession" class="doc-number">{{current + 1}}</h2>
    <div *ngIf="!controlSession" class="exerciseTheme" [ngClass]="{'exerciseTheme2': timerEnabled == false}">
        <h1 [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></h1>
    </div>
    <div class="text-center exerciseTitle" [ngClass]="{'exerciseTitle-slide': slide}">
        <p   [innerHtml]="exercise.enunciado"></p>
        <div *ngIf="controlSession || slide " class="boxTime" [ngClass]="{'boxTimeSlide': slide}">
            <div class="content-time">00:00</div>
        </div>
    </div>
    <div class="wordsContainer" id="space-for-text" *ngIf="!review">
        <div [ngStyle]="color(palabra.answered, palabra.timeout)" *ngFor="let palabra of sets[currentSet]; let i = index" id="word{{ i }}" [attr.index]="i" class="wordBox" [ngClass]="{ 
                wordBoxOk:  palabra.answered == true, 
                wordBoxError:  palabra.answered == false, 
                wordBoxTimeout:  palabra.timeout == true, 
                wordBox2: sets[currentSet].length == 2,
                wordBox31: sets[currentSet].length == 3 && i == 0,
                wordBox32: sets[currentSet].length == 3 && (i == 1 || i == 2),
                wordBox4: sets[currentSet].length == 4, 
                wordBox61: sets[currentSet].length == 6 && (i == 0 || i == 5) ,
                wordBox62: sets[currentSet].length == 6 && (i == 1 || i == 2 || i == 3 || i == 4) ,
                wordBox8: sets[currentSet].length == 8
            }" (click)="validateWord($event)">
            <div *ngIf="palabra.answered == true || setComplete == true">
                {{palabra.rae}}
            </div>
            <div *ngIf="palabra.answered != true && setComplete != true">
                {{palabra.palabra}}
            </div>
        </div>
        <div class="internalTimer" [ngClass]="{ internalTimerEnd:  secs <= 2 }">
            {{secs}}
        </div>
    </div>
    <div class="wordsContainer" [ngClass]="{'words-container-slide': slide }" id="space-for-text" *ngIf="review" [ngStyle]="controlSession || slide ? {'margin-top': '3em;'} : controlSession && slide? {'margin-top': '1em;'} : {}">
        <div *ngFor="let set of sets" class="wordSet">
            <div *ngFor="let palabra of set; let i = index" id="word{{ i }}" [attr.index]="i" class="wordBoxSol" [ngClass]="{ wordBoxOk:  palabra.answered == true, wordBoxError:  palabra.answered == false}">
                <div *ngIf="palabra.palabra != '00:00'" [ngStyle]="controlSession && !slide ? {'font-size': 'calc(var(--font-size) * 1.5)'} : controlSession && slide ? {'font-size': 'calc(var(--font-size) * 1.2)'} : {}">
                        {{ controlSession ? palabra.palabra : palabra.rae}}
                </div>
            </div>
        </div>
    </div>
</div> -->