import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'app/core/shared/shared.service';
import { LeeAnswers } from 'app/core/shared/state/models/Wali-lee/lee-answers.model';
import { LeeBooks } from 'app/core/shared/state/models/Wali-lee/lee-books.model';
import { LeeQuestions } from 'app/core/shared/state/models/Wali-lee/lee-questions.model';
import { LeeResult } from 'app/core/shared/state/models/Wali-lee/lee-result.model';
import { LeeSections } from 'app/core/shared/state/models/Wali-lee/lee-sections.model';
import { WaliLeeService } from 'app/services/wali-lee.service';
import { environment } from 'environments/environment';
import { combineLatest, Subject } from 'rxjs';
import { finalize, takeUntil, take } from 'rxjs/operators';

@Component({
    selector: 'walilee-test',
    templateUrl: './walilee-test.component.html',
    styleUrl: './walilee-test.component.scss',
    encapsulation: ViewEncapsulation.None,
})
export class WalileeTestComponent implements OnInit, OnDestroy {
    public ready: boolean = false;
    public triedSave: boolean = false;
    questions: LeeQuestions[];
    questionIndex: number = 0;
    loading: boolean;
    questionsForm: UntypedFormGroup;
    @Input() book: LeeBooks;
    @Input() section: LeeSections;
    @Input() idUser: number;
    @Output() onClose = new EventEmitter<LeeResult | void>();
    state = "PRE";
    currentUser = JSON.parse(sessionStorage.getItem("currentUser"))
    private _unsubscribeAll: Subject<any>;
    //showGame = false;
    confirmDialog: MatDialogRef<FuseDialogContinueComponent>;
    mobile: boolean;
    public walileeBackgroundStyle: SafeStyle = null; 
    public questionColors: string[] = ['#3bb39c', '#ffc000', '#ff9100', '#ff0194'];
    //params = {};
    //game_url: SafeResourceUrl;
    //IdGame = Math.floor(Math.random() * 4) + 1
    //listener: any;
    //showReward= false;
    //messages = []
    //userReward: RewardResult
    constructor(private _waliLeeService: WaliLeeService,
        private formBuilder: UntypedFormBuilder,
        private _matDialog: MatDialog,
        public _sharedService: SharedService,
        private _sanitizer: DomSanitizer,
        private _translateService: TranslateService,
        private _detector: ChangeDetectorRef
    ) {
        this._unsubscribeAll = new Subject();
    }
        
    ngOnDestroy(): void {
        //window.removeEventListener('message', this.listener);
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit(): void {
        //this._listenMessages();
        this.walileeBackgroundStyle = this._sanitizer.bypassSecurityTrustStyle(`url('${environment.filesUrl}/images/WaliLee/WaliLee.svg?ngsw-bypass=true')`);
        this.mobile = this._sharedService.isMobile();
        this.questionsForm = this.formBuilder.group({
            Questions: this.formBuilder.array([])
        })
       /* let GAME_NAME =  MiniGames[this.IdGame];
        this.params = JSON.stringify({
            GAME_URL_SOURCE: environment.baseApi,
            GAME_ID_USER: this.currentUser.Id,
            GAME_LEVEL_USER: this.currentUser.Level
        });
        this.game_url = this.sanitizer.bypassSecurityTrustResourceUrl(`./assets/games/mini-games/${GAME_NAME}/index.html`);*/
        if (this.idUser) {
            combineLatest([
                this._waliLeeService.getQuestionsAndAnswers(this.currentUser.Id, this.section.IdLee, this.section.IdSection),
                this._waliLeeService.getUserQuestionsAndAnswers(this.idUser, this.section.IdLee, this.section.IdSection)
            ]).pipe(takeUntil(this._unsubscribeAll), take(1)).subscribe(data => {
                this.questions = data[0];
                this.questions.forEach((pregunta, preguntaIndex) => {
                    this.addQuestion(pregunta);
                    //console.log(pregunta.Answers.find(x => x.IsCorrect).Answer);
                    pregunta.Answers = this.shuffleArray(pregunta.Answers);
                    pregunta.Answers.forEach((respuesta, respuestaIndex) => {
                        const userAnsweredThis = !!data[1].find(a => a.IdLee === respuesta.IdLee && a.IdQuestion === respuesta.IdQuestion && a.IdAnswer === respuesta.IdAnswer);

                        respuesta.IsIncorrect = !respuesta.IsCorrect && userAnsweredThis; 
                        respuesta.IsCorrect = respuesta.IsCorrect; 

                        this.addQuestionAnswer(preguntaIndex, respuesta);
                    });
                })

                this.ready = true;
                this.state = 'REVIEW';
                console.log()
                this._detector.detectChanges();
            });
        } else {
            this.getQuestions();
        }
    }

    getQuestions() {
        this._waliLeeService.getQuestionsAndAnswers(this.currentUser.Id, this.section.IdLee, this.section.IdSection)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                this.questions = result;
                this.questions.forEach((pregunta, preguntaIndex) => {
                    this.addQuestion(pregunta);
                    //console.log(pregunta.Answers.find(x => x.IsCorrect).Answer);
                    pregunta.Answers = this.shuffleArray(pregunta.Answers);
                    pregunta.Answers.forEach((respuesta, respuestaIndex) => {
                        this.addQuestionAnswer(preguntaIndex, respuesta);
                    });
                })

                //this.ready = true;

                if (this.section.Started) {
                    this.Restart();
                }
                else {
                    this.start();
                }
            })
    }

    finish() {
        if (this.idUser) {
            this.onClose.emit();

            return;
        }

        this.loading = true;
        let notDone = [];

        this.Questions().controls.forEach((question, questionIndex) => {
            if (this.QuestionAnswers(questionIndex).controls.every(x => x.value.IsCorrect == false)) {
                notDone.push(question.value.IdQuestion);
            }
        });

        if (notDone.length > 0) {
            var message = this._translateService.instant('STUDENT.WALILEE.WALILEE-TEST.MISSING-ANSWERS');

            notDone.forEach((x, index) => {
                if (index == notDone.length - 1) {
                    message += x + "."
                }
                else {
                    message += x + ", "
                }
            })
            this.confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
                disableClose: true
            });
            this.confirmDialog.componentInstance.title = this._translateService.instant('STUDENT.WALILEE.WALILEE-TEST.LEAVE-TEST-TITLE');
            this.confirmDialog.componentInstance.message1 = message;
            this.confirmDialog.componentInstance.message2 = this._translateService.instant('STUDENT.WALILEE.WALILEE-TEST.LEAVE-TEST-MESSAGE');
            this.confirmDialog.componentInstance.space = true;
            this.confirmDialog.componentInstance.margin = false;
            this.confirmDialog.componentInstance.options = [{
                text: this._translateService.instant('ACCEPT'),
                callback: () => {
                    //this.saveTest();
                    this.onClose.emit();
                }
            }, {
                text: "Cancelar",
                callback: () => {
                    //this.loading = false;
                }
            }];

            this.confirmDialog.afterClosed().pipe(takeUntil(this._unsubscribeAll), finalize(() => { this.loading = false; this.confirmDialog = null; this._detector.detectChanges(); })).subscribe(result => {
                result();
                //this.confirmDialog = null;
            });
        }/* else {
            this.saveTest();
        }*/
    }

    start() {
        if (!this.loading) {
            this.loading = true;
            this._waliLeeService.Start(this.currentUser.Id, this.section.IdLee, this.section.IdSection, this.currentUser.IdClass, this.questions.length).pipe(takeUntil(this._unsubscribeAll), finalize(() => { this.loading = false; this._detector.detectChanges(); })).subscribe(_ => {
                this.ready = true;
                this.state = "TEST";
            });
        }
    }

    Restart() {
        if (!this.loading) {
            this.loading = true;

            this._waliLeeService.Restart(this.currentUser.Id, this.section.IdLee, this.section.IdSection).pipe(takeUntil(this._unsubscribeAll), finalize(() => { this.loading = false; this._detector.detectChanges(); })).subscribe(_ => {
                this.ready = true;
                this.state = "TEST";
            });
        }
    }

    cerrar() {
        this.onClose.emit();
    }

    Questions(): UntypedFormArray {
        return this.questionsForm.get('Questions') as UntypedFormArray;
    }
    newQuestion(): UntypedFormGroup {
        return this.formBuilder.group({
            IdQuestion: 0,
            Question: ['', Validators.required],
            Answer: this.formBuilder.array([this.newAnswer()])
        })
    }

    newEmptyQuestion(question: LeeQuestions = null): UntypedFormGroup {
        return this.formBuilder.group({
            IdQuestion: question ? question.IdQuestion : 0,
            Question: question ? [question.Question, Validators.required] : ['', Validators.required],
            Answer: this.formBuilder.array([])
        })
    }

    addQuestion(question: LeeQuestions = null) {
        if (question) {
            this.Questions().push(this.newEmptyQuestion(question));
        }
        else {
            this.Questions().push(this.newQuestion());
        }
    }
    QuestionAnswers(questionIndex): UntypedFormArray {
        return this.Questions().at(questionIndex).get("Answer") as UntypedFormArray
    }

    newAnswer(answer: LeeAnswers = null): UntypedFormGroup {
        return this.formBuilder.group({
            IdQuestion: answer ? answer.IdQuestion : 0,
            IdAnswer: answer ? answer.IdAnswer : 0,
            Answer: answer ? [answer.Answer, Validators.required] : ['', Validators.required],
            IsCorrect: this.idUser ? answer.IsCorrect || false : false,
            IsIncorrect: this.idUser ? answer.IsIncorrect || false : false
        })
    }
    addQuestionAnswer(questionIndex: number, answer: LeeAnswers = null) {
        this.QuestionAnswers(questionIndex).push(this.newAnswer(answer));
    }
    changeCorrectAnswer(questionIndex, answerIndex) {
        this.questionsForm.value.Questions[questionIndex].Answer.forEach((respuesta, index) => {
            if (answerIndex == index) {
                respuesta.IsCorrect = !respuesta.IsCorrect;
            } else {
                respuesta.IsCorrect = false;
            }
        });
    }

    saveTest() {
        //primero se guardan las respuestas
        let notAnwsered = []
        let userAnswers = [];

        this.Questions().controls.forEach((question, questionIndex) => {
            this.QuestionAnswers(questionIndex).controls.forEach(rta => {
                if (rta.value.IsCorrect) {
                    userAnswers.push({
                        IdQuestion: question.value.IdQuestion,
                        IdAnswer: rta.value.IdAnswer
                    });
                }
            });

            if (this.QuestionAnswers(questionIndex).controls.every(x => x.value.IsCorrect == false)) {
                notAnwsered.push(question.value.IdQuestion);
            }
        });

        if (notAnwsered.length > 0) {
            this.triedSave = true;
            var message = this._translateService.instant('main.student.wali-lee.walilee-test.no-has-respondido-siguientes-preguntas');

            notAnwsered.forEach((x, index) => {
                if (index == notAnwsered.length - 1) {
                    message += x + "."
                }
                else {
                    message += x + ", "
                }
            })

            this.confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
                disableClose: true
            });

            this.confirmDialog.componentInstance.title = this._translateService.instant('STUDENT.WALILEE.WALILEE-TEST.FINISH-TEST')
            this.confirmDialog.componentInstance.message1 = message;
            this.confirmDialog.componentInstance.message2 = this._translateService.instant('STUDENT.WALILEE.WALILEE-TEST.ANSWERS-ALL-QUESTIONS')
            this.confirmDialog.componentInstance.space = true;
            this.confirmDialog.componentInstance.margin = false;
            this.confirmDialog.componentInstance.options = [{
                text: this._translateService.instant('ACCEPT'),
                callback: () => {}
            },];

            this.confirmDialog.afterClosed().pipe(takeUntil(this._unsubscribeAll), finalize(() => { this.loading = false; this.confirmDialog = null; this._detector.detectChanges(); })).subscribe();

            return;
        }/* else {
            this.saveTest();
        }*/

        //let numOks = 0;
        //let numTotalPosibleAnswers = this.questions.length;
/*
        this.questions.forEach(question => {
            question.Answers.forEach(answer => {
                if (answer.IsCorrect) {
                    if (userAnswers.find(x => x.IdQuestion == question.IdQuestion && x.IdAnswer == answer.IdAnswer)) {
                        numOks++;
                    }
                }
            })
            if (numOks < 0) {t
                numOks = 0;
            }
        });*/

        this._waliLeeService.saveTest(this.section.IdLee, this.section.IdSection, this.currentUser.Id, this.currentUser.IdClass, userAnswers).pipe(takeUntil(this._unsubscribeAll), finalize(() => { this.loading = false; this._detector.detectChanges(); })).subscribe((result: LeeResult) => this.onClose.emit(result));
/*
        this._waliLeeService.updateTestAnswers(this.section.IdLee, this.section.IdSection, this.currentUser.Id, userAnswers).pipe(takeUntil(this._unsubscribeAll)).subscribe(ok => {
            this._waliLeeService.updateTestResult(this.section.IdLee, this.section.IdSection, this.currentUser.Id, numOks, numTotalPosibleAnswers).pipe(takeUntil(this._unsubscribeAll)).subscribe((result) => {

                this.messages = [`Has obtenido ${50} walinwos.`];    
                this.showReward = true;
                this.loading = false;
                this._detector.detectChanges();

                if(this.section.showGame && result.IsApproved == true){
                    this.showGame = true;
                    this._detector.detectChanges();
                }
                else {
                    this.userReward = {
                        Descripcion: '',
                        Score: result.Score
                    }

                    this.showReward = true;
                    this._detector.detectChanges();
                }
            });
        });*/
    }
/*
    showGameModal(){
        this.confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });
        this.confirmDialog.componentInstance.title = "Minijuegos";
        this.confirmDialog.componentInstance.message1 = 'Tienes la posibilidad de jugar un minijuego para ganar una recompensa extra';
        this.confirmDialog.componentInstance.message2 = "¿Estás interesado?";
        this.confirmDialog.componentInstance.space = true;
        this.confirmDialog.componentInstance.margin = false;
        this.confirmDialog.componentInstance.options = [{
            text: "Si",
            callback: () => {
                //this.showGame = true;
                this._detector.detectChanges();
            }
        }, {
            text: "No",
            callback: () => {
                this.cerrar();
            }
        }];
        this.confirmDialog.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                result();
                this.confirmDialog = null;
            });
    }*/

    shuffleArray(array: any[]): any[] {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]]; // Intercambiar elementos
        }
        return array;
    }

    /*private _listenMessages(): void {
        if (environment.production) return;

        this.listener = this._messageEvent.bind(this);
        window.addEventListener('message', this.listener, false);       
    }
    
    private _messageEvent(e: any) {
        if (['http://localhost:4200', 'https://development.walinwa.net'].indexOf(e.origin) === -1) {
            return;
        }

        let messageRecived = {} as any;

        try {
            messageRecived = JSON.parse(e.data);
        } catch (ex) {
            messageRecived = { message: e.data };
        }

        if (messageRecived.message === 'GameResult') {         
            this.showGame = false;    
            // Guadardar resultado en la versión real
            let gameInfo = {
                fails: messageRecived.data.fails,
                seconds: messageRecived.data.seconds,
                backgroundImage: messageRecived.data.backgroundImage
            }
            this.saveGameResult(gameInfo);

        } else if (messageRecived.message === 'GameExit') {           
            this.showGame = false; 
            let gameInfo = {
                fails: -1,
                seconds: -1,
                backgroundImage: ''
            }
            this.saveGameResult(gameInfo);
        }
    }*/
    next(){
        this.questionIndex++;
    }

    previous(){
        if (this.questionIndex > 0) {
            this.questionIndex--;
        }
    }
    /*saveGameResult(gameInfo){
        this.loading = true;

        let activityInfo = {
            Idlee: this.section.IdLee,
            IdSection: this.section.IdSection,
            Advance: this.section.Advance,
            IdCivilization: this.currentUser.IdCivilizacion
        };

        this._gameService.SaveGameResult(this.IdGame, this.currentUser.Id, gameInfo.seconds, gameInfo.fails, gameInfo.backgroundImage, activityInfo, ActivityType.WaliLee).pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
            this.userReward = result;
            this.messages = [];

            if (this.userReward.Walinwos == null) {
                this.messages[0] = result.Descripcion;
            }
            
            this.showReward = true;
            this.loading = false;
        });
    }*/
}
