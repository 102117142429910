<div style="width: 100%; height: 100%;">

    <!-- start -->
    <div class="walinwa-box walinwa-box-blue" [hidden]="state != 'start'" fxNgClass.gt-sm="scroll_vertical">
        <div class="walinwa-box-header">
            {{'SCHOOL.ADMIN.TITLE' | translate }} {{school.Name}}
        </div>
        <div class="walinwa-box-content" fxLayout="column" fxLayoutAlign="center stretch">
            <div fxLayout="row wrap" fxLayoutAlign="space-around center" class="spe_gap">
                <div fxFlex="0 0 200px" fxLayout="column" fxLayoutAlign="center center" class="spe_gap">
                    <img (click)="navigateTo('/school/admin/teachers')" src="https://walinwa.blob.core.windows.net/images/School/ico-admin-area-profe.svg?ngsw-bypass=true" alt="profesores"
                        class="pointer ico_max_Width">
                    <div class="text-center big_font" [innerHTML]="'SCHOOL.ADMIN.TEACHER-AREA' | translate">
                        
                    </div>
                    <button (click)="navigateTo('/school/admin/teachers')" class="new_but verdes">
                        {{'SCHOOL.ADMIN.GO-TEACHER-AREA' | translate}}
                    </button>
                </div>
                <div fxFlex="0 0 200px" fxLayout="column" fxLayoutAlign="center center" class="spe_gap">
                    <img (click)="navigateTo('/school/admin/classes')" src="https://walinwa.blob.core.windows.net/images/School/ico-admin-area-aula.svg?ngsw-bypass=true" alt="aulas"
                        class="pointer ico_max_Width">
                    <div class="text-center big_font" [innerHTML]="'SCHOOL.ADMIN.CLASS-AREA' | translate">
                        
                    </div>
                    <button (click)="navigateTo('/school/admin/classes')" class="new_but oranges">
                        {{'SCHOOL.ADMIN.GO-CLASS-AREA' | translate}}
                    </button>
                </div>
                <div fxFlex="0 0 200px" fxLayout="column" fxLayoutAlign="center center" class="spe_gap">
                    <img (click)="navigateTo('/school/admin/students')" src="https://walinwa.blob.core.windows.net/images/School/ico-admin-area-alumno.svg?ngsw-bypass=true" alt="alumnos"
                        class="pointer ico_max_Width">
                    <div class="text-center big_font" [innerHTML]="'SCHOOL.ADMIN.STUDENTS-AREA' | translate">
                        
                    </div>
                    <button (click)="navigateTo('/school/admin/students')" class="new_but morados">
                        {{'SCHOOL.ADMIN.GO-STUDENTS-AREA' | translate}}
                    </button>
                </div>
                <div fxFlex="0 0 200px" fxLayout="column" fxLayoutAlign="center center" class="spe_gap">
                    <img (click)="navigateTo('/school/admin/teachers')" src="https://walinwa.blob.core.windows.net/images/School/importar-de-classroom.svg?ngsw-bypass=true" alt="classroom"
                        class="pointer ico_max_Width">
                    <div class="text-center big_font" [innerHTML]="'SCHOOL.ADMIN.CLASSROOM-IMPORT' | translate">
                        
                    </div>
                    <button (click)="navigateTo('/school/admin/classroom')" class="new_but azul">
                        {{'SCHOOL.ADMIN.GO-CLASSROOM-IMPORT' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- demo -->
    <div class="walinwa-box walinwa-box-green" [hidden]="state != 'demo'" fxNgClass.gt-sm="scroll_vertical">
        <div class="walinwa-box-header">
            {{'SCHOOL.ADMIN.TEST-SESSION.TITLE' | translate}}
        </div>
        <div class="walinwa-box-content" fxLayout="column" fxLayoutAlign="center stretch">
            <div fxLayout="column" fxLayoutAlign="stretch" class="spe_gap">
                <div>
                    <label class="font_blue">{{'SCHOOL.ADMIN.TEST-SESSION.SELECT-LEVEL' | translate}}</label>
                </div>
                <div>
                    <select [(ngModel)]="cursoDemo" class="ww_select">
                        <option [value]="class.Level" *ngFor="let class of coursesForDemo | sortBy:'asc':'Level'">{{
                            class.Name }}
                        </option>
                    </select>
                </div>
                <div>
                    <ul>
                        <li>{{'SCHOOL.ADMIN.TEST-SESSION.INFO1' | translate}}
                        </li>
                        <li>{{'SCHOOL.ADMIN.TEST-SESSION.INFO2' | translate}}
                        </li>
                        <li>{{'SCHOOL.ADMIN.TEST-SESSION.INFO3' | translate}}
                        </li>
                        <li>{{'SCHOOL.ADMIN.TEST-SESSION.INFO4' | translate}}
                        </li>
                    </ul>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center" class="spe_gap">
                    <button [disabled]="calling" (click)="continueDemo()" class="new_but azul">
                        {{'CONTINUE' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<img [hidden]="!loading" style="width: 10em; position: fixed;top: 0;left: 0;bottom: 0;right: 0;margin: auto;"
src="https://walinwa.blob.core.windows.net/images/loading.gif?ngsw-bypass=true">