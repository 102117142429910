<div class="walinwa-box">
    <div class="walinwa-box-content" id="editSessionContainer">
        <div>
            <div [innerHTML]="'SCHOOL.TEACHER.ACTIVITIES.HEARING.TITLE' | translate">
            </div>
            <div class="custom-form-field" style="margin-top: 20px">
                <mat-form-field>
                    <label>{{'SCHOOL.TEACHER.ACTIVITIES.HEARING.COURSE' | translate }}</label>
                    <mat-select [(value)]="selectedCourse" [(ngModel)]="selectedCourse"
                        (selectionChange)="getHearingTexts()">
                        <mat-option class="scrollable" *ngFor="let class of courses  | sortBy:'asc':'Level'"
                            [value]="class.Level">{{ class.Name }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <cal-hearing-text *ngIf="hearingTexts" class="calendario dinamico walCalendar azul ww_customCalendar"
                [ngClass]="{'sixWeeks': six_weeks}" [showAll]="true" [hearingTexts]="hearingTexts" [parent]="this"
                [fullDay]="false" [currentUser]="student" [selectedDay]="selectedDay" [currentMonth]="currentMonth"
                [licenseDate]="licenseDate">
            </cal-hearing-text>
        </div>

        <div class="walinwa-row spe_gap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center">

            <button [disabled]="!hasExercise || afterLicense" (click)="showExercise()" class="new_but azul">
                {{'SCHOOL.TEACHER.ACTIVITIES.HEARING.SHOW-EXERCISE' | translate }}
            </button>

        </div>

    </div>

</div>
<router-outlet></router-outlet>