import { MyZoneItemsUser } from 'app/core/shared/state/models/my-zone-items-user.model';
import { MyZoneService } from 'app/services/my-zone.service';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { AvatarShopService } from 'app/services/avatar-shop.service';
import { NavigationService } from 'app/services/navigation.service';
import { AppService } from 'app/app.service';
import { FileService } from 'app/services/file.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { SharedService } from 'app/core/shared/shared.service';

@Component({
    selector: 'app-scenary',
    templateUrl: './scenary.component.html',
    styleUrls: ['./scenary.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ScenaryComponent implements OnInit, OnDestroy {

    private _unsubscribeAll: Subject<any>;
    activeItems;
    avatarActive;
    availableAvatarItems;
    boughtItems;
    showItems = false;
    showHelp = false;
    loading = false;
    availableItems: MyZoneItemsUser[];
    mobileDetect;
    userInfo

    constructor(
        private authService: AuthService,
        private navigationService: NavigationService,
        public myZoneService: MyZoneService,
        private appService: AppService,
        public sharedService: SharedService,
    ) { 
        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
      }
      
    ngOnInit(): void {
        this.getAvailableImages();
        this.mobileDetect = this.appService.mobileDetect();
    }

   

    
    getAvailableImages() {
        this.myZoneService.GetUserItems(this.authService.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe((result: MyZoneItemsUser[]) => {
            this.availableItems = result
            if(result.length == 0){
                let IdUser : number = this.authService.currentUser.Id
                this.availableItems.push({
                    IdMyZone : 100,
                    IdUser : IdUser,
                    RewardDate: new Date(),
                    Active: true,
                    })
            }
            let fileNames = []
            this.availableItems.forEach(x =>{
                fileNames.push(this.getImgName(x))
                if(x.Active){
                    x.selected = true;
                }
            })
            this.myZoneService.GetUserInfo(this.authService.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
                this.userInfo = result;
            })
        })
    }
    save(){
        this.loading = true;
        let activo = this.availableItems.find(x => x.selected == true)
        this.myZoneService.update(this.authService.currentUser.Id,activo.IdMyZone)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(() => {
            this.loading = false;
            this.getAvailableImages()
        })
    }
    getImgName(item): string{
        return "det_" +item.IdMyZone + '.png';
    }

    selectItem(index) {
        this.availableItems.forEach(x => {
            x.selected = false;
        })
        this.availableItems[index].selected = true;
    } 
    errorCallback() {
        this.navigationService.goLogin();
    }
}
