<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="ex-boxesn spe_gap" [ngStyle]="{'pointer-events': review ? 'auto;' : ''}" fxLayout="column"
    fxLayoutAlign="stretch">

    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div class="wordsContainer" [ngClass]="{'action': !review, 'isiPhone': isiPhone == true, 'isAndroid': isAndroid == true}">
        <div class="items_container spe_gap" fxLayout="column" fxLayoutAlign="stretch">
            <div class="boxesContainer" id="space-for-boxes">
                <div *ngFor="let box of exercise.boxes; let i = index" [attr.box]="box.box" class="box dropzone">
                    <div class="exerciseRule">
                        <span>{{ box.box }}</span>
                    </div>
                    <span *ngFor="let word of words; let i = index" class="subPhraseAnswer" [attr.box]="word.box"
                        [hidden]="exercise.operador != '3b' || !(word.box == box.box && word.answered == true)">
                        {{ getWord(word.word) }}
                    </span>
                    <span *ngFor="let word of words; let i = index" class="subPhraseAnswer" [attr.box]="word.box"
                        [hidden]="exercise.operador != 'pg' || !(word.box == box.box && word.answered == true)">
                        {{ word.originalWord2 }}
                    </span>
                </div>
            </div>
            <div id="TextContainer" class="phraseBox phrase">
                <span *ngFor="let word of words; let i = index" [attr.box]="word.box" [attr.id]="i" class="subPhrase"
                    [ngClass]="{'draggable': word.drag == true && !showingSolution,'subPhraseNotAnswered': word.drag == true && word.answered == null && showingSolution, 'subPhraseErr': word.answered == false, 'subPhraseLarge' : largeText == true}">
                    {{ word.word }}
                </span>
                <div id="author" class="authorBox">
                    {{ exercise.libro }}<span class="authorInfo"> {{ exercise.autor }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  -->
<!-- <div class="ex-boxesn" [ngStyle]="{'pointer-events': review ? 'none;' : '','margin-top': controlSession ? '-2em;' : ''}">
    <h2 *ngIf="controlSession" class="doc-number2">{{current + 1}}</h2>
    <div *ngIf="!controlSession" class="exerciseTheme">
        <h1 [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></h1>
    </div>
    <div class="text-center exerciseTitle" [ngClass]="{'exerciseTitle-slide': slide}">
        <p   [innerHtml]="exercise.enunciado"></p>
    </div>
    <div [ngStyle]="controlSession ? {margin: '1em 0 0 0;'} : {}" class="boxesContainer" [ngClass]="{'boxes-container-slide': slide}" id="space-for-boxes">
        <div *ngFor="let box of exercise.boxes; let i = index" [attr.box]="box.box" class="box  dropzone">
            <div class="exerciseRule">
                <span>{{ box.box }}</span>
            </div>
            <span *ngFor="let word of words; let i = index" [ngStyle]="controlSession && slide ? {'height': '17em'} : {}" class="subPhraseAnswer" [attr.box]="word.box" [hidden]="exercise.operador != '3b' || !(word.box == box.box && word.answered == true)">
                {{ getWord(word.word) }}
            </span>
            <span *ngFor="let word of words; let i = index" class="subPhraseAnswer" [attr.box]="word.box" [hidden]="exercise.operador != 'pg' || !(word.box == box.box && word.answered == true)">
                {{ word.originalWord2 }}
            </span>
        </div>
    </div>
    <div id="space-for-text" class="wordsContainer" [ngClass]="{'words-container-slide': slide}" >
        <div id="TextContainer" class="text-center phraseBox phrase"  [ngClass]="{'phraseBox-slide': controlSession && slide }">
            <span  *ngFor="let word of words; let i = index" [ngStyle]="controlSession && !slide ? {'font-size': '2.2vh'} : {}" [attr.box]="word.box" [attr.id]="i" class="subPhrase" [ngClass]="{'draggable': word.drag == true && !showingSolution, 'subPhraseErr': word.answered == false, 'subPhraseLarge' : largeText == true}">
                {{ word.word }}
            </span>
            <div id="author" class="authorBox">
                {{ exercise.libro }}<span  class="authorInfo"> {{ exercise.autor }}</span>
            </div>
        </div>
    </div>
</div> -->