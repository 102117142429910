import { SharedService } from 'app/core/shared/shared.service';
import { SchoolService } from './../../../services/school.service';
import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy, ViewEncapsulation, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { CalReadingTextComponent } from 'app/components/cal-reading-text/cal-reading-text.component';
import { AuthService } from 'app/services/auth.service';
import { NavigationService } from 'app/services/navigation.service';
import { ReadingTextService } from 'app/services/reading-text.service';
import * as moment from 'moment';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { CalendarReadingText } from 'app/core/shared/state/models/Reading-text/reading-text-month.model';
import { ClassService } from 'app/services/class.service';
import { _Header } from '../_header/_header';
import { TranslateService } from '@ngx-translate/core';
declare var $;

@Component({
    selector: 'app-reading-text',
    templateUrl: './reading-text.component.html',
    styleUrls: ['./reading-text.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ReadingTextComponent extends _Header implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    @ViewChild('template', { static: true }) template: TemplateRef<any> | null = null;
    @ViewChild(CalReadingTextComponent) child: CalReadingTextComponent;
    currentUser: any;
    selectedDay: any;
    currentMonth: number;
    currentYear: number;
    readingTexts: CalendarReadingText[] = null;
    six_weeks: boolean;
    canDoIt: boolean;
    loading = false;
    isMobile: boolean;
    timeBlock: boolean;
    isFuture: boolean = false;;

    constructor(
        private authService: AuthService,
        private navigationService: NavigationService,
        private readingTextService: ReadingTextService,
        private router: Router,
        private ref: ChangeDetectorRef,
        private _matDialog: MatDialog,
        private sharedService: SharedService,
        private classService: ClassService,
        private _translateService: TranslateService
    ) {
        super();
        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit() {
        if (!this.authService.check()) return;
        this.sharedService.reading_text_enabled
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(readingTextEnabled => {
            if (!readingTextEnabled) {
                this.navigationService.go("student/session");
            }
        })
        this.currentUser = this.authService.currentUser;
        this.selectedDay = moment();
        this.selectedDay.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        this.currentMonth = this.selectedDay.month() + 1;
        this.currentYear = this.selectedDay.year();
        this.getReadingTextByMonth();
        this.readingTextService.reload_monthly_reading
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                if (result) {
                    this.getReadingTextByMonth();
                }
        })
        this.isMobile = this.detectMobile();
    }

    detectMobile(): boolean {
        const userAgent = navigator.userAgent.toLowerCase();
        return /mobile|android|iphone|ipad|iemobile|wpdesktop/i.test(userAgent);
    }

    sixWeeks() {
        return $('.week').length - 1 >= 6;
    }

    async selectedDayChangedCL(newDate, canDoIt) {
        this.selectedDay = newDate;
        if (this.selectedDay.format() < this.authService.currentUser.CreationDate && !this.authService.currentUser.UserName.includes(".tmp.")) {
            this.canDoIt = false;
        }
        else {
            this.canDoIt = true;
        }
        if (this.authService.currentUser.IsAdmin) {
            this.isFuture = false;
        } else {
            
            if (moment().utc().isSameOrBefore(this.selectedDay, 'day')) //today or future
                this.isFuture = true;
            else if (moment(this.authService.currentUser.CreationDate).diff(this.selectedDay, 'days', true) > 0)
                this.isFuture = true;
            else if (!this.finished() && moment().utc().month() - this.selectedDay.month() > 1)
                this.isFuture = true;
            else
                this.isFuture = false;
        }
        if (this.currentMonth != this.selectedDay.month() + 1) {
            this.currentMonth = this.selectedDay.month() + 1;
            this.currentYear = this.selectedDay.year();
            if (this.child) this.six_weeks = this.child.weeks.length >= 6
            this.getReadingTextByMonth();
        }
    }

    getReadingTextByMonth() {
        //this.readingTexts = [];
        //this.ref.detectChanges();
        if (!this.loading) {
            this.loading = true;

            this.readingTextService.getReadingTextByMonth(this.authService.currentUser.Id, this.currentMonth, this.currentYear, this.authService.currentUser.Level, this.authService.currentUser.IdRole, this.authService.currentUser.CreationDate)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(
                    result => {
                        if (!result || !Array.isArray(result)) return;
                        this.readingTexts = result
                        this.readingTextService.MonthlyReadingTexts = result;
                        this.loading = false;
                        this.finished();
                        this.ref.detectChanges();
                        this.child.refresh((weeks_count) => {
                            this.six_weeks = weeks_count >= 6
                        });
                    },
                    error => {
                        this.readingTexts = [];
                        this.loading = false;
                    }
                )
        }
    }

    play(review: boolean) {
        this.loading = true;
        let currentReadingText = this.readingTexts.find((r) => { return moment(r.date).isSame(this.selectedDay) });
        if (!currentReadingText) {
            let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
                disableClose: true
            });
            confirmDialog.componentInstance.title = this._translateService.instant('STUDENT.READING-TEXT.START-READING-DIALOG-TITLE');
            confirmDialog.componentInstance.message1 = this._translateService.instant('STUDENT.READING-TEXT.CANT-RECOVER-READING-FROM-DATE');
            confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
            confirmDialog.componentInstance.margin = false;
            confirmDialog.afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(result => {
                    this.router.navigate(['/student/reading-text']);
                    confirmDialog = null;
                    this.loading = false;
                });
            return;
        }
        if (this.authService.currentUser.IdClass) {
            this.checkTimeBlock(currentReadingText, review);
        }
        else {
            this.navigationService.go("student/reading-text/" + (review ? "review" : "play") + "/" + currentReadingText.IdReadingText + "/" + currentReadingText.date, { review: review });
            setTimeout(() => {
                this.loading = false;
            }, 1000);
        }

        //this.fixPositionScroll_vertical_Class();
    }

    canPlay() {
        if (!this.readingTexts) return false;
        let currentReadingText = this.readingTexts.find((r) => { return moment(r.date).isSame(this.selectedDay) });
        let canPlay = currentReadingText != null;
        let futuro = false;
        if (this.authService.currentUser.IsAdmin) {
            futuro = false;
        }
        else {
            if (moment().diff(this.selectedDay, 'days', true) < 0) //today or future
                futuro = true;
            else if (moment(this.authService.currentUser.CreationDate).diff(this.selectedDay, 'days', true) > 0)
                futuro = true;
            else if (!this.finished() && moment().month() - this.selectedDay.month() > 1)
                futuro = true;
            else
                futuro = false;
        }
        return canPlay && !futuro;
    }

    finished() {
        if (!this.readingTexts) return false;
        let currentReadingText = this.readingTexts.find((r) => { return moment(r.date).isSame(this.selectedDay) });
        if (!currentReadingText) return false;
        if (currentReadingText.Score == null) return false;
        return currentReadingText.FinishDate;
    }
    started() {
        if (!this.readingTexts) return false;
        let currentReadingText = this.readingTexts.find((r) => { return moment(r.date).isSame(this.selectedDay) });
        if (!currentReadingText) return false;
        return currentReadingText.TextDate != null
    }
    checkTimeBlock(currentReadingText: any, review: boolean) {
        this.timeBlock = false
        this.classService.canExecuteReadingText(this.authService.currentUser.IdClass)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.timeBlock = !result.canStart
                if (this.timeBlock) {
                    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
                        disableClose: true
                    });
                    confirmDialog.componentInstance.title = this._translateService.instant('STUDENT.READING-TEXT.DISABLED-NOW');
                    confirmDialog.componentInstance.theme = "white";
                    confirmDialog.componentInstance.margin = false;
                    confirmDialog.componentInstance.options = [
                        {
                            text: this._translateService.instant('ACCEPT'),
                        }
                    ];
                    confirmDialog.componentInstance.margin = false;
                    confirmDialog.afterClosed()
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(result => {
                            confirmDialog = null;
                            this.loading = false;
                        });
                }
                else {
                    this.navigationService.go("student/reading-text/" + (review ? "review" : "play") + "/" + currentReadingText.IdReadingText + "/" + currentReadingText.date, { review: review });
                        setTimeout(() => {
                            this.loading = false;
                        }, 1000);
                }
            }, () => {
                this.loading = false;
            })
    }

    /*fixPositionScroll_vertical_Class() {
        const elements = document.querySelectorAll('.iphonechromepopup');
        elements.forEach((element) => {
            // (element as HTMLElement).style.overflow = 'inherit';
            element.classList.add("popup");
        });
        //console.log("popup scroll_fixed");
    }*/

}
