import {
    Component,
    OnInit,
    OnDestroy,
    ChangeDetectorRef,
    ViewEncapsulation,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { SchoolService } from "app/services/school.service";
import { FuseDialogContinueComponent } from "@fuse/components/dialog-continue/dialog-continue.component";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { UserService } from "app/services/user.service";
import { UiService } from "@fuse/components/spinner/ui.service";
import { SharedService } from "app/core/shared/shared.service";
import { NavigationService } from "app/services/navigation.service";
import { Subject, forkJoin } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Profiles } from "app/core/shared/session.model";
import { TranslateService } from "@ngx-translate/core";

declare var alasql;
declare var XLSX;
declare var $;

@Component({
    selector: "school-admin-teachers",
    templateUrl: "./admin-teachers.component.html",
    styleUrls: ["./admin-teachers.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class AdminTeachersComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    navigationSubscription;
    confirmDialog: MatDialogRef<FuseDialogContinueComponent>;
    currentUser;
    importExcel = false;
    teachers = [];
    school;
    dataLoaded = false;
    dataLoaded2 = false;
    public loading = false;
    selectedUser = null;    

    ordenColumnasProfe = {
        1: [
            this._translateService.instant('SCHOOL.ADMIN.IMPORT-TEACHER-COLUMN-ORDER1'),
            this._translateService.instant('SCHOOL.ADMIN.IMPORT-TEACHER-COLUMN-ORDER2'),
            this._translateService.instant('SCHOOL.ADMIN.IMPORT-TEACHER-COLUMN-ORDER3'),
        ],
        2: [this._translateService.instant('SCHOOL.ADMIN.IMPORT-TEACHER-COLUMN-ORDER4')],
    };

    numColumnasProfe = [
        { key: 1, name: this._translateService.instant('SCHOOL.ADMIN.IMPORT-NUM-COLS2') },
        { key: 2, name: this._translateService.instant('SCHOOL.ADMIN.IMPORT-NUM-COLS3') },
    ];
    ordenColumnas = {
        1: [
            this._translateService.instant('SCHOOL.ADMIN.IMPORT-STUDENT-COLUMN-ORDER1'),
            this._translateService.instant('SCHOOL.ADMIN.IMPORT-STUDENT-COLUMN-ORDER2'),
            this._translateService.instant('SCHOOL.ADMIN.IMPORT-STUDENT-COLUMN-ORDER3'),
        ],
        2: [
            this._translateService.instant('SCHOOL.ADMIN.IMPORT-STUDENT-COLUMN-ORDER4'), 
            this._translateService.instant('SCHOOL.ADMIN.IMPORT-STUDENT-COLUMN-ORDER5')
        ],
        3: [
            this._translateService.instant('SCHOOL.ADMIN.IMPORT-STUDENT-COLUMN-ORDER6'),
            this._translateService.instant('SCHOOL.ADMIN.IMPORT-STUDENT-COLUMN-ORDER7'),
        ],
    };

    numColumnas = [
        { key: 1, name: this._translateService.instant('SCHOOL.ADMIN.IMPORT-NUM-COLS1') },
        { key: 2, name: this._translateService.instant('SCHOOL.ADMIN.IMPORT-NUM-COLS2') },
        { key: 3, name: this._translateService.instant('SCHOOL.ADMIN.IMPORT-NUM-COLS3') },
    ];
    importDataConfig = {
        descartarPrimeraFila: false,
        numeroColumnasImportar: 2,
        ordenColumnas: 0,
        nombreApellidosSeparados: false,
    };

    importDataConfigProfe = {
        descartarPrimeraFila: false,
        numeroColumnasImportar: 1,
        ordenColumnas: 0,
        nombreApellidosSeparados: false,
    };

    escelOpt = "1";
    state;
    calling;
    formDataAdmin: UntypedFormGroup;
    checkIsAdmin;
    teacher;
    isAdmin;
    msg1 = [];
    msg2 = [];
    showMessage: boolean = false;
    isIpadIphone: boolean;
    runSchoolFirstTime = true;
    regExp = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜäÄëËïÏöÖç\s\.\-'ª’]+$/u;

    constructor(
        private router: Router,
        public schoolService: SchoolService,
        private userService: UserService,
        private _matDialog: MatDialog,
        private _formBuilder: UntypedFormBuilder,
        private _ui: UiService,
        private _sharedService: SharedService,
        private navigationService: NavigationService,
        private changeDetectorRef: ChangeDetectorRef,
        private _translateService: TranslateService
    ) {  
        this._unsubscribeAll = new Subject();
        this._ui.spin$.next(true);
        this.navigationSubscription = this.router.events.subscribe((e: any) => {
            // If it is a NavigationEnd event re-initalise the component
            if (e instanceof NavigationEnd) {
                this.init();
            }
        });
    }

    ngOnDestroy() {
        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit() {
        this.init();
        this.isIpadIphone = this.detectiPadiPhone();
    }

    detectiPadiPhone(): boolean {
        const userAgent = window.navigator.userAgent;
        return /iphone|iPad/i.test(userAgent);
    }

    init() {
        if (!sessionStorage.getItem("currentUser")) {
            this.goLogin();
            return;
        }
        this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
        this.navigationService.reset();
        if (
            !this.navigationService.params.IdClass ||
            !this.navigationService.params.IdUser ||
            !this.navigationService.params.isAdmin
        ) {
            this._ui.showSpinner();
        }
        this.school = this.schoolService.school;
        this._ui.stopSpinner();
        this.dataLoaded = true;
        this.calling = false;
        this.loading = true;
        this.loadClassesTeachersStudents();

        this._sharedService.run_get_school
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                if(data === true && this.runSchoolFirstTime === true){
                    this.runSchoolFirstTime = false;
                    this.getSchool();
                    return;
                }
        });
        
    }

    getSchool() {
        this.schoolService
          .getSchoolByUser(this.currentUser.Id)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            this.schoolService.school = result;
            this._sharedService.changeSchoolValidUntil(result.ValidUntil);
            this._sharedService.changeRunGetSchool(false);
            this.dataLoaded = true;
          });
      }

    selectUser(user: any) {
        this.selectedUser = user;
    }

    public clearSelectedUser(): void {
        this.selectedUser = null;

    }

    importData(data) {
        var listado = this.getEstructuraExcelCorrectaProfe(data);

        if (listado == null) {
            var excelFile: any = document.getElementById("excelFile");
            excelFile.value = "";
            return;
        }

        this.loading = true;
        this.importExcel = true;

        this.schoolService
            .getTeachersName(
                this.school.IdSchool,
                this.school.Initials,
                listado
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result) => {
                    this.successGetStudentsName(result);
                },
                (error) => {
                    this.errorGetStudentsName(error.error);
                }
            );
    }

    showModalSelectSheet(sheets, event) {
        var message = [];
        message[0] = this._translateService.instant('SCHOOL.ADMIN.TEACHERS.EXCEL-SELECT-SHEET-TITLE');
        message[1] = this._translateService.instant('SCHOOL.ADMIN.TEACHERS.EXCEL-SELECT-SHEET-MESSAGE');
        let options = [];
        for (let sheet of sheets) {
            options.push({
                text: sheet,
                callback: () => {
                    alasql(
                        'SELECT * FROM XLSX(?,{headers:false,sheetid:"' +
                        sheet +
                        '"})',
                        [event],
                        (data) => {
                            this.importData(data);
                        }
                    );
                    let excelFile: any = document.getElementById("excelFile");
                    excelFile.value = "";
                },
            });
        }

        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.options = options;
        confirmDialog.componentInstance.margin = false;
        confirmDialog.componentInstance.canClose = true;
        confirmDialog.componentInstance.theme = "white";

        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {
                result();
                this.confirmDialog = null;
            });
    }

    getEstructuraExcelCorrecta(data) {
        try {
            var listado = [];
            for (var i = 0; i < data.length; i++) {
                if (!this.isEmpty(data[i])) {
                    if (this.importDataConfig.descartarPrimeraFila) {
                        if (i == 0) {
                            i++;
                        }
                    }
                    var nombre = "";
                    var apellido = "";
                    if (this.importDataConfig.numeroColumnasImportar == 1) {
                        if (this.importDataConfig.nombreApellidosSeparados) {
                            var cell: any = "";
                            if (
                                data[i]["A"] != null &&
                                data[i]["A"].split(",").length > 1
                            ) {
                                cell = data[i]["A"].split(",");
                            } else if (
                                data[i]["A"] != null &&
                                data[i]["A"].split(".").length > 1
                            ) {
                                cell = data[i]["A"].split(".");
                            } else if (
                                data[i]["A"] != null &&
                                data[i]["A"].split(";").length > 1
                            ) {
                                cell = data[i]["A"].split(";");
                            } else {
                                cell = [data[i]["A"], ""];
                            }
                            nombre = this.importExcelSplit(
                                cell,
                                this.importDataConfig.ordenColumnas
                            )[0];
                            apellido = this.importExcelSplit(
                                cell,
                                this.importDataConfig.ordenColumnas
                            )[1];

                            if (nombre == null && apellido == null)
                                apellido = data[i]["A"];
                        } else {
                            if (this.importDataConfig.ordenColumnas == 0) {
                                if (
                                    data[i]["A"] != null &&
                                    data[i]["A"].length > 0
                                ) {
                                    nombre = data[i]["A"];
                                }
                            } else if (
                                this.importDataConfig.ordenColumnas == 1
                            ) {
                                if (
                                    data[i]["A"] != null &&
                                    data[i]["A"].split(" ").length > 1
                                ) {
                                    nombre = data[i]["A"].split(" ")[0];
                                    apellido = data[i]["A"].split(" ")[1];
                                    if (data[i]["A"].split(" ").length > 2) {
                                        let splt = data[i]["A"].split(" ");
                                        apellido = "";
                                        for (let j = 1; j < splt.length; j++) {
                                            apellido += splt[j] + " ";
                                        }
                                        apellido = apellido.trim();
                                    }
                                }
                            } else {
                                if (
                                    data[i]["A"] != null &&
                                    data[i]["A"].split(" ").length > 1
                                ) {
                                    if (data[i]["A"].split(" ").length > 2) {
                                        nombre = data[i]["A"].split(" ")[2];
                                        apellido = data[i]["A"].split(" ")[0];
                                        apellido =
                                            apellido +
                                            " " +
                                            data[i]["A"].split(" ")[1];
                                        if (
                                            data[i]["A"].split(" ").length > 3
                                        ) {
                                            for (
                                                let index = 3;
                                                index <
                                                data[i]["A"].split(" ").length;
                                                index++
                                            ) {
                                                nombre +=
                                                    " " +
                                                    data[i]["A"].split(" ")[
                                                    index
                                                    ];
                                            }
                                        }
                                    } else {
                                        if (data[i]["A"] != null) {
                                            nombre = data[i]["A"].split(" ")[1];
                                            apellido =
                                                data[i]["A"].split(" ")[0];
                                        }
                                    }
                                }
                            }
                        }
                    } else if (
                        this.importDataConfig.numeroColumnasImportar == 2
                    ) {
                        if (this.importDataConfig.ordenColumnas == 0) {
                            if (data[i]["A"] != null) {
                                nombre = data[i]["A"];
                            }

                            if (data[i]["B"] != null) {
                                apellido = data[i]["B"];
                            }
                        } else if (this.importDataConfig.ordenColumnas == 1) {
                            if (data[i]["B"] != null) {
                                nombre = data[i]["B"];
                            }

                            if (data[i]["A"] != null) {
                                apellido = data[i]["A"];
                            }
                        }
                    } else if (
                        this.importDataConfig.numeroColumnasImportar == 3
                    ) {
                        if (this.importDataConfig.ordenColumnas == 0) {
                            if (data[i]["A"] != null) {
                                nombre = data[i]["A"];
                            }

                            if (data[i]["B"] != null) {
                                apellido = data[i]["B"];
                            }

                            if (data[i]["C"] != null) {
                                apellido += " " + data[i]["C"];
                            }
                        } else if (this.importDataConfig.ordenColumnas == 1) {
                            if (data[i]["A"] != null) {
                                apellido = data[i]["A"];
                            }

                            if (data[i]["B"] != null) {
                                apellido += " " + data[i]["B"];
                            }

                            if (data[i]["C"] != null) {
                                nombre = data[i]["C"];
                            }
                        }
                    }
                    if (
                        nombre.includes("undefined") ||
                        apellido.includes("undefined")
                    )
                        continue;
                    if (
                        nombre != null ||
                        apellido != null ||
                        nombre.length > 0 ||
                        apellido.length > 0
                    )
                        listado.push({
                            Nombre: nombre.trim(),
                            Apellidos: apellido.trim(),
                        });
                }
            }
            return listado;
        } catch (error) {
            var message = [];
            message[0] = this._translateService.instant('SCHOOL.ADMIN.TEACHERS.EXCEL-STRUCTURE-ERROR-TITLE');
            message[1] = this._translateService.instant('SCHOOL.ADMIN.TEACHERS.EXCEL-STRUCTURE-ERROR-MESSAGE');

            let confirmDialog = this._matDialog.open(
                FuseDialogContinueComponent,
                {
                    disableClose: true,
                }
            );
            confirmDialog.componentInstance.title = message[0];
            confirmDialog.componentInstance.message1 = message[1];
            confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
            confirmDialog.componentInstance.margin = false;

            confirmDialog
                .afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result) => {
                    this.confirmDialog = null;
                });

            return null;
        }
    }

    getEstructuraExcelCorrectaProfe(data) {
        try {
            var listado = [];
            var numcol;
            typeof this.importDataConfigProfe.ordenColumnas === "string"
                ? (numcol = Number(this.importDataConfigProfe.ordenColumnas))
                : (numcol = this.importDataConfigProfe.ordenColumnas);
            // if (this.importDataConfigProfe.numeroColumnasImportar == 2 && this.importDataConfigProfe.ordenColumnas == 1) {
            //   this.importDataConfigProfe.nombreApellidosSeparados = true;
            // }
            for (var i = 0; i < data.length; i++) {
                if (!this.isEmpty(data[i])) {
                    if (this.importDataConfigProfe.descartarPrimeraFila) {
                        if (i == 0) {
                            i++;
                        }
                    }
                    var nombre = "";
                    var apellido = "";
                    var email = "";
                    if (
                        this.importDataConfigProfe.numeroColumnasImportar == 1
                    ) {
                        switch (numcol) {
                            // 1. Nombre. 2. Email
                            case 0:
                                if (data[i]["A"] != null) {
                                    nombre = data[i]["A"];
                                }

                                if (data[i]["B"] != null) {
                                    email = data[i]["B"];
                                }
                                break;
                            // 1. Nombre y apellido/s. 2. Email
                            case 1:
                                var cell: any = "";
                                if (
                                    this.importDataConfigProfe
                                        .nombreApellidosSeparados
                                ) {
                                    if (
                                        data[i]["A"] != null &&
                                        data[i]["A"].split(",").length > 1
                                    ) {
                                        cell = data[i]["A"].split(",");
                                    } else if (
                                        data[i]["A"] != null &&
                                        data[i]["A"].split(".").length > 1
                                    ) {
                                        cell = data[i]["A"].split(".");
                                    } else if (
                                        data[i]["A"] != null &&
                                        data[i]["A"].split(";").length > 1
                                    ) {
                                        cell = data[i]["A"].split(";");
                                    }
                                    nombre = cell[0];
                                    apellido = cell[1];
                                } else {
                                    cell = data[i]["A"].split(" ");
                                    for (
                                        let index = 0;
                                        index < cell.length;
                                        index++
                                    ) {
                                        if (cell[index] == "") {
                                            cell.splice(index, 1);
                                        }
                                    }
                                    if (cell[0] != null) {
                                        nombre = cell[0];
                                    }
                                    if (cell[1] != null) {
                                        apellido = cell[1];
                                        if (cell[2] != null) {
                                            apellido += " " + cell[2];
                                        }
                                    }
                                }
                                if (data[i]["B"] != null) {
                                    email = data[i]["B"];
                                }
                                break;
                                var cell: any = "";
                                if (
                                    this.importDataConfigProfe
                                        .nombreApellidosSeparados
                                ) {
                                    if (
                                        data[i]["A"] != null &&
                                        data[i]["A"].split(",").length > 1
                                    ) {
                                        cell = data[i]["A"].split(",");
                                    } else if (
                                        data[i]["A"] != null &&
                                        data[i]["A"].split(".").length > 1
                                    ) {
                                        cell = data[i]["A"].split(".");
                                    } else if (
                                        data[i]["A"] != null &&
                                        data[i]["A"].split(";").length > 1
                                    ) {
                                        cell = data[i]["A"].split(";");
                                    }
                                    nombre = cell[1];
                                    apellido = cell[0];
                                } else {
                                    if (data[i]["A"] != null) {
                                        cell = data[i]["A"].split(" ");
                                        for (
                                            let index = 0;
                                            index < cell.length;
                                            index++
                                        ) {
                                            if (cell[index] == "") {
                                                cell.splice(index, 1);
                                            }
                                        }
                                        if (cell.length == 2) {
                                            apellido = cell[0];
                                            nombre = cell[1];
                                        } else {
                                            if (cell[0] != null) {
                                                apellido = cell[0];
                                            }

                                            for (
                                                let index = 1;
                                                index < cell.length;
                                                index++
                                            ) {
                                                nombre =
                                                    nombre + cell[index] + " ";
                                            }
                                        }
                                    }
                                }
                                if (data[i]["B"] != null) {
                                    email = data[i]["B"];
                                }
                                break;
                            //1. Apellidos y nombre. 2. Email
                            case 2:
                                var cell: any = "";
                                if (
                                    this.importDataConfigProfe
                                        .nombreApellidosSeparados
                                ) {
                                    if (
                                        data[i]["A"] != null &&
                                        data[i]["A"].split(",").length > 1
                                    ) {
                                        cell = data[i]["A"].split(",");
                                    } else if (
                                        data[i]["A"] != null &&
                                        data[i]["A"].split(".").length > 1
                                    ) {
                                        cell = data[i]["A"].split(".");
                                    } else if (
                                        data[i]["A"] != null &&
                                        data[i]["A"].split(";").length > 1
                                    ) {
                                        cell = data[i]["A"].split(";");
                                    }
                                    nombre = cell[1];
                                    apellido = cell[0];
                                } else {
                                    cell = data[i]["A"].split(" ");
                                    for (
                                        let index = 0;
                                        index < cell.length;
                                        index++
                                    ) {
                                        if (cell[index] == "") {
                                            cell.splice(index, 1);
                                        }
                                    }
                                    if (cell.length == 4) {
                                        nombre = cell[2] + " " + cell[3];
                                        apellido = cell[0] + " " + cell[1];
                                    } else {
                                        if (cell[0] != null && cell[1]) {
                                            apellido = cell[0] + " " + cell[1];
                                        }

                                        for (
                                            let index = 2;
                                            index < cell.length;
                                            index++
                                        ) {
                                            nombre = nombre + cell[index] + " ";
                                        }
                                    }
                                }
                                if (data[i]["B"] != null) {
                                    email = data[i]["B"];
                                }
                                break;
                            default:
                                break;
                        }
                    } else if (
                        this.importDataConfigProfe.numeroColumnasImportar == 2
                    ) {
                        if (data[i]["A"] != null) {
                            nombre = data[i]["A"];
                        }

                        if (data[i]["B"] != null) {
                            apellido = data[i]["B"];
                        }

                        if (data[i]["C"] != null) {
                            email = data[i]["C"];
                        }
                    }

                    if (nombre != null || apellido != null)
                        listado.push({
                            Nombre: nombre.trim(),
                            Apellidos: apellido.trim(),
                            Email: email,
                        });
                }
            }
            return listado;
        } catch (error) {
            var message = [];
            message[0] = this._translateService.instant('SCHOOL.ADMIN.TEACHERS.EXCEL-STRUCTURE-ERROR-TITLE');
            message[1] = this._translateService.instant('SCHOOL.ADMIN.TEACHERS.EXCEL-STRUCTURE-ERROR-MESSAGE');

            let confirmDialog = this._matDialog.open(
                FuseDialogContinueComponent,
                {
                    disableClose: true,
                }
            );
            confirmDialog.componentInstance.title = message[0];
            confirmDialog.componentInstance.message1 = message[1];
            confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
            confirmDialog.componentInstance.margin = false;

            confirmDialog
                .afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result) => {
                    this.confirmDialog = null;
                });

            return null;
        }
    }

    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) return false;
        }
        return true;
    }

    getObjectAtributes(obj) {
        var c = 0;
        for (var key in obj) {
            c++;
        }
        return c;
    }

    successGetStudentsName(response) {
        this.loading = false;
        this.calling = false;
        this._ui.stopSpinner();
        var excelFile: any = document.getElementById("excelFile");
        excelFile.value = "";
        var message = [];
        if (this.importExcel)
            message[0] = this._translateService.instant('SCHOOL.ADMIN.TEACHERS.IMPORT-TEACHER-SUCCESS-TITLE');

        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        // confirmDialog.componentInstance.textButton2 = "No"
        confirmDialog.componentInstance.margin = false;

        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(_ => this.teachersList());
    }

    errorGetStudentsName(response) {
        this.loading = false;
        this._ui.stopSpinner();
        var excelFile: any = document.getElementById("excelFile");
        excelFile.value = "";
        this.loading = false;
        var message = [];
        if (Array.isArray(response)) {
            for (var i = 0; i < response.length; i++) {
                message[i] = response[i].split(";")[0];
            }
        } else {
            message[0] =
            this._translateService.instant('SCHOOL.ADMIN.TEACHERS.IMPORT-TEACHER-ERROR-MESSAGE');
        }

        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title =
        this._translateService.instant('SCHOOL.ADMIN.TEACHERS.IMPORT-TEACHER-ERROR-TITLE');
        confirmDialog.componentInstance.messages = message;
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.margin = false;

        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {
                this.confirmDialog = null;
            });
    }

    importExcelSplit(cell, sort) {
        var obj = [];
        var surname2 = "";
        var name = "";
        switch (sort) {
            case "0":
                if (cell.length > 2) {
                    obj = [cell[0] + "." + cell[1] + "." + cell[2], ""];
                } else {
                    obj = [cell[0] + "." + cell[1], ""];
                }

                break;
            case "1":
                obj = [cell[0], cell[1]];
                break;
            case "2":
                obj = [cell[1], cell[0]];
                break;
            case "3":
                if (cell.length > 2) {
                    surname2 = cell[1];
                    name = cell[2];
                } else {
                    name = cell[1];
                }

                obj = [name, cell[0] + " " + surname2];
                break;
            default:
                break;
        }
        return obj;
    }

    exportData(tipo) {
        var listado = [];

        if (this.escelOpt == "2" && tipo == "profesores") {
            tipo = "profesores2";
        }

        switch (tipo) {
            case "profesores":
                var tmp = [];
                var opts = [];
                tmp = this.teachers;
                tmp.sort(function (a, b) {
                    if (a.FamilyName > b.FamilyName) return 1;
                    else if (a.FamilyName < b.FamilyName) return -1;
                    else return 0;
                });
                var tmpArray = [];
                listado.push(tmpArray);

                for (var i = 0; i < tmp.length; i++) {
                    listado[0].push({
                        Nombre: tmp[i].FamilyName + ", " + tmp[i].Name,
                        Correo: tmp[i].Email,
                        Usuario: tmp[i].UserName,
                    });
                }
                opts.push({ sheetid: "profesores", header: true });
                alasql(
                    'SELECT INTO XLSX("listado profesores ' +
                    this.school.Name +
                    '.xlsx",?) FROM ?',
                    [opts, listado]
                );

                break;

            case "profesores2":
                var tmp = [];
                var opts = [];
                tmp = this.teachers;
                tmp.sort(function (a, b) {
                    if (a.FamilyName > b.FamilyName) return 1;
                    else if (a.FamilyName < b.FamilyName) return -1;
                    else return 0;
                });
                var tmpArray = [];
                listado.push(tmpArray);

                for (var i = 0; i < tmp.length; i++) {
                    listado[0].push({
                        Nombre: tmp[i].FamilyName + ", " + tmp[i].Name,
                        Correo: tmp[i].Email,
                        Usuario: tmp[i].UserName,
                    });
                }
                opts.push({ sheetid: "profesores", header: true });
                var tmpAulas = [];

                tmpAulas = this.school.Classes;

                tmpAulas.sort(function (a, b) {
                    if (a.Level === b.Level) {
                        if (a.Name > b.Name) return 1;
                        else if (a.Name < b.Name) return -1;
                        else return 0;
                    }
                    if (a.Level > b.Level) return 1;
                    else if (a.Level < b.Level) return -1;
                    else return 0;
                });
                var tmpArray = [];
                listado.push(tmpArray);
                for (var i = 0; i < tmpAulas.length; i++) {
                    listado[1].push({
                        Grupo: tmpAulas[i].Name,
                        Curso: tmpAulas[i].Course.Name,
                        Profesor:
                            tmpAulas[i].Tutor.Name +
                            " " +
                            tmpAulas[i].Tutor.FamilyName,
                        "Código de aula": tmpAulas[i].Code,
                        "Número de alumnos": tmpAulas[i].NumStudents,
                    });
                }

                opts.push({ sheetid: "aulas", header: true });
                alasql(
                    'SELECT INTO XLSX("listado profesores y aulas ' +
                    this.school.Name +
                    ' 2.xlsx",?) FROM ?',
                    [opts, listado]
                );

                break;
        }
        this.escelOpt = "1";
    }

    errorCallback(error) {
        this.calling = false;
        if (error) {
            if (error == "InvalidUserName")
                this.formDataAdmin.controls["Username"].setErrors({
                    unique: true,
                });
            if (error == "Username")
                this.formDataAdmin.controls["Username"].setErrors({
                    unique: true,
                });
            if (error == "Email")
                this.formDataAdmin.controls["Email"].setErrors({
                    unique: true,
                });
        } else {
            this.goLogin();
        }
    }

    goLogin() {
        sessionStorage.removeItem("currentUser");
        sessionStorage.removeItem("adminLogin");
        sessionStorage.removeItem("state");
        sessionStorage.removeItem("state_params");
        sessionStorage.setItem("isLogged", "false");
        this.router.navigateByUrl("login");
    }

    getSchoolTeachers() {
        this.schoolService.getSchoolTeachers(this.school.IdSchool)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((teachers) => {
                this.loadTeachersData(teachers)
            }, (err) => {
                this.errorCallback(err);
            })
    }

    loadTeachersData(teachers: any): void {
        this.teachers = teachers;

        if (this.isTeacher(this.selectedUser)) {
            let profe = this.teachers.find(x => x.Id == this.selectedUser.Id)
            this.selectUser(profe);
        }

        this.calling = false;
    }

    loadClassesTeachersStudents(): void {
        forkJoin([
            this.schoolService.getSchoolClasses(this.school.IdSchool),
            this.schoolService.getSchoolTeachers(this.school.IdSchool),
        ])
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (data: any[]) => {
                    this.school.Classes = data[0];
                    this.loadTeachersData(data[1]);
                    this.loading = false;
                    this.dataLoaded2 = true;

                    if (this.navigationService.params.state) {
                        this.ChangeState(this.navigationService.params.state);
                    } else {                        
                        if (this.state) this.ChangeState(this.state);
                        else {
                            if (sessionStorage.getItem(Profiles.IsSchoolAdmin) === 'true' || sessionStorage.getItem(Profiles.IsSuperAdmin) === 'true')
                                this.teachersList(true);
                            else
                                this.goLogin();
                        }
                    }
                    this.calling = false;
                },
                error: (err: any) => { },
            });
    }

    volver() {        
        this.navigationService.resetParams();
        // Pablo seteaba este valor a null, pero no especifico por que
        //this.selectedUser = null;
        switch (this.state) {
            case "newTeacher":
            case "editTeacher":
            case "importarProfesoresAulaExcel":
                this.teachersList();

                break;

            default:
                this.router.navigateByUrl("/school/admin");

                break;
        }
    }

    unexpectedError(response) {
        this.calling = false;
        var message = [];
        message[0] = this._translateService.instant('ERROR');
        message[1] = this._translateService.instant('SCHOOL.MODAL-ERROR-MESSAGE1');
        message[2] = this._translateService.instant('SCHOOL.ADMIN.TEACHERS.RESET-INITIAL-PASSWORD-ERROR-MESSAGE2');
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.margin = false;

        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {
                return;
            });
    }

    successResetTeacherPassword(response) {
        this.calling = false;
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title =
        this._translateService.instant('SCHOOL.MODAL-SUCCESS-RESET-PASSWORD-TITLE');
        confirmDialog.componentInstance.message1 =
        this._translateService.instant('SCHOOL.ADMIN.TEACHERS.RESET-INITIAL-PASSWORD-SUCCESS-MESSAGE');
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.margin = false;

        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {
                return;
            });
    }

    errorPreDeleteTeacher(response) {
        this.loading = false;
        this.calling = false;
        if (response == null || response == null || response.length == 0) {
            this.goLogin();
        } else {
            var message = [];
            message[0] = this._translateService.instant('SCHOOL.DELETE-USER-ERROR-TITLE');
            if (response == "Classes") {
                message[1] =
                this._translateService.instant('SCHOOL.ADMIN.TEACHERS.DELETE-TEACHER-ERROR-CLASSES-MESSAGE');
            } else if (response == "isAuthorized") {
                message[1] = this._translateService.instant('SCHOOL.ADMIN.TEACHERS.DELETE-TEACHER-ERROR-AUTHORIZED-MESSAGE');
            } else message[1] = this._translateService.instant('SCHOOL.MODAL-ERROR-MESSAGE1');

            this.confirmDialog = this._matDialog.open(
                FuseDialogContinueComponent,
                {
                    disableClose: true,
                }
            );
            this.confirmDialog.componentInstance.title = message[0];
            this.confirmDialog.componentInstance.message1 = message[1];
            this.confirmDialog.componentInstance.message2 = message[2];
            this.confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
            this.confirmDialog.componentInstance.margin = false;

            this.confirmDialog
                .afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result) => {
                    this.confirmDialog = null;
                });
            return;
        }
    }

    successPreDeleteTeacher(response) {
        this.loading = false;
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.ADMIN.TEACHERS.DELETE-TEACHER-TITLE');
        confirmDialog.componentInstance.message1 =
        this._translateService.instant('SCHOOL.ADMIN.TEACHERS.DELETE-TEACHER-MESSAGE');
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        // confirmDialog.componentInstance.textButton2 = "No"
        confirmDialog.componentInstance.margin = false;
        confirmDialog.componentInstance.theme = "white";

        confirmDialog.componentInstance.options = [
            {
                text: "Sí",
                callback: () => {
                    this.calling = true;
                    this.loading = true;
                    this.schoolService
                        .deleteUser(
                            response,
                            this.currentUser.Id,
                            this.currentUser.IsSchoolAdmin
                        )
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(
                            (result) => {
                                this.successDeleteTeacher(result);
                            },
                            (error) => {
                                this.errorDeleteTeacher(error.error);
                            }
                        );
                },
            },
            {
                text: "No",
                callback: () => { },
            },
        ];
        confirmDialog.componentInstance.margin = false;
        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {
                result();
                this.confirmDialog = null;
            });
    }

    errorDeleteTeacher(response) {
        this.calling = false;
        this.loading = false;
        if (response == null || response == null || response.length == 0) {
            this.goLogin();
        } else {
            var message = [];
            message[0] = this._translateService.instant('ERROR');
            if (response == "Classes") {
                message[1] =
                this._translateService.instant('SCHOOL.ADMIN.TEACHERS.DELETE-TEACHER-ERROR-CLASSES-MESSAGE');
            } else if (response == "isAuthorized") {
                message[1] = this._translateService.instant('SCHOOL.ADMIN.TEACHERS.DELETE-TEACHER-ERROR-AUTHORIZED-MESSAGE');
            } else message[1] = this._translateService.instant('SCHOOL.ADMIN.TEACHERS.DELETE-TEACHER-ERROR-DEFAULT-MESSAGE');

            let confirmDialog = this._matDialog.open(
                FuseDialogContinueComponent,
                {
                    disableClose: true,
                }
            );
            confirmDialog.componentInstance.title = message[0];
            confirmDialog.componentInstance.message1 = message[1];
            confirmDialog.componentInstance.message2 = message[2];
            confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
            confirmDialog.componentInstance.margin = false;

            confirmDialog
                .afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result) => {
                    return;
                });
        }
    }

    successDeleteTeacher(response) {
        this.calling = false;
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title = "";
        confirmDialog.componentInstance.message1 =
        this._translateService.instant('SCHOOL.ADMIN.TEACHERS.DELETE-TEACHER-SUCCESS-MESSAGE1');
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.margin = false;

        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {
                this.calling = true;
                this.getSchoolTeachers();
                this.ChangeState("teacher");
            });
    }
    public selectFirstTeacher(): void {
        this.selectedUser = this.teachers && this.teachers.length > 0 ? this.teachers[0] : null;
    }
    getNumProfesores() {
        if (this.state != "teacher") return;
        return this.teachers.length;
    }

    isTeacher(teacher) {
        var r = false;
        if (teacher && teacher.IdRole && (teacher.IdRole == 2 || teacher.IdRole == 6 || teacher.IdRole == 5)) {
            r = true;
        }
        return r;
    }

    successCallbackUpdateTeacher(response) {
        this.calling = false;
        var message = [];
        message[0] = "";
        message[1] = this._translateService.instant('SCHOOL.ADMIN.TEACHERS.UPDATE-TEACHER-SUCCESS-MESSAGE1');

        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.margin = false;
        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.getSchoolTeachers();
                this.ChangeState("teacher");
                confirmDialog = null;
            });
    }

    successCallbackCreateTeacher(response) {
        this.calling = false;
        if (response == null || response == null || response.length == 0) {
            this.goLogin();
        } else {
            this.getSchoolTeachers();
            this.ChangeState("teacher");
        }
    }

    saveValidTeacher() {
        //New teacher data
        var teacher = {
            UserName: this.formDataAdmin.controls["Username"].value,
            Name: this.formDataAdmin.value.Name.trim(),
            FamilyName: this.formDataAdmin.value.FamilyName.trim(),
            Email: this.formDataAdmin.value.Email,
        };

        this.schoolService
            .addTeacher(
                teacher,
                this.school.IdSchool,
                this.formDataAdmin.controls["Password"].value
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result) => {
                    this.successCallbackCreateTeacher(result);
                },
                (error) => {
                    this.errorCallback(error.error);
                }
            );
        this.calling = true;
    }

    teachersList(selectFirstTeacher: boolean = false) {
        if (selectFirstTeacher) {
            this.selectFirstTeacher();
        }

        this.calling = false;
        this.loading = true;

        this.schoolService
            .getSchoolTeachers(this.school.IdSchool)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result: any) => {
                    this.teachers = result;
                    this.ChangeState("teacher");

                    if (this.school.Users.length > 0) {
                        for (var i = 0; i < this.school.Users.length; i++) {
                            if (
                                this.isTeacher(this.school.Users[i]) &&
                                !this.isTeacher(this.selectedUser)
                            ) {
                                this.selectUser(this.school.Users[i]);
                            }
                        }
                    }

                    this.loading = false;
                    this.dataLoaded2 = true;
                },
                (error) => {
                    this.errorCallback(error.error);
                }
            );
    }

    startPayment() {
        // $state.go('parentMenu.adminPayment', { school: this.school });
        this.navigationService.go("/school/adminPayment", {});
    }

    startPromo() {
        // $state.go('parentMenu.adminPromo', { school: this.school });
        this.router.navigateByUrl("/school/adminPromo");
    }

    ChangeState(state) {
        this.state = state;
        this.loading = false;
        this.navigationService.enableBackButton(true, this.volver.bind(this));        
        setTimeout(() => this.changeDetectorRef.detectChanges());
    }

    newTeacher() {
        this.checkIsAdmin = false;
        this.ChangeState("newTeacher");
        this.formDataAdmin = this._formBuilder.group({
            Name: ["", [Validators.required, Validators.pattern(this.regExp)]],
            FamilyName: ["", [Validators.pattern(this.regExp)]],
            Email: ["", [Validators.required, Validators.email]],
            Username: [{ value: "", disabled: true }],
            Password: [{ value: "12345678p", disabled: true }],
            isSchoolAdmin: [false],
        });
        this.formDataAdmin.markAllAsTouched();

        this.resetUsername();
    }

    viewReports() {
        this.router.navigateByUrl("/school/admin/report");
    }

    setTeacherName(response) {
        this.calling = false;
        this.formDataAdmin.patchValue({ Username: response.Username });
        this.saveTeacher();
    }

    invalidName(response) {
        this.calling = false;
    }

    getTeacherName() {
        if (this.formDataAdmin.invalid) return;
        this.formDataAdmin.value.Name =
            this.formDataAdmin.value.Name.trim().replace(" ", "-");
        this.formDataAdmin.value.FamilyName =
            this.formDataAdmin.value.FamilyName.trim().replace(" ", "-");
        this.schoolService
            .getTeacherName(
                this.school.Initials,
                this.formDataAdmin.value.Name,
                this.formDataAdmin.value.FamilyName
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result) => {
                    this.setTeacherName(result);
                },
                (error) => {
                    this.invalidName(error.error);
                }
            );

        // .then(setTeacherName, invalidStudentUsername);
        this.calling = true;
    }

    parseProfesorName(user) {
        return user.FamilyName + ", " + user.Name;
    }

    comprobarAdmin(teacher): boolean {
        return teacher.IdRole == 6;
    }

    comprobarAsignacion(teacher) {
        if (this.state != "teacher") return true;
        if(!this.school.Classes || this.school.Classes.length == 0){
            return false;
        }
        if (this.school.Classes.some((x) => x.IdTutor == teacher.Id)) {
            return true;
        } else {
            var bandera = false;
            this.school.Classes.forEach((clase) => {
                if (bandera) return bandera;
                if (
                    clase.AuthorizedParents.some(
                        (x) => x.IdUserTutor == teacher.Id
                    )
                ) {
                    return (bandera = true);
                } else {
                    return (bandera = false);
                }
            });
            return bandera;
        }
    }

    editTeacher() {
        if (!this.selectedUser || !this.selectedUser.Id) return;

        this.checkIsAdmin = true;

        if (this.calling) return;
        let idList: number[] = [];
        this.school.Classes.forEach((x) => {
            idList.push(x.IdClass);
        });
        this.teacher = this.selectedUser;
        this.schoolService
            .getUserAsignedClasses(this.teacher.Id, idList)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {
                this.msg1 = [];
                this.msg2 = [];
                result["AsignedClass"].forEach((claseAsignada) => {
                    this.msg1.push(" " + claseAsignada);
                });
                result["AuthorizedClasses"].forEach((claseAutorizada) => {
                    this.msg2.push(" " + claseAutorizada);
                });
                this.showMessage = true;
            });
        this.ChangeState("editTeacher");

        this.formDataAdmin = this._formBuilder.group({
            Id: [this.teacher.Id],
            Name: [this.teacher.Name, [Validators.required, Validators.pattern(this.regExp)]],
            FamilyName: [this.teacher.FamilyName, [Validators.pattern(this.regExp)]],
            Email: [this.teacher.Email, [Validators.required, Validators.email]],
            Username: [
                this.teacher.UserName,
                [Validators.required, Validators.minLength(6), Validators.maxLength(50), Validators.pattern(/^[0-9A-Za-zñÑ\.]+$/)],
            ],
            Password: [this.teacher.Password],
            isSchoolAdmin: [this.comprobarAdmin(this.selectedUser)],
        });
        this.formDataAdmin.markAllAsTouched();
        this.changeDetectorRef.detectChanges();
        if (this.teacher.UserName.split(".")[1] == "admin") {
            this.isAdmin = true;
        } else {
            this.isAdmin = false;
        }

        this.resetUsername();

        setTimeout(() => {
            $("#teacherUserName").bind("input propertychange", () => {
                this.resetUsername();
            });
        }, 1000);
    }

    updateTeacher() {
        if (this.formDataAdmin.invalid) return;
        this.calling = true;
        if (this.formDataAdmin.value.isSchoolAdmin) {
            this.userService
                .doSchoolAdmin(this.formDataAdmin.value.Id)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(
                    (result) => {
                        this.calling = false;
                        this.successRole(result);
                    },
                    (error) => {
                        this.errorRole(error.error);
                    }
                );
        } else {
            this.userService
                .undoSchoolAdmin(this.formDataAdmin.value.Id)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(
                    (result) => {
                        this.calling = false;
                        this.successRole(result);
                    },
                    (error) => {
                        this.errorRole(error.error);
                    }
                );
        }
    }

    errorRole(response) {
        this.calling = false;
     }

    successRole(response) {
        if (this.calling) return;

        var userNameLenghtRegex = /^.{6,25}$/;
        var userNameRegex = /^[\w_ñÑ\.]{1,25}$/;

        if (this.formDataAdmin.value.Email === null) return;

        if (this.state == "editTeacher") {
            if (
                this.formDataAdmin.value.Username ==
                this.school.Initials.toLowerCase() + "."
            ) {
                this.formDataAdmin.controls["Username"].setErrors({
                    required: true,
                });
                return;
            }
            var validUsername =
                this.formDataAdmin.value.Username.match(userNameLenghtRegex);
            if (validUsername == null && this.teacher.UserName.length >= 6) {
                this.formDataAdmin.controls["Username"].setErrors({
                    length: true,
                });
                return;
            }
            let username = this.formDataAdmin.value.Username.split(".")[1];
            validUsername = username.match(userNameRegex);
            if (validUsername == null) {
                this.formDataAdmin.controls["Username"].setErrors({
                    characters: true,
                });
                return;
            }
        }
        this.calling = true;
        this.schoolService
            .updateTeacher(
                this.teacher.Id,
                this.formDataAdmin.value.Name.trim(),
                this.formDataAdmin.value.FamilyName.trim(),
                this.formDataAdmin.value.Email.trim(),
                this.formDataAdmin.value.Username.trim(),
                this.formDataAdmin.value.Password
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result) => {
                    if(this.teacher.Id == this.currentUser.Id){
                        this.currentUser.Name = this.formDataAdmin.value.Name.trim()
                        this.currentUser.FamilyName = this.formDataAdmin.value.FamilyName.trim()
                        this.currentUser.Email = this.formDataAdmin.value.Email.trim()
                        this._sharedService.changeReloadTeacher(this.currentUser);
                    }
                    this.successCallbackUpdateTeacher(result);
                },
                (error) => {
                    this.errorCallback(error.error);
                }
            );
    }

    importTeacher() {;
        this.ChangeState("importarProfesoresAulaExcel");
    }

    resetUsername() {
        if (
            this.formDataAdmin.value.Username == null ||
            this.formDataAdmin.value.Username.indexOf(
                this.school.Initials.toLowerCase() + "."
            ) != 0
        ) {
            this.formDataAdmin.patchValue({
                Username: this.school.Initials.toLowerCase() + ".",
            });
        }
    }

    validateEmail(email) {
        var re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    saveTeacher() {
        if (this.calling) return;

        var userNameLenghtRegex = /^.{6,25}$/;
        var userNameRegex = /^[\w_ñÑ\.]{1,25}$/;

        if (this.formDataAdmin.value.Email === null) return;

        if (this.state == "editTeacher") {
            if (
                this.formDataAdmin.value.Username ==
                this.school.Initials.toLowerCase() + "."
            ) {
                this.formDataAdmin.controls["Username"].setErrors({
                    required: true,
                });
                return;
            }
            var validUsername =
                this.formDataAdmin.value.Username.match(userNameLenghtRegex);
            if (validUsername == null && this.teacher.UserName.length >= 6) {
                this.formDataAdmin.controls["Username"].setErrors({
                    length: true,
                });
                return;
            }
            validUsername =
                this.formDataAdmin.value.Username.match(userNameRegex);
            if (validUsername == null) {
                this.formDataAdmin.controls["Username"].setErrors({
                    characters: true,
                });
                return;
            }
        }

        if (this.formDataAdmin.invalid) return;

        this.userService
            .validateNewUser(
                this.formDataAdmin.controls["Username"].value,
                this.formDataAdmin.value.Email,
                true
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result) => {
                    this.saveValidTeacher();
                },
                (error) => {
                    this.errorCallback(error.error);
                }
            );
    }

    resetTeacherPassword() {
        if (this.calling) return;

        if (!this.selectedUser || !this.selectedUser.Id) return;

        var user = this.selectedUser;

        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true,
        });
        confirmDialog.componentInstance.title =
        this._translateService.instant('SCHOOL.MODAL-RESET-PASSWORD-TITLE')
        confirmDialog.componentInstance.message1 =
        this._translateService.instant('SCHOOL.ADMIN.TEACHERS.RESET-INITIAL-PASSWORD-MESSAGE', {userName: user.UserName})
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.theme = "white";
        confirmDialog.componentInstance.options = [
            {
                text: this._translateService.instant('SCHOOL.ADMIN.TEACHERS.RESET-INITIAL-PASSWORD-OPTION1'),
                callback: () => {
                    this.calling = true;
                    this.schoolService
                        .resetPassword(
                            user.Id,
                            this.currentUser.IdSchool,
                            this.currentUser.IsSchoolAdmin
                        )
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(
                            (result) => {
                                this.successResetTeacherPassword(result);
                            },
                            (error) => {
                                this.unexpectedError(error.error);
                            }
                        );
                },
            },
            {
                text: this._translateService.instant('SCHOOL.ADMIN.TEACHERS.RESET-INITIAL-PASSWORD-OPTION2'),
                callback: () => { },
            },
        ];
        confirmDialog.componentInstance.margin = false;
        confirmDialog
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {
                result();
                this.confirmDialog = null;
            });
    }

    deleteTeacher() {
        if (this.calling) return;

        if (!this.selectedUser || !this.selectedUser.Id) return;

        var user = this.selectedUser;

        if (user.Id == this.currentUser.Id) {
            this.confirmDialog = this._matDialog.open(
                FuseDialogContinueComponent,
                {
                    disableClose: true,
                }
            );
            this.confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.DELETE-USER-ERROR-TITLE');
            this.confirmDialog.componentInstance.message1 =
            this._translateService.instant('SCHOOL.ADMIN.TEACHERS.DELETE-TEACHER-ERROR-MESSAGE1');
            this.confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
            this.confirmDialog.componentInstance.margin = false;

            this.confirmDialog
                .afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result) => {
                    this.confirmDialog = null;
                });

            return;
        }

        this.loading = true;

        this.schoolService
            .preDeleteUser(
                user.Id,
                this.currentUser.Id,
                this.currentUser.IsSchoolAdmin
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result) => {
                    this.successPreDeleteTeacher(result);
                },
                (error) => {
                    this.errorPreDeleteTeacher(error.error);
                }
            );
    }

    loadFile(event) {
        let files = event.target.files;
        let file = files[0];
        this.getSheets(file, event);
    }

    getSheets(file, event) {
        var reader;
        reader = new FileReader();
        reader.onload = (e) => {
            let data;
            let workbook;
            let sheets;
            data = e.target.result;
            workbook = XLSX.read(data, { type: "binary" });
            sheets = workbook.SheetNames;
            if (sheets.length == 1) {
                alasql(
                    "SELECT * FROM XLSX(?,{headers:false})",
                    [event],
                    (data) => {
                        this.importData(data);
                    }
                );
            } else {
                // elegir qué pagina
                this.showModalSelectSheet(sheets, event);
            }
        };
        reader.onerror = function (ex) { };
        reader.readAsBinaryString(file);
    }

    selectFile() {
        document.getElementById("excelFile").click();
    }

    change() {
        this.importDataConfig.ordenColumnas = 0;
        this.importDataConfigProfe.ordenColumnas = 0;
    }
}
