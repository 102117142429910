import { HostListener, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'app/app.service';
import { ExerciseService } from 'app/services/exercise.service';
declare var $

@Component({
  selector: 'ex-verbos1',
  templateUrl: './verbos1.component.html',
  styleUrls: ['./verbos1.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Verbos1Component implements OnInit, OnDestroy {

  @Input() review
  @Input() show_errors;
  @Input() controlSession : boolean = false;
  @Input() slide : boolean = false; @Input() current;
  exercise
  draggingOption
  exerciseIsEnded = false
  finished = false
  f = false
  showingSolution = false;
  focusing = false;
  letters = [{ name: 'a' }, { name: 'b' }, { name: 'c' }, { name: 'd' },
  { name: 'e' }, { name: 'f' }, { name: 'g' }, { name: 'h' }, { name: 'i' },
  { name: 'j' }, { name: 'k' }, { name: 'l' }, { name: 'm' }, { name: 'n' },
  { name: 'ñ' }, { name: 'o' }, { name: 'p' }, { name: 'q' }, { name: 'r' },
  { name: 's' }, { name: 't' }, { name: 'u' }, { name: 'v' }, { name: 'w' },
  { name: 'x' }, { name: 'y' }, { name: 'z' }, { name: 'á' }, { name: 'é' },
  { name: 'í' }, { name: 'ó' }, { name: 'ú' }, { name: 'ü' }]

  elementsOk
  elementsErr
  inputLength: any;
  input: any;
  isMobile: any;
  regex = new RegExp(/Tab|Alt|Arrow|Page/);
  dragging = false
  indexErr = [];
    
  @HostListener('document:keydown', ['$event'])
  keyListener(event: KeyboardEvent) {
      if (this.review || this.isMobile) return;
      if (this.regex.test(event.key)) event.preventDefault();
      let index = this.letters.findIndex(x => x.name == event.key);

      if (index != null && index != -1) {
        this.add_letra(this.letters[index].name)
      }
  }
  @HostListener('window:message', ['$event'])
    exerciseListener(event){
      let data = event.data;
      switch (data) {
        case "SHOW_SOLUTION":
          this.showSolution();
          break;
        case "SHOW_ANSWER":
          this.ShowAnswer();
          break;
          case "SHOW_CUSTOM":
              this.showingSolution = false;
              this.exercise = this.exerciseService.getExerciseDefinition();
              this.get_letras();
              break;
      }
    }
  
    constructor(private exerciseService: ExerciseService,
    private appService: AppService) {
    this.exercise = this.exerciseService.getExerciseDefinition();
    this.get_letras();
  }
    ngOnDestroy() {
        
    }

  ngOnInit() {
    this.isMobile = this.appService.mobileDetect().IsIPhone || this.appService.mobileDetect().IsAndroidPhone || window.innerWidth < 600;
    if (this.review) {
      this.elementsOk = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsOK
      );
      this.elementsErr = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsERR
      );
      if (this.show_errors == false) {
                this.showSolution();
            } else if (this.show_errors == true) {
                this.ShowAnswer();
            }
    } else {
      this.initInteract();
    }
  }

  letras = []

  get_letras() {
    var letras = this.exercise.palabra.split('');
    this.letras = [];
    for (var i = 0; i < letras.length; i++) {
      this.letras.push({
        letra: letras[i],
        answered: null
      });
    }
  }

  add_letra(letra) {
    for (var i = 0; i < this.letras.length; i++) {
      if (this.letras[i].answered != true) {
        if (!this.dragging) {
          this.validar(i, letra);
        }
        return;
      }
    }
  }

  showSolution() {
      this.showingSolution = true;
    for (var i = 0; i < this.letras.length; i++) {
      this.letras[i].answered = true;
    }
  }

    ShowAnswer() {
        this.letras.forEach(letra => {
            letra.answered = null;
        })
        this.showingSolution = true;
        for (var i = 0; i < this.elementsErr.length; i++) {
            this.letras[this.elementsErr[i]].answered = false;
        }
        for (var i = 0; i < this.elementsOk.length; i++) {
            if (this.letras[this.elementsOk[i]].answered == null)
                this.letras[this.elementsOk[i]].answered = true;
        }
    }

  numOk = 0
  numErr = 0

    validar(a, b) {

        if (this.letras[a].letra == b) {
            this.letras[a].answered = true;
            this.numOk++;
        } else {
            this.letras[a].answered = false;
            this.numErr++;
            this.indexErr.push(a)
        }

        if (this.numOk == this.letras.length) {
            this.indexErr.forEach(index => {
                this.exerciseService.answer(index, false, 1)
            })
            this.exerciseService.answer(1, true, 1)
            this.setFocus(false);
            this.finished = true;

        }
    }

  initInteract() {
    this.exerciseService.initInteract({
      onmove: (event) => {
        this.draggingOption = event.target
      },
      drop: (event) => {
        if (this.finished) return;
        this.dragging = true;
        var letra_1 = this.draggingOption.getAttribute("letra");
        var letra_2 = event.target.getAttribute("letra");
        this.validar(letra_1, letra_2);
        this.dragging = false;
      },
    });
  }

  showAnswersTimeOut() {
    this.f = true;
    for (var i = 0; i < this.letras.length; i++) {
      this.letras[i].answered = true;
    }
  }
    change() {
        if (this.review) return;
        if (this.inputLength > this.input.length) {
            return;
        }
        var letter = this.input.charAt(this.input.length - 1)
        let index = this.letters.findIndex(x => x.name == letter);
        if (index != null && index != -1) {
            this.add_letra(this.letters[index].name)
        }
        this.inputLength = this.input.length
    }

    setFocus(bool = true) {
        if (!this.isMobile || this.review || this.showingSolution || this.finished) {
            return;
        }
        if (bool && !this.focusing) {
            document.getElementById("dictation-input").focus();
        }
        if (!bool && this.focusing) {
            document.getElementById("dictation-input").blur();
        }
        this.focusing = bool
    }


}
