<div class="walinwa-box-with-tabs" fxNgClass.gt-sm="scroll_vertical">
    <div class="walinwa-tabs walinwa-tabs-top">
        <div [ngClass]="{ 'selected': state == 'mail' }" (click)="editMailConfig()" class="walinwa-tab-gray">
            {{'SCHOOL.TEACHER.CONFIG.EMAILS-TITLE' | translate}}
        </div>
        <div [hidden]="!currentUser.IdSchool" [ngClass]="{ 'selected': state == 'editConfigSessions'}"
            (click)="editConfigSessions()" class="walinwa-tab-gray">{{'SCHOOL.TEACHER.CONFIG.SESSIONS-TITLE' | translate}}</div>
        <div [hidden]="!currentUser.IdSchool" [ngClass]="{ 'selected': state == 'editConfigDict', 'disabled-sidebar-button': !enableDictation}"
            (click)="enableDictation ? editConfigDict() : null" class="walinwa-tab-gray">{{'SCHOOL.TEACHER.CONFIG.DICTATIONS-TITLE' | translate}}</div>
        <div [hidden]="!currentUser.IdSchool"
            [ngClass]="{ 'selected': state == 'activities','disabled-sidebar-button': noActivitiesEnabled()}"
            (click)="activities()" class="walinwa-tab-gray">{{'SCHOOL.TEACHER.CONFIG.ACTIVITIES-TITLE' | translate}}</div>
        <div [hidden]="!currentUser.IdSchool" [ngClass]="{ 'selected': state == 'challenge'}" (click)="challenge()"
            class="walinwa-tab-gray">{{'SCHOOL.TEACHER.CONFIG.CHALLENGES-TITLE' | translate}}</div>
        <div [hidden]="!currentUser.IdSchool" [ngClass]="{ 'selected': state == 'games'}" (click)="editGames()"
            class="walinwa-tab-gray">{{'SCHOOL.TEACHER.CONFIG.GAMES-TITLE' | translate}}</div>
        <div [hidden]="!currentUser.IdSchool" [ngClass]="{ 'selected': state == 'zone'}" (click)="zoneConfig()"
            class="walinwa-tab-gray">{{'SCHOOL.TEACHER.CONFIG.SHOP-TITLE' | translate}}</div>
        <div [hidden]="!currentUser.IdSchool" [ngClass]="{ 'selected': state == 'options'}" (click)="options()"
            class="walinwa-tab-gray">{{'SCHOOL.TEACHER.CONFIG.OPTIONS-TITLE' | translate}}</div>
    </div>
    <div class="walinwa-box-green">
        <router-outlet></router-outlet>
    </div>
</div>