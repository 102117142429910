import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { Router, NavigationEnd } from '@angular/router';
import { SchoolService } from 'app/services/school.service';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { PaymentService } from 'app/services/payment.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-admin-promo',
  templateUrl: './admin-promo.component.html',
  styleUrls: ['./admin-promo.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminPromoComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
  navigationSubscription;

  confirmDialog: MatDialogRef<FuseDialogContinueComponent>;


  selected = false;
  school;
  validLicense;
  calling;

  course
  price

  constructor(
    private router: Router, 
    private schoolService: SchoolService, 
    private _matDialog: MatDialog,
    private _translateService: TranslateService,
    private paymentService: PaymentService) {
        this._unsubscribeAll = new Subject();
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.init();
      }
    });
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  init() {
    this.school = this.schoolService.school;
    this.validLicense = !(moment(this.school.ValidUntil) < moment());
    this.getSettings()
  }

  getSettings() {
    this.paymentService.getDataPromotor()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      (result: any) => {
        this.course = parseInt(result.promotor_curso)
        this.price = parseInt(result.promotor_precio)
      }
    )
  }

  volver() {
    this.router.navigateByUrl("/school/admin");
  }

  finish() {
    if (this.validLicense == true) {
      var message = [];
      message[0] = this._translateService.instant('SCHOOL.ADMIN.PROMO.MODAL-WARNING-TITLE');
      message[1] = this._translateService.instant('SCHOOL.ADMIN.PROMO.MODAL-WARNING-MESSAGE');
      let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
        disableClose: true
      });
      confirmDialog.componentInstance.title = message[0];
      confirmDialog.componentInstance.message1 = message[1];
      confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
      confirmDialog.componentInstance.margin = false;

      confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
        confirmDialog = null;
      });

      return;

    }
    if (this.selected != true) {
      var message = [];
      message[0] = this._translateService.instant('SCHOOL.ADMIN.PROMO.MODAL-WARNING-TITLE');
      message[1] = this._translateService.instant('SCHOOL.ADMIN.PROMO.MODAL-WARNING-MESSAGE2');
      let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
        disableClose: true
      });
      confirmDialog.componentInstance.title = message[0];
      confirmDialog.componentInstance.message1 = message[1];
      confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
      confirmDialog.componentInstance.margin = false;

      confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
        confirmDialog = null;
      });

      return;

    }

    var message = [];

    message[0] = this._translateService.instant('SCHOOL.ADMIN.PROMO.MODAL-CONTINUE-TITLE');
    message[1] = this._translateService.instant('SCHOOL.ADMIN.PROMO.MODAL-CONTINUE-MESSAGE');
    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    confirmDialog.componentInstance.message1 = message[1];
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
    confirmDialog.componentInstance.margin = false;
    confirmDialog.componentInstance.space = true;
    confirmDialog.componentInstance.options = [
        {
            text: this._translateService.instant('ACCEPT'),
            callback: () => {
                if (this.calling) {
                    return;
                }
                this.calling = true;
                this.schoolService.schoolPromo(this.school.IdSchool)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(this.succesUpdateSchool.bind(this), this.errorCallback.bind(this));
            }
        }, {
            text: this._translateService.instant('CANCEL'),
            callback: () => {}
        }
    ]
    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      confirmDialog = null;
    });
  }

  succesUpdateSchool(response) {

    this.calling = false;
    var message = [];
    message[0] = "";
    message[1] = this._translateService.instant('SCHOOL.ADMIN.PROMO.MODAL-SUCCES-SAVE-MESSAGE');
    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    confirmDialog.componentInstance.message1 = message[1];
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
    confirmDialog.componentInstance.margin = false;

    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      this.volver();
      confirmDialog = null;
    });
  }

  errorCallback(response) {
    this.calling = false;
    var message = [];
    message[0] = this._translateService.instant('ERROR');
    message[1] = this._translateService.instant('SCHOOL.MODAL-ERROR-MESSAGE1');
    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    confirmDialog.componentInstance.message1 = message[1];
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
    confirmDialog.componentInstance.margin = false;

    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      confirmDialog = null;
    });
  }

  formatDate(date) {
    return moment(date).format("DD/MM/YYYY");
  }

}
