<div class="walinwa-box-with-tabs" *ngIf="dataLoaded">
    
    <!-- walinwa tabs -->
    <div class="walinwa-tabs walinwa-tabs-top">
        <div class="walinwa-tab-gray" [ngClass]="{ 'selected': viewReto }" (click)="showMiReto()">{{'STUDENT.CHALLENGE.CURRENT-CHALLENGE' | translate }}</div>
        <div class="walinwa-tab-gray" [ngClass]="{ 'selected': viewListRetos }" (click)="showListRetos()">{{'STUDENT.CHALLENGE.MY-CHALLENGES' | translate }}</div>
    </div>

    <!-- walinwa box - reto actual -->
    <div [hidden]="!viewReto" class="walinwa-box walinwa-box-orange">
        
        <!-- walinwa box content - crear reto -->
        <div class="walinwa-box-content walinwa-box-content-challenge" *ngIf="!currentReto">
            <div fxLayout="column" fxLayoutAlign="stretch">
                <div class="walinwa-challenge-tabs">
                    <div class="walinwa-challenge-tab" *ngFor="let clase of Classes; let i = index" [ngClass]="{'active' : viewCurso[i] == true}" (click)="showCurso(i)">
                        {{ clase.Name }}
                    </div>
                </div>
                <div class="walinwa-challenge-tab-container" *ngFor="let clase of Classes; let i = index" [hidden]="!viewCurso[i]" fxLayout="row wrap" fxLayoutAlign="space-between start">
                    <mat-checkbox class="challenge-checkbox" style="height: 4.5rem;" *ngFor="let alumno of clase.Students | sortBy:'asc':['Alias']; let i = index" [(ngModel)]="alumno.selected" fxFlex="0 0 calc(50% - 1rem)" fxFlex.xs="100%">
                        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                            <div class="mini_avatar" fxFlex="3rem">
                                <avatar-file *ngIf="alumno.activeItems && alumno.activeItems.length > 0" [activeItems]=alumno.activeItems
                                    style="cursor: pointer;"></avatar-file>
                                <img *ngIf="!alumno.activeItems || alumno.activeItems.length == 0" 
                                    src="https://walinwa.blob.core.windows.net/images/user_ico-orange.svg?ngsw-bypass=true" class="default" />
                            </div>
                            <div fxFlex="">
                                {{ alumno.Alias }} ({{alumno.UserName}})
                            </div>
                        </div>
                    </mat-checkbox>
                </div>
                <div class="spe_gap" style="margin: 2rem 0;" fxLayout="row wrap" fxLayoutAlign="space-between center">

                    <div class="" fxFlex="" fxFlex.xs="100%">
                        <label class="font_blue">{{'STUDENT.CHALLENGE.CHALLENGE-THEME-LABEL' | translate }}</label>
                        <div class="ww_field_spe">
                            <select [(ngModel)]="tema">
                                <option [ngValue]="item" *ngFor="let item of temas">{{ item.Description }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="gameStartDiv" [hidden]="!timeBlock" fxFlex="100%">
                        <span>{{'STUDENT.CHALLENGE.DEACTIVATED-HOURS' | translate:{startTime: blockTime?.RetoIni?.substr(0,5), endTime: blockTime?.RetoEnd?.substr(0,5)} }}</span>
                    </div>
                    <div class="spe_gap" fxFlex="" fxFlex.xs="100%" fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center">
                        <button [hidden]="timeBlock" (click)="crear()" class="new_but azul">
                            {{'STUDENT.CHALLENGE.CHALLENGE-SOMEONE-BUTTON' | translate }}
                        </button>
                    </div>
                </div>
                <div *ngIf="slides">
                    <div class="boxSlider" id="">
                        <span class="itemslide" *ngFor="let slide of slides; let i = index" [innerHtml]="slide.desc"></span>
                    </div>
                </div>
            </div>

        </div>

        <!-- walinwa box content - existe reto -->
        <div class="walinwa-box-content walinwa-box-content-challenge" *ngIf="currentReto">

            <div class="walinwa-challenge-pending-title">
                <span *ngIf="currentReto.IdUser === currentUser.Id">{{'STUDENT.CHALLENGE.NEW-CHALLENGE-CREATED' | translate }}</span>
                <span *ngIf="currentReto.IdUser !== currentUser.Id">{{'STUDENT.CHALLENGE.NEW-CHALLENGE-INVITATION' | translate }}</span>
            </div>

            <div>
                <h2>
                    {{'STUDENT.CHALLENGE.CHALLENGE-THEME-TELL-TEXT' | translate }}<span class="temaReto" [innerHtml]="getTema(currentReto.Tema).Description"></span>
                </h2>
            </div>

            <div *ngIf="currentReto.IdUser !== currentUser.Id">
                <h2>
                    {{'STUDENT.CHALLENGE.CHALLENGE-CREATOR' | translate }} {{ creator.Alias }} ({{ creator.UserName }})
                </h2>
            </div>

            <div class="ww_table_rwd">
                <table class="walinwa-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{{'STUDENT.CHALLENGE.OPPONENT-TABLE-HEADER' | translate }}</th>
                            <th>{{'STUDENT.CHALLENGE.STATE-TABLE-HEADER' | translate }}</th>
                            <!-- <th>Puntuación</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let invitacion of currentReto.Invitaciones">
                                <td style="width: 5%; border: none;">
                                    <div class="mini_avatar" fxFlex="3rem">
                                        <avatar-file *ngIf="invitacion.Guest && invitacion.Guest.activeItems && invitacion.Guest.activeItems?.length > 0 ; else image"
                                            [activeItems]=invitacion.Guest.activeItems style="cursor: pointer;"></avatar-file>
                                            <ng-template #image>
                                                <img src="https://walinwa.blob.core.windows.net/images/user_ico-orange.svg?ngsw-bypass=true" class="default" />
                                            </ng-template>
                                        
                                    </div>
                                </td>
                            
                            <td>{{ invitacion.Guest.Alias }}</td>
                            <td>{{ invitacion.Status }}</td>
                            <!-- <td>
                                <span [hidden]="invitacion.IdGuest !== currentUser.Id && todayRetoCompleted === false">
                                    {{ invitacion.Score }}
                                </span>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="spe_gap" fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
                <button (click)="jugar(currentReto)" [hidden]="!aceptar" *ngIf="currentReto.IdUser === currentUser.Id" class="new_but azul">
                    {{'START' | translate }}
                </button>

                <button (click)="jugar(currentReto)" [disabled]="calling == true" [hidden]="!aceptar" *ngIf="currentReto.IdUser !== currentUser.Id" class="new_but azul" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                    <img src="https://walinwa.blob.core.windows.net/images/walistar.png?ngsw-bypass=true" fxFlex="1.3rem" />
                    <span>{{'STUDENT.CHALLENGE.ACCEPT-PRICE-BUTTON' | translate }}</span>
                </button>

                <button (click)="jugar(currentReto)" [hidden]="!comenzar" class="new_but azul">
                    {{'CONTINUE' | translate }}
                </button>
                
                <button (click)="rechazarReto(currentReto.IdReto)" *ngIf="currentReto.IdUser !== currentUser.Id" [hidden]="!rechazar" class="new_but azul">
                    {{'STUDENT.CHALLENGE.DECLINE-BUTTON' | translate }}
                </button>

                <button (click)="rechazarReto(currentReto.IdReto)" *ngIf="currentReto.IdUser == currentUser.Id" [hidden]="!borrar" class="new_but azul">
                    {{'STUDENT.CHALLENGE.DELETE-BUTTON' | translate }}
                </button>

            </div>
            <div *ngIf="slides">
                <div class="boxSlider" id="">
                    <span class="itemslide" *ngFor="let slide of slides; let i = index" [innerHtml]="slide.desc"></span>
                </div>
            </div>

        </div>

    </div>
    
    <!-- walinwa-box - mis retos -->
    <div [hidden]="!viewListRetos" class="walinwa-box walinwa-box-orange" *ngIf="dataLoaded">
        <div class="walinwa-box-content no_padding_tables">
            <div class="ww_table_rwd " *ngIf="dataLoaded">
                <table class="walinwa-table two">
                    <thead>
                        <tr>
                            <th>{{'STUDENT.CHALLENGE.LIST-HEADER.DATE' | translate }}</th>
                            <th>{{'STUDENT.CHALLENGE.LIST-HEADER.THEME' | translate }}</th>
                            <th>{{'STUDENT.CHALLENGE.LIST-HEADER.STATE' | translate }}</th>
                            <th>{{'STUDENT.CHALLENGE.LIST-HEADER.POINTS' | translate }}</th>
                            <th>{{'STUDENT.CHALLENGE.LIST-HEADER.POSITION' | translate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let reto of retos; let i = index">
                            <td>{{ reto.fecha | ToLocalTime }}</td>
                            <td>{{ getTema(reto.Tema).Description }}</td>
                            <td>{{ reto.Status }}</td>
                            <td>{{ reto.Status == 'Finalizado' ? reto.Score : null}}</td>
                            <td>{{ reto.Position != 0 ? reto.Position : null }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</div>
<img [hidden]="!loadingReto" style="width: 10em; position: fixed;top: 0;left: 0;bottom: 0;right: 0;margin: auto;" src="https://walinwa.blob.core.windows.net/images/loading.gif?ngsw-bypass=true">