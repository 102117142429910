import { UiService } from '@fuse/components/spinner/ui.service';
import { MemoImageExcer } from './../../../core/shared/state/models/memo-image-excer.model';
import { MemoImageInfo } from './../../../core/shared/state/models/memo-image-info.model';
import { UserService } from './../../../services/user.service';
import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { NavigationService } from 'app/services/navigation.service';
import { VisualMemoryService } from 'app/services/visual-memory.service';
import { MemoImage } from 'app/core/shared/state/models/memo-image.model';
import { MemoImageCaracterExercise, MemoImageExercise } from 'app/core/shared/state/models/memo-image-exercise.model';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { FileService } from 'app/services/file.service';

@Component({
  selector: 'app-visual-memory',
  templateUrl: './visual-memory.component.html',
  styleUrls: ['./visual-memory.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VisualMemoryComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  historyPoint: string = '0'
  currentUser;
  currentPorcent: number = 0;
  dataMemo: MemoImageExercise[];
  caracteres: any[] = []
  eventsSubject: Subject<object> = new Subject<object>();
  entries = 0
  reviewDataMemo: MemoImageExcer[];
  imageNames: string[] = []
  
  constructor(
    private _visualMemory:VisualMemoryService,
    private authService : AuthService,
    private _ui : UiService,
    private fileService: FileService,
    private _detector: ChangeDetectorRef
  ) {
    this._unsubscribeAll = new Subject();
    this.currentUser = this.authService.currentUser;
    this._visualMemory.getData()
    this._ui.spin$.next(true);
    
  }

    ngOnInit() {
        this._visualMemory.has_finished.pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
            if (result) {
                this.getReviewData()
            }
            else {
                this.configuracionInicial();
                this.getCurrentPorcent();
            }
        })
  }

  ngOnDestroy(): void {
    this._visualMemory.resetTimer();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  configuracionInicial(getTime = true){
    this._ui.showSpinner()
    this._visualMemory.getImages(this.currentUser.Id, this.currentUser.Level)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe( (result: MemoImage[]) => {
      this.dataMemo = [];
      result.forEach(x => {
        const caracteres: MemoImageCaracterExercise[] = [];
        let decodedName = this.decode(x.Name)
        decodedName.split('').forEach(caract => {
          caracteres.push({ caracter: caract, titila:false });
        })
        this.dataMemo.push({
          caracteres: caracteres,
          finished: null,
          name: decodedName,
          IdMemo: x.IdMemo,
          sonIguales: null
        });
        this.imageNames.push(decodedName + ".jpg")
      });
      this._visualMemory.step = 1;
      this._visualMemory.validated = false;
      if(getTime){
        this._visualMemory.getTimer(this.currentUser.Level)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe((result : MemoImageInfo[]) =>{
          this._visualMemory.pageTimes = result;
          this._visualMemory.startTimer();
          this._detector.detectChanges();
        })
      }
      else {
          this._visualMemory.startTimer()
      }
      this._ui.stopSpinner()
    });
  }
  getReviewData(){
    this._visualMemory.getReviewImages(this.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe((result : MemoImageExcer[]) =>{
        this.reviewDataMemo = result;
        this.imageNames = []
        this.reviewDataMemo.forEach(x => {
            x.MemoImage.Name = this.decode(x.MemoImage.Name);
            this.imageNames.push(x.MemoImage.Name + ".jpg");
        })
    });
  }
  // Eventos @Ouput()
    PageOk(event){
      if(event) {
        this.configuracionInicial(false)
      }
    }
  // Funcion para calcular el valor en porcentaje del timer
  getCurrentPorcent(): number {
    let currentSeconds = this._visualMemory.getCurrentSeconds();
    // let currentSeconds = 100000; // Leo
    let fullSeconds = this._visualMemory.getFullSeconds();
    // let fullSeconds = 100000; //Leo
    if (!currentSeconds || !fullSeconds) return 0;
    return (currentSeconds * 47) / (fullSeconds) ;
  }
  decode(str) {
    return decodeURIComponent(escape(window.atob(str)))
  }
}
