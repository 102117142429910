import { Component, Input, OnInit, OnDestroy, HostListener, ViewEncapsulation } from '@angular/core';
import { ExerciseService } from 'app/services/exercise.service';

@Component({
  selector: 'ex-opc1',
  templateUrl: './opc1.component.html',
  styleUrls: ['./opc1.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Opc1Component implements OnInit, OnDestroy {

  @Input() review
  @Input() show_errors;
  @Input() controlSession : boolean = false;
  @Input() slide : boolean = false; @Input() current;
  exercise
  draggingOption
  exerciseIsEnded = false
  finished = false
  opciones = []
  fraseCorrecta
  error
  fraseIni

  elementsOk
  elementsErr

    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case "SHOW_SOLUTION":
                this.finished = true;
                if (this.exerciseService.showingSolution) {
                    setTimeout(this.showAnswersTimeOut.bind(this), 100);
                    break;
                } else {
                    setTimeout(this.showSolution.bind(this), 100);
                    break;
                }
            case "SHOW_ANSWER":
                this.ShowAnswer();
                break;
            case "SHOW_CUSTOM":
                this.error = null;
                this.exercise = this.exerciseService.getExerciseDefinition();
                break;
        }
    }
  
  constructor(private exerciseService: ExerciseService) {
    this.exercise = this.exerciseService.getExerciseDefinition();
    this.FillExercise()
  }
    ngOnDestroy() {
    }

  ngOnInit() {
    if (this.review) {
      this.elementsOk = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsOK
      );
      this.elementsErr = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsERR
      );
      if (this.show_errors == false) {
        this.showSolution();
      } else if (this.show_errors == true) {
        this.ShowAnswer();
      }
    } else {
      this.initInteract();
    }
  }

  isMayus(letra) {
    return letra == letra.toUpperCase();
  }

  getCleanedString(cadena) {
    var specialChars = "!@#$^&%*()+=-[]/{}|:<>?,.";
    for (var i = 0; i < specialChars.length; i++) {
      cadena = cadena.replace(new RegExp("\\" + specialChars[i], "gi"), "");
    }
    cadena = cadena.toLowerCase();
    cadena = cadena.replace(/ /g, "_");
    cadena = cadena.replace(/á/gi, "a");
    cadena = cadena.replace(/é/gi, "e");
    cadena = cadena.replace(/í/gi, "i");
    cadena = cadena.replace(/ó/gi, "o");
    cadena = cadena.replace(/ú/gi, "u");
    cadena = cadena.replace(/ñ/gi, "n");
    return cadena;
  }

  bola = function () {
    for (var i = 0; i < this.opciones.length; i++) {
      if (this.getCleanedString(this.opciones[i]).length > 2) {
        return false;
      }
    }
    return true;
  }

  showSolution() {
    this.error = 0;
    this.exercise.frase = this.fraseCorrecta;
  }

  ShowAnswer() {
    if (this.elementsErr.length > 0) {
      this.error = 1;
      this.exercise.frase = this.fraseIni;
    }
    else {
      this.error = 0;
      this.exercise.frase = this.fraseCorrecta;
    }
  }

  initInteract() {
    this.exerciseService.initInteract({
      onmove: (event) => {
        this.draggingOption = event.target
      },
      drop: (event) => {

        let element = this.draggingOption
        let bin = event.target

        if (this.finished == true) return

        var index = parseFloat(element.getAttribute("optionIndex"));

        var ok = this.exercise.bien == this.opciones[index];

        if (ok) {
          this.error = 0;
          this.exercise.frase = this.fraseCorrecta;
          this.exerciseService.answer(1, true, 1);
          this.finished = true;
          this.opciones = [];
          // $scope.$emit('timer-stop');
          // $scope.$emit('exercise-solved');
        } else {
          this.error = 1;
          this.exerciseService.answer(1, false, 1);
        }

      },
    });
  }

  showAnswersTimeOut() {
    // if (this.error == null) {
      this.exercise.frase = this.fraseCorrecta;
      this.error = 2;
    // }
  }

  FillExercise() {
    this.opciones = this.shuffle([this.exercise.bien, this.exercise.mal]);
    var trozos = this.exercise.frase.split('_');
    this.fraseCorrecta = trozos[0] + this.exercise.bien + trozos[1];
    this.fraseIni = this.exercise.frase;
  }

  shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

}
