import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'app/core/shared/shared.service';
import { AuthService } from 'app/services/auth.service';
import { ExtraRewardService } from 'app/services/extra-reward.service';
import { MedalService } from 'app/services/medal.service';
import { NavigationService } from 'app/services/navigation.service';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RewardsComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
  meses = [
    this._translateService.instant('MONTHS.JANUARY'),
    this._translateService.instant('MONTHS.FEBRUARY'),
    this._translateService.instant('MONTHS.MARCH'),
    this._translateService.instant('MONTHS.APRIL'),
    this._translateService.instant('MONTHS.MAY'),
    this._translateService.instant('MONTHS.JUNE'),
    this._translateService.instant('MONTHS.JULY'),
    this._translateService.instant('MONTHS.AUGUST'),
    this._translateService.instant('MONTHS.SEPTEMBER'),
    this._translateService.instant('MONTHS.OCTOBER'),
    this._translateService.instant('MONTHS.NOVEMBER'),
    this._translateService.instant('MONTHS.DECEMBER')
  ];
  dataLoaded = false;
  tab = 1;
  perseveranceItems = [];
  userMedals
  baseDirectory = "Medals/";
  constructor(
    private authService: AuthService,
    private navigationService: NavigationService,
    private extraRewardService: ExtraRewardService,
    private medalService: MedalService,
    public sharedService: SharedService,
    private _translateService: TranslateService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnInit() {
    if (!this.authService.check()) return;
    this.medalService.getUserMedals(this.authService.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.okUserMedalsCallback(result);
      },
      error => {
        this.navigationService.goLogin();
      }
    )
  }

  setTab(tab) {
    this.tab = tab;
  }
    getMedalBackground(medalTheme: string) {
        let imgName = ""
        switch(this.getMedalStatus(medalTheme)){
            case "noMedal":
                imgName = this.getImgUrl("noMedal.png")
                break;
            case "moTrophy":
                imgName = this.getImgUrl("noTrofeo.png")
                break;
            case "masterMedal":
                imgName = this.getImgUrl("trofeo.gif")
                break;
            case "medalBronze":
                imgName = this.getImgUrl("bronce.gif")
                break;
            case "medalSilver":
                imgName = this.getImgUrl("plata.gif")
                break;
            case "medalGold":
                imgName = this.getImgUrl("oro.gif")
                break;
            case "medalPlatinum":
                imgName = this.getImgUrl("platino.gif")
                break;
            case "medalBronzeSpin":
                imgName = this.getImgUrl("bronceSpin.gif")
                break;
            case "medalSilverSpin":
                imgName = this.getImgUrl("plataSpin.gif")
                break;
            case "medalGoldSpin":
                imgName = this.getImgUrl("oroSpin.gif")
                break;
            case "medalPlatinumSpin":
                imgName = this.getImgUrl("platinoSpin.gif")
                break;
            default:
                imgName = this.getImgUrl("noTrofeo.png")
                break;
        }
        //return this.sharedService_.getImgBackgroundUrl(imgName);
        return "url('https://walinwa.blob.core.windows.net/images/" + imgName + "')";
    }

  getMedalStatus(medalTheme) {
    let medal = null;
    if (!this.userMedals) {
      if (medalTheme.includes('MASTER')) return "moTrophy";
      return "noMedal";
    }
    for (let i = 0; i < this.userMedals.length; i++) {
      if (this.userMedals[i].IdMedal == medalTheme) medal = this.userMedals[i];
    }
    if (!medal) {
      if (medalTheme.includes('MASTER')) return "moTrophy";
      return "noMedal";
    }
    if (medalTheme.includes('MASTER')) return "masterMedal";
    if (medal.Step == 0) return "noMedal";
    if (medal.Step == 1 && medal.LastMedal == false) return "medalBronze";
    if (medal.Step == 2 && medal.LastMedal == false) return "medalSilver";
    if (medal.Step == 3 && medal.LastMedal == false) return "medalGold";
    if (medal.Step == 4 && medal.LastMedal == false) return "medalPlatinum";
    if (medal.Step == 1 && medal.LastMedal == true) return "medalBronzeSpin";
    if (medal.Step == 2 && medal.LastMedal == true) return "medalSilverSpin";
    if (medal.Step == 3 && medal.LastMedal == true) return "medalGoldSpin";
    if (medal.Step == 4 && medal.LastMedal == true) return "medalPlatinumSpin";
  }

  getMedalTextClass(medalTheme) {
    let medal = null;
    if (!this.userMedals) return;
    for (let i = 0; i < this.userMedals.length; i++) {
      if (this.userMedals[i].IdMedal == medalTheme) {
        medal = this.userMedals[i];
      }
    }
    if (!medal) return;
    if (medal.Step == 0) return;
    if (medal.Step == 1) {
      if (medalTheme.includes('MASTER')) return "medalTextMaster";
      return "medalTextBronze";
    }
    if (medal.Step == 2) return "medalTextSilver";
    if (medal.Step == 3) return "medalTextGold";
    if (medal.Step == 4) return "medalTextPlatinum";
  }

  getMedalTextMasterStatus(medalTheme) {
    let medal = null;
    if (!this.userMedals) {
      if (medalTheme.includes('MASTER')) return "";
      return "element-hidden";

    }
    for (let i = 0; i < this.userMedals.length; i++) {
      if (this.userMedals[i].IdMedal == medalTheme) medal = this.userMedals[i];
    }
    if (!medal) {
      if (medalTheme.includes('MASTER')) return "";
      return "element-hidden";
    }
    return "";
  }

  getMedalTextMaster(medalTheme) {
    if (medalTheme == 'MASTERP') return this._translateService.instant('STUDENT.REWARDS.MASTERP');
    if (medalTheme == 'MASTERS') return this._translateService.instant('STUDENT.REWARDS.MASTERS');
    if (medalTheme == 'MASTERT') return this._translateService.instant('STUDENT.REWARDS.MASTERT');
    if (medalTheme == 'MASTERO') return this._translateService.instant('STUDENT.REWARDS.MASTERO');
  }

  okUserMedalsCallback(result) {
    this.userMedals = result;
    this.extraRewardService.getLastYearPerseverance(this.authService.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      (result: any) => {
        this.perseveranceItems = result;
        for (var i = 0; i < this.perseveranceItems.length; i++) {
          var rewardType = this.perseveranceItems[i].RewardType;
          var split = rewardType.split("_");
          this.perseveranceItems[i].year = split[1];
          this.perseveranceItems[i].month = this.meses[split[2] - 1];
        }
        this.dataLoaded = true;
      },
      error => {
        this.navigationService.goLogin();
      }
    )
  }
  getImgUrl(imgName: string): string{
    return this.baseDirectory + imgName;
  }

}
