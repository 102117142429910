import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { EmailConfigService } from 'app/services/email-config.service';
import { NavigationService } from 'app/services/navigation.service';
import { SchoolService } from 'app/services/school.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: "app-config",
    templateUrl: "./config.component.html",
    styleUrls: ["./config.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ConfigComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

	currentUser;
	dataLoaded = false;
	states = [];
	state;
    enableReading: any;
    enableDeduction: any;
    enableDictation: any;

	constructor(
		private router: Router,
		private emailConfigService: EmailConfigService,
		private navigationService: NavigationService,
		private schoolService: SchoolService
	) { 
        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

	ngOnInit(): void {
		if (!sessionStorage.getItem("currentUser")) return this.navigationService.exit();
		this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
		if (!this.currentUser) return this.navigationService.exit();
		this.enableReading = this.schoolService.school.ReadingText;
        this.enableDeduction = this.schoolService.school.DeductionText;
        this.enableDictation = this.schoolService.school.Dictation;
        this.navigationService.enableExitButton();
        this.editMailConfig();
    }

    noActivitiesEnabled() {
        if (!this.enableDeduction && !this.enableDictation && !this.enableReading) {
            return true;
        }
        return false;
    }
    back() {
        if (this.states.length > 1) {
            var last = this.states[this.states.length - 2];
            this.states.splice(this.states.length - 1, 1);
            this[last]();
            this.states.splice(this.states.length - 1, 1);
        } else {
            this.router.navigateByUrl("school/students");
        }
    }

    editMailConfig() {
        this.state = "mail";
        this.states.push("editMailConfig");
        if (this.router.url == "/school/config") {
            this.router.navigateByUrl(this.router.url + "/mail");
        } else {
            this.router.navigateByUrl("/school/config/mail");
        }
    }

    editConfigSessions() {
        this.state = "editConfigSessions";
        this.states.push("editConfigSessions");
        if (this.router.url == "/school/config") {
            this.router.navigateByUrl(this.router.url + "/editConfigSessions");
        } else {
            this.router.navigateByUrl("/school/config/editConfigSessions");
        }
    }

    editConfigDict() {
        if(!this.enableDictation){
            return
        }
        this.state = "editConfigDict";
        this.states.push("editConfigDict");
        if (this.router.url == "/school/config") {
            this.router.navigateByUrl(this.router.url + "/dictations");
        } else {
            this.router.navigateByUrl("/school/config/dictations");
        }
    }
    editGames() {
        this.state = "games";
        this.states.push("editGames");
        if (this.router.url == "/school/config") {
            this.router.navigateByUrl(this.router.url + "/games");
        } else {
            this.router.navigateByUrl("/school/config/games");
        }
    }
    zoneConfig(){
        this.state = "zone";
        this.states.push("editZoneConfig");
        if (this.router.url == "/school/zone") {
            this.router.navigateByUrl(this.router.url + "/zone");
        } else {
            this.router.navigateByUrl("/school/config/zone");
        }
    }
    options() {
        this.state = "options";
        this.states.push("options");
        if (this.router.url == "/school/config") {
            this.router.navigateByUrl(this.router.url + "/options");
        } else {
            this.router.navigateByUrl("/school/config/options");
        }
    }

    challenge() {
        this.state = "challenge";
        this.states.push("challenge");
        if (this.router.url == "/school/config") {
            this.router.navigateByUrl(this.router.url + "/challenge");
        } else {
            this.router.navigateByUrl("/school/config/challenge");
        }
    }

    activities() {
        if (this.noActivitiesEnabled()) return;
        this.state = "activities";
        this.states.push("activities");
        if (this.router.url == "/school/config") {
            this.router.navigateByUrl(this.router.url + "/activities");
        } else {
            this.router.navigateByUrl("/school/config/activities");
        }
    }

    errorCallback() {
        this.navigationService.exit();
    }
}
