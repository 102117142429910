export const environment = {
    production: false,
    hmr: false,
    baseApi:'https://walinwa-api-dev.azurewebsites.net',
    url:'http://www.walinwa.com',
    version:'?v=6.4.23',
    fuseConfig: "vertical-layout-1",
    defaultPassword: "12345678",
    local: false,
    gtag: "G-8S8L0CKZBK",
    filesUrl: 'https://walinwadevfr.blob.core.windows.net',
    appCheckForUpdateInterval: 7200000, // 2 horas
    appCountdownTimeToUpdate: 600000 // 10 minutos
  };

  