

@if (game_url) {
    <iframe [src]="game_url" class="game" [attr.params]="params"></iframe>
} @else {
    <div class="main spe_gap" fxLayout="column" fxLayoutAlign="stretch space-between">
        @for (game of games; track $index) {
            <button (click)="game.subGames ? showSubGames(game.folder) : goToGame(game.folder)" style="cursor: pointer;" class="new_but azul" [disabled]="!game.enabled">{{ game.name }}</button>

            @if (game.subGames) {
                <div class="ww_c_popup_back" [hidden]="subGamesVisible !== game.folder">
                    <div class="ww_c_popup large" style="max-height: 100vh !important;">
                        <div class="head spe_gap" fxLayout="row" fxLayoutAlign="space-between center">
                            <div>
                                <span>{{'STUDENT.MINIGAMES.SUBGAME-SELECT-TEXT' | translate }} {{ game.name }}</span>
                            </div>
                            <div>
                                <img class="pointer" fxFlex="2rem" src="https://walinwa.blob.core.windows.net/images/ico-close-popup.svg?ngsw-bypass=true" (click)="subGamesVisible = null;"/>
                            </div>
                        </div>
                        <div class="cont">
                            <label class="font_blue">{{'STUDENT.MINIGAMES.SUBGAME-LABEL' | translate }}</label>
                            <mat-form-field class="less_top_margin_field" style="width: 400px;">
                                <mat-select [(ngModel)]="subGame" class="waliFacturasSelect">
                                    @for (subGame of subGames; track $index) {
                                        <mat-option class="scrollable" [value]="subGame">{{ subGame }}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                            <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutAlign.xs="center center" class="spe_gap">
                                <button type="button" (click)="goToGame(game.folder, subGame)" [disabled]="!subGame" class="new_but azul">
                                    {{'STUDENT.MINIGAMES.PLAY-BUTTON' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        }
    </div>
}