import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ExerciseSolvedEnum } from "app/core/shared/enums/exercise-solved.enum";
import { SharedService } from "app/core/shared/shared.service";
import { ExerciseService } from "app/services/exercise.service";

@Component({
    selector: "ex-parejas",
    templateUrl: "./parejas.component.html",
    styleUrls: ["./parejas.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ParejasComponent implements OnInit, OnDestroy {
    @Input() review;
    @Input() show_errors;
    @Input() controlSession : boolean = false;
    @Input() slide : boolean = false; @Input() current;
    currentUser;
    dataLoaded;
    exercise;
    duplicatedWords = [];
    finished;
    started = false;
    selected;
    numero_random = Math.floor(Math.random() * (10000 - 0 + 1) + 0)

    elementsOk;
    elementsErr;

    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_SOLUTION]:
                setTimeout(this.showSolution.bind(this), 100);
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_ANSWER]:
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_CUSTOM]:
                setTimeout(this.ShowAnswer.bind(this), 100);
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.TIMER_END]:
                this.finished = true;
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.EXERCISE_END]:
                this.finished = true;
                break;
        }
    }
    
    constructor(private exerciseService: ExerciseService, public sharedService : SharedService) {
        this.exercise = this.exerciseService.getExerciseDefinition();
    }
    public get exercisesSolvedEnum(): typeof ExerciseSolvedEnum{
        return this.exerciseSolvedEnum
    }
    
    public get exerciseSolvedEnum(): typeof ExerciseSolvedEnum{
        return ExerciseSolvedEnum
    }
    ngOnDestroy(){
    }

    ngOnInit() {
        this.initExercise();
            
    }
    cleanExercise(){
        this.started = false;
        
        this.duplicatedWords.forEach(valor => {
            valor.answered = null;
        });
    }
    initExercise(){
        for (var i = 0; i < this.exercise.palabras.length; i++) {
            var foundEmptyA = false;
            var indexA = this.getRandomInt(
                0,
                this.exercise.palabras.length * 2 - 1
            );
            var foundEmptyB = false;
            var indexB = this.getRandomInt(
                0,
                this.exercise.palabras.length * 2 - 1
            );
            while (foundEmptyA != true) {
                if (this.duplicatedWords[indexA] == null) {
                    foundEmptyA = true;
                    this.duplicatedWords[indexA] = true; //tmpValue
                } else {
                    indexA++;
                    if (indexA >= this.exercise.palabras.length * 2) indexA = 0;
                }
            }
            while (foundEmptyB != true) {
                if (this.duplicatedWords[indexB] == null) {
                    foundEmptyB = true;
                    this.duplicatedWords[indexB] = true; //tmpValue
                } else {
                    indexB++;
                    if (indexB >= this.exercise.palabras.length * 2) indexB = 0;
                }
            }
            this.duplicatedWords[indexB] = {
                palabra: this.exercise.palabras[i].palabra1,
                originalIndex: i,
                relatedIndex: indexA,
                selected: false,
                answered: null,
            };
            this.duplicatedWords[indexA] = {
                palabra: this.exercise.palabras[i].palabra2,
                originalIndex: i,
                relatedIndex: indexB,
                selected: false,
                answered: null,
            };
        
            if (this.review) {
                this.elementsOk = JSON.parse(
                    this.exerciseService.getCurrentExercise().ElementsOK
                );
                this.elementsErr = JSON.parse(
                    this.exerciseService.getCurrentExercise().ElementsERR
                );
                if (this.show_errors == false) {
                    this.showSolution();
                } else if (this.show_errors == true || this.show_errors == null) {
                    this.ShowAnswer();
                }
            }
        }
        var timeHide = 15000;
        if (this.exercise.palabras.length <= 4)
            // tiempo (Seg) * 1000 / 10 (10%)
            timeHide = (this.exercise.tiempo * 1000) / 10;
        else if (this.exercise.palabras.length <= 6)
            // tiempo (Seg) * 1000 / 15 (15%)
            timeHide = (this.exercise.tiempo * 1000) / 15;
        else timeHide = (this.exercise.tiempo * 1000) / 20;
        if (timeHide > 15000) timeHide = 15000;

        setTimeout(() => {
            this.started = true;
        }, timeHide);
    }
    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    ShowAnswersTimeOut() {
        for (let i = 0; i < this.duplicatedWords.length; i++) {
            this.duplicatedWords[i].answered = true;
        }
    }

    validateClick(index) {
        if (
            this.duplicatedWords[index].answered == true ||
            this.finished == true ||
            this.started == false
        )
            return;
        if (this.selected == null) {
            for (let i = 0; i < this.duplicatedWords.length; i++) {
                this.duplicatedWords[i].selected = false;
                if (this.duplicatedWords[i].answered == false)
                    this.duplicatedWords[i].answered = null;
            }
            this.duplicatedWords[index].selected = true;
            this.selected = index;
        } else {
            this.duplicatedWords[index].selected = true;
            if (this.selected == this.duplicatedWords[index].relatedIndex) {
                this.exerciseService.answer(
                    parseFloat(this.duplicatedWords[index].originalIndex),
                    true,
                    this.duplicatedWords.length / 2
                );
                this.duplicatedWords[index].answered = true;
                this.duplicatedWords[this.selected].answered = true;
            } else {
                this.exerciseService.answer(
                    parseFloat(this.duplicatedWords[index].originalIndex),
                    false,
                    this.duplicatedWords.length / 2
                );
                this.duplicatedWords[index].answered = false;
                this.duplicatedWords[this.selected].answered = false;
            }
            this.selected = null;
            for (var i = 0; i < this.duplicatedWords.length; i++) {
                if (this.duplicatedWords[i].answered != true) {
                    return;
                }
            }
            this.finished = true;
        }
    }
    showSolution() {
        this.duplicatedWords.forEach((value, key) => {
            value.answered = true;
        });
    }
    ShowAnswer() {
        this.duplicatedWords.forEach((value, key) => {
            value.answered = null;
            for (var i = 0; i < this.elementsErr.length; i++) {
                if (value.originalIndex == this.elementsErr[i]) {
                    value.answered = false;
                }
            }
            for (var i = 0; i < this.elementsOk.length; i++) {
                if (
                    value.originalIndex == this.elementsOk[i] &&
                    value.answered != false
                ) {
                    value.answered = true;
                }
            }
        });
    }

    FillDictionary() {
        this.exerciseService.dictionaryWordList = [];
        for (var j = 0; j < this.exercise.palabras.length; j++) {
            if (
                this.exercise.palabras[j].rae1 == this.exercise.palabras[j].rae2
            )
                this.exerciseService.dictionaryWordList.push(
                    this.exercise.palabras[j].rae1
                );
            else {
                this.exerciseService.dictionaryWordList.push(
                    this.exercise.palabras[j].rae1
                );
                this.exerciseService.dictionaryWordList.push(
                    this.exercise.palabras[j].rae2
                );
            }
        }
    }
}
