<div class="main_container" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/esquina-inf-der.svg&quot;)'">
    <div>
    </div>
    <div class="box_login">
        <div class="bar_title spe_gap" fxLayout="row" fxLayoutAlign="space-between center">
            <div [innerHTML]="'AUTHENTICATION.LOGIN.TITLE-STUDENT' | translate">
            </div>
        </div>
        <div class="box_cont">
            <form class="cus_form" [formGroup]="loginFormStudent" novalidate>
                <section fxLayout="column" fxLayoutAlign="stretch" fxLayoutGap="0">
                    <div>
                        <label class="font_blue text-left">{{'AUTHENTICATION.LOGIN.USER' | translate}}</label>
                        <mat-form-field floatLabel="always" class="spe-form-field" fxFlexFill>
                            <input maxlength="70" matInput formControlName="userName">
                            <mat-error *ngIf="loginFormStudent.get('userName').hasError('required') && logStudent">
                                {{'FIELD-REQUIRED' | translate}}
                            </mat-error>
                            <mat-error *ngIf="!loginFormStudent.get('userName').hasError('required') && logStudent">
                                {{'FIELD-REQUIRED' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <label class="font_blue text-left">{{'PASSWORD' | translate}}</label>
                        <mat-form-field floatLabel="always" class="spe-form-field" fxFlexFill>
                            <input matInput [type]="showPassword2 ? 'text' : 'password'" formControlName="password">
                            <mat-icon class="show-password-icon" [svgIcon]="showPassword2 ? 'gafas_off' : 'gafas_on'"
                                matSuffix (click)="showPassword2 = !showPassword2">
                            </mat-icon>
                            <mat-error *ngIf="logStudent">
                                {{'FIELD-REQUIRED' | translate}}
                            </mat-error>
                            <mat-hint class="mat-error" *ngIf="error_student">
                                {{ error_student | translate}}
                            </mat-hint>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center"
                        fxLayoutAlign.xs="stretch" fxNgClass.gt-xs="spe_gap">
                        <div fxFlex="" fxNgClass.xs="text-center" class="div_hyperlink">
                            <a (click)="newPasswordStudent()" class="hyperlink">{{'AUTHENTICATION.LOGIN.FORGOT-PASSWORD' |
                                translate}}</a>
                        </div>
                        <div fxNgClass.xs="text-center" style="margin-top: 35px !important;">
                            <button mat-raised-button color="accent" class="submit-button fixed_button" aria-label="LOGIN"
                                [disabled]="loading" (click)="login()">
                                {{'AUTHENTICATION.LOGIN.LOGIN' | translate}}
                            </button>
                        </div>
                    </div>
                </section>
            </form>
        </div>
    </div>
</div>