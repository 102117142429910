import { CalendarReadingText } from 'app/core/shared/state/models/Reading-text/reading-text-month.model';
import { ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CalReadingTextComponent } from 'app/components/cal-reading-text/cal-reading-text.component';
import { NavigationService } from 'app/services/navigation.service';
import { ReadingTextService } from 'app/services/reading-text.service';
import { UserService } from 'app/services/user.service';
import * as moment from 'moment';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
declare var $;
@Component({
    selector: "app-reading",
    templateUrl: "./reading.component.html",
    styleUrls: ["./reading.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ReadingReviewComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  @ViewChild(CalReadingTextComponent) childReading: CalReadingTextComponent;
  selectedDayCL: any;
  currentMonthCL: any;
  year: any;
  readingTexts : CalendarReadingText[];
  six_weeks_reading
  student: any;
  showReviewCL: boolean;
  isAdmin: boolean;
  activeButton = false;


  constructor(
    private navigationService: NavigationService,
    private readingTextService: ReadingTextService,
    private ref: ChangeDetectorRef,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { 
    this._unsubscribeAll = new Subject();
}

ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

  ngOnInit() {
    if (this.navigationService.params.isAdmin) this.isAdmin = this.navigationService.params.isAdmin;
    this.selectedDayCL = moment();
    this.currentMonthCL = this.selectedDayCL.month() + 1;
    this.year = this.selectedDayCL.year();
    this.getUser(this.activatedRoute.params['value'].Id);
  }
   getUser(id) {
    this.userService.getUserById(id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        if (result == null) return this.navigationService.exit();
        this.student = result;
        this.getReadings();
      },
      this.errorCallback.bind(this)
    )
  }

  getReadings() {
    this.readingTexts = [];
    this.ref.detectChanges();
    this.readingTextService.getReadingTextByMonth(this.student?.Id, this.currentMonthCL, this.selectedDayCL.year(), this.student?.Level, this.student?.IdRole, this.student?.CreationDate)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        if (result == null) return this.navigationService.exit();
        this.readingTexts = result;
        this.readingTextService.MonthlyReadingTexts = result;
        
        this.childReading.refresh((weeks_count) => {
          this.six_weeks_reading = weeks_count >= 6
        })
      },
      this.errorCallback.bind(this)
    )
  }

    errorCallback() {
        this.navigationService.exit();
    }

    sixWeeks() {
        return $(".week").length - 1 >= 6;
    }

    selectedDayChangedCL(newDate) {
        this.selectedDayCL = newDate;
        this.showReviewCL = false;
        if (!this.readingTexts) return;
        if (this.currentMonthCL != this.selectedDayCL.month() + 1) {
            this.currentMonthCL = this.selectedDayCL.month() + 1;
            if (this.childReading)
                this.six_weeks_reading = this.childReading.weeks.length >= 6;
            this.getReadings();
        }

        let currentReadingText = this.readingTexts.find((r) =>
            moment(r.date).isSame(this.selectedDayCL)
        );
        
        if (!currentReadingText) return;
        if (currentReadingText.Score == null) return;
        if (currentReadingText.FinishDate)
            this.showReviewCL = true;
            this.activeButton = true;
    }

    startReview() {
        if (!this.readingTexts) return;
        let currentReadingText = this.readingTexts.find((r) =>
            moment(r.date).isSame(this.selectedDayCL)
        );
        if (!currentReadingText) return;
        this.navigationService.go(
            this.router.url + "/readingText/review",
            {
                isAdmin: this.isAdmin,
                IdUser: this.activatedRoute.params['value'].Id,
                IdClass: this.navigationService.params.IdClass,
                review: true,
                date: currentReadingText.date,
                selectedDay: this.selectedDayCL,
                IdReadingText: currentReadingText.IdReadingText
            }
        );
    }
}
