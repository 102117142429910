import { Directive, Input, ElementRef, Renderer2, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MediaObserver, MediaChange } from '@angular/flex-layout';

@Directive({
  selector: '[fxNgClass], [fxNgClass.xs], [fxNgClass.sm], [fxNgClass.md], [fxNgClass.lg], [fxNgClass.xl], [fxNgClass.lt-sm], [fxNgClass.lt-md], [fxNgClass.lt-lg], [fxNgClass.lt-xl], [fxNgClass.gt-xs], [fxNgClass.gt-sm], [fxNgClass.gt-md], [fxNgClass.gt-lg], [fxNgClass.gt-xl]'
})
export class FlexLayoutNgClassDirective implements OnInit, OnDestroy {
  private _subscription: Subscription | undefined;
  private _mediaAliases = [];  

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private mediaObserver: MediaObserver
  ) {}

  @Input() fxNgClass: string | string[] | Set<string> | { [className: string]: any };

  @Input('fxNgClass.xs') fxNgClassXs: string | string[] | Set<string> | { [className: string]: any };
  @Input('fxNgClass.sm') fxNgClassSm: string | string[] | Set<string> | { [className: string]: any };
  @Input('fxNgClass.md') fxNgClassMd: string | string[] | Set<string> | { [className: string]: any };
  @Input('fxNgClass.lg') fxNgClassLg: string | string[] | Set<string> | { [className: string]: any };
  @Input('fxNgClass.xl') fxNgClassXl: string | string[] | Set<string> | { [className: string]: any };
  @Input('fxNgClass.lt-sm') fxNgClassLtSm: string | string[] | Set<string> | { [className: string]: any };
  @Input('fxNgClass.lt-md') fxNgClassLtMd: string | string[] | Set<string> | { [className: string]: any };
  @Input('fxNgClass.lt-lg') fxNgClassLtLg: string | string[] | Set<string> | { [className: string]: any };
  @Input('fxNgClass.lt-xl') fxNgClassLtXl: string | string[] | Set<string> | { [className: string]: any };
  @Input('fxNgClass.gt-xs') fxNgClassGtXs: string | string[] | Set<string> | { [className: string]: any };
  @Input('fxNgClass.gt-sm') fxNgClassGtSm: string | string[] | Set<string> | { [className: string]: any };
  @Input('fxNgClass.gt-md') fxNgClassGtMd: string | string[] | Set<string> | { [className: string]: any };
  @Input('fxNgClass.gt-lg') fxNgClassGtLg: string | string[] | Set<string> | { [className: string]: any };
  @Input('fxNgClass.gt-xl') fxNgClassGtXl: string | string[] | Set<string> | { [className: string]: any };

  ngOnInit() {
    this._mediaAliases = [
        { alias: 'xs', apply: [this.fxNgClassXs] },
        { alias: 'sm', apply: [this.fxNgClassSm] },
        { alias: 'md', apply: [this.fxNgClassMd] },
        { alias: 'lg', apply: [this.fxNgClassLg] },
        { alias: 'lt-sm', apply: [this.fxNgClassLtSm] },
        { alias: 'lt-md', apply: [this.fxNgClassLtMd] },
        { alias: 'lt-lg', apply: [this.fxNgClassLtLg] },
        { alias: 'lt-xl', apply: [this.fxNgClassLtXl] },
        { alias: 'gt-xs', apply: [this.fxNgClassGtXs] },
        { alias: 'gt-sm', apply: [this.fxNgClassGtSm] },
        { alias: 'gt-md', apply: [this.fxNgClassGtMd] },
        { alias: 'gt-lg', apply: [this.fxNgClassGtLg] },
        { alias: 'gt-xl', apply: [this.fxNgClassGtXl] }
    ];

    this._subscription = this.mediaObserver.asObservable().subscribe((change: MediaChange[]) => {
      this.applyClassOnMatchedMedia();
    });
  }

  ngOnDestroy() {
    if (this._subscription) this._subscription.unsubscribe();
  }

  private applyClassOnMatchedMedia() {

    this._mediaAliases.forEach(media => {
        if (this.mediaObserver.isActive(media.alias)) {
            this.applyClass(media.apply);
        } else {
          this.applyClass(media.apply, true);
        }  
    });
  }

  private applyClass(classNames: string | string[] | Set<string> | { [className: string]: any } | undefined, remove: boolean = false) {
    if (classNames) {
      if (typeof classNames === 'string') {
        classNames = classNames.split(' ');
      }

      if (Array.isArray(classNames) || classNames instanceof Set) {
        classNames.forEach(className => {
          if (className) {
            if (remove) {
              this.renderer.removeClass(this.elementRef.nativeElement, className);
            } else {
              this.renderer.addClass(this.elementRef.nativeElement, className);
            }
          }
        });
      } else {
        for (const key in classNames) {
          if (classNames[key]) {
            if (remove) {
              this.renderer.removeClass(this.elementRef.nativeElement, key);
            } else {
              this.renderer.addClass(this.elementRef.nativeElement, key);
            }
          }
        }
      }
    }
  }
}