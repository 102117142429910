
<mat-toolbar #matToolbar class="" *ngIf="showToolBar()">
    <!-- <div class="topper_version" *ngIf="routeIndex && !imLogged()"></div> -->
    <div class="topper_version" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/esquina-sup-izq.svg?ngsw-bypass=true&quot;)'">
        <div class="v" *ngIf="versionIsShown">
            Versión<br><span>{{version}}</span>
        </div>
    </div>
    <section fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="stretch"
        class="topper_buts spe_gap">
        <div fxFlex="" fxFlex.gt-sm="10rem" fxFlex.gt-md="12.5rem"  class="text-center">
            <img src="https://walinwa.blob.core.windows.net/images/logo_walinwa_pantallas1.png?ngsw-bypass=true" (click)="versionIsShown = !versionIsShown"
                fxNgClass.lt-md="logo_max_height">
        </div>
        <div fxFlex.gt-md="0 0 calc(75% - 20px)" fxFlfex="" fxLayout="row" fxLayoutAlign.gt-md="end center"
            fxLayoutAlign="center center" class="spe_gap">
            <button *ngIf="routeIndex && !imLogged()" (click)="pruebaGratis()" class="back_amarillo">
                Prueba<br>
                gratis
            </button>

            <button *ngIf="routeIndex && !imLogged()" (click)="renovarLicencia()" class="back_verde">
                Renovar<br>
                licencia
            </button>

            <button *ngIf="routeIndex && !imLogged()" (click)="altaInicial()" class="back_fucsia">
                Alta<br>inicial de<br>alumno
            </button>

            <button *ngIf="showAppInfoButons && !imLogged()" (click)="openPDF()" class="back_naranja">
                Conoce <br>Walinwa
            </button>

            <button *ngIf="showAppInfoButons && !imLogged()" (click)="openVideo()" class="back_morado">
                Vídeo<br>explicativo
            </button>
        </div>
    </section>
</mat-toolbar>