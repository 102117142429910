import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { environment } from 'environments/environment';

@Component({
  selector: 'plank-walilee',
  templateUrl: './plank.component.html',
  styleUrls: ['./plank.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestPlankComponent implements OnInit {

  @Input('title') title: string = '';
  @Output() onClose = new EventEmitter<string>();
 
  public confetiBackgroundStyle: SafeStyle;

  constructor(private _sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.confetiBackgroundStyle = this._sanitizer.bypassSecurityTrustStyle(`url('${environment.filesUrl}/images/MiniGames/Backgrounds/confeti.gif?ngsw-bypass=true')`);
  }

  close() {
    this.onClose.emit();
  }
}
