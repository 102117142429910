import { ControlSession } from './../core/shared/state/models/control-session.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpParams, HttpEvent, HttpHeaderResponse, HttpResponse, HttpErrorResponse, HttpUserEvent, HttpProgressEvent } from '@angular/common/http';
import { Location } from '@angular/common';
import { environment } from 'environments/environment';
import { ControlSessionDTO } from 'app/core/shared/state/models/control-session-dto.model';

@Injectable({
  providedIn: 'root'
})
export class ControlSessionService {

    baseUrl = environment.baseApi + '/api/ControlSession';

    constructor(private http: HttpClient, private _location: Location) {
    }
    getControlSessionById(idControl){
      return this.http.get<ControlSession>(this.baseUrl + '/idControl/' + idControl);
    }
    getByUser(idUser) {
      return this.http.get<ControlSession[]>(this.baseUrl + '/idUser/' + idUser);
    };

    GetControlExercises(IdControl: number) {
        return this.http.get(this.baseUrl + '/Exercises/' + IdControl);
    }

    createExercises(IdControl, level, obj = {}){
        return this.http.post(this.baseUrl + '/createExercises/' + IdControl + "/" + level, obj);
    }
    updateControlSessionName(idControl, name) {
      var controlSession = { IdControl: idControl, Name: name };
      return this.http.post(this.baseUrl + '/updateControlSesionName/', controlSession);
    };
  
    insert(idUser, name) {
      var controlSession = { Name: name, IdTutor: idUser };
      return this.http.put(this.baseUrl, controlSession);
    };
  
    removeExercisesFromControlSession(idControl) {
      return this.http.get(this.baseUrl + "/removeExercisesFromControlSession/" + idControl);
    }
  
    insertExercise(idControl, exercises) {
      return this.http.put(this.baseUrl + "/idControl/" + idControl, exercises);
    };
  
    remove(controlSession : ControlSession) {
      return this.http.delete(this.baseUrl + "/idControl/" + controlSession.IdControl);
    };
  
  
    removeExercise(idControl, type, thema) {
      return this.http.delete(this.baseUrl + "/idControl/" + idControl + "/type/" + type + "/thema/" + thema);
    };
    MapFromDTOToControl(DTOList :ControlSessionDTO[]){
        return DTOList.filter(x => x.IdClass === 0).map((controlDTO) => {
            const { IdControl, Name } = controlDTO;
            return {
                IdControl,
                Name,
                IdTutor: 0,
                Tutor: null,
                Exercises: [],
                CustomSessions: [],
                selected: false,
                count_assigned: undefined,
                editingControlSessionName: false
              };
        });
    }
}
