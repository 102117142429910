import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'walilee',
  templateUrl: './walilee.component.html',
  styleUrl: './walilee.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WalileeComponent implements OnInit {
  public selectedTab: WaliLeeTabs = WaliLeeTabs.List;
  WaliLeeTabs = WaliLeeTabs; // State: typeof State = State

  constructor(private _detector: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    
  }

  public selectTab(tab: WaliLeeTabs) {
    this.selectedTab = tab;
    this._detector.detectChanges();
  }
}

enum WaliLeeTabs
{
    List,
    Results,
    Library
};

