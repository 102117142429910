<div style="display: none; z-index: 9999; position: fixed; top: 0; left: 0; background: white">
    <p id="prueba1"></p>
    <p id="prueba2"></p>
    <p id="prueba3"></p>
</div>
<walinwa-update></walinwa-update>
<div [ngClass]="{mobile: mobile, dev: !production}" class="walinwa-app">
    <div class="walinwa-app-contents">
        <!--spinner></spinner-->
        <!--ng-container *ngIf="isValidBrowser && !globalService.versionError && globalService.isSessionStorageSupported">
            <vertical-layout-1></vertical-layout-1>
        </ng-container-->
        <ng-container *ngIf="isValidBrowser && globalService.isSessionStorageSupported">
            <vertical-layout-1></vertical-layout-1>
        </ng-container>
        <!--div class="overlay" *ngIf="globalService.versionError">
            <div class="error-window">
                <div class="error-message">
                    Existe una nueva versión de Walinwa. Para actualizar pulsa {{ (this.isDesktop) ? 'Ctrl + R.' :'el botón'}} <button *ngIf="!this.isDesktop" type="button" class="actualizar-version-button" (click)="checkVersion()">Actualizar versión</button>
                </div>
            </div>
        </div-->
        <div class="overlay" *ngIf="!globalService.isSessionStorageSupported">
            <div class="error-window">
                <div class="error-message">
                    Este navegador no soporta cookies u otros elementos de sesión.
                </div>
            </div>
        </div>
        <div class="overlay" *ngIf="!isValidBrowser">
            <div class="error-window">
                <div class="error-message">
                    Lo sentimos pero para utilizar Walinwa debes usar otro navegador. Este no es compatible.
                </div>
            </div>
        </div>
    </div>
</div>