<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review, 'large': alphanumericCharacterCount > 29}">
    <span [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="guionf spe_gap" *ngIf="exercise" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" fxLayout="column" fxLayoutAlign="stretch">  
    
    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}</div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div id="space-for-text" class="wordsContainer" [ngClass]="{'words-container-slide': slide, 'wordsContainer4': tmpPhrases.length > 2 && controlSession && !slide, 'wordsContainer2' :tmpPhrases?.length <=2 && controlSession && !slide,'wordsContainer4-slide': tmpPhrases?.length > 2 && slide}">

        <div id="TextContainer" class="items_container">

            <!--Do not insert blanks between SPAN, carry returns or element between these SPANs it would modify the spaces in the web-->
            <div *ngFor="let frase of tmpPhrases; let i = index" id="TextContainer{{ i }}" [attr.index]="i" class="dropzone" [hidden]="dropLevel != 'box'" [ngClass]="{ 'phraseBox': !slide,phraseBoxOk:  frase.answered == true, phraseBoxError:  frase.answered == false}">
                <div class="phraseBoxChild">
                    <span *ngFor="let fraseGuion of frase.fraseGuion" class="subPhrase" [ngClass]="{'subPhraseEdge': browserEdge}">
                        <span *ngFor="let fragmento of fraseGuion.fragmento" class="fragment" [ngClass]="{'bold': fragmento.bold == true}">{{fragmento.texto}}</span>
                    </span>
                    <div  id="author" class="authorBox" [ngClass]="{'authorBox4': tmpPhrases?.length > 2, 'authorBox2' :tmpPhrases?.length <=2, 'authorBox-slide': controlSession && slide}">
                        {{frase.libro}}<span class="authorInfo" [ngClass]="{'authorInfo4': tmpPhrases?.length > 2, 'authorInfo2' :tmpPhrases?.length <=2}"> {{frase.autor}}</span>
                    </div>
                </div>
            </div>

            <!-- Leo: La siguiente estructura no ubico cuando carga // AQUI -->
            <!--Do not insert blanks between SPAN, carry returns or element between these SPANs it would modify the spaces in the web-->
            <div *ngFor="let frase of tmpPhrases; let i = index" id="TextContainer" class="phraseBox" [hidden]="dropLevel == 'box'" [ngClass]="{  phraseBoxOk:  frase.answered == true, phraseBoxError:  frase.answered == false}"> 
                <div class="phraseBoxChild">
                    <span *ngFor="let fraseGuion of frase.fraseGuion; let j = index" class="subPhrase" [ngClass]="{'subPhraseEdge': browserEdge}">
                        <span *ngFor="let fragmento of fraseGuion.fragmento; let k = index" class="fragment" [ngClass]="{'bold': fragmento.bold == true}">
                            <span id="space{{ i }}{{ j }}{{ k }}" class="dropzone dropLetterArea " [attr.optionId]="fragmento.id" [attr.indiceFrase]="i" [attr.indiceFraseGuion]="j" [attr.indiceFragmento]="k" *ngIf="fragmento.texto == '_'">{{fragmento.texto}}</span>
                            <span *ngIf="fragmento.texto != '_'">{{fragmento.texto}}</span>
                        </span>
                    </span>
                    <div class="authorBox" id="author" [ngClass]="{'authorBox4': tmpPhrases?.length > 2, 'authorBox2' :tmpPhrases?.length <=2}">
                        {{frase.libro}}<span class="authorInfo" [ngClass]="{'authorInfo4': tmpPhrases?.length > 2, 'authorInfo2' :tmpPhrases?.length <=2}"> {{frase.autor}}</span>
                    </div>
                </div>
            </div>
            <!-- Leo: La siguiente estructura no ubico cuando carga // CIERRA AQUI -->
        </div>
    </div>

    <div [hidden]="showingSolution" class="optionsContainer" id="space-for-buttons" [ngClass]="{'padding-slide': slide, 'optionsContainer4': tmpPhrases?.length > 2 && controlSession && !slide, 'optionsContainer4-slide': tmpPhrases?.length > 2 && slide,'optionsContainer2' :tmpPhrases?.length <=2 && controlSession && !slide}">
        <div [attr.optionId]="option.id" [ngClass]="{ bola:  bola(), 'bola-slide': bola() && slide, 'element-collapse': finished ,'exerciseLetterOptionContainer-slide': slide }" *ngFor="let option of exercise.opciones; let i = index" id="opt{{option.id}}" class="exerciseLetterOptionContainer" dragChildImg="option{{ i }}">
            <div id="option{{ i }}" [attr.optionId]="option.id" class="exerciseLetterOption draggable">{{ option.opcion }}</div>
        </div>
    </div>
</div>