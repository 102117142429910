<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="boxes2b spe_gap" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" fxLayout="column"
    fxLayoutAlign="stretch">

    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div class="wordsContainer">
        <div class="items_container" id="columnsContainer">

            <div [attr.boxIndex]="0" id="leftColumn" class="leftColumn dropzone">
                <div id="leftTitle" class="boxTitle" [title]="{'title': finished == true}">
                    {{ exercise.boxes[0].descripcion }}
                </div>
                <span *ngFor="let word of leftWords; let i = index" id="left{{ i }}" class="subPhraseAnswer">
                    {{word.palabra}}
                </span>
            </div>

            <div *ngFor="let word of exercise.palabras; let i = index" id="{{ i }}" [attr.wordValidation]="word.opcion"
                [hidden]="exercise.palabras.length != 8" class="wordBox word{{ i }}"
                [ngClass]="{'draggable': finished!=true , 'hideBox': word.answered == true || showAnswer == true, 'wordBoxError': word.answered == false, 'element-hidden': finished == true, 'wordBox-slide': slide }">
                <span>{{ word.palabra }}</span>
            </div>

            <div *ngFor="let word of exercise.palabras; let i = index" id="{{ i }}" [attr.wordValidation]="word.opcion"
                [hidden]="exercise.palabras.length != 6" class="wordBox word{{ i + 1 }}"
                [ngClass]="{'draggable': finished!=true , 'hideBox': word.answered == true || showAnswer == true, 'wordBoxError': word.answered == false, 'element-hidden': finished == true, 'wordBox-slide': slide }">
                <span>{{ word.palabra }}</span>
            </div>

            <div *ngFor="let word of exercise.palabras; let i = index" id="{{ i }}" [attr.wordValidation]="word.opcion"
                [hidden]="exercise.palabras.length != 4" class="wordBox word{{ i + 2 }}"
                [ngClass]="{'draggable': finished!=true , 'hideBox': word.answered == true || showAnswer == true, 'wordBoxError': word.answered == false, 'element-hidden': finished == true, 'wordBox-slide': slide }">
                <span>{{ word.palabra }}</span>
            </div>

            <div [attr.boxIndex]="1" id="rightColumn" class="rightColumn dropzone">
                <div id="rightTitle" class="boxTitle" [ngClass]="{'title': finished == true}">
                    {{ exercise.boxes[1].descripcion }}
                </div>
                <span *ngFor="let word of rightWords; let i = index" id="rightWords{{ i }}" class="subPhraseAnswer">
                    {{ word.palabra }}
                </span>
            </div>

        </div>
    </div>
</div>
<!--  -->
<!-- <div class="boxes2b" [ngStyle]="{'pointer-events': review ? 'none;' : ''}">   <h2 *ngIf="controlSession" class="doc-number">{{current + 1}}</h2>
    <div *ngIf="!controlSession" class="exerciseTheme">
        <h1 [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></h1>
    </div>
    <div class="text-center exerciseTitle" [ngClass]="{'exerciseTitle-slide': slide}">
        <p   [innerHtml]="exercise.enunciado"></p>
    </div>
    <div [ngStyle]="controlSession && !slide ? {height: '29em;'} : {}" [ngClass]="{'columnsContainer': !slide, 'columns-container-slide': slide}" id="columnsContainer" >
    
        <div [attr.boxIndex]="0" id="leftColumn" class="leftColumn dropzone" >
            <div id="leftTitle" class="boxTitle" [ngStyle]="controlSession ? {'font-size' : '1.7em;'} : {}" [title]="{'title': finished == true}">
                {{ exercise.boxes[0].descripcion }}
            </div>
            <span *ngFor="let word of leftWords; let i = index" id="left{{ i }}" class="subPhraseAnswer">
                {{word.palabra}}
            </span>            
        </div>
    
        <div *ngFor="let word of exercise.palabras; let i = index" id="{{ i }}" [ngStyle]="controlSession ? {width: '25%;'} : {}" [attr.wordValidation]="word.opcion" [hidden]="exercise.palabras.length != 8" class="wordBox  word{{ i }}" [ngClass]="{'draggable': finished!=true , 'hideBox': word.answered == true || showAnswer == true, 'wordBoxError': word.answered == false, 'element-hidden': finished == true, 'wordBox-slide': slide }">
            <p [ngStyle]="controlSession && !slide ? {} : {}">{{ word.palabra }}</p>
        </div>  
    
        <div *ngFor="let word of exercise.palabras; let i = index" id="{{ i }}" [ngStyle]="controlSession ? {width: '25%;'} : {}" [attr.wordValidation]="word.opcion" [hidden]="exercise.palabras.length != 6" class="wordBox  word{{ i + 1 }}" [ngClass]="{'draggable': finished!=true , 'hideBox': word.answered == true || showAnswer == true, 'wordBoxError': word.answered == false, 'element-hidden': finished == true, 'wordBox-slide': slide }">
            <p [ngStyle]="controlSession && !slide  ? {} : {}">{{ word.palabra }}</p>
        </div> 
    
        <div *ngFor="let word of exercise.palabras; let i = index" id="{{ i }}" [ngStyle]="controlSession ? {width: '25%;'} : {}" [attr.wordValidation]="word.opcion" [hidden]="exercise.palabras.length != 4" class="wordBox  word{{ i + 2 }}" [ngClass]="{'draggable': finished!=true , 'hideBox': word.answered == true || showAnswer == true, 'wordBoxError': word.answered == false, 'element-hidden': finished == true, 'wordBox-slide': slide }">
            <p [ngStyle]="controlSession && !slide  ? {} : {}">{{ word.palabra }}</p>
        </div>
    
        <div [attr.boxIndex]="1" id="rightColumn" class="rightColumn dropzone">
            <div id="rightTitle" class="boxTitle" [ngStyle]="controlSession ? {'font-size' : '1.7em;'} : {}" [ngClass]="{'title': finished == true}">
                {{ exercise.boxes[1].descripcion }}
            </div>
            <span *ngFor="let word of rightWords; let i = index" id="rightWords{{ i }}" class="subPhraseAnswer">
                {{ word.palabra }}
            </span>
        </div>
    
    </div>
</div> -->