<div class="walinwa-box">
    <div class="walinwa-box-content">
        <div *ngIf="!student">
            {{'SCHOOL.REVIEW-ACTIVITIES.REVIEW-DICTATION-TITLE' | translate: {userInfo: ''} }}
        </div>
        <div *ngIf="student">
            {{ 
                'SCHOOL.REVIEW-ACTIVITIES.REVIEW-DICTATION-TITLE' 
                | translate: { userInfo: (student.FamilyName ? student.FamilyName + ', ' : '') + student.Name + ' (' + student.UserName + ')' }
            }}
        </div>
        <div>
            <cal-dictations *ngIf="dictations" class="calendario dinamico walCalendar azul ww_customCalendar"
                [ngClass]="{ sixWeeks: sixWeeks() }" [parent]="this" [fullDay]="false" [currentUser]="student"
                [selectedDay]="selectedDayDict" [currentMonth]="currentMonthDict" [dictations]="dictations">
            </cal-dictations>
        </div>
        <div fxLayoutAlign="end end" fxLayoutAlign.xs="center center">
            <button (click)="startReview()"
                [disabled]="!activeButton" class="new_but azul">
                {{'SCHOOL.REVIEW-ACTIVITIES.REVIEW-DICTATION' | translate}}
            </button>
        </div>
    </div>
</div>