import { DeductionTextResult } from './../core/shared/state/models/deduction/deduction-text-result-model';
import { CalendarDeduction } from './../core/shared/state/models/deduction/deduction-month.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpParams, HttpEvent, HttpHeaderResponse, HttpResponse, HttpErrorResponse, HttpUserEvent, HttpProgressEvent } from '@angular/common/http';
import { Location } from '@angular/common';
import { environment } from 'environments/environment';
import { DeductionText } from 'app/core/shared/state/models/deduction/deduction-text-model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DeductionTextService {

    baseUrl = environment.baseApi + '/api/DeductionText';
    MonthlyDeduction: CalendarDeduction[] = null;
    private s_reload_monthly_deduction = new BehaviorSubject<boolean>(false);
    reload_monthly_deduction = this.s_reload_monthly_deduction.asObservable();
    constructor(private http: HttpClient) { }

    getDeductionTextByMonth(idUser: number, month: number, year: number, level: number, idRole: number, creationDate: any) {
        return this.http.post<CalendarDeduction[]>(this.baseUrl + '/getDeductionTextByMonth/', { idUser, month, year, level, idRole, creationDate });
    }

    getDeductionByLevel(level: number, month: number, year: number) {
        return this.http.post<CalendarDeduction[]>(this.baseUrl + '/getDeductionTextByLevel/', { level, month, year });
    }

    getDeductionByid(IdDeduction: number, IdUser: number, selectedDate: number) {
        return this.http.post<DeductionText>(this.baseUrl + '/getDeductionByid/', { IdDeduction, IdUser, selectedDate });
    }

    answer(IdDeduction: number, IdQuestion: number, IdAnswer: number, IdUser: number, IsCorrect: boolean) {
        return this.http.post(this.baseUrl + '/answer/', { IdDeduction, IdQuestion, IdAnswer, IdUser, IsCorrect });
    }

    start(IdDeduction: number, IdUser: number, TextDate: any, NumExercises: number, IdQuestion: number, IdAnswer: number, IsCorrect: boolean) {
        return this.http.post(this.baseUrl + '/start/', { IdDeduction, IdUser, TextDate, NumExercises, IdQuestion, IdAnswer, IsCorrect  });
    }
    restart(IdDeduction: number, IdUser: number, UserAnswersTotal: any, UserAnswersOk: number, Retries: number) {
        return this.http.post(this.baseUrl + '/restart/', { IdDeduction, IdUser, UserAnswersTotal, UserAnswersOk, Retries });
    }

    getScore(IdDeduction: number, IdUser: number, TextDate: any) {
        return this.http.post<DeductionTextResult>(this.baseUrl + '/getScore/', { IdDeduction, IdUser, TextDate });
    }
    changeReloadMonthlyDeduction(reload_monthly_deduction: boolean) {
        this.s_reload_monthly_deduction.next(reload_monthly_deduction);
    }
}
