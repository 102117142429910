import { HearingTextService } from './services/hearing-text.service';
import { HearingTextComponent } from './main/student/hearing-text/hearing-text.component';
import { CalHearingTextComponent } from './components/cal-hearing-text/cal-hearing-text.component';
import { MarcarComponent } from './main/exercise/marcar/marcar.component';
import { LogService } from 'app/services/log.service';
import { RetoService } from './services/reto.service';
import { ClassService } from 'app/services/class.service';
import { ApplicationRef, ErrorHandler, NgModule, isDevMode } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import {
    MatProgressSpinnerModule,
    MatProgressSpinner,
} from "@angular/material/progress-spinner";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { PathLocationStrategy, LocationStrategy } from "@angular/common";
import 'hammerjs';
import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from "@fuse/components";
import { SpinnerModule } from "@fuse/components/spinner/spinner.module";

import { fuseConfig } from "app/fuse-config";
import { ErrorInterceptorService } from "app/core/guards/error-interceptor.service";
import { AuthInterceptorService } from "app/core/guards/auth-interceptor.service";
import { AuthGuardService, canActivateRoute } from "app/core/guards/auth-guard.service";

import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";

import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { CoreModule } from "app/core/core.module";
import { AppService } from "./app.service";
import { LoginModule } from "./main/authentication/login/login.module";
import { InitialRegistrationModule } from "./main/authentication/initial-registration/initial-registration.module";
import { RenewLicenseComponent } from "./main/authentication/renew-license/renew-license.component";
import { FreeTrialModule } from "./main/authentication/free-trial/free-trial.module";
import { ResetPasswordModule } from "./main/authentication/reset-password/reset-password.module";
import { RequestResetPasswordModule } from "./main/authentication/request-reset-password/request-reset-password.module";
//import { QuestionaireModule } from "./main/questionaire/questionaire.module";

import { SchoolComponent } from "./main/school/school.component";
import { TeacherComponent } from "./main/school/teacher/teacher.component";
import { StudentsComponent } from "./main/school/teacher/students/students.component";
import { SessionComponent } from "./main/school/teacher/session/session.component";
import { NewStudentComponent } from "./main/school/teacher/new-student/new-student.component";
import { EditAuthComponent } from "./main/school/teacher/edit-auth/edit-auth.component";
import { EditStudentComponent } from "./main/school/teacher/edit-student/edit-student.component";
import { EditClassComponent } from "./main/school/teacher/edit-class/edit-class.component";
import { ConfigComponent } from "./main/school/teacher/config/config.component";
import { ActivitiesComponent } from "./main/school/teacher/activities/activities.component";
import { ConfigMailComponent } from "./main/school/teacher/config/config-mail/config-mail.component";
import { EditConfigSessionComponent } from "./main/school/teacher/config/edit-config-session/edit-config-session.component";
import { EditSessionComponent } from "./main/school/teacher/complements/edit-session/edit-session.component";
import { ExamsComponent } from "./main/school/teacher/complements/exams/exams.component";
import { DictationsComponent } from "./main/school/teacher/activities/dictations/dictations.component";
import { GamesComponent } from "./main/school/teacher/config/games/games.component";
import { OptionsComponent } from "./main/school/teacher/config/options/options.component";
import { AdminComponent } from "./main/school/admin/admin.component";
import { AdminPaymentComponent } from "./main/school/admin-payment/admin-payment.component";
import { AdminPromoComponent } from "./main/school/admin-promo/admin-promo.component";
import { ReportComponent as SchoolReportComponent } from "./main/school/teacher/report/report.component";
import { MessagesComponent } from "./main/school/teacher/messages/messages.component";
import { CalCustomizedSessionComponent } from "./components/cal-customized-session/cal-customized-session.component";
import { CalReadingTextComponent } from "./components/cal-reading-text/cal-reading-text.component";
import { HelpComponent } from "./main/school/help/help.component";
import { MaintenanceComponent } from "./main/maintenance/maintenance.component";
import { AdminReportComponent } from "./main/school/admin-report/admin-report.component";
import { StudentComponent } from "./main/student/student.component";
import { ReadingTextComponent } from "./main/student/reading-text/reading-text.component";
import { PlayComponent } from "./main/student/reading-text/play/play.component";
import { ParentComponent } from "./main/school/parent/parent.component";
import { ParentStudentsComponent } from "./main/school/parent/parent-students/parent-students.component";
import { EditParentStudentsComponent } from "./main/school/parent/edit-parent-students/edit-parent-students.component";
import { AuthParentStudentComponent } from "./main/school/parent/auth-parent-student/auth-parent-student.component";
import { NewParentStudentComponent } from "./main/school/parent/new-parent-student/new-parent-student.component";
import { ConfigParentsComponent } from "./main/school/parent/config-parents/config-parents.component";
import { ConfigParentsMailComponent } from "./main/school/parent/config-parents/config-parents-mail/config-parents-mail.component";
import { ParentReportComponent } from "./main/school/parent/parent-report/parent-report.component";
import { LicenseComponent } from "./main/school/parent/license/license.component";
import { MyAccountComponent } from "./main/school/parent/my-account/my-account.component";
import { DictationComponent } from "./main/student/dictation/dictation.component";
import { PlayComponent as PlayDictationComponent } from "./main/student/dictation/play/play.component";
import { PlayComponent as PlayHearingTextComponent } from "./main/student/hearing-text/play/play.component";
import { SessionComponent as StudentSession } from "./main/student/session/session.component";
import { TooltipComponent } from "./components/tooltip/tooltip.component";
import { ReadingComponent } from "./main/school/teacher/activities/reading/reading.component";
import { HearingComponent } from "./main/school/teacher/activities/hearing/hearing.component";
import { PlankComponent } from "./components/plank/plank.component";
import { TransactionsComponent } from "./main/student/transactions/transactions.component";
import { StudentHelpComponent } from "./main/student/student-help/student-help.component";
import { ReviewTheoryComponent } from "./main/student/review-theory/review-theory.component";
import { ReportComponent as StudentReportComponent } from "./main/student/report/report.component";
import { ContactComponent } from "./main/student/contact/contact.component";
import { GamesComponent as StudentGamesComponent } from "./main/student/games/games.component";
import { GameComponent } from "./main/student/games/game/game.component";
import { ChallengeComponent } from "./main/student/challenge/challenge.component";
import { RewardsComponent } from "./main/student/rewards/rewards.component";
import { ShopComponent } from "./main/student/shop/shop.component";
import { DemandComponent } from "./main/demand/demand.component";
import { EcoembesComponent } from "./main/student/ecoembes/ecoembes.component";
import { ExerciseComponent } from "./main/exercise/exercise.component";
import { GuionpComponent } from "./main/exercise/guionp/guionp.component";
import { GuionfComponent } from "./main/exercise/guionf/guionf.component";
import { Boxes2Component } from "./main/exercise/boxes2/boxes2.component";
import { Boxes2bComponent } from "./main/exercise/boxes2b/boxes2b.component";
import { Boxes3Component } from "./main/exercise/boxes3/boxes3.component";
import { MarcaComponent } from "./main/exercise/marca/marca.component";
import { PreguntaComponent } from "./main/exercise/pregunta/pregunta.component";
import { UnirComponent } from "./main/exercise/unir/unir.component";
import { Unir2Component } from "./main/exercise/unir2/unir2.component";
import { Unir3Component } from "./main/exercise/unir3/unir3.component";
import { MLLuviaComponent } from "./main/exercise/m-lluvia/m-lluvia.component";
import { ParejasComponent } from "./main/exercise/parejas/parejas.component";
import { Unir4Component } from "./main/exercise/unir4/unir4.component";
import { Unir5Component } from "./main/exercise/unir5/unir5.component";
import { Unir6Component } from "./main/exercise/unir6/unir6.component";
import { Boxes2cComponent } from "./main/exercise/boxes2c/boxes2c.component";
import { Boxes3cComponent } from "./main/exercise/boxes3c/boxes3c.component";
import { Boxes4cComponent } from "./main/exercise/boxes4c/boxes4c.component";
import { DefineComponent } from "./main/exercise/define/define.component";
import { Mod1Component } from "./main/exercise/mod1/mod1.component";
import { Mod2Component } from "./main/exercise/mod2/mod2.component";
import { Mod3Component } from "./main/exercise/mod3/mod3.component";
import { FlechasComponent } from "./main/exercise/flechas/flechas.component";
import { OrdenComponent } from "./main/exercise/orden/orden.component";
import { ColocaComponent } from "./main/exercise/coloca/coloca.component";
import { Marca1Component } from "./main/exercise/marca1/marca1.component";
import { SelecComponent } from "./main/exercise/selec/selec.component";
import { VerbosComponent } from './main/exercise/verbos/verbos.component';
import { RapidComponent } from './main/exercise/rapid/rapid.component';
import { AhorcaComponent } from './main/exercise/ahorca/ahorca.component';
import { CruciComponent } from './main/exercise/cruci/cruci.component';
import { MarcaTComponent } from './main/exercise/marca-t/marca-t.component';
import { MarcaFComponent } from './main/exercise/marca-f/marca-f.component';
import { ColocafComponent } from './main/exercise/colocaf/colocaf.component';
import { SelecfComponent } from './main/exercise/selecf/selecf.component';
import { NumerComponent } from './main/exercise/numer/numer.component';
import { BoxesnComponent } from './main/exercise/boxesn/boxesn.component';
import { SopaComponent } from './main/exercise/sopa/sopa.component';
import { ImgComponent } from './main/exercise/img/img.component';
import { PasapComponent } from './main/exercise/pasap/pasap.component';
import { Pasap1Component } from './main/exercise/pasap1/pasap1.component';
import { Sopa1Component } from './main/exercise/sopa1/sopa1.component';
import { Sopa2Component } from './main/exercise/sopa2/sopa2.component';
import { Verbos1Component } from './main/exercise/verbos1/verbos1.component';
import { AhorcafrComponent } from './main/exercise/ahorcafr/ahorcafr.component';
import { FraseComponent } from './main/exercise/frase/frase.component';
import { RimaComponent } from './main/exercise/rima/rima.component';
import { AhorcaiComponent } from './main/exercise/ahorcai/ahorcai.component';
import { AhorcarComponent } from './main/exercise/ahorcar/ahorcar.component';
import { Coloca1Component } from './main/exercise/coloca1/coloca1.component';
import { Opc1Component } from './main/exercise/opc1/opc1.component';
import { DeductionTextComponent } from './main/student/deduction-text/deduction-text.component';
import {PlayComponent as PlayDeductionComponent} from './main/student/deduction-text/play/play.component'
import { CalDeductionTextComponent } from './components/cal-deduction-text/cal-deduction-text.component';
import { ZoneComponent} from './main/student/zone/zone.component'
import { CalendarComponent } from "./components/calendar/calendar.component";
import { CalDictationsComponent } from "./components/cal-dictations/cal-dictations.component";
import { DailyTextComponent } from "./components/daily-text/daily-text.component";
import { PlayComponent as PlayGameComponent } from './main/student/games/game/play/play.component';
import { DeductionComponent } from "./main/school/teacher/activities/deduction/deduction.component";
import { VisualMemoryComponent } from "./main/student/visual-memory/visual-memory.component";
import { DumbComponent } from "./main/student/visual-memory/dumb/dumb.component";
import { SiglasComponent } from './main/exercise/siglas/siglas.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ComplementsComponent } from './main/school/teacher/complements/complements.component';
import { ControlSessionComponent } from './main/school/teacher/complements/control-session/control-session.component';
import { ReadingReviewComponent } from "./main/school/teacher/session/reading/reading.component";
import { DictationReviewComponent } from "./main/school/teacher/session/dictation/dictation.component";
import { DeductionReviewComponent } from "./main/school/teacher/session/deduction/deduction.component";
import { HearingReviewComponent } from "./main/school/teacher/session/hearing/hearing.component";

// Services
//import { QuestionaireService } from "./services/questionaire.service";
import { GlobalService } from "./services/global.service";
import { VersionService } from "./services/version.service";
import { TestService } from "./services/test.service";
import { ExerciseService } from "./services/exercise.service";
import { DictationService } from "./services/dictation.service";
import { TestExerciseService } from "./services/test-exercise.service";
import { SharedService } from "app/core/shared/shared.service";
import { AccountService } from "app/main/authentication/account.service";
import { SchoolService } from "./services/school.service";
import { UserService } from "./services/user.service";
import { CourseService } from "./services/course.service";
import { PaymentService } from "./services/payment.service";
import { LicenseService } from "./services/license.service";
import { SessionService } from "./services/session.service";
import { CustomizedSessionService } from "./services/customized-session.service";
import { ReplaceTags } from "./pipes/replace-tags.pipe";
import { ToLocalTime } from "./pipes/to-local-time.pipe";
import { ReplaceDot } from "./pipes/replace-dot.pipe";
import { EmailConfigService } from "./services/email-config.service";
import { Test2Service } from "./services/test2.service";
import { ReportService } from "./services/report.service";
import { TeacherMessageService } from "./services/teacher-message.service";
import { NavigationService } from "./services/navigation.service";
import { AuthService } from "./services/auth.service";
import { ReadingTextService } from "./services/reading-text.service";
import { ShopService } from "./services/shop.service";
import { AdminMessageService } from "./services/adminMessages.service";
import { DailyTextService } from "./services/daily-text.service";
import { CoinService } from "./services/coin.service";
import { HistoryStageService } from "./services/historyStage.service";
import { CivilizationService } from "./services/civilization.service";
import { ExtraRewardService } from "./services/extra-reward.service";
import { MedalService } from "./services/medal.service";
import { GameService } from "./services/game.service";
import { AnnouncementService } from "./services/announcement.service";
import { HelpService } from "./services/help.service";
import { OnlineStatusModule } from "ngx-online-status";
import { AvatarFileComponent } from "./main/student/avatar-file/avatar-file.component";
import { AvatarShopComponent } from "./main/student/avatar-shop/avatar-shop.component";
import { MiniExerciseComponent } from "./main/school/teacher/activities/exercise/exercise.component";
import { ScenaryComponent } from "./main/student/scenary/scenary.component";
import { ConfigActivitiesComponent } from "./main/school/teacher/config/activities/activities.component";
import { PersonalizedTestComponent } from "./main/school/teacher/complements/personalized-test/personalized-test.component";
import { SessionReviewComponent } from "./main/school/teacher/session/session/session.component";
import { ProgressComponent } from "./main/student/progress/progress.component";
import { PersonalizedTestStudentComponent } from "./main/student/personalized-test/personalized-test.component";
import { Unir4aComponent } from "./main/exercise/unir4a/unir4a.component";
import { TrackingComponent } from "./main/school/teacher/tracking/tracking.component";
import { MiniTestComponent } from "./main/school/teacher/mini-test/mini-test.component";
import { WaliLetrasComponent } from "./main/student/wali-letras/wali-letras.component";
import { WaliLetrasService } from "./services/wali-letras.service";
import { ImageService } from "./services/image.service";
import { ChallengeConfigComponent } from "./main/school/teacher/config/challenge/challenge.component";
import { FooterAuthModule } from "./main/authentication/footer-auth/footer-auth.module";
import { TpvModule } from "./components/tpv/tpv.module";
import { RedirectModule } from "./main/authentication/redirection/redirection.module";
import { FileService} from "./services/file.service"
import { SharedModule } from './shared.module';
import { DictConfigComponent } from './main/school/teacher/config/dict-config/dict-config.component';
import { DigitSpacingPipe } from './pipes/digit-spacing.pipe';
import { ParentReviewComponent } from './main/school/parent/parent-review/parent-review.component';
import { ParentReviewSessionComponent } from "./main/school/parent/parent-review/parent-review-session/parent-review-session.component";
import { ParentReviewDictationComponent } from './main/school/parent/parent-review/parent-review-dictation/parent-review-dictation/parent-review-dictation.component';
import { ParentReviewDeductionTextComponent } from './main/school/parent/parent-review/parent-review-deduction-text/parent-review-deduction-text/parent-review-deduction-text.component';
import { ParentReviewHearingTextComponent } from './main/school/parent/parent-review/parent-review-hearing-text/parent-review-hearing-text/parent-review-hearing-text.component';
import { ParentReviewReadingTextComponent } from './main/school/parent/parent-review/parent-review-reading-text/parent-review-reading-text/parent-review-reading-text.component';
import { ProfileConditions, Profiles } from './core/shared/session.model';
import { PersonalizedTestChatGPTPopupComponent } from './main/school/teacher/complements/personalized-test/personalized-text-chatgpt-popup/personalized-test-chatgpt-popup.component';
import { MessagePopupComponent } from './components/message-popup/message-popup.component';
import { PersonalizedTestJoinTestPopupComponent } from './main/school/teacher/complements/personalized-test/personalized-text-join-tests-popup/personalized-test-join-tests-popup.component';
import { CustomErrorHandler } from './core/logging/error-handler';
import { AdminTeachersComponent } from './main/school/admin/admin-teachers/admin-teachers.component';
import { AdminClassesComponent } from './main/school/admin/admin-classes/admin-classes.component';
import { AdminStudentsComponent } from './main/school/admin/admin-students/admin-students.component';
import { ParentHelpComponent } from './main/school/parent/parent-help/parent-help.component';
import { ZoneConfigComponent } from './main/school/teacher/config/zone/zone.component';
import { AdminFacturasComponent } from './main/school/admin/admin-facturas/admin-facturas.component';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { WalinwaUpdateComponent } from './components/walinwa-update/walinwa-update.component';
import { APP_INITIALIZER } from '@angular/core';
import { WalinwaUpdateService, init } from './services/walinwa-update.service';
import { environment } from 'environments/environment';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { AdminClassroomComponent } from './main/school/admin/admin-classroom/admin-classroom.component';
import { AdminClassroomClientComponent } from './main/school/admin/admin-classroom/admin-classroom-client/admin-classroom-client.component';
import { AdminClassroomDataComponent } from './main/school/admin/admin-classroom/admin-classroom-data/admin-classroom-data.component';
import { MatTreeModule } from '@angular/material/tree';
import { SortByPipe } from './pipes/sort-by.pipe';
import { MiniGamesComponent } from './main/student/mini-games/mini-games.component';
import { WaliLeeService } from './services/wali-lee.service';
import { WaliLeeComponent } from './main/student/wali-lee/wali-lee.component';
import { MapSectionComponent } from './main/student/wali-lee/map-section/map-section.component';
import { WalileeTestComponent } from './main/student/wali-lee/walilee-test/walilee-test.component';
import { WalileeListComponent } from './main/school/teacher/walilee/walilee-list/walilee-list.component';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { WalileeComponent } from './main/school/teacher/walilee/walilee.component';
import { WalileeResultsComponent } from './main/school/teacher/walilee/walilee-results/walilee-results.component';
import { DxBulletModule, DxButtonModule, DxSelectBoxModule, DxTemplateModule } from 'devextreme-angular';
import { ComprehensionsComponent } from './main/student/comprehensions/comprehensions.component';
import { TestPlankComponent } from './main/student/wali-lee/plank/plank.component';
import { WalileeLibraryComponent } from './main/school/teacher/walilee/walilee-library/walilee-library.component';

export const appRoutes: Routes = [
    {
        path: "",
        loadChildren: () =>
            import("./main/main.module").then((m) => m.MainModule),
    },
    { path: "maintenance", component: MaintenanceComponent },
    {
        path: "login",
        loadChildren: () =>
            import("./main/authentication/login/login.module").then(
                (m) => m.LoginModule
            ),
    },
    {
        path: "free-trial",
        loadChildren: () =>
            import("./main/authentication/free-trial/free-trial.module").then(
                (m) => m.FreeTrialModule
            ),
    },
    {
        path: "renew-licence",
        component: RenewLicenseComponent
    },
    {
        path: "initial-registration",
        loadChildren: () =>
            import(
                "./main/authentication/initial-registration/initial-registration.module"
            ).then((m) => m.InitialRegistrationModule),
    },
    {
        path: "redirection/:token",
        loadChildren: () =>
            import("./main/authentication/redirection/redirection.module").then(
                (m) => m.RedirectModule
            ),
    },
    /*{
        path: "questionaire/:idQuestionaire/:idUser",
        loadChildren: () =>
            import("./main/questionaire/questionaire.module").then(
                (m) => m.QuestionaireModule
            ),
    },*/
    {
        path: "test",
        loadChildren: () =>
            import("./main/test/test.module").then((m) => m.TestModule),
    },
    {
        path: "request-reset-password",
        loadChildren: () =>
            import(
                "./main/authentication/request-reset-password/request-reset-password.module"
            ).then((m) => m.RequestResetPasswordModule),
    },
    {
        path: "resetpassword/:idUser/:token",
        loadChildren: () =>
            import(
                "./main/authentication/reset-password/reset-password.module"
            ).then((m) => m.ResetPasswordModule),
    },
    {
        path: "resetpasswordintern/:idUser/:token",
        loadChildren: () =>
            import(
                "./main/authentication/reset-password/reset-password.module"
            ).then((m) => m.ResetPasswordModule),
    },
    {
        path: "admin",
        canActivate: [canActivateRoute],
        data: { AllowedProfiles: [Profiles.IsSuperAdmin] },
        loadChildren: () =>
            import("./main/admin/admin.module").then((m) => m.AdminModule),
    },
    {
        path: "school",
        component: SchoolComponent,
        runGuardsAndResolvers: "always",
        canActivate: [canActivateRoute],
        data: { AllowedProfiles: [Profiles.IsSchoolAdmin, Profiles.IsTutor, Profiles.IsSuperAdmin] },
        children: [
            {
                path: "",
                component: TeacherComponent,
                runGuardsAndResolvers: "always",
                canActivate: [canActivateRoute],
                canActivateChild: [canActivateRoute],
                data: { AllowedProfiles: [Profiles.IsTeacher, Profiles.IsSuperAdmin, Profiles.IsSchoolAdmin]},
                children: [
                    {
                        path: "students",
                        component: StudentsComponent,
                        runGuardsAndResolvers: "always",
                    },
                    {
                        path: "students/:IdUser",
                        component: EditStudentComponent,
                        runGuardsAndResolvers: "always",
                    },
                    {
                        path: "students/class/:IdClass",
                        component: EditClassComponent,
                        runGuardsAndResolvers: "always",
                    },
                    {
                        path: "session/:IdUser/:IdClass",
                        component: SessionComponent,
                        runGuardsAndResolvers: "always",
                        children: [
                            {
                                path: "dictationsReview/:Id/:IdClass",
                                component: DictationReviewComponent,
                                runGuardsAndResolvers: "always",
                            },
                            {
                                path: "readingReview/:Id/:IdClass",
                                component: ReadingReviewComponent,
                                runGuardsAndResolvers: "always",
                            },
                            {
                                path: "hearingReview/:Id/:IdClass",
                                component: HearingReviewComponent,
                                runGuardsAndResolvers: "always",
                            },
                            {
                                path: "deductionReview/:Id/:IdClass",
                                component: DeductionReviewComponent,
                                runGuardsAndResolvers: "always",
                            },
                            {
                                path: "sessionReview/:Id/:IdClass",
                                component: SessionReviewComponent,
                                runGuardsAndResolvers: "always",
                            },
                            
                        ],
                    },
                    {
                        path: "session/:IdUser/:IdClass/readingReview/:Id/:IdClass/readingText/review",
                        component: PlayComponent,
                        runGuardsAndResolvers: "always",
                    },
                    {
                        path: "session/:IdUser/:IdClass/hearingReview/:Id/:IdClass/hearingText/review",
                        component: PlayHearingTextComponent,
                        runGuardsAndResolvers: "always",
                    },
                    {
                        path: "session/:IdUser/:IdClass/dictationsReview/:Id/:IdClass/dictation/review/",
                        component: PlayDictationComponent,
                        runGuardsAndResolvers: "always",
                    },
                    {
                        path: "session/:IdUser/:IdClass/deductionReview/:Id/:IdClass/deduction/review",
                        component: PlayDeductionComponent,
                        runGuardsAndResolvers: "always",
                    },
                    {
                        path: "students/auth/:IdUser",
                        component: EditAuthComponent,
                        runGuardsAndResolvers: "always",
                    },
                    {
                        path: "students/newStudent/:IdClass",
                        component: NewStudentComponent,
                        runGuardsAndResolvers: "always",
                    },
                    {
                        path: "activities",
                        component: ActivitiesComponent,
                        runGuardsAndResolvers: "always",
                        children: [
                            {
                                path: "dictations",
                                component: DictationsComponent,
                                runGuardsAndResolvers: "always",
                                children: [
                                    {
                                        path: ":level",
                                        component: PlayDictationComponent,
                                        runGuardsAndResolvers: "always",
                                    },
                                ],
                            },
                            {
                                path: "reading",
                                component: ReadingComponent,
                                runGuardsAndResolvers: "always",
                                children: [
                                    {
                                        path: "review",
                                        component: PlayComponent,
                                        runGuardsAndResolvers: "always",
                                    },
                                ],
                            },
                            {
                                path: "hearing",
                                component: HearingComponent,
                                runGuardsAndResolvers: "always",
                                children: [
                                    {
                                        path: "review",
                                        component: PlayHearingTextComponent,
                                        runGuardsAndResolvers: "always",
                                    },
                                ],
                            },
                            {
                                path: "deduction",
                                component: DeductionComponent,
                                runGuardsAndResolvers: "always",
                                children: [
                                    {
                                        path: "review",
                                        component: PlayDeductionComponent,
                                        runGuardsAndResolvers: "always",
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: "walilee",
                        component: WalileeComponent,
                        runGuardsAndResolvers: "always"
                    },
                    {
                        path: "complements",
                        component: ComplementsComponent,
                        runGuardsAndResolvers: "always",
                        children: [
                            {
                                path: "editSessions",
                                component: EditSessionComponent,
                                runGuardsAndResolvers: "always",
                            },
                            {
                                path: "exams",
                                component: ExamsComponent,
                                runGuardsAndResolvers: "always",
                            },
                            {
                                path: "controlSession",
                                component: ControlSessionComponent,
                                runGuardsAndResolvers: "always",
                            },
                            {
                                path: "personalizedTest",
                                component: PersonalizedTestComponent,
                                runGuardsAndResolvers: "always",
                            }
                        ],
                    },
                    {
                        path: "config",
                        component: ConfigComponent,
                        runGuardsAndResolvers: "always",
                        children: [
                            {
                                path: "mail",
                                component: ConfigMailComponent,
                                runGuardsAndResolvers: "always",
                            },
                            {
                                path: "editConfigSessions",
                                component: EditConfigSessionComponent,
                                runGuardsAndResolvers: "always",
                            },
                            {
                                path: "dictations",
                                component: DictConfigComponent,
                                runGuardsAndResolvers: "always",
                            },
                            {
                                path: "games",
                                component: GamesComponent,
                                runGuardsAndResolvers: "always",
                            },
                            {
                                path: "zone",
                                component: ZoneConfigComponent,
                                runGuardsAndResolvers: "always",
                            },
                            {
                                path: "options",
                                component: OptionsComponent,
                                runGuardsAndResolvers: "always",
                            },
                            {
                                path: "activities",
                                component: ConfigActivitiesComponent,
                                runGuardsAndResolvers: "always",
                            },
                            {
                                path: "challenge",
                                component: ChallengeConfigComponent,
                                runGuardsAndResolvers: "always",
                            },
                        ],
                    },
                    {
                        path: "messages",
                        component: MessagesComponent,
                        runGuardsAndResolvers: "always",
                    },
                    {
                        path: "tracking",
                        component: TrackingComponent,
                        runGuardsAndResolvers: "always",
                        children: [
                            {
                                path: "report",
                                component: SchoolReportComponent,
                                runGuardsAndResolvers: "always"
                            },
                            {
                                path: "test",
                                component: MiniTestComponent,
                                runGuardsAndResolvers: "always"
                            }
                        ]
                    },
                ],
            },
            {
                path: "",
                component: ParentComponent,
                runGuardsAndResolvers: "always",
                canActivate: [canActivateRoute],
                canActivateChild: [canActivateRoute],
                data: { AllowedProfiles: [Profiles.IsParent, Profiles.IsSuperAdmin] },
                children: [
                    {
                        path: "parentStudents",
                        component: ParentStudentsComponent,
                        runGuardsAndResolvers: "always",
                    },
                    {
                        path: "parentReport",
                        component: ParentReportComponent,
                        runGuardsAndResolvers: "always",
                    },
                    {
                        path: "myAccount",
                        component: MyAccountComponent,
                        runGuardsAndResolvers: "always",
                    },
                    {
                        path: "parentStudents/editStudent/:IdUser",
                        component: EditParentStudentsComponent,
                        runGuardsAndResolvers: "always",
                    },
                    {
                        path: "parentStudents/auth/:IdUser",
                        component: AuthParentStudentComponent,
                        runGuardsAndResolvers: "always",
                    },
                    {
                        path: "parentStudents/newStudent",
                        component: NewParentStudentComponent,
                        runGuardsAndResolvers: "always",
                    },
                    {
                        path: "parentStudents/license/:IdUser",
                        component: LicenseComponent,
                        runGuardsAndResolvers: "always",
                    },
                    {
                        path: "configParents",
                        component: ConfigParentsComponent,
                        runGuardsAndResolvers: "always",
                        children: [
                            {
                                path: "mail",
                                component: ConfigMailComponent,
                                runGuardsAndResolvers: "always",
                            },
                        ],
                    },
                    {
                        path: "parentStudents/review",
                        component: ParentReviewComponent,
                        runGuardsAndResolvers: "always",
                        children: [
                            {
                                path: "sessions",
                                component: ParentReviewSessionComponent,
                                runGuardsAndResolvers: "always",
                                
                            },
                            {
                                path: "dictations",
                                component: ParentReviewDictationComponent,
                                runGuardsAndResolvers: "always",
                                children: [
                                    {
                                        path: "review",
                                        component: PlayDictationComponent,
                                        runGuardsAndResolvers: "always",
                                    }
                                ]
                            },
                            {
                                path: "readingTexts",
                                component: ParentReviewReadingTextComponent,
                                runGuardsAndResolvers: "always",
                                children: [
                                    {
                                        path: "review",
                                        component: PlayComponent,
                                        runGuardsAndResolvers: "always",
                                    }
                                ]
                            },
                            {
                                path: "hearingTexts",
                                component: ParentReviewHearingTextComponent,
                                runGuardsAndResolvers: "always",
                                children: [
                                    {
                                        path: "review",
                                        component: PlayHearingTextComponent,
                                        runGuardsAndResolvers: "always",
                                    }
                                ]
                            },
                            {
                                path: "deductionTexts",
                                component: ParentReviewDeductionTextComponent,
                                runGuardsAndResolvers: "always",
                                children: [
                                    {
                                        path: "review",
                                        component: PlayDeductionComponent,
                                        runGuardsAndResolvers: "always",
                                    }
                                ]
                            },
                        ],
                    },
                ],
            },
            {
                path: "admin",
                component: AdminComponent,
                runGuardsAndResolvers: "always",
                canActivate: [canActivateRoute],
                data: { AllowedProfiles: [Profiles.IsTeacher, Profiles.IsSchoolAdmin, Profiles.IsSuperAdmin] },
            },
            {
                path: "admin/classroom",
                component: AdminClassroomComponent,
                runGuardsAndResolvers: "always",
                canActivate: [canActivateRoute],
                data: { AllowedProfiles: [Profiles.IsSchoolAdmin, Profiles.IsSuperAdmin] }
            },
            {
                path: "admin/facturas",
                component: AdminFacturasComponent,
                runGuardsAndResolvers: "always",
                canActivate: [canActivateRoute],
                data: { AllowedProfiles: [Profiles.IsSchoolAdmin, Profiles.IsSuperAdmin] }
            },
            {
                path: "admin/teachers",
                component: AdminTeachersComponent,
                runGuardsAndResolvers: "always",
                canActivate: [canActivateRoute],
                data: { AllowedProfiles: [Profiles.IsSchoolAdmin, Profiles.IsSuperAdmin] }
            },
            {
                path: "admin/classes",
                redirectTo: 'admin/classes/',
                pathMatch: 'full'
            },
            {
                path: "admin/classes/:idClass",
                component: AdminClassesComponent,
                runGuardsAndResolvers: "always",
                canActivate: [canActivateRoute],
                data: { AllowedProfiles: [Profiles.IsSchoolAdmin, Profiles.IsSuperAdmin] }
            },
            {
                path: "admin/students",
                redirectTo: 'admin/students/null',
                pathMatch: 'full'
            },
            {
                path: "admin/students/:idClass",
                component: AdminStudentsComponent,
                runGuardsAndResolvers: "always",
                canActivate: [canActivateRoute],
                data: { AllowedProfiles: [Profiles.IsSchoolAdmin, Profiles.IsSuperAdmin] },
                children:[                    
                    {
                        path: "sessionAdmin/:IdUser/:IdClass",
                        component: SessionComponent,
                        runGuardsAndResolvers: "always",
                        children: [
                            {
                                path: "dictationsReview/:Id/:IdClass",
                                component: DictationReviewComponent,
                                runGuardsAndResolvers: "always",
                            },
                            {
                                path: "readingReview/:Id/:IdClass",
                                component: ReadingReviewComponent,
                                runGuardsAndResolvers: "always",
                            },
                            {
                                path: "hearingReview/:Id/:IdClass",
                                component: HearingReviewComponent,
                                runGuardsAndResolvers: "always",
                            },
                            {
                                path: "deductionReview/:Id/:IdClass",
                                component: DeductionReviewComponent,
                                runGuardsAndResolvers: "always",
                            },
                            {
                                path: "sessionReview/:Id/:IdClass",
                                component: SessionReviewComponent,
                                runGuardsAndResolvers: "always",
                            },                            
                        ],
                    },
                    {
                        path: "sessionAdmin/:IdUser/:IdClass/readingReview/:Id/:IdClass/readingText/review",
                        component: PlayComponent,
                        runGuardsAndResolvers: "always",
                    },
                    {
                        path: "sessionAdmin/:IdUser/:IdClass/hearingReview/:Id/:IdClass/hearingText/review",
                        component: PlayHearingTextComponent,
                        runGuardsAndResolvers: "always",
                    },
                    {
                        path: "sessionAdmin/:IdUser/:IdClass/dictationsReview/:Id/:IdClass/dictation/review/",
                        component: PlayDictationComponent,
                        runGuardsAndResolvers: "always",
                    },
                    {
                        path: "sessionAdmin/:IdUser/:IdClass/deductionReview/:Id/:IdClass/deduction/review",
                        component: PlayDeductionComponent,
                        runGuardsAndResolvers: "always",
                    }
                ]                
            },
            {
                path: "admin/report",
                component: AdminReportComponent,
                runGuardsAndResolvers: "always",
                canActivate: [canActivateRoute],
                data: { AllowedProfiles: [Profiles.IsSchoolAdmin, Profiles.IsSuperAdmin] }
            },
            {
                path: "admin/:state",
                component: AdminComponent,
                runGuardsAndResolvers: "always",
                canActivate: [canActivateRoute],
                data: { AllowedProfiles: [Profiles.IsSchoolAdmin, Profiles.IsSuperAdmin] }
            },
            {
                path: "adminPayment",
                component: AdminPaymentComponent,
                runGuardsAndResolvers: "always",
                canActivate: [canActivateRoute],
                data: { AllowedProfiles: [Profiles.IsSchoolAdmin, Profiles.IsSuperAdmin] }
            },
            {
                path: "adminPayment/:directPay",
                component: AdminPaymentComponent,
                runGuardsAndResolvers: "always",
                canActivate: [canActivateRoute],
                data: { AllowedProfiles: [Profiles.IsSchoolAdmin, Profiles.IsSuperAdmin] }
            },
            {
                path: "adminPromo",
                component: AdminPromoComponent,
                runGuardsAndResolvers: "always",
                canActivate: [canActivateRoute],
                data: { AllowedProfiles: [Profiles.IsSchoolAdmin, Profiles.IsSuperAdmin] }
            },
          
        ],
    },
    {
        path: "demand",
        canActivate: [canActivateRoute],
        data: { AllowedProfiles: [Profiles.OnDemand] },
        component: DemandComponent,
    },
    {
        path: "student/session/play",
        canActivate: [canActivateRoute],
        data: { AllowedProfiles: [Profiles.IsStudent, Profiles.IsTeacher, Profiles.IsSuperAdmin, Profiles.OnDemand, Profiles.IsSchoolAdmin, Profiles.IsParent] },
        component: ExerciseComponent,
    },    
    {
        path: "student/visual-memory",
        canActivate: [canActivateRoute],
        data: { AllowedProfiles: [Profiles.IsStudent, Profiles.IsTeacher, Profiles.IsSuperAdmin, Profiles.OnDemand, Profiles.IsSchoolAdmin, Profiles.IsParent] },
        component: VisualMemoryComponent
    },
    {
        path: "student",
        component: StudentComponent,
        canActivate: [canActivateRoute],
        data: { AllowedProfiles: [Profiles.IsStudent, { Profile: Profiles.IsTeacher, Assert: ProfileConditions.IsDemoUser }, Profiles.IsSuperAdmin, Profiles.OnDemand, { Profile: Profiles.IsSchoolAdmin, Assert: ProfileConditions.IsDemoUser }, Profiles.IsParent] },
        children: [
            {
                path: "contact",
                component: ContactComponent,
            },
            {
                path: "transactions",
                component: TransactionsComponent,
            },
            {
                path: "session",
                component: StudentSession,
            },
            {
                path: "ecoembes",
                component: EcoembesComponent,
            },
            {
                path: "reading-text",
                component: ReadingTextComponent,
                children: [
                    {
                        path: "play/:IdReadingText/:Date",
                        component: PlayComponent,
                    },
                    {
                        path: "review/:IdReadingText/:Date",
                        component: PlayComponent,
                    },
                ],
            },
            {
                path: "hearing-text",
                component: HearingTextComponent,
                children: [
                    {
                        path: "play/:IdHearingText/:Date",
                        component: PlayHearingTextComponent,
                    },
                    {
                        path: "review/:IdHearingText/:Date",
                        component: PlayHearingTextComponent,
                    },
                ],
            },
            {
                path: "dictation",
                component: DictationComponent,
                children: [
                    {
                        path: "play/",
                        component: PlayDictationComponent,
                        runGuardsAndResolvers: "always",
                    },
                    {
                        path: "review/",
                        component: PlayDictationComponent,
                        runGuardsAndResolvers: "always",
                    },
                ],
            },
            {
                path: "deduction",
                component: DeductionTextComponent,
                children: [
                    {
                        path: "play/:IdDeduction/:Date",
                        component: PlayDeductionComponent,
                        runGuardsAndResolvers: "always",
                    },
                    {
                        path: "review/:IdDeduction/:Date",
                        component: PlayDeductionComponent,
                        runGuardsAndResolvers: "always",
                    },
                ],
            },
            {
                path: "minigames",
                component: MiniGamesComponent,
            },
            {
                path: "zone",
                component: ZoneComponent,
            },
            {
                path: "scenary",
                component: ScenaryComponent,
            },
            {
                path: "progress",
                component: ProgressComponent,
                children: [
                    {
                        path: "report",
                        component: StudentReportComponent,
                    },
                    {
                        path: "test",
                        component: PersonalizedTestStudentComponent,
                    }
                ]
            },
            
            {
                path: "games",
                component: StudentGamesComponent,
                canActivate: [canActivateRoute],
                data: { AllowedProfiles: [{ Profile: Profiles.IsStudent, Assert: ProfileConditions.MeetGamesConditions }, { Profile: Profiles.IsTeacher, Assert: ProfileConditions.IsDemoUser }, Profiles.IsSuperAdmin, Profiles.OnDemand, { Profile: Profiles.IsSchoolAdmin, Assert: ProfileConditions.IsDemoUser }, Profiles.IsParent] },
            
            },
            {
                path: "games/:game",
                component: GameComponent,
                canActivate: [canActivateRoute],
                data: { AllowedProfiles: [{ Profile: Profiles.IsStudent, Assert: ProfileConditions.MeetGamesConditions }, { Profile: Profiles.IsTeacher, Assert: ProfileConditions.IsDemoUser }, Profiles.IsSuperAdmin, Profiles.OnDemand, { Profile: Profiles.IsSchoolAdmin, Assert: ProfileConditions.IsDemoUser }, Profiles.IsParent] },
            
            },
            {
                path: "games/:game/play",
                component: PlayGameComponent
            },
            {
                path: "challenge",
                component: ChallengeComponent,
            },
            {
                path: "rewards",
                component: RewardsComponent,
            },
            {
                path: "shop",
                component: ShopComponent,
                canActivate: [canActivateRoute],
                data: { AllowedProfiles: [{ Profile: Profiles.IsStudent, Assert: ProfileConditions.MeetZoneConditions }, { Profile: Profiles.IsTeacher, Assert: ProfileConditions.IsDemoUser }, Profiles.IsSuperAdmin, Profiles.OnDemand, { Profile: Profiles.IsSchoolAdmin, Assert: ProfileConditions.IsDemoUser }, Profiles.IsParent] },
            },
            {
                path: "walilee",
                component: WaliLeeComponent
            }
        ],
    },
];

@NgModule({
    declarations: [
        AppComponent,
        SchoolComponent,
        AdminComponent,
        AdminTeachersComponent,
        AdminClassesComponent,
        AdminStudentsComponent,
        AdminFacturasComponent,
        AdminPaymentComponent,
        AdminPromoComponent,
        ReplaceTags,
        ToLocalTime,
        ReplaceDot,
        DigitSpacingPipe,
        TeacherComponent,
        StudentsComponent,
        EditStudentComponent,
        EditClassComponent,
        SessionComponent,
        EditSessionComponent,
        EditAuthComponent,
        CalendarComponent,
        CalDictationsComponent,
        CalCustomizedSessionComponent,
        CalReadingTextComponent,
        CalHearingTextComponent,
        NewStudentComponent,
        ConfigComponent,
        ActivitiesComponent,
        ConfigMailComponent,
        EditConfigSessionComponent,
        ExamsComponent,
        DictationsComponent,
        GamesComponent,
        ZoneConfigComponent,
        ConfigActivitiesComponent,
        OptionsComponent,
        SchoolReportComponent,
        MessagesComponent,
        HelpComponent,
        ParentHelpComponent,
        TooltipComponent,
        AdminReportComponent,
        StudentComponent,
        ReadingTextComponent,
        HearingTextComponent,
        PlayHearingTextComponent,
        PlayComponent,
        ParentComponent,
        ParentStudentsComponent,
        EditParentStudentsComponent,
        AuthParentStudentComponent,
        NewParentStudentComponent,
        ConfigParentsComponent,
        ConfigParentsMailComponent,
        ParentReviewSessionComponent,
        ParentReportComponent,
        LicenseComponent,
        ReadingComponent,
        MyAccountComponent,
        ReadingComponent,
        HearingComponent,
        DictationComponent,
        PlayDictationComponent,
        PlankComponent,
        StudentSession,
        DailyTextComponent,
        TransactionsComponent,
        StudentReportComponent,
        StudentGamesComponent,
        ChallengeComponent,
        RewardsComponent,
        ShopComponent,
        ContactComponent,
        StudentHelpComponent,
        ReviewTheoryComponent,
        GameComponent,
        DemandComponent,
        EcoembesComponent,
        ExerciseComponent,
        GuionpComponent,
        GuionfComponent,
        Boxes2Component,
        Boxes2bComponent,
        Boxes3Component,
        MarcaComponent,
        MarcarComponent,
        PreguntaComponent,
        UnirComponent,
        Unir2Component,
        Unir3Component,
        MLLuviaComponent,
        ParejasComponent,
        Unir4Component,
        Unir4aComponent,
        Unir5Component,
        Unir6Component,
        Boxes2cComponent,
        Boxes3cComponent,
        Boxes4cComponent,
        DefineComponent,
        Mod1Component,
        FlechasComponent,
        SelecComponent,
        OrdenComponent,
        ColocaComponent,
        Mod2Component,
        Mod3Component,
        Marca1Component,
        VerbosComponent,
        RapidComponent,
        AhorcaComponent,
        CruciComponent,
        MarcaTComponent,
        MarcaFComponent,
        ColocafComponent,
        SelecfComponent,
        NumerComponent,
        BoxesnComponent,
        SopaComponent,
        ImgComponent, 
        PasapComponent, 
        Pasap1Component, 
        Sopa1Component, 
        Sopa2Component, 
        Verbos1Component, 
        AhorcafrComponent, 
        FraseComponent, 
        RimaComponent, 
        AhorcaiComponent, 
        AhorcarComponent, 
        Coloca1Component,
        Opc1Component,
        RenewLicenseComponent,
        PlayGameComponent,
        DeductionTextComponent,
        PlayDeductionComponent,
        CalDeductionTextComponent,
        ZoneComponent,
        DeductionComponent,
        AvatarShopComponent,
        DeductionComponent,
        AvatarFileComponent,
        VisualMemoryComponent,
        DumbComponent,
        SiglasComponent,
        ComplementsComponent,
        ControlSessionComponent,
        MiniExerciseComponent,
        ScenaryComponent,
        PersonalizedTestComponent,
        PersonalizedTestChatGPTPopupComponent,
        PersonalizedTestJoinTestPopupComponent,        
        DictationReviewComponent,
        DeductionReviewComponent,
        ReadingReviewComponent,
        HearingReviewComponent,
        SessionReviewComponent,
        PersonalizedTestStudentComponent,
        ProgressComponent,
        TrackingComponent,
        MiniTestComponent,
        WaliLetrasComponent,
        ChallengeConfigComponent,
        DictConfigComponent,
        ParentReviewComponent,
        ParentReviewDictationComponent,
        ParentReviewReadingTextComponent,
        ParentReviewHearingTextComponent,
        ParentReviewDeductionTextComponent,
        MessagePopupComponent,
        WalinwaUpdateComponent,
        AdminClassroomComponent,
        AdminClassroomClientComponent,
        AdminClassroomDataComponent,
        MiniGamesComponent,
        WaliLeeComponent,
        MapSectionComponent,
        WalileeTestComponent,
        WalileeListComponent,
        WalileeLibraryComponent,
        WalileeComponent,
        WalileeResultsComponent,        
        ComprehensionsComponent,
        TestPlankComponent
    ],
    imports: [
        SharedModule,
        MatTabsModule,
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { onSameUrlNavigation: "reload" }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (HttpLoaderFactory),
                deps: [HttpClient]
            },
        }),
        MatMomentDateModule,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        SpinnerModule,
        CoreModule,
        LayoutModule,
        LoginModule,
        InitialRegistrationModule,
        FreeTrialModule,
        RequestResetPasswordModule,
        ResetPasswordModule,
        OnlineStatusModule,
        DragDropModule,
        MatDialogModule,
        FooterAuthModule,
        TpvModule,
        RedirectModule,
        MatTreeModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          registrationStrategy: 'registerImmediately'
        }),
        DxDataGridModule,
        DxBulletModule,
        DxTemplateModule,
        DxSelectBoxModule,
        DxButtonModule/*,
        QuestionaireModule*/
    ],
    providers: [
        AuthGuardService,
        AppService,
        { provide: MatMomentDateModule, useValue: "es-ES" },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptorService,
            multi: true,
        },
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        {
            provide: MatDialogRef,
            useValue: {}
        },        
        GlobalService,
        VersionService,
        TestService,
        SharedService,
        AccountService,
        SchoolService,
        UserService,
        ClassService,
        RetoService,
        CourseService,
        PaymentService,
        LicenseService,
        CustomizedSessionService,
        SessionService,
        ExerciseService,
        DictationService,
        TestExerciseService,
        EmailConfigService,
        ReportService,
        Test2Service,
        TeacherMessageService,
        NavigationService,
        AuthService,
        ReadingTextService,
        HearingTextService,
        ShopService,
        AdminMessageService,
        DailyTextService,
        CoinService,
        HistoryStageService,
        CivilizationService,
        ExtraRewardService,
        MedalService,
        GameService,
        AnnouncementService,
        HelpService,
        WaliLetrasService,
        WaliLeeService,
        ImageService,
        //QuestionaireService,
        FileService,
        SortByPipe,
        LogService,
        {
            provide: ErrorHandler,
            useClass: CustomErrorHandler
        },
        { provide: APP_INITIALIZER, useFactory: init, deps: [ApplicationRef, SwUpdate, WalinwaUpdateService], multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new MultiTranslateHttpLoader(httpClient, [
        { prefix: `${environment.filesUrl}/i18n/`, suffix: `.json?date=${new Date().getTime()}` }
        //{ prefix: './assets/i18n/', suffix: '.json' }
    ])
}