import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SchoolService } from 'app/services/school.service';
import { UserService } from 'app/services/user.service';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { AuthService } from 'app/services/auth.service';
import * as moment from 'moment';
import { NavigationService } from 'app/services/navigation.service';
import { DictationService } from 'app/services/dictation.service';
import { CourseService } from 'app/services/course.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
declare var $;

@Component({
    selector: "app-dictations",
    templateUrl: "./dictations.component.html",
    styleUrls: ["./dictations.component.scss"],
    encapsulation: ViewEncapsulation.None,
})

export class DictationsComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
    user;
    selectedDay;
    currentMonth;
    dictations;
    hasExercise;
    selectedCourse;
    courses;
    licenseDate;
    afterLicense;

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private schoolService: SchoolService,
    private matDialog: MatDialog,
    private dictationService: DictationService,
    private navigationService: NavigationService,
    private courseService: CourseService,
  ) {
    this._unsubscribeAll = new Subject();
    moment.locale('es')
  }

  ngOnDestroy(): void
  {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
  }

  ngOnInit() {
    if (!this.authService.check()) return;
    this.selectedDay = moment();
    this.currentMonth = this.selectedDay.month() + 1;
    this.user = this.authService.currentUser;
    this.licenseDate = this.schoolService.school.ValidUntil;
    this.courseService.Courses.pipe(takeUntil(this._unsubscribeAll)).subscribe(courses => {
        this.courses = courses;
        if (this.courses) {
            this.selectedCourse = this.courses[0].Level;
        }
    })
    this.checkDictation(true);
    }

  selectedDayChangedDictation(newDate, canDoIt) {
    this.selectedDay = newDate;
    if (this.authService.currentUser.Id == 10) {
        this.afterLicense = false;
        canDoIt = true;
    }
    else {
        this.afterLicense = this.selectedDay.isAfter(moment(this.licenseDate));
    }
    this.checkDictation(canDoIt);
}
  checkDictation(canDoIt = false) {
    this.hasExercise = false;
    let day = this.selectedDay.day();
    if(day == 0) day = 7;
    this.dictationService.getDictationInfo(this.selectedCourse, this.selectedDay.week(), day)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        this.hasExercise = canDoIt;
      }
    )
  }

  showDictation() {
    let day = this.selectedDay.day();
    if(day == 0) day = 7;
    this.navigationService.go(this.router.url + "/" + this.selectedCourse, {selectedDay: this.selectedDay})
  }
  sixWeeks() {
    return $('.week').length - 1 >= 6;
  }

  hasValue(time) {
    return time != null && time != "";
  }
}
