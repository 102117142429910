import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CalHearingTextComponent } from 'app/components/cal-hearing-text/cal-hearing-text.component';
import { CalendarHearingText } from 'app/core/shared/state/models/Hearing-text/hearing-text-month.model';
import { HearingTextService } from 'app/services/hearing-text.service';
import { NavigationService } from 'app/services/navigation.service';
import { UserService } from 'app/services/user.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
declare var $;
@Component({
    selector: 'app-parent-review-hearing-text',
    templateUrl: './parent-review-hearing-text.component.html',
    styleUrls: ['./parent-review-hearing-text.component.scss']
})
export class ParentReviewHearingTextComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    @ViewChild(CalHearingTextComponent) childHearing: CalHearingTextComponent;
    selectedDayCA: any;
    currentMonthCA: any;
    year: any;
    hearingTexts: CalendarHearingText[];
    six_weeks_hearing
    student: any;
    showReviewCA: boolean;
    activeButton = false;
    IdUser: any;


    constructor(
        private navigationService: NavigationService,
        private hearingTextService: HearingTextService,
        private ref: ChangeDetectorRef,
        private userService: UserService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit() {
        this.selectedDayCA = moment();
        this.currentMonthCA = this.selectedDayCA.month() + 1;
        this.year = this.selectedDayCA.year();
        if (this.navigationService.params.IdUser)
            this.IdUser = this.navigationService.params.IdUser;
        if (this.navigationService.params.student) {
            this.student = this.navigationService.params.student
            this.getHearings();
        }
        else {
            this.getUser(this.IdUser);
        }
    }
    getUser(id) {
        this.userService.getUserById(id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                result => {
                    if (result == null) return this.navigationService.exit();
                    this.student = result;
                    this.navigationService.params.student = result;
                    this.getHearings();
                },
                this.errorCallback.bind(this)
            )
    }

    getHearings() {
        this.hearingTexts = [];
        this.ref.detectChanges();
        this.hearingTextService.getHearingTextByMonth(this.student?.Id, this.currentMonthCA, this.selectedDayCA.year(), this.student?.Level, this.student?.IdRole, this.student?.CreationDate)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                result => {
                    if (result == null) return this.navigationService.exit();
                    this.hearingTexts = result;
                    this.hearingTextService.MonthlyHearing = result;
                    this.childHearing.refresh((weeks_count) => {
                        this.six_weeks_hearing = weeks_count >= 6
                    })
                },
                this.errorCallback.bind(this)
            )
    }

    errorCallback() {
        this.navigationService.go("/school/parentStudents");
    }

    sixWeeks() {
        return $(".week").length - 1 >= 6;
    }

    selectedDayChangedCA(newDate) {
        this.selectedDayCA = newDate;
        this.showReviewCA = false;
        if (!this.hearingTexts) return;
        if (this.currentMonthCA != this.selectedDayCA.month() + 1) {
            this.currentMonthCA = this.selectedDayCA.month() + 1;
            if (this.childHearing)
                this.six_weeks_hearing = this.childHearing.weeks.length >= 6;
            this.getHearings();
        }

        let currentHearingText = this.hearingTexts.find((r) =>
            moment(r.date).isSame(this.selectedDayCA)
        );

        if (!currentHearingText) return;
        if (currentHearingText.Score == null) return;
        if (currentHearingText.FinishDate)
            this.showReviewCA = true;
        this.activeButton = true;
    }

    startReview() {
        if (!this.hearingTexts) return;
        let currentHearingText = this.hearingTexts.find((r) =>
            moment(r.date).isSame(this.selectedDayCA)
        );
        if (!currentHearingText) return;
        this.navigationService.go(this.router.url + "/review",
            {
                IdUser: this.student.Id,
                review: true,
                date: currentHearingText.date,
                parentReview: true,
                student: this.student,
                IdHearingText: currentHearingText.IdHearingText
            }
        );
    }
}