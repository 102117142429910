<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="mod3" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" fxLayout="column" fxLayoutAlign="stretch">

    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div class="wordsContainer" [ngClass]="{'fixControlSessions': controlSession}">
        <div class="items_container" [ngClass]="{'reviewing': review}">
            <span class="epalabras" [ngClass]="{
                naranja: palabra.notAnserwed == true,
                bien: palabra.answered == true && !palabra.notAnserwed,
                mal: palabra.answered == false, 'exercise': !review, 'reviewing': review
            }" (click)="validar(palabra)" [ngStyle]="!review ? getWordPosition(palabra) : null"
                *ngFor="let palabra of exercise.palabras; let i = index">
                {{ getPalabra(palabra) }}
            </span>
        </div>
    </div>
</div>
<!--  -->
<!-- <div class="mod3" [ngStyle]="{'pointer-events': review ? 'none;' : ''}">   <h2 *ngIf="controlSession" class="doc-number">{{current + 1}}</h2>
    <div *ngIf="!controlSession" class="exerciseTheme">
        <h1 [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></h1>
    </div>
    <div class="text-center exerciseTitle"  [ngClass]="{'title' : controlSession,'exerciseTitle-slide': slide}">
        <p   [innerHtml]="exercise.enunciado"></p>
    </div>
    <div class="text-center wordsContainer" [ngClass]="{'words-container-slide': slide, 'words-container-session': !slide && controlSession }" [ngStyle]="controlSession && !slide? {height: '50vh'} : {}">
        <span [ngClass]="{
              naranja: palabra.notAnserwed == true,
              bien: palabra.answered == true && !palabra.notAnserwed,
              mal: palabra.answered == false
          }" (click)="validar(palabra)" [ngStyle]="controlSession  && !slide ? {margin: '.5em', 'font-size': '1.2em;','padding': '0.25em 3em;','border-radius': '2em;'} : getWordPosition(palabra)" *ngFor="let     palabra of exercise.palabras; let i = index">
            {{ getPalabra(palabra) }}
        </span>
    </div>
</div> -->