import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { ILogMessage, LogEventLevel } from 'app/core/logging/log-models';

@Injectable()
export class LogService {

    baseUrl = environment.baseApi + '/api/Log';

    constructor(private http: HttpClient) {
    }

    public logMessage(message: string, level: LogEventLevel = LogEventLevel.Information, MustLog = false): Observable<any> {
        const log: ILogMessage = {
            message: message,
            level: level,
            MustLog
        };

        return this.http.post(`${this.baseUrl}/LogMessage`, log);
    }
}
