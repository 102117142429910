import { ReadingTextResult } from './../../../../core/shared/state/models/Reading-text/reading-text-result.model';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { SharedService } from 'app/core/shared/shared.service';
import { ReadingText } from 'app/core/shared/state/models/Reading-text/reading-text.model';
import { AuthService } from 'app/services/auth.service';
import { NavigationService } from 'app/services/navigation.service';
import { ReadingTextService } from 'app/services/reading-text.service';
import { Subject } from 'rxjs'
import { first, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { LogService } from 'app/services/log.service';
import { LogEventLevel } from 'app/core/logging/log-models';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-play-reading-text',
    templateUrl: './play.component.html',
    styleUrls: ['./play.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PlayComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
    private _loggingSteps: string[] = [];
    private _idUser: number = null;

    teacherReview = false;
    review = false;
    readingText: ReadingText;
    questionIndex: number;
    form: UntypedFormGroup;
    finished = false;
    score: ReadingTextResult;
    loading: boolean;
    walinwos: number;
    correct: boolean;
    messages = [];
    selectedDay: any;
    IdReadingText: number;
    eventsSubject: Subject<object> = new Subject<object>();
    confirmDialog: MatDialogRef<FuseDialogContinueComponent>;
    nuevoFondo: boolean = false;
    retry = false;
    TotalUserAnswers = 0;
    TotalOkUserAnswers = 0;
    reviewingActivity: boolean = false;
    directory = "https://walinwa.blob.core.windows.net/images/Comprensiones/"
    constructor(
        private activatedRoute: ActivatedRoute,
        private readingTextService: ReadingTextService,
        private authService: AuthService,
        private navigationService: NavigationService,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private _matDialog: MatDialog,
        private sharedService: SharedService,
        private _logService: LogService,
        private _detector: ChangeDetectorRef,
        private _translateService: TranslateService
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy() {
        this.readingTextService.MonthlyReadingTexts = null;
        this.readingTextService.changeReloadMonthlyReading(true);
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
      }

    async ngOnInit() {
        if (!this.authService.check()) {
            return this.navigationService.exit();
        }
    
        const navParams = this.navigationService.params;
        if (navParams) {
            this.selectedDay = navParams.date || this.selectedDay;
            this.IdReadingText = navParams.IdReadingText || this.IdReadingText;
        }
    
        const params = await this.activatedRoute.params.pipe(first()).toPromise();
        if (params?.IdReadingText && params?.Date) {
            this.IdReadingText = params.IdReadingText;
            this.selectedDay = params.Date;
        }
    
        this.reviewingActivity = navParams?.reviewing === true;
    
        this.getReadingText(this.IdReadingText, this.selectedDay);
    }

    getReadingText(IdReadingText: number, selectedDate: any) {
        this.loading = true;
        this._detector.detectChanges();
        let idUser = this.authService.currentUser.Id;

        if (this.navigationService.params.IdUser) {
            idUser = this.navigationService.params.IdUser;
            this._idUser = idUser;
            this.teacherReview = true;
            this.review = true;
        } else if (this.router.url.includes("review")) {
            this.teacherReview = true;
            this.review = true;
        }

        if (this.navigationService.params) {
            if (this.navigationService.params.review == true) {
                this.review = true;

                if (!this.teacherReview)
                    this._idUser = idUser;
            }

            if (this.navigationService.params.idUser) {
                idUser = this.navigationService.params.idUser;
                this._idUser = idUser;
            }            
        }

        this.readingTextService.getReadingTextByid(IdReadingText, idUser, selectedDate)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result) => {
                    this.readingText = result;
                    //console.log(result)
                    this._loggingSteps.push(`Valor del objeto readingText al iniciar: ${JSON.stringify(this.readingText)}`);
                    this.SuccesLoadInitText()
                },
                error => {
                    this.loading = false;
                    this.errorCallback(error.error)
                })

    }

    async SuccesLoadInitText() {
        this.checkUserAnswers();

        const allAnswered = this.TotalUserAnswers === this.readingText.Questions.length;

        if (this.readingText.Score > 0 || allAnswered) {
            this.review = true;

            if (!this.teacherReview && allAnswered)
                this._idUser = this._idUser ?? this.authService.currentUser.Id;
        }

        this.ParseFragment();
        this.walinwos = this.readingText.Questions.length * 10;

        if (this.review) {
            if (!this.readingText.MarkedAsFinished && this._idUser !== null)
                await this.readingTextService.getScore(this.readingText.IdReadingText, this._idUser, this.readingText.date).pipe(takeUntil(this._unsubscribeAll), tap(r => this.readingText.Score = r.Score)).toPromise();

            this.loading = false;

            return;
        }

        this.form = this.formBuilder.group({
            IdQuestion: [""],
            IdAnswer: ["", Validators.required],
        });

        this.loading = false;

        if (this.TotalUserAnswers > 0) {
            this.showMessage();
        } else {
            this.questionIndex = -1;
            this._loggingSteps.push(`questionIndex inicial: ${this.questionIndex}`);
        }
    }
    ParseFragment() {
        const text = this.readingText.Fragment;
        const paragraphs = text.split(/\r\n|\r|\n/);
        const htmlParagraphs = paragraphs.map(paragraph => `<p>${paragraph}</p>`);
        const htmlText = htmlParagraphs.join('');
        this.readingText.Fragment = htmlText;
    }

    errorCallback(error: any) {
        this.loading = false;
        if (error == "TooOld") {
            let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
                disableClose: true
            });
            confirmDialog.componentInstance.title = this._translateService.instant('STUDENT.READING-TEXT.START-READING-DIALOG-TITLE');
            confirmDialog.componentInstance.message1 = this._translateService.instant('STUDENT.READING-TEXT.CANT-RECOVER-READING-FROM-DATE');
            confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
            confirmDialog.componentInstance.margin = false;
            confirmDialog.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                this.router.navigate(['/student/reading-text']);
                confirmDialog = null;
            });
            return;
        }
        else {
            this._logService.logMessage(`Comprensión, error del backend ${JSON.stringify(error)}. Registro de pasos -> ${JSON.stringify(this._loggingSteps)}`, LogEventLevel.Error, true).subscribe(); 
            this.finish();
        }
    }

    check() {
        if (this.questionIndex == -1) return true;
        return this.form.valid;
    }

    save() {        
        let answer = this.readingText.Questions[this.questionIndex].Answers.find(x => x.IdAnswer == this.form.value.IdAnswer)
        this.loading = true;
        this._detector.detectChanges();
        this._loggingSteps.push(`Se intenta guardar la pregunta con IdQuestion ${this.form.value.IdQuestion} e IdAnswer ${this.form.value.IdAnswer} correspondiente al questionIndex ${this.questionIndex}`);

        this.readingTextService.answer(this.readingText.IdReadingText, this.form.value.IdQuestion, this.form.value.IdAnswer, this.authService.currentUser.Id, answer.IsCorrect)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: boolean) => {
                if (!result) {
                    this._showDialog(this._translateService.instant('STUDENT.TEXT-EXERCISES.WARNING-TEXT'), this._translateService.instant('STUDENT.TEXT-EXERCISES.ERROR-SAVING-ANSWER-TEXT'));
                    this._logService.logMessage(`Comprensión, error. { logDePasos: ${JSON.stringify(this._loggingSteps)} }`, LogEventLevel.Error, true).subscribe();                    

                    return;
                }

                const numQuestions = this.readingText.Questions.length - 1;
                this._loggingSteps.push(`Se guarda correctamente la pregunta con questionIndex ${this.questionIndex} de ${numQuestions}`);

                if (!answer.IsCorrect) this.walinwos -= 10;

                if (this.questionIndex == numQuestions) {
                    this._loggingSteps.push(`Se han respondido todas las preguntas. Se va a obtener el Score`);
                    this.getScore(answer.IsCorrect);
                
                } else if (this.questionIndex < numQuestions) {
                    this.loading = false;
                    this.correct = answer.IsCorrect;

                } else {
                    this.correct = answer.IsCorrect;
                    this._loggingSteps.push(`Comprensión, error en indice: metodo save() indice ${this.questionIndex} total ${numQuestions} ID ${this.readingText.IdReadingText}`);
                    this._logService.logMessage(`Comprensión, error. { logDePasos: ${JSON.stringify(this._loggingSteps)} }`, LogEventLevel.Error, true).subscribe();
                }                
            },
            error => {
                this.errorCallback(error);
            }
        )
    }

    getDate() {
        if (!this.readingText) return "";
        let meses = [
            this._translateService.instant('MONTHS.JANUARY'),
            this._translateService.instant('MONTHS.FEBRUARY'),
            this._translateService.instant('MONTHS.MARCH'),
            this._translateService.instant('MONTHS.APRIL'),
            this._translateService.instant('MONTHS.MAY'),
            this._translateService.instant('MONTHS.JUNE'),
            this._translateService.instant('MONTHS.JULY'),
            this._translateService.instant('MONTHS.AUGUST'),
            this._translateService.instant('MONTHS.SEPTEMBER'),
            this._translateService.instant('MONTHS.OCTOBER'),
            this._translateService.instant('MONTHS.NOVEMBER'),
            this._translateService.instant('MONTHS.DECEMBER')
        ];
        let dayNames = [
            this._translateService.instant('DAYS.MONDAY'),
            this._translateService.instant('DAYS.TUESDAY'),
            this._translateService.instant('DAYS.WEDNESDAY'),
            this._translateService.instant('DAYS.THURSDAY'),
            this._translateService.instant('DAYS.FRIDAY'),
            this._translateService.instant('DAYS.SATURDAY'),
            this._translateService.instant('DAYS.SUNDAY'),
        ];
        let d = new Date(this.selectedDay);
        let dia = d.getDate();
        let mes = d.getMonth();
        let ano = d.getFullYear();
        let dayIndex = d.getDay() - 1;
        if (dayIndex == -1) dayIndex = 6;
        let dayName = dayNames[dayIndex];
        return this._translateService.instant('STUDENT.READING-TEXT.PLAY.DATE-SHOW', {weekDayName: dayName, day: dia, month: meses[mes]});
    }

    showMessage() {
        this.confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });
        this.confirmDialog.componentInstance.title = this._translateService.instant('STUDENT.HEARING-TEXT.PLAY.COMPREHENSION-LABEL');
        this.confirmDialog.componentInstance.message1 = this._translateService.instant('STUDENT.TEXT-EXERCISES.RESTART-CONTINUE-ADVICE');
        this.confirmDialog.componentInstance.space = true;
        this.confirmDialog.componentInstance.margin = false;
        this.confirmDialog.componentInstance.options = [{
            text: this._translateService.instant('STUDENT.TEXT-EXERCISES.RESTART-BUTTON'),
            callback: () => {
                this.questionIndex = -1;
                this._loggingSteps.push(`Reiniciar: questionIndex inicial: ${this.questionIndex}`);
                this.retry = true;
            }
        }, {
            text: this._translateService.instant('CONTINUE'),
            callback: () => {
                this.questionIndex = this.readingText.Questions.findIndex(x => x.Answers.every(x => !x.UserAnswer));
                this._loggingSteps.push(`Continuar: questionIndex inicial: ${this.questionIndex}`);
                this.form.get('IdQuestion').setValue(this.readingText.Questions[this.questionIndex].IdQuestion);
            }
        }];
        this.confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
            result();
            this.confirmDialog = null;
        });
    }

    next() {
        if (this.questionIndex > -1 && !this.check()) return;

        if (!this.loading && this.correct !== true && this.correct !== false) {
            this.loading = true;
            this.correct = null;
            this._detector.detectChanges();

            switch (this.questionIndex) {
                case -1:
                    this.goNext(true);

                    break;
            
                case 0:
                    if (this.retry) {
                        this.restart();
                    } else {
                        this.start();
                    }

                    break;
                default:
                    this.save();

                    break;
            }
        }
    }

    checkUserAnswers() {
        this.TotalOkUserAnswers = 0;
        this.TotalUserAnswers = 0;
        this.readingText.Questions.forEach(pregunta => {
            pregunta.Answers.forEach(respuesta => {
                if (respuesta.UserAnswer) {
                    this.TotalUserAnswers++;
                    if (respuesta.IsCorrect) {
                        this.TotalOkUserAnswers++;
                    }
                }
            })
        });
    }

    restart() {
        this.loading = true;
        this._detector.detectChanges();
        this.checkUserAnswers();
        this._loggingSteps.push(`Se intenta reiniciar el ejercicio con TotalUserAnswers ${this.TotalUserAnswers} e IdAnswer ${this.form.value.IdAnswer} y TotalOkUserAnswers ${this.TotalOkUserAnswers}`);

        this.readingTextService.restart(this.readingText.IdReadingText, this.authService.currentUser.Id, this.TotalUserAnswers, this.TotalOkUserAnswers, this.readingText.Retries)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._loggingSteps.push(`Se reinicia correctamente el ejercicio`);
                this.save();
            },
            error => {
                this.errorCallback(error);
            });
    }

    start() {
        this.loading = true;
        this._detector.detectChanges();
        let answer = this.readingText.Questions[this.questionIndex].Answers.find(x => x.IdAnswer == this.form.value.IdAnswer);
        this._loggingSteps.push(`Se intenta iniciar el ejercicio con IdQuestion ${this.readingText.Questions[this.questionIndex].IdQuestion} e IdAnswer ${this.form.value.IdAnswer} correspondiente al questionIndex ${this.questionIndex}`);

        this.readingTextService.start(this.readingText.IdReadingText, this.authService.currentUser.Id, this.readingText.date, this.readingText.Questions.length, this.readingText.Questions[this.questionIndex].IdQuestion, this.form.value.IdAnswer, answer.IsCorrect)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: boolean) => {
                if (!result) {
                    this._showDialog(this._translateService.instant('STUDENT.TEXT-EXERCISES.WARNING-TEXT'), this._translateService.instant('STUDENT.TEXT-EXERCISES.ERROR-SAVING-ANSWER-TEXT'));
                    this._logService.logMessage(`Comprensión, error. { logDePasos: ${JSON.stringify(this._loggingSteps)} }`, LogEventLevel.Error, true).subscribe(); 

                    return;
                }

                const numQuestions = this.readingText.Questions.length - 1;
                this._loggingSteps.push(`Se inicia correctamente el ejercicio con questionIndex ${this.questionIndex} de ${numQuestions}`);

                if (!answer.IsCorrect) this.walinwos -= 10;
                
                if (this.questionIndex == numQuestions) {
                    this.getScore(answer.IsCorrect);

                } else if (this.questionIndex < numQuestions) {
                    this.loading = false;
                    this.correct = answer.IsCorrect;

                } else {
                    this.correct = answer.IsCorrect;
                    this._loggingSteps.push(`Comprensión, error en indice: metodo start() indice ${this.questionIndex} total ${numQuestions} ID ${this.readingText.IdReadingText}`);
                }
            },
            error => {
                this.errorCallback(error);
            })
    }

    wasSelected(answer: any) {
        return answer.UserAnswer;
    }

    goNext(forceExecution: boolean) {

        if (!this.loading || forceExecution) {
            this.loading = true;
            this.correct = null;
            this._detector.detectChanges();

            if (forceExecution)
                this._loggingSteps.push(`goNext: el origen de la llamada es interno`);
            else
                this._loggingSteps.push(`goNext: el origen es la llamada es el botón del resultado de la pregunta`);

            if (this.questionIndex == this.readingText.Questions.length - 1) {
                this.showScore()
            } else {
                this.questionIndex++;
                this._loggingSteps.push(`goNext: questionIndex pasa a valer: ${this.questionIndex}`);
                let question = this.readingText.Questions[this.questionIndex];

                if (!question) {
                    this._loggingSteps.push(`Comprensión, error en indice: metodo goNext() indice ${this.questionIndex} total ${this.readingText.Questions.length - 1} ID ${this.readingText.IdReadingText}`);
                    this._logService.logMessage(`Comprensión, error. { logDePasos: ${JSON.stringify(this._loggingSteps)} }`, LogEventLevel.Error, true).subscribe();
                    this.form.patchValue({ IdQuestion: '', IdAnswer: '' });
                    this.form.disable();
                    this.loading = false;

                    return;
                }

                this.readingText.Questions[this.questionIndex].Answers = this.shuffle(this.readingText.Questions[this.questionIndex].Answers);
                this.form.patchValue({ IdQuestion: question.IdQuestion, IdAnswer: "" });
                this.loading = false;
            }
        }
    }

    shuffle(array) {
        let currentIndex = array.length, temporaryValue, randomIndex;
        while (0 !== currentIndex) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array;
    }

    getScore(answerIsCorrect: boolean) {
        this.loading = true;
        this.form.disable();
        this._detector.detectChanges();

        this.readingTextService.getScore(this.readingText.IdReadingText, this.authService.currentUser.Id, this.readingText.date)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
            result => {
                this.sharedService.changeUserCoins(true);
                
                if (result.nuevoFondo) {
                    this.nuevoFondo = true;
                }

                this.score = result;
                this.correct = answerIsCorrect;
                this.loading = false;
            },
            error => {
                this.errorCallback(error);
            }
        );
    }

    finish() {
        if (this.navigationService.params.parentReview) {
            this.navigationService.go("/school/parentStudents/review/readingTexts", {
                IdUser: this.navigationService.params.IdUser,
                student: this.navigationService.params.student,
            });
            return;
        } else if (this.teacherReview && !this.navigationService.params.isAdmin) {
            if (location.href.includes("student/")) {
                this.readingTextService.changeReloadMonthlyReading(true);
                this.navigationService.go("/student/reading-text");
                return
            }
            if (this.navigationService.params.IdUser) {
                this.navigationService.go('/school/session/' + this.navigationService.params.IdUser + '/' + this.navigationService.params.IdClass + '/readingReview/' + this.navigationService.params.IdUser + '/' + this.navigationService.params.IdClass, { IdClass: this.navigationService.params.IdClass, navigate: 'reading', IdUser: this.navigationService.params.IdUser });
            } else {
                this.navigationService.go("/school/activities/reading");
            }
        } else if (this.teacherReview && this.navigationService.params.isAdmin) {
            this.navigationService.go('/school/admin/students/null/sessionAdmin/' + this.navigationService.params.IdUser + '/' + this.navigationService.params.IdClass + '/readingReview/' + this.navigationService.params.IdUser + '/' + this.navigationService.params.IdClass, { IdUser: this.navigationService.params.IdUser, IdClass: this.navigationService.params.IdClass, navigate: 'reading', isAdmin: true });
        } else {
            this.readingTextService.changeReloadMonthlyReading(true);
            this.navigationService.go("/student/reading-text");
        }

        this.fixPositionScroll_vertical_ClassReturn();
    }

    showScore() {
        this.correct = null;
        this.finished = true;
        setTimeout(() => {
            this.messages = [
                this._translateService.instant('STUDENT.TEXT-EXERCISES.CORRECT-COUNT', {correct: this.score.NumOk, total: this.score.NumExercises})
            ];
            if (this.score.Retries > 0) this.messages.push(this._translateService.instant('STUDENT.TEXT-EXERCISES.REPEATING-PENALTY'));
        }, 1000)
    }

    getBookName(book) {
        if (book.charAt(book.length - 1) == "!" || book.charAt(book.length - 1) == "?") {
            return book;
        }
        else {
            return book + ".";
        }
    }

    fixPositionScroll_vertical_ClassReturn() {
        const elements = document.querySelectorAll('.iphonechromepopup');
        elements.forEach((element) => {
            // (element as HTMLElement).style.overflow = 'inherit';
            element.classList.remove("popup");
        });
        //console.log("retornar scroll_fixed");
    }

    private _showDialog(title: string, message: string): void {
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });
        confirmDialog.componentInstance.title = title;
        confirmDialog.componentInstance.message1 = message;
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
        confirmDialog.componentInstance.margin = false;
        confirmDialog.afterClosed()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(result => {
            this.router.navigate(['/student/reading-text']);
            confirmDialog = null;
        });
    }
}
