import { ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'app/services/session.service';
import { UserService } from 'app/services/user.service';
import * as moment from 'moment';
import { NavigationService } from 'app/services/navigation.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
declare var $;

@Component({
    selector: 'app-parent-review-session',
    templateUrl: './parent-review-session.component.html',
    styleUrls: ['./parent-review-session.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ParentReviewSessionComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
    student;
    NotDone = 0;
    Started = 3;
    Finished = 1;
    Pending = 2;
    NotFinished = 4;
    showReview = false;
    dataLoaded = false;
    status = "sessions";
    selectedDay;
    month;
    year;
    currentMonth;
    currentSessionStatus = null;
    currentSession;
    SessionStatus = {
        finished: "FINISHED",
        started: "STARTED",
        newSession: "NEW",
        generating: "GENERATING",
        notfinished: "NOTFINISHED",
    };
    IdUser: any;
    isAdmin: boolean = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private sessionService: SessionService,
        private navigationService: NavigationService,
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit() {
        if (this.navigationService.params.IdUser)
            this.IdUser = this.navigationService.params.IdUser;
        this.selectedDay = moment();
        this.month = this.selectedDay.month() + 1;
        this.year = this.selectedDay.year();
        if (this.navigationService.params.student) {
            this.student = this.navigationService.params.student
            this.loadSessionMonth();
        }
        else {
            this.getUser(this.IdUser);
        }
    }

    getUser(id: number) {
        this.userService.getUserById(id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                result => {
                    if (result == null) return this.navigationService.exit();
                    this.student = result;
                    this.navigationService.params.student = result;
                    this.loadSessionMonth();
                    ;
                },
                this.errorCallback.bind(this)
            )
    }
    loadSessionMonth() {
        this.sessionService.getSessionByUserMonthYear(this.student.Id, this.month, this.year)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(this.successLoadSessionMonth.bind(this), this.errorCallback.bind(this));
    }

    successLoadSessionMonth(response) {
        if (response == null) return this.navigationService.exit();
        this.student.Sessions = response;
        this.currentMonth = this.month;
        this.dataLoaded = true;
        this.loadCurrentSession();
    }

    loadCurrentSession() {
        this.currentSessionStatus = null;
        if (!this.selectedDay) return;
        for (var i = 0; i < this.student.Sessions.length; i++) {
            if (this.selectedDay.isSame(moment(this.student.Sessions[i].ExecutionDate), "day")) {
                this.sessionService.getSession(this.student.Sessions[i].IdSession)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(this.successLoadSession.bind(this), this.errorCallback.bind(this));
            }
        }
    }

    successLoadSession(response) {
        if (response == null) return this.navigationService.exit();
        this.currentSessionStatus = null;
        this.currentSession = response;
        if (this.currentSession.Status == this.SessionStatus.started) {
            this.currentSessionStatus = this.Started;
            this.showReview = false
        }
        else if (this.currentSession.Status == this.SessionStatus.notfinished) {
            this.currentSessionStatus = this.NotFinished;
            this.showReview = false
        }
        else if (this.currentSession.Status == this.SessionStatus.finished) {
            this.currentSessionStatus = this.Finished;
            this.showReview = !this._isRetrySessionAvailable() && 
                this.currentSession.Exercises != null &&
                this.currentSession.Exercises.length > 0;
        } else if (
            this.currentSession.Status == this.SessionStatus.newSession
        ) {
            this.showReview = false;
            if (moment().diff(this.selectedDay, "days", true) < 0) {
                this.currentSessionStatus = null;
            } else if (moment().diff(this.selectedDay, "days", true) < 1)
                this.currentSessionStatus = this.Pending;
            else this.currentSessionStatus = this.NotDone;
        }
    }

    private _isRetrySessionAvailable(): boolean {
        return this.currentSession && moment(this.currentSession.ExecutionDate).diff(moment().utc(), 'days') === 0 && this.currentSession.RetrieveScore !== null && this.currentSession.RetrieveScore !== undefined && this.currentSession.ScoreNew === null;
    }    

    startReview() {
        if (
            this.currentSessionStatus != this.Finished &&
            this.currentSessionStatus != this.NotFinished
        )
            return;
        this.navigationService.go("student/session/play", {
            session: this.currentSession,
            errors: false,
            parentReview: true,
            student: this.student
        });
    }
    sixWeeks() {
        return $(".week").length - 1 >= 6;
    }

    errorCallback() {
        this.navigationService.go("/school/parentStudents");
    }
    selectedDayChanged(newDate) {
        this.showReview = false;
        this.selectedDay = newDate;
        if (this.month != this.selectedDay.month() + 1) {
            this.month = this.selectedDay.month() + 1;
            this.year = this.selectedDay.year();
            this.loadSessionMonth();
            return;
        }
        this.loadCurrentSession();
    }

    startReviewErrors() {
        if (
            this.currentSessionStatus != this.Finished &&
            this.currentSessionStatus != this.NotFinished
        )
            return;
          
        this.navigationService.go("student/session/play", {
            session: this.currentSession,
            errors: true,
            parentReview: true,
            student: this.student
        });
    }
}
