import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';

declare var google: any;

@Component({
  selector: 'app-admin-classroom-client',
  templateUrl: './admin-classroom-client.component.html',
  styleUrl: './admin-classroom-client.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AdminClassroomClientComponent implements OnInit {
  private _loadedAuth: Subject<boolean> = new Subject<boolean>();  
  
  @Output() readonly onConnect: EventEmitter<string> = new EventEmitter<string>();
  @Output() readonly onError: EventEmitter<any> = new EventEmitter<any>();

  public loadedAuth$: Observable<boolean> = this._loadedAuth.asObservable();
  public formConnect: FormGroup;
  public disabled: boolean = false;

  constructor(private _detector: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this._loadGoogleApi();
  }

  private _loadGoogleApi(): void {
    this.loadedAuth$.subscribe(_ => this._initForm());

    let script = document.createElement('script');

    script.innerHTML = '';
    script.src = 'https://accounts.google.com/gsi/client';
    script.type = 'text/javascript';
    script.async = false;
    script.defer = false;
    script.onload = (() => { this._loadedAuth.next(true); this._loadedAuth.complete(); }).bind(this);

    document.head.appendChild(script);
  }

  private _initForm(): void {
    this.formConnect = new FormGroup({
      clientId: new FormControl(null, [Validators.required, Validators.maxLength(300)])
    });
  }
  
  public connectGoogleClassroom() {
    this.formConnect.markAllAsTouched();

    if (this.formConnect.valid) {
      this.disabled = true;
      this._getTokens();
    }
    
    this._detector.detectChanges();
  }

  /*private _initGoogleAuth() {
    console.log("initGoogle");
    google.accounts.id.initialize({
      client_id: '871559537445-4i22j13m33du4gc622hkrns5idn84t1o.apps.googleusercontent.com',   
      login_uri: 'http://localhost:4200',
      //fetch_basic_profile: false,
      scope: 'https://www.googleapis.com/auth/classroom.courses.readonly',
      callback: this._promptClosed.bind(this)
    });

    google.accounts.id.prompt();
  }*/

  private async _getTokens() {
    google.accounts.oauth2.initTokenClient({
      client_id: this.formConnect.value.clientId,
      scope: 'https://www.googleapis.com/auth/classroom.courses.readonly https://www.googleapis.com/auth/classroom.rosters.readonly https://www.googleapis.com/auth/classroom.profile.emails',
      callback: (response) => {          
        if (response && response.access_token) {
          this.onConnect.emit(response.access_token);
        } else {
          this.onError.emit(response);
        }          
      }
    }).requestAccessToken();
  }
}