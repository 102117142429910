import { MemoImage } from 'app/core/shared/state/models/memo-image.model';
export class MemoImageExcer{
    idMemo: number;
    idUser: number;
    Pass: boolean;
    Date: Date;
    MemoImage?: MemoImage;
    Page?: number;

    constructor(idMemo: number, idUser: number,Pass: boolean,Date: Date, Page?: number){
        this.idMemo = idMemo;
        this.idUser = idUser;
        this.Pass   = Pass;
        this.Date = Date;
        this.Page = Page;
    }
}