<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>
<div class="unir5" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" fxLayout="column" fxLayoutAlign="stretch" [ngClass]="{'fixControlSessions': controlSession}">

    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div class="wordsContainer" [ngClass]="{'fixControlSessions': controlSession}">
        <div class="items_container">
            <div id="row1" [ngClass]="{ 'element-hidden': (review == true && controlSession !== true) || showResult == true}">
                <div *ngFor="let word of exercise.grupo1; let i = index" class="draggable" [ngClass]="{'wordBoxOption': !slide, 'word-box-option-slide': slide, 'textSelected':  i == leftSelected, 'wordBoxError': i == leftError, 
                     'element-hidden': word == null  }" id="col1{{i}}" column="left" [attr.index]="i">
                    <span [innerHtml]="word"></span>
                </div>
            </div>
            <div id="row2" [ngClass]="{ 'element-hidden': (review == true && controlSession !== true) || showResult == true }">
                <div *ngFor="let word of exercise.grupo2; let i = index" class="phraseBox dropzone" [ngClass]="{ 'textSelected':  i == rightSelected ,'wordBoxError': i == rightError,
                     'element-hidden': word == null }" id="col2{{i}}" column="right" [attr.index]="i"
                    [innerHtml]="word">
                </div>
            </div>
            <div [hidden]="!showingSolution && !review && !showResult" id="columnResult" class="wordsResult"
                [ngClass]="{ 'element-hidden':  showResult == false}">
                <div *ngFor="let item of exercise.result" class="wordBox wordBoxResult" id="result{{i}}"
                    [ngClass]="{ 'wordBoxOk':  item.answered, wordBoxNotAnswered: item.answered == null, wordBoxError: item.answered == false}">
                    <span [innerHtml]="replaceI(item.word)"></span>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  -->
<!-- <div class="unir5" [ngStyle]="{'pointer-events': review ? 'none;' : ''}">   <h2 *ngIf="controlSession" class="doc-number">{{current + 1}}</h2>
    <div *ngIf="!controlSession" class="exerciseTheme">
        <h1 [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></h1>
    </div>
    <div class="text-center exerciseTitle" [ngClass]="{'exerciseTitle-slide': slide}">
        <p [innerHtml]="exercise.enunciado"></p>
    </div>
    <div class="optionsContainer">
        <div id="row1" class="wordsContainer" [ngClass]="{ 'element-hidden':  showResult == true}">

            <div *ngFor="let word of exercise.grupo1; let i = index" class="draggable" [ngClass]="{'wordBoxOption': !slide, 'word-box-option-slide': slide, 'textSelected':  i == leftSelected, 'wordBoxError': i == leftError, 
                 'element-hidden': word == null  }" id="col1{{i}}" column="left" [attr.index]="i" >
                 <p [innerHtml]="word" ></p>
            </div>

        </div>
        <div id="row2" class="wordsContainer2 " [ngClass]="{ 'element-hidden':  showResult == true,'words-container2-slide': slide}">

            <div [ngStyle]="controlSession && !slide? {'font-size': '1.4em;'} : {}" *ngFor="let word of exercise.grupo2; let i = index" class="phraseBox dropzone" [ngClass]="{ 'textSelected':  i == rightSelected ,'wordBoxError': i == rightError,
                 'element-hidden': word == null }" id="col2{{i}}" column="right" [attr.index]="i" [innerHtml]="word">
            </div>
        </div>
        <div [hidden]="!showingSolution && !review && !showResult" id="columnResult" class="wordsResult" [ngClass]="{ 'element-hidden':  showResult == false}">
            <div  *ngFor="let item of exercise.result" class="wordBox wordBoxResult" id="result{{i}}" [ngClass]="{ 'wordBoxOk':  item.answered, wordBoxNotAnswered: item.answered == null, wordBoxError: item.answered == false}">
                <p [innerHtml]="replaceI(item.word)"></p>
            </div>
        </div>
    </div>
</div> -->