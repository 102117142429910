import { FileService } from 'app/services/file.service';
import { Component, Input, OnInit, OnDestroy, HostListener, ViewEncapsulation } from '@angular/core';
import { ExerciseService } from 'app/services/exercise.service';
import { environment } from 'environments/environment';
import { SharedService } from 'app/core/shared/shared.service';
declare var $

@Component({
  selector: 'ex-ahorcai',
  templateUrl: './ahorcai.component.html',
  styleUrls: ['./ahorcai.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AhorcaiComponent implements OnInit, OnDestroy {

  @Input() review
  @Input() show_errors;
  @Input() controlSession : boolean = false;
  @Input() slide : boolean = false; @Input() current;
  exercise
  draggingOption
  exerciseIsEnded = false
  finished = false

  secretWord = [];
  errors = 8;
  letters = [{ name: 'a', chosen: false }, { name: 'b', chosen: false }, { name: 'c', chosen: false }, { name: 'd', chosen: false },
  { name: 'e', chosen: false }, { name: 'f', chosen: false }, { name: 'g', chosen: false }, { name: 'h', chosen: false }, { name: 'i', chosen: false },
  { name: 'j', chosen: false }, { name: 'k', chosen: false }, { name: 'l', chosen: false }, { name: 'm', chosen: false }, { name: 'n', chosen: false },
  { name: 'ñ', chosen: false }, { name: 'o', chosen: false }, { name: 'p', chosen: false }, { name: 'q', chosen: false }, { name: 'r', chosen: false },
  { name: 's', chosen: false }, { name: 't', chosen: false }, { name: 'u', chosen: false }, { name: 'v', chosen: false }, { name: 'w', chosen: false },
  { name: 'x', chosen: false }, { name: 'y', chosen: false }, { name: 'z', chosen: false }];


  elementsOk
  elementsErr

    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case "SHOW_SOLUTION":
                this.finished = true;
                if (this.exerciseService.showingSolution) {
                    setTimeout(this.showAnswersTimeOut.bind(this), 100);
                    break;
                } else {
                    this.showSolution();
                    break;
                }
            case "SHOW_ANSWER":
            case "SHOW_CUSTOM":
                this.ShowAnswer();
                break;
        }
    }
  
  constructor(private exerciseService: ExerciseService,
            private fileService: FileService,
            private sharedService: SharedService) {
    this.exercise = this.exerciseService.getExerciseDefinition();
    this.FillSecretWord();
  }
    ngOnDestroy() {
    }

  getImgClass() {

    return 'step' + this.errors;

  }
    getImgStyle() {
        return "url('https://walinwa.blob.core.windows.net/images/Exercises/Ahorca/" + this.errors + ".png')";
    }

  getImg(){
    return this.exerciseService.findImgUrl("Img/" + this.exercise.palabra + ".png")
  }

  try(letter) {

    if (this.finished) return;
    var found = false;
    var finishWord = true;
    let index = -1

    this.letters.forEach((item, letterIndex) => {
      if (item.name == letter.name) {
          item.chosen = true;
          index = letterIndex
      }
    });
    this.secretWord.forEach((item) => {
      if (item.compare == letter.name) {
        item.show = true;
        found = true;
      }
      if (item.show == false)
        finishWord = false;

    });

    if (found == false) {
      this.errors--;
      this.exerciseService.answer(
        index,
        false,
        this.secretWord.length
    );
    }
    else {
        let opts = this.secretWord.length;
        let resolved = this.secretWord.filter(l => l.show == true)
        if (resolved.length == this.secretWord.length) {
            opts = 1
        }
        this.exerciseService.answer(
            index,
            true,
            opts
        );
    }

      if (this.errors == 0 || finishWord) {
        if (this.errors == 0) {
            this.exerciseService.getCurrentExercise().Result = 'ERR'
        }
        else if (finishWord && this.errors > 0) {
            this.exerciseService.getCurrentExercise().Result = 'OK'
        }
      this.exerciseService.endExercise()
      this.finished = true;
      this.showAnswersTimeOut();
    }
  }

  ngOnInit() {
    if (this.review) {
      this.elementsOk = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsOK
      );
      this.elementsErr = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsERR
      );
      this.errors = this.errors - this.elementsErr.length;
      if (this.show_errors == false) {
        this.ShowAnswer();
      } else if (this.show_errors == true || this.show_errors == null) {
        this.ShowAnswer();
      }
    } else {
      this.initInteract();
    }
  }

  showSolution() {
      this.finished = true;
    this.secretWord.forEach((item) => {
      item.show = true;
    });
  }

  ShowAnswer() {
    this.elementsErr.forEach(error => {
        this.letters[error].chosen = true;
    });
    this.elementsOk.forEach(acierto => {
        this.letters[acierto].chosen = true;
    });
    this.secretWord.forEach(palabra => {
        palabra.show = false;
        this.elementsOk.forEach(acierto => {
            if(this.letters[acierto].name == palabra.value){
                palabra.show = true;
            }
        });
    })
}

    ShowAnswerHourGlass() {
        this.errors = 0;
        this.finished = false;
        this.secretWord = []
        this.FillSecretWord();
        for (let i of this.elementsOk) this.try(this.letters[i])
        for (let i of this.elementsErr) this.try(this.letters[i])
    }

    FillSecretWord() {
    if (this.exercise.frase && this.exercise.palabra){
        let regEx = new RegExp(this.exercise.palabra, "ig");
        let palabra = this.exercise.frase.match(regEx)
        if (palabra != null && palabra.length > 0) {
            this.exercise.palabra  = palabra[0]
        }
    }
    
    this.exercise.palabra.split("").forEach((element) => {
      this.secretWord.push({ value: element, compare: this.replaceAccent(element).toLowerCase(), show: false });
    });
  }

  replaceAccent(item) {
    item = item.replace(/á/g, "a");
    item = item.replace(/é/g, "e");
    item = item.replace(/í/g, "i");
    item = item.replace(/ó/g, "o");
    item = item.replace(/ú/g, "u");
    item = item.replace(/ü/g, "u");
    item = item.replace(/Á/g, "A");
    item = item.replace(/É/g, "E");
    item = item.replace(/Í/g, "I");
    item = item.replace(/Ó/g, "O");
    item = item.replace(/Ú/g, "U");
    item = item.replace(/Ü/g, "u");
    return item;
  }

  initInteract() {
    this.exerciseService.initInteract({
      onmove: (event) => {
        this.draggingOption = event.target
      },
      drop: (event) => {

      },
    });
  }

  showAnswersTimeOut() {
    this.secretWord.forEach((letter) => {
      letter.show = true;
    });
  }

}
