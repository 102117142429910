<div class="walinwa-box walinwa-box-green" fxNgClass.gt-sm="scroll_vertical">
    <div class="walinwa-box-header">
        {{'SCHOOL.ADMIN.PROMO.TITLE' | translate}}
    </div>
    <div class="walinwa-box-content" fxLayout="column" fxLayoutAlign="stretch">
        <div>
            {{'SCHOOL.ADMIN.PROMO.INFO1' | translate}}
        </div>
        <div>
            {{'SCHOOL.ADMIN.PROMO.INFO2' | translate}}
        </div>
        <div>
            {{'SCHOOL.ADMIN.PROMO.INFO3' | translate}}
        </div>
        <div>
            <mat-checkbox [(ngModel)]="selected" [ngModelOptions]="{standalone: true}">
                {{'SCHOOL.ADMIN.PROMO.INFO-CHECKBOX1' | translate}} {{ course }}/{{ course + 1 }} {{'SCHOOL.ADMIN.PROMO.INFO-CHECKBOX2' | translate}}<br />
                {{'SCHOOL.ADMIN.PROMO.INFO-CHECKBOX3' | translate}}{{ price }} {{'SCHOOL.ADMIN.PROMO.INFO-CHECKBOX4' | translate}}
            </mat-checkbox>
        </div>
        <div>
            <em>
                {{'SCHOOL.ADMIN.PROMO.INFO4' | translate}}
            </em>
        </div>
        <div fxLayout="row" fxLayout.xs="row wrap" fxLayoutAlign="space-between end" fxLayoutAlign.xs="center center" class="spe_gap">
            <a href="https://www.walinwa.com/doc/acceso_alumnos_codigoaula.pdf" target="_blank" id="pdfFile"
                style="display:none"></a>

            <button (click)="volver()" class="new_but azul">
                {{'CANCEL' | translate}}
            </button>

            <button onclick="document.getElementById('pdfFile').click()" class="new_but azul">
                {{'SCHOOL.ADMIN.PROMO.DOWNLOAD-STUDENTS-GUIDE' | translate}}
            </button>

            <button (click)="finish()" class="new_but azul">
                {{'ACCEPT' | translate}}
            </button>
        </div>
    </div>
</div>