import { Component, OnInit, ViewEncapsulation, HostListener, AfterViewInit, Renderer2, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UiService } from '@fuse/components/spinner/ui.service';
import { SharedService } from 'app/core/shared/shared.service';
import { AuthService as AuthService2 } from 'app/services/auth.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationService } from 'app/services/navigation.service';
import { PaymentService } from 'app/services/payment.service';
import * as moment from 'moment';
import { AppService } from 'app/app.service';
import { ToolbarService } from 'app/services/toolbarService.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';


@Component({
    selector: 'renew-license',
    templateUrl: './renew-license.component.html',
    styleUrls: ['./renew-license.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        '(window:resize)': 'onResize($event)'
    }
})
export class RenewLicenseComponent implements OnInit, AfterViewInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    mobile: boolean = false;
     iPad = /iPad|Macintosh/i.test(navigator.userAgent); mobileDetect;
    renewalForm: UntypedFormGroup;
    isError: boolean;
    errorMessage: string;
    errorLicence: string;
    hide = true;
    tpvInfo;
    school
    loading: boolean;
    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _router: Router,
        public _sharedService: SharedService,
        private _ui: UiService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private authService: AuthService2,
        public navigationService: NavigationService,
        private paymentService: PaymentService,
        private appService: AppService,
        private toolbarService:ToolbarService,
        private renderer: Renderer2
    ) {
        this._unsubscribeAll = new Subject();
        this._ui.spin$.next(true);
        this.mobileDetect = this.appService.mobileDetect();
        iconRegistry.addSvgIcon('gafas_off', sanitizer.bypassSecurityTrustResourceUrl("https://walinwa.blob.core.windows.net/images/ver-pass-1.svg?ngsw-bypass=true"));
        iconRegistry.addSvgIcon('gafas_on', sanitizer.bypassSecurityTrustResourceUrl("https://walinwa.blob.core.windows.net/images/ver-pass.svg?ngsw-bypass=true"));
        this.renewalForm = this._formBuilder.group({
            userName: ['', [Validators.required, Validators.pattern(/^[0-9A-Za-zñÑ\.@]+$/)]],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });
        this.renewalForm.markAllAsTouched();
    }

    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.mobile = this._sharedService.isMobile();
    }

    ngAfterViewInit(){

        const matToolbarRef = this.toolbarService.getToolbarRef();
        if (matToolbarRef) {
            setTimeout(() => {
                this.renderer.addClass(matToolbarRef.nativeElement, 'only-logo');
            }, 0);
        }
        }

    ngOnInit() {
        this.mobile = this._sharedService.isMobile();
        // this.loadTPV({"parametrs":{"Ds_SignatureVersion":"HMAC_SHA256_V1","Ds_MerchantParameters":"eyJEc19NZXJjaGFudF9BbW91bnQiOiIwMDAwMDAwMTY2NDAiLCJEc19NZXJjaGFudF9PcmRlciI6IjAwNTg3NzIwMTIwNCIsIkRzX01lcmNoYW50X01lcmNoYW50Q29kZSI6IjM0ODc2NzUyNiIsIkRzX01lcmNoYW50X0N1cnJlbmN5IjoiOTc4IiwiRHNfTWVyY2hhbnRfVHJhbnNhY3Rpb25UeXBlIjoiMCIsIkRzX01lcmNoYW50X1Rlcm1pbmFsIjoiMDAxIiwiRHNfTWVyY2hhbnRfTWVyY2hhbnRVUkwiOiJodHRwOi8vbG9jYWxob3N0OjQ5NjUvYXBpL1JlZFN5cy9jb25maXJtUGF5bWVudCIsIkRzX01lcmNoYW50X1VybE9LIjoiaHR0cDovL2xvY2FsaG9zdDoyMDEwLy9wYWdlcy9wYXJlbnRzL3BheW1lbnRTY2hvb2xPay5odG1sIiwiRHNfTWVyY2hhbnRfVXJsS08iOiJodHRwOi8vbG9jYWxob3N0OjIwMTAvL3BhZ2VzL3BhcmVudHMvcGF5bWVudFNjaG9vbEVyci5odG1sIn0=","Ds_Signature":"eZhednA1l3nJAVZmcrRAlQK2yUH13BJeFp7s7JCKZGY="},"url":"https://sis-t.redsys.es:25443/sis/realizarPago"});
    }
    ParseDate(date){
        return moment(date).format("DD/MM/YYYY");
    }

    goLogin() {
        this.tpvInfo = null
        this._sharedService.paying = false;
        this._router.navigateByUrl("/login")
    }

    checkLicense(user) {
        this.authService.checkLicense(this.renewalForm.value.userName.trim(), this.renewalForm.value.password, (has_active_license: Boolean) => {
            if (has_active_license) {                
                if (!user.IdUserTutor) {
                    this.authService.loginWithParent(this.renewalForm.value.userName.trim(), this.renewalForm.value.password, ok => {
                        this.loading = false;
                        this._ui.stopSpinner();
                    }, ko => {
                        this.loading = false;
                        this._ui.stopSpinner();
                    })
                    return
                }
                
                this.loading = false;
                this._ui.stopSpinner();
                this.errorLicence = "AUTHENTICATION.ERROR.LICENSE-ACTIVE"
                return
            }
            this.authService.loginWithParent(this.renewalForm.value.userName.trim(), this.renewalForm.value.password, ok => {
                this.loading = false;
                this._ui.stopSpinner();
            }, ko => {
                if (ko == "PREPAID") {
                    this.paymentService.updatePaySchoolStudent(user.Id)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(
                        result => {
                            
                            this.loadTPV(result);
                        },
                        err => {
                            this.loading = false;
                            this._ui.stopSpinner();
                        }
                    )
                    return
                } else if (ko == "PARTICULAR") {
                    this.loading = false;
                    this._ui.stopSpinner();
                    this.errorLicence = "AUTHENTICATION.LICENCE.NO-VALID-USER-RENEW"

                    return;
                }

                this.loading = false;
                this._ui.stopSpinner();
            })
        })
    }

    login(renewalForm) {
        this.loading = true;
        this._ui.showSpinner();

        this.authService.getUserByUserNameAndPassword(this.renewalForm.value.userName.trim(), this.renewalForm.value.password, user => {
            this.checkLicense(user)
        }, ko => {
            if (ko.error.code == "License" && ko.error.user) {
                this.checkLicense(ko.error.user)
                return
            }

            this.getError(ko);
        })
        // let userInfo: IUserInfo = Object.assign({}, renewalForm.value);
        // userInfo.offset = (-(new Date().getTimezoneOffset() / 60)).toString();
        // userInfo.userAgent = window.navigator.userAgent;
        // userInfo.version = environment.version;
        // this._accountService.login(userInfo)
        //     
//.pipe(takeUntil(this._unsubscribeAll))
//.subscribe(response => {
        //         this._authService.login(this._sharedService.json(response));
        //         this._accountService.getUserId(userInfo.userName)
//.pipe(takeUntil(this._unsubscribeAll))
//.subscribe(userId => {
        //             if (userId.IdUserTutor != null && userId.IdSchool == null) {
        //                 this._accountService.getUserById(userId.IdUserTutor)
//.pipe(takeUntil(this._unsubscribeAll))
//.subscribe(user => {
        //                     sessionStorage.setItem("state", "parentMenu.license");
        //                     sessionStorage.setItem("state_params", JSON.stringify({ student: userId }));
        //                     this._sharedService.redirectRol(user);
        //                 })
        //             }
        //             else {
        //                 this.authService.login(this.renewalForm.value.userName, this.renewalForm.value.password, 1, ok => {
        //                     setTimeout(() => {
        //                         this.navigationService.go("/school/parentStudents")
        //                     }, 100)
        //                 }, error => {
        //                     this.errorLicence = this._sharedService.verifyLicence(userId);
        //                 })
        //             }
        //         },
        //             error => {
        //                 if (error && error.error && error.error.code && error.error.code == "License") {
        //                     let userId = error.error.user;
        //                     if (userId.IdSchool != null) {
        //                         let url = environment.baseApi + "/api/School/getSchoolLicense/" + userId.IdSchool;
        //                         this._repositoryService.get(url)
//.pipe(takeUntil(this._unsubscribeAll))
//.subscribe((school: any) => {
        //                             if (school.prepaid != true) {
        //                                 this.errorLicence = this._sharedService.verifyLicence(userId);
        //                                 return;
        //                             }
        //                             url = environment.baseApi + "/api/Payment/pay/iduser/" + userId.Id + "/schoolStudent/";
        //                             this._repositoryService.post(url, {})
//.pipe(takeUntil(this._unsubscribeAll))
//.subscribe((response: any) => {
        //                                 this.loadTPV(response);
        //                                 this._authService.logout();
        //                             },
        //                                 error => {
        //                                     this._authService.logout();
        //                                 });
        //                         },
        //                             error => {
        //                                 this._authService.logout();
        //                             });

        //                         //check if payment by school possibel
        //                         // schoolService.getSchoolLicenseByIdSchool(student.IdSchool).then(SchoolLoaded, errorCallback);
        //                     }
        //                     else {
        //                         this._accountService.getUserById(userId.IdUserTutor)
//.pipe(takeUntil(this._unsubscribeAll))
//.subscribe(user => {
        //                             sessionStorage.setItem("state", "parentMenu.license");
        //                             sessionStorage.setItem("state_params", JSON.stringify({ student: userId }));
        //                             this._sharedService.redirectRol(user);
        //                         })
        //                     }
        //                 }
        //             });
        //     },
        //         error => this.getError(error));
    }

    getError(error) {
        this.loading = false;
        this._ui.stopSpinner();
        if (error && error.error && error.error.Error) {
            switch (error.error.Error) {
                case "invalid_pass":
                    this.errorLicence = "PASSWORD-WRONG";
                    break;
                case "invalid_grant":
                    this.errorLicence = "AUTHENTICATION.ERROR.USER";
                    break;
                default:
                    this.errorLicence = "ERROR";
                    break;
            }
        } else {
            this.errorLicence = "AUTHENTICATION.ERROR.CON";
        }
    }

    loadTPV(response) {
        this.loading = false;
        this._ui.stopSpinner();
        this.tpvInfo = response;
        this._sharedService.paying = true;

    }
}
