
<div class="main_container" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/esquina-inf-der.svg?ngsw-bypass=true&quot;)'">
    <section fxFlex="" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch"
        fxLayoutAlign.lt-md="start center" class="box_options spe_gap">
        <div fxFlex="" fxFlex.lt-md="0 0 auto" class="text-center">
            <div class="box_op">
                <!-- <a href="#" (click)="goParticular($event)" class="floating-link" fxHide fxShow.lt-md></a> -->
                <div class="title">
                    {{'AUTHENTICATION.FREE-TRIAL.PARTICULAR' | translate}}
                </div>
                <div class="cont">
                    <a href="#" (click)="goParticular($event)">
                        <img class="" src="https://walinwa.blob.core.windows.net/images/icono-particulares.svg?ngsw-bypass=true" alt="">
                    </a>
                    <div class="" fxHide.lt-md>
                        <div [innerHtml]="'AUTHENTICATION.FREE-TRIAL.TXT-PARTICULAR' | translate"></div>
                    </div>
                    <div class="mt-12" fxHide.lt-md>
                        <button mat-raised-button color="accent" class="submit-button fixed_button" aria-label="FREE-TRIAL"
                            (click)="goParticular($event)">
                            {{'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div fxFlex="" fxFlex.lt-md="0 0 auto" class="text-center">
            <div class="box_op">
                <!-- <a href="#" (click)="goEducational($event)" class="floating-link" fxHide fxShow.lt-md></a> -->
                <div class="title">
                    {{'AUTHENTICATION.FREE-TRIAL.EDUCATIONAL' | translate}}
                </div>
                <div class="cont">
                    <a href="#" (click)="goEducational($event)">
                        <img class="" src="https://walinwa.blob.core.windows.net/images/icono-docentes.svg?ngsw-bypass=true" alt="">
                    </a>
                    <div class="" fxHide.lt-md>
                        <div [innerHtml]="'AUTHENTICATION.FREE-TRIAL.TXT-EDUCATIONAL' | translate"></div>
                    </div>
                    <div class="mt-12" fxHide.lt-md>
                        <button mat-raised-button color="accent" class="submit-button fixed_button" aria-label="LOGIN"
                            (click)="goEducational($event)">
                            {{'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>