import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { FreeTrialComponent } from './free-trial.component';
import { FreeTrialParticularComponent } from './free-trial-particular/free-trial-particular.component';
import { FreeTrialEducationalComponent } from './free-trial-educational/free-trial-educational.component';
import { StudentInfoComponent } from './free-trial-particular/student-info/student-info.component';
import { FamilyPasswordComponent } from './free-trial-particular/family-password/family-password.component';
import { WellcomeComponent } from './free-trial-particular/wellcome/wellcome.component';
import { AdminInfoComponent } from './free-trial-educational/admin-info/admin-info.component';
import { AdminGuideComponent } from './free-trial-educational/admin-guide/admin-guide.component';
import { FooterAuthModule } from '../footer-auth/footer-auth.module';

const routes = [
    { path: '', component: FreeTrialComponent },
    { path: 'free-trial-particular', component: FreeTrialParticularComponent },
    { path: 'free-trial-educational', component: FreeTrialEducationalComponent },
    { path: 'student-info', component: StudentInfoComponent},
    { path: 'family-password', component: FamilyPasswordComponent},
    { path: 'wellcome', component: WellcomeComponent},
    { path: 'admin-info', component: AdminInfoComponent },
    { path: 'admin-guide', component: AdminGuideComponent },
];

@NgModule({
    declarations: [
        FreeTrialComponent,
        FreeTrialParticularComponent,
        FreeTrialEducationalComponent,
        StudentInfoComponent,
        FamilyPasswordComponent,
        WellcomeComponent,
        AdminInfoComponent,
        AdminGuideComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule.forChild(),
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatDatepickerModule,
        MatRadioModule,
        MatSelectModule,
        FooterAuthModule,


        FuseSharedModule
    ],
    providers: []
})
export class FreeTrialModule {
}
