import { Component, OnDestroy, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { SharedService } from 'app/core/shared/shared.service';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';

import { GlobalService } from 'app/services/global.service';
import { AppService } from '../../../app.service';
import { NavigationService } from 'app/services/navigation.service';
import { AuthService } from 'app/services/auth.service';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        '(window:resize)': 'onResize($event)'
    }
})

export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    mobile: boolean = false;
    version: any;
    versionIsShown: boolean;

    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private _sharedService: SharedService,
        private _appService: AppService,
        private _router: Router,
        public globalService: GlobalService,
        private navigationService: NavigationService,
        private authService: AuthService
    ) {
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us'
            },
            {
                id: 'es',
                title: 'Spanish',
                flag: 'es'
            }
        ];

        this.navigation = navigation;

        this.version = environment.version.substring(3);

        if (environment.production)
            this.versionIsShown = false;
        else
            this.versionIsShown = true;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        event.target.innerWidth;
        this.mobile = this._sharedService.isMobile();
    }

    logoApprender() {
        return location.href.includes("loginApprender");
    }

    schoolAdmin() {
        return location.href.includes("demand") || location.href.includes("school") || (location.href.includes("student") && !location.href.includes("student-info") && !location.href.includes("new-student"));
    }

    ngOnInit(): void {
        registerLocaleData(es);
        this.mobile = this._sharedService.isMobile();

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });
    }


    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    search(value): void {
    }

    maintenance() {
        return location.href.includes("maintenance");
    }

    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    imLogged() {
        return sessionStorage.getItem('currentUser') != null
    }

    currentUser() {
        try {
            if (sessionStorage.getItem("currentUser")) {
                return JSON.parse(sessionStorage.getItem("currentUser"));
            }
        } catch (e) {
            return null;
        }
        return null;
    }

    showVersion() {

        // if(this.imLogged()) {
        //     this._router.navigateByUrl("");
        //     document.getElementById("mainWindow").classList.add("show-frame");
        //     this._appService.changeFuseConfig(true);
        // } else {
        //     this._router.navigate(['./login']);

        if (this.versionIsShown)
            this.versionIsShown = false;
        else
            this.versionIsShown = true;

        // this.navigationService.go("/school/parentStudents")

    }
}
