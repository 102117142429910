import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'comprehensions-tabs',
    templateUrl: './comprehensions.component.html',
    styleUrls: ['./comprehensions.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComprehensionsComponent implements OnInit {
    public selectedTab: number = 0;

    constructor(private _detector: ChangeDetectorRef,
                private _router: Router
    ) { }

    ngOnInit() {
        if (location.href.indexOf('/reading-text') > -1)
            this.selectedTab = 1;
        else if (location.href.indexOf('/deduction') > -1)
            this.selectedTab = 2;
        else if (location.href.indexOf('/hearing-text') > -1)
            this.selectedTab = 3;

        this._detector.detectChanges();
    }

    public goTo(selectedTab: number): void {
        switch (selectedTab) {
            case 1: 
                this._router.navigateByUrl('/student/reading-text');

                break;

            case 2: 
                this._router.navigateByUrl('/student/deduction');

                break;

            case 3: 
                this._router.navigateByUrl('/student/hearing-text');

                break;
        }
    }
}
