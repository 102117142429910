import { Component, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ExerciseService } from 'app/services/exercise.service';

@Component({
  selector: 'app-mini-exercise',
  templateUrl: './exercise.component.html',
  styleUrls: ['./exercise.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MiniExerciseComponent implements OnInit {
    show_errors = null
    showEndSession = false
    controlSession = true
    @Input()review = true
    @Input()exerciseModel
    @Input()exercise
    @Input()currentExercise;
    @Input()slide = false;
  constructor(private exerciseService: ExerciseService) { 
      
  }

  ngOnInit(): void {
    this.exerciseService.currentExercise = null;
    this.exerciseService.setCurrentExercise(this.exercise, true)
  }
  
}
