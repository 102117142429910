<div class="walinwa-header">
    <div class="walinwa-header-container">
        <img src=".https://walinwa.blob.core.windows.net/images/logo_walinwa_pantallas1.png" class="walinwa-header-logo">
    </div>
</div>
<div class="walinwa-main">

    <div style="flex-direction: column; justify-content: center;" class="walinwa-container">

        <div class="m-text">En estos momentos no es posible conectar con Walinwa.</div>
        <div class="m-text">Hemos puesto en conocimiento del proveedor de servicios este problema y nos indican que lo intentarán
            solucionar a la mayor brevedad posible.</div>
        <div class="m-text">Gracias por su comprensión.</div>

    </div>

</div>
<div class="walinwa-footer-colors">
    <div class="walinwa-footer-color walinwa-footer-color-1"></div>
    <div class="walinwa-footer-color walinwa-footer-color-2"></div>
    <div class="walinwa-footer-color walinwa-footer-color-3"></div>
</div>
<div style="height: calc(var(--font-size) * 5);" class="walinwa-footer">
    <div class="walinwa-footer-container">

    </div>
</div>