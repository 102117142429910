import { AppService } from './../../../../../app.service';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'app/services/auth.service';
import { NavigationService } from 'app/services/navigation.service';
import { SchoolService } from 'app/services/school.service';
import { UserService } from 'app/services/user.service';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { GameConfig } from 'app/core/shared/state/game-config.model';
import { TranslateService } from '@ngx-translate/core';

declare var $;

@Component({
    selector: "app-games",
    templateUrl: "./games.component.html",
    styleUrls: ["./games.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class GamesComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    classes;
    selectedClass = null;
    form;
    isIpad = false;
    SessionPlayConditions = [
        { value: 0, desc: this._translateService.instant('SCHOOL.TEACHER.CONFIG.SESSIONCONDITIONS-NO-BLOCK') },
        { value: 1, desc: this._translateService.instant('SCHOOL.TEACHER.CONFIG.SESSIONCONDITIONS-FINISH-DAILY') },
        { value: 2, desc: this._translateService.instant('SCHOOL.TEACHER.CONFIG.SESSIONCONDITIONS-FINISH-STARTED') },
        { value: 3, desc: this._translateService.instant('SCHOOL.TEACHER.CONFIG.SESSIONCONDITIONS-FINISH-ALL') }
    ]

    constructor(
        public schoolService: SchoolService,
        private userService: UserService,
        private navigationService: NavigationService,
        private authService: AuthService,
        private formBuilder: UntypedFormBuilder,
        private matDialog: MatDialog,
        private appService: AppService,
        private _translateService: TranslateService
    ) {
        this._unsubscribeAll = new Subject();
        this.selectedClass = 0;
        this.form = this.formBuilder.group({
            GameIni: [null],
            GameEnd: [null],
            MaxDailyGames: [-1],
            EnableMaxDailyGames: [false],
            SessionPlayCondition: [0],
            DictationPlayCondition: [false],
            ComprensionPlayCondition: [false],
            MemoryPlayCondition : [false],
            TestPlayCondition : [false]
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit(): void {
        if (!this.authService.check()) return;
        this.getClasses();
        if (this.appService.mobileDetect().IsIPad) {
            this.isIpad = true;
        }
    }

    getClasses() {
        this.userService.getClassesByIdTutor(this.authService.currentUser.Id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                result => {
                    this.classes = result;
                    this.classes.sort(function (a, b) {
                        return a.Level - b.Level;
                    });
                    if (!this.selectedClass) this.changeClass();
                    setTimeout(() => {
                        $('#select-class').val(this.selectedClass);
                    }, 0);
                },
                error => {
                    this.navigationService.exit();
                });
    }

    hasValue(time) {
        return time != null && time != "";
    }

    save() {
        if (this.isInvalid()) {
            let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
                disableClose: true
            });
            confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.CONFIG.MODAL-BAD-TIME-RANGE-TITLE');
            confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.CONFIG.MODAL-BAD-TIME-RANGE-MESSAGE1');
            confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
            confirmDialog.componentInstance.margin = false;
            confirmDialog.afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(result => {
                    confirmDialog = null;
                });
            return;
        }
        if (this.isInvalid2()) {
            let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
                disableClose: true
            });
            confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.CONFIG.MODAL-BAD-TIME-RANGE-TITLE');
            confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.CONFIG.MODAL-BAD-TIME-RANGE-MESSAGE2');
            confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
            confirmDialog.componentInstance.margin = false;
            confirmDialog.afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(result => {
                    confirmDialog = null;
                });
            return;
        }
        let gameConfig: GameConfig = {
            IdUser: this.authService.currentUser.Id,
            IdClass: this.selectedClass,
            GameIni: this.form.value.GameIni,
            GameEnd: this.form.value.GameEnd,
            MaxDailyGames: this.form.value.MaxDailyGames,
            SessionPlayCondition: this.form.value.SessionPlayCondition,
            DictationPlayCondition: this.form.value.DictationPlayCondition,
            ComprensionPlayCondition: this.form.value.ComprensionPlayCondition,
            MemoryPlayCondition: this.form.value.MemoryPlayCondition,
            TestPlayCondition: this.form.value.TestPlayCondition,
        }
        this.schoolService.saveGameConfig(gameConfig)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                result => {
                    let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
                        disableClose: true
                    });
                    confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.CONFIG.SUCCESS-SAVE-CONFIG-TITLE');
                    confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.CONFIG.SUCCESS-SAVE-CONFIG-MESSAGE');
                    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
                    confirmDialog.componentInstance.margin = false;
                    confirmDialog.afterClosed()
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(result => {
                            confirmDialog = null;
                            this.getClasses();
                        });
                },
                error => {
                    let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
                        disableClose: true
                    });
                    confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.CONFIG.ERROR-SAVE-CONFIG-TITLE');
                    confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.CONFIG.ERROR-SAVE-CONFIG-MESSAGE');
                    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
                    confirmDialog.componentInstance.margin = false;
                    confirmDialog.afterClosed()
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(result => {
                            confirmDialog = null;
                        });
                }
            )
    }
    isInvalid() {
        if (
            (this.hasValue(this.form.value.GameIni) && !this.hasValue(this.form.value.GameEnd)) ||
            (!this.hasValue(this.form.value.GameIni) && this.hasValue(this.form.value.GameEnd))) {
            return true;
        }
        return false;
    }

    isInvalid2() {
        if (this.hasValue(this.form.value.GameIni) &&
            this.hasValue(this.form.value.GameEnd) &&
            (this.form.value.GameIni > this.form.value.GameEnd)) {
            return true;
        }
        return false;
    }
    sameValues() {
        if (!this.classes) return;
        if (this.classes.length == 0) return;
        if(this.classes.length == 1) return true;
        let values1 = {
            GameIni: this.classes[0].GameIni,
            GameEnd: this.classes[0].GameEnd,
            MaxDailyGames: this.classes[0].MaxDailyGames,
            SessionPlayCondition: this.classes[0].SessionPlayCondition ?? 0,
            DictationPlayCondition: this.classes[0].DictationPlayCondition,
            ComprensionPlayCondition: this.classes[0].ComprensionPlayCondition,
            MemoryPlayCondition: this.classes[0].MemoryPlayCondition,
            TestPlayCondition: this.classes[0].TestPlayCondition,
        };
        for (let cls of this.classes) {
            let values2 = {
                GameIni: cls.GameIni,
                GameEnd: cls.GameEnd,
                MaxDailyGames: cls.MaxDailyGames,
                SessionPlayCondition: cls.SessionPlayCondition,
                DictationPlayCondition: cls.DictationPlayCondition ?? 0,
                ComprensionPlayCondition: cls.ComprensionPlayCondition,
                MemoryPlayCondition: cls.MemoryPlayCondition,
                TestPlayCondition: cls.TestPlayCondition,
            };
            if (JSON.stringify(values1) != JSON.stringify(values2))
                return false;
        }
        return true;
    }

    changeClass() {
        setTimeout(() => {
            this.selectedClass = parseFloat(this.selectedClass.toString());
        }, 100);
        let cls;
        if (this.selectedClass == 0) {
            if (this.sameValues()) {
                cls = this.classes[0];
            } else {
                this.form.patchValue({
                    GameIni: null,
                    GameEnd: null,
                    MaxDailyGames: -1,
                    EnableMaxDailyGames: false,
                    SessionPlayCondition: 0,
                    DictationPlayCondition: false,
                    ComprensionPlayCondition: false,
                    MemoryPlayCondition: false,
                    TestPlayCondition: false,
                });
                return;
            }
        } else {
            cls = this.classes.find((c) => c.IdClass == this.selectedClass);
        }
        this.form.patchValue({
            GameIni: cls.GameIni,
            GameEnd: cls.GameEnd,
            MaxDailyGames: cls.MaxDailyGames,
            EnableMaxDailyGames: cls.MaxDailyGames >= 0,
            SessionPlayCondition: cls.SessionPlayCondition ?? 0,
            DictationPlayCondition: cls.DictationPlayCondition,
            ComprensionPlayCondition: cls.ComprensionPlayCondition,
            MemoryPlayCondition: cls.MemoryPlayCondition,
            TestPlayCondition: cls.TestPlayCondition,
        });
    }

    enableMaxDailyGames() {
        if (this.form.value.EnableMaxDailyGames) {
            this.form.patchValue({
                MaxDailyGames: 10,
            });
        } else {
            this.form.patchValue({
                MaxDailyGames: -1,
            });
        }
    }
}
