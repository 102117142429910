<div class="walinwa-box">

    <form [formGroup]="form" class="walinwa-box-content">
        <!--div style="margin-top: -1.3rem; margin-bottom: -1rem;"-->
        <div style="margin-bottom: 0.40rem;">
            <mat-form-field>
                <label class="font_blue"><b>{{'CLASS' | translate}}</b></label>
                <mat-select id="select-class" [(ngModel)]="selectedClass" (selectionChange)="checkDictation()"
                    [ngModelOptions]="{standalone: true}">
                    <mat-option class="scrollable" [value]="0">{{'SCHOOL.TEACHER.CONFIG.ALL-CLASSES' | translate}}</mat-option>
                    <mat-option class="scrollable" *ngFor="let class of classes | sortBy:'asc':['Level','Code']"
                        [value]="class.IdClass">{{ class.Name }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between start"  fxLayoutAlign.lt-md="stretch"  fxNgClass.gt-sm="adjust_ipad_margin" fxNgClass.gt-md="" fxLayoutGap="2rem" fxLayoutGap.xs="1rem">
            <div fxFlex="45" fxLayout="row wrap" fxLayoutAlign="start" class="spe_gap">
                <div fxFlex="100%" fxNgClass.gt-sm="adjust_ipad_margin" fxNgClass.gt-md="">
                    {{'SCHOOL.TEACHER.CONFIG.DICTATIONS.TIMEBLOCK' | translate}}
                </div>
                <div fxFlex="0 0 10rem" fxFlex.xs="100%">
                    <label class="font_blue">{{'SCHOOL.TEACHER.CONFIG.TIMEINI' | translate}}</label>
                    <div class="custom-form-field">
                        <!-- <input [ngClass]="{ 'iPad-time-input': isIpad }" formControlName="GameIni" type="time"> -->
                        <input type="time" *ngIf="!enableDictation" disabled [ngClass]="{ 'iPad-time-input': isIpad }" formControlName="DictIni">
                        <input type="time" *ngIf="enableDictation" [ngClass]="{ 'iPad-time-input': isIpad }" formControlName="DictIni">
                    </div>
                </div>
                <div fxFlex="0 0 10rem" fxFlex.xs="100%">
                    <label class="font_blue">{{'SCHOOL.TEACHER.CONFIG.TIMEEND' | translate}}</label>
                    <div class="custom-form-field">
                        <!-- <input [ngClass]="{ 'iPad-time-input': isIpad }" formControlName="GameEnd" type="time"> -->
                        <input type="time" *ngIf="!enableDictation" disabled [ngClass]="{ 'iPad-time-input': isIpad }" formControlName="DictEnd">
                        <input type="time" *ngIf="enableDictation" [ngClass]="{ 'iPad-time-input': isIpad }" formControlName="DictEnd">
                    </div>
                </div>
            </div>
            <div fxFlex="55" fxLayout="column" fxLayoutAlign="stretch" fxLayoutGap="2rem" fxLayoutGap.lt-md="1rem">
                <div>
                    <mat-checkbox [disabled]="!enableDictation" formControlName="DictSpace">
                        <div>{{'SCHOOL.TEACHER.CONFIG.DICTATIONS.DICTSPACE' | translate}}
                        </div>
                    </mat-checkbox>
                    <tooltip position="l" [info]="'SCHOOL.TEACHER.CONFIG.DICTATIONS.DICTSPACE-INFO' | translate"></tooltip>
                </div>
                <div>
                    <mat-checkbox [disabled]="!enableDictation" formControlName="AccentPenalty">
                        <div>
                            {{'SCHOOL.TEACHER.CONFIG.DICTATIONS.ACCENTPENALTY' | translate}}
                        </div>
                    </mat-checkbox>
                    <tooltip position="l" [info]="'SCHOOL.TEACHER.CONFIG.DICTATIONS.ACCENTPENALTY-INFO' | translate"></tooltip>
                </div>
            </div>
        </div>
        <div class="walinwa-row" fxLayoutAlign="end end" fxLayoutAlign.xs="center center">
            <button (click)="save()" class="new_but azul">
                {{'SAVE' | translate}}
            </button>
        </div>
    </form>

</div>

<router-outlet></router-outlet>