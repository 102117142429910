import { Component, Input, OnInit, OnDestroy, HostListener, ViewEncapsulation } from '@angular/core';
import { ExerciseService } from 'app/services/exercise.service';

@Component({
  selector: 'ex-frase',
  templateUrl: './frase.component.html',
  styleUrls: ['./frase.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FraseComponent implements OnInit, OnDestroy {

  @Input() review
  @Input() show_errors;
  @Input() controlSession : boolean = false;
  @Input() slide : boolean = false; @Input() current;
  exercise
  draggingOption
  exerciseIsEnded = false
  finished = false

  palabras = []
  solucion = []
  posiciones = [];

  elementsOk
  elementsErr

    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case "SHOW_SOLUTION":
                this.showSolution();
                break;
            case "SHOW_ANSWER":
                this.ShowAnswer();
                break;
            case "SHOW_CUSTOM":
                this.exercise = this.exerciseService.getExerciseDefinition();
                this.solucion = [];
                this.get_palabras()
                break;
        }
    }
  
  constructor(private exerciseService: ExerciseService) {
    this.exercise = this.exerciseService.getExerciseDefinition();
    this.get_palabras()
  }
    ngOnDestroy(){
    }

  ngOnInit() {
    if (this.review) {
      this.elementsOk = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsOK
      );
      this.elementsErr = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsERR
      );
      if (this.show_errors == false) {
        this.showSolution();
      } else if (this.show_errors == true) {
        this.ShowAnswer();
      }
    } else {
      this.initInteract();
    }
  }

  showSolution() {
    this.showAnswersTimeOut()
    return
  }

  ShowAnswer() {
    this.solucion = [];
    for (var k = 0; k < this.palabras.length; k++) {
      this.palabras[k].answered = null;
    }
    var sortWords = this.exercise.frase.split(" ");
    for (var i = 0; i < sortWords.length; i++) {
      for (var j = 0; j < this.elementsErr.length; j++) {
        if (this.elementsErr[j] == i) {
          for (var k = 0; k < this.palabras.length; k++) {
            if (sortWords[i] == this.palabras[k].palabra) {
              this.palabras[k].answered = false;
              k = this.palabras.length;

            }
          }
        }
      }
      for (var j = 0; j < this.elementsOk.length; j++) {
        if (this.elementsOk[j] == i) {
          for (var k = 0; k < this.palabras.length; k++) {
            if (sortWords[i] == this.palabras[k].palabra) {
              if (this.palabras[k].answered != false)
                this.palabras[k].answered = true;
              this.solucion.push(k);
              k = this.palabras.length;
            }
          }
        }
      }
    }
  }

  initInteract() {
    this.exerciseService.initInteract({
      onmove: (event) => {
        this.draggingOption = event.target
      },
      drop: (event) => {
        let bin = event.target;
        if (this.finished == true) return;
        let item = this.draggingOption
        var index_palabra = parseFloat(item.getAttribute("id"));
        var palabra = this.palabras[index_palabra];
        var siguiente = this.solucion.length;
        var palabra_siguiente = this.exercise.frase.split(" ")[siguiente];
        if (palabra_siguiente == palabra.palabra) {
          palabra.answered = true;
          this.solucion.push(index_palabra);
          this.exerciseService.answer(palabra.originId, true, 100);
        } else {
          palabra.answered = false;
          this.exerciseService.answer(palabra.originId, false, 100);
        }
        if (this.solucion.length == this.palabras.length) {
          this.finished = true;
          this.exerciseService.endExercise()
        }
      },
    });
  }

  showAnswersTimeOut() {
    var sortWords = this.exercise.frase.split(" ");
    for (var i = 0; i < sortWords.length; i++) {
      for (var j = 0; j < this.palabras.length; j++) {
        if (this.solucion.length <= i) {
          if (sortWords[i] === this.palabras[j].palabra) {
            this.solucion.push([j]);
            this.palabras[j].answered = true;
            j = 0;
          }

        }
      }
    }
  }

  getWordPosition(palabra) {
    return {
      'position': 'absolute',
      'top': palabra.top + '%',
      'left': palabra.left + '%'
    };
  }

  shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  get_pos() {

    var columnas = 3;
    var filas = 4;

    for (var i = 0; i < filas; i++) {
      for (var j = 0; j < columnas; j++) {
        var r1 = (Math.random() * 14);
        var r2 = (Math.random() * 6) + 5;
        this.posiciones.push({
          top: (90 / filas) * i + r1,
          left: (90 / columnas) * j + r2
        });
      }
    }

    this.posiciones = this.shuffle(this.posiciones);
  }


  get_palabras() {
    var aux3 = [];
    var palabras = this.exercise.frase.split(" ");
    for (var i = 0; i < palabras.length; i++) {
      aux3.push({ 'palabra': palabras[i], 'originId': i });
    }


    var aux = this.shuffle(aux3);
    var aux2 = [];
    this.get_pos();
    for (var i = 0; i < aux.length; i++) {
      //var top = (Math.random() * 60) + 1;
      //var left = (Math.random() * 150) + 1;

      var top = this.posiciones[i].top;
      var left = this.posiciones[i].left;


      aux2.push({
        'palabra': aux[i].palabra,
        'top': top,
        'left': left,
        'answered': null,
        'originId': aux[i].originId
      });
    }
    this.palabras = aux2;
  }


}
