import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LeeBooks } from 'app/core/shared/state/models/Wali-lee/lee-books.model';
import { LeeSections } from 'app/core/shared/state/models/Wali-lee/lee-sections.model';
import { LeeTracking } from 'app/core/shared/state/models/Wali-lee/lee-tracking.model';
import { AuthService } from 'app/services/auth.service';
import { NavigationService } from 'app/services/navigation.service';
import { UserService } from 'app/services/user.service';
import { WaliLeeService } from 'app/services/wali-lee.service';
import { DxDataGridComponent } from 'devextreme-angular';
import * as moment from 'moment';
import { combineLatest, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'walilee-results',
  templateUrl: './walilee-results.component.html',
  styleUrl: './walilee-results.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WalileeResultsComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<void> = new Subject();
  private _resultsSubject = new Subject<LeeTracking[]>();
  private _dataSource: LeeTracking[] = [];

  @ViewChild('dataGrid') dataGrid: DxDataGridComponent;
  @Input('repaint') set repaint(value: boolean) {
    if (value) setTimeout(() => this.dataGrid?.instance.repaint());
  }

  public title: any = (book) => `${book.Title} ${book.Author}`;
  public studentName: any = (student) => student ? `${student.FamilyName || ''}${student.FamilyName ? ', ' : ''}${student.Name}` : '';
  public editorOptions: any = { useMaskBehavior: true };
  public results$ = this._resultsSubject.asObservable();
  public testVisible: boolean = false;
  public book: LeeBooks = null;
  public section: LeeSections = null;
  public idUser: number = null;
  public classes: any = [];
  public books: any = [];
  public students: any = [];
    
  constructor (private _detector: ChangeDetectorRef,
               private _navigationService: NavigationService,
               private _userService: UserService,
               private _walileeService: WaliLeeService,
               private _authService: AuthService) {
  }

  ngOnInit(): void {
    this._navigationService.enableExitButton();
    this._initSubscriptions();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private _initSubscriptions() {
    combineLatest([
      this._userService.getClassesByIdTutor(this._authService.currentUser.Id),
      this._walileeService.getBooks(),
      this._walileeService.getResults(this._authService.currentUser.Id),
    ]).pipe(takeUntil(this._unsubscribeAll), take(1)).subscribe(data => {
      this.classes = ((data[0] || []) as any[]).sort((a, b) => a.Level - b.Level);
      this.books = data[1];
      this.students = Array.from(data[2].reduce((map, item) => {
        const key = item.IdUser;

        if (!map.has(key)) map.set(key, item);
        
        return map;
      }, new Map()).values());
      
      data[2].map(result => {
        result.StartDate = moment.utc(result.StartDate).toDate();
        result.BookStartDate = moment.utc(result.BookStartDate).toDate();
        result.BookEndDate = moment.utc(result.BookEndDate).toDate();
        result.CurrentSections = data[2].filter(r => r.IdLee === result.IdLee && r.IdUser === result.IdUser).length;
      });

      this._dataSource = data[2].sort((a, b) => b.StartDate.getTime() - a.StartDate.getTime());
      this._resultsSubject.next(Array.from(this._dataSource.reduce((map, item) => {
        const key = `${item.IdClass}-${item.IdLee}-${item.IdUser}`;

        if (!map.has(key)) map.set(key, item);
        
        return map;
      }, new Map()).values()));

      setTimeout(() => this.dataGrid.instance.repaint());
    });
  }

  public getStudentDataSource(studentBook: any) {
    return this._dataSource.filter(r => r.IdUser === studentBook.data.IdUser && r.IdLee === studentBook.data.IdLee);
  }

  public customizeTooltip({ originalValue, originalTarget }: Record<string, number>) {
    const percent = ((originalValue / originalTarget) * 100).toLocaleString('es-ES', {maximumFractionDigits: 2});

    return ({ text: `${percent}%` });
  }

  public showTest(data: any) {
    this.testVisible = true;
    this.book = { Title: data.Title, Author: data.Author } as LeeBooks;
    this.section = { IdLee: data.IdLee, IdSection: data.IdSection, Chapter: data.SectionTitle } as LeeSections;
    this.idUser = data.IdUser;
    this._detector.detectChanges();
  }

  public onClose(e: any) {
    this.testVisible = false;
    this.book = null;
    this.section = null;
    this.idUser = null;
    this._detector.detectChanges();
  }

  public getClassName(idClass: number, empty: string = '') { 
    if (!idClass) return empty;
    
    const _class = ((this.classes || []).find(__class => __class.IdClass === idClass) || { Name: '' });

    return _class ? `<span>${_class.Name}</span>` : '';
  }

  public getBookTitle(idLee: number, empty: string = '') { 
    if (!idLee) return empty;
    
    const book = ((this._dataSource || []).find(book => book.IdLee === idLee) || { Title: '', Author: '' });

    return book ? `<span class='book-title'>${book.Title}. </span><span class='normal'>${book.Author}</span>` : '';
  }
}
