import { HostListener, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { ExerciseService } from 'app/services/exercise.service';

@Component({
  selector: 'ex-numer',
  templateUrl: './numer.component.html',
    styleUrls: ['./numer.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NumerComponent implements OnInit, OnDestroy {

  @Input() review;
  @Input() show_errors;
  @Input() controlSession : boolean = false;
  @Input() slide : boolean = false; @Input() current;
  exercise;
  words;
  draggingOption;
  exerciseIsEnded = false;
  finished = false;
  options = []
  cols
  rows
  result
  elementsOk;
  elementsErr;

    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case "SHOW_SOLUTION":
                this.finished = true;
                if (this.exerciseService.showingSolution) {
                    setTimeout(this.showAnswersTimeOut.bind(this), 100);
                    break;
                } else {
                    setTimeout(this.showSolution.bind(this), 100);
                    break;
                }
            case "SHOW_ANSWER":
            case "SHOW_CUSTOM":
                this.ShowAnswer();
                break;
        }
    }

  constructor(private exerciseService: ExerciseService) {
    this.exercise = this.exerciseService.getExerciseDefinition();
    this.DrawModel22();
  }
    ngOnDestroy() {
    }

  ngOnInit() {
    if (this.review) {
      this.elementsOk = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsOK
      );
      this.elementsErr = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsERR
      );
      if (this.show_errors == false) {
        this.showSolution();
      } else if (this.show_errors == true || this.show_errors == null) {
        this.ShowAnswer();
      }
    } else {
      this.initInteract()
    }
  }

  showSolution() {
    this.finished = true;
    for (var i = 0; i < this.cols.length; i++) {
      for (var j = 0; j < this.options.length; j++) {
        if (this.options[j].col == this.cols[i] && this.options[j].fila == this.rows[0].col1) {
          this.result[i][0] = this.options[j];
          this.result[i][0].answered = true;
        }
      }
      for (var j = 0; j < this.options.length; j++) {
        if (this.options[j].col == this.cols[i] && this.options[j].fila == this.rows[1].col2) {
          this.result[i][1] = this.options[j];
          this.result[i][1].answered = true;
        }
      }
    }
  }

    ShowAnswer() {
        this.finished = false;
        //recorremos las columnas
        this.cols.forEach((columna, columnaIndex) => {
            //buscamos las opciones que van en la columna
            let opciones = this.options.filter(x => x.col == columna)
            opciones.forEach((opcion) => {
                //buscamos el indice de la palabra en las opciones, es necesario para saber si ha respondido bien, mal o no ha respondido
                let index = this.options.findIndex(x => x.valor == opcion.valor)
                if (opcion.fila == this.rows[0].col1) {
                    this.result[columnaIndex][0] = opcion;
                    this.result[columnaIndex][0].answered = null
                    if (this.elementsErr.includes(index)) {
                        console.log("entro", index)
                        this.result[columnaIndex][0].answered = false
                    }
                    else if (this.elementsOk.includes(index)) {
                        console.log("entro", index)
                        this.result[columnaIndex][0].answered = true;
                    }
                    else {
                        this.result[columnaIndex][0].answered = null;
                    }
                }
                else {
                    this.result[columnaIndex][1] = opcion;
                    this.result[columnaIndex][1].answered = null
                    if (this.elementsErr.includes(index)) {
                        this.result[columnaIndex][1].answered = false
                    }
                    else if (this.elementsOk.includes(index)) {
                        this.result[columnaIndex][1].answered = true;
                    }
                    else {
                        this.result[columnaIndex][1].answered = null;
                    }
                }
            })
        });
    }

  initInteract() {
    this.exerciseService.initInteract({
      onmove: (event) => {
        this.draggingOption = event.target
      },
      drop: (event) => {

        let bin = event.target
        let element = this.draggingOption

        var elementCol = element.getAttribute("col");
        var elementRow = element.getAttribute("row");
        var elementVal = element.getAttribute("val");
        var binCol = bin.getAttribute("col");
        var binRow = bin.getAttribute("row");

        if (elementRow == binRow && elementCol == binCol) {
          for (var i = 0; i < this.options.length; i++) {
            if (this.options[i].valor == elementVal && this.options[i].fila == elementCol && this.options[i].col == elementRow) {
              this.exerciseService.answer(i, true, this.options.length)
              this.options[i].answered = true;
              var idCol = bin.getAttribute("idCol");
              var idRow = bin.getAttribute("idRow");
              this.result[idRow][idCol] = this.options[i];
            }
            else
              if (this.options[i].answered != true) this.options[i].answered = null;
          }
        }
        else {
          for (var i = 0; i < this.options.length; i++) {
            if (this.options[i].valor == elementVal && this.options[i].fila == elementCol && this.options[i].col == elementRow) {
              this.exerciseService.answer(i, false, this.options.length)
              this.options[i].answered = false;
            }
            else
              if (this.options[i].answered != true)
                this.options[i].answered = null;
          }
        }

        for (var i = 0; i < this.options.length; i++) {
          if (this.options[i].answered != true)
            return;
        }
        this.FinishExercise();
      },
    });
  }

  FinishExercise() {
    this.finished = true
  }

  DrawModel22() {
    //Clone the Phrases
    this.rows = (JSON.parse(JSON.stringify(this.exercise.filas)));

    this.cols = (JSON.parse(JSON.stringify(this.exercise.col)));

    this.options = (JSON.parse(JSON.stringify(this.exercise.val)));
    for (var i = 0; i < this.options.length; i++)
      this.options[i].answered = null;
    this.result = [];
    for (var i = 0; i < this.cols.length; i++)
      this.result.push([null, null]);

  }

  showAnswersTimeOut() {
    for (var i = 0; i < this.cols.length; i++) {
      if (this.result[i][0] == null) {
        for (var j = 0; j < this.options.length; j++) {
          if (this.options[j].col == this.cols[i] && this.options[j].fila == this.rows[0].col1) {
            this.options[j].answered = null;
            this.result[i][0] = this.options[j];
          }
        }
      }
      if (this.result[i][1] == null) {
        for (var j = 0; j < this.options.length; j++) {
          if (this.options[j].col == this.cols[i] && this.options[j].fila == this.rows[1].col2) {
            this.options[j].answered = null;
            this.result[i][1] = this.options[j];
          }
        }
      }
    }
  }

}
