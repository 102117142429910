import { cloneDeep } from 'lodash';
import { Injectable } from "@angular/core";
import { Class } from "app/core/shared/state";
import { environment } from "environments/environment";
import { HttpClient } from '@angular/common/http';
import { ClassByLevelDTO } from 'app/core/shared/state/models/Class/class-by-level.model';
import { ZoneConfig } from 'app/core/shared/state/models/Class/zone-config.model';

@Injectable()
export class ClassService {
    // no existe esta api, crear a futuro
    baseUrl = environment.baseApi + '/api/Class';
    ClassesReduced: Class[] = []

    constructor(private http: HttpClient) {}
    GetClasses(): Class[]{
        return cloneDeep(this.ClassesReduced);
    }

    getChallengeBlock(idClass: number) {
        return this.http.get(this.baseUrl + '/getChallengeBlock/' + idClass);
    }
    canExecuteDictation(idClass: number) {
        return this.http.get(this.baseUrl + '/canExecuteDictation/' + idClass)
    }
    canExecuteReadingText(idClass: number) {
        return this.http.get(this.baseUrl + '/canExecuteReadingText/' + idClass)
    }
    canExecuteDeductionText(idClass: number) {
        return this.http.get(this.baseUrl + '/canExecuteDeductionText/' + idClass)
    }
    canExecuteHearingText(idClass: number) {
        return this.http.get(this.baseUrl + '/canExecuteHearingText/' + idClass)
    }
    canExecuteChallenge(idClass: number) {
        return this.http.get(this.baseUrl + '/canExecuteChallenge/' + idClass)
    }
    getSchoolClassesByLevel(idSchool : number, idClass: number) {
        return this.http.get<ClassByLevelDTO[]>(this.baseUrl + '/getRetoClassesByLevel/idSchool/' + idSchool + '/idClass/' + idClass);
    }

    saveZoneConfig(zoneConfig: ZoneConfig) {
        return this.http.post(this.baseUrl + '/saveZoneConfig', zoneConfig);
    }
}