<div class="walinwa-box">
    <div class="walinwa-box-content">
        <div *ngIf="!student">
            {{'SCHOOL.REVIEW-ACTIVITIES.REVIEW-SESSION-TITLE' | translate: {userInfo: ''} }}
        </div>
        <div *ngIf="student">
            {{ 
                'SCHOOL.REVIEW-ACTIVITIES.REVIEW-SESSION-TITLE' 
                | translate: { userInfo: (student.FamilyName ? student.FamilyName + ', ' : '') + student.Name + ' (' + student.UserName + ')' }
            }}
        </div>
        <div>
            <calendar
                class="calendario dinamico walCalendar azul ww_customCalendar"
                [ngClass]="{ sixWeeks: sixWeeks() }"
                [parent]="this"
                [fullDay]="false"
                [currentUser]="student"
                [selectedDay]="selectedDay"
                [currentMonth]="currentMonth"
            >
            </calendar>
        </div>
        <div
            class="walinwa-row spe_gap"
            fxLayout="row wrap"
            fxLayoutAlign="end center"
            fxLayoutAlign.xs="center center"
        >
            <button
                [ngStyle]="{
                    'background-color': showReview ? 'var(--naranja);' : ''
                }"
                [disabled]="showReview == false"
                (click)="startReviewErrors()"
                class="new_but azul"
            >
                {{'SCHOOL.REVIEW-ACTIVITIES.REVIEW-ERRORS' | translate}}
            </button>
            <button
                (click)="startReview()"
                [disabled]="showReview == false"
                class="new_but azul"
            >
                {{'SCHOOL.REVIEW-ACTIVITIES.REVIEW-SESSION' | translate}}
            </button>
        </div>
    </div>
</div>
