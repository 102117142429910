import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpParams, HttpEvent, HttpHeaderResponse, HttpResponse, HttpErrorResponse, HttpUserEvent, HttpProgressEvent } from '@angular/common/http';
import { Location } from '@angular/common';
import { environment } from 'environments/environment';

@Injectable()
export class HistoryStageService {

    baseUrl = environment.baseApi + '/api/historyStage';

    constructor(private http: HttpClient, private _location: Location) {
    }

    getHistoryStagesByUser (idUser) {
        return this.http.get(this.baseUrl + "/idUser/" + idUser);
    }

    setHistoryStageToUser (idUser, idHistoryStage) {
        let offset = -(new Date().getTimezoneOffset() / 60);
        let params = { timeZone: offset };
        return this.http.post(this.baseUrl + "/idUser/" + idUser + "/idHistoryStage/" + idHistoryStage, params);
    }

}
