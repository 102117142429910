import { Component, Input, OnInit } from '@angular/core';
import { CalendarHearingText } from 'app/core/shared/state/models/Hearing-text/hearing-text-month.model';
import * as moment from 'moment';

@Component({
    selector: 'cal-hearing-text',
    templateUrl: './cal-hearing-text.component.html',
    styleUrls: ['./cal-hearing-text.component.scss']
})
export class CalHearingTextComponent implements OnInit {
    @Input() fullDay: boolean;
    @Input() currentUser: any;
    @Input() selectedDay: any;
    @Input() currentMonth: any;
    @Input() parent: any;
    @Input() hearingTexts: CalendarHearingText[]
    @Input() showAll: boolean
    @Input() licenseDate: any;
    month: any;
    weeks: any;
    has_refreshed = true

    constructor(
    ) {
        moment.locale('es')
    }

    ngOnInit(): void {
        if (this.selectedDay == null || this.selectedDay.clone === "undefined") return;
        let currentDay = this.selectedDay.clone();
        this.month = currentDay.clone();
        var start = currentDay.clone();
        start.date(1);
        this._buildMonth(start, this.month);
        if (this.month.month() != moment().month()) this.selectedDay = null;
        this.selectToday();
    }

    isPending(day: any): boolean {
        let currentHearingText = this.hearingTexts.find((r) => { return moment(r.date).isSame(day.date) });
        if (currentHearingText?.TextDate == null) {
            return false;
        } else {
            if (currentHearingText && currentHearingText.TextDate != null && currentHearingText.FinishDate == null) {
                return true;
            } else {
                return false;
            }
        }

    }

    selectToday() {
        for (let week of this.weeks) {
            for (let day of week.days) {
                if (!this.currentSession(day.date)) continue;
                this.select(day);
                return;
            }
        }
    }

    selectedDayChangedCA(date: any): void {
        let currentDay = this.selectedDay.clone();
        this.month = currentDay.clone();
        var start = currentDay.clone();
        start.date(1);
        this._buildMonth(start, this.month);
        this.parent.selectedDayChangedCA(date);
    }

    refresh(callback = (weeks_count) => { }) {
        callback(this.weeks.length)
        setTimeout(() => {
            this.select({ date: this.selectedDay })
        }, 100)
    }

    select(day: any): void {
        this.selectedDay = day.date;
        this.selectedDayChangedCA(this.selectedDay);
    }
    next(): void {
        this.selectedDay.month(this.selectedDay.month() + 1);
        this.month = this.selectedDay.clone();
        this.selectedDayChangedCA(this.selectedDay);
    }

    previous(): void {
        this.selectedDay.month(this.selectedDay.month() - 1);
        this.month = this.selectedDay.clone();
        this.selectedDayChangedCA(this.selectedDay);
    }

    isPast(date: any): boolean {
        let now = moment()
        if (this.showAll) {
            if (this.licenseDate != null && moment(date).isAfter(moment(this.licenseDate))) return false;
            // last month, current month and next month
            if (date.month() == now.month()) return true
            if (date.month() == now.month() - 1) return true
            if (date.month() == now.month() + 1) return true
            return false
        }
        return date.isSameOrBefore(now)
    }
    isDone(date: any): boolean {
        if (!this.hearingTexts) return false;
        var currentHearingText = this.hearingTexts.find(function (r) {
            return r.Week == date.week() && r.Day == date.isoWeekday()
        });
        if (!currentHearingText || currentHearingText.Score == null) return false;
        return currentHearingText.FinishDate != null;
    }

    notDone(date: any): boolean {
        if (!this.hearingTexts) return false;
        if (this.licenseDate != null && moment(date).isAfter(moment(this.licenseDate))) return false;
        var currentHearingText = this.hearingTexts.find(function (r) {
            return r.Week == date.week() && r.Day == date.isoWeekday()
        });
        if (!currentHearingText || this.currentSession(date)) return false;
        return !this.isDone(date);
    }

    currentSession(date: any): boolean {
        if (!this.hearingTexts) return false;
        if (this.licenseDate != null && moment(date).isAfter(moment(this.licenseDate))) return false;
        var currentHearingText = this.hearingTexts.find(function (r) {
            return r.Week == date.week() && r.Day == date.isoWeekday()
        });
        if (!currentHearingText) return false;
        return moment(date).calendar().indexOf("hoy") != -1;
    }

    isAvailableMonth(date: any): boolean {
        if (!this.currentUser) return true;
        if (moment(this.currentUser.CreationDate).diff(date, 'days') > 0 && !this.currentUser.UserName.includes(".tmp.")) return true;
        if ((date.month() > moment().month() - 2 && date.year() === moment().year()) ||
            (date.year() > moment().year()) ||
            (moment().month() == 0 && date.month() == 11 && (date.year() + 1) === moment().year()))
            return true;
        return false;
    }

    isAvailable(date: any): boolean {
        var dictado = date.week() + '_' + date.isoWeekday();
        return true;
    }

    _removeTime(date: any): boolean {
        var a = 0;
        return date.hour(a).minute(a).second(a).millisecond(a);
    }

    _buildMonth(start, month) {
        if (start == null) return;
        this.weeks = [];
        var done = false, date = start.clone(), monthIndex = date.month(), count = 0;
        while (!done) {
            this.weeks.push({ days: this._buildWeek(date.clone(), month) });
            date = date.clone();
            date.add(1, "w");
            done = count++ > 2 && (monthIndex !== date.startOf('week').month());
            monthIndex = date.month();
        }
    }

    _buildWeek(date, month) {
        let days = [];
        let dayItereator = date.startOf('week');
        for (var i = 0; i < 7; i++) {
            days.push({
                name: dayItereator.format("dd").substring(0, 1),
                number: dayItereator.date(),
                isCurrentMonth: dayItereator.month() === month.month(),
                isToday: dayItereator.isSame(new Date(), "day"),
                date: dayItereator,
                notDone: !this.showAll && this.notDone(dayItereator) && this.isPast(dayItereator),
                disabledMonth: !this.isAvailableMonth(dayItereator),
                isDone: (!this.showAll && this.isDone(dayItereator)) || (this.showAll && this.notDone(dayItereator) && this.isPast(dayItereator)),
                currentSession: this.currentSession(dayItereator),
            });
            dayItereator = dayItereator.clone();
            dayItereator.add(1, "d");
        }
        return days;
    }

}
