
<mat-toolbar class="p-0" *ngIf="!globalService.inExercise && !schoolAdmin() && !maintenance()">

    <div id="toolBar">

        <div class="toolBarContainer" fxLayout="row">

            <img src="https://walinwa.blob.core.windows.net/images/logo_walinwa_pantallas1.png?ngsw-bypass=true" class="image-logo" >

            <div class="space"></div>

            <!--<div class="version" *ngIf="!imLogged()">
                <span class="versionText" *ngIf="versionIsShown">Versión {{version }}</span>
            </div>-->

            <div class="version" *ngIf="!logoApprender() && !imLogged()">
                <span class="versionText" *ngIf="versionIsShown">Versión {{version }}</span>
            </div>

            <img src="https://walinwa.blob.core.windows.net/images/apprender_blanco1.png" class="image-logo-apprender" *ngIf="logoApprender()">

            <div class="otrosDatos" *ngIf="imLogged() && currentUser()">
                <div class="userName">
                    {{ currentUser().UserName }}
                </div>
                <div class="schoolLicense" ng-if="!schoolLicense">{{ currentUser().Email }}</div>
            </div>

            <div *ngIf="imLogged() && currentUser()" class="profile-picture">
                <img  src="https://walinwa.blob.core.windows.net/images/Login/finales_AREADEPROFESORES.svg" />
            </div>

        </div>

    </div>

</mat-toolbar>