import { Component, OnInit, ViewEncapsulation, HostListener, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import * as moment from 'moment';

import { User, DataQuery, DataService, createUser } from 'app/core/shared/state';
import { UiService } from '@fuse/components/spinner/ui.service';
import { RepositoryService } from 'app/core/repository/repository.service';
import { SharedService } from 'app/core/shared/shared.service';
import { environment } from 'environments/environment';
import { AppService } from 'app/app.service';
import { ToolbarService } from 'app/services/toolbarService.service';

@Component({
  selector: 'wellcome',
  templateUrl: './wellcome.component.html',
  styleUrls: ['./wellcome.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class WellcomeComponent implements OnInit {

  user;
  students;
  mobile: boolean = false;
 iPad = /iPad|Macintosh/i.test(navigator.userAgent); mobileDetect;
  errorMessage: string;
  password;
  colors: string[] = [
    '#ff9100', // naranja
    '#6ecb0b', // verde
    '#3bb39c', // verde azulado
    '#ffc000', // amarillo
    '#ff0194' // fucsia
  ];

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _router: Router,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private renderer: Renderer2,
    private dataQuery: DataQuery,
    private _ui: UiService,
    private _repositoryService: RepositoryService,
    public _sharedService: SharedService,
    private _dataService: DataService,
    private appService: AppService,
    private toolbarService:ToolbarService
  ) {
    this._ui.spin$.next(true);
    this.errorMessage = "";
    this.mobileDetect = this.appService.mobileDetect();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerWidth;
    this.mobile = this._sharedService.isMobile();
  }

  ngOnInit(): void {

    this.mobile = this._sharedService.isMobile();
    this.user = this.dataQuery.user;
    this.students = this.dataQuery.students;
    this.password = this.dataQuery.password;

  }

  ngAfterViewInit(){

    const matToolbarRef = this.toolbarService.getToolbarRef();
    if (matToolbarRef) {
        setTimeout(() => {
            this.renderer.addClass(matToolbarRef.nativeElement, 'only-logo');
        }, 0);
    }
	}

  getBackgroundColor(i, elem) {
    this.renderer.setStyle(elem, 'background-color', this.colors[i]);
  }

  goParent() {

    let loginForm = this._formBuilder.group({
      userName: this.user.userName,
      password:  this.password
    });
    this._sharedService.login(loginForm);
  }

  goStudent(student) {

    let loginForm = this._formBuilder.group({
      userName: student.userName,
      password: this.password
    });
    this._sharedService.login(loginForm);
  }

}
