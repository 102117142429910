import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpParams, HttpEvent, HttpHeaderResponse, HttpResponse, HttpErrorResponse, HttpUserEvent, HttpProgressEvent } from '@angular/common/http';
import { Location } from '@angular/common';
import { environment } from 'environments/environment';
import { FileRequest, FileResponse } from 'app/core/shared/state/models/file/file.model';
import { FileService } from './file.service';

@Injectable()

export class AnnouncementService {

  baseUrl = environment.baseApi + '/api/Announcement';
  imgUrls: FileResponse[] = [];
  subDirectory = "Announcements"

  constructor(private http: HttpClient,
    private fileService: FileService) {
    
  }
  getAnnouncement(idUser) {
    return this.http.get(this.baseUrl + '/idUser/' + idUser);
  };

  setAnnouncementUser(ReleaseDate, idUser) {
    return this.http.post(this.baseUrl + '/idAnnouncement/' + ReleaseDate + '/idUser/' + idUser, {});
  };


}
