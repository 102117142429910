import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({ name: 'replaceTags' })
export class ReplaceTags implements PipeTransform {
    transform(text: string, enumeration: number = null) {
        let replacedText = this.replaceTags(text);
        if (enumeration != null) {
            return enumeration + '. ' + replacedText;
        }
            return replacedText;
    }
    /**
     * replaceTags
     *
     * @param text
     * @returns {string}
     */
    replaceTags(text): string
    {
        return text.replace(/<i>/g, '<span class="italic">')
        .replace(/<\/i>/g, '</span>')
        .replace(/<ver>/g, '<span class="ver">')
        .replace(/<\/ver>/g, '</span>');
    }
}