<div class="walinwa-box">
    <div class="walinwa-box-content">
        <div *ngIf="!student">
            {{'SCHOOL.REVIEW-ACTIVITIES.REVIEW-HEARING-TITLE' | translate: {userInfo: ''} }}
        </div>
        <div *ngIf="student">
            {{ 
                'SCHOOL.REVIEW-ACTIVITIES.REVIEW-HEARING-TITLE' 
                | translate: { userInfo: (student.FamilyName ? student.FamilyName + ', ' : '') + student.Name + ' (' + student.UserName + ')' }
            }}
        </div>
        <div>
            <cal-hearing-text *ngIf="hearingTexts" class="calendario dinamico walCalendar azul ww_customCalendar"
                [ngClass]="{ sixWeeks: sixWeeks() }" [hearingTexts]="hearingTexts" [parent]="this" [fullDay]="false"
                [currentUser]="student" [selectedDay]="selectedDayCA" [currentMonth]="currentMonthCA">
            </cal-hearing-text>
        </div>
        <div fxLayoutAlign="end end" fxLayoutAlign.xs="center center">
            <button (click)="startReview()"
                [disabled]="!activeButton" class="new_but azul">
                {{'SCHOOL.REVIEW-ACTIVITIES.REVIEW-EXERCISE' | translate}}
            </button>
        </div>
    </div>
</div>