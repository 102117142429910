<div class="walinwa-box walinwa-box-orange">
    <div class="walinwa-box-header">
        {{'STUDENT.TRANSACTIONS.TITLE' | translate}} {{currentUser.Coins | digitSpacingPipe }})
    </div>
    <div class="walinwa-box-content no_padding_tables">
        <div class="">
            <div class="ww_table_rwd">
                <table class="walinwa-table two">
                    <thead>
                        <tr class="">
                            <th style="width: 10%;" ngStyle.xs="width: auto;"></th>
                            <th style="width: 30%;" ngStyle.xs="width: auto;">{{'STUDENT.TRANSACTIONS.DATE' | translate}}</th>
                            <th style="width: 40%;" ngStyle.xs="width: auto;">{{'STUDENT.TRANSACTIONS.DESCRIPTION' | translate}}</th>
                            <th style="width: 20%; text-align: center;" ngStyle.xs="width: auto;">Walinwos</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let trans of transactions; let i = index" class="">
                            <td style="text-align: center;"><span fxHide.xs>{{i+1}}</span></td>
                            <td>{{trans.Date | ToLocalTime }}</td>
                            <td>{{trans.Description}}</td>
                            <td style="text-align: center;">{{ trans.Amount | digitSpacingPipe }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
