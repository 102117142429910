import { SortByPipe } from './../../../../pipes/sort-by.pipe';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { LicenseService } from 'app/services/license.service';
import { UserService } from 'app/services/user.service';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { SchoolService } from 'app/services/school.service';
import { NavigationService } from 'app/services/navigation.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { SharedService } from 'app/core/shared/shared.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: "app-students",
    templateUrl: "./students.component.html",
    styleUrls: ["./students.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class StudentsComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  currentUser;
  calling = false;
  classes = [];
  selectedClass;
  students = [];
  selectedClassCode;
  selectedIdUser;
  selectedAuthUser;
  currentPage;
  dataLoaded = false;
  authUsers;
  school;
  idUser;
  IdClass;
  isIpadIphone;
  loading = false;
  constructor(
    private licenseService: LicenseService,
    private userService: UserService,
    private _matDialog: MatDialog,
    public schoolService: SchoolService,
    private navigationService: NavigationService,
    private sharedService: SharedService,
    private _sortByPipe: SortByPipe,
    private _translateService: TranslateService
  ) { 
    this._unsubscribeAll = new Subject();
}

ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

  ngOnInit() {
    if (!sessionStorage.getItem("currentUser")) return this.navigationService.exit();
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (!this.currentUser) return this.navigationService.exit();
    if (this.navigationService.params.IdUser) this.idUser = this.navigationService.params.IdUser;
    if (this.navigationService.params.IdClass) this.IdClass =this.navigationService.params.IdClass;
    this.navigationService.enableExitButton();
    this.calling = true;
    this.school = this.currentUser.IdSchool;
    this.userService.getClassesByIdTutor(this.currentUser.Id)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(this.successGetClasses.bind(this), this.errorCallback.bind(this));
this.isIpadIphone = this.detectiPadiPhone();
  }
  detectiPadiPhone(): boolean {
    const userAgent = window.navigator.userAgent;
    return /iphone|iPad/i.test(userAgent);
}

    goEditStudent() {
        if (!this.selectedIdUser) return;
        this.navigationService.go("/school/students/" + this.selectedIdUser, {
            IdUser: this.selectedIdUser,
            IdClass: this.selectedClass,
        });
    }

    goEditClass() {
        this.navigationService.go(
            "/school/students/class/" + this.selectedClass,
            {
                IdUser: this.selectedIdUser,
                IdClass: this.selectedClass,
            }
        );
    }

    goSessionReview() {
        if (!this.selectedIdUser) return;
        this.navigationService.go(
            "/school/session/" + this.selectedIdUser + "/" + this.selectedClass,
            {
                IdUser: this.selectedIdUser,
                IdClass: this.selectedClass,
            }
        );
    }

    goAuthTutor() {
        if (!this.selectedIdUser) return;
        this.navigationService.go(
            "/school/students/auth/" + this.selectedIdUser,
            {
                IdUser: this.selectedIdUser,
                IdClass: this.selectedClass,
            }
        );
    }

  resetStudentPassword() {
    if(!this.selectedIdUser) return;
    if (this.calling) return;
    let student = null;
    for (let i = 0; i < this.students.length; i++) {
      if (this.students[i].Id == this.selectedIdUser) {
        student = this.students[i];
      }
    }
    if (!student) return;
    let message = [];
    message[0] = this._translateService.instant('SCHOOL.MODAL-RESET-PASSWORD-TITLE')
    message[1] = this._translateService.instant('SCHOOL.MODAL-RESET-PASSWORD-MESSAGE', {UserName: student.UserName})
    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    confirmDialog.componentInstance.message1 = message[1];
    confirmDialog.componentInstance.message2 = message[2];
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
    confirmDialog.componentInstance.margin = false;
    confirmDialog.componentInstance.theme = "white";
    confirmDialog.componentInstance.options = [{
      text: this._translateService.instant('ACCEPT'),
      callback: () => {
        this.calling = true;
        this.schoolService.resetPassword(student.Id, this.currentUser.Id, this.currentUser.IsSchoolAdmin)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successResetStudentPassword.bind(this), this.unexpectedError.bind(this));
      }
    }, {
      text: "Cancelar",
      callback: () => { }
    }];
    confirmDialog.componentInstance.margin = false;
    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      result();
      confirmDialog = null;
    });
  }

  successResetStudentPassword(response) {
    this.calling = false;
    let message = [];
    message[0] = this._translateService.instant('SCHOOL.MODAL-SUCCESS-RESET-PASSWORD-TITLE');
    message[1] = this._translateService.instant('SCHOOL.MODAL-SUCCESS-RESET-PASSWORD-MESSAGE');
    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    confirmDialog.componentInstance.message1 = message[1];
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
    confirmDialog.componentInstance.margin = false;
    confirmDialog.componentInstance.margin = false;
    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      confirmDialog = null;
    });
  }

    deleteUser() {
        if (!this.selectedIdUser) return;
        if (this.calling || this.loading) return;
        let student = null;
        for (let i = 0; i < this.students.length; i++) {
            if (this.students[i].Id == this.selectedIdUser) {
                student = this.students[i];
            }
        }
        if (!student) return;
        let message = [];
        message[0] = this._translateService.instant('SCHOOL.MODAL-ERROR-DELETE-STUDENT-TITLE');
        message[1] = this._translateService.instant('SCHOOL.MODAL-DELETE-STUDENT-MESSAGE', {UserName: student.UserName});
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.message2 = message[2];
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
        confirmDialog.componentInstance.margin = false;
        confirmDialog.componentInstance.theme = "white";
        confirmDialog.componentInstance.options = [{
            text: this._translateService.instant('ACCEPT'),
            callback: () => {
                this.calling = true;
                this.loading = true;
                this.schoolService.deleteUser(student.Id, this.currentUser.Id, this.currentUser.IsSchoolAdmin)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(() => {
                        this.successDeleteStudent();
                    },err => {
                        console.log(err.error);
                        this.errorDeleteStudent(err.error);
                    })
            }
        }, {
            text: "Cancelar",
            callback: () => { }
        }];
        confirmDialog.componentInstance.margin = false;
        confirmDialog.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                result();
                confirmDialog = null;
            });
    }

    errorDeleteStudent(response) {
        this.calling = false;
        this.loading = false;
        let message = [];
        if (response == null) {
            this.navigationService.exit();
            message[0] = this._translateService.instant('SCHOOL.MODAL-ERROR-DELETE-STUDENT-TITLE');
            message[1] = this._translateService.instant('SCHOOL.MODAL-ERROR-DELETE-STUDENT-MESSAGE2');
        }
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
        confirmDialog.componentInstance.margin = false;
        confirmDialog.componentInstance.margin = false;
        confirmDialog.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                confirmDialog = null;
            });
    }

  successDeleteStudent() {
      this.calling = false;
      this.loading = false;
    let message = [];
    message[0] = this._translateService.instant('SCHOOL.MODAL-ERROR-DELETE-STUDENT-TITLE');
    message[1] = this._translateService.instant('SCHOOL.MODAL-SUCCESS-DELETE-STUDENT-MESSAGE');
    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    confirmDialog.componentInstance.message1 = message[1];
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
    confirmDialog.componentInstance.margin = false;
    confirmDialog.componentInstance.margin = false;
    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      this.calling = true;
      this.selectedIdUser = null;
      this.schoolService.getSchoolByUser(this.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
        (result: any) => {
          this.schoolService.school = result;
          this.schoolService.getStudentsSchool(this.currentUser.IdSchool)
          .pipe(takeUntil(this._unsubscribeAll)).subscribe(
            (resultStudents : any) => {
                  this.schoolService.school.NumStudents = resultStudents.length;
                  this.sharedService.changeNumStudents(resultStudents.length);
                this.schoolService.school.Users = resultStudents;
            });

          this.userService.getClassesByIdTutor(this.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetClasses.bind(this), this.errorCallback.bind(this));
          confirmDialog = null;
        }
      )
    });
  }

  unexpectedError(response) {
    this.calling = false;
    let message = [];
    message[0] = this._translateService.instant('ERROR');
    message[1] = this._translateService.instant('SCHOOL.MODAL-ERROR-MESSAGE');
    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    confirmDialog.componentInstance.message1 = message[1];
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
    confirmDialog.componentInstance.margin = false;
    confirmDialog.componentInstance.margin = false;
    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      confirmDialog = null;
    });
  }

  successGetClasses(response) {
    this.calling = false;
    let order = "asc"
    var columns = ['Level','Name']
    this.classes = response.sort((a,b) => {
        for (let column of columns) {
          if(a[column] == null) a[column] = ""
          if(b[column] == null) a[column] = ""
          let item_a = this.removeAccents(a[column])
          let item_b = this.removeAccents(b[column])
          if (item_a === item_b) {
            continue
          }
          if (order == "asc") {
            return item_a > item_b ? 1 : -1
          } else {
            return item_a > item_b ? -1 : 1
          }
        }
      })
    
    this.sharedService.changeSchoolHasClasses(this.classes.length > 0);

    if (this.classes.length == 0) {
      this.classes = null;
      this.userService.getStudentsByParentId(this.currentUser.Id, 0)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successLoad.bind(this), this.errorCallback.bind(this));
    } else {
        if (this.IdClass && !this.selectedClass) this.selectedClass = this.IdClass;
      if (this.selectedClass) {
        for (let i = 0; i < this.classes.length; i++) {
          if (this.classes[i].IdClass == this.selectedClass)
            this.selectedClassCode = this.classes[i].Code;
        }
      }
      if (this.selectedClassCode == null) {
        this.selectedClass = this.classes[0].IdClass;
        this.selectedClassCode = this.classes[0].Code;
      }
      this.calling = true;
      this.userService.getStudentsByParentId(this.currentUser.Id, this.selectedClass)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successLoad.bind(this), this.errorCallback.bind(this));
    }
  }

  successLoad(response) {
    this.calling = false;
    this.students = [];
    for (let i = 0; i < response.length; i++) {
      if (response[i].UserName.toUpperCase().includes(".TMP.") == false) this.students.push(response[i]);
    }

    this.students = this._sortByPipe.transform((this.students || []), 'asc', ['FamilyName','Name']);

    if(this.idUser) this.selectedIdUser = this.idUser;
    if (!this.selectedIdUser && this.students.length > 0) this.selectedIdUser = this.students[0].Id;
    for (let i = 0; i < this.students.length; i++) {
      if (this.students[i].Id == this.selectedIdUser) {
        if (this.students[i].AuthUser != null)
          this.selectedAuthUser = true;
        else
          this.selectedAuthUser = false;
      }
    }
    if (!this.currentPage) {
      this.currentPage = 0;
    }
    this.dataLoaded = true;
    if (!this.authUsers) {
      this.calling = true;
      this.userService.GetAuthorizedStudentsByParentId(this.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successLoadAuth.bind(this), this.errorCallback.bind(this));
    }
  }

  getComa(user) {
    if (user.FamilyName != null && user.Name != null && user.FamilyName != '' && user.Name != '') {
      return ",";
    }
    return "";
  }
  checkStudents(){
    let flag = false;
    this.students.forEach(student => {
        if(student.FamilyName != null){
            return flag = true;
        }
    })
    return flag;
  }
  changeClass() {
    this.currentPage = 0;
    let classId = 0;
    this.selectedIdUser = null;
    this.idUser = null;
    for (let i = 0; i < this.classes.length; i++) {
      if (this.classes[i].IdClass == this.selectedClass) {
        this.selectedClassCode = this.classes[i].Code;
        classId = this.classes[i].IdClass;
      }
    }
    if (classId == -1) {
      this.successLoad(this.authUsers);
    }
    else {
      this.userService.getStudentsByParentId(this.currentUser.Id, this.selectedClass)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successLoad.bind(this), this.errorCallback.bind(this));
    }
  }
    selectUser(IdUser) {
        if (this.selectedIdUser != IdUser) {
            this.selectedIdUser = IdUser;
            this.selectedAuthUser = false;
            for (let i = 0; i < this.students.length; i++) {
                if (this.students[i].Id == this.selectedIdUser) {
                    if (this.students[i].AuthUser != null)
                        this.selectedAuthUser = true;
                    else this.selectedAuthUser = false;
                }
            }
        }
    }

  successLoadAuth(response) {
    this.calling = false;
    if (response != null) {
      this.authUsers = response;
      this.sharedService.changeExistsAuthorizedStudentsByParent(this.authUsers.length > 0);
      for (let i = 0; i < this.authUsers.length; i++) this.authUsers[i].AuthUser = true;
      let noClasses = false;
      if (this.currentUser.IdSchool) {
        if (!this.classes) {
          this.classes = [];
          noClasses = true
        }
        if (this.authUsers && this.authUsers.length > 0) this.classes.push({ Name: "Autorizados", IdClass: -1 });
      }
      if (!this.currentUser.IdSchool || noClasses) {
        for (let i = 0; i < this.authUsers.length; i++) this.students.push(this.authUsers[i]);

        this.students = this._sortByPipe.transform((this.students || []), 'asc', ['FamilyName','Name']);
      }
    }
  }

  newUser() {
    if (this.school && !this.classes) return;
      if(this.selectedClass){
        this.navigationService.go("school/students/newStudent/" + this.selectedClass)
      }else{
        this.abrirModal()
      }
  }

    errorCallback() {
        this.navigationService.exit();
    }

  abrirModal(){
    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.TEACHER.STUDENTS.MODAL-NEW-STUDENT-TITLE');
    confirmDialog.componentInstance.message1 = this._translateService.instant('SCHOOL.TEACHER.STUDENTS.MODAL-NEW-STUDENT-MESSAGE');
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
    confirmDialog.componentInstance.margin = false;
    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      confirmDialog = null;
    });
  }
  removeAccents(text) {
    if (typeof text !== 'string' || text.includes) return text
    let chars = ["Á", "É", "Í", "Ó", "Ú", "á", "é", "í", "ó", "u"]
    let chars2 = ["A", "E", "I", "O", "U", "a", "e", "i", "o", "u"]
    for (let i = 0; i < chars.length; i++) {
      let char = chars[i]
      let char2 = chars2[i]
      if (text.includes(char)) return text.replace(char, char2).toUpperCase()
    }
    }
}
