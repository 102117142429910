import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'digitSpacingPipe'
})
export class DigitSpacingPipe implements PipeTransform {

  constructor() {}

  transform(amount: number): string {
    const amountString = amount.toString();

    if (amountString && Math.abs(amount).toString().length >= 5) {
        const sign = Math.sign(amount) === -1 ? '-' : ''; // Conservar el signo si es negativo
        const absAmountString = Math.abs(amount).toString();
        let formattedAmount = '';
        
        for (let i = 0; i < absAmountString.length; i++) {
            formattedAmount += absAmountString[i];
            const remainingDigits = absAmountString.length - (i + 1);

            if (remainingDigits > 0 && remainingDigits % 3 === 0) {
                formattedAmount += ' '; // Agrega un espacio cada tres dígitos, excepto al final
            }
        }

        return sign + formattedAmount;
    }

    return amountString;
  }
}