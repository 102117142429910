import { SharedService } from 'app/core/shared/shared.service';
import { FindUrlPipe } from 'app/pipes/find-url.pipe';
import { CoinService } from 'app/services/coin.service';
import { MemoImageUser } from './../../../../core/shared/state/models/memo-image-user.model';
import { VisualMemoryService } from 'app/services/visual-memory.service';
import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, HostListener, SimpleChanges, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MemoImageExcer } from 'app/core/shared/state/models/memo-image-excer.model';
import { MemoImageExercise } from 'app/core/shared/state/models/memo-image-exercise.model';
import { NavigationService } from 'app/services/navigation.service';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UiService } from '@fuse/components/spinner/ui.service';
import { environment } from 'environments/environment';
import { FileService } from 'app/services/file.service';
import { forkJoin, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

declare var window: any;

@Component({
    selector: 'app-dumb',
    templateUrl: './dumb.component.html',
    styleUrls: ['./dumb.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class DumbComponent implements OnInit, AfterViewInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    fragmentos = [];
    fragmento = 0;
    indexCard: number = 0;
    respuestas :MemoImageExcer[]  = [] 
    titilar: boolean;
    loading: boolean = false;
    messages = []
    aciertos = 0;
    noRespondio = false;
    compararRespuestas = [];
    animate = false;
    input;
    spark = false;
    lastLength = 0;
    mobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    // mantener el guion en el final así lo considera un caracter
    regex = new RegExp(/^[a-zA-ZáéíóúñüÁÉÍÓÚÑÜçÇ-]*$/);
    confirmDialog: MatDialogRef<FuseDialogContinueComponent>;
    position: number;
    isDelete: boolean = false;
    review = false;
    // Declaraciones de las variables de entradas
    @Input() currentPorcent: number;
    @Input() dataMemo: MemoImageExercise[];
    @Input() reviewDataMemo: MemoImageExcer[];
    @Input() caracteres;
    @Input() IdUser: number
    // Declaraciones de los Eventos de Salidas
    @Output() redirectTo = new EventEmitter<string>();
    @Output() PageOk = new EventEmitter<boolean>();
    directory = "MemoImage/"
    

    @HostListener('contextmenu', ['$event'])
    onRightClick(event) {
    event.preventDefault();
    }

    @HostListener('document:keydown', ['$event'])
    handleDeleteKeyboardEvent(event: KeyboardEvent) {
        let key = event.key;
        if (event.key == "Unidentified") return;
        if (event.keyCode == 37 || event.keyCode == 38 || event.keyCode == 39 || event.keyCode == 40 || event.keyCode == 18 || event.keyCode == 19) event.preventDefault();
        if (key.includes("Tab")) {
            event.preventDefault()
            if (this.indexCard + 1 == 4) {
                this.selectedCard(0)
            } else {
                this.selectedCard(this.indexCard + 1);
            }
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {

        var viewportHeight = window.innerHeight;
        var visualViewportHeight = window.visualViewport.height;
        var keyboardHeight = viewportHeight - visualViewportHeight;

        var iPadw = window.innerWidth;

        if( iPadw < 1300 && iPadw > 959){
            setTimeout(() => {
                this.handleResize();

                if(keyboardHeight < 100){
                    this.returnHeightContainer();
                    // console.log("B Keyboard BB: " + keyboardHeight); 
                }
            }, 750);
        }

        if( iPadw <= 959){
            setTimeout(() => {
                this.handleResizeMob();

                if(keyboardHeight < 100){
                    this.returnHeightContainerMob();
                    // console.log("B Keyboard: " + keyboardHeight); 
                }
            }, 750);
        }
    }

    change(){
        let key = this.input.charAt(this.input.length -1)
        if(this.input.length < this.lastLength){
            key = "Backspace";
        }
        this.lastLength = this.input.length;
        if(key.includes("Tab")){
            if(this.indexCard + 1 == 4){
                this.selectedCard(0)
            }else {
                this.selectedCard(this.indexCard + 1);
            }
        }
        if(this._visualMemory.step == 2 && !this._visualMemory.validated){
            this.isDelete ? this.setCaracterPosition(this.indexCard,this.position, key) : this.setLetra(key);
            this.setTitilando(key);
        }
    }

    constructor(public _visualMemory:VisualMemoryService,
                private navigationService: NavigationService,
                private _matDialog : MatDialog,
                private _ui: UiService,
                public sharedService: SharedService,
                private _translateService: TranslateService
                ) { 
                    this._unsubscribeAll = new Subject();
                    document.onmousedown = this.disableRightclick;
                }

    ngOnInit(): void {
        this._visualMemory.has_finished.pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
            if (result) {
                this.review = true;
            }
            else {
                this.review = false;
            }
        })
        this.indexCard = 0;

    }

    disableRightclick(evt){
        if(evt.button == 2){
            event.preventDefault()
            return false;    
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if(this._visualMemory.timerEnd) {
            if(this._visualMemory.step == 1){
                this.start()
            }else{
                this.validate();
            }
        }
    }

    ngAfterViewInit(): void {
        if (this.dataMemo) {
            this.checkPosition(this.indexCard);
            this.setTitilando();
        }
    }

    ngOnDestroy() {
        this._visualMemory.resetTimer();
        this.currentPorcent = 0;
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    show(caracter) {
        return (caracter !== '' || caracter !== ' ') ? "&nbsp" : '<br>';
    }

    imageUrl(item: any): any{
        let fileName = "";
        if(item.MemoImage){
            fileName = item.MemoImage.Name + ".jpg";
        }
        else {
            fileName = item.name + ".jpg";
        }
        return "https://walinwa.blob.core.windows.net" + this._visualMemory.subDirectory + fileName;
    }

    isShowable(char){
        return (char !== "" || char !== " ") ? true : false;
    }

    private setLetra(key: string) {
        if (!(this.regex.test(key)) || key.length > 1) {
            if(key.includes('Backspace')){
                //Funcion para borrar un caracter
                for(let i = this.dataMemo[this.indexCard].caracteres.length - 1; i >= 0; i--){
                    if(this.dataMemo[this.indexCard].caracteres[i].caracter && this.dataMemo[this.indexCard].caracteres[i].letraEscrita === undefined && this.dataMemo[this.indexCard].caracteres[i].titila){
                        //quitamos el cursor en la posicion actual
                        this.dataMemo[this.indexCard].caracteres[i].titila = false;
                        // seteamos titila en true en la posicion anteior
                        this.dataMemo[this.indexCard].caracteres[i - 1].titila = true;
                        // eliminamos letraEscrita en la posicion anteior
                        delete this.dataMemo[this.indexCard].caracteres[i - 1].letraEscrita;
                        // agregamos en el dom el espacio en blanco
                        document.getElementById(`letter_${i - 1}${this.indexCard}`).innerHTML = '&nbsp';    
                        return; 
                    }else if(this.dataMemo[this.indexCard].caracteres[i].letraEscrita && !this.dataMemo[this.indexCard].caracteres[i].titila){
                        //quitamos el cursor en la posicion actual
                        this.dataMemo[this.indexCard].caracteres[i].titila = true;
                        // seteamos titila en false en la posicion anteior
                        this.dataMemo[this.indexCard].caracteres[i - 1].titila = false;
                        // eliminamos letraEscrita en la posicion actual ya que es el ultimo caracter escrito
                        delete this.dataMemo[this.indexCard].caracteres[i].letraEscrita;
                        // agregamos en el dom el espacio en blanco en la ultima posicion
                        document.getElementById(`letter_${i}${this.indexCard}`).innerHTML = '&nbsp';    
                        return; 
                    }
                }
            }
            return;
        }        
         
        if (this.indexCard !== null) {
            this.dataMemo[this.indexCard].caracteres.forEach((caract, index) => {
                if (caract.titila && !caract.letraEscrita) {
                    
                    if (this.regex.test(key)) {
                        caract.letraEscrita = key;
                        document.getElementById(`letter_${index}${this.indexCard}`).innerHTML = key;
                        caract. titila= false;
                    }
                    if (index === this.dataMemo[this.indexCard].caracteres.length - 1) {
                        this.dataMemo[this.indexCard].finished = true;
                    }
                    return;
                }
            });
        }
    }
    getPosition(caracter,caracteres){
        return caracteres.indexOf(caracter)
    }
    getTextoIngresado(i){
        let texto = ""
        this.dataMemo[i].caracteres.forEach(element => {
            if(element.caracter == " "){
                texto += " "
            }else if(element.letraEscrita != undefined) {
                texto += element.letraEscrita
            }
        });
        return texto
    }
    private setTitilando(key?) {
        if (!(this.regex.test(key)) || key.length > 1) {
            return;
        } 
        let bandera = false;
        if (this.indexCard !== null) {
            this.dataMemo[this.indexCard].caracteres.forEach((caract, index) => {
                if (!caract.letraEscrita && !caract.titila && !bandera && caract.caracter != " ") {
                    caract.titila = true;
                    bandera = true;
                    return;
                }
            });
        }
    }

    public selectedCard(i: number) {
        switch(i){
            case 0:
                this.checkPosition(0);
                this.dataMemo[1].caracteres.forEach( x => x.titila = false);
                this.dataMemo[2].caracteres.forEach( x => x.titila = false);
                this.dataMemo[3].caracteres.forEach( x => x.titila = false);
            break;
            case 1:
                this.dataMemo[0].caracteres.forEach( x => x.titila = false);
                this.checkPosition(1);
                this.dataMemo[2].caracteres.forEach( x => x.titila = false);
                this.dataMemo[3].caracteres.forEach( x => x.titila = false);
            break;
            case 2:
                this.dataMemo[0].caracteres.forEach( x => x.titila = false);
                this.dataMemo[1].caracteres.forEach( x => x.titila = false);
                this.checkPosition(2);
                this.dataMemo[3].caracteres.forEach( x => x.titila = false);
            break;
            case 3:
                this.dataMemo[0].caracteres.forEach( x => x.titila = false);
                this.dataMemo[1].caracteres.forEach( x => x.titila = false);
                this.dataMemo[2].caracteres.forEach( x => x.titila = false);
                this.checkPosition(3);
            break;
        }
        this.indexCard = i;
        this.setFocus(true);
    }

    checkPosition(poisition:  number){
        let bandera = false
        this.isDelete = false;
        this.dataMemo[poisition].caracteres.forEach( (caract, index) => {
            if(!caract.letraEscrita && !bandera && caract.caracter != " "){
                this.dataMemo[poisition].caracteres[index].titila = true;
                bandera = true;
                this.deleteCaracter(poisition,index);
            }
        });
    }

    
    validate() {
        this.loading = true;
        this._ui.showSpinner();
        this._visualMemory.resetTimer();
        if(this._visualMemory.page == 5){
            this.animate = true;
        }
        this.savePageAndExercises();
    }
    // reinicia el timer, cambia el step a 2
    // step = 2 == ejercicio empezado
    start(){
        this.loading = true
        this._visualMemory.step = 2;
        this.resetTime()
        this.loading = false
        this.indexCard = 0;
        this.selectedCard(this.indexCard)
    }
    volver() {
        this.sharedService.changeUserCoins(true);
        this.navigationService.go("/student/session");
    }
    resetTime(){
        this._visualMemory.stopTimer();
        this._visualMemory.resetTimer();
        setTimeout(() => {
            this._visualMemory.startTimer();
        }, 100);
        
    }
    //llama a la página siguiente y carga las siguientes imagenes a mostrar
    continue(){
        this._visualMemory.getData()
        this.PageOk.emit(true)
    }
    finish() {
        let paginaToSave = new MemoImageUser(
            this.IdUser,
            this._visualMemory.walinwos,
            new Date(),
            this._visualMemory.page,
        )
        if (this._visualMemory.page == 1 && this._visualMemory.walinwos == 0) {
            this.sharedService.changeUserCoins(true);
            this._visualMemory.validated = true
            this.loading = false;
            this.navigationService.go("/student/session");
            this._ui.stopSpinner();
            return
        }
        this.loading = true;
        this._ui.showSpinner();
        this._visualMemory.SavePage(paginaToSave)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.sharedService.changeUserCoins(true);
                this._visualMemory.validated = true
                this.loading = false;
                this.navigationService.go("/student/session");
                this._ui.stopSpinner();
            });
    }
    verifyResponse() {
        this.respuestas = [];
        this.dataMemo.forEach(imagen => {
            let counter = 0;
            let pass = false;
            imagen.caracteres.forEach((caracter, index) => {
                if(caracter.caracter == ' ') {
                    caracter.letraEscrita = caracter.caracter
                }
                if (caracter.caracter === caracter.letraEscrita) {
                    counter++;
                }
            });
            // Verificamos que el contador corresponda al tamaño del arreglo, si son iguales en numero, se respondio correctamente
            if (counter == imagen.caracteres.length) {
                pass = true
                this._visualMemory.walinwos += 5 * this._visualMemory.page;
                
            } else if(counter != imagen.caracteres.length && this.respondio(imagen)) {
                pass = false;
                if(this._visualMemory.page > 1){
                    this._visualMemory.walinwos -= 5 * this._visualMemory.page;
                }
                if(this._visualMemory.walinwos < 0) {
                    this._visualMemory.walinwos = 0;
                }
            }
            else if(!this.respondio(imagen)) {
                pass = null;
            }
            let respuesta = new MemoImageExcer(
                imagen.IdMemo,
                this.IdUser,
                pass,
                new Date(),
                this._visualMemory.page
            );
            this.respuestas.push(respuesta);
            if(pass){
                imagen.sonIguales = true;
            } else if(pass == false) {
                imagen.sonIguales = false;
            } else{
                imagen.sonIguales = null;
            }
        });
        if(this._visualMemory.page == 5 && this.checkAciertos()){
            this.animateValue()
        }
    }
    savePage(){
        
    }
    checkAciertos(){
        this.aciertos = 0;
        this.respuestas.forEach(respuesta => {
            if (respuesta.Pass){
                this.aciertos++;
            }
        })
        return this.aciertos == 4;
    }
    respondio(imagen){
        if(imagen.caracteres[0].letraEscrita == undefined){
            return false
        } else{
            return true
        }
    }
    close(){
        if(this._visualMemory.step == 1 || this.review || this._visualMemory.validated){
            if(this._visualMemory.validated && !this.review) {
                this.finish()
            }else {
                this.volver()
            }
        }else{
            this._visualMemory.pauseTimer()
            this.confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
                disableClose: true
            });
            this.confirmDialog.componentInstance.title = this._translateService.instant('STUDENT.VISUAL-MEMORY.MODAL-TITLE');
            this.confirmDialog.componentInstance.message1 = this._translateService.instant('STUDENT.VISUAL-MEMORY.MODAL-MESSAGE');
            this.confirmDialog.componentInstance.message2 = this._translateService.instant('STUDENT.VISUAL-MEMORY.MODAL-MESSAGE2');
            this.confirmDialog.componentInstance.space = true;
            this.confirmDialog.componentInstance.margin = false;
            this.confirmDialog.componentInstance.options = [{
                text: this._translateService.instant('ACCEPT'),
                callback: () => {
                    this.savePageAndExercises()
                    this.volver();
                    this._visualMemory.restartTimer()
                }
            }, {
                text: "Cancelar",
                callback: () => {
                    this._visualMemory.restartTimer()
                 }
            }];
            this.confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
                result();
                this.confirmDialog = null;
            });
        }
        
    }
	getPalabras(item : MemoImageExercise){
        let espacios = 0
        item.caracteres.forEach((caracter, indice) => {
            if(caracter.caracter == " "){
                espacios++
            }
        })
        return espacios + 1
    }
    
    getCaracteres(espacio,item){
        let espacios = 0
        let caracteres = []
            item.caracteres.forEach((caracter, indice) => {
                if(caracter.caracter == " "){
                    espacios++
                }
                if (espacio == espacios){
                    caracteres.push(indice)
                }

            })

        return item.caracteres.filter((x, y) => caracteres.includes(y))
    }

    public deleteCaracter(i: number, j: number): void {
        this.indexCard = i;
        this.position = j;
        this.isDelete = true;

        let firstCharacter = this.dataMemo[this.indexCard].caracteres[0];

        if (firstCharacter && !firstCharacter.letraEscrita)
            this.position = 0;

        const lastWritedIndex = this.dataMemo[this.indexCard].caracteres.findIndex(c => !Object.prototype.hasOwnProperty.call(c, 'letraEscrita'));

        if (lastWritedIndex !== -1 && j > lastWritedIndex)
            this.position = lastWritedIndex;

        this.dataMemo.forEach((demo, index) => { 
            if (index !== this.indexCard)
                demo.caracteres.forEach(c => c.titila = false);
        });

        this.dataMemo[this.indexCard].caracteres.forEach((caract, index) => {            
            if(index == this.position) {
                caract.titila = true;
                delete caract.letraEscrita;

                const span = document.getElementById(`letter_${index}${this.indexCard}`);

                if (span)
                    span.innerHTML = '&nbsp';    
            }
            else if (index > this.position){
                caract.titila = false;
                /*delete caract.letraEscrita;

                const span = document.getElementById(`letter_${index}${this.indexCard}`);

                if (span)
                    span.innerHTML = '&nbsp';   */ 
 
            } else {
                caract.titila = false;
            }
        });

        /*if (this.position == 0) {
           this.dataMemo[this.indexCard].caracteres.forEach((caract, index) => {
                    if(index == j && this.indexCard == i){
                        console.log("TITILA PRIMERO");
                        caract.titila = true;
                        delete caract.letraEscrita;
                    }
                    else if (index > j){
                        caract.titila = false;
                        console.log("delete caract.letraEscrita; PRIMERO ; j=" + j + " indice=" + index);
                        delete caract.letraEscrita;
                    }
                })
        } else {
            this.dataMemo[this.indexCard].caracteres.forEach((caract, index) => {
                    if (caract.letraEscrita !== undefined ||this.iterator(this.indexCard,this.position) ) {
                        if(index == j && this.indexCard == i){
                            console.log("TITILA SEGUNDO");
                            caract.titila = true;
                            delete caract.letraEscrita;
                        }
                        else if (index > j){
                            console.log("delete caract.letraEscrita; SEGUNDO ; j=" + j + " indice=" + index);
                            caract.titila = false;
                            delete caract.letraEscrita;
                        }
                    } else if (!caract.letraEscrita && index === 0) {
                        console.error("REINICIA SEGUNDO!!!!!!!");
                        console.error("REINICIA SEGUNDO!!!!!!! + caract ");
                        console.error(caract);
                        caract.titila = true;
                    }
                })
        }*/
       
    }

    iterator(indexCard, position){
        let flag = false;
        for (let index = position; index < this.dataMemo[indexCard].caracteres.length; index++) {
            if (this.dataMemo[indexCard].caracteres[index].letraEscrita != undefined) {
                return flag = true;
            }
            
        }
        return flag;
    }

    setCaracterPosition(index: number,j: number, key:string){
        if (!(this.regex.test(key)) || key.length > 1) {
            if(key.includes('Backspace')){
                //Funcion para borrar un caracter
                for(let i = j ; i >= 0; i--){
                    if(this.dataMemo[index].caracteres[i].caracter && this.dataMemo[index].caracteres[i].letraEscrita === undefined && this.dataMemo[index].caracteres[i].titila){
                        //quitamos el cursor en la posicion actual
                      
                        this.dataMemo[index].caracteres[i].titila = false;
                        
                        if(i - 1 < 0){
                            // seteamos titila en true en la posicion anteior
                            this.dataMemo[index].caracteres[0].titila = true;
                            // eliminamos letraEscrita en la posicion anteior
                            delete this.dataMemo[index].caracteres[0].letraEscrita;
                        }
                        else {
                            // seteamos titila en true en la posicion anteior
                            this.dataMemo[index].caracteres[i - 1].titila = true;
                            // eliminamos letraEscrita en la posicion anteior
                            delete this.dataMemo[index].caracteres[i - 1].letraEscrita;
                        }
                        // agregamos en el dom el espacio en blanco
                        document.getElementById(`letter_${i - 1}${index}`).innerHTML = '&nbsp';    
                        return; 
                    }else if(this.dataMemo[index].caracteres[i].letraEscrita && this.dataMemo[index].caracteres[i].titila){
                        //quitamos el cursor en la posicion actual
                        this.dataMemo[index].caracteres[i].titila = true;
                        // seteamos titila en false en la posicion anteior
                        if( i - 1 > 0 ){
                            this.dataMemo[index].caracteres[i - 1].titila = false;
                        }
                        // eliminamos letraEscrita en la posicion actual ya que es el ultimo caracter escrito
                        delete this.dataMemo[index].caracteres[i].letraEscrita;
                        // agregamos en el dom el espacio en blanco en la ultima posicion
                        document.getElementById(`letter_${i}${index}`).innerHTML = '&nbsp';    
                        return; 
                    }
                }
            }
            return;
        }
            if (this.indexCard !== null) {
                this.dataMemo[this.indexCard].caracteres.forEach((caract, index) => {
                    if (caract.titila && !caract.letraEscrita) {
                        if (this.regex.test(key)) {
                            caract.letraEscrita = key;
                            document.getElementById(`letter_${index}${this.indexCard}`).innerHTML = key;
                            caract. titila= false;
                        }
                        if (index === j) {
                            this.isDelete = false;
                        }
                        return;
                    }
                });
            }
        }

    handleResize() {

        var viewportHeight = window.innerHeight;
        var visualViewportHeight = window.visualViewport.height;
        var keyboardHeight = viewportHeight - visualViewportHeight;

        if (keyboardHeight > 100) {

            let containerMidle = document.getElementById("box_scroll_auto");
            let firstHeight = containerMidle.offsetHeight;
            let secondHeight = firstHeight - keyboardHeight;

            containerMidle.style.maxHeight = secondHeight + "px";
            console.log("A Keyboard tablet:", keyboardHeight, " viewportHeight: ", viewportHeight, " visualViewportHeight: ", visualViewportHeight);
            this.ElelemtSetFocu();
        } 
    }

    handleResizeMob() {

        var viewportHeight = window.innerHeight;
        var visualViewportHeight = window.visualViewport.height;
        var keyboardHeight = viewportHeight - visualViewportHeight;

        if (keyboardHeight > 100) {

            let containerMidle = document.getElementById("visualmemory-main");
            // let firstHeight = containerMidle.offsetHeight;
            let windowHeight = window.innerHeight;
            let secondHeight = windowHeight - keyboardHeight;

            containerMidle.style.maxHeight = secondHeight + "px";
            console.log("A Keyboard:", keyboardHeight);
            this.ElelemtSetFocuMob();
        }
    }

    returnHeightContainer(){
        const containerMidle = document.getElementById("box_scroll_auto");
            containerMidle.style.maxHeight = "none";
            containerMidle.scrollTo({top: 0});
            console.log("return height");
    }

    returnHeightContainerMob(){
        const containerMidle = document.getElementById("visualmemory-main");
            containerMidle.style.maxHeight = "none";
            // containerMidle.scrollTo({top: 0});
            console.log("return height");
    }

    ElelemtSetFocu(){
        var viewportHeight = window.innerHeight;
        var visualViewportHeight = window.visualViewport.height;
        var keyboardHeight = viewportHeight - visualViewportHeight;

        let elementScroll = document.querySelector('.currentChar') as HTMLElement;
        let elementScrollid = elementScroll.id;

        let elementTopResource = document.getElementById(elementScrollid);
        let elemTop = elementTopResource.offsetTop;
        
        let containerMidle = document.getElementById("box_scroll_auto");
        // let containerMidleTop = containerMidle.scrollTop;
        let relativeTop = (keyboardHeight > visualViewportHeight) ? ((elemTop < 180) ? 10 : elemTop) : ((elemTop < 180) ? 0 : elemTop) ;
        // let relativeTop = (elemTop < 180) ? 10 : elemTop;

        containerMidle.scrollTo({top: relativeTop});

        console.log(elementScroll.id + ' - ' + ' top ' + elemTop);
    }

    ElelemtSetFocuMob(){
        let elementScroll = document.querySelector('.currentChar') as HTMLElement;
        let elementScrollid = elementScroll.id;

        let elementTopResource = document.getElementById(elementScrollid);
        let elemTop = elementTopResource.offsetTop;
        
        let containerMidle = document.getElementById("visualmemory-main");
        // let containerMidleTop = containerMidle.scrollTop;
        let relativeTop = (elemTop <= 190) ? 0 : elemTop;

        containerMidle.scrollTo({top: relativeTop});

        // console.log(containerMidle.scrollTop + ' - ' + relativeTop + ' top ' + elemTop);
    }

    setFocus(bool = true) {
        if (bool) {

            document.getElementById("dictation-input").focus();

            var iPadw = window.innerWidth;

            if( iPadw < 1300 && iPadw > 959){
                setTimeout(() => {
                    this.handleResize();
                }, 750); //750 is the correct time for IOs and android delay
            }

            if( iPadw <= 959){
                setTimeout(() => {
                    this.handleResizeMob();
                }, 750);
            }

            console.log("focus true");

        }
        if (!bool) {
            document.getElementById("dictation-input").blur();
            
            var iPadw = window.innerWidth;

            if( iPadw < 1300 && iPadw > 959){
                this.returnHeightContainer();
            }

            if( iPadw <= 959){
                this.returnHeightContainerMob();
            }

            console.log("focus false");
            
        }
    }

        animateValue() {
            // this.coinService.showCoins(100, () => {
            //     window.postMessage("reload-coins", "*");
            //   }, null, "box_0")
            this.spark = true;
            let obj = document.getElementById("walinwos-span");
            let start = this._visualMemory.walinwos - (5 * this._visualMemory.page - 1) * 4;
            let end = this._visualMemory.walinwos;
            let duration = 3000;
            let startTimestamp = null;
            const step = (timestamp) => {
              if (!startTimestamp) startTimestamp = timestamp;
              const progress = Math.min((timestamp - startTimestamp) / duration, 1);
              obj.innerHTML = Math.floor(progress * (end - start) + start) + "";
              if (progress < 1) {
                window.requestAnimationFrame(step);
              }
            };
            window.requestAnimationFrame(step);
          }

    savePageAndExercises() {
        this.verifyResponse()
        let paginaToSave = new MemoImageUser(
            this.IdUser,
            this._visualMemory.walinwos,
            new Date(),
            this._visualMemory.page,
        )
        if (this._visualMemory.page == 1 && this._visualMemory.walinwos == 0) {
            this.sharedService.changeUserCoins(true);
            this._visualMemory.validated = true
            this.loading = false;
            this._ui.stopSpinner();
            return
        }
        forkJoin([
            this._visualMemory.SaveExercises(this.respuestas),
            this._visualMemory.SavePage(paginaToSave)
        ])
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.sharedService.changeUserCoins(true);
                this._visualMemory.validated = true
                this.loading = false;
                this._ui.stopSpinner();
            })
    }

          isIpad() {
            const ua = window.navigator.userAgent;
            if (ua.indexOf('iPad') > -1) {
                return true;
            }
        
            if (ua.indexOf('Macintosh') > -1) {
                try {
                    document.createEvent("TouchEvent");
                    return true;
                } catch (e) {}
            }
        
            return false;
        }   
}