import { Injectable } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UiService } from './ui.service';

@Injectable({
    providedIn: 'root'
})
export class SpinnerService
{

    private _visible: BehaviorSubject<boolean>;

    constructor(
        private _router: Router,
        public _ui: UiService,
    )
    {
        this._init();
    }
    get visible(): Observable<any>
    {
        return this._visible.asObservable();
    }

    private _init(): void
    {

        this._visible = new BehaviorSubject(false);

        this._router.events
            .pipe(filter((event) => event instanceof NavigationStart))
            .subscribe(() => {
                this.show();
            });

        this._router.events
            .pipe(filter((event) => event instanceof NavigationEnd || event instanceof NavigationError || event instanceof NavigationCancel))
            .subscribe(() => {
                this.hide();
            });
    }

    show(): void
    {
        this._visible.next(true);
        this._ui.showSpinner();
    }

    hide(): void
    {
        this._visible.next(false);
        this._ui.stopSpinner();
    }
}

