<div class="walinwa-box">

    <form [formGroup]="form" class="walinwa-box-content" fxLayout="column" fxLayoutAlign="stretch">

        <!--div style="margin-top: -1.3rem;"-->
        <div class="nogap">
            <div>
                <mat-form-field style="width: 100%; padding: 0;">
                    <label class="font_blue"><b>{{'CLASS' | translate}}</b></label>
                    <mat-select [(ngModel)]="selectedClass" (ngModelChange)="changeClass()"
                        [ngModelOptions]="{standalone: true}">
                        <mat-option class="scrollable" [value]=0>{{'SCHOOL.TEACHER.CONFIG.ALL-CLASSES' | translate}}</mat-option>
                        <mat-option class="scrollable" *ngFor="let class of classes | sortBy:'asc':['Level','Code']"
                            [value]="class.IdClass">{{ class.Name }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="stretch" fxLayoutAlign.gt-sm="space-between center">
                <div fxFlex.gt-sm="">
                    <label class="font_blue"><b>{{'SCHOOL.TEACHER.CONFIG.SESSIONS.MULTIME' | translate}}</b>
                        <tooltip position="R" [info]="'SCHOOL.TEACHER.CONFIG.SESSIONS.MULTIME-INFO' | translate"></tooltip>
                    </label>
                </div>
                <div fxFlex.gt-sm="" fxNgClass.lt-md="less_top_margin_field2" (click)="showClassWithoutStudents()">
                    <mat-form-field>
                        <mat-select formControlName="MultTime">
                            <mat-option class="scrollable" [ngStyle]="{'display': value.value === 0 ? 'none;' : ''}" [value]="value.value" *ngFor="let value of multTimes">{{
                                value.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="stretch" fxLayoutAlign.gt-sm="space-between center">
                <div fxFlex.gt-sm="">
                    <label class="font_blue"><b>{{'SCHOOL.TEACHER.CONFIG.SESSIONS.SMALLSESSION' | translate}}</b>
                        <tooltip position="R" [info]="'SCHOOL.SMALLSESSION-INFO' | translate"></tooltip>
                    </label>
                </div>
                <div fxFlex.gt-sm="" fxNgClass.lt-md="less_top_margin_field2" (click)="showClassWithoutStudents()">
                    <mat-form-field>
                        <mat-select formControlName="SmallSession">
                            <mat-option [ngStyle]="{'display': value.value === 0 ? 'none;' : ''}" class="scrollable" [value]="value.value" *ngFor="let value of sessionValues">{{
                                value.desc}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <section fxLayout="row wrap" fxLayoutAlign="space-between center" fxNgClass.gt-sm="spe_gap">
            <div fxFlex="100%" fxFlex.gt-sm="" ngStyle.lt-md="margin-bottom:1rem;">
                <mat-checkbox formControlName="SmallSessionWeekend">
                    <span class="font_w_normal">
                        {{'SCHOOL.TEACHER.CONFIG.SESSIONS.SMALLSESSIONWEEKEND' | translate}}
                    </span>
                </mat-checkbox>
                <tooltip position="R" [info]="'SCHOOL.TEACHER.CONFIG.SESSIONS.SMALLSESSIONWEEKEND-INFO' | translate"></tooltip>
            </div>
        </section>
        <section fxLayout="row wrap" fxLayoutAlign="space-between center" fxNgClass.gt-sm="spe_gap">
            <div fxFlex="100%" fxFlex.gt-sm="" ngStyle.lt-md="margin-bottom:1rem;">
                <mat-checkbox formControlName="SmallSessionSummer">
                    <span class="font_w_normal">
                        {{'SCHOOL.TEACHER.CONFIG.SESSIONS.SMALLSESSIONSUMMER' | translate}}
                    </span>
                </mat-checkbox>
                <tooltip position="R" [info]="'SCHOOL.TEACHER.CONFIG.SESSIONS.SMALLSESSIONSUMMER-INFO' | translate"></tooltip>
            </div>
        </section>
        <section fxLayout="row wrap" fxLayoutAlign="space-between center" fxNgClass.gt-sm="spe_gap">
            <div fxFlex="100%" fxFlex.gt-sm="" ngStyle.lt-md="margin-bottom:1rem;">
                <mat-checkbox formControlName="Retrieve">
                    <span class="font_w_normal">{{'SCHOOL.TEACHER.CONFIG.SESSIONS.RETRIEVE' | translate}}
                    </span>
                </mat-checkbox>
                <tooltip position="L" [info]="'SCHOOL.TEACHER.CONFIG.SESSIONS.RETRIEVE-INFO' | translate"></tooltip>
                
            </div>            
        </section>
        <section fxLayout="row wrap" fxLayoutAlign="space-between center" fxNgClass.gt-sm="spe_gap">
            <div fxFlex="100%" fxFlex.gt-sm="" ngStyle.lt-md="margin-bottom:1rem;">
                <mat-checkbox formControlName="WeekendSkipTodaySession">
                    <span class="font_w_normal">{{'SCHOOL.TEACHER.CONFIG.SESSIONS.WEEKENDSKIPTODAYSESSION' | translate}}
                    </span>
                </mat-checkbox>
                <tooltip position="L" [info]="'SCHOOL.TEACHER.CONFIG.SESSIONS.WEEKENDSKIPTODAYSESSION-INFO' | translate"></tooltip>
            </div>
        </section>
        <div class="walinwa-row" fxLayoutAlign="end end" fxLayoutAlign.xs="center center" style="margin-top: 20px">

            <button (click)="save()" class="new_but azul">
                {{'SAVE' | translate}}
            </button>

        </div>
    </form>

</div>