<div class="walinwa-box walinwa-box-green" *ngIf="dataLoaded" fxNgClass.gt-sm="scroll_vertical">
    <div class="walinwa-box-header">
        Sección de informes
    </div>
    <div class="walinwa-box-content" [hidden]="expandControls == false">
        <div *ngIf="loading" class="walinwa-box-content-loading">
            <img src="https://walinwa.blob.core.windows.net/images/loading.gif?ngsw-bypass=true">
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="spe_gap">
            <div fxFlex="" fxFlex.lt-md="100%">
                <label class="font_blue">{{'SCHOOL.TEACHER.TRACKING.REPORT.TITLE' | translate}}</label>
                <div class="ww_field_spe">
                    <select (change)="checkButton()" [(ngModel)]="reportType" [ngModelOptions]="{standalone: true}">
                        <option [value]="reportType.name" *ngFor="let reportType of reportTypes">
                            {{reportType.desc }}
                        </option>
                    </select>
                </div>
            </div>
            <div fxFlex="" fxFlex.lt-md="100%">
                <label class="font_blue">{{'SCHOOL.TEACHER.TRACKING.REPORT.STUDENT' | translate}}</label>
                <div class="ww_field_spe">
                    <select (change)="loadSessionMonth()" [(ngModel)]="studentId" [ngModelOptions]="{standalone: true}">
                        <option [value]="std.Id" *ngFor="let std of students">{{ (std.FamilyName == null ? "" : std.FamilyName) + getComa(std) + ' ' +
                            std.Name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="spe_gap" [hidden]="dateHidden()">
            <div fxFlex="" fxFlex.xs="100%">                
                <mat-form-field class="less_top_margin_field">
                    <label class="font_blue">{{'SCHOOL.TEACHER.TRACKING.REPORT.FROM' | translate}}</label>
                    <input matInput [matDatepicker]="picker1" [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="selectedDay_desde">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
            </div>
            <div fxFlex="" fxFlex.xs="100%">                
                <mat-form-field class="less_top_margin_field">
                    <label class="font_blue">{{'SCHOOL.TEACHER.TRACKING.REPORT.TO' | translate}}</label>
                    <input matInput [matDatepicker]="picker2" [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="selectedDay_hasta">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="" [hidden]="reportType != 'studentRegularity'">
            <label class="font_blue">{{'SCHOOL.TEACHER.TRACKING.REPORT.YEAR' | translate}}</label>
            <div class="ww_field_spe">
                <select [(ngModel)]="year" [ngModelOptions]="{standalone: true}">
                    <option [value]="year.name" *ngFor="let year of years">{{ year.desc }}
                    </option>
                </select>
            </div>
        </div>
        <div class="" [hidden]="reportType != 'studentSession'">
            <calendar *ngIf="student" class="calendario dinamico azul walCalendar ww_customCalendar" [parent]="this" [fullDay]="false" [currentUser]="student" [selectedDay]="selectedDay" [currentMonth]="currentMonth"></calendar>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center" class="spe_gap">
            <button (click)="changeControls()" [hidden]="expandControls == false" [disabled]="disableActionButton"
            class="new_but azul">
            {{'SCHOOL.TEACHER.TRACKING.REPORT.SHOW-REPORT' | translate}}
            </button>
        </div>
    </div>
</div>

<div class="walinwa-box-modal-overlay" [hidden]="expandControls == true">
    <div class="walinwa-report">
        <div class="walinwa-box-modal-header">

            {{'SCHOOL.TEACHER.TRACKING.REPORT.REPORT-SECTION' | translate}}

            <div class="walinwa-box-modal-header-icons">
                <img src="https://walinwa.blob.core.windows.net/images/School/descargar-excel-b.svg?ngsw-bypass=true" title="Descargar en Excel" (click)="getExcel()"
                    [hidden]="showDownload == false" />
                <img src="https://walinwa.blob.core.windows.net/images/School/descargar-pdf-b.svg?ngsw-bypass=true" title="Descargar en PDF" (click)="getPdf()"
                    [hidden]="showDownload == false" />
                <img src="https://walinwa.blob.core.windows.net/images/but-home.svg?ngsw-bypass=true" title="Volver al inicio" (click)="changeControls()"
                    [hidden]="expandControls == true" />
            </div>

        </div>

        <div *ngIf="loading" class="walinwa-box-modal-container walinwa-box-modal-container-loading">

            <img src="https://walinwa.blob.core.windows.net/images/loading.gif?ngsw-bypass=true">

        </div>

        <iframe *ngIf="!loading" id="report-frame" [attr.srcdoc]="htmlCode"
            class="walinwa-box-modal-container"></iframe>

    </div>
</div>