<div class="walinwa-box-modal-overlay" *ngIf="hearingText" fxLayout="column" fxLayoutAlign="center center">

    <div *ngIf="!review" class="hearing_block" [ngStyle]="{ 'background-image': 'url(&quot;https://walinwa.blob.core.windows.net/images/Comprensiones/Hearing/comprension-auditiva.svg?ngsw-bypass=true&quot;)' }"
        [ngClass]="{ 'active': hearingText }" fxLayout="column"
        fxLayoutAlign="stretch">
        <div fxFlex="" class="body" fxLayout="column" fxLayoutAlign="stretch" fxLayoutGap="1.25rem">
            <div class="header_modal" fxLayout="row" fxLayoutAlign="space-between center">
                <div fxFlex="7rem" fxHide.xs>
                    <img src="https://walinwa.blob.core.windows.net/images/Comprensiones/logo_walinwa.svg?ngsw-bypass=true">
                </div>
                <div *ngIf="questionIndex==-1" class="title_blue">
                    {{'STUDENT.HEARING-TEXT.PLAY.HEARING-COMPREHENSION' | translate }} <br fxHide fxShow.xs>{{ getDate() }}
                </div>
                <div *ngIf="questionIndex>-1 && hearingText" class="title_blue">
                    {{'STUDENT.TEXT-EXERCISES.QUESTIONS-PROGRESS' | translate:{questionNum:questionIndex + 1,questionCount:hearingText.Questions.length} }}
                </div>
                <div fxLayout="row" fxLayoutAlign="end start" class="spe_gap">
                    <div *ngIf="hearingText && questionIndex == -1" fxFlex="6rem" fxFlex.xs="4rem">
                        <img *ngIf="!teacherReview && !review" (click)="escuchar()" src="https://walinwa.blob.core.windows.net/images/Comprensiones/Hearing/escuchar-audio-b.svg?ngsw-bypass=true" class="pointer">
                    </div>
                    <div *ngIf="hearingText && questionIndex > -1" class="walinwos_badge" fxLayout="row" fxLayoutAlign="center center"
                        fxLayoutGap="7px">
                        <img src="https://walinwa.blob.core.windows.net/images/walinwo.gif?ngsw-bypass=true" fxFlex="2.5rem">
                        <span>
                            {{ walinwos }} {{'STUDENT.TEXT-EXERCISES.OF-CONNECTOR' | translate }} {{ hearingText.Questions.length * 10 }}
                        </span>
                    </div>
                    <div fxFlex="2.4rem">
                        <img (click)="finish()" [ngClass]="{'fixClose': questionIndex>-1 && hearingText}" src="https://walinwa.blob.core.windows.net/images/ico-close-avatar.svg?ngsw-bypass=true" class="pointer">
                    </div>
                </div>
            </div>
            <div fxFlex="" class="content_scroll" fxLayout="column" fxLayoutAlign="stretch">
                <form [formGroup]="form" *ngIf="hearingText && form" class="" fxFlex="" fxLayout="column"
                    fxLayoutAlign="space-between stretch" fxLayoutGap="1.25rem">

                    <div *ngIf="questionIndex==-1" fxLayout="column" fxlayoutALign="stretch" class="spe_gap">
                        <div [innerHtml]="'STUDENT.HEARING-TEXT.PLAY.START-HEARING-ADVICE' | translate">
                        </div>
                        <div fxLayout="column" fxLayoutAlign="stretch" class="block_questions spe_gap">
                            <span [innerHtml]="question.Question | replaceTags: i + 1" class="question" *ngFor="let question of hearingText.Questions; let i = index;">
                            </span>
                        </div>
                    </div>

                    <div *ngIf="questionIndex>-1" class="hearing-text-content" fxFlex="" fxLayout="column"
                        fxLayoutAlign="stretch" fxLayoutGap="1.25rem">
                        <div class="hearing-text-question"
                            [innerHtml]="hearingText.Questions[questionIndex].Question | replaceTags"></div>

                        <fieldset [disabled]="correct != null" class="hearing-text-answers" fxLayout="column"
                            fxLayoutAlign="stretch" fxLayoutGap="1.25rem">
                            <label [ngClass]="{ isCorrect: correct != null && answer.IsCorrect }"
                                class="hearing-text-answer"
                                *ngFor="let answer of hearingText.Questions[questionIndex].Answers; let i = index;">
                                <input type="radio" [value]="answer.IdAnswer" formControlName="IdAnswer">
                                <span style="width: 100%;" [innerHtml]="answer.Answer | replaceTags"></span>
                            </label>
                        </fieldset>
                    </div>

                    <div class="buttons fixid" fxLayout="row" fxLayout.xs="row wrap"
                        fxLayoutAlign="end end">
                        <button [disabled]="loading || correct != null" (click)="next()" *ngIf="questionIndex >= 0" class="new_but azul">
                            {{'STUDENT.TEXT-EXERCISES.ANSWER-BUTTON' | translate }}
                        </button>
                        <button [disabled]="loading || correct != null" (click)="next()"
                            *ngIf="questionIndex == -1 && !score" class="new_but azul">
                            {{'START' | translate }}
                        </button>
                    </div>

                </form>
            </div>
        </div>
    </div>

    <div *ngIf="review && !loading"  class="hearing_block review_task" 
        [ngStyle]="{ 'background-image': 'url(&quot;https://walinwa.blob.core.windows.net/images/Comprensiones/Hearing/comprension-auditiva.svg?ngsw-bypass=true&quot;)' }"
        [ngClass]="{ 'active': hearingText }" fxLayout="column"
        fxLayoutAlign="stretch">
        <div class="body" fxFlex="" fxLayout="column" fxLayoutAlign="stretch" fxLayoutGap="20px">
            <div class="header_modal" fxLayout="row" fxLayout.xs="row wrap" fxLayoutAlign="space-between center" fxLayoutAlign.xs="space-between start" fxLayoutGap.xs="7px">
                <div class="title_blue">
                    <div>{{'STUDENT.HEARING-TEXT.PLAY.HEARING-COMPREHENSION' | translate }}<br>{{ getDate() }}</div>
                    <div fxHide fxShow.xs *ngIf="hearingText.Score != null" class="nota"><span> {{'STUDENT.TEXT-EXERCISES.MARK-TELL-TEXT' | translate }} {{
                        hearingText.Score }} </span> <span *ngIf="hearingText.Retries > 0">({{'STUDENT.HEARING-TEXT.PLAY.RETRIES' | translate }}
                        {{hearingText.Retries}})</span></div>
                </div>
                <div fxFlex="6rem" fxFlex.xs="4rem">
                    <img (click)="escuchar()" src="https://walinwa.blob.core.windows.net/images/Comprensiones/Hearing/escuchar-audio-b.svg?ngsw-bypass=true" class="pointer">
                </div>
                <div fxHide.xs *ngIf="!teacherReview || !reviewingActivity">
                    <span *ngIf="hearingText.Score != null" class="nota"><span> {{'STUDENT.TEXT-EXERCISES.MARK-TELL-TEXT' | translate }} {{
                            hearingText.Score }} </span> <span *ngIf="hearingText.Retries > 0">({{'STUDENT.HEARING-TEXT.PLAY.RETRIES' | translate }}
                            {{hearingText.Retries}})</span></span>
                </div>
                <div fxFlex="2.4rem">
                    <img (click)="finish()" src="https://walinwa.blob.core.windows.net/images/ico-close-avatar.svg?ngsw-bypass=true" class="pointer">
                </div>
            </div>

            <div fxFlex="" class="content_scroll" fxLayout="column" fxLayoutAlign="stretch" fxLayoutGap="1.25rem">

                <div class="box_question" fxFlex="" *ngIf="teacherReview">
                    <div class="comment sangrado_text" [innerHtml]="hearingText.Fragment | replaceTags"></div>
                    <div class="author_book">
                        <span>{{ getBookName(hearingText.Book) }} </span>
                        <span *ngIf="hearingText.Author"> {{ hearingText.Author }}</span>
                    </div>
                </div>

                <div>
                    <label class="font_blue">{{'STUDENT.HEARING-TEXT.PLAY.ANSWERS-LABEL' | translate }}</label>
                </div>

                <div class="box_question" fxFlex="" fxLayout="column" fxLayoutAlign="stretch" fxLayoutGap="1.25rem">
                    <div class="hearing_text_review_question"
                        *ngFor="let question of hearingText.Questions; let i = index">
                        
                        <div class="morado"
                            [innerHtml]="question.Question | replaceTags: i + 1">
                        </div>

                        <div [ngClass]="{ ok: answer.IsCorrect, ko: !answer.IsCorrect && wasSelected(answer) }"
                            class="hearing_text_review_question_answer"
                            *ngFor="let answer of question.Answers; let i = index;" fxLayout="row" fxLayoutAlign="start center">

                            <div class="hearing_text_review_question_answer_check" fxFlex="2rem">
                                <i *ngIf="answer.IsCorrect" class="fas fa-check"></i>
                            </div>

                            <div class="hearing_text_review_question_answer_text">
                                <span [innerHtml]="answer.Answer | replaceTags"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <img (click)="goNext(false)" *ngIf="correct === false && !loading" class="correct" src="https://walinwa.blob.core.windows.net/images/Comprensiones/bad.png?ngsw-bypass=true">
    <img (click)="goNext(false)" *ngIf="correct === true && !loading" class="correct" src="https://walinwa.blob.core.windows.net/images/Comprensiones/good.png?ngsw-bypass=true">

</div>

<plank-selector *ngIf="finished" title="Ejercicio terminado" [isFinAnticipado]="false" [show]="messages.length>0"
    [mark]="score.Score" [messages]="messages" [walinwos]="score.Walinwos" [newScenary]="nuevoFondo"
    [errors]="score.NumExercises - score.NumOk" (onClose)="finish()"></plank-selector>