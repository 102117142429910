

export enum ShopItemType {
    game = 0,
    bakground = 1,
    bakgroundTop = 2,
    component = 3,
    messageOk = 4,
    componentGif = 5,
    messageTime = 6,
    cofre = 7,
    tablero = 8,
    tablon = 9,
    frases = 10,
    logos = 11,
    walinwos = 12,
    diploma = 13

}