import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LeeBooks } from 'app/core/shared/state/models/Wali-lee/lee-books.model';
import { LeeSections } from 'app/core/shared/state/models/Wali-lee/lee-sections.model';
import { AuthService } from 'app/services/auth.service';
import { WaliLeeService } from 'app/services/wali-lee.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { combineLatest, fromEvent, Observable, of, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MapSectionComponent } from './map-section/map-section.component';

@Component({
    selector: 'wali-lee',
    templateUrl: './wali-lee.component.html',
    styleUrl: './wali-lee.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WaliLeeComponent implements OnInit, OnDestroy, AfterViewInit {
    private _unsubscribeAll: Subject<any>;
    private _timeout: number = null;
    private _numTotalSections: number = 0;
    private _mapWidth: number = 1024;
    private _mapHeight: number = 764;
    private _fireWidth: number = 100;
    private _fireHeigth: number = 150;
    private _fireX: number = 365;
    private _fireY: number = 530;

    @HostListener('window:resize', ['$event'])
    onResize = _ => this._markForUpdate();

    @ViewChildren(MapSectionComponent) mapSectionComponents: QueryList<MapSectionComponent>;
    @ViewChild('mapInner', { static: true }) mapInner: ElementRef;
    @ViewChild('fire', { static: true }) fire: ElementRef;

    public fondoImg = "https://walinwadevfr.blob.core.windows.net/images/WaliLee/fondo.png?ngsw-bypass=true";
    public mapSectionTemplate: TemplateRef<any> = null;
    public mapHidden: boolean = false;
    public onLoadMap: Subject<void> = new Subject();
    public onLoadFire: Subject<void> = new Subject();
    public onLoadMap$: Observable<void> = this.onLoadMap.asObservable();
    public onLoadFire$: Observable<void> = this.onLoadFire.asObservable();
    public drawSections: boolean = false;    
    public mapSections: LeeSections[] = [];
    public mapSectionsCoordinates = [{
        x: 147,
        y: 340
    },{
        x: 188,
        y: 321
    },{
        x: 231,
        y: 293
    },{
        x: 271,
        y: 267
    },{
        x: 314,
        y: 244
    },{
        x: 354,
        y: 217
    },{
        x: 396,
        y: 192
    },{
        x: 445,
        y: 162
    },{
        x: 492,
        y: 134
    },{
        x: 532,
        y: 110
    },{
        x: 564,
        y: 92
    },{
        x: 594,
        y: 72
    },{
        x: 626,
        y: 92
    },{
        x: 658,
        y: 111
    },{
        x: 689,
        y: 128
    },{
        x: 655,
        y: 149
    },{
        x: 621,
        y: 172
    },{
        x: 583,
        y: 198
    },{
        x: 540,
        y: 227
    },{
        x: 500,
        y: 253
    },{
        x: 457,
        y: 281
    },{
        x: 405,
        y: 314
    },{
        x: 362,
        y: 341
    },{
        x: 328,
        y: 364
    },{
        x: 292,
        y: 389
    },{
        x: 259,
        y: 411
    },{
        x: 288,
        y: 430
    },{
        x: 322,
        y: 451
    },{
        x: 355,
        y: 475
    },{
        x: 391,
        y: 496
    },{
        x: 420,
        y: 516
    },{
        x: 444,
        y: 532
    },{
        x: 478,
        y: 554
    },{
        x: 515,
        y: 579
    },{
        x: 554,
        y: 604
    },{
        x: 597,
        y: 632
    },{
        x: 628,
        y: 652
    },{
        x: 656,
        y: 673
    },{
        x: 692,
        y: 650
    },{
        x: 728,
        y: 626
    },{
        x: 772,
        y: 602
    },{
        x: 816,
        y: 576
    },{
        x: 784,
        y: 556
    },{
        x: 752,
        y: 534
    },{
        x: 716,
        y: 514
    },{
        x: 682,
        y: 491
    },{
        x: 649,
        y: 468
    },{
        x: 614,
        y: 445
    },{
        x: 570,
        y: 419
    },{
        x: 532,
        y: 393
    },{
        x: 564,
        y: 373
    },{
        x: 593,
        y: 352
    },{
        x: 621,
        y: 334
    },{
        x: 655,
        y: 311
    },{
        x: 691,
        y: 287
    },{
        x: 723,
        y: 262
    },{
        x: 756,
        y: 241
    },{
        x: 782,
        y: 224
    },{
        x: 806,
        y: 206
    },{
        x: 834,
        y: 221
    },{
        x: 858,
        y: 238
    },{
        x: 886,
        y: 254
    },{
        x: 917,
        y: 274
    },{
        x: 945,
        y: 292
    }, {
        x: 910,
        y: 315
    },{
        x: 880,
        y: 334
    },{
        x: 849,
        y: 335
    },{
        x: 822,
        y: 374
    },{
        x: 787,
        y: 397
    }];

    public book: LeeBooks;
    public showLastMessage: boolean = false;
    public showNextBookMessage: boolean = false;
    public nextBookBackgroundStyle: SafeStyle;
    public nextBookMessage: string;
    
    constructor(private _waliLeeService: WaliLeeService, 
                private _authService: AuthService,
                private _detector: ChangeDetectorRef,
                private _sanitizer: DomSanitizer,
                private _translateService: TranslateService){
        this._unsubscribeAll = new Subject();
    }
    
    ngOnInit(): void {       
        if(moment().hours() >= 8 && moment().hours() <= 18){
            this.fondoImg = "https://walinwadevfr.blob.core.windows.net/images/WaliLee/fondo_manana.png?ngsw-bypass=true"
        }
        this._initSubscriptions();
    }
    
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    
    ngAfterViewInit(): void {
        this._getCurrentMapSection();
        this._attachScroll();
    }

    private _initSubscriptions() {
        combineLatest([
            this._waliLeeService.GetBook(this._authService.currentUser.Id),
            this._waliLeeService.GetMap(this._authService.currentUser.Id),
            this.onLoadMap$,
            this.onLoadFire$
        ]).pipe(takeUntil(this._unsubscribeAll), take(1)).subscribe(data => {
            this.book = data[0];
            this.mapSections = data[1];

            const positionJump = this.mapSectionsCoordinates.length / this.mapSections.length;
            const numTotalCoordinates = this.mapSectionsCoordinates.length - 1;
            const numTotalSections = this.mapSections.length - 1;
            const numberGamesToShow = Math.ceil(numTotalSections / Math.floor(numTotalSections * 0.2));

            this._numTotalSections = numTotalSections;

            let position = 0;
            
            let flag = false;
            this.mapSections.forEach((s: LeeSections, i: number) => {
                s.Chapter = i + 1;

                if(!flag && s.Approved == false){
                    s.Current = true;
                    flag = true;
                }
                else {
                    s.Current = false;
                }
                
                if (i === 0) {
                    s.coordinates = this.mapSectionsCoordinates[i];
                }
                else if (i == numTotalSections){
                    s.coordinates = this.mapSectionsCoordinates[numTotalCoordinates];
                }
                else {
                    s.coordinates = this.mapSectionsCoordinates[Math.floor(position)];
                }

                s.showGame = i % numberGamesToShow === 0;

                if (i === numTotalSections) {
                    s.Advance = 3; // Último test
                } else if (i >= this.mapSections.length / 2) {
                    s.Advance = 2; // Más del 50%
                } else {
                    s.Advance = 1; // Menos del 50%
                }

                position += positionJump;
            });

            this._updateFirePosition();
            this.fire.nativeElement.style.zIndex = '';
            this.drawSections = true;
            if(this.mapSections.every(x => x.Approved)){
                this.loadBookMessage();
            }
            this._detector.detectChanges();
            this._getCurrentMapSection();
        });
    }

    private _getCurrentMapSection() {
        const mapSectionComponet = this.mapSectionComponents.find(ms => !!ms.mapSectionTemplate) || null;

        if (mapSectionComponet)
            this.mapSectionTemplate = mapSectionComponet.mapSectionTemplate;

        this._detector.detectChanges();
    }

    private loadBookMessage() {
        this._waliLeeService.getNextClassBook(this._authService.currentUser.Id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                if (result && result.StartDate) {
                    this.nextBookMessage = this._translateService.instant('STUDENT.WALILEE.NEXT-BOOK-MESSAGE').replace('{0}', moment.utc(result.StartDate).toDate().toLocaleDateString('es-ES', { day: 'numeric', month: 'long' }));
                } else {
                    this.nextBookMessage = this._translateService.instant('STUDENT.NO-BOOK-ASSIGNED');
                }
                this.nextBookBackgroundStyle = this._sanitizer.bypassSecurityTrustStyle(`url('${environment.filesUrl}/images/WaliLee/nextBook.png?ngsw-bypass=true')`);
                this.showNextBookMessage = true;
                this._detector.detectChanges();
            })
    }

    public updateMap(idSection: number, approved: boolean): void {
        const sectionIndex = this.mapSections.findIndex(s => s.IdSection == idSection);
        const nextSectionIndex = sectionIndex + 1;

        this.mapSections[sectionIndex].Started = true;
        this.mapSections[sectionIndex].Approved = approved;
        this.mapSections[sectionIndex].Current = !approved;

        if (approved) {
            if (nextSectionIndex <= this._numTotalSections) {
                this.mapSections[nextSectionIndex].Current = true;
                this.mapSections[nextSectionIndex] = { ...this.mapSections[nextSectionIndex] };
            } else if (sectionIndex === this._numTotalSections) {
                this.showLastMessage = true;
            }
        }

        combineLatest([
            this._waliLeeService.getMapSectionResult(this.mapSections[sectionIndex].IdLee, idSection, this._authService.currentUser.Id),
            this.showLastMessage ? this._waliLeeService.getNextClassBook(this._authService.currentUser.Id) : of(null)
        ]).pipe(takeUntil(this._unsubscribeAll), take(1)).subscribe(data => {
            this.mapSections[sectionIndex].NumExercises = data[0]?.NumExercises ?? 0;
            this.mapSections[sectionIndex].NumOk = data[0]?.NumOk ?? 0;

            this.mapSections[sectionIndex] = { ...this.mapSections[sectionIndex] };
            this.mapSections[nextSectionIndex] = { ...this.mapSections[nextSectionIndex] };

            if (this.showLastMessage) {
                if (data[1] && data[1].StartDate) {
                    this.nextBookBackgroundStyle = this._sanitizer.bypassSecurityTrustStyle(`url('${environment.filesUrl}/images/WaliLee/nextBook.png?ngsw-bypass=true')`);
                    this.nextBookMessage = this._translateService.instant('STUDENT.WALILEE.NEXT-BOOK-MESSAGE').replace('{0}', moment.utc(data[1].StartDate).toDate().toLocaleDateString('es-ES', { day: 'numeric', month: 'long' }));                
                } else {
                    this.nextBookMessage = this._translateService.instant('STUDENT.WALILEE.NO-BOOK-ASSIGNED');
                }
            }

            this.mapHidden = false;
            this._detector.detectChanges();
            this._getCurrentMapSection();
        });
    }

    public hideMap(hide: boolean) {
        this.mapHidden = hide;
        this._detector.detectChanges();
    }

    private _attachScroll() {
        fromEvent(document.querySelector('#studentContainer'), 'scroll').pipe(takeUntil(this._unsubscribeAll)).subscribe(_ => this._markForUpdate());
    }

    private _markForUpdate() {
        if (this._timeout) clearTimeout(this._timeout);
        
        this._timeout = setTimeout(() => this._updateFirePosition());
    }

    private _updateFirePosition() {
        const mapRect = this.mapInner.nativeElement.getBoundingClientRect();
        const changePercent = (mapRect.width - this._mapWidth) / this._mapWidth * 100;

        let changeWidth = changePercent > 0 ? (100 - changePercent) / 100 : (100 + changePercent) / 100;

        changeWidth = parseFloat(changeWidth.toFixed(2));

        this.fire.nativeElement.style.left = ((this._fireX / this._mapWidth) * mapRect.width) + (mapRect.width > this._mapWidth ? changePercent : 0) + 'px';
        this.fire.nativeElement.style.top = ((this._fireY / this._mapHeight) * mapRect.height) + (mapRect.height > this._mapHeight ? changePercent : 0) + 'px';
        this.fire.nativeElement.style.width = this._fireWidth * changeWidth + 'px';
        this.fire.nativeElement.style.height = this._fireHeigth * changeWidth + 'px';
    }

    public lastMessageClose() {
        this.showLastMessage = false;
        this.showNextBookMessage = true;
    }
}