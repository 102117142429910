import { Component, Input, OnInit, OnDestroy, HostListener, ViewEncapsulation } from '@angular/core';
import { SharedService } from 'app/core/shared/shared.service';
import { ExerciseService } from 'app/services/exercise.service';

@Component({
  selector: 'ex-pasap',
  templateUrl: './pasap.component.html',
  styleUrls: ['./pasap.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PasapComponent implements OnInit, OnDestroy     {

  @Input() review
  @Input() show_errors;
  @Input() controlSession : boolean = false;
  @Input() slide : boolean = false; @Input() current;
  exercise
  draggingOption
  exerciseIsEnded = false
  finished = false

  colores = [
    { r: 255, g: 145, b: 0 },
    { r: 12, g: 161, b: 241 },
    { r: 110, g: 203, b: 11 },
    { r: 59, g: 179, b: 156 },
    { r: 255, g: 192, b: 0 },
    { r: 255, g: 1, b: 148 },
    { r: 134, g: 59, b: 153 }
  ]
  colorSeleccionado
  clic = false
  numOk = 0
  numErr = 0
  peque = false;
  reloj
  index_letra_actual = 0
  showResult = false;
  elementsOk
  elementsErr

  timeoutPrincipal
    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case "SHOW_SOLUTION":
                this.showSolution();
                break;
            case "SHOW_ANSWER":
            case "SHOW_CUSTOM":
                this.ShowAnswer();
                break;
        }
    }
  
  constructor(private exerciseService: ExerciseService, private sharedService: SharedService) {
    this.exercise = this.exerciseService.getExerciseDefinition();
    //this.pre()
  }
    ngOnDestroy(){
        if (this.reloj) {
            clearTimeout(this.reloj);
        }
        if(this.timeoutPrincipal){
            clearTimeout(this.timeoutPrincipal);
        }
    }

  ngOnInit() {
    if (this.review) {
      this.elementsOk = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsOK
      );
      this.elementsErr = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsERR
      );
      this.showResult = true;
      if (this.show_errors == false) {
        this.showSolution();
      } else if (this.show_errors == true || this.show_errors == null) {
        this.ShowAnswer();
      }
    } else {
      this.pre();
      this.initInteract();
    }
  }
    showHourGlassSolution(){
        let result;
        this.exercise.result = [];
        this.exercise.palabras.forEach(palabra => {
            palabra.opciones.forEach(opcion => {
                if(opcion.opcion == 1) {
                    result = "<i>" + opcion.palabra + "</i>" + " / " +  palabra.def
                }
            });
            this.exercise.result.splice(this.exercise.result.length, 0, { word: result, answered: null });
        });
    }
    showSolution() {
        let result;
        this.exercise.result = [];
        this.exercise.palabras.forEach(palabra => {
            palabra.opciones.forEach(opcion => {
                if(opcion.opcion == 1) {
                    result = "<i>" + opcion.palabra + "</i>" + " / " +  palabra.def
                }
            });
            this.exercise.result.splice(this.exercise.result.length, 0, { word: result, answered: true });
        });
    }

  ShowAnswer() {
    this.exercise.result = [];
    this.exercise.palabras.forEach((palabra, indexPalabra) => {
        let answered = null;
        let result;
        this.elementsOk.forEach(indexOk => {
            if(indexOk == indexPalabra) {
                answered = true;
            }
        });
        this.elementsErr.forEach(indexError => {
            if(indexError == indexPalabra) {
                answered = false;
            }
        });
        palabra.opciones.forEach(opcion => {
            if(opcion.opcion == 1) {
                result = "<i>" + opcion.palabra + "</i>" + " / " +  palabra.def
            }
        });
        this.exercise.result.splice(this.exercise.result.length, 0, { word: result, answered: answered });
    });
  }

  initInteract() {
    this.exerciseService.initInteract({
      onmove: (event) => {
        this.draggingOption = event.target
      },
      drop: (event) => {

      },
    });
  }
  randomColor() {
    while (true) {
      var random = Math.floor(Math.random() * this.colores.length);
      if (random != this.colorSeleccionado) {
        this.colorSeleccionado = random;
        break;
      }
    }
  }

  validar(palabra) {
    if (!this.clic) {
      this.clic = true;
      if (palabra.opcion) {
        palabra.answered = true;
        this.exerciseService.answer(this.index_letra_actual, true, this.exercise.palabras.length)
        this.numOk++;
        if (this.reloj) {
            clearTimeout(this.reloj)
        }
        this.siguiente_letra(false, true);
      } else {
        palabra.answered = false;
        this.exerciseService.answer(this.index_letra_actual, false, this.exercise.palabras.length)
        this.numErr++;
        this.clic = false;
      }
    }
  }

  shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;

      if (array[currentIndex].originalIndex == null)
        array[currentIndex].originalIndex = randomIndex;
      if (array[randomIndex].originalIndex == null)
        array[randomIndex].originalIndex = currentIndex;
    }
    return array;
  }

  pre() {

    this.exercise.tiempo = Infinity;
    
    for (var i = 0; i < this.exercise.palabras.length; i++) {
      this.exercise.palabras[i].opciones = this.shuffle(this.exercise.palabras[i].opciones);
      for (var j = 0; j < this.exercise.palabras[i].opciones.length; j++) {
        this.exercise.palabras[i].opciones[j].answered = null;
      }
    }

    this.exercise.seg = parseFloat(this.exercise.seg.toString());
    this.siguiente_letra(true, true);

  }

  color() {
    var c = this.colores[this.colorSeleccionado];
    if (c == null) {
      return '';
    }
    return {
      'background': "rgb(" + c.r + ", " + c.g + ", " + c.b + ")"
    }
  }

  defFondo() {

    var c = this.colores[this.colorSeleccionado];
    if (c == null) {
      return {
        'color': 'white'
      };
    }
    return {
      'background': "rgb(" + c.r + ", " + c.g + ", " + c.b + ")",
      'color': 'white'
    };

  }

  palFondo(palabra) {
    if (palabra.show) {
      var c = this.colores[this.colorSeleccionado];
      return {
        'background': "rgb(" + c.r + ", " + c.g + ", " + c.b + ")",
        'color': 'white'
      }
    } else {
      return null;
    }
  }

  dataLoaded

  siguiente_letra(start, correcta) {
    if (!start) {
      var correcta;
      for (var i = 0; i < this.exercise.palabras[this.index_letra_actual].opciones.length; i++) {
        if (this.exercise.palabras[this.index_letra_actual].opciones[i].answered == true || this.exercise.palabras[this.index_letra_actual].opciones[i].opcion) {
          correcta = i;
        }
      }
      this.exercise.palabras[this.index_letra_actual].opciones[correcta].show = 1;
      this.clic = true;
    }

    //Retardo por defecto 2 segundos
    var s = 2000;
    // var s = 200000; // Leo
    if (start) {
      //Al empezar no hay retardo
      s = 0;
    } else if (correcta) {
      //Si es correcta el retardo baja a 1 segundo para no esperar tanto
      s = 1000;
      // s = 100000; // Leo
    }
    this.timeoutPrincipal = setTimeout(() => {
      if (start) {
        this.index_letra_actual = 0;
        this.peque = false;
        for (var i = 0; i < this.exercise.palabras[this.index_letra_actual].opciones.length; i++) {
          if (this.exercise.palabras[this.index_letra_actual].opciones[i].palabra.length > 13) {
            this.peque = true;
          }
        }
        this.dataLoaded = true;
        this.randomColor();
        // esto solo se ejecuta si, el usuario no respondio correctamente todas las palabras   
      } else if (this.index_letra_actual == this.exercise.palabras.length - 1 && this.exerciseService.getCurrentExercise().NumElementsOK < this.exercise.palabras.length) {
        this.finished = true;
        if (this.reloj) clearTimeout(this.reloj)
        this.exerciseService.endExercise()
        return
      } else {
        this.index_letra_actual++;
        this.peque = false;
        for (var i = 0; i < this.exercise.palabras[this.index_letra_actual].opciones.length; i++) {
          if (this.exercise.palabras[this.index_letra_actual].opciones[i].palabra.length > 13) {
            this.peque = true;
          }
        }
        this.clic = false;
        this.randomColor();
      }

      if (this.index_letra_actual < this.exercise.palabras.length) {
        if (this.reloj) {
            clearTimeout(this.reloj);            
        }
        this.reloj = setTimeout(this.siguiente_letra.bind(this), this.exercise.seg * 1000, false, false);
      }
    }, s);

  }
  replaceI(text) {
    return text.replace("<i>", "<span>").replace("</i>", "</span>")
  }
    /*getImgUrl() {
        return this.sharedService_.getImgBackgroundUrl("Exercises/Pasap/pasapalabra.gif")
    }*/
}
