import { Component, Input, OnInit, OnDestroy, HostListener, ViewEncapsulation } from '@angular/core';
import { ExerciseService } from 'app/services/exercise.service';

@Component({
  selector: 'ex-rima',
  templateUrl: './rima.component.html',
  styleUrls: ['./rima.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RimaComponent implements OnInit, OnDestroy {

  @Input() review
  @Input() show_errors;
  @Input() controlSession : boolean = false;
  @Input() slide : boolean = false; @Input() current;
  exercise
  draggingOption
  exerciseIsEnded = false
  finished = false
  showingSolution = false;
  palabras;

  rimasCorrectas;
  rimas;
  error = null;

  elementsOk
  elementsErr

  @HostListener('window:message', ['$event'])
    exerciseListener(event){
      let data = event.data;
      switch (data) {
        case "SHOW_SOLUTION":
          this.finished = true;
          if (this.exerciseService.showingSolution) {
            setTimeout(this.showAnswersTimeOut.bind(this), 100);
            break;
          } else {
            setTimeout(this.showSolution.bind(this), 100);
            break;
          }
        case "SHOW_ANSWER":
        case "SHOW_CUSTOM":
          this.ShowAnswer();
          break;
      }
    }
  
  constructor(private exerciseService: ExerciseService) {
    this.exercise = this.exerciseService.getExerciseDefinition();
    this.FillExercise();
  }
    ngOnDestroy() {
    }

  ngOnInit() {
    if (this.review) {
      this.elementsOk = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsOK
      );
      this.elementsErr = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsERR
      );
      if (this.show_errors == false) {
        this.showSolution();
      } else if (this.show_errors == true || this.show_errors == null) {
        this.ShowAnswer();
      }
    } else {
      this.initInteract();
    }
   
  }

  showSolution() {
    this.showingSolution = true;
    this.rimasCorrectas = this.exercise.rima.split('&');
    this.rimas = [];
    this.palabras = [];

    var c = 0;

    for (var i = 0; i < this.rimasCorrectas.length; i++) {

      var rima = this.rimasCorrectas[i];

      var palabra = this.exercise.palabras[c];


      if (rima.toLowerCase().indexOf(palabra.toLowerCase()) != -1) {

        this.rimas.push({ rima: rima, status: 'ok' });

        this.palabras.push({ palabra: palabra, status: 'ok' });
        c++;
      } else {
        this.rimas.push(rima);

      }

    }
  }

  ShowAnswer() {
    this.showingSolution = true;
    this.rimasCorrectas = this.exercise.rima.split('&');
    this.rimas = [];
    this.palabras = [];

    var c = 0;

    for (var i = 0; i < this.rimasCorrectas.length; i++) {

      var rima = this.rimasCorrectas[i];

      var palabra = this.exercise.palabras[c];


      if (rima.toLowerCase().indexOf(palabra.toLowerCase()) != -1) {

        var barraBajas = ' ';

        var caracteresPalabra = palabra.length;

        for (var k = 0; k < 8; k++) {

          barraBajas += '_';

        }

        var rima2 = null
        var startLastWord = rima.toLowerCase().lastIndexOf(palabra.toLowerCase());
        if (startLastWord >= 0) {
          rima2 = rima.substring(0, startLastWord) + barraBajas + rima.substring(startLastWord + caracteresPalabra);
        }

        var status = '';
        if (this.elementsErr.includes(i)) {
          status = 'error';
          this.rimas.push({ rima: rima2, status: status });
        } else if (this.elementsOk.includes(i)) {
          status = 'ok';
          this.rimas.push({ rima: rima, status: status });
        } else {
          status = 'notAnswered';
          this.rimas.push({ rima: rima2, status: status });
        }


        this.palabras.push({ palabra: palabra, status: status });

        c++;

      } else {
        this.rimas.push(rima);

      }
    }
  }

  initInteract() {
    this.exerciseService.initInteract({
      onmove: (event) => {
        this.draggingOption = event.target
      },
      drop: (event) => {
        if (this.finished == true)
          return;

        var rimaIndex = parseFloat(event.target.getAttribute("index"));
        var palabraIndex = parseFloat(this.draggingOption.getAttribute("index"));
        var palabraRimaIndex = parseFloat(this.draggingOption.getAttribute("rimaIndex"));
        if(this.rimasCorrectas[rimaIndex] == " " || this.rimasCorrectas[rimaIndex] == ""|| this.rimasCorrectas[rimaIndex] == "  ") {
            return;
        }
        var ok = false;

        if (rimaIndex == palabraRimaIndex)
            ok = true;
        
        if (ok) {
          this.exerciseService.answer(rimaIndex, true, this.exercise.palabras.length);
          this.rimas[rimaIndex] = this.rimasCorrectas[rimaIndex];
          this.palabras.splice(palabraIndex, 1);
          if (this.palabras.length == 0) {
            this.finished = true;
            // this.$emit('timer-stop');
            // this.$emit('exercise-solved');
          }
        } else {
          // this.$emit("error");
          this.exerciseService.answer(rimaIndex, false, this.exercise.palabras.length);
          this.setError(palabraIndex);
        }
      },
    });
  }

  setError(n) {

    this.error = n;

    setTimeout(() => {
      this.error = null;
    }, 1000);

  }

  FillExercise() {

    this.rimasCorrectas = this.exercise.rima.split('&');
    this.rimas = [];
    this.palabras = [];

    var c = 0;

    for (var i = 0; i < this.rimasCorrectas.length; i++) {

      var rima = this.rimasCorrectas[i];

      var palabra = this.exercise.palabras[c];



      if (rima.toLowerCase().indexOf(palabra.toLowerCase()) != -1) {

        var barraBajas = ' ';

        var caracteresPalabra = palabra.length;

        for (var k = 0; k < 8; k++) {

          barraBajas += '_';

        }

        var rima2 = null

        var startLastWord = rima.toLowerCase().lastIndexOf(palabra.toLowerCase());
        if (startLastWord >= 0) {
          rima2 = rima.substring(0, startLastWord) + barraBajas + rima.substring(startLastWord + caracteresPalabra);
        }

        //var rima2 = rima.replace(palabra, barraBajas);

        this.rimas.push(rima2);

        this.palabras.push({ palabra: palabra, rimaIndex: i });

        c++;

      } else {
        this.rimas.push(rima);

      }

    }
    if(this.review != true){
        this.palabras = this.shuffle(this.palabras);
    }

  }

  shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  showAnswersTimeOut() {
    for (var i = 0; i < this.rimas.length; i++) {
      this.rimas[i] = this.rimasCorrectas[i];
    }
    this.palabras = [];
  }

}
