import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ExerciseSolvedEnum } from "app/core/shared/enums/exercise-solved.enum";
import { SharedService } from "app/core/shared/shared.service";
import { ExerciseService } from "app/services/exercise.service";

@Component({
    selector: "ex-pregunta",
    templateUrl: "./pregunta.component.html",
    styleUrls: ["./pregunta.component.scss"],
    encapsulation: ViewEncapsulation.None,
    host: {
        "(window:resize)": "onResize($event)",
    },
})
export class PreguntaComponent implements OnInit, OnDestroy {

    @Input() review;
    @Input() show_errors;
    @Input() controlSession : boolean = false;
    @Input() slide : boolean = false; @Input() current;
    exercise;
    palabras;
    draggingOptionIndex;
    draggingOption
    exerciseIsEnded = false;
    finished = false;
    truefalse = false

    elementsOk;
    elementsErr;

    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_SOLUTION]:
                this.finished = true;
                if (this.exerciseService.showingSolution) {
                    setTimeout(this.showAnswersTimeOut.bind(this), 100);
                    break;
                } else {
                    setTimeout(this.showSolution.bind(this), 100);
                    break;
                }
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_ANSWER]:
                this.ShowAnswer();
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_CUSTOM]:
                this.exercise = this.exerciseService.getExerciseDefinition();
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.TIMER_END]:
                this.finished = true;
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.EXERCISE_END]:
                this.finished = true;
                break;
        }
    }

    constructor(private exerciseService: ExerciseService, private sharedService: SharedService) {
        this.exercise = this.exerciseService.getExerciseDefinition();
        try {
            if (this.exercise.opciones[0].valor == "VERDADERO" && this.exercise.opciones[1].valor == "FALSO")
                this.truefalse = true;
        } catch (err) { }
    }
    public get exercisesSolvedEnum(): typeof ExerciseSolvedEnum{
        return this.exerciseSolvedEnum
    }
    
    public get exerciseSolvedEnum(): typeof ExerciseSolvedEnum{
        return ExerciseSolvedEnum
    }
    ngOnDestroy() {
    }

    ngOnInit() {
        if (this.review) {
            this.elementsOk = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsOK
            );
            this.elementsErr = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsERR
            );
            if (this.show_errors == false) {
                this.showSolution();
              } else if (this.show_errors == true) {
                this.ShowAnswer();
              }
        } else {
            this.initInteract();
        }
    }

    validateOption(index) {
        if (this.finished)
            return;
        for (var i = 0; i < this.exercise.opciones.length; i++) {
            this.exercise.opciones[i].answered = null;
        }

        if (this.exercise.opciones[index].opcion) {
            this.exercise.opciones[index].answered = true;
            this.exerciseService.answer(index, true, 1)
            this.finished = true;
        } else {
            this.exercise.opciones[index].answered = false;
            this.exerciseService.answer(index, false, 1);
        }

    }

    showSolution() {
        if (this.exercise != null && this.exercise.opciones != null) {
            for (var i = 0; i < this.exercise.opciones.length; i++) {
                if (this.exercise.opciones[i].opcion)
                    this.exercise.opciones[i].answered = true;
                else
                    this.exercise.opciones[i].answered = null;
            }
        }
    }

    ShowAnswer() {
        if (this.exercise == null || this.exercise.opciones == null || this.elementsErr == null || this.elementsOk == null)
            return;
        for (var i = 0; i < this.exercise.opciones.length; i++) {
            if (this.exercise.opciones[i] != null)
                this.exercise.opciones[i].answered = null;
        }
        for (var p = 0; p < this.elementsErr.length; p++) {
            if (this.exercise.opciones[this.elementsErr[p]] != null)
                this.exercise.opciones[this.elementsErr[p]].answered = false;
        }
        for (var p = 0; p < this.elementsOk.length; p++) {
            if (this.exercise.opciones[this.elementsOk[p]] != null)
                this.exercise.opciones[this.elementsOk[p]].answered = true;
        }
    }

    initInteract() {
        this.exerciseService.initInteract({
            onmove: (event) => {
                this.draggingOption = event.target
                this.draggingOptionIndex =
                    event.target.getAttribute("optionIndex");
            },
            drop: (event) => {
                let bin = event.target;
                let element = this.draggingOption
            },
        });
    }

    showAnswersTimeOut() {
        if (!this.exercise) return;
        for (var i = 0; i < this.exercise.opciones.length; i++) {
            if (this.exercise.opciones[i].opcion)
                this.exercise.opciones[i].answered = true;
            else
                this.exercise.opciones[i].answered = null;
        }
    }
    getImgUrl(checkFalse = false) {
        if(checkFalse){
            if(this.exercise.opciones[1].answered == true){
                return "url('https://walinwa.blob.core.windows.net/images/Exercises/Pregunta/no-es-correcto-ok.svg?ngsw-bypass=true')";
            }
            else if(this.exercise.opciones[1].answered == false){
                return "url('https://walinwa.blob.core.windows.net/images/Exercises/Pregunta/no-es-correcto-err.svg?ngsw-bypass=true')";
            }
            else {
                return "url('https://walinwa.blob.core.windows.net/images/Exercises/Pregunta/no-es-correcto.svg?ngsw-bypass=true')";
            }
        }
        else {
            if(this.exercise.opciones[0].answered == true){
                return "url('https://walinwa.blob.core.windows.net/images/Exercises/Pregunta/es-correcto-ok.svg?ngsw-bypass=true')";
            }
            else if(this.exercise.opciones[0].answered == false){
                return "url('https://walinwa.blob.core.windows.net/images/Exercises/Pregunta/es-correcto-err.svg?ngsw-bypass=true')";
            }
            else {
                return "url('https://walinwa.blob.core.windows.net/images/Exercises/Pregunta/es-correcto.svg?ngsw-bypass=true')";
            }
        }        
    }
}
