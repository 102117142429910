import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'app/core/shared/shared.service';
import { CustomSession, Class } from 'app/core/shared/state';
import { PersonalizedTestDTO } from 'app/core/shared/state/models/personalized-test/PersonalizedTestDTO.model';
import { PersonalizedTestResult } from 'app/core/shared/state/models/personalized-test/personalized-test-result.model';
import { TestUserResult } from 'app/core/shared/state/models/personalized-test/personalized-test-user-result.model';
import { PersonalizedTest } from 'app/core/shared/state/models/personalized-test/personalized-test.model';
import { PersonalizedTestUserReview } from 'app/core/shared/state/models/test-user-review.model';
import { AuthService } from 'app/services/auth.service';
import { ClassService } from 'app/services/class.service';
import { CourseService } from 'app/services/course.service';
import { CustomizedSessionService } from 'app/services/customized-session.service';
import { EmailConfigService } from 'app/services/email-config.service';
import { NavigationService } from 'app/services/navigation.service';
import { PersonalizedTestService } from 'app/services/personalized-test.service';
import { SchoolService } from 'app/services/school.service';
import { UserService } from 'app/services/user.service';
import * as moment from 'moment';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: "app-mini-test",
    templateUrl: "./mini-test.component.html",
    styleUrls: ["./mini-test.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class MiniTestComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    currentUser;
    dataLoaded = false;
    classes: Class[];
    loading: boolean = true;
    classes_personalizedTests = [];
    personalizedTests: PersonalizedTest[] = [];
    personalizedTest: PersonalizedTest;
    customizedSessions: CustomSession[] = [];
    selectedDay: any;
    resultList: TestUserResult[];
    selectedTest = null
    selectedUser = null;
    constructor(
        private authService: AuthService,
        public navigationService: NavigationService,
        public personalizedTestService: PersonalizedTestService,
        private customizedSessionService: CustomizedSessionService,
        private schoolService: SchoolService,
        private userService: UserService,
        private classService: ClassService,
        public sharedService: SharedService,
        private _translateService: TranslateService,
    ) { 
        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit(): void {
        if (!this.authService.check()) return;
        this.currentUser = this.authService.currentUser;
        this.selectedDay = moment().hour(0).minute(0).second(0).millisecond(0);
        this.getClasses();
        this.getPersonalizedTests()
        this.navigationService.setStateTest("classes-personalizedTests");
    }
    goList() {
        let selectedTest = this.classes_personalizedTests.find(x => x.selected)
        this.personalizedTestService.GetTestResult(selectedTest.IdTest, selectedTest.Date, selectedTest.IdClass)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(result => {
            this.resultList = result;
            this.navigationService.setStateTest("list");
            this.navigationService.enableBackButtonTest(true, "list");
        })
        
    }
    selectPersonalizedTest(class_personalizedTest) {
        this.classes_personalizedTests.forEach((x) => {
            x.selected = false;
        });
        class_personalizedTest.selected = true;
        this.personalizedTest = this.personalizedTests.find(
            (x) => x.IdTest == class_personalizedTest.IdTest
        );
        this.selectedTest = class_personalizedTest
    }
    selectUser(user) {
        this.resultList.forEach((x) => {
            x.selected = false;
        });
        user.selected = true;
        this.selectedUser = user;
    }
    reviewUserResult(){
        this.personalizedTestService.doTest = true;
    }

    getClasses() {
        this.classes = this.classService.GetClasses();
    }

    getPersonalizedTests() {
        this.personalizedTestService.getByUser(this.authService.currentUser.Id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result: PersonalizedTestDTO[]) => {
                    this.classes_personalizedTests = result.filter(x => (x.IdClass ?? 0) !== 0);
                    const mappedTest = this.personalizedTestService.MapFromDTOToTest(result);
                    this.personalizedTests = mappedTest.filter(t => t.ShareTest !== 2);
                    this.classes_personalizedTests.forEach(AssignedTest => {
                        AssignedTest.TestState = ""
                        AssignedTest.formatted_date = new Date(AssignedTest.Date).toLocaleDateString("es-ES")
                        AssignedTest.editingTestDate = false
                        AssignedTest.TestState = (AssignedTest.Finished || 0) === 0 ? 'Sin realizar' : (AssignedTest.Total || 0) === (AssignedTest.Finished || 0) ? 'Finalizado' : `Finalizados ${(AssignedTest.Finished || 0)} de ${(AssignedTest.Total || 0)}`;
                        AssignedTest.editingTestDate = (AssignedTest.Finished || 0) !== 0;
                        this.customizedSessions.push(AssignedTest)
                    });
                    this.loading = false
                },() => {
                    this.loading = false;
                });
    }
    anySelected(): boolean {
        return this.classes_personalizedTests.some((x) => x.selected);
    }
    anyUserSelected(): boolean {
        return this.resultList.some((x) => x.selected && x.TestState == 'Finalizado');
    }

    get sortData() {
        return this.classes_personalizedTests
            .sort((a, b) => {
                return <any>new Date(b.Date) - <any>new Date(a.Date);
            })
            .filter((x) => x.TestState.length > 0);
    }

    getDate() {
        let meses = [
            this._translateService.instant('MONTHS.JANUARY'),
            this._translateService.instant('MONTHS.FEBRUARY'),
            this._translateService.instant('MONTHS.MARCH'),
            this._translateService.instant('MONTHS.APRIL'),
            this._translateService.instant('MONTHS.MAY'),
            this._translateService.instant('MONTHS.JUNE'),
            this._translateService.instant('MONTHS.JULY'),
            this._translateService.instant('MONTHS.AUGUST'),
            this._translateService.instant('MONTHS.SEPTEMBER'),
            this._translateService.instant('MONTHS.OCTOBER'),
            this._translateService.instant('MONTHS.NOVEMBER'),
            this._translateService.instant('MONTHS.DECEMBER')
        ];
        let dayNames = [
            this._translateService.instant('DAYS.MONDAY'),
            this._translateService.instant('DAYS.TUESDAY'),
            this._translateService.instant('DAYS.WEDNESDAY'),
            this._translateService.instant('DAYS.THURSDAY'),
            this._translateService.instant('DAYS.FRIDAY'),
            this._translateService.instant('DAYS.SATURDAY'),
            this._translateService.instant('DAYS.SUNDAY'),
        ];
        let d = new Date(
            this.classes_personalizedTests.find((x) => x.selected).Date
        );
        let dia = d.getDate();
        let mes = d.getMonth();
        let ano = d.getFullYear();
        let dayIndex = d.getDay() - 1;
        if (dayIndex == -1) dayIndex = 6;
        let dayName = dayNames[dayIndex];
        return `${dayName}, ${dia} de ${meses[mes]}`;
    }
    get score() {
        return this.resultList.find(
            (x) => x.IdUser == this.resultList.find((x) => x.selected).IdUser
        ).Score;
    }
    activateReview(class_test: PersonalizedTestDTO) {
        if (class_test.TestOpen == null || class_test.TestOpen == false) {
            class_test.TestOpen = true;
        } else {
            class_test.TestOpen = false;
        }
        this.customizedSessionService.update(class_test)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                result => {
                    this.navigationService.enableExitButton();
                    this.navigationService.setStateTest('classes-personalizedTests');
                    this.classes_personalizedTests = []
                    this.getPersonalizedTests()
                })
    }
}
