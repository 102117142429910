import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { NavigationService } from 'app/services/navigation.service';
import { ShopService } from 'app/services/shop.service';
import * as moment from 'moment';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TransactionsComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  currentUser;
  transactions = [];

  constructor(
    private shopService: ShopService,
    private authService: AuthService,
    private navigationService: NavigationService
  ) { 
    this._unsubscribeAll = new Subject();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnInit(): void {
    if (!this.authService.check()) return;
    this.currentUser = this.authService.currentUser;
    this.shopService.getAllTransactions(this.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.okCallback.bind(this), this.errorCallback.bind(this));
  }

  okCallback(response) {
    if (response == null) {
      this.navigationService.goLogin();
      return;
    }
    this.transactions = response;
  }

  errorCallback() {
    this.navigationService.goLogin();
  };

  formatDateTime(date) {
    return moment(date).format("DD/MM/YYYY HH:mm");
  }
  parseAmount(amount: number): string {
    const amountString = amount.toString();

    if (amountString && Math.abs(amount).toString().length >= 5) {
        const sign = Math.sign(amount) === -1 ? '-' : ''; // Conservar el signo si es negativo
        const absAmountString = Math.abs(amount).toString();
        let formattedAmount = '';
        
        for (let i = 0; i < absAmountString.length; i++) {
            formattedAmount += absAmountString[i];
            const remainingDigits = absAmountString.length - (i + 1);

            if (remainingDigits > 0 && remainingDigits % 3 === 0) {
                formattedAmount += ' '; // Agrega un espacio cada tres dígitos, excepto al final
            }
        }

        return sign + formattedAmount;
    }

    return amountString;
}

}
