import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MedalService {

    baseUrl = environment.baseApi + '/api/Medal';

    constructor(private http: HttpClient) { }

    getUserMedals(idUser) {
        return this.http.get(this.baseUrl + '/' + idUser);
    }

}
