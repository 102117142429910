import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LeeBooks } from 'app/core/shared/state/models/Wali-lee/lee-books.model';
import { AuthService } from 'app/services/auth.service';
import { CourseService } from 'app/services/course.service';
import { NavigationService } from 'app/services/navigation.service';
import { UserService } from 'app/services/user.service';
import { WaliLeeService } from 'app/services/wali-lee.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { environment } from 'environments/environment';
import { combineLatest, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'walilee-library',
  templateUrl: './walilee-library.component.html',
  styleUrl: './walilee-library.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WalileeLibraryComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<void> = new Subject();
  private _booksSubject = new Subject<LeeBooks[]>();

  @ViewChild('dataGrid') dataGrid: DxDataGridComponent;
  @Input('repaint') set repaint(value: boolean) {
    if (value) setTimeout(() => this.dataGrid?.instance.repaint());
  }

  public className: any = (_class) => this.getClassGroupName(_class.Level);
  public title: any = (book) => `${book.Title} ${book.Author}`;
  public cover: any = (book) => `${environment.filesUrl}/images/WaliLee/Covers/${book.data.Cover}?ngsw-bypass=true`;
  public books$ = this._booksSubject.asObservable();
  public books: LeeBooks[] = [];
  public courses: any = [];
  public classes: any = [];
    
  constructor (private _navigationService: NavigationService,
               private _userService: UserService,
               private _walileeService: WaliLeeService,
               private _courseService: CourseService,
               private _authService: AuthService) {
  }

  ngOnInit(): void {
    this._navigationService.enableExitButton();
    this._initSubscriptions();
    this._prepareExtensions();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private _initSubscriptions() {
    combineLatest([
      this._courseService.Courses,
      this._userService.getClassesByIdTutor(this._authService.currentUser.Id),
      this._walileeService.getBooks()
    ]).pipe(takeUntil(this._unsubscribeAll), take(1)).subscribe(data => {
      this.courses = ((data[0] || []) as any[]).sort((a, b) => a.Level - b.Level);
      this.classes = ((data[1] || []) as any[]).sort((a, b) => a.Level - b.Level);
      this.courses = this.courses.filter(course => this.classes.find(_class => _class.Level === course.Level || course.Level === (_class.Level % 2 === 0 ? _class.Level + 1 : _class.Level - 1)));
      this.books = data[2].filter(book => this.classes.find(_class => _class.Level === book.ULevel || book.ULevel === (_class.Level % 2 === 0 ? _class.Level + 1 : _class.Level - 1)));      
      this._booksSubject.next(this.books);

      setTimeout(() => this.dataGrid.instance.repaint());
    });
  }

  private _prepareExtensions() {
    Array.prototype.removeRepeatedText = function() {
      if (this.length === 0) return this;

      const shortest = this.reduce((a, b) => a.length <= b.length ? a : b);

      let index = shortest.length;

      for (let i = 0; i < shortest.length; i++) {
          const pos = shortest.length - 1 - i;
  
          const currentChar = shortest[pos];

          if (!this.every(str => str[str.length - 1 - i] === currentChar)) {
              index = pos + 1;
              break;
          }
      }
  
      return this.map((str, i) => i !== 0 ? str : str.slice(0, index + 2));
    };
  }

  public calculateGroupValue(rowData: any): number {
    return rowData.ULevel % 2 === 0 ? rowData.ULevel : rowData.ULevel - 1;
  }

  public getClassGroupName(uLevel: number, empty: string = '') { 
    if (!uLevel) return empty;    
    
    const courses = (this.courses || []).filter(course => course.Level === uLevel || course.Level === (uLevel % 2 === 0 ? uLevel + 1 : uLevel - 1));

    return (courses || []).length > 0 ? `${courses.map(c => c.Name).removeRepeatedText().reduce((text, value, i, array) => text + (i < array.length - 1 ? ', ' : ' y ') + value)}` : '';
  }

  public getBookTitle(idLee: number, empty: string = '') { 
    if (!idLee) return empty;
    
    const book = ((this.books || []).find(book => book.IdLee === idLee) || { Title: '', Author: '' });

    return book ? `<span class='book-title'>${book.Title}. </span><span class='normal'>${book.Author}</span>` : '';
  }

  public downloadActivities(file: string) {    
    const link = document.createElement("a");

    link.setAttribute("href", `${environment.filesUrl}/walilee/${file}?ngsw-bypass=true`);
    link.setAttribute("target", "_blank");
    link.setAttribute("download", file);

    document.body.appendChild(link);

    link.click();
  }

  public onCellPrepared(e: any) {
    if (e.rowType === 'data')
      if(e.column.dataField !== 'IdLee') {
        e.cellElement.style.display = 'none';
      } else {
        e.cellElement.setAttribute('colspan', '3');
      }
  }
}

declare global {
  interface Array<T> {
    removeRepeatedText(): Array<string>;
  }
}
