import { FileService } from 'app/services/file.service';
import { Component, Input, OnInit, OnDestroy, HostListener, ViewEncapsulation } from '@angular/core';
import { ExerciseService } from 'app/services/exercise.service';
import { environment } from 'environments/environment';
import { AppService } from 'app/app.service';
declare var $

@Component({
  selector: 'ex-img',
  templateUrl: './img.component.html',
  styleUrls: ['./img.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ImgComponent implements OnInit, OnDestroy {

  @Input() review
  @Input() show_errors;
  @Input() controlSession : boolean = false;
  @Input() slide : boolean = false; @Input() current;
  exercise
  draggingOption
  exerciseIsEnded = false
  finished = false
  showingSolution = false
  numCols = 0
  f = false
  letters = [{ name: 'a' }, { name: 'b' }, { name: 'c' }, { name: 'd' },
  { name: 'e' }, { name: 'f' }, { name: 'g' }, { name: 'h' }, { name: 'i' },
  { name: 'j' }, { name: 'k' }, { name: 'l' }, { name: 'm' }, { name: 'n' },
  { name: 'ñ' }, { name: 'o' }, { name: 'p' }, { name: 'q' }, { name: 'r' },
  { name: 's' }, { name: 't' }, { name: 'u' }, { name: 'v' }, { name: 'w' },
  { name: 'x' }, { name: 'y' }, { name: 'z' }, { name: 'á' }, { name: 'é' },
  { name: 'í' }, { name: 'ó' }, { name: 'ú' }, { name: 'ü' }]
  dragging = false
  letras = []
  numErr = 0
  numOk = 0
  opciones = 0;

  elementsOk
  elementsErr
  inputLength: any;
  input: any;
  isMobile: any;
  indexError = []
  regex = new RegExp(/Tab|Alt|Arrow|Page/);
    focusing: any;

  @HostListener('document:keydown', ['$event'])
  keyListener(event: KeyboardEvent) {
      if (this.review || this.isMobile) return;
      if (this.regex.test(event.key)) event.preventDefault();
      let index = this.letters.findIndex(x => x.name == event.key);

      if (index != null && index != -1) {
        this.add_letra(this.letters[index].name)
      }
  }
    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case "SHOW_SOLUTION":
                this.showSolution();
                break;
            case "SHOW_ANSWER":
            case "SHOW_CUSTOM":
                this.ShowAnswer();
                break;
        }
    }
  
  constructor(private exerciseService: ExerciseService,
      private appService: AppService) {
    this.exercise = this.exerciseService.getExerciseDefinition();
    this.get_letras()
    // this.exercise.palabras.forEach(element => {
    //   element.palabraAux = element.palabra
    // })
  }
    ngOnDestroy(){
    }

  ngOnInit() {
    this.isMobile = this.appService.mobileDetect().IsIPhone || this.appService.mobileDetect().IsAndroidPhone || window.innerWidth < 600;
    if (this.review) {
      this.elementsOk = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsOK
      );
      this.elementsErr = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsERR
      );
      if (this.show_errors == false) {
        this.showSolution();
      } else if (this.show_errors == true) {
        this.ShowAnswer();
      }
    } else {
      this.initInteract();
    }
  }

  getImgSrc(){
    return this.exerciseService.findImgUrl("Img/" + this.exercise.palabra + ".png")
  }

  showSolution() {
      this.showingSolution = true;
    for(var i = 0;i < this.letras.length;i++){
      this.letras[i].answered = true;
   }
  }

  ShowAnswer() {
    this.showingSolution = true;
    var oks = this.elementsOk
    var errors = this.elementsErr
    
    for(var i = 0;i < this.letras.length;i++){
        var aux = i.toString();
        if (oks.indexOf(i) >= 0)
            this.letras[i].answered = true;
        if (errors.indexOf(i) >= 0)
            this.letras[i].answered = false;      
    }
  }

  initInteract() {
    this.exerciseService.initInteract({
      onmove: (event) => {
        this.draggingOption = event.target
      },
      drop: (event) => {
        if (this.finished) return;
        this.dragging = true;
        var letra_1 = this.draggingOption.getAttribute("letra");
        var letra_2 = event.target.getAttribute("letra");
        this.validar(letra_1, letra_2);
        this.dragging = false;
      },
    });
  }

    validar(a, b) {
        if (this.letras[a].letra == b) {
            this.letras[a].answered = true;
            this.numOk++;
        } else {
            this.letras[a].answered = false;
            this.numErr++;
            this.indexError.push(a);
        }

        if (this.numOk == this.letras.length) {
            this.indexError.forEach(index => {
                this.exerciseService.answer(index, false, 1)
            });
            this.exerciseService.answer(1, true, 1)
            this.setFocus(false);
            this.finished = true;
        }
    }

  get_letras() {
    var letras = this.exercise.palabra.split('');
    this.letras = [];
    for (var i = 0; i < letras.length; i++) {
      this.letras.push({
        letra: letras[i],
        answered: null
      });
    }
  }

  showAnswersTimeOut() {
    this.setFocus(false);
    for (var j = 0; j < this.exercise.palabras.length; j++) {
      this.exercise.palabras[j].answered = false;
    }
  }

  add_letra(letra) {
    for (var i = 0; i < this.letras.length; i++) {
      if (this.letras[i].answered != true) {
        if (!this.dragging) {
          this.validar(i, letra);
        }
        return;
      }
    }
    }
    change() {
        if (this.review) return;
        if (this.inputLength > this.input.length) {
            return;
        }
        var letter = this.input.charAt(this.input.length - 1)
        let index = this.letters.findIndex(x => x.name == letter);
        if (index != null && index != -1) {
            this.add_letra(this.letters[index].name)
        }
        this.inputLength = this.input.length
    }

    setFocus(bool = true) {
        if (!this.isMobile || this.review || this.showingSolution || this.finished) {
            return;
        }
        if (bool && !this.focusing) {
            document.getElementById("dictation-input").focus();
        }
        if (!bool && this.focusing) {
            document.getElementById("dictation-input").blur();
        }
        this.focusing = bool
    }

}
