import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DictationService } from 'app/services/dictation.service';
import { NavigationService } from 'app/services/navigation.service';
import { UserService } from 'app/services/user.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
declare var $;
@Component({
    selector: 'app-parent-review-dictation',
    templateUrl: './parent-review-dictation.component.html',
    styleUrls: ['./parent-review-dictation.component.scss']
})

export class ParentReviewDictationComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
    student: any;
    selectedDayDict: any;
    dictations: any;
    currentMonthDict: any;
    year: any;
    currentDict: any;
    IdUser: any;
    activeButton = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private router: Router,
        private navigationService: NavigationService,
        private dictationService: DictationService
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit() {
        if (this.navigationService.params.IdUser)
            this.IdUser = this.navigationService.params.IdUser;
        this.selectedDayDict = moment();
        this.currentMonthDict = this.selectedDayDict.month() + 1;
        this.year = this.selectedDayDict.year();
        if (this.navigationService.params.student) {
            this.student = this.navigationService.params.student
            this.getDictations();
        }
        else {
            this.getUser(this.IdUser);
        }
    }

    getUser(id) {
        this.userService.getUserById(id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                result => {
                    if (result == null) return this.navigationService.exit();
                    this.student = result;
                    this.navigationService.params.student = result;
                    this.getDictations();
                },
                this.errorCallback.bind(this)
            )
    }

    selectedDayChangedDictation(newDate, canDoIt, day) {
        if (day && day.css.includes("isDone")) {
            this.activeButton = true;
        } else {
            this.activeButton = false;
        }
        this.selectedDayDict = newDate;
        this.check_dictado();
    }

    getDictations() {
        this.dictationService.getDictations(this.student?.Id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response) => {
                this.dictations = response;
                this.check_dictado();
            }, (response) => { });
    }

    check_dictado() {
        this.currentDict = null
        let dictation = this.selectedDayDict.week() + '_' + this.selectedDayDict.isoWeekday()
        this.dictationService.getDictResult(this.student.Id, dictation)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                result => {
                    if (!result) return
                    this.dictationService.getDictationInfo(this.student.Level, this.selectedDayDict.week(), this.selectedDayDict.isoWeekday())
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(
                            result => {
                                this.currentDict = result
                            }
                        )
                }
            )

    }

    errorCallback() {
        this.navigationService.go("/school/parentStudents");
    }

    sixWeeks() {
        return $(".week").length - 1 >= 6;
    }

    startReview() {
        this.navigationService.go(this.router.url + "/review", {
            user: this.student,
            selectedDay: this.selectedDayDict,
            parentReview: true,
            student: this.student
        });
    }
}
