<div class="walinwa-box">

    <form [formGroup]="form" class="walinwa-box-content">

        <!--div style="margin-top: -1.3rem; margin-bottom: -1rem;"-->
        <div>
            <mat-form-field>
                <label class="font_blue">{{'CLASS' | translate}}</label>
                <mat-select id="select-class" [(ngModel)]="selectedClass" (selectionChange)="changeClass()"
                    [ngModelOptions]="{standalone: true}">
                    <mat-option class="scrollable" [value]="0">{{'SCHOOL.TEACHER.CONFIG.ALL-CLASSES' | translate}}</mat-option>
                    <mat-option class="scrollable" *ngFor="let class of classes | sortBy:'asc':['Level','Code']"
                        [value]="class.IdClass">{{ class.Name }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div>
            <mat-checkbox formControlName="BlockName">{{'SCHOOL.BLOCKNAME' | translate}}
                </mat-checkbox>
                <tooltip position="R" [info]="'SCHOOL.BLOCKNAME-INFO' | translate"></tooltip>
        </div>
        <div>
            <mat-checkbox formControlName="GiftShopDisabled">{{'SCHOOL.GIFTSHOPDISABLED' | translate}}</mat-checkbox>
            <tooltip position="R" [info]="'SCHOOL.GIFTSHOPDISABLED-INFO' | translate"></tooltip>
        </div>
        <div>
            <mat-checkbox formControlName="BlockMessageGift">{{'SCHOOL.BLOCKMESSAGEGIFT' | translate}}</mat-checkbox>
                <tooltip position="R" [info]="'SCHOOL.BLOCKMESSAGEGIFT-INFO' | translate"></tooltip>
        </div>

        <div class="walinwa-row" fxLayoutAlign="end end" fxLayoutAlign.xs="center center">

            <button (click)="save()" class="new_but azul">
                {{'SAVE' | translate}}
            </button>

        </div>
    </form>

</div>