import { SchoolService } from './../../../../services/school.service';
import { Component, OnInit, ViewEncapsulation, HostListener, AfterViewInit, Renderer2 } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RepositoryService } from 'app/core/repository/repository.service';
import { SharedService } from 'app/core/shared/shared.service';
import { UiService } from '@fuse/components/spinner/ui.service';
import { Class, DataQuery, DataService, School } from 'app/core/shared/state';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'app/app.service';
import { ToolbarService } from 'app/services/toolbarService.service';


@Component({
  selector: 'new-student',
  templateUrl: './new-student.component.html',
  styleUrls: ['./new-student.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    '(window:resize)': 'onResize($event)'
  }
})

export class NewStudentComponent implements OnInit, AfterViewInit {

  private readonly _destroySubject = new Subject<void>();
  mobile: boolean = false;
 iPad = /iPad|Macintosh/i.test(navigator.userAgent); mobileDetect;
  finishRegistrationForm: UntypedFormGroup;
  userName: string = "   ";
  errorMessage: string;
  class$: Observable<Class>;
  school: School;
  class: Class;
  title: string;
  initials: string;
  code: string;
  prepaid: boolean;
  confirmDialog: MatDialogRef<FuseDialogContinueComponent>;
  tpvInfo;
  regExp = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜäÄëËïÏöÖç\s\.\-'ª’]+$/u
    loading: boolean;

  constructor(private _formBuilder: UntypedFormBuilder,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _repositoryService: RepositoryService,
    public _sharedService: SharedService,
    private _ui: UiService,
    private dataQuery: DataQuery,
    private _matDialog: MatDialog,
    private _translateService: TranslateService,
    private _router: Router,
    private schoolService: SchoolService,
    private appService: AppService,
    private toolbarService:ToolbarService,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute

  ) {
    this._ui.spin$.next(true);
    this.mobileDetect = this.appService.mobileDetect();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerWidth;
    this.mobile = this._sharedService.isMobile();
  }

  ngAfterViewInit(){

    const matToolbarRef = this.toolbarService.getToolbarRef();
    if (matToolbarRef) {
        setTimeout(() => {
            this.renderer.addClass(matToolbarRef.nativeElement, 'only-logo');
        }, 0);
    }
    }

  ngOnInit(): void {

    this.mobile = this._sharedService.isMobile();

    this.class$ = this.dataQuery.class$;
    this.dataQuery.class$.pipe(takeUntil(this._destroySubject.asObservable())).subscribe(classStudent => {

      if (classStudent == null) {
        this._router.navigate(['./login']);
        return;
      }
      this.class = classStudent;
      this.title = classStudent['School'].Name + " en " + classStudent['Name'];
      this.initials = classStudent['School'].Initials;
      this.code = classStudent['Code'];
      this.school = classStudent['School'];
      this.prepaid = classStudent['School'].Prepaid;
    });

    this.finishRegistrationForm = this._formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(this.regExp)]],
      familyName: ['', [Validators.required, Validators.pattern(this.regExp)]]
    });
      this.finishRegistrationForm.markAllAsTouched();
  }

  finishRegistration(form) {
    if (this.finishRegistrationForm.valid) {
      this.loading = true;
      let formData = Object.assign({}, form.value);
      this.initials = this.initials.trim()
      formData.name = formData.name.trim()
      formData.familyName = formData.familyName.trim()
      this.code = this.code.trim()
      let url = environment.baseApi + '/api/School/getStudentName/' + this.initials + '/' + formData.name + '/' + formData.familyName + '/' + this.code;
      this._ui.showSpinner();
      console.log(this.school.Prepaid)
      this.schoolService.getStudentName(this.initials,formData.name, this.code, formData.familyName).subscribe((response: any) => {
        this.userName = response.Username;
        if (this.school["Prepaid"]) {
            this.loading = false;
            this._ui.stopSpinner();
          this.showDialog(response);

        } else {

          this.saveValidData();

        }
      }
        ,
        error => {
          this.errorMessage = this._sharedService.getError(error);
          this.loading = false;
          this._ui.stopSpinner();
        });
    }
  }

  saveValidData() {

    let student = {
      UserName: this.userName,
      Name: this.finishRegistrationForm.value.name,
      FamilyName: this.finishRegistrationForm.value.familyName,
      IdSchool: this.school.idSchool
    };

    this._repositoryService.post(environment.baseApi + "/api/School/addStudent", { student: student, classCode: this.code, password: environment.defaultPassword, defaultPw: false,  location: "Alta inicial del alumno"}).subscribe((response: any) => {
      this.loading = false;
      this._ui.stopSpinner();
      this.showDialog(response);

    }, error => {
      if (error.error == "NoValidLicense") {
        this.loading = false;
        this._ui.stopSpinner();
        this.errorMessage = "AUTHENTICATION.ERROR.INVALID_GRANT";
      }
    });

  }

  hasError(): boolean {
    return this._sharedService.hasError(this.errorMessage);
  }

  showDialog(data): void {
    this.confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });

    this.confirmDialog.componentInstance.title = this._translateService.instant('AUTHENTICATION.INITIAL-REGISTRATION.NEW-STUDENT.TITLE-DIALOG');
    this.confirmDialog.componentInstance.space = true;
    this.confirmDialog.componentInstance.message1 = this._translateService.instant('AUTHENTICATION.INITIAL-REGISTRATION.NEW-STUDENT.TEXT-DIALOG');
    this.confirmDialog.componentInstance.message2 = this._translateService.instant('AUTHENTICATION.INITIAL-REGISTRATION.NEW-STUDENT.USER') + this.userName;
    this.confirmDialog.componentInstance.message3 = this._translateService.instant('AUTHENTICATION.INITIAL-REGISTRATION.NEW-STUDENT.PASSWORD') + environment.defaultPassword;
    this.confirmDialog.componentInstance.textButton = this._translateService.instant('CONTINUE');
    this.confirmDialog.componentInstance.margin = false;

    this.confirmDialog.componentInstance.options = [
      {
        text: this._translateService.instant('ACCEPT'),
        callback: () => {
          if (this.prepaid != true) {
            let loginForm = this._formBuilder.group({
              userName: this.userName,
              password: environment.defaultPassword
            });
            this._sharedService.login(loginForm);
          }
          else {
            let url = environment.baseApi + '/api/Payment/pay/iduser/schoolStudent/';
            let paymentInfo = {
              idClass: this.class['IdClass'],
              username: this.userName,
              name: this.finishRegistrationForm.value.name,
              familyName: this.finishRegistrationForm.value.familyName
            }
            this._ui.showSpinner();
            this.loading = true;
            this._repositoryService.post(url, paymentInfo).subscribe((response: any) => {
              this._ui.stopSpinner();
              this.loading = false;
              this.loadTPV(response);
            },
              error => {
                this.errorMessage = this._sharedService.getError(error);
            
                this._ui.stopSpinner();
                this.loading = false;
              });
          }
        }
      }, {
        text: "Cancelar",
        callback: () => {
          return;
        }
      }
    ]

    this.confirmDialog.afterClosed().subscribe(result => {
      result();
      this.confirmDialog = null;
    });
  }
  loadTPV(response) {
    this.tpvInfo = response;
    this._sharedService.paying = true;
  }
  goLogin(){
    this._sharedService.paying = false;
    this.tpvInfo = null;
    this._router.navigateByUrl("/login")
  }
}
