import { FileRequest, FileResponse } from './../core/shared/state/models/file/file.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class FileService {
    baseUrl = environment.baseApi + '/api/File';
    constructor(private http: HttpClient) { }

    getCountImage(countName: string) {
        return this.http.get(this.baseUrl + "/getCountImage/" + countName);
    }

}
