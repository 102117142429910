<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="unir2 spe_gap" fxLayout="column" fxLayoutAlign="stretch" *ngIf="exercise"
    [ngStyle]="{'pointer-events': review ? 'none;' : ''}" [ngClass]="{'is-ctl-session': controlSession }">

    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div fxFlex="" fxLayout="row wrap" fxLayoutAlign="start start" class="model-columns" *ngIf="!showingSolution">
        <div fxFlex="50" fxLayout="column" fxLayoutAlign="start center" class="box-words">
            <div [attr.wordIndexLeft]="i" style="background: var(--verdeAzulado); color: white;"
                [ngClass]="{'box-word-error': leftError == i}" [hidden]="word == null"
                class="box-word draggable dropzone" *ngFor="let word of exercise.grupo1; let i = index">
                <span class="box-text">
                    {{word}}
                </span>
            </div>
        </div>
        <div fxFlex="50" fxLayout="column" fxLayoutAlign="start center" class="box-words">
            <div [attr.wordIndexRight]="i" [ngClass]="{'box-word-error': rightError == i}" [hidden]="word == null"
                class="box-word draggable dropzone" *ngFor="let word of exercise.grupo2; let i = index">
                <span class="box-text">
                    {{word}}
                </span>
            </div>
        </div>
    </div>
    <div class="model-columns" fxFlex="100" fxLayout="column" fxLayoutAlign="start center" fxLayoutAlign.gt-sm="space-evenly center"
        *ngIf="review || showingSolution">
        <div class="box-word"
            [ngClass]="{'box-word-ok': word.answered, 'box-word-error' : word.answered == false , 'wordBoxNotAnswered' : word.answered == null}"
            *ngFor="let word of exercise.result; let i = index">
            <span>
                {{word.word}}
            </span>
        </div>
    </div>
</div>