import { ExerciseService } from './../../../services/exercise.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { NavigationService } from 'app/services/navigation.service';
import { FileService } from 'app/services/file.service';
import { FileRequest, FileResponse } from 'app/core/shared/state/models/file/file.model';
import { FindUrlPipe } from 'app/pipes/find-url.pipe';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { AppService } from 'app/app.service';
import { SharedService } from 'app/core/shared/shared.service';

@Component({
    selector: 'reviewTheory',
    templateUrl: './review-theory.component.html',
    styleUrls: ['./review-theory.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ReviewTheoryComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    @Output() changeShowHelp = new EventEmitter<boolean>();
    @Input() help = [];

    currentStep = 1;
    steps = 0;
    currentUser;
    imgUrls: FileResponse[] = [];
    subDirectory = "Tutoriales/reviewTheory"
    isMobile: any;
    constructor(
        private exerciseService: ExerciseService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private navigationService: NavigationService,
        private fileService: FileService,
        private appService: AppService,
        public sharedService: SharedService
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit(): void {
        if (!this.authService.check()) return;
        this.isMobile = this.appService.mobileDetect().IsIPhone || this.appService.mobileDetect().IsAndroidPhone || window.innerWidth < 600;
        if (this.isMobile) {
            this.subDirectory = "Tutoriales/reviewTheoryMovil";
        }
        this.currentUser = this.authService.currentUser;
        this.exerciseService.pauseTimer();
        if (this.help && this.help.length != 0) {
            this.currentStep = this.help[0];
            this.steps = this.help[this.help.length - 1];
        } else {
            this.fileService.getCountImage(this.subDirectory.split("/")[1])
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(
                    (result: number) => {
                        this.steps = result
                    },
                    (err) => {
                        console.error(err);
                    });
        }
    }

    changePage(forward: boolean): void {
        if (forward) {
            this.currentStep++;
        }
        else {
            if (this.currentStep > 1)
                this.currentStep--;
        }
    }

    numberOfPages() {
        return this.steps;
    };

    getImgName(): string {
        let imageName = '';
        if (this.isMobile) {
            imageName = "repasovm-"
        }
        else {
            imageName = "repaso-"
        }
        if (this.currentStep < 10) {
            imageName += '0' + this.currentStep.toString();
        } else {
            imageName += this.currentStep.toString();
        }
        return imageName + ".png";
    }

    finish() {
        this.exerciseService.restartTimer();
        this.changeShowHelp.emit(false);
    };

    goLogin() {
        this.navigationService.goLogin();
    }

    jumpToPage(pageNumber: number): void {
        if (pageNumber > 0 && pageNumber <= this.steps) {
            this.currentStep = pageNumber;
        }
    }
}