import { environment } from 'environments/environment';
import { Component, EventEmitter, OnInit, Output, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FileRequest, FileResponse } from 'app/core/shared/state/models/file/file.model';
import { FindUrlPipe } from 'app/pipes/find-url.pipe';
import { FileService } from 'app/services/file.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { AppService } from 'app/app.service';
import { SchoolService } from 'app/services/school.service';
import { SharedService } from 'app/core/shared/shared.service';

@Component({
    selector: 'help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HelpComponent implements OnInit, OnDestroy {

    private _unsubscribeAll: Subject<any>;
    @Output() changeShowHelp = new EventEmitter<boolean>();

    currentStep = 1;
    steps = 0;
    currentUser;
    imgUrls: FileResponse[] = [];
    subDirectory = "Tutoriales/tutorTutorial"
    isMobile: any;
    constructor(
        private router: Router,
        public schoolService: SchoolService,
        private appService: AppService,
        private fileService: FileService,
        public sharedService: SharedService
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit(): void {
        if (!sessionStorage.getItem("currentUser")) {
            this.goLogin();
            return;
        }
        this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
        if (this.currentUser == null) {
            this.goLogin();
            return;
        }
        this.fileService.getCountImage(this.subDirectory.split("/")[1])
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result: number) => {
                    this.steps = result;
                    if (this.currentUser.IdSchool == null) {
                        this.steps = 7;
                    }
                },
                (err) => {
                    console.error(err);
                });
        this.isMobile = this.appService.mobileDetect().IsIPhone || this.appService.mobileDetect().IsAndroidPhone || window.innerWidth < 600;
        if (this.isMobile) {
            this.subDirectory = "Tutoriales/tutorTutorialMovil"
        }
        switch (this.router.url) {
            case "/school/students":
                this.currentStep = 1;
                break;

            case "/school/config/mail":
                this.currentStep = 9;
                break;

            case "/school/config/editConfigSessions":
                this.currentStep = 10;
                break;
            case "/school/config/dictations":
                this.currentStep = 11;
                break;
            case "/school/config/activities":
                this.currentStep = 12;
                break;

            case "/school/config/challenge":
                this.currentStep = 13;
                break;

            case "/school/config/games":
                this.currentStep = 14;
                break;
            case "/school/config/zone":
                this.currentStep = 15;
                break;
            case "/school/config/options":
                this.currentStep = 16;
                break;

            case "/school/activities/dictations":
                this.currentStep = 17;
                break;

            case "/school/activities/reading":
                this.currentStep = 18;
                break;

            case "/school/activities/deduction":
                this.currentStep = 19;
                break;
            case "/school/activities/hearing":
                this.currentStep = 20;
                break;
            case "/school/complements/editSessions":
                this.currentStep = 21;
                break;
            case "/school/complements/exams":
                this.currentStep = 23;
                break;
            case "/school/complements/controlSession":
                this.currentStep = 26;
                break;
            case "/school/complements/personalizedTest":
                this.currentStep = 29;
                break;
            case "/school/tracking/report":
                this.currentStep = 32;
                break;
            case "/school/tracking/test":
                this.currentStep = 34;
                break;
            case "/school/messages":
                this.currentStep = 35;
                break;

            default:
                this.currentStep = 1;
                break;
        }

        if (this.router.url.includes("/school/students/")) {
            if (this.schoolService.extrafields) {
                this.currentStep = 4;
            }
            else {
                this.currentStep = 3;
            }
        }

        if (this.router.url.includes("/school/session")) this.currentStep = 6;
        if (this.router.url.includes("/school/students/auth")) this.currentStep = 8;
        if (this.router.url.includes("/school/students/class")) this.currentStep = 5;
        if (this.router.url.includes("/school/students/newStudent")) this.currentStep = 7;
    }

    numberOfPages() {
        return this.steps;
    };

    finish() {
        this.changeShowHelp.emit(false);
    };

    goLogin() {
        sessionStorage.removeItem('currentUser');
        sessionStorage.removeItem('adminLogin');
        sessionStorage.removeItem("state");
        sessionStorage.removeItem("state_params");
        sessionStorage.setItem("isLogged", "false");
        this.router.navigateByUrl("login");
    }

    changePage(forward: boolean): void {
        if (forward) {
            this.currentStep++;
        }
        else {
            if (this.currentStep > 1)
                this.currentStep--;
        }
    }

    getImgUrl() {
        return "https://walinwa.blob.core.windows.net/images/" + this.subDirectory + "/" + this.getImgName() + "?ngsw-bypass=true" ;
    }
    getImgName(): string {
        let imageName = '';
        if (this.isMobile) {
            imageName = "ayuda_profesores_vm-"
        }
        else {
            imageName = "ayuda_profesores-"
        }
        if (this.currentStep < 10) {
            imageName += '0' + this.currentStep.toString();
        } else {
            imageName += this.currentStep.toString();
        }
        imageName = imageName + ".png";
        return imageName;
    }

}
