<div class="main_container" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/esquina-inf-der.svg&quot;)'">
    <section fxLayout="column" fxLayoutAlign="stretch" class="box_options">
        <div class="box_op">
            <div class="title">
                <span>{{'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-PARTICULAR.WELLCOME.TITLE' | translate }}</span>
            </div>
            <div class="cont">
                <div class="spe_gap" fxLayout="column" fxLayoutAlign="stretch">
                    <div>
                        {{'AUTHENTICATION.FREE-TRIAL.FREE-TRIAL-PARTICULAR.WELLCOME.SUBTITLE' | translate}}
                    </div>
                    <div>
                        <div class="group_users" fxLayout="row wrap" fxLayoutAlign="center center">
                            <div (click)="goStudent(student)" class="student" *ngFor="let student of students; let i = index">
                                <img #img [style.background-color]="getBackgroundColor(i, img)"
                                    src="https://walinwa.blob.core.windows.net/images/alumno.png?ngsw-bypass=true" class="studentImg">
                                <div class="userNameIcon">{{ student.userName }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>