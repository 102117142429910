import { AvatarItemUser } from "app/core/shared/state/models/avatar-item-user.model";
import { AvatarShopService } from "./../../../services/avatar-shop.service";
import { Component, OnInit, ChangeDetectorRef, Type, OnDestroy, ViewEncapsulation, EventEmitter, Output } from "@angular/core";
import { AuthService } from "app/services/auth.service";
import { NavigationService } from "app/services/navigation.service";
import { AvatarItem } from "app/core/shared/state/models/avatar-item.model";
import { FileService } from "app/services/file.service";
import { FindUrlPipe } from "app/pipes/find-url.pipe";
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { environment } from "environments/environment";
import { SchoolService } from "app/services/school.service";
import * as moment from "moment";
import { SharedService } from "app/core/shared/shared.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "avatarShop",
    templateUrl: "./avatar-shop.component.html",
    styleUrls: ["./avatar-shop.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class AvatarShopComponent implements OnInit, OnDestroy {
    @Output() onClose = new EventEmitter<void>();
    private _unsubscribeAll: Subject<any>;
    tooltipText = this._translateService.instant('STUDENT.AVATAR-SHOP.EXPLANATION');
    saveDisabled = false;
    loadingData = true;
    viewHeads = true;
    viewEars = false;
    viewHair = false;
    viewEyes = false;
    ViewMouths = false;
    viewNoses = false;
    viewShirts = false;
    viewEyebrows = false;
    viewAccesories = false;
    calling = false;
    save = false;
    atributoActivo = null;
    typeIndex = 1;
    availableItems: AvatarItem[] = [];
    avatarItems: AvatarItem[] = [];
    initialActiveItems: AvatarItem[] = [];
    activeItems: AvatarItem[] = [];
    hasColor = true;
    showColors = false;
    itemSelcted: AvatarItem;
    recentlySaved = true;
    ActiveItemsOld: any[] = [];
    tabs = [
        "viewHeads",
        "viewEars",
        "viewHair",
        "viewEyes",
        "ViewMouths",
        "viewNoses",
        "viewShirts",
        "viewEyebrows",
        "viewAccesories"
    ];

    regalar_item: AvatarItem;
    regalar_message: any;
    regalar_userTo_bqd: any;
    showListUsers: boolean;
    listStudents: any;
    regalar_message_text: string;
    regalar_userTo: any;
    regalar_password: string;
    error: any;
    regalar_password_error: any;
    regalar_mensaje: string;
    selectedStudent: any;
    showPassword1: any;
    isMobile: boolean = false;
    userMetConditions = {}
    constructor(
        private navigationService: NavigationService,
        private avatarShopService: AvatarShopService,
        private authService: AuthService,
        private changeDetectorRef: ChangeDetectorRef,
        private schoolService: SchoolService,
        public sharedService: SharedService,
        private _translateService: TranslateService
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit(): void {
        this.isMobile = this.detectMobile();
        this.getUserMetConditions()
        this.avatarShopService.reload_items
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                if (result) {
                    this.activeItems = [];
                    this.getUserMetConditions()
                }
            })
    }

    detectMobile(): boolean {
        const userAgent = navigator.userAgent.toLowerCase();
        return /mobile|android|iphone|ipad|iemobile|wpdesktop/i.test(userAgent);
    }

    getUserMetConditions(){
        this.avatarShopService.GetMetConditionsByUser(this.authService.currentUser.Id)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result: any) => {
            this.userMetConditions = result;
            this.getAvatarItems()
        })
    }
    errorCallback() {
        this.calling = false;
        this.navigationService.goLogin();
    }
    hasSpecialItems() {
        return this.availableItems.find(x => x.Id >= 900 && x.Bougth)
    }
    getAvatarItems() {
        this.avatarShopService.getAvailableItems()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                this.successLoadAvailableItems(result)
            })
    }

    successLoadAvailableItems(result) {
        const conditionMappings = {
            1: "conditionSession",
            2: "conditionReward",
            3: "conditionHearing",
            4: "conditionMemoImage",
            5: "conditionReto"
        };
        this.availableItems = result;
        this.avatarShopService.availableItems = result;
        this.availableItems = this.availableItems.sort(function (a, b) {
            return a.Id - b.Id;
        });
        const filteredItems = [];
        this.availableItems.forEach((item) => {
            if(item.ConditionWin){
                if(this.userMetConditions[conditionMappings[item.ConditionWin]]){
                    filteredItems.push(item)
                }
            }
            else {
                filteredItems.push(item)
            }
        })
        this.availableItems = filteredItems;
        this.avatarItems = this.availableItems.filter((x) => {
            return x.Id >= 100 && x.Id < 200;
        });
        if(this.viewAccesories){
            this.showAccesories()
        }
        this.changeDetectorRef.detectChanges();
        this.getBoughtByIdUser();
    }

    getBoughtByIdUser() {
        this.avatarShopService
            .getBoughtByIdUser(this.authService.currentUser.Id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result) => {
                    this.successLoadBoughtItems(result);
                },
                (error) => {
                    this.errorCallback();
                }
            );
    }
    successLoadBoughtItems(result) {
        this.calling = true;
        if (result != null) {
            result.forEach(itemComprado => {
                const itemDisponible = this.availableItems.find(item => itemComprado.IdAvatarItem === item.Id);
                if (itemDisponible) {
                    itemDisponible.Bougth = true;
                    itemDisponible.selectedAttribute = itemComprado.AvatarItemAttributes || null;
                    itemDisponible.Active = itemComprado.Status || false;
                    itemDisponible.Selected = itemComprado.Status || false;
                }
            });
        }
        if (this.activeItems.length == 0) {
            this.activeItems = this.availableItems.filter((x) => {
                return x.Active == true;
            });
        }
        this.initialActiveItems = this.availableItems.filter((x) => {
            return x.Active == true;
        });
        this.activateAtrubuto(this.typeIndex);
        let item = this.activeItems.find((x) => x.Type == 3);
        if (item && item.SubId) {
            let indexSub = this.availableItems.findIndex((x) => x.Id == item.SubId);
            if (indexSub != -1) {
                this.availableItems[indexSub].selectedAttribute = item.selectedAttribute;
                this.activeItems.push(this.availableItems[indexSub])
            }
        }
        this.tryItem(this.availableItems.find(ai => ai.Selected));

        this.activeItems.forEach((x) => {
            if (x.Bougth) {
                let itemColor = {
                    idAvatarItem: x.Id,
                    idColor:
                        x.selectedAttribute?.Color ||
                            x.selectedAttribute?.Color == 0
                            ? x.selectedAttribute.Color
                            : null,
                };
                this.ActiveItemsOld.push(itemColor);
            }
        });
        this.calling = false;
        this.loadingData = false;
        this.recentlySaved = true;
    }

    getImgUrl(imageName: string, baseRoute = false) {
        if(baseRoute){
            return "https://walinwa.blob.core.windows.net/images/" + imageName + "?ngsw-bypass=true";
        }
        return "https://walinwa.blob.core.windows.net/images/Avatar/" + imageName + "?ngsw-bypass=true";
    }
    
    GetActiveItemsImg(type) {
        if (this.activeItems.length == 0) return;
        var itemActivo = this.activeItems.find((x) => {
            return x.Type == type;
        });
        let imageName = "";
        if (itemActivo?.selectedAttribute) {
            imageName = itemActivo.Id +
                "_" +
                itemActivo.selectedAttribute.Color +
                ".png";
        } else {
            imageName = itemActivo.Id +
                "_3" +
                ".png";
        }
        return this.getImgUrl(imageName)
    }
    getcabezaimage(index) {
        var itemActivo = this.activeItems.find((x) => {
            return x.Type == 1;
        });
        let imageName = "";
        if (itemActivo?.selectedAttribute) {
            imageName = this.avatarItems[index].Id +
                "_" +
                itemActivo.selectedAttribute.Color +
                ".png";
        } else {
            imageName = this.avatarItems[index].Id +
                "_3" +
                ".png";
        }
        return this.getImgUrl(imageName)
    }
    getImg(index) {
        if (this.avatarItems[index] == null) return;
        if (this.avatarItems[index]) {
            let imageName = this.avatarItems[index].Id + ".png";
            return this.getImgUrl(imageName)
        }
    }

    getImgoreja(index) {
        var itemActivo = this.activeItems.find((x) => {
            return x.Type == 1;
        });
        let imageName = "";
        if (itemActivo?.selectedAttribute) {
            imageName = this.avatarItems[index].Id +
                "_" +
                itemActivo.selectedAttribute.Color +
                ".png";
        } else {
            imageName = this.avatarItems[index].Id +
                "_3" +
                ".png";
        }
        return this.getImgUrl(imageName)
    }
    getImgPelo(index) {
        var itemActivo = this.activeItems.find((x) => {
            return x.Type == 3;
        });
        let imageName = "";
        if (itemActivo?.selectedAttribute) {
            imageName = this.avatarItems[index].Id +
                "_" +
                itemActivo.selectedAttribute.Color +
                ".png";
        } else {
            imageName = this.avatarItems[index].Id +
                "_0.png";
        }
        return this.getImgUrl(imageName)
    }
    getImgPeloSub(index) {
        var itemActivo = this.activeItems.find((x) => {
            return x.Type == 3;
        });
        let imageName = "";
        if (itemActivo?.selectedAttribute) {
            imageName = this.avatarItems[index].SubId +
                "_" +
                itemActivo.selectedAttribute.Color +
                ".png";
        } else {
            imageName = this.avatarItems[index].SubId +
                "_0.png";
        }
        return this.getImgUrl(imageName)
    }
    getImgCeja(index) {
        var itemActivo = this.activeItems.find((x) => {
            return x.Type == 8;
        });
        let imageName = "";
        if (itemActivo?.selectedAttribute) {
            imageName = this.avatarItems[index].Id +
                "_" +
                itemActivo.selectedAttribute.Color +
                ".png";
        } else {
            imageName = this.avatarItems[index].Id
                + "_0.png";
        }
        return this.getImgUrl(imageName)
    }

    private resolveImageName(item: AvatarItem, useSubId = false): string {

        var itemActivo = this.activeItems.find((x) => {
            return x.Type == this.typeIndex;
        });
        let imageName = "";
        let imageId = useSubId ? item.SubId + "" : item.Id + "";
        let defaultColor = this.resolveDefaultColor()
        if (itemActivo?.selectedAttribute) {

            imageName = imageId +
                "_" +
                itemActivo.selectedAttribute.Color +
                ".png";

        } else if (this.hasColor) {
            imageName = imageId
                + "_" + defaultColor + ".png";
        } else {
            imageName = imageId + ".png";
        }
        return imageName
    }

    private resolveDefaultColor(): number {
        let defaultColor = this.typeIndex == 8 || this.typeIndex == 3 ? 0 : 3
        return defaultColor;
    }
    findItemActive() {
        return this.initialActiveItems.find(x => x.Id == this.itemSelcted.Id)
    }
    buyItem() {
        if (this.itemSelcted) {
            let index;
            this.avatarItems.forEach((x, i) => {
                if (x.Id == this.itemSelcted.Id) {
                    index = i;
                }
            });
            if (index || index >= 0) {
                if (this.calling) return;
                if (this.avatarItems[index].Walinwos > this.authService.currentUser.Coins) return;
                var ColorList = []
                this.activeItems.forEach(itemActivo => {
                    if (itemActivo.selectedAttribute) {
                        ColorList.push({
                            Type: itemActivo.Type,
                            Color: itemActivo.selectedAttribute.Color
                        })
                    }
                })
                this.calling = true;
                this.avatarShopService
                    .setAvatarItemToUserShop(
                        this.authService.currentUser.Id,
                        this.avatarItems[index].Id,
                        this.avatarItems[index].Description,
                        this.avatarItems[index].Walinwos,
                        this.avatarItems[index].Type,
                        this.avatarItems[index]?.selectedAttribute?.Color
                    )

                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(
                        (result: AvatarItem[]) => {
                            this.initialActiveItems = [...result];
                            this.activeItems = [...result];
                            this.ActiveItemsOld = this.GetItemsToSave()
                            this.avatarItems[index].Bougth = true;
                            this.tryItem(this.avatarItems[index]);
                            this.recentlySaved = true;
                            this.avatarShopService.ItemBuyed.next();
                            this.calling = false;
                        },
                        () => {
                            this.errorCallback();
                        }
                    );
            }
        }
    }
    desactivateItem() {
        if (this.itemSelcted) {
            this.itemSelcted.Active = false;
            var index = this.activeItems.findIndex(x => x.Id == this.itemSelcted.Id);
            if (index > -1) {
                this.activeItems.splice(index, 1)
            }
            this.saveChanges()
        }
    }
    saveChanges() {
        this.save = true;
        let idItems = [];

        idItems = idItems.sort();
        if (!this.hasChanges()) {
            this.save = false;
            return
        }
        idItems = this.GetItemsToSave()
        this.ActiveItemsOld = idItems;
        this.avatarShopService
            .activateAllItems(this.authService.currentUser.Id, idItems)

            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (res: any) => {
                    this.save = false;
                    this.initialActiveItems = [...res];
                    this.activeItems = [...res];
                    this.recentlySaved = true;
                    this.avatarShopService.ItemBuyed.next();
                },
                (error) => {
                    this.errorCallback();
                    this.save = false;
                    this.recentlySaved = true;
                }
            );
    }

    showHead() {
        this.typeIndex = 1;
        this.activateAtrubuto(this.typeIndex);
        this.changeViewTab("viewHeads");
        this.hasColor = true;
        this.avatarItems = this.availableItems.filter((x) => {
            return x.Id >= 100 && x.Id < 200;
        });
        this.selectedDefault()
        this.changeDetectorRef.detectChanges();
    }
    showEars() {
        this.typeIndex = 1;
        this.changeViewTab("viewEars");
        this.hasColor = true;
        this.avatarItems = this.availableItems.filter((x) => {
            return x.Id >= 200 && x.Id < 300;
        });
        this.selectedDefault()
    }
    showHair() {
        this.typeIndex = 3;
        this.activateAtrubuto(this.typeIndex);
        this.changeViewTab("viewHair");
        this.hasColor = true;
        this.avatarItems = this.availableItems.filter((x) => {
            return x.Id >= 300 && x.Id < 400;
        });
        this.selectedDefault()
    }
    showEyes() {
        this.typeIndex = 4;
        this.changeViewTab("viewEyes");
        this.hasColor = false;
        this.avatarItems = this.availableItems.filter((x) => {
            return x.Id >= 400 && x.Id < 500;
        });
        this.selectedDefault()
    }
    showMouths() {
        this.typeIndex = 5;
        this.changeViewTab("ViewMouths");
        this.hasColor = false;
        this.avatarItems = this.availableItems.filter((x) => {
            return x.Id >= 500 && x.Id < 600;
        });
        this.selectedDefault()
    }
    showNoses() {
        this.typeIndex = 6;
        this.changeViewTab("viewNoses");
        this.hasColor = false;
        this.avatarItems = this.availableItems.filter((x) => {
            return x.Id >= 600 && x.Id < 700;
        });
        this.selectedDefault()
    }
    showShirts() {
        this.typeIndex = 7;
        this.changeViewTab("viewShirts");
        this.hasColor = false;
        this.avatarItems = this.availableItems.filter((x) => {
            return x.Id >= 700 && x.Id < 800;
        });
        this.selectedDefault()
    }
    showEyebrows() {
        this.typeIndex = 8;
        this.activateAtrubuto(this.typeIndex);
        this.changeViewTab("viewEyebrows");
        this.hasColor = true;
        this.avatarItems = this.availableItems.filter((x) => {
            return x.Id >= 800 && x.Id < 900;
        });
        this.selectedDefault()
    }
    showAccesories() {
        this.typeIndex = 10;
        this.hasColor = false;
        this.changeViewTab("viewAccesories");
        this.avatarItems = this.availableItems.filter((x) => {
            return x.Id >= 900 && x.Id < 1000;
        });
        this.selectedDefault()
    }
    tryItem(item, unselectable: boolean = false) {
        this.recentlySaved = false;
        if(!item){
            return;
        }

        if (unselectable && !this.initialActiveItems.find(i => i.Id === item.Id && i.Type == item.Type)) {
            let initial = this.initialActiveItems.find(i => i.Type === item.Type);
            const index = this.activeItems.findIndex(i => i.Id === item.Id && i.Active === true && i.Type == item.Type && i.Selected);

            if (index >= 0) {
                item.Selected = false;
                item.Active = false;

                if (initial) {
                    this.tryItem(initial, false);
                } else {
                    this.activeItems.splice(index, 1);    
                }
                
                return;
            }
        }

        this.activeItems.forEach((x) => {
            if (x.Type == item.Type) {
                x.Active = false;
            }
        });
        item.Active = true;
        if (!this.itemSelcted) {
            this.itemSelcted = item;
        }
        item.preselected = false;
        this.avatarItems.forEach((x) => {
            if (
                x.Selected == true &&
                x.Id != item.Id &&
                x.Type == item.Type
            ) {
                //x.Active = false;
                x.Selected = false;
            }
            if (x.Id == item.Id) {
                x.Selected = true;
            }
        });
        if (item.Type == 3) {
            if (item.Type == 3 && item.SubId) {
                this.activeItems = this.activeItems.filter((x) => x.Type !== 9 && x.Type !== 3);
                this.activeItems.push(item);

                let itemSub = this.availableItems.find((x) => x.Id == item.SubId && x.Type == 9);
                itemSub.selectedAttribute = item.selectedAttribute;
                this.activeItems.push(itemSub);
            } else {
                this.activeItems = this.activeItems.filter((x) => x.Type !== 9 && x.Type !== 3);
                this.activeItems.push(item)
            }

        } else {
            let cont = this.activeItems.filter((x) => x.Type == item.Type);
            if (cont.length > 1 || item.Type != this.itemSelcted.Type || (cont || []).length === 0) {
                this.activeItems = this.activeItems.filter((x) => x.Type !== item.Type);
                this.activeItems.push(item)
            } else if (this.activeItems.findIndex((x) => x.Type == item.Type) != -1) {
                this.activeItems.splice(
                    this.activeItems.findIndex((x) => x.Type == item.Type),
                    1,
                    item
                );
            }
        }
        this.itemSelcted = item;
    }
    changeColor(color) {
        let item = this.avatarItems.find((x) => x.Selected == true);
        let newColor = item.AvatarItemAttributes.find((x) => x.Color == color);
        if (item.Type == 3 && item.SubId) {
            let itemSub = this.avatarItems.find((x) => x.Id == item.SubId);
            itemSub.selectedAttribute = newColor;
            this.activeItems.splice(
                this.activeItems.findIndex((x) => x.Type == itemSub.Type),
                1,
                itemSub
            );
        }
        item.selectedAttribute = newColor;
        this.activeItems.splice(
            this.activeItems.findIndex((x) => x.Type == item.Type),
            1,
            item
        );
        if (item.Type === 1 || item.Type === 2) {
            this.availableItems
                .filter((x) => x.Type === 1 || x.Type === 2)
                .forEach((x) => {
                    x.selectedAttribute = newColor;
                });
        } else if (item.Type === 3) {
            this.availableItems
                .filter((x) => x.Type === 3 || item.Type === 9)
                .forEach((x) => {
                    x.selectedAttribute = newColor;
                });
        } else if (item.Type === 8) {
            this.availableItems
                .filter((x) => x.Type === 8)
                .forEach((x) => {
                    //VERIFICAR POR QUÉ NO FUNCIONA CON PELOS, AL PARECER NO TIENEN ATTRIBUTOS?
                    x.selectedAttribute = newColor;
                });
        }
        this.recentlySaved = false;
        this.avatarShopService.changeColor(true);
    }
    changeViewTab(currentTab: string) {
        this.showColors = false;
        this.tabs.forEach((x) => {
            if (x == currentTab) {
                this[x] = true;
            } else {
                this[x] = false;
            }
        });
    }

    activateAtrubuto(type) {
        if (type == 1) {
            this.atributoActivo = this.activeItems.find(
                (x) => x.Type == 1
            )?.selectedAttribute;
            this.availableItems.forEach((x) => {
                if (x.Type == 1 || x.Type == 2) {
                    x.selectedAttribute = this.atributoActivo;
                }
            });
        } else if (type == 3) {
            this.atributoActivo = this.activeItems.find(
                (x) => x.Type == 3
            )?.selectedAttribute;
            this.availableItems.forEach((x) => {
                if (x.Type == 3) {
                    x.selectedAttribute = this.atributoActivo;
                }
            });
        } else if (type == 8) {
            this.atributoActivo = this.activeItems.find(
                (x) => x.Type == 8
            )?.selectedAttribute;
            this.availableItems.forEach((x) => {
                if (x.Type == 8) {
                    x.selectedAttribute = this.atributoActivo;
                }
            });
        }
    }

    selectedDefault() {
        let i = this.avatarItems.findIndex((x) => x.preselected == true);
        if (i != -1) {
            this.avatarItems[i].Active = false;
            this.avatarItems[i].preselected = false;
            this.avatarItems[i].Selected = false;
        }
        let indexPreselected = this.activeItems.findIndex(
            (x) => x.preselected == true
        );
        if (indexPreselected != -1) {
            this.activeItems.splice(indexPreselected, 1);
        }

        let types = [10, 11].indexOf(this.avatarItems[0].Type) !== -1 ? [10, 11] : [this.avatarItems[0].Type];

        if (types.length > 1) {

            const _types = this.avatarItems.filter(x => x.Bougth && x.Active && types.indexOf(x.Type) > -1).map(i => i.Type);

            if (_types.length === 0) {
                types = [this.activeItems.find((x) => types.indexOf(x.Type) > -1 && (x.Active == true || x.Selected == true || x.Bougth == true))?.Type || types[0]];
            } else {
                types =_types;
            }
        }

        for (let i = 0; i <= types.length - 1; i++) {
            let currentType = types[i];
            let itemActivo = this.activeItems.find((x) => x.Type == currentType && (x.Active == true || x.Selected == true || x.Bougth == true));

            if (!itemActivo) {
                let itemComprado = this.avatarItems.find(x => x.Bougth && x.Active && x.Type == currentType) || this.avatarItems.find(x => x.Bougth && x.Type == currentType);

                if(itemComprado){
                    itemComprado.preselected = true;
                    itemComprado.Active = true;
                    itemComprado.Selected = true;
                    this.activeItems.splice(this.activeItems.length, 0, itemComprado);
                    itemActivo = itemComprado;
                }
                else if(currentType != 10 && currentType != 11) {
                    this.avatarItems[0].preselected = true;
                    this.avatarItems[0].Active = true;
                    this.avatarItems[0].Selected = true;
                    this.activeItems.splice(
                        this.activeItems.length,
                        0,
                        this.avatarItems[0]
                    );
                    itemActivo = this.avatarItems[0];
                }
            }

            itemActivo = this.avatarItems.find(x => x.Id == itemActivo.Id)
            this.itemSelcted = itemActivo;
            this.tryItem(itemActivo);
        }
    }
    hasChanges(): boolean {
        let idItems = this.GetItemsToSave()
        if (idItems.length !== this.ActiveItemsOld.length) {
            return true;
        }

        // Ordenar los arreglos por alguna clave (si es necesario)
        const sortedArray1 = idItems.sort((a, b) => JSON.stringify(a) > JSON.stringify(b) ? 1 : -1);
        const sortedArray2 = this.ActiveItemsOld.sort((a, b) => JSON.stringify(a) > JSON.stringify(b) ? 1 : -1);

        // Comparar los elementos uno por uno
        for (let i = 0; i < sortedArray1.length; i++) {
            const obj1 = sortedArray1[i];
            const obj2 = sortedArray2[i];

            // Comparar los objetos
            if (JSON.stringify(obj1) !== JSON.stringify(obj2)) {
                return true;
            }
        }

        return false;

    }
    
    GetItemsToSave() {
        let idItems = [];
        let newSubItemId = null;
        let oldSubItemId = null;

        this.activeItems.forEach((ActiveItem, index) => {
            if (ActiveItem.Bougth) {
                let itemColor = {
                    IdAvatarItem: ActiveItem.Id,
                    IdColor:
                        ActiveItem.selectedAttribute?.Color ||
                            ActiveItem.selectedAttribute?.Color == 0
                            ? ActiveItem.selectedAttribute.Color
                            : null,
                    Type: ActiveItem.Type
                };
                idItems.push(itemColor);
            }
            else if (ActiveItem.Type != 9) {
                let oldActiveItem = this.initialActiveItems.find(y => y.Type == ActiveItem.Type)
                if (!oldActiveItem || !oldActiveItem.Bougth) {
                    return;
                }
                let itemColor = {
                    IdAvatarItem: oldActiveItem.Id,
                    IdColor:
                        oldActiveItem.selectedAttribute?.Color ||
                            oldActiveItem.selectedAttribute?.Color == 0
                            ? oldActiveItem.selectedAttribute.Color
                            : null,
                    Type: oldActiveItem.Type
                };
                this.activeItems.splice(index, 1, oldActiveItem);
                        
                if (oldActiveItem.Type === 3 && oldActiveItem.SubId)
                    newSubItemId = oldActiveItem.SubId;
                
                if (ActiveItem.Type === 3 && ActiveItem.SubId)
                    oldSubItemId = ActiveItem.SubId;

                idItems.push(itemColor);
            }
        });

        if (newSubItemId)
            this.activeItems.push(this.availableItems.find(subItem => subItem.Id === newSubItemId));

        if (oldSubItemId)
            this.activeItems = this.activeItems.filter(item => item.Id !== oldSubItemId);

        return idItems;
    }

    PanelAvantarHide() {
        const panelAvat = document.getElementById("avatarpanel");
        panelAvat.classList.remove('active');
        this.onClose.emit();
    }
    regalar_click() {
        if (!this.itemSelcted || (this.itemSelcted && !this.itemSelcted.Bougth)) {
            return;
        }
        this.searchUser();
        this.regalar_item = this.itemSelcted;
    }

    searchUser() {

        if (this.authService.currentUser.IdSchool != null) {
            if (this.listStudents == null) {
                this.schoolService.getStudentsSchool(this.authService.currentUser.IdSchool)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(
                        result => {
                            this.successCallbackGetAllStudents(result);
                        },
                        error => {
                            this.errorCallback()
                        }
                    )
            } else {
                this.regalar_message = false;
                this.regalar_message = "";
                this.regalar_userTo_bqd = null;
                this.showListUsers = true;
            }
        } else {
            if (this.listStudents == null) {
                this.schoolService.getStudentsTutor(this.authService.currentUser.IdUserTutor)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(
                        result => {
                            this.successCallbackGetAllStudents(result);
                        },
                        error => {
                            this.errorCallback()
                        }
                    )
            } else {
                this.regalar_message = false;
                this.regalar_message_text = "";
                this.regalar_userTo_bqd = null;
                this.showListUsers = true;
            }
        }


    }
    successCallbackGetAllStudents(result) {
        this.regalar_message = false;
        this.regalar_message_text = "";
        this.listStudents = result.sort(this.SortArray);
        this.regalar_userTo_bqd = null;
        this.showListUsers = true;
        if (this.authService.currentUser.IdSchool == null) {
            let today = moment().format("DD/MM/YYYY")
            this.listStudents = this.listStudents.filter(x => x.LicenseStatus != null);
            this.listStudents = this.listStudents.filter(x => x.Id != this.authService.currentUser.Id && moment(x.LicenseStatus).format("DD/MM/YYYY") >= today);
        }
    }
    cancelRegalar() {
        this.regalar_item = null;
        this.regalar_userTo = null;
        this.regalar_password = "";
        this.regalar_message = false;
        this.regalar_message_text = "";
        this.error = null;
        this.regalar_userTo_bqd = null;
        this.selectedStudent = null;
    }
    SortArray(x, y) {
        return x.Name.localeCompare(y.Name);
    }

    checkRegalar() {
        let studentExist = this.listStudents.find(x => x.UserName.includes(this.regalar_userTo_bqd))
        if(!studentExist && !this.selectedStudent) {
            this.error = this._translateService.instant('STUDENT.AVATAR-SHOP.NO-EXISTING-USER-ERROR');
            this.showErr()
            return;
        }
        if(!this.selectedStudent){
            this.selectedStudent = studentExist
        }
        
        this.avatarShopService.checkRegalar(this.regalar_item.Id, this.authService.currentUser.Id, this.selectedStudent.Id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                result => {
                    this.error = false;
                    this.regalar_userTo = this.selectedStudent;

                    var userTo = this.regalar_userTo.Alias;

                    this.regalar_mensaje = this._translateService.instant('STUDENT.AVATAR-SHOP.GIFT-MESSAGE', {itemDescription: this.regalar_item.Description, giftingUser: userTo});
                    if (this.authService.currentUser.BlockMessageGift) {
                        this.continueRegalarMessage();
                    }
                },
                error => {


                    if (!error.error) return;
                    //Errores
                    //1 -> Ya tiene el objeto

                    switch (error.error.error) {
                        case 1:
                            this.error = this._translateService.instant('STUDENT.AVATAR-SHOP.USER-HAS-NO-AVATAR-ERROR');
                            break;
                        case 2:
                            this.error = this._translateService.instant('STUDENT.AVATAR-SHOP.USER-ALREADY-HAS-ITEM-ERROR');
                            break;
                    }

                    this.showErr()
                });
    }
    showErr() {
        var message = [];
        message[0] = this._translateService.instant('STUDENT.AVATAR-SHOP.GIFT-ERROR');
        message[1] = this.error;
        this.regalar_userTo = null;
        this.regalar_password = "";
        this.error = null;
        this.regalar_userTo_bqd = null;
        this.selectedStudent = null;
        this.navigationService.error(message, () => { })
    }
    regalar() {
        if(this.calling){
            return;
        }
        this.calling = true;
        this.regalar_password_error = null;
        if (this.regalar_password == "") {
            //No ha escrito la clave
            this.regalar_password_error = this._translateService.instant('STUDENT.AVATAR-SHOP.GIFT-BLANK-FIELD-ERROR');
        } else {
            this.authService.checkLogin(this.authService.currentUser.UserName, this.regalar_password)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(
                    result => {
                        this.avatarShopService.regalar(this.regalar_item.Id, this.authService.currentUser.Id, this.regalar_userTo.Id, this.regalar_message_text, this.regalar_item.Type)
                            .pipe(takeUntil(this._unsubscribeAll))
                            .subscribe(
                                result => {
                                    
                                    var message = [];
                                    message[0] = this._translateService.instant('STUDENT.AVATAR-SHOP.GIFT-CORRECT-MESSAGE1');
                                    message[1] = this._translateService.instant('STUDENT.AVATAR-SHOP.GIFT-CORRECT-MESSAGE2');
                                    this.navigationService.error(message, () => {
                                        let indexAvatarItem = this.avatarItems.findIndex(x => x.Id == this.regalar_item.Id)
                                        if(indexAvatarItem){
                                            this.avatarItems.splice(indexAvatarItem, 1)
                                        }
                                        indexAvatarItem = this.activeItems.findIndex(x => x.Id == this.regalar_item.Id)
                                        if(indexAvatarItem){
                                            this.activeItems.splice(indexAvatarItem, 1)
                                        }
                                        this.avatarShopService.ItemBuyed.next()
                                        this.regalar_item = null;
                                        this.regalar_userTo = null;
                                        this.regalar_message = false;
                                        this.regalar_message_text = "";
                                        this.regalar_password = "";
                                        this.error = null;
                                        this.regalar_userTo_bqd = null;
                                        this.calling = false;

                                    })
                                },
                                error => {
                                    this.calling = false;
                                }
                            )
                    },
                    error => {
                        this.calling = false;
                        this.regalar_password_error = this._translateService.instant('PASSWORD-WRONG');
                    }
                )
        }
    }
    continueRegalarMessage() {
        this.regalar_message = true;
    }

    es_regalado(item) {
        if (!item) return "";
        for (let i = 0; i < this.authService.currentUser.AvatarShopPresents_recibidos.length; i++) {
            if (this.authService.currentUser.AvatarShopPresents_recibidos[i].IdAvatarItem == item.Id) {
                return this.authService.currentUser.AvatarShopPresents_recibidos[i].UserNameFrom ? this.authService.currentUser.AvatarShopPresents_recibidos[i].UserNameFrom : this._translateService.instant('STUDENT.SHOP.USER-NOT-EXIST')
            }
        }
        return "";
    }
}
