import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class Theme{
    
    public getMedalThemeText(theme: string) {
        switch (theme) {
            case "bv":
                return "Uso de B y V";
            case "gj":
                return "Uso de G y J";
            case "lly":
                return "Uso de Y y LL";
            case "h":
                return "Uso de H";
            case "xs":
                return "Uso de S y X";
            case "c":
                return "Uso de C y CC";
            case "dz":
                return "Uso de D y Z";
            case "gu":
                return "Gue/i y diéresis";
            case "otr":
                return "Uso de B-C-G-P";
            case "r":
                return "Uso de R y RR";
            case "mn":
                return "Uso de M y N";
            case "mbp":
                return "M antes de B y P";
            case "til":
                return "Polisílabas";
            case "dip":
                return "Diptongos";
            case "tai":
                return "Oraciones";
            case "tmo":
                return "Monosílabos";
            case "abc":
                return "Vocabulario";
            case "ppp":
                return "Atención";
            case "mem":
                return "Memoria";
            case "ana":
                return "Anagramas";
        }
    }
}