<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="numer spe_gap" fxLayout="column" fxLayoutAlign="stretch" [ngStyle]="{'pointer-events': review ? 'none;' : ''}">

    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div fxFlex="" fxLayout="column" class="tableContainer">
        <table class="table-fill">
            <thead>
                <tr>
                    <th class="text-center"></th>
                    <th class="text-center">{{rows[0].col1}}</th>
                    <th class="text-center">{{rows[1].col2}}</th>
                </tr>
            </thead>
            <tbody class="table-hover">
                <tr *ngFor="let col of cols; let i = index">
                    <td class="text-center firstCol">{{ col }}</td>
                    <td class="dropzone text-center item" [attr.row]="col" [attr.col]="rows[0].col1" [attr.idRow]="i" idCol="0" [ngClass]="{ 'itemError' : result[i][0] && result[i][0].answered == false,'itemOk' : result[i][0] && result[i][0].answered == true }">
                        <span [ngClass]="{'element-hidden': result[i][0] && result[i][0].answered == null && show_errors != false}">
                            {{ result[i][0] ? result[i][0].valor : '' }}
                        </span>
                    </td>
                    <td class="dropzone text-center item" [attr.row]="col" [attr.col]="rows[1].col2" [attr.idRow]="i" idCol="1" [ngClass]="{'itemError' : result[i][1] && result[i][1].answered == false,'itemOk' : result[i][1] && result[i][1].answered == true }">
                        <span [ngClass]="{'element-hidden': result[i][1] && result[i][1].answered == null && show_errors != false}">
                            {{ result[i][1] ? result[i][1].valor : '' }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
    <div fxFlex="auto" [hidden]="finished"  class="optionsContainer" [ngClass]="{'element-collapse': finished,'optionsContainer-ctrl-session': controlSession && !slide, 'options-container-slide': slide}">
        <ul fxFlex="" fxLayout="row wrap" class="spe_gap">
            <li *ngFor="let opt of options; let i = index" class="listOption item draggable" dragChildImg="option{{ i }}" [ngClass]="{'element-hidden': opt.answered == true || opt.answered == false && show_errors != false, 'itemError' : opt.answered == false}" id="option{{ i }}" [attr.row]="opt.col" [attr.col]="opt.fila" [attr.val]="opt.valor">
                <span>{{ opt.valor }}</span>
            </li>
        </ul>
    </div>
</div>