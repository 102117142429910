import { Component, Input, OnInit, OnDestroy, HostListener, ViewEncapsulation } from '@angular/core';
import { ExerciseService } from 'app/services/exercise.service';

@Component({
  selector: 'ex-boxesn',
  templateUrl: './boxesn.component.html',
  styleUrls: ['./boxesn.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BoxesnComponent implements OnInit, OnDestroy {

  @Input() review;
  @Input() show_errors;
  @Input() controlSession : boolean = false;
  @Input() slide : boolean = false; @Input() current;
  exercise;
  words;
  draggingOption;
  exerciseIsEnded = false;
  finished = false;

  words_to_drag = 1
  largeText

  elementsOk;
  elementsErr;

  isiPhone: boolean;
  isAndroid: boolean;

    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case "SHOW_SOLUTION":
                this.showSolution();
                break;
            case "SHOW_ANSWER":
            case "SHOW_CUSTOM":    
                this.ShowAnswer();
                break;
        }
    }

  showingSolution = false;

  @HostListener("window:resize", ["$event"])
    onResize(event) {
    // 
  }

  constructor(private exerciseService: ExerciseService) {
    this.exercise = this.exerciseService.getExerciseDefinition();
    this.init();

    // Detectar si es un iPhone
    this.isiPhone = /iPhone/i.test(navigator.userAgent);

    // Detectar si es un dispositivo Android
    this.isAndroid = /Android/i.test(navigator.userAgent);
  }
  ngOnDestroy() {
  }

  ngOnInit() {
    if (this.review) {
      this.elementsOk = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsOK
      );
      this.elementsErr = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsERR
      );
      if (this.show_errors == false) {
        this.showSolution();
      } else if (this.show_errors == true || this.show_errors == null) {
        this.ShowAnswer();
      }
    } else {
      this.initInteract();
    }

  }

  fill() {
    for (var i = 0; i < this.words.length; i++) {
      if (this.words[i].wordFull != null && this.words[i].originalWord != null)
        this.words[i].word = this.words[i].originalWord;
    }
  }

  init() {
    var lastChar = this.exercise.libro[this.exercise.libro.length - 1];
    if (lastChar != '?' && lastChar != '!') {
      this.exercise.libro = this.exercise.libro + '.';
    }

    this.words = [];
    var items = this.exercise.fragmento.split(" ");

    for (var i = 0; i < items.length; i++) {
      var item: any = {
        word: items[i], drag: false, box: null, answered: null
      };
      var cont = true;
      for (var j = 0; j < this.exercise.boxes.length && cont; j++) {
        for (var z = 0; z < this.exercise.boxes[j].palabras.length && cont; z++) {
          if (this.exercise.operador == "3b") {
            if (this.getWord(items[i]) == this.getWord(this.exercise.boxes[j].palabras[z])) {
              item.drag = true;
              item.box = this.exercise.boxes[j].box;
              cont = false;
            }
          } else if (this.exercise.operador == "pg") {
            if (this.getWord(items[i]) == this.getWord(this.exercise.boxes[j].palabras[z].palabra)) {
              item.drag = true;
              item.box = this.exercise.boxes[j].box;
              //Search capital letter
              if (item.word[0] == item.word[0].toUpperCase())
                this.exercise.boxes[j].palabras[z].palabraGuion = this.exercise.boxes[j].palabras[z].palabraGuion[0].toUpperCase() + this.exercise.boxes[j].palabras[z].palabraGuion.slice(1);

              var pattern = new RegExp(this.exercise.boxes[j].palabras[z].palabra, 'gi');
              item.originalWord = item.word;
              item.originalWord2 = this.getWord2(item.word);
              item.word = item.word.replace(pattern, this.exercise.boxes[j].palabras[z].palabraGuion);
              item.wordFull = this.exercise.boxes[j].palabras[z].palabra;
              item.idWord = this.exercise.boxes[j].palabras[z].idPalabra;
              cont = false;
            }
          }
        }
      }
      this.words.push(item);
    }

    for (var i = 0; i < this.words.length; i++)
      for (var j = i + 1; j < this.words.length; j++)
        if ((this.getWord(this.words[i].word) == this.getWord(this.words[j].word)) ||
          (this.getWord(this.words[i].word) == this.getWord(this.words[j].wordFull))) {
          this.words[j].drag = false;
          this.words[j].box = null;
          this.words[j].answered = null;
          if (this.words[j].wordFull != null && this.words[j].originalWord != null)
            this.words[j].word = this.words[j].originalWord;
        }

    this.largeText = this.exercise.fragmento.length > 500;
    this.words_to_drag = this.words.filter(w => w.drag == true).length
  }

  showSolution() {
    this.fill()
    this.showingSolution = true;
    for (var j = 0; j < this.words.length; j++) {
      if (this.words[j].drag == true)
        this.words[j].answered = true;

    }
  }

  ShowAnswer() {
    this.fill()
    this.showingSolution = true;
    for (var j = 0; j < this.words.length; j++) {
      if (this.words[j].drag == true)
        this.words[j].answered = null;
      for (var i = 0; i < this.elementsOk.length; i++)
        if (this.elementsOk[i] == j)
          this.words[j].answered = true;
      for (var i = 0; i < this.elementsErr.length; i++)
        if (this.elementsErr[i] == j)
          this.words[j].answered = false;
    }
  }

  initInteract() {
    this.exerciseService.initInteract({
      onmove: (event) => {
        this.draggingOption = event.target
      },
      drop: (event) => {
        for (var j = 0; j < this.words.length; j++) {
          if (this.words[j].answered == false)
            this.words[j].answered = null;
        }
        let item = this.draggingOption
        let bin = event.target
        var boxWord = item.getAttribute("box");
        var boxBox = bin.getAttribute("box");
        if (this.words[item.id].answered == true) return;
        if (boxWord == boxBox) {
          this.words[item.id].answered = true;
          this.words[item.id].drag = false;
          this.exerciseService.answer(item.id, true, this.words_to_drag)
          // exerciseService.AddOk(item.id);
          if (this.words[item.id].wordFull != null && this.words[item.id].originalWord != null) {
            this.words[item.id].word = this.words[item.id].originalWord;
          }
        }
        else {
          this.words[item.id].answered = false;
          // this.$emit("error");
          // exerciseService.AddError(item.id);
          this.exerciseService.answer(item.id, false, this.words_to_drag)
        }
        var done = true;
        for (var i = 0, len = this.words.length; i < len; i++) {

          if (this.words[i].answered != true && this.words[i].drag == true) {
            done = false;
          }
        }
        if (done) {
          this.finished = true;
        }
      },
    });
  }

  showAnswersTimeOut() {
    for (var j = 0; j < this.words.length; j++) {
      this.words[j].answered = true;
      this.words[j].drag = false;
    }
  }

  getWord(word) {
    if (word == null) return word;
    return word.replace('.', '').replace(',', '').replace(';', '').replace(':', '')
      .replace(".", "")
      .replace(";", "")
      .replace(":", "")
      .replace("-", "").replace("—", "")
      .replace("\"", "")
      .replace("!", "").replace("¡", "")
      .replace("?", "").replace("¿", "")
      .replace("<", "").replace(">", "")
      .replace("{", "").replace("}", "")
      .replace("[", "").replace("]", "")
      .replace("«", "").replace("»", "")
      .replace("(", "").replace(")", "")
      .toLowerCase();
  }

  getWord2(word) {
    if (word == null) return word;
    return word.replace('.', '').replace(',', '').replace(';', '').replace(':', '')
      .replace(".", "")
      .replace(";", "")
      .replace(":", "")
      .replace("-", "").replace("—", "")
      .replace("\"", "")
      .replace("!", "").replace("¡", "")
      .replace("?", "").replace("¿", "")
      .replace("<", "").replace(">", "")
      .replace("{", "").replace("}", "")
      .replace("[", "").replace("]", "")
      .replace("«", "").replace("»", "")
      .replace("(", "").replace(")", "");
  }

}
