<div class="walinwa-box walinwa-box-green" *ngIf="dataLoaded" fxNgClass.gt-sm="scroll_vertical">
    <div class="walinwa-box-header">
        {{'SCHOOL.PARENT.STUDENTS.LICENSE.TITLE' | translate}}
    </div>
    <div class="walinwa-box-content">
        <div>
            {{'SCHOOL.PARENT.STUDENTS.LICENSE.CONTENT' | translate}}
        </div>
        <div class="three_elements gapi" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between stretch" fxLayoutAlign.xs="center center">

            <div class="license" *ngFor="let license of licenses; let i = index">

                <div class="price">{{ license.Price }}€</div>

                <div class="desc">{{ license.Name }}</div>

                <div class="buttoneri" fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center">
                    <button [disabled]="!license.Active" (click)="submitForm(i)" class="new_but azul">{{'SCHOOL.PARENT.CONTINUE' | translate}}</button>
                </div>

            </div>

        </div>
    </div>
</div>