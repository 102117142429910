import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ExerciseService } from "app/services/exercise.service";

@Component({
    selector: "ex-marca-t",
    templateUrl: "./marca-t.component.html",
    styleUrls: ["./marca-t.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class MarcaTComponent implements OnInit, OnDestroy {
    @Input() review;
    @Input() show_errors;
    @Input() controlSession : boolean = false;
    @Input() slide: boolean = false;
    @Input() current;
    exercise;
    finished = false;
    answered;

    elementsOk = [];
    elementsErr = [];

    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case "SHOW_SOLUTION":
                this.showSolution();
                break;
            case "SHOW_ANSWER":
            case "SHOW_CUSTOM":
                this.ShowAnswer();
                break;
        }
    }

    constructor(private exerciseService: ExerciseService) {
        this.exercise = this.exerciseService.getExerciseDefinition();
    }
    ngOnDestroy(){
    }

    ngOnInit() {
        if (this.review) {
            this.elementsOk = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsOK
            );
            this.elementsErr = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsERR
            );
            if (this.show_errors == false) {
            this.showSolution();
            } else if (this.show_errors == true || this.show_errors == null) {
            this.ShowAnswer();
            }
        }
        var lastChar = this.exercise.libro[this.exercise.libro.length - 1];
        if (lastChar != "?" && lastChar != "!") {
            this.exercise.libro = this.exercise.libro + ".";
        }
    }
    azul() {
        return this.exercise.enunciado.indexOf("<br><br><b>") != -1;
    }

    parseEnunciado() {
        // return this.exercise.enunciado.replace("<br><br>", "<br>");
        return this.exercise.enunciado.replace(/:.*/, ":");
    }

    parseEnunciadoRegla() {
        // Use a regular expression to keep all text and HTML content after the ":"
        const match = this.exercise.enunciado.match(/:(.*)/);
        if (match) {
          return match[1].replace("<br><br>", "");
        } else {
          // Return the original text if ":" is not found
          return this.exercise.enunciado;
        }
      }
      

    validateClick(spanId) {
        if (this.finished) return;
        this.answered = null;
        for (var i = 0; i < this.exercise.palabras.length; i++) {
            if (this.exercise.palabras[i].opcion == "F") {
                this.exercise.palabras[i].answered = null;
                this.answered = null;
            }
        }
        this.exercise.palabras[spanId].answered = true;
        let options = 0;
        this.exercise.palabras.forEach((palabra) => {
            if (palabra.opcion === "T") options += 1;
        });
        if (this.exercise.palabras[spanId].opcion == "T") {
            this.exerciseService.answer(spanId, true, options);
        } else {
            this.exerciseService.answer(spanId, false, options);
        }

        var done = true;
        for (var i = 0; i < this.exercise.palabras.length; i++) {
            if (
                this.exercise.palabras[i].opcion == "T" &&
                this.exercise.palabras[i].answered != true
            )
                done = false;
            if (
                this.exercise.palabras[i].opcion != "T" &&
                this.exercise.palabras[i].answered == true
            ) {
                done = false;
                this.answered = false;
            }
        }
        if (done) {
            this.answered = true;
            this.finished = true;
        }
    }
    showSolution() {
        this.answered = true;
        this.exercise.palabras.forEach((value, key) => {
            if (value.opcion == "T")                
                value.answered = true;                
            else
                value.answered = null;
        });
    }                   
    
    ShowAnswer() {
        switch (this.exerciseService.getCurrentExercise().Result) {
            case "JMP":
                this.answered = null;
                break;
            case "OK-JMP":
                this.answered = null;
                break;
            case "ERR-JMP":
                this.answered = false;
                break;
            case "OUT":
                this.answered = null;
                break;
            case "OK-OUT":
                this.answered = null;
                break;
            case "ERR-OUT":
                this.answered = false;
                break;
            case "ERR-OK":
                this.answered = false;
                break;
            case "OK":
                this.answered = true;
                break;
        }

        this.exercise.palabras.forEach((value, key) => {
            value.answered = null;
            for (var p = 0; p < this.elementsErr.length; p++) {
                if (key == this.elementsErr[p]) value.answered = true;
            }
            for (var p = 0; p < this.elementsOk.length; p++) {
                if (key == this.elementsOk[p] && value.answered != false)
                    value.answered = true;
            }
        });
    }
}
