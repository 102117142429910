<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="colocaf spe_gap" (click)="setFocus(focusing ? false : true)" fxLayout="column" fxLayoutAlign="stretch"
    [ngStyle]="{'pointer-events': review ? 'none;' : ''}">

    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div id="space-for-text" class="wordsContainer">
        <div class="items_container spe_gap" fxLayout="column" fxLayoutAlign.lt-md="start center" fxLayoutAlign="space-between center">
            <div class="exerciseRule">
                <span class="subPhrase" *ngIf="answered != true && timeout  != true">{{ exercise.frase2 }}</span>
                <span class="subPhrase" *ngIf="answered == true || timeout == true">{{ exercise.frase }}</span>
            </div>
            <div *ngIf="!controlSession" class="dropContainer" [ngClass]="{ phraseBoxOk:  answered == true, phraseBoxError:  error}">
                <div *ngIf="answer.length == 0"></div>
                <div *ngFor="let text of answer; let i = index" class="optionBox" dragChildImg="asnwer{{ i }}">
                    <div id="asnwer{{ i }}" type="down" [attr.index]="i" class="optionBoxChild ">{{ text.name }}</div>
                </div>
            </div>
            <div class="letters" *ngIf="!isMobile">
                <ul class="list-inline">
                    <li *ngFor="let letter of letters; let i = index" class="letterContainer">
                        <div class="letter-button letter" (click)="insert(letter)">
                            {{ letter.name }}
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<form style="position: fixed; top: -99999em; left: -99999em;" autocomplete="off" *ngIf="this.isMobile">
    <input [(ngModel)]="input" autocorrect="off" autocapitalize="off" spellcheck="false" (ngModelChange)="change()"
        [ngModelOptions]="{standalone: true}" autocomplete="off" type="text" id="dictation-input" autofocus  />
</form>
