<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="ahorcai spe_gap" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" fxLayout="column"
    fxLayoutAlign="stretch">

    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div class="wordsContainer">
        <div class="items_container spe_gap" fxLayout="column" fxLayoutAlign="space-between center"
            fxLayoutAlign.lt-md="start center">
            <div class="imagen">
                <img [src]="getImg()" />
            </div>
            <div class="secretWord spe_gap"
                [ngClass]="{ 'perfect': review && elementsErr.length == 0 && elementsOk.length == secretWord.length}" fxLayout="column">
                <div class="retries" *ngIf="errors != 1">
                    Te quedan <span class="textRed">{{errors
                        }}</span> intentos
                </div>
                <div class="retries" *ngIf="errors == 1">
                    Te queda <span class="textRed">{{errors
                        }}</span> intento
                </div>
                <div class="word">
                    <ul class="list-inline">
                        <li *ngFor="let letter of secretWord">
                            <span [hidden]="letter.show" class="letter letterSpace">_</span>
                            <span [hidden]="!letter.show" class="letter letterSpace">{{ letter.value }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div fxHide fxShow.xs class="imageContainer two" *ngIf="!controlSession">
                <div class="image" [style.background-image]="getImgStyle()" [ngClass]="{'reviewing': review}"></div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-evenly stretch" class="keyboard_sec spe_gap">
                <div fxFlex="" class="letters">
                    <ul class="list-inline" [ngClass]="{'fixControlSessions': controlSession}">
                        <li *ngFor="let letter of letters" class="letterContainer">
                            <div [ngClass]="{ 'chosen': letter.chosen}" class="letter-button letter"
                                [disabled]="letter.chosen" (click)="letter.chosen ? null : try(letter);">
                                {{letter.name}}
                            </div>
                        </li>
                    </ul>
                </div>
                <div fxHide.xs class="imageContainer">
                    <div [style.background-image]="getImgStyle()" class="image"></div>
                </div>
            </div>
        </div>

    </div>

</div>
<!--  -->
<!-- <div class="ahorcai" [ngStyle]="{'pointer-events': review ? 'none;' : ''}">   <h2 *ngIf="controlSession" class="doc-number">{{current + 1}}</h2>

    <div *ngIf="!controlSession" class="exerciseTheme">
        <h1 [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></h1>
    </div>

    <div class="text-center exerciseTitle" [ngClass]="{'exerciseTitle-slide': slide}">
        <p   [innerHtml]="exercise.enunciado"></p>
    </div>

    <div class="space" [ngStyle]="slide ? {height : '23em'}:{}">

        <div class="imagen">

            <img [src]="getImg()" />

        </div>

        <div [ngStyle]="controlSession && !slide ? {height : '30%;'} : controlSession && slide ? {height : '20%;'} : {}" class="secretWord"  [ngClass]="{ 'perfect': review && elementsErr.length == 0 && elementsOk.length == secretWord.length}">
            <div class="retries" *ngIf="errors != 1" [ngStyle]="controlSession ? {'font-size': '1.5em;'} : {}">
                Te quedan <span [ngStyle]="controlSession ? {'font-size': '1em;'} : {}" class="textRed">{{errors }}</span> intentos
            </div>
            <div class="retries" *ngIf="errors == 1" [ngStyle]="controlSession ? {'font-size': '1.5em;'} : {}">
                Te queda <span [ngStyle]="controlSession ? {'font-size': '1em;'} : {}" class="textRed">{{errors }}</span> intento
            </div>
            <div class="word" [ngStyle]="controlSession ? {'margin-bottom': '1em;', width: '90%;'} : {}">
                <ul class="list-inline">
                    <li *ngFor="let letter of secretWord">
                        <span [hidden]="letter.show" class="letter letterSpace">_</span>
                        <span [hidden]="!letter.show" class="letter letterSpace">{{ letter.value }}</span>
                    </li>
                </ul>
            </div>
        </div>

        <div style="height: 15em;display: flex;justify-content: center;" *ngIf="!controlSession">
            <div class="letters">
                <ul class="list-inline">
                    <li *ngFor="let letter of letters" class="letterContainer" [ngStyle]="controlSession ? {width: '2.5em;', height: '2.5em;'} : {}">
                        <div [ngClass]="{ 'chosen': letter.chosen}" class="letter-button letter" [disabled]="letter.chosen" (click)="try(letter);">
                            {{letter.name}}
                        </div>
                    </li>
                </ul>
            </div>
            <div class="imageContainer">
                <div [ngStyle]="{'background-image': getImgStyle()}" class="image"></div>
            </div>
        </div>

    </div>

</div> -->