export class MemoImageUser{
    idUser: number;
    Walinwos: number;
    Date: Date;
    Page: number;

    constructor(idUser: number, Walinwos: number, Date: Date, Page: number){
        this.idUser = idUser;
        this.Walinwos = Walinwos;
        this.Date   = Date;
        this.Page   = Page;
    }
}
