import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { NavigationService } from 'app/services/navigation.service';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs'
import { SharedService } from 'app/core/shared/shared.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-progress',
    templateUrl: './progress.component.html',
    styleUrls: ['./progress.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProgressComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
    currentUser;
    dataLoaded = false;
    states = [];
    testEnabled = false;
    state;

    constructor(
        private router: Router,
        private authService: AuthService,
        private navigationService: NavigationService,
        private sharedService: SharedService,
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit(): void {
        if (!this.authService.check()) return
        this.currentUser = this.authService.currentUser
        this.goReport()
        this.sharedService.personalized_test_enabled
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                this.testEnabled = result;
            })
    }

    goReport() {
        this.state = 'report';
        this.states.push('report');
        if (this.router.url == '/student/progress') {
            this.router.navigateByUrl(this.router.url + '/report');
        } else {
            this.router.navigateByUrl('/student/progress/report');
        }
    }

    GoTestResult() {
        this.state = 'test';
        this.states.push('test');
        if (this.router.url == '/student/progress') {
            this.navigationService.go(this.router.url + '/test', { review: true });
        } else {
            this.navigationService.go('/student/progress/test', { review: true });
        }
    }
}

