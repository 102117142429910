import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { DataState, DataStore } from './data.store';

@Injectable({ providedIn: 'root' })
export class DataQuery extends Query<DataState> {
  class$ = this.select((state) => state.class);
  user$ = this.select((state) => state.user);
  school$ = this.select((state) => state.school);
  students$ = this.select((state) => state.students);
  password$ = this.select((state) => state.password);

  constructor(protected store: DataStore) {
    super(store);
  }

  get class() {
    return this.getValue().class;
  }

  get user() {
    return this.getValue().user;
  }

  get school() {
    return this.getValue().school;
  }

  get students() {
    return this.getValue().students;
  }

  get password(){
    return this.getValue().password;
  }
}
