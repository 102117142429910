import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpParams, HttpEvent, HttpHeaderResponse, HttpResponse, HttpErrorResponse, HttpUserEvent, HttpProgressEvent } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Location } from '@angular/common';
import { AuthService } from '../guards/auth.service';


@Injectable()
export class RepositoryService {
    baseUrl = environment.baseApi;
    constructor(private http: HttpClient, private _location: Location, private _authService: AuthService) {
    }

    public get(route: string) {
        return this.http.get(route);
    }

    public getAuth(route: string) {
        let header = this._authService.authJsonHeaders();
        return this.http.get(route, {headers: header});
    }

    public post(route: string, body) {
        return this.http.post(route, body);
    }

    public put(route: string, body) {
        return this.http.put(this.createCompleteRoute(route, this._location.normalize(this.baseUrl)), body);
    }

    public delete(route: string) {
        return this.http.delete(this.createCompleteRoute(route, this._location.normalize(this.baseUrl)));
    }

    private createCompleteRoute(route: string, envAddress: string) {
        return `${envAddress}/${route}`;
    }
}
