<!-- Footer Enunciado -->
<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="siglas" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" fxLayout="column" fxLayoutAlign="stretch">

    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div class="wordsContainer" id="space-for-text">
        <div id="TextContainer" class="items_container">

            <div *ngFor="let frase of tmpPhrases; let i = index" id="TextContainer{{ i }}"
                [ngStyle]="{background : getColor(i)}" [attr.index]="i" class="exerciseRule"
                [ngClass]="{ phraseBoxOk:  frase.answered == true, phraseBoxError:  frase.answered == false, phraseBoxOkNowAnswered: finished && frase.opcion && frase.answered == null}"
                (click)="validate(i)">

                <div class="phraseBoxChild">
                    <span [innerHtml]="frase.frase"></span>
                </div>

            </div>

        </div>
    </div>

</div>
<!--  -->
<!-- <div class="siglas" [ngStyle]="{'pointer-events': review ? 'none;' : ''}">   <h2 *ngIf="controlSession" class="doc-number">{{current + 1}}</h2>

    <div *ngIf="!controlSession" class="exerciseTheme">

        <h1 [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></h1>

    </div>

    <div class="text-center exerciseTitle" [ngStyle]="controlSession ? {'margin-top': '4.5em;'} : {}"  [ngClass]="{'azul': azul(), 'cs' : controlSession,'exerciseTitle-slide': slide}">

        <p style="background-color: rgb(125,125,125,0.6); padding: 2%;" [innerHtml]="exercise.enunciado"></p>

    </div>

    <div  [ngClass]="{'words-container-slide': slide,'wordsContainer': !slide}"  id="space-for-text">

        <div id="TextContainer" class="text-center" [ngStyle]="controlSession && !slide? {'margin-top': '3em;'} : {}" >

            <div *ngFor="let frase of tmpPhrases; let i = index" id="TextContainer{{ i }}" [ngStyle]="{background : getColor(i)}" [attr.index]="i" class="exerciseRule" [ngClass]="{ phraseBoxOk:  frase.answered == true, phraseBoxError:  frase.answered == false, phraseBoxOkNowAnswered: finished && frase.opcion && frase.answered == null}" (click)="validate(i)">
                
                <div class="phraseBoxChild">

                    <span [innerHtml]="frase.frase"></span>

                </div>

            </div>

        </div>

    </div>

</div> -->