import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'app/app.service';
import { SharedService } from 'app/core/shared/shared.service';
import { FileService } from 'app/services/file.service';
import { SchoolService } from 'app/services/school.service';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'parent-help',
    templateUrl: './parent-help.component.html',
    styleUrls: ['./parent-help.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ParentHelpComponent implements OnInit, OnDestroy {

    private _unsubscribeAll: Subject<any>;
    @Output() changeShowHelp = new EventEmitter<boolean>();

    currentStep = 1;
    steps = 0;
    currentUser;
    subDirectory = "Tutoriales/partTutorial"
    isMobile: any;
    constructor(
        private router: Router,
        public schoolService: SchoolService,
        private appService: AppService,
        private fileService: FileService,
        public sharedService: SharedService
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit(): void {
        if (!sessionStorage.getItem("currentUser")) {
            this.goLogin();
            return;
        }
        this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
        if (this.currentUser == null) {
            this.goLogin();
            return;
        }
        this.fileService.getCountImage(this.subDirectory.split("/")[1])
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result: number) => {
                    this.steps = result;
                },
                (err) => {
                    console.error(err);
                });
        this.isMobile = this.appService.mobileDetect().IsIPhone || this.appService.mobileDetect().IsAndroidPhone || window.innerWidth < 600;
        if (this.isMobile) {
            this.subDirectory = "Tutoriales/partTutorialMovil"
        }
        
        switch (this.router.url) {
            case "/school/parentStudents":
                this.currentStep = 1;
                break;

            case "/school/parentStudents/license":
                this.currentStep = 5;
                break;

            case "/school/parentStudents/newStudent":
                this.currentStep = 8;
                break;
            case "/school/configParents/mail":
                this.currentStep = 9;
                break;
            case "/school/myAccount":
                this.currentStep = 10;
                break;

            case "/school/parentReport":
                this.currentStep = 11;
                break;
            default:
                this.currentStep = 1;
                break;
        }
        if (this.router.url.includes("/school/parentStudents/editStudent/")) {
            if (this.schoolService.extrafields) {
                this.currentStep = 4;
            }
            else {
                this.currentStep = 3;
            }
        }
        
        if (this.router.url.includes("/school/parentStudents/license")) this.currentStep = 5;
        if (this.router.url.includes("/school/parentStudents/review")) this.currentStep = 6;
        if (this.router.url.includes("/school/parentStudents/auth")) this.currentStep = 7;
    }

    numberOfPages() {
        return this.steps;
    };

    finish() {
        this.changeShowHelp.emit(false);
    };

    goLogin() {
        sessionStorage.removeItem('currentUser');
        sessionStorage.removeItem('adminLogin');
        sessionStorage.removeItem("state");
        sessionStorage.removeItem("state_params");
        sessionStorage.setItem("isLogged", "false");
        this.router.navigateByUrl("login");
    }

    changePage(forward: boolean): void {
        if (forward) {
            this.currentStep++;
        }
        else {
            if (this.currentStep > 1)
                this.currentStep--;
        }
    }

    getImgUrl() {
        //return this.sharedService_.getImgUrl(this.subDirectory + "/" + this.getImgName());
        return "https://walinwa.blob.core.windows.net/images/" + this.subDirectory + "/" + this.getImgName() + "?ngsw-bypass=true" ;
    }
    getImgName(): string {
        let imageName = '';
        if (this.isMobile) {
            imageName = "ayuda_particulares_vm-"
        }
        else {
            imageName = "ayuda_particulares-"
        }
        if (this.currentStep < 10) {
            imageName += '0' + this.currentStep.toString();
        } else {
            imageName += this.currentStep.toString();
        }
        imageName = imageName + ".png";
        return imageName;
    }

}