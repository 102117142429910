<div *ngIf="!controlSession" class="indicationsFooterbar">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="boxes4c spe_gap" [ngStyle]="{'pointer-events': review ? 'none;' : ''}" fxLayout="column"
    fxLayoutAlign="stretch">

    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div fxFlex="" class="simulacion_canvas" [ngClass]="{'canvas-slide': slide}" fxLayout="column" fxLayoutAlign="stretch">
        <div class="palabras" [ngClass]="{'fixControlSessions': controlSession}">
            <ng-container *ngFor="let palabra of exercise.palabras; let i = index">
                <div *ngIf="!finished" [id]="'palabra' + i + slide + 'boxes4c' + numero_random"
                    class="palabra draggable"
                    [ngClass]="{'element-hidden': palabra.answered == true && finished == false,'bien': palabra.answered == true, 'mal': palabra.answered == false,naranja : palabra.answered == null && finished == true }">
                    <span>{{ palabra.palabra }}</span>
                </div>
            </ng-container>
        </div>
        <div fxFlex="" class="father_boxes">
            <div *ngFor="let caja of exercise.boxes; let i = index" class="caja_padre" [ngClass]="{'der': i == 3}">
                <ng-container *ngFor="let palabra of exercise.palabras; let i = index">
                    <div *ngIf="finished && palabra.opcion == caja.valor"
                        [id]="'palabra' + i + slide + 'boxes4c' + numero_random" class="palabra-review"
                        [ngClass]="{'bien': palabra.answered == true, 'mal': palabra.answered == false,'naranja' : palabra.answered == null && finished == true }">
                        <span>{{ palabra.palabra }}</span>
                    </div>
                </ng-container>
                <div [id]="'box' + i + slide + 'boxes4c' + numero_random" class="caja dropzone" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/Exercises/Boxesc/front.png?ngsw-bypass=true&quot;)'"
                    [ngClass]="{'arriba-izq': i == 1 && !finished, 'arriba-der': i == 2 && !finished}">
                    <span class="descripcion">{{ caja.descripcion }}</span>
                </div>
            </div>
        </div>
    </div>
</div>