<div class="walinwa-box-content">
    <!-- <div class="walinwa-box-exercise" [ngClass]="{'walinwa-box-exercise-cs': controlSession && !slide}"> -->
    <div class="walinwa-box-exercise">
        <div class="center" [ngStyle]="slide ? {width: '160%'} : {width: exerciseModel == 'cruci' ?  '95%': '100%'}">
            <ex-guionp *ngIf="!showEndSession && exerciseModel == 'guionp'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-guionp>
            <ex-boxes2 *ngIf="!showEndSession && exerciseModel == 'boxes2'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-boxes2>
            <ex-boxes2b *ngIf="!showEndSession && exerciseModel == 'boxes2b'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-boxes2b>
            <ex-boxes3 *ngIf="!showEndSession && exerciseModel == 'boxes3'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-boxes3>
            <ex-guionf *ngIf="!showEndSession && exerciseModel == 'guionf'" [review]="review" [slide]="slide" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-guionf>
            <ex-marca *ngIf="!showEndSession && exerciseModel == 'marca' || exerciseModel == 'marca.1' || exerciseModel == 'marca.2' || exerciseModel == 'marca.3'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-marca>
            <ex-marcar *ngIf="!showEndSession && exerciseModel == 'marcar'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-marcar>
            <ex-pregunta *ngIf="!showEndSession && exerciseModel == 'pregunta'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-pregunta>
            <ex-unir *ngIf="!showEndSession && exerciseModel == 'unir'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-unir>
            <ex-unir2 *ngIf="!showEndSession && exerciseModel == 'unir2'" [review]="review" [slide]="slide" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-unir2>
            <ex-unir3 *ngIf="!showEndSession && exerciseModel == 'unir3'" [review]="review" [slide]="slide" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-unir3>
            <ex-unir4 *ngIf="!showEndSession && exerciseModel == 'unir4'" [review]="review" [slide]="slide"[show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-unir4>
            <ex-unir4a *ngIf="!showEndSession && exerciseModel == 'unir4a'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-unir4a>
            <ex-unir5 *ngIf="!showEndSession && exerciseModel == 'unir5'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-unir5>
            <ex-unir6 *ngIf="!showEndSession && exerciseModel == 'unir6'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-unir6>
            <ex-mLluvia *ngIf="!showEndSession && exerciseModel == 'mLluvia'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-mLluvia>
            <ex-parejas *ngIf="!showEndSession && exerciseModel == 'parejas'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-parejas>
            <ex-boxes2c *ngIf="!showEndSession && exerciseModel == 'boxes2c'" [slide]="slide" [review]="false" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-boxes2c>
            <ex-boxes3c *ngIf="!showEndSession && exerciseModel == 'boxes3c'" [slide]="slide" [review]="false" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-boxes3c>
            <ex-boxes4c *ngIf="!showEndSession && exerciseModel == 'boxes4c'" [slide]="slide" [review]="false" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-boxes4c>
            <ex-define *ngIf="!showEndSession && exerciseModel == 'define'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-define>
            <ex-mod1 *ngIf="!showEndSession && exerciseModel == 'mod1'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-mod1>
            <ex-mod2 *ngIf="!showEndSession && exerciseModel == 'mod2'" [slide]="slide"  [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-mod2>
            <ex-selec *ngIf="!showEndSession && exerciseModel == 'selec'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-selec>
            <ex-flechas *ngIf="!showEndSession && exerciseModel == 'flechas'" [slide]="slide"  [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-flechas>
            <ex-orden *ngIf="!showEndSession && exerciseModel == 'orden'" [review]="review" [slide]="slide" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-orden>
            <ex-coloca *ngIf="!showEndSession && exerciseModel == 'coloca'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-coloca>
            <ex-marca1 *ngIf="!showEndSession && exerciseModel == 'marca1'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-marca1>
            <ex-verbos *ngIf="!showEndSession && exerciseModel == 'verbos'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-verbos>
            <ex-rapid *ngIf="!showEndSession && exerciseModel == 'rapid'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-rapid>
            <ex-ahorca *ngIf="!showEndSession && exerciseModel == 'ahorca'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-ahorca>
            <ex-cruci *ngIf="!showEndSession && exerciseModel == 'cruci'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-cruci>
            <ex-marca-t *ngIf="!showEndSession && exerciseModel == 'marcaT'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-marca-t>
            <ex-marca-f *ngIf="!showEndSession && exerciseModel == 'marcaf'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-marca-f>
            <ex-colocaf *ngIf="!showEndSession && exerciseModel == 'colocaf'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-colocaf>
            <ex-selecf *ngIf="!showEndSession && exerciseModel == 'selecf'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-selecf>
            <ex-mod3  *ngIf="!showEndSession && exerciseModel == 'mod3'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-mod3>
            <ex-numer *ngIf="!showEndSession && exerciseModel == 'numer'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-numer>
            <ex-boxesn *ngIf="!showEndSession && exerciseModel == 'boxesn'" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [slide]="slide" [controlSession]="controlSession"></ex-boxesn>
            <ex-sopa *ngIf="!showEndSession && exerciseModel == 'sopa'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-sopa>
            <ex-ahorcafr *ngIf="!showEndSession && exerciseModel == 'ahorcafr'" [slide]="slide"  [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-ahorcafr>
            <ex-ahorcai *ngIf="!showEndSession && exerciseModel == 'ahorcai'" [review]="review" [slide]="slide" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-ahorcai>
            <ex-ahorcar *ngIf="!showEndSession && exerciseModel == 'ahorcar'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-ahorcar>
            <ex-coloca1 *ngIf="!showEndSession && exerciseModel == 'coloca1'" [review]="review" [slide]="slide" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-coloca1>
            <ex-frase *ngIf="!showEndSession && exerciseModel == 'frase'" [review]="review" [slide]="slide" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-frase>
            <ex-opc1 *ngIf="!showEndSession && exerciseModel == 'opc1'" [review]="review" [slide]="slide"  [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-opc1>
            <ex-img *ngIf="!showEndSession && exerciseModel == 'img'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-img>
            <ex-pasap *ngIf="!showEndSession && exerciseModel == 'pasap'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-pasap>
            <ex-pasap1 *ngIf="!showEndSession && exerciseModel == 'pasap1'" [slide]="slide"  [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-pasap1>
            <ex-rima *ngIf="!showEndSession && exerciseModel == 'rima'" [review]="review" [slide]="slide" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-rima>
            <ex-sopa1 *ngIf="!showEndSession && exerciseModel == 'sopa1'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-sopa1>
            <ex-sopa2 *ngIf="!showEndSession && exerciseModel == 'sopa2'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-sopa2>
            <ex-verbos1 *ngIf="!showEndSession && exerciseModel == 'verbos1'" [slide]="slide" [review]="review" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-verbos1>
            <ex-siglas *ngIf="!showEndSession && exerciseModel == 'siglas'" [review]="review" [slide]="slide" [show_errors]="show_errors" [current]="currentExercise" [controlSession]="controlSession"></ex-siglas>
        </div>
    </div>
</div>