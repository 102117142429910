import { Component, Input, OnInit, OnDestroy, HostListener, ViewEncapsulation } from '@angular/core';
import { ExerciseSolvedEnum } from 'app/core/shared/enums/exercise-solved.enum';
import { ExerciseService } from 'app/services/exercise.service';


@Component({
  selector: 'ex-unir3',
  templateUrl: './unir3.component.html',
  styleUrls: ['./unir3.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Unir3Component implements OnInit, OnDestroy {

  @Input() review
  @Input() show_errors;
  @Input() controlSession : boolean = false;
  @Input() slide : boolean = false; 
  @Input() current;
  exercise
  finished
  showingSolution = false
  elementsOk
  elementsErr
  draggingWord
  intialColumn

  @HostListener('window:message', ['$event'])
    exerciseListener(event){
      let data = event.data;
      switch (data) {
        case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_SOLUTION]:
          this.finished = true;
          if (this.exerciseService.showingSolution) {
            setTimeout(this.ShowAnswersTimeOut.bind(this), 100);
              break;
          }else {
            setTimeout(this.ShowSolution.bind(this), 100);
            break;
          }
        case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_ANSWER]:
          setTimeout(this.ShowAnswer.bind(this), 500)
          this.finished = true;
          break;
          case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_CUSTOM]:
            setTimeout(this.ShowAnswer.bind(this), 500)
            this.finished = true;
            break;
        case this.exercisesSolvedEnum[this.exerciseSolvedEnum.TIMER_END]:
          this.finished = true
          break;
        case this.exercisesSolvedEnum[this.exerciseSolvedEnum.EXERCISE_END]:
          this.finished = true
          this.showingSolution = true;
          break;
      }
    }

  constructor(private exerciseService: ExerciseService) {
    this.exercise = this.exerciseService.getExerciseDefinition();
    this.exercise.result = []
  }
  public get exercisesSolvedEnum(): typeof ExerciseSolvedEnum{
    return this.exerciseSolvedEnum
  }
    
  public get exerciseSolvedEnum(): typeof ExerciseSolvedEnum{
    return ExerciseSolvedEnum
  }
  ngOnDestroy() {
  }

  ngOnInit() {
    this.FillColumn()
    if (this.review) {
      this.elementsOk = JSON.parse(this.exerciseService.getCurrentExercise().ElementsOK)
      this.elementsErr = JSON.parse(this.exerciseService.getCurrentExercise().ElementsERR)
      if (this.show_errors == false) {
        this.ShowSolution();
      } else if (this.show_errors == true || this.show_errors == null) {
        this.ShowAnswer();
      }
    } else {
      this.exerciseService.initInteract({
        onmove: (event) => {
          this.draggingWord = event.target
        },
        drop: (event) => {
          if (this.finished) return;
          let bin = this.draggingWord
          let element = event.target
          if (this.finished) return
          let binIndex = bin.getAttribute("index");
          let binOriginalIndex = bin.getAttribute("originalIndex");

          let elementIndex = element.getAttribute("index");
          let elementOriginalIndex = element.getAttribute("originalIndex");

          if (binIndex == elementIndex) return

          let result

          for (var i = 0; i < this.intialColumn.length; i++) {
            if (this.intialColumn[i].answered == false)
              this.intialColumn[i].answered = null;
          }

          if (this.intialColumn[binIndex].relatedIndex == elementIndex) {
            if (this.exercise.operador == "un")
              result = this.exercise.valor[elementOriginalIndex].v1.replace('_', '') + this.exercise.valor[elementOriginalIndex].v2.replace('_', '');
            else
              result = this.exercise.valor[elementOriginalIndex].v1 + " / " + this.exercise.valor[elementOriginalIndex].v2;
            this.intialColumn[binIndex].answered = true;
            this.intialColumn[elementIndex].answered = true;
            let elemIndex = this.exercise.result.findIndex(x => x.word == result)
            if(elemIndex > -1) {
                this.exercise.result.splice(elemIndex, 1, { word: result, answered: true });
            }
            else {
                this.exercise.result.splice(this.exercise.result.length, 0, { word: result, answered: true });
            }
            this.exercise.valor[elementOriginalIndex].answered = true;
            this.exerciseService.answer(parseFloat(binOriginalIndex), true, this.exercise.valor.length);

          } else {
            this.intialColumn[binIndex].answered = false;
            this.intialColumn[elementIndex].answered = false;
            if (this.exercise.valor[elementOriginalIndex].answered != false)
              this.exercise.valor[elementOriginalIndex].answered = false;
            if (this.exercise.valor[binOriginalIndex].answered != false)
              this.exercise.valor[binOriginalIndex].answered = false;
            if (this.exercise.operador == "un")
              result = this.exercise.valor[elementOriginalIndex].v1.replace('_', '') + this.exercise.valor[elementOriginalIndex].v2.replace('_', '');
            else
              result = this.exercise.valor[elementOriginalIndex].v1 + " / " + this.exercise.valor[elementOriginalIndex].v2;
              let elemIndex = this.exercise.result.findIndex(x => x.word == result)
              if(elemIndex > -1) {
                  this.exercise.result.splice(elemIndex, 1, { word: result, answered: false });
              }
              else {
                  this.exercise.result.splice(this.exercise.result.length, 0, { word: result, answered: false });
              }
            // this.$emit("error");
            this.exerciseService.answer(parseFloat(binOriginalIndex), false, this.exercise.valor.length);
          }

          for (var i = 0; i < this.intialColumn.length; i++) {
            if (this.intialColumn[i].answered != true)
              return;
          }
          this.finished = true;
          this.showingSolution = true;
        }
      });
    }
  }

  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  FillColumn() {
    this.intialColumn = Array(this.exercise.valor.length * 2);
    for (var i = 0; i < this.exercise.valor.length; i++) {
      var foundEmptyA = false;
      var indexA = this.getRandomInt(0, this.exercise.valor.length * 2 - 1);
      var foundEmptyB = false;
      var indexB = this.getRandomInt(0, this.exercise.valor.length * 2 - 1);
      while (foundEmptyA != true) {
        if (this.intialColumn[indexA] == null) {
          foundEmptyA = true;
          this.intialColumn[indexA] = true;//tmpValue
        }
        else {
          indexA++;
          if (indexA >= this.exercise.valor.length * 2)
            indexA = 0;
        }
      }
      while (foundEmptyB != true) {
        if (this.intialColumn[indexB] == null) {
          foundEmptyB = true;
          this.intialColumn[indexB] = true;//tmpValue
        }
        else {
          indexB++;
          if (indexB >= this.exercise.valor.length * 2)
            indexB = 0;
        }
      }
      this.intialColumn[indexA] = { "palabra": this.exercise.valor[i].v1, "relatedIndex": indexB, "originalIndex": i, "selected": false, "answered": null };
      this.intialColumn[indexB] = { "palabra": this.exercise.valor[i].v2, "relatedIndex": indexA, "originalIndex": i, "selected": false, "answered": null };
    }
  }

  ShowAnswer() {
    this.showingSolution = true
    this.exercise = this.exerciseService.getExerciseDefinition();
    this.exercise.result = [];
    if (!this.exercise.grupo1) this.exercise.grupo1 = this.exercise.valor;
    for (var i = 0; i < this.exercise.valor.length; i++) {
      var answered = null;  
      for (var p = 0; p < this.elementsErr.length; p++) {
        if (this.elementsErr[p] < this.exercise.valor.length && this.exercise.valor[this.elementsErr[p]] && this.exercise.valor[i] && this.exercise.valor[i].v1 == this.exercise.valor[this.elementsErr[p]].v1)
          answered = false;
      }
      for (var p = 0; p < this.elementsOk.length; p++) {
        if (this.elementsOk[p] < this.exercise.valor.length && this.exercise.valor[i] && this.exercise.valor[this.elementsOk[p]] && this.exercise.valor[i].v1 == this.exercise.valor[this.elementsOk[p]].v1 && answered == null)
          answered = true;
      }
      var result;
      if (this.exercise.operador == "un")
        result = this.exercise.valor[i].v1.replace('_', '') + this.exercise.valor[i].v2.replace('_', '');
      else
        result = this.exercise.valor[i].v1 + " / " + this.exercise.valor[i].v2;
      this.exercise.result.splice(this.exercise.result.length, 0, { word: result, answered: answered });
    }
  }

  ShowSolution() {
    this.showingSolution = true
    this.exercise = this.exerciseService.getExerciseDefinition();
    this.exercise.result = [];
    if (!this.exercise.grupo1) this.exercise.grupo1 = this.exercise.valor;
    for (var i = 0; i < this.exercise.grupo1.length; i++) {
      if (this.exercise.grupo1[i] != null) {
        var result;
        if (this.exercise.operador == "un")
          result = this.exercise.valor[i].v1.replace('_', '') + this.exercise.valor[i].v2.replace('_', '');
        else
          result = this.exercise.valor[i].v1 + " / " + this.exercise.valor[i].v2;       
        
        this.exercise.result.splice(this.exercise.result.length, 0, { word: result, answered: true });

      }
    }
  }
    ShowHourglassAnswer() {
        this.showingSolution = false;
        this.exercise = this.exerciseService.getExerciseDefinition();
        this.exercise.result = []
    }
  ShowAnswersTimeOut() {
    this.showingSolution = true
    this.exercise.result = []
    if (!this.exercise.grupo1) this.exercise.grupo1 = this.exercise.valor;
    for (var i = 0; i < this.exercise.valor.length; i++) {
        var result
        if (this.exercise.operador == "un")
            result = this.exercise.valor[i].v1.replace('_', '') + this.exercise.valor[i].v2.replace('_', '');
        else
            result = this.exercise.valor[i].v1 + " / " + this.exercise.valor[i].v2;
        this.exercise.result.splice(this.exercise.result.length, 0, { word: result, answered: this.exercise.valor[i].answered });
    }
  }
}
