import { Component, Input, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { UiService } from './ui.service';
import { SpinnerService } from './spinner.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit, OnDestroy, AfterViewInit {

    private _unsubscribeAll: Subject<any>;

    constructor(
        private _ui: UiService,
        public _spinnerService: SpinnerService) {
    }
    ngOnInit() {
    }

    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngAfterViewInit() {
    }
}
