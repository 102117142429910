import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { TranslateService } from '@ngx-translate/core';
import { LogEventLevel } from 'app/core/logging/log-models';
import { SharedService } from 'app/core/shared/shared.service';
import { HearingTextResult } from 'app/core/shared/state/models/Hearing-text/hearing-text-result.model';
import { HearingText } from 'app/core/shared/state/models/Hearing-text/hearing-text.model';
import { AuthService } from 'app/services/auth.service';
import { HearingTextService } from 'app/services/hearing-text.service';
import { LogService } from 'app/services/log.service';
import { NavigationService } from 'app/services/navigation.service';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { first, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-play-hearing-text',
  templateUrl: './play.component.html',
  styleUrls: ['./play.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PlayComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
    private _loggingSteps: string[] = [];
    private _idUser: number = null;

    teacherReview = false;
    review = false;
    hearingText : HearingText;
    questionIndex: number;
    form: UntypedFormGroup;
    score: HearingTextResult;
    loading: boolean;
    walinwos: number;
    correct: boolean;
    messages = [];
    selectedDay: any;
    eventsSubject: Subject<object> = new Subject<object>();
    confirmDialog: MatDialogRef<FuseDialogContinueComponent>;
    nuevoFondo: boolean = false;
    retry = false;
    TotalUserAnswers = 0;
    TotalOkUserAnswers = 0;
    sonido: any;
    pista: string;
    speaking = false;
    audioId: string = null
    audio = document.createElement("audio");
    finished: boolean;
    reviewingStudentActivity = false;
    IdHearingText: any;
    reviewingActivity: boolean;
    directory = "https://walinwa.blob.core.windows.net/images/Comprensiones/"

    constructor(
        private activatedRoute: ActivatedRoute,
        private hearingTextService: HearingTextService,
        private authService: AuthService,
        private navigationService: NavigationService,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private _matDialog: MatDialog,
        private sharedService: SharedService,
        private _logService: LogService,
        private _detector: ChangeDetectorRef,
        private _translateService: TranslateService
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy() {
        this.hearingTextService.MonthlyHearing = null;
        this.hearingTextService.changeReloadMonthlyHearing(true);
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
      }

      async ngOnInit() {
        if (!this.authService.check()) {
            return this.navigationService.exit();
        }
    
        const navParams = this.navigationService.params;
        if (navParams) {
            this.selectedDay = navParams.date || this.selectedDay;
            this.IdHearingText = navParams.IdHearingText || this.IdHearingText;
        }
    
        const params = await this.activatedRoute.params.pipe(first()).toPromise();
        if (params?.IdHearingText && params?.Date) {
            this.IdHearingText = params.IdHearingText;
            this.selectedDay = params.Date;
        }
    
        this.reviewingActivity = navParams?.reviewing === true;
        this.getHearingText(this.IdHearingText, this.selectedDay);
    }

    getHearingText(IdHearingText: number, selectedDate: any) {
        this.loading = true;
        this._detector.detectChanges();        
        let idUser = this.authService.currentUser.Id;

        if (this.navigationService.params.IdUser) {
            idUser = this.navigationService.params.IdUser;
            this._idUser = idUser;
            this.teacherReview = true;
            this.review = true;
        } else if (this.router.url.includes("review")) {
            this.teacherReview = true;
            this.review = true;
        }

        if (this.navigationService.params) {
            if (this.navigationService.params.review == true) {
                this.review = true;

                if (!this.teacherReview)
                    this._idUser = idUser;
            }

            if (this.navigationService.params.IdUser) {
                idUser = this.navigationService.params.IdUser;
                this._idUser = idUser;
            }            
        }

        this.hearingTextService.getHearingTextByid(IdHearingText, idUser, selectedDate)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result) => {
                    this.hearingText = result;
                    this._loggingSteps.push(`Valor del objeto hearingText al iniciar: ${JSON.stringify(this.hearingText)}`);
                    this.SuccesLoadInitText()
                },
                error => {
                    this.loading = false;
                    this.errorCallback(error.error)
                });

    }

    async SuccesLoadInitText() {
        this.checkUserAnswers();

        const allAnswered = this.TotalUserAnswers === this.hearingText.Questions.length;

        if (this.hearingText.Score > 0 || allAnswered) {
            this.review = true;

            if (!this.teacherReview && allAnswered)
                this._idUser = this._idUser ?? this.authService.currentUser.Id;
        }

        this.walinwos = this.hearingText.Questions.length * 10;
        this.ParseFragment();
        this.loadAudio();

        if (this.review) {

            if (!this.hearingText.MarkedAsFinished && this._idUser !== null)
                await this.hearingTextService.getScore(this.hearingText.IdHearingText, this._idUser, this.hearingText.date).pipe(takeUntil(this._unsubscribeAll), tap(r => this.hearingText.Score = r.Score)).toPromise();

            this.loading = false;

            return;
        }

        this.hearingText.Questions[0].Answers = this.shuffle(this.hearingText.Questions[0].Answers);
        this.form = this.formBuilder.group({
            IdQuestion: [""],
            IdAnswer: ["", Validators.required],
        });

        this.loading = false;

        if (this.TotalUserAnswers > 0) {
            this.showMessage();
        } else {
            this.questionIndex = -1;
            this._loggingSteps.push(`questionIndex inicial: ${this.questionIndex}`);
        }
    }

    padNumberToThreeCharacters(inputNumber: number): string {
        // Convierte el número de entrada a una cadena
        let paddedNumber: string = inputNumber.toString();
    
        // Agrega ceros a la izquierda hasta que tenga 3 caracteres
        while (paddedNumber.length < 3) {
            paddedNumber = '0' + paddedNumber;
        }
    
        return paddedNumber;
    }

    loadAudio() {
        this.audioId = this.padNumberToThreeCharacters(this.hearingText.IdHearingText);
        let soundUrl = environment.filesUrl + this.hearingTextService.subDirectory + this.audioId + ".mp3"
        this.audio.src = soundUrl;
        this.audio.preload = "auto";
    }

    escuchar() {
        if (this.speaking) return;
        let playPromise = this.audio.play();
        if (playPromise !== undefined) {
            playPromise.then(_ => {
                this.speaking = true;
            }).catch(error => {
                this.speaking = false;
            });
        }
        this.audio.onended = () => {
            this.speaking = false;
        }
        return;
    }

    errorCallback(error: any) {
        this.loading = false;
        if (error == "TooOld") {
            let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
                disableClose: true
            });
            confirmDialog.componentInstance.title = this._translateService.instant('STUDENT.HEARING-TEXT.START-HEARING-DIALOG-TITLE');
            confirmDialog.componentInstance.message1 = this._translateService.instant('STUDENT.HEARING-TEXT.CANT-RECOVER-HEARING-FROM-DATE');
            confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
            confirmDialog.componentInstance.margin = false;
            confirmDialog.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                this.router.navigate(['/student/hearing-text']);
                confirmDialog = null;
            });
            return;
        }
        else {
            this._logService.logMessage(`Auditiva, error del backend ${JSON.stringify(error)}. Registro de pasos -> ${JSON.stringify(this._loggingSteps)}`, LogEventLevel.Error, true).subscribe();
            this.finish();
        }
    }

    check() {
        if (this.questionIndex == -1) return true;
        return this.form.valid;
    }

    save() {
        let answer = this.hearingText.Questions[this.questionIndex].Answers.find(x => x.IdAnswer == this.form.value.IdAnswer)
        this.loading = true;
        this._detector.detectChanges();

        this.hearingTextService.answer(this.hearingText.IdHearingText, this.form.value.IdQuestion, this.form.value.IdAnswer, this.authService.currentUser.Id, answer.IsCorrect)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result : boolean) => {
                if (!result) {
                    this._showDialog('Atención', 'Ha ocurrido un problema al guardar la respuesta. La actividad se cerrará.');
                    this._logService.logMessage(`Comprensión, error. { logDePasos: ${JSON.stringify(this._loggingSteps)} }`, LogEventLevel.Error, true).subscribe();

                    return;
                }

                const numQuestions = this.hearingText.Questions.length - 1;
                this._loggingSteps.push(`Se guarda correctamente la pregunta con questionIndex ${this.questionIndex} de ${numQuestions}`);

                if (!answer.IsCorrect) this.walinwos -= 10;

                if (this.questionIndex == numQuestions) {
                    this._loggingSteps.push(`Se han respondido todas las preguntas. Se va a obtener el Score`);
                    this.getScore(answer.IsCorrect);
                
                } else if (this.questionIndex < numQuestions) {
                    this.loading = false;
                    this.correct = answer.IsCorrect;

                } else {
                    this.correct = answer.IsCorrect;
                    this._loggingSteps.push(`Auditiva, error en indice: metodo save() indice ${this.questionIndex} total ${numQuestions} ID ${this.hearingText.IdHearingText}`);
                    this._logService.logMessage(`Auditiva, error. { logDePasos: ${JSON.stringify(this._loggingSteps)} }`, LogEventLevel.Error, true).subscribe();
                }                
            },
            error => {
                this.errorCallback(error);
            }
        );
    }

    getDate() {
        if (!this.hearingText) return "";
        let meses = [
            this._translateService.instant('MONTHS.JANUARY'),
            this._translateService.instant('MONTHS.FEBRUARY'),
            this._translateService.instant('MONTHS.MARCH'),
            this._translateService.instant('MONTHS.APRIL'),
            this._translateService.instant('MONTHS.MAY'),
            this._translateService.instant('MONTHS.JUNE'),
            this._translateService.instant('MONTHS.JULY'),
            this._translateService.instant('MONTHS.AUGUST'),
            this._translateService.instant('MONTHS.SEPTEMBER'),
            this._translateService.instant('MONTHS.OCTOBER'),
            this._translateService.instant('MONTHS.NOVEMBER'),
            this._translateService.instant('MONTHS.DECEMBER')
        ];
        let dayNames = [
            this._translateService.instant('DAYS.MONDAY'),
            this._translateService.instant('DAYS.TUESDAY'),
            this._translateService.instant('DAYS.WEDNESDAY'),
            this._translateService.instant('DAYS.THURSDAY'),
            this._translateService.instant('DAYS.FRIDAY'),
            this._translateService.instant('DAYS.SATURDAY'),
            this._translateService.instant('DAYS.SUNDAY'),
        ];
        let d = new Date(this.selectedDay);
        let dia = d.getDate();
        let mes = d.getMonth();
        let ano = d.getFullYear();
        let dayIndex = d.getDay() - 1;
        if (dayIndex == -1) dayIndex = 6;
        let dayName = dayNames[dayIndex];
        return `${dayName}, ${dia} de ${meses[mes]}`;
    }

    showMessage() {
        this.confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });
        this.confirmDialog.componentInstance.title =  this._translateService.instant('STUDENT.HEARING-TEXT.PLAY.COMPREHENSION-LABEL');
        this.confirmDialog.componentInstance.message1 = this._translateService.instant('STUDENT.TEXT-EXERCISES.RESTART-CONTINUE-ADVICE');
        this.confirmDialog.componentInstance.space = true;
        this.confirmDialog.componentInstance.margin = false;
        this.confirmDialog.componentInstance.options = [{
            text: this._translateService.instant('STUDENT.TEXT-EXERCISES.RESTART-BUTTON'),
            callback: () => {
                this.questionIndex = -1;
                this._loggingSteps.push(`Reiniciar: questionIndex inicial: ${this.questionIndex}`);
                this.retry = true;
            }
        }, {
            text: this._translateService.instant('CONTINUE'),
            callback: () => {
                this.questionIndex = this.hearingText.Questions.findIndex(x => x.Answers.every(x => !x.UserAnswer));
                this._loggingSteps.push(`Continuar: questionIndex inicial: ${this.questionIndex}`);
                this.form.get('IdQuestion').setValue(this.hearingText.Questions[this.questionIndex].IdQuestion);
            }
        }];    
        this.confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
            result();
            this.confirmDialog = null;
        });
    }

    next() {
        this.audio.pause();
        if (this.questionIndex > -1 && !this.check()) return;

        if (!this.loading && this.correct !== true && this.correct !== false) {
            this.loading = true;
            this.correct = null;
            this._detector.detectChanges();

            switch (this.questionIndex) {
                case -1:
                    this.goNext(true);

                    break;
            
                case 0:
                    if (this.retry) {
                        this.restart();
                    } else {
                        this.start();
                    }

                    break;
                default:
                    this.save();

                    break;
            }
        }
    }

    checkUserAnswers() {
        this.TotalOkUserAnswers = 0;
        this.TotalUserAnswers = 0;
        this.hearingText.Questions.forEach(pregunta => {
            pregunta.Answers.forEach(respuesta => {
                if (respuesta.UserAnswer) {
                    this.TotalUserAnswers++;
                    if (respuesta.IsCorrect) {
                        this.TotalOkUserAnswers++;
                    }
                }
            })
        });
    }

    restart() {
        this.loading = true;
        this._detector.detectChanges();
        this.checkUserAnswers();
        this._loggingSteps.push(`Se intenta reiniciar el ejercicio con TotalUserAnswers ${this.TotalUserAnswers} e IdAnswer ${this.form.value.IdAnswer} y TotalOkUserAnswers ${this.TotalOkUserAnswers}`);

        this.hearingTextService.restart(this.hearingText.IdHearingText, this.authService.currentUser.Id, this.TotalUserAnswers, this.TotalOkUserAnswers, this.hearingText.Retries)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._loggingSteps.push(`Se reinicia correctamente el ejercicio`);
                this.save();
            },
            error => {
                this.errorCallback(error);
            });
    }

    start() {
        this.loading = true;
        this._detector.detectChanges();
        let answer = this.hearingText.Questions[this.questionIndex].Answers.find(x => x.IdAnswer == this.form.value.IdAnswer);
        this._loggingSteps.push(`Se intenta iniciar el ejercicio con IdQuestion ${this.hearingText.Questions[this.questionIndex].IdQuestion} e IdAnswer ${this.form.value.IdAnswer} correspondiente al questionIndex ${this.questionIndex}`);

        this.hearingTextService.start(this.hearingText.IdHearingText, this.authService.currentUser.Id, this.hearingText.date, this.hearingText.Questions.length, this.hearingText.Questions[this.questionIndex].IdQuestion, this.form.value.IdAnswer, answer.IsCorrect)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: boolean) => {
                if (!result) {
                    this._showDialog(this._translateService.instant('STUDENT.TEXT-EXERCISES.WARNING-TEXT'), this._translateService.instant('STUDENT.TEXT-EXERCISES.ERROR-SAVING-ANSWER-TEXT'));
                    this._logService.logMessage(`Comprensión, error. { logDePasos: ${JSON.stringify(this._loggingSteps)} }`, LogEventLevel.Error, true).subscribe();

                    return;
                }

                const numQuestions = this.hearingText.Questions.length - 1;
                this._loggingSteps.push(`Se inicia correctamente el ejercicio con questionIndex ${this.questionIndex} de ${numQuestions}`);

                if (!answer.IsCorrect) this.walinwos -= 10;
                
                if (this.questionIndex == numQuestions) {
                    this.getScore(answer.IsCorrect);

                } else if (this.questionIndex < numQuestions) {
                    this.loading = false;
                    this.correct = answer.IsCorrect;

                } else {
                    this.correct = answer.IsCorrect;
                    this._loggingSteps.push(`Auditiva, error en indice: metodo start() indice ${this.questionIndex} total ${numQuestions} ID ${this.hearingText.IdHearingText}`);
                }
            },
            error => {
                this.errorCallback(error);
            })
    }

    wasSelected(answer: any) {
        return answer.UserAnswer;
    }

    goNext(forceExecution: boolean) {
        if (!this.loading || forceExecution) {
            this.loading = true;
            this.correct = null;
            this._detector.detectChanges();

            if (forceExecution)
                this._loggingSteps.push(`goNext: el origen de la llamada es interno`);
            else
                this._loggingSteps.push(`goNext: el origen es la llamada es el botón del resultado de la pregunta`);

            if (this.questionIndex == this.hearingText.Questions.length - 1) {
                this.showScore();
            } else {
                this.questionIndex++;
                this._loggingSteps.push(`goNext: questionIndex pasa a valer: ${this.questionIndex}`);
                let question = this.hearingText.Questions[this.questionIndex];

                if (!question) {
                    this._loggingSteps.push(`Auditiva, error en indice: metodo goNext() indice ${this.questionIndex} total ${this.hearingText.Questions.length - 1} ID ${this.hearingText.IdHearingText}`);
                    this._logService.logMessage(`Auditiva, error. { logDePasos: ${JSON.stringify(this._loggingSteps)} }`, LogEventLevel.Error, true).subscribe();
                    this.form.patchValue({ IdQuestion: '', IdAnswer: '' });
                    this.form.disable();
                    this.loading = false;

                    return;
                }

                this.hearingText.Questions[this.questionIndex].Answers = this.shuffle(this.hearingText.Questions[this.questionIndex].Answers);
                this.form.patchValue({ IdQuestion: question.IdQuestion, IdAnswer: "" });
                this.loading = false;
            }
        }
    }

    shuffle(array) {
        let currentIndex = array.length, temporaryValue, randomIndex;
        while (0 !== currentIndex) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array;
    }

    getScore(answerIsCorrect: boolean) {
        this.loading = true;
        this.form.disable();
        this._detector.detectChanges();

        this.hearingTextService.getScore(this.hearingText.IdHearingText, this.authService.currentUser.Id, this.hearingText.date)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
            result => {
                this.sharedService.changeUserCoins(true);

                if (result.nuevoFondo) {
                    this.nuevoFondo = true;
                }

                this.score = result;
                this.correct = answerIsCorrect
                this.loading = false;
            },
            error => {
                this.errorCallback(error);
            }
        )
    }

    finish() {
        this.audio.pause();
        if (this.navigationService.params.parentReview) {
            this.navigationService.go("/school/parentStudents/review/hearingTexts", {
                IdUser: this.navigationService.params.IdUser,
                student: this.navigationService.params.student,
            });
            return;
        } else if (this.teacherReview && !this.navigationService.params.isAdmin) {
            if (location.href.includes("student/")) {
                this.hearingTextService.changeReloadMonthlyHearing(true);
                this.navigationService.go("/student/hearing-text");
                return
            }
            if (this.navigationService.params.IdUser) {
                this.navigationService.go('/school/session/' + this.navigationService.params.IdUser + '/' + this.navigationService.params.IdClass + '/hearingReview/' + this.navigationService.params.IdUser + '/' + this.navigationService.params.IdClass, { IdClass: this.navigationService.params.IdClass, navigate: 'hearing', IdUser: this.navigationService.params.IdUser });
            } else {
                this.navigationService.go("/school/activities/hearing");
            }
        } else if (this.teacherReview && this.navigationService.params.isAdmin) {
            this.navigationService.go('/school/admin/students/null/sessionAdmin/' + this.navigationService.params.IdUser + '/' + this.navigationService.params.IdClass + '/hearingReview/' + this.navigationService.params.IdUser + '/' + this.navigationService.params.IdClass, { IdUser: this.navigationService.params.IdUser, IdClass: this.navigationService.params.IdClass, navigate: 'hearing', isAdmin: true });
        } else {
            this.hearingTextService.changeReloadMonthlyHearing(true);
            this.navigationService.go("/student/hearing-text");
        }
    }
    ParseFragment() {
        const text = this.hearingText.Fragment;
        const paragraphs = text.split(/\r\n|\r|\n/);
        const htmlParagraphs = paragraphs.map(paragraph => `<p>${paragraph}</p>`);
        const htmlText = htmlParagraphs.join('');
        this.hearingText.Fragment = htmlText;
    }

    showScore() {
        this.correct = null;
        this.finished = true;
        setTimeout(() => {
            this.messages = [
                this._translateService.instant('STUDENT.TEXT-EXERCISES.CORRECT-COUNT', {correct:this.score.NumOk, total:this.score.NumExercises})
            ];
            if (this.score.Retries > 0) this.messages.push(this._translateService.instant('STUDENT.TEXT-EXERCISES.REPEATING-PENALTY'));
        }, 1000)
    }

    getBookName(book) {
        if (book.charAt(book.length - 1) == "!" || book.charAt(book.length - 1) == "?") {
            return book;
        }
        else {
            return book + ".";
        }
    }

    private _showDialog(title: string, message: string): void {
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });
        confirmDialog.componentInstance.title = title;
        confirmDialog.componentInstance.message1 = message;
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
        confirmDialog.componentInstance.margin = false;
        confirmDialog.afterClosed()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(result => {
            this.router.navigate(['/student/hearing-text']);
            confirmDialog = null;
        });
    }
}
