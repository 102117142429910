<div class="walinwa-box walinwa-box-green" *ngIf="navigationService.StateTest !='review'">
    <div class="walinwa-box-content" *ngIf="navigationService.StateTest=='classes-personalizedTests'">
        <div>
            <div class="ww_table_rwd">
                <table class="walinwa-table">
                    <thead>
                        <tr>
                            <th width="">{{'SCHOOL.TEACHER.TRACKING.PERSONALIZED-TEST.DATE' | translate}}</th>
                            <th width="">{{'CLASS' | translate}}</th>
                            <th width="">{{'SCHOOL.TEACHER.TRACKING.PERSONALIZED-TEST.NAME' | translate}}</th>
                            <th width="">{{'SCHOOL.TEACHER.TRACKING.PERSONALIZED-TEST.STATE' | translate}}</th>
                            <th width="" class="text-center">{{'SCHOOL.TEACHER.TRACKING.PERSONALIZED-TEST.AUTHORIZED' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="!loading">
                            <tr *ngFor="let class_personalizedTest of sortData"
                                (click)="selectPersonalizedTest(class_personalizedTest)"
                                [ngClass]="{ selected: class_personalizedTest.selected }">
                                <td>{{ class_personalizedTest.formatted_date }}
                                </td>
                                <td>
                                    <span *ngIf="class_personalizedTest.Aula">{{ class_personalizedTest.Aula
                                        }}</span>
                                    <span *ngIf="!class_personalizedTest.Aula">{{ class_personalizedTest.IdClass
                                        }}</span>
                                </td>
                                <td>
                                    <span *ngIf="class_personalizedTest.Name">{{ class_personalizedTest.Name
                                        }}</span>
                                    <span *ngIf="!class_personalizedTest.Name">{{ class_personalizedTest.IdTest
                                        }}</span>
                                </td>
                                <td>
                                    <span>{{ class_personalizedTest.TestState }}</span>
                                </td>
                                <td class="text-center">
                                    <mat-checkbox (click)="activateReview(class_personalizedTest)"
                                        [checked]="class_personalizedTest.TestOpen">

                                    </mat-checkbox>
                                </td>
                            </tr>
                        </ng-container>
                        <tr *ngIf="classes_personalizedTests?.length==0">
                            <td class="font_blue" colspan="5">{{'SCHOOL.TEACHER.TRACKING.PERSONALIZED-TEST.NO-TEST' | translate}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="walinwa-row spe_gap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center">
            <button (click)="goList()" [disabled]="loading || !anySelected()" class="new_but azul">
                {{'SCHOOL.TEACHER.TRACKING.PERSONALIZED-TEST.SHOW-TEST' | translate}}
            </button>
        </div>
    </div>

    <div class="walinwa-box-content" *ngIf="navigationService.StateTest=='list'">
        <div>
            <div *ngIf="loading" class="walinwa-box-content-loading">
                <img src="https://walinwa.blob.core.windows.net/images/loading.gif?ngsw-bypass=true" />
            </div>
            <div class="ww_table_rwd">
                <table class="walinwa-table">
                    <thead>
                        <tr>
                            <th width="">{{'SCHOOL.TEACHER.TRACKING.PERSONALIZED-TEST.STUDENT-NAME' | translate}}</th>
                            <th width="">{{'SCHOOL.TEACHER.TRACKING.PERSONALIZED-TEST.STATE' | translate}}</th>
                            <th width="">{{'SCHOOL.TEACHER.TRACKING.PERSONALIZED-TEST.SCORE' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container>
                            <tr *ngFor="let resultado of resultList" (click)="selectUser(resultado)"
                                [ngClass]="{ selected: resultado.selected }">
                                <td>
                                    <span *ngIf="!resultado.Name">{{ resultado.IdUser }}</span>
                                    <span *ngIf="resultado.Name">{{ resultado.Name }}</span>
                                </td>
                                <td>
                                    <span> {{resultado.TestState}}</span>
                                </td>
                                <td>
                                    <span *ngIf="resultado.Score != null">{{ resultado.Score }}</span>
                                </td>
                            </tr>
                        </ng-container>
                        <tr *ngIf="classes_personalizedTests?.length==0">
                            <td class="font_blue" colspan="3">{{'SCHOOL.TEACHER.TRACKING.PERSONALIZED-TEST.NO-ASSIGNED-TEST' | translate}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="walinwa-row spe_gap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center">
            <button (click)="reviewUserResult()" [disabled]="loading || !anyUserSelected()" class="new_but azul">
                {{'SCHOOL.TEACHER.TRACKING.PERSONALIZED-TEST.REVIEW-TEST' | translate}}
            </button>
        </div>
    </div>
</div>
<personalized-test 
*ngIf="personalizedTestService.doTest"
[review]="true" 
[teacherReview]="true" 
[IdTest]="selectedTest.IdTest" 
[date]="selectedTest.Date"
[Score]="selectedUser.Score"
[IdUser]="selectedUser.IdUser">
</personalized-test>