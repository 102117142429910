<div fxLayout="column" fxLayoutAlign="center center" style="width: 94%;" [hidden]="mapHidden">
    <div class="map">
        @if (showNextBookMessage) {
            <div class="next-book" [style.background-image]="nextBookBackgroundStyle" [innerHtml]="nextBookMessage"></div>
        }
        <div class="map-inner" #mapInner>
            <div class="header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="0.5rem" [ngStyle]="{'width': mapInner.getBoundingClientRect().width + 'px'}" style="position: absolute;">
                <div style="font-size: 120%;" fxFlex="auto" fxFlex.xs="200px" fxLayout="column wrap" fxLayoutAlign="space-between start">
                    @if (drawSections) {
                        <div style="font-style: italic;">{{book.Title}}</div>
                        <div>({{book.Author}})</div>
                    }
                </div>
            </div>
            
            <img class="map-inner-img" [src]="fondoImg" (load)="onLoadMap.next()">
            <img #fire class="fuego" src="https://walinwadevfr.blob.core.windows.net/images/WaliLee/fuego.gif?ngsw-bypass=true" style="z-index: -99999" (load)="onLoadFire.next()">

            @if (drawSections) {
                @for (section of mapSections; track section) {
                    <map-section class="map-section-component" [ngClass]="{'current': section.Current}" [book]="book" [section]="section" [mapHeight]="764" [mapWidth]="1024" [mapInner]="mapInner" (onApprobed)="updateMap($event, true)" (onSuspended)="updateMap($event, false)" (onModalShown)="hideMap($event)"></map-section>
                }
            }
        </div>
    </div>
</div>

@if (showLastMessage) {
    <plank-walilee [title]="book.Title" (onClose)="lastMessageClose()"></plank-walilee>
}

@if (drawSections && mapSectionTemplate) {
    <ng-container *ngTemplateOutlet="mapSectionTemplate"></ng-container>
}