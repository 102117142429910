import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpParams, HttpEvent, HttpHeaderResponse, HttpResponse, HttpErrorResponse, HttpUserEvent, HttpProgressEvent } from '@angular/common/http';
import { Location } from '@angular/common';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';

@Injectable()
export class DailyTextService {

  baseUrl = environment.baseApi + '/api/DailyText';
  dailyText = null
  dailyTextScore : any;

  constructor(private http: HttpClient) { }

  getCurrent() {
    return this.http.get(this.baseUrl + '/');
  };

  getWeekResult() {
    return this.http.get(this.baseUrl + '/weekResult/');
  };
  getDailyTextDay(){
    return this.http.get(this.baseUrl + '/dailyTextDay/');
  }
  getCurrentScore(idUser, idDailyText) {
    return this.http.get(this.baseUrl + '/idUser/' + idUser + '/idDailyText/' + idDailyText);
  };

  addCurrentScore(idUser, idDailyText, score) {
    var offset = -(new Date().getTimezoneOffset() / 60);
    var params = { timeZone: offset };
    return this.http.post(this.baseUrl + '/idUser/' + idUser + '/idDailyText/' + idDailyText + '/score/' + score, params);
  };
}
