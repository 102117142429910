<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema"
        [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="marca-t spe_gap" fxLayout="column" fxLayoutAlign="stretch"
    [ngStyle]="{'pointer-events': review ? 'none;' : ''}">

    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <!-- <span [innerHtml]="exercise.enunciado"></span> -->
            <span [innerHtml]="parseEnunciado()"></span>
        </div>
    </div>

    <!-- 2da Caja de mensaje -->
    <div class="ruleContainer" [hidden]="parseEnunciadoRegla() == exercise.enunciado || !parseEnunciadoRegla()">
        <div class="exerciseRulenew" [ngClass]="{'azul': azul()}">
            <span class="regla" [innerHtml]="parseEnunciadoRegla()"></span>
        </div>
    </div>

    <div id="space-for-text" class="wordsContainer">
        <div class="items_container">
            <div class="phraseBox phrase" [ngClass]="{
                phraseBoxOk: answered == true,
                phraseBoxError: answered == false && show_errors != null
            }">
                <span *ngFor="let word of exercise.palabras; let i = index" id="{{ i }}" (click)="validateClick(i)"
                    class="subPhrase" [ngClass]="{
                    wordOk: word.answered && word.opcion == 'T',
                    wordError: word.answered && word.opcion == 'F',
                    wordNotAnswered: !word.answered && word.opcion == 'T' && show_errors == null
                }">{{ word.palabra }}
                </span>
                <div id="author" class="authorBox">
                    {{ exercise.libro
                    }}<span class="authorInfo"> {{ exercise.autor }}</span>
                </div>
            </div>
        </div>
    </div>
</div>