import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from 'app/core/shared/shared.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {

  @Input() info: String;
  @Input() position: String;
  @Input() color: String;
  showing: Boolean;

  constructor(public sharedService: SharedService) { 
    this.showing = false;
    if(!this.position) this.position = "L";
    if(!this.color) this.color = "gray";
  }

  ngOnInit() {}

  toggle() {
    this.showing = !this.showing;
  }

}
