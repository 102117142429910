import { ChangeDetectorRef, Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from 'app/services/course.service';
import { NavigationService } from 'app/services/navigation.service';
import { SchoolService } from 'app/services/school.service';
import { UserService } from 'app/services/user.service';
import * as moment from 'moment';
import 'moment/locale/es';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { AvatarShopService } from 'app/services/avatar-shop.service';
import * as htmlToImage from 'html-to-image';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { UpdateStudent } from 'app/core/shared/state/models/User/update-student.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-parent-students',
  templateUrl: './edit-parent-students.component.html',
  styleUrls: ['./edit-parent-students.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditParentStudentsComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  student;
  formDataStudent : UntypedFormGroup;
  currentUser;
  showStudentId = false;
  smallSessionOptions = [
    { name: 'Sesión por defecto', value: false },
    { name: 'Sesión con menos ejercicios', value: true }
  ];
  blockNameOptions = [
    { name: 'Modificable', value: false },
    { name: 'Bloqueado', value: true }
  ];
  blockContactOptions = [
    { name: 'Activado', value: false },
    { name: 'Bloqueado', value: true }
  ];
  sliderSession = {
    value: 0,
    options: {
      floor: 0,
      ceil: 1,
      step: 1,
      hideLimitLabels: true,
      hidePointerLabels: true
    }
  };
  sliderExtraTime = {
    value: 0,
    options: {
      floor: 0,
      ceil: 5,
      step: 1,
      hideLimitLabels: true,
      hidePointerLabels: true
    }
  };
  sliderBlockName = {
    value: 0,
    options: {
      floor: 0,
      ceil: 1,
      step: 1,
      hideLimitLabels: true,
      hidePointerLabels: true
    }
  };
  sliderBlockContact = {
    value: 0,
    options: {
      floor: 0,
      ceil: 1,
      step: 1,
      hideLimitLabels: true,
      hidePointerLabels: true
    }
  };
  dataLoaded = false;
  classes = [];
  courses;
  extraFields = false;
  multiTimes = [
    {
      value: 1,
      name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-DEFAULT')
    },
    {
        value: -1,
        name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-NO-TIME')
    },
    {
      value: 1.5,
      name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-MODERATE')
    }, {
      value: 2,
      name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-MEDIUM')
    }, {
      value: 2.5,
      name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-HIGH')
    }, {
      value: 3,
      name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-HIGHER')
    }, {
      value: 4,
      name: this._translateService.instant('SCHOOL.TEACHER.MULTIMES-MAX')
    }
  ];

  maxDate;
  availableAvatarItems;
  boughtItems;
  activeItems;
  isBackground = false;
  regExp = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜäÄëËïÏöÖç\s\.\-'ª’]+$/u
  constructor(
    private activateRoter: ActivatedRoute,
    private userService: UserService,
    private _formBuilder: UntypedFormBuilder,
    private router: Router,
    private courseService: CourseService,
    private schoolService: SchoolService,
    private navigationService: NavigationService,
    private dateAdapter: DateAdapter<Date>,
    private matDialog: MatDialog,
    private avatarShopService: AvatarShopService,
    private changeDetectorRef: ChangeDetectorRef,
    private _translateService: TranslateService
  ) {
    this._unsubscribeAll = new Subject();
    moment.locale('es');
    this.dateAdapter.setLocale("es-ES");
  }

  ngOnDestroy(): void
   {
       // Unsubscribe from all subscriptions
       this._unsubscribeAll.next();
       this._unsubscribeAll.complete();
   }

  ngOnInit() {
    if (!sessionStorage.getItem("currentUser")) return this.navigationService.exit();
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (!this.currentUser) return this.navigationService.exit();
    this.navigationService.enableBackButton(true, this.back.bind(this));
    this.maxDate = new Date();
    this.courseService.Courses.pipe(takeUntil(this._unsubscribeAll)).subscribe(courses => {
        this.courses = courses;
    })
    this.userService.getUserById(this.activateRoter.params['value'].IdUser)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetStudent.bind(this), this.errorCallback.bind(this));
    this.GetActiveItems();
  }

  back() {
    if (!this.extraFields) {
      this.navigationService.go("/school/parentStudents", {
        IdUser: this.student.Id
      })
    } else {
      this.changeExtraFields();
    }
  }

  successCallbackCreateUser() {
    var message = [];
    message[0] = "Edición de alumno"
    message[1] = "Alumno editado con éxito"
    let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
      disableClose: true
    });
    confirmDialog.componentInstance.title = message[0];
    confirmDialog.componentInstance.message1 = message[1];
    confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
    confirmDialog.componentInstance.margin = false;
    confirmDialog.componentInstance.margin = false;
    confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
      this.navigationService.go("/school/parentStudents", {
        IdUser: this.student.Id
      })
      confirmDialog = null;
    });
  }

  successGetStudent(response) {
    this.student = response;
    if (this.student.MultTime) {
      this.student.MultTime = this.student.MultTime;
    } else {
      this.student.MultTime = 1;
    }
    this.formDataStudent = this._formBuilder.group({
      Name: [this.student.Name,[Validators.required, Validators.pattern(this.regExp), Validators.maxLength(40)]],
      FamilyName: [this.student.FamilyName, [Validators.pattern(this.regExp), Validators.maxLength(50)]],
      Username: [this.student.UserName, [Validators.required, Validators.minLength(6), Validators.maxLength(50),Validators.pattern(/^[0-9A-Za-zñÑ\.]+$/)]],
      Alias: [this.student.Alias,[Validators.maxLength(50)]],
      Level: [this.student.Level],
      DateOfBirth: [this.student.DateOfBirth, [Validators.required]],
      MultTime: [this.student.MultTime]
    });
      this.formDataStudent.markAllAsTouched();
    if (this.student.SmallSession != null) {
      for (var i = 0; i < this.smallSessionOptions.length; i++) {
        if (this.smallSessionOptions[i].value == this.student.SmallSession)
          this.sliderSession.value = i;
      }
    }
    else {
      this.sliderExtraTime.value = 0;
      this.sliderSession.value = 0;
    }
    if (this.student.BlockName !== null) {
      for (var i = 0; i < this.blockNameOptions.length; i++) {
        if (this.blockNameOptions[i].value === this.student.BlockName)
          this.sliderBlockName.value = i;
      }
    }
    if (this.student.BlockContact !== null) {
      for (var i = 0; i < this.blockContactOptions.length; i++) {
        if (this.blockContactOptions[i].value === this.student.BlockContact)
          this.sliderBlockContact.value = i;
      }
    }
    this.userService.getClassesByIdTutor(this.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetClasses.bind(this), this.errorCallback.bind(this));
  }

  successGetClasses(response) {
    this.classes = response;
    if (this.classes.length == 0) {
      this.classes = null;
    }
    this.dataLoaded = true;
  }

  errorCallback() {
    this.navigationService.exit();
  }

  changeExtraFields() {
    this.extraFields = !this.extraFields;
    this.schoolService.extrafields = this.extraFields;
  }

  formatDate(date) {
    return moment(date).hour(moment().hours());
  }

  updateStudent() {
    let userNameLenghtRegex = /^.{6,50}$/;
    var userNameRegex = /^[0-9A-Za-záéíóúÁÉÍÓÚñÑ\.]+$/;
    var validUsername = this.formDataStudent.value.Username.match(userNameLenghtRegex);
    if (validUsername == null && this.student.UserName.length >= 6) {
      this.formDataStudent.controls['Username'].setErrors({ 'length': true });
      return;
    }
    validUsername = this.formDataStudent.value.Username.match(userNameRegex);
    if (validUsername == null) {
      this.formDataStudent.controls['Username'].setErrors({ 'invalidName': false });
      return;
    }
    if(this.formDataStudent.value.Alias.length >= 50){
        let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
          });
          confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.MODAL-ERROR-EDIT-STUDENT-TITLE');
          confirmDialog.componentInstance.message1 = this._translateService.instant('ALIAS-MAX');
          confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
          confirmDialog.componentInstance.margin = false;
          confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
            confirmDialog = null;
          });
          return;
    }
      let user: UpdateStudent = {
          Id: this.student.Id,
          Name: this.formDataStudent.value.Name,
          FamilyName: this.formDataStudent.value.FamilyName,
          Alias: this.formDataStudent.value.Alias,
          IdClass: this.formDataStudent.value.IdClass,
          MultTime: this.formDataStudent.value.MultTime,
          Level: this.formDataStudent.value.Level,
          UserName: this.formDataStudent.value.Username,
          SmallSession: this.student.SmallSession,
          BlockName: this.student.BlockName,
          BlockAlias: this.student.BlockAlias,
          BlockContact: this.student.BlockContact,
          GiftShopDisabled: this.student.GiftShopDisabled,
          BlockMessageGift: this.student.BlockMessageGift,
          AvatarDisabled: this.student.AvatarDisabled,
          DateofBirth: this.formatDate(this.formDataStudent.controls['DateOfBirth'].value)
      };
    this.userService.updateUserProfile(user)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successCallbackCreateUser.bind(this), this.errorCallbackCreateUser.bind(this));
  
  }

  errorCallbackCreateUser(error) {
    switch (error.error) {
      case "username":
        this.formDataStudent.controls['Username'].setErrors({ 'unique': true });
        break;
      default:
        this.errorCallback();
        break;
    }
  }

    GetActiveItems(): void {
        this.avatarShopService.GetActiveItemsByIdUser(this.activateRoter.params['value'].IdUser)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {
                this.activeItems = result;
            })
    }
  saveAvatar(){
    this.isBackground = true;
    let name = this.formDataStudent.value.Username;
    setTimeout(() => {
      htmlToImage.toPng(document.getElementById('avatar-download'))
    .then(function (dataUrl) {
      var link = document.createElement('a');
       link.download = name +'.png';
       link.href = dataUrl;
       link.click();
    });
    }, 1000);
    setTimeout(() => {
      this.isBackground = false;
    }, 1000);
    
  }

}
