import { CalendarDeduction } from './../../../../../core/shared/state/models/deduction/deduction-month.model';
import { ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CalDeductionTextComponent } from 'app/components/cal-deduction-text/cal-deduction-text.component';
import { DeductionTextService } from 'app/services/deduction-text.service';
import { NavigationService } from 'app/services/navigation.service';
import { UserService } from 'app/services/user.service';
import * as moment from 'moment';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
declare var $;
@Component({
    selector: "app-deduction",
    templateUrl: "./deduction.component.html",
    styleUrls: ["./deduction.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class DeductionReviewComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
  @ViewChild(CalDeductionTextComponent) child: CalDeductionTextComponent;
  six_weeks_deduction
  selectedDayDT: any;
  month: any;
  year: any;
  currentMonthDT: any;
  student: any;
  deductionTexts: CalendarDeduction[];
  isAdmin: boolean;
  activeButton = false;

  constructor(
    private userService: UserService,
    private navigationService: NavigationService,
    private deductionService: DeductionTextService,
    private router: Router,
    private ref: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute
  ) { 
    this._unsubscribeAll = new Subject();
}

ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit() {
        this.selectedDayDT = moment();
        this.year = this.selectedDayDT.year();
        this.currentMonthDT = this.selectedDayDT.month() + 1;
        this.getUser(this.activatedRoute.params["value"].Id);
        if (this.navigationService.params.isAdmin)
            this.isAdmin = this.navigationService.params.isAdmin;
    }

  getUser(id) {
    this.userService.getUserById(id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        if (result == null) return this.navigationService.exit();
        this.student = result;
        this.getDeductions();
      },
      this.errorCallback.bind(this)
    )
  }

   getDeductions() {
    this.deductionTexts = [];
    this.ref.detectChanges();
    this.deductionService.getDeductionTextByMonth(this.student?.Id, this.currentMonthDT, this.selectedDayDT.year(), this.student?.Level, this.student?.IdRole, this.student?.CreationDate)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(
      result => {
        if (result == null) return this.navigationService.exit();
        this.deductionTexts = result;
        this.deductionService.MonthlyDeduction = result;
        this.child.refresh((weeks_count) => {
          this.six_weeks_deduction = weeks_count >= 6
      })
      },
      this.errorCallback.bind(this)
    )
  }

    selectedDayChangedDT(newDate) {
        this.selectedDayDT = newDate;
        if (!this.deductionTexts) return;
        if (this.currentMonthDT != this.selectedDayDT.month() + 1) {
            this.currentMonthDT = this.selectedDayDT.month() + 1;
            if (this.child)
                this.six_weeks_deduction = this.child.weeks.length >= 6;
            this.getDeductions();
        }

        let currentDeductionText = this.deductionTexts.find((r) =>
            moment(r.date).isSame(this.selectedDayDT)
        );
        if (!currentDeductionText) return;
        if (currentDeductionText.Score == null) return;
        if (currentDeductionText.FinishDate) {
            this.activeButton = true;
        }
            
    }

    startReview() {
        if (!this.deductionTexts) return;
        let currentDeductionText = this.deductionTexts.find((r) =>
            moment(r.date).isSame(this.selectedDayDT)
        );
        if (!currentDeductionText) return;
        this.navigationService.go(
            this.router.url + "/deduction/review",
            {
                isAdmin: this.isAdmin,
                IdUser: this.activatedRoute.params['value'].Id,
                IdClass: this.navigationService.params.IdClass,
                review: true,
                date: currentDeductionText.date,
                IdDeduction: currentDeductionText.IdDeduction,
                selectedDay: this.selectedDayDT
            }
        );
    }

    errorCallback() {
        this.navigationService.exit();
    }

    sixWeeks() {
        return $(".week").length - 1 >= 6;
    }
}
