import { NavigationService } from 'app/services/navigation.service';
import { Component, OnInit, ViewEncapsulation, HostListener, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { UiService } from '@fuse/components/spinner/ui.service';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

import { AccountService } from '../account.service';
import { IUserInfo } from '../user-info';
import { environment } from 'environments/environment';
import { SharedService } from 'app/core/shared/shared.service';
import { RepositoryService } from '../../../core/repository/repository.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'app/services/user.service';
import { AuthService } from 'app/services/auth.service';
import { AppService } from 'app/app.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';


@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        '(window:resize)': 'onResize($event)'
    }
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    mobile: boolean = false;
 iPad = /iPad|Macintosh/i.test(navigator.userAgent); mobileDetect;
    errorMessage: boolean = false;
    resetPasswordForm: UntypedFormGroup;
    isSubmitted = false;
    idUser;
    token;

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _accountService: AccountService,
        private _router: Router,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        public _sharedService: SharedService,
        private _ui: UiService,
        private _repositoryService: RepositoryService,
        private _matDialog: MatDialog,
        private _translateService: TranslateService,
        private _activatedRoute: ActivatedRoute,
        private userService: UserService,
        private authService: AuthService,
        private navigationService: NavigationService,
        private appService: AppService,
        private activatedRoute: ActivatedRoute
    ) {
        this._unsubscribeAll = new Subject();
        this._ui.spin$.next(true);
        this.mobileDetect = this.appService.mobileDetect();
    }

    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        event.target.innerWidth;
        this.mobile = this._sharedService.isMobile();
    }

    ngOnInit(): void {

        this.mobile = this._sharedService.isMobile();
        this.resetPasswordForm = this._formBuilder.group({
            password1: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(10)]],
            password2: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(10)]]
        }, { validator: this.matchValidator });

        this.idUser = this._activatedRoute.params['_value'].idUser;
        this.token = this._activatedRoute.params['_value'].token;// + "==";
        // if (location.href.includes("resetpasswordintern")) {
        //     this.token = this._activatedRoute.params['_value'].token
        // }
    }

    matchValidator(group: UntypedFormGroup) {

        var valid = false;

        if (group.controls.password1.value == group.controls.password2.value) valid = true;

        if (valid) {
            return null;
        }

        return {
            mismatch: true
        };

    }

    submit(form) {
        if (!this.isSubmitted) {
            if (this.resetPasswordForm.valid) {
                this.isSubmitted = true;
                //console.log(this.token, "CfDJ8L5HWtGXyh1OvJmYwogS0FRLmCbOX6hof_Le2YYc-SxIgLoK0WBtsh1FkZuRt3qTwqo1lg31BwBtCSnvhd_CMKSsJnuHvItMfZiBFoD34l1twWJDaVI2Qvh8xJPJTttJ_92oGc4mBfKvu25Okx--mNWD8GGoVurGCG6c5dB-Ww8h_4IxV-4UKB763h3hn26FOw==")
                this.userService.ResetPassword(this.idUser, this.token, this.resetPasswordForm.value.password1).pipe(takeUntil(this._unsubscribeAll)).subscribe(_ => {
                    this._ui.stopSpinner();
                    this.authService.login(this.idUser, this.resetPasswordForm.value.password1, _ => {
                    }, error => {
                    });
                }, error => {
                    this._ui.stopSpinner()
                    this._router.navigate(['./request-reset-password'])
                });
            }
        }
    }
}
