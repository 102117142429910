import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { RedirectComponent } from './redirection.component';

const routes = [
    { path: '', component: RedirectComponent },

];

@NgModule({
    declarations: [
        RedirectComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule.forChild(),
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,

        FuseSharedModule
    ],
    exports: [RouterModule],
})
export class RedirectModule {
}