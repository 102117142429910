<div class="header">
    <i class="fa fa-angle-left" (click)="previous()"></i>
    <!-- <span>{{month.format("MMMM, YYYY")}}</span> --><span>{{month.format("MMMM")}} {{'COMPONENTS.CALENDAR.DATE-CONNECTOR' | translate }} {{month.format("YYYY")}}</span>
    <i class="fa fa-angle-right" (click)="next()"></i>
</div>
<div class="week names">
    <span class="day">L</span>
    <span class="day">M</span>
    <span class="day">X</span>
    <span class="day">J</span>
    <span class="day">V</span>
    <span class="day">S</span>
    <span class="day">D</span>
</div>
<div class="week" *ngFor="let week of weeks">
    <span class="day" 
    [ngClass]="
    {'isPast':isPast(day.date), 
    'isCustomized' : isCustomized(day.date) && !isExaminControl(day.date) && !isControlSessioninExam(day.date), 
    'isExaminControl' : isExaminControl(day.date)  && !isPast(day.date),
    'isControl' : isControlSession(day.date),
    'isControlinExam' : isControlSessioninExam(day.date)  && !isPast(day.date), 
    'isTest' : isPersonalizedTest(day.date), 
    'isExam' : isExam(day.date),
    'different-month': !day.isCurrentMonth || ((isPast(day.date) || day.isToday) && disablePast), 
    selected: day.date.isSame(selectedDay)}"
    (click)="select(day)" *ngFor="let day of week.days">{{day.number}}</span>
</div>