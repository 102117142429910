<div id="dialog-continue" class="content-dialog">
    <mat-dialog-content>
        <div>
            <h1 *ngIf="title" matDialogTitle>{{title}}</h1>
            <p [innerHtml]="message1"></p>
            <p style="margin-top: 1em" [ngClass]="{'margin-p': !margin}" class="" [innerHtml]="message2"></p>
            <p style="margin-top: 1em" [ngClass]="{'margin-p': !margin}" class="" [innerHtml]="message3"></p>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="pt-24 two fixed">
            <button mat-raised-button color="accent" class="submit-button"
                (click)="dialogRef.close(false)">{{textButton2}}</button>
            <button mat-raised-button color="accent" class="submit-button"
                (click)="dialogRef.close(true)">{{textButton1}}</button>
        </div>
    </mat-dialog-actions>
</div>