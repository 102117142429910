import { Component, OnInit, ViewEncapsulation, HostListener, Renderer2, Input, OnDestroy } from '@angular/core';
import { SharedService } from 'app/core/shared/shared.service';
import { ExerciseService } from 'app/services/exercise.service';

@Component({
  selector: 'ex-guionf',
  templateUrl: './guionf.component.html',
  styleUrls: ['./guionf.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GuionfComponent implements OnInit, OnDestroy {

  @Input() review
  @Input() show_errors;
  @Input() controlSession : boolean = false;
  @Input() slide : boolean = false; @Input() current;
  exercise
  draggingOption
  exerciseIsEnded = false
  finished = false
  tmpPhrases = []
  dropLevel

  elementsOk
  elementsErr
  showingSolution = false
    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case "SHOW_SOLUTION":
                this.finished = true;
                if (this.exerciseService.showingSolution) {
                    setTimeout(this.showAnswersTimeOut.bind(this), 100);
                    break;
                } else {
                    setTimeout(this.showSolution.bind(this), 100);
                    break;
                }
            case "SHOW_ANSWER":
            case "SHOW_CUSTOM":
                this.ShowAnswer();
                break;
        }
    }

  constructor(private exerciseService: ExerciseService) {
    this.exercise = this.exerciseService.getExerciseDefinition();
    this.DrawModel5()
  }
  ngOnDestroy() {
  }

  ngOnInit() {
    if (this.review) {
      this.elementsOk = this.getStandardJSON(JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsOK
      ))
      this.elementsErr = this.getStandardJSON(JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsERR
      ))

      if (this.show_errors == false) {
        this.showSolution();
      } else if (this.show_errors == true || this.show_errors == null) {
        this.ShowAnswer();
      }
    } else {
        console.log(this.exercise);
      this.initInteract();
    }
  }

  getStandardJSON(json) {
    if (this.isNewJSON(json)) {
      return this.getOldJSON(json);
    } else {
      return json;
    }
  }

  getOldJSON(json) {
    var e = this.exercise
    if (e.palabras)
      var palabras = (JSON.parse(JSON.stringify(e.palabras)));
    var aux = [];
    for (var i = 0; i < json.length; i++) {
      if (!Array.isArray(json[i]) && isNaN(json[i]) && json[i].split(";").length > 1) {
        var a = {};
        var letras = ['a', 'b', 'c', 'd', 'e', 'f'];
        for (var j = 0; j < json[i].split(";").length; j++) {
          a[letras[j]] = json[i].split(";")[j];
        }
        aux.push(a);
      } else {
        if (!Array.isArray(json[i]) && e.palabras)
          aux.push(palabras[json[i]]);
        else
          aux.push(json[i]);
      }
    }
    return aux;
  }

  isNewJSON(a) {
    if (a != null && a.length > 0)
      return true
    else
      return false;
  }

  ValidateExerciseBox(indiceFrase, idOption) {
    var optionText = "";
    var tmpPhrase = "";
    //Lets try with the first letter as capital letter
    var tmpPhraseCapital = "";
    var optionTextCapital = "";
    var indiceOption;
    if (this.tmpPhrases[indiceFrase].answered === true)
      return;

    for (var i = 0; i < this.exercise.opciones.length; i++) {
      if (this.exercise.opciones[i].id === idOption) {
        indiceOption = i;
        optionText = this.exercise.opciones[i].opcion;
        optionTextCapital = optionText.substring(0, 1).toUpperCase() + optionText.substring(1, optionText.length);
      }
    }


    for (var i = 0; i < this.tmpPhrases[indiceFrase].fraseGuion.length; i++) {
      for (var j = 0; j < this.tmpPhrases[indiceFrase].fraseGuion[i].fragmento.length; j++) {
        if (this.tmpPhrases[indiceFrase].fraseGuion[i].fragmento[j].texto == "_") {
          tmpPhrase += optionText;
          tmpPhraseCapital += optionTextCapital;
        }
        else {
          tmpPhrase += this.tmpPhrases[indiceFrase].fraseGuion[i].fragmento[j].texto;
          tmpPhraseCapital += this.tmpPhrases[indiceFrase].fraseGuion[i].fragmento[j].texto;
        }
      }
    }

    if (tmpPhrase == this.tmpPhrases[indiceFrase].frase) {

      for (var i = 0; i < this.tmpPhrases[indiceFrase].fraseGuion.length; i++) {
        for (var j = 0; j < this.tmpPhrases[indiceFrase].fraseGuion[i].fragmento.length; j++) {
          if (this.tmpPhrases[indiceFrase].fraseGuion[i].fragmento[j].texto == "_")
            this.tmpPhrases[indiceFrase].fraseGuion[i].fragmento[j].texto = optionText;
        }
      }


      this.tmpPhrases[indiceFrase].answered = true;
      // exerciseService.AddOk(indiceFrase + ";" + indiceOption);
      this.exerciseService.answer(indiceFrase, true, 100)
      for (var i = 0; i < this.tmpPhrases.length; i++) {
        if (this.tmpPhrases[i].answered != true) {
          return "";
        }
      }
      return "complete"
    }
    if (tmpPhraseCapital == this.tmpPhrases[indiceFrase].frase) {

      for (var i = 0; i < this.tmpPhrases[indiceFrase].fraseGuion.length; i++) {
        for (var j = 0; j < this.tmpPhrases[indiceFrase].fraseGuion[i].fragmento.length; j++) {
          if (this.tmpPhrases[indiceFrase].fraseGuion[i].fragmento[j].texto == "_")
            this.tmpPhrases[indiceFrase].fraseGuion[i].fragmento[j].texto = optionTextCapital;
        }
      }
      this.tmpPhrases[indiceFrase].answered = true;
      // exerciseService.AddOk(indiceFrase + ";" + indiceOption);
      this.exerciseService.answer(indiceFrase, true, 100)
      for (var i = 0; i < this.tmpPhrases.length; i++) {
        if (this.tmpPhrases[i].answered != true) {
          return;
        }
      }
      return "complete"
    }



    this.tmpPhrases[indiceFrase].answered = false;
    // this.$emit("error");
    // exerciseService.AddError(indiceFrase + ";" + indiceOption);
    this.exerciseService.answer(indiceFrase, false, 100)
    return "error";
  }

  getCleanedString(cadena) {
    var specialChars = "!@#$^&%*()+=-[]/{}|:<>?,.";
    for (var i = 0; i < specialChars.length; i++) {
      cadena = cadena.replace(new RegExp("\\" + specialChars[i], "gi"), "");
    }
    cadena = cadena.toLowerCase();
    cadena = cadena.replace(/ /g, "_");
    cadena = cadena.replace(/á/gi, "a");
    cadena = cadena.replace(/é/gi, "e");
    cadena = cadena.replace(/í/gi, "i");
    cadena = cadena.replace(/ó/gi, "o");
    cadena = cadena.replace(/ú/gi, "u");
    cadena = cadena.replace(/ñ/gi, "n");
    return cadena;
  }

  bola() {
    for (var i = 0; i < this.exercise.opciones.length; i++) {
      if (this.getCleanedString(this.exercise.opciones[i].opcion).length > 2) {
        return false;
      }
    }
    return true;
  }

    ValidateExerciseWord(indiceFrase, indiceFraseGuion, indiceFragmento, idOptionSelected) {
    let optionText = this.exercise.opciones.find(opcion => opcion.id == idOptionSelected).opcion;
    let optionTextCapital = optionText.substring(0, 1).toUpperCase() + optionText.substring(1);

    let isOptionCorrect = this.tmpPhrases[indiceFrase].fraseGuion[indiceFraseGuion].fragmento[indiceFragmento].id == idOptionSelected;

    if (isOptionCorrect) {
        this.exerciseService.answer(indiceFraseGuion, true, 100);

        let tmpPhrase = '';
        let tmpPhraseCapital = '';
        for (let j = 0; j < this.tmpPhrases[indiceFrase].fraseGuion[indiceFraseGuion].fragmento.length; j++) {
            let currentFragmento = this.tmpPhrases[indiceFrase].fraseGuion[indiceFraseGuion].fragmento[j];
        
            if (j === indiceFragmento) {
                tmpPhrase += optionText;
                tmpPhraseCapital += optionTextCapital;
            } else {
                tmpPhrase += currentFragmento.texto;
                tmpPhraseCapital += currentFragmento.texto;
            }
        }

        let selectedText = (this.difIntStr(tmpPhrase, this.tmpPhrases[indiceFrase].frase) <= this.difIntStr(tmpPhraseCapital, this.tmpPhrases[indiceFrase].frase))
            ? optionText
            : optionTextCapital;

        this.tmpPhrases[indiceFrase].fraseGuion[indiceFraseGuion].fragmento[indiceFragmento].texto = selectedText;

        let finalPhrase = '';
        for (let i = 0; i < this.tmpPhrases[indiceFrase].fraseGuion.length; i++) {
            for (let j = 0; j < this.tmpPhrases[indiceFrase].fraseGuion[i].fragmento.length; j++) {
                finalPhrase += this.tmpPhrases[indiceFrase].fraseGuion[i].fragmento[j].texto;
            }
        }

        if (this.tmpPhrases[indiceFrase].frase.toLowerCase() !== finalPhrase.toLowerCase()) {
            return '';
        }
        this.tmpPhrases[indiceFrase].answered = true;
        return 'complete';
    }
    else {
        this.tmpPhrases[indiceFrase].answered = false;
        this.exerciseService.answer(indiceFraseGuion, false, 100);
        return 'error';
    }
}

  showSolution() {
    var phraseSolved = "";
    for (var i = 0; i < this.tmpPhrases.length; i++) {
      this.tmpPhrases[i].answered = true;

      for (var j = 0; j < this.tmpPhrases[i].fraseGuion.length; j++) {
        for (var k = 0; k < this.tmpPhrases[i].fraseGuion[j].fragmento.length; k++) {
          this.setValues(i, j);
        }
      }
    }
  }
    ShowAnswer() {
        this.removeValues();
        this.tmpPhrases.forEach((frase, indexFrase) => {
            if (this.dropLevel == "box") {
                if (this.elementsErr.find(x => x == indexFrase + "")) {
                    frase.answered = false;
                }
                let ok = this.elementsOk.find(x => x == indexFrase + "")
                if (ok) {
                    if (frase.answered != false) {
                        frase.answered = true;
                    }
                    let indexFragmento = frase.fraseGuion.findIndex(x => x.fragmento.length > 1)
                    this.setValues(indexFrase, indexFragmento)
                }
            }
            else {
                frase.fraseGuion.forEach((fragmento, indexFragmento) => {
                    let err = this.elementsErr.find(x => x == indexFragmento + "");
                    if (err) {
                        frase.answered = false;
                    }
                    let ok = this.elementsOk.find(x => x == indexFragmento + "");
                    if (ok) {
                        this.setValues(indexFrase, indexFragmento)
                    }
                });
            }

        })
    }
  initInteract() {
    this.exerciseService.initInteract({
      onmove: (event) => {
        this.draggingOption = event.target
      },
      drop: (event) => {
        if (this.finished == true) return;

        let element = this.draggingOption
        let bin = event.target

        if (this.dropLevel == "box") {
          this.handleDropBox(element, bin);
        } else if (this.dropLevel == "word") {
          this.handleDropWord(element, bin);
        }
      },
    });
  }

  showAnswersTimeOut() {
    this.showingSolution = true;
    for (var i = 0; i < this.tmpPhrases.length; i++) {
      var phraseSolved = "";
      if (this.tmpPhrases[i].answered != true) {
        this.tmpPhrases[i].answered = null;
      }
      for (var j = 0; j < this.tmpPhrases[i].fraseGuion.length; j++) {
        for (var k = 0; k < this.tmpPhrases[i].fraseGuion[j].fragmento.length; k++) {
          if (this.tmpPhrases[i].fraseGuion[j].fragmento[k].texto == "_") {
            var optionText = "";
            var optionTextCapital = "";
            for (var l = 0; l < this.exercise.opciones.length; l++) {
              if (this.exercise.opciones[l].id == this.tmpPhrases[i].fraseGuion[j].fragmento[k].id) {
                optionText = this.exercise.opciones[l].opcion;
                optionTextCapital = optionText.substring(0, 1).toUpperCase() + optionText.substring(1, optionText.length);
              }
            }
            var alt1 = phraseSolved + optionText;
            var alt2 = phraseSolved + optionTextCapital;

            if (this.tmpPhrases[i].frase.substring(0, alt2.length) == alt2)
              this.tmpPhrases[i].fraseGuion[j].fragmento[k].texto = optionTextCapital;
            else
              this.tmpPhrases[i].fraseGuion[j].fragmento[k].texto = optionText;
            phraseSolved += this.tmpPhrases[i].fraseGuion[j].fragmento[k].texto;
          }
          else {
            phraseSolved += this.tmpPhrases[i].fraseGuion[j].fragmento[k].texto;
          }
        }
      }
    }
  }

  difIntStr(str1, str2) {

    /* If the strings are equal, bail */
    if (str1 === str2) {
      return 0;
    }

    ///*If the lengths are not equal, there is no point comparing each character.*/
    //if (str1.length != str2.length) {
    //    return false;
    //}

    /*loop here to go through each position and check if both strings are equal.*/
    var numDiffChar = 0;
    var index = 0;
    while (index < str1.length) {
      if (str1.charAt(index) !== str2.charAt(index)) {
        numDiffChar++;
      }
      index++;
    }
    return numDiffChar;
  }

  DrawModel5() {

    //Clone the Phrases
    this.tmpPhrases = (JSON.parse(JSON.stringify(this.exercise.frases)));

    for (var i = 0; i < this.tmpPhrases.length; i++) {

      var lastChar = this.tmpPhrases[i].libro[this.tmpPhrases[i].libro.length - 1];
      if (lastChar != '?' && lastChar != '!') {
        this.tmpPhrases[i].libro = this.tmpPhrases[i].libro + '.';
      }
    }

    if (this.exercise.operador == "nn" || this.exercise.operador == "1n" || this.exercise.operador == "11") {
      this.dropLevel = "box";
    } else if (this.exercise.operador == "2n" || this.exercise.operador == "22" || this.exercise.operador == "0" || this.exercise.operador == "") {
      this.dropLevel = "word";
    }
  }

  handleDropWord(item, bin) {
    for (var j = 0; j < this.tmpPhrases.length; j++) {
      if (this.tmpPhrases[j].answered == false)
        this.tmpPhrases[j].answered = null;
    }
    var idOptionSelected = item.getAttribute("optionId");
    var indiceFrase = bin.getAttribute("indiceFrase");
    var indiceFraseGuion = bin.getAttribute("indiceFraseGuion");
    var indiceFragmento = bin.getAttribute("indiceFragmento");

    var result = this.ValidateExerciseWord(indiceFrase, indiceFraseGuion, indiceFragmento, idOptionSelected);

    if (result == "complete") {
      this.exercise.opciones = [];
      this.exerciseService.endExercise()
    }

  }

  handleDropBox(item, bin) {
    for (var j = 0; j < this.tmpPhrases.length; j++) {
      if (this.tmpPhrases[j].answered == false)
        this.tmpPhrases[j].answered = null;
    }
    var idOption = item.getAttribute("optionId");
    var phraseIndex = bin.getAttribute("index");
    var result = this.ValidateExerciseBox(phraseIndex, idOption);

    if (result == "complete") {
      this.finished = true;
      this.exercise.opciones = [];
      // this.$emit('timer-stop');
      // this.$emit('exercise-solved');
      this.exerciseService.endExercise()
    }

    return;
  }

  removeValues() {
    this.tmpPhrases = (JSON.parse(JSON.stringify(this.exercise.frases)));
    for (var i = 0; i < this.tmpPhrases.length; i++) {

      var lastChar = this.tmpPhrases[i].libro[this.tmpPhrases[i].libro.length - 1];
      if (lastChar != '?' && lastChar != '!') {
        this.tmpPhrases[i].libro = this.tmpPhrases[i].libro + '.';
      }
    }
  }

  setValues(indiceFrase, indiceFraseGuion) {

    var phraseSolved = "";
    for (var i = 0; i < indiceFraseGuion; i++)
      for (var k = 0; k < this.tmpPhrases[indiceFrase].fraseGuion[i].fragmento.length; k++)
        phraseSolved += this.tmpPhrases[indiceFrase].fraseGuion[i].fragmento[k].texto;


    for (var k = 0; k < this.tmpPhrases[indiceFrase].fraseGuion[indiceFraseGuion].fragmento.length; k++) {
      if (this.tmpPhrases[indiceFrase].fraseGuion[indiceFraseGuion].fragmento[k].texto == "_") {
        var optionText = "";
        var optionTextCapital = "";
        for (var l = 0; l < this.exercise.opciones.length; l++) {
          if (this.exercise.opciones[l].id == this.tmpPhrases[indiceFrase].fraseGuion[indiceFraseGuion].fragmento[k].id) {
            optionText = this.exercise.opciones[l].opcion;
            optionTextCapital = optionText.substring(0, 1).toUpperCase() + optionText.substring(1, optionText.length);
          }
        }
        var alt1 = phraseSolved + optionText;
        var alt2 = phraseSolved + optionTextCapital;

        if (this.tmpPhrases[indiceFrase].frase.substring(0, alt2.length) == alt2)
          this.tmpPhrases[indiceFrase].fraseGuion[indiceFraseGuion].fragmento[k].texto = optionTextCapital;
        else
          this.tmpPhrases[indiceFrase].fraseGuion[indiceFraseGuion].fragmento[k].texto = optionText;
        this.tmpPhrases[indiceFrase].fraseGuion[indiceFraseGuion].fragmento[k].bold = true;
        phraseSolved += this.tmpPhrases[indiceFrase].fraseGuion[indiceFraseGuion].fragmento[k].texto;
      }
      else {
        phraseSolved += this.tmpPhrases[indiceFrase].fraseGuion[indiceFraseGuion].fragmento[k].texto;
      }
    }
  }

  get alphanumericCharacterCount(): number {
    const textWithoutHtmlTags = this.exercise.tema.replace(/<[^>]*>/g, '');
    return textWithoutHtmlTags.length;
  }

}
