<div id="dailyTextContainer" [hidden]="loadingText" [ngStyle]="{'border-color': sundayText == true || pendingTest ? 'var(--fucsia)' : color}"
    class="walinwa-box walinwa-box-daily-text walinwa-box-orange" [ngClass]="{'test-box-border': pendingTest}">
    <ng-container *ngIf="(!pendingTest || IdTest == null); else testBlock">
        <div class="walinwa-box-header" [style.backgroundColor]="color"
            [hidden]="weeklyText == null || weeklyText == false || didyouknow == true || sundayText == true || (waliLetrasToday && !isProd)">
            <span>{{'COMPONENTS.DAILY-TEXT.WEEK-MOST-VOTED-SENTENCE' | translate}}</span>
        </div>

        <div class="walinwa-box-header" [style.backgroundColor]="color"
            [hidden]="ecoText == true || didyouknow == null || didyouknow == false || sundayText == true || (waliLetrasToday && !isProd)">
            <span>{{'COMPONENTS.DAILY-TEXT.DID-YOU-KNOW' | translate}}</span>
        </div>

        <div class="walinwa-box-header" [style.backgroundColor]="color"
            [hidden]="ecoText == true || didyouknow == true || weeklyText == true || sundayText == true || (waliLetrasToday && !isProd)">
            <span>{{'COMPONENTS.DAILY-TEXT.DAY-SENTENCE' | translate}}</span>
        </div>

        <div class="walinwa-box-header"
            style="background-color: var(--fucsia);"
            [hidden]="ecoText == true || didyouknow == true || weeklyText == true || sundayText == false || (waliLetrasToday && !isProd)">
            <span>{{'COMPONENTS.DAILY-TEXT.SUNDAY-QUESTION' | translate}}</span>
        </div>

        <div class="walinwa-box-header ecoembes-title" [style.backgroundColor]="color"
            [hidden]="ecoText != true || weeklyText == null || didyouknow == true || weeklyText == true || sundayText == true || (waliLetrasToday && !isProd)">
            <span>{{'COMPONENTS.DAILY-TEXT.ECO-SATURDAYS-EXERCISE' | translate}}</span>
        </div>
        <div class="walinwa-box-header" style="justify-content: center;"
            [style.backgroundColor]="color" [hidden]="!waliLetrasToday || isProd">
            <span>{{'COMPONENTS.DAILY-TEXT.WALILETRAS-LETTER-GAME' | translate}}</span>
        </div>

        <div fxFlex="" fxLayout="row" fxLayoutAlign="space-between center" class="spe_gap">
            
            <div fxFlex="" fxLayout="column" fxLayoutAlign="start center" fxLayoutAlign.xs="start stretch" class="ww_content_daily spe_gap" [ngClass]="{'waliLetras': waliLetrasToday && !isProd}">
                
                <span *ngIf="dailyText && (!waliLetrasToday || isProd)"
                    [ngClass]="{ 'dailyTextStars' : weeklyText == true, 'sundayText': sundayText}"
                    class="dailyText spe_gap" [hidden]="dailyText.Autor == '¿SABÍAS QUÉ?'  || dailyText.Autor == 'ECOEMBES'" fxLayout="row nowrap" style="align-self: start; width: 100%;">

                    <span fxLayout="column" fxLayoutAlign="start" class="spe_gap" *ngIf="sundayText != true" style="width: auto;">
                        <span class="dailyTextSpan" [innerHtml]="dailyText.Phrase | replaceTags"
                            ></span>

                        <span class="dailyAuthor text-right walinwa-100">
                            <span class="dailyBook" [hidden]="dailyText.Book==null || dailyText.Book == ''">{{ dailyText.Book
                                }}.
                            </span>
                            {{dailyText.Autor}}
                        </span>
                    </span>
                    
                    <span fxLayout="row wrap" fxLayoutAlign="space-between center" class="spe_gap" *ngIf="sundayText == true" style="width: 100%;">
                        <img class="sundayStar" src="https://walinwa.blob.core.windows.net/images/Student/daily-text/sundayStar.gif?ngsw-bypass=true" 
                            *ngIf="sundayText && !dailyText.answer" fxFlex="4rem" />
                        <img class="sundayStar" src="https://walinwa.blob.core.windows.net/images/Student/daily-text/sundayStar.png?ngsw-bypass=true"
                            *ngIf="sundayText && dailyText.answer" fxFlex="4rem" />
                        
                        <span class="dailyTextSpan" [innerHtml]="dailyText.Phrase | replaceTags"
                            fxFlex="0 0 calc(100% - 5rem)"></span>
                    </span>
                    <img class="trofeo" src="https://walinwa.blob.core.windows.net/images/Student/daily-text/trofeo_giro_izq_alta_con_base.gif?ngsw-bypass=true"
                        *ngIf="weeklyText" />

                </span>

                <div class="dailyAuthorContainer waliLetras walinwa-100 spe_gap" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/WaliLetras/waliletras-new-a.svg?ngsw-bypass=true&quot;)'"  *ngIf="waliLetrasToday && !isProd" fxLayout.xs="row wrap" fxLayoutAlign="end end">
                    <button class="new_but verdes"
                            (click)="waliLetrasService.show = true">
                            {{buttonText}}</button>
                </div>

                <div *ngIf="dailyText && dailyText.Autor == '¿SABÍAS QUÉ?' && (!waliLetrasToday || isProd)"
                    [ngClass]="{ 'bg': dailyText.Phrase.length > 150, 'med': dailyText.Phrase.length > 85 && dailyText.Phrase.length <= 150, 'sm': dailyText.Phrase.length < 85 }"
                    class="dailyTextDidYouKnow walinwa-100">
                    <span class="dailyTextSpan" [innerHtml]="dailyText.Phrase | replaceTags"></span>
                </div>

                <div class="dailyTextEcoembes walinwa-100 spe_gap" [hidden]="ecoText != true || waliLetrasToday" fxFlex="row" fxLayoutAlign="space-between center">
                    <img class="ecoembes-img" [hidden]="dailyTextScore != null"
                        src="https://walinwa.blob.core.windows.net/images/Student/daily-text/reciclaje.gif?ngsw-bypass=true" fxFlex="5rem" />
                    <img class="sundayStar" [hidden]="dailyTextScore == null"
                        src="https://walinwa.blob.core.windows.net/images/Student/daily-text/green_stars.png?ngsw-bypass=true" fxFlex="4rem" />
                    <span class="dailyTextSpan ecoembes-text" fxFlex="">{{'COMPONENTS.DAILY-TEXT.ECO-SATURDAYS-EXERCISE-EXPLAIN-TEXT' | translate}}</span>
                </div>

                <div class="dailyAuthorContainer walinwa-100" *ngIf="dailyText && (!waliLetrasToday || isProd)" [hidden]="ecoText">

                    <div fxLayout="row" fxLayoutAlign="space-around center" class="walinwa-100 dailyButtons"
                        *ngIf="!weeklyText && dailyText.Autor != '¿SABÍAS QUÉ?' && sundayText != true && dailyText.Autor != 'ECOEMBES'">

                        <img id="like" [src]="getImgButtonFrase('me_gusta')" (click)="like()"
                            [ngClass]="{'buttonDisabled': dailyTextScore.Score != 1 || isDemo,  'noCursor': dailyTextScore.Score != 1}"
                            *ngIf="dailyTextScore" class="pointer" fxFlex="0 0 13rem" fxHide.xs fxShow />
                        <img id="like" [src]="getImgButtonFrase('me_gusta')" (click)="like()" *ngIf="!dailyTextScore"
                            [ngClass]="{'buttonDisabled': isDemo}" class="pointer" fxFlex="0 0 13rem" fxHide.xs fxShow />
                            <!-- mobile -->
                        <a href="javascript:void(0);" id="like-mob" (click)="like()" class="buttonnewmob like" [ngClass]="{'buttonDisabled': dailyTextScore.Score != 1 || isDemo}" *ngIf="dailyTextScore" fxHide fxShow.xs>{{'COMPONENTS.DAILY-TEXT.LIKE-TEXT' | translate}}</a>

                        <a href="javascript:void(0);" id="like-mob" (click)="like()" class="buttonnewmob like" [ngClass]="{'buttonDisabled': isDemo}" *ngIf="!dailyTextScore" fxHide fxShow.xs>{{'COMPONENTS.DAILY-TEXT.LIKE-TEXT' | translate}}</a>
                        

                        <img id="dontLike" [src]="getImgButtonFrase('no_me_gusta')" (click)="dontLike()"
                            [ngClass]="{'buttonDisabled': dailyTextScore.Score != -1 || isDemo,  'noCursor': dailyTextScore.Score != -1}"
                            *ngIf="dailyTextScore" class="pointer" fxFlex="0 0 13rem" fxHide.xs fxShow />
                        <img id="dontLike" [src]="getImgButtonFrase('no_me_gusta')" (click)="dontLike()"
                            *ngIf="!dailyTextScore" [ngClass]="{'buttonDisabled': isDemo}" class="pointer" fxFlex="0 0 13rem" fxHide.xs fxShow />
                            <!-- mobile -->
                        <a href="javascript:void(0);" id="dontLike-mob" (click)="dontLike()" [ngClass]="{'buttonDisabled': dailyTextScore.Score != -1 || isDemo}"  *ngIf="dailyTextScore" class="buttonnewmob dislike" fxHide fxShow.xs>{{'COMPONENTS.DAILY-TEXT.DONT-LIKE-TEXT' | translate}}</a>

                        <a href="javascript:void(0);" id="dontLike-mob" (click)="dontLike()" *ngIf="!dailyTextScore" [ngClass]="{'buttonDisabled': isDemo}" class="buttonnewmob dislike" fxHide fxShow.xs>{{'COMPONENTS.DAILY-TEXT.DONT-LIKE-TEXT' | translate}}</a>

                        <img id="dontKnow" [src]="getImgButtonFrase('no_lo_entiendo')" (click)="dontKnow()"
                            [ngClass]="{'buttonDisabled': dailyTextScore.Score != 0 || isDemo,  'noCursor': dailyTextScore.Score != 0}"
                            *ngIf="dailyTextScore" class="pointer" fxFlex="0 0 13rem" fxFlex.xs="60%" fxHide.xs fxShow />
                        <img id="dontKnow" [src]="getImgButtonFrase('no_lo_entiendo')" (click)="dontKnow()"
                            *ngIf="!dailyTextScore" [ngClass]="{'buttonDisabled': isDemo}" class="pointer" fxFlex="0 0 13rem" fxFlex.xs="60%" fxHide.xs fxShow />
                            <!-- mobile -->
                        <a href="javascript:void(0);" id="dontKnow-mob" (click)="dontKnow()" [ngClass]="{'buttonDisabled': dailyTextScore.Score != 0 || isDemo}" *ngIf="dailyTextScore" class="buttonnewmob" fxHide fxShow.xs>{{'COMPONENTS.DAILY-TEXT.DONT-UNDERSTAND-TEXT' | translate}}</a>

                        <a href="javascript:void(0);" id="dontKnow-mob" (click)="dontKnow()" *ngIf="!dailyTextScore" [ngClass]="{'buttonDisabled': isDemo}" class="buttonnewmob" fxHide fxShow.xs>{{'COMPONENTS.DAILY-TEXT.DONT-UNDERSTAND-TEXT' | translate}}</a>

                    </div>

                    <div fxLayout="row" fxLayout.xs="row wrap" fxLayoutAlign="space-evenly center" fxLayoutAlign.xs="center center" class="dailyButtons walinwa-100 spe_gap"
                        *ngIf="!weeklyText && dailyText.Autor == '¿SABÍAS QUÉ?' && sundayText != true && dailyText.Autor != 'ECOEMBES'">

                        <img id="like" [src]="getImgButtonFrase('lo_sabia')" (click)="like()" *ngIf="dailyTextScore"
                        class="pointer" fxFlex="0 0 13rem" fxFlex.xs="60%" [ngClass]="{buttonDisabled: dailyTextScore.Score == -1 || isDemo}" fxHide.xs fxShow />
                        <img id="like" [src]="getImgButtonFrase('lo_sabia')" (click)="like()" *ngIf="!dailyTextScore"
                            [ngClass]="{'buttonDisabled': isDemo}" class="pointer" fxFlex="0 0 13rem" fxFlex.xs="60%" fxHide.xs fxShow />
                            <!-- mobile -->
                        <a href="javascript:void(0);" id="like-mob" class="buttonnewmob like" *ngIf="dailyTextScore" [ngClass]="{buttonDisabled: dailyTextScore.Score == -1 || isDemo}" fxHide fxShow.xs>{{'COMPONENTS.DAILY-TEXT.ALREADY-KNEW-TEXT' | translate}}</a>
                        <a href="javascript:void(0);" id="like-mob" class="buttonnewmob like" (click)="like()" *ngIf="!dailyTextScore"
                        [ngClass]="{'buttonDisabled': isDemo}" fxHide fxShow.xs>{{'COMPONENTS.DAILY-TEXT.ALREADY-KNEW-TEXT' | translate}}</a>

                        <img id="dontLike" [src]="getImgButtonFrase('no_lo_sabia')"
                            *ngIf="dailyTextScore"
                            [ngClass]="{buttonDisabled: dailyTextScore.Score != -1 || isDemo}" class="pointer" fxFlex="0 0 13rem" fxFlex.xs="60%" fxHide.xs fxShow />
                        <img id="dontLike" [src]="getImgButtonFrase('no_lo_sabia')" (click)="dontLike()"
                            *ngIf="!dailyTextScore" [ngClass]="{'buttonDisabled': isDemo}" class="pointer" fxFlex="0 0 13rem" fxFlex.xs="60%" fxHide.xs fxShow />
                            <!-- mobile -->
                        <a href="javascript:void(0);" id="dontLike-mob" class="buttonnewmob dislike"
                        *ngIf="dailyTextScore"
                        [ngClass]="{buttonDisabled: dailyTextScore.Score != -1 || isDemo}" fxHide fxShow.xs>{{'COMPONENTS.DAILY-TEXT.DIDNT-KNEW-TEXT' | translate}}</a>
                        <a href="javascript:void(0);" id="dontLike-mob" class="buttonnewmob dislike" (click)="dontLike()"
                        *ngIf="!dailyTextScore" [ngClass]="{'buttonDisabled': isDemo}" fxHide fxShow.xs>{{'COMPONENTS.DAILY-TEXT.DIDNT-KNEW-TEXT' | translate}}</a>

                    </div>

                    <div fxLayout="row" fxLayout.xs="row wrap" fxLayoutAlign="space-around center" fxLayoutAlign.xs="center center" class="sundayButtons walinwa-100 spe_gap" *ngIf="dailyText.options"
                        [hidden]="sundayText == false ||  dailyText.Autor == 'ECOEMBES'">

                        <div [ngClass]="{ ok: option.correct && dailyText.answer, err: !option.correct && dailyText.answer }"
                            style="border-radius: 5em; box-shadow: none !important; min-width: auto !important; width: 100% !important;  display: flex; justify-content: center; align-items: center; cursor: pointer;"
                            class="boton" (click)="answerSunday(i)"
                            *ngFor="let option of dailyText.options; let i = index"
                            [innerHtml]="option.option | replaceTags"></div>

                    </div>

                </div>

                <div style="margin-top: -3em;" *ngIf="dailyText && (!waliLetrasToday || isProd)" [hidden]="dailyText.Autor != 'ECOEMBES'" class="dailyAuthorContainer walinwa-100" fxLayout="row wrap" fxLayoutAlign="end center">
                    <button class="new_but azul" [disabled]="dailyTextScore!= null"
                        (click)="ecoembes()">{{'COMPONENTS.DAILY-TEXT.PARTICIPATE-BUTTON' | translate}}</button>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #testBlock>
        <div class="walinwa-box-header" style="justify-content: center; background-color: var(--fucsia);" fxLayoutAlign="start center">
            <span>{{'COMPONENTS.DAILY-TEXT.EVALUATION-TEST' | translate}}</span>
        </div>
        <div class="dailyAuthorContainer" style="height: 75%">
            <div fxFlex="" fxLayout="column" fxLayoutAlign="start center" fxLayoutAlign.xs="start stretch" class="spe_gap ww_content_daily">
                <div fxFlex="" fxLayout="row">
                    <div *ngIf="!mobile">
                        <img style="width: 75%" src="https://walinwa.blob.core.windows.net/images/Student/daily-text/gifttest.gif?ngsw-bypass=true">
                    </div>

                    <div style="width: 100%; align-items: center; display: flex;">
                        <span class="dailyTextSpan" [innerHTML]="'COMPONENTS.DAILY-TEXT.PENDING-TEST-TEXT' | translate"></span>
                    </div>
                </div>
                <div class="dailyAuthorContainer walinwa-100 spe_gap" fxLayout.xs="row wrap" fxLayoutAlign="end end" [ngStyle]="!mobile && {'margin-top': '-2em'}">
                    <button class="new_but azul" (click)="personalizedTestService.doTest = true">
                        {{'COMPONENTS.DAILY-TEXT.START-TEST-BUTTON' | translate}}
                    </button>
                </div>
            </div>

            <personalized-test [review]="false" *ngIf="personalizedTestService.doTest"
                [IdTest]="IdTest"></personalized-test>
        </div>
    </ng-template>
    <wali-letras *ngIf="waliLetrasService.show"></wali-letras>
</div>