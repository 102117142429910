import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VersionService } from 'app/services/version.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    constructor(
        private router: Router,
        private versionService: VersionService) { 
    this._unsubscribeAll = new Subject();
}

ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
}

  ngOnInit(): void {
    this.versionService.getVersion()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result: any) => {
            if (!result.Version.includes("Maintenance")) {
                this.router.navigateByUrl("");
            }
        })
  }

}
