import { CustomSession } from 'app/core/shared/state';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/es'

@Component({
  selector: 'calCustomizedSession',
  templateUrl: './cal-customized-session.component.html',
  styleUrls: ['./cal-customized-session.component.scss']
})
export class CalCustomizedSessionComponent implements OnInit, OnChanges {
  @Input() fullDay;
  @Input() currentUser;
  @Input() selectedDay;
  @Input() currentMonth;
  @Input() parent;
  @Input() customizedSessions : CustomSession[]
  @Input() disablePast
  @Input() isTest = false;
  @Input() isControl = false;
  @Input() isExamen = false;
  @Input() isCustom = false;

  calendarUser;
  month;
  weeks;
    selectedDayHours;
  constructor(
  ) { 
    moment.locale('es')
  }

    ngOnInit() {
    this.calendarUser = this.currentUser;
    if (this.selectedDay == null || this.selectedDay.clone === "undefined") return;
    let currentDay = this.selectedDay.clone();
    this.selectedDayHours = this.selectedDay.hours()
    this.month = currentDay.clone();
    var start = currentDay.clone();
    start.date(1);
    
    this._buildMonth(start, this.month);
    if (this.month.month() != moment().month()) this.selectedDay = null;
  }

  ngOnChanges() {
    setTimeout(() => {
      let currentDay = this.selectedDay.clone();
      this.month = currentDay.clone();
      let start = currentDay.clone();
      start.date(1);
      this._buildMonth(start, this.month);
    }, 100)
  }

  selectedDayChanged(date) {
    let currentDay = this.selectedDay.clone();
    this.month = currentDay.clone();
    var start = currentDay.clone();
    start.date(1);
    this._buildMonth(start, this.month);
    this.parent.selectedDayChanged(date);
  }

  select(day) {
    //if (this.isPast(day.date)) return
      console.log(day)
      console.log(day.date)
    this.selectedDay = day.date;
    this.selectedDayChanged(this.selectedDay);
  }

  next() {
    var next = this.month.clone();
    this._removeTime(next.month(next.month() + 1)).date(1);
    this.month.month(this.month.month() + 1);
    this.selectedDay = this.month;
    this.selectedDayChanged(this.selectedDay);
  }

  previous() {
    var previous = this.month.clone();
    this._removeTime(previous.month(previous.month() - 1).date(1));
    this.month.month(this.month.month() - 1);
    this.selectedDay = this.month;
    this.selectedDayChanged(this.selectedDay);
  }

  isPast(date) {
    if(this.isTest){
        return date.isBefore(moment().hour(0).minute(0).second(0).millisecond(0));
    }else {
        return date.isSameOrBefore(moment());
    }
  };

  isCustomized(date) {
    if (!this.customizedSessions || this.isTest || this.isControl || this.isExamen)
        return false;
      for (var i = 0; i < this.customizedSessions?.length; i++) {
        
      if (moment(this.customizedSessions[i].Date).isSame(date, "day"))
        return this.customizedSessions[i].IdExam == null && this.customizedSessions[i].IdControl == null && this.customizedSessions[i].IdTest == null;
    }
    return false;
  };

  isExam(date) {
    if (!this.customizedSessions || this.isTest || this.isCustom)
      return false;
    for (var i = 0; i < this.customizedSessions?.length; i++) {
      if (moment(this.customizedSessions[i].Date).isSame(date, "day"))
        return this.customizedSessions[i].IdExam != null;
    }
    return false;
  };

  isExaminControl(date){
    if (!this.isExamen )
      return false;
    for (var i = 0; i < this.customizedSessions?.length; i++) {
      if (moment(this.customizedSessions[i].Date).isSame(date, "day") && this.isExamen )
        return this.customizedSessions[i].IdExam != null;
    }
    return false;
  }
  isControlSession(date) {
    if (!this.customizedSessions || this.isTest || this.isCustom)
      return false;
    for (var i = 0; i < this.customizedSessions?.length; i++) {
        if (moment(this.customizedSessions[i].Date).isSame(date, "day"))
        return this.customizedSessions[i].IdControl != null;
    }
    return false;
  };
  isControlSessioninExam(date) {
    if (!this.isExamen )
      return false;
    for (var i = 0; i < this.customizedSessions?.length; i++) {
        if (moment(this.customizedSessions[i].Date).isSame(date, "day"))
        return this.customizedSessions[i].IdControl != null;
    }
    return false;
  };
  isPersonalizedTest(date) {
    if (!this.customizedSessions || !this.isTest || this.isCustom)
      return false;
    for (var i = 0; i < this.customizedSessions?.length; i++) {
        if (moment(this.customizedSessions[i].Date).isSame(date, "day"))
        return this.customizedSessions[i].IdTest != null;
    }
    return false;
  };

  _removeTime(date) {
    var a = 0;
    return date.hour(a).minute(a).second(a).millisecond(a);
  }

  _buildMonth(start, month) {
    this.weeks = [];
    var done = false, date = start.clone(), monthIndex = date.month(), count = 0;
    while (!done) {
      this.weeks.push({ days: this._buildWeek(date.clone(), month) });
      date = date.clone();
      date.add(1, "w");
      done = count++ > 2 && (monthIndex !== date.startOf('week').month());
      monthIndex = date.month();
    }
  }

  _buildWeek(date, month) {
    let days = [];
    let dayItereator = date.startOf('week').hour(this.selectedDayHours);
    for (var i = 0; i < 7; i++) {
      days.push({
        name: dayItereator.format("dd").substring(0, 1),
        number: dayItereator.date(),
        isCurrentMonth: dayItereator.month() === month.month(),
        isToday: this.isTest ? false :dayItereator.isSame(new Date(), "day"),
        date: dayItereator,
        month: dayItereator.format("MM")
      });
      dayItereator = dayItereator.clone();
      dayItereator.add(1, "d");
    }
    return days;
  }

}
