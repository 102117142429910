import { NgModule } from "@angular/core";
import { SortByPipe } from "./pipes/sort-by.pipe";

@NgModule({
    imports: [],
    declarations: [
        SortByPipe,
    ],
    exports: [
        SortByPipe,
    ],
})
export class SharedModule
{
}