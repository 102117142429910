import { Component, OnInit, ViewChild, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CalReadingTextComponent } from 'app/components/cal-reading-text/cal-reading-text.component';
import { AuthService } from 'app/services/auth.service';
import { CourseService } from 'app/services/course.service';
import { NavigationService } from 'app/services/navigation.service';
import { ReadingTextService } from 'app/services/reading-text.service';
import { SchoolService } from 'app/services/school.service';
import * as moment from 'moment';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
declare var $;

@Component({
    selector: "app-reading",
    templateUrl: "./reading.component.html",
    styleUrls: ["./reading.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ReadingComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
    @ViewChild(CalReadingTextComponent) child2: CalReadingTextComponent;
    readingTexts;
    student;
    selectedDay = moment();
    currentMonth;
    courses = [];
    selectedCourse;
    hasExercise = false;
    six_weeks = false;
    licenseDate;
    afterLicense;



  constructor(
    private authService: AuthService,
    private courseService: CourseService,
    private readingTextService: ReadingTextService,
    private router: Router,
    private navigationService: NavigationService,
    private schoolService: SchoolService
  ) { 
    this._unsubscribeAll = new Subject();
}

ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

  ngOnInit() {
    if (!this.authService.check()) return;
    this.selectedDay = moment();
    this.currentMonth = this.selectedDay.month() + 1;
    this.licenseDate = this.schoolService.school.ValidUntil;
      this.courseService.Courses.pipe(takeUntil(this._unsubscribeAll)).subscribe(courses => {
          this.courses = courses;
          if (this.courses) {
              this.selectedCourse = this.courses[0].Level;
          }
      })
      this.getReadingTexts();
      this.readingTextService.reload_monthly_reading
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                if (result) {
                    this.getReadingTexts();
                }
        })
  }

  getReadingTexts() {
    this.hasExercise = false;
    this.readingTextService.getReadingTextByLevel(this.selectedCourse, this.currentMonth, this.selectedDay.year())
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
      result => {
        this.readingTexts = result;
        this.readingTextService.MonthlyReadingTexts = result;
        this.child2.refresh((weeks_count) => {
          this.six_weeks = weeks_count >= 6
        })
        if (!this.readingTexts) return;
        let currentReadingText = this.readingTexts.find(r => moment(r.date).isSame(this.selectedDay));
        if (!currentReadingText) return;
        if (moment().month() < this.selectedDay.month() - 1) return
        if (moment().month() > this.selectedDay.month() + 1) return
        this.hasExercise = true;
      }
    )
  }

  selectedDayChangedCL(newDate) {
    this.selectedDay = newDate;
    this.afterLicense = this.selectedDay.isAfter(moment(this.licenseDate));
    if (this.currentMonth != this.selectedDay.month() + 1) {
      this.currentMonth = this.selectedDay.month() + 1;
      this.getReadingTexts();
    } else {
      this.hasExercise = false;
      if (!this.readingTexts) return;
      let currentReadingText = this.readingTexts.find(r => moment(r.date).isSame(this.selectedDay));
      if (!currentReadingText) return;
      if (moment().month() < this.selectedDay.month() - 1) return
      if (moment().month() > this.selectedDay.month() + 1) return
      this.hasExercise = true;
    }

    }

    showExercise() {
        if (!this.readingTexts) return;
        let currentReadingText = this.readingTexts.find((r) =>
            moment(r.date).isSame(this.selectedDay)
        );
        if (!currentReadingText) return;
        this.navigationService.go(
            this.router.url + "/review",
            {
                    review: true,
                    reviewing: true,
                    date: currentReadingText.date,
                    IdReadingText: currentReadingText.IdReadingText,
                    selectedDay: this.selectedDay
                }
        );
    }
}
