<section style="width: 100%; height: 100%;" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch"
    fxLayoutAlign.lt-md="stretch" fxNgClass.gt-sm="spe_gap">
    <div fxFlex="11rem" fxFlex.lt-md="0 0 auto" fxNgClass.lt-md="mob_cont_menu" 
        fxNgClass.gt-sm="scroll_vertical">
        <div class="ww_sidebar_menu" id="mobiMenuFlot">
            <button routerLink="./students" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"
                class="" (click)="GoToHelpPageAlumnos()">
                {{'SCHOOL.TEACHER.STUDENTS-TITLE' | translate }}
            </button>

            <button [disabled]="inConfig()" routerLink="./config" routerLinkActive="active" class=""
                (click)="GoToHelpPageConfiguracion()">
                {{'SCHOOL.CONFIG-TITLE' | translate }}
            </button>

            <button [disabled]="inActivities() || allActivitiesDisabled"
                [ngClass]="{'disabled-sidebar-button' : allActivitiesDisabled}" routerLink="./activities"
                routerLinkActive="active" class="" (click)="GoToHelpPageActividades()">
                {{'SCHOOL.TEACHER.ACTIVITIES-TITLE' | translate }}
            </button>

            <button [disabled]="inComplements() || allComplementsDisabled" routerLink="./complements" routerLinkActive="active" class=""
            [ngClass]="{'disabled-sidebar-button' : allComplementsDisabled}" (click)="GoToHelpPageComplementos()">
            {{'SCHOOL.TEACHER.COMPLEMENTS-TITLE' | translate }}
            </button>

            @if (walileeEnabled) {
                <button [disabled]="inWalilee()" routerLink="./walilee" routerLinkActive="active" class="" [ngClass]="{'disabled-sidebar-button' : !walileeEnabled}">
                    {{'SCHOOL.TEACHER.WALILEE-TITLE' | translate }}
                </button>
            }

            <button routerLink="./tracking" [disabled]="!reportEnabled || inReport()"
                [ngClass]="{'disabled-sidebar-button' : !reportEnabled}" routerLinkActive="active" class=""
                (click)="GoToHelpPageSeguimiento()">
                {{'SCHOOL.TRACKING-TITLE' | translate }}
            </button>

            <button routerLink="./messages" routerLinkActive="active" class="" (click)="GoToHelpPageMensajes()">
                {{'SCHOOL.TEACHER.MESSAGES-TITLE' | translate }}
            </button>
        </div>
    </div>
    <div fxFlex="" fxFlex.lt-md="0 0 auto" id="">
        <router-outlet></router-outlet>
    </div>
</section>

<div class="walinwa-overlay" style="padding: 6em; font-size: var(--font-size);" *ngIf="showPopUp"
    (click)="closePopUp()">
    <img style="max-height: 90%; max-width: 90%;" [src]="getImg()" *ngIf="announcement" />
</div>