<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="opc1 spe_gap" fxLayout="column" fxLayoutAlign="stretch" [ngStyle]="{'pointer-events': review ? 'none;' : ''}">

    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">
            <span [innerHtml]="exercise.enunciado"></span>
        </div>
    </div>

    <div [ngClass]="{ ok: error == 0, error: error == 1, time: error == 2}" class="frase dropzone" [innerHtml]="exercise.frase"></div>

    <div class="optionsContainer" fxFlex="" fxLayout="row nowrap" fxLayoutAlign="center center" id="space-for-buttons">
        <div [attr.optionIndex]="i" [ngClass]="{ bola:  bola(), 'element-collapse': finished }" *ngFor="let option of opciones; let i = index" id="opt{{ i }}" class="exerciseLetterOptionContainer draggable" dragChildImg="option{{ i }}">
            <div id="option{{ i }}" class="exerciseLetterOption" fxFlex="" fxLayout="column" fxLayoutAlign="center center">
                <div [ngClass]="{'letraMayus' : isMayus(option)}" class="letra">{{ option }}</div>
                <div class="tipo" *ngIf="isMayus(option)">mayúscula</div>
                <div class="tipo" *ngIf="!isMayus(option)">minúscula</div>
            </div>
        </div>
    </div>

</div>