import { AvatarItem } from "./../core/shared/state/models/avatar-item.model";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Subject } from "rxjs";

import { environment } from "environments/environment";
import {
    FileRequest,
    FileResponse,
} from "app/core/shared/state/models/file/file.model";
import { FileService } from "./file.service";
import { AvatarItemType } from "app/core/shared/enums/avatar-item-type.enum";
@Injectable({
    providedIn: "root",
})
export class AvatarShopService {
    baseUrl = environment.baseApi + "/api/AvatarItem";
    private activeAvatar = new BehaviorSubject(false);
    currentStatus = this.activeAvatar.asObservable();
    imgUrls: FileResponse[] = [];
    subDirectory = "/images/Avatar/";
    ItemBuyed: Subject<any> = new Subject();
    private s_changed_color = new Subject<boolean>();
    changed_color = this.s_changed_color.asObservable();
    private s_reload_items = new Subject<boolean>();
    reload_items = this.s_reload_items.asObservable();
    availableItems: AvatarItem[] = [];
    constructor(private http: HttpClient, private fileService: FileService) {}


    changeColor(changeColor: boolean){
        this.s_changed_color.next(changeColor);
    }
    changeReloadItems(reload_items: boolean) {
        this.s_reload_items.next(reload_items);
    }
    getAvailableItems() {
        return this.http.get<AvatarItem[]>(this.baseUrl + "/available");
    }
    GetMetConditionsByUser(idUser: number) {
        return this.http.get(this.baseUrl + "/winConditions/" + idUser);
    }
    getBoughtByIdUser(idUser) {
        return this.http.get(this.baseUrl + "/bought/" + idUser);
    }
    GetActiveItemsByIdUser(IdUser : number){
        return this.http.get(this.baseUrl + "/Active/" + IdUser);
    }

    activateAllItems(idUser, data) {
        return this.http.post(
            this.baseUrl + "/idUser/" + idUser + "/activateAllItem",
            data
        );
    }

    setAvatarItemToUserShop(idUser: number, idAvatarItem: number, Description: string, Walinwos: number, Type: any, IdColor?: any) {
        var params = {};
        if (IdColor) {
            params = { IdColor, Description,Walinwos,Type };
        } else {
            params = { Description,Walinwos,Type };
        }
        return this.http.post(
            this.baseUrl +
                "/idUser/" +
                idUser +
                "/idAvatarItem/" +
                idAvatarItem,
            params
        );
    }

    checkRegalar(IdAvatarItem: number, IdUserFrom: number, IdUserTo: number) {
        let params = { IdAvatarItem: IdAvatarItem, IdUserFrom: IdUserFrom, IdUserTo: IdUserTo };
        return this.http.post(this.baseUrl + "/CheckRegalar", params);
    }

    regalar(IdAvatarItem: number, IdUserFrom: number, IdUserTo: number, Message: string, Type: AvatarItemType) {
        let params = { Message: Message, IdAvatarItem: IdAvatarItem, IdUserFrom: IdUserFrom, IdUserTo: IdUserTo, Type: Type };
        return this.http.post(this.baseUrl + "/Regalar", params);
    }
    setAvatarStatus(status: boolean) {
        this.activeAvatar.next(status);
    }
}
