import { NgModule } from '@angular/core';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { AuthGuardService } from './guards/auth-guard.service';
import { RepositoryService } from './repository/repository.service';
import { SharedService} from './shared/shared.service';
import { AppService } from 'app/app.service';
import { AuthService } from './guards/auth.service';

@NgModule({
  declarations: [],
  imports: [],
  providers: [AuthGuardService, AuthService,RepositoryService, SharedService, AppService ],
})

export class CoreModule { }