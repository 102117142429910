<div *ngIf="!controlSession" class="indicationsFooterbar" [ngClass]="{'reviewing': review}">
    <span [innerHtml]="exercise.tema" [ngStyle]="exerciseService.currentExercise != null && exerciseService.currentExercise.civilization?.includes('Eskimo') ? {'color': 'var(--gris)'} : {} "></span>
</div>

<div class="marca-f spe_gap" fxLayout="column" fxLayoutAlign="stretch" [ngStyle]="{'pointer-events': review ? 'none;' : ''}">

    <!-- Caja de mensaje inicial -->
    <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="spe_gap panelWordsIndication">
        <div *ngIf="controlSession" fxNgClass.gt-xs="text-center" fxNgClass.xs="text-left">{{current + 1}}
        </div>
        <div fxFlex="" class="text-center">
            <span [innerHtml]="parseEnunciado()"></span>
        </div>
    </div>

    <div id="space-for-text" class="wordsContainer">
        <div class="items_container">
            <div  
            *ngFor="let frase of tmpPhrases; let i = index" 
            id="TextContainer{{ i }}" 
            [attr.index]="i" 
            class="exerciseRule" 
            [ngClass]="{ phraseBoxOk:  frase.answered == true, phraseBoxError:  frase.answered == false}" 
            (click)="validate(i)">
                <div class="phraseBoxChild">
                    <span [innerHtml]="frase.frase"></span>
                </div>
            </div>
        </div>
    </div>
</div>