import { FileService } from 'app/services/file.service';
import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ExerciseService } from 'app/services/exercise.service';
import { environment } from 'environments/environment';
declare var $, wordfindgame

@Component({
  selector: 'ex-sopa2',
  templateUrl: './sopa2.component.html',
  styleUrls: ['./sopa2.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Sopa2Component implements OnInit, OnDestroy {

  @Input() review
  @Input() show_errors;
  @Input() controlSession : boolean = false;
  @Input() slide : boolean = false; @Input() current;
  exercise
  draggingOption
  exerciseIsEnded = false
  finished = false

  elementsOk
  elementsErr

  numero_random = Math.floor(Math.random() * (10000 - 0 + 1) + 0)

  @HostListener('window:message', ['$event'])
    exerciseListener(event){
      let data = event.data;
      switch (data) {
          case "SHOW_SOLUTION":
              if (this.exerciseService.showingSolution) {
                  setTimeout(this.showAnswersTimeOut.bind(this), 100);
                  break;
              } else {
                  $('.pre_solved').addClass('solved')
                  break;
              }
        case "SHOW_ANSWER":
          setTimeout(this.ShowAnswer.bind(this), 1000)
          break;
          case "SHOW_CUSTOM":
              $('.pre_solved').removeClass('solved');
              break;
      }
    }

  constructor(private exerciseService: ExerciseService,
            private fileService: FileService) {
    this.exercise = this.exerciseService.getExerciseDefinition();
    this.FillExercise()
  }

  getImgSrc(){
    return this.exerciseService.findImgUrl("Img/" + this.exercise.palabra + ".png")
  }

  ngOnInit() {
    if (this.review) {
      this.elementsOk = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsOK
      );
      this.elementsErr = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsERR
      );
      if (this.show_errors == false) {
        setTimeout(this.showSolution.bind(this), 1000)
      } else if (this.show_errors == true) {
        setTimeout(this.ShowAnswer.bind(this), 1000)
      }
    } else {
      this.initInteract();
    }
  }

  ngOnDestroy() {
  }

  gamePuzzle
  words = []

  showSolution() {
    wordfindgame.finishGame();
    wordfindgame.solve(this.gamePuzzle, this.words, this.review);
  }

  ShowAnswer() {
    $('.solved').removeClass('solved');
    wordfindgame.finishGame();
    wordfindgame.answered2(this.gamePuzzle, this.words, this.elementsOk);
  }

  initInteract() {
    this.exerciseService.initInteract({
      onmove: (event) => {
        this.draggingOption = event.target
      },
      drop: (event) => {

      },
    });
  }

  showAnswersTimeOut() {
    $('li.word').each((index, element) => {
      var val = $(this).text();
      if ($(this).hasClass('wordFound')) {
        //this.exerciseService.answer(index, true, 1);
      } else {
        //this.exerciseService.answer(index, false, 1);
      }
    });
    wordfindgame.finishGame();
    wordfindgame.solve(this.gamePuzzle, this.words, this.review);
  }

  reloj
  muestra

  FillExercise() {
    this.words.push(this.exercise.palabra);


    //En el json vendrá definido el parámetro ‘size’ para especificar tamaño del recuadro:
    //1.	‘S’ = 10 * 10 letras
    //2.	‘M’ = 13 * 13 letras
    //3.	‘L’ = 16 * 16 letras

    if (this.exercise.muestra) {
      this.muestra = true;
    }


    var orientaciones = ['horizontal', 'horizontalBack', 'vertical', 'verticalUp',
      'diagonal', 'diagonalUp', 'diagonalBack', 'diagonalUpBack'];



    //Según el parámetro ‘level’ también daremos más dificultad al ejercicio:
    //1.	Opción 1: muestra solo palabras en horizontal y vertical
    //2.	Opcion2: palabras en horizontal y vertical de izda a derecha y de derecha a izquierda
    //3.	Opción 3: muestra también palabras en diagonal de arriba a abajo
    //4.	Opción 4: muestra las dos anteriores y palabras al revés(abajo arriba, derecha izquierda y diagonal de abajo a arriba)

    if (this.exercise.level == 1) {
      orientaciones = ['horizontal', 'vertical'];
    }

    if (this.exercise.level == 2) {
      orientaciones = ['horizontal', 'vertical', 'diagonal'];
    }

    if (this.exercise.level == 3) {
      orientaciones = ['horizontal', 'horizontalBack', 'vertical', 'verticalUp',
        'diagonal'];
    }

    if (this.exercise.level == 4) {
      orientaciones = ['horizontal', 'horizontalBack', 'vertical', 'verticalUp',
        'diagonal', 'diagonalUp', 'diagonalBack', 'diagonalUpBack'];
    }

    var opciones = { height: this.exercise.size, width: this.exercise.size, fillBlanks: true, orientations: orientaciones };

    setTimeout(() => {
      this.gamePuzzle = wordfindgame.create(this.words, '#juego' + this.numero_random, '#Palabras', opciones);

      wordfindgame.pre_solve(this.gamePuzzle, this.words);

      this.reloj = setInterval(() => {
        if (this.finished) {
          clearInterval(this.reloj);
          return;
        }
        if ($('.complete').length > 0) {
          this.finished = true;
          this.exerciseService.answer(1, true, 1);
          clearInterval(this.reloj);
        }
      }, 100);
    }, 100)

  }

}
