<section class="ww_content">
    <div class="container_ww">
        <div class="walinwa-box walinwa-box-green">

            <div class="walinwa-box-header spe_gap">
                <div>{{'SCHOOL.ADMIN.FACTURAS.TITLE' | translate}}</div>
                <img (click)="close()" src="https://walinwa.blob.core.windows.net/images/blanco-99.png?ngsw-bypass=true"
                    class="walinwa-box-header-close-icon">
            </div>

            <div class="walinwa-box-with-tabs" style="height: 100%;">
                <div class="walinwa-row-space walinwa-box-content" *ngIf="dataLoaded" style="height: 32em">
                    <div class="walinwa-table-container scrollable">
                        <table class="walinwa-table">
                            <tr>
                                <th style="width:15%">
                                    {{'SCHOOL.ADMIN.FACTURAS.NUM' | translate}}
                                </th>
                                <th style="width:25%">
                                    {{'SCHOOL.ADMIN.FACTURAS.PRINCIPAL' | translate}}
                                </th>
                                <th style="width:15%">
                                    {{'SCHOOL.ADMIN.FACTURAS.DATE' | translate}}
                                </th>
                                <th style="width:15%">
                                    {{'SCHOOL.ADMIN.FACTURAS.NET' | translate}}
                                </th>
                                <th style="width:15%">
                                    {{'SCHOOL.ADMIN.FACTURAS.RECLAIMED' | translate}}
                                </th>
                                <th style="width:15%">
                                    {{'SCHOOL.ADMIN.FACTURAS.PAYED' | translate}}
                                </th>
                            </tr>
                            <tr class="walinwa-table-row"
                                *ngFor="let walifactura of walifacturas  | sortBy:'desc':['Fecha','Name']"
                                (click)="exportarPDF(walifactura.Num)">
                                <td style="width:15%">{{ walifactura.Num }}</td>
                                <td style="width:25%">
                                    <span [ngClass]="{'text-red': DeudaFactura(walifactura)}"
                                        *ngIf="isSchool(walifactura)">{{
                                        walifactura.Name }}</span>
                                    <span [ngClass]="{'text-red': DeudaFactura(walifactura)}"
                                        *ngIf="!isSchool(walifactura)">{{
                                        walifactura.Empresa }}</span>
                                </td>
                                <td style="width:15%">{{ formatearFecha(walifactura.Fecha) }}</td>
                                <td style="width:15%">{{ getPrecioNeto(walifactura) }} €</td>
                                <td style="width:15%">
                                    {{ (walifactura.Reclama) ? formatearFecha(walifactura.Reclama) : '' }}</td>
                                <td style="width:15%">
                                    {{ (walifactura.FechaAbono) ? formatearFecha(walifactura.FechaAbono) : '' }}</td>
                            </tr>
                        </table>

                    </div>

                </div>
            </div>

        </div>
    </div>
</section>