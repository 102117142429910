<div class="walinwa-box-with-tabs" fxNgClass.gt-sm="scroll_vertical">
    <div class="walinwa-tabs walinwa-tabs-top">
        <div [hidden]="!currentUser.IdSchool"
            [ngClass]="{ 'selected': state == 'dictations','disabled-sidebar-button': !enableDictation}"
            (click)="editDicts()" class="walinwa-tab-gray">{{'SCHOOL.TEACHER.ACTIVITIES.DICTATION-TITLE' | translate }}</div>
        <div [hidden]="!currentUser.IdSchool"
            [ngClass]="{ 'selected': state == 'reading','disabled-sidebar-button': !enableReading}" (click)="reading()"
            class="walinwa-tab-gray">{{'SCHOOL.TEACHER.ACTIVITIES.READING-TEXT-TITLE' | translate }}</div>
        <div [hidden]="!currentUser.IdSchool"
            [ngClass]="{ 'selected': state == 'deduction','disabled-sidebar-button': !enableDeduction}"
            (click)="deduction()" class="walinwa-tab-gray">{{'SCHOOL.TEACHER.ACTIVITIES.DEDUCTION-TEXT-TITLE' | translate }}</div>
        <div [hidden]="!currentUser.IdSchool"
            [ngClass]="{ 'selected': state == 'hearing','disabled-sidebar-button': !enableHearing}"
            (click)="hearing()" class="walinwa-tab-gray">{{'SCHOOL.TEACHER.ACTIVITIES.HEARING-TEXT-TITLE' | translate }}</div>

    </div>
    <div class="walinwa-box-green">
        <router-outlet></router-outlet>
    </div>
</div>