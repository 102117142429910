<div class="walinwa-box">

    <form [formGroup]="form" class="walinwa-box-content">
        <div style="margin-bottom: -1rem;">
            <mat-form-field>
                <label class="font_blue">{{'CLASS' | translate}}</label>
                <mat-select id="select-class" [(ngModel)]="selectedClass" (selectionChange)="changeClass()"
                    [ngModelOptions]="{standalone: true}">
                    <mat-option class="scrollable" [value]="0">{{'SCHOOL.TEACHER.CONFIG.ALL-CLASSES' | translate}}</mat-option>
                    <mat-option class="scrollable" *ngFor="let class of classes | sortBy:'asc':['Level','Code']"
                        [value]="class.IdClass">{{ class.Name }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between start" fxLayoutAlign.lt-md="stretch" fxLayoutGap="2rem" fxLayoutGap.xs="1rem">
            <div fxFlex="" fxLayout="row wrap" fxLayoutAlign="start" class="spe_gap">
                <div fxFlex="100%">
                    {{'SCHOOL.TEACHER.CONFIG.GAMES.TIMEBLOCK' | translate}}
                </div>
                <div fxFlex="0 0 10rem" fxFlex.xs="100%">
                    <label class="font_blue">{{'SCHOOL.TEACHER.CONFIG.TIMEINI' | translate}}</label>
                    <div class="custom-form-field">
                        <!-- <input [ngClass]="{ 'iPad-time-input': isIpad }" formControlName="GameIni" type="time"> -->
                        <input [ngClass]="{ 'iPad-time-input': isIpad }" formControlName="GameIni" type="time">
                    </div>
                </div>
                <div fxFlex="0 0 10rem" fxFlex.xs="100%">
                    <label class="font_blue">{{'SCHOOL.TEACHER.CONFIG.TIMEEND' | translate}}</label>
                    <div class="custom-form-field">
                        <!-- <input [ngClass]="{ 'iPad-time-input': isIpad }" formControlName="GameEnd" type="time"> -->
                        <input [ngClass]="{ 'iPad-time-input': isIpad }" formControlName="GameEnd" type="time">
                    </div>
                </div>
            </div>
            <div fxFlex="" fxLayout="column" fxLayoutAlign="stretch" fxLayoutGap="2rem" fxLayoutGap.lt-md="1rem">
                <div>
                    <mat-checkbox (change)="enableMaxDailyGames()" formControlName="EnableMaxDailyGames">{{'SCHOOL.TEACHER.CONFIG.GAMES.ENABLEMAXDAILYGAMES' | translate}}</mat-checkbox>
                        <tooltip position="l" [info]="'SCHOOL.TEACHER.CONFIG.GAMES.ENABLEMAXDAILYGAMES-INFO' | translate"></tooltip>
                </div>
                <div [hidden]="!form.value.EnableMaxDailyGames" fxLayout="row" fxLayoutAlign="start baseline" class="spe_gap">
                    <label class="font_blue" fxFlex="120px" fxFlex.xs="103px">{{'SCHOOL.TEACHER.CONFIG.GAMES.DAILY-LIMIT' | translate}}</label>
                    <div class="ww_field_spe two" fxFlex="0 0 10rem" fxFlex.xs="">
                        <input formControlName="MaxDailyGames" type="number" min="0">
                    </div>
                </div>
            </div>
        </div>
        <div style="margin-top:30px; margin-bottom: 10px;">
            <label class="cust_label"><b>{{'SCHOOL.TEACHER.CONFIG.GAMES.TO-PLAY' | translate}}</b></label>
        </div>
        <div fxFlex="100%" class="session-play-select">
            <mat-form-field>
                <mat-select formControlName="SessionPlayCondition">
                    <mat-option class="scrollable" [value]="value.value" *ngFor="let value of SessionPlayConditions">{{
                        value.desc}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="schoolService.school?.Dictation">
            
            <mat-checkbox formControlName="DictationPlayCondition">{{'SCHOOL.TEACHER.CONFIG.GAMES.DICTATIONPLAYCONDITION' | translate}}
            </mat-checkbox>
            <tooltip position="R" [info]="'SCHOOL.TEACHER.CONFIG.GAMES.DICTATIONPLAYCONDITION-INFO' | translate"></tooltip>
        </div>
        <div *ngIf="schoolService.school?.ReadingText || schoolService.school?.DeductionText || schoolService.school?.HearingText">
            <mat-checkbox formControlName="ComprensionPlayCondition">{{'SCHOOL.TEACHER.CONFIG.GAMES.COMPRENSIONPLAYCONDITION' | translate}}</mat-checkbox>
            <tooltip position="R" [info]="'SCHOOL.TEACHER.CONFIG.GAMES.COMPRENSIONPLAYCONDITION-INFO' | translate"></tooltip>
        </div>
        <div>
            <mat-checkbox formControlName="MemoryPlayCondition">{{'SCHOOL.TEACHER.CONFIG.GAMES.MEMORYPLAYCONDITION' | translate}}</mat-checkbox>
            <tooltip position="R" [info]="'SCHOOL.TEACHER.CONFIG.GAMES.MEMORYPLAYCONDITION-INFO' | translate"></tooltip>
        </div>
        <div *ngIf="schoolService.school?.PersonalizedTest">
            <mat-checkbox formControlName="TestPlayCondition">{{'SCHOOL.TEACHER.CONFIG.GAMES.TESTPLAYCONDITION' | translate}}</mat-checkbox>
            <tooltip position="R" [info]="'SCHOOL.TEACHER.CONFIG.GAMES.TESTPLAYCONDITION-INFO' | translate"></tooltip>
        </div>
        <div class="spe_gap" fxLayout="row wrap" fxLayoutAlign="end end" fxLayoutAlign.xs="center center">
            <button (click)="save()" class="new_but azul">
                {{'SAVE' | translate}}
            </button>
        </div>
    </form>

</div>