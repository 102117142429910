import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SchoolService } from 'app/services/school.service';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NavigationService } from 'app/services/navigation.service';
import { CourseService } from 'app/services/course.service';
import { UserService } from 'app/services/user.service';
import * as moment from 'moment';
import 'moment/locale/es';
import { DateAdapter } from '@angular/material/core';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-parent-student',
  templateUrl: './new-parent-student.component.html',
  styleUrls: ['./new-parent-student.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewParentStudentComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

  currentStudentId = 0;
  currentStudent = null;
  calling = false;
  dataLoaded = false;
  maxDate;
  courses;
  showPassword = false;
  classes = [];
  school;
  currentUser;
  newUserForm: UntypedFormGroup;
  submited = false;
  showPassword1 = false;
  regExp = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜäÄëËïÏöÖç\s\.\-'ª’]+$/u

  constructor(
    private navigationService: NavigationService,
    private matDialog: MatDialog,
    private userService: UserService,
    private courseService: CourseService,
    private dateAdapter: DateAdapter<Date>,
    private _formBuilder: UntypedFormBuilder,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private _translateService: TranslateService
  ) {
    this._unsubscribeAll = new Subject();
    moment.locale('es');
    this.dateAdapter.setLocale("es-ES");
    iconRegistry.addSvgIcon('gafas_off', sanitizer.bypassSecurityTrustResourceUrl("https://walinwa.blob.core.windows.net/images/ver-pass-1.svg?ngsw-bypass=true"));
    iconRegistry.addSvgIcon('gafas_on', sanitizer.bypassSecurityTrustResourceUrl("https://walinwa.blob.core.windows.net/images/ver-pass.svg?ngsw-bypass=true"));
   }
   ngOnDestroy(): void
   {
       // Unsubscribe from all subscriptions
       this._unsubscribeAll.next();
       this._unsubscribeAll.complete();
   }

  ngOnInit() {
    if (!sessionStorage.getItem("currentUser")) return this.navigationService.exit();
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (!this.currentUser) return this.navigationService.exit();
      this.navigationService.enableBackButton(true, this.back.bind(this));
    this.courseService.Courses.pipe(takeUntil(this._unsubscribeAll)).subscribe(courses => {
        this.courses = courses;
        if (this.courses) {
            this.newStudent();
        }
    })
    this.school = this.currentUser.IdSchool != null;
    this.maxDate = new Date()
    this.userService.getClassesByIdTutor(this.currentUser.Id)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successGetClasses.bind(this), this.errorCallback.bind(this));
  }

  newStudent(){
    this.newUserForm = this._formBuilder.group({
      Name: ['', [Validators.required, Validators.pattern(this.regExp), Validators.maxLength(40)]],
      Email: [{ value: this.currentUser.Email, disabled: true }, [Validators.required, Validators.maxLength(50)]],
      FamilyName: ['', [Validators.required, Validators.pattern(this.regExp)]],
      Username: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(25),Validators.pattern(/^[0-9A-Za-zñÑ\.]+$/)]],
      Level:[this.courses[0].Level, [Validators.required]],
      DateOfBirth: ['', [Validators.required]],
      Password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(10)]]
    });
      this.newUserForm.markAllAsTouched();
  }

  back() {
    this.navigationService.go("/school/parentStudents", {});
  }

  showPass() {
    this.showPassword = !this.showPassword;
  };

  successCallbackCreateUser(response) {
    this.calling = false;
    if (response == null) {
      this.navigationService.goLogin();
    }
    else {
      this.currentStudent = response;
      this.currentStudentId = response.Id;
      if (!this.school) {
        var message = [];
        message[0] = this._translateService.instant('SCHOOL.PARENT.STUDENTS.NEW-STUDENT.MODAL-CREATE-USER-TITLE')
        message[1] = this._translateService.instant('SCHOOL.PARENT.STUDENTS.NEW-STUDENT.MODAL-CREATE-USER-MESSAGE')
        let confirmDialog = this.matDialog.open(FuseDialogContinueComponent, {
          disableClose: true
        });
        confirmDialog.componentInstance.title = message[0];
        confirmDialog.componentInstance.message1 = message[1];
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT')
        confirmDialog.componentInstance.margin = false;
        confirmDialog.componentInstance.margin = false;
        confirmDialog.afterClosed()
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(result => {
          this.back();
          confirmDialog = null;
        });
      }
    }
  };

  successGetClasses(response) {
    this.classes = response;
    if (this.classes.length == 0) {
      this.classes = null;
    }
    this.dataLoaded = true;
  }

  errorCallback() {
    this.navigationService.exit();
  }

  errorCallbackkGetCourses(error) {
    this.navigationService.goLogin();
  };

  cancel() {
    this.back();
  }

  saveUser() {
    this.submited = true;

    if (!this.newUserForm.valid && !parseInt(this.newUserForm.value.Password)) return;
    let userNameLenghtRegex = /^.{6,25}$/;
    let userNameRegex = /^[\w_ñÑ\.]{6,25}$/;

    let passLengthRegex = /^.{6,10}$/;
    let passRegex = /^[\w_ñÑ\.]{6,10}$/;
    if (this.newUserForm.value.DateOfBirth == '' || this.newUserForm.value.DateOfBirth == null) {
      this.newUserForm.controls['DateOfBirth'].setErrors({ 'required': true });
      return;
    }
    var validUsername = this.newUserForm.value.Username.match(userNameLenghtRegex);
    if (validUsername == null) {
      this.newUserForm.controls['Username'].setErrors({ 'length': true });
      return;
    }
    validUsername = this.newUserForm.value.Username.match(userNameRegex);
    if (validUsername == null) {
      this.newUserForm.controls['Username'].setErrors({ 'characters': true });
      return;
    }
    var validPass = this.newUserForm.value.Password.match(passLengthRegex);
    if (validPass == null) {
      this.newUserForm.controls['Password'].setErrors({ 'length': true });
      return;
    }
    validPass = this.newUserForm.value.Password.match(passRegex);
    if (validPass == null) {
      this.newUserForm.controls['Password'].setErrors({ 'characters': true });
      return;
    }

    if (this.calling)
      return;

    //New student data
    var user = {
      Level: this.newUserForm.controls['Level'].value,
      Name: this.newUserForm.controls['Name'].value,
      FamilyName: this.newUserForm.controls['FamilyName'].value,
      UserName: this.newUserForm.controls['Username'].value,
      DateOfBirth: this.formatDate(this.newUserForm.controls['DateOfBirth'].value),
      IdUserTutor: this.currentUser.Id,
      Email: this.newUserForm.controls['Email'].value,
    };
    this.calling = true;

    this.userService.CreateStudent(user, this.newUserForm.controls['Password'].value)
.pipe(takeUntil(this._unsubscribeAll))
.subscribe(this.successCallbackCreateUser.bind(this), this.errorCallbackCreateUser.bind(this));
  };

  formatDate(date) {
    return moment(date).hour(moment().hours());
  }

  errorCallbackCreateUser(response) {
    this.calling = false;
    if (response.error == "Username") {
      this.newUserForm.controls['Username'].setErrors({ 'unique': true });
    } else {
      this.errorCallback();
    }
  }

}
