import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { SpinnerService } from '@fuse/components/spinner/spinner.service';
import { SchoolService } from 'app/services/school.service';
import { ImportData } from './admin-classroom-data/admin-classroom-data.component';
import { MatDialog } from '@angular/material/dialog';
import { FuseDialogContinueComponent } from '@fuse/components/dialog-continue/dialog-continue.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NavigationService } from 'app/services/navigation.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-admin-classroom',
  templateUrl: './admin-classroom.component.html',
  styleUrl: './admin-classroom.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AdminClassroomComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;

  public readonly GOOGLE_AUTH: string = 'GOOGLE_AUTH';
  public readonly OBTAIN_CLASSROOM_DATA: string = 'OBTAIN_CLASSROOM_DATA';
  public readonly ERROR: string = 'ERROR';
  public state: string = this.GOOGLE_AUTH;
  public importing: boolean = false;
  public token: string = null;
  
  constructor(private _detector: ChangeDetectorRef,
              private _spinnerService: SpinnerService,
              private _schoolService: SchoolService,
              private _matDialog: MatDialog,
              private _router: Router,
              private _translateService: TranslateService,
              private _navigationService: NavigationService) { 
    this._unsubscribeAll = new Subject();
  }

  async ngOnInit(): Promise<void> {    
    this._navigationService.enableBackButton(true, (() => { this._navigationService.resetParams(); this._router.navigateByUrl("/school/admin"); }).bind(this));
    this._spinnerService.show();
    this.state = this.GOOGLE_AUTH;
    this._detector.detectChanges();
    this._spinnerService.hide();
  }
  
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  public getClassroomData(token: string) {
    this.token = token;
    this.state = this.OBTAIN_CLASSROOM_DATA;
    this._detector.detectChanges();
  }

  /*public authGoogleClassroom(clientData: ClientData) {
    this.state = this.GOOGLE_AUTH;

    sessionStorage.setItem('clientSecret', clientData.clientSecret);

    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?state=${clientData.clientId}&scope=https://www.googleapis.com/auth/classroom.courses.readonly https://www.googleapis.com/auth/classroom.rosters.readonly&include_granted_scopes=true&response_type=code&redirect_uri=${window.location.href.split('?')[0]}&client_id=${clientData.clientId}`;
  }*/

  public onError(e: any): void {
    this.state = this.ERROR;
    this._detector.detectChanges();
  }

  public importData(classroomData: ImportData) {
    this.importing = true;
    this._schoolService.importClassroomData(classroomData).pipe(takeUntil(this._unsubscribeAll)).subscribe(_ => this._showDialog(this._translateService.instant('SCHOOL.ADMIN.IMPORT-CLASSROOM.MODAL-SUCCESS-IMPORT-FROM-CLASSROM-MESSAGE'), '/school/admin/classes/'), error => this._showDialog(this._translateService.instant('SCHOOL.ADMIN.IMPORT-CLASSROOM.MODAL-SUCCESS-IMPORT-FROM-CLASSROM-MESSAGE', {error: (error.error || '').toString()})));
  }

  private _showDialog(message: string, urlRedirectOnClose: string = null) {   
    this.importing = false;
    this._detector.detectChanges();

    let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
        disableClose: true
    });

    confirmDialog.componentInstance.title = this._translateService.instant('SCHOOL.ADMIN.IMPORT-CLASSROOM.MODAL-IMPORT-FROM-CLASSROM-TITLE');
    confirmDialog.componentInstance.message1 = message;
    confirmDialog.componentInstance.space = true;
    confirmDialog.componentInstance.margin = false;
    confirmDialog.componentInstance.theme = "blue"
    confirmDialog.componentInstance.options = [{
        text: this._translateService.instant('ACCEPT'),
        callback: () => {}
    }];

    confirmDialog.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe(_ => {
      confirmDialog = null;

      if ((urlRedirectOnClose || '').length > 0)
        this._router.navigateByUrl(urlRedirectOnClose);
    });
  }
}