import { Component, OnInit, OnDestroy } from '@angular/core';
import { WalinwaUpdateService } from 'app/services/walinwa-update.service';
import { environment } from 'environments/environment';
import { interval, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'walinwa-update',
  templateUrl: './walinwa-update.component.html',
  styleUrls: ['./walinwa-update.component.scss']
})
export class WalinwaUpdateComponent implements OnInit, OnDestroy {
  public timeToUpdate: number = 0;
  private _subscription: Subscription;
  private _unsubscribeAll: Subject<void>;

  constructor(private _walinwaUpdateService: WalinwaUpdateService) {}

  ngOnInit(): void {
    this._unsubscribeAll = new Subject();
    this._walinwaUpdateService.newVersion().pipe(takeUntil(this._unsubscribeAll)).subscribe(_ => this._initCountdown())
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private _initCountdown() {
    if (!this._subscription) {
      this.timeToUpdate = environment.appCountdownTimeToUpdate;
      this._subscription = interval(1000).pipe(takeUntil(this._unsubscribeAll)).subscribe(_ => this.timeToUpdate > 0 ? this.timeToUpdate -= 1000 : this.updateWalinwa());
    }
  }

  private _clearCountdown() {
    this.timeToUpdate = 0;

    if (this._subscription)
      this._subscription.unsubscribe();
  }

  public updateWalinwa(): void {
    this._clearCountdown();
    this._walinwaUpdateService.forceUpdate();
  }
}