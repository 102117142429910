<div class="main_container" [style.background-image]="'url(&quot;https://walinwa.blob.core.windows.net/images/esquina-inf-der.svg&quot;)'">
    <section fxLayout="column" fxLayoutAlign="stretch" class="box_options">
        <div>
            <div class="box_op">
                <div class="title">
                    <span>{{'AUTHENTICATION.LICENCE.TITLE-LICENCE' | translate}}</span>
                </div>
                <div class="cont">
                    <form class="walinwa-box-content" [formGroup]="renewalForm" autocomplete="off">
                        <div>
                            <label class="font_blue"><b>{{'AUTHENTICATION.LICENCE.USER' | translate}}</b></label>
                            <mat-form-field class="spe-form-field">
                                <input matInput type="text" formControlName="userName">
                                <mat-error *ngIf="renewalForm.get('userName').hasError('required')">
                                    {{'FIELD-REQUIRED' | translate}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div>
                            <label class="font_blue"><b>{{'PASSWORD' | translate}}</b></label>
                            <mat-form-field class="spe-form-field">
                                <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
                                <mat-icon [svgIcon]="hide ? 'gafas_on' : 'gafas_off'" matSuffix (click)="hide = !hide">
                                </mat-icon>
                                <mat-error *ngIf="renewalForm.get('password').hasError('required')">
                                    {{'FIELD-REQUIRED' | translate}}
                                </mat-error>
                                <mat-error *ngIf="renewalForm.get('password').errors?.minlength">
                                    {{'PASSWORD-LENGTH' | translate}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div *ngIf="errorLicence">
                            <mat-error>
                                {{errorLicence | translate}}
                            </mat-error>
                        </div>
                        <div *ngIf="navigationService.params.ValidUntil">
                            <span>Tu licencia actual ha finalizado. <br>
                                Puedes continuar trabajando con tu usuario en Walinwa hasta el
                                {{ParseDate(navigationService.params.ValidUntil)}} por solo
                                {{navigationService.params.IndividualAmount}} euros.<br>
                                Para renovar la licencia pulsa Continuar.
                            </span>
                        </div>
                        <div fxLayoutAlign="end end" fxLayoutAlign.xs="center center" style="margin-top: 35px !important;">
                            <button mat-raised-button class="submit-button orange fixed_button" aria-label="LOGIN"
                                (click)="login(renewalForm)" [disabled]="!renewalForm.valid || loading">
                                {{'CONTINUE' | translate}}
                            </button>
                        </div>
                    </form>
                    <tpv [config]="tpvInfo" (onClose)="goLogin()" *ngIf="tpvInfo"></tpv>
                </div>
            </div>
        </div>
    </section>
</div>