import { Component, Input, OnInit, OnDestroy, HostListener, ViewEncapsulation } from '@angular/core';
import { SharedService } from 'app/core/shared/shared.service';
import { ExerciseService } from 'app/services/exercise.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'ex-ahorcar',
  templateUrl: './ahorcar.component.html',
  styleUrls: ['./ahorcar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AhorcarComponent implements OnInit, OnDestroy {

  @Input() review
  @Input() show_errors;
  @Input() controlSession : boolean = false;
  @Input() slide : boolean = false; @Input() current;
  exercise
  draggingOption
  exerciseIsEnded = false
  finished = false

  errors = 8
  secretWord = []
  letters = [{ name: 'a', chosen: false }, { name: 'b', chosen: false }, { name: 'c', chosen: false }, { name: 'd', chosen: false },
  { name: 'e', chosen: false }, { name: 'f', chosen: false }, { name: 'g', chosen: false }, { name: 'h', chosen: false }, { name: 'i', chosen: false },
  { name: 'j', chosen: false }, { name: 'k', chosen: false }, { name: 'l', chosen: false }, { name: 'm', chosen: false }, { name: 'n', chosen: false },
  { name: 'ñ', chosen: false }, { name: 'o', chosen: false }, { name: 'p', chosen: false }, { name: 'q', chosen: false }, { name: 'r', chosen: false },
  { name: 's', chosen: false }, { name: 't', chosen: false }, { name: 'u', chosen: false }, { name: 'v', chosen: false }, { name: 'w', chosen: false },
  { name: 'x', chosen: false }, { name: 'y', chosen: false }, { name: 'z', chosen: false }]
  wordError = false
  fragmentos = []

  elementsOk
  elementsErr

    @HostListener('window:message', ['$event'])
    exerciseListener(event) {
        let data = event.data;
        switch (data) {
            case "SHOW_SOLUTION":
                this.showSolution();
                break;
            case "SHOW_ANSWER":
            case "SHOW_CUSTOM":
                this.ShowAnswer();
                break;

        }
    }
  constructor(private exerciseService: ExerciseService,private sharedService: SharedService) {
    this.exercise = this.exerciseService.getExerciseDefinition();
    this.FillSecretWord();
    this.findSecretWordPosition();
  }
    ngOnDestroy() {
    }

  ngOnInit() {
    if (this.review) {
      this.elementsOk = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsOK
      );
      this.elementsErr = JSON.parse(
        this.exerciseService.getCurrentExercise().ElementsERR
      );
      this.errors = this.errors - this.elementsErr.length;
      if (this.show_errors == false) {
        this.showSolution();
      } else if (this.show_errors == true || this.show_errors == null) {
        this.ShowAnswer();
      }
    } else {
      this.initInteract();
    }
  }

  getImgClass() {
    return 'step' + this.errors;
  }
    getImgStyle() {
        return "url('https://walinwa.blob.core.windows.net/images/Exercises/Ahorca/" + this.errors + ".png')";
    }
    showSolution() {
        this.elementsErr.forEach(error => {
            this.letters[error].chosen = true;
        });
        this.elementsOk.forEach(acierto => {
            this.letters[acierto].chosen = true;
        });
        this.secretWord.forEach((letter) => {
            letter.show = true;
        });
    }

    ShowAnswer() {
        this.elementsErr.forEach(error => {
            this.letters[error].chosen = true;
        });
        this.elementsOk.forEach(acierto => {
            this.letters[acierto].chosen = true;
        });
        this.secretWord.forEach(palabra => {
            palabra.show = false;
            this.elementsOk.forEach(acierto => {
                if(this.letters[acierto].name == palabra.value){
                    palabra.show = true;
                }
            });
        })
    }

  initInteract() {
    this.exerciseService.initInteract({
      onmove: (event) => {
        this.draggingOption = event.target
      },
      drop: (event) => {

      },
    });
  }

  showAnswersTimeOut() {
    this.secretWord.forEach((letter) => {
      letter.show = true;
    });
  }

    FillSecretWord() {
    if (this.exercise.frase && this.exercise.palabra){
        let regEx = new RegExp(this.exercise.palabra, "ig");
        let palabra = this.exercise.frase.match(regEx)
        if (palabra != null && palabra.length > 0) {
            this.exercise.palabra  = palabra[0]
        }
    }
    this.exercise.palabra.split("").forEach((element : string) => {
      this.secretWord.push({ value: element, compare: this.replaceAccent(element).toLowerCase(), show: false });
    });
  }

  replaceAccent(item : string) {
    item = item.replace(/á/g, "a");
    item = item.replace(/é/g, "e");
    item = item.replace(/í/g, "i");
    item = item.replace(/ó/g, "o");
    item = item.replace(/ú/g, "u");
    item = item.replace(/ü/g, "u");
    item = item.replace(/Á/g, "A");
    item = item.replace(/É/g, "E");
    item = item.replace(/Í/g, "I");
    item = item.replace(/Ó/g, "O");
    item = item.replace(/Ú/g, "U");
    item = item.replace(/Ü/g, "u");
    return item;
  }

  addMargin

  findSecretWordPosition() {
    //this.exercise.frase
    var i;
    var primer_guion = -1;
    var aux = "";

    for (i = 0; i < this.exercise.frase.length; i++) {
      if (primer_guion < 0) {
        if (this.exercise.frase.toLowerCase().charAt(i) == '_') {
          primer_guion = i;
        }
        aux += this.exercise.frase.charAt(i);
      } else {
        if (this.exercise.frase.toLowerCase().charAt(i) != '_') {
          aux += this.exercise.frase.charAt(i);
        }
      }
    }
    this.fragmentos = aux.split(this.exercise.palabra);
    this.addMargin = false;
    if (this.fragmentos.length > 1
      && this.fragmentos[1].charAt(0) != '.'
      && this.fragmentos[1].charAt(0) != '?'
      && this.fragmentos[1].charAt(0) != ','
      && this.fragmentos[1].charAt(0) != ';'
      && this.fragmentos[1].charAt(0) != '!')
      this.addMargin = true;
  }

  try(letter) {
    if (this.finished)
      return;
    var found = false;
    var finishWord = true;
    let index = -1;  
    this.letters.forEach((item, letterIndex) => {
        if (item.name == letter.name) {
            item.chosen = true;
            index = letterIndex
        }
    });
    this.secretWord.forEach((item) => {
      if (item.compare == letter.name) {
        item.show = true;
        found = true;
      }
      if (item.show == false)
        finishWord = false;

    });
    if (found == false) {
        this.errors--;
        this.exerciseService.answer(
          index,
          false,
          this.secretWord.length
      );
      }
      else {
          let opts = this.secretWord.length;
          let resolved = this.secretWord.filter(l => l.show == true)
          if (resolved.length == this.secretWord.length) {
              opts = 1
          }
          this.exerciseService.answer(
              index,
              true,
              opts
          );
      }
      if (this.errors == 0 || finishWord) {
        this.finished = true;
        if (this.errors == 0) {
            this.exerciseService.getCurrentExercise().Result = 'ERR'
        }
        else if (finishWord && this.errors > 0) {
            this.exerciseService.getCurrentExercise().Result = 'OK'
        }
        this.exerciseService.endExercise()
        this.showAnswersTimeOut()
    }
  }

}
