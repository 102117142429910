import { Component, Input, OnInit, OnDestroy, HostListener, ViewEncapsulation } from '@angular/core';
import { ExerciseService } from 'app/services/exercise.service';

@Component({
    selector: 'ex-siglas',
    templateUrl: './siglas.component.html',
    styleUrls: ['./siglas.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SiglasComponent implements OnInit, OnDestroy {
    @Input() review;
    @Input() show_errors;
    @Input() controlSession : boolean = false;
    @Input() slide : boolean = false; @Input() current;
    exercise;
    finished = false;
    answered;

    elementsOk = [];
    elementsErr = [];
    tmpPhrases = []
    numErr = 0

    @HostListener('window:message', ['$event'])
    exerciseListener(event){
        let data = event.data;
        switch (data) {
            case "SHOW_SOLUTION":
                if (this.exerciseService.showingSolution) {
                    setTimeout(this.ShowAnswerTimeout.bind(this), 100);
                    break;
                } else {
                    setTimeout(this.showSolution.bind(this), 100);
                    break;
                }
            case "SHOW_ANSWER":
            case "SHOW_CUSTOM":
                setTimeout(this.ShowAnswer.bind(this),100);
                break;
        }
    }
    constructor(private exerciseService: ExerciseService) {
        this.exercise = this.exerciseService.getExerciseDefinition();
        this.tmpPhrases = this.exercise.frases;
    }
    ngOnDestroy() {
      

    }

    ngOnInit() {
        if (this.review) {
            this.elementsOk = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsOK
            );
            this.elementsErr = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsERR
            );
            if (this.show_errors == false) {
                this.showSolution();
            } else if (this.show_errors == true || this.show_errors == null) {
                this.ShowAnswer();
            }
        }
        else {
            this.tmpPhrases = this.shuffle(JSON.parse(JSON.stringify(this.exercise.frases)));
            this.tmpPhrases.forEach(x => {
                x.answered = null;
            })
        }
    }
    shuffle(array) {
        let currentIndex = array.length, temporaryValue, randomIndex;
        while (0 !== currentIndex) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array;
    }
    azul() {
        return this.exercise.enunciado.indexOf("<br><br><b>") != -1;
    }

    validate(index) {
        var numTotal = 0, numTotalOk = 0;
        if (this.finished)
            return;
        if (this.tmpPhrases[index].answered == true)
            return;
        let realIndex = this.exercise.frases.findIndex(x => x.frase == this.tmpPhrases[index].frase)
        if (this.tmpPhrases[index].opcion == true) {
            this.tmpPhrases[index].answered = true;
            // exerciseService.AddOk(index);
            this.exerciseService.answer(realIndex, true, 1)
        } else {
            // $scope.$emit("error");
            this.tmpPhrases[index].answered = false;
            this.numErr++;
            // exerciseService.AddError(index);
            this.exerciseService.answer(realIndex, false, 1)
        }
        for (var i = 0; i < this.tmpPhrases.length; i++) {
            if (this.tmpPhrases[index].answered == false)
                return;
        }
        this.finished = true;
        if (numTotalOk > 1 && this.numErr >= (numTotal * 0.4))
            window.postMessage("EXERCISE_SOLVED_MSG_2", "*");
        else if (numTotalOk == 1 && this.numErr > (numTotal * 0.5))
            window.postMessage("EXERCISE_SOLVED_MSG_1", "*");
    }
    getColor(i){
        let colors = [
            'var(--morado)',
            'var(--naranja)',
            'var(--azul)',
        ]
        return colors[i]
            
    }
    ShowAnswerTimeout(){
        this.finished = true;
    }
    showSolution() {
        this.answered = true;
        this.tmpPhrases.forEach((value, key) => {
            if (value.opcion == true)
                value.answered = true;
            else
                value.answered = null;
        });
    }

    ShowAnswer() {
        switch (this.exerciseService.getCurrentExercise().Result) {
            case "JMP":
                this.answered = null;
                break;
            case "OK-JMP":
                this.answered = null;
                break;
            case "ERR-JMP":
                this.answered = false;
                break;
            case "OUT":
                this.answered = null;
                break;
            case "OK-OUT":
                this.answered = null;
                break;
            case "ERR-OUT":
                this.answered = false;
                break;
            case "ERR-OK":
                this.answered = false;
                break;
            case "OK":
                this.answered = true;
                break;
        }

        this.tmpPhrases.forEach((value, key) => {
            value.answered = null;
            for (var p = 0; p < this.elementsErr.length; p++) {
                if (key == this.elementsErr[p]) value.answered = false;
            }
            for (var p = 0; p < this.elementsOk.length; p++) {
                if (key == this.elementsOk[p] && value.answered != false)
                    value.answered = true;
            }
        });
    }

}
