import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { AuthService } from "app/core/guards/auth.service";
import { ExerciseService } from "app/services/exercise.service";
import { Observable } from "rxjs";
declare var $;

@Component({
    selector: "ex-selec",
    templateUrl: "./selec.component.html",
    styleUrls: ["./selec.component.scss"],
    encapsulation: ViewEncapsulation.None,

})
export class SelecComponent implements OnInit, OnDestroy {
    @Input() review;
    @Input() show_errors;
    @Input() controlSession : boolean = false;
    @Input() slide : boolean = false; @Input() current;
    @Input() events: Observable<void>;
    exercise;
    draggingElement;
    letters = [];
    ok = [];
    error = [];
    finished = false;

    elementsOk = [];
    elementsErr = [];
    leftWords;
    rightWords;
    leftValidation;
    rightValidation;
    eventsSubscription;

    @HostListener('window:message', ['$event'])
    exerciseListener(event){
        let data = event.data;
        switch (data) {
            case "SHOW_SOLUTION":
                this.finished = true;
                if (this.exerciseService.showingSolution) {
                    setTimeout(this.ShowAnswersTimeOut.bind(this), 100);
                    break;
                } else {
                    setTimeout(this.showSolution.bind(this), 100);
                    break;
                }
            case "SHOW_ANSWER":
                this.ShowAnswer();
                break;
            case "SHOW_CUSTOM":
                this.exercise = this.exerciseService.getExerciseDefinition();
                this.initExercise()
                break;
        }
    }
    
    constructor(private exerciseService: ExerciseService) {
        this.exercise = this.exerciseService.getExerciseDefinition();
    }
    ngOnDestroy() {
       
    }

    ngOnInit() {
        this.initExercise()
    }
    initExercise(){
        this.letters = [];
        for (var i = 0; i < this.exercise.palabras[0].palabra.length; i++) {
            this.letters.push({
                letter: this.exercise.palabras[0].palabra.charAt(i),
                answer: false,
                answered: null,
            });
            if (i + 1 != this.exercise.palabras[0].palabra.length)
                this.letters.push({
                    letter: "",
                    answer: false,
                    answered: null,
                });
        }
        var counter = -1;
        for (var i = 0; i < this.exercise.palabras[0].muestra.length; i++) {
            counter += this.exercise.palabras[0].muestra[i].length * 2;
            if (counter < this.letters.length && this.letters[counter])
                this.letters[counter].answer = true;
        }
        if (this.review) {
            this.elementsOk = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsOK
            );
            this.elementsErr = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsERR
            );
            if (this.show_errors == false) {
                this.showSolution();
              } else if (this.show_errors == true) {
                this.ShowAnswer();
              }
        }
    }
    validate(index) {
        if (this.letters[index].letter != "" || this.finished) return;
        for (var i = 0, len = this.letters.length; i < len; i++) {
            if (this.letters[i].answered == false) {
                this.letters[i].answered = null;
                this.letters[i].letter = "";
            }
        }
        this.letters[index].letter = "-";
        if (this.letters[index].answer) {
            this.letters[index].answered = true;
            this.elementsOk.push(index);
        } else {
            this.letters[index].answered = false;
            //this.$emit("error");
            this.elementsErr.push(index);
        }

        var done = false;
        for (var i = 0, len = this.letters.length; i < len; i++) {
            if (this.letters[i].answered == false) return;
            if (
                this.letters[i].answer == true &&
                this.letters[i].answered != true
            )
                return;
        }
        let options = 0;
        for (let palabra of this.exercise.palabras)
            options += palabra.muestra.length - 1;
        if (this.elementsErr.length > 0)
            this.exerciseService.answer(this.elementsErr, false, 1);
        if (this.elementsOk.length > 0)
            this.exerciseService.answer(this.elementsOk, true, 1);

        //FINISH
        //this.$emit("timer-stop");
        //window.postMessage("TIMER_END", "*");
        this.finished = true;
        //this.$emit("exercise-solved");
    }
    showSolution() {
        for (var i = 0, len = this.letters.length; i < len; i++) {
            if (this.letters[i].answer == true) {
                this.letters[i].answered = true;
                this.letters[i].letter = "-";
            }
        }
        for (var i = 0, len = this.letters.length; i < len; i++) {
            if (this.letters[i].answered == false) {
                this.letters[i].answered = null;
                this.letters[i].letter = "";
            }
        }
    }
    ShowAnswer() {

        for (var p = 0; p < this.elementsErr.length; p++) {
            for (var q = 0; q < this.elementsErr[p].length; q++) {
                this.letters[this.elementsErr[p][q]].answered = false;
                this.letters[this.elementsErr[p][q]].letter = "-";
            }
        }
        for (var p = 0; p < this.elementsOk.length; p++) {
            for (var q = 0; q < this.elementsOk[p].length; q++) {
                this.letters[this.elementsOk[p][q]].answered = true;
                this.letters[this.elementsOk[p][q]].letter = "-";
            }
        }
    }

    ShowAnswersTimeOut() {
        for (var i = 0, len = this.letters.length; i < len; i++) {
            if (this.letters[i].answer == true) {
                this.letters[i].answered = true;
                this.letters[i].letter = "-";
            }
        }
        for (var i = 0, len = this.letters.length; i < len; i++) {
            if (this.letters[i].answered == false) {
                this.letters[i].answered = null;
                this.letters[i].letter = "";
            }
        }
    };
}
