import { environment } from 'environments/environment';
import { DeductionTextService } from "./../../../../services/deduction-text.service";
import {
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ExerciseService } from "app/services/exercise.service";
import { SessionService } from "app/services/session.service";
import { UserService } from "app/services/user.service";
import { AppService } from "app/app.service";
import * as moment from "moment";
import { NavigationService } from "app/services/navigation.service";
import { ReadingTextService } from "app/services/reading-text.service";
import { SchoolService } from "app/services/school.service";
import { DictationService } from "app/services/dictation.service";
import { CalDeductionTextComponent } from "app/components/cal-deduction-text/cal-deduction-text.component";
import { CalReadingTextComponent } from "app/components/cal-reading-text/cal-reading-text.component";
declare var $;

@Component({
    selector: "app-session",
    templateUrl: "./session.component.html",
    styleUrls: ["./session.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class SessionComponent implements OnInit {
    currentUser: any;
    enableDictation: boolean;
    selectedIdUser: number;
    selectedIdClass: number;
    enableReadingText: boolean;
    enableDeductionText: boolean;
    enableHearingTexts: boolean;
    state: string;
    IdUser: number;
    navigate: any;
    IdClass: number;
    isAdmin: boolean;
    isProd = environment.production;
    constructor(
        private activatedRoute: ActivatedRoute,
        private navigationService: NavigationService,
        private schoolService: SchoolService
    ) {
        moment.locale("es");
    }

    ngOnInit() {
        if (!sessionStorage.getItem("currentUser"))
            return this.navigationService.exit();
        this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
        if (!this.currentUser) return this.navigationService.exit();
        if (this.navigationService.params.IdUser)
            this.selectedIdUser = this.navigationService.params.IdUser;
        if (this.navigationService.params.IdClass)
            this.selectedIdClass = this.navigationService.params.IdClass;
        if (this.navigationService.params.isAdmin)
            this.isAdmin = this.navigationService.params.isAdmin;
        this.navigationService.enableBackButton(true, () => {
            this.navigationService.go("/school/students", {
                IdUser: this.selectedIdUser,
                IdClass: this.selectedIdClass,
            });
        });
        if (this.navigationService.params.navigate)
            this.navigate = this.navigationService.params.navigate;
        this.enableReadingText = this.schoolService.school.ReadingText;
        this.enableDictation = this.schoolService.school.Dictation;
        this.enableDeductionText = this.schoolService.school.DeductionText;
        this.enableHearingTexts = this.schoolService.school.HearingText;
        this.IdUser = this.activatedRoute.params["value"].IdUser;
        this.IdClass = this.navigationService.params.IdClass;
        if (this.navigate) {
            this.initNavigate();
        } else {
            this.goSesion();
            this.state = "session";
        }
    }
    errorCallback() {
        this.navigationService.exit();
    }
    initNavigate() {
        switch (this.navigate) {
            case "dictation":
                this.state = "dictations";
                this.goDicts();
                break;
            case "deduction":
                this.state = "deduction";
                this.goDeduction();
                break;
            case "reading":
                this.state = "reading";
                this.goReading();
                break;
            case "hearing":
                this.state = "hearing";
                this.goHearing();
                break;

            default:
                this.state = "session";
                this.goSesion();
                break;
        }
    }

    goDicts() {
        if (!this.enableDictation) {
            return;
        }
        this.state = "dictations";
        if (!this.isAdmin) {
            this.navigationService.go(
                "/school/session/" +
                    this.IdUser +
                    "/" +
                    this.IdClass +
                    "/dictationsReview/" +
                    this.IdUser +
                    "/" +
                    this.IdClass,
                {
                    IdUser: this.selectedIdUser,
                    IdClass: this.selectedIdClass,
                }
            );
        } else {
            this.navigationService.go(
                "/school/admin/students/null/sessionAdmin/" +
                    this.IdUser +
                    "/" +
                    this.selectedIdClass +
                    "/dictationsReview/" +
                    this.IdUser +
                    "/" +
                    this.selectedIdClass,
                {
                    IdUser: this.selectedIdUser,
                    IdClass: this.selectedIdClass,
                    isAdmin: this.isAdmin,
                }
            );
        }
    }

    goReading() {
        if (!this.enableReadingText) {
            return;
        }
        this.state = "reading";
        if (!this.isAdmin) {
            this.navigationService.go(
                "/school/session/" +
                    this.IdUser +
                    "/" +
                    this.IdClass +
                    "/readingReview/" +
                    this.IdUser +
                    "/" +
                    this.IdClass,
                {
                    IdUser: this.selectedIdUser,
                    IdClass: this.selectedIdClass,
                }
            );
        } else {
            this.navigationService.go(
                "/school/admin/students/null/sessionAdmin/" +
                    this.IdUser +
                    "/" +
                    this.selectedIdClass +
                    "/readingReview/" +
                    this.IdUser +
                    "/" +
                    this.selectedIdClass,
                {
                    IdUser: this.selectedIdUser,
                    IdClass: this.selectedIdClass,
                    isAdmin: this.isAdmin,
                }
            );
        }
    }
    goDeduction() {
        if (!this.enableDeductionText) {
            return;
        }
        this.state = "deduction";
        if (!this.isAdmin) {
            this.navigationService.go(
                "/school/session/" +
                    this.IdUser +
                    "/" +
                    this.IdClass +
                    "/deductionReview/" +
                    this.IdUser +
                    "/" +
                    this.IdClass,
                {
                    IdUser: this.selectedIdUser,
                    IdClass: this.selectedIdClass,
                }
            );
        } else {
            this.navigationService.go(
                "/school/admin/students/null/sessionAdmin/" +
                    this.IdUser +
                    "/" +
                    this.selectedIdClass +
                    "/deductionReview/" +
                    this.IdUser +
                    "/" +
                    this.selectedIdClass,
                {
                    IdUser: this.selectedIdUser,
                    IdClass: this.selectedIdClass,
                    isAdmin: this.isAdmin,
                }
            );
        }
    }

    goHearing() {
        if (!this.enableHearingTexts) {
            return;
        }
        this.state = "hearing";
        if (!this.isAdmin) {
            this.navigationService.go(
                "/school/session/" +
                    this.IdUser +
                    "/" +
                    this.IdClass +
                    "/hearingReview/" +
                    this.IdUser +
                    "/" +
                    this.IdClass,
                {
                    IdUser: this.selectedIdUser,
                    IdClass: this.selectedIdClass,
                }
            );
        } else {
            this.navigationService.go(
                "/school/admin/students/null/sessionAdmin/" +
                    this.IdUser +
                    "/" +
                    this.selectedIdClass +
                    "/hearingReview/" +
                    this.IdUser +
                    "/" +
                    this.selectedIdClass,
                {
                    IdUser: this.selectedIdUser,
                    IdClass: this.selectedIdClass,
                    isAdmin: this.isAdmin,
                }
            );
        }
    }

    goSesion() {
        this.state = "session";
        if (!this.isAdmin) {
            this.navigationService.go(
                "/school/session/" +
                    this.IdUser +
                    "/" +
                    this.IdClass +
                    "/sessionReview/" +
                    this.IdUser +
                    "/" +
                    this.IdClass,
                {
                    IdUser: this.selectedIdUser,
                    IdClass: this.selectedIdClass,
                }
            );
        } else {
            this.navigationService.go(
                "/school/admin/students/null/sessionAdmin/" +
                    this.IdUser +
                    "/" +
                    this.selectedIdClass +
                    "/sessionReview/" +
                    this.IdUser +
                    "/" +
                    this.selectedIdClass,
                {
                    IdUser: this.selectedIdUser,
                    IdClass: this.selectedIdClass,
                    isAdmin: this.isAdmin,
                }
            );
        }
    }
}
