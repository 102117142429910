import { Component, OnInit, Input, OnDestroy, HostListener, ViewEncapsulation } from "@angular/core";
import { ExerciseSolvedEnum } from "app/core/shared/enums/exercise-solved.enum";
import { ExerciseService } from "app/services/exercise.service";

@Component({
    selector: "ex-guionp",
    templateUrl: "./guionp.component.html",
    styleUrls: ["./guionp.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class GuionpComponent implements OnInit, OnDestroy {
    @Input() review;
    @Input() show_errors;
    @Input() controlSession : boolean = false;
    @Input() slide : boolean = false; @Input() current;
    exercise;
    palabras;
    draggingOptionIndex;
    draggingOption
    exerciseIsEnded = false;
    finished = false;

    elementsOk;
    elementsErr;


    constructor(private exerciseService: ExerciseService) {
        this.exercise = this.exerciseService.getExerciseDefinition();
        this.palabras = JSON.parse(JSON.stringify(this.exercise.palabras));
    }
    @HostListener('window:message', ['$event'])
    exerciseListener(event){
        switch (event.data) {
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_SOLUTION]:
                this.finished = true;
                if (this.exerciseService.showingSolution) {
                    setTimeout(this.showAnswersTimeOut.bind(this), 100);
                    break;
                } else {
                    setTimeout(this.showSolution.bind(this), 100);
                    break;
                }
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_ANSWER]:
                this.ShowAnswer();
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.SHOW_CUSTOM]:
                this.exercise = this.exerciseService.getExerciseDefinition();
                this.palabras = JSON.parse(JSON.stringify(this.exercise.palabras));
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.TIMER_END]:
                this.finished = true;
                break;
            case this.exercisesSolvedEnum[this.exerciseSolvedEnum.EXERCISE_END]:
                this.finished = true;
                break;
        }
    }
    public get exercisesSolvedEnum(): typeof ExerciseSolvedEnum{
        return this.exerciseSolvedEnum
    }
    
    public get exerciseSolvedEnum(): typeof ExerciseSolvedEnum{
        return ExerciseSolvedEnum
    }

    ngOnDestroy() {
    }

    isArray(txt) {
        return Array.isArray(txt)
    }

    ngOnInit() {
        if (this.review) {
            this.elementsOk = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsOK
            );
            this.elementsErr = JSON.parse(
                this.exerciseService.getCurrentExercise().ElementsERR
            );
            if (this.show_errors == false) {
                this.showSolution();
            } else if (this.show_errors == true) {
                this.ShowAnswer();
            }
        } else {
            this.initInteract();
        }
        
    }

    // showSolution() {
    //     this.palabras = JSON.parse(JSON.stringify(this.exercise.palabras));
    //     for (var i = 0; i < this.palabras.length; i++) {
    //         this.palabras[i].answered = true;
    //         this.palabras[i].palabraGuion = [];
    //         this.palabras[i].palabraGuion.push(this.palabras[i].palabra);
    //     }
    // }
    showSolution(answered = true) {
        this.finished = this.exerciseService.showingSolution;
        this.palabras = JSON.parse(JSON.stringify(this.exercise.palabras));
        for (var i = 0; i < this.palabras.length; i++) {
            this.palabras[i].answered = answered;
            this.palabras[i].palabraGuion = [];
            this.palabras[i].palabraGuion.push(this.palabras[i].palabra);
        }
    }
    ShowAnswer(){
        this.palabras = JSON.parse(JSON.stringify(this.exercise.palabras));
        for (var i = 0; i < this.palabras.length; i++) {
          var answered = null;
          this.palabras[i].palabraGuion = [];
          for (var p = 0; p < this.elementsOk.length; p++) {
            if (this.palabras[i].palabra == this.palabras[this.elementsOk[p]].palabra)
                answered = true;
          }
          for (var p = 0; p < this.elementsErr.length; p++) {
            if (this.palabras[i].palabra == this.palabras[this.elementsErr[p]].palabra)
                answered = false;
          }
            this.palabras[i].answered = answered;
            this.palabras[i].palabraGuion = [];
            this.palabras[i].palabraGuion.push(this.palabras[i].palabra);
        }
    }

    initInteract() {
        this.exerciseService.initInteract({
            onmove: (event) => {
                this.draggingOption = event.target
                this.draggingOptionIndex =
                    event.target.getAttribute("optionIndex");
            },
            drop: (event) => {
                // if (this.exerciseIsEnded) return;
                // if (this.exercise.finished) return;
                let wordIndex = event.target.getAttribute("wordIndex");
                let bin = event.target;
                let element = this.draggingOption
                if (this.exercise.operador == "nn") {
                    this.handleDropBox(element, bin);
                } else if (
                    this.exercise.operador == "1n" ||
                    this.exercise.operador == "11"
                ) {
                    this.handleDropWord(element, bin);
                } else {
                    this.handleDropLetter(element, bin);
                }
            },
        });
    }

    getCleanedString(cadena) {
        var specialChars = "!@#$^&%*()+=-[]/{}|:<>?,.";
        for (var i = 0; i < specialChars.length; i++) {
            cadena = cadena.replace(new RegExp("\\" + specialChars[i], "gi"), "");
        }
        cadena = cadena.toLowerCase();
        cadena = cadena.replace(/ /g, "_");
        cadena = cadena.replace(/á/gi, "a");
        cadena = cadena.replace(/é/gi, "e");
        cadena = cadena.replace(/í/gi, "i");
        cadena = cadena.replace(/ó/gi, "o");
        cadena = cadena.replace(/ú/gi, "u");
        cadena = cadena.replace(/ñ/gi, "n");
        return cadena;
    }

    bola() {
        for (var i = 0; i < this.exercise.opciones.length; i++) {
            if (this.getCleanedString(this.exercise.opciones[i]).length > 2) {
                return false;
            }
        }
        return true;
    }

    showAnswersTimeOut() {
        for (var i = 0; i < this.palabras.length; i++) {
            if (this.palabras[i].answered != true) {
                this.palabras[i].answered = null;
                this.palabras[i].palabraGuion = [];
                this.palabras[i].palabraGuion.push(this.palabras[i].palabra);
            }
        }
    }

    ValidateExerciseWord(optionIndex, wordIndex) {
        if (!this.palabras[wordIndex]) return false;
        var wordSolution = "";
        for (
            var j = 0, lenString = this.palabras[wordIndex].palabraGuion.length;
            j < lenString;
            j++
        ) {
            wordSolution += this.palabras[wordIndex].palabraGuion[j];
        }
        wordSolution = wordSolution.replace(
            "_",
            this.exercise.opciones[optionIndex]
        );
        if (wordSolution == this.palabras[wordIndex].palabra) {
            return true;
        }
        return false;
    }

    ValidateExerciseLetter(optionId, wordId, palabraGuionIndex) {
        var palabra = "";
        var palabraGuionSol = [];
        for (var i = 0; i < this.palabras[wordId].palabraGuion.length; i++) {
            if (this.palabras[wordId].palabraGuion[i] != "_") {
                palabra = palabra + this.palabras[wordId].palabraGuion[i];
                palabraGuionSol.push(this.palabras[wordId].palabraGuion[i]);
            } else {
                var fragment;
                if (this.palabras[wordId].palabraGuion.length > i + 1 && this.palabras[wordId].palabraGuion[i + 1] != '')//End of the word
                    fragment = this.palabras[wordId].palabra.substring(
                        palabra.length,
                        this.palabras[wordId].palabra.indexOf(this.palabras[wordId].palabraGuion[i + 1], palabra.length));
                else
                    fragment = this.palabras[wordId].palabra.substring(
                        palabra.length);
                palabra = palabra + fragment;
                palabraGuionSol.push(fragment);
            }
        }
        if (this.exercise.opciones[optionId] == palabraGuionSol[palabraGuionIndex]) {
            this.palabras[wordId].palabraGuion[palabraGuionIndex] = palabraGuionSol[palabraGuionIndex];
            return true;
        }
        return false;
    }

    handleDropLetter(item, bin) {


        for (var j = 0; j < this.palabras.length; j++) {
            if (this.palabras[j].answered == false)
                this.palabras[j].answered = null;
        }

        var idOption = item.getAttribute("idOption");
        var idWord = bin.getAttribute("idWord");
        var palabraGuionIndex = bin.getAttribute("palabraGuionIndex");

        if (this.ValidateExerciseLetter(idOption, idWord, palabraGuionIndex)) {
            this.exerciseService.answer(parseFloat(idWord), true, 100);
            //if (exerciseService.getCurrentExercise().EvaUserP)
            //    wordsService.AddOk(exerciseService.getCurrentExercise().IdSession, this.palabras[idWord].idPalabra, this.palabras[idWord].rae, this.palabras[idWord].palabraGuion.join(""));

            for (var i = 0; i < this.palabras[idWord].palabraGuion.length; i++) {
                if (this.palabras[idWord].palabraGuion[i] == "_") {
                    return; //OK but not finished
                }
            }
            this.palabras[idWord].answered = true;// Word Finished
            for (var i = 0; i < this.palabras.length; i++) {
                if (this.palabras[i].answered != true)
                    return; //Word finished  but exercise not finished
            }
            //Exercise finished     
            this.exerciseService.endExercise()
            // this.FinishExercise();
        } else {
            // this.$emit("error");
            //exerciseService.AddError(this.palabras[idWord]);
            this.exerciseService.answer(parseFloat(idWord), false, 100);
            //if (exerciseService.getCurrentExercise().EvaUserP)
            //    wordsService.AddError(exerciseService.getCurrentExercise().IdSession, this.palabras[idWord].idPalabra, this.palabras[idWord].rae, this.palabras[idWord].palabraGuion.join(""));
            this.palabras[idWord].answered = false;
        }
    }

    ValidateExerciseBox(optionId) {
        for (var i = 0, len = this.palabras.length; i < len; i++) {
            var wordSolution = "";
            for (var j = 0, lenString = this.palabras[i].palabraGuion.length; j < lenString; j++) {
                wordSolution += this.palabras[i].palabraGuion[j];
            }

            if (this.palabras[i].palabra != wordSolution.replace("_", this.exercise.opciones[optionId])) {
                return false;
            }

        }
        return true;
    }

    handleDropBox(item, bin) {
        for (var j = 0; j < this.palabras.length; j++) {
            this.palabras[j].answered = null;
        }
        var idOption = item.getAttribute("idOption");
        if (this.ValidateExerciseBox(idOption)) {
            for (var i = 0; i < this.palabras.length; i++) {
                this.exerciseService.answer(i, true, 100);
            }

            //if (exerciseService.getCurrentExercise().EvaUserP)
            //    for (var j = 0; j < this.palabras.length ; j++) {
            //        wordsService.AddOk(exerciseService.getCurrentExercise().IdSession, this.palabras[j].idPalabra, this.palabras[j].rae, this.palabras[idWord].palabraGuion.join(""));
            //    }

            for (var i = 0, len = this.palabras.length; i < len; i++) {
                for (var j = 0, lenString = this.palabras[i].palabraGuion.length; j < lenString; j++) {
                    if (this.palabras[i].palabraGuion[j] == "_")
                        this.palabras[i].palabraGuion[j] = this.exercise.opciones[idOption];
                }
                this.palabras[i].answered = true;
            }
            //Exercise finished     
            // FinishExercise();
            this.exerciseService.endExercise()

        } else {
            // this.$emit("error");
            for (var i = 0; i < this.palabras.length; i++) {
                this.exerciseService.answer(i, false, 100);
            }

            //if (exerciseService.getCurrentExercise().EvaUserP)
            //    for (var j = 0; j < this.palabras.length ; j++) {
            //        wordsService.AddError(exerciseService.getCurrentExercise().IdSession, this.palabras[j].idPalabra, this.palabras[j].rae, this.palabras[idWord].palabraGuion.join(""));
            //    }

            for (var j = 0, len = this.palabras.length; j < len; j++) {
                this.palabras[j].answered = false;
            }

        }
        return;
    }

    handleDropWord(element, bin) {


        let idOption = element.getAttribute("idOption");
        let idWord = bin.getAttribute("idWord");



        if (!this.palabras[idWord]) return;
        for (var j = 0; j < this.palabras.length; j++) {
            if (this.palabras[j].answered == false) this.palabras[j].answered = null;
        }
        if (this.ValidateExerciseWord(idOption, idWord)) {
            this.palabras[idWord].palabraGuion = [];
            this.palabras[idWord].palabraGuion.push(this.palabras[idWord].palabra);
            this.palabras[idWord].answered = true;
            this.exerciseService.answer(
                idWord,
                this.palabras[idWord].answered,
                100
            );
            for (var j = 0; j < this.palabras.length; j++) {
                if (this.palabras[j].answered != true) return;
            }
            // FinishExercise
            this.exerciseService.endExercise()
        } else {
            this.palabras[idWord].answered = false;
            this.exerciseService.answer(
                idWord,
                this.palabras[idWord].answered,
                this.palabras.length
            );
        }
    }

    hiddenWord(word) {
        if (this.exerciseService.exerciseIsEnded()) {
            this.exerciseIsEnded = true;
            this.exercise.finished = true;
        }
        if (this.exerciseService.isShowingSolution()) return word.palabra;
        if (word.answered == true) return word.palabra;
        let aux = "";
        for (let section of word.palabraGuion) {
            aux += section;
        }
        return aux;
    }
}
